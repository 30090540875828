import { Grid } from "@mui/material";
import Piece from "../../avatar/Piece";
import PiecePreview from "./PiecePreview";

const SkinOptions = ({ avatar, onChange }) => {
  const skinColor = [
    "tanned_skin",
    "yellow_skin",
    "pale_skin",
    "light_skin",
    "brown_skin",
    "dark_brown_skin",
    "black_skin",
    "green_skin",
    "purple_skin",
    "pink_skin",
    "blue_skin",
    "neon_green_skin",
    "luminous_blue_skin",
    "alien_gray_skin",
    "galactic_purple_skin",
    "deep_space_blue_skin",
    "sunshine_yellow_skin",
    "hot_pink_skin",
    "ruby_red_skin",
    "shiny_gold_skin",
    "metallic_silver_skin",
    "copper_skin",
    "fluorescent_orange_skin",
    "glowing_cyan_skin",
    "pearl_white_skin",
    "midnight_black_skin",
    "moon_glow_skin",
    "emerald_green_skin",
    "sapphire_blue_skin",
    "mystic_maroon_skin",
    "royal_indigo_skin",
    "charcoal_skin",
  ];

  const styles = {
    container: {
      width: "100%",
      overflow: "hidden",
      paddingTop: 2,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        gap={{ xs: 1 }}
      >
        {skinColor?.map((color, i) => (
          <PiecePreview
            key={i}
            value={color}
            size={100}
            selected={avatar?.options?.skinColor === color}
            onClick={() => onChange("skinColor", color)}
          >
            <Piece type="color" color={color} />
          </PiecePreview>
        ))}
      </Grid>
    </Grid>
  );
};

export default SkinOptions;
