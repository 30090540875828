const Mummy = ({ skinColor }) => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#fffadd",
      strokeWidth: 2,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    five: {
      fill: "#fffadd",
    },
    six: {
      fill: "#fff",
    },
    seven: {
      fill: "#f9e196",
    },
    eight: {
      fill: "#f2ebee",
    },
    nine: {
      fill: skinColor,
    },
    ten: {
      fill: "#efc7c7",
    },
    eleven: {
      fill: "#202020",
    },
    twelve: {
      fill: "#765460",
    },
  };

  return (
    <g>
      <path
        style={styles.twelve}
        d="m484.55,488.89c.37,2.21,1.88,4,3.83,4.52,2.58.69,5.62-.96,6.62-4,2.34.34,4.69.68,7.03,1.02-.33,2.28,1.07,4.36,2.98,4.84,2.22.56,4.81-1.12,5.15-3.83,4.45.44,8.9.88,13.35,1.33-.18.2-.49.54-.89.94-5.08,4.94-10.87,4.2-32.58,4.21-4.96,0-10.34.04-15.65-3.33-1.28-.81-2.25-1.63-2.88-2.2-.26-.72-.52-1.45-.78-2.17l14.22-1.5,8.24-.44,2.33.7"
      />
      <g>
        <path
          style={styles.five}
          d="m288.78,746.86c-.4-14.89.38-33.27,4.22-53.91,2.91-15.7,6.98-29.41,11.15-40.89,18.82-16.26,44.95-35.52,78.85-52.06,24.12-11.77,46.61-19.45,65.69-24.57,21.07,1.84,42.13,3.67,63.2,5.51,19.71,2.58,50.2,9.07,83.11,27.06,26.46,14.46,45.41,31.41,57.79,44.37,4.94,15.77,9.6,33.7,13.21,53.58,4.64,25.54,6.56,48.82,7.05,68.96-14.2,12.19-36.17,28.56-66.05,42-51.38,23.12-97.39,24.63-120,23.99-46.92-1.33-82.48-14.45-100-21.99-49.98-21.53-81.86-53.17-98.22-72.04Z"
        />
        <path
          style={styles.five}
          d="m518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12.32,7.46,1.17,8.35.49.51,1.02.81,1.4.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22.48-.66,1.01-1.11,1.37-1.37.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
        />
        <g>
          <path d="m460.98,525.94s-11.31,49.7-11.31,49.7c0,0-.13.58-.13.58-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7s1.95.44,1.95.44h0Z" />
          <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59,0,0,1.88.69,1.88.69h0Z" />
        </g>
        <path
          style={styles.one}
          d="m340.97,363.98c-.44.45-.85.92-.85.92-.63.71-1.47,1.75-2.32,3.08-3.83,6.02-4.02,12.25-4.08,14.87-.08,3.74-.24,12.36,5.93,19.7,1.41,1.67,5.86,6.72,13.5,8.02,5.31.91,9.62-.43,11.65-1.21.35,1.08.7,2.16,1.05,3.24.89,2.66,2.2,6.5,3.92,11.08,5.77,15.36,12.34,29.14,16.63,36.78,18.49,32.89,48.78,52.52,50.09,53.35,5.51,3.49,26.99,16.57,56.35,20.87,48.98,7.17,96.5-11.21,122.61-37.57,8.76-8.84,15.79-19.26,16.17-19.83,9.81-14.66,13.89-26.95,20.35-46.96,6.8-21.04,10.75-33.5,12.52-51.65,1.23-12.64,2.58-26.47-1.21-44.22-2.02-9.48-9.2-40.95-35.7-60.62,0,0-.97-.72-2.22-1.59-25.41-17.65-105.98-50.7-181.99-20.74-31.37,12.37-44.52,24.29-50.87,33.65-5.34,7.87-8.29,15.77-9.39,18.78-10.44,28.46-3.48,51.92-11.74,54.78-3.81,1.32-5.33-3.66-13.3-3.13-9.13.61-16.5,7.78-17.11,8.39Z"
        />
        <path
          style={styles.five}
          d="m352.66,352.75c-3.4,1.27-8.79,3.79-14,8.72-3.98,3.76-6.49,7.64-8.01,10.47.48,2.73.95,5.46,1.43,8.18l.58,10.68c2.82,5.64,5.64,11.28,8.46,16.92,3.85,1.28,7.7,2.56,11.55,3.84-.06.52-.47,4.95,2.92,8.18,2.78,2.64,6.19,2.66,6.89,2.64,1.57,4.17,3.13,8.35,4.7,12.52l11.35,7.45,11.21-114.06-6.72-19.95-11.52,6.48,1.42,4.65-3.87,3.7-.7,4.99-4.27,4.6-5.79,13.75c-.65,0-1.76.1-2.85.75-2.76,1.66-2.77,5.25-2.77,5.49Z"
        />
        <path
          style={styles.five}
          d="m657.52,291.55l3.33-4.35-6.13-4.89,8.18,1.56,17.32-9.65c-4.23-4.01-15.27-13.31-31.57-14.87-20.46-1.96-40.97,9.06-53.31,28.17,4.02-.59,22.09-3.21,38.44-4.96,2.06-.22,5.58-.56,9.91.52,6.65,1.67,11.24,5.71,13.82,8.46Z"
        />
        <path
          style={styles.five}
          d="m455.49,247.26l1.46.17c7.22-3.56,18.33-8.09,32.58-10.26,6.43-.98,11.2-1.13,16.7-1.3,15.31-.48,27.09-.85,40.17,3.65,4.53,1.56,7.57,3.14,14.61,4.96,7.84,2.03,13.78,2.58,15.31,2.75,7.73.89,20.24,5.6,41.3,22.29l-103.8,11.29-81.21-16.77,7.01-14.73,7.03-3,8.84.96Z"
        />
        <path
          style={styles.five}
          d="m376.15,312.21c.29-.16,2.72-7.35,2.72-7.35,0,0,2.46-11.54,9.86-13.46,7.41-1.92,5.33,12.23,5.33,12.23l-17.91,8.58Z"
        />
        <path
          style={styles.five}
          d="m387.72,290.78c.33-3.86,1.62-6.31,2.7-7.81,1-1.38,1.9-2.06,4.54-4.39,2.39-2.11,4.38-3.97,5.9-5.42,3.9-1.59,7.81-3.17,11.71-4.76-.2,3.6-1.25,13.64-8.87,20.29-.47.41-7.97,6.83-12.13,4.7,0,0-.3-.15-3.84-2.61h0Z"
        />
        <path
          style={styles.five}
          d="m381.95,447.56c.36.79,10.65,12.04,10.85,12.74s5.69-7.93,5.69-7.93l-7.41-7.1-9.13,2.28Z"
        />
        <path
          style={styles.five}
          d="m388.69,464.34c-.13.36-.03.7,0,.83,1.18,4,1.28,8.27,2.48,12.26,1.68,5.59-.87,11.16-4.17,18.39-1.45,3.18-4.02,7.86-8.48,12.65,1.07,1.78,2.13,3.55,3.2,5.33,4.37-3.6,8.74-7.2,13.11-10.8l-6.13,17.22,11.14-5.48v7.8l10.38,3.18,7.3-12.53-.91,12.53c1.96-1.51,5.9-4.98,8.35-10.85,3-7.2,1.92-13.63,1.4-16-8.14-11.7-16.29-23.39-24.43-35.09-5.37-.82-12.47-1.54-13.23.56Z"
        />
        <path
          style={styles.five}
          d="m662.45,387.96l7.9,12.26-.89,9.53-6.2,8.76.14,7.01s3.46,9.21-23.07,16.03-16.96-21.54-16.96-21.54l39.09-32.06Z"
        />
        <polygon
          style={styles.five}
          points="630.66 467.62 643.15 462.06 650.41 453.32 651.54 443.4 658.13 430.29 643.67 432.44 629.87 461.76 630.66 467.62"
        />
        <path
          style={styles.five}
          d="m614.69,474.28l-1.52,28.85-6.32,19.86v11.83l5.54-2.87,2.47-12.93,2.49,10.32s3.38-7.1,12.52-6.81c0,0,10.4.81,6.9-11.32,0,0-6.37-7.43-.11-15.26l3.22-12.54v-21.36l-22.15,7.54-3.04,4.68Z"
        />
        <path
          style={styles.five}
          d="m312,648.09s-10.16,1.82-11.09,4.13-2.41,8.08-2.41,8.08l-4.69,6.04-3.34,7.79,1.88,7.24,3.39,8.67-1.93,2.91-3.35,6.68s-4.76,4.45-3.64,10.54c0,0-3.82.61,3.64,15.09l-1.77,4.42-2.65,2.89s-.74,11.37,2.73,13.77,53-17.16,53-17.16l42.03-33.29s-27.79-37.15-28.47-38.32-43.33-9.48-43.33-9.48Z"
        />
        <path
          style={styles.five}
          d="m367.96,614.1l-11.54-3.92-20.09-2.53-17.89.3s-18.54,3.48-21.29,7.96h10.74l-3.32,4.01s16.11,3,5.61,17.29l49.95-20.34,7.83-1.92"
        />
        <path
          style={styles.five}
          d="m364.57,612.95c-.72-1.36,8.67-20.92,20.41-11.34"
        />
        <path
          style={styles.five}
          d="m380.81,601.61c.26-1.94,1.17-6.47,4.87-9.23,4.29-3.2,9.73-2.07,15.14-.94,3.27.68,8.46,2.22,14.09,6.07"
        />
        <path
          style={styles.five}
          d="m643.81,644.94c5.36,1.73,9.25,3.85,11.74,5.4,0,0,3.93,2.45,15.45,12.91h0c2.11,2.32,5.19,5.22,9.4,7.82,5.86,3.61,11.41,5.11,15.07,5.78-1.77-.16-4.07-.14-6.65.48-6.78,1.63-10.78,6.31-12.2,8.17l-6.18-5.8,3.71,6.19-5.11,5.09c-4.36-6.17-8.73-12.34-13.09-18.5"
        />
        <path
          style={styles.five}
          d="m654.98,667.95c.39.76,10.98,20.08,10.98,20.08l-1.75,9.36c.87.87,3.01,3.24,3.74,7,.85,4.36-.72,7.77-1.25,8.81.4.16,3.72,1.58,4.7,5.21.74,2.72-.3,4.96-.59,5.54.6.71,2.14,2.74,2.33,5.75.27,4.22-2.35,7.06-2.81,7.55,1.41,4.7,2.81,9.4,4.22,14.09.53.56,1.13,1.4,1.4,2.52.56,2.36-.6,5.02-2.91,6.86,1.19.85,2.38,1.7,3.58,2.55v6.98l-3.58,4.64-31.56-13.41-14.29-77.36,27.79-16.19Z"
        />
        <path
          style={styles.five}
          d="m623.38,624.75l12.06,5.16,5.46,8.22,7.68,7.77.54,3.87s-8.81,2.75-11.29,2.73-22.17-14.9-22.17-14.9l7.73-12.85Z"
        />
        <path
          style={styles.nine}
          d="m469.48,513.36c17.05,2.4,40.66,3.88,68.27-.04,24.02-3.41,43.8-9.89,58.47-15.93-.81,2.01-1.97,4.35-3.65,6.79-3.33,4.83-7.23,7.86-9.97,9.63-.91.29-2.59.95-4.11,2.5-2.34,2.39-2.72,5.25-2.8,6.15-9.4,4.21-22.44,8.88-38.48,11.26-20.62,3.06-37.97,1.13-49.53-1.11l-6.62-7.52-11.57-11.74Z"
        />
        <path
          style={styles.nine}
          d="m425.95,400.61c8.99-6.18,19.38-12.58,31.2-18.68,16.76-8.65,32.51-14.7,46.2-18.99,17.81.4,38.11,2.01,60.24,5.81,32.66,5.61,60.44,14.48,82.58,23.31l-3.26,9.08,1.79,5.42c-6.29,2.08-13.71,4-22.13,5.19-17.19,2.45-31.94.96-42.53-1.07-8.15-3.17-18.75-7.15-30.78-11.26-3.52-1.2-8.23-2.77-14.62-3-2.59-.09-6.16.01-13.03,1.43-5.7,1.18-13.67,3.26-23.04,7.17-8.55,1.01-17.3,1.92-26.27,2.74-8.24.75-16.33,1.38-24.26,1.91-1.83.35-10.13,1.75-17.1-3.49-2.42-1.82-4-3.92-5-5.59Z"
        />
        <path
          style={styles.seven}
          d="m459.68,380.6c7.14,12.08,22.37,15.53,32.5,9.08,8.28-5.27,12.17-16.53,8.69-27.1-4.31,1.59-8.61,3.17-12.92,4.76,3.59,4.84,3.44,11.45-.25,15.92-3.51,4.24-9.67,5.87-14.74,3.52-5.73-2.65-6.89-8.91-6.99-9.49-2.1,1.1-4.19,2.2-6.29,3.3Z"
        />
        <path
          style={styles.seven}
          d="m592.42,374.88l-18.83-4.2c-1.11,1.94-4.44,8.34-2.76,16.12,2.39,11.07,12.97,15.62,15.36,16.64,8.87,3.82,19.95,3.07,28.37-3.13,7.54-5.56,9.98-13.3,10.67-15.91-7.08-2.17-14.16-4.34-21.24-6.5.21.23,4.62,5.17,2.8,10.96-1.69,5.36-7.47,7.45-11.48,7.5-1.16.01-5.21-.1-8.45-3.23-.39-.38-4.31-4.28-3.41-9.23.38-2.08,1.46-3.5,2.18-4.42,2.35-3.02,5.42-4.18,6.81-4.61Z"
        />
        <path
          style={styles.nine}
          d="m457.66,490.94l36.99-2.68c9.99,2.05,21.88,3.74,35.31,4.17,17.86.58,33.37-1.22,45.66-3.54-12.34,6.09-38.64,16.89-72.49,14.07-19.47-1.62-35.02-7.21-45.47-12.02Z"
        />
        <path
          style={styles.ten}
          d="m466.96,376.61l-.99.69,22.31-9.48c3.93,7.98.75,16.69-5.15,19.36-5.11,2.31-12.65.24-15.26-4.83-1.11-2.15-1.08-4.35-.91-5.74Z"
        />
        <ellipse
          style={styles.ten}
          cx="595.31"
          cy="386.18"
          rx="11.55"
          ry="10.19"
        />
        <path
          style={styles.eight}
          d="m491.9,487.79l-7.63,1.13c.68,2.82,3.24,4.69,5.88,4.48,2.19-.17,4.13-1.76,4.85-3.99l-3.1-1.62Z"
        />
        <path
          style={styles.eight}
          d="m501.73,490.43c-.16,2.63,1.88,4.81,4.23,4.87,2.02.05,3.93-1.47,4.38-3.66-2.87-.4-5.74-.81-8.61-1.21Z"
        />
        <path
          style={styles.nine}
          d="m598.97,445.9c-11.47-3.97-24.41-7.77-38.72-10.9-10.58-2.32-20.59-3.98-29.91-5.17-7.29-1.05-13.36-.74-17.66-.24-7.06.82-12.84,2.61-17.03,4.26-2.51,1.18-6.31,2.87-11.04,4.6-8,2.93-15.84,4.96-34.46,8.08-7.94,1.33-18.15,2.91-30.15,4.43,15.58,6.43,36.38,12.97,61.44,15.51,53.69,5.42,96.28-10.66,117.54-20.56Z"
        />
        <path
          style={styles.three}
          d="m486.72,374.36c0,4.37-3.55,7.92-7.93,7.92-4.15,0-7.57-3.21-7.89-7.28,2.3-1.11,4.64-2.2,7.01-3.28,2.2-1,4.38-1.97,6.55-2.9,1.4,1.43,2.26,3.38,2.26,5.54Z"
        />
        <path
          style={styles.four}
          d="m487.5,367.53c1.47,2.13,2.35,4.76,2.35,7.61,0,7.08-5.39,12.82-12.03,12.82-5.92,0-10.85-4.56-11.85-10.56"
        />
        <circle style={styles.three} cx="596.67" cy="386.59" r="5.48" />
        <circle style={styles.six} cx="592.42" cy="381.55" r="2.4" />
        <circle style={styles.six} cx="483.46" cy="373.78" r="1.83" />
        <path
          style={styles.four}
          d="m602.41,377.48c2.7,1.93,4.44,4.94,4.44,8.32,0,5.84-5.17,10.57-11.55,10.57s-11.54-4.73-11.54-10.57c0-5.46,4.53-9.95,10.33-10.51"
        />
        <path
          style={styles.four}
          d="m573.59,370.68c-2.13,3.36-3.33,7.21-3.33,11.31,0,13.02,12.18,23.58,27.2,23.58,14.17,0,25.8-9.4,27.07-21.39"
        />
        <line
          style={styles.four}
          x1="476.64"
          y1="351.42"
          x2="476.63"
          y2="351.42"
        />
        <path
          style={styles.four}
          d="m499.92,362.56c1.56,2.86,2.45,6.11,2.45,9.55,0,11.56-9.99,20.93-22.3,20.93-9.1,0-16.92-5.11-20.39-12.44"
        />
        <line
          style={styles.four}
          x1="494.05"
          y1="355.8"
          x2="494.04"
          y2="355.79"
        />
        <path
          style={styles.four}
          d="m484.46,368.82c1.02-.44,2.03-.87,3.04-1.29,4.21-1.77,8.35-3.43,12.42-4.97,3.88-1.48,7.7-2.86,11.45-4.15-6.43-.67-12.22-1.6-17.32-2.61h-.01c-5.96-1.19-10.97-2.5-14.95-3.65-.85-.25-1.67-.49-2.45-.73h0c-9.7-2.98-14.79-5.5-25.91-8.66-12.07-3.43-22.2-5.05-28.76-5.87-3.51.9-7.4,2.1-11.55,3.72-4.45,1.74-8.37,3.63-11.74,5.48-2.9,6.46-7.07,18-7.04,32.87.04,18.49,6.56,32.26,10.17,38.74,15.88-12.31,35.12-25.26,57.88-37.1,2.07-1.08,4.16-2.15,6.29-3.2,1.63-.81,3.27-1.61,4.93-2.4"
        />
        <path
          style={styles.four}
          d="m642.07,348.04c-3.25-.97-6.71-1.83-10.37-2.54-16.87-3.27-31.91-2.05-43.43.2-9.97,3.15-21.36,6.13-34.04,8.41-15.89,2.87-30.36,4.03-42.85,4.3l-11.45,4.15c31.56.77,69.35,5.07,110.45,17.18,12.75,3.76,24.69,7.93,35.8,12.33,1.44-3.57,2.99-8.97,2.15-15.26-.58-4.34-1.95-6.46-3.52-11.74-1.15-3.87-2.46-9.59-2.74-17.02Z"
        />
        <path
          style={styles.four}
          d="m642.07,348.04c3.12.62,6.97,1.67,11.15,3.52,5.49,2.43,9.66,5.4,12.56,7.83"
        />
        <path
          style={styles.four}
          d="m663.15,389.08c4.99,3.4,7.82,8.96,7.28,14.53-.49,5.08-3.6,8.36-4.66,9.39-2.41,3.51-2.84,6.3-2.77,8.22.06,1.79.55,2.63.39,4.3-.28,2.98-2.27,5.22-3.91,6.65-3.68,3.06-8.09,6.07-13.3,8.61-24.18,11.78-48.86,5.26-57.91,2.35-7.63-2.68-16.09-5.25-25.32-7.5-11.79-2.86-22.75-4.68-32.6-5.81-3.72-.57-9.5-1.14-16.43-.39-11.48,1.23-19.63,5.36-21.91,6.46-9.37,4.49-28.68,10.06-72,15.07-14.24-3.01-28.47-6.01-42.71-9.02l-10.11-7.03-4.7-12.52c-4.46-.23-8.18-2.73-9.39-6.26-.81-2.36-.27-4.52.07-5.56,13.99,3.79,31.55,7.33,51.98,8.69,19.97,1.34,37.49.25,51.65-1.56,18-4.21,32.25-8.66,41.96-11.96,19.64-6.68,25.74-10.34,37.48-9.17,9.04.9,18.87,4.4,27.39,7.43,6.87,2.45,12.47,4.87,16.43,6.69,13.95,1.8,67.06,7.52,81.35-17.81.67-1.19,1.26-2.46,1.76-3.8Z"
        />
        <path
          style={styles.four}
          d="m399.84,413.95c-3.94.07-9.03-.23-14.79-1.6-7.7-1.83-13.64-4.86-17.61-7.3"
        />
        <line
          style={styles.four}
          x1="368.35"
          y1="399.3"
          x2="370.3"
          y2="405.83"
        />
        <path
          style={styles.four}
          d="m352.66,352.75c.46,2.1.84,4.53,1.01,7.23.1,1.6.11,3.11.07,4.5-.29,1.08-.58,2.19-.85,3.33-.64,2.68-1.16,5.26-1.59,7.75-.21.64-.64,2.24-.05,4.06,1.07,3.26,4.45,4.25,4.76,4.34,1.48,1.01,3.35,2.55,5.09,4.79,3.22,4.17,4.17,8.48,4.5,10.76"
        />
        <path
          style={styles.four}
          d="m490.75,326.69c-6.43,1.34-11.74,1.97-15.38,2.37-8.22.92-15.83,1.23-22.7,1.17-3.57-.14-7.89-.01-12.72.78-7.61,1.26-13.7,3.71-18,5.87-7.76,3.07-15.53,6.13-23.29,9.2-4.46,1.1-12.47,3.72-20.15,10.37-5.03,4.36-8.23,8.98-10.17,12.36-1.08,4.11-2.11,9.8-1.96,16.59.12,5.55,1,10.26,1.96,13.89-.91.07-1.83.13-2.74.2-.65.66-2.23,2.45-2.74,5.28-.61,3.41.76,6.04,1.17,6.78-4.46-.24-8.18-.65-10.89-1-10.29-1.33-12.42-2.72-13.76-4.22-.94-1.05-1.58-2.61-2.87-5.74-1.44-3.5-2.31-6.5-2.82-8.54-2.63-2.88-3.1-5.16-3.05-6.72.09-2.7,1.79-3.93,1.5-7.17-.17-1.93-.84-2.21-.98-4.04-.2-2.55.89-4.72,1.83-6.13,3.27-4.93,6.73-7.63,9.33-9.2,4.75-2.86,10.29-4.17,11.35-7.83.2-.71.51-2.42,1.96-3.72.73-.65,1.51-.99,2.07-1.17,2.87-4.72,4.25-8,4.98-10.17.24-.71.79-2.43,2.15-4.3,1.1-1.5,2.29-2.51,3.13-3.13.38-5.21,1.81-7.11,3.13-7.83.5-.27,1.42-.62,1.57-1.37.21-1.09-1.47-1.75-1.57-3.13-.04-.63.24-1.55,1.76-2.74,2.05-1.12,4.1-2.24,6.15-3.36.84-.46,1.68-.92,2.52-1.38,4.5-.68,11.44-1.42,19.89-.94,7.82.44,12.79,1.7,21.52,3.33,6.43,1.2,15.84,2.78,27.59,4.11l47.15,13.5c8.02,2.06,17.42,3.89,27.98,4.89,7.42.71,14.31.9,20.54.78,2.79-.1,7.57-.32,13.47-.91,18.11-1.82,26.92-5.14,47.38-8.48,4.14-.68,10.65-1.66,18.78-2.54,6.33-.61,17.47-.67,28.96,5.09,1.57.79,3.03,1.63,4.38,2.49,1.45.93,2.7,1.83,3.73,2.62-2.59-9.54-4.99-17.62-6.94-23.89-2.24-7.23-3.38-10.5-5.87-14.28-4.03-6.14-9.15-9.88-12.76-12.05-3.24.17-6.62.46-10.13.89-9.37,1.15-17.76,3.08-25.04,5.28-5.65,1.53-11.93,2.91-18.78,3.91-13.79,2.02-26.09,1.98-36.2,1.17,0,0-28.83,1.37-57.33-6.07-6.02-1.57-2.52-1.05-30.17-9.98-14.96-4.83-21.75-6.84-30.91-7.58-9.43-.76-17.27.17-22.48,1.09-1.4.28-3.35.85-5.43,2.04-3.08,1.76-4.94,4.04-5.9,5.42-1.29.85-3.68,2.68-5.48,5.87-1.62,2.87-2.03,5.54-2.15,7.04-1.48,1.28-3.37,3.2-5.09,5.87-1.98,3.08-2.99,5.98-3.52,8.02-1.37,2.22-2.74,4.43-4.11,6.65"
        />
        <path
          style={styles.three}
          d="m439.45,271.66l.16-.93c-1.07-6.09-2-16.05.74-27.76,1.83-7.8,4.73-13.99,7.24-18.39-3,1.35-8.18,4.21-12.52,9.78-7.61,9.78-7.07,20.78-6.83,23.71-4.08-7-11.82-18.16-25.06-27.82-9.96-7.26-19.57-11.16-26.41-13.3,6.22,3.3,16.25,9.63,25.43,20.74,9.27,11.22,13.6,22.39,15.65,29.15-7.41-5.31-19.85-12.74-36.78-16.43-9.95-2.17-18.77-2.47-25.63-2.15,6.56.43,16.7,1.94,27.78,7.24,7.6,3.63,13.36,7.96,17.41,11.54-6.25-2.25-17.67-5.45-32.09-4.3-14,1.11-24.53,5.81-30.33,8.92,7.18-2.34,18.17-4.97,31.7-4.62,11.13.29,20.31,2.5,26.8,4.62-2.46-.44-11.79-1.82-21.13,3.79-11.55,6.94-13.94,19.05-14.28,20.93,1.2-2.8,6.62-14.62,19.96-20.15,7.83-3.25,14.93-2.9,18.58-2.44,4.58-1.67,11.21-3.53,19.37-4,8.43-.49,15.42.68,20.24,1.87Z"
        />
        <path
          style={styles.three}
          d="m638.54,321.63c4.5-4.87,14.7-14.49,30.72-18.59,7.51-1.92,14.17-2.08,19.17-1.76-5.44,1.15-12.55,3.29-20.15,7.43-4.54,2.48-8.31,5.15-11.35,7.63,4.25-1.64,13.4-4.51,24.85-2.74,8.61,1.33,14.97,4.73,18.59,7.04-3.61-1.33-11.18-3.55-20.54-1.96-6.32,1.07-11.1,3.48-14.04,5.28,2.87,1.62,8.99,5.62,13.06,13.3,6.47,12.21,2.74,24.26,1.96,26.61-.85-4.55-3.8-17.08-14.87-27.98-10.71-10.55-22.8-13.43-27.39-14.28Z"
        />
        <path
          style={styles.three}
          d="m577.5,263.33c.71-2.23,1.52-5.7,1.37-9.98-.16-4.67-1.4-8.33-2.35-10.57,1.12,1.92,2.46,4.69,3.25,8.23,1.18,5.27.62,9.71.07,12.31h-2.35Z"
        />
        <path
          style={styles.three}
          d="m585.13,261.96c.8-2.05,5.2-12.81,17.22-18,10.34-4.47,19.67-1.94,22.18-1.17-3.8-.41-11.85-.75-20.81,3.13-10.91,4.73-16.59,12.85-18.59,16.04Z"
        />
        <path
          style={styles.four}
          d="m438.9,251.67c.65-.95,1.91-2.52,3.99-3.8,1.86-1.14,3.6-1.58,4.7-1.76,6.06.35,10.55,1.75,13.5,2.93,8.6,3.45,10.98,7.86,23.48,14.28,2.27,1.17,1.27.55,7.43,3.52,11.47,5.53,13.32,6.9,17.8,8.61,3.41,1.3,8.51,2.86,15.26,3.52,3.12.45,7.54,1.47,12.33,3.91,6.79,3.47,11.05,8.13,13.29,11"
        />
        <path
          style={styles.three}
          d="m439.61,270.73c4.07-2.16,10.36-4.76,18.35-5.45,14.32-1.23,25.52,4.43,25.51,4.47,0,0,0,0,0,0-1.01-.04-16-3.04-23.55,1.59t-5.57,4.55l-14.74-5.16Z"
        />
        <path
          style={styles.four}
          d="m616.47,266.74c-7.07-2.22-16.42-4.23-27.43-4.2-4.17.01-8.04.32-11.54.78-2.83.29-6.87.66-11.74.98-11.08.71-21.87,1.41-34.43.39-15.01-1.22-19.9-3.85-30.33-1.17-3.93,1.01-7.02,2.36-9,3.33"
        />
        <path
          style={styles.four}
          d="m595.31,290.26c13.28-20.99,35.2-32.78,56.15-29.87,13.32,1.85,22.7,9.15,27.39,13.5-1.71.1-4.08.45-6.65,1.57-5.44,2.37-8.26,6.63-9.3,8.41-2.05-.26-4.1-.52-6.15-.78l4.11,4.11-2.93,4.7c-1.59-1.41-3.54-2.91-5.87-4.3-5.59-3.35-10.98-4.65-14.72-5.2"
        />
        <path
          style={styles.four}
          d="m455.49,247.26c9.47-3.62,17.67-6.08,24.03-7.76,9.73-2.57,14.8-3.23,19.66-3.37,4.4-.13,8.08.19,10.7.52,9.32-.61,17.13-.16,22.93.47,7.01.76,11.94,1.88,13.07,2.14,6.39,1.47,11.68,3.34,15.65,4.96,7.8.97,18.8,3.28,30.75,9.15,7.6,3.73,13.68,7.97,18.31,11.74"
        />
        <path
          style={styles.four}
          d="m420,450.96c17.9,7.39,51.22,18.4,93.91,16.43,38.53-1.77,68.12-13.34,85.06-21.49"
        />
        <path
          style={styles.four}
          d="m416.84,450.29c.21,6.1.8,12.9,1.98,20.23,1.73,10.7,4.33,20.02,7.04,27.78.49,2.69,1.06,7.93-.78,14.09-2.11,7.02-6.22,11.43-8.24,13.33v-11.37l-6.63,11.37-10.38-3.18v-7.8l-11.14,5.48,5.87-16.6-12.52,10.73-3.52-5.87c2.81-2.8,8.31-9.05,10.96-18.78,3.41-12.55-.09-22.93-1.48-26.5-1.38-5.04-3.28-8.82-2.83-11.85.48-3.19,4.73-6.09,5.74-6.77"
        />
        <path style={styles.four} d="m646.17,392.07s-5.68,10.41-1.47,14.5" />
        <path
          style={styles.four}
          d="m423.69,491.6c20.3.25,43.12-.63,67.92-3.47,19.61-2.25,37.67-5.42,54-9,13.13-2.62,30.14-7.62,48.13-17.61,8.14-4.52,15.16-9.33,21.12-13.96"
        />
        <path
          style={styles.four}
          d="m455.49,491.05c11.62,5.09,32.25,12.35,58.82,12.34,29.78,0,52.44-9.14,64.19-14.87"
        />
        <path
          style={styles.four}
          d="m491.61,488.13c18.5,4.2,52.04,9.11,91.57-.78,23.79-5.96,42.41-15.44,55.37-23.48,1.21-.34,8.36-2.48,11.41-9.39,1.19-2.7,1.36-5.22,1.3-6.92-.09-1.46-.07-4.15,1.17-7.17,1.48-3.61,3.9-5.74,5.08-6.66"
        />
        <path
          style={styles.four}
          d="m425.41,496.98c7.81,4.41,19.3,10,33.94,14.04,7.96,2.19,26.33,6.54,56.76,4.52,39.92-2.65,74.43-14.52,98.75-25.32"
        />
        <path
          style={styles.four}
          d="m614.86,476.41c.45,8.02.13,18.58-2.73,30.5-1.47,6.11-3.35,11.49-5.28,16.09v11.83l5.54-2.87,2.47-9.42,2.49,6.81c1.69-2.28,4.37-5.09,8.35-6.52,4.67-1.68,7.85-.27,9.91-2.35,1.37-1.38,2.28-4.31,0-11.22-.49-.9-1.06-2.23-1.3-3.91-.5-3.47.73-6.2,1.3-7.3,1.02-1.61,2.35-4.08,3.13-7.3,1.06-4.37.52-7.74.26-10.7-.34-3.87-.42-9.46.88-16.61"
        />
        <path
          style={styles.four}
          d="m468.6,513.19c3.73,5.27,7.31,8.32,9.97,10.16,1.24.85,2.95,1.91,4.96,3.91,2.45,2.45,3.97,4.94,4.88,6.69"
        />
        <path
          style={styles.four}
          d="m575.62,523.57s.77-8.05,5.47-9.09,16.31-17.09,16.31-17.09"
        />
        <path
          style={styles.four}
          d="m472.04,490.22c-.52.71-.56,1.24-.52,1.57.55,5.01,24.22,8.74,45.33,4.17,4.57-.99,8.13-2.17,9.97-2.82"
        />
        <path d="m514.57,433.73c-4.77-.92-12.99-1.81-16.44,2.13-1.64,1.93-2.47,4.94-1.67,7.38.94,2.48,3.9,3.46,6.37,3.8,3.83.45,7.76-.48,11.3-2.04,0,0,.88,1.8.88,1.8-5.68,2.9-13.24,4.47-18.79.31-3.97-3.12-2.98-9.41.09-12.83,4.25-4.58,13.54-4.21,18.25-.54h0Z" />
        <path style={styles.four} d="m549,444.04s14.74,7.05,18.33-7.3" />
        <path d="m506.74,443c4.01-.96,8.43.79,11.08,3.9.8.78,2.06,2.91,2.25,3.33,1.13,1.68,2.76,3.15,4.51,4.14,5.44,3.17,12.61.63,15.76-4.61,0,0-.12.26-.12.26.62-1.6,1.51-3.12,2.68-4.42,1.15-1.29,2.64-2.38,4.24-3,3.01-1.16,6.23-1.77,9.44-1.35-5.08,2.3-11.56,3.93-13.38,9.85-3.61,7.16-13.28,10.34-20.19,5.96-2.21-1.35-3.97-3.18-5.32-5.38-.54-1.09-1.07-2.19-1.85-3.12-2.27-2.86-5.57-4.58-9.09-5.57h0Z" />
        <path
          style={styles.four}
          d="m454.18,551.29c6.88,4.32,18.73,10.44,34.56,12.49,8.82,1.14,15.84-1.66,21.78-2.48-7.76-7.64-14.28-8.63-19.43-10.3-6.54-2.12-10.74-1.8-17.74-4.96-6.48-2.92-11.05-6.87-13.85-9.7,5.52-1.07,12.99-1.71,21.42.05,2.08.43,4.02.97,5.81,1.57,9.7,1.59,19.4,3.17,29.1,4.76"
        />
        <path
          style={styles.four}
          d="m437.26,578.69c14.54,5.2,29.07,10.41,43.61,15.61,8.24,2.13,19.93,4.2,33.96,3.65,16.34-.64,29.43-4.58,38.01-7.92"
        />
        <path
          style={styles.four}
          d="m363.78,611.88c.59-1.46,3.81-7.1,6.78-9.49,4.03-3.24,8.39-3.6,9.91-3.65.87-1.4,2.57-3.69,5.48-5.48,4.91-3.03,9.89-2.56,11.48-2.35l22.57,3.39,57.26,17.48c5.61,1.11,12.49,1.97,20.34,1.85,13.51-.21,24.54-3.24,32.09-6.02,10.27-2.24,27.56-4.36,46.96,1.62,5.91,1.83,11.07,4.11,15.47,6.46,9.78,2.92,19.57,5.85,29.35,8.77-33.56,12.61-79.71,24.89-133.78,24.62-65.01-.32-97.06-18.55-105.65-23.74-7.56-4.57-13.46-9.01-17.48-12.26,15.3,2.74,34.09,4.88,55.57,4.7,22.21-.19,41.53-2.83,57.13-6"
        />
        <path
          style={styles.four}
          d="m416.48,593.78c2.57-.79,5.45-1.52,8.61-2.08,6.49-1.15,12.29-1.24,17-.94,3.28.23,7.47.2,12.22-.62,3.3-.57,6.18-1.4,8.58-2.27"
        />
        <path
          style={styles.four}
          d="m362.66,611.68c-10.49,3.36-20.75,12.93-22.1,23.58-1.88,14.82,9.44,24.45,10.17,25.04,4.83,1.43,11.11,2.79,18.52,3.13,10.72.49,19.57-1.36,25.57-3.13,4.71-.17,11.88-.39,20.61-.52,22.34-.34,27.73.33,36.52-.52,8.65-.84,20.99-3.2,35.74-10.17"
        />
        <path
          style={styles.four}
          d="m446.74,659.62l67.3,5.91c10.19.75,23.59.67,38.87-2.35,9.93-1.96,18.51-4.74,25.58-7.56,2.31-1.34,5.18-2.74,8.59-3.92,5.15-1.77,9.78-2.41,13.3-2.6,1.31.03,11.02.08,17.74-7.83,5.38-6.34,5.3-13.62,5.23-15.39"
        />
        <path
          style={styles.four}
          d="m348.01,621.23l-38.75,16.64c3.8-2.42,5.5-7.07,4.17-11.23-1.22-3.81-4.76-6.51-8.87-6.73.7-1.15,1.39-2.3,2.09-3.44h-10.43c5.09-3.18,13.47-7.44,24.52-8.87,4.9-.64,9.29-.58,12.96-.26,9.34,1.31,18.67,2.62,28.01,3.93"
        />
        <path
          style={styles.four}
          d="m343.18,649.83c-1.04.74-1.68,1.02-3.92,1.61-5.84,1.53-10.18-1.28-16.17-2.61-3.47-.77-8.63-1.28-15.44.25-4.05.44-5.85,1.87-6.73,3.14-1.3,1.88-.76,3.6-2.09,6.52-1.03,2.26-2.52,3.81-3.65,4.78-1.06.98-2.86,2.92-3.91,5.92-1.72,4.9-.23,9.19.26,10.43.56.91,1.14,2.04,1.57,3.39,1.33,4.19.47,7.91-.09,9.69,34.96-3.11,78.49-2.82,126.87,7.53,14.31,3.06,27.7,6.71,40.13,10.7,20.02,6.24,47.06,12.02,79.09,11.48,12.87-.22,24.67-1.43,35.22-3.13,19.37-6.51,45.61-12.04,75.91-8.87,5.79.61,11.29,1.48,16.49,2.55.49-.9,2.28-4.42,1.25-8.81-.74-3.15-2.55-5.15-3.39-5.97.53-2.06.96-4.75.78-7.85-.46-8.24-4.85-13.98-6.78-16.22-1.59.3-3.24.66-4.96,1.09-3.05.77-5.83,1.67-8.35,2.61-2.6,2.01-6.15,4.22-10.7,5.74-13.6,4.53-25.81-.62-29.48-2.35-1.75-.95-4.65-2.42-8.35-3.91-13.8-5.55-23.93-5.26-39.65-7.3-5.63-.73-14.11-2.05-24.5-4.51"
        />
        <path
          style={styles.four}
          d="m293,692.95c-.14.8-.29,1.6-.43,2.4-1.07,1.09-2.54,2.87-3.66,5.39-1.6,3.61-1.62,6.88-1.51,8.61-.43.53-1.98,2.55-1.89,5.48.12,3.76,2.86,5.95,3.25,6.26.29,1.09.65,2.96.27,5.24-.53,3.22-2.21,5.36-3,6.24-.27,1.54-.51,3.93,0,6.78.58,3.22,1.85,5.61,2.73,6.99,18.32-9.97,44.05-21.51,76.32-28.9,38.67-8.86,71.99-8.4,94.91-6.26"
        />
        <path
          style={styles.four}
          d="m666.71,713.2c.6.26,2.89,1.33,4.12,3.97,1.53,3.27.21,6.33,0,6.78.47.55,2.5,3.05,2.35,6.78-.15,3.68-2.32,6-2.83,6.52-10.76-4.18-25.37-8.59-43.08-10.43-24.36-2.53-44.6.83-57.91,4.17-28.1,9.64-50.57,10.68-65.74,9.91-33.41-1.69-83.94-14.82-97.04-18.23-4.84-1.26-12.22-3.27-22.43-3.95,0,0-4.8-.32-25.13.16h0"
        />
        <path
          style={styles.four}
          d="m417.46,659.75c12.71,4.37,30.23,9.89,51.46,15.03,12.95,3.14,29.87,7.23,47.93,9.39,19.19,2.29,48.43,3.44,85.21-4.29"
        />
        <path style={styles.four} d="m507.25,664.93s29.16,12.09,39.14,20.46" />
        <path
          style={styles.four}
          d="m288.77,746.34c32.49-5.71,77.34-9.09,128.49,1.23,16.66,3.36,31.82,7.74,45.39,12.52,19.09,5.26,44.43,10.1,74.35,10.17,18.82.05,35.57-1.81,49.7-4.3,14.46-3.57,32.77-6.61,54-6.65,11.71-.02,22.38.87,31.8,2.2,1.83-2.44,3.78-5.95,3.02-9.63-.33-1.58-.99-2.4-1.96-4.3-1.14-2.26-2.51-5.64-3.22-10.31"
        />
        <path d="m323.89,780.73c19.17-8.97,40.16-13.43,61.14-15.52,22.82-6.79,50.25-9.6,75.05-7.05,0,0-.16,1.99-.16,1.99-25.05-1.49-50.34,1.14-74.46,8.08-20.94,1.79-41.38,6.8-61.57,12.49h0Z" />
        <path
          style={styles.four}
          d="m381,767.69c12.64,3.85,26.31,7.52,40.96,10.79,17.35,3.86,33.73,6.63,48.91,8.61"
        />
        <path d="m350.96,800.03c43.16-1.35,88.27-6.14,129.36-16.11,6.47-2.92,13.28-5.08,20.26-6.42,8.72-1.7,17.69-2.03,26.52-1.08,11.84,1.21,25.76,5.87,34.91,11.33,12.26,3.38,24.94,5.28,37.66,5.52,21.14.29,42.68-2.89,62.03-11.68,2.63-2.73,8.97-6.94,12.56-10.33,0,0-.28.45-.28.45.59-1.57,1.24-2.66,1.5-4.18.22-1.28-.24-2.7-.72-3.92,0,0,.45.5.45.5l-3.19-1.72s.95-1.76.95-1.76c0,0,3.19,1.72,3.19,1.72l.31.17c.69,1.68,1.27,3.45.98,5.32-.17,1.53-1.09,3.13-1.56,4.43,0,0-.09.28-.09.28-2.15,1.98-4.42,3.72-6.66,5.48-2.29,1.73-4.48,3.6-6.62,5.38-23.18,10.82-50.49,14.1-75.83,11.69-8.64-.88-17.2-2.49-25.57-4.79-10.55-5.96-22.3-9.78-34.34-11.12-8.56-.89-17.24-.64-25.71.99-6.77,1.28-13.39,3.36-19.68,6.17-42.64,10.04-86.61,15.59-130.45,13.69h0Z" />
        <path d="m408.5,827.08c15.94-2.31,31.63-6.42,46.1-13.57,7.45-3.22,15.41-5.35,23.51-6.13,8.06-.65,16.17-.32,24.26-.3,26.22.63,52.47,3.43,78.11,9.03,5.64,1.35,12.04,2.9,17.56,4.64,0,0-11.88-2.28-11.88-2.28-27.6-5.38-55.71-9.15-83.83-9.68-8.02-.08-16.08-.49-24.08.09-7.95.7-15.78,2.72-23.15,5.83-14.59,7.03-30.52,10.9-46.61,12.39h0Z" />
        <path d="m461.84,839c6.51-1.31,12.96-2.85,19.27-4.82,6.15-2,12.7-3.71,17.96-7.56,9-7.31,19.56-13.38,30.86-16.42,0,0,4.87-1.29,4.87-1.29,0,0,.46,1.95.46,1.95-9.51,2.51-18.66,6.32-26.92,11.7-4.29,2.58-7.93,6.16-12.5,8.34-10.82,4.42-22.42,6.88-34,8.1h0Z" />
        <path
          style={styles.four}
          d="m551.76,784.22c21.37-2.27,36.49-4.41,45.43-6.79,3.37-.89,12.46-3.57,22.85-.61.61.17,1.1.33,1.43.44,9.37,3.14,20.68,5.6,33.51,5.71,2.15.02,4.25-.03,6.29-.13"
        />
        <path
          style={styles.four}
          d="m622.3,624.49c5.96,1.35,9.91,3.78,12.26,5.55,5.23,3.96,3.69,5.59,10.84,12.02,1.69,1.52,3.08,2.61,3.17,4.33.14,2.92-3.6,4.86-6.11,6.87-11.55,9.28-11.12,31.42-11.12,31.42h0"
        />
        <path
          style={styles.four}
          d="m648.57,646.39c4.18,1.87,6.1,3.08,11.3,6.87,4.01,2.92,7.32,5.9,9.98,8.61,2.13,2.64,5.79,6.53,11.35,9.78,5.04,2.95,9.73,4.26,12.91,4.89-2.03.24-4.66,1-7.73,2.06-3.96,1.37-8.12,3.4-8.9,6.08l-5.48-4.03,2.15,5.23-4.35,4.36c-2.57-3.6-5.13-7.19-7.7-10.79"
        />
        <path d="m377.47,309.94c-6.97-2.16-14.81-.9-21.68,1.12-1.63.48-3.23,1.1-4.64,2.06-1.31.88-2.4,2.04-3.5,3.16-2.56,2.58-5.51,4.45-9.07,5.29-.31.07-1.44.43-1.31.92s1.32.21,1.59.14c3.04-.72,5.95-2,8.47-3.87s4.27-4.69,7.06-6.02c1.46-.7,3.09-1.02,4.65-1.38,1.74-.4,3.5-.73,5.28-.96,3.62-.46,7.28-.35,10.78.73.63.2,1.47-.04,2.03-.34.29-.15.97-.67.33-.87h0Z" />
        <path d="m368.95,328.53c5.96-4.36,12.82-7.31,20.1-8.54.28-.05,1.71-.35,1.71-.75,0-.45-.85-.26-1.14-.21-8.4,1.42-16.4,4.74-23.28,9.77-.54.39.48.41.66.39.66-.06,1.4-.28,1.94-.67h0Z" />
        <path d="m450.46,330.34c16.6,2.79,30.68,13.22,47.16,16.47.3.06.76.02,1.02-.17.09-.07.52-.36.16-.43-16.56-3.26-30.72-13.71-47.41-16.52-.35-.06-1.67.52-.92.65h0Z" />
        <path d="m505.04,348.22c1.14,2.48,4.13,2.94,6.58,3.07,3.87.21,7.76.25,11.63.16,7.77-.18,15.53-1.07,22.98-3.33.83-.25.73-.71-.12-.46-6.96,2.11-14.2,2.98-21.46,3.18-3.61.1-7.23.04-10.84-.09-2.59-.1-6.07-.19-7.33-2.92-.16-.36-1.57.13-1.45.39h0Z" />
        <path d="m523.25,347.79c8.52.96,17.14-.59,24.82-4.4.86-.43-.45-.54-.87-.33-7.12,3.54-15.14,4.91-23.04,4.02-.39-.04-1.68.62-.91.7h0Z" />
        <path d="m602.93,337.54c17.26-1.13,34.68,2.02,50.47,9.07.45.2,1.77-.45,1.15-.72-16.11-7.19-33.83-10.34-51.43-9.19-.73.05-1.19.91-.19.84h0Z" />
        <path d="m665.09,337.08c-9.02-4.09-18.21-8.17-28.17-9.24-.62-.07-1.51.81-.54.92,9.73,1.04,18.74,5.08,27.57,9.08.48.22,1.79-.46,1.14-.76h0Z" />
        <path d="m640.65,312.96c-14.69.83-29.22,3.16-43.73,5.5-.56.09-1.31.65-.28.48,14.39-2.32,28.81-4.62,43.38-5.44.36-.02,1.58-.59.64-.54h0Z" />
        <path d="m509.24,325.65c10.53-3.11,20.54,4.27,30.98,4.52,5.33.13,10.55-1.2,15.71-2.4,5.52-1.29,11.06-2.5,16.61-3.63,6.2-1.27,12.42-2.44,18.65-3.52.93-.16.62-1.22-.29-1.06-11.01,1.91-21.97,4.11-32.86,6.6-5.36,1.23-10.76,2.76-16.29,2.89s-10.84-1.77-16.14-3.33-11.12-2.76-16.66-1.12c-.89.26-.61,1.33.29,1.06h0Z" />
        <path
          style={styles.eleven}
          d="m507.25,307.67c1.34.42,2.26.67,3.92.85.89.1,3.59.35,6.06.51,2.47.17,4.72.25,4.72.25,0,0,2.25.1,4.73.13,2.48.04,5.18,0,6.08-.02,1.66-.04,2.6-.22,3.97-.53-1.39-.2-2.34-.31-3.99-.28-.9.02-3.59.06-6.05.02-2.47-.03-4.71-.12-4.71-.12,0,0-2.24-.09-4.7-.26-2.46-.16-5.14-.42-6.03-.51-1.65-.16-2.59-.13-4-.05Z"
        />
        <path
          style={styles.eleven}
          d="m538.58,299.56c2.1.51,3.54.8,6.09.92,1.38.06,5.53.2,9.34.23,3.81.04,7.27-.01,7.27-.01,0,0,3.46-.04,7.27-.18,3.81-.13,7.95-.38,9.33-.47,2.55-.18,3.98-.51,6.07-1.08-2.16-.22-3.61-.32-6.15-.17-1.37.09-5.51.33-9.3.46-3.79.14-7.24.18-7.24.18,0,0-3.45.05-7.24,0-3.79-.03-7.93-.17-9.31-.22-2.54-.09-3.99.05-6.14.33Z"
        />
        <path
          style={styles.eleven}
          d="m591.79,297.56c1.26.14,2.13.16,3.61-.07.8-.12,3.19-.6,5.34-1.16,2.15-.56,4.1-1.06,4.1-1.06,0,0,7.81-2.02,9.37-2.43,1.44-.38,2.22-.71,3.37-1.25-1.26.09-2.11.18-3.55.55-1.56.4-9.37,2.43-9.37,2.43,0,0-1.95.5-4.1,1.05-2.14.55-4.5,1.03-5.29,1.15-1.45.24-2.26.46-3.48.79Z"
        />
        <path
          style={styles.eleven}
          d="m570.36,276.63c.73.03,1.21.03,2.06-.08.46-.06,1.83-.22,3.1-.32,1.26-.11,2.42-.17,2.42-.17,0,0,1.15-.06,2.42-.08,1.27-.03,2.65,0,3.11,0,.85.02,1.33-.04,2.05-.14-.71-.16-1.19-.26-2.04-.28-.46-.01-1.86-.03-3.13,0-1.28.02-2.43.09-2.43.09,0,0-1.16.06-2.43.17-1.27.1-2.65.27-3.11.33-.85.11-1.32.25-2,.49Z"
        />
        <path
          style={styles.eleven}
          d="m604.3,270.07c-1.51-.31-2.54-.48-4.35-.48-.98,0-3.94.04-6.65.18-2.71.13-5.16.35-5.16.35,0,0-2.46.21-5.15.54-2.69.32-5.61.79-6.58.96-1.79.31-2.78.66-4.21,1.22,1.54-.01,2.57-.06,4.35-.35.96-.16,3.87-.63,6.54-.95,2.67-.33,5.11-.54,5.11-.54,0,0,2.44-.21,5.13-.35,2.69-.14,5.63-.18,6.61-.19,1.81-.01,2.83-.15,4.35-.4Z"
        />
        <path
          style={styles.eleven}
          d="m527.88,268.83c1.04.28,1.75.44,3.01.55.68.06,2.73.25,4.61.46,1.88.22,3.58.43,3.58.43,0,0,1.7.24,3.57.5,1.87.27,3.9.61,4.58.73,1.25.21,1.97.23,3.05.23-1.01-.39-1.7-.62-2.95-.84-.68-.12-2.72-.46-4.59-.73-1.88-.26-3.58-.49-3.58-.49,0,0-1.71-.21-3.59-.43-1.88-.21-3.94-.4-4.63-.46-1.27-.11-1.99-.05-3.06.06Z"
        />
        <path
          style={styles.eleven}
          d="m550.34,255.81c1.28.12,2.13.16,3.63.15.81-.02,3.25.01,5.47.3,2.22.28,4.2.76,4.2.76,0,0,1.99.45,4.09,1.22,2.11.73,4.31,1.81,5,2.21.86.47,1.58.86,3.18,1.72-.82-.99-1.49-1.61-2.82-2.37-.72-.42-2.96-1.52-5.11-2.26-2.14-.78-4.17-1.24-4.17-1.24,0,0-2.01-.5-4.27-.78-2.26-.3-4.75-.33-5.57-.3-1.53.03-2.39.24-3.62.59Z"
        />
        <path
          style={styles.eleven}
          d="m512.5,246.71c-.71-.28-1.2-.46-2.09-.61-.48-.08-1.94-.3-3.28-.42-1.34-.13-2.57-.19-2.57-.19,0,0-1.22-.06-2.57-.06-1.35,0-2.82.08-3.3.11-.9.06-1.41.19-2.14.41.76.06,1.27.09,2.17.03.48-.03,1.94-.11,3.28-.11,1.34,0,2.55.05,2.55.05,0,0,1.21.05,2.54.19,1.33.12,2.77.34,3.25.42.88.14,1.4.16,2.16.17Z"
        />
        <path
          style={styles.eleven}
          d="m508.03,251.14c-2.33-.24-3.89-.35-6.62-.25-1.48.06-5.94.16-10.02-.01-2.04-.07-3.99-.21-5.42-.32-1.43-.12-2.36-.21-2.36-.21,0,0-.92-.1-2.35-.26-1.43-.17-3.36-.42-5.38-.76-4.04-.63-8.38-1.62-9.82-1.97-2.66-.64-4.21-.84-6.54-1.06,2.09,1.05,3.54,1.7,6.23,2.37,1.45.36,5.84,1.36,9.92,2,2.04.34,3.99.58,5.43.76,1.44.16,2.38.26,2.38.26,0,0,.93.08,2.38.21,1.45.11,3.41.26,5.48.33,4.12.18,8.63.07,10.12,0,2.76-.12,4.32-.47,6.57-1.09Z"
        />
        <path
          style={styles.eleven}
          d="m482.9,261.6c1.23-.59,2.07-.83,3.56-1.27.41-.12,1.23-.32,2.26-.49,1.03-.17,2.27-.32,3.46-.46,2.37-.29,4.53-.51,4.53-.51,0,0,2.16-.22,4.54-.41,2.38-.2,4.98-.35,5.84-.4,1.6-.09,2.5-.27,3.83-.57-1.35-.2-2.26-.29-3.87-.21-.87.04-3.48.2-5.86.4-2.39.19-4.55.42-4.55.42,0,0-2.17.21-4.54.5-1.19.15-2.43.29-3.49.47-1.06.17-1.92.39-2.35.52-1.57.48-2.42,1.04-3.35,2.03Z"
        />
        <path
          style={styles.eleven}
          d="m495.26,266.9c1.16.12,1.94.17,3.31.12.74-.03,2.96-.09,5-.02,2.04.06,3.88.23,3.88.23,0,0,1.85.16,3.86.47,2.02.29,4.19.75,4.91.92,1.33.3,2.11.39,3.27.48-1.05-.51-1.78-.82-3.12-1.13-.73-.17-2.93-.64-4.96-.93-2.04-.31-3.9-.47-3.9-.47,0,0-1.86-.18-3.92-.24-2.06-.08-4.3-.01-5.05.03-1.38.07-2.15.24-3.28.55Z"
        />
        <path
          style={styles.eleven}
          d="m485.88,268.41c1.43.43,2.38.71,4.07,1.1.45.11,1.39.33,2.45.68.52.18,1.03.43,1.45.83.44.4.82.94,1.26,1.45.85,1.03,1.77,1.92,2.46,2.55.71.62,1.17,1,1.17,1,0,0,1.88,1.53,4.17,2.86,2.28,1.35,4.89,2.56,5.77,2.94,1.61.72,2.59.97,4.05,1.28-1.25-.82-2.11-1.34-3.7-2.07-.86-.39-3.45-1.58-5.68-2.9-2.25-1.3-4.07-2.79-4.07-2.79,0,0-.45-.37-1.14-.97-.67-.62-1.56-1.47-2.37-2.46-.42-.48-.81-1.05-1.34-1.53-.52-.5-1.18-.82-1.75-1.01-1.14-.38-2.07-.59-2.54-.7-1.74-.38-2.77-.38-4.26-.26Z"
        />
        <path
          style={styles.eleven}
          d="m517.17,282.04c.36.06.6.09,1.02.09.23,0,.92,0,1.55.04.63.04,1.21.07,1.21.07,0,0,.57.05,1.21.11.63.06,1.32.16,1.54.19.42.06.66.06,1.03.06-.34-.12-.57-.2-1-.26-.23-.03-.92-.13-1.55-.19-.63-.06-1.21-.11-1.21-.11,0,0-.58-.03-1.21-.07-.64-.03-1.33-.04-1.56-.03-.43,0-.67.04-1.02.11Z"
        />
        <path
          style={styles.eleven}
          d="m442.19,249.31c.73.93,1.26,1.54,2.35,2.43.59.48,2.38,1.89,4.11,3.07,1.71,1.2,3.34,2.19,3.34,2.19,0,0,1.62,1,3.46,2,1.83,1,3.9,1.97,4.59,2.28,1.28.57,2.06.77,3.22,1-1-.64-1.68-1.05-2.94-1.62-.68-.31-2.73-1.27-4.55-2.26-1.82-.98-3.43-1.97-3.43-1.97,0,0-1.61-.98-3.3-2.17-1.71-1.18-3.48-2.57-4.07-3.04-1.08-.87-1.75-1.29-2.77-1.9Z"
        />
        <path
          style={styles.eleven}
          d="m404.24,271.61c1.74,1.3,2.95,2.14,5.29,3.22,1.26.57,5.12,2.3,8.9,3.2,1.89.46,3.72.73,5.07.88,1.35.16,2.22.22,2.22.22,0,0,3.46.26,7.26.55,1.89.17,3.84.43,5.46.77,1.63.34,2.92.74,3.56.96,2.35.83,3.67,1.29,5.66,2.19-1.52-1.55-2.79-2.49-5.25-3.37-.67-.23-2.03-.66-3.71-1.01-1.68-.35-3.69-.62-5.6-.79-3.8-.29-7.26-.55-7.26-.55,0,0-.86-.07-2.18-.22-1.32-.15-3.1-.41-4.92-.86-3.65-.87-7.44-2.56-8.69-3.12-2.32-1.04-3.7-1.5-5.8-2.08Z"
        />
        <path
          style={styles.eleven}
          d="m461.09,284.35c1.06.22,1.78.34,3.05.36,1.38.03,8.29.14,8.29.14,0,0,6.91.12,8.29.14,1.27.02,2-.08,3.07-.26-1.06-.22-1.78-.34-3.06-.36-1.38-.03-8.29-.14-8.29-.14,0,0-6.91-.12-8.29-.14-1.27-.02-2,.08-3.07.26Z"
        />
        <path
          style={styles.eleven}
          d="m540.39,322.45c-.77-.1-1.29-.16-2.2-.14-.49,0-1.97.02-3.33-.03-1.36-.05-2.59-.14-2.59-.14,0,0-1.23-.09-2.58-.26-1.35-.16-2.81-.4-3.29-.49-.9-.16-1.42-.19-2.19-.2.72.3,1.21.48,2.11.64.49.09,1.96.34,3.32.5,1.36.17,2.6.26,2.6.26,0,0,1.24.1,2.61.15,1.37.05,2.86.04,3.35.03.92-.02,1.43-.12,2.19-.3Z"
        />
        <path
          style={styles.eleven}
          d="m487.95,319.88c.49.68.94,1.1,1.85,1.55.49.26,2.06.78,3.53,1,1.47.25,2.8.37,2.8.37,0,0,5.33.62,6.4.75.98.11,1.55.09,2.38.03-.81-.25-1.36-.4-2.34-.52-1.06-.12-6.38-.74-6.38-.74,0,0-1.34-.13-2.78-.37-1.45-.21-2.95-.72-3.41-.96-.86-.42-1.35-.65-2.06-1.11Z"
        />
        <path
          style={styles.eleven}
          d="m386.71,292.43c-.14.53-.05,1.11.2,1.61.24.5.61.92,1.01,1.26.8.69,1.72,1.15,2.67,1.49,1.82.62,3.77,1.23,6.31,1.67.69.14,1.72.33,3,.51,1.28.2,2.82.43,4.5.63,3.37.44,7.35.81,11.14,1.17,3.8.35,7.4.71,10.05,1.02,2.64.34,4.35.56,4.35.56,0,0,.43.06,1.18.18.75.12,1.83.3,3.14.52,2.6.52,6.13,1.2,9.68,2.36.45.14.9.27,1.35.41l1.32.49c.88.34,1.77.63,2.62,1,1.68.77,3.34,1.46,4.8,2.26,2.94,1.57,5.41,2.93,6.69,3.52,2.35,1.14,4.37,1.78,6.34,2.06,1.97.27,3.91.18,5.99-.42-2.17-.19-3.84-.72-5.55-1.35-1.71-.65-3.45-1.42-5.69-2.53-1.22-.58-3.6-1.89-6.62-3.5-1.5-.82-3.24-1.56-5-2.36-.89-.38-1.82-.69-2.74-1.04l-1.38-.51c-.47-.14-.93-.28-1.4-.42-3.7-1.2-7.32-1.89-9.99-2.42-1.34-.22-2.44-.4-3.2-.53-.77-.13-1.2-.19-1.2-.19,0,0-1.73-.23-4.41-.58-2.68-.31-6.31-.69-10.11-1.04-3.79-.36-7.74-.73-11.07-1.16-1.67-.19-3.17-.42-4.43-.61-1.26-.18-2.27-.36-2.95-.48-2.49-.41-4.43-.78-6.31-.99-.89-.11-1.73-.27-2.54-.61-.78-.33-1.62-.87-1.78-1.99Z"
        />
        <path
          style={styles.eleven}
          d="m565.36,352.03c2.23.95,3.76,1.53,6.54,2.14,1.51.33,6.05,1.28,10.22,2.11,4.18.8,7.98,1.53,7.98,1.53,0,0,3.81.69,7.99,1.44,4.19.73,8.77,1.47,10.3,1.71,2.82.43,4.46.44,6.88.35-2.29-.79-3.85-1.27-6.66-1.73-1.52-.25-6.09-1.01-10.28-1.71-4.18-.75-7.98-1.43-7.98-1.43,0,0-3.79-.73-7.96-1.53-4.16-.85-8.69-1.78-10.2-2.09-2.79-.58-4.41-.7-6.83-.78Z"
        />
        <path
          style={styles.eleven}
          d="m647.86,376c-1.18-.42-1.91-.79-3.26-1.33-.73-.29-2.92-1.32-5-2.08-2.08-.78-4.01-1.35-4.01-1.35,0,0-1.93-.58-4.09-1.07-2.16-.49-4.56-.85-5.35-.95-1.47-.19-2.33-.14-3.58-.01,1.21.33,2.03.52,3.48.73.79.1,3.15.46,5.29.94,2.14.48,4.05,1.06,4.05,1.06,0,0,1.91.56,3.96,1.33,2.06.75,4.22,1.77,4.98,2.07,1.39.55,2.28.7,3.53.67Z"
        />
        <path
          style={styles.eleven}
          d="m642.39,349.31c-.74-.11-1.25-.17-2.14-.13-.48.02-1.92.11-3.24.23-1.32.12-2.51.26-2.51.26,0,0-1.2.14-2.51.34-1.31.19-2.73.45-3.2.54-.87.17-1.35.34-2.05.63.75-.02,1.26-.04,2.13-.2.47-.09,1.89-.34,3.19-.53,1.3-.2,2.49-.34,2.49-.34,0,0,1.19-.14,2.5-.26,1.31-.12,2.75-.21,3.22-.23.88-.05,1.38-.14,2.11-.3Z"
        />
        <path
          style={styles.eleven}
          d="m642.91,360.03c-.38-.05-.64-.08-1.09-.07-.25,0-.98.01-1.66.02-.68,0-1.29-.02-1.29-.02,0,0-.61-.03-1.29-.05-.67-.05-1.41-.1-1.66-.11-.45-.04-.71-.03-1.1,0,.37.11.62.17,1.08.22.25.02.98.08,1.66.12.68.03,1.3.05,1.3.05,0,0,.62,0,1.3.02.68,0,1.42-.01,1.67-.02.46-.02.71-.07,1.09-.16Z"
        />
        <path
          style={styles.eleven}
          d="m398.23,371.57c3.31-.81,5.51-1.35,9.25-2.71,1.01-.37,3.05-1.08,5.55-1.89,2.51-.8,5.49-1.68,8.36-2.46,2.87-.79,5.63-1.48,7.67-1.98,2.04-.5,3.36-.82,3.36-.82,0,0,21.15-4.99,25.38-5.99,3.9-.93,6.03-1.78,9.15-3.16-3.4.16-5.69.35-9.6,1.26-4.23,1-25.39,5.99-25.39,5.99,0,0-1.32.32-3.37.82-2.05.5-4.82,1.2-7.72,1.99-2.9.79-5.91,1.67-8.45,2.48-2.53.82-4.6,1.54-5.62,1.93-3.78,1.41-5.79,2.6-8.58,4.55Z"
        />
        <path
          style={styles.eleven}
          d="m394.55,398.12c1.51-.89,2.5-1.52,4.08-2.82.86-.71,3.46-2.81,5.92-4.62,2.46-1.83,4.76-3.39,4.76-3.39,0,0,2.3-1.57,4.91-3.17,2.6-1.62,5.51-3.26,6.48-3.79,1.8-.99,2.75-1.68,4.13-2.75-1.68.5-2.8.88-4.62,1.87-.98.53-3.92,2.18-6.53,3.82-2.62,1.62-4.94,3.2-4.94,3.2,0,0-2.33,1.57-4.8,3.4-2.49,1.83-5.1,3.94-5.96,4.66-1.59,1.32-2.36,2.23-3.44,3.6Z"
        />
        <path
          style={styles.eleven}
          d="m389.73,350.15c-.34.9-.55,1.52-.74,2.64-.1.61-.39,2.43-.6,4.11-.22,1.68-.37,3.21-.37,3.21,0,0-.16,1.53-.28,3.22-.13,1.69-.21,3.54-.23,4.15-.04,1.14.05,1.78.21,2.73.19-.94.3-1.58.35-2.71.02-.61.1-2.45.23-4.13.12-1.68.27-3.21.27-3.21,0,0,.15-1.53.37-3.2.21-1.67.5-3.49.59-4.09.18-1.12.2-1.76.2-2.73Z"
        />
        <path
          style={styles.eleven}
          d="m376.15,368.63c-.25.75-.4,1.26-.5,2.18-.06.5-.22,2-.31,3.38-.1,1.38-.13,2.64-.13,2.64,0,0-.05,1.26-.04,2.64,0,1.38.05,2.89.08,3.4.05.93.16,1.45.36,2.21.09-.78.13-1.31.09-2.23-.02-.5-.08-2-.08-3.38,0-1.38.04-2.63.04-2.63,0,0,.04-1.25.14-2.62.09-1.37.25-2.87.3-3.36.1-.92.09-1.44.06-2.23Z"
        />
        <path
          style={styles.eleven}
          d="m385.76,387.72c-.4,1.22-.66,2.06-.75,3.6-.04.81-.17,3.4.79,5.6.45,1.1,1.15,1.99,1.65,2.62.5.62.82,1.02.82,1.02,0,0,.33.39.75,1.03.41.64.89,1.57,1.18,2.62.63,2.11.5,4.57.47,5.37-.08,1.49-.06,2.35.03,3.64.38-1.23.61-2.07.71-3.6.04-.82.18-3.36-.49-5.61-.31-1.13-.83-2.13-1.28-2.83-.46-.68-.79-1.08-.79-1.08,0,0-.32-.4-.82-1.02-.49-.62-1.14-1.44-1.54-2.44-.88-2-.78-4.48-.74-5.27.08-1.49.08-2.35,0-3.63Z"
        />
        <path
          style={styles.eleven}
          d="m384.07,412.44c0-.53-.02-.89-.14-1.5-.13-.66-.78-3.98-.78-3.98,0,0-.64-3.32-.78-3.98-.12-.61-.24-.95-.44-1.44,0,.53.02.89.14,1.5.13.66.78,3.98.78,3.98,0,0,.64,3.32.78,3.98.12.61.24.95.44,1.44Z"
        />
        <path
          style={styles.eleven}
          d="m377.56,414.89c-.33-.36-.57-.6-1.03-.94-.5-.38-3.01-2.25-3.01-2.25,0,0-2.51-1.87-3.01-2.25-.46-.34-.75-.5-1.19-.72.33.36.57.6,1.03.94.5.38,3.01,2.25,3.01,2.25,0,0,2.51,1.87,3.01,2.25.46.34.75.5,1.19.72Z"
        />
        <path
          style={styles.eleven}
          d="m381.97,434.01c1.16.87,1.97,1.42,3.53,2.14.84.39,3.39,1.51,5.78,2.42,2.38.92,4.59,1.64,4.59,1.64,0,0,2.2.74,4.66,1.43,2.46.7,5.17,1.33,6.08,1.52,1.68.36,2.66.4,4.11.4-1.35-.52-2.27-.84-3.93-1.21-.9-.2-3.59-.82-6.03-1.52-2.44-.68-4.63-1.41-4.63-1.41,0,0-2.19-.72-4.55-1.64-2.37-.9-4.9-2.01-5.74-2.39-1.55-.7-2.48-.99-3.87-1.38Z"
        />
        <path
          style={styles.eleven}
          d="m421.96,442.76c1.66.26,2.79.4,4.77.29,1.07-.06,4.29-.28,7.23-.64,2.94-.34,5.59-.78,5.59-.78,0,0,2.66-.42,5.55-1.04,2.9-.6,6.02-1.41,7.06-1.7,1.91-.53,2.95-1.02,4.43-1.79-1.67.19-2.78.35-4.68.86-1.03.28-4.13,1.08-7.01,1.68-2.87.61-5.51,1.04-5.51,1.04,0,0-2.63.44-5.55.77-2.92.35-6.11.57-7.18.64-1.96.12-3.07.32-4.72.67Z"
        />
        <path
          style={styles.eleven}
          d="m504.99,409.75c2.1-.7,3.49-1.18,5.84-2.22.64-.27,1.93-.82,3.53-1.4,1.61-.56,3.51-1.2,5.38-1.67,3.72-.99,7.19-1.41,7.19-1.41,0,0,3.46-.49,7.31-.52,1.92,0,3.93.05,5.59.27,1.67.21,3,.54,3.65.74,1.21.35,2.13.69,3,1.03.87.35,1.7.71,2.65,1.27-.68-.87-1.39-1.53-2.21-2.1-.83-.56-1.79-1.04-3.08-1.43-.69-.22-2.11-.57-3.86-.79-1.75-.23-3.8-.28-5.76-.28-3.93.03-7.47.54-7.47.54,0,0-3.55.43-7.34,1.44-1.9.47-3.85,1.13-5.49,1.7-1.63.6-2.94,1.16-3.6,1.44-2.4,1.08-3.63,1.98-5.33,3.38Z"
        />
        <path
          style={styles.eleven}
          d="m567.99,419.37c.46.33.79.55,1.41.81.34.14,1.36.55,2.32.86.96.32,1.85.55,1.85.55,0,0,.89.24,1.88.44.99.21,2.08.36,2.44.4.67.08,1.06.06,1.63,0-.55-.15-.92-.24-1.59-.33-.36-.05-1.44-.2-2.42-.4-.98-.2-1.86-.43-1.86-.43,0,0-.88-.23-1.83-.55-.95-.31-1.96-.71-2.3-.85-.62-.26-.99-.36-1.54-.51Z"
        />
        <path
          style={styles.eleven}
          d="m540.17,422.88c2.06.05,3.4.12,5.77.14.64.02,1.93.06,3.48.28,1.55.21,3.36.61,5.05,1.15,1.69.53,3.23,1.22,4.34,1.79,1.11.57,1.81.98,1.81.98,0,0,.7.41,1.79,1.06,1.09.65,2.54,1.57,4.13,2.47,1.58.91,3.31,1.75,4.86,2.31,1.54.56,2.87.84,3.52.96,2.44.43,3.9.25,5.89-.23-2.04-.27-3.36-.5-5.67-.92-.62-.12-1.88-.39-3.34-.92-1.46-.53-3.13-1.34-4.68-2.23-1.55-.88-3.01-1.8-4.1-2.45-1.1-.65-1.81-1.07-1.81-1.07,0,0-.71-.42-1.86-1.01-1.15-.59-2.76-1.31-4.51-1.87-1.75-.57-3.63-.98-5.25-1.2-1.61-.23-2.95-.26-3.62-.28-2.46,0-3.87.35-5.79,1.04Z"
        />
        <path
          style={styles.eleven}
          d="m590.21,437.05c.88.36,1.49.58,2.6.77.6.1,2.41.38,4.08.53,1.67.16,3.2.21,3.2.21,0,0,1.52.06,3.2.02,1.68-.03,3.5-.17,4.11-.23,1.12-.11,1.74-.29,2.65-.58-.95-.05-1.59-.06-2.7.04-.6.06-2.41.2-4.07.23-1.66.04-3.17-.02-3.17-.02,0,0-1.51-.05-3.17-.21-1.66-.15-3.45-.42-4.04-.52-1.1-.18-1.73-.21-2.69-.23Z"
        />
        <path
          style={styles.eleven}
          d="m509.96,415.73c-.5.22-.83.38-1.37.73-.29.19-1.17.75-2,1.24-.83.48-1.59.91-1.59.91,0,0-.77.41-1.61.86-.85.44-1.79.89-2.11,1.04-.58.27-.89.47-1.34.78.53-.12.89-.22,1.48-.49.32-.15,1.27-.6,2.12-1.04.85-.45,1.62-.87,1.62-.87,0,0,.76-.44,1.59-.92.83-.49,1.72-1.06,2.01-1.25.54-.35.81-.6,1.2-.99Z"
        />
        <path
          style={styles.eleven}
          d="m508.25,422.27c-1.07.45-1.77.76-2.94,1.41-.64.36-2.58,1.37-4.45,2.12-1.86.77-3.6,1.33-3.6,1.33,0,0-1.74.57-3.69,1.08-1.95.52-4.1.98-4.82,1.13-1.33.28-2.06.53-3.13.95,1.15,0,1.93-.03,3.27-.3.72-.15,2.89-.61,4.86-1.14,1.97-.51,3.73-1.1,3.73-1.1,0,0,1.77-.56,3.65-1.34,1.89-.76,3.87-1.79,4.52-2.16,1.19-.67,1.79-1.19,2.62-1.99Z"
        />
        <path
          style={styles.eleven}
          d="m653.75,400.22c.28-1.26.44-2.13.48-3.65.02-.83.06-3.31.04-5.59-.01-2.28-.07-4.35-.07-4.35,0,0-.05-2.07-.16-4.34-.1-2.27-.27-4.75-.34-5.58-.12-1.52-.32-2.38-.67-3.62-.12,1.29-.18,2.16-.07,3.68.06.82.23,3.29.33,5.56.08,2.27.16,4.33.16,4.33,0,0,.03,2.06.06,4.33.02,2.27-.02,4.74-.04,5.57-.03,1.52.07,2.39.26,3.67Z"
        />
        <path
          style={styles.eleven}
          d="m653.59,353.53c.02.46.05.77.17,1.3.13.57.82,3.44.82,3.44,0,0,.68,2.87.82,3.44.13.53.24.82.43,1.24-.02-.46-.05-.77-.17-1.3-.13-.57-.82-3.44-.82-3.44,0,0-.68-2.87-.82-3.44-.13-.53-.24-.82-.43-1.24Z"
        />
        <path
          style={styles.eleven}
          d="m659.67,355.6c-.01.8,0,1.33.15,2.26.17,1,1.02,6.01,1.02,6.01,0,0,.84,5.01,1.02,6.01.16.92.33,1.43.6,2.18.01-.8,0-1.33-.15-2.26-.17-1-1.02-6.01-1.02-6.01,0,0-.84-5.01-1.02-6.01-.16-.92-.33-1.43-.6-2.18Z"
        />
        <path
          style={styles.eleven}
          d="m664.81,352.49c-.17-.22-.3-.37-.54-.59-.27-.24-1.62-1.43-1.62-1.43,0,0-1.35-1.19-1.62-1.43-.25-.22-.41-.32-.65-.47.17.22.3.37.54.59.27.24,1.62,1.43,1.62,1.43,0,0,1.35,1.19,1.62,1.43.25.22.41.32.65.47Z"
        />
        <path
          style={styles.eleven}
          d="m658.77,396.84c-.02-.23-.05-.38-.12-.64-.04-.14-.15-.57-.25-.96-.09-.39-.17-.75-.17-.75,0,0-.07-.36-.14-.75-.06-.4-.13-.83-.15-.98-.04-.27-.08-.42-.14-.64-.02.23-.02.39.01.65.02.15.09.58.15.98.08.4.14.76.14.76,0,0,.08.36.17.75.1.39.21.82.25.96.07.26.14.4.25.61Z"
        />
        <path
          style={styles.eleven}
          d="m661.28,413.71c-.86.29-1.42.5-2.37.98-.51.26-2.05,1.03-3.5,1.66-1.44.64-2.79,1.15-2.79,1.15,0,0-1.34.52-2.84,1-1.5.5-3.16.95-3.71,1.1-1.02.27-1.58.49-2.41.85.9-.03,1.51-.08,2.55-.35.56-.14,2.23-.6,3.74-1.11,1.51-.49,2.86-1.02,2.86-1.02,0,0,1.35-.51,2.81-1.15,1.46-.63,3.01-1.41,3.53-1.67.95-.49,1.44-.86,2.13-1.44Z"
        />
        <path
          style={styles.eleven}
          d="m646.12,412.14c-1.78.39-2.96.68-4.95,1.43-1.08.41-4.34,1.58-7.38,2.48-3.04.92-5.85,1.59-5.85,1.59,0,0-2.81.68-5.93,1.25-3.12.59-6.56,1.02-7.7,1.15-2.11.24-3.3.52-5.06.98,1.8.2,3.04.28,5.17.05,1.16-.12,4.63-.56,7.77-1.16,3.15-.58,5.98-1.27,5.98-1.27,0,0,2.84-.67,5.9-1.6,3.07-.9,6.36-2.09,7.45-2.51,2.01-.77,3.07-1.4,4.58-2.4Z"
        />
        <path
          style={styles.eleven}
          d="m656.45,422.56c-1.26.1-2.1.2-3.53.57-.78.2-3.11.79-5.26,1.3-2.15.49-4.11.93-4.11.93,0,0-1.96.41-4.13.85-2.16.43-4.53.86-5.32,1-1.46.26-2.26.52-3.45.95,1.26.03,2.11.02,3.57-.24.79-.14,3.17-.56,5.34-1,2.17-.45,4.14-.86,4.14-.86,0,0,1.96-.44,4.12-.93,2.16-.5,4.5-1.1,5.27-1.31,1.43-.38,2.22-.72,3.35-1.27Z"
        />
        <path
          style={styles.eleven}
          d="m658.56,427.79c-1.3-.04-2.21.02-3.71.42-.82.2-3.21.98-5.36,1.77-2.15.78-4.13,1.4-4.13,1.4,0,0-1.97.63-4.2,1.04-2.23.44-4.7.63-5.53.66-1.52.07-2.38.19-3.67.38,1.27.29,2.16.43,3.71.36.84-.02,3.36-.22,5.63-.67,2.28-.42,4.28-1.07,4.28-1.07,0,0,2.01-.62,4.16-1.4,2.16-.78,4.51-1.55,5.31-1.76,1.47-.4,2.27-.71,3.5-1.14Z"
        />
        <path
          style={styles.eleven}
          d="m653.19,437.46c-.47.72-.78,1.2-1.26,2.06-.26.47-1.11,1.86-2.1,2.97-.48.57-.99,1.07-1.36,1.43-.39.35-.64.58-.64.58,0,0-.26.21-.67.54-.41.32-.98.75-1.62,1.13-1.25.8-2.75,1.44-3.26,1.62-.93.35-1.45.58-2.23.93.85-.05,1.45-.12,2.41-.47.52-.19,2.06-.84,3.35-1.66.65-.4,1.24-.83,1.66-1.16.42-.34.69-.56.69-.56,0,0,.26-.23.65-.59.39-.37.91-.88,1.4-1.46,1.01-1.14,1.89-2.57,2.15-3.05.49-.9.65-1.47.83-2.31Z"
        />
        <path
          style={styles.eleven}
          d="m629.87,461.76c-1.17.42-1.95.73-3.22,1.45-.69.39-2.76,1.56-4.7,2.52-1.94.97-3.77,1.7-3.77,1.7,0,0-1.82.75-3.9,1.33-2.08.61-4.41.92-5.19.98-1.44.13-2.25.26-3.48.43,1.21.28,2.07.41,3.54.28.8-.05,3.2-.38,5.32-1,2.13-.59,3.98-1.36,3.98-1.36,0,0,1.86-.74,3.82-1.72,1.97-.97,4.05-2.15,4.74-2.54,1.27-.73,1.93-1.26,2.87-2.07Z"
        />
        <path
          style={styles.eleven}
          d="m607.93,465.07c-.82.07-1.37.14-2.31.39-.51.13-2.03.53-3.44.85-1.41.31-2.69.6-2.69.6,0,0-1.29.25-2.71.53-1.42.26-2.98.51-3.5.6-.96.15-1.49.31-2.27.57.82.04,1.38.04,2.34-.1.52-.08,2.08-.33,3.51-.59,1.42-.28,2.72-.54,2.72-.54,0,0,1.29-.28,2.7-.6,1.41-.33,2.95-.72,3.45-.86.94-.25,1.45-.48,2.19-.85Z"
        />
        <path
          style={styles.eleven}
          d="m618.25,475.76c.37.62.62,1.02,1.13,1.69.28.36,1.08,1.46,1.71,2.55.64,1.07,1.12,2.11,1.12,2.11,0,0,.49,1.03.91,2.2.44,1.17.78,2.49.88,2.93.19.82.34,1.27.59,1.94.01-.72,0-1.21-.19-2.04-.1-.45-.45-1.79-.89-2.98-.43-1.19-.93-2.24-.93-2.24,0,0-.49-1.05-1.14-2.14-.64-1.1-1.45-2.22-1.74-2.58-.52-.67-.89-1-1.46-1.44Z"
        />
        <path
          style={styles.eleven}
          d="m631.17,468.83c-.13.72-.21,1.21-.21,2.08,0,.94,0,5.62,0,5.62,0,0,0,4.68,0,5.62,0,.86.08,1.35.21,2.08.13-.72.21-1.21.21-2.08,0-.94,0-5.62,0-5.62,0,0,0-4.69,0-5.62,0-.86-.08-1.35-.21-2.08Z"
        />
        <path
          style={styles.eleven}
          d="m635,468.31c-.09.92-.12,1.54-.03,2.62.1,1.17.65,7.04.65,7.04,0,0,.53,5.87.65,7.04.1,1.08.25,1.69.5,2.58.09-.92.12-1.54.03-2.62-.1-1.17-.65-7.04-.65-7.04,0,0-.53-5.87-.65-7.04-.1-1.08-.25-1.69-.5-2.58Z"
        />
        <path
          style={styles.eleven}
          d="m614.69,522.04c.17-.69.27-1.16.32-1.99.05-.9.31-5.43.31-5.43,0,0,.26-4.52.31-5.43.04-.83,0-1.31-.09-2.02-.17.69-.27,1.16-.32,1.99-.05.9-.31,5.43-.31,5.43,0,0-.26,4.53-.31,5.43-.04.83,0,1.31.09,2.02Z"
        />
        <path
          style={styles.eleven}
          d="m613.9,491.44c-.96.65-1.59,1.13-2.53,2.13-.5.55-2,2.19-3.37,3.69-1.37,1.5-2.69,2.79-2.69,2.79,0,0-1.3,1.3-2.9,2.54-1.57,1.27-3.5,2.33-4.16,2.63-.75.37-1.42.71-2.92,1.45,1.15-.1,1.96-.26,3.21-.85.69-.3,2.67-1.41,4.28-2.7,1.63-1.27,2.95-2.59,2.95-2.59,0,0,1.34-1.3,2.71-2.81,1.37-1.51,2.87-3.15,3.37-3.69.92-1,1.38-1.63,2.04-2.58Z"
        />
        <path
          style={styles.eleven}
          d="m587.89,492.39c.6-.06,1-.12,1.67-.33.36-.12,1.45-.49,2.44-.87.98-.38,1.86-.77,1.86-.77,0,0,.88-.38,1.82-.85.95-.46,1.95-1.02,2.28-1.21.61-.35.93-.61,1.37-1.01-.56.21-.93.37-1.54.71-.33.19-1.33.74-2.27,1.2-.94.47-1.81.85-1.81.85,0,0-.87.39-1.85.76-.97.38-2.06.75-2.42.87-.67.22-1.03.39-1.56.66Z"
        />
        <path
          style={styles.eleven}
          d="m581.38,498.41c1.31-.19,2.19-.35,3.67-.86.8-.28,3.19-1.13,5.37-1.95,2.17-.83,4.15-1.59,4.15-1.59,0,0,1.97-.79,4.13-1.65,2.16-.88,4.49-1.88,5.26-2.22,1.43-.63,2.19-1.11,3.28-1.86-1.29.31-2.15.55-3.58,1.16-.78.33-3.11,1.31-5.25,2.21-2.15.87-4.11,1.65-4.11,1.65,0,0-1.97.75-4.14,1.58-2.18.79-4.56,1.66-5.36,1.95-1.47.52-2.26.93-3.42,1.58Z"
        />
        <path
          style={styles.eleven}
          d="m575.07,495.53c-1.2-.4-2.14-.56-3.66-.26-.41.09-1.24.28-2.17.72-.94.41-1.97,1.13-2.79,1.9-.82.78-1.63,1.34-2.34,1.62-.7.3-1.18.42-1.18.42,0,0-.47.14-1.24.32-.76.18-1.8.4-2.9.59-2.19.39-4.61.62-5.42.67-1.49.11-2.33.26-3.58.51,1.26.22,2.12.32,3.63.23.82-.05,3.27-.27,5.49-.67,1.11-.19,2.16-.42,2.94-.6.78-.18,1.28-.33,1.28-.33,0,0,.51-.13,1.26-.45.38-.16.82-.39,1.26-.69.44-.3.86-.68,1.28-1.06.81-.76,1.73-1.39,2.61-1.78.87-.41,1.64-.59,2.02-.68,1.42-.28,2.24-.39,3.51-.45Z"
        />
        <path
          style={styles.eleven}
          d="m544.85,507.59c-.56-.02-.94-.02-1.6.07-.36.04-1.42.18-2.41.3-.98.1-1.88.19-1.88.19,0,0-.9.07-1.88.14-.99.05-2.06.11-2.42.13-.66.03-1.03.09-1.59.21.56.09.94.14,1.6.11.36-.01,1.44-.06,2.43-.13.99-.08,1.89-.15,1.89-.15,0,0,.9-.09,1.88-.19.99-.11,2.06-.25,2.41-.3.66-.09,1.02-.21,1.55-.39Z"
        />
        <path
          style={styles.eleven}
          d="m521.09,510.3c-1.47-.27-2.46-.42-4.22-.43-1.9,0-11.43,0-11.43,0,0,0-9.53,0-11.43,0-1.76,0-2.75.15-4.22.43,1.47.27,2.46.42,4.22.43,1.9,0,11.43,0,11.43,0,0,0,9.53,0,11.43,0,1.76,0,2.75-.15,4.22-.43Z"
        />
        <path
          style={styles.eleven}
          d="m483.78,510.1c-.42-.11-.7-.18-1.21-.23-.27-.03-1.09-.13-1.84-.26-.75-.13-1.42-.28-1.42-.28,0,0-.67-.15-1.4-.35-.73-.2-1.51-.46-1.77-.55-.48-.17-.76-.23-1.19-.31.37.23.62.37,1.11.54.26.09,1.05.36,1.79.56.74.21,1.41.36,1.41.36,0,0,.68.16,1.43.29.75.13,1.58.23,1.85.26.51.05.8.03,1.23-.01Z"
        />
        <path
          style={styles.eleven}
          d="m446.37,491.35c.81,1.14,1.44,1.88,2.78,2.87.72.55,2.99,2.06,5.26,3.08,2.25,1.06,4.41,1.73,4.41,1.73,0,0,2.14.71,4.57,1.22,2.42.54,5.09.89,5.98.99,1.64.19,2.59.14,3.99,0-1.35-.37-2.26-.6-3.89-.8-.88-.11-3.53-.46-5.91-.98-2.39-.51-4.49-1.2-4.49-1.2,0,0-2.12-.66-4.32-1.7-2.22-1-4.43-2.47-5.13-3-1.31-.96-2.09-1.45-3.26-2.22Z"
        />
        <path
          style={styles.eleven}
          d="m433.57,492.49c.18.94.4,1.59,1.07,2.52.35.52,1.6,1.94,3.02,2.9,1.39.99,2.8,1.64,2.8,1.64,0,0,1.39.67,2.97,1.26,1.57.58,3.29,1.21,3.86,1.42,1.05.39,1.67.54,2.59.77-.78-.55-1.35-.88-2.4-1.28-.57-.21-2.29-.84-3.86-1.42-1.57-.58-2.93-1.23-2.93-1.23,0,0-1.37-.63-2.72-1.59-1.37-.93-2.57-2.28-2.9-2.77-.65-.88-1.01-1.39-1.51-2.2Z"
        />
        <path
          style={styles.eleven}
          d="m422.45,482.17c1.23,1.13,2.34,1.71,4.29,2.14.53.12,1.6.3,2.89.47,1.29.15,2.81.33,4.27.5,2.91.34,5.56.65,5.56.65,0,0,10.6,1.24,12.71,1.49,1.95.22,3.08.19,4.75.07-1.6-.5-2.69-.79-4.64-1.03-2.11-.25-12.7-1.49-12.7-1.49,0,0-2.65-.31-5.56-.65-1.46-.17-2.98-.35-4.27-.5-1.29-.17-2.32-.35-2.83-.45-1.88-.41-2.93-.6-4.49-1.2Z"
        />
        <path
          style={styles.eleven}
          d="m420.99,477.78c.61.43,1.04.7,1.86,1.04.44.18,1.78.71,3.03,1.12,1.25.42,2.4.74,2.4.74,0,0,1.15.33,2.44.62,1.28.3,2.7.55,3.17.62.87.14,1.38.13,2.13.09-.71-.23-1.19-.37-2.06-.51-.47-.08-1.87-.32-3.15-.62-1.27-.29-2.42-.61-2.42-.61,0,0-1.14-.32-2.38-.74-1.24-.41-2.57-.93-3.01-1.11-.81-.33-1.29-.46-2.02-.64Z"
        />
        <path
          style={styles.eleven}
          d="m423.63,456.97c1.31,1.6,2.35,2.63,4.47,3.91,1.13.72,4.75,2.55,8.26,3.68,3.48,1.19,6.73,1.94,6.73,1.94,0,0,3.23.79,6.8,1.55,3.56.77,7.44,1.61,8.73,1.89,2.39.51,3.79.6,5.86.66-1.91-.8-3.22-1.3-5.61-1.83-1.29-.28-5.17-1.12-8.73-1.9-3.56-.76-6.76-1.54-6.76-1.54,0,0-3.22-.75-6.63-1.92-3.44-1.11-6.94-2.89-8.04-3.58-2.06-1.22-3.26-1.86-5.07-2.88Z"
        />
        <path
          style={styles.eleven}
          d="m487.66,483.38c1.04.26,1.78.34,3.05.21.69-.06,2.72-.38,4.57-.66,1.85-.29,3.54-.55,3.54-.55,0,0,6.74-1.04,8.09-1.25,1.24-.2,1.93-.41,2.94-.76-1.07-.03-1.79-.03-3.03.16-1.35.21-8.09,1.25-8.09,1.25,0,0-1.69.26-3.54.54-1.86.29-3.87.6-4.54.66-1.24.13-1.92.26-2.98.4Z"
        />
        <path
          style={styles.eleven}
          d="m504.65,472.45c.66.14,1.1.22,1.9.24.43,0,1.72.02,2.9.03,1.18,0,2.25,0,2.25,0,0,0,1.07-.02,2.25-.03,1.18-.03,2.47-.07,2.9-.08.79-.03,1.24-.12,1.89-.27-.67-.09-1.12-.14-1.91-.12-.43.01-1.71.05-2.89.08-1.18.02-2.25.04-2.25.04,0,0-1.07,0-2.25,0-1.18-.01-2.46-.02-2.89-.03-.79-.01-1.24.04-1.9.15Z"
        />
        <path
          style={styles.eleven}
          d="m527.36,474.63c-.35-.02-.59-.03-1,.03-.44.06-2.67.34-2.67.34,0,0-2.23.28-2.67.34-.41.05-.64.12-.97.23.35.02.59.03,1-.03.44-.06,2.67-.34,2.67-.34,0,0,2.23-.28,2.67-.34.41-.05.64-.12.97-.23Z"
        />
        <path
          style={styles.eleven}
          d="m572.17,462.62c-1.2.08-2,.16-3.38.48-.74.18-2.98.7-5.04,1.13-2.06.42-3.94.8-3.94.8,0,0-1.88.34-3.95.71-2.07.36-4.34.69-5.1.8-1.4.2-2.17.43-3.32.8,1.2.07,2.01.08,3.41-.11.76-.11,3.04-.44,5.12-.8,2.08-.38,3.97-.72,3.97-.72,0,0,1.88-.38,3.95-.8,2.07-.43,4.31-.96,5.05-1.14,1.38-.34,2.13-.65,3.22-1.15Z"
        />
        <path
          style={styles.eleven}
          d="m611.61,447.96c-1.14.45-1.89.77-3.13,1.49-.67.39-2.7,1.52-4.62,2.45-1.92.94-3.7,1.7-3.7,1.7,0,0-1.78.76-3.79,1.49-2,.74-4.23,1.41-4.98,1.62-1.37.4-2.13.71-3.24,1.22,1.22-.07,2.05-.15,3.44-.54.75-.21,3-.89,5.02-1.63,2.03-.73,3.83-1.5,3.83-1.5,0,0,1.81-.76,3.74-1.71,1.94-.94,3.99-2.09,4.66-2.48,1.25-.73,1.88-1.27,2.78-2.09Z"
        />
        <path
          style={styles.eleven}
          d="m548.25,399.17c.09-.98.13-1.64.06-2.8-.04-.63-.14-2.51-.15-4.23-.02-1.73.04-3.29.04-3.29,0,0,.05-1.57.18-3.29.12-1.72.34-3.59.42-4.21.14-1.15.15-1.81.12-2.8-.33.93-.53,1.57-.68,2.72-.08.63-.3,2.51-.43,4.24-.13,1.73-.18,3.31-.18,3.31,0,0-.06,1.58-.04,3.32,0,1.74.11,3.63.15,4.26.08,1.16.23,1.81.5,2.76Z"
        />
        <path
          style={styles.eleven}
          d="m528.89,396.3c.15-.84.22-1.41.2-2.41-.01-.54-.06-2.17-.14-3.66-.09-1.49-.18-2.84-.18-2.84,0,0-.11-1.35-.23-2.84-.13-1.49-.32-3.1-.38-3.64-.12-.99-.28-1.55-.54-2.35-.04.85-.05,1.42.06,2.41.06.54.25,2.15.38,3.63.12,1.48.24,2.83.24,2.83,0,0,.08,1.35.17,2.83.08,1.48.13,3.11.15,3.65.03,1,.12,1.56.29,2.39Z"
        />
        <path
          style={styles.eleven}
          d="m526.76,396.32c-.04-.47-.07-.78-.2-1.32-.07-.29-.28-1.16-.44-1.97-.15-.81-.29-1.54-.29-1.54,0,0-.11-.74-.23-1.55-.11-.81-.2-1.7-.23-2-.05-.55-.12-.85-.24-1.31-.05.47-.08.78-.03,1.33.03.3.12,1.19.23,2.01.12.82.24,1.56.24,1.56,0,0,.14.74.29,1.55.16.81.37,1.69.44,1.98.13.54.26.83.46,1.25Z"
        />
        <path
          style={styles.eleven}
          d="m543.76,396.56c.05-.18.08-.3.09-.52.02-.24.11-1.43.11-1.43,0,0,.09-1.19.11-1.43.02-.22,0-.35-.01-.53-.05.18-.08.3-.09.52-.02.24-.11,1.43-.11,1.43,0,0-.09,1.19-.11,1.43-.02.22,0,.35.01.53Z"
        />
        <path
          style={styles.eleven}
          d="m449.19,386.08c-.21.4-.34.69-.47,1.21-.07.28-.26,1.14-.38,1.94-.12.8-.17,1.53-.17,1.53,0,0-.06.73-.06,1.54,0,.81.06,1.68.09,1.97.05.54.14.84.29,1.27.02-.46.02-.76-.03-1.29-.03-.29-.09-1.15-.08-1.95,0-.8.05-1.52.05-1.52,0,0,.05-.72.17-1.51.11-.79.3-1.63.37-1.91.13-.52.17-.82.21-1.27Z"
        />
        <path
          style={styles.eleven}
          d="m432.91,449.92c.78.4,1.29.68,2.22,1.11.51.22,1.99.98,3.32,1.77,1.34.78,2.56,1.48,2.56,1.48,0,0,1.22.71,2.66,1.32,1.43.64,3.12,1.02,3.69,1.07,1.05.14,1.67.04,2.52-.2-.88-.08-1.44-.16-2.45-.3-.54-.06-2.17-.42-3.57-1.04-1.41-.6-2.62-1.3-2.62-1.3,0,0-1.21-.7-2.55-1.48-1.33-.79-2.84-1.57-3.37-1.79-.96-.43-1.56-.55-2.43-.65Z"
        />
        <path
          style={styles.eleven}
          d="m494.72,454.99c-.11.18-.18.31-.26.54-.09.25-.55,1.53-.55,1.53,0,0-.46,1.28-.55,1.53-.08.24-.11.38-.15.59.11-.18.18-.31.26-.54.09-.25.55-1.53.55-1.53,0,0,.46-1.28.55-1.53.08-.24.11-.38.15-.59Z"
        />
        <path
          style={styles.eleven}
          d="m497.79,457.83c-.13.18-.21.3-.32.53-.13.25-.66,1.53-.66,1.53,0,0-.5,1.3-.58,1.56-.08.24-.11.39-.14.6.1-.19.17-.32.25-.56.08-.26.58-1.56.58-1.56,0,0,.54-1.28.66-1.53.11-.23.15-.37.21-.58Z"
        />
        <path
          style={styles.eleven}
          d="m517.27,450.6c-.24.36-.38.61-.58,1.07-.21.5-1.27,3.04-1.27,3.04,0,0-1.06,2.53-1.27,3.04-.19.47-.26.75-.35,1.17.24-.36.38-.61.58-1.07.21-.5,1.27-3.04,1.27-3.04,0,0,1.06-2.53,1.27-3.04.19-.47.26-.75.35-1.17Z"
        />
        <path
          style={styles.eleven}
          d="m517.09,459.4c-.2.28-.32.48-.48.85-.09.2-.34.81-.55,1.38-.2.57-.39,1.09-.39,1.09,0,0-.17.52-.36,1.1-.18.58-.35,1.22-.4,1.43-.1.39-.12.63-.14.97.14-.31.23-.53.33-.92.06-.21.23-.84.4-1.42.18-.57.35-1.09.35-1.09,0,0,.19-.51.39-1.08.21-.56.46-1.17.55-1.37.16-.37.22-.59.3-.93Z"
        />
        <path
          style={styles.eleven}
          d="m525.74,457.85c-.19.33-.35.54-.51.96-.09.23-.34.91-.53,1.54-.19.64-.36,1.22-.36,1.22,0,0-.16.58-.33,1.22-.17.64-.33,1.35-.38,1.59-.09.44-.1.7-.11,1.07.15-.35.27-.58.38-1.01.13-.47.79-2.78.79-2.78,0,0,.71-2.29.85-2.76.13-.43.14-.69.2-1.06Z"
        />
        <path
          style={styles.eleven}
          d="m536.4,456.09c.14.37.21.62.44,1.03.23.44,1.51,2.58,1.51,2.58,0,0,.33.53.68,1.12.34.59.79,1.2.96,1.38.3.36.47.56.68.88-.19-.33-.29-.58-.47-1.01-.09-.24-.42-.91-.8-1.48-.36-.58-.68-1.12-.68-1.12,0,0-1.32-2.11-1.61-2.52-.25-.38-.45-.57-.71-.86Z"
        />
        <path
          style={styles.eleven}
          d="m547.5,462c-.09-.23-.15-.38-.3-.63-.16-.27-.99-1.6-.99-1.6,0,0-.82-1.33-.99-1.6-.15-.25-.26-.37-.42-.55.09.23.15.38.3.63.16.27.99,1.6.99,1.6,0,0,.82,1.33.99,1.6.15.24.26.37.42.55Z"
        />
        <path
          style={styles.eleven}
          d="m558.56,456.36c-.1-.31-.17-.52-.36-.86-.2-.37-1.19-2.22-1.19-2.22,0,0-.99-1.85-1.19-2.22-.18-.34-.32-.52-.52-.77.1.31.17.52.36.86.2.37,1.19,2.22,1.19,2.22,0,0,.99,1.85,1.19,2.22.18.34.32.52.52.77Z"
        />
        <path
          style={styles.eleven}
          d="m564.56,456.11c-.11-.22-.19-.37-.37-.6-.1-.12-.4-.49-.71-.81-.3-.32-.59-.59-.59-.59,0,0-.29-.27-.63-.55-.34-.28-.73-.56-.86-.65-.24-.17-.4-.23-.63-.32.18.17.31.28.55.44.13.09.51.36.85.64.34.27.62.54.62.54,0,0,.29.27.59.58.3.31.6.68.7.8.18.23.3.34.48.51Z"
        />
        <path
          style={styles.eleven}
          d="m567.72,449.07c.29.35.49.58.9.91.23.18.9.72,1.51,1.22.63.48,1.19.92,1.19.92,0,0,.58.43,1.21.91.64.46,1.34.97,1.58,1.13.44.3.71.44,1.12.62-.32-.32-.54-.53-.97-.83-.23-.17-.93-.67-1.57-1.13-.63-.47-1.21-.9-1.21-.9,0,0-.57-.44-1.19-.92-.62-.49-1.28-1.03-1.51-1.21-.41-.33-.67-.49-1.06-.71Z"
        />
        <path
          style={styles.eleven}
          d="m579.53,453.68c-.19-.2-.33-.33-.59-.51-.29-.2-1.73-1.21-1.73-1.21,0,0-1.44-1.01-1.73-1.21-.27-.19-.43-.27-.69-.38.19.2.33.33.59.51.29.2,1.73,1.21,1.73,1.21,0,0,1.44,1.01,1.73,1.21.27.19.43.27.69.38Z"
        />
        <path
          style={styles.eleven}
          d="m574.85,438.28c.24.35.41.58.75.93.19.2.75.78,1.26,1.31.5.54.96,1.04.96,1.04,0,0,.45.5.94,1.05.48.56,1.01,1.17,1.19,1.38.32.38.53.57.86.84-.2-.37-.35-.61-.67-1-.18-.21-.71-.82-1.19-1.38-.5-.55-.95-1.06-.95-1.06,0,0-.46-.5-.96-1.04-.51-.54-1.07-1.12-1.26-1.32-.35-.35-.58-.53-.93-.76Z"
        />
        <path
          style={styles.eleven}
          d="m596.22,446.08c-.58-.09-.97-.14-1.66-.12-.75.02-4.48.12-4.48.12,0,0-3.73.1-4.48.12-.69.02-1.08.09-1.65.21.58.09.97.14,1.66.12.75-.02,4.48-.12,4.48-.12,0,0,3.73-.1,4.48-.12.69-.02,1.08-.09,1.65-.21Z"
        />
        <path
          style={styles.eleven}
          d="m410.56,450.05c-.34.75-.55,1.27-.77,2.22-.11.51-.45,2.07-.55,3.54-.12,1.46.05,2.8.05,2.8-.17.06,1.37,5.15.92,6.15-.14.92-.25,1.43-.62,2.17.61-.55.94-1.11,1.09-2.1.04-.27.08-.82.03-1.48-.05-.66-.21-1.41-.38-2.12-.17-.71-.34-1.37-.43-1.86-.1-.49-.13-.81-.13-.81,0,0-.17-1.28-.05-2.71.1-1.42.43-2.96.54-3.47.2-.94.26-1.49.3-2.32Z"
        />
        <path
          style={styles.eleven}
          d="m393.96,445.4c-.24.46-.4.78-.57,1.36-.09.32-.37,1.28-.59,2.17-.21.89-.4,1.7-.4,1.7,0,0-.17.81-.35,1.71-.17.9-.32,1.88-.37,2.21-.09.61-.08.96-.05,1.48.16-.5.25-.83.34-1.43.05-.33.2-1.31.37-2.2.18-.89.34-1.7.34-1.7,0,0,.19-.81.4-1.69.22-.88.49-1.84.58-2.16.17-.59.22-.93.29-1.45Z"
        />
        <path
          style={styles.eleven}
          d="m413.78,471.44c-.07.57-.1.96-.04,1.64.03.37.14,1.47.23,2.47.11,1.01.22,1.92.22,1.92,0,0,.12.91.25,1.92.15,1,.32,2.09.38,2.45.11.67.24,1.04.44,1.58,0-.58,0-.96-.11-1.63-.06-.36-.22-1.45-.37-2.45-.13-1-.25-1.91-.25-1.91,0,0-.1-.91-.22-1.91-.09-1-.2-2.1-.23-2.47-.06-.67-.14-1.05-.29-1.61Z"
        />
        <path
          style={styles.eleven}
          d="m394.05,320.61c1.02.36,1.79.49,3.08.35.71-.05,2.76-.51,4.59-1.01,1.84-.48,3.51-.91,3.51-.91,0,0,1.67-.43,3.51-.9,1.83-.51,3.84-.81,4.51-.77,1.23,0,1.92.03,2.98.24-.91-.58-1.68-.86-2.99-.86-.72-.05-2.82.27-4.65.78-1.84.48-3.51.91-3.51.91,0,0-1.67.43-3.51.9-1.84.5-3.83.95-4.5,1-1.24.14-1.93.24-3.01.27Z"
        />
        <path
          style={styles.eleven}
          d="m411.75,332.75c.83.14,1.54.26,2.29.31.75-.02,1.55-.02,2.55-.21,1.09-.16,4.28-.97,7.06-2.08,1.4-.53,2.7-1.1,3.67-1.5.96-.41,1.58-.68,1.58-.68,0,0,.62-.26,1.58-.67.96-.4,2.26-.96,3.64-1.46,2.75-1.07,5.86-1.76,6.91-1.89.96-.16,1.72-.2,2.44-.36.72-.08,1.41-.17,2.25-.3-.82-.17-1.52-.32-2.28-.41-.75,0-1.56-.04-2.56.11-1.1.12-4.32.84-7.11,1.93-1.41.51-2.71,1.08-3.68,1.48-.96.41-1.58.68-1.58.68,0,0-2.47,1.1-5.21,2.16-2.74,1.1-5.83,1.88-6.88,2.04-.96.18-1.72.27-2.43.45-.72.11-1.4.23-2.24.4Z"
        />
        <path
          style={styles.eleven}
          d="m469.69,321.41c-.85.01-1.42.04-2.4.24-1.06.21-6.37,1.29-6.37,1.29,0,0-5.31,1.07-6.37,1.29-.98.2-1.52.4-2.3.71.85-.01,1.42-.04,2.4-.24,1.06-.21,6.37-1.29,6.37-1.29,0,0,5.31-1.07,6.37-1.29.98-.2,1.52-.4,2.3-.71Z"
        />
        <path
          style={styles.eleven}
          d="m480.85,443.92c-.3.55-.69.87-1.08,1.22-.4.35-.86.71-1.38,1.21-.58.53-2.35,2.11-4.13,3.35-1.77,1.25-3.5,2.21-3.5,2.21,0,0-1.71.99-3.71,1.84-1.99.87-4.25,1.58-5.01,1.78-1.4.4-2.17.69-3.33,1.15,1.24-.02,2.1-.07,3.53-.47.78-.19,3.08-.92,5.1-1.81,2.03-.87,3.77-1.88,3.77-1.88,0,0,1.76-.97,3.56-2.25,1.82-1.26,3.62-2.86,4.2-3.41.53-.52.93-.93,1.27-1.38.33-.45.62-.95.7-1.57Z"
        />
        <path
          style={styles.eleven}
          d="m469.2,449.7c-.88.61-1.48.96-2.55,1.56-.57.34-2.43,1.14-4.27,1.38-1.83.28-3.53.21-3.53.21,0,0-1.69-.03-3.56-.22-1.87-.18-3.91-.37-4.59-.44-1.26-.12-1.98-.08-3.05.02,1.03.3,1.73.47,2.99.6.68.07,2.72.26,4.6.44,1.88.19,3.61.22,3.61.22,0,0,1.73.08,3.63-.22.95-.14,1.93-.39,2.73-.68.8-.29,1.43-.61,1.74-.78,1.13-.64,1.66-1.21,2.26-2.1Z"
        />
        <path
          style={styles.eleven}
          d="m486.65,454.42c-.77-.06-1.33.2-2.07.7-.4.28-1.61,1.11-2.71,1.87-1.11.76-2.09,1.49-2.09,1.49,0,0-1,.72-1.98,1.67-1,.94-1.83,2.2-2.06,2.64-.45.8-.59,1.32-.71,2.07.61-1.04.88-1.49,1.09-1.85.23-.42,1.02-1.63,1.98-2.54.94-.93,1.93-1.64,1.93-1.64,0,0,.97-.72,2.08-1.48,1.1-.76,2.31-1.6,2.71-1.87.74-.5,1.09-.84,1.82-1.06Z"
        />
        <path
          style={styles.eleven}
          d="m520.41,445.95c.11.4.21.67.45,1.1.13.23.55.92,1,1.51.44.59.89,1.09.89,1.09,0,0,.45.5.99,1,.54.51,1.18,1,1.39,1.16.4.29.66.41,1.05.57-.31-.29-.51-.47-.91-.76-.21-.16-.84-.64-1.37-1.14-.53-.49-.97-.99-.97-.99,0,0-.44-.49-.88-1.07-.44-.58-.85-1.26-.99-1.49-.25-.42-.41-.65-.66-.98Z"
        />
        <path
          style={styles.eleven}
          d="m529.69,453.76c.39.08.66.11,1.13.07.25-.02,1.01-.13,1.68-.34.67-.2,1.25-.47,1.25-.47,0,0,.58-.26,1.17-.65.59-.38,1.16-.89,1.34-1.07.33-.33.48-.56.67-.91-.3.25-.5.42-.83.74-.18.17-.73.67-1.31,1.04-.57.38-1.14.64-1.14.64,0,0-.56.27-1.22.46-.65.2-1.39.31-1.64.33-.46.05-.71.09-1.1.16Z"
        />
        <path
          style={styles.eleven}
          d="m541.97,444.58c-.1.05-.16.09-.27.16-.11.08-.68.51-.68.51,0,0-.56.43-.68.51-.1.08-.16.13-.23.21.1-.05.16-.09.27-.16.11-.08.68-.51.68-.51,0,0,.56-.43.68-.51.1-.08.16-.13.23-.21Z"
        />
        <path
          style={styles.eleven}
          d="m546.92,441.32c-.18.07-.3.12-.49.23-.21.13-1.24.76-1.24.76,0,0-1.04.63-1.24.76-.19.12-.29.2-.43.33.18-.07.3-.12.49-.23.21-.13,1.24-.76,1.24-.76,0,0,1.04-.63,1.24-.76.19-.12.29-.2.43-.33Z"
        />
        <path
          style={styles.eleven}
          d="m552.65,439.5c.21.03.36.05.62.05.28,0,1.67-.04,1.67-.04,0,0,1.39-.03,1.67-.04.26,0,.4-.03.61-.08-.21-.03-.36-.05-.62-.05-.28,0-1.67.04-1.67.04,0,0-1.39.03-1.67.04-.26,0-.4.03-.61.08Z"
        />
        <path
          style={styles.eleven}
          d="m539.92,431.81c-.33.18-.55.32-.88.61-.18.16-.72.64-1.19,1.1-.47.46-.88.9-.88.9,0,0-.41.44-.85.94-.44.5-.88,1.06-1.03,1.25-.27.35-.39.58-.55.92.27-.26.45-.44.72-.78.15-.19.59-.75,1.02-1.24.43-.5.84-.93.84-.93,0,0,.41-.44.88-.89.47-.46,1-.94,1.18-1.1.33-.29.5-.48.74-.77Z"
        />
        <path
          style={styles.eleven}
          d="m542.35,432.18c-.19.11-.31.19-.5.37-.1.09-.4.39-.66.67-.26.28-.48.55-.48.55,0,0-.22.27-.45.58-.23.31-.46.66-.53.78-.14.22-.19.36-.27.56.14-.17.23-.28.37-.49.07-.12.3-.46.53-.77.23-.31.45-.57.45-.57,0,0,.22-.27.48-.55.26-.28.56-.57.66-.66.19-.18.28-.29.42-.46Z"
        />
        <path
          style={styles.eleven}
          d="m546.81,432.44c-.05.13-.09.22-.13.38-.02.09-.09.35-.17.58-.08.23-.16.44-.16.44,0,0-.09.21-.19.43-.1.22-.23.46-.27.54-.08.14-.12.23-.17.36.09-.1.15-.18.24-.32.04-.08.17-.32.28-.54.1-.23.19-.44.19-.44,0,0,.08-.21.16-.45.08-.24.15-.5.17-.58.04-.16.05-.26.05-.4Z"
        />
        <path
          style={styles.eleven}
          d="m517.11,429.54c.06.3.1.49.24.82.07.18.3.71.52,1.19.23.48.43.91.43.91,0,0,.22.43.46.9.24.47.53.97.62,1.14.18.31.31.47.51.69-.1-.28-.18-.47-.36-.78-.09-.17-.37-.67-.62-1.13-.24-.47-.46-.9-.46-.9,0,0-.2-.43-.43-.91-.21-.48-.44-1.01-.52-1.18-.14-.33-.24-.5-.4-.76Z"
        />
        <path
          style={styles.eleven}
          d="m521.26,433.07c.03.19.06.32.14.54.05.12.19.46.35.77.15.31.31.58.31.58,0,0,.15.27.34.56.19.29.41.6.49.7.14.18.24.27.39.4-.09-.17-.16-.28-.3-.47-.07-.1-.3-.4-.48-.69-.19-.29-.34-.56-.34-.56,0,0-.15-.27-.31-.58-.15-.31-.3-.65-.34-.77-.09-.21-.15-.33-.25-.5Z"
        />
        <path
          style={styles.eleven}
          d="m523.98,429.43c-.08.23-.13.39-.12.68,0,.16.04.63.16,1.04.11.42.27.77.27.77-.03.02.71,1.38.91,1.63.16.24.27.36.44.53-.1-.22-.17-.37-.32-.61-.19-.24-.93-1.59-.9-1.6,0,0-.16-.35-.27-.75-.12-.4-.15-.86-.15-1.01,0-.05,0-.25-.02-.68Z"
        />
        <path
          style={styles.eleven}
          d="m503.35,441.44c.45-.03.74-.06,1.26-.18.28-.06,1.11-.25,1.88-.38.77-.14,1.47-.23,1.47-.23,0,0,.7-.1,1.48-.17.78-.08,1.63-.13,1.91-.14.52-.03.82-.08,1.26-.17-.44-.07-.74-.11-1.27-.09-.29.01-1.14.06-1.93.14-.78.08-1.49.18-1.49.18,0,0-.71.1-1.48.23-.78.13-1.61.32-1.89.38-.51.12-.79.24-1.2.43Z"
        />
        <path
          style={styles.eleven}
          d="m573.14,511.22c-.17.76-.28,1.27-.4,2.17-.06.49-.32,1.94-.74,3.23-.4,1.29-.94,2.4-.94,2.4,0,0-.52,1.12-1.26,2.25-.72,1.15-1.69,2.27-2.02,2.63-.62.67-.94,1.07-1.42,1.69.67-.4,1.11-.7,1.75-1.38.34-.36,1.33-1.51,2.07-2.69.76-1.16,1.28-2.31,1.28-2.31,0,0,.55-1.14.96-2.46.44-1.32.69-2.81.76-3.31.12-.92.07-1.46-.04-2.23Z"
        />
        <path
          style={styles.eleven}
          d="m584.15,502.25c-.47.63-.78,1.05-1.26,1.84-.25.43-1.07,1.68-1.93,2.76-.84,1.09-1.66,2.04-1.66,2.04,0,0-3.26,3.84-4.06,4.42-.68.61-1.05.97-1.63,1.51.73-.29,1.22-.55,1.93-1.17.84-.62,4.1-4.47,4.1-4.47,0,0,.83-.95,1.68-2.05.86-1.09,1.7-2.37,1.95-2.81.48-.8.66-1.32.87-2.07Z"
        />
        <path
          style={styles.eleven}
          d="m554.13,514.37c-.63.64-1.12.96-1.99,1.5-.46.31-2.03.99-3.57,1.33-1.53.37-2.96.53-2.96.53,0,0-1.43.19-3.01.4-1.58.21-3.29.63-3.85.83-1.03.32-1.56.69-2.28,1.24,1.32-.4,1.9-.58,2.43-.74.54-.19,2.2-.59,3.77-.81,1.57-.21,3-.41,3-.41,0,0,.36-.05.92-.13.56-.09,1.31-.22,2.09-.41,1.57-.35,3.21-1.05,3.71-1.39.93-.58,1.37-1.12,1.73-1.95Z"
        />
        <path
          style={styles.eleven}
          d="m531.39,521.64c.19.07.32.11.55.14.13.02.51.06.86.1.35.03.67.05.67.05,0,0,.32.02.67.03.35,0,.73.01.86.01.24,0,.37-.02.56-.07-.2-.03-.33-.05-.56-.05-.25.01-1.52-.05-1.52-.05,0,0-1.27-.11-1.52-.15-.23-.03-.37-.03-.57-.03Z"
        />
        <path
          style={styles.eleven}
          d="m556.44,530.03c.53,0,.91-.06,1.5-.33.32-.14,1.22-.65,2-1.18.79-.53,1.43-1.1,1.43-1.1,0,0,.67-.56,1.26-1.31.61-.74,1.06-1.7,1.17-2.03.22-.6.25-.98.22-1.52-.17.51-.29.83-.51,1.41-.11.31-.54,1.23-1.12,1.94-.57.73-1.22,1.27-1.22,1.27,0,0-.63.56-1.4,1.08-.77.52-1.66,1.02-1.96,1.16-.56.26-.87.42-1.37.6Z"
        />
        <path
          style={styles.eleven}
          d="m507.05,531.95c.05.39.32.69.62.88.3.2.67.33,1.12.43.48.12,1.93.45,3.26.73,1.34.27,2.55.51,2.55.51,0,0,1.22.22,2.56.46,1.34.23,2.81.45,3.3.52.9.13,1.43.12,2.2.08-.74-.24-1.24-.38-2.14-.52-.49-.07-1.95-.29-3.29-.52-1.34-.24-2.55-.46-2.55-.46,0,0-1.21-.24-2.54-.51-1.33-.3-2.77-.62-3.25-.73-.44-.1-.77-.18-1.07-.29-.29-.12-.57-.25-.77-.59Z"
        />
        <path
          style={styles.eleven}
          d="m496.86,529.98c.15.31.27.51.52.83.14.17.55.69.95,1.14.4.45.77.86.77.86,0,0,.38.4.8.83.43.43.91.88,1.07,1.03.3.27.5.4.79.57-.21-.27-.36-.45-.66-.72-.16-.15-.64-.59-1.07-1.02-.42-.43-.8-.83-.8-.83,0,0-.37-.41-.77-.85-.4-.45-.81-.96-.94-1.14-.25-.32-.42-.48-.67-.71Z"
        />
        <path
          style={styles.eleven}
          d="m478.99,514.84c-.45,1.08-.4,2.11.37,3.35.21.34.68.98,1.42,1.59.73.63,1.73,1.15,2.69,1.56.95.4,1.86.77,2.53,1.05.66.29,1.06.5,1.06.5,0,0,1.65.82,3,2.34,1.38,1.47,2.46,3.45,2.81,4.09.65,1.21,1.09,1.86,1.78,2.81-.31-1.13-.54-1.9-1.19-3.13-.35-.66-1.45-2.68-2.9-4.23-.71-.78-1.5-1.43-2.13-1.84-.62-.43-1.06-.63-1.06-.63,0,0-.43-.23-1.1-.52-.67-.28-1.58-.66-2.53-1.05-.93-.41-1.86-.89-2.52-1.46-.67-.56-1.1-1.14-1.29-1.44-.35-.55-.56-1.01-.72-1.47-.15-.46-.25-.93-.22-1.51Z"
        />
        <path
          style={styles.eleven}
          d="m478.02,523.21c-.66-.28-1.1-.45-1.92-.63-.88-.2-5.32-1.17-5.32-1.17,0,0-4.43-.98-5.32-1.17-.82-.18-1.3-.21-2.01-.23.66.28,1.1.45,1.92.63.88.2,5.32,1.17,5.32,1.17,0,0,4.43.98,5.32,1.17.82.18,1.3.21,2.01.23Z"
        />
        <path
          style={styles.eleven}
          d="m448.07,513.8c.28.3.48.5.89.78.22.15.88.6,1.51.98.63.38,1.21.7,1.21.7,0,0,.58.32,1.24.64.66.32,1.39.64,1.64.74.45.19.73.25,1.14.32-.36-.22-.6-.35-1.05-.54-.24-.1-.97-.42-1.63-.74-.65-.32-1.24-.64-1.24-.64,0,0-.58-.32-1.2-.7-.62-.38-1.28-.82-1.5-.97-.4-.28-.65-.41-1.02-.59Z"
        />
        <path
          style={styles.eleven}
          d="m425.73,498.87c.45,1.01.8,1.69,1.61,2.73.44.56,1.8,2.22,3.2,3.61,1.38,1.4,2.75,2.56,2.75,2.56,0,0,1.36,1.17,2.96,2.3,1.6,1.15,3.45,2.24,4.08,2.58,1.16.63,1.88.86,2.96,1.15-.9-.66-1.51-1.08-2.65-1.71-.62-.34-2.44-1.41-4.02-2.54-1.58-1.12-2.92-2.27-2.92-2.27,0,0-1.35-1.14-2.71-2.52-1.37-1.37-2.72-3-3.15-3.56-.81-1.02-1.32-1.55-2.11-2.34Z"
        />
        <path
          style={styles.eleven}
          d="m459.79,536.63c1.45.53,2.43.85,4.21,1.18.96.18,3.86.72,6.5,1.27,2.64.55,5.03,1.1,5.03,1.1,0,0,2.39.55,5.01,1.22,2.62.66,5.45,1.44,6.4,1.71,1.74.49,2.77.63,4.3.79-1.37-.7-2.32-1.14-4.06-1.64-.94-.27-3.79-1.06-6.42-1.72-2.63-.67-5.03-1.22-5.03-1.22,0,0-2.4-.56-5.05-1.11-2.65-.56-5.55-1.1-6.52-1.27-1.79-.32-2.83-.34-4.37-.3Z"
        />
        <path
          style={styles.eleven}
          d="m495.49,543.78c.37.22.62.36,1.1.52.26.09,1.04.35,1.76.57.72.21,1.38.4,1.38.4,0,0,.66.17,1.39.36.73.18,1.53.35,1.8.4.49.1.78.11,1.21.11-.4-.15-.67-.25-1.16-.35-.27-.06-1.07-.23-1.79-.4-.73-.19-1.39-.36-1.39-.36,0,0-.65-.19-1.37-.4-.72-.22-1.49-.48-1.75-.56-.48-.16-.76-.22-1.18-.29Z"
        />
        <path
          style={styles.eleven}
          d="m465.64,542.72c-.4-.16-.66-.27-1.15-.37-.53-.11-3.19-.68-3.19-.68,0,0-2.65-.57-3.19-.68-.49-.1-.78-.12-1.21-.13.39.16.66.26,1.15.37.53.11,3.19.68,3.19.68,0,0,2.66.57,3.19.68.49.1.78.12,1.2.13Z"
        />
        <path
          style={styles.eleven}
          d="m466.24,542.38c.69.63,1.14,1.06,2.01,1.73.48.35,1.85,1.49,3.09,2.58,1.24,1.08,2.42,2.02,2.42,2.02,0,0,1.17.96,2.6,1.82,1.41.9,3.14,1.54,3.74,1.68,1.09.3,1.76.29,2.68.17-.91-.22-1.5-.39-2.54-.68-.57-.14-2.23-.76-3.6-1.63-1.39-.84-2.54-1.78-2.54-1.78,0,0-1.16-.93-2.4-2.01-1.24-1.08-2.63-2.24-3.11-2.6-.89-.67-1.47-.96-2.34-1.3Z"
        />
        <path
          style={styles.eleven}
          d="m487.03,553.41c.39.16.65.25,1.14.35.53.11,3.15.63,3.15.63,0,0,2.62.53,3.15.63.48.1.77.11,1.19.11-.39-.16-.66-.25-1.14-.35-.53-.11-3.15-.63-3.15-.63,0,0-2.62-.53-3.15-.63-.48-.1-.77-.11-1.19-.12Z"
        />
        <path
          style={styles.eleven}
          d="m454.46,551.78c.28.99.53,1.66,1.24,2.67.37.55,1.64,2.13,3.09,3.25,1.43,1.15,2.86,1.97,2.86,1.97,0,0,1.42.85,2.99,1.72,1.57.88,3.29,1.84,3.86,2.16,1.06.59,1.71.83,2.68,1.16-.79-.66-1.34-1.08-2.39-1.67-.57-.32-2.29-1.28-3.86-2.16-1.58-.87-2.98-1.71-2.98-1.71,0,0-1.41-.82-2.8-1.93-1.41-1.09-2.63-2.61-2.99-3.14-.69-.97-1.1-1.5-1.72-2.32Z"
        />
        <path
          style={styles.eleven}
          d="m504.4,577.07c-1.35-.35-2.27-.56-3.9-.69-.89-.07-3.54-.29-5.98-.5-2.43-.22-4.65-.42-4.65-.42,0,0-2.21-.22-4.64-.47-2.43-.26-5.08-.55-5.96-.65-1.63-.18-2.57-.15-3.96-.06,1.33.42,2.24.66,3.87.86.88.1,3.54.39,5.97.65,2.44.24,4.65.46,4.65.46,0,0,2.22.2,4.65.43,2.44.2,5.1.42,5.99.49,1.64.12,2.58.05,3.96-.11Z"
        />
        <path
          style={styles.eleven}
          d="m510.52,569.78c-.53-.1-.89-.15-1.53-.16-.69,0-4.14,0-4.14,0,0,0-3.45,0-4.14,0-.64,0-1,.06-1.53.16.53.1.89.15,1.53.16.69,0,4.14,0,4.14,0,0,0,3.45,0,4.14,0,.64,0,1-.06,1.53-.16Z"
        />
        <path
          style={styles.eleven}
          d="m485.61,585.71c.67.17,1.13.27,1.94.34.44.04,1.76.16,2.96.33,1.2.16,2.29.34,2.29.34,0,0,1.09.18,2.28.43,1.19.24,2.48.56,2.9.67.79.2,1.25.26,1.94.32-.62-.31-1.05-.5-1.84-.71-.43-.11-1.72-.43-2.92-.67-1.2-.25-2.29-.43-2.29-.43,0,0-1.09-.19-2.31-.35-1.21-.17-2.54-.29-2.98-.33-.82-.07-1.29-.03-1.97.06Z"
        />
        <path
          style={styles.eleven}
          d="m462.26,584.29c.5.06.83.09,1.42.13.32.01,1.27.11,2.13.3.86.18,1.63.41,1.63.41,0,0,.77.23,1.62.48.85.26,1.78.53,2.09.61.58.15.92.17,1.42.18-.47-.19-.78-.31-1.35-.46-.31-.08-1.23-.34-2.08-.61-.85-.25-1.62-.48-1.62-.48,0,0-.78-.23-1.65-.41-.87-.2-1.85-.29-2.17-.3-.6-.03-.94.04-1.43.16Z"
        />
        <path
          style={styles.eleven}
          d="m476.35,588.01c1.57.28,2.84.6,4.17.93,1.33.33,2.71.68,4.45,1.19.95.25,2.79.97,5.2,1.78,2.45.78,5.31,1.38,8.12,1.8,2.77.55,5.54.71,7.55.63,2.01-.08,3.3-.21,3.3-.21,0,0,.32-.04.89-.11.57-.08,1.39-.15,2.37-.33,1.98-.29,4.61-.88,7.33-1.55,2.7-.7,5.53-1.44,7.92-2.06,2.39-.62,4.35-1.06,5.33-1.26,3.63-.74,5.63-1.34,8.72-2.11-3.15-.4-5.38-.41-9.09.32-1.01.2-3.01.64-5.41,1.27-2.39.62-5.21,1.36-7.91,2.06-2.69.66-5.27,1.24-7.18,1.53-.95.18-1.74.25-2.29.33-.55.07-.86.11-.86.11,0,0-1.24.12-3.16.19-1.92.07-4.5-.08-7.21-.62-2.71-.4-5.58-1.01-7.84-1.74-2.28-.77-4.23-1.52-5.24-1.78-1.85-.54-3.36-.73-4.79-.77-1.43-.03-2.78.1-4.34.4Z"
        />
        <path
          style={styles.eleven}
          d="m446.1,582.18c-.48.18-.79.31-1.32.57-.29.14-1.17.54-2.02.82-.84.28-1.63.48-1.63.48,0,0-.78.2-1.66.36-.88.17-1.84.32-2.16.37-.59.09-.92.19-1.4.35.51.02.85.02,1.45-.06.32-.05,1.29-.19,2.17-.37.88-.17,1.68-.37,1.68-.37,0,0,.8-.19,1.65-.48.86-.28,1.76-.68,2.05-.83.54-.27.82-.49,1.2-.83Z"
        />
        <path
          style={styles.eleven}
          d="m460.3,587.54c-.71-.1-1.19-.14-2.03-.11-.91.05-5.48.27-5.48.27,0,0-4.57.23-5.48.27-.84.05-1.32.14-2.01.31.71.1,1.2.14,2.04.1.91-.05,5.47-.27,5.47-.27,0,0,4.56-.23,5.47-.27.84-.05,1.32-.14,2.02-.31Z"
        />
        <path
          style={styles.eleven}
          d="m405.81,591.68c.26-.3.57-.38.88-.44.31-.07.66-.09,1.12-.16.98-.16,5.88-.95,5.88-.95,0,0,4.9-.79,5.88-.95.9-.15,1.4-.31,2.14-.57-.78-.02-1.3-.01-2.21.13-.98.16-5.88.95-5.88.95,0,0-4.9.79-5.88.95-.45.08-.82.14-1.15.29-.32.14-.64.38-.77.75Z"
        />
        <path
          style={styles.eleven}
          d="m430.36,590.58c1.55.94,2.58,1.57,4.5,2.48,1.05.47,4.14,2.05,7.08,3.35,2.93,1.3,5.69,2.25,5.69,2.25,0,0,2.74.99,5.8,1.91,3.06.92,6.42,1.82,7.55,2.1,2.07.53,3.29.66,5.1.78-1.65-.76-2.77-1.24-4.84-1.78-1.12-.29-4.47-1.18-7.51-2.1-3.04-.91-5.75-1.89-5.75-1.89,0,0-2.72-.95-5.62-2.23-2.89-1.27-5.99-2.86-7.05-3.33-1.95-.9-3.15-1.22-4.94-1.54Z"
        />
        <path
          style={styles.eleven}
          d="m482.2,602.71c.37.19.64.28,1.13.34.27.04,1.06.08,1.78.1.72.03,1.38.06,1.38.06,0,0,2.63.11,3.16.13.49.02.76-.01,1.17-.07-.4-.09-.68-.15-1.16-.17-.53-.02-3.16-.13-3.16-.13,0,0-.66-.03-1.38-.06-.73-.03-1.51-.07-1.77-.1-.14-.01-.45-.04-1.16-.11Z"
        />
        <path
          style={styles.eleven}
          d="m488.11,607.22c1.18.37,1.99.6,3.45.71.79.06,3.2.22,5.4-.21,1.09-.23,2.06-.56,2.81-.76.74-.22,1.21-.33,1.21-.33,0,0,1.89-.48,4.03-.65,2.14-.2,4.5-.17,5.28-.16,1.45.02,2.28-.07,3.5-.25-1.21-.27-2.03-.43-3.49-.46-.79-.02-3.17-.05-5.36.16-2.19.18-4.14.68-4.14.68,0,0-.49.12-1.22.33-.73.19-1.72.53-2.76.74-2.08.41-4.44.25-5.21.2-1.44-.1-2.27-.09-3.5,0Z"
        />
        <path
          style={styles.eleven}
          d="m477.81,608.9c.42.74,1.12,1.17,1.85,1.41.74.25,1.56.32,2.54.33,1.06.02,4.27.05,7.21,0,2.94-.04,5.61-.24,5.61-.24,0,0,2.68-.16,5.6-.55,2.93-.37,6.08-1.09,7.11-1.39,1.92-.51,2.94-1.04,4.39-1.87-1.65.25-2.74.46-4.63.94-1.01.29-4.11,1-7,1.36-2.89.39-5.53.55-5.53.55,0,0-2.65.19-5.57.23-2.92.06-6.11.02-7.18.01-.98,0-1.71.01-2.4-.06-.68-.08-1.34-.19-2-.72Z"
        />
        <path
          style={styles.eleven}
          d="m558.4,605.5c.82.04,1.49.05,2.19.04.71.03,1.44-.01,2.38-.03,1.02-.05,4.09-.02,6.9.33,2.81.32,5.34.8,5.34.8,0,0,2.54.49,5.33,1.02,2.79.56,5.87,1.08,6.89,1.22,1.9.27,3.01.23,4.62.07-1.56-.44-2.61-.71-4.48-.99-1.02-.15-4.06-.67-6.85-1.22-2.8-.53-5.34-1.02-5.34-1.02,0,0-2.55-.48-5.4-.81-2.85-.36-5.99-.39-7.04-.33-.97.03-1.72.16-2.42.29-.7.17-1.35.38-2.12.64Z"
        />
        <path
          style={styles.eleven}
          d="m613.68,620.29c-.69-.55-1.17-.9-2.1-1.37-.5-.25-2.03-.99-3.46-1.59-1.42-.61-2.75-1.11-2.75-1.11,0,0-1.32-.5-2.79-.99-1.47-.49-3.1-.95-3.64-1.09-1-.27-1.6-.32-2.48-.36.8.36,1.35.58,2.35.85.54.15,2.16.6,3.62,1.09,1.46.48,2.77.98,2.77.98,0,0,1.31.49,2.72,1.1,1.42.6,2.93,1.33,3.43,1.58.93.45,1.48.65,2.32.91Z"
        />
        <path
          style={styles.eleven}
          d="m551.9,591.35c-.47.22-.78.38-1.27.73-.54.38-3.21,2.3-3.21,2.3,0,0-2.68,1.92-3.21,2.3-.49.36-.74.6-1.1.97.47-.22.78-.38,1.27-.73.54-.38,3.21-2.3,3.21-2.3,0,0,2.68-1.92,3.21-2.3.49-.36.74-.6,1.1-.97Z"
        />
        <path
          style={styles.eleven}
          d="m565.3,595.43c-.33-.03-.55-.04-.94.01-.21.03-.84.11-1.41.21-.57.11-1.09.21-1.09.21,0,0-.52.12-1.08.24-.56.14-1.18.31-1.38.37-.37.11-.58.21-.87.36.33-.04.55-.07.92-.18.2-.06.81-.23,1.37-.36.56-.13,1.08-.24,1.08-.24,0,0,.52-.1,1.08-.2.57-.1,1.19-.18,1.4-.21.38-.05.6-.11.92-.2Z"
        />
        <path
          style={styles.eleven}
          d="m547.43,590.75c-.95,0-1.59,0-2.69.18-.6.09-2.39.37-4.05.57-1.65.21-3.16.36-3.16.36,0,0-1.51.15-3.17.27-1.66.12-3.48.2-4.08.23-1.12.05-1.74.15-2.68.35.94.16,1.58.24,2.7.2.61-.02,2.43-.1,4.1-.23,1.67-.12,3.18-.27,3.18-.27,0,0,1.51-.15,3.17-.35,1.66-.2,3.46-.48,4.06-.58,1.11-.18,1.72-.38,2.61-.72Z"
        />
        <path
          style={styles.eleven}
          d="m448.78,616.94c1.51,1.43,2.67,2.32,4.92,3.37,1.21.59,4.98,2.08,8.55,3.03,3.55.99,6.81,1.7,6.81,1.7,0,0,3.24.78,6.85,1.49,3.61.74,7.64,1.15,8.98,1.18,2.48.11,3.92-.15,5.92-.72-2.08-.2-3.44-.36-5.86-.48-1.31-.04-5.25-.44-8.82-1.17-3.57-.7-6.81-1.47-6.81-1.47,0,0-3.25-.71-6.75-1.69-3.52-.95-7.2-2.4-8.38-2.96-2.2-1.01-3.49-1.49-5.42-2.28Z"
        />
        <path
          style={styles.eleven}
          d="m529.55,647.22c1.48.59,2.5.96,4.35,1.3,1,.19,4.01.72,6.79,1.15,2.77.43,5.3.76,5.3.76,0,0,2.53.34,5.32.64,2.79.31,5.84.57,6.85.65,1.88.14,2.95.06,4.54-.14-1.55-.39-2.6-.62-4.46-.78-1.01-.08-4.05-.34-6.83-.65-2.78-.3-5.3-.63-5.3-.63,0,0-2.52-.33-5.28-.76-2.76-.42-5.77-.96-6.76-1.14-1.84-.33-2.92-.38-4.51-.4Z"
        />
        <path
          style={styles.eleven}
          d="m486,649.77c.9.2,1.65.35,2.44.47.79.16,1.62.25,2.69.37,1.16.12,4.62.6,7.77,1.25,3.15.63,6.02,1.28,6.02,1.28,0,0,2.86.65,6.05,1.25,3.18.63,6.71,1.04,7.89,1.11,1.09.09,1.95.06,2.76.02.81-.08,1.58-.22,2.48-.39-.91-.17-1.66-.29-2.46-.39-.79-.14-1.63-.2-2.7-.29-1.16-.08-4.63-.49-7.78-1.1-3.16-.6-6.02-1.24-6.02-1.24,0,0-2.86-.65-6.04-1.29-3.17-.66-6.69-1.15-7.86-1.26-1.08-.12-1.95-.11-2.76-.1-.81.06-1.58.16-2.49.3Z"
        />
        <path
          style={styles.eleven}
          d="m493.93,639.91c2.28.21,3.83.29,6.51.01,1.45-.15,5.81-.65,9.8-1.2,3.99-.54,7.6-1.12,7.6-1.12,0,0,3.61-.57,7.57-1.29,3.96-.71,8.26-1.59,9.69-1.9,2.64-.57,4.08-1.12,6.18-2.03-2.29.09-3.82.2-6.45.74-1.43.3-5.71,1.17-9.65,1.88-3.94.72-7.54,1.29-7.54,1.29,0,0-3.6.57-7.57,1.11-3.97.55-8.32,1.04-9.76,1.2-2.67.3-4.16.67-6.37,1.29Z"
        />
        <path
          style={styles.eleven}
          d="m532.43,622.46c-1.06,0-1.77.03-3,.25-1.32.25-7.95,1.48-7.95,1.48,0,0-6.62,1.23-7.95,1.48-1.22.23-1.9.46-2.89.85,1.05,0,1.76-.02,2.99-.25,1.33-.25,7.96-1.48,7.96-1.48,0,0,6.63-1.23,7.96-1.48,1.22-.23,1.89-.46,2.88-.84Z"
        />
        <path
          style={styles.eleven}
          d="m526.42,619.52c-.69,0-1.16.01-1.97.15-.87.16-5.23.95-5.23.95,0,0-4.36.79-5.23.95-.8.15-1.25.3-1.89.54.7,0,1.16-.01,1.97-.15.87-.16,5.23-.94,5.23-.94,0,0,4.36-.79,5.23-.94.8-.15,1.25-.3,1.9-.55Z"
        />
        <path
          style={styles.eleven}
          d="m566.23,607.52c-1.69.07-2.83.15-4.78.58-2.1.48-12.63,2.86-12.63,2.86,0,0-10.52,2.39-12.63,2.86-1.94.45-3,.86-4.56,1.53,1.69-.06,2.82-.15,4.77-.58,2.1-.48,12.64-2.87,12.64-2.87,0,0,10.53-2.39,12.64-2.87,1.94-.45,3-.86,4.55-1.53Z"
        />
        <path
          style={styles.eleven}
          d="m569.87,608.65c-.76.11-1.27.2-2.13.47-.46.15-1.86.59-3.15.97-1.29.36-2.47.7-2.47.7,0,0-1.18.3-2.48.64-1.3.32-2.73.64-3.21.75-.88.2-1.36.37-2.08.66.77-.01,1.29-.04,2.17-.23.48-.1,1.91-.42,3.22-.75,1.31-.34,2.49-.64,2.49-.64,0,0,1.18-.33,2.48-.7,1.3-.38,2.7-.82,3.16-.97.86-.28,1.32-.52,1.99-.89Z"
        />
        <path
          style={styles.eleven}
          d="m550.43,636.15c-1.76-.24-2.96-.36-5.05-.25-1.13.06-4.54.27-7.66.62-3.12.33-5.93.85-5.93.85,0,0-2.82.47-5.86,1.32-3.05.85-6.22,2.09-7.29,2.39-1.98.62-3.05,1.06-4.74,1.61,1.77.1,3.03-.02,5.05-.63,1.11-.3,4.29-1.55,7.26-2.37,2.98-.84,5.76-1.31,5.76-1.31,0,0,2.77-.51,5.86-.83,3.09-.34,6.48-.55,7.6-.62,2.08-.13,3.26-.36,4.99-.77Z"
        />
        <path
          style={styles.eleven}
          d="m369.2,614.64c1.47,1.04,2.51,1.71,4.48,2.54,1.07.45,4.29,1.74,7.32,2.76,3.02,1.03,5.81,1.81,5.81,1.81,0,0,2.79.8,5.9,1.5,3.1.72,6.53,1.31,7.68,1.48,2.11.32,3.34.3,5.14.18-1.73-.54-2.89-.87-4.98-1.2-1.13-.18-4.53-.76-7.61-1.47-3.08-.69-5.84-1.48-5.84-1.48,0,0-2.77-.77-5.76-1.8-2.99-1-6.19-2.29-7.25-2.72-1.96-.81-3.12-1.14-4.88-1.58Z"
        />
        <path
          style={styles.eleven}
          d="m418.44,626.72c.94.37,1.58.59,2.74.82,1.26.24,7.56,1.46,7.56,1.46,0,0,6.3,1.21,7.56,1.46,1.16.22,1.84.25,2.85.26-.93-.37-1.57-.59-2.73-.82-1.26-.24-7.57-1.46-7.57-1.46,0,0-6.31-1.22-7.57-1.46-1.16-.22-1.84-.25-2.84-.25Z"
        />
        <path
          style={styles.eleven}
          d="m453,637.21c1.44.63,2.44,1.02,4.26,1.42.99.21,3.96.81,6.7,1.27,2.74.47,5.24.81,5.24.81,0,0,2.5.35,5.26.65,2.76.31,5.78.54,6.79.6,1.86.12,2.92.02,4.49-.2-1.54-.36-2.58-.57-4.42-.7-1-.07-4.01-.3-6.75-.6-2.75-.29-5.24-.64-5.24-.64,0,0-2.49-.34-5.21-.81-2.73-.46-5.68-1.06-6.66-1.26-1.81-.38-2.87-.46-4.45-.53Z"
        />
        <path
          style={styles.eleven}
          d="m380.81,598.92c.47.64,1.14.97,1.76,1.26.64.27,1.32.53,2.21.79.96.29,3.88,1.09,6.59,1.66,2.71.58,5.2.96,5.2.96,0,0,2.49.41,5.24.68,2.76.29,5.78.42,6.78.44,1.86.05,2.91-.12,4.45-.42-1.55-.27-2.59-.42-4.43-.48-1-.02-3.99-.16-6.72-.44-2.73-.27-5.2-.67-5.2-.67,0,0-2.47-.38-5.15-.96-2.69-.57-5.57-1.36-6.53-1.64-.88-.25-1.58-.43-2.26-.56-.67-.14-1.3-.21-1.96-.63Z"
        />
        <path
          style={styles.eleven}
          d="m398.77,608.35c1.44.31,2.41.48,4.15.53.94.01,3.75.06,6.33.1,2.58,0,4.93.01,4.93.01,0,0,2.35-.02,4.93-.04,2.58-.05,5.4-.11,6.33-.13,1.73-.05,2.71-.23,4.14-.55-1.45-.22-2.44-.33-4.16-.29-.94.02-3.75.07-6.32.12-2.58.02-4.92.04-4.92.04,0,0-2.34,0-4.92-.02-2.58-.04-5.39-.08-6.32-.09-1.73-.03-2.71.09-4.16.31Z"
        />
        <path
          style={styles.eleven}
          d="m412.96,611.68c.33.06.56.1.96.1.43,0,2.61,0,2.61,0,0,0,2.17,0,2.61,0,.4,0,.63-.04.96-.1-.33-.06-.56-.1-.96-.1-.43,0-2.61,0-2.61,0,0,0-2.17,0-2.61,0-.4,0-.63.04-.96.1Z"
        />
        <path
          style={styles.eleven}
          d="m403.65,598.54c1.14.45,1.93.72,3.36.97.77.14,3.11.51,5.26.78,2.15.27,4.11.45,4.11.45,0,0,1.96.19,4.12.32,2.16.14,4.52.2,5.31.21,1.45.02,2.28-.1,3.49-.33-1.21-.22-2.03-.34-3.47-.38-.78-.02-3.13-.08-5.28-.22-2.15-.13-4.1-.31-4.1-.31,0,0-1.95-.18-4.09-.45-2.14-.27-4.46-.64-5.23-.77-1.42-.24-2.25-.27-3.48-.28Z"
        />
        <path
          style={styles.eleven}
          d="m473.02,611.88c-1.15-.37-1.94-.58-3.35-.77-.76-.11-3.05-.43-5.15-.73-2.1-.33-4-.62-4-.62,0,0-1.9-.32-3.99-.68-2.09-.38-4.36-.8-5.12-.94-1.4-.27-2.21-.31-3.42-.33,1.12.45,1.89.73,3.29,1.01.76.15,3.03.58,5.13.95,2.1.35,4,.68,4,.68,0,0,1.91.3,4.01.63,2.1.32,4.4.63,5.17.72,1.41.18,2.23.16,3.44.09Z"
        />
        <path
          style={styles.eleven}
          d="m469.79,612.47c-.57-.12-.95-.19-1.63-.21-.74-.02-4.43-.12-4.43-.12,0,0-3.69-.11-4.43-.12-.68-.02-1.07.03-1.64.12.57.12.95.19,1.63.21.74.02,4.43.12,4.43.12,0,0,3.69.11,4.43.12.68.02,1.07-.03,1.64-.12Z"
        />
        <path
          style={styles.eleven}
          d="m351.32,616.87c.52,1.93,1.12,3.28,2.77,5.08.46.48,1.38,1.43,2.67,2.39,1.28.97,2.9,1.95,4.54,2.71,3.27,1.56,6.49,2.21,6.49,2.21,0,0,.8.2,2.03.41,1.23.2,2.9.47,4.64.75,1.73.29,3.51.67,4.95,1.16,1.45.48,2.55,1.05,3.09,1.36.99.57,1.72,1.06,2.41,1.56.68.51,1.33,1.02,2.02,1.75-.79-1.84-1.76-3.05-3.86-4.32-.57-.34-1.76-.95-3.29-1.46-1.53-.52-3.37-.91-5.12-1.2-1.74-.28-3.41-.54-4.63-.74-1.22-.21-1.98-.39-1.98-.39,0,0-.77-.16-1.95-.5-1.17-.35-2.74-.89-4.3-1.63-1.57-.73-3.12-1.67-4.33-2.59-1.23-.91-2.1-1.81-2.53-2.25-1.56-1.68-2.42-2.66-3.61-4.28Z"
        />
        <path
          style={styles.eleven}
          d="m396.72,651.7c2.75.56,4.63.88,7.94.94,3.59.06,21.59.37,21.59.37,0,0,17.99.31,21.59.37,3.32.04,5.2-.2,7.97-.67-2.77-.56-4.65-.88-7.96-.95-3.59-.06-21.57-.37-21.57-.37,0,0-17.98-.31-21.57-.37-3.31-.04-5.2.2-7.99.67Z"
        />
        <path
          style={styles.eleven}
          d="m347.81,629.78c.54,1.04,1,1.91,1.56,2.8.61.84,1.26,1.73,2.22,2.75,1.03,1.11,4.3,4.3,7.73,6.69.85.61,1.68,1.19,2.49,1.68.8.49,1.52.98,2.17,1.32,1.29.71,2.12,1.17,2.12,1.17,0,0,.85.41,2.18,1.05.65.34,1.47.64,2.33,1.01.86.38,1.82.72,2.81,1.06,3.93,1.41,8.43,2.23,9.93,2.42,1.38.2,2.49.21,3.53.22,1.04-.06,2.03-.19,3.18-.35-1.15-.27-2.1-.47-3.11-.64-1-.23-2.06-.36-3.41-.57-1.46-.2-5.84-1-9.68-2.37-.96-.33-1.9-.66-2.74-1.03-.85-.36-1.64-.65-2.28-.98-1.29-.62-2.13-1.02-2.13-1.02,0,0-.81-.45-2.06-1.14-.64-.33-1.34-.8-2.12-1.29-.79-.47-1.6-1.04-2.43-1.64-3.34-2.33-6.53-5.44-7.55-6.51-.95-.98-1.67-1.77-2.44-2.45-.72-.72-1.43-1.38-2.32-2.16Z"
        />
        <path
          style={styles.eleven}
          d="m342.89,650.54c-.44-.59-.76-.99-1.43-1.56-.36-.31-1.47-1.21-2.54-1.96-1.07-.76-2.08-1.38-2.08-1.38,0,0-1.01-.63-2.17-1.24-1.15-.62-2.46-1.2-2.89-1.39-.81-.34-1.3-.45-2.03-.58.64.38,1.07.63,1.86.97.43.18,1.72.76,2.86,1.37,1.14.6,2.14,1.22,2.14,1.22,0,0,1,.61,2.06,1.37,1.06.74,2.15,1.64,2.51,1.94.66.56,1.07.83,1.7,1.23Z"
        />
        <path
          style={styles.eleven}
          d="m348.01,618.98c-.52-.22-.88-.36-1.54-.49-.35-.07-1.42-.28-2.41-.44-.99-.15-1.88-.28-1.88-.28,0,0-.9-.11-1.89-.23-.99-.11-2.07-.2-2.43-.22-.67-.05-1.05-.01-1.61.06.55.13.92.21,1.59.26.36.03,1.44.11,2.42.22.98.12,1.88.23,1.88.23,0,0,.89.13,1.87.28.98.16,2.04.36,2.39.43.65.13,1.03.15,1.6.17Z"
        />
        <path
          style={styles.eleven}
          d="m354.85,614.11c-1.02-.2-1.72-.3-2.95-.29-.66,0-2.66.04-4.49.14-1.83.09-3.49.22-3.49.22,0,0-1.66.13-3.48.32-1.82.19-3.8.46-4.46.56-1.21.18-1.89.39-2.87.75,1.04.02,1.74.01,2.95-.16.66-.09,2.62-.36,4.44-.55,1.81-.2,3.46-.32,3.46-.32,0,0,1.65-.13,3.47-.22,1.82-.09,3.81-.13,4.47-.14,1.22-.02,1.91-.12,2.94-.3Z"
        />
        <path
          style={styles.eleven}
          d="m297.53,661.07c2.1.26,3.51.38,5.99.28,1.34-.05,5.37-.2,9.07-.22,3.7-.03,7.06.04,7.06.04,0,0,3.36.07,7.06.25,3.7.17,7.72.48,9.06.59,2.47.2,3.89.13,6-.04-2.02-.6-3.41-.95-5.9-1.17-1.34-.12-5.39-.43-9.1-.6-3.71-.18-7.09-.24-7.09-.24,0,0-3.38-.08-7.1-.05-3.72.02-7.77.16-9.12.23-2.49.12-3.89.41-5.94.93Z"
        />
        <path
          style={styles.eleven}
          d="m293.8,678.49c.74.18,1.25.29,2.15.34.49.02,1.95.08,3.3.14,1.34.04,2.57.08,2.57.08,0,0,1.22.03,2.57.05,1.34.01,2.81.02,3.3.03.9,0,1.41-.08,2.16-.23-.75-.14-1.26-.21-2.16-.21-.49,0-1.95-.02-3.29-.03-1.34-.03-2.56-.05-2.56-.05,0,0-1.22-.04-2.56-.08-1.34-.06-2.8-.12-3.29-.14-.9-.04-1.41,0-2.17.1Z"
        />
        <path
          style={styles.eleven}
          d="m298.5,672.91c2.05.36,3.44.56,5.89.56,1.33,0,5.31.02,8.96.03,3.65.04,6.97.08,6.97.08,0,0,3.32.07,6.97.15,3.65.11,7.63.24,8.95.28,2.45.08,3.84-.07,5.9-.36-2.03-.47-3.41-.73-5.86-.84-1.33-.04-5.31-.17-8.96-.28-3.65-.08-6.98-.15-6.98-.15,0,0-3.32-.04-6.98-.09-3.66,0-7.64-.02-8.97-.02-2.45.02-3.84.24-5.88.64Z"
        />
        <path
          style={styles.eleven}
          d="m423.83,695c-1.82-.91-3.35-1.66-5.02-2.34-1.68-.64-3.47-1.25-5.8-1.84-2.52-.65-10.15-2.46-17.2-3.76-3.52-.68-6.9-1.23-9.39-1.64-2.49-.39-4.1-.65-4.1-.65,0,0-6.44-.99-13.55-1.88-7.1-.91-14.86-1.75-17.45-2.02-4.77-.5-7.52-.38-11.58-.05,3.91,1.16,6.57,1.84,11.33,2.38,2.58.29,10.32,1.13,17.4,2.03,7.08.88,13.49,1.86,13.49,1.86,0,0,1.6.26,4.08.65,2.48.41,5.83.97,9.33,1.64,7.01,1.3,14.57,3.08,17.07,3.71,2.32.57,4.14.97,5.91,1.23,1.76.3,3.45.49,5.48.69Z"
        />
        <path
          style={styles.eleven}
          d="m488.68,694.42c1.75.4,2.94.62,5.04.71,2.28.1,13.72.53,13.72.53,0,0,11.43.45,13.72.53,2.11.07,3.31-.06,5.08-.32-1.75-.4-2.94-.62-5.04-.71-2.28-.1-13.72-.53-13.72-.53,0,0-11.43-.45-13.72-.53-2.11-.07-3.31.06-5.08.32Z"
        />
        <path
          style={styles.eleven}
          d="m508.22,707.16c1.47.52,2.48.83,4.29,1.09.98.14,3.94.53,6.67.82,2.72.29,5.2.49,5.2.49,0,0,2.48.21,5.21.37,2.73.17,5.72.27,6.71.29,1.83.04,2.88-.1,4.41-.36-1.53-.3-2.56-.47-4.39-.53-.99-.03-3.96-.13-6.68-.3-2.72-.16-5.19-.36-5.19-.36,0,0-2.47-.2-5.18-.5-2.71-.29-5.66-.68-6.64-.81-1.81-.24-2.86-.25-4.41-.2Z"
        />
        <path
          style={styles.eleven}
          d="m493.93,711.99c1.97.6,3.64,1.09,5.42,1.49,1.78.36,3.67.67,6.11.88,2.63.24,10.56.81,17.84.97,3.64.1,7.12.1,9.68.08,2.56-.03,4.22-.05,4.22-.05,0,0,1.65-.05,4.22-.12,2.56-.09,6.04-.23,9.67-.49,7.27-.46,15.16-1.37,17.78-1.72,2.42-.31,4.3-.7,6.06-1.14,1.76-.47,3.4-1.03,5.35-1.72-2.07-.06-3.79-.08-5.6,0-1.81.04-3.7.22-6.11.51-2.6.33-10.43,1.23-17.64,1.69-3.6.26-7.05.4-9.59.5-2.54.08-4.19.13-4.19.13,0,0-1.64.02-4.19.05-2.54.01-5.99.01-9.6-.09-7.22-.16-15.09-.73-17.7-.94-2.41-.19-4.31-.29-6.12-.25-1.81,0-3.53.09-5.6.24Z"
        />
        <path
          style={styles.eleven}
          d="m458.34,704.94c.67.17,1.22.3,1.8.4.58.14,1.2.23,1.98.36.85.12,3.4.61,5.71,1.25,2.31.62,4.39,1.26,4.39,1.26,0,0,2.09.64,4.41,1.26,2.32.64,4.92,1.13,5.8,1.25.8.12,1.45.14,2.05.15.61-.03,1.18-.09,1.86-.18-.67-.17-1.22-.29-1.8-.4-.58-.14-1.2-.23-1.98-.35-.85-.12-3.4-.6-5.71-1.24-2.31-.62-4.39-1.25-4.39-1.25,0,0-2.09-.64-4.41-1.27-2.32-.65-4.92-1.15-5.79-1.27-.8-.12-1.44-.14-2.05-.16-.61.02-1.18.09-1.86.17Z"
        />
        <path
          style={styles.eleven}
          d="m614.67,685.49c-1.22-.22-2.05-.33-3.51-.3-.79.02-3.16.09-5.34.23-2.17.13-4.14.3-4.14.3,0,0-1.97.17-4.14.41-2.16.24-4.51.57-5.3.69-1.44.22-2.24.47-3.4.9,1.24.02,2.07.01,3.51-.19.78-.11,3.12-.44,5.27-.68,2.15-.25,4.12-.41,4.12-.41,0,0,1.96-.17,4.13-.3,2.16-.13,4.53-.21,5.31-.23,1.45-.04,2.27-.17,3.49-.41Z"
        />
        <path
          style={styles.eleven}
          d="m550.09,686.24c1.53.5,2.58.8,4.46,1.02,1.02.12,4.1.43,6.92.6,2.83.18,5.4.25,5.4.25,0,0,2.57.08,5.41.06,2.83,0,5.92-.14,6.95-.2,1.9-.11,2.96-.35,4.52-.76-1.6-.17-2.68-.25-4.56-.16-1.02.05-4.09.18-6.91.19-2.82.02-5.38-.05-5.38-.05,0,0-2.56-.07-5.37-.26-2.81-.17-5.87-.48-6.88-.59-1.88-.2-2.96-.18-4.56-.1Z"
        />
        <path
          style={styles.eleven}
          d="m629.52,664.14c-2.26.68-3.76,1.1-6.35,1.95-.7.23-2.12.67-3.88,1.08-1.76.4-3.87.76-5.91.97-2.03.22-3.99.3-5.43.32-.72.02-1.32,0-1.73,0-.41,0-.65,0-.65,0,0,0-3.73-.07-7.82-.56-4.09-.46-8.53-1.26-10-1.52-2.73-.49-4.32-.56-6.68-.58,2.2.87,3.7,1.4,6.44,1.91,1.48.27,5.95,1.08,10.09,1.54,4.14.49,7.95.56,7.95.56,0,0,.24,0,.66,0,.42,0,1.03.01,1.77,0,1.47-.02,3.47-.1,5.56-.32,2.09-.22,4.25-.58,6.07-1,1.82-.42,3.28-.88,4-1.12,2.67-.91,4.07-1.79,5.93-3.24Z"
        />
        <path
          style={styles.eleven}
          d="m631.37,640.64c-1.07.94-1.77,1.58-2.89,2.8-.6.66-2.48,2.62-4.38,4.21-.94.81-1.88,1.52-2.58,2.05-.71.51-1.16.84-1.16.84l-1.2.79c-.73.47-1.73,1.1-2.82,1.69-2.16,1.22-4.64,2.29-5.48,2.62-1.54.62-2.38,1.05-3.64,1.72,1.41-.18,2.37-.35,3.94-.96.85-.33,3.38-1.42,5.57-2.66,1.11-.6,2.12-1.25,2.87-1.73.74-.49,1.21-.81,1.21-.81,0,0,.46-.33,1.18-.85.71-.53,1.67-1.25,2.62-2.08,1.93-1.61,3.84-3.6,4.45-4.28,1.13-1.25,1.63-2.09,2.29-3.35Z"
        />
        <path
          style={styles.eleven}
          d="m665.96,712.52c-.51-1.05-1.09-1.89-1.8-2.67-.72-.78-1.6-1.5-2.84-2.21-.67-.38-2.04-1.09-3.8-1.72-1.75-.63-3.87-1.2-5.91-1.59-2.03-.43-3.99-.71-5.44-.9-1.45-.18-2.39-.27-2.39-.27,0,0-3.75-.39-7.88-.52-4.13-.14-8.62-.13-10.11-.13-2.76,0-4.32.23-6.63.64,2.3.45,3.87.69,6.62.7,1.49.01,5.97,0,10.07.14,4.09.13,7.79.5,7.79.5,0,0,.92.09,2.35.27,1.42.18,3.35.47,5.34.89,2,.39,4.04.94,5.72,1.54,1.68.6,2.97,1.27,3.6,1.62,1.16.65,2.04,1.21,2.85,1.78.81.57,1.59,1.13,2.45,1.93Z"
        />
        <path
          style={styles.eleven}
          d="m661.62,690.97c-1.41-.1-2.37-.12-4.03.06-1.8.2-10.78,1.27-10.78,1.27,0,0-8.99,1.05-10.78,1.27-1.66.2-2.58.45-3.93.87,1.41.1,2.37.12,4.03-.06,1.8-.2,10.78-1.27,10.78-1.27,0,0,8.99-1.05,10.78-1.27,1.66-.2,2.58-.45,3.93-.87Z"
        />
        <path
          style={styles.eleven}
          d="m653.22,688.03c-1.02-.15-1.71-.22-2.92-.17-.66.04-2.62.15-4.43.25-1.8.13-3.44.25-3.44.25,0,0-1.64.14-3.44.29-1.8.18-3.76.37-4.42.44-1.21.13-1.88.31-2.86.62,1.03.07,1.72.09,2.92-.03.65-.06,2.61-.26,4.41-.43,1.8-.15,3.43-.29,3.43-.29,0,0,1.64-.12,3.44-.24,1.8-.1,3.77-.22,4.42-.26,1.21-.06,1.89-.19,2.89-.42Z"
        />
        <path
          style={styles.eleven}
          d="m667.47,720.15c-.94-.61-1.59-.99-2.81-1.48-.66-.26-2.67-1.03-4.55-1.61-1.88-.6-3.64-.97-3.64-.97,0,0-1.75-.41-3.73-.61-1.97-.23-4.14-.14-4.86-.06-1.33.12-2.06.37-3.09.81,1.12-.04,1.85-.06,3.14-.18.7-.07,2.81-.16,4.74.06,1.93.19,3.65.59,3.65.59,0,0,1.73.37,3.58.96,1.86.58,3.85,1.34,4.51,1.6,1.22.47,1.95.65,3.04.88Z"
        />
        <path
          style={styles.eleven}
          d="m671.01,724.38c-1.38-.42-2.32-.68-4.01-.87-.92-.09-3.66-.38-6.18-.64-2.52-.23-4.81-.45-4.81-.45,0,0-2.29-.19-4.82-.41-2.53-.19-5.28-.39-6.2-.46-1.69-.11-2.67-.03-4.1.15,1.4.35,2.35.56,4.04.68.92.07,3.67.28,6.19.46,2.52.21,4.81.4,4.81.4,0,0,2.29.21,4.81.45,2.52.26,5.26.54,6.17.63,1.69.18,2.66.14,4.1.04Z"
        />
        <path
          style={styles.eleven}
          d="m631.48,724c1.88.73,3.16,1.18,5.48,1.65,1.26.26,5.03,1.03,8.49,1.74,3.45.75,6.59,1.43,6.59,1.43,0,0,3.13.72,6.57,1.5,3.43.81,7.21,1.71,8.4,2.03,1.08.31,1.84.66,2.4,1.17.54.53,1.04,1.23.9,2.24.53-.9.3-1.99-.25-2.79-.58-.84-1.55-1.39-2.73-1.74-1.28-.36-5-1.25-8.46-2.06-3.45-.79-6.59-1.5-6.59-1.5,0,0-3.14-.69-6.6-1.44-3.46-.74-7.25-1.5-8.51-1.74-2.33-.45-3.69-.51-5.7-.51Z"
        />
        <path
          style={styles.eleven}
          d="m651.67,748.64c-2.04-.15-3.29-.52-5.61-.99-1.24-.27-5.08-1.19-8.63-1.8-3.55-.63-6.8-1.02-6.8-1.02,0,0-3.25-.43-6.84-.67-3.59-.27-7.52-.35-8.83-.34-2.41,0-3.78.24-5.78.67,2.02.31,3.38.47,5.77.5,1.3,0,5.19.08,8.75.35,3.56.24,6.78.66,6.78.66,0,0,3.22.39,6.74,1.02,3.52.61,7.29,1.51,8.59,1.78,2.38.47,3.89.45,5.85-.15Z"
        />
        <path
          style={styles.eleven}
          d="m641.49,753.14c-1.98-.59-3.33-.94-5.76-1.17-1.31-.13-5.26-.46-8.89-.67-3.63-.22-6.93-.34-6.93-.34,0,0-3.3-.13-6.93-.17-3.63-.06-7.6-.01-8.91.02-2.44.05-3.81.3-5.83.75,2.04.31,3.42.47,5.85.44,1.31-.02,5.26-.06,8.88-.01,3.62.04,6.91.17,6.91.17,0,0,3.29.12,6.9.34,3.61.21,7.54.55,8.85.66,2.42.21,3.8.15,5.86,0Z"
        />
        <path
          style={styles.eleven}
          d="m492.96,752.61c1.98.6,3.33.95,5.76,1.19,1.31.13,5.26.49,8.88.74,3.62.26,6.92.42,6.92.42,0,0,3.3.18,6.93.29,3.63.12,7.59.17,8.91.17,2.44,0,3.82-.2,5.85-.6-2.03-.37-3.41-.57-5.84-.59-1.31-.01-5.26-.06-8.88-.18-3.62-.11-6.91-.28-6.91-.28,0,0-3.29-.17-6.9-.43-3.61-.25-7.55-.61-8.86-.73-2.42-.22-3.81-.17-5.87-.01Z"
        />
        <path
          style={styles.eleven}
          d="m506.09,746.96c.93.27,1.56.43,2.69.55,1.22.13,7.36.74,7.36.74,0,0,6.13.62,7.36.74,1.13.11,1.78.08,2.74,0-.93-.27-1.56-.43-2.69-.55-1.22-.13-7.36-.74-7.36-.74,0,0-6.13-.62-7.36-.74-1.13-.11-1.78-.08-2.74,0Z"
        />
        <path
          style={styles.eleven}
          d="m511.17,762.73c1.38.31,2.31.49,3.97.55,1.8.07,10.79.4,10.79.4,0,0,8.99.34,10.79.4,1.66.06,2.6-.05,4-.26-1.38-.31-2.31-.49-3.97-.55-1.8-.07-10.79-.4-10.79-.4,0,0-8.99-.34-10.79-.4-1.66-.06-2.6.05-4,.26Z"
        />
        <path
          style={styles.eleven}
          d="m504.57,731.74c1.92.92,3.35,1.4,5.89,1.59.69.05,2.07.11,3.75.05,1.68-.07,3.65-.26,5.52-.55,1.87-.28,3.63-.65,4.92-.93,1.29-.29,2.12-.48,2.12-.48,0,0,3.3-.81,6.93-1.7,1.81-.44,3.71-.86,5.32-1.16,1.61-.3,2.94-.49,3.61-.58,2.46-.31,3.83-.6,5.93-.98-2.09-.4-3.57-.53-6.08-.23-.68.08-2.04.28-3.68.58-1.64.31-3.56.74-5.38,1.18-3.63.89-6.93,1.71-6.93,1.71,0,0-.82.19-2.1.48-1.28.28-3.02.63-4.85.91-1.83.28-3.75.47-5.38.54-1.63.06-2.96,0-3.63-.04-2.45-.16-3.84-.2-5.97-.37Z"
        />
        <path
          style={styles.eleven}
          d="m507.43,728.1c-1.05-.55-1.78-.89-3.12-1.26-.73-.2-2.93-.76-4.98-1.16-2.04-.41-3.92-.68-3.92-.68,0,0-1.87-.28-3.95-.48-2.07-.21-4.35-.31-5.1-.33-1.4-.04-2.19.08-3.35.3,1.16.21,1.95.33,3.33.38.75.02,3,.12,5.06.33,2.06.19,3.92.47,3.92.47,0,0,1.86.27,3.89.68,2.03.4,4.21.95,4.94,1.15,1.34.36,2.12.47,3.3.6Z"
        />
        <path
          style={styles.eleven}
          d="m486.91,734.5c3.09.59,5.12,1.04,8.79,1.42,1.98.17,7.93,1.03,13.5,1.47,2.78.22,5.46.26,7.44.17.99-.02,1.8-.11,2.37-.15.57-.05.89-.08.89-.08,0,0,.32-.04.88-.11.57-.07,1.37-.18,2.35-.36,1.95-.34,4.55-.93,7.23-1.65,2.68-.71,5.45-1.49,7.81-2.07,2.36-.58,4.3-.95,5.27-1.1,3.59-.57,5.59-.98,8.72-1.35-3.04-.79-5.29-1-9.01-.43-1.01.15-3.02.53-5.41,1.12-2.4.59-5.18,1.38-7.84,2.08-2.66.71-5.2,1.29-7.08,1.62-.94.18-1.72.29-2.26.35-.54.07-.85.11-.85.11,0,0-.31.03-.85.08-.54.04-1.32.12-2.28.14-1.91.08-4.51.04-7.23-.17-5.45-.44-11.44-1.3-13.45-1.46-3.7-.36-5.88-.19-8.97.38Z"
        />
        <path
          style={styles.eleven}
          d="m402.28,711.61c2.16,2.17,3.79,3.54,7.04,5.22.88.45,2.66,1.32,4.89,2.24,2.23.92,4.9,1.88,7.5,2.68,2.59.8,5.1,1.45,6.95,1.89,1.85.45,3.06.7,3.06.7,0,0,4.81,1.05,10.12,1.93,5.3.88,11.09,1.84,13.01,2.16,3.55.6,5.6.68,8.66.75-2.83-1.17-4.8-1.85-8.36-2.48-1.93-.32-7.71-1.29-13.02-2.17-5.29-.88-10.05-1.91-10.05-1.91,0,0-1.19-.25-3.02-.7-1.83-.44-4.3-1.08-6.84-1.87-2.55-.78-5.17-1.73-7.34-2.62-2.18-.9-3.91-1.74-4.77-2.18-3.17-1.6-5.03-2.4-7.83-3.64Z"
        />
        <path
          style={styles.eleven}
          d="m369.14,716.01c-1.41-.38-2.37-.61-4.08-.74-.93-.07-3.71-.26-6.27-.4-2.56-.12-4.88-.22-4.88-.22,0,0-2.33-.07-4.89-.16-2.56-.07-5.35-.09-6.28-.09-1.72,0-2.69.15-4.12.42,1.43.26,2.41.4,4.12.42.93.01,3.71.06,6.27.1,2.55.08,4.87.15,4.87.15,0,0,2.32.11,4.87.23,2.55.16,5.33.33,6.26.39,1.71.12,2.69.05,4.14-.1Z"
        />
        <path
          style={styles.eleven}
          d="m330.4,704.37c1.54.13,2.58.18,4.4,0,1.96-.19,11.78-1.2,11.78-1.2,0,0,9.82-1,11.78-1.2,1.81-.19,2.82-.45,4.31-.89-1.54-.13-2.58-.18-4.4,0-1.96.19-11.78,1.2-11.78,1.2,0,0-9.82,1-11.78,1.2-1.81.19-2.82.45-4.31.89Z"
        />
        <path
          style={styles.eleven}
          d="m285.72,710.22c.97-.16,1.62-.29,2.72-.65.59-.2,2.38-.77,4.04-1.24,1.66-.47,3.18-.84,3.18-.84,0,0,1.52-.37,3.21-.71,1.69-.35,3.54-.66,4.16-.75,1.14-.18,1.78-.36,2.71-.67-.98-.05-1.65-.06-2.8.11-.62.09-2.49.4-4.19.75-1.7.35-3.23.72-3.23.72,0,0-1.53.37-3.2.84-1.67.46-3.47,1.05-4.07,1.25-1.1.37-1.69.69-2.54,1.19Z"
        />
        <path
          style={styles.eleven}
          d="m289.32,721.99c.77-.09,1.3-.16,2.17-.42.95-.28,5.71-1.73,5.71-1.73,0,0,4.76-1.44,5.71-1.73.88-.27,1.35-.49,2.04-.85-.77.09-1.29.16-2.17.42-.95.28-5.71,1.73-5.71,1.73,0,0-4.76,1.44-5.71,1.73-.88.27-1.35.49-2.04.85Z"
        />
        <path
          style={styles.eleven}
          d="m285.8,732.98c1.14,0,1.95-.15,3.2-.69.69-.28,2.62-1.31,4.41-2.19,1.79-.88,3.45-1.62,3.45-1.62,0,0,1.66-.74,3.51-1.5,1.85-.76,3.89-1.51,4.58-1.75,1.26-.46,1.94-.8,2.94-1.35-1.13.15-1.89.29-3.16.74-.69.24-2.75,1-4.6,1.76-1.86.76-3.53,1.51-3.53,1.51,0,0-1.67.74-3.48,1.62-1.81.88-3.74,1.91-4.39,2.18-1.21.53-1.86.88-2.93,1.29Z"
        />
        <path
          style={styles.eleven}
          d="m302.18,714.37c.86-.07,1.44-.13,2.43-.37.54-.13,2.14-.52,3.63-.84,1.49-.3,2.84-.58,2.84-.58,0,0,1.36-.24,2.85-.5,1.5-.25,3.14-.47,3.68-.54,1.01-.14,1.57-.29,2.4-.54-.86-.06-1.45-.08-2.47.05-.55.07-2.2.29-3.7.54-1.5.26-2.87.51-2.87.51,0,0-1.36.27-2.85.58-1.49.32-3.11.71-3.64.84-.99.25-1.53.48-2.31.86Z"
        />
        <path
          style={styles.eleven}
          d="m451.9,709.55c-1.49-.57-2.52-.91-4.37-1.19-1.01-.16-4.04-.58-6.83-.83-2.79-.27-5.34-.39-5.34-.39,0,0-2.55-.14-5.35-.17-2.81-.04-5.87.06-6.88.11-1.88.09-2.94.32-4.48.73,1.58.18,2.65.26,4.51.19,1.01-.04,4.05-.14,6.83-.11,2.79.02,5.32.16,5.32.16,0,0,2.53.13,5.3.4,2.77.25,5.78.67,6.78.82,1.85.27,2.92.3,4.51.29Z"
        />
        <path
          style={styles.eleven}
          d="m469.49,750.43c-3.64-.94-6.02-1.63-10.33-2.44-1.17-.23-3.49-.7-6.33-1.38-2.83-.67-6.17-1.52-9.36-2.35-6.38-1.65-12.15-3.28-12.15-3.28,0,0-5.77-1.61-12.1-3.48-6.32-1.89-13.21-3.95-15.51-4.63-4.24-1.27-6.75-1.65-10.48-2.07,3.33,1.74,5.63,2.83,9.86,4.14,2.29.7,9.18,2.8,15.52,4.65,6.34,1.86,12.13,3.47,12.13,3.47,0,0,5.78,1.64,12.18,3.3,3.2.83,6.55,1.68,9.41,2.36,2.86.68,5.23,1.16,6.42,1.38,4.39.8,7,.76,10.74.32Z"
        />
        <path
          style={styles.eleven}
          d="m389.34,731.05c-2.56-.8-4.33-1.26-7.49-1.53-1.71-.15-6.86-.48-11.59-.56-4.73-.1-9.02-.03-9.02-.03,0,0-4.29.05-9.01.24-4.72.17-9.85.46-11.56.56-3.16.19-4.93.56-7.53,1.21,2.66.34,4.47.49,7.62.33,1.71-.09,6.83-.37,11.53-.55,4.7-.19,8.97-.25,8.97-.25,0,0,4.27-.07,8.97.04,4.7.08,9.8.41,11.5.55,3.14.24,4.93.17,7.61,0Z"
        />
        <path
          style={styles.eleven}
          d="m505.43,777.72c-1.23-.62-2.08-1.01-3.64-1.43-.85-.23-3.4-.88-5.77-1.39-2.36-.52-4.53-.91-4.53-.91,0,0-2.16-.4-4.56-.75-2.39-.36-5.02-.65-5.89-.73-1.61-.16-2.55-.09-3.91.05,1.33.36,2.23.57,3.83.73.87.09,3.48.38,5.86.73,2.38.35,4.53.74,4.53.74,0,0,2.15.39,4.5.91,2.35.51,4.89,1.16,5.74,1.38,1.56.41,2.47.53,3.84.67Z"
        />
        <path
          style={styles.eleven}
          d="m400.84,763.64c1.64.02,2.7.13,4.57.32.51.05,1.52.2,2.73.5,1.21.3,2.62.78,3.94,1.32,1.32.53,2.56,1.12,3.46,1.56.91.45,1.49.74,1.49.74,0,0,.59.29,1.49.75.91.45,2.15,1.07,3.47,1.66,1.33.59,2.75,1.15,3.99,1.54,1.24.39,2.29.61,2.81.71,1.94.34,3.12.21,4.71-.2-1.63-.19-2.68-.39-4.53-.73-.5-.09-1.5-.31-2.7-.68-1.2-.37-2.58-.92-3.89-1.5-1.3-.58-2.53-1.19-3.44-1.64-.91-.45-1.49-.75-1.49-.75,0,0-.59-.29-1.5-.74-.92-.45-2.17-1.04-3.52-1.59-1.35-.55-2.8-1.04-4.06-1.36-1.26-.31-2.33-.47-2.86-.52-1.97-.19-3.14.05-4.67.62Z"
        />
        <path
          style={styles.eleven}
          d="m451.89,758.3c-1.36-.36-2.29-.58-3.94-.72-.89-.08-3.58-.33-6.04-.56-2.46-.26-4.69-.49-4.69-.49,0,0-2.23-.26-4.68-.55-2.45-.32-5.12-.66-6.01-.78-1.64-.22-2.59-.22-4-.16,1.33.45,2.25.73,3.89.96.89.13,3.57.49,6.02.78,2.46.29,4.69.55,4.69.55,0,0,2.24.24,4.7.5,2.46.25,5.15.48,6.05.56,1.65.13,2.6.07,4-.08Z"
        />
        <path
          style={styles.eleven}
          d="m378.72,754.79c-2.2-.38-3.69-.58-6.32-.55-1.42.03-5.7.11-9.61.19-3.92.11-7.48.2-7.48.2,0,0-3.56.11-7.48.24-3.92.15-8.19.32-9.61.38-2.62.12-4.1.41-6.28.93,2.21.31,3.71.46,6.33.36,1.42-.05,5.69-.22,9.6-.37,3.91-.13,7.47-.25,7.47-.25,0,0,3.56-.09,7.47-.2,3.91-.08,8.18-.17,9.6-.2,2.62-.05,4.11-.29,6.3-.73Z"
        />
        <path
          style={styles.eleven}
          d="m335.74,786.38c1.61.64,2.97,1.17,4.44,1.62,1.48.4,3.05.79,5.09,1.08,2.2.34,8.85,1.16,14.98,1.44,1.53.08,3.03.15,4.41.18,1.38.03,2.65.08,3.73.07,2.16,0,3.55.01,3.55.01,0,0,1.39-.02,3.55-.05,2.16-.05,5.08-.13,8.13-.31,6.11-.31,12.76-.94,14.96-1.16,4.08-.43,6.35-1.04,9.67-2.07-3.47-.23-5.8-.31-9.86.08-2.2.21-8.8.83-14.87,1.15-3.03.18-5.93.26-8.07.31-2.14.04-3.52.06-3.52.06,0,0-1.38,0-3.52-.02-1.07,0-2.33-.04-3.7-.08-1.37-.02-2.85-.09-4.36-.18-6.06-.28-12.62-1.09-14.8-1.41-2.01-.28-3.59-.48-5.11-.55-1.51-.12-2.96-.16-4.7-.18Z"
        />
        <path
          style={styles.eleven}
          d="m350.16,797.54c1.22.05,2.04.06,3.46-.13.77-.1,3.08-.39,5.2-.65,2.12-.24,4.06-.46,4.06-.46,0,0,1.93-.19,4.06-.4,2.13-.18,4.45-.38,5.23-.44,1.43-.11,2.23-.29,3.41-.59-1.21-.14-2.03-.21-3.46-.11-.78.06-3.11.24-5.24.44-2.13.21-4.07.4-4.07.4,0,0-1.94.22-4.06.45-2.13.25-4.44.55-5.21.66-1.42.2-2.21.43-3.37.82Z"
        />
        <path
          style={styles.eleven}
          d="m391.82,795.09c1.67.04,2.79.02,4.73-.28,1.05-.16,4.2-.64,7.1-1.08,2.9-.42,5.53-.8,5.53-.8,0,0,2.64-.36,5.53-.75,2.9-.37,6.07-.78,7.12-.92,1.95-.25,3.03-.54,4.62-1.04-1.66-.11-2.79-.14-4.74.09-1.06.14-4.22.54-7.13.91-2.9.4-5.54.76-5.54.76,0,0-2.64.38-5.54.8-2.9.44-6.05.93-7.11,1.09-1.94.31-3.01.66-4.58,1.22Z"
        />
        <path
          style={styles.eleven}
          d="m465.98,787.84c-1.38-.21-2.32-.31-3.97-.26-1.78.06-10.72.38-10.72.38,0,0-8.93.31-10.72.38-1.65.06-2.58.24-3.94.54,1.38.21,2.32.31,3.97.26,1.78-.06,10.72-.38,10.72-.38,0,0,8.93-.31,10.72-.38,1.65-.06,2.58-.24,3.94-.54Z"
        />
        <path
          style={styles.eleven}
          d="m474.94,792.09c1.63-.37,2.71-.65,4.52-1.36.98-.39,3.95-1.52,6.72-2.43,2.76-.92,5.31-1.64,5.31-1.64,0,0,2.55-.73,5.38-1.39,2.83-.68,5.95-1.27,6.99-1.46,1.92-.35,2.99-.68,4.56-1.21-1.66-.07-2.79-.06-4.73.27-1.05.18-4.19.78-7.05,1.46-2.86.67-5.42,1.41-5.42,1.41,0,0-2.57.72-5.35,1.64-2.79.91-5.78,2.05-6.77,2.45-1.83.73-2.79,1.32-4.17,2.25Z"
        />
        <path
          style={styles.eleven}
          d="m524.89,782.67c.97.39,1.66.63,2.89.8.67.1,2.68.31,4.53.45,1.84.15,3.51.29,3.51.29,0,0,1.67.14,3.48.43,1.82.27,3.73.82,4.35,1.05.58.19,1.03.36,1.47.47.43.14.85.27,1.36.4-.42-.31-.78-.57-1.19-.81-.41-.21-.86-.44-1.45-.64-.64-.24-2.61-.8-4.45-1.07-1.84-.3-3.52-.44-3.52-.44,0,0-1.68-.14-3.52-.29-1.84-.14-3.83-.35-4.5-.44-1.22-.16-1.92-.18-2.97-.2Z"
        />
        <path
          style={styles.eleven}
          d="m568.3,801.97c-1.01-.54-1.71-.88-3-1.27-.7-.21-2.8-.83-4.75-1.35-1.95-.5-3.72-.95-3.72-.95,0,0-1.78-.42-3.74-.88-1.96-.44-4.11-.87-4.83-1-1.32-.25-2.1-.27-3.24-.26,1.07.4,1.8.65,3.12.91.71.14,2.86.57,4.81,1.01,1.95.45,3.73.87,3.73.87,0,0,1.76.45,3.7.96,1.94.52,4.03,1.13,4.73,1.34,1.29.38,2.05.5,3.18.64Z"
        />
        <path
          style={styles.eleven}
          d="m585.34,806.39c.79.32,1.34.52,2.32.72,1.07.22,6.43,1.3,6.43,1.3,0,0,5.36,1.09,6.43,1.3.99.2,1.57.23,2.42.24-.79-.32-1.34-.52-2.32-.72-1.07-.22-6.43-1.3-6.43-1.3,0,0-5.36-1.09-6.43-1.3-.99-.2-1.57-.23-2.42-.24Z"
        />
        <path
          style={styles.eleven}
          d="m646.01,793.69c-1.47.04-2.46.1-4.16.45-1.83.39-11.02,2.35-11.02,2.35,0,0-9.18,1.96-11.02,2.35-1.69.37-2.62.71-3.98,1.28,1.47-.04,2.46-.1,4.16-.45,1.84-.39,11.02-2.35,11.02-2.35,0,0,9.19-1.96,11.02-2.35,1.69-.37,2.62-.71,3.98-1.28Z"
        />
        <path
          style={styles.eleven}
          d="m633.84,790.74c-1.93-.27-3.23-.41-5.52-.37-1.24.02-4.96.07-8.36-.02-3.41-.08-6.5-.27-6.5-.27,0,0-3.09-.18-6.49-.51-3.39-.32-7.08-.8-8.31-.98-2.26-.31-3.57-.33-5.52-.29,1.83.67,3.09,1.07,5.36,1.4,1.23.18,4.94.67,8.36.99,3.41.33,6.53.51,6.53.51,0,0,3.11.2,6.54.28,3.43.09,7.17.05,8.42.01,2.3-.06,3.6-.31,5.49-.75Z"
        />
        <path
          style={styles.eleven}
          d="m654.22,784.51c-1.53-.07-2.56-.08-4.35.12-.97.11-3.88.43-6.56.62-2.68.2-5.12.3-5.12.3,0,0-2.44.1-5.13.12-2.69.03-5.62-.05-6.59-.09-1.8-.06-2.83.04-4.35.23,1.49.37,2.51.58,4.32.65.98.04,3.93.12,6.63.09,2.7-.02,5.16-.12,5.16-.12,0,0,2.45-.09,5.15-.29,2.7-.19,5.63-.51,6.6-.63,1.8-.22,2.8-.51,4.25-1Z"
        />
        <path
          style={styles.eleven}
          d="m612.91,774.65c1.87.34,3.14.53,5.38.53,2.43,0,14.58-.04,14.58-.04,0,0,12.15-.03,14.58-.04,2.24-.01,3.51-.21,5.38-.56-1.87-.34-3.14-.53-5.38-.53-2.43,0-14.58.04-14.58.04,0,0-12.15.03-14.58.04-2.24.02-3.51.21-5.38.56Z"
        />
        <path
          style={styles.eleven}
          d="m674.94,771.98c-.9-.32-1.57-.44-2.7-.41-.62.01-2.44.16-4.11.27-1.67.12-3.19.23-3.19.23,0,0-6.07.43-7.29.52-1.12.08-1.75.22-2.67.47.95.11,1.59.16,2.71.08,1.21-.09,7.29-.52,7.29-.52,0,0,1.52-.11,3.19-.23,1.67-.12,3.49-.26,4.09-.28.61-.03,1.24-.06,2.68-.14Z"
        />
        <path
          style={styles.eleven}
          d="m674.56,767.56c-1.28-.37-2.16-.59-3.73-.73-.85-.08-3.41-.28-5.75-.42-2.35-.12-4.48-.23-4.48-.23,0,0-2.14-.07-4.48-.15-2.35-.06-4.91-.08-5.77-.08-1.58,0-2.47.14-3.78.4,1.32.23,2.21.36,3.78.36.85,0,3.41.02,5.75.08,2.34.08,4.47.15,4.47.15,0,0,2.13.11,4.47.24,2.34.14,4.89.34,5.73.41,1.57.13,2.46.08,3.8-.04Z"
        />
        <path
          style={styles.eleven}
          d="m565.57,814.48c-1.23.11-2.05.21-3.45.56-.76.19-3.05.75-5.16,1.17-2.11.43-4.05.75-4.05.75,0,0-1.93.32-4.07.59-2.14.28-4.48.49-5.26.55-1.44.12-2.25.29-3.45.58,1.22.16,2.05.23,3.51.12.79-.06,3.14-.27,5.29-.55,2.15-.27,4.1-.6,4.1-.6,0,0,1.95-.32,4.07-.75,2.13-.42,4.43-.98,5.19-1.18,1.41-.36,2.17-.7,3.28-1.25Z"
        />
        <path
          style={styles.eleven}
          d="m583.07,818.06c-2.33.92-3.88,1.38-6.63,2.23-.75.22-2.26.64-4.15.98-1.89.35-4.15.64-6.33.88-4.37.47-8.35.97-8.35.97,0,0-3.98.48-8.34,1.1-4.36.61-9.11,1.36-10.68,1.63-2.91.49-4.52,1.02-6.87,1.9,2.51.02,4.19-.02,7.1-.48,1.57-.25,6.3-1.01,10.65-1.62,4.35-.62,8.31-1.1,8.31-1.1,0,0,3.96-.49,8.33-.95,2.19-.24,4.49-.53,6.43-.89,1.94-.35,3.52-.79,4.3-1.02,2.87-.91,4.39-1.92,6.22-3.61Z"
        />
        <path
          style={styles.eleven}
          d="m591.13,821.21c-4.79,1.18-7.96,1.9-13.42,3.61-.74.23-1.85.58-3.25.95-.7.19-1.46.4-2.28.62-.82.2-1.7.41-2.63.63-3.69.86-8.08,1.73-12.33,2.35-4.24.63-8.32,1.1-11.33,1.39-3.02.27-4.97.45-4.97.45,0,0-1.95.14-4.98.36-3.03.21-7.14.44-11.45.65-8.62.44-18.03.72-21.16.8-5.78.17-9.06.7-13.89,1.66,4.85.85,8.15,1.29,13.96,1.17,3.14-.06,12.58-.34,21.23-.79,4.32-.22,8.45-.46,11.5-.67,3.05-.22,5.02-.37,5.02-.37,0,0,1.97-.18,5.01-.45,3.05-.29,7.17-.76,11.48-1.4,4.31-.63,8.79-1.51,12.56-2.39.94-.23,1.84-.44,2.68-.65.84-.23,1.62-.44,2.33-.64,1.43-.37,2.56-.75,3.32-.98,5.58-1.8,8.55-3.5,12.58-6.32Z"
        />
        <path
          style={styles.eleven}
          d="m505.88,821.69c-.76.05-1.27.1-2.14.32-.94.24-5.64,1.44-5.64,1.44,0,0-4.7,1.2-5.64,1.44-.87.22-1.34.42-2.03.74.76-.05,1.27-.1,2.13-.32.94-.24,5.64-1.44,5.64-1.44,0,0,4.7-1.2,5.64-1.44.87-.22,1.34-.42,2.03-.74Z"
        />
        <path
          style={styles.eleven}
          d="m522.73,812.39c-2.58.03-4.33.11-7.31.7-1.62.32-6.46,1.32-10.9,2.27-4.43.97-8.46,1.86-8.46,1.86,0,0-4.02.92-8.44,1.93-4.42,1.03-9.23,2.19-10.83,2.59-2.95.74-4.56,1.4-6.91,2.48,2.58-.16,4.31-.33,7.26-1.04,1.6-.38,6.41-1.53,10.81-2.58,4.42-1.01,8.43-1.93,8.43-1.93,0,0,4.02-.88,8.45-1.85,4.43-.93,9.27-1.94,10.88-2.28,2.97-.62,4.61-1.2,7.01-2.16Z"
        />
        <path
          style={styles.eleven}
          d="m472.85,801.82c.47-.11.79-.19,1.31-.42.28-.13,1.13-.51,1.9-.86.77-.36,1.47-.69,1.47-.69,0,0,.7-.34,1.46-.71.76-.38,1.59-.8,1.86-.94.51-.26.77-.46,1.15-.76-.46.14-.77.25-1.28.51-.28.14-1.1.56-1.86.94-.76.37-1.46.71-1.46.71,0,0-.7.33-1.47.69-.77.35-1.62.73-1.9.86-.52.23-.8.4-1.2.67Z"
        />
        <path
          style={styles.eleven}
          d="m484.76,806.62c1.24.14,2.08.21,3.54.09.79-.06,3.17-.27,5.34-.52,2.17-.27,4.15-.51,4.15-.51,0,0,1.97-.28,4.13-.59,2.16-.33,4.51-.74,5.29-.89,1.44-.27,2.24-.55,3.39-1.01-1.24.01-2.08.05-3.52.31-.78.14-3.12.55-5.27.88-2.16.31-4.12.59-4.12.59,0,0-1.97.24-4.13.5-2.17.24-4.53.45-5.32.52-1.46.13-2.27.31-3.48.62Z"
        />
        <path
          style={styles.eleven}
          d="m520.41,802c2.58.35,4.32.51,7.36.46,1.64-.03,6.61-.11,11.06.55,2.23.31,4.31.8,5.82,1.26,1.51.45,2.46.83,2.46.83,0,0,.95.36,2.42.99,1.47.62,3.45,1.55,5.6,2.4,2.16.85,4.5,1.55,6.51,1.99,2.01.45,3.69.67,4.52.77,3.1.33,4.9.08,7.43-.49-2.58-.38-4.27-.65-7.26-.99-.81-.1-2.43-.31-4.37-.75-1.94-.43-4.2-1.1-6.29-1.92-2.09-.82-4.07-1.74-5.56-2.38-1.49-.64-2.48-1.01-2.48-1.01,0,0-.98-.39-2.56-.87-1.57-.47-3.74-.99-6.04-1.31-4.6-.69-9.64-.6-11.3-.56-3.08.08-4.81.44-7.34,1.04Z"
        />
        <path
          style={styles.eleven}
          d="m586.7,814.67c.62.13,1.04.2,1.79.21.81.02,4.86.09,4.86.09,0,0,4.05.08,4.86.09.75.01,1.17-.04,1.8-.15-.62-.13-1.04-.2-1.79-.21-.81-.02-4.86-.09-4.86-.09,0,0-4.05-.08-4.86-.09-.75-.01-1.17.05-1.8.15Z"
        />
        <path
          style={styles.eleven}
          d="m475.32,804.17c-1.78.27-2.97.49-4.98,1.14-1.09.35-4.37,1.4-7.4,2.26-3.03.87-5.81,1.58-5.81,1.58,0,0-2.78.71-5.85,1.4-3.07.7-6.45,1.36-7.58,1.57-2.08.4-3.23.77-4.92,1.39,1.8.04,3.02,0,5.11-.37,1.13-.21,4.52-.87,7.61-1.57,3.09-.69,5.88-1.41,5.88-1.41,0,0,2.79-.71,5.84-1.58,3.05-.86,6.34-1.91,7.43-2.27,2.02-.67,3.1-1.23,4.66-2.12Z"
        />
        <path
          style={styles.eleven}
          d="m385.18,813.43c2.25.23,3.78.33,6.44.09,1.44-.13,5.76-.54,9.71-.97,3.95-.46,7.55-.87,7.55-.87,0,0,3.59-.46,7.53-.96,3.94-.53,8.24-1.16,9.66-1.38,2.64-.41,4.1-.87,6.23-1.63-2.26-.06-3.79-.04-6.42.35-1.43.21-5.71.84-9.64,1.37-3.94.51-7.52.97-7.52.97,0,0-3.59.41-7.53.86-3.95.43-8.26.84-9.69.98-2.65.25-4.13.61-6.32,1.2Z"
        />
        <path
          style={styles.eleven}
          d="m393.99,818.89c.81.08,1.36.11,2.31.02.52-.05,2.07-.22,3.48-.42,1.41-.21,2.7-.4,2.7-.4,0,0,1.28-.22,2.69-.47,1.41-.26,2.93-.59,3.44-.7.93-.21,1.45-.41,2.19-.75-.81.04-1.36.09-2.29.29-.5.11-2.02.44-3.42.7-1.4.25-2.68.47-2.68.47,0,0-1.28.19-2.69.4-1.41.19-2.95.36-3.47.42-.95.1-1.48.23-2.26.45Z"
        />
        <path
          style={styles.eleven}
          d="m497.59,489.99c-.08.3-.14.49-.25.83-.05.19-.27.73-.55,1.19-.26.47-.55.87-.55.87,0,0-.27.41-.57.86-.29.46-.63.94-.77,1.08-.24.26-.37.41-.61.61.3-.09.49-.21.74-.49.14-.15.49-.63.78-1.09.3-.45.57-.87.57-.87,0,0,.29-.4.55-.88.28-.47.51-1.03.56-1.23.11-.36.11-.58.08-.88Z"
        />
        <path
          style={styles.eleven}
          d="m497.67,490.43c.06.16.1.27.2.45.11.19.66,1.14.66,1.14,0,0,.55.95.66,1.14.1.18.17.27.29.4-.06-.16-.1-.27-.2-.45-.11-.19-.66-1.14-.66-1.14,0,0-.55-.95-.66-1.14-.1-.18-.17-.27-.29-.4Z"
        />
        <path
          style={styles.eleven}
          d="m512.28,492.45s-.07.08-.11.15c-.04.07-.29.41-.29.41,0,0-.26.33-.32.38-.05.06-.08.09-.11.15.05-.04.09-.07.14-.12.06-.06.32-.39.32-.39,0,0,.25-.34.29-.41.04-.07.06-.11.08-.17Z"
        />
        <path
          style={styles.eleven}
          d="m481.18,489.84c-.04.1-.06.17-.08.29-.03.13-.08.81-.08.81,0,0,0,.68.02.81.01.12.03.19.06.29,0-.11,0-.18,0-.3-.02-.13-.02-.8-.02-.8,0,0,.05-.67.08-.8.02-.12.02-.19.02-.3Z"
        />
        <path
          style={styles.eleven}
          d="m546.49,496.4c-1.96-.36-3.3-.53-5.65-.44-1.27.05-5.1.29-8.58.72-1.74.21-3.42.48-4.64.77-1.23.29-1.99.55-1.99.55,0,0-.78.24-1.94.67-1.17.42-2.71,1.04-4.35,1.54-3.29,1.02-7.05,1.52-8.3,1.68-2.31.3-3.61.61-5.52,1.17,1.98.17,3.33.25,5.67-.03,1.26-.16,5.09-.66,8.49-1.72,1.7-.52,3.26-1.15,4.41-1.56,1.15-.42,1.89-.65,1.89-.65,0,0,.74-.25,1.91-.52,1.17-.28,2.8-.54,4.52-.75,3.45-.43,7.24-.67,8.5-.72,2.33-.12,3.64-.33,5.6-.71Z"
        />
        <path
          style={styles.eleven}
          d="m538.78,480.96c.93.43,1.59.68,2.78.87.65.11,2.62.35,4.43.32,1.82,0,3.45-.24,3.45-.24,0,0,.41-.05,1.04-.16.63-.11,1.47-.27,2.35-.45,1.75-.38,3.67-.66,4.31-.68,1.18-.07,1.84-.14,2.86-.18-.96-.34-1.68-.47-2.9-.41-.67.01-2.63.3-4.38.68-.88.18-1.71.35-2.33.45-.62.11-1.02.16-1.02.16,0,0-1.6.23-3.38.23-1.78.03-3.7-.2-4.34-.31-1.18-.18-1.85-.23-2.87-.29Z"
        />
        <path
          style={styles.eleven}
          d="m566.45,484.46c-2.14-.47-3.64-.68-6.23-.57-1.41.04-5.62.38-9.45.82-3.83.43-7.3.76-7.3.76,0,0-.87.07-2.21.17-1.35.1-3.17.21-5.06.18-1.89-.02-3.83-.31-5.5-.62-1.67-.31-3.06-.58-3.76-.68-2.58-.39-4.19-.26-6.27.42,2.19.12,3.56.43,6.07.82.68.1,2.04.37,3.73.69,1.68.32,3.74.63,5.7.64,1.96.04,3.81-.09,5.17-.18,1.36-.11,2.24-.18,2.24-.18,0,0,3.5-.32,7.33-.75,3.83-.44,7.99-.77,9.38-.82,2.56-.13,4-.36,6.17-.69Z"
        />
        <path d="m491.9,437.1c-5.58,8.83-12.83,17.36-22.7,21.38,2.37-1.12,4.57-2.53,6.61-4.15,4.01-3.31,7.41-7.29,10.34-11.57,1.45-2.13,2.83-4.36,4-6.61,0,0,1.76.95,1.76.95h0Z" />
        <path
          style={styles.four}
          d="m572.17,438.28s3.36,9.19,5.67,10.88,7.85,2.6,7.85,2.6"
        />
        <path
          style={styles.four}
          d="m424.85,401.15c.6.72,1.56,1.77,2.89,2.87,5.32,4.4,12.49,6.14,26.96,5.59,7.99-.3,13.79-1.13,19.57-1.96,6.54-.93,17.45-2.48,31.82-4.48"
        />
        <path d="m456.92,388.86c17.37,7.38,37.28,3.73,51.32-8.73-12.33,14.42-34.88,17.91-51.32,8.73h0Z" />
        <path d="m501.75,402.3c7.05-5.07,11.58-13.43,12.05-22.17.19,8.98-3.89,17.92-10.82,23.74,0,0-1.24-1.57-1.24-1.57h0Z" />
        <path d="m511.86,399.81c4.38-4.65,8.08-10.61,9.33-16.58.24-1.06.81-3.88,1.03-4.98,0,0,1.29-6.67,1.29-6.67,0,0-.87,6.74-.87,6.74-.27,1.89-.7,5.19-1.01,6.75-1.36,5.93-4.42,11.39-8.28,16.06,0,0-1.5-1.32-1.5-1.32h0Z" />
        <path d="m564.62,405.31c-4.27-5.19-7.56-11.1-9.69-17.35,2.75,6,6.56,11.44,11.17,16l-1.48,1.35h0Z" />
        <path d="m564.56,382.28c9.09,16.57,27.04,26.65,45.89,22.75,6.06-1.17,11.9-3.47,17.23-6.64-4.78,4.14-10.63,7.08-16.77,8.67-19.37,5.32-39.87-5.94-46.35-24.78h0Z" />
        <path d="m638.8,388.74c.14,3.63-1.34,7.25-3.98,9.65,2-2.69,2.75-6.21,1.99-9.41,0,0,1.99-.24,1.99-.24h0Z" />
        <path d="m538.3,377.91c-.95-.09-1.79-.54-2.5-1.13-.71-.6-1.27-1.38-1.63-2.24-.37-.86-.54-1.81-.5-2.73.06-.92.3-1.84.87-2.6.31.89.53,1.66.8,2.39.25.73.52,1.41.82,2.08.3.67.63,1.32.98,2,.36.69.75,1.38,1.17,2.24Z" />
        <path
          style={styles.four}
          d="m484.55,488.89c.41,2.57,2.63,4.48,5.2,4.52,2.63.04,4.94-1.89,5.36-4.52"
        />
        <path
          style={styles.four}
          d="m502.07,490.22c-.46,2.28.81,4.41,2.55,4.96,2.08.65,4.82-.94,5.22-3.77"
        />
        <path
          style={styles.two}
          d="m501.56,366.54c1.19-.17,3.6-.34,6.35.68,2.32.86,3.87,2.18,4.69,3,2.68,3.29,6.86,3.52,8.74,1.7,1.24-1.21,1.51-3.35.92-5.37-.08-.37-.24-.83-.53-1.28-1.02-1.55-2.86-1.81-3.23-1.86-5.88-.27-11.76-.55-17.64-.82l.69,3.95Z"
        />
        <path
          style={styles.two}
          d="m558.56,367.78c-4.29,3.32-5.41,7.51-4.08,9.22,1.38,1.77,5.37.82,5.67.74,4.48-2.36,8.96-4.71,13.44-7.07l-15.03-2.9Z"
        />
        <path
          style={styles.eleven}
          d="m597.48,289.53c.04-.55.36-.84.66-1.13.31-.29.7-.56,1.18-.97.51-.44,2.08-1.72,3.63-2.76,1.53-1.05,2.99-1.91,2.99-1.91,0,0,1.45-.87,3.1-1.75,1.64-.88,3.47-1.79,4.08-2.1,1.12-.56,1.7-1,2.53-1.7-1.06.23-1.76.41-2.89.97-.61.3-2.45,1.21-4.11,2.11-1.66.89-3.13,1.77-3.13,1.77,0,0-1.48.87-3.04,1.94-1.57,1.06-3.18,2.36-3.69,2.82-.47.41-.85.77-1.12,1.21-.26.42-.43,1-.19,1.51Z"
        />
        <path
          style={styles.eleven}
          d="m607.36,286.33c.31-.17.51-.29.82-.55.17-.14.68-.56,1.17-.91.49-.35.96-.64.96-.64,0,0,.47-.29,1-.57.53-.29,1.14-.56,1.34-.64.37-.16.57-.29.86-.48-.35.04-.59.08-.97.24-.21.09-.82.36-1.36.65-.54.29-1.02.58-1.02.58,0,0-.48.29-.98.65-.5.35-1.02.78-1.19.93-.31.27-.45.46-.64.76Z"
        />
        <path
          style={styles.eleven}
          d="m650.46,280.81c-1.07-.54-1.81-.87-3.19-1.14-.74-.15-2.98-.56-5.05-.83-2.07-.28-3.96-.44-3.96-.44,0,0-1.89-.17-3.98-.26-2.09-.1-4.36-.09-5.12-.07-1.4.03-2.19.22-3.34.57,1.18.23,1.96.36,3.35.34.75,0,3-.02,5.07.08,2.06.08,3.94.25,3.94.25,0,0,1.87.16,3.92.44,2.05.27,4.27.67,5,.82,1.36.26,2.16.28,3.36.25Z"
        />
        <path
          style={styles.eleven}
          d="m658.13,269.87c-1.73-.77-2.94-1.23-5.14-1.51-1.19-.16-4.79-.52-8.1-.58-1.66-.05-3.24-.01-4.4.02-1.17.05-1.92.08-1.92.08,0,0-.75.05-1.92.13-1.16.09-2.74.23-4.38.45-3.29.41-6.83,1.16-7.99,1.45-2.16.52-3.31,1.1-4.95,2.06,1.9-.08,3.15-.17,5.27-.66,1.15-.27,4.63-1.01,7.86-1.41,1.61-.22,3.16-.36,4.3-.45,1.14-.08,1.88-.13,1.88-.13,0,0,.74-.03,1.89-.07,1.15-.03,2.7-.06,4.33-.01,3.26.05,6.79.42,7.96.56,2.17.26,3.42.22,5.31.09Z"
        />
        <path
          style={styles.eleven}
          d="m677.07,272.79c-.39-.17-.66-.28-1.16-.37-.53-.1-3.2-.56-3.2-.56,0,0-2.67-.47-3.2-.56-.49-.08-.78-.08-1.21-.05.39.17.66.28,1.16.37.53.1,3.2.56,3.2.56,0,0,2.67.47,3.2.56.49.08.78.08,1.21.05Z"
        />
        <path
          style={styles.eleven}
          d="m395.53,503.58c.33-.42.53-.72.78-1.29.13-.31.52-1.24.85-2.11.32-.87.61-1.66.61-1.66,0,0,.27-.8.57-1.67.29-.88.58-1.84.68-2.17.17-.6.2-.95.21-1.49-.26.46-.42.78-.6,1.37-.1.32-.4,1.28-.68,2.15-.3.87-.57,1.66-.57,1.66,0,0-.29.78-.61,1.65-.34.85-.72,1.78-.84,2.09-.24.57-.31.92-.4,1.44Z"
        />
        <path
          style={styles.eleven}
          d="m400.38,515.32c.13-.28.21-.47.28-.82.07-.38.44-2.28.44-2.28,0,0,.37-1.9.44-2.28.07-.35.07-.56.05-.87-.13.28-.21.47-.28.82-.08.39-.44,2.29-.44,2.29,0,0-.37,1.9-.44,2.29-.07.35-.07.56-.05.86Z"
        />
        <path
          style={styles.eleven}
          d="m409.33,520.6c.27-.67.46-1.1.7-1.89.12-.43.58-1.7,1-2.9.41-1.2.71-2.3.71-2.3,0,0,.31-1.1.59-2.33.28-1.23.55-2.57.63-3.02.16-.83.15-1.31.11-2.04-.3.66-.48,1.11-.65,1.93-.09.45-.35,1.78-.63,3-.28,1.22-.58,2.31-.58,2.31,0,0-.3,1.09-.7,2.27-.42,1.17-.89,2.47-1.01,2.92-.24.82-.25,1.33-.18,2.05Z"
        />
        <path
          style={styles.eleven}
          d="m409.02,498.31c-.04.29-.07.48-.06.81,0,.18.02.72-.01,1.22-.02.5-.08.94-.08.94,0,0-.05.45-.15.93-.09.49-.23,1.01-.28,1.18-.09.32-.12.51-.14.8.16-.24.25-.41.35-.74.05-.18.2-.71.29-1.2.1-.49.15-.95.15-.95,0,0,.06-.45.08-.96.03-.5.02-1.05,0-1.24-.02-.34-.07-.53-.16-.8Z"
        />
        <path
          style={styles.eleven}
          d="m399.95,460.3c-.03,1.39-.01,2.32.29,3.89.17.85.65,3.42.9,5.78.27,2.37.26,4.53.26,4.53,0,0,.03,2.16-.31,4.5-.29,2.35-1.09,4.81-1.41,5.59-.56,1.47-.8,2.34-1.2,3.67.97-.99,1.57-1.76,2.18-3.29.35-.82,1.17-3.39,1.47-5.82.35-2.43.31-4.65.31-4.65,0,0,.01-2.22-.26-4.64-.25-2.42-.75-5.01-.92-5.87-.32-1.59-.7-2.45-1.32-3.69Z"
        />
        <path
          style={styles.eleven}
          d="m424.11,660.3c1.26.55,2.11.88,3.68,1.17.85.16,3.39.63,5.72,1.06,2.33.46,4.44.87,4.44.87,0,0,2.11.44,4.43.92,2.32.5,4.84,1.05,5.69,1.24,1.55.34,2.47.37,3.84.35-1.22-.62-2.06-1-3.61-1.36-.84-.18-3.38-.74-5.7-1.25-2.33-.48-4.44-.92-4.44-.92,0,0-2.12-.42-4.45-.88-2.34-.43-4.89-.9-5.73-1.06-1.57-.27-2.49-.25-3.86-.15Z"
        />
        <path
          style={styles.eleven}
          d="m474.97,668.97c.4.18.66.29,1.16.39.27.06,1.06.23,1.79.42.72.19,1.38.37,1.38.37,0,0,.65.2,1.37.42.71.23,1.48.5,1.74.6.47.17.76.22,1.19.28-.35-.25-.6-.41-1.07-.59-.26-.1-1.03-.37-1.75-.6-.72-.22-1.38-.42-1.38-.42,0,0-.66-.18-1.39-.38-.73-.19-1.54-.36-1.8-.42-.5-.1-.79-.1-1.22-.07Z"
        />
        <path
          style={styles.eleven}
          d="m479.68,673.36c.41.17.68.27,1.19.37.27.05,1.09.21,1.83.38.74.17,1.41.36,1.41.36,0,0,.67.18,1.39.41.73.23,1.51.51,1.77.61.48.18.77.23,1.21.29-.36-.26-.61-.42-1.09-.6-.26-.1-1.05-.38-1.78-.61-.73-.23-1.41-.42-1.41-.42,0,0-.67-.19-1.42-.36-.75-.18-1.57-.34-1.85-.38-.51-.09-.81-.08-1.25-.04Z"
        />
        <path
          style={styles.eleven}
          d="m544.56,685.39c-1.28-.72-2.17-1.16-3.82-1.61-1.79-.48-10.74-2.9-10.74-2.9,0,0-2.24-.6-4.75-1.16-2.5-.57-5.28-.99-6.21-1.08-1.72-.19-2.72-.07-4.16.23,1.41.41,2.35.67,4.02.88.91.1,3.63.51,6.1,1.07,2.47.54,4.71,1.14,4.71,1.14,0,0,8.94,2.42,10.73,2.9,1.65.44,2.64.51,4.11.54Z"
        />
        <path
          style={styles.eleven}
          d="m513.33,674.13c-.55-.22-.93-.35-1.61-.45-.74-.1-4.43-.61-4.43-.61,0,0-3.7-.51-4.43-.61-.68-.09-1.08-.07-1.67,0,.55.22.93.35,1.61.45.74.1,4.43.61,4.43.61,0,0,3.7.51,4.43.61.68.09,1.08.07,1.67,0Z"
        />
        <path
          style={styles.eleven}
          d="m587.07,678.22c-.84-.34-1.42-.55-2.47-.69-.57-.08-2.28-.3-3.85-.47-1.57-.16-3-.3-3-.3,0,0-1.43-.12-3.01-.26-1.58-.12-3.3-.23-3.87-.26-1.06-.05-1.67.04-2.56.22.87.26,1.47.4,2.52.47.57.04,2.28.15,3.86.26,1.57.13,3,.25,3,.25,0,0,1.42.15,2.99.31,1.56.19,3.27.39,3.84.46,1.05.13,1.66.1,2.56,0Z"
        />
        <path
          style={styles.eleven}
          d="m598.63,679.68c-.35-.08-.59-.12-1-.12-.23,0-.9-.01-1.53-.03-.62-.03-1.19-.05-1.19-.05,0,0-.56-.04-1.18-.08-.62-.06-1.29-.13-1.52-.15-.41-.05-.65-.03-1.01,0,.33.13.56.21.98.26.23.03.9.1,1.53.15.62.04,1.19.08,1.19.08,0,0,.57.03,1.19.05.63.02,1.31.03,1.53.02.42,0,.66-.05,1-.15Z"
        />
        <path
          style={styles.eleven}
          d="m512.76,666.34c1.52.58,2.54.92,4.39,1.23,1,.16,4.01.7,6.72,1.36,2.72.65,5.15,1.39,5.15,1.39,0,0,2.44.73,5.06,1.71,2.63.96,5.42,2.19,6.34,2.61,1.71.78,2.75,1.06,4.33,1.43-1.22-1.06-2.1-1.74-3.82-2.55-.93-.44-3.76-1.68-6.43-2.65-2.66-.99-5.13-1.73-5.13-1.73,0,0-2.46-.76-5.22-1.42-2.75-.68-5.8-1.22-6.82-1.38-1.88-.29-2.98-.22-4.59-.01Z"
        />
        <path
          style={styles.eleven}
          d="m561.65,678.29c-.41-.09-.68-.13-1.16-.13-.26,0-1.05,0-1.77.02-.72,0-1.37-.01-1.37-.01,0,0-.65-.02-1.37-.04-.72-.03-1.5-.06-1.76-.08-.48-.02-.76.01-1.17.09.4.13.67.2,1.15.23.26.01,1.05.05,1.77.08.72.02,1.38.03,1.38.03,0,0,.66,0,1.38.01.72,0,1.51-.01,1.77-.02.48-.01.76-.07,1.16-.19Z"
        />
        <path
          style={styles.eleven}
          d="m659.58,659.24c.54,1.1.94,1.82,1.9,2.88.51.58,2.1,2.28,3.63,3.76,1.53,1.49,2.99,2.76,2.99,2.76,0,0,1.45,1.28,3.12,2.61,1.66,1.33,3.55,2.68,4.19,3.12,1.18.81,1.95,1.12,3.1,1.51-.84-.89-1.42-1.46-2.58-2.27-.63-.44-2.5-1.77-4.14-3.09-1.65-1.31-3.09-2.57-3.09-2.57,0,0-1.44-1.26-2.95-2.73-1.52-1.46-3.08-3.15-3.59-3.71-.95-1.04-1.59-1.55-2.58-2.26Z"
        />
        <path
          style={styles.eleven}
          d="m663.04,670.63c.2.29.34.47.63.75.16.15.63.6,1.07,1.02.42.43.81.81.81.81,0,0,.37.4.79.84.4.45.84.94.98,1.1.26.3.45.45.73.66-.15-.31-.26-.52-.53-.83-.14-.17-.58-.66-.99-1.11-.41-.44-.79-.84-.79-.84,0,0-.39-.39-.81-.82-.43-.42-.91-.87-1.07-1.02-.3-.27-.5-.39-.81-.55Z"
        />
        <path
          style={styles.eleven}
          d="m670.62,674.4c.12.31.22.52.45.83.13.17.53.67.92,1.11.39.44.77.81.77.81,0,0,.38.38.81.77.44.39.94.79,1.11.92.31.24.52.33.83.45-.22-.25-.37-.42-.68-.65-.17-.13-.66-.52-1.09-.91-.43-.38-.8-.76-.8-.76,0,0-.37-.37-.76-.8-.39-.43-.78-.92-.91-1.09-.24-.31-.4-.46-.66-.68Z"
        />
        <path
          style={styles.eleven}
          d="m690.98,676.85c-.44-.14-.74-.21-1.27-.26-.29-.02-1.15-.1-1.94-.21-.79-.1-1.5-.23-1.5-.23,0,0-.71-.12-1.49-.3-.78-.17-1.62-.39-1.9-.46-.52-.14-.82-.17-1.29-.19.4.24.67.38,1.19.53.28.08,1.13.3,1.92.47.79.17,1.51.3,1.51.3,0,0,.72.13,1.52.24.8.11,1.67.19,1.96.21.54.04.85,0,1.3-.09Z"
        />
        <path
          style={styles.eleven}
          d="m664.21,699.11c-.64-.37-1.08-.6-1.92-.81-.45-.12-1.82-.43-3.09-.64-1.27-.21-2.43-.33-2.43-.33,0,0-1.16-.13-2.45-.18-1.29-.06-2.69-.03-3.16-.01-.86.04-1.35.17-2.05.4.73.12,1.21.18,2.06.16.46-.02,1.85-.04,3.11.02,1.27.05,2.42.17,2.42.17,0,0,1.15.12,2.4.33,1.25.2,2.6.51,3.05.62.83.2,1.31.24,2.05.27Z"
        />
        <path
          style={styles.eleven}
          d="m672.77,746.48c-1.13-.49-1.82-.86-3.14-1.34-.72-.24-2.81-1.2-4.82-1.99-2-.78-3.87-1.34-3.87-1.34,0,0-1.86-.59-3.96-1.05-2.09-.49-4.41-.82-5.19-.91-1.43-.17-2.26-.08-3.47.14,1.17.38,1.95.61,3.35.79.76.1,3.04.42,5.1.9,2.06.46,3.89,1.03,3.89,1.03,0,0,1.84.55,3.81,1.32,1.95.77,4.08,1.74,4.84,1.99,1.36.49,2.24.57,3.46.47Z"
        />
        <path
          style={styles.four}
          d="m431.37,617.47c17.98,6.11,40.11,11.64,65.72,13.88,9.53.83,18.55,1.12,27,1.04,7.54-.55,15.71-1.53,24.39-3.12,17.75-3.27,32.98-8.31,45.39-13.45"
        />
        <path
          style={styles.eleven}
          d="m593.97,616.52c-.87.7-1.45,1.15-2.37,2.03-.49.49-2.07,1.84-3.72,2.82-1.63,1-3.23,1.69-3.23,1.69,0,0-1.59.72-3.42,1.33-1.82.62-3.86,1.17-4.54,1.35-1.25.34-1.93.66-2.92,1.19,1.12,0,1.87-.04,3.14-.37.68-.18,2.74-.73,4.59-1.36,1.86-.62,3.49-1.36,3.49-1.36,0,0,1.65-.71,3.33-1.74,1.7-1.01,3.35-2.42,3.86-2.93.95-.93,1.34-1.61,1.78-2.64Z"
        />
        <path
          style={styles.eleven}
          d="m611.94,620.74c-.75.34-1.23.58-2.01,1.11-.42.29-1.7,1.13-2.95,1.77-1.24.66-2.43,1.14-2.43,1.14,0,0-1.18.49-2.53.89-1.34.42-2.84.72-3.35.81-.93.17-1.44.34-2.21.62.81.11,1.37.15,2.32-.01.51-.09,2.05-.4,3.42-.83,1.37-.41,2.58-.92,2.58-.92,0,0,1.21-.49,2.48-1.16,1.28-.65,2.59-1.51,3.02-1.81.79-.55,1.16-.97,1.66-1.62Z"
        />
        <path
          style={styles.eleven}
          d="m623.44,625.67c-1.39,1.23-2.32,1.96-3.85,3.39-.8.8-3.5,2.94-6.26,4.53-2.73,1.64-5.35,2.85-5.35,2.85,0,0-2.61,1.25-5.61,2.32-2.99,1.09-6.35,1.95-7.48,2.19-2.08.47-3.22.91-4.93,1.63,1.84.17,3.11.2,5.24-.26,1.16-.23,4.59-1.11,7.65-2.23,3.06-1.09,5.73-2.38,5.73-2.38,0,0,2.69-1.24,5.47-2.91,2.82-1.62,5.65-3.85,6.49-4.71,1.6-1.52,2.25-2.69,2.9-4.42Z"
        />
        <path
          style={styles.eleven}
          d="m395.59,670.83c2.08,1.04,3.51,1.67,6.14,2.28,2.85.66,17.16,3.85,17.16,3.85,0,0,14.3,3.22,17.16,3.85,2.64.58,4.2.62,6.53.56-2.08-1.04-3.51-1.67-6.14-2.28-2.85-.66-17.16-3.85-17.16-3.85,0,0-14.3-3.22-17.16-3.85-2.64-.58-4.2-.62-6.53-.56Z"
        />
        <path
          style={styles.eleven}
          d="m593.09,695.83c-.36-.05-.61-.08-1.03-.04-.23.02-.92.06-1.55.1-.63.02-1.21.05-1.21.05,0,0-.58,0-1.21.01-.63,0-1.32-.02-1.55-.02-.42-.01-.67.02-1.03.1.35.11.59.17,1.02.18.23,0,.92.03,1.56.03.64,0,1.21-.02,1.21-.02,0,0,.58-.02,1.21-.04.64-.03,1.33-.08,1.56-.1.42-.04.66-.11,1.01-.23Z"
        />
        <path
          style={styles.eleven}
          d="m332.16,378.97c.47-.14.78-.25,1.28-.53.27-.15,1.08-.6,1.83-.99.76-.38,1.45-.72,1.45-.72,0,0,.7-.32,1.47-.67.78-.34,1.64-.68,1.92-.79.53-.21.81-.38,1.22-.65-.49.05-.82.1-1.35.31-.29.11-1.16.45-1.94.8-.78.35-1.48.68-1.48.68,0,0-.7.34-1.46.72-.76.39-1.58.84-1.84,1-.5.28-.74.51-1.09.85Z"
        />
        <path
          style={styles.eleven}
          d="m333.34,391.21c.56-.05.95-.11,1.57-.37.69-.24,3.81-2.01,3.81-2.01,0,0,.78-.44,1.66-.85.88-.43,1.88-.76,2.22-.85.62-.17.97-.3,1.5-.51-.56-.07-.96-.08-1.61.09-.35.09-1.39.44-2.29.87-.9.43-1.68.87-1.68.87,0,0-3.12,1.76-3.78,1.99-.6.26-.91.46-1.39.76Z"
        />
        <path
          style={styles.eleven}
          d="m365.21,399.17c-.92-.3-1.55-.46-2.68-.5-.61-.02-2.44-.06-4.12.02-1.68.06-3.2.21-3.2.21,0,0-1.52.14-3.18.38-1.66.24-3.46.61-4.05.74-1.1.25-1.69.53-2.54.99.97-.01,1.6-.04,2.69-.28.59-.13,2.36-.49,4.01-.73,1.64-.25,3.14-.38,3.14-.38,0,0,1.5-.14,3.16-.2,1.66-.07,3.47-.04,4.07-.02,1.11.03,1.75-.06,2.7-.22Z"
        />
        <path
          style={styles.eleven}
          d="m365.96,397.44c-.44-.35-.74-.57-1.33-.84-.64-.3-3.84-1.76-3.84-1.76,0,0-3.2-1.47-3.84-1.76-.59-.27-.96-.35-1.5-.46.44.35.74.57,1.33.84.64.3,3.84,1.76,3.84,1.76,0,0,3.2,1.47,3.84,1.76.59.27.96.35,1.5.46Z"
        />
        <path
          style={styles.eleven}
          d="m353.51,354.26c.07-.47.41-.64.71-.83.31-.18.71-.33,1.17-.6.5-.28,2.04-1.1,3.51-1.75,1.46-.65,2.82-1.17,2.82-1.17,0,0,1.36-.53,2.88-1.04,1.52-.52,3.19-1.04,3.75-1.22,1.03-.33,1.58-.62,2.37-1.1-.93.05-1.55.11-2.58.43-.56.17-2.24.7-3.77,1.22-1.53.52-2.9,1.05-2.9,1.05,0,0-1.38.52-2.86,1.18-1.48.65-3.05,1.48-3.56,1.78-.47.28-.84.49-1.15.81-.29.3-.58.77-.39,1.23Z"
        />
        <path
          style={styles.eleven}
          d="m357.9,346.14c1.23-.32,2.04-.57,3.36-1.23.71-.36,2.86-1.41,4.88-2.29,2.01-.88,3.88-1.59,3.88-1.59,0,0,1.86-.72,3.95-1.41,2.08-.7,4.39-1.35,5.16-1.56,1.42-.39,2.19-.75,3.32-1.33-1.27-.02-2.14.02-3.57.4-.78.2-3.11.86-5.21,1.57-2.11.7-3.99,1.43-3.99,1.43,0,0-1.89.71-3.92,1.6-2.04.88-4.21,1.95-4.93,2.31-1.32.68-1.99,1.23-2.93,2.09Z"
        />
        <path
          style={styles.eleven}
          d="m350.65,376.86c.68-.74,1.11-1.23,1.73-2.2.33-.53,1.37-2.09,2.46-3.42,1.07-1.34,2.15-2.47,2.15-2.47,0,0,1.07-1.13,2.37-2.27,1.28-1.14,2.79-2.25,3.3-2.61.94-.66,1.42-1.12,2.12-1.83-.95.3-1.59.55-2.56,1.21-.52.36-2.07,1.49-3.37,2.66-1.32,1.15-2.41,2.31-2.41,2.31,0,0-1.11,1.15-2.2,2.51-1.11,1.36-2.17,2.95-2.5,3.49-.62,1-.84,1.65-1.09,2.61Z"
        />
        <path
          style={styles.eleven}
          d="m381.17,344.59c-.65.55-1.07.94-1.66,1.73-.32.43-1.3,1.7-2.24,2.84-.93,1.14-1.82,2.14-1.82,2.14,0,0-.88,1.01-1.89,2.08-1.01,1.07-2.15,2.21-2.53,2.58-.7.69-1.04,1.15-1.5,1.87.75-.41,1.24-.72,1.95-1.41.39-.37,1.54-1.51,2.55-2.6,1.02-1.08,1.91-2.1,1.91-2.1,0,0,.89-1.01,1.84-2.16.95-1.14,1.93-2.43,2.25-2.86.59-.8.83-1.32,1.15-2.12Z"
        />
        <path
          style={styles.eleven}
          d="m357.88,384.78c.23-.28.38-.48.57-.86.2-.42,1.19-2.47,1.19-2.47,0,0,.99-2.06,1.19-2.47.18-.38.24-.62.32-.98-.23.28-.38.47-.56.85-.2.42-1.19,2.48-1.19,2.48,0,0-.99,2.06-1.19,2.48-.18.38-.24.62-.32.97Z"
        />
        <path
          style={styles.eleven}
          d="m384.12,350.72c.88-.6,1.45-1.03,2.3-1.92.46-.48,1.86-1.91,3.22-3.15,1.35-1.24,2.64-2.31,2.64-2.31,0,0,1.28-1.07,2.76-2.16,1.47-1.1,3.14-2.21,3.69-2.57,1.03-.67,1.55-1.15,2.31-1.9-1.02.31-1.7.55-2.75,1.22-.57.36-2.26,1.48-3.74,2.6-1.49,1.11-2.79,2.19-2.79,2.19,0,0-1.31,1.07-2.67,2.33-1.37,1.25-2.79,2.7-3.25,3.19-.85.91-1.22,1.53-1.71,2.47Z"
        />
        <path
          style={styles.eleven}
          d="m473.6,300.73c.66.34,1.13.55,1.98.74.46.1,1.85.4,3.12.63,1.28.22,2.44.43,2.44.43,0,0,1.17.18,2.45.37,1.29.18,2.7.34,3.16.39.87.09,1.37.04,2.11-.07-.71-.25-1.19-.39-2.05-.49-.47-.05-1.87-.21-3.15-.39-1.28-.19-2.44-.37-2.44-.37,0,0-1.16-.2-2.43-.43-1.27-.24-2.65-.53-3.11-.63-.85-.18-1.35-.2-2.1-.19Z"
        />
        <path
          style={styles.eleven}
          d="m641.67,643.31c.77.4,1.09.81,1.68,1.54.34.38,1.08,1.81,1.69,3.18.62,1.36,1.08,2.63,1.08,2.63,0,0,.47,1.27.83,2.71.38,1.44.55,3.04.58,3.57.07.99.15,1.54.32,2.4.25-.82.4-1.41.34-2.44-.03-.55-.2-2.22-.59-3.69-.37-1.48-.86-2.77-.86-2.77,0,0-.47-1.3-1.09-2.68-.61-1.37-1.37-2.86-1.77-3.31-.65-.82-1.34-1.17-2.2-1.15Z"
        />
        <path
          style={styles.eleven}
          d="m652.56,652.5c.03.68.07,1.12.23,1.88.09.41.31,1.66.36,2.82.07,1.16,0,2.21,0,2.21,0,0-.06,1.05-.28,2.19-.2,1.14-.58,2.35-.72,2.75-.26.73-.35,1.17-.47,1.84.42-.53.68-.92.95-1.67.15-.41.54-1.65.74-2.82.22-1.17.28-2.25.28-2.25,0,0,.08-1.08,0-2.27-.05-1.19-.28-2.47-.38-2.89-.17-.78-.38-1.2-.73-1.78Z"
        />
        <path
          style={styles.eleven}
          d="m631.85,684.14c.86-.63,1.42-1.09,2.21-2.04.86-1.03,5.15-6.21,5.15-6.21,0,0,4.3-5.17,5.15-6.21.79-.96,1.13-1.59,1.59-2.55-.86.63-1.42,1.09-2.21,2.04-.86,1.03-5.15,6.21-5.15,6.21,0,0-4.3,5.17-5.15,6.21-.79.96-1.13,1.59-1.59,2.55Z"
        />
        <path
          style={styles.eleven}
          d="m506.76,357.78c-1.05.02-1.76.06-2.94.36-.64.16-2.57.63-4.35,1.01-1.78.39-3.41.7-3.41.7,0,0-1.63.31-3.42.61-1.8.3-3.76.58-4.42.67-1.21.17-1.88.39-2.87.76,1.05.13,1.76.19,2.97.03.66-.09,2.64-.37,4.44-.67,1.81-.3,3.44-.62,3.44-.62,0,0,1.64-.31,3.43-.7,1.79-.39,3.73-.86,4.38-1.03,1.19-.31,1.83-.62,2.75-1.13Z"
        />
        <path
          style={styles.eleven}
          d="m592.83,346.16c.97.26,1.62.41,2.78.47.63.04,2.51.17,4.22.39,1.72.21,3.26.5,3.26.5,0,0,1.55.28,3.23.69,1.68.4,3.49.94,4.09,1.13,1.11.35,1.77.44,2.77.54-.83-.56-1.42-.9-2.54-1.27-.6-.2-2.43-.75-4.14-1.15-1.7-.41-3.27-.69-3.27-.69,0,0-1.56-.29-3.3-.51-1.74-.23-3.64-.36-4.28-.39-1.17-.06-1.84.06-2.82.28Z"
        />
        <path
          style={styles.eleven}
          d="m633.45,298.53c-1.16.07-1.92.15-3.21.51-.7.2-2.8.78-4.74,1.25-1.94.48-3.72.85-3.72.85,0,0-1.78.37-3.75.72-1.97.35-4.12.66-4.84.76-1.33.18-2.06.42-3.15.82,1.15.15,1.93.22,3.27.05.72-.09,2.9-.4,4.88-.76,1.98-.35,3.77-.73,3.77-.73,0,0,1.79-.37,3.75-.85,1.96-.47,4.07-1.06,4.77-1.26,1.29-.38,1.98-.75,2.97-1.35Z"
        />
        <path
          style={styles.eleven}
          d="m554.3,265.2c-1.94-1.72-3.08-2.92-5.35-4.79-.6-.52-1.81-1.57-3.21-2.96-1.41-1.37-3.02-3.07-4.59-4.69-1.57-1.62-3.13-3.13-4.33-4.21-1.2-1.08-2.03-1.71-2.03-1.71,0,0-.2-.16-.57-.46-.38-.27-.92-.67-1.58-1.15-1.34-.93-3.25-2.04-5.3-3.04-4.08-2.01-8.68-3.79-10.23-4.35-2.85-1.03-4.61-1.25-7.19-1.4,2.22,1.34,3.71,2.19,6.51,3.23,1.51.56,6.06,2.33,10.04,4.28,1.99.97,3.8,2.02,5.06,2.89.62.45,1.13.82,1.49,1.08.34.28.54.43.54.43,0,0,.79.61,1.94,1.64,1.16,1.04,2.67,2.51,4.23,4.12,1.56,1.6,3.18,3.32,4.62,4.72,1.43,1.42,2.69,2.51,3.33,3.05,2.39,1.94,4.09,2.68,6.61,3.29Z"
        />
        <path
          style={styles.eleven}
          d="m402.36,418.1c.83.02,1.38,0,2.32-.2,1.01-.22,6.09-1.36,6.09-1.36,0,0,5.08-1.13,6.09-1.36.93-.21,1.44-.44,2.18-.81-.83-.02-1.38,0-2.32.2-1.01.22-6.09,1.36-6.09,1.36,0,0-5.08,1.13-6.09,1.36-.93.21-1.44.44-2.18.81Z"
        />
        <path
          style={styles.eleven}
          d="m627.58,522.05c.26-.91.4-1.53.45-2.63.05-1.19.24-7.15.24-7.15,0,0,.2-5.96.24-7.15.03-1.1-.07-1.73-.27-2.65-.26.91-.4,1.53-.45,2.63-.05,1.19-.24,7.15-.24,7.15,0,0-.2,5.96-.24,7.15-.03,1.1.07,1.73.27,2.65Z"
        />
        <path
          style={styles.eleven}
          d="m288.65,746.92c1.49.68,2.5,1.09,4.36,1.47,1.01.21,4.03.83,6.78,1.46,2.76.62,5.25,1.24,5.25,1.24,0,0,2.5.62,5.23,1.36,2.73.73,5.69,1.59,6.68,1.88,1.82.53,2.91.64,4.54.74-1.4-.85-2.37-1.37-4.19-1.92-.99-.3-3.96-1.16-6.71-1.9-2.74-.74-5.25-1.36-5.25-1.36,0,0-2.51-.63-5.28-1.26-2.77-.64-5.8-1.26-6.82-1.46-1.87-.37-2.97-.36-4.61-.26Z"
        />
        <path
          style={styles.eleven}
          d="m308.46,766.84c.97.16,1.63.24,2.77.16.62-.04,2.47-.16,4.17-.27,1.7-.09,3.25-.17,3.25-.17,0,0,1.55-.06,3.25-.12,1.7-.04,3.56-.08,4.18-.1,1.14-.02,1.79-.16,2.74-.4-.96-.23-1.61-.36-2.75-.35-.62.01-2.48.06-4.19.09-1.71.06-3.26.12-3.26.12,0,0-1.55.08-3.26.16-1.71.11-3.56.23-4.18.27-1.14.09-1.78.27-2.71.58Z"
        />
        <path
          style={styles.eleven}
          d="m300.13,758.65c.57.13.95.2,1.63.19.37-.01,1.47-.05,2.47-.09,1.01-.06,1.92-.11,1.92-.11,0,0,.92-.07,1.92-.14,1-.09,2.1-.2,2.46-.23.67-.07,1.05-.19,1.59-.4-.58-.07-.96-.1-1.64-.04-.36.03-1.45.14-2.46.23-1,.08-1.91.14-1.91.14,0,0-.91.05-1.92.11-1,.04-2.1.08-2.46.09-.67.02-1.05.1-1.62.25Z"
        />
        <path d="m481.88,532.61c4.44,1.45,16.71,6.13,24.34,18.65,2.76,4.53,4.43,7.62,5.22,11.12.97-5.11,1.79-9.01,2.93-14.3.94-4.36,1.91-8.64,2.91-12.83-2.76.43-5.74.75-8.93.87-10.59.42-19.64-1.41-26.46-3.51Z" />
      </g>
    </g>
  );
};

export default Mummy;
