const BackpackOne = () => {
  const styles = {
    one: {
      fill: "#e5a83c",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
    two: {
      strokeWidth: 2,
      fill: "#e5a83c",
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="M321.94,637.66c.15-6.44,1.17-16.22,5.56-27.16,3.33-8.28,6.94-15.3,11-19,11.57-10.55,32.2-18.81,57-26,22.81-6.61,42.92-8.34,57.74-8.5-1.71,6.17-3.41,12.34-5.12,18.51-16.57,4.48-35.45,10.84-55.61,19.98-29.21,13.25-52.69,28.55-70.56,42.16Z"
      />
      <path
        style={styles.two}
        d="M512.11,559.31c13.78,0,34.31,1.68,57.39,10.19,16.09,5.94,30.04,12.79,38,20,3.91,3.54,7.69,9.72,11,18,2.89,7.23,3.92,13.72,4.3,18.34-12.28-9.24-29.38-20.23-51.3-29.34-23.85-9.91-45.37-14.26-61.4-16.25,.67-6.98,1.35-13.96,2.02-20.94Z"
      />
      <path
        style={styles.one}
        d="M370.69,606.29c3.77,25.58,6.31,56.78,4.81,92.21-1.79,42.16-8.78,78.2-16.34,106.36,5.78,3.05,11.57,6.11,17.35,9.16,9.29-32.95,17.89-74.35,20.99-122.52,2.35-36.63,1.02-69.49-1.81-97.43-8.33,4.07-16.67,8.14-25,12.22Z"
      />
      <path
        style={styles.one}
        d="M541.04,586.22c.5,29.71,3.42,65.09,11.46,104.28,10.99,53.61,28.13,97.72,44.03,130.99,5.65-2.49,11.3-4.98,16.95-7.47-12.69-29.99-25.81-67.81-34.99-112.52-8.27-40.25-11.38-76.49-12.05-106.72-8.47-2.85-16.93-5.71-25.4-8.56Z"
      />
    </g>
  );
};

export default BackpackOne;
