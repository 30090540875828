import { Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import useMiddleware from "../../api/useMiddleware";
import { cancelSubscription } from "../../api/user";
import { SET_ERRORS, SET_SUCCESSES, StoreDispatch } from "../../store/Store";
import {
  cardLight,
  normal,
  secondaryText,
  small,
  text,
  purple,
} from "../../utils/themeContstants";
import CustomCheckbox from "../custom/CustomCheckbox";
import CustomModal from "../custom/CustomModal";
import SecondaryButton from "../custom/SecondaryButton";

const CancelModal = ({ open, onClose, closeModal }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(false);
  const [understands, setUnderstands] = useState(false);

  const handleClose = () => {
    setLoading(false);
    setUnderstands(false);
    onClose();
  };

  const handleCancel = () => {
    setLoading(true);
    cancelSubscription(middleware).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        setUnderstands(false);
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
        closeModal();
      }
    });
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
      maxWidth: 450,
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      backgroundColor={cardLight}
      title={"Unsubscribe"}
      primaryButton={
        <SecondaryButton
          label="Unsubscribe"
          disabled={!understands}
          onClick={handleCancel}
          loading={loading}
        />
      }
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Typography sx={styles.description}>
            You are about to unsubscribe from VIP. Your benefits will continue
            until the expiration date.
          </Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 2 }}
          >
            <Grid>
              <CustomCheckbox
                checked={understands}
                onChange={(value) => setUnderstands(value)}
                color={purple}
                disabled={loading}
              />
            </Grid>

            <Grid>
              <Typography
                sx={{
                  fontSize: small,
                  color: secondaryText,
                  fontWeight: 600,
                  maxWidth: 400,
                }}
              >
                By checking this box you understand that your VIP will not renew
                after the expiration date once you unsubscribe
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default CancelModal;
