export const getColor = (color) => {
  switch (color) {
    case "hunter":
      return "#dfe9e1";
    case "my_hero":
      return "#4b1221";
    case "demon":
      return "#582d35";
    case "full_black":
      return "#191919";
    case "auburn":
      return "#A55728";
    case "black":
      return "#2C1B18";
    case "blonde":
      return "#B58143";
    case "blonde_golden":
      return "#D6B370";
    case "brown":
      return "#724133";
    case "brown_dark":
      return "#4A312C";
    case "pastel_pink":
      return "#F59797";
    case "blue":
      return "#000fdb";
    case "platinum":
      return "#ECDCBF";
    case "red":
      return "#C93305";
    case "silver":
      return "#E8E1E1";
    case "blue1":
      return "#65C9FF";
    case "blue2":
      return "#5199E4";
    case "blue3":
      return "#25557C";
    case "gray1":
      return "#E6E6E6";
    case "gray2":
      return "#929598";
    case "heather":
      return "#3C4F5C";
    case "pastel_blue":
      return "#B1E2FF";
    case "pastel_green":
      return "#A7FFC4";
    case "pastel_orange":
      return "#FFDEB5";
    case "pastel_red":
      return "#FFAFB9";
    case "pastel_yellow":
      return "#FFFFB1";
    case "pink":
      return "#FF488E";
    case "red":
      return "#FF5C5C";
    case "white":
      return "#FFFFFF";
    case "black_clothes":
      return "#262E33";
    case "tanned_skin":
      return "#FD9841";
    case "yellow_skin":
      return "#F8D25C";
    case "pale_skin":
      return "#FFDBB4";
    case "light_skin":
      return "#EDB98A";
    case "brown_skin":
      return "#D08B5B";
    case "dark_brown_skin":
      return "#AE5D29";
    case "black_skin":
      return "#614335";
    case "green_skin":
      return "#39b54a";
    case "purple_skin":
      return "#8338ec";
    case "pink_skin":
      return "#ff006e";
    case "blue_skin":
      return "#4361ee";
    case "neon_green_skin":
      return "#39FF14";
    case "luminous_blue_skin":
      return "#0000FF";
    case "alien_gray_skin":
      return "#A0A0A0";
    case "galactic_purple_skin":
      return "#800080";
    case "deep_space_blue_skin":
      return "#000080";
    case "sunshine_yellow_skin":
      return "#FFFF00";
    case "hot_pink_skin":
      return "#FF00FF";
    case "ruby_red_skin":
      return "#8B0000";
    case "shiny_gold_skin":
      return "#FFD700";
    case "metallic_silver_skin":
      return "#C0C0C0";
    case "copper_skin":
      return "#B87333";
    case "fluorescent_orange_skin":
      return "#FF7F00";
    case "glowing_cyan_skin":
      return "#00FFFF";
    case "pearl_white_skin":
      return "#F5F5F5";
    case "midnight_black_skin":
      return "#696969";
    case "moon_glow_skin":
      return "#F0E68C";
    case "emerald_green_skin":
      return "#008000";
    case "sapphire_blue_skin":
      return "#0000CD";
    case "mystic_maroon_skin":
      return "#800000";
    case "royal_indigo_skin":
      return "#4B0082";
    case "charcoal_skin":
      return "#1A1A1A";
    default:
      return color;
  }
};
