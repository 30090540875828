import { CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import {
  adminUserSearch,
  chatBanUser,
  chatUnbanUser,
  forceAnticheat,
  giveBp,
  giveVip,
  searchUser,
  unlinkEpic,
  unlinkPaypal,
  unlockFunds,
  verifyUserSecurity,
  clearBanDebt,
  resetBalance,
  forceStream,
  lockTipping
} from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import Avatar from "../../avatar/Avatar";
import {
  SET_ERRORS,
  SET_SEARCH_USER,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { AccountTypeEnum } from "../../utils/enums";
import {
  currencyFormatter,
  getDateFromId,
  getReadableDateString,
  getTime,
  isWithinOneMinute,
} from "../../utils/helpers";
import {
  card,
  cardDark,
  cardLight,
  large,
  modalShadow,
  normal,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";
import CustomInput from "../custom/CustomInput";
import OutlineButton from "../custom/OutlineButton";
import PrimaryButton from "../custom/PrimaryButton";
import AddFundsModal from "./AddFundsModal";
import BanUserModal from "./BanUserModal";
import BanLengthModal from "./BanLengthModal";
import InfoItem from "./InfoItem";
import SetRoleModal from "./SetRoleModal";
import StaffSearchResultItem from "./StaffSearchResultItem";
import UserAlts from "./UserAlts";
import UserDeposits from "./UserDeposits";
import UserHistory from "./UserHistory";
import UserLocker from "./UserLocker";
import UserLogs from "./UserLogs";
import UserNotes from "./UserNotes";
import UserPurchases from "./UserPurchases";
import UserTips from "./UserTips";
import UserWithdrawals from "./UserWithdrawals";
import UserMoneyLogs from "./UserMoneyLogs";
import AddSnipesModal from "./AddSnipesModal";
import UserACActivity from "./ac_dashboard/UserACActivity";
import UnbanModal from "./UnbanModal";
import UserTranscripts from "./UserDiscordTranscripts";
import UnlinkModal from "./UnlinkModal";

const StaffSearch = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const location = useLocation();

  const [query, setQuery] = useState("");
  const [results, setResults] = useState(null);
  const [user, setUser] = useState(null);
  const [verifiable, setVerifiable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [selected, setSelected] = useState("info");

  // actions
  const [banned, setBanned] = useState(false);
  const [chatBanned, setChatBanned] = useState(false);
  const [unlockable, setUnlockableFunds] = useState(false);
  const [forceable, setAnticheatForceable] = useState(false);
  const [forceableStream, setForceableStream] = useState(false);
  const [isTippingLocked, setIsTippingLocked] = useState(false);
  const [lockTippingLoading, setLockTippingLoading] = useState(false);
  const [paypal, setPaypal] = useState(false);
  const [vip, setVip] = useState(false);
  const [bp, setBp] = useState(false);
  const [banDebt, setBanDebt] = useState(false);
  const [resetBalanceLoading, setResetBalanceLoading] = useState(false);
  const [epic, setEpic] = useState(false);
  const [banLoading, setBanLoading] = useState(false);
  const [banLengthLoading, setBanLengthLoading] = useState(false);
  const [chatBanLoading, setChatBanLoading] = useState(false);
  const [unlockFundsLoading, setUnlockFundsLoading] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);
  const [forceStreamLoading, setForceStreamLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [paypalLoading, setPaypalLoading] = useState(false);
  const [vipLoading, setVipLoading] = useState(false);
  const [bpLoading, setBpLoading] = useState(false);
  const [banDebtLoading, setBanDebtLoading] = useState(false);
  const [epicLoading, setEpicLoading] = useState(false);
  const [role, setRole] = useState(null);
  const [open, setOpen] = useState(false);
  const [fundsOpen, setFundsOpen] = useState(false);
  const [unlinkOpen, setUnlinkOpen] = useState(false);
  const [snipesOpen, setSnipesOpen] = useState(false);
  const [reasonOpen, setReasonOpen] = useState(false);
  const [balance, setBalance] = useState(null);
  const [banDebtAmount, setBanDebtAmount] = useState(null);
  const [snipes, setSnipes] = useState(null);
  const [reason, setReason] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [unbanOpen, setUnbanOpen] = useState(false);
  const [banLengthOpen, setBanLengthOpen] = useState(false);
  const [unbanTimestamp, setUnbanTimestamp] = useState(null);
  const handleOpenProfile = (user_id) => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const handleFetchUsers = (searchQuery) => {
    adminUserSearch(middleware, searchQuery).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setResults(res?.userArray);
      }
    });
  };

  const handleSearch = (searchQuery) => {
    setUser(null);
    setHovered(null);

    if (searchQuery == "" || searchQuery == null || searchQuery?.length >= 33) {
      setResults(null);
      return;
    }
    setLoading(true);
    handleFetchUsers(searchQuery);
  };

  const handleUserClick = (user_id) => {
    setResults(null);
    setQuery("");
    setUser(null);
    setHovered(null);
    setLoading(false);
    setUserLoading(true);
    store?.user?.account_type >= AccountTypeEnum.JUNIOR_ADMIN
      ? setSelected("info")
      : setSelected("notes");
    // setSelected("info");
    searchUser(middleware, user_id).then((res) => {
      setUserLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setUser(res?.userdata);
      }
    });
  };

  const handleUnlinkEpic = () => {
    setEpicLoading(true);
    unlinkEpic(middleware, user?._id).then((res) => {
      setEpicLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setEpic(false);
      }
    });
  };

  const handleUnlinkPaypal = () => {
    setPaypalLoading(true);
    unlinkPaypal(middleware, user?._id).then((res) => {
      setPaypalLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setPaypal(false);
      }
    });
  };

  const handleGiveVip = () => {
    setVipLoading(true);
    giveVip(middleware, user?._id).then((res) => {
      setVipLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        setVip(true);
      }
    });
  };

  const handleGiveBp = () => {
    setBpLoading(true);
    giveBp(middleware, user?._id).then((res) => {
      setBpLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        setBp(true);
      }
    });
  };

  const handleClearBanDebt = () => {
    setBanDebtLoading(true);
    clearBanDebt(middleware, user?._id).then((res) => {
      setBanDebtLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        setBanDebt(false);
        setBanDebtAmount(0);
      }
    });
  };

  const handleResetBalance = () => {
    setResetBalanceLoading(true);
    resetBalance(middleware, user?._id).then((res) => {
      setResetBalanceLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        setBanDebt(true);
        setBanDebtAmount(banDebtAmount + balance);
      }
    });
  };

  const handleUnbanUserChat = () => {
    setChatBanLoading(true);
    chatUnbanUser(middleware, user?._id).then((res) => {
      setChatBanLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setChatBanned(false);
      }
    });
  };
  const handleBanUserChat = () => {
    setChatBanLoading(true);
    chatBanUser(middleware, user?._id).then((res) => {
      setChatBanLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setChatBanned(true);
      }
    });
  };

  const handleUnlockFunds = () => {
    setUnlockFundsLoading(true);
    unlockFunds(middleware, user?._id).then((res) => {
      // unlockFundsLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setUnlockFundsLoading(false);
        setUnlockableFunds(false);
      }
    });
  };

  const handleForceAc = () => {
    setForceLoading(true);
    forceAnticheat(middleware, user?._id).then((res) => {
      // unlockFundsLoading(false);
      setForceLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setForceLoading(false);
        if (forceable) {
          setAnticheatForceable(false);
        }
        if (!forceable) {
          setAnticheatForceable(true);
        }
      }
    });
  };
  const handleForceStream = () => {
    setForceStreamLoading(true);
    forceStream(middleware, user?._id).then((res) => {
      // unlockFundsLoading(false);
      setForceStreamLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        setForceStreamLoading(false);
        if (forceableStream) {
          setForceableStream(false);
        }
        if (!forceableStream) {
          setForceableStream(true);
        }
      }
    });
  };
  const handleLockTipping = () => {
    setLockTippingLoading(true);
    lockTipping(middleware, user?._id).then((res) => {
      setLockTippingLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        setLockTippingLoading(false);
        if (isTippingLocked) {
          setIsTippingLocked(false);
        }
        if (!isTippingLocked) {
          setIsTippingLocked(true);
        }
      }
    });
  };
  const handleVerifyUser = () => {
    setVerifyLoading(true);
    verifyUserSecurity(middleware, user?._id).then((res) => {
      // unlockFundsLoading(false);
      setVerifyLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        setVerifyLoading(false);
        setVerifiable(false);
      }
    });
  };

  useEffect(() => {
    if (user) {
      setEpic(user?.connections?.epic?.connection_id);
      setPaypal(user?.connections?.paypal?.email);
      setBanned(user?.behavior_info?.banned);
      setUnbanTimestamp(user?.behavior_info?.unban_timestamp);
      setChatBanned(
        new Date(user?.behavior_info?.match_chat_unban_timestamp) > new Date()
      );
      setRole(user?.account_type);
      setBalance(user?.funds?.balance);
      setSnipes(user?.premium_info?.snipes);
      setVip(user?.premium_info?.active);
      setBp(user?.battle_pass_info?.is_active);
      setBanDebt(user?.behavior_info?.has_debt);
      setBanDebtAmount(user?.behavior_info?.debt_price);
      setUnlockableFunds(
        user?.funds?.max_withdrawal_amount < user?.funds?.balance
      );
      setAnticheatForceable(!user?.anticheat?.must_run_ac);
      setForceableStream(user?.anticheat?.must_stream);
      setIsTippingLocked(user?.behavior_info?.tip_locked);
      setVerifiable(user?.must_be_verified);
    }
  }, [user]);

  useEffect(() => {
    if (location?.state?.user_id) {
      setLoading(false);
      setUserLoading(true);
      setSelected("info");
      searchUser(middleware, location?.state?.user_id).then((res) => {
        setUserLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          setUser(res?.userdata);
        }
      });
    }
  }, [location?.state]);

  const styles = {
    container: {
      width: "100%",
      borderRadius: 1,
      padding: 2,
      boxShadow: modalShadow,
      backgroundColor: card,
    },
    width: {
      width: "100%",
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    subtitle: {
      fontSize: 14,
      color: secondaryText,
      fontWeight: 600,
    },
    noResultsText: {
      fontSize: 18,
      color: text,
      fontWeight: 600,
    },
    userContainer: {
      width: "100%",
      borderRadius: 1,
      padding: 2,
      backgroundColor: card,
      boxShadow: modalShadow,
    },
    username: {
      fontSize: large,
      fontWeight: 800,
      color: user?.premium_info?.active ? "rgb(255, 255, 193)" : text,
      textShadow: user?.premium_info?.active
        ? "rgb(255 93 0) 0px 0px 10px"
        : null,
    },
    epic: {
      fontSize: normal,
      fontWeight: 400,
      color: text,
    },
    subHeader: {
      fontSize: small,
      fontWeight: 600,
      color: secondaryText,
    },
    activeMatch: {
      fontSize: normal,
      fontWeight: 600,
      color: "#1E90FF",
      textDecoration: "underline",
      cursor: "pointer",
    },
  };

  return (
    <>
      <SetRoleModal
        open={open}
        onClose={() => setOpen(false)}
        user={user}
        role={role}
        setRole={setRole}
      />
      <AddFundsModal
        user={user}
        open={fundsOpen}
        onClose={() => setFundsOpen(false)}
        balance={balance}
        setBalance={setBalance}
      />{" "}
      <UnlinkModal
        user={user}
        open={unlinkOpen}
        onClose={() => setUnlinkOpen(false)}
        setEpic={setEpic}
      />
      <AddSnipesModal
        user={user}
        open={snipesOpen}
        onClose={() => setSnipesOpen(false)}
        snipes={snipes}
        setSnipes={setSnipes}
      />
      <BanUserModal
        user={user}
        open={reasonOpen}
        onClose={() => setReasonOpen(false)}
        reason={reason}
        setReason={setReason}
        setBanned={setBanned}
      />
      <UnbanModal
        user={user}
        open={unbanOpen}
        onClose={() => setUnbanOpen(false)}
        setBanned={setBanned}
      />
      <BanLengthModal
        user={user}
        open={banLengthOpen}
        onClose={() => setBanLengthOpen(false)}
        setUnbanTimestamp={setUnbanTimestamp}
      />
      <Grid item>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <FaSearch style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>SEARCH</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={styles.container}>
        <CustomInput
          value={query}
          placeholder="Search users..."
          autoFocus={true}
          onChange={(val) => {
            setQuery(val);
            handleSearch(val);
          }}
          onPaste={(val) => {
            setQuery(val);
            handleSearch(val);
          }}
        />
      </Grid>
      {loading && (
        <Grid
          item
          alignSelf="center"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}
      {!loading &&
        query !== "" &&
        query != null &&
        (results == null || results?.length < 1) && (
          <Grid item sx={{ width: "100%", padding: 2 }}>
            <Typography sx={styles.noResultsText}>
              No results for <span style={{ fontWeight: 800 }}>{query}</span>
            </Typography>
          </Grid>
        )}
      {!loading && results && results?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            {results?.map((user, i) => {
              return (
                <StaffSearchResultItem
                  user={user}
                  key={i}
                  onClick={() => handleUserClick(user?.user_id)}
                />
              );
            })}
          </Grid>
        </Grid>
      )}
      {!loading && !userLoading && user && (
        <>
          <Grid item sx={styles.userContainer}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 1 }}
                >
                  <Grid
                    item
                    onMouseEnter={() => setHovered("avatar")}
                    onMouseLeave={() => setHovered(null)}
                    onClick={() => handleOpenProfile(user?._id)}
                    sx={{ marginLeft: -2 }}
                  >
                    <Avatar
                      size={200}
                      avatar={user?.new_avatar}
                      bgColor={hovered === "avatar" ? cardLight : cardDark}
                      sx={{
                        cursor: hovered === "avatar" ? "pointer" : "default",
                        transition: transition,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid
                        item
                        onMouseEnter={() => setHovered("username")}
                        onMouseLeave={() => setHovered(null)}
                        onClick={() => handleOpenProfile(user?._id)}
                      >
                        <Typography
                          sx={{
                            ...styles.username,
                            cursor:
                              hovered === "username" ? "pointer" : "default",
                            color: hovered === "username" ? yellow : text,
                          }}
                        >
                          {user?.username}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.subtitle}>
                          {`${currencyFormatter().format(
                            balance
                          )} (${currencyFormatter().format(
                            balance - user?.funds?.max_withdrawal_amount
                          )} locked)`}
                        </Typography>

                        <Typography sx={styles.subHeader}>
                          Account created{" "}
                          {getReadableDateString(getDateFromId(user?._id)) +
                            " at " +
                            getTime(getDateFromId(user?._id))}
                        </Typography>

                        {user?.info?.past_epic_ids?.length > 1 && (
                          <Typography sx={styles.subHeader}>
                            Has been unlinked:{" "}
                            {user?.info?.past_epic_ids?.length - 1 ?? "N/A"}x
                          </Typography>
                        )}

                        {user?.match_info?.match_id && (
                          <Typography
                            sx={styles.subtitle}
                            onClick={() =>
                              window.open(
                                "https://rivox.gg/token/" +
                                  user?.match_info?.match_id
                              )
                            }
                          >
                            <span style={styles.activeMatch}>Active Match</span>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  {store?.user?.account_type >=
                    AccountTypeEnum.JUNIOR_ADMIN && (
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid item>
                          <PrimaryButton
                            label="Set Role"
                            type="secondary"
                            size="small"
                            onClick={() => setOpen(true)}
                          />
                        </Grid>

                        <Grid item>
                          <PrimaryButton
                            label={banned ? "Unban" : "Ban"}
                            type="secondary"
                            size="small"
                            loading={banLoading}
                            onClick={() => {
                              if (banned) {
                                setUnbanOpen(true);
                              } else {
                                setReasonOpen(true);
                              }
                            }}
                          />
                        </Grid>

                        {banned && (
                          <Grid item>
                            <PrimaryButton
                              label="Set Unban Date"
                              type="secondary"
                              size="small"
                              loading={banLengthLoading}
                              onClick={() => setBanLengthOpen(true)}
                            />
                          </Grid>
                        )}

                        {
                          <Grid item>
                            <PrimaryButton
                              label={chatBanned ? "CHAT UNBAN" : "CHAT BAN"}
                              type="secondary"
                              size="small"
                              loading={chatBanLoading}
                              onClick={() => {
                                if (chatBanned) {
                                  handleUnbanUserChat();
                                } else {
                                  handleBanUserChat();
                                }
                              }}
                            />
                          </Grid>
                        }

                        {epic && (
                          <Grid item>
                            <PrimaryButton
                              label={"Unlink Game Account"}
                              type="secondary"
                              size="small"
                              loading={epicLoading}
                              onClick={() => setUnlinkOpen(true)}
                            />
                          </Grid>
                        )}
                        {forceable && (
                          <PrimaryButton
                            label="Force Anticheat"
                            type="secondary"
                            size="small"
                            loading={forceLoading}
                            onClick={() => handleForceAc()}
                          />
                        )}
                        {!forceable && (
                          <PrimaryButton
                            label="Remove Forced Anticheat"
                            type="secondary"
                            size="small"
                            loading={forceLoading}
                            onClick={() => handleForceAc()}
                          />
                        )}
                        {!forceableStream && (
                          <PrimaryButton
                            label="Force Stream"
                            type="secondary"
                            size="small"
                            loading={forceStreamLoading}
                            onClick={() => handleForceStream()}
                          />
                        )}
                        {forceableStream && (
                          <PrimaryButton
                            label="Remove Forced Stream"
                            type="secondary"
                            size="small"
                            loading={forceStreamLoading}
                            onClick={() => handleForceStream()}
                          />
                        )}
                        {!isTippingLocked && (
                          <PrimaryButton
                            label="Lock tipping"
                            type="secondary"
                            size="small"
                            loading={lockTippingLoading}
                            onClick={() => handleLockTipping()}
                          />
                        )}
                        {isTippingLocked && (
                          <PrimaryButton
                            label="Unlock tipping"
                            type="secondary"
                            size="small"
                            loading={lockTippingLoading}
                            onClick={() => handleLockTipping()}
                          />
                        )}
                        {verifiable && (
                          <PrimaryButton
                            label="Verify User"
                            type="secondary"
                            size="small"
                            loading={verifyLoading}
                            onClick={() => handleVerifyUser()}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {store?.user?.account_type >= AccountTypeEnum.GOD && (
                    <Typography sx={styles.subtitle}>
                      MANAGEMENT CONTROLS
                    </Typography>
                  )}
                  {store?.user?.account_type >=
                    AccountTypeEnum.SENIOR_ADMIN && (
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 1 }}
                    >
                      <PrimaryButton
                        label="Add Funds"
                        type="secondary"
                        size="small"
                        onClick={() => setFundsOpen(true)}
                      />
                      {store?.user?.account_type >= AccountTypeEnum.GOD &&
                        store?.user?.account_type !==
                          AccountTypeEnum.SENIOR_ADMIN && (
                          <PrimaryButton
                            label="Add Snipes"
                            type="secondary"
                            size="small"
                            onClick={() => setSnipesOpen(true)}
                          />
                        )}
                      {store?.user?.account_type >= AccountTypeEnum.GOD &&
                        store?.user?.account_type !==
                          AccountTypeEnum.SENIOR_ADMIN &&
                        unlockable && (
                          <PrimaryButton
                            label="Unlock Funds"
                            type="secondary"
                            size="small"
                            loading={unlockFundsLoading}
                            onClick={() => handleUnlockFunds()}
                          />
                        )}

                      {store?.user?.account_type >= AccountTypeEnum.GOD &&
                        store?.user?.account_type !==
                          AccountTypeEnum.SENIOR_ADMIN &&
                        !vip && (
                          <Grid item>
                            <PrimaryButton
                              label={"Give VIP (30d)"}
                              type="secondary"
                              size="small"
                              loading={vipLoading}
                              onClick={() => handleGiveVip()}
                            />
                          </Grid>
                        )}

                      {store?.user?.account_type >= AccountTypeEnum.GOD &&
                        store?.user?.account_type !==
                          AccountTypeEnum.SENIOR_ADMIN &&
                        !bp && (
                          <Grid item>
                            <PrimaryButton
                              label={"Give BP"}
                              type="secondary"
                              size="small"
                              loading={bpLoading}
                              onClick={() => handleGiveBp()}
                            />
                          </Grid>
                        )}
                      {store?.user?.account_type >= AccountTypeEnum.GOD &&
                        store?.user?.account_type !==
                          AccountTypeEnum.SENIOR_ADMIN &&
                        banDebt && (
                          <Grid item>
                            <PrimaryButton
                              label={"Clear Ban Debt"}
                              type="secondary"
                              size="small"
                              loading={banDebtLoading}
                              onClick={() => handleClearBanDebt()}
                            />
                          </Grid>
                        )}

                      {store?.user?.account_type >=
                        AccountTypeEnum.SENIOR_ADMIN && (
                        <Grid item>
                          <PrimaryButton
                            label={"Reset Balance"}
                            type="secondary"
                            size="small"
                            loading={resetBalanceLoading}
                            onClick={() => handleResetBalance()}
                          />
                        </Grid>
                      )}

                      {paypal && (
                        <Grid item>
                          <PrimaryButton
                            label={"Unlink Paypal"}
                            type="secondary"
                            size="small"
                            loading={paypalLoading}
                            onClick={() => handleUnlinkPaypal()}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {store?.user?.account_type == AccountTypeEnum.ADMIN && (
                    <Grid item>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 1 }}
                      >
                        {
                          <Grid item>
                            <PrimaryButton
                              label={chatBanned ? "CHAT UNBAN" : "CHAT BAN"}
                              type="secondary"
                              size="small"
                              loading={chatBanLoading}
                              onClick={() => {
                                if (chatBanned) {
                                  handleUnbanUserChat();
                                } else {
                                  handleBanUserChat();
                                }
                              }}
                            />
                          </Grid>
                        }
                        {/* {epic && (
                            <Grid item>
                              <PrimaryButton
                                label={"Unlink Game Account"}
                                type="secondary"
                                size="small"
                                loading={epicLoading}
                                onClick={() => setUnlinkOpen(true)}
                              />
                            </Grid>
                          )} */}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <Grid item>
                    {store?.user?.account_type >=
                      AccountTypeEnum.JUNIOR_ADMIN && (
                      <OutlineButton
                        label="Info"
                        selected={selected === "info"}
                        onClick={() => setSelected("info")}
                      />
                    )}
                  </Grid>

                  <Grid item>
                    {store?.user?.account_type >=
                      AccountTypeEnum.JUNIOR_ADMIN && (
                      <OutlineButton
                        label="Alts"
                        selected={selected === "alts"}
                        onClick={() => setSelected("alts")}
                      />
                    )}
                  </Grid>

                  {store?.user?.account_type >=
                    AccountTypeEnum.JUNIOR_ADMIN && (
                    <Grid item>
                      <OutlineButton
                        label="Logs"
                        selected={selected === "logs"}
                        onClick={() => setSelected("logs")}
                      />
                    </Grid>
                  )}

                  {store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                    <Grid item>
                      <OutlineButton
                        label="Money Logs"
                        selected={selected === "moneylogs"}
                        onClick={() => setSelected("moneylogs")}
                      />
                    </Grid>
                  )}

                  {store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                    <Grid item>
                      <OutlineButton
                        label="Notes"
                        selected={selected === "notes"}
                        onClick={() => setSelected("notes")}
                      />
                    </Grid>
                  )}

                  {store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                    <Grid item>
                      <OutlineButton
                        label="History"
                        selected={selected === "history"}
                        onClick={() => setSelected("history")}
                      />
                    </Grid>
                  )}
                  {store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                    <Grid item>
                      <OutlineButton
                        label="Discord Transcripts"
                        selected={selected === "transcripts"}
                        onClick={() => setSelected("transcripts")}
                      />
                    </Grid>
                  )}

                  {store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                    <Grid item>
                      <OutlineButton
                        label="Withdrawals"
                        selected={selected === "withdrawals"}
                        onClick={() => setSelected("withdrawals")}
                      />
                    </Grid>
                  )}

                  {store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                    <Grid item>
                      <OutlineButton
                        label="Deposits"
                        selected={selected === "deposits"}
                        onClick={() => setSelected("deposits")}
                      />
                    </Grid>
                  )}

                  {store?.user?.account_type >=
                    AccountTypeEnum.JUNIOR_ADMIN && (
                    <Grid item>
                      <OutlineButton
                        label="Tips"
                        selected={selected === "tips"}
                        onClick={() => setSelected("tips")}
                      />
                    </Grid>
                  )}

                  {store?.user?.account_type >=
                    AccountTypeEnum.JUNIOR_ADMIN && (
                    <Grid item>
                      <OutlineButton
                        label="Purchases"
                        selected={selected === "purchases"}
                        onClick={() => setSelected("purchases")}
                      />
                    </Grid>
                  )}

                  {store?.user?.account_type >=
                    AccountTypeEnum.SENIOR_ADMIN && (
                    <Grid item>
                      <OutlineButton
                        label="Locker"
                        selected={selected === "locker"}
                        onClick={() => setSelected("locker")}
                      />
                    </Grid>
                  )}

                  {(store?.user?.account_type === AccountTypeEnum.GOD ||
                    store?.user?.account_type ===
                      AccountTypeEnum.ANTICHEAT) && (
                    <Grid item>
                      <OutlineButton
                        label="AC Activity"
                        selected={selected === "ac"}
                        onClick={() => setSelected("ac")}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {selected === "info" &&
            store?.user?.account_type >= AccountTypeEnum.JUNIOR_ADMIN && (
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 0.5 }}
                >
                  <InfoItem label="User ID" value={user?._id ?? "N/A"} />
                  <InfoItem
                    label="Discord ID"
                    value={user?.connections?.discord?.connection_id ?? "N/A"}
                  />
                  {user?.connections?.twitch?.connection_id && (
                    <InfoItem
                      label="Twitch ID & Username"
                      value={
                        user?.connections?.twitch?.connection_id +
                          " / " +
                          user.connections?.twitch?.name ?? "N/A"
                      }
                    />
                  )}
                  {user?.info?.past_epic_ids?.length > 0 && (
                    <InfoItem
                      label="Past Game IDs"
                      value={user?.info?.past_epic_ids.toString() ?? "N/A"}
                    />
                  )}
                  {/* {user?.info?.past_epic_ids?.length > 1 && (
                    <InfoItem
                      label="Unlinked X Times"
                      value={user?.info?.past_epic_ids?.length - 1 ?? "N/A"}
                    />
                  )} */}
                  {user?.connections?.epic?.connection_id && (
                    <InfoItem
                      label="Current Game ID"
                      value={
                        user?.connections?.epic?.connection_id?.toString() ??
                        "N/A"
                      }
                      isEpic={true}
                    />
                  )}{" "}
                  {user?.anticheat?.must_run_ac && (
                    <InfoItem
                      label="Is Running Anticheat"
                      value={
                        isWithinOneMinute(
                          user?.anticheat?.last_heartbeat_timestamp
                        )
                          ? "Correctly running since " +
                            getReadableDateString(
                              new Date(
                                user?.anticheat?.last_heartbeat_timestamp
                              )
                            ) +
                            " at " +
                            getTime(
                              new Date(
                                user?.anticheat?.last_heartbeat_timestamp
                              )
                            )
                          : "Not Running Anticheat"
                      }
                    />
                  )}
                  {user?.info.past_paypals.length > 0 &&
                    store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                      <InfoItem
                        label="Past Paypals Linked"
                        value={user?.info?.past_paypals.toString() ?? "N/A"}
                      />
                    )}
                  {user?.connections?.paypal?.email &&
                    store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                      <InfoItem
                        label="Current Paypal Linked"
                        value={user?.connections?.paypal?.email ?? "N/A"}
                      />
                    )}
                  {store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                    <InfoItem
                      label="Email"
                      value={user?.info?.email ?? "N/A"}
                    />
                  )}
                  {/* {store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                    <>
                      <InfoItem
                        label="Balance"
                        value={`${currencyFormatter().format(balance)} (${currencyFormatter().format(balance - user?.funds?.max_withdrawal_amount)} locked)`}
                      />
                      <InfoItem
                        label="Max Withdrawal Amount"
                        value={currencyFormatter().format(user?.funds?.max_withdrawal_amount)}
                      />
                    </>
                  )} */}
                  {store?.user?.account_type >=
                    AccountTypeEnum.JUNIOR_ADMIN && (
                    <>
                      {banDebt && (
                        <InfoItem
                          label="Debt For Unban"
                          value={currencyFormatter().format(banDebtAmount)}
                        />
                      )}
                      {user?.behavior_info?.has_match_debt && (
                        <InfoItem
                          label="Debt For Match"
                          value={currencyFormatter().format(
                            user?.behavior_info?.debt_reset_price
                          )}
                        />
                      )}
                      {user?.behavior_info?.has_unlink_debt && (
                        <InfoItem
                          label="Debt For Unlink"
                          value={currencyFormatter().format(
                            user?.behavior_info?.unlink_debt_price
                          )}
                        />
                      )}
                    </>
                  )}
                  <InfoItem label="Banned" value={banned ? "True" : "False"} />
                  {banned == true && (
                    <InfoItem
                      label="Unban Date"
                      value={
                        getReadableDateString(
                          unbanTimestamp
                            ? new Date(unbanTimestamp)
                            : new Date(user?.ban_info?.unban_timestamp)
                        ) +
                        " at " +
                        getTime(
                          unbanTimestamp
                            ? new Date(unbanTimestamp)
                            : new Date(user?.ban_info?.unban_timestamp)
                        ) +
                        " (ADMIN'S TIMEZONE)"
                      }
                    />
                  )}
                  <InfoItem
                    label="Prior Bans"
                    value={user?.behavior_info?.prior_bans}
                  />
                  <InfoItem
                    label="Behavior Points"
                    value={user?.behavior_info?.behavior_points}
                  />
                  <InfoItem label="Account Type" value={role} />
                </Grid>
              </Grid>
            )}

          {selected === "alts" && (
            <UserAlts user={user} onClick={handleUserClick} />
          )}

          {selected === "logs" && (
            <UserLogs user={user} onClick={handleUserClick} />
          )}
          {selected === "moneylogs" && (
            <UserMoneyLogs user={user} onClick={handleUserClick} />
          )}

          {selected === "notes" && <UserNotes user={user} />}

          {selected === "history" && <UserHistory user={user} />}
          {selected === "transcripts" && <UserTranscripts user={user} />}

          {selected === "withdrawals" && <UserWithdrawals user={user} />}

          {selected === "deposits" && <UserDeposits user={user} />}

          {selected === "tips" && <UserTips user={user} />}

          {selected === "purchases" && <UserPurchases user={user} />}

          {selected === "locker" && (
            <UserLocker user={user} setUser={setUser} />
          )}

          {selected === "ac" && <UserACActivity user={user} />}
        </>
      )}
    </>
  );
};

export default StaffSearch;
