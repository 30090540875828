import React from "react";

function MapFrame() {
  return (
    <iframe
      src="https://fortnite.gg/creative?creator=0delay"
      width="100%"
      height="500"
      frameBorder="0"
      allowFullScreen
      title="Fortnite"
    ></iframe>
  );
}

export default MapFrame;
