import Clown from "./types/Clown";
import Default from "./types/Default";
import DefaultPierced from "./types/DefaultPierced";
import Injured from "./types/Injured";
import InjuredPierced from "./types/InjuredPierced";
import NoseHoop from "./types/NoseHoop";
import Pointy from "./types/Pointy";
import PointyPierced from "./types/PointyPierced";
import Squidward from "./types/Squidward";
import SquidwardPierced from "./types/SquidwardPierced";

const Nose = ({ type }) => {
  switch (type) {
    case "squidward":
      return <Squidward />;
    case "pointy":
      return <Pointy />;
    case "default_nose":
      return <Default />;
    case "clown":
      return <Clown />;
    case "injured":
      return <Injured />;
    case "squidward_pierced":
      return <SquidwardPierced />;
    case "pointy_pierced":
      return <PointyPierced />;
    case "default_nose_pierced":
      return <DefaultPierced />;
    case "injured_pierced":
      return <InjuredPierced />;
    case "nose_hoop":
      return <NoseHoop />;
    default:
      return null;
  }
};

export default Nose;
