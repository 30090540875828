import axios from "axios";
import { API_ROUTE, errMessage } from "../utils/constants";

export const getStreams = async (skip) => {
  const params = { skip };

  return await axios
    .get(`${API_ROUTE}/home/streams`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getStats = async () => {
  return await axios
    .get(`${API_ROUTE}/home/stats`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
