import { Grid } from "@mui/material";
import GameItem from "../games/GameItem";
import CustomModal from "./CustomModal";

const SelectGameModal = ({ open, onClose, title }) => {
  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      title={title}
      titleSize="large"
      description="Select a game to continue."
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={{ width: "100%", marginTop: 2 }}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 2 }}
          >
            <GameItem
              game="fortnite"
              onClick={() => onClose("fortnite")}
              size={"small"}
            />

            {/* <GameItem
              game="warzone"
              onClick={() => onClose("warzone")}
              size="small"
            />

            <GameItem
              game="fifa"
              onClick={() => onClose("fifa")}
              size="small"
            />

            <GameItem
              game="clashroyale"
              onClick={() => onClose("clashroyale")}
              size="small"
            /> */}

            {/* <GameItem
              game="chess"
              onClick={() => onClose("chess")}
              size="small"
            /> */}
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default SelectGameModal;
