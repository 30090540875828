const Headband = () => {
  const styles = {
    one: {
      fill: "#e01020",
    },
    two: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#fff",
    },
    four: {
      fill: "#3912dd",
    },
  };

  return (
    <g>
      <path
        style={styles.three}
        d="m387.91,292.94c48.13-10.43,109.2-18.13,179.35-13.77,27.12,1.69,52.3,4.98,75.3,9.15,3.5,4.49,7.41,10.27,10.98,17.37,3.08,6.14,5.19,11.87,6.67,16.78-40.17-4.46-84.37-7.53-132.07-8.08-54.5-.64-104.63,2.1-149.57,6.62.93-5.33,2.45-11.52,4.98-18.19,1.38-3.63,2.87-6.93,4.37-9.88Z"
      />
      <path
        style={styles.one}
        d="m385.37,298.3c36.59-6.61,80.61-11.85,130.5-12.34,50.48-.5,95.05,3.96,132.08,9.95,1.3,2.19,2.6,4.38,3.9,6.56-38.2-5.77-83.29-9.98-133.89-9.74-51.04.25-96.45,4.97-134.83,11.2.75-1.88,1.5-3.76,2.24-5.64Z"
      />
      <path
        style={styles.four}
        d="m381.01,309.15c37.97-6.61,83.64-11.85,135.42-12.34,52.38-.5,98.63,3.96,137.05,9.95,1.35,2.19,2.7,4.38,4.05,6.56-39.64-5.77-86.43-9.98-138.93-9.74-52.96.25-100.08,4.97-139.91,11.2.78-1.88,1.55-3.76,2.33-5.64Z"
      />
      <path
        style={styles.two}
        d="m387.91,292.94c48.13-10.43,109.2-18.13,179.35-13.77,27.12,1.69,52.3,4.98,75.3,9.15,3.5,4.49,7.41,10.27,10.98,17.37,3.08,6.14,5.19,11.87,6.67,16.78-40.17-4.46-84.37-7.53-132.07-8.08-54.5-.64-104.63,2.1-149.57,6.62.93-5.33,2.45-11.52,4.98-18.19,1.38-3.63,2.87-6.93,4.37-9.88Z"
      />
    </g>
  );
};

export default Headband;
