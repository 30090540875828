const Drizzy = ({ skinColor, color }) => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#eadfda",
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#191919",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    six: {
      fill: "#191919",
    },
    seven: {
      fill: skinColor,
    },
    eight: {
      fill: color,
    },
    nine: {
      fill: skinColor,
    },
    ten: {
      fill: skinColor,
    },
  };

  return (
    <g>
      <g>
        <path
          style={styles.six}
          d="m738.86,688.47c5.4-28.2-11.67-62.25-40.12-76.16-13.26-6.48-25.99-7.12-33.91-6.78-45.87-12.51-91.74-25.01-137.61-37.52-33.7-11.75-67.39-23.51-101.09-35.26-14.61-.71-29.22-1.43-43.83-2.14-48.74,5.55-82.54,22.26-101.48,33.7-21.54,13.01-41.98,25.91-48.2,50.27-4.69,18.38.86,35.01,4.73,43.94,7.73,22.48,24,60.35,58.07,96.75,46.02,49.17,99.28,68.71,129.51,76.66,20.16,5.3,84.81,19.85,159.16-5.8,13.76-4.75,50.79-18.78,88.09-50.44,25.49-21.63,40.17-42.21,48.94-54.69,10.53-14.98,15.84-22.67,17.72-32.52Z"
        />
        <g>
          <g>
            <path
              style={styles.ten}
              d="m636.2,439.18c1.89,3.47,1.76,7.69-.34,11.03l-6.12,9.75h-4.5l10.34-21.93.62,1.15Z"
            />
            <path
              style={styles.six}
              d="m625.24,459.96h-13.5c-.74-1.35-1.52-2.72-2.35-4.11-.91-1.54-1.83-3.03-2.74-4.46,1.53-.48,3.55-.87,5.87-.63,7.87.82,12.15,8.17,12.72,9.2Z"
            />
            <path
              style={styles.seven}
              d="m577.11,477.37c6.55,13.96,13.46,27.41,20.66,40.38l-1.68,2.47c-.08-.55-.86-5.35-5.35-7.83-3.88-2.14-7.69-.99-8.35-.78-3.36-4.7-8.97-7.21-14.61-6.52-8.02.97-11.7,7.9-12,8.48-1.97,2.82-4.22,5.8-6.79,8.86-2.21,2.64-4.4,5.02-6.51,7.18l-7.44,3.72c-1.78-1.42-3.56-2.83-5.34-4.24-.62-1.55-1.98-4.17-4.7-5.48-4.59-2.22-11.45.04-15.91,6.26-1.29.65-3.45,1.55-6.26,1.83-2.69.26-4.89-.16-6.26-.53-.39-.56-1.64-2.25-3.92-2.87-2.54-.69-5.36.24-7.3,2.35-1.05,1.42-2.66,3.99-3.39,7.57-1.06,5.18.35,9.37,1.04,11.07-2.61-.47-5.22-.95-7.83-1.42-.92-6.29-2.55-13.87-5.47-22.17-3.31-9.39-7.33-17.07-10.96-22.96.17-24,.35-48,.52-72,4.87-8.17,9.74-16.35,14.61-24.52l5.41-.75c1.05-9.98,4.24-20.25,5.29-30.23,7.83-.19,16.38-.03,25.52.67,6.27.48,12.2,1.17,17.76,2,6.15.54,15.27,1.7,26.02,4.47,30.02,7.75,54.24,25.2,55.52,23.19.32-.5-1.21-1.52-3.52-5.58-1.86-3.26-3.32-5.83-3.62-8.81-.65-6.48,4.38-13.42,9.78-15.06.82-.25,2.59-.76,4.7-.2.48.13,3.07.88,4.5,3.23,1.51,2.48.31,4.59,1.47,5.28,1.36.83,3.17-1.96,6.26-2.25,3.56-.33,7.94,2.74,8.9,6.36,1.23,4.66-3.13,10.58-6.95,13.5-2.59,1.99-6.22,3.95-7.01,7.58-.04.21-.05.41-.03.64.41,4.74,12.46,4.84,15.4,12.48,1.12,2.9.5,5.8-.01,7.48l-3.68,7.81-10.34,21.93c-.57-1.03-4.85-8.38-12.72-9.2-2.32-.24-4.34.15-5.87.63.91,1.43,1.83,2.92,2.74,4.46.83,1.39,1.61,2.76,2.35,4.11l-46.96-65.35c-4.4-1.42-9.19-.13-12.13,3.26-4.07,4.69-2.21,10.69-2.12,10.99,3.97,11.42,9.35,24.35,16.73,38.05,2.2,4.07,4.43,7.95,6.66,11.64-.29,2.56-.39,5.94.25,9.82.39,2.33.97,4.36,1.57,6.06.45.98.91,1.96,1.37,2.94Zm-43.63-34.83c.94-.74,1.64-1.57,2.15-2.34-1.83-1.31-32.7-22.9-51.06-10.96-1.8,1.17-3.61,2.74-5.29,4.89,5.35-.17,12.04.1,19.57,1.57,10.52,2.05,18.85,5.69,24.65,8.8,1.13.23,6.09,1.11,9.98-1.96Zm-29.1,16.81c10.6,3.01,19.48.46,22.84-.7-5.31-9.3-14.66-14.93-24.16-14.33-7.21.44-12.27,4.32-14.32,6.11,2.58,2.44,7.8,6.7,15.64,8.92Z"
            />
            <path
              style={styles.six}
              d="m596.53,522.79c.2,1.34.36,3.52-.22,6.06.43-2.26.42-4.33.22-6.06Z"
            />
            <path
              style={styles.six}
              d="m596.53,522.79c.2,1.73.21,3.8-.22,6.06-.13.59-.3,1.21-.52,1.84-1.91,5.41-6.12,8.08-7.4,8.83,3.06-3.2,4.23-7.29,2.87-10.43-.75-1.74-2.87-4.58-5.48-4.18-2.07.32-3.19,2.49-3.39,2.88.35,1.57.71,3.15,1.06,4.73-.92.05-2.36.25-3.93,1-2.32,1.12-3.61,2.81-4.17,3.65-1.26-.83-2.51-1.66-3.76-2.5-.24-2.91-.47-5.82-.7-8.73,1.3-1.29,5.42-5.04,12.02-5.98,6.86-.98,12.02,1.68,13.58,2.57.01.08.03.16.04.25h0Z"
            />
            <path
              style={styles.two}
              d="m570.89,525.94c.23,2.91.46,5.82.7,8.73-1.08-2.84-2.78-9.29.85-15.44,3.22-5.46,8.59-7.22,9.95-7.62.66-.21,4.47-1.36,8.35.78,4.49,2.48,5.27,7.28,5.35,7.83.17.73.33,1.59.44,2.56-.01-.09-.03-.17-.04-.25-1.56-.89-6.72-3.55-13.58-2.57-6.6.94-10.72,4.69-12.02,5.98Z"
            />
            <path
              style={styles.two}
              d="m596.22,529.35c-2.06,9.62-10.51,14.31-12,15.11-4-2.78-8-5.55-12-8.33-.07-.13-.32-.64-.63-1.46,1.25.84,2.5,1.67,3.76,2.5.56-.84,1.85-2.53,4.17-3.65,1.57-.75,3.01-.95,3.93-1-.35-1.58-.71-3.16-1.06-4.73.2-.39,1.32-2.56,3.39-2.88,2.61-.4,4.73,2.44,5.48,4.18,1.36,3.14.19,7.23-2.87,10.43,1.28-.75,5.49-3.42,7.4-8.83.22-.63.39-1.25.52-1.84-.02.16-.05.33-.09.5Z"
            />
            <path
              style={styles.six}
              d="m573.99,458.66l9.18,14.08c-3.02-4.33-6.11-9.02-9.18-14.08Z"
            />
            <path
              style={styles.six}
              d="m573.99,458.66c3.07,5.06,6.16,9.75,9.18,14.08l16.92,41.61c-.77,1.13-1.55,2.27-2.32,3.4-7.2-12.97-14.11-26.42-20.66-40.38-.46-.98-.92-1.96-1.37-2.94-.6-1.7-1.18-3.73-1.57-6.06-.64-3.88-.54-7.26-.25-9.82l.07.11Z"
            />
            <path
              style={styles.two}
              d="m572.74,585.96l2.61,6c-12.62,37.84-22.41,68.72-28.96,89.74-16.1,51.65-22.87,76.51-39.13,112.69-9.03,20.1-15.48,33.4-21.38,43.91-1.02-1.12-1.59-1.59-1.93-1.65,5.18-8.08,6.63-18.22,13.4-31.82,8.47-17.02,12.78-32.62,15.1-44.35-13.04,26.84-17.98,52.72-31.02,79.56-1.76-.44-11.62.08-13.39-.36,3.38-6.7,6.76-13.52,10.12-20.45.05-.1.11-.21.16-.32h0c10.79-22.25,21.45-45.64,31.81-70.18,23.78-56.37,42.28-110.02,56.78-159.59,1.94-1.06,3.89-2.12,5.83-3.18Z"
            />
            <path
              style={styles.seven}
              d="m571.11,580.52c-.81-2.69-1.61-5.37-2.41-8.06l-8.37-6.3c-1.45-1.09-2.89-2.18-4.34-3.26-2.13,3.75-6.4,5.78-10.64,5.06-4.22-.72-7.56-4.01-8.35-8.22-.01-.01-.03-.01-.04-.02-.46-.26-4.72-2.7-7.28-6.97-.01-.01-.02-.02-.02-.04-.55-1.05-.99-2.23-1.27-3.54-1.25-5.91,1.38-12.19,6.65-15.84l7.44-3.72c2.11-2.16,4.3-4.54,6.51-7.18,2.57-3.06,4.82-6.04,6.79-8.86.3-.58,3.98-7.51,12-8.48,5.64-.69,11.25,1.82,14.61,6.52-1.36.4-6.73,2.16-9.95,7.62-3.63,6.15-1.93,12.6-.85,15.44.31.82.56,1.33.63,1.46,4,2.78,8,5.55,12,8.33,1.49-.8,9.94-5.49,12-15.11.04-.17.07-.34.09-.5.58-2.54.42-4.72.22-6.06h0c-.11-.98-.27-1.84-.44-2.57l1.68-2.47c.77-1.13,1.55-2.27,2.32-3.4l-16.92-41.61-9.18-14.08-.07-.11c-2.23-3.69-4.46-7.57-6.66-11.64-7.38-13.7-12.76-26.63-16.73-38.05-.09-.3-1.95-6.3,2.12-10.99,2.94-3.39,7.73-4.68,12.13-3.26l46.96,65.35h22.3c1.73-.42,3.18-.39,4.29-.22,8.4,1.28,11.96,12.38,12.15,13,2.69,10.52,5.39,21.04,8.09,31.56,3.74-9.65,9.96-15.95,15.91-15.65.59.03,3.87.26,5.48,2.61,2.17,3.18.56,9.15-4.7,14.48.3,5.22.68,13.46.78,23.61.16,15.21-.44,23.85-.78,38.08-.21,8.81-.37,21.89,0,38.09.6,2.96,1.11,6.23,1.44,9.78.24,2.63.36,5.12.39,7.44,1.17,4.3,2.34,8.61,3.52,12.91-6.47,5.56-15.41,12.08-27,17.61-18.4,8.78-35.28,11.13-46.18,11.74l-4.69-9.83c-3.62-5.46-7.47-12.09-10.96-19.91-4.63-10.37-7.35-19.93-9-27.78-2.5-4.59-5.04-9.76-7.43-15.52-1.65-3.97-3.05-7.79-4.24-11.44Z"
            />
            <path
              style={styles.six}
              d="m568.7,572.46c.8,2.69,1.6,5.37,2.41,8.06-.92-2.8-1.72-5.49-2.41-8.06Z"
            />
            <path
              style={styles.seven}
              d="m549.91,603.7c-1.1.58-2.45.25-3.13-.72-.61-.88-.55-2.11.18-2.96,4.83-2.64,9.67-5.28,14.5-7.91v3.43c-1.5,1.71-3.67,3.8-6.66,5.68-1.75,1.1-3.42,1.89-4.89,2.48Z"
            />
            <path
              style={styles.six}
              d="m546.96,600.02c-.73.85-.79,2.08-.18,2.96.68.97,2.03,1.3,3.13.72,1.47-.59,3.14-1.38,4.89-2.48,2.99-1.88,5.16-3.97,6.66-5.68v-3.43c1.82-.99,3.63-1.98,5.45-2.97-14.5,49.57-33,103.22-56.78,159.59-10.36,24.54-21.02,47.93-31.81,70.17-6.23-18.27-14.54-45.56-21.13-79.53-4.64-23.91-2.2-19.05-10-70.03-4.72-30.8-6.97-61.93-10.77-92.85-.18-1.48-.44-3.57-.63-6.16,2.75,4.83,15.41,25.83,40.95,32.32,11.58,2.95,21.66,1.85,27.97.57h0c17.68,5.13,34.43.03,39.32-1.61.97-.53,1.95-1.06,2.92-1.59Z"
            />
            <path
              style={styles.six}
              d="m535.63,440.2c-.51.77-1.21,1.6-2.15,2.34-3.89,3.07-8.85,2.19-9.98,1.96-5.8-3.11-14.13-6.75-24.65-8.8-7.53-1.47-14.22-1.74-19.57-1.57,1.68-2.15,3.49-3.72,5.29-4.89,18.36-11.94,49.23,9.65,51.06,10.96Z"
            />
            <path d="m536.96,559.72c-.74-.35-4.93-2.46-7.28-6.97,2.56,4.27,6.82,6.71,7.28,6.97Z" />
            <path
              style={styles.six}
              d="m525.07,544.03s5.41-11.29,3.01-.83c-.01.04-.02.07-.02.11,0,.03-.02.06-.02.08-.07.3-.12.58-.17.83l-2.8-.19Z"
            />
            <path
              style={styles.six}
              d="m568.7,572.46c.69,2.57,1.49,5.26,2.41,8.06.54,1.81,1.09,3.63,1.63,5.44-1.94,1.06-3.89,2.12-5.83,3.18-1.82.99-3.63,1.98-5.45,2.97-4.83,2.63-9.67,5.27-14.5,7.91-.97.53-1.95,1.06-2.92,1.59-4.89,1.64-21.64,6.74-39.32,1.61h0c-.12-.03-.24-.07-.37-.1-3.45-1.03-6.94-2.45-10.38-4.37-12.96-7.22-19.89-18.49-22.96-23.49-5.54-9-6.29-14.63-14.09-37.83-3.67-10.92-6.86-19.67-8.87-25.04-.26-13.22-.52-26.43-.78-39.65.69.21,1.8.44,3.13.26,2.63-.36,4.23-2.07,4.7-2.61v-40.17c4.76-9.18,4.17-19.95-1.57-27.65-5.94-7.98-15-9.41-16.95-9.68,2.14-9.25,5.19-23.29,8.08-40.67,3.23-19.39,3.31-25.12,6.78-33.65,7.9-19.37,23.85-29.96,32.35-35.6,35.38-23.46,74.75-18.1,81.39-17.1,9.53,1.44,61.74,9.33,85.57,57.13,22.44,45.02,1.65,89.22-1.04,94.7l-10.44,12.52c.51-1.68,1.13-4.58.01-7.48-2.94-7.64-14.99-7.74-15.4-12.48-.02-.23-.01-.43.03-.64.79-3.63,4.42-5.59,7.01-7.58,3.82-2.92,8.18-8.84,6.95-13.5-.96-3.62-5.34-6.69-8.9-6.36-3.09.29-4.9,3.08-6.26,2.25-1.16-.69.04-2.8-1.47-5.28-1.43-2.35-4.02-3.1-4.5-3.23-2.11-.56-3.88-.05-4.7.2-5.4,1.64-10.43,8.58-9.78,15.06.3,2.98,1.76,5.55,3.62,8.81,2.31,4.06,3.84,5.08,3.52,5.58-1.28,2.01-25.5-15.44-55.52-23.19-10.75-2.77-19.87-3.93-26.02-4.47-5.56-.83-11.49-1.52-17.76-2-9.14-.7-17.69-.86-25.52-.67-1.05,9.98-4.24,20.25-5.29,30.23l-5.41.75c-4.87,8.17-9.74,16.35-14.61,24.52-.17,24-.35,48-.52,72,3.63,5.89,7.65,13.57,10.96,22.96,2.92,8.3,4.55,15.88,5.47,22.17,2.61.47,5.22.95,7.83,1.42-.69-1.7-2.1-5.89-1.04-11.07.73-3.58,2.34-6.15,3.39-7.57,1.94-2.11,4.76-3.04,7.3-2.35,2.28.62,3.53,2.31,3.92,2.87,1.37.37,3.57.79,6.26.53,2.81-.28,4.97-1.18,6.26-1.83,4.46-6.22,11.32-8.48,15.91-6.26,2.72,1.31,4.08,3.93,4.7,5.48,1.78,1.41,3.56,2.82,5.34,4.24-.64.37-1.3.82-1.95,1.37-3.21,2.69-4.49,6.28-5.03,8.61,0-.04.01-.07.02-.11,2.4-10.46-3.01.83-3.01.83l-27.98-1.9c-1.92,7.83-3.83,15.65-5.74,23.48,10.23,5.73,19.2,9.31,25.69,11.54,15.04,5.18,21.11,4.66,23.68,4.31,7.33-1,12.86-4.19,16.11-6.46-.28-4.03-.56-8.07-.84-12.1,1.45,1.08,2.89,2.17,4.34,3.26l8.37,6.3Z"
            />
            <path d="m527.87,544.22c.05-.25.1-.53.17-.83-.79,3.68.12,6.81,1.62,9.32,0,.02.01.03.02.04,2.35,4.51,6.54,6.62,7.28,6.97,0,.01.03.01.04.02-6,5.09-11.93.91-11.93.91v-16.62l2.8.19Z" />
            <path d="m512.45,448.81c0-.65-.53-1.17-1.18-1.17s-1.17.52-1.17,1.17.52,1.18,1.17,1.18,1.18-.53,1.18-1.18Zm-3.51-3.13c3.61.72,6.18,3.06,5.74,5.22-.43,2.16-3.7,3.33-7.3,2.61-3.61-.72-6.18-3.06-5.74-5.22.43-2.16,3.7-3.33,7.3-2.61Z" />
            <path
              style={styles.one}
              d="m511.27,447.64c.65,0,1.18.52,1.18,1.17s-.53,1.18-1.18,1.18-1.17-.53-1.17-1.18.52-1.17,1.17-1.17Z"
            />
            <path
              style={styles.one}
              d="m488.74,450.43c2.05-1.79,7.11-5.67,14.32-6.11,9.5-.6,18.85,5.03,24.16,14.33-3.36,1.16-12.24,3.71-22.84.7-7.84-2.22-13.06-6.48-15.64-8.92Zm20.2-4.75c-3.6-.72-6.87.45-7.3,2.61-.44,2.16,2.13,4.5,5.74,5.22,3.6.72,6.87-.45,7.3-2.61.44-2.16-2.13-4.5-5.74-5.22Z"
            />
            <path
              style={styles.seven}
              d="m504.34,603.12c.13.03.25.07.37.1-6.31,1.28-16.39,2.38-27.97-.57-25.54-6.49-38.2-27.49-40.95-32.32-.04-.49-.07-1-.1-1.52-.32-5.82-.16-13.17,1.4-21.46,1.25-6.68,3.11-12.38,4.95-16.96,1.74-1.56,3.48-3.13,5.22-4.69v-5.74c.26-2.53.52-5.05.78-7.57,3.92,16.29,8.39,29.53,11.99,39.07,11.74,31.18,20.03,38.7,24.43,42.09,7.31,5.62,14.78,8.24,19.88,9.57Z"
            />
            <path
              style={styles.seven}
              d="m517.04,577.15c-6.49-2.23-15.46-5.81-25.69-11.54,1.91-7.83,3.82-15.65,5.74-23.48l27.98,1.9v16.62s5.93,4.18,11.93-.91c.79,4.21,4.13,7.5,8.35,8.22,4.24.72,8.51-1.31,10.64-5.06.28,4.03.56,8.07.84,12.1-3.25,2.27-8.78,5.46-16.11,6.46-2.57.35-8.64.87-23.68-4.31Z"
            />
            <path
              style={styles.two}
              d="m478.32,818.91c-.05.11-.11.22-.16.32-3.38,6.76-6.74,11.39-10.12,18.2-4.5-14.88-8.71-28.43-12.43-45.13-9.84-44.13-14.14-84.9-15.39-120.71-1.38,27.89-.45,62.94,7.04,102.45,4.7,24.76,11.09,46.64,17.78,65.33-3.77-.61-7.54-1.23-11.31-1.84-9.93-40.25-15.2-74.67-18.19-100.56-3.16-27.35-4-47.06-5.37-79.21-2.28-53.86-.72-82.24.4-97.43.05-.68.1-1.35.15-2.01,0-.04.01-.08.01-.12.44-5.16.89-10.32,1.36-15.5.01-.15.03-.29.04-.44.36-3.68.72-6.95,1.04-9.74-.04-.42-.58-6.48,4.24-10.35,4.25-3.4,9.22-2.36,9.85-2.21v5.74c-.78.41-1.73,1.01-2.71,1.85-1.15,1.01-1.96,2.03-2.51,2.84-.83,1.99-2.02,5.05-3.13,8.87-3.42,11.78-3.64,22.3-3.22,29.55.03.52.06,1.03.1,1.52.19,2.59.45,4.68.63,6.16,3.8,30.92,6.05,62.05,10.77,92.85,7.8,50.98,5.36,46.12,10,70.03,6.59,33.97,14.9,61.26,21.13,79.53h0Z"
            />
            <path
              style={styles.seven}
              d="m455.09,470.39c-.47.54-2.07,2.25-4.7,2.61-1.33.18-2.44-.05-3.13-.26-1.26-.3-3.1-.91-4.96-2.22-2.85-2.01-4.23-4.62-6.45-8.8-1.05-1.98-1.79-3.69-2.74-5.87-.93-2.14-1.65-3.96-2.15-5.28l-1.05-23.76-.91-15.7.18-.33c1.2.64,2.32,1.25,3.34,1.83.07-.04,1.57-.96,1.57-2.74s-1.5-2.7-1.57-2.74c-.23-1.52-.42-4.15.39-7.24.91-3.43,2.63-5.79,3.66-7,1.95.27,11.01,1.7,16.95,9.68,5.74,7.7,6.33,18.47,1.57,27.65v40.17Z"
            />
            <path
              style={styles.seven}
              d="m447.26,525.7c-1.74,1.56-3.48,3.13-5.22,4.69.55-.81,1.36-1.83,2.51-2.84.98-.84,1.93-1.44,2.71-1.85Z"
            />
            <path
              style={styles.one}
              d="m432.52,407.13c.07.04,1.57.96,1.57,2.74s-1.5,2.7-1.57,2.74c-1.02-.58-2.14-1.19-3.34-1.83-13.22-7.04-37.24-17.32-68.66-17.88-27.53-.49-49.11,6.7-61.43,11.88-.2-.12-2.03-1.28-2.22-3.52-.15-1.7.72-3.37,2.22-4.3,12.89-4.69,33-10.17,57.91-9.79,35.35.54,61.99,12.61,75.52,19.96Z"
            />
            <path
              style={styles.seven}
              d="m433.17,530.6c-.35,3.88-.7,7.77-1.04,11.66-.47,4.68-.95,10.04-1.4,15.94,0,.04-.01.08-.01.12-1.52-1.23-3.12-2.78-4.59-4.71-6.11-8.04-6.01-17.33-5.74-21.09,1.43.26,3.41.47,5.74.22,3.13-.34,5.55-1.36,7.04-2.14Z"
            />
            <path
              style={styles.eight}
              d="m429.18,410.78l-.18.33-5.22,41.22c-6.78,41.67-13.56,83.35-20.35,125.02-6.77,1.99-15.22,3.86-25.04,4.69-11.96,1.02-22.3.19-30.26-1.04-6.22.32-10.91-.62-14.09-1.57-2.72-.81-4.08-1.55-7.56-2.6-4.75-1.45-8.79-2.06-11.48-2.35-.02-3.19-.1-6.5-.26-9.91-.13-2.78-.31-5.48-.52-8.09,3.05-1.04,6.29-2.33,9.65-3.91,1.26-.6,2.48-1.21,3.65-1.83,15.07.38,26.62-3.94,28.7-10.96.79-2.66.83-7.83-2.08-10.32-1.17-1-2.44-1.25-7.05-1.68-5.83-.54-8.74-.81-11.49-.8-4.08.01-9.6.28-16.17,1.32-11.82,2.91-18.08,3.52-18.78,1.83-.37-.91.84-2.47,3.65-4.7,12.74-3.26,26.96-6.21,42.53-8.34,8.44-1.16,16.54-1.98,24.26-2.53,1.76-.13,3.5-.24,5.21-.34.8-.58,6.07-4.53,6-10.7,0-.6-.02-2.44-.86-4.02-2.32-4.35-10.37-5.92-19.74-3.54h-67.31c10.39-5.95,24.61-12.4,42.26-15.92,6.32-1.26,12.27-1.98,17.74-2.34,1.69.65,8.44,3.03,14.87-.27,1.64-.83,2.92-1.87,3.91-2.86.38-.31,4.73-3.99,4.18-9.66-.39-3.99-2.98-6.44-3.65-7.04h-66.27l42.27-33.39c.19-.15,3.87-3.05,3.39-7.37-.42-3.75-3.77-6.91-8.09-7.24-2.53,2.26-6.14,4.82-10.89,6.46-1.01.34-1.99.62-2.94.84-1.32.34-3.14.84-5.28,1.5-1.58.5-9.37,1.86-16.86,6.9-1.27.85-2.54,1.71-3.81,2.57l-6.13-23.36c12.32-5.18,33.9-12.37,61.43-11.88,31.42.56,55.44,10.84,68.66,17.88Z"
            />
            <path
              style={styles.seven}
              d="m382.3,503.52c.07,6.17-5.2,10.12-6,10.7-1.71.1-3.45.21-5.21.34-7.72.55-15.82,1.37-24.26,2.53-15.57,2.13-29.79,5.08-42.53,8.34-2.81,2.23-4.02,3.79-3.65,4.7.7,1.69,6.96,1.08,18.78-1.83,6.57-1.04,12.09-1.31,16.17-1.32,2.75-.01,5.66.26,11.49.8,4.61.43,5.88.68,7.05,1.68,2.91,2.49,2.87,7.66,2.08,10.32-2.08,7.02-13.63,11.34-28.7,10.96-1.17.62-2.39,1.23-3.65,1.83-3.36,1.58-6.6,2.87-9.65,3.91.21,2.61.39,5.31.52,8.09.16,3.41.24,6.72.26,9.91,2.69.29,6.73.9,11.48,2.35,3.48,1.05,4.84,1.79,7.56,2.6,3.18.95,7.87,1.89,14.09,1.57-.19,1.32-.62,2.98-1.56,4.7-2.93,5.31-8.5,6.93-9.92,7.3-.15,1.88-.59,4.47-1.82,7.3-2.2,5.04-5.7,8.02-7.57,9.4-2.29,7.48-4.58,14.95-6.87,22.43-.09-.2-.15-.34-.17-.39-.65,2.49-1.87,6.05-4.31,9.78-1.09,1.68-7.16,10.63-16.85,13.2-.02.01-.04.01-.06.02-.02,0-.05.01-.07.01-12.91,2.48-26.23-6.46-34.65-16.37-.26-.3-.51-.61-.75-.92-6.94-8.62-9.39-17.78-10.36-23.07-.55-9.61-1.11-19.22-1.67-28.83-.13-2.39-.27-4.78-.41-7.17-3.56-6.2-5.72-11.67-7.05-15.65-1.38-4.15-2.35-7.14-2.61-11.22-.38-6.2,1.13-11.25,2.35-14.35-.91-1.55-3.16-5.82-2.61-11.47.36-3.62,1.7-6.32,2.61-7.83,8.7-13.22,17.39-26.44,26.09-39.65,2.23-8.19,5.52-17.44,10.43-27.13,1.7-3.35,3.45-6.47,5.22-9.39,6.82-4.82,13.33-9.26,19.7-13.56,1.27-.86,2.54-1.72,3.81-2.57,7.49-5.04,15.28-6.4,16.86-6.9,2.14-.66,3.96-1.16,5.28-1.5.95-.22,1.93-.5,2.94-.84,4.75-1.64,8.36-4.2,10.89-6.46,4.32.33,7.67,3.49,8.09,7.24.48,4.32-3.2,7.22-3.39,7.37l-42.27,33.39h66.27c.67.6,3.26,3.05,3.65,7.04.55,5.67-3.8,9.35-4.18,9.66-.99.99-2.27,2.03-3.91,2.86-6.43,3.3-13.18.92-14.87.27-5.47.36-11.42,1.08-17.74,2.34-17.65,3.52-31.87,9.97-42.26,15.92h67.31c9.37-2.38,17.42-.81,19.74,3.54.84,1.58.86,3.42.86,4.02Z"
            />
            <path
              style={styles.two}
              d="m323.69,652.37c-.48,3.7-1.73,7.41-4.26,10.67-9.5,12.3-29.21,8.16-36.39,6.66-20.98-4.41-33.16-20.1-37.17-25.83l7.3-29.48c.7,4.4,2.32,10.98,6.53,17.74,1.09,1.76,2.38,3.55,3.83,5.33.24.31.49.62.75.92.24.29.49.58.74.86,2.38,2.68,17.51,19.75,33.91,15.51.02,0,.05-.01.07-.01.9-.18,1.8-.41,2.7-.7,2.5-.81,10.56-3.89,16.69-15.39.67-2.17,1.33-4.35,2-6.52.87,2.02,4.47,11.08,3.3,20.24Z"
            />
          </g>
          <g>
            <path
              style={styles.five}
              d="m529.66,552.71c-1.5-2.51-2.41-5.64-1.62-9.32"
            />
            <path
              style={styles.five}
              d="m536.96,559.72c-.46-.26-4.72-2.7-7.28-6.97"
            />
            <path
              style={styles.five}
              d="m525.07,544.03v16.62s5.93,4.18,11.93-.91"
            />
            <path
              style={styles.five}
              d="m299,654.74c.9-.18,1.8-.41,2.7-.7,2.5-.81,10.56-3.89,16.69-15.39.67-2.17,1.33-4.35,2-6.52,2.29-7.48,4.58-14.95,6.87-22.43,1.87-1.38,5.37-4.36,7.57-9.4,1.23-2.83,1.67-5.42,1.82-7.3,1.42-.37,6.99-1.99,9.92-7.3.94-1.72,1.37-3.38,1.56-4.7-6.22.32-10.91-.62-14.09-1.57-2.72-.81-4.08-1.55-7.56-2.6-4.75-1.45-8.79-2.06-11.48-2.35-.02-3.19-.1-6.5-.26-9.91-.13-2.78-.31-5.48-.52-8.09,3.05-1.04,6.29-2.33,9.65-3.91,1.26-.6,2.48-1.21,3.65-1.83,15.07.38,26.62-3.94,28.7-10.96.79-2.66.83-7.83-2.08-10.32-1.17-1-2.44-1.25-7.05-1.68-5.83-.54-8.74-.81-11.49-.8-4.08.01-9.6.28-16.17,1.32-11.82,2.91-18.08,3.52-18.78,1.83-.37-.91.84-2.47,3.65-4.7,12.74-3.26,26.96-6.21,42.53-8.34,8.44-1.16,16.54-1.98,24.26-2.53,1.76-.13,3.5-.24,5.21-.34.8-.58,6.07-4.53,6-10.7,0-.6-.02-2.44-.86-4.02-2.32-4.35-10.37-5.92-19.74-3.54h-67.31c10.39-5.95,24.61-12.4,42.26-15.92,6.32-1.26,12.27-1.98,17.74-2.34,1.69.65,8.44,3.03,14.87-.27,1.64-.83,2.92-1.87,3.91-2.86.38-.31,4.73-3.99,4.18-9.66-.39-3.99-2.98-6.44-3.65-7.04h-66.27l42.27-33.39c.19-.15,3.87-3.05,3.39-7.37-.42-3.75-3.77-6.91-8.09-7.24-2.53,2.26-6.14,4.82-10.89,6.46-1.01.34-1.99.62-2.94.84-1.32.34-3.14.84-5.28,1.5-1.58.5-9.37,1.86-16.86,6.9-1.27.85-2.54,1.71-3.81,2.57-6.37,4.3-12.88,8.74-19.7,13.56-1.77,2.92-3.52,6.04-5.22,9.39-4.91,9.69-8.2,18.94-10.43,27.13-8.7,13.21-17.39,26.43-26.09,39.65-.91,1.51-2.25,4.21-2.61,7.83-.55,5.65,1.7,9.92,2.61,11.47-1.22,3.1-2.73,8.15-2.35,14.35.26,4.08,1.23,7.07,2.61,11.22,1.33,3.98,3.49,9.45,7.05,15.65.14,2.39.28,4.78.41,7.17.56,9.61,1.12,19.22,1.67,28.83.7,4.4,2.32,10.98,6.53,17.74,1.09,1.76,2.38,3.55,3.83,5.33"
            />
            <path
              style={styles.five}
              d="m264.28,638.38c8.42,9.91,21.74,18.85,34.65,16.37"
            />
            <path
              style={styles.five}
              d="m299.09,404.78c-.2-.12-2.03-1.28-2.22-3.52-.15-1.7.72-3.37,2.22-4.3,12.89-4.69,33-10.17,57.91-9.79,35.35.54,61.99,12.61,75.52,19.96.07.04,1.57.96,1.57,2.74s-1.5,2.7-1.57,2.74c-1.02-.58-2.14-1.19-3.34-1.83-13.22-7.04-37.24-17.32-68.66-17.88-27.53-.49-49.11,6.7-61.43,11.88Z"
            />
            <path
              style={styles.five}
              d="m602.74,818.91c4.95-23.44,8.72-52.43,7.83-85.56-.42-15.37-1.78-29.6-3.66-42.54-2.08-3.04-6.34-10.14-6.78-20.07-.3-6.88,1.35-12.36,2.61-15.57l4.69,9.83c10.9-.61,27.78-2.96,46.18-11.74,11.59-5.53,20.53-12.05,27-17.61-1.18-4.3-2.35-8.61-3.52-12.91-.03-2.32-.15-4.81-.39-7.44-.33-3.55-.84-6.82-1.44-9.78,8.15.8,17.48,3.08,26.61,8.35,36.12,20.87,36.98,70.28,36.99,74.6"
            />
            <path
              style={styles.five}
              d="m555.99,562.9c.28,4.03.56,8.07.84,12.1-3.25,2.27-8.78,5.46-16.11,6.46-2.57.35-8.64.87-23.68-4.31-6.49-2.23-15.46-5.81-25.69-11.54,1.91-7.83,3.82-15.65,5.74-23.48l27.98,1.9,2.8.19c.05-.25.1-.53.17-.83,0-.02.01-.05.02-.08.54-2.33,1.82-5.92,5.03-8.61.65-.55,1.31-1,1.95-1.37-1.78-1.42-3.56-2.83-5.34-4.24-.62-1.55-1.98-4.17-4.7-5.48-4.59-2.22-11.45.04-15.91,6.26-1.29.65-3.45,1.55-6.26,1.83-2.69.26-4.89-.16-6.26-.53-.39-.56-1.64-2.25-3.92-2.87-2.54-.69-5.36.24-7.3,2.35-1.05,1.42-2.66,3.99-3.39,7.57-1.06,5.18.35,9.37,1.04,11.07-2.61-.47-5.22-.95-7.83-1.42-.92-6.29-2.55-13.87-5.47-22.17-3.31-9.39-7.33-17.07-10.96-22.96.17-24,.35-48,.52-72,4.87-8.17,9.74-16.35,14.61-24.52l5.41-.75c1.05-9.98,4.24-20.25,5.29-30.23,7.83-.19,16.38-.03,25.52.67,6.27.48,12.2,1.17,17.76,2,6.15.54,15.27,1.7,26.02,4.47,30.02,7.75,54.24,25.2,55.52,23.19.32-.5-1.21-1.52-3.52-5.58-1.86-3.26-3.32-5.83-3.62-8.81-.65-6.48,4.38-13.42,9.78-15.06.82-.25,2.59-.76,4.7-.2.48.13,3.07.88,4.5,3.23,1.51,2.48.31,4.59,1.47,5.28,1.36.83,3.17-1.96,6.26-2.25,3.56-.33,7.94,2.74,8.9,6.36,1.23,4.66-3.13,10.58-6.95,13.5-2.59,1.99-6.22,3.95-7.01,7.58-.04.21-.05.41-.03.64.41,4.74,12.46,4.84,15.4,12.48,1.12,2.9.5,5.8-.01,7.48l10.44-12.52c2.69-5.48,23.48-49.68,1.04-94.7-23.83-47.8-76.04-55.69-85.57-57.13-6.64-1-46.01-6.36-81.39,17.1-8.5,5.64-24.45,16.23-32.35,35.6-3.47,8.53-3.55,14.26-6.78,33.65-2.89,17.38-5.94,31.42-8.08,40.67,1.95.27,11.01,1.7,16.95,9.68,5.74,7.7,6.33,18.47,1.57,27.65v40.17c-.47.54-2.07,2.25-4.7,2.61-1.33.18-2.44-.05-3.13-.26.26,13.22.52,26.43.78,39.65,2.01,5.37,5.2,14.12,8.87,25.04,7.8,23.2,8.55,28.83,14.09,37.83,3.07,5,10,16.27,22.96,23.49,3.44,1.92,6.93,3.34,10.38,4.37"
            />
            <path
              style={styles.five}
              d="m572.74,585.96c-.54-1.81-1.09-3.63-1.63-5.44-.81-2.69-1.61-5.37-2.41-8.06"
            />
            <path
              style={styles.five}
              d="m546.96,600.02c4.83-2.64,9.67-5.28,14.5-7.91,1.82-.99,3.63-1.98,5.45-2.97"
            />
            <path
              style={styles.five}
              d="m436.57,392.89c-1.03,1.21-2.75,3.57-3.66,7-.81,3.09-.62,5.72-.39,7.24"
            />
            <path
              style={styles.five}
              d="m447.26,472.74c-1.26-.3-3.1-.91-4.96-2.22-2.85-2.01-4.23-4.62-6.45-8.8-1.05-1.98-1.79-3.69-2.74-5.87-.93-2.14-1.65-3.96-2.15-5.28l-1.05-23.76-.91-15.7-5.22,41.22c-6.78,41.67-13.56,83.35-20.35,125.02-6.77,1.99-15.22,3.86-25.04,4.69-11.96,1.02-22.3.19-30.26-1.04"
            />
            <path
              style={styles.five}
              d="m238.32,657.99c-1.8-4.08-15.17-35.81,1.29-60.29,2.48-3.69,6.21-8.08,11.89-12.14"
            />
            <path
              style={styles.five}
              d="m253.17,614.39l-7.3,29.48c4.01,5.73,16.19,21.42,37.17,25.83,7.18,1.5,26.89,5.64,36.39-6.66,2.53-3.26,3.78-6.97,4.26-10.67,1.17-9.16-2.43-18.22-3.3-20.24-.09-.2-.15-.34-.17-.39-.65,2.49-1.87,6.05-4.31,9.78-1.09,1.68-7.16,10.63-16.85,13.2-.02.01-.04.01-.06.02-.02,0-.05.01-.07.01-16.4,4.24-31.53-12.83-33.91-15.51-.25-.28-.5-.57-.74-.86-.26-.3-.51-.61-.75-.92-6.94-8.62-9.39-17.78-10.36-23.07Z"
            />
            <polyline
              style={styles.five}
              points="323.7 652.22 323.69 652.37 314.95 773.21"
            />
            <polyline
              style={styles.five}
              points="245.87 643.87 238.32 657.99 238.32 658 237.63 659.28"
            />
            <path
              style={styles.five}
              d="m680.61,635.65c2.31,2.48,5.6,6.36,8.74,11.61,4.89,8.19,6.76,15.32,7.56,18.26,3.26,11.97,10.02,33.16,19.28,61.6"
            />
            <path
              style={styles.five}
              d="m582.39,511.61c-3.36-4.7-8.97-7.21-14.61-6.52-8.02.97-11.7,7.9-12,8.48-1.97,2.82-4.22,5.8-6.79,8.86-2.21,2.64-4.4,5.02-6.51,7.18l-7.44,3.72c-5.27,3.65-7.9,9.93-6.65,15.84.28,1.31.72,2.49,1.27,3.54,0,.02.01.03.02.04,2.35,4.51,6.54,6.62,7.28,6.97,0,.01.03.01.04.02.79,4.21,4.13,7.5,8.35,8.22,4.24.72,8.51-1.31,10.64-5.06,1.45,1.08,2.89,2.17,4.34,3.26l8.37,6.3c.69,2.57,1.49,5.26,2.41,8.06,1.19,3.65,2.59,7.47,4.24,11.44,2.39,5.76,4.93,10.93,7.43,15.52,1.65,7.85,4.37,17.41,9,27.78,3.49,7.82,7.34,14.45,10.96,19.91"
            />
            <line
              style={styles.five}
              x1="597.77"
              y1="517.75"
              x2="596.09"
              y2="520.22"
            />
            <path
              style={styles.five}
              d="m611.74,459.96l-46.96-65.35c-4.4-1.42-9.19-.13-12.13,3.26-4.07,4.69-2.21,10.69-2.12,10.99,3.97,11.42,9.35,24.35,16.73,38.05,2.2,4.07,4.43,7.95,6.66,11.64"
            />
            <path
              style={styles.five}
              d="m675.26,605.52c-.37-16.2-.21-29.28,0-38.09.34-14.23.94-22.87.78-38.08-.1-10.15-.48-18.39-.78-23.61,5.26-5.33,6.87-11.3,4.7-14.48-1.61-2.35-4.89-2.58-5.48-2.61-5.95-.3-12.17,6-15.91,15.65-2.7-10.52-5.4-21.04-8.09-31.56-.19-.62-3.75-11.72-12.15-13-1.11-.17-2.56-.2-4.29.22h-8.8"
            />
            <path
              style={styles.five}
              d="m479.28,434.13c1.68-2.15,3.49-3.72,5.29-4.89,18.36-11.94,49.23,9.65,51.06,10.96-.51.77-1.21,1.6-2.15,2.34-3.89,3.07-8.85,2.19-9.98,1.96-5.8-3.11-14.13-6.75-24.65-8.8-7.53-1.47-14.22-1.74-19.57-1.57Z"
            />
            <path
              style={styles.five}
              d="m573.92,458.55c-.29,2.56-.39,5.94.25,9.82.39,2.33.97,4.36,1.57,6.06.45.98.91,1.96,1.37,2.94,6.55,13.96,13.46,27.41,20.66,40.38.77-1.13,1.55-2.27,2.32-3.4l-16.92-41.61-9.18-14.08-.07-.11Z"
            />
            <path
              style={styles.five}
              d="m514.68,450.9c-.43,2.16-3.7,3.33-7.3,2.61-3.61-.72-6.18-3.06-5.74-5.22.43-2.16,3.7-3.33,7.3-2.61,3.61.72,6.18,3.06,5.74,5.22Z"
            />
            <path
              style={styles.five}
              d="m611.74,459.96c-.74-1.35-1.52-2.72-2.35-4.11-.91-1.54-1.83-3.03-2.74-4.46,1.53-.48,3.55-.87,5.87-.63,7.87.82,12.15,8.17,12.72,9.2h-13.5Z"
            />
            <path
              style={styles.five}
              d="m478.32,818.9c10.79-22.24,21.45-45.63,31.81-70.17,23.78-56.37,42.28-110.02,56.78-159.59,1.94-1.06,3.89-2.12,5.83-3.18l2.61,6c-12.62,37.84-22.41,68.72-28.96,89.74-16.1,51.65-22.87,76.51-39.13,112.69-9.03,20.1-15.48,33.4-21.38,43.91-1.02-1.12-1.59-1.59-1.93-1.65-.35-.07-.46.29-.54.81-.16,1-.24,2.58-1.98,2.58,13.04-26.84,17.98-52.72,31.02-79.56-2.32,11.73-6.63,27.33-15.1,44.35-6.77,13.6-8.22,23.74-13.4,31.82l-.54.81c-.6.88-1.26,1.74-1.98,2.58-1.76-.44-11.62.08-13.39-.36,3.38-6.7,6.76-13.52,10.12-20.45"
            />
            <path
              style={styles.five}
              d="m435.69,568.81c-.42-7.25-.2-17.77,3.22-29.55,1.11-3.82,2.3-6.88,3.13-8.87"
            />
            <path
              style={styles.five}
              d="m430.72,558.32c-.05.66-.1,1.33-.15,2.01-1.12,15.19-2.68,43.57-.4,97.43,1.37,32.15,2.21,51.86,5.37,79.21,2.99,25.89,8.26,60.31,18.19,100.56,3.77.61,7.54,1.23,11.31,1.84-6.69-18.69-13.08-40.57-17.78-65.33-7.49-39.51-8.42-74.56-7.04-102.45,1.25,35.81,5.55,76.58,15.39,120.71,3.72,16.7,7.93,30.25,12.43,45.13,3.38-6.81,6.74-11.44,10.12-18.2.05-.1.11-.21.16-.32h0c-6.23-18.28-14.54-45.57-21.13-79.54-4.64-23.91-2.2-19.05-10-70.03-4.72-30.8-6.97-61.93-10.77-92.85-.18-1.48-.44-3.57-.63-6.16"
            />
            <path
              style={styles.five}
              d="m447.26,519.96c-.63-.15-5.6-1.19-9.85,2.21-4.82,3.87-4.28,9.93-4.24,10.35-.32,2.79-.68,6.06-1.04,9.74-.47,4.68-.95,10.04-1.4,15.94"
            />
            <path
              style={styles.five}
              d="m432.13,542.26c.34-3.89.69-7.78,1.04-11.66-1.49.78-3.91,1.8-7.04,2.14-2.33.25-4.31.04-5.74-.22-.27,3.76-.37,13.05,5.74,21.09,1.47,1.93,3.07,3.48,4.59,4.71,0-.04.01-.08.01-.12.44-5.16.89-10.32,1.36-15.5.01-.15.03-.29.04-.44Z"
            />
            <path
              style={styles.five}
              d="m546.96,600.02c-.97.53-1.95,1.06-2.92,1.59-4.89,1.64-21.64,6.74-39.32,1.61h0c-.12-.03-.24-.07-.37-.1-5.1-1.33-12.57-3.95-19.88-9.57-4.4-3.39-12.69-10.91-24.43-42.09-3.6-9.54-8.07-22.78-11.99-39.07-.26,2.52-.52,5.04-.78,7.57v5.74c-.78.41-1.73,1.01-2.71,1.85-1.15,1.01-1.96,2.03-2.51,2.84-1.84,4.58-3.7,10.28-4.95,16.96-1.56,8.29-1.72,15.64-1.4,21.46.03.52.06,1.03.1,1.52,2.75,4.83,15.41,25.83,40.95,32.32,11.58,2.95,21.66,1.85,27.97.57"
            />
            <path
              style={styles.five}
              d="m596.53,522.78c-.11-.97-.27-1.83-.44-2.56-.08-.55-.86-5.35-5.35-7.83-3.88-2.14-7.69-.99-8.35-.78-1.36.4-6.73,2.16-9.95,7.62-3.63,6.15-1.93,12.6-.85,15.44.31.82.56,1.33.63,1.46,4,2.78,8,5.55,12,8.33,1.49-.8,9.94-5.49,12-15.11.04-.17.07-.34.09-.5"
            />
            <path
              style={styles.five}
              d="m596.53,522.79c.2,1.34.36,3.52-.22,6.06-.13.59-.3,1.21-.52,1.84-1.91,5.41-6.12,8.08-7.4,8.83,3.06-3.2,4.23-7.29,2.87-10.43-.75-1.74-2.87-4.58-5.48-4.18-2.07.32-3.19,2.49-3.39,2.88.35,1.57.71,3.15,1.06,4.73-.92.05-2.36.25-3.93,1-2.32,1.12-3.61,2.81-4.17,3.65-1.26-.83-2.51-1.66-3.76-2.5-.24-2.91-.47-5.82-.7-8.73,1.3-1.29,5.42-5.04,12.02-5.98,6.86-.98,12.02,1.68,13.58,2.57.01.08.03.16.04.25"
            />
            <polyline
              style={styles.five}
              points="639.26 430.22 635.58 438.03 625.24 459.96"
            />
            <path
              style={styles.five}
              d="m635.58,438.03l.62,1.15c1.89,3.47,1.76,7.69-.34,11.03l-6.12,9.75"
            />
            <line
              style={styles.five}
              x1="299.09"
              y1="404.78"
              x2="305.22"
              y2="428.14"
            />
            <path
              style={styles.five}
              d="m546.96,600.02c-.73.85-.79,2.08-.18,2.96.68.97,2.03,1.3,3.13.72,1.47-.59,3.14-1.38,4.89-2.48,2.99-1.88,5.16-3.97,6.66-5.68v-3.43"
            />
          </g>
        </g>
        <polygon
          style={styles.nine}
          points="514.89 549.9 523.87 559.46 523.87 549.29 514.89 549.9"
        />
        <path
          style={styles.four}
          d="m347.49,426.22c-3.97,4.29-8.27,8.69-12.92,13.13-7.32,6.98-14.52,13.13-21.39,18.52h-5.74c13.35-10.55,26.7-21.1,40.05-31.65Z"
        />
        <path
          style={styles.four}
          d="m346.34,478.47c-4.85.59-10.11,1.51-15.68,2.91-14.25,3.59-25.8,9.13-34.45,14.28,10.23-3.92,20.9-7.76,32.02-11.45,6.12-2.03,12.16-3.94,18.1-5.75Z"
        />
        <path
          style={styles.four}
          d="m371.09,519.7c-9.11-.4-19.87-.21-31.83,1.3-15.31,1.94-28.33,5.52-38.61,9.13,1.22-1.57,2.43-3.13,3.65-4.7,11.75-2.56,24.18-4.93,37.24-6.98,9.82-1.54,19.35-2.8,28.54-3.83.51-.22.82-.17,1-.07,1.25.65.07,4.9,0,5.14Z"
        />
        <path
          style={styles.four}
          d="m344.15,550.19c-.37.7-.99,1.67-2.02,2.51-2.27,1.85-4.64,1.55-7.04,1.96-4.75.81-5,3.6-11.35,6.85-1.95,1-4.94,2.27-9,3.07-.17-2.7-.35-5.39-.52-8.09,3.66-.4,6.45-1.28,8.35-2.02,2.76-1.08,3.11-1.67,5.48-2.35,2.86-.81,4.28-.5,8.22-.78,1.85-.13,4.57-.43,7.89-1.14Z"
        />
        <path
          style={styles.four}
          d="m550.53,408.86c-.16-.08-2.06-.96-3.74.04-1.58.94-2.3,3.14-1.5,5.2,9.47,21.27,20.05,43.32,31.88,65.95,6.81,13.04,13.69,25.61,20.6,37.71.78-1.14,1.55-2.27,2.33-3.41-5.64-13.87-11.28-27.74-16.92-41.61-5.89-8.42-12.11-18.37-18.04-29.86-6.33-12.27-11.05-23.8-14.6-34.02Z"
        />
        <path d="m439.14,400.69c6.77,4.85,11.41,12.47,12.56,20.66-.91-3.97-2.61-7.72-4.96-10.97-2.34-3.25-5.33-5.98-8.7-8.03l1.11-1.67h0Z" />
        <path d="m511.9,394.85c6.14-.62,12.45.11,18.38,2,2.96.95,5.86,2.15,8.56,3.71,2.7,1.55,5.24,3.37,7.45,5.55-2.67-1.57-5.32-3.08-8.06-4.4-2.74-1.32-5.52-2.52-8.4-3.48-5.75-1.94-11.78-3.01-17.93-3.37Z" />
        <path d="m589.48,416.82c6.14-.42,12.38.41,18.29,2.29,2.95.94,5.84,2.12,8.55,3.62,2.71,1.5,5.28,3.25,7.55,5.34-2.71-1.47-5.39-2.91-8.14-4.19-2.75-1.27-5.54-2.45-8.41-3.4-5.74-1.94-11.72-3.1-17.84-3.66Z" />
        <path d="m612.62,459.48c.1.28,38.05,70.64,38.06,70.92,0,0,3.66,19.96,3.66,19.96-.06-.28,15.16,55.2,15.16,55.15,0-.03-16.61-54.84-16.6-54.87,0,0-4.17-19.86-4.17-19.86.6,1.38-37.98-70.67-37.86-70.36,0,0,1.76-.95,1.76-.95h0Z" />
        <path d="m574.31,468.22c-4.79,1.03-8.54,5.32-9.13,10.26,0-2.54.77-5.13,2.26-7.31,1.5-2.18,3.72-3.9,6.28-4.86l.58,1.91h0Z" />
        <path d="m563.28,506.06c-.06-5.41.55-10.72.99-16.1.4,5.22.75,10.66,1,15.9,0,0-1.99.2-1.99.2h0Z" />
        <path d="m492.02,527.53c8.05-6.3,14.51-14.4,19.25-23.48-.89,2.42-1.95,4.79-3.18,7.06-3.72,6.87-8.77,13-14.81,17.97,0,0-1.26-1.56-1.26-1.56h0Z" />
        <path d="m521.69,501.7c-4.26,6.3-1.55,14.25.92,20.71,0,0-1.84.79-1.84.79-2.95-6.58-4.46-15.77.92-21.5h0Z" />
        <path
          style={styles.five}
          d="m525,523.61s8.85-2.62,10.4,2.93c.41,1.47,1.75,2.48,3.27,2.64,1.39.15,3.15.02,5.07-.88"
        />
        <path d="m525.03,550.29c-2.68-.09-5.36-.06-8.04.1-1.34.08-2.68.18-4.01.34l-3.99.51.45-.19-4.13,3.08-.2-1.72,5.38,2.35,1.28.56-.9.98c-1.54,1.68-2.65,3.84-3.08,6.17-.44,2.33-.19,4.84.73,7.13-1.22-2.14-1.86-4.68-1.73-7.24.12-2.56.99-5.12,2.51-7.3l.38,1.54-5.38-2.35-1.58-.69,1.38-1.03,4.13-3.08.21-.16.24-.03,4.08-.52c1.36-.16,2.73-.26,4.1-.35,2.74-.16,5.48-.2,8.23-.11l-.07,2Z" />
        <path
          style={styles.five}
          d="m514.28,549.6c.64,1.1,1.4,2.25,2.3,3.42,2.81,3.63,5.95,6.05,8.48,7.63"
        />
        <path d="m555.19,563.5c-1.67-2.65-10.03-11.54-12.86-7.09-.45.66-.72,1.45-.9,2.24.1-.81.28-1.63.68-2.37,2.75-5.18,12.42,3.2,14.67,6.02,0,0-1.6,1.2-1.6,1.2h0Z" />
        <path d="m537,552.83c-2.81,1.06-5.75-2.42-4.17-4.96.38,2.45,1.69,4.17,4.17,4.96h0Z" />
        <path d="m542.46,528.61l19.83-.38s.31,0,.31,0c10.82,7.16,21.37,14.72,31.75,22.5,2.97,2.28,7.29,5.56,10.15,7.93-3.18-1.85-7.78-4.78-10.94-6.78-7.21-4.64-14.31-9.44-21.35-14.34-3.52-2.45-7.02-4.92-10.46-7.48,0,0,.59.18.59.18l-19.83.38s-.04-2-.04-2h0Z" />
        <path d="m596.08,517.26c9.11,5.57,18.26,11.92,26.97,18.14,3.69,4.04,7.24,8.24,10.73,12.47-4.04-3.62-7.96-7.36-11.85-11.14-5.83-3.52-14.12-8.89-20.32-13.14-2.25-1.51-4.46-3.08-6.65-4.67,0,0,1.11-1.66,1.11-1.66h0Z" />
        <line
          style={styles.five}
          x1="653.09"
          y1="582.57"
          x2="669.49"
          y2="624.83"
        />
        <polygon points="659.55 504.12 665.09 538.76 657.58 504.49 659.55 504.12 659.55 504.12" />
        <path
          style={styles.five}
          d="m558.39,407.13l6.78,7.96c.84-.04,3.24-.28,5.48-2.09,2.55-2.06,3.2-4.77,3.36-5.54"
        />
        <path d="m356.65,458.18c-1.47,4.51,1.85,7.91,6.43,7.66,1.71.08,6.25.29,8.01.38,0,0-5.35.25-5.35.25l-2.68.12c-.83.03-1.94.15-2.76-.03-4.11-.52-6.73-5.06-5.55-9.01,0,0,1.9.62,1.9.62h0Z" />
        <path d="m361.75,496.51c-1.43,5.35.12,10.6,6.58,10.62,2.61.16,5.25.42,7.85.83-2.09.33-4.18.56-6.28.72l-1.57.11c-7.67.87-11.09-6.25-8.53-12.81.07-.15.1-.28.28-.57l1.67,1.1h0Z" />
        <path d="m345.99,527.59v8.28l-.33-.74c1.03,1.15,2.43,2.06,4,2.49,1.58.44,3.34.38,4.98-.19-1.5.86-3.31,1.32-5.14,1.17-1.96-.15-3.98-.93-5.51-2.29,0,0,0-.45,0-.45v-8.28h2Z" />
        <path d="m314.51,557.44c-8.28,2.38-14.73,9.22-17.34,17.35,0,.02-.29-1.06-.29-1.06,6.36,5.96,8.49,15.77,5.08,23.7,1.3-3.99,1.37-8.32.2-12.25-1.2-4.16-3.78-7.78-7.11-10.38,0,0,.22-.65.22-.65,2.78-8.71,9.79-16.1,18.66-18.64,0,0,.58,1.91.58,1.91h0Z" />
        <path
          style={styles.five}
          d="m302.28,530.98c-7.15,1.91-11.88,5.02-14.67,7.24-3.97,3.16-5.25,6.03-11.22,13.04-3.61,4.24-6.77,7.53-8.87,9.65-2.63,2.13-4.87,3.69-6.38,4.69-5.27,3.51-8,4.35-9.32,7.38-.91,2.09-.88,4.11-.74,5.4"
        />
        <path d="m295.19,496.62s-10.02,2.9-10.02,2.9l.42-.24c-9.55,9-18,19.19-25.11,30.28-2.54,2.38-8.55,8.08-11.25,10.14,0,0-3.88,3.21-3.88,3.21,2.25-2.02,5.34-4.68,7.51-6.7-.01.02,7.28-7.29,7.07-7.05,6.8-11.42,15.02-22.03,24.44-31.49,0,0,.25-.07.25-.07l10.02-2.9s.56,1.92.56,1.92h0Z" />
        <polyline
          style={styles.five}
          points="307.43 457.87 288.13 462.04 269.87 478.22"
        />
        <path d="m440.74,411.43c-3.23,4.36-4.49,10.02-3.25,15.25.2,4.39.31,8.79.08,13.18,0,.04-.24-.65-.24-.64,0,0,4.17,4.92,4.17,4.92l-1.03-.32s6.52-1.79,6.52-1.79c0,0-.72,1.13-.72,1.13-.52-4.83,1.57-9.83,5.42-12.69-1.63,1.65-2.8,3.69-3.38,5.85-.69,2.46-.53,5,.15,7.38-.32.09-6.91,1.9-7.46,2.05,0,0-.62.17-.62.17l-.41-.49-4.17-4.92s-.22-.25-.22-.25l-.02-.39c-.23-4.35-.13-8.71.07-13.06-.89-4.73,1-11.65,5.1-15.37h0Z" />
        <path d="m527.22,458.68c-8.4-14.73-26.08-18.07-39.13-7.24,0,0,0-1.52,0-1.52,9.04,8.09,21.52,11.14,33.47,10.2-12.1,2.41-25.33-.57-34.77-8.68,0,0-.91-.76-.91-.76,0,0,.91-.76.91-.76,13.54-11.37,33.1-7.55,40.43,8.76h0Z" />
        <path d="m533.09,461.52c-2.44-9.99-10.65-17.97-20.39-20.84,0,0,.65-1.89.65-1.89,9.9,3.66,17.86,12.27,19.74,22.74h0Z" />
        <path d="m488.48,457.87c3.38,5.77,9.49,9.53,16.24,9.4-6.68,1.88-14.57-2.57-16.24-9.4h0Z" />
        <path d="m441.73,444.66c-.27,4.02.28,7.95,1.56,11.77,0,0-.39-.67-.39-.67,3.5,1.94,5.65-1.08,6.23-4.31-.58-1.41-1.93-5.76-2.82-8.14,0,0,1.89-.65,1.89-.65.93,2.81,1.89,5.62,2.87,8.41.73,2.46-.15,5.28-2.29,6.86-2.23,1.84-5.82,1.15-7.5-1.03.21-2.09.05-4.15-.2-6.18-.27-1.96-.63-3.91-1.35-5.81l1.98-.26h0Z" />
        <path d="m334.08,417.17c-4.99,5.48,5.73,5.35,8.66,3.55,0,0-.46.48-.46.48.68-1.29.74-2.84.35-4.23-.38-1.38-1.19-2.65-2.36-3.43l1.54-1.28c1.64,3.01,1.78,6.32,1.75,9.66,0,0-.48.04-.48.04-3.17.18-9.73,1.94-11.51-1.63-.51-1.58.21-3,.87-4.33,0,0,1.64,1.15,1.64,1.15h0Z" />
        <polygon points="326.44 610.26 323.7 604.53 328.08 609.13 326.44 610.26 326.44 610.26" />
        <path d="m321,569.62c24.56-1.84,49.4-.08,73.57,4.6-24.37-3.44-48.96-4.76-73.57-4.6h0Z" />
        <line
          style={styles.five}
          x1="625.24"
          y1="485.08"
          x2="626.02"
          y2="478.48"
        />
        <line
          style={styles.five}
          x1="420.3"
          y1="532.52"
          x2="410.73"
          y2="532.52"
        />
      </g>
      <path
        style={styles.three}
        d="m619.86,386.29c-.52-3.92-2.89-6.68-5.09-6.94-2.06-.25-3.64.74-3.64.74-.3.18-.57.4-.81.64-2.52,2.56-3.35,5.01-3.67,6.34-1.36,5.6,1.66,10.66,4.11,14.77.81,1.37,1.65,2.57,2.45,3.6,1.36,1.76,3.85,2.17,5.72.97,2.82-1.8,6.9-4.52,10.42-7.31,1.37-1.09,3.27-2.67,4.4-5.38.42-1.02.64-1.95.75-2.69.05-.3.07-.6.05-.9-.14-2.14-1.26-3.97-2.96-4.73-.14-.06-.29-.12-.43-.17-1.09-.35-2.12-.03-3.38.48-2,.81-2.19,1.16-3.91,1.74-1.28.43-1.83.46-2.35.29-.92-.29-1.44-1.07-1.66-1.47Z"
      />
    </g>
  );
};

export default Drizzy;
