import "../styles/Gucci.css";

const Gucci = () => {
  return (
    <g>
      <g>
        <g>
          <path
            class="gucci-4"
            d="m744.01,372.33c.64,1.77,2.49,7.52-.01,13.67-2.88,7.09-10.38,11.84-19,12-5.2,3.45-11.86,7.12-20,10-19.17,6.78-32.29,5.53-42,4,8.79-1.55,14.55-3.77,25-7,8.04-2.48,15.38-5.22,22-8-5.42-2.22-11.12-4.86-17-8-15.83-8.46-28.38-18.17-38-27-.92-2.19-2.5-6.27-3.64-11.69h-.01c-1.61-7.61-2.39-17.88.65-29.31,3.81-14.35,11.82-23.69,16.02-27.98l.02-.02c6.05-2.77,15.3-6.11,26.96-7,17.97-1.37,32,3.82,39,7,1.73,1.12,7.49,5.15,10,13,2.99,9.32-.82,17.12-1.61,18.64-2.18-10.44-6.15-18.48-9.04-23.42-.82-1.4-2.31-2.29-3.93-2.34-4.91-.15-10.82.06-17.42,1.12-6.55,1.06-12.19,2.7-16.77,4.37-1.53.56-2.65,1.87-3,3.45-1.91,8.65-3.83,17.3-5.74,25.94-.31,1.42.04,2.9.94,4.03,12.04,15.03,24.09,30.06,36.13,45.09,2.03,2.54,5.96,2.36,7.73-.36,2.24-3.46,4.87-8.12,7.16-13.84.22-.08,1.9-.66,3.55.32,1.84,1.09,2,3.16,2.01,3.33Z"
          />
          <path
            class="gucci-7"
            d="m744,339c.18,11.66-2.34,21.7-5.55,29.68-2.29,5.72-4.92,10.38-7.16,13.84-1.77,2.72-5.7,2.9-7.73.36-12.04-15.03-24.09-30.06-36.13-45.09-.9-1.13-1.25-2.61-.94-4.03,1.91-8.64,3.83-17.29,5.74-25.94.35-1.58,1.47-2.89,3-3.45,4.58-1.67,10.22-3.31,16.77-4.37,6.6-1.06,12.51-1.27,17.42-1.12,1.62.05,3.11.94,3.93,2.34,2.89,4.94,6.86,12.98,9.04,23.42.92,4.38,1.53,9.18,1.61,14.36Z"
          />
          <path
            class="gucci-3"
            d="m668,293l.02.02c-4.2,4.29-12.21,13.63-16.02,27.98-3.04,11.43-2.26,21.7-.65,29.31-32.56-7.62-69.89-14.33-111.35-18.31-51.21-4.92-97.13-4.58-136-2l4.31-56.15c34.23-4.91,77.34-7.9,126.69-3.85,42.37,3.47,103.38,14.59,133,23Z"
          />
          <path
            class="gucci-7"
            d="m408.31,273.85l-4.31,56.15c-1.87-.02-4.44.07-7.41.58l5.18-55.76,6.54-.97Z"
          />
          <path
            class="gucci-3"
            d="m408.31,273.85l-6.54.97c2.14-.33,4.32-.66,6.54-.97Z"
          />
          <path
            class="gucci-3"
            d="m404,330l-7.41.58c2.97-.51,5.54-.6,7.41-.58Z"
          />
          <path
            class="gucci-3"
            d="m401.77,274.82l-5.18,55.76c-3.74.63-8.13,1.91-12.59,4.42-.76.43-1.49.87-2.18,1.33l-1.53-60.32c1.38-.78,2.94-1.47,4.71-2.01,8.03-2.45,14.77,0,16.77.82Z"
          />
          <path
            class="gucci-7"
            d="m380.29,276.01l1.53,60.32c-2.36,1.54-4.29,3.18-5.82,4.67l-1-61.07h0s.03-.03.03-.03l5.26-3.89Z"
          />
          <path
            class="gucci-3"
            d="m381.82,336.33l-5.82,4.67c1.53-1.49,3.46-3.13,5.82-4.67Z"
          />
          <path
            class="gucci-3"
            d="m380.29,276.01l-5.26,3.89c1.24-1.21,2.99-2.64,5.26-3.89Z"
          />
          <path
            class="gucci-3"
            d="m375,279.93l1,61.07c-1.07,5.64-2.15,11.28-3.22,16.93l-14.7-2.34c-.58-5.18-.97-10.72-1.08-16.59-.29-15.72,1.53-29.62,4-41.13,4.17-5.46,8.33-10.91,12.5-16.37.37-.42.87-.97,1.5-1.57Z"
          />
        </g>
        <path
          class="gucci-5"
          d="m658,412c1.79-6.35,4.89-17.04,3-30-1.22-8.38-3.74-15.15-6-20"
        />
      </g>
      <path
        class="gucci-1"
        d="m358,308c15.91-5.07,36.54-10.7,61-15,10.94-1.92,44.62-7.43,88-7,33.78.34,86.14,4.41,149,24-1.05,2.38-2.09,5.05-3,8-1.63,5.28-2.47,10.13-2.89,14.23-38.67-11.62-93.66-23.52-160.11-23.23-53.36.23-98.64,8.24-133,17,.33-6,.67-12,1-18Z"
      />
      <path
        class="gucci-7"
        d="m655,362.83c2.47,4.56,6.47,13.38,7,25.17.49,10.8-2.14,19.28-4,24,5.76-.51,12.15-1.44,19-3,13.36-3.05,24.44-7.63,33-12-7.51-2.89-16.02-6.76-25-12-12.53-7.31-22.46-15.22-30-22.17Z"
      />
      <polygon
        class="gucci-7"
        points="375 299.83 375.87 329.83 381.82 327.74 380.96 296.83 375 297.74 375 299.83"
      />
      <polygon
        class="gucci-7"
        points="400 293 397.65 319.13 404.83 319.13 407 292 400 293"
      />
      <polygon
        class="gucci-8"
        points="687 328 742.26 323.17 744 339 687.43 337.79 686.49 333.76 687 328"
      />
      <path
        class="gucci-2"
        d="m695,286c-6.73,4.56-19.54,14.55-29,32-9.86,18.19-10.99,35.09-11,43.26-2.17-5.32-7.57-20.56-3-39.26,3.67-15.01,11.93-24.73,16.04-29,3.54-1.41,7.53-2.79,11.96-4,5.46-1.49,10.52-2.42,15-3Z"
      />
      <g>
        <path
          class="gucci-6"
          d="m651.36,350.31c-32.56-7.62-69.9-14.33-111.36-18.31-51.21-4.92-97.13-4.58-136-2-1.87-.02-4.44.07-7.41.58-3.74.63-8.13,1.91-12.59,4.42-3.39,1.91-6.02,4.07-8,6-1.07,5.64-2.15,11.28-3.22,16.93l-14.7-2.34c-.58-5.18-.97-10.72-1.08-16.59-.29-15.72,1.53-29.62,4-41.13,4.17-5.46,8.33-10.91,12.5-16.37,1.54-1.77,5.3-5.61,11.5-7.5,8.03-2.45,14.77,0,16.77.82,2.14-.33,4.32-.66,6.54-.97,34.23-4.91,77.34-7.9,126.69-3.85,42.37,3.47,103.38,14.59,133,23l.02.02"
        />
        <polygon
          class="gucci-6"
          points="375 279.92 376 341 381.82 336.33 380.29 276.01 375 279.92"
        />
        <polygon
          class="gucci-6"
          points="401.77 274.82 396.59 330.58 404 330 408.31 273.85 401.77 274.82"
        />
        <path
          class="gucci-6"
          d="m692.23,307.82c-1.91,8.65-3.82,17.29-5.73,25.94-.31,1.41.03,2.89.94,4.02,12.04,15.03,24.08,30.06,36.13,45.1,2.03,2.54,5.97,2.36,7.73-.37,5.37-8.29,13.02-23.52,12.71-43.52-.28-17.51-6.54-30.76-10.65-37.78-.82-1.4-2.31-2.29-3.93-2.34-4.91-.16-10.82.05-17.42,1.12-6.55,1.06-12.19,2.7-16.77,4.37-1.53.56-2.65,1.87-3,3.45Z"
        />
        <path
          class="gucci-6"
          d="m742.39,324.64c.79-1.53,4.59-9.32,1.61-18.64-2.51-7.85-8.27-11.88-10-13-7-3.18-21.03-8.37-39-7-11.66.89-20.91,4.23-26.96,7-4.2,4.28-12.22,13.63-16.04,28-5.2,19.58.78,35.73,3,41,9.62,8.83,22.17,18.54,38,27,5.88,3.14,11.58,5.78,17,8-6.62,2.78-13.96,5.52-22,8-10.45,3.23-16.21,5.45-25,7,9.71,1.53,22.83,2.78,42-4,8.14-2.88,14.8-6.55,20-10,8.62-.16,16.12-4.91,19-12,2.5-6.15.65-11.9,0-13.67-.01-.17-.17-2.24-2.01-3.33-1.65-.98-3.33-.4-3.55-.32"
        />
        <path
          class="gucci-6"
          d="m655,362c2.26,4.85,4.78,11.62,6,20,1.89,12.96-1.21,23.65-3,30"
        />
      </g>
    </g>
  );
};

export default Gucci;
