const ChromeBeanie = () => {
  const styles = {
    one: {
      stroke: "#737573",
      fill: "none",
    },
    two: {
      stroke: "#737573",
      fill: "#3e3e41",
      strokeWidth: 2,
    },
    three: {
      fill: "none",
      strokeWidth: 2,
      stroke: "#000",
    },
    four: {
      fill: "#737573",
      stroke: "#3e3e41",
    },
    five: {
      fill: "#ced0c9",
    },
    six: {
      fill: "#737573",
    },
    seven: {
      fill: "#393b3c",
    },
  };

  return (
    <g>
      <g>
        <g>
          <path
            style={styles.seven}
            d="m671.22,331.35c.13.87.25,2.48-.39,4.3-.34.95-.8,1.69-1.19,2.22.34.58.84,1.52,1.19,2.74,0,0-3.82,8.48-15.13,9-.69-.79-1.55-1.96-2.24-3.52-.49-1.13-.77-2.17-.92-3,0,0,15.74-5.63,17.06-16.89.53,1.71,1.08,3.43,1.62,5.15Z"
          />
          <path
            style={styles.seven}
            d="m670.83,340.61c.42,1.49.88,4.2-1.58,11.74-.6,1.82-1.48,4.29-2.76,7.17-.84.09-3.88.3-6.71-1.69-4.06-2.87-4.09-7.75-4.08-8.22,11.31-.52,15.13-9,15.13-9Z"
          />
          <path
            style={styles.seven}
            d="m667.28,318.83c.78,2.45,1.54,4.91,2.32,7.37-1.32,11.26-17.06,16.89-17.06,16.89-.57-.47-1.22-1.11-1.85-1.96-1.12-1.54-1.61-3.05-1.85-4.04,14.22-4.18,10.86-40.06,10.86-40.06,4.53,8.41,6.57,16.31,7.58,21.8Z"
          />
          <path
            style={styles.seven}
            d="m659.7,297.03s3.36,35.88-10.86,40.06c-1.09-.41-2.48-1.05-3.95-2.09-1.9-1.35-3.16-2.83-3.95-3.91,18.16-22.44,1.57-54.59,1.57-54.59.92.72,1.82,1.47,2.67,2.24,3.31,2.89,6.2,5.99,8.73,9.33,1.17,1.51,2.25,3.08,3.28,4.69.91,1.43,1.74,2.86,2.49,4.27Z"
          />
          <path
            style={styles.seven}
            d="m654.05,287.98l-.12.09c-2.53-3.34-5.42-6.44-8.73-9.33.81-6-3.02-15.67-3.23-16.22,5.83,9.86,9.63,18.78,12.08,25.46Z"
          />
          <path
            style={styles.seven}
            d="m641.97,262.52c.21.55,4.04,10.22,3.23,16.22-.86-.77-1.76-1.52-2.67-2.24-2.15-1.72-4.47-3.36-6.97-4.94,3.49-10.08-12.16-34.07-12.6-34.75,8,8.73,14.21,17.54,19.01,25.69,0,0,0,.01.01.02Z"
          />
          <path
            style={styles.seven}
            d="m642.52,276.5s16.59,32.15-1.57,54.59c-2.26-.48-4.9-1.21-7.77-2.35-2.27-.91-4.24-1.91-5.92-2.87,20.53-28.7,2.52-57.67,2.52-57.67,2.03,1.09,3.95,2.21,5.77,3.36,2.5,1.58,4.82,3.22,6.97,4.94Z"
          />
          <path
            style={styles.seven}
            d="m622.95,236.81c.44.68,16.09,24.67,12.6,34.75-1.83-1.15-3.74-2.27-5.77-3.36-3.05-1.65-6.34-3.23-9.9-4.76,3.88-21.15-21.84-48.31-22.59-49.09,7.65,5.33,15.55,11.85,23.2,19.82.84.88,1.66,1.75,2.45,2.63h.01Z"
          />
          <path
            style={styles.seven}
            d="m619.88,263.44c3.56,1.53,6.85,3.11,9.9,4.76,0,0,18.01,28.97-2.52,57.67-1.9-.02-4.77-.26-8.02-1.44-2.88-1.03-5.03-2.43-6.45-3.52,14.21-21.39-2.56-61.25-2.56-61.25,3.42,1.22,6.64,2.48,9.66,3.78Z"
          />
          <path
            style={styles.seven}
            d="m597.29,214.35c.75.78,26.48,27.94,22.59,49.09-3.02-1.3-6.24-2.56-9.66-3.78-1.78-.65-3.61-1.28-5.51-1.9h0c-2.81-29.26-25.68-54.39-25.68-54.39,5.71,2.93,11.89,6.54,18.23,10.96l.02.02Z"
          />
          <path
            style={styles.seven}
            d="m610.23,259.66s16.77,39.86,2.56,61.25c-2.89.44-7.06.74-11.98,0-5.08-.76-9.06-2.35-11.71-3.65,10.52-28.63-.59-60.29-2.18-64.57,3.83.88,5.93,1.45,5.93,1.45,4.19,1.18,8.14,2.38,11.87,3.61h0c1.9.63,3.73,1.26,5.51,1.91Z"
          />
          <path
            style={styles.seven}
            d="m604.72,257.75c-3.72-1.23-7.68-2.43-11.87-3.61,0,0-2.1-.57-5.93-1.45-.12-.32-.19-.49-.19-.49,2.63-22.28-28.16-57.57-28.16-57.57,5.43,1.88,12.51,4.66,20.47,8.74,0,0,22.87,25.13,25.68,54.38Z"
          />
          <path
            style={styles.seven}
            d="m586.92,252.69c1.59,4.28,12.7,35.94,2.18,64.57-4.19.04-9.22-.24-14.84-1.24-4.69-.84-8.81-2-12.27-3.19,6.32-33.92-5.46-65.74-5.46-65.74,3.82.55,7.42,1.12,10.75,1.69,8.48,1.46,15.22,2.89,19.65,3.91Z"
          />
          <path
            style={styles.seven}
            d="m558.57,194.63s30.79,35.29,28.16,57.57c0,0,.07.17.19.49-4.43-1.02-11.17-2.45-19.65-3.91.81-29.61-27.35-60.23-27.35-60.23h0c1.2.14,2.46.35,3.77.62,5.67,1.19,10.14,3.2,13.23,4.9.52.18,1.08.36,1.64.56Z"
          />
          <path
            style={styles.seven}
            d="m539.92,188.55s28.16,30.62,27.35,60.23c-3.33-.57-6.92-1.14-10.75-1.69-4.91-.71-10.21-1.38-15.82-1.97,6.88-29.1-8.27-56.92-8.27-56.92,2.12-.07,4.65,0,7.49.34h0Z"
          />
          <path
            style={styles.seven}
            d="m556.53,247.09s11.77,31.82,5.46,65.74c-4.23.55-9.63.83-15.79.13-3.77-.44-7.13-1.16-10.01-1.96,5.01-42.8-4.68-63.41-6.54-66.89,3.8.3,7.5.63,11.06,1.01,5.61.59,10.91,1.26,15.82,1.97Z"
          />
          <path
            style={styles.seven}
            d="m529.65,244.11c-3.36-.25-6.82-.47-10.34-.64l.02-.43c2.1-7.96,0-57.42,0-57.42.54,0,1.1.01,1.66.03,4.9.22,8.84,1.47,11.44,2.55,0,0,15.16,27.82,8.27,56.92-3.56-.38-7.25-.71-11.06-1.01Z"
          />
          <path
            style={styles.seven}
            d="m529.65,244.11c1.86,3.48,11.55,24.09,6.54,66.89-2.64.42-5.65.73-8.95.78-6.36.1-11.82-.78-16.05-1.82,1.54-6.9-1.45-64.37-1.58-66.84,3.29.07,6.53.19,9.71.35,3.52.17,6.98.39,10.34.64Z"
          />
          <path
            style={styles.seven}
            d="m509.6,243.12c-5.5-.12-11.13-.11-16.84.06v-.14c-4.22-34.05,8.47-54.7,8.47-54.7,1.3.25,2.37.56,3.18.83,2.73-1.35,8.03-3.5,14.92-3.55,0,0,2.1,49.46,0,57.42l-.02.43c-3.18-.16-6.42-.28-9.71-.35Z"
          />
          <path
            style={styles.seven}
            d="m509.6,243.12c.13,2.47,3.13,59.94,1.58,66.84-3.41.91-8.04,1.76-13.56,1.69-3.94-.05-7.37-.56-10.13-1.17-1.28.33-2.57.67-3.88,1.04-11.8-46.69.19-67.95.19-67.95,3.01-.18,5.99-.31,8.96-.39,5.71-.17,11.34-.18,16.84-.06Z"
          />
          <path
            style={styles.seven}
            d="m491.38,188.39c3.85-.82,7.28-.56,9.85-.05,0,0-12.68,20.65-8.47,54.7v.14c-2.97.08-5.95.21-8.96.39-5.13.3-10.3.74-15.5,1.34,0,0-2.52-41.26,16.37-53.98,1.63-.91,3.89-1.93,6.71-2.54Z"
          />
          <path
            style={styles.seven}
            d="m483.8,243.57s-11.99,21.26-.19,67.95c-2.66.75-5.24,1.54-7.7,2.35-1.89.39-3.9.72-6.05.98-4.89.57-9.32.58-13.16.32-14.82-39.42-2.41-67.61-2.1-68.3,4.56-.79,9.14-1.44,13.7-1.96,5.2-.6,10.37-1.04,15.5-1.34Z"
          />
          <path
            style={styles.seven}
            d="m447,229.19c.68-4.49,1.76-8.69,3.07-12.53,1.93-5.67,4.35-10.56,6.63-14.48,3.92-6.74,7.46-10.61,7.46-10.61,8.89-2.34,16.73-1.33,20.51-.64-18.89,12.72-16.37,53.98-16.37,53.98-4.56.52-9.14,1.17-13.7,1.96-2.55.44-5.11.92-7.66,1.45-.47-3.23-.68-6.38-.68-9.43.01-3.36.27-6.6.74-9.7Z"
          />
          <path
            style={styles.seven}
            d="m455.71,194.78c2.86-1.46,5.7-2.5,8.45-3.21,0,0-3.53,3.87-7.46,10.61,0,0-11.18,4.34-15.98,9.97h-.01c.99-1.96,2.06-3.89,3.2-5.76h.01c1.65-2.7,3.49-5.28,5.52-7.64,1.8-1.39,3.88-2.75,6.28-3.97Z"
          />
          <path
            style={styles.seven}
            d="m440.72,212.15c4.8-5.63,15.98-9.97,15.98-9.97-2.28,3.92-4.7,8.81-6.63,14.48-5.77,1.27-14.86,8.81-14.86,8.81.98-2.9,2.1-5.86,3.39-8.81.66-1.51,1.36-3.02,2.11-4.51h.01Z"
          />
          <path
            style={styles.seven}
            d="m454.6,246.87c-.31.69-12.72,28.88,2.1,68.3-2.35,1.21-5.49,2.53-9.34,3.4-5.93,1.32-11.02,1-14.35.52-25.79-50.61-1.64-66.89-1.64-66.89,5.15-1.5,10.34-2.79,15.58-3.88,2.55-.53,5.11-1.01,7.66-1.45Z"
          />
          <path
            style={styles.seven}
            d="m450.07,216.66c-1.31,3.84-2.39,8.04-3.07,12.53-7.42,2.76-15.07,9.28-15.55,9.68.58-2.55,1.28-5.35,2.15-8.3.48-1.66,1.02-3.37,1.6-5.1,0,0,9.09-7.54,14.86-8.81Z"
          />
          <path
            style={styles.seven}
            d="m445.97,201.7c1.01-.96,2.16-1.97,3.46-2.95-2.03,2.36-3.86,4.94-5.52,7.64h-.01c-.42.14-13.54,4.39-19.12,11.51,1.59-2.59,3.39-5.19,5.4-7.79,1.11-1.14,2.48-2.36,4.15-3.52,4.34-3.05,8.69-4.32,11.64-4.89Z"
          />
          <path
            style={styles.seven}
            d="m447,229.19c-.46,3.1-.73,6.34-.74,9.7-.41.16-12.32,4.61-16.74,9.88.3-1.91.76-4.53,1.41-7.63.16-.73.32-1.48.5-2.26,0,0,.01,0,.01-.01.47-.4,8.13-6.92,15.55-9.68Z"
          />
          <path
            style={styles.seven}
            d="m446.27,238.89c0,3.05.2,6.2.68,9.43-5.24,1.09-10.43,2.38-15.58,3.88-.71.2-1.4.41-2.1.63l-.2-.63s.13-1.25.46-3.42h0c4.42-5.28,16.33-9.73,16.74-9.89Z"
          />
          <path
            style={styles.seven}
            d="m421.78,223.17c.92-1.73,1.92-3.49,3-5.25t.01-.02c5.58-7.12,18.7-11.37,19.12-11.51-1.14,1.87-2.21,3.8-3.2,5.76-.75,1.49-1.45,3-2.11,4.51,0,0-14.58,5.59-19.7,12.53.85-1.97,1.81-3.98,2.88-6.02Z"
          />
          <path
            style={styles.seven}
            d="m438.6,216.66c-1.29,2.95-2.41,5.91-3.39,8.81-.59,1.73-1.12,3.44-1.6,5.1,0,0-11.9,1.3-18.92,10.57.57-2.06,1.24-4.24,2.06-6.51.64-1.76,1.35-3.58,2.16-5.44,5.12-6.94,19.7-12.53,19.7-12.53Z"
          />
          <path
            style={styles.seven}
            d="m431.44,238.88c-.18.78-.34,1.53-.5,2.26,0,0-14.07,4.48-18.77,13.04.25-2.4.73-5.56,1.56-9.27.27-1.2.59-2.46.95-3.77,7.02-9.27,18.92-10.57,18.92-10.57-.87,2.95-1.57,5.75-2.15,8.3,0,.01-.01.01-.01.01Z"
          />
          <path
            style={styles.seven}
            d="m429.26,252.83c.7-.22,1.39-.43,2.1-.63,0,0-24.15,16.28,1.64,66.89-1.37.93-3.04,1.89-5,2.74-4.53,1.95-8.71,2.42-11.57,2.47-32.83-23.76-5.13-64.35-4.58-65.15h.01c5.68-2.43,11.49-4.53,17.41-6.32Z"
          />
          <path
            style={styles.seven}
            d="m430.94,241.14c-.66,3.1-1.11,5.72-1.41,7.63h0c-.33,2.18-.46,3.43-.46,3.43l.2.63c-5.91,1.79-11.72,3.89-17.41,6.31,0,0-.02-1.82.31-4.96,4.7-8.56,18.77-13.04,18.77-13.04Z"
          />
          <path
            style={styles.seven}
            d="m430.18,210.11c-2.01,2.6-3.8,5.2-5.4,7.79t-.01.02c-1.08,1.76-2.08,3.52-3,5.25-.33.05-7.08,1.09-15.24,8.11,2.21-2.86,4.68-5.69,7.45-8.45,5.49-5.47,11.11-9.6,16.19-12.72Z"
          />
          <path
            style={styles.seven}
            d="m421.78,223.17c-1.07,2.04-2.03,4.05-2.88,6.02-.81,1.86-1.52,3.68-2.16,5.44,0,0-7.36-1.39-16.8,6.51,1.88-3.27,4.07-6.58,6.6-9.86,8.15-7.02,14.9-8.06,15.24-8.11Z"
          />
          <path
            style={styles.seven}
            d="m399.94,241.14c9.44-7.9,16.8-6.51,16.8-6.51-.82,2.27-1.49,4.45-2.06,6.51-.36,1.31-.68,2.57-.95,3.77-.54.18-15.71,5.23-22.57,16.7,1.8-6.03,4.54-13.14,8.78-20.47Z"
          />
          <path
            style={styles.seven}
            d="m416.43,324.3c-1.37,1.14-3.84,2.88-7.38,3.92-3.18.93-5.92.92-7.63.78-26.85-27.91-6.59-61.31-6.59-61.31,5.49-3.22,11.17-6.06,17.01-8.54-.54.8-28.25,41.39,4.58,65.15Z"
          />
          <path
            style={styles.seven}
            d="m413.74,244.91c-.84,3.71-1.31,6.87-1.56,9.27-.33,3.14-.31,4.96-.31,4.96h-.01c-5.84,2.49-11.52,5.33-17.01,8.55-2.3,1.36-4.34,2.92-5.83,4.18l-.28-.33c.52-2.73,1.29-6.11,2.43-9.92,0,0,0,0,.01,0,6.86-11.47,22.03-16.52,22.57-16.7Z"
          />
          <path
            style={styles.seven}
            d="m401.43,329c-.46.94-2.06,3.83-5.53,5.35-1.92.84-3.67.94-4.74.91-25.53-15.91-10.79-52.96-10.79-52.96,1.06-1.89,2.98-4.94,6.05-8.08,0,0,.98-1,2.58-2.35,1.49-1.26,3.53-2.82,5.83-4.18,0,0-20.26,33.4,6.59,61.31Z"
          />
          <path
            style={styles.seven}
            d="m391.15,335.26c-.62,1.03-1.87,2.75-4.08,3.91-.71.37-1.37.62-1.98.79-12.63-2.09-18.95-15.39-18.95-15.39-.78-4.85-1.97-16.59,4.21-28.96,3.07-6.14,6.98-10.5,10.01-13.31,0,0-14.74,37.05,10.79,52.96Z"
          />
          <path
            style={styles.seven}
            d="m385.1,339.96c0,.69-.11,2.41-1.31,4.04-.63.84-1.33,1.37-1.85,1.7-12.37,4.17-15.79-3.13-15.79-3.13-.82-1.46-2.28-4.51-2.37-8.61-.09-4.56,1.54-7.93,2.37-9.39,0,0,6.32,13.3,18.95,15.39Z"
          />
          <path
            style={styles.seven}
            d="m381.94,345.7c.33,5.56-2.94,10.3-7.37,11.47-4.22,1.12-7.86-1.41-8.43-1.82-.62-1.71-1.37-4.57-1.05-8.09.17-1.88.61-3.47,1.05-4.69,0,0,3.42,7.3,15.79,3.13Z"
          />
        </g>
        <g>
          <path
            style={styles.three}
            d="m641.97,262.52c5.83,9.86,9.63,18.78,12.08,25.46"
          />
          <path
            style={styles.three}
            d="m622.95,236.81c8,8.73,14.21,17.54,19.01,25.69"
          />
          <path
            style={styles.three}
            d="m597.29,214.35c7.65,5.33,15.55,11.85,23.2,19.82.84.88,1.66,1.75,2.45,2.63"
          />
          <path
            style={styles.three}
            d="m391.16,261.61c1.8-6.03,4.54-13.14,8.78-20.47,1.88-3.27,4.07-6.58,6.6-9.86,2.21-2.86,4.68-5.69,7.45-8.45,5.49-5.47,11.11-9.6,16.19-12.72,1.11-1.14,2.48-2.36,4.15-3.52,4.34-3.05,8.69-4.32,11.64-4.89,1.01-.96,2.16-1.97,3.46-2.95,1.8-1.39,3.88-2.75,6.28-3.97,2.86-1.46,5.7-2.5,8.45-3.21,8.89-2.34,16.73-1.33,20.51-.64,1.63-.91,3.89-1.93,6.71-2.54,3.85-.82,7.28-.56,9.85-.05,1.3.25,2.37.56,3.18.83,2.73-1.35,8.03-3.5,14.92-3.55.54,0,1.1.01,1.66.03,4.9.22,8.84,1.47,11.44,2.55,2.12-.07,4.65,0,7.49.34,1.2.15,2.46.36,3.77.63,5.67,1.19,10.14,3.2,13.23,4.9.52.18,1.08.36,1.64.56,5.43,1.88,12.51,4.66,20.47,8.74,5.71,2.93,11.89,6.54,18.23,10.96"
          />
          <path
            style={styles.three}
            d="m388.72,271.54c.52-2.73,1.29-6.11,2.43-9.92"
          />
          <path
            style={styles.three}
            d="m604.72,257.76c1.9.62,3.73,1.25,5.51,1.9,3.42,1.22,6.64,2.48,9.66,3.78,3.56,1.53,6.85,3.11,9.9,4.76,2.03,1.09,3.95,2.21,5.77,3.36,2.5,1.58,4.82,3.22,6.97,4.94.92.72,1.82,1.47,2.67,2.24,3.31,2.89,6.2,5.99,8.73,9.33,1.17,1.51,2.25,3.08,3.28,4.69.91,1.43,1.74,2.86,2.49,4.27,4.53,8.41,6.57,16.31,7.58,21.8.78,2.45,1.54,4.91,2.32,7.37.53,1.71,1.08,3.43,1.62,5.15.13.87.25,2.48-.39,4.3-.34.95-.8,1.69-1.19,2.22.34.58.84,1.52,1.19,2.74.42,1.49.88,4.2-1.58,11.74-.6,1.82-1.48,4.29-2.76,7.17-.84.09-3.88.3-6.71-1.69-4.06-2.87-4.09-7.75-4.08-8.22-.69-.79-1.55-1.96-2.24-3.52-.49-1.13-.77-2.17-.92-3-.57-.47-1.22-1.11-1.85-1.96-1.12-1.54-1.61-3.05-1.85-4.04-1.09-.41-2.48-1.05-3.95-2.09-1.9-1.35-3.16-2.83-3.95-3.91-2.26-.48-4.9-1.21-7.77-2.35-2.27-.91-4.24-1.91-5.92-2.87-1.9-.02-4.77-.26-8.02-1.44-2.88-1.03-5.03-2.43-6.45-3.52-2.89.44-7.06.74-11.98,0-5.08-.76-9.06-2.35-11.71-3.65-4.19.04-9.22-.24-14.84-1.24-4.69-.84-8.81-2-12.27-3.19-4.23.55-9.63.83-15.79.13-3.77-.44-7.13-1.16-10.01-1.96-2.64.42-5.65.73-8.95.78-6.36.1-11.82-.78-16.05-1.82-3.41.91-8.04,1.76-13.56,1.69-3.94-.05-7.37-.56-10.13-1.17-1.28.33-2.57.67-3.88,1.04-2.66.75-5.24,1.54-7.7,2.35-1.89.39-3.9.72-6.05.98-4.89.57-9.32.58-13.16.32-2.35,1.21-5.49,2.53-9.34,3.4-5.93,1.32-11.02,1-14.35.52-1.37.93-3.04,1.89-5,2.74-4.53,1.95-8.71,2.42-11.57,2.47-1.37,1.14-3.84,2.88-7.38,3.92-3.18.93-5.92.92-7.63.78-.46.94-2.06,3.83-5.53,5.35-1.92.84-3.67.94-4.74.91-.62,1.03-1.87,2.75-4.08,3.91-.71.37-1.37.62-1.98.79,0,.69-.11,2.41-1.31,4.04-.63.84-1.33,1.37-1.85,1.7.33,5.56-2.94,10.3-7.37,11.47-4.22,1.12-7.86-1.41-8.43-1.82-.62-1.71-1.37-4.57-1.05-8.09.17-1.88.61-3.47,1.05-4.69-.82-1.46-2.28-4.51-2.37-8.61-.09-4.56,1.54-7.93,2.37-9.39-.78-4.85-1.97-16.59,4.21-28.96,3.07-6.14,6.98-10.5,10.01-13.31,1.06-1.89,2.98-4.94,6.05-8.08,0,0,.98-1,2.58-2.35,1.49-1.26,3.53-2.82,5.83-4.18,5.49-3.22,11.17-6.06,17.01-8.54"
          />
          <path
            style={styles.three}
            d="m411.86,259.14c5.68-2.42,11.49-4.52,17.41-6.31.7-.22,1.39-.43,2.1-.63,5.15-1.5,10.34-2.79,15.58-3.88,2.55-.53,5.11-1.01,7.66-1.45,4.56-.79,9.14-1.44,13.7-1.96,5.2-.6,10.37-1.04,15.5-1.34,3.01-.18,5.99-.31,8.96-.39,5.71-.17,11.34-.18,16.84-.06,3.29.07,6.53.19,9.71.35,3.52.17,6.98.39,10.34.64,3.8.3,7.5.63,11.06,1.01,5.61.59,10.91,1.26,15.82,1.97,3.82.55,7.42,1.12,10.75,1.69,8.48,1.46,15.22,2.89,19.65,3.91,3.83.88,5.93,1.45,5.93,1.45,4.19,1.18,8.14,2.38,11.87,3.61"
          />
          <path style={styles.three} d="m366.15,324.57s6.32,13.3,18.95,15.39" />
          <path
            style={styles.three}
            d="m380.37,282.3s-14.74,37.05,10.79,52.96"
          />
          <path
            style={styles.three}
            d="m394.84,267.69s-20.26,33.4,6.59,61.31"
          />
          <path style={styles.three} d="m366.15,342.57s3.42,7.3,15.79,3.13" />
          <path
            style={styles.three}
            d="m424.78,217.9c1.59-2.59,3.39-5.19,5.4-7.79"
          />
          <path
            style={styles.three}
            d="m416.43,324.3c-32.83-23.76-5.13-64.35-4.58-65.15h.01s-.02-1.83.31-4.97c.25-2.4.73-5.56,1.56-9.27.27-1.2.59-2.46.95-3.77.57-2.06,1.24-4.24,2.06-6.51.64-1.76,1.35-3.58,2.16-5.44.85-1.97,1.81-3.98,2.88-6.02.92-1.73,1.92-3.49,3-5.25"
          />
          <path
            style={styles.three}
            d="m431.36,252.2s-24.15,16.28,1.64,66.89"
          />
          <path
            style={styles.three}
            d="m454.6,246.86h0c-.31.7-12.72,28.89,2.1,68.31"
          />
          <path style={styles.three} d="m483.8,243.57s-11.99,21.26-.19,67.95" />
          <path
            style={styles.three}
            d="m509.6,243.04v.08c.13,2.47,3.13,59.94,1.58,66.84"
          />
          <path
            style={styles.three}
            d="m529.35,243.57s.11.18.3.54c1.86,3.48,11.55,24.09,6.54,66.89"
          />
          <path
            style={styles.three}
            d="m556.53,247.09s11.77,31.82,5.46,65.74"
          />
          <path
            style={styles.three}
            d="m610.23,259.66s16.77,39.86,2.56,61.25"
          />
          <path style={styles.three} d="m629.78,268.2s18.01,28.97-2.52,57.67" />
          <path style={styles.three} d="m642.52,276.5s16.59,32.15-1.57,54.59" />
          <path style={styles.three} d="m659.7,297.03s3.36,35.88-10.86,40.06" />
          <path
            style={styles.three}
            d="m652.53,343.09s15.74-5.63,17.06-16.89c.02-.11.03-.22.04-.33"
          />
          <path style={styles.three} d="m670.83,340.61s-3.82,8.48-15.13,9" />
          <path
            style={styles.three}
            d="m443.91,206.39c1.65-2.7,3.49-5.28,5.52-7.64"
          />
          <path
            style={styles.three}
            d="m431.45,238.87c.58-2.55,1.28-5.35,2.15-8.3.48-1.66,1.02-3.37,1.6-5.1.98-2.9,2.1-5.86,3.39-8.81.66-1.51,1.36-3.02,2.11-4.51.99-1.96,2.06-3.89,3.2-5.76"
          />
          <path
            style={styles.three}
            d="m429.53,248.77c.3-1.91.76-4.53,1.41-7.63.16-.73.32-1.48.5-2.26"
          />
          <path style={styles.three} d="m429.06,252.2s.13-1.25.46-3.42" />
          <path
            style={styles.three}
            d="m464.15,191.57s-3.53,3.87-7.46,10.61c-2.28,3.92-4.7,8.81-6.63,14.48-1.31,3.84-2.39,8.04-3.07,12.53-.46,3.1-.73,6.34-.74,9.7,0,3.05.2,6.2.68,9.43"
          />
          <path
            style={styles.three}
            d="m468.3,244.91s-2.52-41.26,16.37-53.98"
          />
          <path
            style={styles.three}
            d="m501.23,188.34s-12.68,20.65-8.47,54.7"
          />
          <path style={styles.three} d="m519.33,185.62s2.1,49.46,0,57.42" />
          <path style={styles.three} d="m532.43,188.2s15.16,27.82,8.27,56.92" />
          <path
            style={styles.three}
            d="m539.92,188.55s28.16,30.62,27.35,60.23"
          />
          <path
            style={styles.three}
            d="m558.57,194.63s30.79,35.29,28.16,57.57c0,0,.07.17.19.49,1.59,4.28,12.7,35.94,2.18,64.57"
          />
          <path
            style={styles.three}
            d="m579.04,203.37s22.87,25.13,25.68,54.38h0"
          />
          <path
            style={styles.three}
            d="m597.27,214.33l.02.02c.75.78,26.48,27.94,22.59,49.09h0"
          />
          <path
            style={styles.three}
            d="m622.94,236.8h.01c.44.69,16.09,24.68,12.6,34.76"
          />
          <path
            style={styles.three}
            d="m641.96,262.5s0,.01.01.02c.21.55,4.04,10.22,3.23,16.22"
          />
          <path
            style={styles.three}
            d="m413.75,244.91h-.01c-.54.18-15.71,5.23-22.57,16.7-.01,0-.01,0-.01,0"
          />
          <path style={styles.three} d="m416.74,234.63s-7.36-1.39-16.8,6.51" />
          <path
            style={styles.three}
            d="m421.79,223.17h-.01c-.33.05-7.08,1.09-15.24,8.11"
          />
          <path
            style={styles.three}
            d="m443.91,206.39h-.01c-.42.14-13.54,4.39-19.12,11.51t-.01.02"
          />
          <path style={styles.three} d="m438.6,216.66s-14.58,5.59-19.7,12.53" />
          <path style={styles.three} d="m433.6,230.57s-11.9,1.3-18.92,10.57" />
          <path
            style={styles.three}
            d="m430.94,241.14s-14.07,4.48-18.77,13.04"
          />
          <path style={styles.three} d="m456.7,202.18s-11.18,4.34-15.98,9.97" />
          <path
            style={styles.three}
            d="m435.21,225.47s9.09-7.54,14.86-8.81h.01"
          />
          <path
            style={styles.three}
            d="m431.43,238.89h.01s.01-.01.01-.02c.47-.4,8.13-6.92,15.55-9.68h.01"
          />
          <path
            style={styles.three}
            d="m446.28,238.89h-.01c-.41.16-12.32,4.61-16.74,9.88h0"
          />
        </g>
      </g>
      <path
        style={styles.three}
        d="m388.72,271.54c2.07-10.7,7.76-31.27,25.27-48.72,5.49-5.47,11.1-9.6,16.19-12.72,1.12-1.14,2.49-2.36,4.15-3.52,4.35-3.05,8.69-4.32,11.65-4.89,2.4-2.28,5.61-4.8,9.74-6.92,12.1-6.2,24-4.75,28.95-3.84,1.64-.92,3.9-1.94,6.71-2.54,5.79-1.24,10.62-.02,13.03.78,2.95-1.45,8.88-3.86,16.58-3.52,4.91.21,8.85,1.47,11.45,2.54,3.02-.1,6.88.07,11.25.98,5.67,1.18,10.14,3.2,13.23,4.89,13.66,4.57,39.84,15.36,63.57,40.11,19.31,20.14,28.88,41.04,33.56,53.8"
      />
      <path
        style={styles.three}
        d="m366.15,355.35c-.61-1.71-1.37-4.57-1.05-8.09.17-1.88.61-3.47,1.05-4.7-.82-1.46-2.28-4.5-2.37-8.61-.1-4.55,1.54-7.93,2.37-9.39-.78-4.85-1.97-16.59,4.21-28.96,3.07-6.14,6.98-10.49,10-13.3,1.06-1.89,2.98-4.94,6.05-8.09,0,0,3.63-3.72,8.42-6.53,78.26-45.96,198.01-13.55,198.01-13.55,35.16,9.92,53.3,21.21,64.35,38.63,6.32,9.96,8.89,19.66,10.07,26.06,1.32,4.17,2.63,8.35,3.95,12.52.14.88.26,2.49-.39,4.3-.34.95-.79,1.68-1.18,2.22.34.58.83,1.52,1.18,2.74.43,1.49.89,4.2-1.58,11.74-.59,1.82-1.48,4.29-2.76,7.17-.84.09-3.88.3-6.71-1.7-4.05-2.86-4.09-7.74-4.08-8.22-.69-.79-1.55-1.96-2.24-3.52-.49-1.13-.77-2.16-.92-3-.56-.47-1.22-1.11-1.84-1.96-1.12-1.54-1.61-3.05-1.84-4.04-1.09-.41-2.49-1.05-3.95-2.09-1.9-1.35-3.16-2.83-3.95-3.91-2.26-.47-4.91-1.2-7.76-2.35-2.27-.91-4.25-1.91-5.92-2.87-1.91-.01-4.78-.26-8.03-1.43-2.88-1.04-5.03-2.43-6.45-3.52-2.89.43-7.06.74-11.98,0-5.07-.76-9.06-2.35-11.71-3.65-4.18.04-9.22-.25-14.84-1.24-4.7-.83-8.81-2-12.28-3.19-4.23.55-9.62.84-15.79.13-3.77-.43-7.13-1.15-10-1.96-2.65.42-5.65.73-8.95.78-6.35.1-11.81-.78-16.06-1.83-3.41.91-8.04,1.77-13.56,1.7-3.94-.05-7.37-.56-10.13-1.17-1.28.33-2.58.68-3.89,1.04-2.66.75-5.23,1.53-7.69,2.35-1.89.39-3.91.72-6.06.98-4.89.57-9.32.58-13.15.33-2.35,1.21-5.49,2.53-9.34,3.39-5.93,1.33-11.02,1.01-14.35.52-1.37.93-3.04,1.89-5,2.74-4.54,1.95-8.71,2.42-11.58,2.48-1.37,1.13-3.84,2.87-7.37,3.91-3.18.94-5.92.92-7.63.78-.47.94-2.05,3.83-5.53,5.35-1.92.84-3.67.94-4.74.91-.62,1.03-1.87,2.75-4.08,3.91-.7.37-1.38.62-1.97.78,0,.69-.11,2.42-1.32,4.04-.62.84-1.33,1.37-1.84,1.7.33,5.56-2.94,10.3-7.37,11.48-4.21,1.12-7.86-1.42-8.42-1.83Z"
      />
      <path style={styles.three} d="m366.15,324.57s6.32,13.3,18.95,15.39" />
      <path style={styles.three} d="m380.37,282.3s-14.74,37.04,10.79,52.96" />
      <path style={styles.three} d="m394.84,267.69s-20.26,33.4,6.59,61.31" />
      <path style={styles.three} d="m366.15,342.57s3.42,7.3,15.79,3.13" />
      <path
        style={styles.three}
        d="m416.43,324.3c-33.17-24-4.57-65.17-4.57-65.17,0,0-.3-24.97,18.32-49.03"
      />
      <path style={styles.three} d="m431.37,252.2s-24.15,16.28,1.64,66.89" />
      <path style={styles.three} d="m454.6,246.86s-12.9,28.4,2.1,68.31" />
      <path style={styles.three} d="m483.8,243.57s-11.99,21.26-.2,67.96" />
      <path style={styles.three} d="m509.6,243.04s3.16,59.87,1.58,66.92" />
      <path style={styles.three} d="m529.35,243.57s12.37,20.22,6.84,67.43" />
      <path style={styles.three} d="m556.53,247.09s11.77,31.82,5.45,65.74" />
      <path style={styles.three} d="m610.23,259.66s16.77,39.86,2.55,61.26" />
      <path style={styles.three} d="m629.78,268.2s18.01,28.97-2.52,57.67" />
      <path style={styles.three} d="m642.53,276.5s16.59,32.15-1.58,54.59" />
      <path style={styles.three} d="m659.7,297.03s3.36,35.88-10.85,40.05" />
      <path style={styles.three} d="m652.53,343.09s16.06-5.74,17.11-17.22" />
      <path style={styles.three} d="m670.82,340.61s-3.82,8.48-15.13,9" />
      <path style={styles.three} d="m429.06,252.2s3.64-33.96,20.38-53.45" />
      <path style={styles.three} d="m464.15,191.57s-21.96,24.07-17.21,56.75" />
      <path style={styles.three} d="m468.3,244.91s-2.52-41.25,16.37-53.97" />
      <path style={styles.three} d="m501.23,188.34s-12.68,20.66-8.47,54.7" />
      <path style={styles.three} d="m519.33,185.62s2.09,49.46,0,57.42" />
      <path style={styles.three} d="m532.44,188.2s15.15,27.82,8.27,56.92" />
      <path style={styles.three} d="m539.92,188.55s28.16,30.62,27.35,60.23" />
      <path
        style={styles.three}
        d="m558.57,194.63s30.79,35.29,28.16,57.57c0,0,13.69,34.28,2.37,65.06"
      />
      <path style={styles.three} d="m579.03,203.37s22.89,25.14,25.69,54.38" />
      <path style={styles.three} d="m597.27,214.33s26.56,27.66,22.62,49.12" />
      <path style={styles.three} d="m622.94,236.8s16.16,24.54,12.61,34.76" />
      <path style={styles.three} d="m641.96,262.5s4.07,10.06,3.24,16.24" />
      <path style={styles.three} d="m413.74,244.91s-15.61,5.04-22.59,16.72" />
      <path style={styles.three} d="m416.75,234.63s-7.37-1.39-16.81,6.51" />
      <path style={styles.three} d="m421.78,223.17s-6.89.91-15.24,8.11" />
      <path style={styles.three} d="m443.91,206.39s-13.48,4.28-19.14,11.53" />
      <path style={styles.three} d="m438.6,216.66s-14.59,5.59-19.7,12.53" />
      <path style={styles.three} d="m433.6,230.57s-11.9,1.31-18.91,10.58" />
      <path style={styles.three} d="m430.94,241.14s-14.07,4.48-18.77,13.04" />
      <path style={styles.three} d="m456.7,202.18s-11.18,4.34-15.99,9.97" />
      <path style={styles.three} d="m435.21,225.47s9.1-7.55,14.87-8.81" />
      <path style={styles.three} d="m431.44,238.89s7.92-6.86,15.57-9.7" />
      <path style={styles.three} d="m446.27,238.89s-12.27,4.51-16.74,9.89" />
      <path
        style={styles.two}
        d="m516.66,214.6c.03,3.35-.32,7.96-1.99,13.23-.85,2.69-1.89,5.06-2.93,7.07-.03.93.26,1.51.44,1.8.86,1.37,2.97,1.88,5.17,1.1.12.2,1.57,2.59,4.21,2.68,2.18.08,4.22-1.44,5-3.75,2.47.23,4.39-.63,4.79-2,.08-.27.13-.68-.01-1.24-.79-1.22-1.56-2.55-2.29-4-2.88-5.72-3.96-11.06-4.34-15.05,1.31-.03,3.49.1,5.95,1.14,1.53.65,2.71,1.47,3.55,2.14.23.17,1.06.78,1.79.56,1.02-.3,1.67-2.1.98-4.38.44-.29,1.23-.9,1.81-1.97.54-1,.65-1.94.67-2.47-.18-.49-.49-1.2-1.06-1.93-.72-.93-1.51-1.48-2-1.77.34-2.15-.38-3.92-1.47-4.36-.22-.09-.64-.21-1.29,0-.68.87-2.07,2.42-4.45,3.49-2.13.96-4.1,1.13-5.27,1.14-.11-1.29-.12-3.48.81-5.89.66-1.72,1.57-2.95,2.27-3.74.46-.73.33-1.21.28-1.36-.33-.91-1.98-1.61-4.08-1.27-.77-.86-1.51-1.42-2.04-1.77-1.06-.7-1.57-.75-1.79-.76-.44-.02-.83.09-1.38.37-.62.32-1.66.97-2.67,2.29-1.7-.54-3.23-.07-3.66.85-.24.51-.1,1.09.05,1.46.88.88,2.17,2.38,3.15,4.52.98,2.14,1.26,4.06,1.34,5.29-1.19.04-3.41-.05-5.91-1.12-2.14-.91-3.58-2.16-4.39-2.96-.83-.53-1.36-.45-1.56-.4-1.11.27-1.91,2.11-1.49,4.61-.52.42-1.23,1.08-1.86,2.05-.59.91-.9,1.77-1.07,2.39.09.54.33,1.5,1.04,2.5.82,1.14,1.84,1.71,2.36,1.96-.57,2-.05,3.8,1.11,4.43.24.13.7.31,1.4.22.93-.99,2.56-2.5,4.99-3.64,2.4-1.12,4.56-1.4,5.86-1.47Z"
      />
      <path
        style={styles.four}
        d="m518.08,209.27c-.22-2.28-.84-5.69-2.66-9.28-.85-1.68-1.79-3.05-2.65-4.13.19-.16.72-.57,1.51-.61,1-.05,1.72.51,1.89.65.31-.68.88-1.69,1.92-2.54.44-.36.87-.62,1.26-.8.5.29,1.17.76,1.85,1.46.78.82,1.27,1.64,1.57,2.22.09-.13.52-.75,1.37-.88.85-.13,1.51.33,1.64.43-.8,1.13-1.81,2.86-2.51,5.23-1,3.36-.89,6.4-.69,8.22-.35-.23-.92-.54-1.66-.65-1.42-.21-2.48.46-2.82.7Z"
      />
      <path
        style={styles.four}
        d="m522.65,211.01c.32,3.46,1.1,8.8,3.19,14.64,1,2.81,2.09,5.12,3.08,6.95-.18.3-.7,1.06-1.55,1.19-1.06.17-1.87-.78-2.06-1.02-.29,1.24-.85,3.12-1.94,4.77-.47.71-.94,1.23-1.36,1.62-.57-.51-1.34-1.33-2.12-2.58-.9-1.45-1.46-2.93-1.79-3.97-.09.24-.57,1.41-1.53,1.72-.97.3-1.72-.5-1.86-.67.92-2.12,2.05-5.27,2.8-9.4,1.02-5.59.87-10.4.64-13.19.36.35.94.82,1.7.99,1.44.32,2.48-.7,2.81-1.05Z"
      />
      <path
        style={styles.four}
        d="m517.67,212.67c-2.13.06-5.38.44-8.94,2.06-1.7.78-3.11,1.67-4.24,2.51-.16-.21-.58-.79-.6-1.63-.03-1.05.56-1.77.71-1.94-.7-.37-1.73-1.05-2.6-2.21-.37-.49-.63-.97-.81-1.39.32-.5.82-1.16,1.57-1.8.86-.74,1.72-1.17,2.32-1.41-.13-.1-.76-.61-.87-1.5-.12-.89.37-1.52.47-1.64,1.13.94,2.85,2.15,5.18,3.09,3.24,1.31,6.11,1.47,7.79,1.44-.21.32-.5.86-.61,1.58-.21,1.39.41,2.49.63,2.84Z"
      />
      <path
        style={styles.four}
        d="m523.42,212.58c1.93,0,4.87.27,8.12,1.73,1.54.69,2.82,1.49,3.85,2.25.12-.2.41-.77.35-1.57-.07-1-.64-1.66-.78-1.82.55-.37,1.35-1.05,1.96-2.16.26-.47.43-.93.54-1.33-.31-.47-.79-1.08-1.48-1.68-.79-.69-1.56-1.09-2.09-1.31.1-.1.58-.59.59-1.44.01-.85-.46-1.45-.56-1.56-.87.9-2.22,2.08-4.14,3.01-2.72,1.32-5.27,1.52-6.8,1.51.23.32.55.84.72,1.55.33,1.37-.12,2.47-.28,2.83Z"
      />
      <path
        style={styles.five}
        d="m519.12,212.47c.04,1.81-.08,4.31-.72,7.16-.33,1.45-.73,2.72-1.14,3.79,0,.5.11.82.19.97.36.75,1.21,1.06,2.1.67.05.11.66,1.43,1.75,1.53.9.08,1.74-.72,2.05-1.98,1.04.16,1.84-.29,2-1.04.03-.15.05-.37-.01-.69-.34-.68-.68-1.42-.99-2.23-1.25-3.17-1.73-6.09-1.91-8.28.54-.02,1.46.05,2.49.62.65.36,1.16.81,1.52,1.19.1.1.46.43.77.32.43-.16.7-1.16.39-2.43.19-.16.52-.5.76-1.1.22-.56.26-1.08.27-1.38-.08-.27-.22-.66-.47-1.07-.31-.51-.66-.81-.87-.97.13-1.19-.19-2.17-.66-2.41-.09-.05-.28-.11-.55,0-.28.49-.86,1.35-1.85,1.96-.88.54-1.7.65-2.19.66-.05-.7-.07-1.9.29-3.22.26-.95.63-1.63.92-2.07.19-.4.13-.67.11-.75-.14-.5-.84-.85-1.7-.64-.32-.46-.63-.75-.85-.93-.44-.36-.65-.38-.74-.38-.18,0-.34.06-.56.22-.25.18-.67.55-1.07,1.28-.69-.27-1.3,0-1.47.51-.09.28-.03.59.03.79.36.46.89,1.25,1.3,2.4.41,1.15.54,2.18.58,2.85-.48.03-1.38,0-2.39-.56-.86-.47-1.44-1.13-1.77-1.55-.33-.27-.54-.23-.62-.2-.44.16-.74,1.14-.56,2.47-.2.22-.48.58-.72,1.1-.23.49-.34.94-.4,1.27.04.28.14.8.42,1.32.33.6.73.91.94,1.04-.21,1.06,0,2.03.47,2.36.1.07.28.17.56.12.36-.53,1-1.33,1.97-1.94.96-.61,1.83-.76,2.36-.8Z"
      />
      <path
        style={styles.five}
        d="m519.6,208.47c-.1-1.11-.36-2.76-1.12-4.49-.35-.81-.74-1.46-1.08-1.98.07-.08.29-.29.61-.32.4-.04.7.22.77.29.12-.34.35-.84.77-1.27.18-.18.35-.32.51-.41.21.14.48.36.76.69.33.39.53.79.65,1.07.04-.07.21-.38.56-.45.35-.08.63.14.68.19-.33.57-.74,1.43-1.02,2.6-.4,1.65-.34,3.14-.26,4.02-.14-.11-.38-.26-.68-.31-.58-.1-1.01.24-1.15.35Z"
      />
      <path
        style={styles.five}
        d="m521.62,211.85c.15,1.76.5,4.47,1.39,7.44.43,1.43.89,2.61,1.32,3.55-.08.15-.29.53-.64.59-.44.07-.78-.41-.86-.54-.12.63-.34,1.57-.79,2.39-.19.35-.38.61-.55.8-.24-.26-.56-.68-.88-1.33-.38-.74-.61-1.49-.75-2.01-.04.12-.23.7-.62.85-.39.14-.7-.27-.76-.35.36-1.05.81-2.62,1.1-4.69.39-2.82.31-5.24.2-6.65.15.18.39.41.7.5.6.16,1.02-.36,1.16-.54Z"
      />
      <path
        style={styles.five}
        d="m519.51,211.4c-.87.04-2.18.25-3.59,1.14-.67.42-1.23.91-1.67,1.36-.07-.11-.23-.43-.25-.89-.02-.57.21-.96.27-1.05-.28-.2-.69-.57-1.04-1.19-.15-.27-.25-.52-.33-.75.12-.27.32-.63.61-.98.33-.4.67-.64.9-.78-.05-.05-.3-.33-.36-.81-.05-.48.14-.82.18-.89.45.5,1.14,1.15,2.08,1.65,1.31.7,2.47.77,3.16.75-.09.18-.2.47-.24.87-.07.77.19,1.37.28,1.56Z"
      />
      <path
        style={styles.five}
        d="m521.88,211.34c.86,0,2.18.13,3.67.94.7.38,1.3.83,1.78,1.26.05-.11.18-.44.15-.89-.04-.56-.3-.94-.37-1.03.25-.21.62-.6.89-1.23.12-.27.19-.53.24-.75-.15-.26-.37-.61-.69-.94-.37-.38-.72-.6-.97-.72.05-.06.26-.34.26-.81,0-.48-.22-.81-.27-.87-.39.52-1,1.19-1.87,1.73-1.22.76-2.36.89-3.04.89.1.17.25.46.33.86.15.76-.04,1.37-.11,1.57Z"
      />
      <path
        style={styles.one}
        d="m523.08,210.1c.08,1.38-.92,2.52-2.25,2.54s-2.47-1.09-2.54-2.48.95-2.5,2.27-2.51,2.44,1.08,2.52,2.45Z"
      />
      <path
        style={styles.six}
        d="m518.13,201.21s.52.9.77,1.77c.25.87-.46-1.68-.46-1.68-.05-.02-.1-.04-.16-.05-.05-.01-.1-.02-.14-.03Z"
      />
      <path
        style={styles.six}
        d="m521.65,201.11c-.03.22-.05.44-.07.67-.04.41-.07.82-.09,1.21v-2.08s.06.04.08.07c.04.05.06.1.07.13Z"
      />
      <path
        style={styles.six}
        d="m527.28,207.56c-.3.16-.67.34-1.13.47-.73.21-1.39.24-1.86.23.46.05,1.13.07,1.89-.1.52-.12.95-.29,1.27-.45-.06-.05-.11-.09-.17-.14Z"
      />
      <path
        style={styles.six}
        d="m527.59,212.08c-.16,0-.32,0-.48,0-.9-.05-1.69-.26-2.32-.52.61.25,1.33.49,2.16.64.24.04.47.07.69.09-.02-.07-.03-.14-.05-.21Z"
      />
      <path
        style={styles.six}
        d="m513.53,207.99c.24.14.53.28.87.4.79.28,1.5.31,1.97.28-.29.04-.65.07-1.06.03-.84-.08-1.49-.38-1.87-.6l.1-.11Z"
      />
      <path
        style={styles.six}
        d="m513.77,212.73c.08,0,.16,0,.25-.01.66-.06,1.19-.31,1.58-.55-.21.15-.47.32-.78.47-.4.2-.78.34-1.1.43.02-.11.04-.22.05-.33Z"
      />
      <path
        style={styles.six}
        d="m519.55,225.06c.09-.27.17-.59.22-.96.07-.55.05-1.03,0-1.39.11.38.22.96.14,1.67-.04.38-.13.7-.22.95-.03-.04-.06-.09-.08-.14-.02-.04-.04-.08-.05-.12Z"
      />
      <path
        style={styles.six}
        d="m523.35,224.61c-.11-.18-.23-.4-.34-.65-.21-.53-.28-1-.29-1.34-.01.34.01.82.18,1.37.11.36.25.66.37.89.02-.03.05-.08.06-.15.01-.05.01-.09.01-.12Z"
      />
    </g>
  );
};

export default ChromeBeanie;
