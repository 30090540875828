const Letterman = ({ color }) => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#fff",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      fill: color,
    },
    four: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="M361,806c-.67-28-1.33-56-2-84-.71-7.57-3.66-28.25-20-47-12.63-14.5-27.18-20.87-34.5-23.5-3.52,10.58-6.83,22.45-9.5,35.5-4.59,22.47-6.12,42.81-6.22,59.93,.52,.74,1.27,1.81,2.22,3.07,3.65,4.89,9.6,12.06,28,27,9.6,7.8,17.78,14.4,30,22,4.78,2.98,8.92,5.32,12,7Z"
      />
      <path
        style={styles.one}
        d="M673.05,774.99c-.93-15.31-2.53-31.7-5.05-48.99-3.88-26.6-9.28-50.71-15.21-72.04-6.04,5.04-13.27,12.22-19.79,22.04-13.5,20.33-16.42,40.65-17.13,51.49-1.13,29-2.27,58.01-3.4,87.01,11.34-5.27,24.16-12.24,37.53-21.5,8.72-6.04,16.38-12.15,23.05-18.01Z"
      />
      <path
        style={styles.three}
        d="M478.24,840.49c17.85,1.18,41.97,.99,69.76-4.49,26.88-5.31,48.66-13.88,64.47-21.5,1.16-29.72,2.32-59.45,3.48-89.17,1-10.71,4.22-29.18,16.05-48.33,6.83-11.06,14.53-19.11,20.79-24.63-9.99-9.23-21.86-18.96-35.79-28.37-28.95-19.56-56.88-31.05-79-38-17.78,8.05-35.57,16.11-53.35,24.16l-54.52-29.23c-20.27,7.31-43.93,17.56-69.13,32.07-22.54,12.98-41.44,26.58-56.85,39.09,7.38,2.98,17.26,8.08,26.85,16.91,21.06,19.4,26.44,43.7,28,53,.37,27.8,.74,55.6,1.1,83.4,1.62,1.15,3.98,2.77,6.9,4.6,10.28,6.45,23.89,13.19,50,20,15.13,3.94,34.57,8.72,61.24,10.49Z"
      />
      <path
        style={styles.two}
        d="M458.83,530.89c-.46,8.94-.07,21.4,3.67,35.61,5.46,20.71,15.48,35.39,22.15,43.66-5.04,.58-16.54,1.21-29.15-4.66-15.87-7.4-23.18-20.31-25.37-24.57,2.31-1.42,7.27-4.92,10.37-11.43,1.77-3.73,2.33-7.16,2.5-9.5,.73-4.6,2.52-11.94,7.5-19.5,2.76-4.2,5.78-7.35,8.33-9.61Z"
      />
      <path
        style={styles.two}
        d="M516.73,540.55c-1.3,9.1-3.96,21.17-9.73,34.45-6.9,15.88-15.63,27.54-22.35,35.16,8.27,.2,29.06-.5,45.35-15.16,3.24-2.92,5.87-5.99,8-9-1.11-1.38-2.58-3.4-4-6,0,0-2.54-3.19-3-7-1-8.33-2-16.67-3-25-.37-3.95-2.79-7.13-6-8-2.37-.64-4.45,.17-5.27,.55Z"
      />
      <ellipse style={styles.two} cx="497" cy="637" rx="7" ry="8" />
      <ellipse style={styles.two} cx="497" cy="669" rx="7" ry="8" />
      <ellipse style={styles.two} cx="497" cy="701" rx="7" ry="8" />
      <ellipse style={styles.two} cx="497" cy="733" rx="7" ry="8" />
      <ellipse style={styles.two} cx="497" cy="762" rx="7" ry="8" />
      <ellipse style={styles.two} cx="498" cy="791" rx="7" ry="8" />
      <path d="M304.9,650.58c7.15,3.16,13.93,7.18,20.07,12.03,3.09,2.39,5.95,5.07,8.7,7.84,1.39,1.37,2.64,2.88,3.97,4.31,1.25,1.5,2.47,3.02,3.66,4.56,4.65,6.26,8.58,13.06,11.59,20.25,3.04,7.17,5.22,14.69,6.58,22.35v.04s0,.04,0,.04l.63,83.4-1.58-83.39v.08c-1.43-7.57-3.67-14.99-6.76-22.04-3.05-7.07-7-13.72-11.65-19.83-1.19-1.51-2.41-2.99-3.65-4.45-1.32-1.39-2.56-2.86-3.94-4.19-2.73-2.69-5.56-5.29-8.62-7.6-6.07-4.68-12.76-8.55-19.8-11.56l.8-1.83Z" />
      <path d="M653.48,653.09c-20.89,19.55-34.33,46.76-37.37,75.21,0,0-3.64,86.2-3.64,86.2l2.64-86.28c1.42-14.4,5.34-28.52,11.64-41.56,6.26-13.05,14.92-24.95,25.34-35.02,0,0,1.38,1.45,1.38,1.45h0Z" />
      <polyline
        style={styles.four}
        points="484.65 610.16 480.87 615.22 478.24 840.49"
      />
      <path d="M552.18,835l-21.8-43.58,1.41,.43-13.7,8s.41-1.27,.41-1.27l16.39,39.41-18.21-38.6-.38-.81,.79-.46,13.7-8,.96-.56,.45,.99s20.06,44.44,19.99,44.43h0Z" />
      <path
        style={styles.one}
        d="M552.19,835.14l-21.69-43.64-12.33,7.5,16.71,39.25c2.61-.35,5.32-.76,8.11-1.25,3.2-.57,6.27-1.19,9.19-1.86Z"
      />
      <path d="M415.01,829.14l19.1-39.59,.48-.98,.95,.59,16,10s.76,.48,.76,.48c0,0-.38,.79-.38,.79l-16.54,34.06c-.35,.08,14.81-34.86,14.72-34.89,0,0,.38,1.26,.38,1.26l-16-10s1.42-.39,1.42-.39c-.05-.03-20.78,38.87-20.88,38.68h0Z" />
      <path
        style={styles.one}
        d="M435.33,834.44l15.67-34.44-16-10-19.97,39.48c2.83,.88,5.82,1.73,8.97,2.52,3.95,.99,7.74,1.8,11.33,2.44Z"
      />
    </g>
  );
};

export default Letterman;
