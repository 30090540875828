const Kunai = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#8f8c8a",
    },
    three: {
      fill: "#4d494d",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    six: {
      fill: "#4a4a4d",
    },
    seven: {
      fill: "#3e3a3f",
    },
    eight: {
      fill: "#959195",
    },
    nine: {
      fill: "#2c2c2c",
    },
    ten: {
      fill: "#2c2c2c",
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="M501.75,461.12c1.01-.72,2.59-1.66,4.69-2.28,2.45-.73,4.56-.71,5.89-.6,1.04,.45,2.18,.91,3.4,1.39,7.27,2.8,17.76,5.79,30.6,6.18,12.39,.38,22.56-1.79,29.45-3.84-1.2,3.16-2.41,6.33-3.61,9.49-7.05,2.4-18.62,5.36-33.05,4.69-16.01-.74-28.27-5.62-35.21-9.01-.72-2-1.44-4.01-2.16-6.01Z"
      />
      <path
        style={styles.four}
        d="M529,464.64v1.36c.24-.42,.48-.84,.72-1.25-.24-.04-.48-.07-.72-.11Z"
      />
      <path
        style={styles.four}
        d="M541.33,465.64l.24,1.43c.16-.48,.33-.96,.49-1.44-.24,0-.49,0-.73,0Z"
      />
      <path
        style={styles.four}
        d="M555.54,466.24l.32,1.95c.38-.68,.77-1.36,1.15-2.04-.49,.03-.98,.06-1.48,.09Z"
      />
      <path
        style={styles.four}
        d="M567.05,464.34l.36,.89c.11-.36,.22-.73,.34-1.09-.23,.07-.46,.13-.7,.2Z"
      />
      <path
        style={styles.four}
        d="M517.01,460.52v1.48c.16-.46,.32-.91,.48-1.37-.16-.04-.32-.08-.48-.12Z"
      />
      <polygon points="514.03 471.46 515.73 468.05 515.99 471.85 514.03 471.46 514.03 471.46" />
      <polygon points="527.26 474.8 528.78 471.65 529.23 475.12 527.26 474.8 527.26 474.8" />
      <polygon points="540.63 476.07 541.63 474 542.63 476.07 540.63 476.07 540.63 476.07" />
      <polygon points="554.63 475.07 555.63 473 556.63 475.07 554.63 475.07 554.63 475.07" />
      <polygon points="567.69 472.77 568.2 470.37 569.64 472.35 567.69 472.77 567.69 472.77" />
      <path
        style={styles.three}
        d="M454.52,459.31c-16.86,0-30.52,7.16-30.52,15.98s13.67,15.98,30.52,15.98,30.52-7.16,30.52-15.98-13.67-15.98-30.52-15.98Zm.96,16.82c-6.24,0-11.3-.65-11.3-1.44s5.06-1.44,11.3-1.44,11.3,.65,11.3,1.44-5.06,1.44-11.3,1.44Z"
      />
      <path
        style={styles.three}
        d="M480.85,467.2c.87-1.89,2.82-2.83,4.44-2.36,1.64,.47,2.27,2.17,2.33,2.36v15.89c-.13,.39-.51,1.36-1.37,1.69-1.24,.49-3.18-.46-4.14-2.64,.33-.34,3.69-3.97,2.7-8.89-.72-3.6-3.31-5.58-3.96-6.05Z"
      />
      <path
        style={styles.two}
        d="M487.62,467.2l16.29-.08c6.63,3.2,16.87,7.16,29.92,8.53,16.94,1.79,30.6-1.63,38.33-4.21,.64-1.8,1.4-3.6,2.04-5.41h6.27c.26,2.06,.45,4.4,.46,6.97,.01,2.76-.18,5.26-.46,7.45-30.95,.56-61.91,1.12-92.86,1.68,.05-2.6,.07-5.24,.07-7.93,0-2.37-.03-4.71-.07-7.01Z"
      />
      <path
        style={styles.one}
        d="M498.43,481.95c6.74,3.04,19.72,7.81,36.6,7.41,16.33-.4,28.71-5.46,35.29-8.71-23.96,.43-47.93,.87-71.89,1.3Z"
      />
      <path
        style={styles.three}
        d="M580.46,466.04c.88-1.6,1.76-3.2,2.64-4.81,.58,3.68,1,8.05,.96,12.98-.04,4.36-.43,8.27-.96,11.62-.87-1.79-1.75-3.58-2.62-5.37,.31-2.37,.52-5.12,.46-8.17-.04-2.28-.23-4.37-.48-6.25Z"
      />
      <path
        style={styles.five}
        d="M496.03,467.2c.37,1.73,.69,4.05,.55,6.77-.17,3.41-.97,6.16-1.68,8.04"
      />
      <path
        style={styles.five}
        d="M497.44,467.16c.8,.94,2.49,3.2,2.99,6.58,.62,4.19-.99,7.39-1.56,8.41"
      />
      <path
        style={styles.five}
        d="M510.24,469.89c.29,1.52,.53,3.38,.52,5.53,0,2.48-.34,4.61-.72,6.27"
      />
      <path
        style={styles.five}
        d="M517.49,472.39c.3,.88,.75,2.58,.48,4.71-.27,2.19-1.16,3.76-1.68,4.54"
      />
      <path
        style={styles.five}
        d="M526.31,474.61c.07,.76,.07,1.79-.17,2.97-.36,1.8-1.12,3.12-1.66,3.9"
      />
      <path
        style={styles.five}
        d="M535.17,475.79c.24,.53,.59,1.5,.59,2.75,0,1.25-.35,2.22-.59,2.74"
      />
      <path
        style={styles.five}
        d="M542.93,476.15c.14,.64,.26,1.45,.27,2.39,0,1.03-.12,1.92-.27,2.6"
      />
      <path
        style={styles.five}
        d="M554.52,475.45c.11,.57,.21,1.5-.02,2.61-.3,1.43-1,2.42-1.39,2.9"
      />
      <path
        style={styles.five}
        d="M560.97,474.41c.32,.48,.83,1.41,.98,2.69,.2,1.72-.39,3.03-.66,3.55"
      />
      <path
        style={styles.five}
        d="M573.73,467.49c.51,1.42,1.04,3.39,1.2,5.77,.21,3.1-.3,5.65-.78,7.32"
      />
      <path
        style={styles.three}
        d="M583.34,461c1.31,1.38,3.97,4.57,4.81,9.37,1.53,8.74-4.27,15.25-5.05,16.1,.58-4.13,.99-8.89,.96-14.18-.02-4.12-.31-7.91-.72-11.3Z"
      />
      <path
        style={styles.nine}
        d="M586.94,466.28c15.99-6.65,31.98-13.3,47.97-19.95l149.36,25.79-145.76,26.52-51.92-16.46c.76-1.38,2.14-4.34,2.13-8.34-.01-3.51-1.1-6.19-1.79-7.57Z"
      />
      <polyline
        style={styles.five}
        points="634.91 446.34 651.1 461.96 638.52 498.64"
      />
      <path
        style={styles.six}
        d="M588.15,470.37l62.96-8.41,133.17,10.16-149.36-25.79-47.97,19.95c.25,.55,.5,1.19,.72,1.92,.24,.79,.39,1.52,.48,2.16Z"
      />
      <polygon
        style={styles.ten}
        points="580.94 475.45 487.61 475.45 487.62 482.15 580.48 480.46 580.94 475.45"
      />
      <path
        style={styles.seven}
        d="M469.69,469.66c1.23,.69,1.62,2.27,.88,3.46-.13,.2-.27,.41-.42,.61-2.49,3.3-6.52,3.76-7.6,3.84-.07,0-.14,.01-.2,.03-3.19,.53-8.18,.95-14.07-.26-3.32-.68-6.14-1.72-8.4-2.77-1.51-.7-1.91-2.68-.78-3.9,.09-.1,.17-.23,.29-.3,4.81-3.12,8.6-3.73,14.66-3.85,4.2-.08,9.84-.12,15.62,3.12,0,0,.02,0,.02,.01Z"
      />
      <path
        style={styles.seven}
        d="M430.73,485.27c3.69,2.12,9.97,5.07,18.27,6.01,7.33,.83,12.87-.31,17.06-1.2,8.08-1.73,11.79-4.24,13.46-5.53,3.14-2.42,4.26-4.54,4.81-6.01,.72-1.93,.78-3.68,.72-4.81-3.56,3.17-8.57,6.82-15.14,9.61-17.13,7.27-33.15,3.63-39.17,1.92Z"
      />
      <path d="M524.48,441c-4.63,5.1-11.09,7.47-17.3,9.97-2.79,1.26-6.05,1.78-8.44,3.71-2.45,2.16-5.04,4.33-7.79,6.28,2.3-3.58,4.38-7.61,7.64-10.38,2.21-1.55,4.48-2.45,6.82-3.66,6.05-2.86,12.17-5.99,19.06-5.93h0Z" />
      <path d="M573.73,442.93c2.25,.96,3.93,2.7,5.35,4.62,1.39,1.91,2.56,4.01,3.19,6.3-.83-.86-1.57-1.75-2.32-2.63-2.12-2.64-4.68-5.23-6.21-8.29h0Z" />
      <path d="M508.6,453.31c-5.93,1.35-9.27,7.99-5.73,13.2,0,0-1.73,1-1.73,1-3.27-6.11,.67-13.39,7.46-14.19h0Z" />
      <path
        style={styles.eight}
        d="M533.71,481.14l-1.32,5.33-3.6-4.03-4.69,.07,.38,6.44c-3.76-.39-8.12-1.1-12.88-2.35-4.92-1.29-9.19-2.89-12.73-4.45,11.61-.33,23.23-.67,34.84-1Z"
      />
      <path
        style={styles.five}
        d="M501.75,461.12c1.01-.72,2.59-1.66,4.69-2.28,2.45-.73,4.56-.71,5.89-.6,1.04,.45,2.18,.91,3.4,1.39,7.27,2.8,17.76,5.79,30.6,6.18,12.39,.38,22.56-1.79,29.45-3.84-1.2,3.16-2.41,6.33-3.61,9.49-7.05,2.4-18.62,5.36-33.05,4.69-16.01-.74-28.27-5.62-35.21-9.01-.72-2-1.44-4.01-2.16-6.01Z"
      />
      <polygon points="527.26 474.8 528.78 471.65 529.23 475.12 527.26 474.8 527.26 474.8" />
      <polygon points="567.69 472.77 568.2 470.37 569.64 472.35 567.69 472.77 567.69 472.77" />
      <path
        style={styles.five}
        d="M454.52,459.31c-16.86,0-30.52,7.16-30.52,15.98s13.67,15.98,30.52,15.98,30.52-7.16,30.52-15.98-13.67-15.98-30.52-15.98Zm.96,16.82c-6.24,0-11.3-.65-11.3-1.44s5.06-1.44,11.3-1.44,11.3,.65,11.3,1.44-5.06,1.44-11.3,1.44Z"
      />
      <path
        style={styles.five}
        d="M480.85,467.2c.87-1.89,2.82-2.83,4.44-2.36,1.64,.47,2.27,2.17,2.33,2.36v15.89c-.13,.39-.51,1.36-1.37,1.69-1.24,.49-3.18-.46-4.14-2.64,.33-.34,3.69-3.97,2.7-8.89-.72-3.6-3.31-5.58-3.96-6.05Z"
      />
      <path
        style={styles.five}
        d="M487.62,467.2l16.29-.08c6.63,3.2,16.87,7.16,29.92,8.53,16.94,1.79,30.6-1.63,38.33-4.21,.64-1.8,1.4-3.6,2.04-5.41h6.27c.26,2.06,.45,4.4,.46,6.97,.01,2.76-.18,5.26-.46,7.45-30.95,.56-61.91,1.12-92.86,1.68,.05-2.6,.07-5.24,.07-7.93,0-2.37-.03-4.71-.07-7.01Z"
      />
      <path
        style={styles.five}
        d="M498.43,481.95c6.74,3.04,19.72,7.81,36.6,7.41,16.33-.4,28.71-5.46,35.29-8.71-23.96,.43-47.93,.87-71.89,1.3Z"
      />
      <path
        style={styles.five}
        d="M580.46,466.04c.85-1.01,1.7-2.03,2.54-3.04,.66,2.72,1.29,6.58,1.06,11.21-.22,4.5-1.17,8.18-2.06,10.79-.51-1.51-1.02-3.02-1.52-4.54,.31-2.37,.52-5.12,.46-8.17-.04-2.28-.23-4.37-.48-6.25Z"
      />
      <path
        style={styles.five}
        d="M496.03,467.2c.37,1.73,.69,4.05,.55,6.77-.17,3.41-.97,6.16-1.68,8.04"
      />
      <path
        style={styles.five}
        d="M497.44,467.16c.8,.94,2.49,3.2,2.99,6.58,.62,4.19-.99,7.39-1.56,8.41"
      />
      <path
        style={styles.five}
        d="M510.24,469.89c.29,1.52,.53,3.38,.52,5.53,0,2.48-.34,4.61-.72,6.27"
      />
      <path
        style={styles.five}
        d="M517.49,472.39c.3,.88,.75,2.58,.48,4.71-.27,2.19-1.16,3.76-1.68,4.54"
      />
      <path
        style={styles.five}
        d="M526.31,474.61c.07,.76,.07,1.79-.17,2.97-.36,1.8-1.12,3.12-1.66,3.9"
      />
      <path
        style={styles.five}
        d="M535.17,475.79c.24,.53,.59,1.5,.59,2.75,0,1.25-.35,2.22-.59,2.74"
      />
      <path
        style={styles.five}
        d="M542.93,476.15c.14,.64,.26,1.45,.27,2.39,0,1.03-.12,1.92-.27,2.6"
      />
      <path
        style={styles.five}
        d="M554.52,475.45c.11,.57,.21,1.5-.02,2.61-.3,1.43-1,2.42-1.39,2.9"
      />
      <path
        style={styles.five}
        d="M560.97,474.41c.32,.48,.83,1.41,.98,2.69,.2,1.72-.39,3.03-.66,3.55"
      />
      <path
        style={styles.five}
        d="M573.73,467.49c.51,1.42,1.04,3.39,1.2,5.77,.21,3.1-.3,5.65-.78,7.32"
      />
      <path
        style={styles.five}
        d="M583.34,461c1.31,1.38,3.97,4.57,4.81,9.37,1.53,8.74-4.27,15.25-5.05,16.1,.58-4.13,.99-8.89,.96-14.18-.02-4.12-.31-7.91-.72-11.3Z"
      />
      <path
        style={styles.five}
        d="M586.94,466.28c15.99-6.65,31.98-13.3,47.97-19.95l149.36,25.79-145.76,26.52-51.92-16.46c.76-1.38,2.14-4.34,2.13-8.34-.01-3.51-1.1-6.19-1.79-7.57Z"
      />
      <polyline
        style={styles.five}
        points="634.91 446.34 651.1 461.96 638.52 498.64"
      />
    </g>
  );
};

export default Kunai;
