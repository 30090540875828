const TacSquad = () => {
  const styles = {
    one: {
      fill: "#5465ff",
    },
    two: {
      fill: "#5465ff",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    three: {
      strokeWidth: 2,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#f2f2ed",
    },
  };

  return (
    <g>
      <path
        style={styles.four}
        d="M388,199c-6.75,11.98-15.15,30.34-19,54-6.06,37.2,2.39,67.36,7.8,82.45-.93,33.18-1.87,66.37-2.8,99.55,2.88,8.22,25.06,68.23,90,92,73.17,26.79,135.16-16.39,140.27-20.09,20.21-17.43,30.99-34.74,36.73-45.91,9.77-19.04,15.02-40.05,19-56,1.35-5.42,2.35-9.94,3-13,2.12-9.7,3.81-22.33,3-37-.42-7.63-1.44-14.52-2.7-20.54,2.33-15.11,4.04-38.21-1.3-65.46-6.38-32.58-19.96-56.4-29.17-70-16.94,10.33-33.88,20.67-50.83,31-15.24-5.14-38.4-10.99-67-11-30.19,0-54.45,6.51-70,12-19-10.67-38-21.33-57-32Z"
      />
      <path
        style={styles.one}
        d="M424,340c2.12,5.83,5.37,13.15,10.44,20.85,4.26,6.47,8.69,11.54,12.53,15.36,2.63,1.97,8.03,5.46,15.67,6.58,6.41,.94,11.64-.2,14.62-1.1,3.92-.81,8.89-1.42,14.62-1.1,3.09,.17,5.89,.58,8.36,1.1,3.92,1.77,7.84,3.53,11.76,5.3-4.27-4.42-7.53-8.2-9.67-10.79-5.51-6.64-8.01-10.58-13.58-14.27-2.53-1.68-4.8-2.7-6.27-3.29-4.54-1.61-9.78-3.16-15.67-4.39-5.72-1.19-10.99-1.85-15.67-2.19-1.68-.27-3.43-.63-5.22-1.1-9.28-2.41-16.6-6.83-21.93-10.97Z"
      />
      <path
        style={styles.three}
        d="M427,345c2.03,5.31,5.14,11.98,10,19,4.08,5.9,8.32,10.51,12,14,2.52,1.8,7.69,4.98,15,6,6.14,.86,11.15-.18,14-1,3.75-.74,8.51-1.29,14-1,2.96,.16,5.64,.53,8,1,3.75,1.61,7.51,3.22,11.26,4.83-4.09-4.03-7.21-7.48-9.26-9.83-5.28-6.05-7.67-9.64-13-13-2.43-1.53-4.6-2.46-6-3-4.35-1.47-9.37-2.88-15-4-5.48-1.09-10.52-1.69-15-2-1.61-.25-3.28-.57-5-1-8.89-2.2-15.89-6.22-21-10Z"
      />
      <path
        style={styles.one}
        d="M650,343c-1.16,5.1-3.2,11.63-6.9,18.68-3.18,6.06-6.75,10.9-9.94,14.6-2.28,1.93-7.05,5.38-14.21,6.49-6.03,.94-11.09-.2-13.97-1.09-3.76-.8-8.5-1.4-13.92-1.09-2.92,.17-5.57,.58-7.9,1.09-3.7,1.76-7.42,3.53-11.17,5.32,4.06-4.44,7.11-8.19,9.1-10.72,5.02-6.4,7.24-10.14,12.26-13.59,2.26-1.56,4.3-2.5,5.61-3.04,4.06-1.48,8.75-2.9,14.02-4.01,5.1-1.08,9.82-1.68,14.01-1.98,1.49-.24,3.04-.57,4.61-.99,8.11-2.16,14.16-6.06,18.4-9.67Z"
      />
      <path
        style={styles.three}
        d="M647.96,348c-1.16,4.96-3.2,11.32-6.89,18.18-3.18,5.9-6.74,10.61-9.94,14.22-2.27,1.88-7.05,5.24-14.2,6.32-6.03,.91-11.08-.19-13.96-1.06-3.76-.78-8.5-1.37-13.91-1.06-2.92,.17-5.56,.56-7.89,1.06-3.7,1.71-7.42,3.44-11.16,5.17,4.06-4.33,7.11-7.97,9.09-10.43,5.02-6.23,7.24-9.87,12.25-13.23,2.26-1.51,4.29-2.44,5.61-2.96,4.06-1.44,8.75-2.82,14.01-3.9,5.09-1.05,9.81-1.63,14-1.93,1.49-.24,3.03-.55,4.61-.96,8.1-2.1,14.15-5.9,18.39-9.42Z"
      />
      <path
        style={styles.three}
        d="M513,321c4.94-1.59,10.23-3.29,14-2,4.25,1.46,7.46,7.03,7,12-.68,7.37-9.19,11.94-15,12-.66,0-13.28-.1-20-11-4.38-7.11-4.57-16.09-.5-23.43-1.01,5.29,1.35,10.37,6.41,12.79,3.38,1.62,6.82,.05,8.09-.35Z"
      />
      <path
        style={styles.three}
        d="M575.62,319c-4.94-1.59-10.23-3.29-14-2-4.25,1.46-7.46,7.03-7,12,.68,7.37,9.19,11.94,15,12,.66,0,13.28-.1,20-11,4.38-7.11,4.57-16.09,.5-23.43,1.01,5.29-1.35,10.37-6.41,12.79-3.38,1.62-6.82,.05-8.09-.35Z"
      />
      <path
        style={styles.three}
        d="M516,482h55c-2.33-.06-8.98,.48-15-4-3.3-2.45-5.41-5.29-6.72-7.51-.67-1.14-2.23-1.34-3.13-.37-2.32,2.48-5.64,5.42-10.15,7.89-8.29,4.54-16.1,4.03-20,4Z"
      />
      <g>
        <g>
          <path d="M528,429l7,5-7-5Z" />
          <path d="M528.81,427.86l6.29,6-.2,.28-7.71-4,1.62-2.27h0Zm0,0l6.29,6,.9,.85-1.1-.57-7.71-4,1.62-2.27h0Z" />
        </g>
        <g>
          <path d="M567,429l-7,5,7-5Z" />
          <path d="M567.81,430.14l-7.71,4-.2-.28,6.29-6,1.62,2.27h0Zm0,0l-7.71,4-1.1,.57,.9-.85,6.29-6,1.62,2.27h0Z" />
        </g>
      </g>
      <path
        style={styles.two}
        d="M439,236c-7.54,3.89-22.79,13.02-35,31-12.54,18.47-15.26,36.53-16,45-3.84-10.45-8.93-28.38-8-51,.97-23.69,8.1-41.82,13-52,15.33,9,30.67,18,46,27Z"
      />
      <path
        style={styles.two}
        d="M585.97,237.47c7.48,2.07,23.42,7.59,37.78,22.16,16.36,16.6,21.89,35.67,23.95,45.23,3.53-12.23,7.43-32.08,3.23-54.34-4-21.2-13.7-34.98-19.73-42.06-15.14,9.71-30.21,19.38-45.23,29.02Z"
      />
      <path
        style={styles.two}
        d="M376.13,387.83c-.38,15.06-.75,30.11-1.13,45.17,4.41,4.58,21.31,21.03,48.5,23.5,33.6,3.05,56.43-17.65,60-21-3.71,1.65-38.36,16.38-72-3-25.11-14.47-33.46-38.6-35.37-44.67Z"
      />
      <path
        style={styles.two}
        d="M396,474c6.51,1.57,19.39,3.7,34.5-.5,9.33-2.59,16.38-6.73,21-10-2.91,3.79-6.53,7.91-11,12-9.85,9.02-19.92,14.34-27.5,17.5-3.15-2.74-6.56-6.05-10-10-2.72-3.12-5.03-6.16-7-9Z"
      />
      <path
        style={styles.two}
        d="M480,223c4.13,2.59,8.31,5.42,12.5,8.5,25.34,18.63,42.89,40.53,55,60,2.9-2.26,15.01-12.21,18-30,3.41-20.25-7.62-35.06-9.5-37.5-10.25-2.05-22.74-3.75-37-4-15.08-.27-28.28,1.15-39,3Z"
      />
      <path
        style={styles.two}
        d="M650,438c-2.66,1.29-6.23,2.58-10.57,3-14.08,1.36-24.47-7.62-26.56-9.5,2.32,3.88,7.47,11.2,17.05,16.5,5.18,2.87,10.09,4.27,13.74,5,1.4-2.09,2.93-4.76,4.23-8,1.03-2.57,1.68-4.95,2.11-7Z"
      />
      <path
        style={styles.two}
        d="M631,476c-2.24-1.78-5.34-4.68-8-9-3.7-6.02-4.69-11.78-5-15,.4,1.94,1.99,8.39,8,13,3.45,2.64,7.95,2.61,10,3-1.33,2.33-3.67,5.67-5,8Z"
      />
    </g>
  );
};

export default TacSquad;
