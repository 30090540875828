import { Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { giveSnipes, sauceBones } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { numFormatter } from "../../utils/helpers";
import {
  cardLight,
  normal,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import CurrencyInput from "../custom/CurrencyInput";
import CustomModal from "../custom/CustomModal";
import RivoxCoin from "../custom/RivoxCoin";
import PrimaryButton from "../custom/PrimaryButton";
import SecondaryButton from "../custom/SecondaryButton";
import CustomInput from "../custom/CustomInput";

const AddSnipesModal = ({ open, onClose, user, snipes, setSnipes }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setAmount("");
    setLoading(false);
    onClose();
  };

  const handleAddSnipes = () => {
    setLoading(true);
    giveSnipes(middleware, user?._id, amount).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        setAmount("");
        setSnipes(parseInt(amount) + parseInt(user?.premium_info?.snipes));
      }
    });
  };

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Give ${user?.username} Snipes`}
      primaryButton={
        <PrimaryButton
          label="Add Snipes"
          disabled={amount === "" || amount == null || amount <= 0}
          loading={loading}
          onClick={handleAddSnipes}
        />
      }
      secondaryButton={<SecondaryButton label="Close" onClick={handleClose} />}
      backgroundColor={cardLight}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: normal,
                  color: secondaryText,
                  fontWeight: 600,
                }}
              >
                {user?.username}'s Snipes
              </Typography>
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 0.5 }}
              >
                <RivoxCoin size={32} />

                <Grid item>
                  <Typography
                    sx={{ fontSize: 32, color: text, fontWeight: 700 }}
                  >
                    {snipes}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <CustomInput
            value={amount}
            onChange={(value) => setAmount(parseInt(value) || 0)}
            autoFocus={true}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default AddSnipesModal;
