const PointyPierced = () => {
  const styles = {
    one: {
      fill: "#f9d438",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      fill: "#f9e698",
    },
  };

  return (
    <g>
      <g>
        <path d="M535.45,353.27c1.04,2.34,2.11,4.67,3.12,7.03,4.24,9.86,8.51,19.69,12.66,29.59,2.35,5.6,4.57,11.25,6.68,16.94,1.15,3.11,2.09,6.32,2.86,9.55,.89,3.69-.31,5.73-3.96,6.79-2.22,.64-4.54,.99-6.84,1.24-6.72,.74-13.43,.27-20.13-.47-.86-.09-1.82-.07-2.63,.21-3.46,1.22-6.88,1.01-10.26-.19-2.65-.95-4.42-2.82-5.39-5.5-1.37-3.75-1.34-7.36,1.06-10.67,2.65-3.66,6.09-6.12,10.79-6.35,1.24-.06,2.5-.02,3.74,.11,.87,.09,1.8,.29,2.05,1.43-1.8,.17-3.53,.15-5.17,.52-4.15,.92-7.32,3.16-8.72,7.35-.92,2.74-.34,5.43,.72,8,.29,.7,1.03,1.31,1.7,1.75,2.54,1.7,8.11,2.19,10.89-.82,2.34-2.53,5.29-3.64,8.77-3.36,1.6,.13,2.81,.78,3.71,2.24-1.13,1.35-2.51,.79-3.74,.67-1.77-.17-3.39,.03-4.81,1.57,3.33,.19,6.67,.59,10,.52,4.06-.09,8.1-.56,12.15-.88,.49-.04,.98-.19,1.45-.35,2.91-1.05,3.04-1.72,1.86-4.58-3.21-7.76-6.32-15.56-9.37-23.39-2.08-5.35-4.02-10.75-5.94-16.16-2.53-7.13-4.98-14.29-7.46-21.44-.13-.37-.19-.77-.28-1.15,.16-.06,.32-.12,.48-.18Z" />
      </g>
      <g>
        <g>
          <circle style={styles.one} cx="526" cy="411" r="4.5" />
          <path
            style={styles.two}
            d="M524.36,407.73c.12-.09,1.4-.94,2.86-.41,1.17,.42,1.98,1.56,2.05,2.86-.44-.53-1.37-1.49-2.86-2.05-.79-.29-1.51-.39-2.05-.41Z"
          />
        </g>
      </g>
    </g>
  );
};

export default PointyPierced;
