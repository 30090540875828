import Background from "./pieces/background/Background";
import Clothes from "./pieces/clothes/Clothes";
import Eyebrows from "./pieces/eyebrows/Eyebrows";
import Eyes from "./pieces/eyes/Eyes";
import FacialHair from "./pieces/facial_hair/FacialHair";
import Hair from "./pieces/hair/Hair";
import Hat from "./pieces/hat/Hat";
import Mouth from "./pieces/mouth/Mouth";
import Nose from "./pieces/nose/Nose";
import Tattoo from "./pieces/tattoos/Tattoo";
import Earrings from "./pieces/earrings/Earrings";
import Necklace from "./pieces/necklace/Necklace";
import Glasses from "./pieces/glasses/Glasses";
import BackBling from "./pieces/backbling/BackBling";
import Base from "./pieces/base/Base";
import Emote from "./pieces/emote/Emote";

const Avatar = ({ size, avatar, bgColor, sx }) => {
  const options = avatar?.options;

  // is Emoji
  const isEmoji = (clothesValue) => {
    switch (clothesValue) {
      case "drool_emoji":
      case "nerd_emoji":
      case "alien_emoji":
      case "laugh_emoji":
      case "hearts_emoji":
      case "angry_emoji":
      case "crying_emoji":
      case "demon_emoji":
      case "eyes_emoji":
      case "heart_eyes_emoji":
      case "singer_emoji":
      case "skull_emoji":
      case "crossbones_emoji":
      case "monkey_emoji":
      case "interesting_emoji":
      case "disguise_emoji":
      case "sunglasses_emoji":
      case "cowboy_emoji":
      case "blown_emoji":
      case "zipper_emoji":
      case "half-skull_emoji":
      case "swirl_emoji":
      case "confused_emoji":
        return true;
      default:
        return false;
    }
  };

  // should show base
  const shouldShowBase = (clothesValue) => {
    switch (clothesValue) {
      case "travis_scott":
      case "lil_uzi":
      case "alien":
      case "juice_wrld":
      case "drizzy_drake":
      case "ape_shit":
      case "rex":
      case "yeat_cat_hoodie":
      case "doge":
        return false;
      default:
        return true;
    }
  };

  // should show nose
  const shouldShowNose = (hatValue, clothesValue) => {
    if (hatValue === "the_chef" || hatValue === "joker") return false;

    switch (clothesValue) {
      case "travis_scott":
      case "lil_uzi":
      case "juice_wrld":
      case "drizzy_drake":
      case "ape_shit":
      case "zombie":
      case "mummy":
      case "rex":
      case "yeat_cat_hoodie":
      case "doge":
        return false;
      default:
        return true;
    }
  };

  // should show eyes
  const shouldShowEyes = (glassesValue, clothesValue) => {
    switch (clothesValue) {
      case "travis_scott":
      case "lil_uzi":
      case "juice_wrld":
      case "drizzy_drake":
      case "ape_shit":
      case "zombie":
      case "mummy":
      case "rex":
      case "yeat_cat_hoodie":
      case "doge":
        return false;
      default:
        if (glassesValue != null && glassesValue !== "anti_mainstream") {
          return false;
        } else {
          return true;
        }
    }
  };

  // should show eyebrows
  const shouldShowEyebrows = (eyesValue, clothesValue) => {
    switch (eyesValue) {
      case "duoma_eyes":
        return false;
      default:
        if (
          clothesValue === "travis_scott" ||
          clothesValue === "alien" ||
          clothesValue === "juice_wrld" ||
          clothesValue === "zombie" ||
          clothesValue === "mummy" ||
          clothesValue === "rex" ||
          clothesValue === "yeat_cat_hoodie" ||
          clothesValue === "doge"
        ) {
          return false;
        }
        return true;
    }
  };

  // should show glasses
  const shouldShowGlasses = (clothesValue) => {
    switch (clothesValue) {
      case "travis_scott":
      case "lil_uzi":
      case "alien":
      case "juice_wrld":
      case "drizzy_drake":
      case "ape_shit":
      case "zombie":
      case "rex":
      case "yeat_cat_hoodie":
      case "doge":
        return false;
      default:
        return true;
    }
  };

  // should show tattoo
  const shouldShowTattooOne = (clothesValue) => {
    switch (clothesValue) {
      case "cardigan":
      case "shoulder_with_jacket":
      case "blouse":
      case "mummy":
      case "rex":
      case "drizzy_drake":
        return false;
      default:
        return true;
    }
  };

  const shouldShowTattooTwo = (clothesValue) => {
    switch (clothesValue) {
      case "cardigan":
      case "shoulder_with_jacket":
      case "blouse":
      case "louis":
      case "gym_rat":
      case "gym_rat_tatted":
        return true;
      default:
        return false;
    }
  };

  // should show hair
  const shouldShowHair = (clothesValue) => {
    switch (clothesValue) {
      case "bathing_ape":
      case "college_hoodie":
      case "brrrrrr":
      case "minion":
      case "canadian_goose":
      case "tate":
      case "hoodie_eyes":
      case "incognito":
      case "cleezy":
      case "pinhead":
      case "scream":
      case "skelly_zip":
      case "y2k_hoodie":
      case "travis_scott":
      case "lil_uzi":
      case "alien":
      case "juice_wrld":
      case "drizzy_drake":
      case "creeper":
      case "ape_shit":
      case "zombie":
      case "mummy":
      case "rex":
      case "yeat_cat_hoodie":
      case "doge":
        return false;
      default:
        if (options?.hat?.value) {
          if (
            options?.hat?.value === "tac_squad" ||
            options?.hat?.value === "doctor" ||
            options?.hat?.value === "naughty" ||
            options?.hat?.value === "nice" ||
            options?.hat?.value === "party_hat" ||
            options?.hat?.value === "terminator" ||
            options?.hat?.value === "gucci_band" ||
            options?.hat?.value === "jason" ||
            options?.hat?.value === "beats" ||
            options?.hat?.value === "anonymous" ||
            options?.hat?.value === "headband" ||
            options?.hat?.value === "obito" ||
            options?.hat?.value === "joker" ||
            options?.hat?.value === "sakonji"
          )
            return true;

          return false;
        }

        return true;
    }
  };

  // should show earrings
  const shouldShowEarrings = (clothesValue) => {
    switch (clothesValue) {
      case "bathing_ape":
      case "college_hoodie":
      case "minion":
      case "tate":
      case "hoodie_eyes":
      case "incognito":
      case "cleezy":
      case "pinhead":
      case "scream":
      case "skelly_zip":
      case "travis_scott":
      case "lil_uzi":
      case "juice_wrld":
      case "drizzy_drake":
      case "creeper":
      case "ape_shit":
      case "rex":
      case "yeat_cat_hoodie":
      case "doge":
        return false;
      default:
        return true;
    }
  };

  // should show hat
  const shouldShowHat = (clothesValue) => {
    switch (clothesValue) {
      case "bathing_ape":
      case "college_hoodie":
      case "minion":
      case "tate":
      case "hoodie_eyes":
      case "incognito":
      case "cleezy":
      case "pinhead":
      case "scream":
      case "skelly_zip":
      case "travis_scott":
      case "lil_uzi":
      case "juice_wrld":
      case "drizzy_drake":
      case "creeper":
      case "ape_shit":
      case "rex":
      case "yeat_cat_hoodie":
      case "doge":
        return false;
      default:
        return true;
    }
  };

  // should show facial hair
  const shouldShowFacialHair = (clothesValue, hatValue) => {
    if (hatValue === "the_chef" || hatValue === "joker") {
      return false;
    }

    switch (clothesValue) {
      case "tate":
      case "dawn":
      case "incognito":
      case "cleezy":
      case "pinhead":
      case "scream":
      case "skelly_zip":
      case "travis_scott":
      case "lil_uzi":
      case "alien":
      case "juice_wrld":
      case "drizzy_drake":
      case "creeper":
      case "ape_shit":
      case "zombie":
      case "mummy":
      case "rex":
      case "yeat_cat_hoodie":
      case "doge":
        return false;
      default:
        return true;
    }
  };

  // should show mouth
  const shouldShowMouth = (clothesValue, hatValue) => {
    if (hatValue === "the_chef" || hatValue === "joker") {
      return false;
    }

    switch (clothesValue) {
      case "tate":
      case "dawn":
      case "incognito":
      case "cleezy":
      case "pinhead":
      case "scream":
      case "skelly_zip":
      case "travis_scott":
      case "lil_uzi":
      case "alien":
      case "juice_wrld":
      case "drizzy_drake":
      case "creeper":
      case "ape_shit":
      case "zombie":
      case "mummy":
      case "rex":
      case "yeat_cat_hoodie":
      case "doge":
        return false;
      default:
        return true;
    }
  };

  // should show necklace
  const shouldShowNecklace = (clothesValue) => {
    switch (clothesValue) {
      case "dawn":
      case "cleezy":
      case "incognito":
      case "pinhead":
      case "scream":
      case "skelly_zip":
      case "travis_scott":
      case "lil_uzi":
      case "juice_wrld":
      case "drizzy_drake":
      case "creeper":
      case "ape_shit":
      case "rex":
      case "yeat_cat_hoodie":
      case "doge":
        return false;
      default:
        return true;
    }
  };

  // should show backbling
  const shouldShowBackbling = (clothesValue) => {
    switch (clothesValue) {
      case "lil_uzi":
      case "juice_wrld":
      case "drizzy_drake":
      case "ape_shit":
      case "rex":
      case "yeat_cat_hoodie":
      case "doge":
        return false;
      default:
        return true;
    }
  };

  return (
    <div style={{ position: 'relative', width: size, height: size, ...sx }}>
    <svg
      viewBox={`125 100 750 750`}
      width={size}
      height={size}
      style={{ overflow: "visible", ...sx }}
    >
      <Background color={bgColor} />

      {!isEmoji(options?.clothes?.value) &&
        shouldShowBase(options?.clothes?.value) && (
          <Base color={options?.skinColor} />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowNose(options?.hat?.value, options?.clothes?.value) && (
          <Nose
            type={
              options?.nose?.current_variant
                ? options?.nose?.current_variant?.value
                : options?.nose?.value
            }
          />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowEyes(options?.glasses?.value, options?.clothes?.value) && (
          <Eyes type={options?.eyes?.value} />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowEyebrows(options?.eyes?.value, options?.clothes?.value) && (
          <Eyebrows type={options?.eyebrows?.value} />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowTattooOne(options?.clothes?.value) && (
          <Tattoo
            type={
              options?.tattoos?.current_variant
                ? options?.tattoos?.current_variant?.value
                : options?.tattoos?.value
            }
          />
        )}

      {options?.clothes?.value !== "bathing_ape" &&
        options?.clothes?.value !== "minion" && (
          <Clothes
            color={options?.clothesColor}
            type={
              options?.clothes?.current_variant
                ? options?.clothes?.current_variant?.value
                : options?.clothes?.value
            }
            skinColor={options?.skinColor}
          />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowGlasses(options?.clothes?.value) && (
          <Glasses type={options?.glasses?.value} />
        )}

      {/* {options?.clothes?.value === "alien" && (
        <Glasses type={options?.glasses?.value} />
      )} */}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowTattooTwo(options?.clothes?.value) && (
          <Tattoo
            type={
              options?.tattoos?.current_variant
                ? options?.tattoos?.current_variant?.value
                : options?.tattoos?.value
            }
          />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowHair(options?.clothes?.value) && (
          <Hair type={options?.hair?.value} color={options?.hairColor} />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowEarrings(options?.clothes?.value) && (
          <Earrings type={options?.earrings?.value} />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowFacialHair(options?.clothes?.value, options?.hat?.value) && (
          <FacialHair
            type={options?.facialHair?.value}
            color={options?.facialHairColor}
          />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowMouth(options?.clothes?.value, options?.hat?.value) && (
          <Mouth
            type={
              options?.mouth?.current_variant
                ? options?.mouth?.current_variant?.value
                : options?.mouth?.value
            }
          />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowNecklace(options?.clothes?.value) && (
          <Necklace
            type={
              options?.necklace?.current_variant
                ? options?.necklace?.current_variant?.value
                : options?.necklace?.value
            }
            skinColor={options?.skinColor}
          />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowHat(options?.clothes?.value) && (
          <Hat
            type={
              options?.hat?.current_variant
                ? options?.hat?.current_variant?.value
                : options?.hat?.value
            }
            color={options?.hatColor}
          />
        )}

      {!isEmoji(options?.clothes?.value) &&
        shouldShowBackbling(options?.clothes?.value) && (
          <BackBling
            type={
              options?.backBling?.current_variant
                ? options?.backBling?.current_variant?.value
                : options?.backBling?.value
            }
            skinColor={options?.skinColor}
          />
        )}

      {(options?.clothes?.value === "bathing_ape" ||
        options?.clothes?.value === "minion") && (
        <Clothes
          color={options?.clothesColor}
          type={options?.clothes?.value}
          skinColor={options?.skinColor}
        />
      )}


    </svg>

    {options?.emote?.value && <Emote type={options.emote.value} />}
</div>
    
  );
};

export default Avatar;
