import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getAvatarVariants } from "../../api/avatar";
import useMiddleware from "../../api/useMiddleware";
import Piece from "../../avatar/Piece";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { normal, secondaryText } from "../../utils/themeContstants";
import LockerGlimmer from "../glimmers/LockerGlimmer";
import PiecePreview from "./PiecePreview";

const VariantSelect = ({ avatar, selected, option, onChange, width = 426 }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(true);
  const [variants, setVariants] = useState(null);

  const getIsSelected = (variantValue) => {
    if (avatar?.options[`${selected}`]?.current_variant) {
      return (
        avatar?.options[`${selected}`]?.current_variant?.value === variantValue
      );
    }

    return avatar?.options[`${selected}`]?.value === variantValue;
  };

  const getIsOwned = (variant) => {
    if (
      store?.user?.new_locker?.options[`${selected}`]?.includes(option?._id)
    ) {
      if (
        store?.user?.new_locker?.options[`${selected}`]?.includes(variant?._id)
      )
        return true;

      if (store?.user?.new_locker?.variants?.includes(variant?._id)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    setVariants(null);
    setLoading(true);
    if (option) {
      getAvatarVariants(middleware, option?.value).then((res) => {
        setLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          setVariants(res?.variants);
        }
      });
    }
  }, [option]);

  const styles = {
    container: {
      width,
      borderRadius: 1,
      boxSizing: "border-box",
    },
    width: {
      width: "100%",
    },
    label: {
      fontSize: normal,
      fontWeight: 700,
      color: secondaryText,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
      >
        <Grid item>
          <Typography sx={styles.label}>VARIANTS</Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 1 }}
          >
            {loading && <LockerGlimmer numItems={3} size={100} />}

            {!loading && variants && (
              <>
                {variants?.map((variant, i) => (
                  <PiecePreview
                    key={i}
                    value={variant?.value}
                    size={100}
                    selected={getIsSelected(variant?.value)}
                    onClick={() => {
                      if (i === 0) {
                        onChange(selected, variant, true);
                      } else {
                        onChange(selected, variant, false);
                      }
                    }}
                    owned={getIsOwned(variant)}
                  >
                    <Piece
                      type={selected}
                      option={variant?.value}
                      skinColor={avatar?.options?.skinColor}
                      color={avatar?.options[`${selected}Color`]}
                    />
                  </PiecePreview>
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VariantSelect;
