import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { BiCheck } from "react-icons/bi";
import {
  black,
  cardDark,
  cardVeryLight,
  doubtedBlue,
  normal,
  purple,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";
import DoubtedCoin from "./DoubtedCoin";

const RadioListItem = ({
  selected,
  onClick,
  title,
  description,
  value,
  backgroundColor,
  backgroundColorHover,
  coin = false,
}) => {
  const [hovered, setHovered] = useState(false);

  const styles = {
    container: {
      padding: 1.5,
      width: "100%",
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
      backgroundColor,
      boxSizing: "border-box",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: backgroundColorHover,
      },
    },
    title: {
      fontSize: normal,
      fontWeight: 600,
      color: text,
    },
    description: {
      fontSize: normal,
      fontWeight: 400,
      color: secondaryText,
    },
    radioContainer: {
      height: 25,
      width: 25,
      borderRadius: 100,
      boxSizing: "border-box",
      border: `2px solid ${hovered || selected ? doubtedBlue : cardVeryLight}`,
      transition: transition,
      backgroundColor: selected ? doubtedBlue : cardDark,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => onClick(value)}
    >
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.radioContainer}>
          {selected && <BiCheck style={{ fontSize: 16, color: text }} />}
        </Grid>

        {!coin && (
          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
            >
              <Grid item>
                <Typography sx={styles.title}>{title}</Typography>
              </Grid>

              {description && (
                <Grid item>
                  <Typography sx={styles.description}>{description}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {coin && (
          <Grid item>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 0.5 }}
            >
              <DoubtedCoin size={30} />

              <Grid item>
                <Typography sx={styles.title}>{title}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default RadioListItem;
