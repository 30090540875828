import { useEffect, useRef } from "react";

const usePagination = (bottom, cb) => {
  const observer = useRef(null);
  const callbackRef = useRef(null);

  useEffect(() => {
    callbackRef.current = cb;
  }, [cb]);

  useEffect(() => {
    const options = {
      root: document,
      rootMargin: "20px",
      threshold: 1,
    };

    const callback = (items) => {
      if (items[0].isIntersecting) {
        callbackRef.current();
      }
    };

    observer.current = new IntersectionObserver(callback, options);
    if (bottom.current) {
      observer.current.observe(bottom.current);
    }
    return () => {
      observer.current.disconnect();
    };
  });
};

export default usePagination;
