const Goatee = ({ color }) => {
  const styles = {
    one: {
      fill: color,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
  };

  return (
    <g transform="translate(483, 440)">
      <path
        style={styles.one}
        d="M62.07,5.44c1.52-2.63,4.1-3.8,7.02-4.3,5.57-.96,11.07-.91,16.6,.61,5.23,1.43,10.56,2.45,15.86,3.63,4.41,.98,8.44,2.7,11.04,6.56,1.32,1.96,2.41,4.19,3.04,6.45,2.6,9.34,4.05,18.9,4.62,28.56,.33,5.48-.65,10.88-2.63,15.97-1.02,2.62-2.8,5-4.5,7.3-3.01,4.08-6.84,7.38-10.93,10.34-6.27,4.55-12.6,9.02-18.96,13.44-2.55,1.78-5.25,3.35-7.9,4.99-5.4,3.35-11.42,4.36-17.64,4.56-.63,.02-1.28-.03-1.91-.13-10.38-1.78-20.33-4.8-29.59-9.97-4.87-2.72-9.9-5.18-14.96-7.55-3.72-1.74-6.32-4.38-7.34-8.32-.95-3.62-1.76-7.29-2.32-10.99C.17,57.32,.39,47.96,.84,38.62c.22-4.45,.87-8.9,1.62-13.3,.49-2.89,2.55-4.99,4.93-6.54,2.19-1.43,4.57-2.63,6.97-3.69,5.77-2.54,11.59-4.99,17.42-7.39,5.03-2.07,10.2-3.78,15.62-4.47,3.55-.45,7.17-.86,10.69,.27,1.33,.43,2.54,1.21,3.99,1.93Zm.22,7.76c-1.25,2.46-3.47,3.74-6.16,4.37-1.71,.4-3.4,.86-5.12,1.23-6.34,1.39-12.8,1.87-19.25,2.37-4.24,.33-8.38,1.05-12.32,2.81-2.11,.94-3.4,2.34-3.77,4.56-.35,2.12-.73,4.26-.83,6.4-.33,6.96,.06,13.91,.96,20.8,.9,6.87,3.53,12.93,9.47,17.07,1.18,.82,2.32,1.71,3.57,2.42,3.32,1.89,6.55,3.94,10.47,4.54,6,.92,10.69-1.12,14.15-5.99,3.15-4.44,4.02-9.5,3.63-14.82-.31-4.2-1.59-8.13-3.56-11.85-1.01-1.9-.64-2.53,1.51-2.83,4.51-.64,9.01-1.28,13.52-1.9,1.66-.23,3.33-.46,5-.56,1.34-.08,1.7,.43,1.4,1.77-.16,.7-.44,1.37-.64,2.06-1.33,4.57-2.02,9.2-1.18,13.96,.63,3.53,1.86,6.78,4.48,9.35,3.3,3.24,8.16,4.22,12.19,2.2,11.68-5.85,18.65-15.56,18.36-29.52-.09-4.39-.44-8.78-.91-13.14-.29-2.68-.87-5.37-1.63-7.96-1.44-4.9-3.37-6.25-8.45-5.74-3.96,.4-7.9,1.11-11.81,1.85-3.61,.69-7.17,1.79-10.88,1.52-3.33-.23-6.74-.41-9.5-2.74-.84-.71-1.69-1.42-2.69-2.26Z"
      />
    </g>
  );
};

export default Goatee;
