import { CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { searchForUser } from "../../api/search";
import Avatar from "../../avatar/Avatar";
import { SET_ERRORS, StoreDispatch } from "../../store/Store";
import {
  card,
  cardDark,
  cardLight,
  normal,
  secondaryText,
  small,
  text,
  transition,
} from "../../utils/themeContstants";
import CustomInput from "../custom/CustomInput";
import CustomModal from "../custom/CustomModal";
import NotSelected from "../custom/NotSelected";
import useMiddleware from "../../api/useMiddleware";

const PremiumFriendModal = ({ open, onClose, setFriend }) => {
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setQuery("");
    setResults(null);
    setLoading(false);
    onClose();
  };

  const handleSearch = (searchQuery) => {
    if (
      searchQuery === "" ||
      searchQuery == null ||
      searchQuery?.length >= 21
    ) {
      setResults(null);
      return;
    }

    setResults(null);
    setLoading(true);
    searchForUser(middleware, searchQuery).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setResults(res?.userArray);
      }
    });
  };

  const handleUserClick = (user) => {
    setFriend(user);
    handleClose();
  };

  const styles = {
    width: {
      width: "100%",
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    username: {
      fontSize: normal,
      color: text,
      fontWeight: 800,
    },
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 600,
    },
    container: {
      width: "100%",
      paddingLeft: 2,
      paddingBottom: 1,
      paddingRight: 2,
      borderRadius: 1,
      backgroundColor: cardDark,
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: card,
      },
    },
  };

  return (
    <CustomModal
      title="Choose a Friend"
      onClose={handleClose}
      open={open}
      backgroundColor={cardLight}
      minWidth={600}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.label}>
                Search for a friend to gift premium to
              </Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <CustomInput
                value={query}
                placeholder="Search users..."
                onChange={(val) => {
                  setQuery(val);
                  handleSearch(val);
                }}
                autoFocus={true}
                onPaste={(val) => {
                  setQuery(val);
                  handleSearch(val);
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {loading && (
          <Grid item>
            <CircularProgress size={30} sx={{ color: secondaryText }} />
          </Grid>
        )}

        {!loading &&
          query !== "" &&
          query != null &&
          (results == null || results?.length < 1) && (
            <NotSelected label="No results" />
          )}

        {loading ||
        results?.length < 1 ||
        results == null ||
        query === "" ||
        query == null ? null : (
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              {results?.map((user, i) => {
                return (
                  <Grid
                    item
                    sx={styles.container}
                    key={i}
                    onClick={() => handleUserClick(user)}
                  >
                    <Grid container justifyContent="start" alignItems="center">
                      <Grid item sx={{ marginLeft: -2 }}>
                        <Avatar
                          size={100}
                          bgColor={card}
                          avatar={user?.avatar}
                        />
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.username}>
                          {user?.username}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

export default PremiumFriendModal;
