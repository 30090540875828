import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BsCheck } from "react-icons/bs";
import {
  card,
  cardVeryLight,
  doubtedBlue,
  normal,
  secondaryButton,
  secondaryText,
  text,
} from "../../utils/themeContstants";

const StepperItem = ({
  current,
  finished,
  title,
  description,
  isLast,
  minWidth,
}) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  const styles = {
    title: {
      fontSize: normal,
      fontWeight: 600,
      color: text,
    },
    titleUnselected: {
      fontSize: normal,
      fontWeight: 600,
      color: secondaryText,
      opacity: 0.7,
    },
    subTitle: {
      fontSize: normal,
      fontWeight: 400,
      color: "#d7e4f1",
      maxWidth: 200,
    },
    subTitleUnselected: {
      fontSize: normal,
      fontWeight: 400,
      color: secondaryText,
      opacity: 0.7,
      maxWidth: 200,
    },
  };

  return (
    <Grid
      item
      sx={{
        flexGrow: isLast ? null : 1,
        minWidth: minWidth ?? null,
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={{ width: "100%" }}>
          <Grid container justifyContent="start" alignItems="center">
            <Grid
              item
              sx={{
                height: 26,
                width: 26,
                borderRadius: 100,
                boxSizing: "border-box",
                border: `2px solid ${cardVeryLight}`,
                textAlign: "center",
                backgroundColor: current || finished ? doubtedBlue : card,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {finished ? (
                <BsCheck style={{ color: text, fontSize: 20, marginTop: 2 }} />
              ) : null}
            </Grid>

            {isLast ? null : (
              <Grid
                item
                sx={{ flexGrow: 1, paddingLeft: 0.5, paddingRight: 0.5 }}
              >
                <div
                  style={{
                    height: 2,
                    backgroundColor: finished ? doubtedBlue : cardVeryLight,
                    width: "100%",
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={current ? styles.title : styles.titleUnselected}>
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={current ? styles.subTitle : styles.subTitleUnselected}
              >
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StepperItem;
