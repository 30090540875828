import { CircularProgress, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import {
  cardVeryLight,
  normal,
  red,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import Hover from "./Hover";

const ListItem = ({
  label,
  icon,
  onClick,
  selected = false,
  selectedBg = null,
  bg = null,
  hoveredBg = null,
  disabled = false,
  loading = false,
  open = true,
  isNew = false,
  newLabel = null,
  external = false,
}) => {
  const [hovered, setHovered] = useState(false);
  const [refEl, setRefEl] = useState(null);

  const getBackgroundColor = () => {
    if (!bg) {
      if (disabled || loading) return "transparent";

      if (selected) return cardVeryLight;

      if (hovered) return cardVeryLight;

      return "transparent";
    }

    if (bg) {
      if (selected) {
        return selectedBg;
      }
      return hovered ? hoveredBg : bg;
    }
  };

  const styles = {
    container: {
      position: "relative",
      borderRadius: open ? 1 : 0,
      backgroundColor: getBackgroundColor(),
      padding: 1.5,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor:
        disabled || loading ? "not-allowed" : hovered ? "pointer" : "default",
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    label: {
      fontSize: 13,
      color: text,
      fontWeight: 700,
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={disabled || loading ? null : onClick}
      ref={setRefEl}
    >
      {isNew && open && (
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 1,
            paddingRight: 1,
            paddingTop: 0.5,
            paddingBottom: 0.5,
            borderRadius: 1,
            backgroundColor: red,
            position: "absolute",
            right: 0,
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Typography
            sx={{
              fontSize: 12,
              color: "#fff",
              fontWeight: 800,
              textTransform: "uppercase",
            }}
          >
            {newLabel}
          </Typography>
        </Grid>
      )}

      {!open && hovered && (
        <Hover label={label} placement="right" refEl={refEl} />
      )}
      <Grid
        container
        justifyContent={open ? "start" : "center"}
        alignItems="center"
        gap={{ xs: 1 }}
      >
        {icon && (
          <Grid item sx={styles.iconContainer}>
            {!loading && <>{icon}</>}

            {loading && (
              <CircularProgress size={20} sx={{ color: secondaryText }} />
            )}
          </Grid>
        )}

        {open && (
          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.label}>{label}</Typography>
              </Grid>

              {external && (
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BiLinkExternal
                    style={{ fontSize: 14, color: secondaryText }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ListItem;
