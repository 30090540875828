import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useMiddleware from "../../api/useMiddleware";
import { addConnection } from "../../api/user";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  SET_USER,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import LoadingScreen from "../custom/LoadingScreen";

const ConnectionRedirect = ({ type }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const navigate = useNavigate();
  const updateStore = useContext(StoreDispatch);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    addConnection(
      middleware,
      type,
      type === "twitter"
        ? searchParams.get("oauth_token")
        : searchParams.get("code"),
      searchParams.get("oauth_verifier")
    ).then((res) => {
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
        navigate("/profile/accounts");
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: `Successfully connected your ${type} account.`,
        });
        updateStore({ type: SET_USER, payload: res?.user });
        navigate("/profile/accounts");
      }
    });
  }, []);

  return <LoadingScreen size={150} />;
};

export default ConnectionRedirect;
