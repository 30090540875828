const Mohawk = ({ color }) => {
  const styles = {
    one: {
      fill: color,
    },
    two: {
      fill: color,
    },
    three: {
      fill: color,
      filter: "brightness(80%)",
    },
    four: {
      fill: color,
      filter: "brightness(80%)",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: color,
    },
  };

  return (
    <g transform="translate(356, 186)">
      <g>
        <path
          style={styles.four}
          d="M82.56,20.37c-1.43-.52-3.02-1-4.77-1.36-5.24-1.09-9.84-.76-13.22-.15,16.12,12.95,28.65,23.02,28.65,23.02,0,0,0,0-.02-.01-.86-.69-10.64-21.49-10.64-21.49Z"
        />
        <path
          style={styles.four}
          d="M240.6,19.16c-.89-.47-1.87-.91-2.94-1.29-5.4-1.91-10.37-1.3-13.56-.54v24.54l16.5-22.71Z"
        />
        <polygon
          style={styles.one}
          points="256.65 22.55 234.79 50.11 260.15 24.01 256.65 22.55"
        />
        <path
          style={styles.five}
          d="M200.92,120.55l6.52,5.45,56.69-26.03-43.94,6.05,32.6-37.23-25.51,10.29,45.07-47.52c-3.78-3.05-9.66-7.04-17.57-9.68-5.45-1.82-10.35-2.51-14.17-2.72-5.57,8.17-11.15,16.34-16.72,24.52l.28-32.99-9.07,4.54c-2.2-1.74-5.45-4.09-9.64-6.36-11.1-6-21.76-7.6-37.42-8.17C148.68,0,133.58,1.31,127.22,1.91c-12.44,1.18-29.2,3.28-49.04,7.26,5.01,10.9,10.02,21.79,15.02,32.69l-28.63-23c-5.96,.53-21.61,2.78-35.72,15.74C8,53.76,7.22,80.99,7.16,85.44c3.17,4.24,6.34,8.47,9.51,12.71l-15.59,.3c-.77,6.12-2.68,26.92,9.92,43.88,3.2,4.31,6.72,7.54,9.97,9.95,.19-3.49,.58-8.27,1.43-13.88,1.01-6.69,2.05-13.56,4.82-21.06,1.78-4.82,6.05-16.02,16.38-25.25,7.13-6.37,14.05-8.97,17.99-10.51,22.5-8.8,43.03-2.43,50.89,.52,3.08,2.76,8.39,6.8,15.87,9.38,6.18,2.13,11.61,2.49,15.31,2.42,3.24,5.13,10.49,15.07,23.24,21.79,8.6,4.53,16.51,5.91,21.68,6.36,1.66,1.41,3.31,2.82,4.97,4.24l7.37-5.75Z"
        />
        <polygon
          style={styles.three}
          points="211.92 14.24 198.23 30.92 214.97 16.57 211.92 14.24"
        />
        <polygon
          style={styles.three}
          points="88 8.49 99.2 30 90.7 8.01 88 8.49"
        />
        <polygon
          style={styles.three}
          points="23.92 41.38 52.39 58.6 27.05 38.01 23.92 41.38"
        />
        <polygon
          style={styles.three}
          points="18.88 48.77 19.39 49.09 39.64 61.57 20.71 45.88 18.88 48.77"
        />
        <polygon
          style={styles.three}
          points="54.36 50.09 87 75.67 80.51 75.67 54.36 50.09"
        />
        <path
          style={styles.three}
          d="M117.19,84.43c-.08-.51-17.67-27.13-17.67-27.13l22.18,30.04-4.51-2.91Z"
        />
        <polygon
          style={styles.three}
          points="121.69 24.11 141.24 92.72 143.66 92.74 121.69 24.11"
        />
        <polygon
          style={styles.three}
          points="198.98 119.95 214.7 85.92 219.27 88.17 201.35 118.8 198.98 119.95"
        />
        <polygon
          style={styles.three}
          points="191 122.3 202.9 73.02 205.24 73.02 193.55 124.72 191 122.3"
        />
        <polygon
          style={styles.three}
          points="181.09 .24 165.18 113.4 166.9 114.35 181.09 .24"
        />
        <line style={styles.three} x1="8.59" y1="84.47" x2="33.8" y2="101.67" />
        <line style={styles.two} x1="63.47" y1="66.37" x2="80.72" y2="74.7" />
      </g>
    </g>
  );
};

export default Mohawk;
