const PartyHat = () => {
  const styles = {
    one: {
      fill: "url(#party_hat-gradient-2)",
    },
    two: {
      fill: "url(#party_hat-gradient-6)",
    },
    three: {
      fill: "url(#party_hat-gradient-5)",
    },
    four: {
      fill: "url(#party_hat-gradient-7)",
    },
    five: {
      fill: "url(#party_hat-gradient-3)",
    },
    six: {
      fill: "url(#party_hat-gradient)",
    },
    seven: {
      fill: "url(#party_hat-gradient-4)",
    },
    eight: {
      fill: "url(#party_hat-gradient-8)",
    },
  };

  return (
    <g>
      <defs>
        <linearGradient
          id="party_hat-gradient"
          x1="345.73"
          y1="191.5"
          x2="420.45"
          y2="191.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#0a187f" />
          <stop offset="1" stop-color="#121ad6" />
        </linearGradient>
        <linearGradient
          id="party_hat-gradient-2"
          x1="471.11"
          y1="318.67"
          x2="526.34"
          y2="173.74"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#a60eaa" />
          <stop offset="1" stop-color="#df16de" />
        </linearGradient>
        <linearGradient
          id="party_hat-gradient-3"
          x1="575.14"
          y1="385.96"
          x2="600.19"
          y2="223.65"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#b2130b" />
          <stop offset="1" stop-color="#df2715" />
        </linearGradient>
        <linearGradient
          id="party_hat-gradient-4"
          x1="698.85"
          y1="210.23"
          x2="632.41"
          y2="192.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#b26b0f" />
          <stop offset="1" stop-color="#e09118" />
        </linearGradient>
        <linearGradient
          id="party_hat-gradient-5"
          x1="603.9"
          y1="192.73"
          x2="571.79"
          y2="186"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#a59d08" />
          <stop offset="1" stop-color="#d5d014" />
        </linearGradient>
        <linearGradient
          id="party_hat-gradient-6"
          x1="556.92"
          y1="219.23"
          x2="533.08"
          y2="168.19"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#80c90e" />
          <stop offset="1" stop-color="#91ea15" />
        </linearGradient>
        <linearGradient
          id="party_hat-gradient-7"
          x1="437.34"
          y1="178.64"
          x2="433.68"
          y2="144.51"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#0db26b" />
          <stop offset="1" stop-color="#17ea98" />
        </linearGradient>
        <linearGradient
          id="party_hat-gradient-8"
          x1="412.81"
          y1="215.07"
          x2="392.46"
          y2="168.43"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#0776ad" />
          <stop offset="1" stop-color="#0d86d0" />
        </linearGradient>
      </defs>
      <g>
        <polygon
          style={styles.six}
          points="425.71 297 411.58 216 351 86 356.05 209 382.69 297 425.71 297"
        />
        <polygon
          style={styles.one}
          points="411.58 216 467.11 80 568.07 253 562.02 339 425.71 297 411.58 216"
        />
        <polygon
          style={styles.five}
          points="614.21 80 568.07 253 562.02 339 604.93 308.5 613 221.5 633.2 188.5 614.21 80"
        />
        <polygon
          style={styles.seven}
          points="674 80 633.2 188.5 613 221.5 604.93 308.5 634.71 279 643.29 211.5 674 80"
        />
      </g>
      <g>
        <polygon
          style={styles.three}
          points="600.7 130.65 557.98 183 555.82 232 563.05 244.38 569.68 246.96 600.7 130.65"
        />
        <polygon
          style={styles.two}
          points="501.18 138.37 514.18 102.91 557.98 183 555.82 232 501.18 138.37"
        />
        <path
          style={styles.four}
          d="M417.82,200.72c-.18-.72,30.11-98.72,30.11-98.72l4.57,13.8-34.67,84.92Z"
        />
        <polygon
          style={styles.eight}
          points="363.34 112.48 363.34 106.83 414.64 208.49 411.58 216 363.34 112.48"
        />
      </g>
    </g>
  );
};

export default PartyHat;
