const LipstickThree = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
  };

  return (
    <g transform="translate(507, 455)">
      <path d="M0,26.97c.94-.86,1.78-1.71,2.7-2.47,6.52-5.42,12.65-11.23,18.4-17.47,1.62-1.75,3.39-3.39,5.24-4.9,2.75-2.24,7.49-3.27,11.08-.29,1.89-.15,3.62-.43,5.35-.4,2.26,.04,4.13,1.05,5.6,2.81,2.21,2.64,4.31,5.34,5.06,8.81,.39,1.77,.47,3.58-.55,5.1-1.01,1.5-.85,2.95-.55,4.53,.53,2.84,.74,5.71,.26,8.57-1.03,6.18-4.95,9.88-10.66,11.76-6.24,2.05-12.66,2.34-19.15,1.36-4.4-.66-8.02-3.06-11.68-5.32-3.06-1.89-5.8-4.24-8.19-6.95-1.27-1.44-2.47-2.94-2.92-5.14Zm2.04,1.17c.15,.23,.25,.46,.4,.62,5.56,5.96,12.47,9.64,20.31,11.52,5.84,1.4,11.76,1.08,17.57-.24,6.28-1.42,9.67-5.67,9.98-12.09,.09-1.99-.01-3.99,.04-5.98,.02-.94,.07-1.92,.33-2.82,.98-3.37,1.04-6.62-1.16-9.57-1.04-1.4-2.18-2.75-3.38-4.02-1.5-1.59-3.41-2.01-5.5-1.49-1.08,.27-2.11,.71-3.19,1-1.03,.28-2.22,.45-2.74-.72-.72-1.63-2.05-1.81-3.37-1.44-1.34,.37-2.7,1-3.79,1.85-1.44,1.12-2.62,2.56-3.9,3.87-3.18,3.25-6.19,6.68-9.57,9.71-3.79,3.41-7.9,6.46-12.04,9.8Z" />
      <path d="M2.04,28.14c4.14-3.34,8.25-6.39,12.04-9.8,3.37-3.03,6.39-6.46,9.57-9.71,1.28-1.31,2.47-2.75,3.9-3.87,1.09-.85,2.45-1.48,3.79-1.85,1.33-.37,2.65-.19,3.37,1.44,.52,1.17,1.71,.99,2.74,.72,1.08-.29,2.11-.74,3.19-1,2.09-.52,4-.1,5.5,1.49,1.2,1.27,2.34,2.62,3.38,4.02,2.19,2.95,2.13,6.2,1.16,9.57-.26,.9-.31,1.88-.33,2.82-.05,1.99,.05,3.99-.04,5.98-.31,6.42-3.7,10.67-9.98,12.09-5.81,1.31-11.74,1.64-17.57,.24-7.84-1.88-14.75-5.56-20.31-11.52-.16-.17-.26-.39-.4-.62Zm9.89-3.11c2.38,1.6,4.64,1.96,6.95,1.95,7.5-.03,14.99-.25,22.26-2.37,1.96-.57,3.86-1.36,6.13-2.17-.9-1.53-1.69-2.7-2.29-3.96-1.1-2.32-3-3.2-5.42-3.2-3.43,0-6.86-.04-10.29,.14-2.13,.12-4.33,.38-6.21,1.55-2.44,1.51-4.84,3.09-7.18,4.74-1.28,.9-2.41,2.01-3.95,3.32Z" />
      <path d="M11.93,25.03c1.54-1.31,2.67-2.42,3.95-3.32,2.34-1.65,4.74-3.23,7.18-4.74,1.88-1.16,4.08-1.43,6.21-1.55,3.42-.19,6.86-.14,10.29-.14,2.42,0,4.31,.88,5.42,3.2,.6,1.26,1.4,2.43,2.29,3.96-2.27,.81-4.17,1.6-6.13,2.17-7.27,2.12-14.76,2.34-22.26,2.37-2.3,.01-4.57-.36-6.95-1.95Zm32.87-2.99c-1.21-3.21-3.16-4.64-6.34-4.43-2.78,.19-5.58,0-8.37,.18-2.05,.12-4.24,.16-6.08,.92-2.92,1.2-5.62,2.97-8.4,4.51-.45,.25-.9,.51-1.34,.76,.15,.25,.29,.5,.44,.75,10.1,.79,20.09,.32,30.09-2.68Z" />
      <path
        style={styles.one}
        d="M44.8,22.04c-10,3-19.99,3.47-30.09,2.68-.15-.25-.29-.5-.44-.75,.45-.25,.89-.51,1.34-.76,2.79-1.53,5.48-3.31,8.4-4.51,1.84-.76,4.03-.79,6.08-.92,2.78-.17,5.59,.01,8.37-.18,3.18-.21,5.13,1.22,6.34,4.43Z"
      />
    </g>
  );
};

export default LipstickThree;
