import {
  Divider,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { BsPiggyBankFill, BsPlayFill, BsTwitch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { getStats, getStreams } from "../../api/home";
import { getMatches, getOngoingMatches } from "../../api/matches";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { TWITCH_URL } from "../../utils/authURL";
import CustomPill from "../custom/CustomPill";
import {
  HOME_MATCH_LIMIT,
  HOME_ONGOING_LIMIT,
  STREAMS_LIMIT,
} from "../../utils/limits";
import SpacingWrapper from "../../utils/SpacingWrapper";
import {
  background,
  backgroundOpacity,
  cardLight,
  cardVeryLight,
  doubtedBlue,
  emptyIcon,
  lightGrey,
  linearGradientToTop,
  menuShadow,
  offWhite,
  secondaryButton,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";
import Empty from "../custom/Empty";
import NotSelected from "../custom/NotSelected";
import OutlineButton from "../custom/OutlineButton";
import ScrollableContainer from "../custom/ScrollableContainer";
import SecondaryButton from "../custom/SecondaryButton";
import MatchGlimmer from "../glimmers/MatchGlimmer";
import MobileStreamItemGlimmer from "../glimmers/MobileStreamItemGlimmer";
import StreamItemGlimmer from "../glimmers/StreamItemGlimmer";
import MatchItem from "../matches/MatchItem";
import StreamItem from "../streams/StreamItem";
import {
  currencyFormatter,
  hexToRgbA,
  numFormatter,
} from "../../utils/helpers";
import { RiSwordFill } from "react-icons/ri";
import { AiFillFire, AiFillTrophy } from "react-icons/ai";
import GameItem from "../games/GameItem";
import { FaChevronRight } from "react-icons/fa";
import OngoingTokenItem from "../tokens/OngoingTokenItem";
import OngoingGlimmer from "../glimmers/OngoingGlimmer";
import SelectGameModal from "../custom/SelectGameModal";

const Home = () => {
  const updateStore = useContext(StoreDispatch);
  const isTablet = useMediaQuery("(max-width: 768px)");
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const ongoingBottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  const [ongoingLoading, setOngoingLoading] = useState(true);
  const [ongoingMatches, setOngoingMatches] = useState(null);
  const [ongoingSkip, setOngoingSkip] = useState(0);
  const [ongoingHasMore, setOngoingHasMore] = useState(false);
  const [ongoingFetchLoading, setOngoingFetchLoading] = useState(false);

  const [streams, setStreams] = useState(null);
  const [streamSkip, setStreamSkip] = useState(0);
  const [hasMoreStreams, setHasMoreStreams] = useState(false);
  const [streamsLoading, setStreamsLoading] = useState(true);
  const [fetchStreamsLoading, setFetchStreamsLoading] = useState(false);

  const [statsLoading, setStatsLoading] = useState(true);
  const [stats, setStats] = useState(null);

  const [gamesOpen, setGamesOpen] = useState(false);
  const [gamesTitle, setGamesTitle] = useState(null);

  const handleGetMatches = (filtering = false) => {
    setFetchLoading(true);
    getMatches(skip, null, null, null, null, null, true, null).then((res) => {
      setLoading(false);
      setFetchLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        if (res?.matches?.length >= HOME_MATCH_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setMatches(
          filtering
            ? res?.matches
            : matches
              ? [...matches, ...res?.matches]
              : res?.matches
        );
      }
    });
  };

  const handleGetOngoing = () => {
    setOngoingFetchLoading(true);
    getOngoingMatches(ongoingSkip, true).then((res) => {
      setOngoingLoading(false);
      setOngoingFetchLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.matches?.length >= HOME_ONGOING_LIMIT) {
          setOngoingHasMore(true);
        } else {
          setOngoingHasMore(false);
        }

        setOngoingMatches(
          ongoingMatches ? [...ongoingMatches, ...res?.matches] : res?.matches
        );
      }
    });
  };

  const handleGetStreams = () => {
    setFetchStreamsLoading(true);
    getStreams(streamSkip).then((res) => {
      setStreamsLoading(false);
      setFetchStreamsLoading(false);

      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        if (res?.streams?.length >= STREAMS_LIMIT) {
          setHasMoreStreams(true);
        } else {
          setHasMoreStreams(false);
        }

        setStreams(streams ? [...streams, ...res?.streams] : res?.streams);
      }
    });
  };

  const handleGetStats = () => {
    getStats().then((res) => {
      setStatsLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setStats(res?.stats);
      }
    });
  };

  useEffect(() => {
    if (loading) {
      if (matches == null) {
        handleGetMatches();
        return;
      }
    }
  }, [loading, matches]);

  useEffect(() => {
    if (skip !== 0) {
      handleGetMatches();
    }
  }, [skip]);

  useEffect(() => {
    if (ongoingLoading) {
      if (ongoingMatches == null) {
        handleGetOngoing();
        return;
      }
    }
  }, [ongoingLoading, ongoingMatches]);

  useEffect(() => {
    if (ongoingSkip !== 0) {
      handleGetOngoing();
    }
  }, [ongoingSkip]);

  useEffect(() => {
    if (streamsLoading) {
      if (streams == null) {
        handleGetStreams();
        return;
      }
    }
  }, [streamsLoading, streams]);

  useEffect(() => {
    if (streamSkip !== 0) {
      handleGetStreams();
    }
  }, [streamSkip]);

  useEffect(() => {
    if (statsLoading) {
      if (!stats) {
        handleGetStats();
      }
    }
  }, [stats, statsLoading]);

  const styles = {
    container: {
      // backgroundSize: "cover",
      // backgroundImage: `url(${homeBackground})`,
      width: "100%",
      minHeight: "100vh",
      position: "relative",
    },
    width: {
      width: "100%",
    },
    headerContainer: {
      zIndex: 1,
      width: "100%",
    },
    header: {
      fontSize: isTablet ? 32 : 52,
      color: text,
      fontWeight: 800,
      maxWidth: 800,
      textAlign: "center",
      lineHeight: 1.25,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 12,
    },
    description: {
      fontWeight: 400,
      color: secondaryText,
      fontSize: isTablet ? 14 : 17,
      maxWidth: 500,
      textAlign: "center",
    },
    sectionHeader: {
      fontSize: 22,
      fontWeight: 700,
      color: text,
    },
    meta: {
      fontSize: isTablet ? 14 : 13,
      fontWeight: 500,
      color: offWhite,
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      fontSize: 16,
      color: doubtedBlue,
    },
    nextContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 48,
      width: 48,
      backgroundColor: cardLight,
      borderRadius: 100,
      boxShadow: menuShadow,
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: cardVeryLight,
      },
    },
  };

  return (
    <>
      <SelectGameModal
        title={gamesTitle}
        open={gamesOpen}
        onClose={(selectedGame) => {
          setGamesOpen(false);
          setGamesTitle(null);

          if (selectedGame) {
            navigate(`/wagers/matches`);
          }
        }}
      />

      <Grid item sx={styles.container}>
        <div
          style={{
            // backgroundImage: `url(${characterHeader})`,
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            width: "100%",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1,
            maxWidth: "100%",
            maxHeight: 554,
          }}
        />

        <div
          style={{
            backgroundColor: backgroundOpacity(0.6),
            zIndex: -1,
            position: "absolute",
            width: "100%",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 0,
            maxWidth: "100%",
            maxHeight: 554,
          }}
        />

        <div
          style={{
            background: linearGradientToTop(
              hexToRgbA(background, 1),
              hexToRgbA(background, 0)
            ),
            zIndex: -1,
            position: "absolute",
            width: "100%",
            top: 0,
            bottom: -1,
            right: 0,
            left: 0,
            zIndex: 0,
            maxWidth: "100%",
            maxHeight: 554,
          }}
        />

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <SpacingWrapper maxWidth={1400}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              gap={{ xs: 6 }}
              sx={{ paddingBottom: 8, maxWidth: 1400 }}
            >
              <Grid item sx={styles.headerContainer} alignSelf="center">
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item>
                    <Typography sx={styles.header}>
                      The Fastest Growing Esports Wager Platform
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.description}>
                      Join{" "}
                      {statsLoading ? (
                        <span
                          style={{
                            display: "inline-block",
                            textAlign: "center",
                          }}
                        >
                          <Skeleton
                            variant="text"
                            width={75}
                            height={24}
                            sx={{ borderRadius: 1, bgcolor: cardVeryLight }}
                          />
                        </span>
                      ) : (
                        <span style={{ fontWeight: 800, color: text }}>
                          {numFormatter(0).format(stats?.player_count)}
                        </span>
                      )}{" "}
                      other players competing in wager matches and start earning
                      today.
                    </Typography>
                  </Grid>

                  <Grid item sx={styles.fullWidth}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      gap={{ xs: 2 }}
                    >
                      <Grid item sx={{ minWidth: isTablet ? "100%" : null }}>
                        <SecondaryButton
                          label="Find Matches"
                          icon={
                            <RiSwordFill
                              style={{ fontSize: 18, color: text }}
                            />
                          }
                          bg={!store?.user ? secondaryButton : doubtedBlue}
                          fullWidth
                          onClick={() => {
                            navigate("/wagers/matches")
                            // setGamesTitle("Find Wagers");
                            // setGamesOpen(true);
                          }}
                        />
                      </Grid>

                      {!store?.user && (
                        <Grid item sx={{ minWidth: isTablet ? "100%" : null }}>
                          <SecondaryButton
                            label="Register Now"
                            bg={doubtedBlue}
                            icon={
                              <BsPlayFill
                                style={{ fontSize: 18, color: text }}
                              />
                            }
                            onClick={() => navigate("/login")}
                            fullWidth
                          />
                        </Grid>
                      )}

                      <Grid item sx={{ minWidth: isTablet ? "100%" : null }}>
                        <SecondaryButton
                          label="Leaderboard"
                          icon={
                            <AiFillTrophy
                              style={{ fontSize: 18, color: text }}
                            />
                          }
                          bg={!store?.user ? secondaryButton : doubtedBlue}
                          onClick={() => navigate("/leaderboard")}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sx={{ marginTop: 2 }}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          gap={{ xs: 1 }}
                        >
                          <Grid item sx={styles.iconContainer}>
                            <RiSwordFill style={styles.icon} />
                          </Grid>

                          <Grid item>
                            <Typography sx={styles.meta}>
                              {statsLoading ? (
                                <span
                                  style={{
                                    display: "inline-block",
                                    textAlign: "center",
                                  }}
                                >
                                  <Skeleton
                                    variant="text"
                                    width={50}
                                    height={18}
                                    sx={{
                                      borderRadius: 1,
                                      bgcolor: cardVeryLight,
                                    }}
                                  />
                                </span>
                              ) : (
                                <span style={{ fontWeight: 800, color: text }}>
                                  {numFormatter(0).format(
                                    stats?.matches_played
                                  )}
                                </span>
                              )}{" "}
                              Wagers Played
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.meta}>•</Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          gap={{ xs: 1 }}
                        >
                          <Grid item sx={styles.iconContainer}>
                            <BsPiggyBankFill style={styles.icon} />
                          </Grid>

                          <Grid item>
                            <Typography sx={styles.meta}>
                              {statsLoading ? (
                                <span
                                  style={{
                                    display: "inline-block",
                                    textAlign: "center",
                                  }}
                                >
                                  <Skeleton
                                    variant="text"
                                    width={50}
                                    height={18}
                                    sx={{
                                      borderRadius: 1,
                                      bgcolor: cardVeryLight,
                                    }}
                                  />
                                </span>
                              ) : (
                                <span style={{ fontWeight: 800, color: text }}>
                                  {currencyFormatter().format(
                                    stats?.winnings_paid
                                  )}
                                </span>
                              )}{" "}
                              Winnings Paid
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.meta}>•</Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          gap={{ xs: 1 }}
                        >
                          <Grid item sx={styles.iconContainer}>
                            <AiFillFire style={styles.icon} />
                          </Grid>

                          <Grid item>
                            <Typography sx={styles.meta}>
                              {statsLoading ? (
                                <span
                                  style={{
                                    display: "inline-block",
                                    textAlign: "center",
                                  }}
                                >
                                  <Skeleton
                                    variant="text"
                                    width={50}
                                    height={18}
                                    sx={{
                                      borderRadius: 1,
                                      bgcolor: cardVeryLight,
                                    }}
                                  />
                                </span>
                              ) : (
                                <span style={{ fontWeight: 800, color: text }}>
                                  {stats?.ongoing_matches}
                                </span>
                              )}{" "}
                              Ongoing Wagers
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid item sx={{ width: "100%", zIndex: 1 }}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography sx={styles.sectionHeader}>
                          Browse games
                        </Typography>
                      </Grid>

                      <Grid item>
                        <OutlineButton
                          label="View all"
                          onClick={() => {
                            setGamesTitle("Browse Games");
                            setGamesOpen(true);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {!isTablet && (
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 2 }}
                      >
                        <GameItem
                          game="fortnite"
                          onClick={() => navigate("/wagers/matches/matches")}
                          size={isTablet ? "small" : null}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {isTablet && (
                    <Grid item sx={styles.width}>
                      <ScrollableContainer gap={2}>
                        <GameItem
                          game="fortnite"
                          onClick={() => navigate("/wagers/matches/matches")}
                          size={isTablet ? "small" : null}
                        />

                      </ScrollableContainer>
                    </Grid>
                  )}
                </Grid>
              </Grid> */}

              <Grid item sx={{ width: "100%", zIndex: 1 }}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 2 }}
                    >
                      <Grid item>
                        <Typography sx={styles.sectionHeader}>
                          Live Wagers
                        </Typography>
                      </Grid>

                      {store?.user ? (
                        !store?.user?.connections?.twitch ? (
                          <Grid item>
                            <SecondaryButton
                              label="Link Twitch"
                              size="small"
                              icon={<BsTwitch style={{ fontSize: 18, color: text }} />}
                              onClick={() => window.open(TWITCH_URL, "_self")}
                            />
                          </Grid>
                        ) : (
                          <CustomPill
                            label={"How to stream?"}
                            hoverLabel="Stream with #rivox.gg in your twitch title and start playing!"
                          />
                        )
                      ) : null}

                    </Grid>
                  </Grid>

                  {streamsLoading && isTablet && (
                    <MobileStreamItemGlimmer numItems={4} />
                  )}

                  {streamsLoading && !isTablet && (
                    <StreamItemGlimmer numItems={4} />
                  )}

                  {!streamsLoading && streams?.length < 1 && (
                    <Empty
                      title="No live streams"
                      label="Try linking your Twitch and streaming"
                      icon={<BsTwitch style={emptyIcon} />}
                    />
                  )}

                  {isTablet && (
                    <Grid item sx={styles.width}>
                      <ScrollableContainer gap={1}>
                        {streams?.map((stream, i) => (
                          <StreamItem
                            stream={stream}
                            key={i}
                            width={273}
                            height={153}
                          />
                        ))}
                      </ScrollableContainer>
                    </Grid>
                  )}

                  {!isTablet && (
                    <>
                      {!streamsLoading && streams?.length > 0 && (
                        <Grid
                          item
                          sx={{
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns:
                                "repeat(auto-fill,minmax(325px, 2fr))",
                              gap: 12,
                            }}
                          >
                            {streams?.map((stream, i) => (
                              <StreamItem stream={stream} key={i} />
                            ))}
                          </div>

                          {hasMoreStreams && (
                            <Grid item sx={{ width: "100%", marginTop: 2 }}>
                              <Grid
                                container
                                justifyContent="start"
                                alignItems="center"
                                gap={{ xs: 1 }}
                              >
                                <Grid item sx={{ flexGrow: 1 }}>
                                  <Divider
                                    sx={{
                                      width: "100%",
                                      backgroundColor: text,
                                      opacity: 0.1,
                                    }}
                                  />
                                </Grid>

                                <Grid item>
                                  <OutlineButton
                                    label="Show more"
                                    onClick={() => {
                                      setStreamSkip(streams?.length);
                                    }}
                                    loading={fetchStreamsLoading}
                                  />
                                </Grid>

                                <Grid item sx={{ flexGrow: 1 }}>
                                  <Divider
                                    sx={{
                                      width: "100%",
                                      backgroundColor: text,
                                      opacity: 0.1,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid item sx={{ width: "100%", zIndex: 1 }}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid item>
                      <Typography sx={styles.sectionHeader}>
                        Open Wagers
                      </Typography>
                    </Grid>
                  </Grid>

                  {loading && <MatchGlimmer numItems={4} />}

                  {!loading && matches?.length < 1 && (
                    <Grid item alignSelf="center" sx={{ marginTop: 4 }}>
                      <NotSelected label="no available wagers" />
                    </Grid>
                  )}

                  {!loading && matches?.length > 0 && (
                    <Grid item sx={styles.width}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fill,minmax(250px, 4fr))",
                          gap: 12,
                          position: "relative",
                        }}
                      >
                        {matches?.map((match, i) => (
                          <MatchItem home={true} match={match} key={i} />
                        ))}

                        {/* <Grid
                          item
                          sx={{
                            ...styles.nextContainer,
                            position: "absolute",
                            right: -24,
                            top: 0,
                            bottom: 0,
                            margin: "auto 0",
                          }}
                        >
                          <FaChevronRight
                            style={{ fontSize: 24, color: lightGrey }}
                          />
                        </Grid> */}
                      </div>

                      {hasMore && (
                        <Grid item sx={{ width: "100%", marginTop: 2 }}>
                          <Grid
                            container
                            justifyContent="start"
                            alignItems="center"
                            gap={{ xs: 1 }}
                          >
                            <Grid item sx={{ flexGrow: 1 }}>
                              <Divider
                                sx={{
                                  width: "100%",
                                  backgroundColor: text,
                                  opacity: 0.1,
                                }}
                              />
                            </Grid>

                            <Grid item>
                              <OutlineButton
                                label="Show more"
                                onClick={() => {
                                  setSkip(matches?.length);
                                }}
                                loading={fetchLoading}
                              />
                            </Grid>

                            <Grid item sx={{ flexGrow: 1 }}>
                              <Divider
                                sx={{
                                  width: "100%",
                                  backgroundColor: text,
                                  opacity: 0.1,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid item>
                      <Typography sx={styles.sectionHeader}>
                        Ongoing Wagers
                      </Typography>
                    </Grid>
                  </Grid>

                  {ongoingLoading && <OngoingGlimmer numItems={6} />}

                  {!ongoingLoading && ongoingMatches?.length < 1 && (
                    <Grid item alignSelf="center" sx={{ marginTop: 4 }}>
                      <NotSelected label="no ongoing wagers" />
                    </Grid>
                  )}

                  {!ongoingLoading &&
                    ongoingMatches &&
                    ongoingMatches?.length > 0 && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                          gap={{ xs: 1 }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "grid",
                              gap: 8,
                              gridTemplateColumns: `repeat(${isTablet ? "1" : "2"
                                }, minmax(0, 1fr))`,
                            }}
                          >
                            {ongoingMatches?.map((match, i) => (
                              <OngoingTokenItem token={match} key={i} />
                            ))}
                          </div>

                          {ongoingHasMore && (
                            <Grid item sx={{ width: "100%", marginTop: 2 }}>
                              <Grid
                                container
                                justifyContent="start"
                                alignItems="center"
                                gap={{ xs: 1 }}
                              >
                                <Grid item sx={{ flexGrow: 1 }}>
                                  <Divider
                                    sx={{
                                      width: "100%",
                                      backgroundColor: text,
                                      opacity: 0.1,
                                    }}
                                  />
                                </Grid>

                                <Grid item>
                                  <OutlineButton
                                    label="Show more"
                                    onClick={() => {
                                      setOngoingSkip(ongoingMatches?.length);
                                    }}
                                    loading={ongoingFetchLoading}
                                  />
                                </Grid>

                                <Grid item sx={{ flexGrow: 1 }}>
                                  <Divider
                                    sx={{
                                      width: "100%",
                                      backgroundColor: text,
                                      opacity: 0.1,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </SpacingWrapper>
        </Grid>
      </Grid>
      {/* <Grid item sx={styles.container}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <SpacingWrapper>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              gap={{ xs: 6 }}
              sx={{ paddingBottom: 8, maxWidth: 1200 }}
            >
              <HomeCards />

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography sx={styles.header}>Browse games</Typography>
                      </Grid>

                      <Grid item>
                        <OutlineButton
                          label="View all"
                          onClick={() => navigate("/games")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {!isTablet && (
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 2 }}
                      >
                        <GameItem
                          game="fortnite"
                          onClick={() => navigate("/wagers/matches/matches")}
                          size={isTablet ? "small" : null}
                        />
                        <GameItem
                          game="fifa"
                          onClick={() => navigate("/fifa/matches")}
                          size={isTablet ? "small" : null}
                        />
                        <GameItem
                          game="clashroyale"
                          onClick={() => navigate("/clashroyale/matches")}
                          size={isTablet ? "small" : null}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {isTablet && (
                    <Grid item sx={styles.width}>
                      <ScrollableContainer gap={2}>
                        <GameItem
                          game="fortnite"
                          onClick={() => navigate("/wagers/matches/matches")}
                          size={isTablet ? "small" : null}
                        />
                        <GameItem
                          game="fifa"
                          onClick={() => navigate("/fifa/matches")}
                          size={isTablet ? "small" : null}
                        />
                        <GameItem
                          game="clashroyale"
                          onClick={() => navigate("/clashroyale/matches")}
                          size={isTablet ? "small" : null}
                        />
                      </ScrollableContainer>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 2 }}
                    >
                      <Grid item>
                        <Typography sx={styles.header}>Live Streams</Typography>
                      </Grid>

                      {store?.user && !store?.user?.connections?.twitch && (
                        <Grid item>
                          <SecondaryButton
                            label="Link Twitch"
                            size="small"
                            icon={
                              <BsTwitch style={{ fontSize: 18, color: text }} />
                            }
                            onClick={() => window.open(TWITCH_URL, "_self")}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {streamsLoading && isTablet && (
                    <MobileStreamItemGlimmer numItems={4} />
                  )}

                  {streamsLoading && !isTablet && (
                    <StreamItemGlimmer numItems={4} />
                  )}

                  {!streamsLoading && streams?.length < 1 && (
                    <Empty
                      title="No live streams"
                      label="Try linking your Twitch and streaming"
                      icon={<BsTwitch style={emptyIcon} />}
                    />
                  )}

                  {isTablet && (
                    <Grid item sx={styles.width}>
                      <ScrollableContainer gap={1}>
                        {streams?.map((stream, i) => (
                          <StreamItem
                            stream={stream}
                            key={i}
                            width={273}
                            height={153}
                          />
                        ))}
                      </ScrollableContainer>
                    </Grid>
                  )}

                  {!isTablet && (
                    <>
                      {!streamsLoading && streams?.length > 0 && (
                        <Grid item sx={styles.width}>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns:
                                "repeat(auto-fill,minmax(250px, 2fr))",
                              gap: 12,
                            }}
                          >
                            {streams?.map((stream, i) => (
                              <StreamItem stream={stream} key={i} />
                            ))}
                          </div>

                          {hasMoreStreams && (
                            <Grid item sx={{ width: "100%" }}>
                              <Grid
                                container
                                justifyContent="start"
                                alignItems="center"
                                gap={{ xs: 1 }}
                              >
                                <Grid item sx={{ flexGrow: 1 }}>
                                  <Divider
                                    sx={{
                                      width: "100%",
                                      backgroundColor: text,
                                      opacity: 0.1,
                                    }}
                                  />
                                </Grid>

                                <Grid item>
                                  <OutlineButton
                                    label="Show more"
                                    onClick={() => {
                                      setStreamSkip(streams?.length);
                                    }}
                                    loading={fetchStreamsLoading}
                                  />
                                </Grid>

                                <Grid item sx={{ flexGrow: 1 }}>
                                  <Divider
                                    sx={{
                                      width: "100%",
                                      backgroundColor: text,
                                      opacity: 0.1,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid item>
                      <Typography sx={styles.header}>Open Matches</Typography>
                    </Grid>
                  </Grid>

                  {loading && <MatchGlimmer numItems={4} />}

                  {!loading && matches?.length < 1 && (
                    <Grid item alignSelf="center" sx={{ marginTop: 4 }}>
                      <NotSelected label="no available matches" />
                    </Grid>
                  )}

                  {!loading && matches?.length > 0 && (
                    <Grid item sx={styles.width}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fill,minmax(250px, 4fr))",
                          gap: 12,
                        }}
                      >
                        {matches?.map((match, i) => (
                          <MatchItem home={true} match={match} key={i} />
                        ))}
                      </div>

                      {hasMore && (
                        <Grid item sx={{ width: "100%", marginTop: 2 }}>
                          <Grid
                            container
                            justifyContent="start"
                            alignItems="center"
                            gap={{ xs: 1 }}
                          >
                            <Grid item sx={{ flexGrow: 1 }}>
                              <Divider
                                sx={{
                                  width: "100%",
                                  backgroundColor: text,
                                  opacity: 0.1,
                                }}
                              />
                            </Grid>

                            <Grid item>
                              <OutlineButton
                                label="Show more"
                                onClick={() => {
                                  setSkip(matches?.length);
                                }}
                                loading={fetchLoading}
                              />
                            </Grid>

                            <Grid item sx={{ flexGrow: 1 }}>
                              <Divider
                                sx={{
                                  width: "100%",
                                  backgroundColor: text,
                                  opacity: 0.1,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item sx={styles.width}>
                    <Grid item>
                      <Typography sx={styles.header}>
                        Ongoing Matches
                      </Typography>
                    </Grid>
                  </Grid>

                  {ongoingLoading && <OngoingGlimmer numItems={6} />}

                  {!ongoingLoading && ongoingMatches?.length < 1 && (
                    <Grid item alignSelf="center" sx={{ marginTop: 4 }}>
                      <NotSelected label="no ongoing matches" />
                    </Grid>
                  )}

                  {!ongoingLoading &&
                    ongoingMatches &&
                    ongoingMatches?.length > 0 && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                          gap={{ xs: 1 }}
                        >
                          {ongoingMatches?.map((match, i) => (
                            <OngoingTokenItem token={match} key={i} />
                          ))}

                          {ongoingHasMore && (
                            <Grid item sx={{ width: "100%", marginTop: 2 }}>
                              <Grid
                                container
                                justifyContent="start"
                                alignItems="center"
                                gap={{ xs: 1 }}
                              >
                                <Grid item sx={{ flexGrow: 1 }}>
                                  <Divider
                                    sx={{
                                      width: "100%",
                                      backgroundColor: text,
                                      opacity: 0.1,
                                    }}
                                  />
                                </Grid>

                                <Grid item>
                                  <OutlineButton
                                    label="Show more"
                                    onClick={() => {
                                      setOngoingSkip(ongoingMatches?.length);
                                    }}
                                    loading={ongoingFetchLoading}
                                  />
                                </Grid>

                                <Grid item sx={{ flexGrow: 1 }}>
                                  <Divider
                                    sx={{
                                      width: "100%",
                                      backgroundColor: text,
                                      opacity: 0.1,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </SpacingWrapper>
        </Grid>
      </Grid> */}
    </>
  );
};

export default Home;
