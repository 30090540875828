import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { AvatarRarityEnum } from "../../utils/enums";
import {
  blue,
  emojiYellow,
  menuShadow,
  red,
  secondaryText,
  transition,
} from "../../utils/themeContstants";
import RivoxCoin from "../custom/RivoxCoin";
import { hexToRgbA, numFormatter } from "../../utils/helpers";
import { CDN_URL } from "../../utils/constants";

const CrateItem = ({ type, onClick }) => {


  const exoticCrate = `${CDN_URL}exotic-crate.svg`;
  const rareCrate = `${CDN_URL}rare-crate.svg`;
  const godlikeCrate = `${CDN_URL}godlike-crate.svg`;
  const emojiCrate = `${CDN_URL}emoji-crate.svg`;




  const isDesktop = useMediaQuery("(min-width:1025px)");

  const [hovered, setHovered] = useState(false);

  const getCrateImage = () => {
    switch (type) {
      case AvatarRarityEnum.EXOTIC:
        return exoticCrate;
      case AvatarRarityEnum.RARE:
        return rareCrate;
      case AvatarRarityEnum.GODLIKE:
        return godlikeCrate;
      case AvatarRarityEnum.EMOJI:
        return emojiCrate;
      default:
        return null;
    }
  };

  const getBorderColor = () => {
    switch (type) {
      case AvatarRarityEnum.EXOTIC:
        return "#fb8b24";
      case AvatarRarityEnum.RARE:
        return blue;
      case AvatarRarityEnum.GODLIKE:
        return red;
      case AvatarRarityEnum.EMOJI:
        return emojiYellow;
      default:
        return null;
    }
  };

  const getBackgroundColor = () => {
    switch (type) {
      case AvatarRarityEnum.EXOTIC:
        return `rgba(251, 139, 36, 0.3)`;
      case AvatarRarityEnum.RARE:
        return `rgba(21, 117, 226, 0.3)`;
      case AvatarRarityEnum.GODLIKE:
        return `rgba(255, 84, 101, 0.3)`;
      case AvatarRarityEnum.EMOJI:
        return hexToRgbA(emojiYellow, 0.3);
      default:
        return null;
    }
  };

  const getCratePrice = () => {
    switch (type) {
      case AvatarRarityEnum.EXOTIC:
        return 4;
      case AvatarRarityEnum.RARE:
        return 2;
      case AvatarRarityEnum.GODLIKE:
        return 1.99;
      case AvatarRarityEnum.EMOJI:
        return 1.49;
      default:
        return null;
    }
  };

  const getCrateName = () => {
    switch (type) {
      case AvatarRarityEnum.EXOTIC:
        return "Exotic Crate";
      case AvatarRarityEnum.RARE:
        return "Rare Crate";
      case AvatarRarityEnum.GODLIKE:
        return "Godlike Crate";
      case AvatarRarityEnum.EMOJI:
        return "Emoji Crate";
      default:
        return null;
    }
  };

  const styles = {
    container: {
      borderRadius: 2,
      border: `3px solid ${getBorderColor()}`,
      boxSizing: "border-box",
      backgroundColor: getBackgroundColor(),
      transition: transition,
      cursor: hovered ? "pointer" : "cursor",
      transform: hovered ? "translateY(-5px)" : null,
      boxShadow: hovered ? menuShadow : null,
      position: "relative",
    },
    wordContainer: {
      width: "100%",
      padding: 1,
      backgroundColor: getBackgroundColor(),
      marginTop: "auto",
      position: "relative",
    },
    name: {
      fontSize: 18,
      color: "#fff",
      fontWeight: 800,
      textAlign: "center",
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Grid
        container
        direction="column"
        alignItems={isDesktop ? "start" : "center"}
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={getCrateImage()} draggable={false} height={200} />
        </Grid>

        <Grid item sx={styles.wordContainer}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={styles.name}>{getCrateName()}</Typography>
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                gap={{ xs: 0.5 }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RivoxCoin size={22} />
                </Grid>

                <Grid item>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "#fff",
                      fontWeight: 800,
                    }}
                  >
                    {numFormatter().format(getCratePrice())}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: red,
                      fontWeight: 800,
                      textDecoration: "line-through",
                    }}
                  >
                    <span style={{ color: secondaryText }}>
                      {numFormatter().format(getCratePrice() * 1.5)}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CrateItem;
