import "../styles/Spaz.css";

const Spaz = () => {
  return (
    <g>
      <g>
        <path
          class="spaz-7"
          d="m384.97,556.67l23.28,29.56-23.38,11.42-19.06-29.78c8.34-3.54,14.76-7.82,19.16-11.2Z"
        />
        <path
          class="spaz-7"
          d="m387.73,535.69c-.25.71-.82,1.99-2.08,3.12-2.09,1.86-4.58,1.98-5.57,1.94-1.64-1.89-3.22-3.75-4.61-5.55-1.95-1.91-3.94-3.83-5.98-5.74-.47-.44-.95-.88-1.42-1.32,1.36-.59,5.11-2.02,9.54-1.04,6.99,1.55,9.77,7.76,10.12,8.59Zm-7.04-2.72c.58-.99-.14-2.41-1.59-3.17-1.45-.77-3.08-.59-3.66.39-.57.98.14,2.4,1.58,3.17,1.45.77,3.1.59,3.67-.39Z"
        />
        <path
          class="spaz-7"
          d="m365.81,567.87l19.06,29.78-3.67,1.79-19.74-29.87c.42-.15.85-.31,1.28-.47,1.05-.4,2.08-.81,3.07-1.23Z"
        />
        <path
          class="spaz-9"
          d="m380.08,540.75c3.09,3.55,6.4,7.19,9.07,10.65-3.92,4.14-11.28,8.89-19.2,13.4-1.54.88-3.07,1.68-4.57,2.4-1.44.69-2.85,1.31-4.24,1.88-8.72,3.54-16.42,4.72-21.53,5.15-.22.02-.43.03-.64.05-5.34-4.1-10.63-8.15-15.87-12.18l-5.01-3.5h0c6.57-1.59,14.02-3.87,22.03-7.19-.05.32,0,.65.15.94l6.89,12.44c.4.72,1.32.94,2.04.5.73-.44,1-1.38.6-2.09l-6.91-12.44c-.08-.15-.2-.28-.33-.38,1.5-.65,3.02-1.33,4.55-2.06,1.87-.88,3.67-1.78,5.42-2.7l12.93,17.42,6.63-4.44-12.29-17.03-5.28-6.87-4.82-6.27-5.09-6.63-3.99-5.19-4.31-5.61-4.21-5.48-4.2-5.47-4.16-5.42h0l-4.11-5.36h0l-3.57-4.66h-.01l-3.93-5.12-4.06-5.29h-.01l-10.87-14.15h.01c2.98-.45,6-.91,9.2-1.55l49.39,58.79c4.03,3.39,8.14,7,12.31,10.85.47.44.95.88,1.42,1.32,2.04,1.91,4.03,3.83,5.98,5.74,1.39,1.8,2.97,3.66,4.61,5.55Zm-5.8,8.35c.65-.56.76-1.53.23-2.15l-5.1-6.02c-.28-.33-.68-.5-1.09-.5-.37,0-.74.13-1.04.39-.65.55-.76,1.51-.24,2.13l5.09,6.02c.52.63,1.49.68,2.15.13Zm-12.86-16.56c.65-.56.75-1.51.24-2.11l-4.97-5.86c-.27-.32-.66-.48-1.06-.48-.36,0-.73.13-1.03.39-.64.54-.75,1.49-.24,2.09l4.95,5.86c.52.61,1.46.66,2.11.11Zm-10.65-12.32c.64-.55.75-1.49.24-2.08l-4.86-5.73c-.26-.31-.64-.47-1.04-.47-.35,0-.72.13-1.02.39-.63.54-.74,1.47-.24,2.05l4.85,5.75c.5.59,1.43.63,2.07.09Zm-8.51-11.21c.63-.54.74-1.46.25-2.05l-4.78-5.63c-.25-.3-.62-.45-1.01-.45-.35,0-.72.13-1.02.39-.62.54-.74,1.45-.25,2.03l4.77,5.64c.49.58,1.41.61,2.04.07Zm-9.31-10.94c.62-.54.73-1.45.25-2.02l-4.69-5.53c-.25-.29-.61-.44-.99-.44-.35,0-.71.13-1.01.39-.62.54-.73,1.44-.25,2l4.67,5.53c.48.58,1.39.61,2.02.07Zm-8.2-10.8c.62-.54.73-1.44.26-2l-4.61-5.42c-.24-.29-.59-.43-.96-.43-.35,0-.71.13-1.01.39-.62.53-.73,1.42-.26,1.98l4.59,5.43c.48.56,1.37.58,1.99.05Zm-8.94-11.47c.61-.53.72-1.42.25-1.97l-4.51-5.31c-.24-.28-.58-.42-.94-.42s-.71.13-1.01.39c-.61.53-.72,1.4-.26,1.95l4.5,5.32c.47.55,1.35.57,1.97.04Z"
        />
        <path
          class="spaz-7"
          d="m361.46,569.57l19.74,29.87-11.35,5.55-20.51-31.54-.1-.51c3.71-.72,7.83-1.79,12.22-3.37Z"
        />
        <path d="m379.1,529.8c1.45.76,2.17,2.18,1.59,3.17-.57.98-2.22,1.16-3.67.39-1.44-.77-2.15-2.19-1.58-3.17.58-.98,2.21-1.16,3.66-.39Z" />
        <path d="m374.51,546.95c.53.62.42,1.59-.23,2.15-.66.55-1.63.5-2.15-.13l-5.09-6.02c-.52-.62-.41-1.58.24-2.13.3-.26.67-.39,1.04-.39.41,0,.81.17,1.09.5l5.1,6.02Z" />
        <path
          class="spaz-9"
          d="m359.8,541.57l12.29,17.03-6.63,4.44-12.93-17.42c2.55-1.33,4.97-2.69,7.27-4.05Z"
        />
        <path
          class="spaz-7"
          d="m349.34,573.45l20.51,31.54-6.83,3.33c-2.74-3.87-5.45-7.71-8.16-11.54-3.07-4.35-6.13-8.68-9.16-12.97-2.25-3.2-4.5-6.37-6.73-9.53.21-.02.42-.03.64-.05,2.73-.19,5.99-.57,9.63-1.29l.1.51Z"
        />
        <path
          class="spaz-7"
          d="m363.02,608.32l-21.99,13.55c-.6-1.06-2.04-3.93-1.66-7.78.56-5.66,4.64-8.94,5.32-9.47l10.17-7.84c2.71,3.83,5.42,7.67,8.16,11.54Z"
        />
        <path d="m361.66,530.43c.51.6.41,1.55-.24,2.11-.65.55-1.59.5-2.11-.11l-4.95-5.86c-.51-.6-.4-1.55.24-2.09.3-.26.67-.39,1.03-.39.4,0,.79.16,1.06.48l4.97,5.86Z" />
        <path
          class="spaz-5"
          d="m312.1,479.49l3.93,5.12h.01l3.57,4.66h0l4.11,5.36h0l4.16,5.42,4.2,5.47,4.21,5.48,4.31,5.61,3.99,5.19,5.09,6.63,4.82,6.27,5.28,6.87c-2.3,1.36-4.72,2.72-7.27,4.05-1.75.92-3.55,1.82-5.42,2.7-1.53.73-3.05,1.41-4.55,2.06-.25-.22-.57-.33-.89-.33-.27,0-.55.08-.8.23-.41.25-.68.68-.75,1.13-8.01,3.32-15.46,5.6-22.03,7.18h0s-16.5-11.55-16.5-11.55c-.55-.85-1.43-2.49-1.68-4.8-.29-2.81.52-5.08.95-6.1-3.96-7.42-7.87-14.78-11.74-22.05-1.4-.73-3.46-2.06-5.28-4.39-1.27-1.64-2.03-3.27-2.5-4.51-1.42-.76-2.9-1.59-4.39-2.52-2.25-1.41-4.28-2.84-6.06-4.24-9.49-21.73-13.14-33.02-11.19-34.34.09.08.3.28.63.54.03.06.07.12.11.17h0c-.36,2.5.23,3.83.68,4.5,2.97,4.5,14.34,2.76,20.51-3.4,1.49-1.49,2.41-2.97,2.88-3.82,4.4-.88,8.48-1.43,12.65-2.03l10.87,14.15h.01l4.06,5.29Zm-24.96,29.79c.7-.37.88-1.6.39-2.73-.49-1.14-1.46-1.75-2.16-1.38-.7.38-.87,1.6-.39,2.73.49,1.14,1.45,1.76,2.16,1.38Z"
        />
        <path
          class="spaz-7"
          d="m354.86,596.78l-10.17,7.84c-.53-.15-2.63-.84-3.97-3.09-1.36-2.31-.97-4.65-.86-5.19,1.95-4.18,3.9-8.36,5.84-12.53,3.03,4.29,6.09,8.62,9.16,12.97Z"
        />
        <path d="m351.01,518.14c.51.59.4,1.53-.24,2.08-.64.54-1.57.5-2.07-.09l-4.85-5.75c-.5-.58-.39-1.51.24-2.05.3-.26.67-.39,1.02-.39.4,0,.78.16,1.04.47l4.86,5.73Z" />
        <path d="m349.8,563.2c.4.71.13,1.65-.6,2.09-.72.44-1.64.22-2.04-.5l-6.89-12.44c-.16-.29-.2-.62-.15-.94.81-.32,1.62-.67,2.44-1.03.13.1.25.23.33.38l6.91,12.44Z" />
        <path d="m342.51,506.96c.49.59.38,1.51-.25,2.05s-1.55.51-2.04-.07l-4.77-5.64c-.49-.58-.37-1.49.25-2.03.3-.26.67-.39,1.02-.39.39,0,.76.15,1.01.45l4.78,5.63Z" />
        <path d="m341.67,550.05c.32,0,.64.11.89.33-.82.36-1.63.71-2.44,1.03.07-.45.34-.88.75-1.13.25-.15.53-.23.8-.23Z" />
        <path d="m333.2,496.05c.48.57.37,1.48-.25,2.02-.63.54-1.54.51-2.02-.07l-4.67-5.53c-.48-.56-.37-1.46.25-2,.3-.26.66-.39,1.01-.39.38,0,.74.15.99.44l4.69,5.53Z" />
        <path d="m325.01,485.27c.47.56.36,1.46-.26,2-.62.53-1.51.51-1.99-.05l-4.59-5.43c-.47-.56-.36-1.45.26-1.98.3-.26.66-.39,1.01-.39.37,0,.72.14.96.43l4.61,5.42Z" />
        <path d="m316.06,473.83c.47.55.36,1.44-.25,1.97-.62.53-1.5.51-1.97-.04l-4.5-5.32c-.46-.55-.35-1.42.26-1.95.3-.26.66-.39,1.01-.39s.7.14.94.42l4.51,5.31Z" />
        <path
          class="spaz-8"
          d="m297.35,453.92l9.02,4.58c-3.2.64-6.22,1.1-9.2,1.55h-.01l-.33-.43c-1.27-1.78-2.54-3.56-3.8-5.33l4.32-.37Z"
        />
        <path
          class="spaz-8"
          d="m296.83,459.62l.33.43c-4.17.6-8.25,1.15-12.65,2.03-.47.85-1.39,2.33-2.88,3.82-6.17,6.16-17.54,7.9-20.51,3.4-.45-.67-1.04-2-.68-4.49.13.19.3.36.49.51.37.17.82.36,1.34.53,1.8.91,4.45,1.67,7.78.96,3.72-.8,6.38-3.03,8.05-4.94.01,0,.02-.02.03-.04,1.36-1.17,2.23-2.21,2.52-2.56l.39-.32,2.57,3.2,13.22-2.53Z"
        />
        <path
          class="spaz-8"
          d="m293.03,454.29c1.26,1.77,2.53,3.55,3.8,5.33l-13.22,2.53-2.57-3.2-17.9-22.27c1.32.09,3.12.05,5.22-.49,3.97-1.02,6.88-3.25,8.44-4.68,5.34,7.49,10.75,15.09,16.23,22.78Z"
        />
        <path
          class="spaz-8"
          d="m289.83,428.03c.32.85-.42,2.34-1.92,2.81-2.42,1.39-4.85,2.79-7.27,4.18-.78-1.67-1.57-3.33-2.35-4.99,2.97-.87,5.94-1.74,8.92-2.61,1.2-.51,2.33-.11,2.62.61Z"
        />
        <path d="m287.53,506.55c.49,1.13.31,2.36-.39,2.73-.71.38-1.67-.24-2.16-1.38-.48-1.13-.31-2.35.39-2.73.7-.37,1.67.24,2.16,1.38Z" />
        <path
          class="spaz-8"
          d="m275.88,457.44c1.41.54,2.83,1.08,4.24,1.63-.27.46-.92,1.55-1.99,2.76-.01.02-.02.03-.03.04-2.24,1.95-5.8,4.3-10.24,4.67-2.29.19-4.2-.21-5.59-.69-.84-.41-1.5-.87-1.94-1.22-.32-.57-.36-1.27-.11-1.91.37-.94,1.25-1.37,1.4-1.44,1.34.36,5.19,1.19,9.68-.8,2.04-.9,3.57-2.11,4.58-3.04Z"
        />
        <path
          class="spaz-8"
          d="m274.86,427.18c.83,1.27,1.66,2.54,2.5,3.81-.16.16-.35.33-.56.52-.1-.15-.21-.29-.31-.44-.9.93-2.11,2-3.66,2.96-4.08,2.52-7.95,2.69-9.69,2.65l-3.12-4.01c1.41.17,3.87.26,6.86-.67,3.97-1.23,6.7-3.57,7.98-4.82Z"
        />
        <path
          class="spaz-8"
          d="m268.2,447.52l7.68,9.92c-1.01.93-2.54,2.14-4.58,3.04-4.49,1.99-8.34,1.16-9.68.8-1.76-2.9-3.51-5.81-5.24-8.68.83,1.12,3.47,1.26,6.41.24,3.37-1.18,5.84-3.44,5.5-5.04-.02-.1-.05-.19-.09-.28Z"
        />
        <path
          class="spaz-8"
          d="m272.8,424.06c.69,1.04,1.37,2.08,2.06,3.12-1.28,1.25-4.01,3.59-7.98,4.82-2.99.93-5.45.84-6.86.67l-2.6-3.34c2.46-.11,6.28-.62,10.65-2.61,1.83-.83,3.41-1.77,4.73-2.66Z"
        />
        <path
          class="spaz-8"
          d="m269.53,419.09c1.1,1.65,2.18,3.31,3.27,4.97-1.32.89-2.9,1.83-4.73,2.66-4.37,1.99-8.19,2.5-10.65,2.61l-2.92-3.74c1.58.08,4.18-.04,7.27-1.23,3.88-1.5,6.49-3.92,7.76-5.27Z"
        />
        <path d="m269.4,418.88c.13-.02.26-.03.38-.05-.08.08-.16.17-.25.26-.04-.07-.08-.14-.13-.21Z" />
        <path d="m269.4,418.88c.05.07.09.14.13.21-1.27,1.35-3.88,3.77-7.76,5.27-3.09,1.19-5.69,1.31-7.27,1.23l-.05-.06c.54-.5,1.23-1.09,2.07-1.72,1.5-.98,3.33-2,5.46-2.88,2.91-1.2,5.48-1.77,7.42-2.05Z" />
        <path
          class="spaz-8"
          d="m268.29,447.8c.34,1.6-2.13,3.86-5.5,5.04-2.94,1.02-5.58.88-6.41-.24-.04-.07-.09-.14-.13-.21,0-.01-.01-.01-.01-.02-.05-.1-.09-.21-.11-.32-.33-1.6,2.12-3.85,5.47-5.02,3.14-1.11,5.98-.88,6.6.49.04.09.07.18.09.28Zm-5.6,2.38c1.07-.37,1.87-.96,1.8-1.3s-.99-.32-2.06.05c-1.06.38-1.86.96-1.79,1.3s.99.32,2.05-.05Z"
        />
        <path d="m264.49,448.88c.07.34-.73.93-1.8,1.3-1.06.37-1.98.39-2.05.05s.73-.92,1.79-1.3c1.07-.37,1.99-.39,2.06-.05Z" />
        <path d="m256.52,423.81c-.84.63-1.53,1.22-2.07,1.72l.05.06c-.16,0-.31-.01-.45-.02.69-.54,1.52-1.14,2.47-1.76Z" />
      </g>
      <path
        class="spaz-2"
        d="m267.35,472.14c3,6.67,6.52,13.67,10.65,20.86,1.57,2.72,3.15,5.35,4.74,7.88,2.01,2.29,4.41,4.71,7.26,7.12,1.3,1.1,2.58,2.09,3.83,3,4.18,8.41,8.36,16.83,12.54,25.24,1.04,2.93,2.98,7.31,6.63,11.76,3.84,4.69,7.98,7.52,10.74,9.11-1.1.44-2.73.92-4.74.89-.77-.01-1.46-.1-2.07-.22-5.27-3.85-10.53-7.69-15.8-11.54l-.13-10.25-11.61-22.02c-1.13-.5-2.79-1.4-4.39-2.98-2.2-2.16-3.21-4.5-3.66-5.81-3.49-2.25-6.97-4.5-10.46-6.75l-10.83-29.04,7.29,2.75Z"
      />
      <path
        class="spaz-1"
        d="m264,438c.95.25,4.92,1.18,9-1,2.97-1.58,4.44-3.97,5-5-1.68,1.26-4.01,2.76-7,4-2.66,1.1-5.08,1.68-7,2Z"
      />
      <path
        class="spaz-1"
        d="m261,434c.95.25,4.92,1.18,9-1,2.97-1.58,4.44-3.97,5-5-1.68,1.26-4.01,2.76-7,4-2.66,1.1-5.08,1.68-7,2Z"
      />
      <path
        class="spaz-1"
        d="m258,429.62c1.02.24,5.27,1.1,9.64-.94,3.18-1.48,4.76-3.72,5.36-4.68-1.8,1.18-4.29,2.58-7.5,3.74-2.85,1.03-5.44,1.57-7.5,1.87Z"
      />
      <path
        class="spaz-1"
        d="m254,425.62c1.02.24,5.27,1.1,9.64-.94,3.18-1.48,4.76-3.72,5.36-4.68-1.8,1.18-4.29,2.58-7.5,3.74-2.85,1.03-5.44,1.57-7.5,1.87Z"
      />
      <polygon
        class="spaz-1"
        points="266 451 271.89 460.21 275.69 457.19 269.5 448.64 266 451"
      />
      <path
        class="spaz-1"
        d="m261,462c.64.37,4.98,2.79,10,1,3.73-1.33,5.6-4.19,6.14-5.08-1.7,1.03-4.1,2.24-7.14,3.08-3.64,1.01-6.81,1.11-9,1Z"
      />
      <path
        class="spaz-1"
        d="m260.63,466.59c-.1.43,3.94,2.67,8.37,2.41,7.89-.46,13.2-8.65,12.86-9.03-.31-.36-5.39,6.28-13.86,7.03-4.07.36-7.28-.78-7.37-.41Z"
      />
      <polygon
        class="spaz-6"
        points="350.54 572.48 371.43 604.99 369.85 605.3 349.34 572.75 350.54 572.48"
      />
      <polygon
        class="spaz-6"
        points="342.22 574.28 366 609 363.87 610.07 339.31 574.25 342.22 574.28"
      />
      <polygon
        class="spaz-6"
        points="361.46 569.57 382 601 384 600 363.02 568.99 361.46 569.57"
      />
      <polygon
        class="spaz-6"
        points="380.22 565.55 373.61 569.57 387.57 590.15 395.74 585.03 380.22 565.55"
      />
      <polygon
        class="spaz-6"
        points="366 567.78 385.64 598.72 387.22 597.65 367 567.2 366 567.78"
      />
      <polygon
        class="spaz-6"
        points="348 587 341 602 339.86 596.34 346.02 584.26 348 587"
      />
      <path
        class="spaz-6"
        d="m355.72,598.72c-2.85,1.89-8.2,6.03-11.72,13.28-1.82,3.76-2.61,7.25-2.97,9.87-.56-1.37-1.71-4.67-1.03-8.87.63-3.9,2.52-6.54,3.46-7.7,3.85-2.77,7.7-5.54,11.54-8.3l.72,1.72Z"
      />
      <path
        class="spaz-2"
        d="m359.8,541.57c-6.78,3.54-14.38,7.1-22.8,10.43-6.62,2.62-12.95,4.79-18.91,6.59"
      />
      <polygon
        class="spaz-2"
        points="386.43 532.92 378.07 538.4 380.37 541.08 388 536 386.43 532.92"
      />
      <path
        class="spaz-10"
        d="m302.15,459.96l51.89,60.95,32.22,33.17,2.9-2.67c-3.87-5.14-8.55-10.72-14.15-16.4-7.15-7.25-14.19-13.04-20.48-17.61-16.05-19.63-32.1-39.26-48.15-58.89l-4.22,1.46Z"
      />
      <polygon
        class="spaz-10"
        points="352.53 545.62 365.46 563.04 372.09 558 360 541 352.53 545.62"
      />
      <g>
        <path
          class="spaz-4"
          d="m363.02,608.32l45.24-22.1-23.29-29.56c-4.92,3.79-12.38,8.71-22.23,12.43-9.57,3.62-17.93,4.81-23.77,5.18,7.88,11.15,15.89,22.5,24.05,34.05Z"
        />
        <path
          class="spaz-4"
          d="m260.09,463.96c-.15.01-.28.05-.39.13-1.95,1.33,1.71,12.61,11.19,34.35,1.79,1.4,3.81,2.83,6.06,4.23,1.5.94,2.97,1.77,4.4,2.52.46,1.25,1.22,2.88,2.5,4.51,1.82,2.33,3.88,3.66,5.28,4.39,3.87,7.27,7.78,14.63,11.74,22.05-.43,1.01-1.25,3.29-.95,6.1.25,2.31,1.13,3.95,1.68,4.8l16.5,11.56,5,3.5c5.24,4.03,10.53,8.08,15.87,12.18,6.68-.49,18.35-2.28,30.98-9.47,7.92-4.51,15.28-9.26,19.2-13.4-4.09-5.3-9.67-11-13.68-16.21-1.95-1.91-3.95-3.82-5.98-5.74-4.65-4.35-9.24-8.4-13.73-12.17l-49.39-58.79c-3.19.64-6.21,1.1-9.2,1.55-4.17.6-8.26,1.16-12.66,2.03-.47.85-1.39,2.33-2.88,3.82-6.17,6.17-17.54,7.91-20.51,3.4-.45-.67-1.04-2-.68-4.5,0-.03.01-.05.01-.07"
        />
        <path
          class="spaz-4"
          d="m263.14,436.68l20.47,25.47,13.22-2.54c-6.89-9.67-13.67-19.18-20.35-28.54-.9.93-2.1,1.99-3.65,2.95-4.09,2.53-7.95,2.7-9.69,2.65Z"
        />
        <path
          class="spaz-4"
          d="m363.02,608.32l-21.99,13.55c-.6-1.06-2.04-3.94-1.66-7.78.56-5.67,4.64-8.95,5.32-9.47,3.39-2.61,6.78-5.23,10.17-7.84l-10.17,7.84c-.53-.15-2.63-.84-3.97-3.09-1.36-2.3-.97-4.64-.87-5.19,1.95-4.18,3.9-8.36,5.85-12.53"
        />
        <path
          class="spaz-4"
          d="m278.29,430.03c2.96-.87,5.94-1.74,8.92-2.61,1.2-.52,2.33-.12,2.62.61.33.85-.42,2.34-1.91,2.8-2.43,1.4-4.85,2.79-7.27,4.18l-2.35-4.99Z"
        />
        <path
          class="spaz-4"
          d="m263.14,436.68l-8.7-11.15c1.49-1.38,4.19-3.57,7.99-5.07,2.83-1.12,5.29-1.47,6.96-1.57,2.64,4.01,5.29,8.04,7.97,12.1-1.36,1.32-4.49,4.04-9,5.2-2.1.54-3.9.58-5.22.49Z"
        />
        <path
          class="spaz-4"
          d="m368.07,528.14c1.36-.59,5.1-2.02,9.54-1.04,6.99,1.55,9.77,7.77,10.12,8.59-.25.71-.82,1.99-2.08,3.12-2.41,2.15-5.37,1.97-5.91,1.93"
        />
        <polyline
          class="spaz-4"
          points="293.03 454.29 297.35 453.92 306.37 458.5"
        />
        <path
          class="spaz-4"
          d="m268.29,447.8c.34,1.61-2.13,3.87-5.5,5.04s-6.34.82-6.67-.79,2.12-3.85,5.47-5.02,6.36-.84,6.69.77Z"
        />
        <path
          class="spaz-4"
          d="m268.2,447.52l7.68,9.92c-1.01.93-2.54,2.14-4.58,3.04-4.49,1.99-8.34,1.16-9.68.8-1.8-2.98-3.6-5.95-5.38-8.91"
        />
        <path
          class="spaz-4"
          d="m280.23,458.86c-.26.49-3.53,6.53-10.18,7.96-6.04,1.3-9.89-2.28-10.35-2.73"
        />
        <path d="m315.81,475.8h0c-.61.53-1.49.51-1.96-.04l-4.51-5.32c-.46-.55-.34-1.42.26-1.95h0c.61-.53,1.48-.51,1.95.03l4.52,5.31c.47.55.35,1.44-.26,1.97Z" />
        <path d="m324.75,487.27h0c-.62.53-1.51.51-1.99-.05l-4.59-5.43c-.47-.56-.35-1.45.26-1.98h0c.61-.53,1.5-.51,1.97.04l4.61,5.42c.48.56.36,1.46-.26,2Z" />
        <path d="m332.95,498.07h0c-.62.54-1.53.51-2.02-.06l-4.67-5.53c-.48-.57-.36-1.47.26-2.01h0c.62-.54,1.52-.51,2,.06l4.69,5.52c.49.57.37,1.48-.25,2.02Z" />
        <path d="m342.26,509.01h0c-.63.54-1.55.51-2.05-.08l-4.76-5.64c-.49-.58-.38-1.49.25-2.03h0c.63-.54,1.54-.51,2.03.07l4.78,5.63c.5.58.38,1.51-.25,2.05Z" />
        <path d="m350.77,520.22h0c-.64.55-1.57.51-2.07-.09l-4.85-5.74c-.5-.59-.39-1.52.25-2.06h0c.63-.54,1.56-.51,2.06.08l4.86,5.74c.5.59.4,1.53-.24,2.08Z" />
        <path d="m361.42,532.54h0c-.65.55-1.59.5-2.11-.1l-4.95-5.87c-.51-.6-.4-1.54.24-2.09h0c.64-.55,1.58-.51,2.09.09l4.97,5.86c.52.61.41,1.56-.24,2.11Z" />
        <path d="m374.28,549.1h0c-.66.56-1.62.5-2.15-.12l-5.08-6.03c-.52-.62-.42-1.58.23-2.13h0c.65-.55,1.61-.5,2.13.11l5.1,6.02c.53.62.43,1.59-.23,2.15Z" />
        <path d="m349.2,565.29h0c-.73.44-1.64.21-2.04-.5l-6.89-12.45c-.39-.7-.12-1.63.6-2.07h0c.72-.44,1.62-.22,2.01.48l6.92,12.44c.4.71.13,1.66-.6,2.1Z" />
        <path
          class="spaz-3"
          d="m296.83,459.62l62.96,81.95c-3.87,2.29-8.1,4.58-12.68,6.75-10.68,5.06-20.58,8.24-29.02,10.27"
        />
        <path
          class="spaz-3"
          d="m257.42,429.33c2.47-.11,6.29-.62,10.65-2.61,2.15-.98,3.94-2.09,5.37-3.12"
        />
        <path
          class="spaz-3"
          d="m260.02,432.67c1.42.17,3.87.26,6.86-.67,4.17-1.29,6.98-3.83,8.17-5.02"
        />
        <path d="m264.49,448.88c.07.34-.74.93-1.8,1.3s-1.98.4-2.05.05.73-.92,1.8-1.3,1.99-.4,2.06-.05Z" />
        <line class="spaz-3" x1="361.14" y1="569.08" x2="381.2" y2="599.44" />
        <line class="spaz-3" x1="365.38" y1="567.2" x2="384.87" y2="597.65" />
        <polyline
          class="spaz-3"
          points="352.53 545.62 365.46 563.04 372.09 558.6 359.8 541.57"
        />
        <line class="spaz-3" x1="349.34" y1="573.45" x2="369.85" y2="604.99" />
        <polyline
          class="spaz-3"
          points="380.22 565.55 395.74 585.03 387.27 590.34"
        />
        <line class="spaz-3" x1="283.32" y1="484.79" x2="308.04" y2="474.2" />
        <line class="spaz-3" x1="286.67" y1="490.06" x2="312.4" y2="479.36" />
        <line class="spaz-3" x1="288.26" y1="494.56" x2="316.04" y2="484.61" />
        <line class="spaz-3" x1="294.42" y1="499.74" x2="319.62" y2="489.27" />
        <line class="spaz-3" x1="298.59" y1="504.18" x2="323.74" y2="494.63" />
        <line class="spaz-3" x1="303.68" y1="507.96" x2="327.9" y2="500.05" />
        <line class="spaz-3" x1="308.66" y1="513.98" x2="332.1" y2="505.52" />
        <line class="spaz-3" x1="312.2" y1="519.52" x2="336.31" y2="511" />
        <line class="spaz-3" x1="318.28" y1="525.2" x2="340.62" y2="516.61" />
        <line class="spaz-3" x1="322.44" y1="531.34" x2="344.61" y2="521.8" />
        <line class="spaz-3" x1="325.97" y1="536.78" x2="349.7" y2="528.43" />
        <line class="spaz-3" x1="332.95" y1="543.94" x2="354.52" y2="534.7" />
        <path d="m287.53,506.55c.49,1.14.31,2.36-.39,2.74s-1.67-.24-2.16-1.38-.31-2.36.39-2.73,1.67.24,2.15,1.37Z" />
        <path d="m254.05,425.57c1.5.11,4.32.11,7.72-1.21,4.15-1.6,6.85-4.26,8.01-5.53-1.98.26-4.69.82-7.79,2.1-3.49,1.44-6.16,3.25-7.94,4.64Z" />
        <path
          class="spaz-4"
          d="m261.62,461.28c-.15.07-1.02.5-1.4,1.44-.37.93-.1,1.99.71,2.6,1.38.65,3.79,1.48,6.93,1.22,7.15-.59,12.01-6.32,12.79-7.27-1.59-.61-3.19-1.22-4.77-1.83"
        />
        <path d="m380.69,532.97c-.57.99-2.22,1.16-3.67.39s-2.15-2.18-1.58-3.17,2.21-1.16,3.66-.39,2.16,2.19,1.59,3.17Z" />
      </g>
    </g>
  );
};

export default Spaz;
