import { AccountTypeEnum } from "../../utils/enums";

export const isCreatingTeam = (match, user_id) => {
  return match?.creating_team_ids?.includes(user_id);
};

export const isJoiningTeam = (match, user_id) => {
  return match?.joining_team_ids?.includes(user_id);
};

export const isStaff = (accountType) => {
  return accountType >= AccountTypeEnum.MODERATOR;
};

export const isInMatch = (match, user_id) => {
  const allUsers = match?.creating_team_ids?.concat(match?.joining_team_ids);
  return allUsers?.includes(user_id);
};
