const Katana = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#3d3b3b",
    },
    three: {
      fill: "#dbd9d9",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "none",
      strokeWidth: 2,
    },
    five: {
      strokeMiterLimit: 10,
      fill: "none",
      strokeWidth: 2,
      stroke: "#515050",
    },
    six: {
      fill: "#eae8e8",
    },
  };

  return (
    <g>
      <g>
        <g>
          <path
            style={styles.two}
            d="M290.01,441.24c-.44,.8-1.17,1.38-2.06,1.54-6.82,1.24-13.63,2.48-20.45,3.72-1.38,.11-2.64-.75-3-2-.31-1.07,.08-2.27,1-3l20-11c5.05-3.17,12.46-6.93,22-9,4.41-.95,8.45-1.37,11.99-1.5h.51s-.01-.03-.01-.04c5.1-.44,10.2-.9,15.3-1.35,1.26-.11,2.53,.53,3.01,1.69,.15,.39,.22,.79,.2,1.2-.06,1.1-.8,1.82-1,2l-13.3,7.6c-.46,.26-.98,.4-1.51,.4h-1.98c-.14,0-.28,.01-.41,.03-3.35,.43-6.97,1.06-10.8,1.97-6.75,1.6-12.63,3.69-17.57,5.81-.32,.14-.61,.32-.86,.56-.19,.18-.38,.39-.57,.63-.19,.25-.36,.5-.49,.74Z"
          />
          <path
            style={styles.two}
            d="M260.51,290.43l9-10-13.43-4.08-9.57,11.08,14,3Zm.57,2.92l-9.57,11.08,14,3,9-10-13.43-4.08Zm-2.08-63.35c20.33,63.32,40.66,126.64,60.99,189.96-.13,.02-.27,.03-.4,.04h-.1c-4.34,.03-10.27,.53-16.99,2.5-7.5,2.21-13.22,5.45-17,8-20.22-63.69-40.45-127.39-60.67-191.08,1.27-9.98,8.98-17.39,17.67-17.92,9.87-.6,16.13,7.97,16.5,8.5Zm42.51,186.47l9-10-13.43-4.07-9.57,11.07,14,3Zm-6-17l9-10-13.43-4.07-9.57,11.07,14,3Zm-5-18l9-10-13.43-4.07-9.57,11.07,14,3Zm-7-18.56l9-10-13.43-4.08-9.57,11.08,14,3Zm-5-19l9-10-13.43-4.08-9.57,11.08,14,3Zm-6-17.48l9-10-13.43-4.08-9.57,11.08,14,3Zm-18-55l9-10-13.43-4.08-9.57,11.08,14,3Zm-6.01-17.93l9-10-13.43-4.08-9.57,11.08,14,3Z"
          />
          <path
            style={styles.three}
            d="M322.69,431.5l61.62,167.87-27.33,14.67-66.97-172.8c.13-.24,.3-.49,.49-.74,.19-.24,.38-.45,.57-.63,.25-.24,.54-.42,.86-.56,4.94-2.12,10.82-4.21,17.57-5.81,3.83-.91,7.45-1.54,10.8-1.97,.13-.02,.27-.03,.41-.03h1.98Z"
          />
          <polygon
            style={styles.one}
            points="310.51 406.47 301.51 416.47 287.51 413.47 297.08 402.4 310.51 406.47"
          />
          <polygon
            style={styles.one}
            points="304.51 389.47 295.51 399.47 281.51 396.47 291.08 385.4 304.51 389.47"
          />
          <polygon
            style={styles.one}
            points="299.51 371.47 290.51 381.47 276.51 378.47 286.08 367.4 299.51 371.47"
          />
          <polygon
            style={styles.one}
            points="292.51 352.91 283.51 362.91 269.51 359.91 279.08 348.83 292.51 352.91"
          />
          <polygon
            style={styles.one}
            points="287.51 333.91 278.51 343.91 264.51 340.91 274.08 329.83 287.51 333.91"
          />
          <polygon
            style={styles.one}
            points="281.51 316.43 272.51 326.43 258.51 323.43 268.08 312.35 281.51 316.43"
          />
          <polygon
            style={styles.one}
            points="274.51 297.43 265.51 307.43 251.51 304.43 261.08 293.35 274.51 297.43"
          />
          <polygon
            style={styles.one}
            points="269.51 280.43 260.51 290.43 246.51 287.43 256.08 276.35 269.51 280.43"
          />
          <polygon
            style={styles.one}
            points="263.51 261.43 254.51 271.43 240.51 268.43 250.08 257.35 263.51 261.43"
          />
          <polygon
            style={styles.one}
            points="257.5 243.5 248.5 253.5 234.5 250.5 244.07 239.42 257.5 243.5"
          />
        </g>
        <g>
          <path
            style={styles.four}
            d="M319.59,420h.41s-.01-.03-.01-.04c-20.33-63.32-40.66-126.64-60.99-189.96-.37-.53-6.63-9.1-16.5-8.5-8.69,.53-16.4,7.94-17.67,17.92,20.22,63.69,40.45,127.39,60.67,191.08,3.78-2.55,9.5-5.79,17-8,6.72-1.97,12.65-2.47,16.99-2.5"
          />
          <path
            style={styles.four}
            d="M285.5,430.5l-20,11c-.92,.73-1.31,1.93-1,3,.36,1.25,1.62,2.11,3,2,6.82-1.24,13.63-2.48,20.45-3.72,.89-.16,1.62-.74,2.06-1.54,.13-.24,.3-.49,.49-.74,.19-.24,.38-.45,.57-.63,.25-.24,.54-.42,.86-.56,4.94-2.12,10.82-4.21,17.57-5.81,3.83-.91,7.45-1.54,10.8-1.97,.13-.02,.27-.03,.41-.03h1.98c.53,0,1.05-.14,1.51-.4l13.3-7.6c.2-.18,.94-.9,1-2,.02-.41-.05-.81-.2-1.2-.48-1.16-1.75-1.8-3.01-1.69-5.1,.45-10.2,.91-15.3,1.35-.13,.02-.27,.03-.4,.04h-.1c-3.54,.13-7.58,.55-11.99,1.5-9.54,2.07-16.95,5.83-22,9Z"
          />
          <polyline
            style={styles.four}
            points="290.01 441.24 356.98 614.04 384.31 599.37 322.69 431.5"
          />
        </g>
      </g>
      <polygon
        style={styles.six}
        points="309.5 433.5 372.87 605.15 384.31 599.37 322.69 431.5 309.5 433.5"
      />
      <path
        style={styles.five}
        d="M264.5,443.5c8-4.33,16-8.67,24-13,4.11-2.4,9.83-5.15,17-7,4.89-1.26,9.31-1.8,13-2,4.99-.27,6.85-.89,8-1,4.84-.45,8.82-.52,11.5-.5"
      />
      <polyline
        style={styles.five}
        points="228.83 248.88 268 267 246.16 303.45 286 319 260.57 348.83 301 365 272.95 387.83 314 407 286.15 429.41"
      />
      <g>
        <path
          style={styles.four}
          d="M224.83,239.42c20.22,63.69,40.45,127.39,60.67,191.08,3.78-2.55,9.5-5.79,17-8,6.98-2.05,13.11-2.51,17.5-2.5-20.33-63.33-40.67-126.67-61-190-.37-.53-6.63-9.1-16.5-8.5-8.69,.53-16.4,7.94-17.67,17.92Z"
        />
        <path
          style={styles.four}
          d="M285.5,430.5l-20,11c-.92,.73-1.31,1.93-1,3,.36,1.25,1.62,2.11,3,2,6.82-1.24,13.63-2.48,20.45-3.72,.89-.16,1.63-.75,2.07-1.54,.13-.24,.29-.49,.49-.74,.19-.24,.38-.45,.57-.63,.25-.24,.54-.42,.86-.56,4.94-2.13,10.83-4.22,17.57-5.81,3.83-.91,7.45-1.54,10.8-1.97,.14-.02,.27-.03,.41-.03h1.99c.53,0,1.05-.14,1.51-.4l13.3-7.6c.2-.18,.94-.9,1-2,.02-.41-.05-.81-.2-1.2-.47-1.17-1.75-1.81-3-1.7-5.24,.46-10.47,.93-15.71,1.39-3.55,.12-7.64,.53-12.09,1.5-9.54,2.07-16.95,5.83-22,9Z"
        />
        <polyline
          style={styles.four}
          points="290.01 441.24 356.98 614.04 384.31 599.37 322.69 431.5"
        />
        <polygon
          style={styles.one}
          points="244.07 239.42 234.5 250.5 248.5 253.5 257.5 243.5 244.07 239.42"
        />
        <polygon
          style={styles.one}
          points="250.08 257.35 240.51 268.43 254.51 271.43 263.51 261.43 250.08 257.35"
        />
        <polygon
          style={styles.one}
          points="256.08 276.35 246.51 287.43 260.51 290.43 269.51 280.43 256.08 276.35"
        />
        <polygon
          style={styles.one}
          points="261.08 293.35 251.51 304.43 265.51 307.43 274.51 297.43 261.08 293.35"
        />
        <polygon
          style={styles.one}
          points="268.08 312.35 258.51 323.43 272.51 326.43 281.51 316.43 268.08 312.35"
        />
        <polygon
          style={styles.one}
          points="274.08 329.83 264.51 340.91 278.51 343.91 287.51 333.91 274.08 329.83"
        />
        <polygon
          style={styles.one}
          points="279.08 348.83 269.51 359.91 283.51 362.91 292.51 352.91 279.08 348.83"
        />
        <polygon
          style={styles.one}
          points="286.08 367.4 276.51 378.47 290.51 381.47 299.51 371.47 286.08 367.4"
        />
        <polygon
          style={styles.one}
          points="291.08 385.4 281.51 396.47 295.51 399.47 304.51 389.47 291.08 385.4"
        />
        <polygon
          style={styles.one}
          points="297.08 402.4 287.51 413.47 301.51 416.47 310.51 406.47 297.08 402.4"
        />
      </g>
    </g>
  );
};

export default Katana;
