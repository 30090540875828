import "./styles/England.css";

const England = () => {
  return (
    <g>
      <defs>
        <linearGradient
          id="england-gradient"
          x1="316.59"
          y1="664.79"
          x2="456.91"
          y2="593.87"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" />
          <stop offset=".18" stop-color="#9adaea" />
          <stop offset=".62" stop-color="#006ab7" />
          <stop offset="1" stop-color="#003570" />
        </linearGradient>
        <linearGradient
          id="england-gradient-2"
          x1="183.92"
          y1="660.36"
          x2="315.76"
          y2="593.73"
          gradientTransform="translate(819.53) rotate(-180) scale(1 -1)"
          xlinkHref="#england-gradient"
        />
      </defs>
      <g id="England_Jersey" data-name="England Jersey">
        <path
          class="wc-england-6"
          d="m673.05,774.99c-10.77,9.78-27.1,22.99-49.05,35.01-33.44,18.31-66.72,26.66-95.49,29.93-30.81,3.51-56.43,1.19-71.51-.93-6.61-.93-12.97-2.07-19.07-3.37-42.6-9.09-72.54-26.03-84.93-33.63-30.34-18.6-51.24-39.78-64.22-55.07-.07-18.76,1.61-41.2,7.22-65.93,2.37-10.43,5.16-20.08,8.15-28.91,22.39-19.49,54.11-42.4,95.85-60.09,9.39-3.98,18.52-7.36,27.3-10.26,2.25-.74,4.47-1.45,6.67-2.13.6-.19,1.19-.37,1.78-.53h.03c.75-.25,1.49-.47,2.23-.68,3.63-1.08,7.19-2.07,10.68-2.98-.23.26-.46.53-.7.79-.18.21-.36.41-.54.62-.98,1.11-1.96,2.21-2.94,3.32-.08.1-.17.19-.26.29-.49.56-.98,1.13-1.48,1.69-.26.29-.51.58-.77.87-.35,1.67-.65,4.18,0,7,.15.66.34,1.29.58,1.9,1.12,2.97,3.14,5.38,5.51,7.36h0c1.23,1.04,2.57,1.95,3.92,2.77.36.22.73.43,1.09.63.02.01.04.02.06.04,1.07.59,2.13,1.13,3.15,1.63,1.32.63,2.57,1.18,3.68,1.67.44.2.89.39,1.33.56.02,0,.03.01.05.02,12.36,5.05,23.37,3.72,25.62,3.42,1.19-.16,2.4-.37,3.63-.62.01,0,.03,0,.04-.01,1.05-.21,2.12-.46,3.19-.74.04-.02.07-.02.1-.03,3.17-.84,6.34-1.94,9.31-3.24.48-.2.95-.42,1.41-.63.15-.07.3-.14.44-.21,2.34-1.11,4.51-2.37,6.42-3.7,2.09-1.45,3.85-3.03,5.13-4.65.02-.02.03-.04.04-.06,1.56-1.98,2.4-4.04,2.29-6.11-.08-1.3-.57-3.29-2.84-5.66-.28-.29-.59-.59-.93-.89-.52-.48-1.11-.96-1.79-1.45-.32-.25-.67-.49-1.03-.73-.13-.08-.26-.18-.4-.26h-.01s-.07-.06-.11-.08c.17.02.34.05.51.07.26.04.52.07.79.11h.01c.27.04.53.07.8.11.09.02.19.03.29.05h.01c.07.01.14.02.22.03h.01c.43.06.87.12,1.31.19,1.87.28,3.83.59,5.85.95,1.05.18,2.12.37,3.21.57.7.13,1.42.27,2.14.41.56.11,1.12.22,1.69.34,2.48.5,5.03,1.06,7.66,1.68,2.38.57,4.82,1.18,7.31,1.85.9.24,1.81.49,2.72.75,19.7,5.56,42.55,14.71,65.58,29.96,17.07,11.3,28.71,24.07,39,35,5.36,17.51,12.23,37.22,16,60,3.77,22.8,5.49,43.67,6.05,61.99Z"
        />
        <path
          class="wc-england-7"
          d="m444.64,580.44c-.75.92-1.42,1.89-1.89,2.88v-.45l.02-.74.03-1.67,1.45-.02h.39Z"
        />
        <polygon
          class="wc-england-8"
          points="510.74 580.52 511.37 581.7 519 589 520 585 510.74 580.52"
        />
        <path class="wc-england-8" d="m519,591c-1,0,0-4,0-4l1,3.81-1,.19Z" />
        <path
          class="wc-england-9"
          d="m447.45,576.83c-19.23,4.72-42.66,12.27-67.95,24.67-32.87,16.12-57.82,35.03-75.35,50.59,3.54.9,14.21,4.09,22.35,14.41,6.46,8.19,8.03,16.68,8.5,20.5,5.95-12.09,23.84-44.39,62.5-64.5,26.09-13.57,50.4-15.75,63.88-15.92-12.99-4.77-20.29-14.42-18.88-22.08.28-1.5,1.18-4.52,4.95-7.67Z"
        />
        <rect class="wc-england-8" x="512" y="581" width="2" height="1" />
        <polygon
          class="wc-england-8"
          points="512 582.38 512 580.52 519 585 512 582.38"
        />
        <path
          class="wc-england-2"
          d="m511,581c19.23,4.72,41.71,7.6,67,20,32.87,16.12,51.47,32.44,69,48-3.54.9-11.83,2.17-19.97,12.5-6.46,8.19-8.03,16.68-8.5,20.5-5.95-12.09-23.84-44.39-62.5-64.5-26.09-13.57-33.55-14.33-47.03-14.5,14-7,9.18-18.11,3-21-1.38-.65,2.77,2.15-1-1Z"
        />
        <path d="m511.03,581.18c4.1,1.34,8.22,5.45,7.97,9.68-.03,1.54-.65,3.01-1.33,4.17-.62,1.24-1.42,2.43-2.36,3.42-5.43,5.78-13.95,9.52-20.95,11.19-10.46,2.81-21.74,1.91-31.85-1.86-5.03-1.82-9.83-4.35-14.1-7.64-4.77-3.62-8.37-8.17-7.52-14.21,0,0,0-.05,0-.05l.17-1.15.3-.58c-.19.16-.33.49-.31.74l.02-.19c.19-1.88,1.8-4.54,2.83-5.1,1.71-1.19,3.11-2.06,3.8-3.93.45-1.51.97-4.22,1.31-5.86,0,0,1.96.39,1.96.39l-.58,3.03c-.21,1.02-.33,1.98-.71,3.14-.67,2.15-2.75,3.84-4.43,4.92-.76.49-1.86,2.28-2.01,3.8,0,0,.02-.19.02-.19,0,.27-.14.63-.33.82l.3-.58-.13.99c-.73,5.08,2.51,8.94,6.74,12.16,4.05,3.11,8.64,5.53,13.47,7.27,14.89,5.54,31.2,4.21,44.55-3.82,3.16-2.03,6.31-4.33,8.01-7.72.9-1.51,1.32-2.88.99-4.52-.82-3.35-3.55-5.6-6.11-7.84,0,0,.27-.48.27-.48h0Z" />
        <g>
          <circle class="wc-england-3" cx="532.79" cy="663.16" r="2.16" />
          <circle class="wc-england-3" cx="530.16" cy="667.89" r="2.16" />
          <circle class="wc-england-3" cx="534.95" cy="670.84" r="2.16" />
          <circle class="wc-england-3" cx="537.7" cy="665.72" r="2.16" />
          <circle class="wc-england-10" cx="533.97" cy="666.9" r="1.57" />
        </g>
        <g>
          <circle class="wc-england-3" cx="566.79" cy="666.16" r="2.16" />
          <circle class="wc-england-3" cx="564.16" cy="670.89" r="2.16" />
          <circle class="wc-england-3" cx="568.95" cy="673.84" r="2.16" />
          <circle class="wc-england-3" cx="571.7" cy="668.72" r="2.16" />
          <circle class="wc-england-10" cx="567.97" cy="669.9" r="1.57" />
        </g>
        <g>
          <circle class="wc-england-3" cx="547.79" cy="691.16" r="2.16" />
          <circle class="wc-england-3" cx="545.16" cy="695.89" r="2.16" />
          <circle class="wc-england-3" cx="549.95" cy="698.84" r="2.16" />
          <circle class="wc-england-3" cx="552.7" cy="693.72" r="2.16" />
          <circle class="wc-england-10" cx="548.97" cy="694.9" r="1.57" />
        </g>
        <g>
          <circle class="wc-england-3" cx="560.79" cy="683.16" r="2.16" />
          <circle class="wc-england-3" cx="558.16" cy="687.89" r="2.16" />
          <circle class="wc-england-3" cx="562.95" cy="690.84" r="2.16" />
          <circle class="wc-england-3" cx="565.7" cy="685.72" r="2.16" />
          <circle class="wc-england-10" cx="561.97" cy="686.9" r="1.57" />
        </g>
        <g>
          <circle class="wc-england-3" cx="570.79" cy="697.16" r="2.16" />
          <circle class="wc-england-3" cx="568.16" cy="701.89" r="2.16" />
          <circle class="wc-england-3" cx="572.95" cy="704.84" r="2.16" />
          <circle class="wc-england-3" cx="575.7" cy="699.72" r="2.16" />
          <circle class="wc-england-10" cx="571.97" cy="700.9" r="1.57" />
        </g>
        <g>
          <circle class="wc-england-3" cx="534.79" cy="680.16" r="2.16" />
          <circle class="wc-england-3" cx="532.16" cy="684.89" r="2.16" />
          <circle class="wc-england-3" cx="536.95" cy="687.84" r="2.16" />
          <circle class="wc-england-3" cx="539.7" cy="682.72" r="2.16" />
          <circle class="wc-england-10" cx="535.97" cy="683.9" r="1.57" />
        </g>
        <path
          class="wc-england-1"
          d="m527,656v42.33c2.71,3.74,6.5,8.24,11.63,12.67,6.28,5.41,12.41,8.84,17.1,11,4.01-1.2,10.92-3.86,17.59-9.95,4.89-4.46,7.9-9.17,9.67-12.5v-43.54h-55.98Z"
        />
      </g>
      <g id="Shoulder">
        <g>
          <path d="m289.61,748.67c-.91-1.03-1.83-2.05-2.71-3.1-.16-.19-.28-.51-.27-.76.05-2.47.11-4.94.2-7.41.05-1.47.14-2.94.27-4.41.38-4.26.69-8.53,1.2-12.78.63-5.25,1.33-10.5,2.18-15.72.92-5.63,2.04-11.23,3.07-16.84.73-3.99,1.22-8.03,2.23-11.95,1.74-6.77,3.67-13.51,5.8-20.17.76-2.37,2.14-4.61,3.59-6.66,1.05-1.49,2.62-2.65,4.07-3.82,6.08-4.88,12.09-9.86,18.34-14.52,4.77-3.55,9.8-6.77,14.82-9.97,13.26-8.43,27.33-15.36,41.35-22.38,13.87-6.94,28.29-12.49,43.1-17.07,6.81-2.11,13.66-4.07,20.67-5.5-.25,1.22-.39,1.42-1.42,1.69-13.06,3.43-25.64,8.26-38.17,13.21-8.35,3.3-16.39,7.25-24.35,11.37-6.67,3.45-13.39,6.81-19.95,10.47-6.61,3.68-13.14,7.53-19.55,11.57-8.21,5.17-16.25,10.61-24.38,15.91-4.23,2.76-8.05,6.02-11.92,9.25-1.81,1.51-2.89,3.25-3.44,5.46-1.94,7.73-3.97,15.45-5.84,23.2-1.14,4.69-2.15,9.42-3.07,14.16-.85,4.41-1.53,8.86-2.21,13.3-.68,4.45-1.32,8.9-1.89,13.37-.27,2.12-.34,4.26-.52,6.39-.31,3.75-.63,7.5-.94,11.25-.05.56,0,1.12-.02,1.68-.07,2.35-.19,4.71-.22,7.06-.01,1.19.13,2.38.21,3.57-.08.05-.15.1-.23.16Z" />
          <path d="m671.16,776.11c0-4.67.12-9.14-.03-13.6-.17-4.96-.57-9.92-.92-14.88-.2-2.89-.43-5.77-.74-8.65-.31-2.86-.75-5.71-1.14-8.57-.48-3.48-.94-6.96-1.49-10.43-.6-3.77-1.25-7.53-1.97-11.28-1.13-5.83-2.24-11.66-3.55-17.45-1.76-7.74-3.63-15.46-5.61-23.15-1.27-4.93-3.11-9.67-5.69-14.09-.28-.47-.65-.91-1.05-1.29-2.43-2.31-4.84-4.63-7.32-6.88-3.68-3.35-7.31-6.75-11.14-9.92-5.38-4.47-10.81-8.9-16.42-13.08-11.22-8.36-23.42-15.08-36.09-20.98-9.9-4.61-20-8.7-30.39-12.06-7.93-2.57-16.05-4.38-24.18-6.14-4.02-.87-8.05-1.74-12.06-2.64-.64-.14-1.24-.44-1.85-.66l.06-.31c2.3.29,4.62.48,6.9.9,4.7.87,9.37,1.93,14.07,2.79,7,1.29,13.88,3.04,20.66,5.17,8.46,2.65,16.72,5.82,24.85,9.37,9.84,4.3,19.41,9.09,28.57,14.7,7.87,4.82,15.45,10.07,22.57,15.95,4.99,4.12,9.86,8.38,14.76,12.61,2.38,2.06,4.85,4.05,6.96,6.36,1.57,1.73,3.02,3.79,3.84,5.96,4.11,10.81,7.26,21.92,9.88,33.18,1.93,8.31,3.77,16.64,5.47,25,1.07,5.28,1.85,10.63,2.69,15.96.7,4.44,1.34,8.9,1.89,13.36.36,2.91.59,5.84.74,8.76.37,7.24.63,14.48.99,21.71.05,1.07-.28,1.82-1.08,2.46-.68.54-1.33,1.11-2.17,1.81Z" />
        </g>
      </g>
    </g>
  );
};

export default England;
