import { Divider, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { changeTeamName } from "../../api/teams";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import {
  cardLight,
  cardVeryLight,
  doubtedBlue,
  normal,
  offWhite,
  small,
  text,
} from "../../utils/themeContstants";
import CustomInput from "../custom/CustomInput";
import CustomModal from "../custom/CustomModal";
import PrimaryButton from "../custom/PrimaryButton";
import SecondaryButton from "../custom/SecondaryButton";

const EditTeamModal = ({ open, onClose, team }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setName("");
    setLoading(false);
    onClose();
  };

  const handleTeamNameChange = () => {
    setLoading(true);
    changeTeamName(middleware, team?._id, name).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        handleClose();
      }
    });
  };

  useEffect(() => {
    if (team && team) {
      setName(team?.name);
    }
  }, [open, team]);

  const styles = {
    width: {
      width: "100%",
    },
    label: {
      fontSize: normal,
      fontWeight: 700,
      color: offWhite,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
  };

  return (
    <CustomModal
      title="Edit Team Name"
      description="Change the name of your team"
      titleSize="large"
      open={open}
      onClose={handleClose}
      primaryButton={
        <SecondaryButton
          bg={doubtedBlue}
          label="Save"
          disabled={name === "" || name === team?.name || name?.length > 35}
          loading={loading}
          onClick={handleTeamNameChange}
        />
      }
      secondaryButton={<SecondaryButton label="Cancel" onClick={handleClose} />}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <Grid item sx={styles.width}>
              <CustomInput
                value={name}
                onChange={setName}
                placeholder={"Enter a team name"}
                autoFocus={true}
                onKeyDown={() => {
                  if (name !== "" && name != null && name?.length > 2) {
                    handleTeamNameChange();
                  } else {
                    return;
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default EditTeamModal;
