import { CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { getAutoLogs, getMoneyLogs } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { CLIENT_URL } from "../../utils/constants";
import {
  currencyFormatter,
  getNotiTimestamp,
  getReadableDateString,
  getTime,
} from "../../utils/helpers";
import { TRANSACTION_LIMIT } from "../../utils/limits";
import {
  card,
  cardLight,
  green,
  modalShadow,
  normal,
  offWhite,
  red,
  secondaryText,
  small,
  text,
  transition,
} from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";
import NotSelected from "../custom/NotSelected";
import LogGlimmer from "../glimmers/LogGlimmer";

const UserMoneyLogs = ({ user, onClick }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [skip, setSkip] = useState(0);

  const handleGetLogs = () => {
    getMoneyLogs(middleware, user?._id, skip).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.logs?.length >= TRANSACTION_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setLogs(logs ? [...logs, ...res?.logs] : res?.logs);
      }
    });
  };

  const handleLogClick = (attached) => {
    return window.open(`${CLIENT_URL}/token/${attached}`, "_blank");
  };

  usePagination(bottom, () => setSkip(logs?.length));

  const getBalanceHelper = (balance_before, amount, action) => {
    if (!action) {
      return;
    }
    return (
      <Typography sx={styles.balance_label}>
        --- Balance : {currencyFormatter().format(balance_before)}
        {" to "}
        {currencyFormatter().format(
          getCurrentBalanceByAction(balance_before, amount, action)
        )}
      </Typography>
    );
  };

  const getAction = (action, amount, entry_fee) => {
    switch (action) {
      case "add":
        if (
          amount == entry_fee * 2 ||
          amount == entry_fee * 3 ||
          amount == entry_fee * 4
        ) {
          return "Cancel " + "(+" + currencyFormatter().format(amount) + ")";
        }
        if (entry_fee !== amount) {
          return "Win " + "(+" + currencyFormatter().format(amount) + ")";
        } else if (entry_fee === amount) {
          return "Cancel " + "(+" + currencyFormatter().format(amount) + ")";
        }
      case "remove":
        if (
          amount == entry_fee * 2 ||
          amount == entry_fee * 3 ||
          amount == entry_fee * 4
        ) {
          return "Ready Up " + "(-" + currencyFormatter().format(amount) + ")";
        }
        if (entry_fee !== amount) {
          return "Reset " + "(-" + currencyFormatter().format(amount) + ")";
        }

        if (entry_fee === amount) {
          return "Ready Up " + "(-" + currencyFormatter().format(amount) + ")";
        }
    }
  };
  const getTotalChange = (actions) => {
    let change = 0.0;

    for (let i = 0; i < actions.length; i++) {
      if (actions[i]?.action === "add") {
        change += actions[i].amount;
      }
      if (actions[i]?.action === "remove") {
        change -= actions[i].amount;
      }
    }

    return change;
  };
  const getStyle = (amount) => {
    if (amount > 0) {
      amount = 1;
    }
    if (amount < 0) {
      amount = -1;
    }
    switch (amount) {
      case 0:
        return styles.neutral;
      case 1:
        return styles.add;
      case -1:
        return styles.remove;
    }
  };
  const getCurrentBalanceByAction = (balance, amount, action) => {
    switch (action) {
      case "add":
        return balance + amount;
      case "remove":
        return balance - amount;
    }
  };

  useEffect(() => {
    if (logs == null) {
      handleGetLogs();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetLogs();
    }
  }, [skip]);

  const styles = {
    width: {
      width: "100%",
    },
    label: {
      fontSize: small,
      fontWeight: 500,
      color: secondaryText,
    },
    balance_label: {
      fontSize: normal,
      fontWeight: 500,
      color: offWhite,
      display: "inline",
    },
    neutral: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    add: {
      fontSize: normal,
      fontWeight: 500,
      color: green,
    },
    remove: {
      fontSize: normal,
      fontWeight: 500,
      color: red,
    },
    cancel: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf="center"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!logs || logs?.length < 1) && (
        <Grid item alignSelf="center">
          <NotSelected label="NO LOGS" />
        </Grid>
      )}

      {!loading && logs && logs?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 0.5 }}
          >
            {logs?.map((log, i) => (
              <Grid
                item
                sx={{
                  borderRadius: 1,
                  padding: 1,
                  width: "100%",
                  backgroundColor: card,
                  boxShadow: modalShadow,
                  transition: transition,
                  "&:hover": {
                    cursor: "pointer",
                    transform: "translateY(-5px)",
                  },
                }}
                key={i}
                onClick={() => handleLogClick(log?.match_id)}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  sx={{
                    width: "100%",
                    padding: 1,
                    borderRadius: 1,
                    backgroundColor: cardLight,
                  }}
                >
                  <Grid item>
                    <Typography sx={styles.label}>
                      {getReadableDateString(new Date(log?.timestamp)) +
                        " at " +
                        getTime(new Date(log?.timestamp)) +
                        " in: " +
                        log?.match_id}
                    </Typography>
                  </Grid>

                  <Grid item>
                    {log?.actions.map((action, i) => (
                      <Typography
                        sx={
                          action?.action === "add" ? styles.add : styles.remove
                        }
                      >
                        {getAction(
                          action?.action,
                          action?.amount,
                          log?.entry_fee
                        )}{" "}
                        {getBalanceHelper(
                          action?.balance_before,
                          action?.amount,
                          action?.action
                        )}
                      </Typography>
                    ))}
                    <Typography sx={getStyle(getTotalChange(log?.actions))}>
                      {" "}
                      Total Change:{" "}
                      {currencyFormatter().format(getTotalChange(log?.actions))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {hasMore && (
              <div style={{ width: "100%" }} ref={bottom}>
                <LogGlimmer numItems={1} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserMoneyLogs;
