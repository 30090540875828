import Anime from "./types/Anime";
import BlackEye from "./types/BlackEye";
import Crying from "./types/Crying";
import Default from "./types/Default";
import DefaultHappy from "./types/DefaultHappy";
import Duoma from "./types/Duoma";
import FutureVision from "./types/FutureVision";
import Hearts from "./types/Hearts";
import Lashes from "./types/Lashes";
import Peepers from "./types/Peepers";
import Shaggy from "./types/Shaggy";
import SideEye from "./types/SideEye";
import Smile from "./types/Smile";
import Suspicious from "./types/Suspicious";
import Unamused from "./types/Unamused";
import Wink from "./types/Wink";

const Eyes = ({ type }) => {
  switch (type) {
    case "side_eye":
      return <SideEye />;
    case "crying":
      return <Crying />;
    case "hearts":
      return <Hearts />;
    case "black_eyes":
      return <BlackEye />;
    case "default":
      return <Default />;
    case "smile":
      return <Smile />;
    case "lashes":
      return <Lashes />;
    case "anime":
      return <Anime />;
    case "suspicious":
      return <Suspicious />;
    case "unamused":
      return <Unamused />;
    case "wink":
      return <Wink />;
    case "peepers":
      return <Peepers />;
    case "future_vision":
      return <FutureVision />;
    case "default_happy":
      return <DefaultHappy />;
    case "shaggy":
      return <Shaggy />;
    case "duoma_eyes":
      return <Duoma />;
    default:
      return null;
  }
};

export default Eyes;
