const Musketeer = ({ color }) => {
  const styles = {
    one: {
      fill: color,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
  };

  return (
    <g transform="translate(460, 420)">
      <g>
        <path
          style={styles.one}
          d="M.77,15.73c1.43-.87,2.43-.2,3.41,.22,4.56,1.98,9.25,3.56,14.17,4.33,17.1,2.66,32.89-.61,47.14-10.6,2.32-1.63,4.77-2.93,7.57-3.44,4.45-.81,6.75,.17,9.28,4.05,.76-2.44,2.25-4.08,4.49-4.97,2.55-1.01,5.17-1,7.78-.37,1.23,.3,2.46,.75,3.58,1.34,13.03,6.79,26.6,7.26,40.53,3.49,6.3-1.71,12.07-4.59,17.51-8.18,.89-.59,1.72-1.49,3.38-.91-.81,.8-1.4,1.5-2.11,2.07-6.14,4.95-12.95,8.79-20.17,11.89-11.66,5-23.79,7.85-36.54,7.7-2.31-.03-4.66-.1-6.92-.56-5.24-1.06-9.58-3.46-11.58-9.05-2.39,6.91-7.53,10.36-13.99,12.19-10.35,2.94-20.91,3.56-31.59,2.55-10.68-1.01-20.92-3.63-30.59-8.29-1.84-.89-3.78-1.67-5.36-3.44Z"
        />
        <path
          style={styles.one}
          d="M104.31,75.88c-.23,6.23-1.84,11.87-4.54,17.21-.76,1.49-1.64,2.93-2.46,4.38-.47,.82-1.27,1.3-1.94,.56-5.16-5.6-11.48-10.32-14.02-17.94-1.8-5.4-1.55-10.73,1.22-15.76,2.1-3.82,5.2-6.25,9.86-5.88,2.59,.21,4.72,1.43,6.5,3.25,3.92,4,5.35,8.95,5.38,14.19Z"
        />
      </g>
    </g>
  );
};

export default Musketeer;
