import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import { AiOutlineLink } from "react-icons/ai";
import { IoMdLink } from "react-icons/io";
import useMiddleware from "../../api/useMiddleware";
import { fetchTwitterURL } from "../../api/user";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { DISCORD_URL, PAYPAL_URL, TWITCH_URL } from "../../utils/authURL";
import { large, normal, offWhite, text } from "../../utils/themeContstants";
import SelectGameModal from "../custom/SelectGameModal";
import ConnectionButton from "./ConnectionButton";
import ConnectionItem from "./ConnectionItem";
import EpicModal from "./EpicModal";
import GameConnectionModal from "./GameConnectionModal";

const LinkedAccounts = () => {
  const isDesktop = useMediaQuery("(min-width:1025px)");
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();

  const [epicOpen, setEpicOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [game, setGame] = useState(false);
  const [selectGameOpen, setSelectGameOpen] = useState(false);


  const handleEpicOpen = () => {
    if (store?.user?.connections?.epic) {
      sendConnectionAlreadyLinked();
      return
    }
    else {
      setSelectGameOpen(true);
      setOpen(true);
    }
  }

  const sendConnectionAlreadyLinked = () => {
    updateStore({
      type: SET_ERRORS,
      payload: "Try removing before re-linking.",
    });
  };

  const sendPaypalLinkedError = () => {
    updateStore({
      type: SET_ERRORS,
      payload:
        "Please create a ticket in the Discord if you wish to remove your PayPal account.",
    });
  };

  const getTwitterURL = () => {
    fetchTwitterURL(middleware).then((res) => {
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        window.open(res?.url, "_self");
      }
    });
  };

  const addConnection = (type) => {
    switch (type) {
      case "twitch":
        if (store?.user?.connections?.twitch) {
          sendConnectionAlreadyLinked();
          return;
        } else {
          window.open(TWITCH_URL, "_self");
        }
        break;
      case "discord":
        if (store?.user?.connections?.discord) {
          sendConnectionAlreadyLinked();
          return;
        } else {
          window.open(DISCORD_URL, "_self");
        }
        break;
      case "epic":
        setGame("fortnite");
        setOpen(true);
        break;
      case "twitter":
        if (store?.user?.connections?.twitter) {
          sendConnectionAlreadyLinked();
          return;
        } else {
          getTwitterURL();
        }
        break;
      case "paypal":
        if (store?.user?.connections?.paypal) {
          sendPaypalLinkedError();
          return;
        } else {
          window.open(PAYPAL_URL, "_self");
        }
        break;
      case "clashroyale":
        setOpen(true);
        setGame("clashroyale");
        break;
      case "chess":
        setOpen(true);
        setGame("chess");
        break;
      case "game":
        if (store?.user?.connections?.epic) {
          console.log("name", store?.user?.connections?.epic)
          sendConnectionAlreadyLinked();
          return
        }
        else {
          setSelectGameOpen(true);
        }
        break;
      default:
        return null;
    }
  };

  const styles = {
    width: {
      width: "100%",
    },
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
  };

  return (
    <>
      <EpicModal open={epicOpen} onClose={() => setEpicOpen(false)} />
      <GameConnectionModal
        open={open}
        onClose={() => setOpen(false)}
        game={"fortnite"}
        minWidth={600}
      />
      {/* <SelectGameModal
        title="Link Game Account"
        open={selectGameOpen}
        onClose={(selectedGame) => {
          setSelectGameOpen(false);

          if (selectedGame) {
            setGame(selectedGame);
            setOpen(true);
          }
        }}
      /> */}

      <Grid item>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <AiOutlineLink style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>Linked Accounts</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography
              sx={{ fontSize: large, fontWeight: 600, color: offWhite }}
            >
              Link an account
            </Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 1 }}
            >
              {/* <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                <ConnectionButton
                  type="paypal"
                  onClick={() => addConnection("paypal")}
                  fullWidth={isDesktop ? false : true}
                />
              </Grid> */}

              <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                <ConnectionButton
                  type="discord"
                  onClick={() => addConnection("discord")}
                  fullWidth={isDesktop ? false : true}
                />
              </Grid>

              <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                <ConnectionButton
                  type="twitch"
                  onClick={() => addConnection("twitch")}
                  fullWidth={isDesktop ? false : true}
                />
              </Grid>

              <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                <ConnectionButton
                  type="twitter"
                  onClick={() => addConnection("twitter")}
                  fullWidth={isDesktop ? false : true}
                />
              </Grid>


              <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                <ConnectionButton
                  type="game"
                  onClick={() => handleEpicOpen()}
                  fullWidth={isDesktop ? false : true}
                />
              </Grid>

              {/* <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                <ConnectionButton
                  type="epic"
                  onClick={() => addConnection("epic")}
                  fullWidth={isDesktop ? false : true}
                />
              </Grid>

              <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                <ConnectionButton
                  type="clashroyale"
                  onClick={() => addConnection("clashroyale")}
                  fullWidth={isDesktop ? false : true}
                />
              </Grid>

              <Grid item sx={{ minWidth: isDesktop ? 0 : "100%" }}>
                <ConnectionButton
                  type="chess"
                  onClick={() => addConnection("chess")}
                  fullWidth={isDesktop ? false : true}
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography
              sx={{ fontSize: large, fontWeight: 600, color: offWhite }}
            >
              Your accounts
            </Typography>
          </Grid>

          {store?.user?.connections && (
            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 2 }}
              >
                {Object.keys(store?.user?.connections)?.map((connection, i) => (
                  <ConnectionItem
                    connection={store?.user?.connections[connection]}
                    key={i}
                    type={connection}
                  />
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default LinkedAccounts;
