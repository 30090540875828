// events
export const NEW_USER_UPDATE_EVENT = "newUserInfo";
export const NEW_ADD_MATCH_EVENT = "newMatchEvent";
export const NEW_REMOVE_MATCH_EVENT = "newRemoveMatchEvent";
export const NEW_MATCH_UPDATE_EVENT = "newMatchUpdateEvent";
export const NEW_MATCH_CHAT_MESSAGE_EVENT = "newMatchMessage";
export const NEW_ONGOING_MATCH_EVENT = "newOngoingMatch";
export const NEW_REMOVE_ONGOING_MATCH_EVENT = "newRemoveOngoingMatch";
export const NEW_NOTIFICATION = "newNotification";
export const NEW_TEAMMATE_EVENT = "newTeammate";
export const NEW_REMOVE_TEAM_EVENT = "newRemoveTeamEvent";
export const NEW_PURCHASE_EVENT = "newPurchaseEvent";
export const NEW_HISTORY_EVENT = "newHistoryEvent";

// ids
export const TOKEN_PAGE_ID = "tokenPage";
