import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../store/Store";
import {
  getFee,
  getGameModeString,
  getMatchTitle,
  getPlatformString,
  getRegionString,
  getRegionStringFromArray,
  getStateString,
  getTeamSizeString,
  numFormatter,
} from "../../utils/helpers";
import {
  card,
  cardLight,
  cardVeryLight,
  doubtedBlue,
  doubtedOrange,
  normal,
  text,
  yellow,
} from "../../utils/themeContstants";
import CoinBadge from "../custom/CoinBadge";
import CustomPill from "../custom/CustomPill";
import GameBadge from "../custom/GameBadge";
import RivoxCoin from "../custom/RivoxCoin";
import "../styles/Table.css";

const OngoingTokenItem = ({ token }) => {
  const isDesktop = useMediaQuery("(min-width:1025px)");
  const navigate = useNavigate();
  const store = useContext(StoreContext);

  const handleClick = () => {
    if (store?.user) {
      navigate(`/token/${token?._id}`);
    } else {
      navigate("/login");
    }
  };

  const styles = {
    container: {
      padding: 2,
      borderRadius: 2,
      backgroundColor: card,
      // borderBottom: `1px solid ${cardVeryLight}`,
      // boxShadow: "0 1px 2px rgba(0,0,0,.9),0 0px 2px rgba(0,0,0,.9)",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: cardLight,
      },
    },
    label: {
      fontSize: 13,
      color: text,
      fontWeight: 700,
      opacity: 0.8,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
    title: {
      fontSize: 18,
      fontWeight: 700,
      color: text,
    },
  };

  return (
    <Grid item sx={styles.container} onClick={handleClick}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 2 }}
          >
            {/* <GameBadge game={token?.game} size="large" borderRadius={100} /> */}

            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 1 }}
              >
                <Grid item>
                  <Typography sx={styles.title}>
                    {getMatchTitle(
                      token?.game_match_type,
                      token?.team_size,
                      token?.game_map_type,
                      token?.extra_options,
                      token?.game_mode_options
                    )}
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                  >
                    {token?.game === "fortnite" && (
                      <>
                        <CustomPill
                          label={getTeamSizeString(token?.team_size)}
                          hoverLabel="Team Size"
                        />

                        <CustomPill
                          label={getPlatformString(token?.platform)}
                          hoverLabel="Platform"
                        />

                        {token?.region?.length > 0 ? (
                          <>
                            <CustomPill
                              label={getRegionStringFromArray(
                                token?.region,
                                token?.selected_region
                              )}
                              hoverLabel="Region"
                            />
                          </>
                        ) : (
                          <CustomPill
                            label={getRegionString(token?.region)}
                            hoverLabel="Region"
                          />
                        )}

                        {!token?.best_of && (
                          <token
                            label={token?.first_to}
                            firstTo={true}
                            hoverLabel="First to"
                          />
                        )}

                        {token?.best_of && (
                          <CustomPill
                            label={`Best of ${token?.best_of}`}
                            hoverLabel="Best of"
                          />
                        )}

                        {token?.extra_options?.kill_lead && (
                          <CustomPill
                            label={`${token?.extra_options?.kill_lead} Round Lead`}
                            hoverLabel="Round lead"
                            color={yellow} // Or any other color you want
                            glow={true} // Make it glow
                          />
                        )}
                      </>
                    )}

                    {token?.game === "fifa" && (
                      <>
                        <CustomPill
                          label={getPlatformString(token?.platform)}
                          hoverLabel="Platform"
                        />

                        <CustomPill
                          label={getRegionString(token?.region)}
                          hoverLabel="Region"
                        />

                        <CustomPill
                          label={`Best of ${token?.best_of}`}
                          hoverLabel="Best of"
                        />
                      </>
                    )}

                    {token?.game === "clashroyale" && (
                      <>
                        <CustomPill
                          label={`Best of ${token?.best_of}`}
                          hoverLabel="Best of"
                        />

                        <CustomPill
                          label={getTeamSizeString(token?.team_size)}
                          hoverLabel="Team size"
                        />
                      </>
                    )}

                    {token?.game === "warzone" && (
                      <>
                        <CustomPill
                          label={getPlatformString(token?.platform)}
                          hoverLabel="Platform"
                        />

                        <CustomPill
                          label={getRegionString(token?.region)}
                          hoverLabel="Region"
                        />

                        <CustomPill
                          label={`Best of ${token?.best_of}`}
                          hoverLabel="Best of"
                        />

                        {token?.extra_options?.must_stream &&
                          token?.platform == null && (
                            <CustomPill
                              label={
                                token?.extra_options?.must_stream
                                  ? "Must Stream"
                                  : null
                              }
                              hoverLabel="PC Stream Requirements"
                            />
                          )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="end"
            justifyContent="center"
          >
            <Grid item>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 0.5 }}
                wrap="nowrap"
              >
                <RivoxCoin size={25} />

                <Grid item>
                  <Typography
                    sx={{ fontSize: 22, fontWeight: 800, color: text }}
                  >
                    {numFormatter().format(
                      parseFloat(
                        token?.entry_fee * getFee(token?.game) +
                          token?.entry_fee
                      )
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ marginTop: -0.5 }}>
              <Typography
                sx={{
                  fontSize: 11,
                  fontWeight: 800,
                  color: doubtedBlue,
                }}
              >
                PRIZE
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        gap={{ xs: 2 }}
      >
        <GameBadge game={token?.game} />

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={styles.label}>Game Type</Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.value}>
                {getGameModeString(token?.game_match_type)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {isDesktop && (
          <>
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
              >
                <Grid item>
                  <Typography sx={styles.label}>State</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.value}>
                    {getStateString(token?.state)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {token?.region && (
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography sx={styles.label}>Region</Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.value}>
                      {token?.region?.length > 0
                        ? getRegionStringFromArray(
                            token?.region,
                            token?.selected_region
                          )
                        : getRegionString(token?.region)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
              >
                <Grid item>
                  <Typography sx={styles.label}>Team Size</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.value}>
                    {`${token?.team_size}v${token?.team_size}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <CoinBadge
              amount={numFormatter().format(token?.entry_fee)}
              label="Entry Fee"
              placement="top"
              size="small"
            />
          </>
        )}

        <CoinBadge
          type="prize"
          placement="top"
          label="Prize"
          amount={numFormatter().format(
            token?.entry_fee * 0.80 + token?.entry_fee
          )}
          size="small"
        />
      </Grid> */}
    </Grid>
  );
};

export default OngoingTokenItem;
