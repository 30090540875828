const BlackEye = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#662d91",
    },
    three: {
      fill: "#2e3192",
    },
    four: {
      fill: "#1b1464",
    },
    five: {
      fill: "#010101",
    },
  };

  return (
    <g>
      <path d="M606.12,350.74c6.64,.16,13.02,1.23,18.92,4.41,2.67,1.44,5.04,3.25,6.8,5.75,2.52,3.58,2.9,7.38,.79,11.26-.67,1.24-.94,2.45-1.07,3.84-.42,4.41-2.76,7.78-6.16,10.47-3.87,3.06-8.35,4.75-13.15,5.54-10.2,1.69-19.9,.19-28.8-5.21-2.15-1.3-4.18-2.95-5.88-4.79-6.3-6.81-6.99-16.44,1.93-23.11,5.6-4.19,11.98-6.37,18.79-7.42,2.59-.4,5.23-.51,7.84-.75Zm-30.6,19.81c-.27,1.01-.54,2.02-.8,3.03,2.9-2.87,6.04-4.91,9.53-6.52,6.9-3.17,14.17-4.31,21.71-4.25,5.61,.05,11.07,.9,16.1,3.39,2.23,1.1,4.14,2.85,6.19,4.32,.38,.27,.72,.59,1.33,1.08-.03-2.96-1.58-4.75-3.29-6.35-2.36-2.2-5.25-3.52-8.29-4.54-5.43-1.84-11.04-2.16-16.68-1.82-7.83,.47-15.22,2.48-21.52,7.41-1.61,1.26-2.93,2.89-4.38,4.35,.28-.64,.46-1.36,.86-1.92,1.93-2.73,4.58-4.65,7.49-6.17,9.85-5.14,20.33-6.63,31.15-4.43,7.46,1.51,14.12,4.56,16.7,13.14,1.56-4.12,.43-7.29-2.01-10.14-2.42-2.83-5.56-4.63-9-5.91-8.12-3.03-16.45-3.39-24.91-1.82-5.97,1.11-11.52,3.2-16.28,7.11-2.15,1.77-3.7,3.96-4.63,6.6-.42,1.2-.63,2.38-.23,3.58,.44-.06,.7-.1,.95-.14Zm24.8-4.22c-9.17-.53-21.39,3.6-24.36,8.37,.46,.26,.89,.57,1.36,.77,13.94,5.83,28.25,6.86,42.88,3.16,2.7-.68,5.33-1.69,7.64-3.47-1.31-2.33-3.28-3.65-5.54-4.65-3.68,5.6-8.72,7.76-14.1,6.1-4.97-1.53-7.91-5.31-7.88-10.29Zm29.17,10.51c-18.35,8.44-36.41,7.91-54.78-1.2,.71,1.12,1.19,1.95,1.75,2.73,3,4.21,7.18,6.88,11.84,8.83,9.08,3.8,18.4,4.69,27.92,1.84,4.34-1.3,8.2-3.45,11.07-7.05,1.22-1.52,2.41-3.14,2.19-5.15Zm-28-10.63c.43,4.02,2.58,6.44,6.51,7.46,3.95,1.03,8.29-.12,10.15-2.76,.36-.52,.42-1.25,.66-2.01-5.81-2.02-11.46-2.91-17.32-2.69Z" />
      <path d="M490.82,362.16c-2.53,.19-4.57-1.9-7.18-1.76-.07,.32-.23,.67-.21,1.01,.15,2.72-1.21,4.69-3.17,6.31-5.02,4.15-13.44,4.59-18.9,1.03-3.75-2.44-4.95-5.29-4.06-9.94-.75,.09-1.44,.09-2.1,.25-6.99,1.81-13.61,4.41-19.38,8.92-1.46,1.14-3.14,1.98-4.89,2.75,.82-2.2,2.13-3.92,3.92-5.32,5.77-4.52,12.47-7,19.48-8.7,11.44-2.77,22.51-1.17,33.31,3.08,1.2,.47,2.29,1.2,3.43,1.81-.08,.19-.17,.38-.25,.57Zm-32.8-3.68c-.32,4.1,1.29,6.86,4.81,8.48,4.44,2.04,8.94,2.09,13.41,.15,3.78-1.64,4.76-3.42,4.47-7.72-7.4-1.92-14.88-2.49-22.69-.92Z" />
      <path
        style={styles.one}
        d="M600.32,366.33c-.03,4.98,2.91,8.76,7.88,10.29,5.37,1.66,10.42-.51,14.1-6.1,2.26,1,4.24,2.32,5.54,4.65-2.31,1.78-4.94,2.78-7.64,3.47-14.63,3.7-28.94,2.67-42.88-3.16-.48-.2-.91-.51-1.36-.77,2.97-4.77,15.19-8.9,24.36-8.37Z"
      />
      <path
        style={styles.three}
        d="M629.49,376.84c.22,2.01-.97,3.63-2.19,5.15-2.87,3.6-6.73,5.75-11.07,7.05-9.53,2.85-18.84,1.96-27.92-1.84-4.66-1.95-8.84-4.62-11.84-8.83-.56-.78-1.04-1.61-1.75-2.73,18.37,9.11,36.43,9.63,54.78,1.2Z"
      />
      <path
        style={styles.two}
        d="M575.52,370.56c-.25,.03-.51,.07-.95,.13-.4-1.2-.19-2.38,.23-3.58,.93-2.64,2.48-4.83,4.63-6.6,4.75-3.91,10.3-6.01,16.28-7.11,8.46-1.57,16.79-1.21,24.91,1.82,3.45,1.29,6.59,3.09,9,5.91,2.43,2.84,3.56,6.01,2.01,10.14-2.58-8.58-9.25-11.62-16.7-13.14-10.83-2.2-21.3-.71-31.15,4.43-2.91,1.52-5.56,3.44-7.49,6.17-.4,.56-.58,1.28-.86,1.92l.1-.1Z"
      />
      <path
        style={styles.four}
        d="M575.42,370.65c1.45-1.46,2.77-3.09,4.38-4.35,6.3-4.93,13.69-6.94,21.52-7.41,5.63-.34,11.25-.01,16.68,1.82,3.04,1.03,5.93,2.34,8.29,4.54,1.71,1.6,3.27,3.39,3.29,6.35-.6-.49-.95-.81-1.33-1.08-2.05-1.46-3.96-3.22-6.19-4.32-5.04-2.49-10.5-3.34-16.1-3.39-7.53-.06-14.81,1.08-21.71,4.25-3.49,1.6-6.64,3.64-9.53,6.52,.27-1.01,.54-2.02,.8-3.03,0,0-.1,.1-.1,.1Z"
      />
      <path d="M601.49,366.22c5.86-.22,11.51,.66,17.32,2.69-.24,.76-.29,1.5-.66,2.01-1.86,2.64-6.2,3.79-10.15,2.76-3.93-1.02-6.08-3.44-6.51-7.46Z" />
      <path
        style={styles.five}
        d="M458.02,358.48c7.81-1.58,15.29-1,22.69,.92,.3,4.3-.69,6.08-4.47,7.72-4.47,1.94-8.97,1.89-13.41-.15-3.53-1.62-5.14-4.38-4.81-8.48Z"
      />
      <circle style={styles.one} cx="475.5" cy="362.5" r="2.5" />
      <circle style={styles.one} cx="614.5" cy="368.5" r="2.5" />
    </g>
  );
};

export default BlackEye;
