import { CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { BsPiggyBank } from "react-icons/bs";
import { FaCoins } from "react-icons/fa";
import useMiddleware from "../../api/useMiddleware";
import { getDeposits } from "../../api/user";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { TRANSACTION_LIMIT } from "../../utils/limits";
import {
  emptyIcon,
  normal,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";
import Empty from "../custom/Empty";
import NotSelected from "../custom/NotSelected";
import MatchHistoryGlimmer from "../glimmers/MatchHistoryGlimmer";
import TransactionItem from "./TransactionItem";

const Deposits = () => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [deposits, setDeposits] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetDeposits = () => {
    getDeposits(middleware, skip).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.deposits?.length >= TRANSACTION_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setDeposits(deposits ? [...deposits, ...res?.deposits] : res?.deposits);
      }
    });
  };

  usePagination(bottom, () => setSkip(deposits?.length));

  useEffect(() => {
    if (deposits == null) {
      handleGetDeposits();
    }
  }, [deposits]);

  useEffect(() => {
    if (skip !== 0) {
      handleGetDeposits();
    }
  }, [skip]);

  const styles = {
    width: {
      width: "100%",
    },
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <BsPiggyBank style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>Deposits</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          {loading && (
            <Grid
              item
              alignSelf="center"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 4,
              }}
            >
              <CircularProgress size={30} sx={{ color: secondaryText }} />
            </Grid>
          )}

          {!loading && (
            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 1 }}
              >
                {(deposits?.length < 1 || !deposits) && (
                  <Empty
                    title="No deposits"
                    label="All your deposits will appear here"
                    icon={<BsPiggyBank style={emptyIcon} />}
                  />
                )}

                {deposits && deposits?.length > 0 && (
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      {deposits?.map((transaction, i) => (
                        <TransactionItem transaction={transaction} key={i} />
                      ))}
                      {hasMore && (
                        <div style={{ width: "100%" }} ref={bottom}>
                          <MatchHistoryGlimmer numItems={1} />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Deposits;
