// ========== COLORS ==========
// backgrounds
export const background = "#1A2C39";
export const backgroundDark = "#182935";
export const container = "#071826";
export const backgroundOpacity = (opacity) => `rgba(26, 44, 57, ${opacity})`;
export const linearGradientToTop = (rgbaOne, rgbaTwo) =>
  `linear-gradient(to top, ${rgbaOne}, ${rgbaTwo})`;

// cards
export const card = "#213744";
export const cardVeryLight = "#325468";
export const cardExtraLight = "#35586e";
export const cardLight = "#284252";
export const cardDark = "#0F212E";
export const cardDarker = "#071824";

// text
export const text = "#FFFFFF";
export const secondaryText = "#B1BAD2";
export const offWhite = "#d7e4f1";
export const lightGrey = "#D7D9E7";
export const darkGrey = "#4a4b5f";

// main colors
export const arcteryxHover = "#BAA2FF";
export const arcteryx = "#8C6FF7";
export const yellow = "#FCE71C";
export const emojiYellow = "#f5e050";
export const green = "#72F238";
export const purple = "#6634D6";
export const blue = "#18C5FF";
export const black = "#000";
export const glimmer = "#395465";
export const secondaryButton = "#395466";
export const red = "#F54A4A";
export const paypal = "#0070BA";
export const pink = "#ff006e";

// shadows
export const modalShadow =
  "rgb(0 0 0 / 40%) 0px 10px 20px, rgb(255 255 255 / 4%) 0px 1px 0px inset, rgb(0 0 0 / 35%) 0px -4px 0px inset";
export const menuShadow =
  "rgb(0 0 0 / 25%) 0px 5px 8px -4px, rgb(0 0 0 / 18%) 0px 0px 20px 0px, rgb(0 0 0 / 35%) 0px 40px 34px -16px";
export const iconShadow = "drop-shadow(rgb(255, 139, 25) 0px 0px 6px)";

// ========== FONT SIZES ==========
export const small = 12;
export const normal = 14;
export const large = 18;
export const badgeIconSize = 16;
export const badgeIconSizeSmall = 14;

// animations
export const transition = "all 0.15s ease-in-out";

// DOUBTED
export const ogDoubtedBlue = "#4399FF";
export const doubtedBlue = "#6634D6";
export const ogDoubtedOrange = "#F09641";
export const doubtedOrange = "#ef8e33";
export const doubtedGreen = "#16db65";
export const muffinsIdea = "#FF69B4";
export const doubtedMenuShadow =
  "0 4px 8px rgba(0,0,0,.4),0 0px 4px rgba(0,0,0,.4)";
export const detailIcon = {
  fontSize: 32,
  color: "#c5d7e8",
};
export const detailIconSmall = {
  fontSize: 24,
  color: "#c5d7e8",
};
export const emptyIcon = { color: secondaryText, fontSize: 60 };
export const emptyIconSmall = { color: secondaryText, fontSize: 40 };
