import { Grid, Typography } from "@mui/material";
import {
  card,
  cardLight,
  doubtedBlue,
  modalShadow,
  normal,
  offWhite,
  secondaryText,
  text,
} from "../../utils/themeContstants";

const InfoItem = ({ label, value, isEpic }) => {
  const styles = {
    container: {
      width: "100%",
      padding: 1,
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    value: {
      fontWeight: 700,
      color: text,
      fontSize: normal,
    },
    epicValue: {
      textDecoration: "underline",
      color: doubtedBlue,
      fontWeight: 400,
      overflowWrap: "break-word",
      verticalAlign: "middle",
      cursor: "default",
      fontSize: normal,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        sx={{ padding: 1, borderRadius: 1, backgroundColor: cardLight }}
      >
        <Grid item>
          <Typography sx={styles.label}>{label}</Typography>
        </Grid>

        <Grid item>
          {isEpic && (
            <Typography
              sx={styles.epicValue}
              onClick={() =>
                window.open(
                  "https://fortnitetracker.com/profile/all/BoldPromises/events?id=" +
                    value
                )
              }
            >
              <span style={styles.epicValue}>View Tracker</span>
            </Typography>
          )}
          {!isEpic && <Typography sx={styles.value}>{value}</Typography>}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InfoItem;
