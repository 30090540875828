const ThreeD = () => {
  const styles = {
    one: {
      fill: "#018ca8",
    },
    two: {
      fill: "#c6c7c2",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      fill: "#960703",
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="M418.12,336.19l247.66,3.09-1.58,57.12-96.94,2.29-9.91-7.92c-2.71-9.52-9.02-15.96-13.88-16.11-3.63-.11-7.51,3.24-8.04,3.7-1.42,1.23-2,2.2-3.54,4.35-1.37,1.91-3.41,4.61-6.14,7.8-3.04,2.2-6.08,4.4-9.12,6.6l-105.55,1.16,7.04-62.08Z"
      />
      <path
        style={styles.two}
        d="M343,363l1-1c.99-.99,.99-.99,1-1,1.79-1.77,4-3,4-3,.02-.01,0,0,2-1,.2-.1,1.81-.9,2-1h3c13.27,10.92,26.55,21.85,39.82,32.77l9.59,2.76c1.35,1.29,2.75,2.62,4.19,4,3.16-22.55,5.75-41.09,7.93-56.62-26.75,2.88-45.06,1.08-56.22,6.05-2.32,1.03-6.66,3.01-6.66,3.01-.03,.01-.06,.03-.09,.04-5.2,2.53-9.46,6.67-12.16,11.79-1.32,2.5-1.79,3.9-1.41,4.19,.26,.2,.93-.13,2-1Z"
      />
      <path
        style={styles.one}
        d="M496.48,388.92l-46.32,.82c-8.94,.16-15.7-5.07-14.92-11.65l2.43-20.5c.8-6.77,9.01-12.23,18.11-12.09l47.14,.7c8.38,.12,14.32,5.47,13.31,11.86l-3.06,19.34c-.98,6.22-8.45,11.39-16.69,11.54Z"
      />
      <path
        style={styles.three}
        d="M635.48,388.43l-46.32,.82c-8.94,.16-15.7-5.07-14.92-11.65l2.43-20.5c.8-6.77,9.01-12.23,18.11-12.09l47.14,.7c8.38,.12,14.32,5.47,13.31,11.86l-3.06,19.34c-.98,6.22-8.45,11.39-16.69,11.54Z"
      />
    </g>
  );
};

export default ThreeD;
