import { Grid, Typography } from "@mui/material";
import { CLIENT_URL } from "../../utils/constants";
import {
  card,
  cardLight,
  large,
  modalShadow,
  normal,
  secondaryText,
  text,
} from "../../utils/themeContstants";

const FAQ = () => {
  const styles = {
    width: {
      width: "100%",
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    container: {
      width: "100%",
      padding: 2,
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
    },
    modified: {
      fontSize: normal,
      fontWeight: 700,
      color: secondaryText,
    },
    question: {
      fontSize: large,
      fontWeight: 800,
      color: text,
    },
    answer: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.header}>FAQ</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.modified}>
              Frequently Asked Questions
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.container}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 4 }}
          sx={{ backgroundColor: cardLight, padding: 2, borderRadius: 1 }}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.question}>What is Rivox?</Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.answer}>
                  Rivox is a competitive gaming matchmaking platform that
                  provides users the way of finding entry-fee based game of
                  skill matches.
                  <br />
                  <br />
                  You can learn more about us in our{" "}
                  <a
                    style={{ fontWeight: 800, color: text }}
                    href={"https://discord.gg/rivox"}
                  >
                    Discord
                  </a>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.question}>What is Rivox AC?</Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.answer}>
                  Rivox AC is an Anti-Cheat system brought to you by Rivox in
                  order to combat cheating in all PC games offered on the
                  website. Currently, only select users are obligated to run
                  Rivox AC while playing. For more information, please see our{" "}
                  <a
                    style={{ fontWeight: 800, color: text }}
                    href={`${CLIENT_URL}/support/terms`}
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.question}>
                  Is Skill Gaming Legal?
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.answer}>
                  Skill Games are in fact legal! Because game of skill based
                  matches are based on skill and not on chance or luck, they are
                  NOT a form of gambling. However, you must be 18 years or older
                  to compete in game of skill based matches.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.question}>
                  Is my information going to be safe on Rivox?
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.answer}>
                  Yes! All transactions are encrypted and handled through
                  trusted payment providers that comply with PCI-DSS standards.
                  Other than your PayPal email, Cash Tag or payment information
                  you submit/is tied to your payment method, we have no access
                  to any of your personal payment credentials through PayPal,
                  Cash App or Shopify.
                  <br />
                  <br />
                  Rivox also does not store any passwords, and the only way to
                  register on Rivox is by using other accounts that the
                  user owns from other platforms such as Google, Twitch,
                  Discord, etc.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.question}>How do the fees work?</Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.answer}>
                  Rivox takes 10% of the prize pool at the end of the game. By
                  doing this, we can keep updating this platform and make it the
                  best experience for you, the user!
                  <br />
                  <br />
                  Other than our matches, we also take fees on withdraws to
                  cover the fee that payment processors charge us, and we take
                  a small 5% fee on any Tip transaction unless you have VIP.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.question}>
                  How long does it take to receive a withdraw?
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.answer}>
                  Rivox tries to send out withdrawals as FAST as we can. Most
                  withdrawals will be sent out within 24 hours from when they
                  are received, but can take up to 3 business days. If you do
                  not receive your withdraw within 3 business days, please make
                  a ticket in our{" "}
                  <a
                    style={{ fontWeight: 800, color: text }}
                    href={"https://discord.gg/rivox"}
                  >
                    Discord
                  </a>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.question}>
                  Am I allowed to have more than one Rivox account?
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.answer}>
                  You are NOT allowed to have more than one Rivox account. If we
                  notice this activity happening you will be indefinitely banned
                  from using Rivox products or services. This includes logging
                  into other people's accounts or playing on behalf of other
                  people by logging into the linked accounts.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.question}>
                  How do I change my PayPal/Cash App account?
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.answer}>
                  You cannot manually change your PayPal/Cash App account. If you
                  have a reason for changing your account, please make a ticket
                  in our{" "}
                  <a
                    style={{ fontWeight: 800, color: text }}
                    href={"https://discord.gg/rivox"}
                  >
                    Discord
                  </a>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.question}>
                  Is Crypto and Cash App the only way to deposit?
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.answer}>
                  Yes, Crypto and Cash App are the only ways to deposit on
                  Rivox. The reason for this is for the safety of both us and
                  you! We may be adding new payment methods in the future.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.question}>What are tickets?</Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.answer}>
                  Tickets are how the staff communicate with users to help with
                  disputed matches, clarification of rules, reporting a player,
                  and general inquiries regarding the website. By opening a
                  ticket, you will receive direct access to our support and
                  moderation team to assist with your issues.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.question}>
                  How can I create a ticket or get help in a match?
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={styles.answer}>
                  To open a ticket, join our{" "}
                  <a
                    style={{ fontWeight: 800, color: text }}
                    href={"https://discord.gg/rivox"}
                  >
                    Discord
                  </a>{" "}
                  and find the channel #open-a-ticket, or #vip-open-a-ticket.
                  Once created, you will provide staff with an explanation of
                  your issue, and how they can help. After this, a staff member
                  will join the ticket to resolve the problem as quickly as
                  possible. You can join our discord at the bottom of our home
                  page below "Connect With Us".
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FAQ;
