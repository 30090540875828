import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { kickUser } from "../../api/teams";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SEARCH_USER,
  SET_SUCCESSES,
  SET_TEAMS,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import {
  cardDark,
  cardLight,
  cardVeryLight,
  normal,
  red,
  secondaryText,
  small,
  text,
  transition,
} from "../../utils/themeContstants";
import Avatar from "../../avatar/Avatar";
import CustomIconButton from "../custom/CustomIconButton";
import { MdOutlinePersonRemoveAlt1 } from "react-icons/md";

const TeamMemberItem = ({ user, team, viewOnly = false }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [kickLoading, setKickLoading] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleOpenProfile = (user_id) => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const handleKick = () => {
    setKickLoading(true);
    kickUser(middleware, user?._id, team?._id).then((res) => {
      setKickLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({ type: SET_TEAMS, payload: res?.teams });
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
      }
    });
  };

  useEffect(() => {
    if (team) {
      if (team?.team_owner === store?.user?._id) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  }, [team]);

  const styles = {
    container: {
      width: "100%",
      backgroundColor: "transparent",
      border: `1px solid ${cardVeryLight}`,
      borderRadius: 2,
      paddingLeft: 2,
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: cardLight,
      },
    },
    username: {
      fontSize: normal,
      color: user?.premium_info?.active ? "rgb(255, 255, 193)" : text,
      textShadow: user?.premium_info?.active
        ? "rgb(255 93 0) 0px 0px 10px"
        : null,
      fontWeight: 800,
    },
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 600,
    },
  };

  return (
    <Grid item sx={{ marginLeft: -2 }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => handleOpenProfile(user?._id)}
        >
          <Avatar
            avatar={user?.new_avatar}
            size={125}
            bgColor={hovered ? cardLight : cardDark}
            sx={{ cursor: hovered ? "pointer" : "default" }}
          />
        </Grid>

        <Grid item>
          <Grid
            container
            alignItems="center"
            justifyContent="start"
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.username}>{user?.username}</Typography>
            </Grid>

            {isOwner && store?.user?._id !== user?._id && !viewOnly && (
              <CustomIconButton
                onClick={handleKick}
                loading={kickLoading}
                label="Kick user"
                icon={
                  <MdOutlinePersonRemoveAlt1
                    style={{ fontSize: 20, color: red }}
                  />
                }
              />
              // <Grid item>
              //   <SecondaryButton
              //     label="Kick"
              //     loading={kickLoading}
              //     onClick={handleKick}
              //     size="small"
              //   />
              // </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    // <Grid
    //   item
    //   sx={styles.container}
    //   onClick={() => handleOpenProfile(user?._id)}
    // >
    //   <Grid
    //     container
    //     justifyContent="space-between"
    //     alignItems="center"
    //     gap={{ xs: 2 }}
    //   >
    //     <Grid item>
    //       <Grid container justifyContent="start" alignItems="center">
    //         <Grid item sx={{ marginLeft: -2 }}>
    //           <Avatar avatar={user?.new_avatar} size={75} bgColor={cardLight} />
    //         </Grid>

    //         <Grid item>
    //           <Grid
    //             container
    //             direction="column"
    //             alignItems="start"
    //             justifyContent="center"
    //           >
    //             <Grid item>
    //               <Grid
    //                 container
    //                 justifyContent="start"
    //                 alignItems="center"
    //                 gap={{ xs: 1 }}
    //               >
    //                 <Grid item>
    //                   <Typography
    //                     sx={{
    //                       ...styles.username,
    //                       color: user?.premium_info?.active
    //                         ? "rgb(255, 255, 193)"
    //                         : text,
    //                     }}
    //                   >
    //                     {user?.username}
    //                   </Typography>
    //                 </Grid>

    //                 {user?._id === team?.team_owner && (
    //                   <Badge label="Captain">
    //                     <FaCrown
    //                       style={{
    //                         color: yellow,
    //                         fontSize: badgeIconSizeSmall,
    //                       }}
    //                     />
    //                   </Badge>
    //                 )}
    //               </Grid>
    //             </Grid>

    //             <Grid item>
    //               <Typography sx={styles.meta}>
    //                 {user?.connections?.epic?.name ?? "No Account Linked"}
    //               </Typography>
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //     </Grid>

    //     {isOwner && store?.user?._id !== user?._id && !viewOnly && (
    //       <Grid item>
    //         <PrimaryButton
    //           label="Kick"
    //           type="purple"
    //           loading={kickLoading}
    //           onClick={handleKick}
    //         />
    //       </Grid>
    //     )}
    //   </Grid>
    // </Grid>
  );
};

export default TeamMemberItem;
