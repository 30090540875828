const Retro = () => {
  const styles = {
    one: {
      fill: "#ff7f00",
    },
    two: {
      fill: "#ff7f00",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    three: {
      fill: "#ff9536",
    },
    four: {
      fill: "#ff8603",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="M350.6,422.99c-.07-1.37-.06-2.66-.25-3.93-.05-.33-.7-.72-1.14-.82-4.86-1.14-8.22-3.96-9.52-8.83-2.41-9.05,6.04-17.16,15.31-14.79,5.43,1.39,9.09,6.11,9.02,11.63-.07,5.66-4.04,10.56-9.59,11.83-.41,.09-.83,.19-1.31,.3v4.62c10.24,.68,18.48,5.07,24.35,13.56,4.72,6.83,6.3,14.45,5.17,22.64-2.16,15.68-16.19,27.66-31.86,27.2-15.99-.46-28.99-12.82-30.32-28.62-1.57-18.74,12.92-34.3,30.13-34.8Zm30.95,31.72c-.1-17.05-13.42-30.3-30.37-30.21-16.14,.09-29.43,13.98-29.35,30.67,.08,16.38,13.52,29.98,29.51,29.86,16.86-.12,30.31-13.62,30.2-30.32Zm-29.71-59.4c-6.24,.02-11.3,4.96-11.31,11.05,0,6.16,4.97,10.96,11.34,10.93,5.97-.02,11.06-5.04,11.06-10.91,0-6.14-4.95-11.08-11.09-11.06Zm-.77,23.2c.24,1.54,.46,2.99,.69,4.44,.09,0,.19-.02,.28-.02,.11-1.46,.22-2.91,.34-4.41h-1.3Z"
      />
      <path
        style={styles.two}
        d="M381.55,454.71c.1,16.7-13.34,30.19-30.2,30.32-16,.12-29.43-13.47-29.51-29.86-.08-16.69,13.21-30.59,29.35-30.67,16.95-.09,30.26,13.16,30.37,30.21Zm-12.47-11.02c-.46-10.11-8.31-17.29-18.43-16.84-9.15,.4-16.6,8.7-16.22,18.08,.39,9.61,8.43,17.26,17.77,16.79,10.48-.52,17-9.01,16.88-18.03Z"
      />
      <path
        style={styles.four}
        d="M351.84,395.31c6.14-.02,11.09,4.92,11.09,11.06,0,5.87-5.08,10.89-11.06,10.91-6.37,.02-11.35-4.77-11.34-10.93,0-6.09,5.07-11.03,11.31-11.05Zm-8.95,8.48c.04,.26,.03,.85,.24,1.35,.16,.39,.58,.68,.89,1.01,.29-.32,.71-.59,.84-.96,.52-1.52,1.53-2.58,2.89-3.34,2.01-1.12,4.12-1.41,6.35-.71,.35,.11,.96,.05,1.14-.17,.19-.23,.15-.89-.05-1.15-.67-.87-1.33-1.87-2.23-2.41-4.21-2.51-10.08,1.09-10.06,6.39Z"
      />
      <path
        style={styles.one}
        d="M351,418.51h2c-.18,1.52-.35,3-.52,4.47-.14,0-.29,.02-.43,.02-.34-1.47-.69-2.93-1.06-4.49Z"
      />
      <path d="M369.09,443.69c.12,9.02-6.4,17.51-16.88,18.03-9.34,.46-17.38-7.18-17.77-16.79-.38-9.38,7.07-17.68,16.22-18.08,10.12-.45,17.97,6.73,18.43,16.84Zm-17.18,16.79c8.76,0,15.93-7.25,15.93-16.14,0-8.78-7.14-16.29-15.54-16.33-8.94-.05-16.41,7.25-16.4,16.04,0,9.28,6.98,16.43,16.01,16.44Z" />
      <ellipse style={styles.two} cx="352" cy="406.5" rx="12" ry="11.5" />
      <polyline
        style={styles.one}
        points="351.07 418.51 353.13 418.51 353 423.5 351 423.72"
      />
      <path
        style={styles.three}
        d="M345.46,405.09c-.89-3.55,1.18-7.12,4.46-8.07,2.95-.86,6.26,.59,7.72,3.52-1.04-.25-3.47-.7-6.32,.23-3.41,1.11-5.26,3.49-5.86,4.32Z"
      />
      <path
        style={styles.three}
        d="M333,466c2.88,10.44,12.82,16.69,22,15,7.12-1.31,13.01-7.25,14.83-15-.74,1.14-5.99,8.86-15.83,10-8.11,.94-16.22-2.94-21-10Z"
      />
    </g>
  );
};

export default Retro;
