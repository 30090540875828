import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getAvatarOptions } from "../../api/avatar";
import useMiddleware from "../../api/useMiddleware";
import Piece from "../../avatar/Piece";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import LockerGlimmer from "../glimmers/LockerGlimmer";
import PiecePreview from "./PiecePreview";

const PieceOptions = ({ avatar, onChange, setSelected, type }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(null);

  const handleGetOptions = () => {
    getAvatarOptions(middleware, type).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setOptions(res?.options);
      }
    });
  };

  useEffect(() => {
    if (options == null) {
      handleGetOptions();
    }
  });

  useEffect(() => {
    if (!loading) {
      setSelected(avatar?.options[`${type}`]);
    }
  }, [loading]);

  useEffect(() => {
    if (avatar?.options) {
      setSelected(avatar?.options[`${type}`]);
    }
  }, [avatar]);

  const styles = {
    container: {
      width: "100%",
      overflow: "hidden",
      paddingTop: 2,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        gap={{ xs: 1 }}
      >
        {loading && <LockerGlimmer numItems={20} size={100} />}

        {!loading && options?.length > 0 && (
          <>
            {options?.map((option, i) => (
              <PiecePreview
                key={i}
                option={option}
                value={option?.value}
                selected={option?.value === avatar?.options[`${type}`]?.value}
                onClick={() => onChange(type, option)}
                owned={store?.user?.new_locker?.options[`${type}`]?.includes(
                  option?._id
                )}
                size={100}
              >
                <Piece
                  type={type}
                  color={avatar?.options[`${type}Color`]}
                  option={option?.value}
                  skinColor={avatar?.options?.skinColor}
                  size={100}
                />
              </PiecePreview>
            ))}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PieceOptions;
