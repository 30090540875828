import { Grid } from "@mui/material";
import MatchReadyTeamItem from "./MatchReadyTeamItem";

const MatchReadyState = ({ token }) => {
  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        alignItems="stretch"
        justifyContent="start"
        gap={{ xs: 2 }}
      >
        <MatchReadyTeamItem
          team={token?.creating_team}
          isCreatingTeam={true}
          token={token}
        />

        <MatchReadyTeamItem
          team={token?.joining_team}
          isCreatingTeam={false}
          token={token}
        />
      </Grid>
    </Grid>
  );
};

export default MatchReadyState;
