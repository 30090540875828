import { Divider, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AiOutlineTrophy } from "react-icons/ai";
import { IoSkullOutline } from "react-icons/io5";
import { RiSwordLine } from "react-icons/ri";
import { leaveTeam } from "../../api/teams";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  SET_TEAMS,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { getReadableDateFromId, numFormatter } from "../../utils/helpers";
import {
  cardDark,
  cardLight,
  cardVeryLight,
  detailIcon,
  doubtedBlue,
  green,
  large,
  normal,
  red,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import CoinBadge from "../custom/CoinBadge";
import CustomIconButton from "../custom/CustomIconButton";
import CustomMenuButton from "../custom/CustomMenuButton";
import CustomModal from "../custom/CustomModal";
import DetailContainer from "../custom/DetailContainer";
import DoubtedCoin from "../custom/DoubtedCoin";
import PrimaryButton from "../custom/PrimaryButton";
import SecondaryButton from "../custom/SecondaryButton";
import TeamInviteModal from "./TeamInviteModal";
import TeamMemberItem from "./TeamMemberItem";
import { HiOutlinePencilAlt } from "react-icons/hi";
import EditTeamModal from "./EditTeamModal";
import RivoxCoin from "../custom/RivoxCoin";

const TeamProfileModal = ({ open, onClose, team, viewOnly = false }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [leaveLoading, setLeaveLoading] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [selected, setSelected] = useState("stats");
  const [isOwner, setIsOwner] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const handleClose = () => {
    setLeaveLoading(false);
    setInviteOpen(false);
    setSelected("stats");
    onClose();
  };

  const handleLeave = () => {
    setLeaveLoading(true);
    leaveTeam(middleware, team?._id).then((res) => {
      setLeaveLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({ type: SET_TEAMS, payload: res?.teams });
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        handleClose();
      }
    });
  };

  useEffect(() => {
    if (team) {
      if (team?.team_owner === store?.user?._id) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  }, [team]);

  const styles = {
    width: {
      width: "100%",
    },
    title: {
      color: text,
      textAlign: "left",
      fontSize: 28,
      fontWeight: 800,
    },
    winContainer: {
      borderRadius: 2,
      padding: 2,
      backgroundColor: "rgba(114, 242, 56, .15)",
    },
    lossContainer: {
      borderRadius: 2,
      padding: 2,
      backgroundColor: "rgba(245, 74, 74, .15)",
    },
    containerLabel: {
      fontSize: normal,
      fontWeight: 800,
    },
    label: {
      fontSize: large,
      color: text,
      fontWeight: 700,
    },
  };

  return (
    <>
      <TeamInviteModal
        team={team}
        open={inviteOpen}
        onClose={() => setInviteOpen(false)}
      />
      <EditTeamModal
        team={team}
        open={editOpen}
        onClose={() => setEditOpen(false)}
      />

      <CustomModal
        titleOptions={
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <Grid item>
                    <Typography sx={styles.title}>{team?.name}</Typography>
                  </Grid>

                  {!viewOnly && (
                    <CustomIconButton
                      label="Edit team name"
                      icon={
                        <HiOutlinePencilAlt
                          style={{ fontSize: 20, color: text }}
                        />
                      }
                      onClick={() => setEditOpen(true)}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid item>
                <Typography
                  sx={{ fontSize: 12, fontWeight: 500, color: secondaryText }}
                >
                  Created on{" "}
                  <span style={{ color: text }}>
                    {getReadableDateFromId(team?._id)}
                  </span>
                </Typography>
              </Grid>

              <Grid
                item
                sx={{
                  borderBottom: `1px solid ${cardVeryLight}`,
                  width: "100%",
                  marginTop: 2,
                }}
              >
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <CustomMenuButton
                    label="Stats"
                    onClick={() => setSelected("stats")}
                    selected={selected === "stats"}
                  />

                  <CustomMenuButton
                    label="Roster"
                    onClick={() => setSelected("roster")}
                    selected={selected === "roster"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        open={open}
        onClose={handleClose}
        primaryButton={
          <>
            {viewOnly ? (
              <SecondaryButton label="Close" onClick={handleClose} />
            ) : (
              <SecondaryButton
                bg={doubtedBlue}
                label="Invite"
                onClick={() => setInviteOpen(true)}
              />
            )}
          </>
        }
        secondaryButton={
          <>
            {viewOnly ? null : (
              <SecondaryButton
                label="Leave team"
                loading={leaveLoading}
                onClick={handleLeave}
              />
            )}
          </>
        }
      >
        {selected === "stats" && (
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.label}>
                Stats
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <DetailContainer
                    label="Earnings"
                    value={numFormatter().format(team?.earnings)}
                    icon={
                      <Grid item>
                        <RivoxCoin size={35} />
                      </Grid>
                    }
                    bg={cardLight}
                  />

                  <DetailContainer
                    label="Played"
                    value={team?.wins + team?.losses}
                    icon={<RiSwordLine style={detailIcon} />}
                    bg={cardLight}
                  />

                  <DetailContainer
                    label="Wins"
                    value={team?.wins}
                    icon={<AiOutlineTrophy style={detailIcon} />}
                    bg={cardLight}
                  />

                  <DetailContainer
                    label="Losses"
                    value={team?.losses}
                    icon={<IoSkullOutline style={detailIcon} />}
                    bg={cardLight}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {selected === "roster" && (
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
            >
              <Grid item sx={styles.label}>
                Roster
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="start"
                  gap={{ xs: 1 }}
                >
                  {team?.users?.map((user, i) => (
                    <>
                      <TeamMemberItem
                        team={team}
                        user={user}
                        key={i}
                        viewOnly={viewOnly}
                      />
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </CustomModal>
    </>
  );
};

export default TeamProfileModal;
