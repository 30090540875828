const Duoma = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#f8c819",
    },
    three: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    four: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    five: {
      fill: "#3efffa",
    },
    six: {
      fill: "#ff36a8",
    },
    seven: {
      fill: "#ffee05",
    },
  };

  return (
    <g>
      <g>
        <path
          style={styles.one}
          d="m586.77,362.83c-.73,1.44-2.08,4.58-1.91,8.74.2,4.7,2.22,8.01,3.13,9.33,9.15.16,18.31.32,27.46.48.75-1.09,4.05-6.14,2.95-12.89-.87-5.32-3.98-8.64-5.12-9.75-8.84,1.36-17.68,2.72-26.52,4.08Z"
        />
        <path
          style={styles.two}
          d="m611.44,368.91c.49.99,1.6,3.52,1.23,6.84-.34,3.05-1.73,5.15-2.4,6.04-4.57-.48-9.14-.96-13.71-1.44l1.45-2.81,1.17-8.15,12.27-.48Z"
        />
        <path
          style={styles.six}
          d="m611.97,360.04c.39.57,1.34,2.13,1.32,4.31-.02,2.63-1.44,4.39-1.85,4.87-2.76.06-5.51.12-8.27.17-.14-3.55-.28-7.1-.42-10.65,3.07.43,6.15.86,9.22,1.29Z"
        />
        <path
          style={styles.seven}
          d="m589.46,363.64c-1.25,1.46-1.52,3.48-.7,5.09,1.01,1.98,3.15,2.36,3.38,2.4,3.68-.58,7.35-1.16,11.03-1.73.42-.69,1.09-1.98,1.3-3.74.22-1.89-.19-3.39-.46-4.16-4.85.72-9.7,1.43-14.55,2.15Z"
        />
        <path d="m573.1,383.46c1.07-.72,2.56-1.57,4.44-2.22,1.65-.57,3-.79,4.01-.92,5.52-.75,10.81-.57,11.16-.55,4.54.18,4.87.4,10.85.8,6.25.42,9.43.52,12.7.37,2.78-.13,6.61-.61,10.91-2.16,3.22-1.16,5.81-2.6,7.77-3.88-.58.89-1.16,1.86-1.73,2.9-.36.65-.68,1.29-.99,1.91-.13.69-.62,1.18-1.17,1.23-.38.03-.68-.16-.8-.25-.08-.05-.67-.38-1.36-.12-.76.28-.97,1.04-.99,1.11-.09.2-.73,1.56-2.28,1.97-1.09.29-1.99-.06-2.28-.18-.45-.05-1.07-.06-1.79.06-.73.13-1.32.36-1.73.55-.58.43-1.15.86-1.73,1.29-.1.1-.43.39-.92.43-.82.06-1.33-.64-1.36-.68-.49-.64-.99-1.27-1.48-1.91-.77-.11-1.71-.18-2.77-.12-1.48.08-2.71.38-3.64.68-.53.64-1.07,1.27-1.6,1.91-.15.06-.46.17-.86.12-.54-.07-.88-.39-.99-.49-.64-.84-1.27-1.68-1.91-2.53l-3.58-.12-2.71,2.22c-.12.07-.44.22-.86.18-.46-.04-.76-.28-.86-.37-.43-.9-.86-1.81-1.29-2.71-1.02-.17-2.7-.3-4.62.25-.95.27-1.73.64-2.34.99-.22.11-.58.26-1.05.31-.61.06-1.1-.09-1.36-.18-.78-.43-1.56-.86-2.34-1.29-1.09-.01-2.36.04-3.76.25-1.84.27-3.42.72-4.68,1.17Z" />
        <path d="m569.15,378.47c3.34-3.76,10.27-10.47,21.14-13.96,4.91-1.57,9.36-2.09,12.87-2.19.09.31.17.62.26.92l6.48-1.54c4.51-.09,10.33.21,16.94,1.66,5.78,1.27,10.61,3.09,14.36,4.81-1.6-.98-3.43-2.29-5.3-4.01-2.6-2.39-4.45-4.81-5.73-6.78-.12-.21-.08-.47.08-.64.17-.18.44-.22.66-.1,2.65.7,5.3,1.4,7.95,2.1l-8.38-3.14c-5.05-.72-12.19-1.42-20.78-1.19-8.83.24-14.96.4-22.22,3.14-4.2,1.59-11.31,4.99-18.34,12.59,1.48-1.04,2.96-2.08,4.44-3.11.13-.05.24-.06.31-.02.32.2.06,1.36-.8,3.5-1.31,2.65-2.63,5.3-3.94,7.95Z" />
        <path
          style={styles.three}
          d="m569.58,365.4c3.16-2.87,7.8-6.47,13.99-9.55,5.86-2.91,11.23-4.42,15.29-5.24"
        />
        <path style={styles.three} d="m567.06,361.64s22.13-19.48,44.38-13.99" />
        <path style={styles.three} d="m594.61,343.52s-17.14,3.45-27.55,13.75" />
        <path style={styles.three} d="m621.36,347.53s11.28,1.91,18.98,7.15" />
        <path style={styles.four} d="m587.86,380.35s-7.3-10.52.83-18.1" />
        <path style={styles.four} d="m615.2,381.36s8.57-11.77-1.6-22" />
        <path d="m554.91,333.9c2.79-4.17,7.41-9.87,14.43-14.59,2.29-1.54,6.08-4.09,11.08-5.62,2.04-.63,5.68-1.51,8.26-2.1,1.43-.32,2.62-.57,3.45-.74,4.43,1.14,8.02,1.98,10.44,2.52,5.17,1.16,8.02,1.68,12.24,3.15,2.3.8,4.14,1.58,5.3,2.1,9.29.41,18.57.82,27.86,1.23l-9.49,4.07,11.46,9.99-13.68-.99,8.38,10.6-11.71-5.55,6.66,8.7c-4.65-4.38-24.19-21.77-52.88-21.13-14.28.32-25.37,4.98-31.81,8.36Z" />
        <path
          style={styles.five}
          d="m595.47,379.33c-2.71.21-5.04-1.52-5.49-3.76-.33-1.62.37-3.36,1.73-4.54,1.22-.41,2.73-.8,4.5-1.01,1.13-.13,2.16-.16,3.08-.14.25.29,1.82,2.17,1.42,4.64-.39,2.41-2.5,4.39-5.24,4.81Z"
        />
        <path d="m600.5,361.64l-.12,7.49-9.49.59,1.76,1.17,5.21-.92,14.08-.77c.02-.06.07-.22,0-.37-.11-.21-.43-.32-.77-.18-.42.01-.84.02-1.25.03.01-.02.17-.24.08-.49-.08-.21-.29-.35-.52-.34-.37.17-.78.34-1.23.49-.92.31-1.77.49-2.5.59-1.46.05-2.92.1-4.38.15-.03-2.53-.06-5.05-.09-7.58l-.77.15Z" />
        <path d="m592.67,372.12c-.11.09-.16.17-.15.25.03.23.64.4,1.85.49.71-.13,1.51-.24,2.37-.31.87-.07,1.68-.08,2.4-.06v.52c-.54-.02-2,0-3.4.97-.79.55-1.27,1.21-1.53,1.65-.41.61-.82,1.21-1.23,1.82l.89.18c.34-.35.8-.75,1.37-1.12.7-.46,1.37-.76,1.9-.94l.34,1.66c-.06.34-.16.73-.32,1.14-.35.91-.86,1.56-1.25,1.98h.65c.6-.8,1.19-1.61,1.79-2.41l.09-2.74h-1.39l-2.43,1.04c.58-.56,1.32-1.2,2.25-1.81,1.03-.68,2-1.13,2.8-1.45l.74-1.05c.1-.05.16-.15.15-.25-.01-.13-.21-.19-.28-.22-1.26-.44-3.73.2-3.73.2-.9.23-2.19.47-3.88.45Z" />
        <path d="m600.1,373.34h5.18v.76l-3.13,2.73h.72l1.29-.57.92-.02-.09.43-5.44,3.82,3.99.08,3.34-1.56c-.14,0-.36.03-.62.08-.68.13-1.12.35-1.6.55-.37.16-.9.36-1.59.55h-2.03l4.01-2.99.66-.02c1.45.68,2.03,1.48,2.3,2.1.35.8.22,1.42.71,1.82.59.48,1.57.25,2.28,0v-.83c-.45-.61-1.19-1.47-2.3-2.25-1.03-.72-2.01-1.12-2.71-1.34.53-.43,1.07-.86,1.6-1.29.04-.1.02-.17,0-.2-.07-.16-.36-.25-.72-.17l-2.3.89h-.69l3.31-2.91h2.31c.36-.18.72-.35,1.07-.53.19-.05.33-.19.34-.35.02-.21-.2-.45-.51-.43h-3.08l-1.08.96h-.51l.05-.39,1.11-.71.06-.94h-.97l-1.43,1.26v.65c-.81-.09-2.02-.11-3.36.34-.42.14-.79.31-1.11.48Z" />
        <circle style={styles.one} cx="610.18" cy="375.39" r="1.57" />
      </g>
      <g>
        <path
          style={styles.one}
          d="m480.43,362.02c.73,1.44,2.08,4.58,1.91,8.74-.2,4.7-2.22,8.01-3.13,9.33-9.15.16-18.31.32-27.46.48-.75-1.09-4.05-6.14-2.95-12.89.87-5.32,3.98-8.64,5.12-9.75,8.84,1.36,17.68,2.72,26.52,4.08Z"
        />
        <path
          style={styles.two}
          d="m455.76,368.11c-.49.99-1.6,3.52-1.23,6.84.34,3.05,1.73,5.15,2.4,6.04,4.57-.48,9.14-.96,13.71-1.44l-1.45-2.81-1.17-8.15-12.27-.48Z"
        />
        <path
          style={styles.six}
          d="m455.23,359.23c-.39.57-1.34,2.13-1.32,4.31.02,2.63,1.44,4.39,1.85,4.87,2.76.06,5.51.12,8.27.17.14-3.55.28-7.1.42-10.65-3.07.43-6.15.86-9.22,1.29Z"
        />
        <path
          style={styles.seven}
          d="m477.74,362.83c1.25,1.46,1.52,3.48.7,5.09-1.01,1.98-3.15,2.36-3.38,2.4-3.68-.58-7.35-1.16-11.03-1.73-.42-.69-1.09-1.98-1.3-3.74-.22-1.89.19-3.39.46-4.16l14.55,2.15Z"
        />
        <path d="m494.1,382.65c-1.07-.72-2.56-1.57-4.44-2.22-1.65-.57-3-.79-4.01-.92-5.52-.75-10.81-.57-11.16-.55-4.54.18-4.87.4-10.85.8-6.25.42-9.43.52-12.7.37-2.78-.13-6.61-.61-10.91-2.16-3.22-1.16-5.81-2.6-7.77-3.88.58.89,1.16,1.86,1.73,2.9.36.65.68,1.29.99,1.91.13.69.62,1.18,1.17,1.23.38.03.68-.16.8-.25.08-.05.67-.38,1.36-.12.76.28.97,1.04.99,1.11.09.2.73,1.56,2.28,1.97,1.09.29,1.99-.06,2.28-.18.45-.05,1.07-.06,1.79.06.73.13,1.32.36,1.73.55.58.43,1.15.86,1.73,1.29.1.1.43.39.92.43.82.06,1.33-.64,1.36-.68.49-.64.99-1.27,1.48-1.91.77-.11,1.71-.18,2.77-.12,1.48.08,2.71.38,3.64.68.53.64,1.07,1.27,1.6,1.91.15.06.46.17.86.12.54-.07.88-.39.99-.49.64-.84,1.27-1.68,1.91-2.53l3.58-.12,2.71,2.22c.12.07.44.22.86.18.46-.04.76-.28.86-.37.43-.9.86-1.81,1.29-2.71,1.02-.17,2.7-.3,4.62.25.95.27,1.73.64,2.34.99.22.11.58.26,1.05.31.61.06,1.1-.09,1.36-.18.78-.43,1.56-.86,2.34-1.29,1.09-.01,2.36.04,3.76.25,1.84.27,3.42.72,4.68,1.17Z" />
        <path d="m498.04,377.66c-3.34-3.76-10.27-10.47-21.14-13.96-4.91-1.57-9.36-2.09-12.87-2.19-.09.31-.17.62-.26.92l-6.48-1.54c-4.51-.09-10.33.21-16.94,1.66-5.78,1.27-10.61,3.09-14.36,4.81,1.6-.98,3.43-2.29,5.3-4.01,2.6-2.39,4.45-4.81,5.73-6.78.12-.21.08-.47-.08-.64-.17-.18-.44-.22-.66-.1-2.65.7-5.3,1.4-7.95,2.1l8.38-3.14c5.05-.72,12.19-1.42,20.78-1.19,8.83.24,14.96.4,22.22,3.14,4.2,1.59,11.31,4.99,18.34,12.59-1.48-1.04-2.96-2.08-4.44-3.11-.13-.05-.24-.06-.31-.02-.32.2-.06,1.36.8,3.5,1.31,2.65,2.63,5.3,3.94,7.95Z" />
        <path
          style={styles.three}
          d="m497.61,364.59c-3.16-2.87-7.8-6.47-13.99-9.55-5.86-2.91-11.23-4.42-15.29-5.24"
        />
        <path
          style={styles.three}
          d="m500.14,360.83s-22.13-19.48-44.38-13.99"
        />
        <path style={styles.three} d="m472.59,342.71s17.14,3.45,27.55,13.75" />
        <path style={styles.three} d="m445.83,346.72s-11.28,1.91-18.98,7.15" />
        <path style={styles.four} d="m479.33,379.55s7.3-10.52-.83-18.1" />
        <path style={styles.four} d="m452,380.56s-8.57-11.77,1.6-22" />
        <path d="m512.28,333.1c-2.79-4.17-7.41-9.87-14.43-14.59-2.29-1.54-6.08-4.09-11.08-5.62-2.04-.63-5.68-1.51-8.26-2.1-1.43-.32-2.62-.57-3.45-.74-4.43,1.14-8.02,1.98-10.44,2.52-5.17,1.16-8.02,1.68-12.24,3.15-2.3.8-4.14,1.58-5.3,2.1-9.29.41-18.57.82-27.86,1.23l9.49,4.07-11.46,9.99,13.68-.99-8.38,10.6,11.71-5.55-6.66,8.7c4.65-4.38,24.19-21.77,52.88-21.13,14.28.32,25.37,4.98,31.81,8.36Z" />
        <path
          style={styles.five}
          d="m471.72,378.52c2.71.21,5.04-1.52,5.49-3.76.33-1.62-.37-3.36-1.73-4.54-1.22-.41-2.73-.8-4.5-1.01-1.13-.13-2.16-.16-3.08-.14-.25.29-1.82,2.17-1.42,4.64.39,2.41,2.5,4.39,5.24,4.81Z"
        />
        <path d="m466.7,360.83l.12,7.49,9.49.59-1.76,1.17-5.21-.92-14.08-.77c-.02-.06-.07-.22,0-.37.11-.21.43-.32.77-.18.42.01.84.02,1.25.03-.01-.02-.17-.24-.08-.49.08-.21.29-.35.52-.34.37.17.78.34,1.23.49.92.31,1.77.49,2.5.59,1.46.05,2.92.1,4.38.15.03-2.53.06-5.05.09-7.58l.77.15Z" />
        <path d="m474.53,371.31c.11.09.16.17.15.25-.03.23-.64.4-1.85.49-.71-.13-1.51-.24-2.37-.31-.87-.07-1.68-.08-2.4-.06v.52c.54-.02,2,0,3.4.97.79.55,1.27,1.21,1.53,1.65.41.61.82,1.21,1.23,1.82l-.89.18c-.34-.35-.8-.75-1.37-1.12-.7-.46-1.37-.76-1.9-.94l-.34,1.66c.06.34.16.73.32,1.14.35.91.86,1.56,1.25,1.98h-.65c-.6-.8-1.19-1.61-1.79-2.41l-.09-2.74h1.39s2.43,1.04,2.43,1.04c-.58-.56-1.32-1.2-2.25-1.81-1.03-.68-2-1.13-2.8-1.45l-.74-1.05c-.1-.05-.16-.15-.15-.25.01-.13.21-.19.28-.22,1.26-.44,3.73.2,3.73.2.9.23,2.19.47,3.88.45Z" />
        <path d="m467.1,372.53h-5.18s0,.76,0,.76l3.13,2.73h-.72s-1.29-.57-1.29-.57l-.92-.02.09.43,5.44,3.82-3.99.08-3.34-1.56c.14,0,.36.03.62.08.68.13,1.12.35,1.6.55.37.16.9.36,1.59.55h2.03s-4.01-2.99-4.01-2.99l-.66-.02c-1.45.68-2.03,1.48-2.3,2.1-.35.8-.22,1.42-.71,1.82-.59.48-1.57.25-2.28,0v-.83c.45-.61,1.19-1.47,2.3-2.25,1.03-.72,2.01-1.12,2.71-1.34-.53-.43-1.07-.86-1.6-1.29-.04-.1-.02-.17,0-.2.07-.16.36-.25.72-.17l2.3.89h.69s-3.31-2.91-3.31-2.91h-2.31c-.36-.18-.72-.35-1.07-.53-.19-.05-.33-.19-.34-.35-.02-.21.2-.45.51-.43h3.08s1.08.96,1.08.96h.51s-.05-.39-.05-.39l-1.11-.71-.06-.94h.97s1.43,1.26,1.43,1.26v.65c.81-.09,2.02-.11,3.36.34.42.14.79.31,1.11.48Z" />
        <circle style={styles.one} cx="457.02" cy="374.58" r="1.57" />
      </g>
    </g>
  );
};

export default Duoma;
