import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMiddleware from "../../api/useMiddleware";
import { updateTOSVersion } from "../../api/user";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  SET_TOS_VERSION,
  SET_USER,
  StoreDispatch,
} from "../../store/Store";
import { CLIENT_URL } from "../../utils/constants";
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  small,
  text,
} from "../../utils/themeContstants";
import CustomCheckbox from "../custom/CustomCheckbox";
import CustomModal from "../custom/CustomModal";
import RivoxCoin from "../custom/RivoxCoin";
import SecondaryButton from "../custom/SecondaryButton";

const UpdateTOSModal = ({ open, onClose }) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const navigate = useNavigate();

  const [acceptLoading, setAcceptLoading] = useState(false);
  const [canAccept, setCanAccept] = useState(false);

  const handleClose = () => {
    setAcceptLoading(false);
    setCanAccept(false);
    onClose();
  };

  const handleAccept = () => {
    setAcceptLoading(true);
    updateTOSVersion(middleware).then((res) => {
      setAcceptLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        setCanAccept(false);
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        updateStore({ type: SET_USER, payload: res?.user });
        updateStore({ type: SET_TOS_VERSION, payload: res?.tos_version });
        handleClose();
      }
    });
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
      maxWidth: isDesktop ? 500 : null,
    },
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Update to our Terms of Service"
      titleSize="large"
      icon={<RivoxCoin size={40} />}
      primaryButton={
        <SecondaryButton
          bg={doubtedBlue}
          label="Accept"
          loading={acceptLoading}
          disabled={!canAccept}
          onClick={handleAccept}
        />
      }
      secondaryButton={
        <SecondaryButton
          label="Terms of Service"
          onClick={() => {
            navigate("/support/terms");
            handleClose();
          }}
        />
      }
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Typography sx={styles.description}>
            As we continue to improve Rivox' products and features,
            we have also been working on improving our{" "}
            <a
              style={{ fontWeight: 800, color: text }}
              href={`${CLIENT_URL}/support/terms`}
              target="_blank"
            >
              Terms of Service
            </a>
            .
            <br />
            <br />
            Our{" "}
            <a
              style={{ fontWeight: 800, color: text }}
              href={`${CLIENT_URL}/support/terms`}
              target="_blank"
            >
              Terms of Service
            </a>{" "}
            now include terms about using our new anti-cheat:{" "}
            <span style={{ fontWeight: 800, color: doubtedBlue }}>
              Rivox AC
            </span>
            . This anti-cheat will allow us to stop cheaters in all pc based
            games on our platform, and continue to improve the quality of the
            experience for you!
            <br />
            <br />
            Please take a moment to read our{" "}
            <a
              style={{ fontWeight: 800, color: text }}
              href={`${CLIENT_URL}/support/terms`}
              target="_blank"
            >
              Terms of Service
            </a>
            , and keep in mind that if you do not agree to these new terms, you
            can open a ticket in our{" "}
            <a
              style={{ fontWeight: 800, color: text }}
              href={"https://discord.gg/rivox"}
              target="_blank"
            >
              Discord
            </a>{" "}
            and we can help you disable your account. Thanks for using
            Rivox.
            <br />
            <br />- Rivox
          </Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider sx={{ width: "100%", backgroundColor: cardVeryLight }} />
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 1 }}
            wrap="nowrap"
          >
            <Grid>
              <CustomCheckbox
                checked={canAccept}
                onChange={(value) => setCanAccept(value)}
                color={doubtedBlue}
                disabled={acceptLoading}
                checkColor={text}
              />
            </Grid>

            <Grid>
              <Typography
                sx={{
                  fontSize: normal,
                  color: text,
                  fontWeight: 600,
                  maxWidth: 400,
                }}
              >
                I have read and accept the new Terms of Service.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default UpdateTOSModal;
