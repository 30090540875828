const Creeper = () => {
  const styles = {
    one: {
      fill: "#818481",
      strokeMiterLimit: 10,
      stroke: "#000",
    },
    two: {
      fill: "#818481",
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
    },
    three: {
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#78df86",
      stroke: "#78df86",
    },
    four: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2.5,
      fill: "none",
    },
    five: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 1.5,
    },
    six: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
    },
    seven: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
      fill: "none",
    },
    eight: {
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#c5f0bc",
      stroke: "#c5f0bc",
    },
    nine: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
      fill: "#393a39",
    },
    ten: {
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#48a843",
      stroke: "#48a843",
    },
    eleven: {
      strokeMiterLimit: 10,
      fill: "#78df86",
      stroke: "#78df86",
    },
    twelve: {
      fill: "#01ad6b",
    },
    thirteen: {
      fill: "#f4c8a4",
    },
    fourteen: {
      fill: "#02ab55",
    },
  };

  return (
    <g>
      <path
        style={styles.thirteen}
        d="m288.78,746.86c-.4-14.89.38-33.27,4.22-53.91,2.91-15.7,6.98-29.41,11.15-40.89,18.82-16.26,44.95-35.52,78.85-52.06,24.12-11.77,46.61-19.45,65.69-24.57,21.07,1.84,42.13,3.67,63.2,5.51,19.71,2.58,50.2,9.07,83.11,27.06,26.46,14.46,45.41,31.41,57.79,44.37,4.94,15.77,9.6,33.7,13.21,53.58,4.64,25.54,6.56,48.82,7.05,68.96-14.55,11.97-36.17,28.56-66.05,42-51.38,23.12-97.39,24.63-120,23.99-46.92-1.33-82.48-14.45-100-21.99-49.98-21.53-81.86-53.17-98.22-72.04Z"
      />
      <path
        style={styles.thirteen}
        d="m518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12.32,7.46,1.17,8.35.49.51,1.02.81,1.4.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22.48-.66,1.01-1.11,1.37-1.37.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
      />
      <path d="m460,523c1.9,1.69,3.9,3.36,6,5,15.78,12.32,32.48,19.14,47,23,1.96-5.48,3.91-10.96,5.87-16.44-4.61.02-9.68-.16-15.13-.64-.79-.07-6.86-.61-12.73-1.62-12.45-2.13-23.75-6.29-31-9.31Z" />
      <path
        style={styles.six}
        d="m561.21,467.57c0-.25-8.28-1.72-17.31,1.49-9.75,3.46-15.37,10.59-15.15,10.86.22.28,6.85-7.14,18.48-10.53,7.81-2.28,13.99-1.6,13.98-1.82Z"
      />
      <g>
        <path
          style={styles.seven}
          d="m369.44,383.6c.52-3.87,1.04-7.74,1.57-11.61-1.13.65-2.26,1.3-3.39,1.96-1.05.61-2.09,1.22-3.13,1.83-.3-.25-4.21-3.44-8.61-2.09-4.19,1.29-7.07,6.16-6.13,11.48,0,.18.02,1.28.46,2.68.49,1.56,1.5,3.49,3.58,4.89,3.92,2.63,8.12,1.06,8.48.91"
        />
        <path
          style={styles.seven}
          d="m364.09,380.99c-1.16-.12-3.95-.24-6.91,1.3-4.06,2.12-5.53,5.85-5.87,6.78"
        />
      </g>
      <g>
        <path d="m438.49,342.87c-.5-4.69,11.16-10.72,21.78-12.26.97-.14,3.6-.49,7.04-.39,0,0,13.71.38,24.13,9.39,1.57,1.36,3.32,3.19,3.26,5.48,0,.16-.09,2.26-1.43,3-1.57.87-3.83-.71-5.61-1.7-2.97-1.65-6.07-2.14-12.26-3.13-8.22-1.31-15.65.82-19.17,1.83-5.76,1.65-8.54,3.53-12.52,2.22-.48-.16-4.92-1.67-5.22-4.43Z" />
        <path d="m633.73,345.36c.5-4.69-11.16-10.72-21.78-12.26-.97-.14-3.6-.49-7.04-.39,0,0-13.71.38-24.13,9.39-1.57,1.36-3.32,3.19-3.26,5.48,0,.16.09,2.26,1.43,3,1.57.87,3.83-.71,5.61-1.7,2.97-1.65,6.07-2.14,12.26-3.13,8.22-1.31,15.65.82,19.17,1.83,5.76,1.65,8.54,3.53,12.52,2.22.48-.16,4.92-1.67,5.22-4.43Z" />
      </g>
      <path
        style={styles.four}
        d="m512.54,395.09c4.79.05,10.54-.24,16.96-1.3,6.64-1.1,12.34-2.77,16.96-4.43,5.84.63,10.81,4.37,12.78,9.65,2.45,6.58-.93,12.35-1.37,13.09-1.59,2.79-4.34,6.84-8.8,10.65-5.05,4.32-10.1,6.48-13.3,7.57"
      />
      <g>
        <path
          style={styles.five}
          d="m446.15,367.35c-.18.31,10.22,11.45,24,10.43,12.03-.89,20.08-10.57,19.83-10.96-.25-.38-8.63,8.54-20.97,9.03-13.12.53-22.68-8.8-22.86-8.51Z"
        />
        <path
          style={styles.five}
          d="m582.11,367.35c-.18.31,10.22,11.45,24,10.43,12.03-.89,20.08-10.57,19.83-10.96-.25-.38-8.63,8.54-20.97,9.03-13.12.53-22.68-8.8-22.86-8.51Z"
        />
      </g>
      <path
        style={styles.fourteen}
        d="m440.51,555.85c3.53,10.48-5.05,23.1-16.79,40.38-2.73,4.02-5.14,7.25-6.83,9.44,7.16,7.87,6.88,15.34,8.79,18.38.98,1.56-45.1,46.05-45.1,46.05l-10.66-32.22-41.38-5.11-22.26,17.48.77,36.55-14.41,52.57.19,13.11c3.64,3.62,8.62,8.45,14.69,13.98,6.82,6.22,14.09,12.84,23.49,20.01,5.8,4.43,21.06,15.66,42.95,26.46,8.69,4.28,16.25,7.43,21.92,9.62,20.1-10.54,40.21-21.09,60.31-31.63l30.62-103.21c-1.01-8.52-2.01-20.81-1.6-35.56,1.96-70.32,33.46-104.03,16.4-125.78-9.36-11.93-27.56-12.9-37.4-11.36-17.45,2.73-34.62,17.39-33,28.38.88,5.98,6.84,5.21,9.29,12.47Z"
      />
      <path
        style={styles.fourteen}
        d="m517.43,445.93l-4.89,57.98-34.27,10.9s-40.3,3.39-42.41,2.47-6.38-17.29-6.38-17.29l32.65-41.72,48.39-12.15,6.91-.2Z"
      />
      <path
        style={styles.fourteen}
        d="m550.5,509.98l-27.33,23.38-6,25.08,18.13,17.39s-23.87,11.77-24.46,12.65-.72,13.84,0,14.53,16.77,6.08,16.77,6.08l16.27-6.89,12.65,10.07,21.76-.45-4.01-14.78-24.66-7.74,6.93-10.19,4.6-14.37,1.86-22.1-11.02-9.27v-2.23s34.35-10.23,47.91-20.31c13.53-10.04,28-28.42,28-28.42l-93.2,15.24-18.98,16.93,34.76-4.61Z"
      />
      <path
        style={styles.fourteen}
        d="m364.81,409.35s16.58,54.49,35.73,71.6,38.9-7.49,38.9-7.49v-55.71l-1.55-51.19-73.08,42.79Z"
      />
      <path
        style={styles.fourteen}
        d="m425.47,259.44s-23.8,10.64-24.35,15.97,7.54,22.09,7.54,22.09l46.7,4.86,27.39,1.2-2.23-28.15s-17.34-19.84-18.38-19.52-36.66,3.55-36.66,3.55Z"
      />
      <path
        style={styles.fourteen}
        d="m530.81,250.62c-.87,2.73,1.76,40.04,3.85,38.99s36.78-9.1,36.78-9.1c0,0,3.66-18.68,2.35-20.32s-42.98-9.57-42.98-9.57Z"
      />
      <path
        style={styles.fourteen}
        d="m584.22,255.89l29.33,17.07s6.41,24.35,4.84,24.54-24,0-24,0l-19.37-24.85-1.24-14.87,9.31-1.89"
      />
      <polygon
        style={styles.fourteen}
        points="517.17 322.74 518.87 348.33 552.78 341.76 544.7 320.92 517.17 322.74"
      />
      <path
        style={styles.fourteen}
        d="m615.65,350.83s28.94-5.67,32.87-10.36,14.88-2.08,14.88-2.08v21.13s-18.11,12.45-20.41,12.81-17.05,1.28-17.05,1.28l-10.29-22.78Z"
      />
      <path
        style={styles.fourteen}
        d="m663.4,388.68l-.53,2.08-9.52,37.63-15.78,4.96s-5.87-17.61-5.48-18.39,17.87-25.61,17.87-25.61"
      />
      <path
        style={styles.fourteen}
        d="m648.08,659.72l-31.76-40.4c-2.82-1.05-5.44-2.45-7.85-4.15l-34.34-10.3-74.7-18.78s.52,21.71-6.39,30.88c-6.91,9.16-.55,1.67-.55,1.67l4.84,15.9-4.84,48.26.36,32.09,6.59,57.98c-17.83,16.74-35.66,33.48-53.48,50.22,17.3-.03,34.61-.07,51.91-.1l-1.57,17.98c20.27,0,48.69-2.27,80.87-12.41,41.16-12.96,70.76-33.22,88.49-47.54.12-6.83.39-17.04.71-29.48.39-14.79.84-29.67,1.1-30.07.51-.78-19.4-61.76-19.4-61.76Z"
      />
      <path
        style={styles.fourteen}
        d="m423.36,271.22l-35.54,34.22,2.22,66.89,41.7,96.32,41.72,152.07,71.89-14.68s-.26-62.44-1.7-66.7,44.1-61.43,45.07-61.8,37.69-14.82,37.69-14.82c0,0,15.32-28.33,15.13-34.33s11.66-77.53,10.95-80.63-13.89-57.45-14.11-60.6-23.9-21.94-23.9-21.94l-30.84-12.9-160.28,18.9Z"
      />
      <polygon
        style={styles.twelve}
        points="396.05 345.42 433.57 351.68 431.09 361.48 400.17 361.48 390.04 353.13 396.05 345.42"
      />
      <path
        style={styles.twelve}
        d="m357.7,359.71c-.01-.22-.09-2.34,1.39-3.45,1.56-1.17,4.28-.8,6,1.3,1.37.29,3.23.84,5.22,1.96,2.86,1.6,4.66,3.64,5.66,4.95l.81,11.07-12.29,4.53-5.8-3.44-.98-16.92Z"
      />
      <path
        style={styles.twelve}
        d="m365.35,409.83l14.61,8s-2.24-4.4-1.49,7.04c.02.27.04.54.06.83l-8.74-2.02-4.44-13.85Z"
      />
      <polygon
        style={styles.twelve}
        points="383.76 439.74 385.3 455.65 411.8 452.95 417.26 444.89 418.7 437.13 383.76 439.74"
      />
      <path
        style={styles.twelve}
        d="m456.78,445.93l15.13,7.76-2.09,7.57s-15.26.13-15.65,0-6.52-11.61-6.52-11.61l9.13-3.72Z"
      />
      <path
        style={styles.twelve}
        d="m463.17,472.8s10.74-1.63,48.09-.98v14.04l-48.09.7v-13.76Z"
      />
      <path
        style={styles.twelve}
        d="m441.26,509.98l-3.91,9.2,18.52,6.55s23.01.03,23.83,0,7.52-12.55,7.52-12.55l-1.63-3.91s-34.16-1.3-44.33.72Z"
      />
      <path
        style={styles.twelve}
        d="m433.37,526.35c-.06,0,19.79-6.58,19.79-6.58v9.52s-2.37,6.36-17.14,15.99l-2.66-18.93Z"
      />
      <polygon
        style={styles.twelve}
        points="522.91 549.83 537.66 551 538.31 563.52 526.24 568.74 523.43 577.22 522.91 549.83"
      />
      <polygon
        style={styles.twelve}
        points="540.26 568 539.74 585.3 531.89 584.01 521.61 576.65 530.35 566.35 540.26 568"
      />
      <polygon
        style={styles.twelve}
        points="544.7 563.52 549.78 566.35 543.78 581.33 538.31 577.61 544.7 563.52"
      />
      <path
        style={styles.twelve}
        d="m472.26,568l1.09,25.78s10.78,2.39,16.09.93l-.47-29.98-16.71,3.26Z"
      />
      <path
        style={styles.twelve}
        d="m537.78,605l1.43,21.26,13.87-2.21,11.3,17.61,19.7-1.26-1.83-20.92-22.47-7.06-1.53-5.23s-12-2.97-20.48-2.18Z"
      />
      <path
        style={styles.twelve}
        d="m542.35,648.96s13.7,1.84,22.83,4.31l-6.39,17.99-19.57-3.82,3.13-18.48Z"
      />
      <polygon
        style={styles.twelve}
        points="491.87 665.13 492.49 682.8 508.53 682.8 506.35 668.91 505.3 660.11 491.87 665.13"
      />
      <path
        style={styles.twelve}
        d="m486.13,655.61s-.2,12.78.68,15.84c0,0-9.1,2.55-17.09,1.76l1.14-21.65,15.26,4.05Z"
      />
      <path
        style={styles.twelve}
        d="m455.74,610.16s.21.1.6.28c2.74,1.28,14.24,6.41,20.53,5.38l-1.57,18.72-22.43-1.65s-6.52-8.59-6.26-9.24,9.13-13.49,9.13-13.49Z"
      />
      <path
        style={styles.twelve}
        d="m449.48,686.78c0,.52-.78,24.52,0,24.26s28.17,15.78,28.17,15.78l8.48-6.16s-.33-15.49.68-20.97l-18.44-10.73-18.9-2.18Z"
      />
      <path
        style={styles.twelve}
        d="m401.61,765.27c0-.59,10.86-13.21,10.86-13.21l13.21,4.76,1.11,20.87-17.07,14.83-17.56-2.5-5.66-16.44,15.11-8.32Z"
      />
      <polygon
        style={styles.twelve}
        points="379.01 751.18 379.01 725.65 403.61 725.07 403.61 749.76 379.01 751.18"
      />
      <path
        style={styles.twelve}
        d="m449.13,737.1s-.08,2.93,18.02,1.47l-1.37,26.14-20.54-5.21,3.89-22.4Z"
      />
      <path
        style={styles.twelve}
        d="m492.1,729.57s.02-.25.05-.68c.2-3.07.98-15.45.64-17.14l18.42-.49-4.82,21.12-14.28-2.8Z"
      />
      <polygon
        style={styles.twelve}
        points="541.5 727.32 542.35 753.48 569.46 764.7 583.86 763.41 585.42 741.35 555.2 726.83 541.5 727.32"
      />
      <polygon
        style={styles.twelve}
        points="611.54 688.24 610.46 718.68 630.13 710.18 652.49 690.83 650.17 674.2 622.41 683.54 611.54 688.24"
      />
      <path
        style={styles.twelve}
        d="m614.48,661.58s10.09.25,16.2,1.64l1.71-17.1-17.9-1.27v16.73Z"
      />
      <path
        style={styles.twelve}
        d="m562.3,714.44c.02.28,24.66,2.55,24.66,2.55l-2.74-18.27-21.91-2.94-4.37,12.92,4.37,5.74Z"
      />
      <rect
        style={styles.twelve}
        x="374.35"
        y="609.08"
        width="28.43"
        height="11.63"
        transform="translate(-46.04 31.63) rotate(-4.4)"
      />
      <rect
        style={styles.twelve}
        x="397.3"
        y="632.82"
        width="24.16"
        height="16.36"
      />
      <rect
        style={styles.twelve}
        x="382.29"
        y="636.33"
        width="13.63"
        height="29.91"
      />
      <path
        style={styles.twelve}
        d="m331.57,642.6s-12.95,5.62-17.8,9.49,3.27,17.9,3.27,17.9l20.2-12-5.67-15.39Z"
      />
      <path
        style={styles.twelve}
        d="m348.68,705.22s13.21-4.9,15.07-8.23l-4.99-20.84-15.07,5.63-4.99,10.61,9.98,12.83Z"
      />
      <path
        style={styles.twelve}
        d="m336.1,726.43l17.77-6.24,19.69,5.36.79,13.01s-.62,4.89,1.43,8.36l-12.46,3.75-12.1-11.33-12.53-6.55-2.6-6.36Z"
      />
      <path
        style={styles.twelve}
        d="m327.46,728.25l-3.62-14.05-9.39-2.97-.59,17.42s12.52,2.77,13.11,2.58.49-2.98.49-2.98Z"
      />
      <polygon
        style={styles.twelve}
        points="328.73 778.58 349.37 778.35 348.68 759.01 328.04 759.55 328.73 778.58"
      />
      <path
        style={styles.twelve}
        d="m367.76,759.01c.39-.59-1.96-12.52-1.96-12.52l9.47-.59.51,8.8-8.02,4.3Z"
      />
      <path
        style={styles.twelve}
        d="m488.77,784.64l.98,15.46c-8.62,2.25-17.24,4.49-25.86,6.74-.31-7.4-.63-14.8-.94-22.2h25.83Z"
      />
      <path
        style={styles.twelve}
        d="m442.11,833.36s22.86,2.93,22.89-1.62l-.77-16h-17.82l-6.75,6.18,2.45,11.45Z"
      />
      <path
        style={styles.twelve}
        d="m464.28,840.24c.08-3.76.15-7.52.23-11.28,8.95.04,17.89.09,26.84.13-.17,3.96-.35,7.92-.52,11.89-4.28.48-9.27.75-14.81.51-4.32-.19-8.25-.65-11.74-1.24Z"
      />
      <path
        style={styles.twelve}
        d="m494.2,791.39s1.45,16.83,1.45,18.91,18.78,0,18.78,0l-.91-18.91h-19.32Z"
      />
      <polygon
        style={styles.twelve}
        points="546.59 779.75 571.04 779.77 567.86 797.59 547.47 802.05 546.59 779.75"
      />
      <polygon
        style={styles.twelve}
        points="616.34 775.75 606.85 780.43 606.85 786.84 611.54 792.47 616.96 795.04 627.65 788.52 628.47 781.02 616.34 775.75"
      />
      <polygon
        style={styles.twelve}
        points="611.54 814.81 621 809.26 622.41 800.01 613.19 794.26 600.91 796.97 601.45 810.43 611.54 814.81"
      />
      <path
        style={styles.twelve}
        d="m644.97,774.65l-3.81.34s-3.98,13.34-.85,21.98c1.57-.1,3.14-.21,4.71-.31.99-6.32,1.99-12.64,2.98-18.96l-3.03-3.05Z"
      />
      <path
        style={styles.twelve}
        d="m649.77,743.88s5.41,2.43,11.28,1.88l-2.15,17.16-12.82-6.1,3.7-12.94Z"
      />
      <polygon
        style={styles.twelve}
        points="645.39 726.02 625.83 725.26 626.51 737.85 636.78 743.88 646.76 734.15 645.39 726.02"
      />
      <polygon
        style={styles.twelve}
        points="651.64 718.68 650.87 700.74 661.83 700.74 664.52 701.64 667.48 717.6 651.64 718.68"
      />
      <polygon
        style={styles.twelve}
        points="633.66 772.87 635.41 785.81 641.58 785.81 642.03 775.84 633.66 772.87"
      />
      <path
        style={styles.twelve}
        d="m635.09,802.36c3.71-2.13,7.43-4.27,11.14-6.4-1.78-.05-3.57-.1-5.35-.16,0,0-1.17,2.64-5.48.94l-.32,5.62Z"
      />
      <path
        style={styles.twelve}
        d="m395.35,679.87l24.12-.75-4.26,26.68s-19.1,1.6-19.28,1.01-.58-26.93-.58-26.93Z"
      />
      <path
        style={styles.twelve}
        d="m439.96,551s10.62,10.09,18.58,11.87l-7.1,17.09s-14.9-.79-15.15-1.57-5.89-17.49-5.89-17.49l9.56-9.9Z"
      />
      <path
        style={styles.twelve}
        d="m417.63,540.57c-1.26,3.21-2.86,8.52-2.57,15.13.03.75.19,3.79,1.22,7.83,1.01,3.96,3.28,10.58,8.93,17.81,0,0,11.03.33,11.08,0,.04-.33-1.23-42.59-1.23-42.59l-17.43,1.83Z"
      />
      <polygon
        style={styles.twelve}
        points="614.09 461.32 596.48 461.32 584.54 473.47 615.78 475.98 630.65 467.01 614.09 461.32"
      />
      <polygon
        style={styles.twelve}
        points="612.78 484.48 625.94 485.87 635.45 471.22 609.78 477.55 612.78 484.48"
      />
      <path
        style={styles.twelve}
        d="m594.3,445.03l-4.6,9.06s6.52,6.12,38.09-1.44v-10.62l-33.48,3Z"
      />
      <path
        style={styles.twelve}
        d="m653.35,362s-11.98,6.59-25.57,9.24c0,0,.4,3.5-1.37,14.15l33.15-3.95-6.22-19.43Z"
      />
      <polygon
        style={styles.twelve}
        points="657.83 315.59 663.4 334.94 653.35 338.39 646.04 317 657.83 315.59"
      />
      <polygon
        style={styles.twelve}
        points="588.73 279.47 602.09 297.5 604.02 300.83 584.22 304.75 567.91 287.61 588.73 279.47"
      />
      <path
        style={styles.twelve}
        d="m506.58,289.61l.82-21.65s-38.87,1.22-39.33,14.17l3,20.23,35.51-12.75Z"
      />
      <polygon
        style={styles.twelve}
        points="431.74 297.5 432.78 294.32 470.87 301.07 468.06 324.06 461.87 322.22 431.74 297.5"
      />
      <path
        style={styles.twelve}
        d="m530.95,240.56s13.61,1.32,21.32,3.24l-3.92,9-24.91-3.11-9.65-9.14-4.43-1.14s11.9-.43,21.6,1.14Z"
      />
      <polygon
        style={styles.twelve}
        points="381.08 310.15 377.54 327.81 387.26 331.7 391.57 322.22 390.04 310.15 381.08 310.15"
      />
      <path
        style={styles.twelve}
        d="m370.41,372.33l5.55,12.67s-8.15.43-8.73.39-13.36-7.1-13.36-7.88,4.16-10.7,4.16-10.7l12.38,5.52Z"
      />
      <path
        style={styles.twelve}
        d="m389.04,464.91c1.35-1.56,16.35-11.28,16.35-11.28l-20.09,2.02,3.73,9.26Z"
      />
      <polygon
        style={styles.twelve}
        points="399.05 479.12 410.58 492.12 437.88 492.12 428.09 480.96 399.05 479.12"
      />
      <path
        style={styles.twelve}
        d="m560.61,534.57s10.92,8.65,5.31,23.09l-9.95-4.96s5.56-5.78,4.65-18.13Z"
      />
      <path
        style={styles.twelve}
        d="m454.43,597.88l2.58,7.79-9.68,2.46s-3.63-8.35-3.81-8.74,10.91-1.51,10.91-1.51Z"
      />
      <path
        style={styles.twelve}
        d="m421.42,583.89l-14.84,5.61,13.55,5.21s6-3.87,6.65-4.62-5.37-6.21-5.37-6.21Z"
      />
      <path
        style={styles.twelve}
        d="m374.84,621.79l11.25,22.47s14.87-8.41,15.52-8.9,6.91-16.72,6.91-16.72l-33.68,3.15Z"
      />
      <path
        style={styles.twelve}
        d="m344.87,703.25c.78.88,12.24,21.35,12.24,21.35l16.33-11.96-5.87-21.96-22.7,12.56Z"
      />
      <polygon
        style={styles.eight}
        points="375 687.21 377.98 707.64 388.94 702.09 385.57 681.46 375 687.21"
      />
      <polygon
        style={styles.eight}
        points="297.55 675.26 293.82 693.26 301.24 694.11 303.53 676.12 297.55 675.26"
      />
      <polygon
        style={styles.eight}
        points="303.54 654.29 301.22 660.7 307.04 660.7 303.54 654.29"
      />
      <path
        style={styles.eight}
        d="m357.49,751.67s4.34,6.91,10.27,7.34l-.44-9.54-9.83,2.2Z"
      />
      <path
        style={styles.eight}
        d="m464.51,839.33v-6.58c-11.71-.2-23.41-.4-35.12-.6,4.57,1.08,9.25,2.13,14.02,3.13,7.2,1.52,14.24,2.86,21.1,4.04Z"
      />
      <path
        style={styles.eight}
        d="m422.35,503.91c-2.09.07,2.61,20.87,2.61,20.87l11.74.48s-1.3-10.04-1.57-10.67-9.13-10.8-12.78-10.67Z"
      />
      <path
        style={styles.eight}
        d="m544.7,320.92l3.13,24.5,27.2-4.49s-3.63-14.61-5.93-18.71-24.39-1.29-24.39-1.29Z"
      />
      <path
        style={styles.eight}
        d="m422.74,467.65s.39,9.28,0,12.27l42.76-6.98,3.02-5.93-45.78.64Z"
      />
      <path
        style={styles.eight}
        d="m543.46,501.83l32.93-9.03s-10.7-3.73-7.5-12.87l-21.59,1.31-3.85,2.2v18.39Z"
      />
      <path
        style={styles.eight}
        d="m645.66,409.78c.7-.17,12.96-3.3,12.96-3.3l-2.08,5.79c-2.62,1.16-5.77,2.82-9.07,5.22-2.7,1.96-4.88,3.96-6.59,5.76,1.25-2.96,2.5-5.92,3.76-8.88l1.03-4.58Z"
      />
      <polygon
        style={styles.eight}
        points="611.09 271.22 599.87 275.91 615.92 293.04 631.07 287.16 611.09 271.22"
      />
      <path
        style={styles.eight}
        d="m515.51,255.6s14.94-3.33,19.83-2.06c0,0-3.62,3.91,0,8.22s2.25,9.46,2.25,9.46l-22.08-15.62Z"
      />
      <path
        style={styles.eight}
        d="m452.23,559.87l-.41,17.74s14.46.23,18.61-8.48l.04-4.79s-9.59,5.05-18.24-4.47Z"
      />
      <path
        style={styles.eight}
        d="m318.6,681.78s3.71,13.94,5.92,17.46l16.84-9.22-6.28-14.85-16.48,6.6Z"
      />
      <path
        style={styles.eight}
        d="m291.8,702.47c-.35,2.87-.69,5.74-1.04,8.61,0,0,4.84.35,4.81,0s2.8-17.8,2.8-17.8c-1.55-.39-3.11-.79-4.66-1.18-.18,1.01-.36,2.02-.54,3.03-.44,2.47-.9,4.91-1.37,7.34Z"
      />
      <polygon
        style={styles.eight}
        points="319.63 687.7 315.22 689.56 317.77 694.88 322.61 694.88 319.63 687.7"
      />
      <polygon
        style={styles.eight}
        points="311.9 743.46 310.34 760.97 328.85 760.97 329.41 745.9 311.9 743.46"
      />
      <path
        style={styles.eight}
        d="m369.23,776.01s1.01,2.34,4.21,3.97l1.17,10.55-10.17,2.82.49-16.43s3.42-.24,4.3-.9Z"
      />
      <path
        style={styles.eight}
        d="m398.62,803.14l1.62,14.29-4.82,3.08s-5.49-1.4-5.62-2.83l-7.69-14.54h16.5Z"
      />
      <path
        style={styles.eight}
        d="m505.63,759.89s13.37,1.56,20.87.78l.72,19.1-18.63-2.36-2.95-17.52Z"
      />
      <path
        style={styles.eight}
        d="m567.03,795.98l.69-.2,11.44-3.31s5.77,16.43,7.43,18.29l-17.22.88s-.39-14.11-2.35-15.67Z"
      />
      <path
        style={styles.eight}
        d="m628.47,745.9c0,.29-4.98,14.42-12.12,18.8l1.26-15.18,10.86-3.62Z"
      />
      <path
        style={styles.eight}
        d="m635.41,743.46s.29,3.82,8.51,7.73c0,0,.15-15.5,3.4-16.07s-.56-.04-.56-.04l-2.26-.92s-3.31,6.96-9.09,9.3Z"
      />
      <path
        style={styles.eight}
        d="m615.72,722.45s3.06,8.53,3.75,15.41l-16.75-2.73-1.26-17.51,13.9,3.06.36,1.78Z"
      />
      <path
        style={styles.eight}
        d="m559.79,667.44s3.42,8.81,1.47,15.36l19.19,2.46-1.32-16.35-19.34-1.47Z"
      />
      <polygon
        style={styles.eight}
        points="646.51 652.72 646.07 668.19 655.34 669.15 651.28 653.85 646.51 652.72"
      />
      <path
        style={styles.eight}
        d="m505.39,587.39l-1.82,26.84,15.82-5.71s-6.26-10.49-4.04-20.05l-9.96-1.08Z"
      />
      <polygon
        style={styles.eight}
        points="452.35 638.91 454.04 662.84 475.3 654.29 473.87 634.54 452.35 638.91"
      />
      <path
        style={styles.eight}
        d="m418.63,606.04s17.73-2.63,22.85-3.79-1.01,18.47-1.01,18.47c0,0-15.52-.2-16.96,0s-6.26-6.49-6.26-6.49l1.37-8.18Z"
      />
      <path
        style={styles.eight}
        d="m327.46,727.9c.69-1.48,1.27-16.16,1.27-16.16l7.14,3.15,1.04,13.76-9.46-.75Z"
      />
      <path
        style={styles.eight}
        d="m444.58,769.83s1.62,13.41,0,19.41c-.14.53-.31,1-.51,1.4-2.42,4.92,23.08-4.86,23.08-4.86l3.07-17.09-25.64,1.13Z"
      />
      <path
        style={styles.eight}
        d="m435.13,723.43s-16.57,6.26-20.22,6.59,3.47,18.51,3.47,18.51c0,0,17.79-1.59,18.18-1.6s3.13-12.54,3.13-12.54l-4.57-10.95Z"
      />
      <path
        style={styles.eight}
        d="m500,632.89s10.32-2.22,23.66-.32l1.21,22.51-20.45-1.82-5.38-1.83.96-18.55Z"
      />
      <path
        style={styles.eight}
        d="m387.26,414.96s4.83,2.35,30.78,9.91l1.43,11.56-21.62-9-10.6-12.47Z"
      />
      <path
        style={styles.eight}
        d="m428.74,351.3l-2.35,7.83,38.75.39s-1.09-4.53,4.54-5l-40.94-3.21Z"
      />
      <path
        style={styles.eight}
        d="m419.22,280.22s-11.25,8.35-10.87,13.43l-4.86-.91-1.28,2.26-13.78-3.11s9.74-15.55,13.04-16.48l17.74,4.81Z"
      />
      <polygon
        style={styles.eight}
        points="587.8 378.66 589.7 372.23 579.39 374.65 587.8 378.66"
      />
      <path
        style={styles.eight}
        d="m595.53,608.22l-2.02,6.27s11.96,1.2,17.72,4.2l-15.7-10.47Z"
      />
      <path
        style={styles.ten}
        d="m417.6,631.84c-.37,1.75-.69,3.73-.86,5.9-.28,3.52-.12,6.65.2,9.23,12.14-2.01,24.28-4.02,36.42-6.03-.06-2.41-.04-4.99.1-7.73.12-2.45.33-4.77.59-6.95l-36.44,5.58Z"
      />
      <path
        style={styles.ten}
        d="m396.74,631.98l1.54-14.33,20.35-.68-1.03,14.87s-14.24,1.56-20.85.14Z"
      />
      <path
        style={styles.ten}
        d="m439.7,601.81l-1.81,14.56,16.55-2.13s2.24-.03,2.36-9.49c0,0-11.75,1.31-17.1-2.93Z"
      />
      <polygon
        style={styles.ten}
        points="492.49 632.89 492.49 665.13 502.17 664.74 501.65 631.84 492.49 632.89"
      />
      <polygon
        style={styles.ten}
        points="521.25 631.84 522.78 648.48 541.7 648.96 540.43 632.57 521.25 631.84"
      />
      <polygon
        style={styles.ten}
        points="502.17 618.64 539.22 617.65 537.78 605 521.25 605 501.71 615.2 502.17 618.64"
      />
      <polygon
        style={styles.ten}
        points="535.7 599.39 544.7 593.78 553.09 595.61 553.7 606.04 537.78 605 535.7 599.39"
      />
      <path
        style={styles.ten}
        d="m574.13,597.88s2.23,12.67,1.85,13.94l18.02,1.64s1.14-5.02,2.02-4.9-.88-.12-.88-.12l-21.01-10.55Z"
      />
      <polygon
        style={styles.ten}
        points="614.48 661.22 612.96 677.05 597.65 672.3 597.46 657.83 614.48 661.22"
      />
      <path
        style={styles.ten}
        d="m631.43,648.28c-.09,5.61-.41,11.64-1.04,18.02-.51,5.11-1.17,9.94-1.92,14.48h19.01l.82-28.65-16.86-3.86Z"
      />
      <path
        style={styles.ten}
        d="m646.07,696.36s1.41-15.57,1.41-15.57h12.61l1.21,7.79,3.22,13.07h-8.69l1.43-4.81c-3.73-.16-7.46-.31-11.19-.47Z"
      />
      <polygon
        style={styles.ten}
        points="559.79 635.36 577.57 637.87 579.13 668.91 560.35 667.61 559.79 635.36"
      />
      <path
        style={styles.ten}
        d="m581.48,685.26c6.09.02,13.57.68,21.84,2.88,3.39.9,6.49,1.96,9.29,3.06-.33,2.86-.48,6.26-.21,10.05.16,2.23.45,4.29.79,6.14-2.76-1.38-5.91-2.74-9.44-3.93-8.22-2.76-15.69-3.63-21.49-3.79-.26-4.81-.52-9.61-.78-14.42Z"
      />
      <path
        style={styles.ten}
        d="m577.57,637.87v-14.22c7.82,2.08,15.97,4.05,24.44,5.86,3.89.83,7.73,1.6,11.52,2.32.32,4.53.63,9.07.95,13.6-3.87-1.29-8.03-2.5-12.47-3.59-8.88-2.17-17.12-3.36-24.44-3.98Z"
      />
      <path
        style={styles.ten}
        d="m562.3,714.43c.45,5.31.89,10.61,1.34,15.92-3.29,0-6.84-.11-10.6-.4-3.33-.26-6.47-.63-9.39-1.08.03-5.09.07-10.17.1-15.26,2.74.1,5.49.21,8.26.33,3.45.15,6.89.31,10.3.48Z"
      />
      <path
        style={styles.ten}
        d="m583.04,716.92c-.34,1.98-.59,4.25-.65,6.78-.08,3.45.23,6.49.65,9l19.67,2.43-1.29-17.89-18.38-.31Z"
      />
      <polygon
        style={styles.ten}
        points="543.29 679.33 523.7 679.33 503.61 681.46 502.17 664.74 542.35 665.91 543.29 679.33"
      />
      <polygon
        style={styles.ten}
        points="503.35 695.78 504.42 712.64 522.82 712.15 523.66 697.59 503.35 695.78"
      />
      <polygon
        style={styles.ten}
        points="469.11 722.03 467.15 738.57 485.22 736.48 485.22 721.48 469.11 722.03"
      />
      <path
        style={styles.ten}
        d="m432.62,725.07s-.15,8.49,3.33,17.38c0,0,8.63-3.88,13.49-3.88l1.19-16.28-18.02,2.78Z"
      />
      <path
        style={styles.ten}
        d="m419.47,711.04s9.86-1.89,16.09-1.73c0,0,11.17-3.5,14.44-2.91l.64-16.36c-4.36,1.24-9.08,2.4-14.14,3.4-7.04,1.39-13.62,2.24-19.6,2.73.25,1.43.52,3,.76,4.43.6,3.48,1.21,6.96,1.81,10.43Z"
      />
      <path
        style={styles.ten}
        d="m416.09,662.13s.58,13.7.47,17.35l18.7-2.35-.19-16.43-18.98,1.43Z"
      />
      <path
        style={styles.ten}
        d="m454.43,659.72l16.43-4.1v35.21s-16.83-1.71-20.23-.78l3.8-30.33Z"
      />
      <path
        style={styles.ten}
        d="m395.35,664.32s-.39,33.03,1.96,34.21l-12.82.72-2.54-14.97-1.37-16.04s2.9-2.9,14.77-3.91Z"
      />
      <path
        style={styles.ten}
        d="m363.75,696.6s7.18,12.18,7.91,15.14l8.37-5.55s-3.98-14.63-5.09-16.63l-11.19,7.04Z"
      />
      <path
        style={styles.ten}
        d="m374.35,727.73s-1.18,9.4-.66,11.62c0,0,4.11-2.54,5.32-2.25,0,0,.23-16.27,1.57-20.18l2.16-11.7-2.7,1.9-5.68,20.61Z"
      />
      <path
        style={styles.ten}
        d="m324.91,699.24s20.74-9.26,15.65-11.05l13.99,32.09s-12.55,6.82-17.9,5.77c0,0-12.52-23.22-11.74-26.8Z"
      />
      <path
        style={styles.ten}
        d="m322.92,709.31s1.2,15.15,5.84,20.96c0,0-2.67-12.09,1.34-17.09l-2.06-4.42-5.13.55Z"
      />
      <path
        style={styles.ten}
        d="m298.3,693.57s-3.2,14.67-2.74,17.51l20.54,1.86s1.28-14.94.83-17.02l-18.64-2.34Z"
      />
      <polygon
        style={styles.ten}
        points="291.65 725.55 292.83 742.04 311.9 743.21 313.86 727.32 291.65 725.55"
      />
      <path
        style={styles.ten}
        d="m300.61,662.74s9.97,13.56,11.29,17.13c0,0-14.33-4.52-14.96-4.61l3.67-12.53Z"
      />
      <polygon
        style={styles.ten}
        points="312.28 671.72 309.8 675.17 303.53 664.8 312.28 671.02 312.28 671.72"
      />
      <polygon
        style={styles.ten}
        points="304.42 652.44 305.87 657.98 313.17 653.1 310.19 647.49 304.42 652.44"
      />
      <path
        style={styles.ten}
        d="m332.25,647.2s-4.47-13.04-3.72-14.89c0,0,14.58-8.04,17.31-7.51l2.64,12.71-16.24,9.69Z"
      />
      <path
        style={styles.ten}
        d="m381.82,600.57l-37.31,21.34.79,3.56s10.34-3.58,10.78-4.03,8.18-5.7,8.18-5.7l13.4-4.96,4.16-10.21Z"
      />
      <path
        style={styles.ten}
        d="m377.04,620.72s1.72,7.97,5.25,16.67c0,0-11.14,5.24-16.98,4.72l-1.86-15.16,13.59-6.23Z"
      />
      <polyline
        style={styles.ten}
        points="428.91 581.33 421.42 583.89 421.76 589.87 436.29 590.85 429.15 581.33"
      />
      <path
        style={styles.ten}
        d="m417.26,549.57c2.53,2.9,6.3,7.99,8.67,15.26,1.8,5.53,2.17,10.41,2.15,13.83,4.67,1.33,9.33,2.66,14,3.99l-5.39-12.86c-2.79-3.62-6.09-7.51-9.98-11.48-3.25-3.32-6.44-6.22-9.46-8.74Z"
      />
      <path
        style={styles.ten}
        d="m433.38,511.68c-.14.52,1.3,13.06,1.3,13.06l11.93,3.11,1.04-8.08-14.28-8.08Z"
      />
      <path
        style={styles.ten}
        d="m432.02,498.88l10.68.47c-.58,1.06-1.43,2.94-1.72,5.45-.25,2.22.05,4.04.32,5.18-3.05,0-6.1,0-9.15,0-5.07-4.24-10.14-8.48-15.21-12.71l15.08,1.61Z"
      />
      <path
        style={styles.ten}
        d="m422.74,479.92l8.35,12.51.65,6.78,39.33,1.63-.63-17.12-5.24-11.45s-29.35,7.75-42.46,7.64Z"
      />
      <path
        style={styles.ten}
        d="m532.22,486.16l-1.87,15.67h13.11c-.71-2.42-1.57-6.56-.85-11.61.47-3.29,1.48-5.94,2.37-7.81-1.82.67-3.77,1.32-5.82,1.94-2.42.72-4.74,1.32-6.93,1.81Z"
      />
      <path
        style={styles.ten}
        d="m527.35,446.26l-.52,13.24c2.55-.38,5.3-.69,8.22-.87,5.16-.32,9.87-.2,14.02.16,11.35.39,22.7.78,34.04,1.17l6.59-5.87c-5.71-.52-10.39-.68-13.76-.72-3.87-.04-6.52.06-7.5-1.57-1.07-1.77.48-4.57,1.3-5.87l-42.39.33Z"
      />
      <polygon
        style={styles.ten}
        points="535.84 471.22 557.87 467.67 564.72 464.25 549.07 458.78 534.13 458.69 535.84 471.22"
      />
      <path
        style={styles.ten}
        d="m585.24,469.63c4.02,1.15,8.78,2.17,14.17,2.65,11.85,1.06,21.76-.88,28.48-2.84,1.86.93,3.72,1.86,5.58,2.8-5.93,3.28-15.24,7.48-27.3,9.34-16.08,2.48-29.19-.41-36.42-2.59,5.14,1.26,10.26-.08,13.14-3.57,1.71-2.08,2.2-4.39,2.36-5.78Z"
      />
      <path
        style={styles.ten}
        d="m577.02,492.65c6.39-1.49,13.04-2.91,19.95-4.22,5.86-1.11,11.57-2.08,17.12-2.93-.27-1.78-.55-3.56-.82-5.33-5.88,1.4-13.99,2.68-23.64,2.27-8.21-.35-15.14-1.82-20.41-3.34-.26.57-1.79,4.08-.13,7.87,2.17,4.95,7.7,5.66,7.93,5.69Z"
      />
      <path
        style={styles.ten}
        d="m552.78,503.91c6.77-2.58,14.47-4.94,23.05-6.68,2.68-.54,5.29-1,7.83-1.37,6.58-.92,13.53-1.69,20.84-2.25,5.17-.4,9.32-.63,14.15-.78-6.5,5.75-12.14,11.47-18.64,17.21-1.6-.58-3.33-1.11-5.19-1.56-7.46-1.79-14.14-1.53-19.17-.78-4.28,1.16-9.06,2.22-14.3,3.01-3.38.51-6.61.86-9.64,1.08l1.08-7.87Z"
      />
      <path
        style={styles.ten}
        d="m593.48,445.34c2.8-1.15,5.78-2.28,8.93-3.36,3.57-1.23,6.99-2.27,10.25-3.15,3.74-.67,8.66-1.95,14.03-4.51,4.28-2.04,7.71-4.36,10.3-6.39,5.25-.75,10.51-1.5,15.76-2.26-3.74,8.73-4.99,15.34-6.21,18.92-.69,2.02-1.76,4.95-3.68,7.47-1.45,1.89-3.82,1.89-4.83,2.09-3.87.76-9.21,2.13-17.03,4.76.45-3.67.89-7.35,1.34-11.02-4.6-.04-9.55-.26-14.81-.73-5.01-.45-9.71-1.08-14.07-1.8Z"
      />
      <polygon
        style={styles.ten}
        points="664.37 377.4 661.86 392.06 649.75 393.38 655.18 379.48 664.37 377.4"
      />
      <path
        style={styles.ten}
        d="m580.43,354.43c5.15-.16,10.59-.49,16.3-1.04,6.73-.65,13.04-1.54,18.91-2.56,2.72,2.97,6.45,7.78,9.13,14.56,1.82,4.6,2.63,8.77,3,12.01-13.3.69-26.61,1.39-39.91,2.08.55-2.36,1.28-6.91-.07-12.35-1.68-6.76-5.6-10.99-7.37-12.69Z"
      />
      <path
        style={styles.ten}
        d="m563.45,305.44c.16,2.28.59,5.3,1.72,8.69,1.15,3.44,2.65,6.15,3.91,8.09-3.06.41-6.25.73-9.57,1.04-5.17.49-11.1.91-14.17.65v-16.96l18.1-1.52Z"
      />
      <path
        style={styles.ten}
        d="m573.78,341.17c0,.22-5.87,14.3-5.87,14.3-1.8.31-4.51.73-7.83,1.04-6.59.62-11.54.44-13.17.39-5.19-.15-13.43,0-26.74,1.3-.3-4.65-.61-9.3-.91-13.96,7.22-.06,13.08-.46,17.08-.82,10.39-.92,15.29-2.13,25.05-2.44,5.29-.17,9.61,0,12.39.17Z"
      />
      <path
        style={styles.ten}
        d="m632.09,308.13s5.92,8.65,9,19.17c0,0,14.32-6.86,16.75-11.71l-7.52-15.9-18.23,8.44Z"
      />
      <path
        style={styles.ten}
        d="m615.69,266.31l-4.6,4.91s18.96,13.23,19.98,15.94l4.38-6.65s-14-11.29-19.76-14.2Z"
      />
      <path
        style={styles.ten}
        d="m509.73,240.25c12.26,4.24,24.53,8.48,36.79,12.72,0,0-25.37-.4-31.63,2.93l-4.76-2.93-.22-6.62,1.01-3.56c-.39-.85-.79-1.69-1.18-2.54Z"
      />
      <path
        style={styles.ten}
        d="m454.66,248l-27.68,11.28c3.06,1.99,6.9,4.14,11.49,6.03,5.77,2.37,11.03,3.63,15.25,4.32,21,2.47,42,4.94,63,7.41l1.04,15.49c12.66-.95,23.04-2.11,30.59-3.07,0,0,18.05-2.28,17.88-4.34-.03-.33-.76-.43-1.4-1.38-.68-1-.73-2.17-.64-3.09-.35-3.46-.7-6.92-1.04-10.39l-26.61,4.91,1.3-4.17c-2.13-1.52-4.3-3.05-6.52-4.57-5.37-3.67-10.66-7.08-15.85-10.24-1.59-.98-3.17-1.95-4.76-2.93-.78,1.18-1.87,3.12-2.48,5.73-.64,2.76-.47,5.09-.26,6.52-6.86-1.14-14.45-2.79-22.57-5.22-12.02-3.59-22.31-8.01-30.75-12.3Z"
      />
      <path
        style={styles.ten}
        d="m546.52,252.96l6.62-8.99s25.08,6.27,32.36,8.99c0,0-4.07,6.52-10.44,7.22l-7.28-4.62s-15.46-3.57-21.26-2.6Z"
      />
      <path
        style={styles.ten}
        d="m468.78,289.61v12.91l38.61,2.87-.82-15.78s-31.82,1.16-37.79,0Z"
      />
      <path
        style={styles.ten}
        d="m436.99,283.44l-3.2,11.56-24.07-5.78s6.94-7.76,10.66-9l16.6,3.22Z"
      />
      <path
        style={styles.ten}
        d="m425.86,356.68l39.28,2.85-5.09,20.09s-26.09.12-39.72-3.92c0,0-.34-9.86,5.54-19.02Z"
      />
      <path
        style={styles.ten}
        d="m411.05,388.68s-1.1,13.36,1.25,18.97c0,0-26.31-5.58-31.22-7.48l-3.6-17.05,33.58,5.57Z"
      />
      <path
        style={styles.ten}
        d="m405.39,420.17s8.2,8.5,14.93,12.86l25.22,3.4s-10.02-2.56-12.76-11.56l-27.39-4.7Z"
      />
      <path
        style={styles.ten}
        d="m357.7,355.62s-1.32,14.45,4.01,21.89c0,0-17.61-9.19-21.6-12.61,0,0,9.54-9.14,17.58-9.28Z"
      />
      <path
        style={styles.ten}
        d="m374.35,357.3c-.15.05-2.39.75-3.05,3.04-.59,2.05.56,3.68.7,3.87l15.91,4.04,3.65-15.13-15.6-5.37-1.31,7.25c-.1.76-.2,1.53-.3,2.29Z"
      />
      <path
        style={styles.ten}
        d="m379.17,424.87c-.02,2.27.22,5.62,1.57,9.39.89,2.5,2.02,4.51,3.03,6,10.04.43,20.07.87,30.11,1.3-.21,1.36-.65,2.34-1.04,3-2.79,4.74-9.24,4.27-19.3,7.96-2.77,1.01-4.97,2.04-6.39,2.74,13.09,3.22,26.17,6.43,39.26,9.65l23.26-11.96-35.65-12.85-1.3-9.79-22.66-4.39-10.86-1.04Z"
      />
      <path
        style={styles.ten}
        d="m476.74,443.13c-2.11-2.62-2.75-5.93-1.57-8.61.74-1.66,1.98-2.64,2.74-3.13,10.52.26,21.04.52,31.57.78.65,1.47,1.44,3.71,1.7,6.52.29,3.18-.2,5.79-.65,7.43l-33.78-3Z"
      />
      <path
        style={styles.ten}
        d="m426.03,529.29s-2.12,5.02-7.07,8.93l-.65,2.35,10.55-2.3-2.82-8.98Z"
      />
      <polygon
        style={styles.ten}
        points="613.19 707.4 625.83 710.88 626.51 725.36 615.95 723.79 613.19 707.4"
      />
      <polygon
        style={styles.ten}
        points="645.39 711.25 645.39 726.02 650.17 726.11 653.1 711.25 645.39 711.25"
      />
      <polygon
        style={styles.ten}
        points="648.3 734.46 646.76 756.17 653.1 730.24 650.34 728.88 648.3 734.46"
      />
      <polygon
        style={styles.ten}
        points="662.38 730.64 660.09 745.35 665.65 744.73 666.82 730.36 662.38 730.64"
      />
      <path
        style={styles.ten}
        d="m611.54,754.15l3.23-4.63h2.84c.45,1.92.89,4.86.49,8.41-.45,3.99-1.77,7.04-2.74,8.87-.39.89-.86,2.25-.98,3.95-.17,2.54.53,4.53.98,5.56-2.58,1.57-5.15,3.14-7.73,4.71l-.78-3.82c1.28-2.21,2.72-5.19,3.72-8.9,1.58-5.85,1.38-10.92.98-14.15Z"
      />
      <path
        style={styles.ten}
        d="m582.26,748.84l-4.07,14.16,30.87,3.34c-.03-1.96.12-4.48.82-7.33.46-1.89,1.06-3.52,1.66-4.86-3.07-.21-6.31-.53-9.68-1.01-7.29-1.03-13.86-2.58-19.6-4.3Z"
      />
      <path
        style={styles.ten}
        d="m604.3,776.31s-4.4,7.21-4.21,11.34l-14.67,3.74,4.4-14.38,14.48-.7Z"
      />
      <path
        style={styles.ten}
        d="m615.36,776.31c3.47-1.96,7.29-4.43,11.25-7.52,2.76-2.15,5.2-4.31,7.33-6.37.3,3.24.37,7.86-.78,13.22-1.01,4.75-2.64,8.54-4.05,11.23-4.58-3.52-9.17-7.04-13.75-10.56Z"
      />
      <polygon
        style={styles.ten}
        points="642.07 763.32 641.09 775.84 632.77 771.83 635.41 760.97 642.07 763.32"
      />
      <path
        style={styles.ten}
        d="m658.89,772.32l6.26-2.54c2.22-1.17,4.43-2.35,6.65-3.52-.17-4.89-.34-9.78-.52-14.67-2.13.63-4.25,1.27-6.38,1.9l-.15,5.17-5.15.33-.72,13.34Z"
      />
      <path
        style={styles.ten}
        d="m562.92,761.95c.14,4.78.52,10.03,1.27,15.65.89,6.67,2.14,12.71,3.52,18.05,3.82-1.06,7.63-2.12,11.45-3.18-1.47-2.56-4.16-8.03-4.3-15.46-.13-6.48,1.73-11.46,2.93-14.09-4.96-.32-9.91-.65-14.87-.97Z"
      />
      <polygon
        style={styles.ten}
        points="526.24 760.67 527.21 779.77 546.59 779.75 544.53 760.34 526.24 760.67"
      />
      <path
        style={styles.ten}
        d="m508.25,792.53v16.57l40.72,2.73-1.46-15.85c-5.46.24-11.59.22-18.27-.3-7.87-.61-14.92-1.79-21-3.14Z"
      />
      <path
        style={styles.ten}
        d="m586.14,809.46l17.29-10.87c1.94,2.64,3.99,5.98,5.69,10.04.61,1.46,1.12,2.88,1.55,4.23-7.7,3.49-15.41,6.99-23.11,10.48l-1.42-13.89Z"
      />
      <path
        style={styles.ten}
        d="m509.59,823.86v15.76c3.24-.08,6.71-.29,10.37-.66,3.89-.39,7.52-.93,10.88-1.54-.43-4.03-.85-8.06-1.28-12.09l-19.98-1.48Z"
      />
      <path
        style={styles.ten}
        d="m492.1,759.89l2.15,32.18s9.31-1.24,14,.45l.38-14.83s-2.52-14.29-2.28-18.2l-14.25.4Z"
      />
      <path
        style={styles.ten}
        d="m442.11,819.04l22.31-.53c-.24-3.19-.41-6.49-.5-9.9-.21-7.83.05-15.16.59-21.94-6.7.67-13.4,1.35-20.1,2.02l-2.3,30.34Z"
      />
      <path
        style={styles.ten}
        d="m399.95,816.56c.25-5.04-.04-11.78-2.25-19.3-.86-2.92-1.88-5.53-2.93-7.83,2.75.19,5.76.28,8.99.21,3.13-.07,6.05-.29,8.71-.6v13.76l-12.52,13.76Z"
      />
      <path
        style={styles.ten}
        d="m403.7,824.59c4.94,1.95,10.69,3.88,17.18,5.45,8.09,1.95,15.45,2.91,21.72,3.33v-14.32l-38.71-2.48-.2,8.03Z"
      />
      <path
        style={styles.ten}
        d="m420.2,760.38s6.75,11.31,5.58,14.61l19.18-3.95,1.27-15.54-26.02,4.89Z"
      />
      <path
        style={styles.ten}
        d="m389.38,778.96l4.03,10.94-19.1-1.54c-.46-4.12-.91-8.23-1.37-12.35,0,0,11.05,2.94,16.43,2.94Z"
      />
      <polygon
        style={styles.ten}
        points="424.3 789.04 412.47 803.53 422.35 802.25 424.3 789.04"
      />
      <path
        style={styles.ten}
        d="m399.24,748.45s2.27,14.58,2.37,16.24l18.59-4.3-2.05-13.45-18.9,1.52Z"
      />
      <path
        style={styles.ten}
        d="m367.57,759.3s11.05,1.27,9.59,4.11c0,0,1.27-13.21-1.37-16.49l-8.22,12.38Z"
      />
      <path
        style={styles.ten}
        d="m344.87,755.39s5.18-3.42,7.53-3.72c0,0,4.89,9.86,7.63,10.21l-7.14,5.54-8.02-12.03Z"
      />
      <polygon
        style={styles.ten}
        points="346.63 778.38 346.14 794.93 364.92 793.35 364.92 778.18 346.63 778.38"
      />
      <path
        style={styles.ten}
        d="m309.32,766.83c.34-1.95.68-3.91,1.02-5.86l18.39.92c.5,2.65.9,6.15.68,10.24-.21,4.1-.97,7.55-1.74,10.14-1.81-1.32-3.64-2.71-5.49-4.17-4.75-3.76-9.02-7.55-12.86-11.26Z"
      />
      <polygon
        style={styles.ten}
        points="505.63 727.73 505.63 744.73 544.83 746.93 544.83 726.02 505.63 727.73"
      />
      <path
        style={styles.ten}
        d="m383.77,652.6l-3.2,17.49-19.53,9.78h-6.49l-4.61-11.64s1.07-3.7,24.41-10.24l9.42-5.38Z"
      />
      <path
        style={styles.ten}
        d="m540.52,578.91c.01.63-.04,2.33-1.17,4.04-1.02,1.53-2.35,2.23-2.94,2.5,1.46.29,2.99.63,4.58,1.05,2.15.56,4.15,1.18,5.99,1.83-.47-3.16-.94-6.32-1.41-9.47-.51.19-1.21.38-2.05.44-1.3.09-2.36-.17-3-.39Z"
      />
      <path
        style={styles.three}
        d="m507.39,305.39l3.52,51.26-42.85-2.22s-6.59-12.26,0-26.35c-2.43-8.9-2.03-13.29-1.11-15.65.23-.59,1.24-2.99,1.83-6.39.25-1.49.35-2.72.39-3.52,5.73,1.06,12.15,1.96,19.17,2.48,6.91.51,13.29.58,19.04.39Z"
      />
      <path
        style={styles.three}
        d="m517.17,292.22v30.52c5.62.71,11.77,1.2,18.39,1.3,3.4.05,6.66,0,9.78-.13v-16.96l38.87-3.26s-9.65-15.91-15.65-18.26c-7.17,2.04-15.57,3.95-25.04,5.22-9.81,1.32-18.69,1.68-26.35,1.57Z"
      />
      <path
        style={styles.three}
        d="m540.65,253.22c17.32,4.98,27.96,13.67,27.18,20.73-.17,1.48-.94,3.79-4.22,6.4-.03.19-.34,2.35,1.3,3.91,1.5,1.43,3.41,1.21,3.65,1.17,7.02-.71,15.5-2.42,24.52-6.26,2.42-1.03,4.68-2.13,6.78-3.26-10.7-6.78-21.39-13.57-32.09-20.35-3.6-.92-7.85-1.75-12.65-2.22-5.49-.53-10.38-.45-14.48-.13Z"
      />
      <path
        style={styles.three}
        d="m485.87,274.09c-1.61.17-7.78,1-12.52,6.26-3.21,3.56-4.21,7.42-4.57,9.26-16.13-3.13-32.26-6.26-48.39-9.39l32.74-10.89c2.91.41,5.82.82,8.74,1.24,8.04,1.15,16.04,2.32,24,3.52Z"
      />
      <path
        style={styles.three}
        d="m454.07,247.7c7.08,3.49,15.35,7.05,24.75,10.21,10.45,3.51,20.12,5.8,28.57,7.3-.36-1.09-.85-3.06-.52-5.48.49-3.59,2.46-5.93,3.26-6.78-.2-1.21-.48-3.17-.52-5.62-.06-3.31.34-4.88-.65-5.87-.52-.52-1.69-1.16-4.7-.39-5.52-.52-11.97-1.11-19-.19-12.51,1.63-23.14,4.31-31.19,6.81Z"
      />
      <path
        style={styles.three}
        d="m391.57,353.13c4.53,1.16,9.73,2.2,15.52,2.87,7.23.84,13.75.92,19.3.65-1.56,1.89-4.07,5.45-5.35,10.57-.95,3.8-.88,7.08-.65,9.26-1.65-.17-3.3-.35-4.96-.52-.93.66-2.77,2.19-3.91,4.83-1.72,3.99-.58,7.64-.26,8.57-11.26-2.08-22.52-4.16-33.78-6.23-1.37-1.31-4.9-5.03-5.87-10.94-.56-3.39-.06-6.22.39-7.96,5.3,1.35,10.61,2.7,15.91,4.04.58-2.06,1.15-4.2,1.7-6.39.74-3,1.39-5.92,1.96-8.74Z"
      />
      <path
        style={styles.three}
        d="m432.78,424.87c-3.65-.3-7.53-.75-11.6-1.38-5.74-.9-11.01-2.05-15.8-3.31-1.4-1.71-3.23-4.36-4.57-7.96-1.03-2.77-1.46-5.27-1.63-7.17,3.89,1.02,8.28,1.95,13.11,2.61,3.57.49,6.93.77,10.04.91-.27.56-.44,1.12-.53,1.65-1.25,7,9.69,13.87,10.97,14.65Z"
      />
      <path
        style={styles.three}
        d="m376.83,408.83c-.13.39,0,8.74,0,8.74,4.74,2.83,9.48,5.65,14.22,8.48,4.09.78,8.17,1.57,12.26,2.35-2.76-1.84-5.86-4.24-9-7.3-1.97-1.92-3.65-3.82-5.09-5.61-2.48-.98-5.22-2.25-8.09-3.91-1.56-.9-2.99-1.83-4.3-2.74Z"
      />
      <path
        style={styles.three}
        d="m412.7,431.39c.52,1.41,1.05,3.32,1.25,5.62.15,1.76.07,3.3-.08,4.55,7.05,2.72,14.76,5.35,23.13,7.69,4.65,1.3,9.17,2.43,13.53,3.4,3.26-2.78,6.52-5.57,9.78-8.35-3.1-1.4-6.39-3.08-9.78-5.09-1.53-.91-2.99-1.82-4.37-2.74-3.47-.25-7.06-.59-10.76-1.04-8.18-1-15.77-2.42-22.7-4.04Z"
      />
      <path
        style={styles.three}
        d="m422.74,467.65c10.07.56,20.88.7,32.33.21,4.61-.2,9.1-.49,13.45-.85,6.69-4.17,14.25-8.44,22.7-12.54,6.75-3.27,13.22-6.02,19.3-8.35-10.91-1-21.83-2-32.74-3,2.08,1.89,2.8,4.81,1.83,7.3-1.11,2.85-4.23,4.6-7.43,4.04-.1.8-.36,2.12-1.17,3.52-.65,1.11-1.4,1.87-1.96,2.35-2.14-.51-4.46-1.18-6.91-2.07-4.63-1.68-8.5-3.71-11.61-5.63-3.77,1.25-8.02,2.93-12.52,5.22-6.22,3.16-11.29,6.64-15.26,9.78Z"
      />
      <polygon
        style={styles.three}
        points="409.03 489.83 431.54 492.22 432.2 499.01 416.83 497.54 409.03 489.83"
      />
      <polygon
        style={styles.three}
        points="512.54 502.74 507.26 512 498.39 534.57 458.64 525.17 480.48 523.61 485.59 503.26 512.54 502.74"
      />
      <path
        style={styles.three}
        d="m520.7,506.52c5.02-.09,10.34-.33,15.91-.78,5.7-.46,11.1-1.09,16.17-1.83-.63,3.63-1.16,7.69-1.43,12.13-.26,4.1-.27,7.9-.13,11.35-.89-.03-2.95.03-5.09,1.3-2.8,1.66-3.81,4.27-4.08,5.03-4.5,1.03-10.03,1.9-16.38,2.1-7.86.24-14.61-.65-19.84-1.72,1.27-2.36,3.54-6.57,4.8-8.93,3.35-6.22,6.7-12.43,10.06-18.65Z"
      />
      <path
        style={styles.three}
        d="m550.71,468.53l-2.89,12.43c-2.45,1.37-5.79,2.92-9.91,4.04-4.35,1.19-8.19,1.52-11.09,1.57.3-4.87.61-9.74.91-14.61,4.05-.24,8.45-.7,13.15-1.46,3.5-.56,6.78-1.24,9.83-1.97Z"
      />
      <path
        style={styles.three}
        d="m527.35,432.3v13.96c8.37-.33,17.08-.47,26.09-.39,5.51.05,10.91.18,16.17.39.75-1.86,1.57-4.43,1.96-7.57.36-2.9.23-5.41,0-7.3-6.29.57-12.95.98-19.96,1.17-8.58.23-16.69.11-24.26-.26Z"
      />
      <path
        style={styles.three}
        d="m548.35,458.78c12.3,3.62,24.59,7.23,36.89,10.85,1.62.16,5.88.37,10.33-2.11,3.33-1.86,5.24-4.37,6.13-5.74-1.74-.52-3.48-1.04-5.22-1.57-4.72.3-9.64.49-14.74.52-12.1.08-23.28-.7-33.39-1.96Z"
      />
      <path
        style={styles.three}
        d="m547.83,480.96c1.94-.03,16.27-.3,25.96-2.87,3.26-.86,6.25-2.06,8.77-4.65,1.36-1.4,2.19-2.82,2.69-3.8-6.41-1.89-12.82-3.77-19.24-5.66-.82.51-1.7,1-2.65,1.46-4.66,2.27-9.16,2.96-12.63,3.1-.96,4.14-1.93,8.28-2.89,12.43Z"
      />
      <path
        style={styles.three}
        d="m596.48,460.22c7.65.28,17.18-.07,27.91-2.09,7.64-1.44,14.32-3.43,19.94-5.49-3.28,6.72-6.55,13.44-9.83,20.16-4.82-2.66-10.82-5.5-17.94-7.89-7.54-2.54-14.4-3.92-20.09-4.7Z"
      />
      <path
        style={styles.three}
        d="m626.48,384.7c-.13.39-.54,12.91-.54,12.91l-10.42,27.65-2.74,13.57,25.04-11.22,7.83-12.78,4.7-21,5.61-14.35-29.48,5.22Z"
      />
      <path
        style={styles.three}
        d="m663.4,334.94c-.71.49-1.67,1.05-2.88,1.49-1.84.67-3.47.79-4.57.78,1.41,2.14,3.28,5.53,4.43,10.04,1.15,4.52,1.13,8.4.91,10.96,1.49-.52,2.99-1.04,4.48-1.57l-2.39-21.71Z"
      />
      <polygon
        style={styles.three}
        points="651.62 362 652.66 379.75 664.05 377.26 664.05 356.65 651.62 362"
      />
      <path
        style={styles.three}
        d="m567.91,355.48l12.52-1.04c1.63,1.61,4.04,4.4,5.87,8.48,1.83,4.1,2.3,7.77,2.42,10.06-3.74.21-7.48.42-11.22.64.1-1.44.09-3.21-.2-5.22-.41-2.78-1.22-5.03-1.96-6.65-.73-.98-1.71-2.12-3-3.26-1.61-1.42-3.18-2.37-4.43-3Z"
      />
      <path
        style={styles.three}
        d="m650.61,322.22c-1.06.93-2.49,2.01-4.3,3-1.96,1.07-3.78,1.7-5.22,2.09,1.35,4.61,2.7,9.22,4.04,13.83,1.29.1,3.77.12,6.52-1.04,2.04-.86,3.46-2.04,4.3-2.87-.52-1.48-1.04-2.95-1.57-4.43-1.26-3.54-2.52-7.06-3.78-10.57Z"
      />
      <path
        style={styles.three}
        d="m603.66,301.51l-2.46-3.13c8.2-3.09,14.67-4.83,19.3-5.87,2.78-.63,6.33-1.32,9.65-4.17,2.72-2.34,4.15-5.15,4.93-7.15,4.96,6.4,9.91,12.79,14.87,19.19l-18.23,8.44c-.51-.91-5.43-9.35-15.26-10.43-6.42-.71-11.26,2.13-12.8,3.13Z"
      />
      <path
        style={styles.three}
        d="m377.54,327.81l10.96,1.78c-.11.21-2.17,4.41.29,8.38,1.73,2.79,4.99,4.32,8.32,3.91-.56,2.47-1.62,5.62-3.72,8.8-.6.91-1.21,1.72-1.83,2.45-5.2-1.79-10.4-3.58-15.6-5.37l1.58-19.95Z"
      />
      <path
        style={styles.three}
        d="m382.17,310.19l8.99,2.64c.2-1.03.63-2.54,1.58-4.14,1.33-2.23,3.02-3.51,3.99-4.14,1.92-3.09,3.84-6.18,5.76-9.27l-13.93-3.06-6.39,17.97Z"
      />
      <path
        style={styles.three}
        d="m377.48,383.12l6.29,29.74-6.94-4.03-12.02.53c-1.56.49-11.12,3.35-19.97-2.12-9.23-5.71-10.57-16.07-11-19.37-1.59-12.23,5.04-21.3,6.62-23.35,4.33,3.45,9.96,7.36,16.93,10.92,7.51,3.84,14.45,6.19,20.09,7.68Z"
      />
      <path
        style={styles.three}
        d="m488.96,542.2c-.16,10.28-.32,20.57-.49,30.85l-18-4.17c-.15-4.19.06-9.73,1.43-16.04,1.26-5.77,3.12-10.51,4.83-14.09,1.72.91,4,1.91,6.78,2.61,2.03.51,3.88.75,5.44.85Z"
      />
      <path
        style={styles.three}
        d="m531.79,562.29c-.83,1.59-1.65,3.17-2.48,4.76,1.45.73,3.27,1.84,5.08,3.52,3.05,2.82,4.66,5.89,5.48,7.83,2.48-6.17,4.96-12.35,7.43-18.52-1.67.8-3.96,1.69-6.78,2.22-3.58.67-6.64.49-8.74.2Z"
      />
      <path
        style={styles.eleven}
        d="m547.83,535.22c.71-.09,7.21-.79,11.73,4.06,2.92,3.13,3.36,6.82,3.46,8.07.17-1.2.35-2.4.52-3.6.35-2.47.7-4.94,1.04-7.4-.42-.69-2.45-3.79-6.4-4.71-3.71-.86-7.69.54-10.34,3.58Z"
      />
      <path
        style={styles.three}
        d="m547.3,554.39c2.43,1.1,6.32,3.25,9.78,7.3,1.93,2.26,3.21,4.5,4.04,6.3l5.48-10.35c-1.75-1.07-4.3-2.35-7.57-3.13-5.04-1.21-9.31-.65-11.74-.13Z"
      />
      <path
        style={styles.three}
        d="m545.57,578.86c.73,3.2,1.47,6.41,2.2,9.61,1.95-1.07,5.09-3.11,7.88-6.69,3.44-4.43,4.55-8.91,4.96-11.22-2.54,2.02-5.79,4.22-9.78,6.13-1.84.88-3.6,1.59-5.25,2.17Z"
      />
      <path
        style={styles.three}
        d="m417.26,549.3c4.21,2.49,7.04,5.09,8.87,7.04,3.56,3.8,7.18,9.23,7.3,9.14,0,0,0,0,0,0,0-.79,4.57-8.22,4.57-8.22.82-1.34,1.84-3.37,2.48-6.26-2.17-2.79-4.34-5.58-6.51-8.37-2.03.5-4.23,1.14-6.53,1.97-3.95,1.43-7.35,3.08-10.17,4.7Z"
      />
      <path
        style={styles.three}
        d="m424.96,522.54c.4,3.7,1.03,6.72,1.57,8.9,1.61,6.51,3.06,8.13,3.65,8.74,1.85,1.88,4.06,2.65,5.48,3,.1-3.57.05-7.45-.26-11.61-.19-2.56-.46-5-.78-7.3-3.22-.57-6.43-1.15-9.65-1.72Z"
      />
      <path
        style={styles.three}
        d="m436.7,570.57c1.19,4.63,2.99,7.97,4.43,10.17,5.72,8.72,15.43,14.23,15.65,13.97,0,0,0,0,0-.01.15-5.36-1.04-8.48-1.04-8.48-.94-2.45-2.2-5.37-3.91-8.61-1.54-.04-3.48-.26-5.61-.91-4.86-1.5-7.99-4.45-9.52-6.13Z"
      />
      <path
        style={styles.three}
        d="m428.09,578.39c2.12,3.92,6,9.93,12.65,15.39,5.03,4.13,9.96,6.57,13.59,8.02.5-.37,1.56-1.26,2.19-2.81.81-1.97.42-3.73.26-4.3-5.43-3.87-10.87-7.74-16.3-11.61l-12.39-4.7Z"
      />
      <path
        style={styles.three}
        d="m448.66,599.14c-.04.75-.12,1.61-.29,2.54-.18.96-.41,1.81-.65,2.54-3.64-1.06-7.53-2.35-11.62-3.93-4.62-1.79-8.82-3.69-12.58-5.58-.15-1.62-.3-3.23-.46-4.85,5,.52,10,1.04,15,1.56l10.59,7.71Z"
      />
      <path
        style={styles.three}
        d="m473.51,610.16c1.35,1.17,3.36,2.66,6.07,3.87,2.24,1,4.29,1.5,5.87,1.76-.86,7.41-1.3,16.01-.78,25.53.28,5.11.8,9.88,1.47,14.29h-15.75c.13-4.99.26-9.98.39-14.97l-17.41.29c-.06-2.41-.04-4.99.1-7.73.12-2.45.33-4.77.59-6.95,6.07-.07,12.13-.13,18.2-.2.51-2.43.95-5.22,1.17-8.32.2-2.77.21-5.32.1-7.59Z"
      />
      <path
        style={styles.three}
        d="m493.08,615.99l-.59,16.9,9.68-.76-.47-16.93c-1.01.32-2.24.61-3.64.79-1.96.24-3.66.16-4.99,0Z"
      />
      <path
        style={styles.three}
        d="m521.25,617.65v14.18c6.81-.09,14.4.18,22.6,1.08,5.71.62,11.03,1.47,15.95,2.45l-1.47-14.28c-3.01-.49-6.09-.94-9.24-1.34-9.8-1.25-19.11-1.88-27.84-2.08Z"
      />
      <path
        style={styles.three}
        d="m382.92,600.04s-4.39,7.83-4.59,10.13c0,0,20.54-2.49,22.21-2.39,0,0-1.96,6.55-2.25,9.88,0,0,18.1-.88,20.35-.68,0,0,3.52-21.71,4.89-22.25l-.88-4.85s-8.54.77-14.15-1.13c0,0-23.43,9.43-25.58,11.3Z"
      />
      <path
        style={styles.three}
        d="m418.63,616.97s-1.86,12.23-.59,14.87c0,0,30.33-7.43,36-5.58,0,0,.39-11.06.39-12.03,0,0-27.78,4.3-35.8,2.74Z"
      />
      <path
        style={styles.three}
        d="m502.17,649.48v15.26s18-.65,40.17,1.17l-.65-16.96s-11.35-1.83-39.52.52Z"
      />
      <path
        style={styles.three}
        d="m542.35,665.91l2.22,31.7,37.7,2.09-1.04-14.22s-4.57-1.57-19.96-2.67c0,0,.91-11.67-.91-15.2l-18-1.7Z"
      />
      <path
        style={styles.three}
        d="m577.57,637.87l1.57,31.04,18.52,3.39-.91-15.21,17.74,4.12v-15.78s-15.13-5.61-36.91-7.57Z"
      />
      <path
        style={styles.three}
        d="m614.48,645.43l-1.87-26.8s33.57,26.21,39.23,32.74l1.23,1.89s2.63.12-38.59-7.83Z"
      />
      <path
        style={styles.three}
        d="m656.86,668.61c-.41-.06-9.41-1-9.41-1l-.65,13.17h12.61l-2.55-12.17Z"
      />
      <path
        style={styles.three}
        d="m657.26,696.83s-21.65-.65-30.13-2.09l-1.3,16.57s25.66-.13,27.96,0c0,0,.61-5.61,3.48-14.48Z"
      />
      <path
        style={styles.three}
        d="m644.87,726.11l-.39,8.48s2.48.78,4.17.52l1.52-9h-5.3Z"
      />
      <path
        style={styles.three}
        d="m666.41,730.34l-1.26,23.81s1.33-2.05,5.64-2.57l-2.28-22.32-2.1,1.08Z"
      />
      <path
        style={styles.three}
        d="m664.76,758.65l-5.15.33s1.17-12.2.78-13.63h4.76l-.39,13.3Z"
      />
      <path
        style={styles.three}
        d="m665.23,770.27c2.26-1.16,4.51-2.31,6.77-3.47.07,2.52.13,5.04.2,7.57-1.89,1.52-3.78,3.04-5.67,4.57-.43-2.89-.86-5.77-1.29-8.66Z"
      />
      <path
        style={styles.three}
        d="m654.45,788.54c.43-1.44.84-2.93,1.24-4.45.98-3.76,1.75-7.38,2.35-10.83,0,0,.44-2.67,1.57-14.28-1.1.41-3.93,1.3-7.37.46-2.49-.61-4.2-1.86-5.09-2.61-.75,4.39-1.36,9.21-1.7,14.41-.57,8.84-.22,16.8.52,23.61,2.83-2.1,5.65-4.2,8.48-6.31Z"
      />
      <path
        style={styles.three}
        d="m621.31,806.38l7.94-19.88c-1.98,1.09-3.97,2.21-5.98,3.35-2.9,1.65-5.73,3.31-8.5,4.96,1.07,1.31,2.22,2.88,3.33,4.73,1.49,2.49,2.5,4.84,3.21,6.83Z"
      />
      <path
        style={styles.three}
        d="m614.77,794.82c-1.35-1.25-2.82-2.77-4.3-4.6-1.49-1.84-2.68-3.6-3.62-5.18-3.04,1.28-6.36,2.54-9.98,3.72-4.06,1.32-7.9,2.34-11.45,3.13,2.23,4.79,4.47,9.59,6.7,14.38,2.71-1.64,5.59-3.28,8.66-4.89,4.87-2.56,9.57-4.73,13.99-6.55Z"
      />
      <path
        style={styles.three}
        d="m579.16,792.47c-1.38-2.7-3.13-6.95-3.72-12.42-.84-7.78,1.1-13.96,2.35-17.12,3.19.58,6.52,1.11,9.98,1.57,6.75.88,13.14,1.37,19.08,1.57-.07,1.39-.26,3.05-.68,4.89-.5,2.15-1.2,3.96-1.86,5.37-2.59.21-5.27.38-8.02.5-2.2.1-4.35.16-6.46.2-.64,1.52-1.27,3.15-1.86,4.89-1.21,3.59-2.01,6.95-2.54,9.98-2.09.2-4.17.39-6.26.59Z"
      />
      <path
        style={styles.three}
        d="m547.47,795.65l-.88-15.9c-1.65-.17-3.39-.29-5.2-.36-4.16-.16-7.99.01-11.43.36-3.04.07-6.42-.01-10.06-.34-4.17-.37-7.95-1-11.26-1.72,0,2.6-.02,5.28-.1,8.02-.07,2.32-.16,4.59-.28,6.81,6.48,1.51,14.22,2.8,22.98,3.26,5.92.31,11.36.2,16.24-.15Z"
      />
      <path
        style={styles.three}
        d="m530.35,837.89c-.26-4.18-.52-8.37-.78-12.55,4.26-.25,9.06-.22,14.27.35,1.81.2,3.54.44,5.2.73.26-6.84.14-14.31-.59-22.3-.26-2.92-.6-5.74-.98-8.46,2.74.24,5.69.4,8.8.44,4.12.05,7.95-.13,11.45-.44.52,2.45.97,5.18,1.27,8.17.29,2.82.39,5.44.39,7.83,2.56.4,5.83.67,9.59.39,2.95-.22,5.52-.72,7.63-1.27.39,1.51.74,3.22.98,5.09.4,3.16.51,5.15.33,7.5-6.3,1.17-12.72,3.17-19.02,4.34.07.78.14,1.55.22,2.33-9.9,2.53-20.32,4.79-31.25,6.67-2.52.43-5.03.84-7.51,1.22Z"
      />
      <path
        style={styles.three}
        d="m526.24,760.67l-.49-15.95c7.15.55,14.46,1.03,21.93,1.42,5.89.31,11.69.55,17.4.73l-1.66,15.36c-3.32-.54-6.81-.99-10.46-1.33-9.79-.92-18.78-.83-26.71-.23Z"
      />
      <path
        style={styles.three}
        d="m492.68,759.89h13.7c-.51-5.74-.87-11.85-.98-18.29-.08-4.4-.04-8.65.1-12.72l-13.4.68.59,30.33Z"
      />
      <path
        style={styles.three}
        d="m565.08,746.88l-.78-16.25c8.64.55,15.1,1.44,19.51,2.18,2.33.39,7.32,1.29,14.24,1.93,2.26.21,4.13.34,5.38.41-.1,2.65-.38,5.67-.98,8.98-.53,2.96-1.23,5.61-1.96,7.92-1.98-.26-4-.55-6.07-.88-4.25-.68-8.31-1.48-12.16-2.35-2.19-.48-4.57-.91-7.11-1.24-3.64-.48-7.02-.68-10.07-.72Z"
      />
      <path
        style={styles.three}
        d="m560.89,698.51l.86,15.79c-4.39-.15-8.79-.31-13.21-.49-1.6-.06-3.2-.13-4.79-.2.13,5.09.26,10.17.39,15.26h-38.64l-1.08-16.24c3.8.32,8.07.42,12.72.1,1.98-.14,3.88-.34,5.67-.59.28-4.85.56-9.71.85-14.56,12.41.31,24.82.61,37.23.92Z"
      />
      <path
        style={styles.three}
        d="m524.28,678.89l-.62,18.7c-2.76-.4-5.61-.75-8.55-1.04-7.97-.78-15.44-.99-22.33-.83v-14.67c2.66.36,4.81.47,6.17.51,4.83.14,8.07-.35,20.48-2.01,2.25-.3,4.03-.54,4.84-.65Z"
      />
      <path
        style={styles.three}
        d="m582.26,699.7v16.96c7.21-.63,16.22-.48,26.15,1.96,2.46.61,4.78,1.3,6.95,2.05-.37-2-.72-4.03-1.08-6.07-.41-2.37-.8-4.72-1.17-7.04-3.51-1.86-8.04-3.86-13.5-5.38-6.78-1.89-12.79-2.42-17.35-2.48Z"
      />
      <path
        style={styles.three}
        d="m597.46,686.82c8.52,1.7,13.55,3.6,16.63,5.09,1.18.57,4.52,2.26,9.2,2.74,1.62.17,2.96.14,3.85.1.45-4.89.89-9.78,1.34-14.67-3.86-.54-7.99-1.26-12.33-2.25-6.89-1.57-13.07-3.5-18.49-5.51-.07,4.84-.13,9.67-.2,14.51Z"
      />
      <path
        style={styles.three}
        d="m667.24,717.6c-1.51.58-3.52,1.17-5.93,1.4-4.48.42-8.1-.61-10.17-1.4-.66,3.87-1.32,7.73-1.98,11.6,1.38.9,5.01,3.03,10.1,3,4.6-.02,7.95-1.78,9.37-2.64-.46-3.99-.93-7.97-1.39-11.96Z"
      />
      <path
        style={styles.three}
        d="m449.45,738.57l-3.23,16.92c-.53.03-1.39.08-2.45.2-6.56.69-9.78,2.41-15.36,3.62-1.84.4-4.65.9-8.22,1.08-.68-4.24-1.37-8.48-2.05-12.72,6.79-.62,11.64-2.33,14.87-3.82,2.91-1.34,7.16-3.73,13.7-4.89,1.15-.2,2.1-.32,2.74-.39Z"
      />
      <path
        style={styles.three}
        d="m444.55,771.04c.03-2.46.17-5.14.49-8.02.3-2.72.71-5.24,1.17-7.53,2.75.54,6.16.97,10.08.98,3.96,0,7.4-.44,10.17-.98l.68-16.92,18.1-1.47c-.38,6.78-.44,14.4.1,22.7.62,9.53,1.9,18.1,3.42,25.53-8.09.45-16.17.9-24.26,1.35.16-5.37.33-10.74.49-16.12-6.82.16-13.63.33-20.45.49Z"
      />
      <path
        style={styles.three}
        d="m496.3,809.1c1.56-.33,3.52-.61,5.77-.62,2.43-.02,4.53.28,6.17.62,6.88.68,13.75,1.37,20.63,2.05l.68,14.18-31.7-2.35-1.57-13.89Z"
      />
      <path
        style={styles.three}
        d="m490.73,814.67c-4.06,1.25-8.92,2.42-14.48,3.13-4.07.52-7.82.7-11.15.68-.03,4.3-.07,8.61-.1,12.91,8.58-.72,17.15-1.43,25.73-2.15v-14.58Z"
      />
      <polygon
        style={styles.three}
        points="433.79 773.59 424.3 789.04 412.47 789.04 408.65 777.7 433.79 773.59"
      />
      <path
        style={styles.three}
        d="m444.41,788.7c-.74,4.19-1.35,8.82-1.71,13.85-.45,6.35-.4,12.17-.1,17.32-3.62-.75-7.44-1.42-11.45-1.96-10.84-1.46-20.79-1.72-29.54-1.35,2.02-3.45,4.79-7.29,8.61-10.98.95-.92,1.9-1.76,2.84-2.54,1.23-.09,2.47-.19,3.72-.29,1.87-.15,3.73-.32,5.58-.49.56-1.77,1.1-3.87,1.47-6.26.4-2.61.51-4.96.49-6.95,5.12-.27,10.4-.41,15.85-.39,1.43,0,2.85.02,4.25.04Z"
      />
      <path
        style={styles.three}
        d="m408.65,777.7l-7.04-13.01c-3.23.51-5.94.8-7.92.98-8.96.81-12.07.08-14.48-1.08-.92-.44-1.63-.89-2.05-1.17-1.24,4.17-2.48,8.35-3.72,12.52,4.56,1.32,10.57,2.6,17.71,2.93,6.95.33,12.92-.33,17.51-1.17Z"
      />
      <path
        style={styles.three}
        d="m374.35,788.36c3.34.94,7.68,1.79,12.78,1.86,2.83.04,5.4-.17,7.63-.49.9,1.28,1.95,3.08,2.74,5.38,1.24,3.62,1.24,6.8,1.08,8.76-5.51-.31-11.02-.62-16.53-.93-.9-.69-1.86-1.5-2.84-2.45-1.71-1.67-3.02-3.33-4.01-4.79-.28-2.45-.56-4.89-.84-7.34Z"
      />
      <path
        style={styles.three}
        d="m360.82,761.88c-.82.93-1.95,2.03-3.42,3.09-1.67,1.2-3.25,1.96-4.5,2.45-1.4-1.6-2.8-3.2-4.21-4.79l-1.57.1v16.23l17.8-.77v-1.27c.61.04,1.79.04,3.03-.59.54-.27.96-.6,1.27-.88-.05-.87-.21-2.07-.68-3.42-.46-1.32-1.07-2.33-1.57-3.03-2.05-2.37-4.11-4.74-6.16-7.1Z"
      />
      <path
        style={styles.three}
        d="m357.49,751.67l-3.82-2.84-2.64-9.49c1.3-.08,3.09-.34,5.09-1.08,2.41-.89,4.17-2.12,5.28-3.03,1.7,4.74,3.39,9.49,5.09,14.23-1.15.53-2.59,1.09-4.3,1.52-1.79.44-3.39.62-4.7.68Z"
      />
      <path
        style={styles.three}
        d="m334.7,741.11c.98-.84,5.61-4.66,11.45-3.72,1.51.25,2.79.76,3.8,1.31-.2-.58-.54-1.41-1.16-2.28-1.76-2.48-4.19-3.06-6.26-4.21-1.73-.96-3.93-2.7-5.87-6.16-.85.64-2.72,1.87-5.38,2.15-1.6.17-2.93-.06-3.82-.29,2.41,4.4,4.83,8.8,7.24,13.21Z"
      />
      <path
        style={styles.three}
        d="m354.55,720.27c4.8-1.93,10.14-4.44,15.75-7.73,3.82-2.23,7.24-4.52,10.27-6.75-1.54,3.64-3.06,7.99-4.21,13.01-.7,3.07-1.17,5.96-1.47,8.61l-13.5,7.83c-.71-2.46-1.7-5.29-3.13-8.32-1.2-2.56-2.49-4.77-3.72-6.65Z"
      />
      <path
        style={styles.three}
        d="m313.86,727.32c-.65,5.52-1.31,11.03-1.96,16.55,3.08.07,6.46.29,10.08.76,2.65.34,5.13.78,7.43,1.27.12,2.12.17,4.41.1,6.85-.09,3.33-.39,6.39-.78,9.13,6.65.25,13.3.5,19.96.75l-3.82-7.24-16.53-25.88c-2.2.18-5.09.18-8.41-.43-2.39-.44-4.42-1.1-6.07-1.76Z"
      />
      <path
        style={styles.three}
        d="m319.63,695.13l3.42,14.77,4.99-.98c-1.1-3.5-2.42-7.13-4.01-10.86-.35-.83-.71-1.64-1.08-2.45-1.11-.16-2.22-.33-3.33-.49Z"
      />
      <path
        style={styles.three}
        d="m298.3,693.57l3.52-16.34,10.07,2.64c.57,2.18,1.31,4.55,2.25,7.04,1.37,3.6,2.91,6.74,4.4,9.39-2.98-.76-6.35-1.45-10.08-1.96-3.7-.5-7.12-.72-10.17-.78Z"
      />
      <path
        style={styles.three}
        d="m316.11,712.93l-25.14-1.86v14.48c3.79,0,7.88.13,12.23.49,3.78.31,7.35.75,10.66,1.27.75-4.79,1.5-9.59,2.25-14.38Z"
      />
      <path
        style={styles.three}
        d="m380.58,668.23l1.37,16.04c-2.45,1.99-5.16,4.03-8.12,6.07-3.5,2.41-6.89,4.48-10.08,6.26-1.96-5.41-3.91-10.83-5.87-16.24,1.41-.34,2.96-.81,4.6-1.47,4.01-1.61,7.06-3.76,9.2-5.58,1.06-.85,2.33-1.76,3.82-2.64,1.84-1.09,3.58-1.88,5.09-2.45Z"
      />
      <path
        style={styles.three}
        d="m384.49,699.24c-1.48,4.55-2.89,9.9-3.91,15.95-1.16,6.88-1.55,13.11-1.57,18.39l20.35-1.96v17.32l18.78-2.01c-.58-2.27-1.09-5.1-1.17-8.36-.05-1.82.04-3.5.2-4.99.72-3.59,1.43-7.17,2.15-10.76.32-5,.21-11.18-1.08-18.1-.59-3.15-1.34-6.02-2.15-8.61-3.41.84-7.13,1.59-11.15,2.15-7.63,1.07-14.53,1.24-20.45.98Z"
      />
      <path
        style={styles.three}
        d="m435.55,709.32c0,.39-2.93,15.75-2.93,15.75l19.66-3.03c2.33-.22,4.85-.36,7.53-.39,3.36-.03,6.47.13,9.29.39.59-5.77,1.17-11.54,1.76-17.32-1.76.11-4.38.27-7.56.5-13.48.98-17.65,1.73-19.73,2.14-1.9.37-4.68.98-8.02,1.96Z"
      />
      <path
        style={styles.three}
        d="m486.82,690.04l-.68,14.67h-15.26c.16-4.5.33-9,.49-13.5,2.76-.09,5.63-.25,8.61-.49,2.36-.19,4.64-.42,6.85-.68Z"
      />
      <polygon
        style={styles.three}
        points="435.07 661.18 454.43 659.72 450.23 690.04 416.09 696.11 416.58 678.89 435.26 677.13 435.07 661.18"
      />
      <path
        style={styles.three}
        d="m433.6,644.26c-2.18.26-5.72.72-10.08,1.47-7.27,1.25-9.89,2.11-14.88,2.57-2.56.23-6.46.42-11.34-.02-.65,5.35-1.3,10.69-1.96,16.04,6.73-1.21,14.23-2.2,22.4-2.74,6.15-.4,11.95-.5,17.32-.39.03-2.19-.02-4.55-.2-7.04-.25-3.61-.71-6.92-1.27-9.88Z"
      />
      <path
        style={styles.three}
        d="m371.92,658.73c-2.2-5.54-4.4-11.08-6.6-16.62,1.79-.01,4.4-.16,7.43-.88,4.55-1.07,7.37-2.78,10.47-4.3,3.22-1.59,7.89-3.56,14.09-5.09-.28,2.34-.47,4.97-.49,7.83-.02,3.17.18,6.07.49,8.62-1.92-.07-5.28.06-9,1.65-2.57,1.1-4.44,2.52-5.67,3.62-1.97,1.33-4.38,2.72-7.24,3.91-1.21.5-2.37.92-3.47,1.27Z"
      />
      <path
        style={styles.three}
        d="m357.88,680.36l-17.32,7.83c-.48-1.8-1.14-3.82-2.05-5.97-1.14-2.7-2.42-4.98-3.62-6.85-5.41,2.25-10.83,4.5-16.24,6.75-.19-1.37-.43-2.8-.74-4.27-.75-3.59-1.73-6.81-2.78-9.62,6.33-3.03,12.65-6.07,18.98-9.1l-1.86-11.93,16.24-9.39-2.64-12.33,10.47-4.21c3.36-3.57,6.54-5.27,8.9-6.16,2.66-1.01,5.71-1.52,10.76-3.82,1-.45,1.81-.85,2.35-1.13-.54,1.55-1.09,3.63-1.27,6.12-.12,1.67-.05,3.16.1,4.4-1.57.47-3.25,1.05-4.99,1.76-3.4,1.39-6.3,2.97-8.71,4.5-.02,2.18.08,4.65.39,7.34.34,2.94.88,5.56,1.47,7.83l-15.85,9.39c-.09,3.4.14,7.86,1.37,12.91,1.74,7.15,4.75,12.53,7.04,15.95Z"
      />
      <path
        style={styles.three}
        d="m301.83,661.24l11.65-8.27c-.22,2.03-.33,4.68,0,7.73.33,3.05,1.01,5.6,1.66,7.53-.83,1.27-1.66,2.54-2.49,3.82-.84-1.41-1.92-3-3.32-4.6-2.63-3.02-5.39-4.98-7.5-6.21Z"
      />
      <path
        style={styles.three}
        d="m291.65,725.55c0,.42,1.17,10.79,1.17,10.79-1.13.1-2.26.2-3.39.29-.07-2.3-.07-4.74.03-7.3.07-1.84.18-3.61.33-5.31.62.51,1.24,1.01,1.86,1.52Z"
      />
      <path
        style={styles.three}
        d="m291.39,736.22c-.21.13-.43.28-.65.46-.57.45-.98.93-1.27,1.34.07.97.13,1.98.16,3.03.02.76.03,1.5.03,2.22l.03,3.33c.77,1.08,1.55,2.15,2.32,3.23.27-4.49.54-8.98.81-13.47-.48-.04-.96-.09-1.43-.13Z"
      />
      <polygon
        style={styles.three}
        points="329.41 745.9 339.13 746.41 348.68 762.63 328.73 761.88 329.41 745.9"
      />
      <path
        style={styles.three}
        d="m344.28,794.78c6.72-.48,13.43-.96,20.15-1.43-.88,1.15-1.86,2.63-2.74,4.43-1.17,2.42-1.79,4.65-2.12,6.37-2.27-1.31-4.56-2.68-6.88-4.09-2.89-1.76-5.69-3.52-8.41-5.28Z"
      />
      <path
        style={styles.three}
        d="m375.2,795.7c-1.19.47-.42,3.77-1.89,9.2-.67,2.46-1.52,4.37-2.1,5.53,6.09,2.87,12.17,5.74,18.26,8.61.12-.45.23-.91.35-1.36-1.4-1.97-2.88-4.3-4.3-6.98-1.48-2.79-2.6-5.42-3.46-7.76-4.3-6.62-6.05-7.56-6.85-7.24Z"
      />
      <path
        style={styles.three}
        d="m394.76,820.93l6.85-4.38h2.28c-.07,2.68-.13,5.35-.2,8.03l-8.93-3.65Z"
      />
      <path
        style={styles.three}
        d="m557.35,607.18c.36,1.53.67,3.23.88,5.09.38,3.34.32,6.32.1,8.8,2.77.15,5.67.4,8.71.78,6.37.8,12.13,2.03,17.22,3.42,3.46.78,6.91,1.57,10.37,2.35-.29-2.1-.5-4.53-.49-7.24,0-2.55.21-4.85.49-6.85-2.34-.33-4.72-.65-7.15-.93-4.73-.56-9.33-.99-13.79-1.32-2.95-.34-6.33-.93-9.98-1.96-2.33-.65-4.45-1.39-6.36-2.15Z"
      />
      <path
        style={styles.three}
        d="m626.61,727.35c1.41,4.3,2.83,8.61,4.24,12.91.29.09,2.03.67,2.54,2.24.08.23.14.53.14.89-5.16,1.69-9.21,3.31-12,4.5-2.79,1.19-5.33,2.38-5.93,1.63-.54-.67.94-2.28,2.15-5.35,1.04-2.66,1.35-5.05,1.43-6.72l-2.87-11.8c3.43.57,6.87,1.13,10.3,1.7Z"
      />
      <path
        style={styles.nine}
        d="m431.66,309.3l48.9,8.45c2.27.39,4.16,2,4.87,4.2s1.29,4.82,1.48,7.88c.3,4.75-.49,8.68-1.27,11.31-.17.57-.41,1.1-.73,1.6-1.23,1.93-3.9,6.94-2.7,13.18.12.6.26,1.17.42,1.71,1.14,3.76-1.28,7.63-5.18,8.09-1.26.15-2.55.27-3.85.37-24.14,1.88-44.41-5.35-57.88-12.07-1.99-.99-3.33-2.99-3.41-5.21-.01-.32-.02-.64-.01-.97.05-3.73,1.19-6.74,2.21-8.72.44-.86.66-1.82.66-2.79v-8.12c0-1.69.67-3.32,1.9-4.48,1.97-1.87,4.21-4.4,6.19-7.72.64-1.07,1.19-2.12,1.66-3.13,1.2-2.57,3.94-4.05,6.74-3.57Z"
      />
      <path
        style={styles.nine}
        d="m554.07,325.56s-3.13-8.87,6.52-9.65c0,0,20.12-2.39,30-4.17,1.99-.36,5.19-1.01,9.13-2.16,1.21,0,2.84.1,4.7.6,1.62.43,2.93,1.03,3.91,1.57,0,0,18.78,15.65,20.61,40.43-7.35,1.91-15.18,3.69-23.48,5.22-14.93,2.76-28.85,4.27-41.48,5v-7.08c-.07-3.96-.63-9.69-2.87-16.17-2.06-5.96-4.83-10.49-7.04-13.57Z"
      />
      <path
        style={styles.nine}
        d="m474.15,378.93c.16-1.45,1.34-2.56,2.79-2.62,5.27-.21,11.14-.14,17.48.39,6.85.57,10.8-1.07,13.07,1.71.61.74.85,1.48,1.13,2.51,1.84,6.8,1.58,13.22,1.57,16.43,0,1.37.33,5.04.99,12.39.61,6.76.78,13.57,1.74,20.28.7,4.89,1.5,8.7-.78,11.15-1.28,1.37-2.98,1.75-4.11,1.96-10.58,1.93-27.16-.73-31.67.84-.51.18-2.26.51-2.73,1.99-.22.7-.43,1.44-.6,2.21-1.01,4.36-.87,8.32-.38,11.48.28,1.81-1.13,3.47-2.97,3.39-.02,0-.03,0-.05,0-5.37-.27-8.8-3.35-13.3-6.52-2.89-2.03-7.03-4.67-12.52-7.3-1.47-.64-3.32-1.62-5.22-3.13-2.72-2.18-4.44-4.57-5.48-6.33-.18-.3-.4-.56-.67-.79-1.7-1.42-3.92-3.6-5.85-6.71-1.47-2.37-2.38-4.61-2.94-6.41-.12-.39-.16-.81-.1-1.21.24-1.83.37-4.33-.09-7.24-.51-3.2-1.54-5.73-2.41-7.45-.13-.25-.3-.48-.5-.68-2.72-2.83-3.61-6.47-2.3-9.09.21-.41.62-1.11,1.43-1.8.64-.55,1.5-.75,2.34-.61,6.91,1.18,15.66,2,25.71,1.36,7.22-.46,13.6-1.57,18.98-2.86.73-.18,1.36-.63,1.77-1.26,1.58-2.47,3.37-5.9,4.56-10.23.58-2.11.92-4.09,1.12-5.86Z"
      />
      <path
        style={styles.nine}
        d="m527.61,381.89l6.89,60.07c.21,1.33,1.35,2.3,2.69,2.3h40.73c1.48,0,2.71,1.18,2.73,2.67.01,1.13-.02,2.33-.13,3.59-.41,4.77-1.71,8.71-2.97,11.61-.38.87-.31,1.89.28,2.64.12.15.23.27.35.36,1.99,1.69,6.72.26,11.76-3.72.16-.12.33-.23.51-.32,6.6-3.1,13.21-6.21,19.81-9.31.96-.45,1.57-1.41,1.57-2.47v-17.07c0-.79.33-1.54.94-2.05,3.49-2.98,7.47-7.54,8.46-13.58.23-1.39.25-2.58.26-3.39.08-5.3-1.16-9.84-2.62-13.38-.44-1.06-1.5-1.72-2.65-1.66l-39.54,1.94c-.73.04-1.44-.21-1.98-.71-1.29-1.21-2.81-2.92-4.09-5.23-2.66-4.81-2.77-9.46-2.64-11.82.02-.32-.01-.63-.1-.93-.35-1.15-1.11-2.12-3.78-3.68-3.16-1.85-6.69-1.7-9.01-1.73-4.76-.06-8.09.16-11.03.33-11.44.65-15.42.33-16.49,2.67-.4.86-.3,1.86.07,2.88Z"
      />
      <path
        style={styles.six}
        d="m369.13,809.39c1.5.73,3,1.45,4.5,2.18l.85-8.83c.43-2.52.75-5.41.78-8.61.07-6.67-1.1-12.28-2.35-16.43,1.3-5.39,2.61-10.78,3.91-16.17-2.91-.66-6.65-1.81-10.7-3.91-5.31-2.76-9.07-6.1-11.48-8.61-1.25-.52-2.03-.6-2.35-.26-1.13,1.22,3.65,7.92,14.35,20.09,1.57,3.91,3.13,7.83,4.7,11.74,3.15,7.87,5.43,17.84.78,25.3-1.06,1.7-2.24,2.86-3,3.52Z"
      />
      <path d="m294,748.15c-1.25-13.42-2.5-33.61-3.26-47.22,0,0,2-.08,2-.08.19,13.69.59,33.79,1.27,47.3h0Z" />
      <path
        style={styles.six}
        d="m627.78,746.65s-.52.52,6.78,7.3l1.3,12.52s1.57-19.83,0-22.17-8.09,2.35-8.09,2.35Z"
      />
      <path d="m662.43,687.6c-3.68,9.29-7.03,18.76-9.88,28.34-2.77,9.59-5.45,19.37-5.72,29.41.12-15.1,4.67-29.8,8.94-44.16,1.51-4.8,3.09-9.56,4.8-14.31,0,0,1.86.72,1.86.72h0Z" />
      <path d="m635.14,801.62l1.1-9.37c.02.45-2.67-18.74-2.67-18.63,0,0-.02-.13-.02-.13l.03-.16,1.3-7.04,1.97.36s-1.3,7.04-1.3,7.04c-.02-1.48,1.35,18.9,1.35,18.57,0,0,0,.05,0,.05v.03s-1.76,9.27-1.76,9.27h0Z" />
      <path d="m393.53,821.52c12.4-16.95,26.5-32.53,40.75-47.93-12.9,16.54-26.02,32.97-40.75,47.93h0Z" />
      <path d="m301.83,662.07c7.41,10.83,13.78,22.35,19.18,34.31,2.8,6.01,4.55,12.69,4.96,19.31.26,6.59,1.74,12.58,5.71,17.84,7,16.68,18.93,31.09,33.31,41.97.93.62,2.95.8,4.02-.19,0,0,1.13,1.65,1.13,1.65-1.84,1.21-4.4,1.13-6.15-.18-13.98-11.82-25.41-26.32-34.49-42.17-1.49-1.36-4.1-5.73-4.92-8.7-1.05-3.34-1.18-6.79-1.4-10.01-.33-6.43-1.76-12.66-4.3-18.6-4.94-12.08-10.69-23.83-17.05-35.23h0Z" />
      <path d="m635.5,773.88c-4.91,12.01-9.93,23.98-14.89,35.97,4.34-12.23,8.63-24.48,13.02-36.68l1.87.72h0Z" />
      <line
        style={styles.seven}
        x1="425.35"
        y1="580.74"
        x2="486.39"
        y2="614.39"
      />
      <path
        style={styles.six}
        d="m549,589.35c-5.23,3.43-10.95,6.88-17.18,10.22-13.57,7.28-26.47,12.42-37.91,16.1"
      />
      <path d="m513.34,374.82c-12.85-.26-32.3-.96-45.31-1.56,0,0,1.01-.7,1.01-.7l-6.52,21.13-.2.64s-.64.06-.64.06c-1.93.18-6.8.62-8.66.79-12.53.78-25.17.06-37.54-2.1-.02,0,1.06-.52,1.05-.52-5.73,9.9-5.78,22.36-.88,32.66-5.74-10.39-6.18-23.65-.57-34.24,13.39,2.35,27.11,3.18,40.66,1.94,0,0,5.7-.52,5.7-.52l-.84.7,6.52-21.13.23-.74.78.03,22.62.94c6.63.23,15.93.45,22.63.62,0,0-.04,2-.04,2h0Z" />
      <path d="m488.31,368.17c-26.57,3.06-55.16-.11-79.92-10.43,11.74,2.12,28.12,5.2,39.76,6.75,13.35,1.84,26.69,2.85,40.16,3.69h0Z" />
      <path d="m522.03,373.18l44.87-1.92c2.08.11,4.37.62,6.11,2.24,1.69,1.46,2.1,3.93,2.3,5.95.42,3.77,1.75,7.32,3.7,10.56,2.08,2.62,6.56,5.12,10.57,5.25,4.16.33,8.38-.63,12.7-1.38,8.68-1.47,17.53-1.77,26.28-1.22,0,0,.8.04.8.04,0,0,.13.86.13.86.84,6.03.71,12.2-.31,18.21-1.12,3.11-2.12,6.28-3.55,9.26-1.1,2.65-3.19,6.08-4.6,8.6-3.31,5.51-3.78,11.63-3.75,18.01-1.88-4.57-2.33-9.94-.85-14.76,1.55-4.69,4.5-8.72,6.38-13.15,1.27-2.54,2.58-6.72,3.42-8.62,1.1-5.72,1.51-11.55,1.27-17.38-.01,0,.98.93.92.91-8.62-.54-17.31-.24-25.81,1.2-4.23.72-8.62,1.75-13.2,1.4-4.61-.31-9.15-2.53-12.08-6.15-2.09-3.45-3.57-7.39-4.01-11.41-.16-4.39-2.42-6.19-6.35-6.44,0,0-44.87,1.92-44.87,1.92l-.09-2h0Z" />
      <path d="m528.98,446.65h44.31s.99,0,.99,0c.21,8.32-1.35,16.67-4.58,24.34,0,.02-.95-1.39-.95-1.38,0,0,17.51.48,17.51.48l-17.45,1.52-1.56.14.61-1.52c2.99-7.12,4.51-14.84,4.43-22.56,0,0,1,.99,1,.99h-44.31v-2h0Z" />
      <path d="m432.34,453.91l42.98,14.02c.86.37,1.91.59,2.8.43,2.98-.6,2.9-4.71,1.31-6.69-1.42-1.06-3.42-4.33-3.6-6.83-.39-2.86.43-5.81,2.12-8.16,0,0,.49-.03.49-.03,13.07-.94,26.74-.37,39.79,1.05,0,0-.23,1.99-.23,1.99-5.85-.52-13.9-1.37-19.69-1.42-6.58-.17-13.17-.05-19.74.38l.76-.43c-1.3,1.83-1.96,4.16-1.72,6.41.21,2.28,1.37,4.49,3.07,6.06,1.38,1.97,1.83,4.68.64,6.87-1.21,2.47-4.5,2.53-6.41,1.54,0,0-42.58-15.2-42.58-15.2h0Z" />
      <path d="m434.26,304.06l60.16,12s1.46.29,1.46.29l-.84,1.28c-5.08,8.22-4.49,18.9-1.43,27.78,0,0,.17.57.17.57l-.38.44c-5.15,5.5-6.08,13.66-1.78,19.98-5.13-6.23-4.63-15.08.3-21.32,0,0-.21,1-.21,1-3.26-9.56-3.84-20.73,1.7-29.61,0,0,.61,1.57.61,1.57l-60.16-12s.39-1.96.39-1.96h0Z" />
      <path d="m549.05,314.13l2.87,13.04s-.16-.37-.16-.36c5.33,7.84,8.11,17.51,7.04,26.95-.1,3.7-1.43,7.57-3.68,10.54,2.1-3.12,3.19-6.87,3.1-10.57.59-9.32-2.61-18.57-8.21-25.94,0,0-.04-.19-.04-.19l-2.87-13.04,1.95-.43h0Z" />
      <path d="m558.51,367.29c12.74-1.49,25.4-2.88,38.04-4.99,12.63-2.08,25.19-4.6,37.87-6.48-24.52,7.49-50.16,12.77-75.91,11.48h0Z" />
      <path d="m558.62,312.05c11.58-1.57,23.08-3.53,34.53-5.88.06,0,11.46-2.3,11.52-2.29,6.61-.39,13.12,3.34,16.19,9.16-1.63-2.8-4.02-5.15-6.87-6.65-2.77-1.52-6.3-2.17-9.24-1.93-6.73,1.54-16.15,3.78-22.81,5.21-7.64,1.63-15.31,3.07-23.01,4.36,0,0-.3-1.98-.3-1.98h0Z" />
      <path d="m365.68,409.18c6.45,4.15,12.1,8.86,18.65,12.74,6.54,3.97,13.44,6.98,20.67,9.6-11.76-.64-23.32-5.16-32.68-12.27-3.09-2.47-5.91-5.31-8.06-8.67,0,0,1.42-1.41,1.42-1.41h0Z" />
      <path d="m547.41,534.23l-10.17,4.7.51-.53c-1.14,2.32-2.29,4.63-3.46,6.93-1.06,2-2.48,4.92-3.73,6.78.58-1.15,1.02-2.36,1.48-3.56,1.35-3.63,2.74-7.61,3.97-11.26,0,0,.39-.18.39-.18l10.17-4.7.84,1.82h0Z" />
      <path
        style={styles.seven}
        d="m416.15,550.48l20.68-6.78s10.17-2.35,12.52-12.78"
      />
      <path d="m648.83,752.39c-4.2,14.17-5.27,29.34-2.92,44.09-3.01-14.62-2.65-29.99.99-44.61l1.93.52h0Z" />
      <path d="m375.83,761.45c.63-7.75-.79-15.56-3.65-22.77-.32-3.52-.13-7.08.6-10.53,1.4-6.91,5.53-12.64,7.84-19.2,1.24-3.2,2.32-6.47,3.26-9.78-.78,3.35-1.7,6.68-2.79,9.95-1.94,6.53-5.8,12.55-6.83,19.3-.53,3.32-.54,6.71-.08,10.03,2.66,5.94,4.36,15.61,3.66,23.14,0,0-1.99-.15-1.99-.15h0Z" />
      <g>
        <path d="m460.98,525.94s-11.31,49.7-11.31,49.7c0,0-.13.58-.13.58-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7s1.95.44,1.95.44h0Z" />
        <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59,0,0,1.88.69,1.88.69h0Z" />
      </g>
      <path
        style={styles.seven}
        d="m340.97,363.98c-.44.45-.85.92-.85.92-.63.71-1.47,1.75-2.32,3.08-3.83,6.02-4.02,12.25-4.08,14.87-.08,3.74-.24,12.36,5.93,19.7,1.41,1.67,5.86,6.72,13.5,8.02,5.31.91,9.62-.43,11.65-1.21.35,1.08.7,2.16,1.05,3.24.89,2.66,2.2,6.5,3.92,11.08,5.77,15.36,12.34,29.14,16.63,36.78,18.49,32.89,48.78,52.52,50.09,53.35,5.51,3.49,26.99,16.57,56.35,20.87,48.98,7.17,96.5-11.21,122.61-37.57,8.76-8.84,15.79-19.26,16.17-19.83,9.81-14.66,13.89-26.95,20.35-46.96,6.8-21.04,10.75-33.5,12.52-51.65,1.23-12.64,2.58-26.47-1.21-44.22-2.02-9.48-9.2-40.95-35.7-60.62,0,0-.97-.72-2.22-1.59-25.41-17.65-105.98-50.7-181.99-20.74-31.37,12.37-44.52,24.29-50.87,33.65-5.34,7.87-8.29,15.77-9.39,18.78-10.44,28.46-3.48,51.92-11.74,54.78-3.81,1.32-5.33-3.66-13.3-3.13-9.13.61-16.5,7.78-17.11,8.39Z"
      />
      <path
        style={styles.three}
        d="m474,555.89l-11.48-8.93s-10.83,3.52-9.2,13.43c0,0,8.93,7.7,18.33,2.61l2.35-7.11Z"
      />
      <polygon
        style={styles.three}
        points="447.34 529.29 462.52 546.96 469.73 528.57 447.65 519.76 447.34 529.29"
      />
      <path
        style={styles.eight}
        d="m504.08,561.22s.91-24.21,1.99-26.52l12.82.38s-2.71,24.93-.82,29.86l-13.99-3.72Z"
      />
      <polygon
        style={styles.three}
        points="507.31 560.9 512.07 575.36 509.69 582.77 543.46 587.39 527.05 578.56 533.59 566.68 507.31 560.9"
      />
      <path
        style={styles.three}
        d="m499.04,574.35c.13.39,0,8.29,0,8.29l11.64-1.81c.35-3.01.7-6.01,1.06-9.02-4.23.85-8.46,1.7-12.7,2.54Z"
      />
      <path
        style={styles.twelve}
        d="m500,581.33l-1.74,14.54,10.57-2.09,5.09-3.91s8.42-2.01,8.35-7.96-22.26-.58-22.26-.58Z"
      />
      <path
        style={styles.twelve}
        d="m532.43,593.78l3.26,6.13,9.88-6.13,4-6.39-8.57-3.79s-4.79,11.1-8.57,10.19Z"
      />
      <path
        style={styles.twelve}
        d="m465.43,539.28l4.43,14.28,14.87,2.93,8.68-22.53,5.57-5.54s-12.58,3.78-23.7-9.96-9.84,20.82-9.84,20.82Z"
      />
      <path
        style={styles.nine}
        d="m515.61,277.35h-8.87c3.65,57.45,7.3,114.9,10.96,172.35.72,13.55-.24,24.81-1.39,32.98-1.11,7.94-2.2,15.44-5.66,24.76-4.01,10.83-9.29,18.95-13.3,24.26-1.78,1.49-5.22,4.78-7.3,10.17-2.54,6.58-1.55,12.36-1.04,14.61-.25,8.11-.28,14.68-.26,19.04.05,12.75.52,19.27-.78,29.48-.52,4.06-1.14,7.34-1.57,9.39v86.09c-.26,3.78-.59,9.49-.78,16.43-.52,18.78.45,30.99,2.16,60.82,1.17,20.29,1.42,23.36,1.75,32.31.47,12.79.59,23.27.62,30.16,2.07.03,4.15.06,6.22.09-.48-3.11-.44-5.61-.32-7.3.29-3.98,1.16-6.35,1.3-12,.09-3.43-.29-4.69-1.57-14.09-.66-4.87-1.16-8.9-1.49-11.7-.67-18.53-1.22-37.24-1.64-56.12-.92-40.58-1.25-80.42-1.04-119.48,1.52-2.35,3.54-5.88,5.22-10.43,3.17-8.62,3.44-16.35,3.17-21.39-.36-14.61-.72-29.22-1.09-43.83,1.41-2.16,3.47-5.37,5.82-9.32,8.18-13.74,14.83-27.86,17.92-36.33,9.16-25.17,6.38-54.33,4.96-65.48-2.59-23.53-4.9-47.72-6.87-72.56-2.25-28.4-3.94-56.06-5.13-82.92Z"
      />
      <path
        style={styles.three}
        d="m436.66,570.45s-.01-.01-.03-.02c-1.1-.71-5.33,2.28-6.07,5.06-1.11,4.17,4.65,11.6,11.49,11.27,4.52-.22,8.67-3.79,10.26-8.87,0,0-11.18-1.58-15.65-7.44Z"
      />
      <path
        style={styles.ten}
        d="m442.09,551l12.35,8.87s-5.74,9.39-1.11,17.74c4.63,8.35-19.37-11.26-19.37-11.26l8.14-15.35Z"
      />
      <path
        style={styles.ten}
        d="m439.43,547.34s12.39,12.07,16.43,12.53,5.28-12.91,5.28-12.91l-11.8-14.44s-2.91,15.52-9.91,14.82Z"
      />
      <polyline
        style={styles.three}
        points="433.37 583.6 428.67 581.41 426.04 577.22 441.23 582.24"
      />
      <path
        style={styles.seven}
        d="m556.96,530.06c1.83,1.07,4.9,3.22,7.43,6.98,3.47,5.16,3.86,10.33,3.9,12.52-.67,7.71-3.05,20.83-12.64,32.22-3.53,4.2-7.02,6.95-10.41,9.36-16.33,11.65-35.77,19.5-51.33,24.53-22.77-11.45-45.54-22.9-68.31-34.34-.64-.84-1.62-2.18-2.69-3.92-1.17-1.9-13.8-23.07-4.48-40.17,1.08-1.98,3.02-4.94,6.52-7.95-1.35-9.15-2.7-18.31-4.05-27.46"
      />
      <rect
        style={styles.two}
        x="507"
        y="283.83"
        width="9.76"
        height="18.49"
        rx="4.88"
        ry="4.88"
        transform="translate(-11.47 21.01) rotate(-2.32)"
      />
      <rect
        style={styles.nine}
        x="509.35"
        y="296.13"
        width="6.16"
        height="3.56"
        rx="1.78"
        ry="1.78"
        transform="translate(-11.66 21.03) rotate(-2.32)"
      />
      <rect
        style={styles.two}
        x="509.73"
        y="279.72"
        width="3.53"
        height="9.36"
        rx="1.76"
        ry="1.76"
        transform="translate(-11.12 20.98) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="508.68"
        y="307.97"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-12.12 21.01) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="511.32"
        y="303.8"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-11.95 21.12) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="511.62"
        y="311.92"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-12.28 21.13) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="509.07"
        y="317.26"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-12.5 21.03) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="512.06"
        y="321.22"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-12.66 21.16) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="510.15"
        y="326.35"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-12.87 21.09) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="512.01"
        y="330.41"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-13.03 21.16) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="510.84"
        y="335.6"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-13.24 21.12) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="512.7"
        y="339.95"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-13.42 21.2) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="511.38"
        y="345.04"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-13.62 21.15) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="513.66"
        y="349.49"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-13.8 21.25) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="511.31"
        y="354.58"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-14.01 21.16) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="513.84"
        y="359.18"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-14.2 21.26) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="512.19"
        y="364.06"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-14.4 21.2) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="514.9"
        y="368.52"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-14.57 21.31) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="512.99"
        y="374.29"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-14.81 21.24) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="515.53"
        y="378.64"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-14.98 21.35) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="513.55"
        y="384.31"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-15.22 21.27) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="516.93"
        y="389.06"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-15.41 21.41) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="514.29"
        y="394.88"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-15.64 21.31) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="517.42"
        y="398.94"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-15.81 21.44) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="515.56"
        y="403.93"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-16.01 21.37) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="518.3"
        y="408.23"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-16.18 21.48) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="515.85"
        y="413.12"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-16.38 21.39) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="519.23"
        y="416.94"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-16.53 21.53) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="516.78"
        y="421.83"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-16.74 21.43) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="520.3"
        y="425.7"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-16.89 21.58) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="516.51"
        y="431.47"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-17.13 21.43) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="521.26"
        y="435.67"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-17.29 21.63) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="517.54"
        y="441.79"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-17.54 21.48) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="521.99"
        y="446"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-17.71 21.66) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="522.43"
        y="456.17"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-18.12 21.69) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="518.62"
        y="451.09"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(-17.92 21.53) rotate(-2.32)"
      />
      <rect
        style={styles.one}
        x="518.32"
        y="461.11"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
      />
      <rect
        style={styles.one}
        x="522.48"
        y="465.68"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
      />
      <rect
        style={styles.one}
        x="517.78"
        y="470.81"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
      />
      <rect
        style={styles.one}
        x="521.1"
        y="475.61"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
      />
      <rect
        style={styles.one}
        x="516.55"
        y="480.01"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
      />
      <rect
        style={styles.one}
        x="519.78"
        y="484.64"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
      />
      <rect
        style={styles.one}
        x="514.89"
        y="489.78"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
      />
      <rect
        style={styles.one}
        x="517.73"
        y="494.92"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
      />
      <rect
        style={styles.one}
        x="512.45"
        y="499.52"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
      />
      <rect
        style={styles.one}
        x="513.62"
        y="504.16"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(105.04 -88.68) rotate(10.91)"
      />
      <rect
        style={styles.one}
        x="509.95"
        y="507.93"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(105.69 -87.91) rotate(10.91)"
      />
      <rect
        style={styles.one}
        x="509.32"
        y="513.01"
        width="6.06"
        height="2.56"
        rx="1.08"
        ry="1.08"
        transform="translate(106.64 -87.7) rotate(10.91)"
      />
      <rect
        style={styles.one}
        x="505.7"
        y="517.4"
        width="5.47"
        height="2"
        rx=".92"
        ry=".92"
        transform="translate(327.32 -184.77) rotate(30)"
      />
      <rect
        style={styles.one}
        x="504.48"
        y="522.15"
        width="5.47"
        height="2"
        rx=".92"
        ry=".92"
        transform="translate(329.53 -183.52) rotate(30)"
      />
      <rect
        style={styles.one}
        x="500.81"
        y="525.62"
        width="5.47"
        height="2"
        rx=".92"
        ry=".92"
        transform="translate(330.77 -181.22) rotate(30)"
      />
      <rect
        style={styles.one}
        x="500.47"
        y="530.26"
        width="5.47"
        height="2"
        rx=".92"
        ry=".92"
        transform="translate(333.05 -180.43) rotate(30)"
      />
      <rect
        style={styles.one}
        x="495.68"
        y="532.97"
        width="5.47"
        height="2"
        rx=".92"
        ry=".92"
        transform="translate(333.76 -177.67) rotate(30)"
      />
      <rect
        style={styles.one}
        x="496"
        y="537.61"
        width="5.47"
        height="2"
        rx=".92"
        ry=".92"
        transform="translate(336.12 -177.21) rotate(30)"
      />
      <rect
        style={styles.one}
        x="490.62"
        y="539.82"
        width="5.47"
        height="2"
        rx=".92"
        ry=".92"
        transform="translate(336.5 -174.22) rotate(30)"
      />
      <rect
        style={styles.one}
        x="493.12"
        y="544.67"
        width="5.47"
        height="2"
        rx=".92"
        ry=".92"
        transform="translate(88.49 -68.89) rotate(8.72)"
      />
      <rect
        style={styles.one}
        x="488.62"
        y="547.65"
        width="5.47"
        height="2"
        rx=".92"
        ry=".92"
        transform="translate(88.89 -68.17) rotate(8.72)"
      />
      <rect
        style={styles.one}
        x="493.26"
        y="551.22"
        width="5.47"
        height="2"
        rx=".92"
        ry=".92"
        transform="translate(-23.04 21.6) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="488.97"
        y="555.28"
        width="5.47"
        height="2"
        rx=".92"
        ry=".92"
        transform="translate(-23.22 21.42) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="493.08"
        y="558.64"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(-23.36 21.61) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="489.02"
        y="563.59"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(-23.58 21.44) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="493.08"
        y="568.14"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(-23.77 21.62) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="488.33"
        y="572.62"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(-23.96 21.42) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="492.76"
        y="576.89"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(-24.14 21.62) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="488.63"
        y="582.26"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(-24.37 21.44) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="493.47"
        y="586.88"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(-24.57 21.66) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="488.58"
        y="591.58"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(-24.77 21.45) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="493.03"
        y="595.69"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(-24.94 21.64) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="487.96"
        y="600.43"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(-25.15 21.43) rotate(-2.44)"
      />
      <rect
        style={styles.one}
        x="491.63"
        y="605.76"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(118.88 -79.99) rotate(10.5)"
      />
      <rect
        style={styles.one}
        x="486.93"
        y="609.34"
        width="6.2"
        height="2.39"
        rx="1.07"
        ry="1.07"
        transform="translate(119.45 -79.07) rotate(10.5)"
      />
      <rect
        style={styles.one}
        x="487.56"
        y="613.82"
        width="5.74"
        height="2.03"
        rx=".95"
        ry=".95"
        transform="translate(120.25 -79.07) rotate(10.5)"
      />
      <rect
        style={styles.one}
        x="486.28"
        y="617.65"
        width="3.76"
        height="1.91"
        rx=".75"
        ry=".75"
      />
      <rect
        style={styles.one}
        x="488.77"
        y="621.03"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.57"
        y="624.4"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.23"
        y="627.97"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.42"
        y="631.74"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.18"
        y="635.64"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.03"
        y="638.33"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="485.91"
        y="644.48"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="485.91"
        y="651.67"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="485.91"
        y="658.71"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.28"
        y="666.15"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="485.96"
        y="673.98"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.06"
        y="681.85"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.06"
        y="690.07"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.11"
        y="698.53"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.21"
        y="705.77"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="485.96"
        y="713.76"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.06"
        y="721"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="485.96"
        y="729.74"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="485.74"
        y="745.63"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.74"
        y="753.26"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.59"
        y="762.7"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="487.14"
        y="772.34"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="487.53"
        y="782.17"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.41"
        y="790.44"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.6"
        y="798.02"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="489.06"
        y="805.54"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="489.35"
        y="812.24"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="490.21"
        y="820.6"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="489.7"
        y="828.67"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="489.77"
        y="836.64"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="486.06"
        y="736.98"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.47"
        y="641.4"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.57"
        y="647.88"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.45"
        y="655.17"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.65"
        y="662.31"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.65"
        y="670.21"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.76"
        y="677.5"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.82"
        y="685.73"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.76"
        y="693.98"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.75"
        y="702.32"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.66"
        y="716.63"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.65"
        y="724.97"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.85"
        y="733.36"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.84"
        y="741.7"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="489.59"
        y="749.4"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="489.58"
        y="757.74"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="489.32"
        y="767.15"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="489.66"
        y="777.23"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="489.9"
        y="786.23"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="491.13"
        y="794.11"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="492.2"
        y="801.49"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="492.81"
        y="808.88"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="493.8"
        y="816.15"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="493.98"
        y="824.46"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="493.2"
        y="832.72"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <rect
        style={styles.one}
        x="488.4"
        y="710.15"
        width="3.31"
        height="1.61"
        rx=".64"
        ry=".64"
      />
      <path
        style={styles.fourteen}
        d="m428.22,461.32c-.39.5-9.91,9.49-9.91,9.49l-6.33-8.42,16.24-1.07Z"
      />
      <polygon
        style={styles.twelve}
        points="406.5 479.59 422.25 479.92 428.22 489.78 409.83 483.43 406.5 479.59"
      />
    </g>
  );
};

export default Creeper;
