import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import Hover from "../custom/Hover";
import { getReadableTime } from "../../utils/helpers";
import {
  cardDark,
  normal,
  text,
  small,
  doubtedBlue,
} from "../../utils/themeContstants";

const MatchSystemMessage = ({ message }) => {
  const isDesktop = useMediaQuery("(min-width:1025px)");

  const [hovered, setHovered] = useState(false);
  const [refEl, setRefEl] = useState(null);
  const styles = {
    container: {
      padding: 1.5,
      backgroundColor: cardDark,
      borderRadius: 1,
      overflowX: "hidden",
      width: "100%",
      maxWidth: "100%",
      position: "relative",
    },
    username: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    message: {
      fontWeight: 500,
      color: text,
      fontSize: normal,
      overflowWrap: "break-word",
      verticalAlign: "middle",
      lineHeight: 1.6,
      marginLeft: 4,
    },
    badgeContainer: {
      display: "inline-flex",
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={setRefEl}
    >
      {hovered && (
        <Hover
          label={getReadableTime(new Date(message?.timestamp))}
          placement={isDesktop ? "left" : "top"}
          refEl={refEl}
        />
      )}
      <div
        item
        style={{
          whiteSpace: "nowrap",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "start",
          overflow: "hidden",
          maxWidth: "100%",
          verticalAlign: "middle",
          marginRight: 2,
        }}
      >
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: doubtedBlue,
            paddingLeft: 4,
            paddingRight: 4,
            borderRadius: 2,
          }}
        >
          <Typography sx={{ fontSize: small, color: text, fontWeight: 700 }}>
            MATCH
          </Typography>
        </div>
      </div>

      <span style={styles.message}>{message?.message}</span>
    </Grid>
  );
};

export default MatchSystemMessage;
