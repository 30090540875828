const Scared = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
  };

  return (
    <g transform="translate(510, 458)">
      <path d="M49.83,24.07c-.83-.13-1.43-.18-2-.31-3.77-.82-7.5-1.98-11.31-2.42-12.47-1.46-23.82,1.48-33.72,9.41-.41,.32-.79,.67-1.21,.97-.48,.35-1.05,.39-1.38-.12-.2-.31-.29-1.03-.1-1.21,1.07-1.01,.85-2.35,1.01-3.57,.76-5.82,2.7-11.2,6.01-16.05,2.14-3.14,4.82-5.74,8.1-7.68,7.58-4.49,15.06-4.01,22.09,.99,6.1,4.34,9.77,10.48,11.46,17.78,.15,.67,.59,1.27,1.05,2.21ZM3.12,27.16c13.72-9.38,28.12-10.74,43.63-5.82-2.18-3.65-3.44-7.52-6.21-10.59-.74,.43-1.31,.82-1.93,1.11-1.88,.86-3.72,1.92-5.69,2.47-7.41,2.07-14.91,2.33-22.43,.6-.97-.22-1.47,.04-2.07,.71-2.87,3.2-4.01,7.18-5.3,11.52Zm7.92-14.27c.8,.08,1.31,.14,1.82,.19,.57,.05,1.15,.07,1.72,.14,6.02,.67,11.97,.27,17.82-1.24,2.27-.58,4.45-1.43,6.45-2.84-2.51-2.46-5.44-3.84-8.78-4.24-4.69-.56-9.03,.68-13.07,2.98-2.19,1.25-4.23,2.75-5.96,5.01Z" />
      <path d="M42.71,29.56c-.92-.12-1.74-.19-2.54-.32-2.44-.41-4.86-.98-7.31-1.25-4.45-.5-8.71,.54-12.87,2.01-2.44,.86-4.84,1.83-7.27,2.75-.25,.1-.51,.19-.98,.36,.44-1.34,1.37-1.89,2.28-2.42,2.71-1.58,5.63-2.64,8.63-3.46,6.27-1.72,12.43-1.37,18.46,1.08,.48,.19,.93,.44,1.38,.69,.07,.04,.08,.2,.2,.56Z" />
      <path d="M3.12,27.16c1.3-4.35,2.43-8.32,5.3-11.52,.6-.67,1.11-.93,2.07-.71,7.52,1.73,15.02,1.48,22.43-.6,1.97-.55,3.81-1.61,5.69-2.47,.62-.28,1.19-.68,1.93-1.11,2.76,3.07,4.02,6.94,6.21,10.59-15.51-4.92-29.91-3.56-43.63,5.82Z" />
      <path
        style={styles.one}
        d="M11.04,12.9c1.74-2.26,3.77-3.76,5.96-5.01,4.04-2.3,8.38-3.55,13.07-2.98,3.34,.4,6.27,1.78,8.78,4.24-2,1.41-4.18,2.26-6.45,2.84-5.86,1.51-11.81,1.92-17.82,1.24-.57-.06-1.15-.09-1.72-.14-.51-.05-1.02-.11-1.82-.19Z"
      />
    </g>
  );
};

export default Scared;
