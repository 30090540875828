import { getColor } from "../../utils/getColor";
import AngelDevil from "./types/AngelDevil";
import Axe from "./types/Axe";
import BackpackOne from "./types/BackpackOne";
import CatOne from "./types/CatOne";
import CatTwo from "./types/CatTwo";
import DogOne from "./types/DogOne";
import ESP from "./types/ESP";
import FightingStance from "./types/FightingStance";
import Foggy from "./types/Foggy";
import Guitar from "./types/Guitar";
import Jerry from "./types/Jerry";
import Katana from "./types/Katana";
import Kaw from "./types/Kaw";
import Loser from "./types/Loser";
import LouisBag from "./types/LouisBag";
import MiddleFinger from "./types/MiddleFinger";
import MoneyCalling from "./types/MoneyCalling";
import Phantom from "./types/Phatom";
import Pochita from "./types/Pochita";
import Raining from "./types/Raining";
import Satchel from "./types/Satchel";
import Scar from "./types/Scar";
import Snake from "./types/Snake";
import Spaz from "./types/Spaz";
import SwordOne from "./types/SwordOne";
import Vandal from "./types/Vandal";
import Wings from "./types/Wings";
import WireBat from "./types/WireBat";

const BackBling = ({ type, skinColor }) => {
  switch (type) {
    case "backpack_backBling":
      return <BackpackOne />;
    case "cat_backBling":
      return <CatOne />;
    case "cat_2_backBling":
      return <CatTwo />;
    case "dog_backBling":
      return <DogOne />;
    case "sword_katana_backBling":
      return <Katana />;
    case "sword_backBling":
      return <SwordOne />;
    case "guitar_backBling":
      return <Guitar />;
    case "axe_backBling":
      return <Axe />;
    case "satchel_backBling":
      return <Satchel />;
    case "foggy":
      return <Foggy />;
    case "vandal_backBling":
      return <Vandal />;
    case "phantom_backBling":
      return <Phantom />;
    case "angel_devil":
      return <AngelDevil />;
    case "coins":
      return <Raining />;
    case "scar":
      return <Scar />;
    case "spaz":
      return <Spaz />;
    case "wire_bat":
      return <WireBat skinColor={getColor(skinColor)} />;
    case "jerry":
      return <Jerry />;
    case "loser":
      return <Loser skinColor={getColor(skinColor)} />;
    case "money_calling":
      return <MoneyCalling skinColor={getColor(skinColor)} />;
    case "kaw":
      return <Kaw />;
    case "shoulder_snake":
      return <Snake />;
    case "wings":
      return <Wings />;
    case "lv_bag":
      return <LouisBag />;
    case "middle_finger":
      return <MiddleFinger skinColor={getColor(skinColor)} />;
    case "fighting_stance":
      return <FightingStance skinColor={getColor(skinColor)} />;
    case "pochita":
      return <Pochita />;
    case "esp":
      return <ESP />;
    default:
      return null;
  }
};

export default BackBling;
