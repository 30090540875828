const Frown = () => {
  return (
    <g transform="translate(507, 462)">
      <path d="M0,14.3c.7-.7,1.36-1.46,2.09-2.1C9.12,6.08,17.05,2.41,25.71,.78c7.47-1.4,14.88-.91,22.16,1.44,4.72,1.53,9.07,3.99,12.96,7.47,.68,.61,1.27,1.45,1.71,2.32,.48,.95,.15,1.7-.79,1.97-1.22,.35-2.3,.29-3.43-.76-5.29-4.94-11.44-7.81-18.08-9.03C25.92,1.55,12.63,4.66,.71,14.54c-.1,.09-.26,.09-.39,.13-.11-.12-.22-.24-.32-.36Z" />
      <path d="M50.01,18.65c-1.71,2.23-3.9,3.46-6.31,4.03-9.45,2.23-18.62,1.21-27.41-3.62,11.28,4.25,22.53,5.67,33.72-.42Z" />
    </g>
  );
};

export default Frown;
