import { CircularProgress } from "@mui/material";
import { useContext, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BiCloset } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { RiSwordFill, RiSwordLine, RiTeamLine } from "react-icons/ri";
import { TbShirt } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { acceptRematch } from "../../api/matches";
import { joinTeam } from "../../api/teams";
import useMiddleware from "../../api/useMiddleware";
import { claimGiveaway, claimTransferFundsGiveaway } from "../../api/user";
import {
  SET_ERRORS,
  SET_JOIN_MATCH_ID,
  SET_SUCCESSES,
  SET_TEAMS,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { NotificationTypeEnum } from "../../utils/enums";
import { secondaryText, text } from "../../utils/themeContstants";
import ListItem from "../custom/ListItem";

const NotificationAction = ({
  type,
  disabled = false,
  noti,
  handleClose,
  handleNotificationClose,
}) => {
  const middleware = useMiddleware();
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const getActionTitle = () => {
    switch (type) {
      case NotificationTypeEnum.NEW_MATCH:
        return "View Match";
      case NotificationTypeEnum.TEAM_INVITE:
        return "Accept Invite";
      case NotificationTypeEnum.GIVEAWAY:
        return "Claim Prize";
      case NotificationTypeEnum.REMATCH:
        return "Accept Rematch";
      case NotificationTypeEnum.AVATAR:
        return "View Locker";
      case NotificationTypeEnum.PRIVATE_INVITE:
        return "View Invite";
      default:
        return null;
    }
  };

  const getActionIcon = () => {
    switch (type) {
      case NotificationTypeEnum.NEW_MATCH:
      case NotificationTypeEnum.REMATCH:
        return <RiSwordLine style={styles.icon} />;
      case NotificationTypeEnum.TEAM_INVITE:
        return loading ? (
          <CircularProgress size={20} sx={{ color: secondaryText }} />
        ) : (
          <RiTeamLine style={styles.icon} />
        );
      case NotificationTypeEnum.GIVEAWAY:
        return <GiReceiveMoney style={styles.icon} />;
      case NotificationTypeEnum.AVATAR:
        return <TbShirt style={styles.icon} />;
      case NotificationTypeEnum.PRIVATE_INVITE:
        return <AiOutlineEye style={styles.icon} />;
      default:
        return null;
    }
  };

  const handleViewPrivateInvite = () => {
    updateStore({ type: SET_JOIN_MATCH_ID, payload: noti?.attached });
    handleClose();
    handleNotificationClose();
  };

  const handleMatchAction = () => {
    navigate(`/token/${noti?.attached}`);
    handleClose();
    handleNotificationClose();
  };

  const handleAvatarAction = () => {
    navigate("/locker");
    handleClose();
    handleNotificationClose();
  };

  const handleAcceptInvite = () => {
    setLoading(true);
    joinTeam(middleware, noti?._id, noti?.attached).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });

        updateStore({ type: SET_TEAMS, payload: res?.teams });
        navigate(`/profile/teams`);
        handleClose();
        handleNotificationClose();
      }
    });
  };

  const handleClaimPrize = () => {
    setLoading(true);
    const giveawayAttached = noti.attached;

    switch(giveawayAttached) {
      case "launch":
        claimGiveaway(middleware, noti?._id).then((res) => {
          setLoading(false);
          if (res?.error) {
            updateStore({
              type: SET_ERRORS,
              payload: res?.message,
            });
          } else {
            updateStore({
              type: SET_SUCCESSES,
              payload: res?.message,
            });
            handleClose();
            handleNotificationClose();
          }
        });
        break;
      // case "transfer":
      //   claimTransferFundsGiveaway(middleware, noti?._id).then((res) => {
      //     setLoading(false);
      //     if (res?.error) {
      //       updateStore({
      //         type: SET_ERRORS,
      //         payload: res?.message,
      //       });
      //     } else {
      //       updateStore({
      //         type: SET_SUCCESSES,
      //         payload: res?.message,
      //       });
      //       handleClose();
      //       handleNotificationClose();
      //     }
      //   });
      //   break;
      default:
        break;
    }

  };

  const handleAcceptRematch = () => {
    setLoading(true);
    acceptRematch(middleware, noti?.attached, noti?._id).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        navigate(`/token/${res?.match}`);
        handleClose();
        handleNotificationClose();
      }
    });
  };

  const handleAction = () => {
    switch (type) {
      case NotificationTypeEnum.NEW_MATCH:
        return handleMatchAction();
      case NotificationTypeEnum.TEAM_INVITE:
        return handleAcceptInvite();
      case NotificationTypeEnum.GIVEAWAY:
        return handleClaimPrize();
      case NotificationTypeEnum.REMATCH:
        return handleAcceptRematch();
      case NotificationTypeEnum.AVATAR:
        return handleAvatarAction();
      case NotificationTypeEnum.PRIVATE_INVITE:
        return handleViewPrivateInvite();
      default:
        return null;
    }
  };

  const styles = {
    icon: { fontSize: 20, color: text },
  };

  return (
    <ListItem
      label={getActionTitle()}
      icon={getActionIcon()}
      onClick={handleAction}
      disabled={loading || disabled}
    />
  );
};

export default NotificationAction;
