import { Dialog, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { RiTeamLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { createMatch } from "../../api/matches";
import useMiddleware from "../../api/useMiddleware";
import { getUserTeamsDetailed } from "../../api/user";
import { SET_ERRORS, StoreDispatch } from "../../store/Store";
import { getFee, getGameHeader } from "../../utils/helpers";
import {
  gameModeOptions,
  getTeamOptions,
  newGameModeOptions,
  newTeamSizeOptions,
  getTeamSizeOptions,
  checkLowestGameMode,
} from "../../utils/options";
import {
  card,
  doubtedBlue,
  emptyIcon,
  large,
  normal,
  secondaryText,
  text,
  cardVeryLight,
  cardDark,
  cardLight,
  small,
  doubtedOrange,
} from "../../utils/themeContstants";
import CurrencyInput from "../custom/CurrencyInput";
import CustomCheckbox from "../custom/CustomCheckbox";
import CustomDropdown from "../custom/CustomDropdown";
import CustomIconButton from "../custom/CustomIconButton";
import Empty from "../custom/Empty";
import SecondaryButton from "../custom/SecondaryButton";
import StepperDialog from "../custom/StepperDialog";
import StepperItem from "../custom/StepperItem";
import TeamProfileModal from "../teams/TeamProfileModal";
import ClashCreate from "./create/ClashCreate";
import DoubtedCoin from "../custom/DoubtedCoin";
import { numFormatter } from "../../utils/helpers";
import RadioListItem from "../custom/RadioListItem";
import CoinBadge from "../custom/CoinBadge";
import FortniteCreate from "./create/FortniteCreate";
import ChessCreate from "./create/ChessCreate";
import FifaCreate from "./create/FifaCreate";
import WarzoneCreate from "./create/WarzoneCreate";

const CreateMatch = ({ open, onClose, game }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const navigate = useNavigate();

  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState(null);
  const [filteredTeams, setFilteredTeams] = useState(null);
  const [teamOptions, setTeamOptions] = useState(null);
  const [showError, setShowError] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);

  // stepper
  const [currentState, setCurrentState] = useState(0);

  // create state
  const [region, setRegion] = useState(null);
  const [gameMode, setGameMode] = useState(null);
  const [teamSize, setTeamSize] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [entryFee, setEntryFee] = useState("");
  const [puttingUp, setPuttingUp] = useState(false);
  const [publicMatch, setPublic] = useState(false);
  const [team, setTeam] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [bestOf, setBestOf] = useState(null);
  const [firstTo, setFirstTo] = useState(null);
  const [map, setMap] = useState(null);
  const [loot, setLoot] = useState(null);
  const [health, setHealth] = useState(null);
  const [killLead, setKillLead] = useState(null);
  const [mustStream, setMustStream] = useState(false);

  const getStateTitle = () => {
    if (currentState === 0) return "General";

    if (currentState === 1) return "Teams";

    if (currentState === 2) return "Entry Fee";
  };

  const getStateMeta = () => {
    if (currentState === 0) return "Basic information about your match.";

    if (currentState === 1)
      return "Choose the team size and your desired team.";

    if (currentState === 2) return "Set the entry fee for the match.";
  };

  const handleClose = () => {
    setSelected(null);
    setCurrentState(0);
    setTeams(null);
    setFilteredTeams(null);
    setRegion(null);
    setGameMode(null);
    setTeamSize(null);
    setSelectedTeam(null);
    setEntryFee("");
    setPuttingUp(false);
    setPublic(false);
    setShowError(false);
    setCreateLoading(false);
    setTeamOptions(null);
    setTeam(null);
    setViewOpen(false);
    setPlatform(null);
    setBestOf(null);
    setFirstTo(null);
    setMap(null);
    setLoot(null);
    setHealth(null);
    setKillLead(null);
    onClose();
  };

  const isButtonDisabled = () => {
    // if (gameMode === "zbm") {
    //   return (
    //     !region ||
    //     !bestOf ||
    //     !teamSize ||
    //     !selectedTeam ||
    //     !gameMode ||
    //     entryFee === "" ||
    //     entryFee == null ||
    //     parseFloat(entryFee) <= 0
    //   );
    // }

    // return (
    //   !region ||
    //   !teamSize ||
    //   !selectedTeam ||
    //   !gameMode ||
    //   !firstTo ||
    //   entryFee === "" ||
    //   entryFee == null ||
    //   parseFloat(entryFee) <= 0
    // );
    if (currentState === 1) {
      return !teamSize || !selectedTeam;
    }

    if (currentState === 2) {
      return entryFee === "" || entryFee == null || parseFloat(entryFee) < 0.5;
    }

    if (game === "clashroyale" || game === "chess") {
      if (currentState === 0) {
        return !gameMode || !bestOf;
      }
    }

    if (game === "fifa") {
      if (currentState === 0) {
        return (
          !gameMode || !region || region?.length < 1 || !platform || !bestOf
        );
      }
    }

    if (game === "warzone") {
      if (currentState === 0) {
        return !gameMode || !region || region?.length < 1 || !bestOf;
      }
    }

    if (game === "fortnite") {
      if (currentState === 0) {
        // if (gameMode === "real") {
        //   return !gameMode || !map || !region || (!firstTo && !bestOf);
        // }
    
        if (gameMode === "build" || gameMode?.includes("build")) {
          return (
            !gameMode ||
            !loot ||
            !health ||
            !region ||
            region?.length < 1 ||
            (!firstTo && !bestOf)
          );
        }
        if (gameMode?.includes("esl_hp") || gameMode?.includes("esl_ctf")) {
          return (
            !gameMode ||
          !region ||
          region?.length < 1
          );
        }

        return (
          !gameMode || !region || region?.length < 1 || (!firstTo && !bestOf)
        );
      }
    }
  };

  const handleGetTeams = () => {
    getUserTeamsDetailed(middleware).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        handleClose();
      } else {
        setTeams(res?.teams);
      }
    });
  };

  const handleCreateMatch = () => {
    if (currentState !== 2) {
      setCurrentState(currentState + 1);
      return;
    }
    setCreateLoading(true);
    createMatch(
      middleware,
      parseFloat(entryFee),
      region,
      null,
      selectedTeam,
      firstTo ?? 5,
      !publicMatch,
      "token",
      puttingUp ? true : null,
      teamSize,
      platform,
      bestOf,
      game,
      map,
      !loot && !health && !killLead && !mustStream
        ? null
        : {
            loot_type: loot,
            health,
            kill_lead: killLead,
            must_stream: mustStream,
          },
      gameMode
    ).then((res) => {
      setCreateLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        handleClose();
        navigate(`/token/${res?.match?._id}`);
      }
    });
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      if (teams == null) {
        handleGetTeams();
      }
    }
  }, [open]);

  useEffect(() => {
    setTeamSize(null);
    setRegion(null);
  }, [gameMode]);

  useEffect(() => {
    const newFilteredTeams = teams?.filter(
      (team) => team?.users?.length === teamSize
    );
    setFilteredTeams(newFilteredTeams);
    setPuttingUp(false);
    setSelectedTeam(null);
    setTeamOptions(null);
    setTeam(null);
    setViewOpen(false);
  }, [teamSize]);

  useEffect(() => {
    if (filteredTeams && filteredTeams?.length > 0) {
      const newTeamOptions = getTeamOptions(filteredTeams);
      setTeamOptions(newTeamOptions);
    }
  }, [filteredTeams]);

  useEffect(() => {
    if (entryFee && parseFloat(entryFee) < 0.5) {
      return setShowError(true);
    }

    return setShowError(false);
  }, [entryFee]);

  const styles = {
    width: {
      width: "100%",
    },
    label: {
      fontSize: large,
      color: text,
      fontWeight: 700,
    },
    meta: {
      fontSize: normal,
      fontWeight: 400,
      color: secondaryText,
    },
    subLabel: {
      fontSize: normal,
      fontWeight: 600,
      color: "#d7e4f1",
    },
    checkLabel: {
      fontSize: 14,
      color: "#d7e4f1",
      fontWeight: 600,
      maxWidth: 400,
    },
    error: {
      fontSize: small,
      fontWeight: 500,
      color: doubtedOrange,
    },
  };

  return (
    <>
      <TeamProfileModal
        viewOnly={true}
        open={viewOpen}
        onClose={() => setViewOpen(false)}
        team={team}
      />

      <StepperDialog
        title="Create Match"
        open={open}
        onClose={handleClose}
        game={game}
        loading={loading}
        minWidth={600}
        stepperItems={[
          <StepperItem
            title="General"
            finished={currentState > 0}
            current={currentState === 0}
            isLast={false}
          />,
          <StepperItem
            title="Teams"
            finished={currentState > 1}
            current={currentState === 1}
            isLast={false}
          />,
          <StepperItem
            title="Entry Fee"
            finished={currentState > 2}
            current={currentState === 2}
            isLast={true}
          />,
        ]}
        primaryButton={
          <SecondaryButton
            label={currentState === 2 ? "Create Match" : "Next"}
            bg={doubtedBlue}
            loading={createLoading}
            onClick={handleCreateMatch}
            disabled={isButtonDisabled()}
          />
        }
        secondaryButton={
          currentState > 0 ? (
            <SecondaryButton
              label="Back"
              onClick={() => {
                if (currentState === 1) {
                  setTeamSize(null);
                  setSelectedTeam(null);
                  setTeam(null);
                  setGameMode(null);
                  setRegion(null);
                  setFirstTo(null);
                  setBestOf(null);
                  setPlatform(null);
                  setMap(null);
                  setLoot(null);
                  setHealth(null);
                }

                if (currentState === 2) {
                  setPuttingUp(null);
                  setEntryFee("");
                  setTeamSize(null);
                  setSelectedTeam(null);
                  setTeam(null);
                }

                setCurrentState(currentState - 1);
              }}
            />
          ) : null
        }
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 3 }}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
              >
                <Grid item>
                  <Typography sx={styles.label}>{getStateTitle()}</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.meta}>{getStateMeta()}</Typography>
                </Grid>
              </Grid>
            </Grid>

            {currentState === 0 && (
              <>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <CustomCheckbox
                        checked={publicMatch}
                        onChange={(value) => setPublic(value)}
                        color={doubtedBlue}
                        checkColor={"#fff"}
                      />
                    </Grid>

                    <Grid item>
                      <Typography sx={styles.checkLabel}>
                        Private Match (You are inviting your opponents)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {game === "clashroyale" && (
                  <ClashCreate
                    game={game}
                    setGameMode={setGameMode}
                    setBestOf={setBestOf}
                  />
                )}

                {game === "fifa" && (
                  <FifaCreate
                    game={game}
                    setGameMode={setGameMode}
                    setRegion={setRegion}
                    platform={platform}
                    setPlatform={setPlatform}
                    setBestOf={setBestOf}
                  />
                )}

                {game === "chess" && (
                  <ChessCreate
                    game={game}
                    setGameMode={setGameMode}
                    setBestOf={setBestOf}
                  />
                )}

                {game === "warzone" && (
                  <WarzoneCreate
                    game={game}
                    setGameMode={setGameMode}
                    platform={platform}
                    setPlatform={setPlatform}
                    setRegion={setRegion}
                    setBestOf={setBestOf}
                    mustStream={mustStream}
                    setMustStream={setMustStream}
                  />
                )}

                {game === "fortnite" && (
                  <FortniteCreate
                    game={game}
                    setGameMode={setGameMode}
                    setBestOf={setBestOf}
                    setRegion={setRegion}
                    region={region}
                    setFirstTo={setFirstTo}
                    gameMode={gameMode}
                    platform={platform}
                    setPlatform={setPlatform}
                    setMap={setMap}
                    setLoot={setLoot}
                    setHealth={setHealth}
                    firstTo={firstTo}
                    setKillLead={setKillLead}
                  />
                )}
              </>
            )}

            {currentState === 1 && (
              <>
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.subLabel}>Team size</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <CustomDropdown
                        options={
                          game === "fortnite"
                            ? getTeamSizeOptions(checkLowestGameMode(gameMode))
                            : newTeamSizeOptions(game, gameMode[0])
                        }
                        placeholder="Team size"
                        onChange={(value) => setTeamSize(value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {!teamSize && (
                  <Empty
                    title="No team size selected"
                    label="Try selecting a team size first."
                    icon={<RiTeamLine style={emptyIcon} />}
                  />
                )}

                {teamSize && (
                  <>
                    {filteredTeams?.length < 1 && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Empty
                            title={`You have no ${teamSize}v${teamSize} teams`}
                            label="Try creating a team."
                            icon={<RiTeamLine style={emptyIcon} />}
                          />

                          <Grid item>
                            <SecondaryButton
                              label="Create Team"
                              fullWidth
                              onClick={() =>
                                navigate("/profile/teams", {
                                  state: {
                                    create: true,
                                  },
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {filteredTeams?.length > 0 && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                          gap={{ xs: 1 }}
                        >
                          <Grid item>
                            <Typography sx={styles.subLabel}>
                              Select your team
                            </Typography>
                          </Grid>

                          <Grid item sx={styles.width}>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              gap={{ xs: 1 }}
                            >
                              <Grid item sx={{ flexGrow: 1 }}>
                                <CustomDropdown
                                  options={teamOptions}
                                  placeholder="Teams"
                                  onChange={(value) => {
                                    setSelectedTeam(value?._id);
                                    setTeam(value);
                                  }}
                                />
                              </Grid>

                              {team && (
                                <Grid item>
                                  <SecondaryButton
                                    label="View Team"
                                    onClick={() => setViewOpen(true)}
                                    fullWidth
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}

            {currentState === 2 && (
              <>
                {teamSize > 1 && (
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <CustomCheckbox
                          checked={puttingUp}
                          onChange={(value) => setPuttingUp(value)}
                          color={doubtedBlue}
                          checkColor={"#fff"}
                        />
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.checkLabel}>
                          Cover Entry Fee for Teammates
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.subLabel}>
                        Entry fee per player
                      </Typography>
                    </Grid>

                    {/* <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 1 }}
                      >
                        <RadioListItem
                          title="0.50 Coins"
                          value={0.5}
                          selected={selected === 0.5}
                          onClick={() => setSelected(0.5)}
                          backgroundColorHover={cardLight}
                          coin
                        />
                        <RadioListItem
                          title="1 Coin"
                          value={1}
                          selected={selected === 1}
                          onClick={() => setSelected(1)}
                          backgroundColorHover={cardLight}
                          coin
                        />
                        <RadioListItem
                          title="3 Coins"
                          value={3}
                          selected={selected === 3}
                          onClick={() => setSelected(3)}
                          backgroundColorHover={cardLight}
                          coin
                        />
                        <RadioListItem
                          title="5 Coins"
                          value={5}
                          selected={selected === 5}
                          onClick={() => setSelected(5)}
                          backgroundColorHover={cardLight}
                          coin
                        />
                      </Grid>
                    </Grid> */}

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="start"
                        gap={{ xs: 1 }}
                      >
                        <Grid item sx={{ flexGrow: 1 }}>
                          <CurrencyInput
                            value={entryFee}
                            onChange={(value) => setEntryFee(value)}
                            autoFocus={false}
                          />
                        </Grid>

                        <CoinBadge
                          type="prize"
                          insideLabel="Prize"
                          label="The amount a player will receive from this match after winning."
                          amount={
                            entryFee > 0
                              ? numFormatter().format(
                                  parseFloat(entryFee) * getFee(game) +
                                    parseFloat(entryFee)
                                )
                              : "0.00"
                          }
                          size="small"
                        />
                      </Grid>
                    </Grid>

                    {showError && (
                      <Grid item>
                        <Typography sx={styles.error}>
                          Minimum entry fee is 0.50 Coins
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </StepperDialog>
    </>
  );
};

export default CreateMatch;
