import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import { FaShieldAlt } from "react-icons/fa";
import { ImPower } from "react-icons/im";
import { RiTeamFill, RiThunderstormsFill } from "react-icons/ri";
import { TbSwords } from "react-icons/tb";
import {
  SET_SEARCH_USER,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { AccountTypeEnum } from "../../utils/enums";
import { getReadableTime } from "../../utils/helpers";
import {
  badgeIconSizeSmall,
  blue,
  cardLight,
  normal,
  text,
  transition,
  red,
  green,
  doubtedBlue,
  doubtedOrange,
  offWhite,
  purple,
  muffinsIdea,
  black,
} from "../../utils/themeContstants";
import Badge from "../custom/Badge";
import Hover from "../custom/Hover";
import SecondaryButton from "../custom/SecondaryButton";
import { isCreatingTeam, isJoiningTeam } from "./matchHelpers";
import { TiCloudStorage } from "react-icons/ti";
import { GiSwordsEmblem } from "react-icons/gi";

const MatchMessage = ({ message, token }) => {
  const isDesktop = useMediaQuery("(min-width:1025px)");

  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);

  const [hovered, setHovered] = useState(false);
  const [refEl, setRefEl] = useState(null);
  const [userHovered, setUserHovered] = useState(false);
  const [linkHovered, setLinkHovered] = useState(false);

  const handleOpenProfile = (user_id) => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  const isClashLink = (message) => {
    return message?.includes("https://link.clashroyale.com/invite/friend");
  };

  const getClashLink = (clashLink) => {
    const link = clashLink?.split("https://")[1];

    return `https://${link}`;
  };

  const styles = {
    container: {
      padding: 1.5,
      backgroundColor: cardLight,
      borderRadius: 1,
      overflowX: "hidden",
      width: "100%",
      maxWidth: "100%",
      position: "relative",
    },
    username: {
      fontSize: normal,
      fontWeight: 700,
      color: userHovered ? doubtedBlue : text,
      transition: transition,
      cursor: userHovered ? "pointer" : "default",
    },
    message: {
      fontWeight: 400,
      color: text,
      fontSize: normal,
      overflowWrap: "break-word",
      verticalAlign: "middle",
      lineHeight: 1.6,
      marginLeft: 4,
    },
    badgeContainer: {
      display: "inline-flex",
    },
    clashLink: {
      textDecoration: "underline",
      color: linkHovered ? doubtedBlue : offWhite,
      fontWeight: 400,
      overflowWrap: "break-word",
      verticalAlign: "middle",
      lineHeight: 1.6,
      marginLeft: 4,
      cursor: linkHovered ? "pointer" : "default",
      fontSize: normal,
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={setRefEl}
    >
      {hovered && (
        <Hover
          label={getReadableTime(new Date(message?.timestamp))}
          placement={isDesktop ? "left" : "top"}
          refEl={refEl}
        />
      )}

      <div
        item
        style={{
          whiteSpace: "nowrap",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "start",
          overflow: "hidden",
          maxWidth: "100%",
          verticalAlign: "middle",
        }}
      >
        {isCreatingTeam(token, message?.user?.user_id) && (
          <div style={styles.badgeContainer}>
            <Badge label="Creating Team" size="small">
              <RiTeamFill
                style={{ color: doubtedBlue, fontSize: badgeIconSizeSmall }}
              />
            </Badge>
          </div>
        )}

        {isJoiningTeam(token, message?.user?.user_id) && (
          <div style={styles.badgeContainer}>
            <Badge label="Joining Team" size="small">
              <RiTeamFill
                style={{ color: doubtedOrange, fontSize: badgeIconSizeSmall }}
              />
            </Badge>
          </div>
        )}

        {!isJoiningTeam(token, message?.user?.user_id) &&
          !isCreatingTeam(token, message?.user?.user_id) && (
            <div style={styles.badgeContainer}>

              {(message?.account_type >=
                AccountTypeEnum.MODERATOR) && (
                  <Badge label="STAFF">
                    <FaShieldAlt
                      style={{
                        color: blue,
                        fontSize: badgeIconSizeSmall,
                      }}
                    />
                  </Badge>
                )}

              {/* {(message?.account_type === AccountTypeEnum.MODERATOR ||
                message?.account_type === AccountTypeEnum.SENIOR_MODERATOR) && (
                <Badge label="MODERATOR" size="small">
                  <FaShieldAlt
                    style={{
                      color: blue,
                      fontSize: badgeIconSizeSmall,
                    }}
                  />
                </Badge>
              )}
              {message?.account_type === AccountTypeEnum.GOD && (
                <Badge label="OWNER" size="small">
                  <ImPower
                    style={{
                      color: green,
                      fontSize: badgeIconSizeSmall,
                    }}
                  />
                </Badge>
              )}
              {message?.account_type === AccountTypeEnum.SENIOR_ADMIN && (
                <Badge label="SR. ADMIN" size="small">
                  <GiSwordsEmblem
                    style={{
                      color: "white",
                      fontSize: badgeIconSizeSmall,
                    }}
                  />
                </Badge>
              )}
              {message?.account_type === AccountTypeEnum.ADMIN && (
                <Badge label="ADMIN" size="small">
                  <TbSwords
                    style={{
                      color: red,
                      fontSize: badgeIconSizeSmall,
                    }}
                  />
                </Badge>
              )}
              {message?.account_type === AccountTypeEnum.JUNIOR_ADMIN && (
                <Badge label="JR. ADMIN">
                  <TbSwords
                    style={{
                      color: purple,
                      fontSize: badgeIconSizeSmall,
                    }}
                  />
                </Badge>
              )}
              {message?.account_type === AccountTypeEnum.ANTICHEAT && (
                <Badge label="ANTICHEAT STAFF">
                  <TiCloudStorage
                    style={{
                      color: muffinsIdea,
                      fontSize: badgeIconSizeSmall,
                    }}
                  />
                </Badge>
              )} */}
            </div>
          )}

        <div
          style={{
            display: "inline-flex",
            marginLeft: 4,
            alignItems: "center",
            justifyContent: "center",
          }}
          onMouseEnter={() => setUserHovered(true)}
          onMouseLeave={() => setUserHovered(false)}
          onClick={() => handleOpenProfile(message?.user?.user_id)}
        >
          <Typography sx={styles.username}>
            {message?.user?.username}:
          </Typography>
        </div>
      </div>

      {isClashLink(message?.message) ? (
        <span
          onMouseEnter={() => setLinkHovered(true)}
          onMouseLeave={() => setLinkHovered(false)}
          style={styles.clashLink}
          onClick={() => window.open(getClashLink(message?.message), "_blank")}
        >
          Click here to add me on Clash Royale!
        </span>
      ) : (
        <span style={styles.message}>{message?.message}</span>
      )}
    </Grid>
  );
};

export default MatchMessage;
