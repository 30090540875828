const Joker = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#fff",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      fill: "#91483a",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#527384",
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    six: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
  };

  return (
    <g>
      <path
        style={styles.three}
        d="m440.05,439.81l.43.58c3.7,2.05,9.39,4.81,16.7,6.78,8.07,2.18,15.81,2.78,34.43,1.57,9.75-.64,22.43-1.77,37.3-3.91,16.21-2.24,28.95-1.87,37.83-1.04,12.36,1.15,15.65,1.57,15.65,1.57,10.82,1.36,18.09,2.85,27.78.77,1.43-.31,2.6-.63,3.37-.85,3.1-.9,6.6-2.13,10.33-3.83,3.16-1.44,5.94-2.96,8.35-4.43-1.56,3.17-3.37,6.5-5.48,9.91-5.16,8.36-10.77,15.08-15.91,20.35-.93.84-1.87,1.7-2.8,2.58-3.37,3.15-6.51,6.3-9.46,9.42-.59.45-1.66,1.26-3.06,2.18,0,0-7.38,4.9-17.13,7.57-59.03,16.18-93.42-4.79-93.42-4.79-12.45-7.59-25.13-20.2-29.05-24.46-4.68-5.09-8.01-10.38-11.35-13.11-.55-.45-2.47-1.95-3.72-4.6-.42-.9-.66-1.7-.79-2.24Z"
      />
      <path
        style={styles.six}
        d="m484.7,460.48c7.12-1.86,14.47-3.65,22.04-5.35,4.76-1.07,9.46-2.07,14.09-3,1.83-.24,4.09-.37,6.65-.13,1.5.14,2.85.38,4.04.65,1.81.51,3.96.96,6.39,1.17,5.18.46,9.52-.34,12.52-1.17,1.15-.47,2.75-.97,4.7-1.17,2.72-.29,4.96.13,6.39.52,3.72,1.69,8.17,3.41,13.3,4.83,5.96,1.64,11.37,2.47,15.91,2.87-3.56,2.59-8.07,5.43-13.57,7.96-4.05,1.87-7.85,3.2-11.22,4.17-8.55,1.44-19,2.53-30.91,2.35-12.12-.19-22.7-1.64-31.3-3.39-2.29-1.56-4.82-3.14-7.57-4.7-4.05-2.28-7.92-4.12-11.48-5.61Z"
      />
      <path
        style={styles.six}
        d="m484.7,460.48c3.8.98,8.69,1.58,14.09.52.38-.08.76-.16,1.13-.24.73-.17,1.45-.35,2.19-.47,6.81-1.12,15.32-1.65,24.86-.2,2.73.41,5.3.95,7.7,1.57,1.75.42,3.96.78,6.52.78,3.32,0,6.16-.59,8.34-1.27,1.68-.52,3.42-.86,5.17-1.03,1.1-.11,2.2-.21,3.31-.31,11.67-.98,22.64-.89,32.74-.13"
      />
      <path
        style={styles.three}
        d="m510.39,409.35c-.52.36-3.56,2.53-4.17,6.65-.67,4.51,2.1,7.67,2.48,8.09,5.21-.63,12.33-.69,20.09,1.83,1.69.55,3.25,1.17,4.7,1.83,1.64.53,4.69,1.24,8.35.52,1.7-.34,3.11-.9,4.17-1.43,2.71-1.66,6.53-3.49,11.35-4.3,1.29-.22,2.51-.34,3.65-.39.61.17,4.15,1.09,6.54-.82,2.86-2.28,3.34-8-.41-12.74-.35.61-.7,1.22-1.04,1.83-.52,0-1.29-.02-2.22-.13-4-.48-6.76-2.13-8.35-3-2.3-1.26-5.73-2.85-10.57-4.17-1.47-.22-3.04-.36-4.7-.38-9.3-.11-16.7,3.56-21.13,6.38-4.16,2.08-7.52,2.78-8.48,1.57-.19-.25-.34-.64-.26-1.3Z"
      />
      <path
        style={styles.four}
        d="m570.65,351.37c4.1-.25,8.9-.52,14.28-.78,16.29-.81,20.42-.72,24.46-.39,4.09.33,8.83.97,14.09,2.15-8.8-9.33-17.61-18.65-26.41-27.98l-26.41,27Z"
      />
      <path
        style={styles.four}
        d="m452.29,348.44c4.1-.25,8.9-.52,14.28-.78,16.29-.81,20.42-.72,24.46-.39,4.09.33,8.83.97,14.09,2.15-.94-.77-2.51-2.1-4.31-3.92-5.8-5.84-7.46-10.1-13.5-17.8-1.16-1.48-3.01-3.76-5.48-6.46-1.79,1.66-4.49,4-8.02,6.46-9.22,6.42-14.93,7.25-18.78,13.3-1.81,2.84-2.46,5.65-2.73,7.44Z"
      />
      <path
        style={styles.three}
        d="m577.5,317.91c-3.03,1.27-6.5-.23-7.63-3.13-1.05-2.7.19-5.91,2.93-7.24,4.43-2.08,16.71-7.13,32.04-4.08,9.76,1.94,16.75,6.38,20.59,9.26,1.15,1.07,1.58,2.15,1.24,2.6-.28.37-1.03.27-1.24.23-5.82-.29-9.35-1.17-11.54-1.96-.7-.25-2.57-.96-5.18-1.27-1.77-.21-3.24-.17-4.21-.1-3.58-.06-8.06.17-13.11,1.17-5.69,1.13-10.37,2.88-13.89,4.5Z"
      />
      <path
        style={styles.three}
        d="m501.58,316.14c3.03,1.27,6.5-.23,7.63-3.13,1.05-2.7-.19-5.91-2.93-7.24-4.43-2.08-16.71-7.13-32.04-4.08-9.76,1.94-16.75,6.38-20.59,9.26-1.15,1.07-1.58,2.15-1.24,2.6.28.37,1.03.27,1.24.23,5.82-.29,9.35-1.17,11.54-1.96.7-.25,2.57-.96,5.18-1.27,1.77-.21,3.24-.17,4.21-.1,3.58-.06,8.06.17,13.11,1.17,5.69,1.13,10.37,2.88,13.89,4.5Z"
      />
      <path
        style={styles.six}
        d="m451.11,367.22c6.17-3.33,25.24-12.59,43.83-5.18,2.21.88,4.55,2.05,6.95,3.62-3.26,1.61-7.36,3.23-12.23,4.3-11.68,2.57-21.6.62-27.2-.98-.85-.32-1.77-.62-2.76-.88-3.26-.86-6.22-.99-8.58-.88Z"
      />
      <path
        style={styles.two}
        d="m467.11,360.9c-.48,1.38-1.41,4.7.23,7.2,1.81,2.73,5.69,2.9,10.56,3.11,2.74.12,6.67,0,11.35-1.16.5-.55,1.85-2.17,2.16-4.68.28-2.3-.46-4.06-.79-4.75-2.16-.55-4.65-1.02-7.42-1.25-6.59-.55-12.15.45-16.08,1.54Z"
      />
      <ellipse
        style={styles.five}
        cx="479.87"
        cy="364.23"
        rx="5.28"
        ry="4.26"
      />
      <circle style={styles.one} cx="476.49" cy="363.4" r="1.82" />
      <circle style={styles.two} cx="481.04" cy="365.22" r="1.82" />
      <path d="m449.74,364.23c9.96-8.68,24.27-11.26,37.05-8.95,4.29.76,8.47,2.1,12.39,3.92-2.05-.69-4.11-1.32-6.21-1.81-15.04-3.79-29.86-.68-43.23,6.84h0Z" />
      <path
        style={styles.four}
        d="m453.75,370.74c3.78,2.19,12.48,6.57,24.36,6.55,11.24-.01,19.56-3.94,23.38-6.05-2.14,5.93-5.97,14.21-12.91,22.49-3.96,4.72-8.01,8.34-11.54,11.05-1.64-2.1-4.15-5.26-7.34-9-5.52-6.47-7.55-8.23-10.08-12.03-1.86-2.81-4.2-7.06-5.87-13.01Z"
      />
      <path
        style={styles.six}
        d="m621.77,367.22c-6.17-3.33-25.24-12.59-43.83-5.18-2.21.88-4.55,2.05-6.95,3.62,3.26,1.61,7.36,3.23,12.23,4.3,11.68,2.57,21.6.62,27.2-.98.85-.32,1.77-.62,2.76-.88,3.26-.86,6.22-.99,8.58-.88Z"
      />
      <path
        style={styles.two}
        d="m605.76,360.9c.48,1.38,1.41,4.7-.23,7.2-1.81,2.73-5.69,2.9-10.56,3.11-2.74.12-6.67,0-11.35-1.16-.5-.55-1.85-2.17-2.16-4.68-.28-2.3.46-4.06.79-4.75,2.16-.55,4.65-1.02,7.42-1.25,6.59-.55,12.15.45,16.08,1.54Z"
      />
      <ellipse
        style={styles.five}
        cx="593.01"
        cy="364.23"
        rx="5.28"
        ry="4.26"
      />
      <circle style={styles.one} cx="596.38" cy="363.4" r="1.82" />
      <circle style={styles.two} cx="591.83" cy="365.22" r="1.82" />
      <path d="m623.14,364.23c-13.37-7.52-28.19-10.63-43.23-6.84-2.1.49-4.16,1.12-6.21,1.81,3.92-1.82,8.1-3.16,12.39-3.92,12.78-2.31,27.09.27,37.05,8.95h0Z" />
      <path
        style={styles.four}
        d="m619.13,370.74c-3.78,2.19-12.48,6.57-24.36,6.55-11.24-.01-19.56-3.94-23.38-6.05,2.14,5.93,5.97,14.21,12.91,22.49,3.96,4.72,8.01,8.34,11.54,11.05,1.64-2.1,4.15-5.26,7.34-9,5.52-6.47,7.55-8.23,10.08-12.03,1.86-2.81,4.2-7.06,5.87-13.01Z"
      />
      <path d="m557.61,372.96c-.36,6.14-.35,12.14,1.17,18.13-3.38-5.24-4.04-12.52-1.17-18.13h0Z" />
    </g>
  );
};

export default Joker;
