const Growl = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
  };

  return (
    <g transform="translate(500, 465)">
      <path d="M68.2,8.41c-.73-2.74-1.42-5.03-.49-7.76,.44,1.87,.7,3.45,1.2,4.96,.59,1.8,1.05,3.63,3.09,4.63,1.55,.76,1.72,2.19,.71,3.72-.7,1.05-1.73,1.89-2.87,2.57,.71-1.21,1.41-2.42,2.31-3.96-1.29-.58-2.34-1.21-3.48-1.54-4.75-1.35-9.63-1.48-14.52-1.53-11.38-.1-21.7,3.72-31.85,8.39-3.63,1.67-7.56,2.79-11.46,3.75-3.19,.78-6.1-.61-8.59-2.57C-.29,17.09-.74,11.42,1.22,8.65,3.66,5.2,7,3.18,11,1.89,19.23-.77,27.51-.16,35.88,.91c9.01,1.14,17.87,2.9,26.52,5.67,1.81,.58,3.63,1.14,5.81,1.83ZM18.05,2.98c-.46-.06-.75-.17-1-.11-2.15,.54-4.32,1.02-6.42,1.72-1.35,.45-2.72,1.06-3.86,1.9-1.96,1.46-3.67,3.29-3.65,5.92,.02,3.06,2.23,5.3,5.36,5.8,3.91,.63,7.17-1.15,10.69-2.49-3.03-4.23-1.77-8.5-1.12-12.74Zm1.36-.39c-.87,4.59-1.52,8.81,2.1,12.25,4.62-2.03,9.02-3.96,13.25-5.82-.13-2.06-.24-3.92-.36-5.86-4.91-.5-9.82-1.3-14.99-.58Zm17.36,5.69c4.17-.76,8.39-1.52,12.6-2.29-4.43-1.63-8.94-2.11-13.4-2.43-1.02,1.92-.11,3.21,.8,4.71Z" />
      <path d="M59.47,18.73c-5.02-4.68-3.19-3.65-11.19-4.59,5.59-1.72,11.39-.19,11.19,4.59Z" />
      <path
        style={styles.one}
        d="M18.05,2.98c-.65,4.24-1.91,8.51,1.12,12.74-3.52,1.34-6.79,3.12-10.69,2.49-3.13-.51-5.34-2.74-5.36-5.8-.02-2.63,1.69-4.46,3.65-5.92,1.14-.84,2.51-1.45,3.86-1.9,2.1-.7,4.27-1.17,6.42-1.72,.25-.06,.54,.06,1,.11Z"
      />
      <path
        style={styles.one}
        d="M19.41,2.59c5.17-.72,10.08,.07,14.99,.58,.12,1.94,.24,3.79,.36,5.86-4.23,1.86-8.63,3.79-13.25,5.82-3.62-3.44-2.97-7.67-2.1-12.25Z"
      />
      <path
        style={styles.one}
        d="M36.78,8.28c-.92-1.51-1.82-2.79-.8-4.71,4.46,.32,8.97,.79,13.4,2.43-4.21,.76-8.43,1.53-12.6,2.29Z"
      />
    </g>
  );
};

export default Growl;
