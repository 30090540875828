import { Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { FaCrown } from "react-icons/fa";
import { RiVipCrownLine } from "react-icons/ri";
import { StoreContext } from "../../store/Store";
import { getReadableDateString } from "../../utils/helpers";
import {
  card,
  cardLight,
  cardVeryLight,
  menuShadow,
  modalShadow,
  normal,
  offWhite,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";
import RivoxCoin from "../custom/RivoxCoin";
import NotSelected from "../custom/NotSelected";
import SubscriptionDetailsModal from "./SubscriptionDetailsModal";

const Subscriptions = () => {
  const store = useContext(StoreContext);

  const [open, setOpen] = useState(false);

  const styles = {
    width: {
      width: "100%",
    },
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    premiumContainer: {
      width: "100%",
      borderRadius: 1,
      padding: 2,
      backgroundColor: card,
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: cardLight,
      },
    },
    value: {
      fontSize: normal,
      color: text,
      fontWeight: 700,
    },
    description: {
      fontSize: normal,
      color: offWhite,
      fontWeight: 500,
    },
  };

  return (
    <>
      <SubscriptionDetailsModal open={open} onClose={() => setOpen(false)} />

      <Grid item sx={styles.width}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          gap={{ xs: 2 }}
        >
          <Grid item>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 1 }}
            >
              <Grid item sx={styles.icon}>
                <RiVipCrownLine style={{ fontSize: 22, color: text }} />
              </Grid>

              <Grid item>
                <Typography sx={styles.title}>Subscriptions</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 2 }}
            >
              {!store?.user?.premium_info?.active && (
                <Grid item alignSelf="center">
                  <NotSelected label="NO ACTIVE SUBSCRIPTIONS" />
                </Grid>
              )}

              {store?.user?.premium_info?.active && (
                <>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            fontWeight: 800,
                            color: text,
                          }}
                        >
                          {store?.user?.premium_info?.renewing
                            ? "RECURRING"
                            : "NON RECURRING"}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            fontWeight: 500,
                            color: secondaryText,
                          }}
                        >
                          {store?.user?.premium_info?.renewing
                            ? "These subscriptions will renew on the next billing date"
                            : "These subscriptions will end after the expiration date"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sx={styles.premiumContainer}
                    onClick={() => setOpen(true)}
                  >
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 2 }}
                    >
                      <RivoxCoin size={60} />

                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                        >
                          <Grid item>
                            <Typography sx={styles.value}>
                              Rivox VIP
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography sx={styles.description}>
                              1 month •{" "}
                              {store?.user?.premium_info?.renewing
                                ? "Renews on "
                                : "Expires on "}
                              {getReadableDateString(
                                new Date(
                                  store?.user?.premium_info?.expiration_date
                                )
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Subscriptions;
