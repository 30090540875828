import { useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { StoreContext } from "../../store/Store";
import LoadingScreen from "../custom/LoadingScreen";

const VerifyStaff = ({ children, role }) => {
  const [isLogged] = useState(!!localStorage.getItem("accessToken"));
  const store = useContext(StoreContext);

  const [loading, setLoading] = useState(true);

  const hasCorrectRole = () => {
    if (!store?.user || store?.user?.account_type < 100) return false;

    for (let i = 0; i < role?.length; i++) {
      if (store?.user?.account_type === role[i]) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (!isLogged) {
      setLoading(false);
    } else {
      if (store?.user) {
        setLoading(false);
      }
    }
  }, [store]);

  return (
    <>
      {loading && <LoadingScreen size={150} />}

      {!loading && isLogged && hasCorrectRole() && children}

      {!loading && (!isLogged || !hasCorrectRole()) && (
        <Navigate to="/" replace />
      )}
    </>
  );
};

export default VerifyStaff;
