const Fury = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
  };

  return (
    <g transform="translate(505, 460)">
      <path d="M55.26,30.29c-3.36-.51-6.75-1.18-10.19-1.51-4.71-.46-9.46-.6-14.06,.69-2.87,.8-5.62,2.01-8.5,2.83-4.77,1.35-9.46,3.1-14.61,2.77-3.84-.25-6.73-1.87-7.4-5.99C-.57,22.36-.05,15.94,3.1,10.01,5.61,5.3,9.91,2.03,15.64,1.62c2.69-.19,5.51,.61,8.21,1.21,5.65,1.25,11.2,1.2,16.69-.63C44.77,.79,49.03-.17,53.59,.03c4.47,.2,7.67,2.32,10.41,5.44,2.6,2.96,4.31,6.39,5.68,9.97,.97,2.51,1.61,5.23,.76,7.82-1.23,3.75-4.28,5.81-8.21,6.49-2.25,.39-4.57,.37-6.96,.55ZM2.7,16.88c.16,.09,.31,.19,.47,.28,.45-.21,.93-.36,1.33-.63,1.38-.94,2.71-1.26,4.11,.01,.23,.21,.83,.22,1.17,.1,1.24-.46,2.34-.28,3.61,.1,1.65,.49,3.45,.64,5.2,.72,2.74,.12,5.48,.03,8.22,.07,1.57,.02,3.21,.42,4.7,.13,5.73-1.1,11.55-.95,17.33-1.36,1.4-.1,2.99,.22,4.06-1.56,.6-1.01,2.21-2.01,4-1.13,.4,.2,1.11-.21,1.69-.29,.97-.14,2.15-.63,2.89-.28,1.33,.63,2.02,.22,3.15-.42,1.4-.79,1.11-1.61,.48-2.37-1.87-2.25-3.77-4.5-5.89-6.53-1.13-1.08-2.68-2.28-4.14-2.42-5.16-.49-9.93,1.22-14.67,3.01-3.35,1.26-6.94,2.08-10.56,1.69-3.99-.43-7.97-1.15-11.87-2.06-5.15-1.19-8.61,1.59-11.41,4.76-1.94,2.2-2.61,5.42-3.85,8.19Zm-.26,2.28c-.19,3.51-.53,7.05,.78,10.51,.6,1.58,2.05,2.79,3.95,2.89,4.2,.23,8.08-1.12,12.01-2.23,4.97-1.41,9.58-4.01,14.85-4.35,3.97-.26,7.98-.4,11.95-.18,4.98,.28,9.93,1.08,14.9,1.45,2.17,.16,4.17-.47,5.47-2.51,1.71-2.69,1.86-8.4,.07-10.9-.12-.16-.45-.18-.27-.12-1.41,.77-2.75,2.12-3.64,1.88-1.94-.53-3.32,.04-4.81,.9-.21,.12-.54,.29-.71,.21-1.62-.7-2.86,.24-4.21,.79-3.26,1.35-6.77,1.13-10.21,1.34-1.93,.12-3.87,.17-5.79,.35-4.08,.38-8.14,.83-12.25,.4-.61-.06-1.25,.1-1.87,.08-3.44-.1-6.88-.22-10.32-.34-.8-.03-1.63-.2-2.39-.06-1.59,.29-3.12,.65-3.96-1.18-1.21,.36-2.3,.69-3.55,1.07Z" />
      <path d="M49.7,33.8c-.9,2.57-3.07,3.55-5.29,4.35-4.76,1.7-9.74,1.93-14.73,1.47-1.98-.18-3.96-.83-5.84-1.51-1.07-.39-1.04-.8-.57-1.77,9.17,2.62,17.9,1.47,26.42-2.53Z" />
      <path
        style={styles.one}
        d="M2.7,16.88c1.24-2.77,1.92-5.99,3.85-8.19,2.79-3.17,6.26-5.95,11.41-4.76,3.91,.9,7.89,1.62,11.87,2.06,3.62,.39,7.21-.43,10.56-1.69,4.74-1.79,9.51-3.5,14.67-3.01,1.46,.14,3.01,1.34,4.14,2.42,2.12,2.03,4.02,4.28,5.89,6.53,.63,.76,.93,1.58-.48,2.37-1.13,.64-1.82,1.05-3.15,.42-.74-.35-1.92,.14-2.89,.28-.58,.08-1.28,.49-1.69,.29-1.78-.88-3.39,.12-4,1.13-1.07,1.78-2.66,1.46-4.06,1.56-5.78,.41-11.6,.26-17.33,1.36-1.5,.29-3.13-.11-4.7-.13-2.74-.04-5.48,.05-8.22-.07-1.74-.08-3.54-.23-5.2-.72-1.27-.38-2.37-.57-3.61-.1-.34,.13-.94,.11-1.17-.1-1.4-1.27-2.73-.95-4.11-.01-.4,.27-.89,.43-1.33,.63-.16-.09-.31-.19-.47-.28Z"
      />
      <path
        style={styles.one}
        d="M2.45,19.16c1.25-.38,2.34-.7,3.55-1.07,.84,1.83,2.37,1.47,3.96,1.18,.76-.14,1.59,.04,2.39,.06,3.44,.12,6.88,.24,10.32,.34,.62,.02,1.26-.15,1.87-.08,4.11,.43,8.18-.02,12.25-.4,1.92-.18,3.86-.22,5.79-.35,3.44-.22,6.94,.01,10.21-1.34,1.35-.56,2.59-1.5,4.21-.79,.17,.07,.5-.09,.71-.21,1.49-.86,2.86-1.42,4.81-.9,.89,.24,2.23-1.1,3.64-1.88-.18-.07,.15-.05,.27,.12,1.79,2.5,1.64,8.21-.07,10.9-1.3,2.04-3.31,2.67-5.47,2.51-4.98-.37-9.92-1.17-14.9-1.45-3.97-.22-7.98-.08-11.95,.18-5.27,.35-9.88,2.95-14.85,4.35-3.93,1.11-7.81,2.46-12.01,2.23-1.89-.1-3.35-1.32-3.95-2.89-1.31-3.46-.96-7-.78-10.51Z"
      />
    </g>
  );
};

export default Fury;
