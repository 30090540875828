import { useEffect, useState } from "react";
import {
  black,
  cardDark,
  cardLight,
  cardVeryLight,
  container,
  green,
  secondaryButton,
  text
} from "../../utils/themeContstants";
import { Grid, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const CustomDateTimeSelect = ({
  value,
  onChange,
  size = 30,
  disabled,
  color = secondaryButton,
  inputColor = text,
}) => {
  const [selectedDate, setSelectedDate] = useState(value);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const handleDateChange = (newValue) => {
    if (disabled) {
      return;
    }
    onChange(newValue);
    setSelectedDate(newValue);
  };

  const getBackgroundColor = () => {
    if (disabled) {
      return secondaryButton;
    }

    if (color) {
      return selectedDate ? color : cardDark;
    }

    return selectedDate ? green : cardDark;
  };

  const styles = {
    container: {
      height: size,
      borderRadius: 2,
      width: '100%',
      backgroundColor: getBackgroundColor(),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        cursor: disabled ? "not-allowed" : "pointer",
        backgroundColor: getBackgroundColor(),
      },
    },
    input: {
      color: inputColor,
      '& .MuiInputBase-root': {
        color: inputColor,
      },
      '& .MuiInputLabel-root': {
        color: inputColor,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    }
  };

  return (
    <Grid item sx={styles.container}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          disabled={disabled}
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} sx={styles.input} />}
        />
      </LocalizationProvider>
    </Grid>
  );
};

export default CustomDateTimeSelect;
