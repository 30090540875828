import { Divider, Grid } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../store/Store";
import { cardLight, text } from "../../utils/themeContstants";
import DownloadACModal from "../anti_cheat/DownloadACModal";
import CustomMenu from "../custom/CustomMenu";
import ListItem from "../custom/ListItem";
import NewsModal from "../news/NewsModal";

const MoreMenu = ({ anchor, handleClose }) => {
  const store = useContext(StoreContext);

  const [newsOpen, setNewsOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);

  const navigate = useNavigate();

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <>
      <NewsModal open={newsOpen} onClose={() => setNewsOpen(false)} />
      <DownloadACModal
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
      />

      <CustomMenu anchor={anchor} handleClose={handleClose} minWidth={200}>
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <Grid item sx={styles.width}>
              <Grid container direction="column" alignItems="center">
                <ListItem
                  label="What's New"
                  onClick={() => {
                    setNewsOpen(true);
                    handleClose();
                  }}
                  selected={false}
                  selectedBg={cardLight}
                  hoveredBg={cardLight}
                  open={true}
                />

                <ListItem
                  label="Get Support"
                  onClick={() => {
                    window.open("https://discord.gg/rivox", "_blank");
                    handleClose();
                  }}
                  selected={false}
                  selectedBg={cardLight}
                  hoveredBg={cardLight}
                  open={true}
                  external={true}
                />

                <ListItem
                  label="Terms"
                  onClick={() => {
                    navigate("/support/terms");
                    handleClose();
                  }}
                  selected={false}
                  selectedBg={cardLight}
                  hoveredBg={cardLight}
                  open={true}
                />

                <ListItem
                  label="Privacy Policy"
                  onClick={() => {
                    navigate("/support/privacy");
                    handleClose();
                  }}
                  selected={false}
                  selectedBg={cardLight}
                  hoveredBg={cardLight}
                  open={true}
                />

                <ListItem
                  label="FAQ"
                  onClick={() => {
                    navigate("/support/faq");
                    handleClose();
                  }}
                  selected={false}
                  selectedBg={cardLight}
                  hoveredBg={cardLight}
                  open={true}
                />

                {store?.user && store?.user?.anticheat?.must_run_ac && (
                  <ListItem
                    label="Download Rivox Anti-Cheat"
                    onClick={() => {
                      setDownloadOpen(true);
                    }}
                    selected={false}
                    selectedBg={cardLight}
                    hoveredBg={cardLight}
                    open={true}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item sx={{ width: "100%", paddingLeft: 1, paddingRight: 1 }}>
              <Divider
                sx={{
                  width: "100%",
                  backgroundColor: text,
                  opacity: 0.1,
                }}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid container direction="column" alignItems="center">
                <ListItem
                  label="Rivox Twitter"
                  onClick={() => {
                    window.open("https://twitter.com/rivoxgg", "_blank");
                    handleClose();
                  }}
                  selected={false}
                  selectedBg={cardLight}
                  hoveredBg={cardLight}
                  open={true}
                  external={true}
                />

                <ListItem
                  label="Rivox TikTok"
                  onClick={() => {
                    window.open(
                      "https://www.tiktok.com/@Rivoxgg?lang=en",
                      "_blank"
                    );
                    handleClose();
                  }}
                  selected={false}
                  selectedBg={cardLight}
                  hoveredBg={cardLight}
                  open={true}
                  external={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomMenu>
    </>
  );
};

export default MoreMenu;
