import { Grid, Skeleton } from "@mui/material";
import { cardVeryLight } from "../../utils/themeContstants";

const StoreItemGlimmer = () => {
  return (
    <Grid item>
      <Skeleton
        variant="rectangular"
        animation="pulse"
        sx={{
          bgcolor: cardVeryLight,
          minHeight: 264,
          height: 264,
          borderRadius: 1.5,
        }}
      />
    </Grid>
  );
};

export default StoreItemGlimmer;
