import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { promoteUser } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { AccountTypeEnum } from "../../utils/enums";
import { card, cardDark, cardLight } from "../../utils/themeContstants";
import CustomModal from "../custom/CustomModal";
import PrimaryButton from "../custom/PrimaryButton";
import RadioListItem from "../custom/RadioListItem";
import SecondaryButton from "../custom/SecondaryButton";

const SetRoleModal = ({ open, onClose, user, role, setRole }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setSelectedRole(null);
    setLoading(false);
    onClose();
  };

  const handleUpdateRole = () => {
    setLoading(true);
    promoteUser(middleware, user?._id, selectedRole).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
        setRole(selectedRole);
      }
    });
  };

  useEffect(() => {
    if (open && selectedRole == null) {
      setSelectedRole(role);
    }
  }, [open]);

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      title={`Update ${user?.username}'s Role`}
      open={open}
      onClose={handleClose}
      primaryButton={
        <PrimaryButton
          label="Save"
          type="green"
          disabled={selectedRole === role}
          loading={loading}
          onClick={handleUpdateRole}
        />
      }
      backgroundColor={cardLight}
      secondaryButton={<SecondaryButton label="Close" onClick={handleClose} />}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 0.5 }}
          >
            <Grid item sx={styles.width}>
              <RadioListItem
                title={"None"}
                value={AccountTypeEnum.PLAYER}
                selected={selectedRole === AccountTypeEnum.PLAYER}
                onClick={() => setSelectedRole(AccountTypeEnum.PLAYER)}
                backgroundColor={card}
                backgroundColorHover={cardDark}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <RadioListItem
                title={"S2"}
                value={AccountTypeEnum.MODERATOR}
                selected={selectedRole === AccountTypeEnum.MODERATOR}
                onClick={() => setSelectedRole(AccountTypeEnum.MODERATOR)}
                backgroundColor={card}
                backgroundColorHover={cardDark}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <RadioListItem
                title={"S3"}
                value={AccountTypeEnum.SENIOR_MODERATOR}
                selected={selectedRole === AccountTypeEnum.SENIOR_MODERATOR}
                onClick={() =>
                  setSelectedRole(AccountTypeEnum.SENIOR_MODERATOR)
                }
                backgroundColor={card}
                backgroundColorHover={cardDark}
              />
            </Grid>
            {/* <Grid item sx={styles.width}>
              <RadioListItem
                title={"Junior Admin"}
                value={AccountTypeEnum.JUNIOR_ADMIN}
                selected={selectedRole === AccountTypeEnum.JUNIOR_ADMIN}
                onClick={() => setSelectedRole(AccountTypeEnum.JUNIOR_ADMIN)}
                backgroundColor={card}
                backgroundColorHover={cardDark}
              />
            </Grid> */}

            <Grid item sx={styles.width}>
              <RadioListItem
                title={"Anti-Cheat"}
                value={AccountTypeEnum.ANTICHEAT}
                selected={selectedRole === AccountTypeEnum.ANTICHEAT}
                onClick={() => setSelectedRole(AccountTypeEnum.ANTICHEAT)}
                backgroundColor={card}
                backgroundColorHover={cardDark}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <RadioListItem
                title={"Admin"}
                value={AccountTypeEnum.ADMIN}
                selected={selectedRole === AccountTypeEnum.ADMIN}
                onClick={() => setSelectedRole(AccountTypeEnum.ADMIN)}
                backgroundColor={card}
                backgroundColorHover={cardDark}
              />
            </Grid>

            {store?.user?.account_type >= AccountTypeEnum.GOD && (
              <Grid item sx={styles.width}>
                <RadioListItem
                  title={"Head Admin"}
                  value={AccountTypeEnum.SENIOR_ADMIN}
                  selected={selectedRole === AccountTypeEnum.SENIOR_ADMIN}
                  onClick={() => setSelectedRole(AccountTypeEnum.SENIOR_ADMIN)}
                  backgroundColor={card}
                  backgroundColorHover={cardDark}
                />
                <RadioListItem
                  title={"Management"}
                  value={AccountTypeEnum.GOD}
                  selected={selectedRole === AccountTypeEnum.GOD}
                  onClick={() => setSelectedRole(AccountTypeEnum.GOD)}
                  backgroundColor={card}
                  backgroundColorHover={cardDark}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default SetRoleModal;
