import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Avatar from "../../avatar/Avatar";
import Piece from "../../avatar/Piece";
import {
  SET_WALLET_OPEN,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { AvatarRarityEnum } from "../../utils/enums";
import {
  canChangePieceColor,
  getAvatarBackground,
  getAvatarBorder,
  getAvatarBorderColor,
  getAvatarRarity,
  getCoinAmount,
  getCoinAmountSale,
  numFormatter,
} from "../../utils/helpers";
import SpacingWrapper from "../../utils/SpacingWrapper";
import {
  cardDark,
  green,
  offWhite,
  red,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import RivoxCoin from "../custom/RivoxCoin";
import PrimaryButton from "../custom/PrimaryButton";
import SecondaryButton from "../custom/SecondaryButton";
import TextButton from "../custom/TextButton";
import ColorSelect from "../locker/ColorSelect";
import VariantSelect from "../locker/VariantSelect";
import Wallet from "../wallet/Wallet";
import GiftItemModal from "./GiftItemModal";
import PurchaseItemModal from "./PurchaseItemModal";

const ShopItemDetail = () => {
  const isDesktop = useMediaQuery("(min-width:1025px)");
  const navigate = useNavigate();
  const store = useContext(StoreContext);
  const location = useLocation();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(true);
  const [owned, setOwned] = useState(false);
  const [item, setItem] = useState(null);
  const [currentAvatar, setCurrentAvatar] = useState(null);
  const [canChangeColor, setCanChangeColor] = useState(false);
  const [walletOpen, setWalletOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [giftOpen, setGiftOpen] = useState(false);

  const handleChangeOption = (option, value) => {
    let newAvatar = currentAvatar;
    newAvatar.options[option] = value;
    setCurrentAvatar({ ...newAvatar });
  };

  const handleChangeVariant = (option, variant, isPiece) => {
    let newAvatar = currentAvatar;
    if (isPiece) {
      newAvatar.options[option].current_variant = null;
      setCurrentAvatar({ ...newAvatar });
      return;
    }

    newAvatar.options[option].current_variant = variant;
    setCurrentAvatar({ ...newAvatar });
  };

  const handleBuyPiece = () => {
    if (store?.user) {
      const price = parseFloat(item?.price);
      if (store?.user?.funds?.balance < price) {
        return setWalletOpen(true);
      } else {
        return setConfirmOpen(true);
      }
    } else {
      return navigate("/login");
    }
  };

  useEffect(() => {
    if (!location?.state?.item) {
      return navigate("/shop/avatars");
    }

    if (!item && loading) {
      if (location?.state?.item) {
        setItem(location?.state?.item);
        setLoading(false);
      } else {
        navigate("/shop/avatars");
      }
    }
  }, [item, loading, location?.state]);

  useEffect(() => {
    if (store?.user && !loading) {
      if (
        store?.user?.new_locker?.options[item?.category]?.includes(item?._id)
      ) {
        setOwned(true);
      }
    }
  }, [store?.user, loading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (item && store?.user) {
      const avatar = store?.user?.new_avatar?.options;
      let newAvatar = {};
      let completeAvatar = {};
      newAvatar.tattoos = avatar?.tattoos;
      newAvatar.earrings = avatar?.earrings;
      newAvatar.necklace = avatar?.necklace;
      newAvatar.backBling = avatar?.backBling;
      newAvatar.hat = avatar?.hat;
      newAvatar.hair = avatar?.hair;
      newAvatar.glasses = avatar?.glasses;
      newAvatar.eyebrows = avatar?.eyebrows;
      newAvatar.eyes = avatar?.eyes;
      newAvatar.mouth = avatar?.mouth;
      newAvatar.facialHair = avatar?.facialHair;
      newAvatar.nose = avatar?.nose;
      newAvatar.clothes = avatar?.clothes;
      newAvatar.skinColor = avatar?.skinColor;
      newAvatar.hairColor = avatar?.hairColor;
      newAvatar.facialHairColor = avatar?.facialHairColor;
      newAvatar.clothesColor = avatar?.clothesColor;
      newAvatar.hatColor = avatar?.hatColor;
      newAvatar[item?.category] = item;
      completeAvatar.options = newAvatar;
      setCurrentAvatar(completeAvatar);
    }
  }, [item, store?.user]);

  useEffect(() => {
    if (item) {
      return setCanChangeColor(
        canChangePieceColor(item?.category, item?.value)
      );
    }
  }, [item]);

  const styles = {
    width: {
      width: "100%",
    },
    titleContainer: {
      width: "100%",
      padding: 1,
      backgroundColor: getAvatarBackground(item?.rarity, owned),
    },
    pieceContainer: {
      overflow: "hidden",
      borderRadius: 1.5,
      border: `3px solid ${getAvatarBorder(item?.rarity, owned)}`,
      boxSizing: "border-box",
      background: getAvatarBackground(item?.rarity, owned),
      position: "relative",
      width: isDesktop ? null : "100%",
    },
    buttonContainer: {
      padding: 1,
      width: "100%",
      backgroundColor: "rgba(7, 24, 38, 0.2)",
    },
    meta: {
      fontSize: 15,
      color: secondaryText,
      fontWeight: 400,
      textAlign: "center",
    },
  };

  return loading ? null : (
    <>
      <Wallet
        open={walletOpen}
        onClose={() => setWalletOpen(false)}
        selectedTab="deposit"
      />
      <PurchaseItemModal
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        item={item}
      />

      <GiftItemModal
        item={item}
        onClose={() => setGiftOpen(false)}
        open={giftOpen}
      />

      <Grid item alignSelf="start">
        <TextButton
          label="Back to Shop"
          color={offWhite}
          icon={
            <BiArrowBack
              style={{
                color: offWhite,
                fontSize: 22,
              }}
            />
          }
          onClick={() => navigate("/shop/avatars")}
        />
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          justifyContent={isDesktop ? "space-between" : "center"}
          alignItems="center"
          gap={{ xs: 4 }}
          wrap={isDesktop ? "nowrap" : "wrap"}
        >
          <Grid item sx={styles.pieceContainer}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent={isDesktop ? "start" : "center"}
            >
              <Grid item sx={styles.titleContainer}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 800,
                            color: getAvatarBorder(item?.rarity, owned),
                          }}
                        >
                          {getAvatarRarity(item?.rarity)}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography sx={{ color: "rgba(255, 255, 255, 0.3)" }}>
                          |
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: 13,
                            color: "rgba(255, 255, 255, 0.5)",
                            fontWeight: 800,
                          }}
                        >
                          {item?.category?.toUpperCase()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 22,
                        color: text,
                        fontWeight: 800,
                      }}
                    >
                      {item?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Piece
                  type={item?.category}
                  color={currentAvatar?.options[`${item?.category}Color`]}
                  option={
                    item?.current_variant
                      ? item?.current_variant?.value
                      : item?.value
                  }
                  skinColor={currentAvatar?.options?.skinColor}
                  size={isDesktop ? 300 : 200}
                  owned={owned}
                />
              </Grid>

              <Grid item sx={styles.buttonContainer}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <>
                    <Grid item>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <RivoxCoin size={30} />
                        </Grid>

                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: 22,
                              color: text,
                              fontWeight: 800,
                            }}
                          >
                            {numFormatter().format(
                              getCoinAmountSale(item?.rarity)
                            )}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: 22,
                              color: red,
                              fontWeight: 600,
                              textDecoration: "line-through",
                            }}
                          >
                            <span style={{ color: secondaryText }}>
                              {numFormatter().format(
                                getCoinAmount(item?.rarity) * 1.5
                              )}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 1 }}
                      >
                        {!owned && (
                          <Grid item sx={styles.width}>
                            <SecondaryButton
                              onClick={handleBuyPiece}
                              label="Buy Now"
                              fullWidth
                              bg={getAvatarBorder(item?.rarity, owned)}
                            />
                          </Grid>
                        )}

                        {owned && (
                          <Grid item alignSelf="center">
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              gap={{ xs: 0.5 }}
                            >
                              <Grid item>
                                <Typography
                                  sx={{
                                    fontSize: 22,
                                    color: text,
                                    fontWeight: 800,
                                  }}
                                >
                                  Owned
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <AiOutlineCheck
                                  style={{ fontSize: 26, color: green }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        <Grid item sx={styles.width}>
                          <SecondaryButton
                            fullWidth
                            label="Buy as a Gift"
                            disabled={!store?.user}
                            onClick={() => {
                              if (store?.user?.funds?.balance < item?.price) {
                                return updateStore({
                                  type: SET_WALLET_OPEN,
                                  payload: true,
                                });
                              }

                              setGiftOpen(true);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={{ marginBottom: "auto" }}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="start"
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="start"
              >
                <Grid item>
                  <Avatar
                    size={isDesktop ? 450 : 350}
                    avatar={currentAvatar}
                    bgColor={cardDark}
                  />
                </Grid>

                <Grid item>
                  <SecondaryButton
                    label="View Your Locker"
                    onClick={() => navigate("/locker")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {(canChangeColor || item?.variants?.length > 0) && (
        <Grid item alignSelf="start">
          <Typography sx={styles.meta}>
            {canChangeColor &&
              item?.variants?.length > 0 &&
              "When purchasing this item you will also receive all variants and color options for the item."}

            {!canChangeColor &&
              item?.variants?.length > 0 &&
              "When purchasing this item you will also receive all variant options for the item."}

            {canChangeColor &&
              item?.variants?.length < 1 &&
              "When purchasing this item you will also receive all color options for the item."}
          </Typography>
        </Grid>
      )}

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          {item?.variants?.length > 0 && (
            <VariantSelect
              selected={item?.category}
              avatar={currentAvatar}
              onChange={handleChangeVariant}
              option={item}
              width="100%"
            />
          )}

          {canChangeColor && (
            <ColorSelect
              selected={item?.category}
              avatar={currentAvatar}
              onChange={handleChangeOption}
              width="100%"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ShopItemDetail;
