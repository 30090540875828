const SixNine = () => {
  const styles = {
    one: {
      fill: "#3c7228",
    },
    two: {
      fill: "#f8d300",
    },
    three: {
      fill: "#4a0000",
    },
    four: {
      fill: "#aba018",
    },
    five: {
      fill: "#aba018",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    six: {
      fill: "#de401a",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    seven: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    eight: {
      fill: "#537ebc",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    nine: {
      fill: "#de401a",
    },
    ten: {
      fill: "#e00d43",
    },
    eleven: {
      fill: "#cfe017",
    },
    twelve: {
      fill: "#840c2e",
    },
    thirteen: {
      fill: "#71b259",
    },
    fourteen: {
      fill: "#d43713",
    },
    fifteen: {
      fill: "#e4427f",
    },
    sixteen: {
      fill: "#9dd186",
    },
    seventeen: {
      fill: "#354553",
    },
  };

  return (
    <g>
      <path
        style={styles.six}
        d="m575.37,547.24c-5.01,4.64-10.65,9.35-16.97,13.94-21.78,15.82-43.56,24.39-60.78,29.22,14.35-1.1,47.13-5.72,75.89-30.58,10.64-9.19,18.17-18.94,23.44-27.24-7.19,4.89-14.38,9.77-21.57,14.66"
      />
      <path
        style={styles.eight}
        d="m658.19,316.25c-1.02.09,19.1,77.45-2.23,145.01-17.29,54.76-57.82,90.42-87.26,111.16,29.51-32.18,48.06-62.85,59.48-84.99,9.41-18.25,21.3-41.69,32.94-147.09,1.56-14.14-2.56-24.12-2.93-24.09Z"
      />
      <path
        style={styles.five}
        d="m368.05,471.6c-6.56,9-15.23,23.12-20.97,42.1-6.87,22.71-6.5,42.34-5.35,54.3-3.09-7.62-7.8-22.24-6.13-40.74,1.17-12.99,5.07-23.2,8.22-29.76,8.08-8.63,16.15-17.27,24.23-25.9"
      />
      <path
        style={styles.five}
        d="m401.87,320.65c-13.59,19.74,4.91,36.89-.26,92.87-1.46,15.77-2.67,11.67-4.17,27.65-2.19,23.36-6,63.94,15.72,101.33,4.71,8.11,12.68,19.6,26.02,31.2-13.63-23.35-28.53-58.24-20.35-95.74,3.01-13.8,8.09-23.13,10.43-27.65,13.85-26.75,29.27-73.7,34.66-162.15-3.88.94-9.41,2.46-15.87,4.84-15.29,5.64-36.38,13.42-46.17,27.65Z"
      />
      <path
        style={styles.eight}
        d="m590.75,251.92c17.93-8.54,30.09-5.44,34.29-4.05,26.71,8.8,33.06,47.35,35.27,60.74,2.86,17.36,1.17,31.85-.44,40.61-23.04-32.43-46.08-64.86-69.12-97.29Z"
      />
      <path
        style={styles.twelve}
        d="m609.88,289.45c-3.26-1.27-6.72-2.49-10.37-3.62-9.53-2.96-18.45-4.73-26.41-5.77-1.92-2.61-3.85-5.22-5.77-7.83h42.55v17.22Z"
      />
      <g>
        <g>
          <path
            style={styles.ten}
            d="m659.72,320.96c5.29,16.45,6.14,30.89,6.02,40.4-5.1,46.88-17.74,88.48-35.6,121.69-3.93,6.94-8.48,14.47-13.72,22.43-22.7,32.55-51.25,53.59-82.36,58.73-13.24,2.19-32.17,2.36-56.15-6.82,38.63,6.59,64.78-2.22,77.48-7.82,62.58-27.63,78.92-101.28,91.18-156.53,3.45-15.55,7.44-39.16.32-66.6-1.84-7.07-4.17-16.06-8.12-24.55,0-.01-.01-.02-.01-.03,0,0,0-.01-.01-.01-4.84-10.42-12.11-20.1-23.88-24.63-11.4-4.39-22.88-2.35-30.99.36-4.14,1.39-7.4,2.96-9.31,3.94,3.6-2.52,28.03-19.08,51.3-10.02,20.49,7.98,27.93,31.1,33.85,49.46Z"
          />
          <path
            style={styles.ten}
            d="m587.01,268.81h.01c2.72-1.7,5.87-3.27,9.46-4.51,11.41-3.91,21.99-2.52,29.46-.34,5.36,1.57,9.13,3.54,10.45,4.26,3.2,2,7.78,5.34,12.13,10.56,1.89,2.27,7.35,9.23,10.18,19.96,2.51,9.53,1.78,17.62,1.02,22.22-5.92-18.36-13.36-41.48-33.85-49.46-23.27-9.06-47.7,7.5-51.3,10.02.38-.98.74-1.95,1.09-2.94h.01c.17-.14,2.47-1.99,5.14-5.19.01,0,.01-.01.01-.01,1.74-1.51,3.8-3.09,6.19-4.57Z"
          />
          <path
            style={styles.fifteen}
            d="m648.82,351.98c2.25,83.78-68.05,140.75-80.12,150.24,18.98-18.18,61.49-64.22,64.95-128.74.8-14.87,4.76-60.33-23.87-81.39-10.48-7.71-22.14-9.85-30.46-10.4,1.14-.36,2.9-.88,5.15-1.34,6.25-1.3,16.32-2.23,27.66,1.56,15.69,5.25,24.05,16.24,26.62,19.94.01,0,.01.01.01.01,0,0,.01.02.01.03,5.4,14.24,9.54,31.1,10.05,50.09Z"
          />
          <path
            style={styles.twelve}
            d="m614.87,277.22c11.77,4.53,19.04,14.21,23.88,24.63-2.57-3.7-10.93-14.69-26.62-19.94-11.34-3.79-21.41-2.86-27.66-1.56l-.59-2.77c8.11-2.71,19.59-4.75,30.99-.36Z"
          />
          <path
            style={styles.seventeen}
            d="m616.42,505.48c4.84-6.94,9.42-14.42,13.7-22.37-9.04,20.54-24.26,48.75-49.69,77.02-33.71,37.49-70.06,58.1-93.13,68.87,22.78-13.58,52.07-34.11,81.4-64.17,20.11-20.62,35.72-41.1,47.72-59.35Z"
          />
          <path
            style={styles.three}
            d="m625.94,263.96c-7.47-2.18-18.05-3.57-29.46.34-3.59,1.24-6.74,2.81-9.46,4.5.1-.17.97-1.55,2.5-3.39,1.74-1.34,3.59-3.04,5.05-5.05,7.04-5.77,18.38-10.13,31.37,3.6Z"
          />
          <path
            style={styles.thirteen}
            d="m585.5,236.75c14.3,9.5,13.3,17.83,9.07,23.61-2.08,1.7-3.79,3.53-5.05,5.05-2.83,2.19-5.35,3.41-5.35,3.41,4.8-7.56,8.55-18.94,1.33-32.07Z"
          />
          <path
            style={styles.sixteen}
            d="m585.5,236.75c7.22,13.13,3.47,24.51-1.33,32.07-1.1,1.73-2.25,3.26-3.35,4.56,0,0,0,.01-.01.01-2.23,1.94-3.93,3.77-5.14,5.18,7.16-20.23,7.3-41.81-2.2-56.15,5.63,4.94,9.5,9.74,12.02,14.33h.01Z"
          />
          <path
            style={styles.thirteen}
            d="m573.47,222.42c9.5,14.34,9.36,35.92,2.2,56.15h-.01c-.35,1-.71,1.97-1.09,2.95-1.11.32-3.87.96-7.18,0-2.59-.75-4.38-2.15-5.4-3.14-.31-.3-.55-.57-.73-.77,9.28-21.74,8.89-45.59-3.17-59.03-11.31-12.62-33.87-17.16-56.68-9.54,28.04-10.83,56.01-5.62,69.3,9.74,1,1.15,1.91,2.37,2.76,3.64Z"
          />
          <path
            style={styles.one}
            d="m558.09,218.58c12.06,13.44,12.45,37.29,3.17,59.03,5.93-17.97,2.1-27.69-.22-31.88-10.88-19.63-48.95-26.24-95.86-11.4l-.18-.55c11.25-11.8,23.79-19.86,36.33-24.71.03-.01.05-.02.08-.03,22.81-7.62,45.37-3.08,56.68,9.54Z"
          />
          <path
            style={styles.sixteen}
            d="m561.04,245.73c2.32,4.19,6.15,13.91.22,31.88-7.75-7.63-18.82-16.74-33.65-24.26-31.88-16.16-62.16-15.34-76.7-13.93,4.83-1.91,9.59-3.61,14.27-5.09,46.91-14.84,84.98-8.23,95.86,11.4Z"
          />
          <path
            style={styles.nine}
            d="m561.26,277.61c.18.2.42.47.73.77-7.99-2.2-14.93-2.41-20.83-1.64-35.54,4.58-65.16,48.35-77.37,108.22-.24-20.56,1.3-38.98,3.53-54.75,1.13-4.4,9.16-33.54,38.77-48.3,20.47-10.2,39.99-7.91,49.75-5.74,0,.01.01.01.01.01,2.49.55,4.35,1.1,5.41,1.43Z"
          />
          <path
            style={styles.two}
            d="m463.79,384.96c0,1,.02,2,.04,3,.35,18,2.02,34.29,4.23,48.52,6.02,19.11,8.18,34.76,9.07,45,2.8,32.08-1.86,66.97-1.96,67.69,1.48-30.7-1.56-55.17-1.56-55.17-1.47-11.83-4.03-26.35-8.61-42.65-5.68-18.51-9.24-36.36-10.95-53.18-6.49-63.99,13.85-113.01,46.06-126.55,6.11-2.57,22.44-7.98,49.32,1.25,2.09.98,4.22,2.08,6.41,3.3-9.76-2.17-29.28-4.46-49.75,5.74-29.61,14.76-37.64,43.9-38.77,48.3-2.23,15.77-3.77,34.19-3.53,54.75Z"
          />
          <path
            style={styles.eleven}
            d="m376.39,453.2c-1.46,5.64-3.64,11.1-7.14,16.6-4.57,7.18-9.76,12.14-13.42,15.2-2.92,2.46-7.92,7.03-12.92,13.7-20.08,26.79-18.93,59.36-18.39,74.73.22,6.07.89,15.19,3.13,26.22-4.23-8.59-9.81-22.6-11.35-40.69-1.91-22.56,3.39-40.45,7.05-50.09,9.54-25.17,18.27-54.65,23.87-88.04,1.77-10.57,3.11-20.79,4.09-30.63.69-19.49,4.43-47.35,18.6-76.98,4.79-10,10.05-18.72,15.26-26.22,5.37-8.66,16.14-23.35,34.83-34.83,2.46-1.51,5.48-3.23,9.06-4.98,8.63-4.19,16.33-6.47,21.85-7.77,14.54-1.41,44.82-2.23,76.7,13.93,14.83,7.52,25.9,16.63,33.65,24.26-29.92-19.51-54.48-19.54-64.96-18.81-19.22,1.34-37.81,8.87-54.21,21.3-25.69,19.46-46.01,50.95-54.96,89.47-2.98,22.05-5.02,38.74-6.26,49.3-1.54,13.07-1.81,24.02-4.48,34.33Z"
          />
          <path
            style={styles.two}
            d="m561.26,277.61c-13.99-7.57-26.06-10.91-35.84-12.12-.08,0-.15-.02-.22-.03-8.38-1.13-14.84-.48-18.83.19-.32.05-.62.1-.9.15-.91.14-1.74.3-2.51.46-49.52,10.25-89.69,78.64-86.09,169.83.37,24.16-2.33,66.16-23.87,111.91-12.93,27.45-28.76,48-41.69,62.16,5.87-7.65,12.64-18.04,18.21-31.25,3.52-8.33,5.86-16.1,7.44-22.88,4.07-17.72,7-32.55,9-43.25,4.78-25.66,8.64-46.76,10.45-74.01.66-9.97.92-18.92.98-26.48.01-.02.01-.05.01-.07.04-5.28-.01-9.89-.1-13.7.88-18.94,3.85-34.5,6.66-45.78,4.54-18.27,10.12-39.76,27-60.65,4.27-5.29,8.27-9.31,11.13-11.99,16.4-12.43,34.99-19.96,54.21-21.3,10.48-.73,35.04-.7,64.96,18.81Z"
          />
          <path
            style={styles.two}
            d="m525.42,265.49c6.84.95,14.95,3.09,24.01,7.38-26.88-9.23-43.21-3.82-49.32-1.25-32.21,13.54-52.55,62.56-46.06,126.55.39,4.61.69,10.13.66,16.39-.04,10.54-1,23.18-4,37.05-.47,2.18-.99,4.4-1.57,6.64-8.16,31.65-21.05,44.4-33.05,76.05-6.22,16.41-13.65,41.7-15.26,75.86-2.86-17.23-4.16-40.68,2.34-66.86,1.57-6.31,3.45-12.18,5.48-17.58,17.29-35,21.27-60.01,22.31-73.59.65-8.52,1.21-26.62,1.17-44.22-.02-9.5-.21-17.4-.39-22.91.5-63.74,33.85-111.9,73.73-119.2.28-.05.58-.1.9-.15,4.69-.71,11.03-1.12,18.83-.19.07.01.14.02.22.03Z"
          />
          <path
            style={styles.four}
            d="m454.71,414.56c13.48,50.88,9.29,89.99,5.29,111.16-4.22,22.37-11.48,41.98-14.73,50.64-9.5,25.37-20.4,45.55-29.18,59.97,20.57-37.31,42.91-93.39,37.78-159.93-.66-8.6-1.75-16.87-3.16-24.79,3-13.87,3.96-26.51,4-37.05Z"
          />
          <path
            style={styles.fourteen}
            d="m442.09,280.1c-2.86,2.68-6.86,6.7-11.13,11.99-16.88,20.89-22.46,42.38-27,60.65-2.81,11.28-5.78,26.84-6.66,45.78.09,3.81.14,8.42.1,13.7,0,.02,0,.05-.01.07-2.1,26.08-6.95,58.51-17.69,94.62-11.02,37.03-25.14,67.47-37.96,90.79,14.42-41.46,26.37-87.32,33.65-137.35.35-2.39.69-4.77,1-7.15,2.67-10.31,2.94-21.26,4.48-34.33,1.24-10.56,3.28-27.25,6.26-49.3,8.95-38.52,29.27-70.01,54.96-89.47Z"
          />
        </g>
        <g>
          <path
            style={styles.seven}
            d="m501.41,209.04c22.81-7.62,45.37-3.08,56.68,9.54,12.06,13.44,12.45,37.29,3.17,59.03.18.2.42.47.73.77,1.02.99,2.81,2.39,5.4,3.14,3.31.96,6.07.32,7.18,0"
          />
          <path
            style={styles.seven}
            d="m501.33,209.07c-12.54,4.85-25.08,12.91-36.33,24.71,10.92-12.45,23.66-20.45,36.33-24.71"
          />
          <path style={styles.seven} d="m501.33,209.07s.05-.02.08-.03" />
          <path
            style={styles.seven}
            d="m575.67,278.57c7.16-20.23,7.3-41.81-2.2-56.15-.85-1.27-1.76-2.49-2.76-3.64-13.29-15.36-41.26-20.57-69.3-9.74"
          />
          <path
            style={styles.seven}
            d="m561.26,277.61c5.93-17.97,2.1-27.69-.22-31.88-10.88-19.63-48.95-26.24-95.86-11.4-4.68,1.48-9.44,3.18-14.27,5.09,14.54-1.41,44.82-2.23,76.7,13.93,14.83,7.52,25.9,16.63,33.65,24.26Z"
          />
          <path
            style={styles.seven}
            d="m475.17,549.18h0c1.48-30.71-1.56-55.18-1.56-55.18-1.47-11.83-4.03-26.35-8.61-42.65-5.68-18.51-9.24-36.36-10.95-53.18-6.49-63.99,13.85-113.01,46.06-126.55,6.11-2.57,22.44-7.98,49.32,1.25,3.74,1.27,7.68,2.84,11.83,4.74-1.06-.33-2.92-.88-5.41-1.43"
          />
          <path
            style={styles.seven}
            d="m555.84,276.17c-9.76-2.17-29.28-4.46-49.75,5.74-29.61,14.76-37.64,43.9-38.77,48.3-2.23,15.77-3.77,34.19-3.53,54.75,0,1,.02,2,.04,3,.35,18,2.02,34.29,4.23,48.52,6.02,19.11,8.18,34.76,9.07,45,2.8,32.08-1.86,66.97-1.96,67.69"
          />
          <path
            style={styles.seven}
            d="m638.75,301.85c-4.84-10.42-12.11-20.1-23.88-24.63-11.4-4.39-22.88-2.35-30.99.36-4.14,1.39-7.4,2.96-9.31,3.94,3.6-2.52,28.03-19.08,51.3-10.02,20.49,7.98,27.93,31.1,33.85,49.46,5.29,16.45,6.14,30.89,6.02,40.4-5.1,46.88-17.74,88.48-35.6,121.69"
          />
          <path
            style={styles.seven}
            d="m630.12,483.11c-4.28,7.95-8.86,15.43-13.7,22.37-22.7,32.55-51.25,53.59-82.36,58.73-13.24,2.19-32.17,2.36-56.15-6.82,38.63,6.59,64.78-2.22,77.48-7.82,62.58-27.63,78.92-101.28,91.18-156.53,3.45-15.55,7.44-39.16.32-66.6-1.84-7.07-4.17-16.06-8.12-24.55"
          />
          <path
            style={styles.seven}
            d="m638.77,301.89c5.4,14.24,9.54,31.1,10.05,50.09,2.25,83.78-68.05,140.75-80.12,150.24,18.98-18.18,61.49-64.22,64.95-128.74.8-14.87,4.76-60.33-23.87-81.39-10.48-7.71-22.14-9.85-30.46-10.4,1.14-.36,2.9-.88,5.15-1.34,6.25-1.3,16.32-2.23,27.66,1.56,15.69,5.25,24.05,16.24,26.62,19.94.01,0,.01.01.01.01,0,0,.01.02.01.03Z"
          />
          <path
            style={styles.seven}
            d="m630.14,483.05s-.02.04-.02.06c-9.04,20.54-24.26,48.75-49.69,77.02-33.71,37.49-70.06,58.1-93.13,68.87,22.78-13.58,52.07-34.11,81.4-64.17,20.11-20.62,35.72-41.1,47.72-59.35,5.24-7.96,9.79-15.49,13.72-22.43Z"
          />
          <path
            style={styles.seven}
            d="m525.42,265.49c9.78,1.21,21.85,4.55,35.84,12.12-29.92-19.51-54.48-19.54-64.96-18.81-19.22,1.34-37.81,8.87-54.21,21.3-25.69,19.46-46.01,50.95-54.96,89.47-2.98,22.05-5.02,38.74-6.26,49.3-1.54,13.07-1.81,24.02-4.48,34.33-1.46,5.64-3.64,11.1-7.14,16.6-4.57,7.18-9.76,12.14-13.42,15.2-2.92,2.46-7.92,7.03-12.92,13.7-20.08,26.79-18.93,59.36-18.39,74.73.22,6.07.89,15.19,3.13,26.22-4.23-8.59-9.81-22.6-11.35-40.69-1.91-22.56,3.39-40.45,7.05-50.09,9.54-25.17,18.27-54.65,23.87-88.04,1.77-10.57,3.11-20.79,4.09-30.63.69-19.49,4.43-47.35,18.6-76.98,4.79-10,10.05-18.72,15.26-26.22,5.37-8.66,16.14-23.35,34.83-34.83,2.46-1.51,5.48-3.23,9.06-4.98,8.63-4.19,16.33-6.47,21.85-7.77"
          />
          <path
            style={styles.seven}
            d="m506.37,265.65c4.69-.71,11.03-1.12,18.83-.19"
          />
          <path
            style={styles.seven}
            d="m397.39,412.29c-.06,7.56-.32,16.51-.98,26.48-1.81,27.25-5.67,48.35-10.45,74.01-2,10.7-4.93,25.53-9,43.25-1.58,6.78-3.92,14.55-7.44,22.88-5.57,13.21-12.34,23.6-18.21,31.25,12.93-14.16,28.76-34.71,41.69-62.16,21.54-45.75,24.24-87.75,23.87-111.91-3.6-91.19,36.57-159.58,86.09-169.83.77-.16,1.6-.32,2.51-.46"
          />
          <path
            style={styles.seven}
            d="m442.09,280.1c-2.86,2.68-6.86,6.7-11.13,11.99-16.88,20.89-22.46,42.38-27,60.65-2.81,11.28-5.78,26.84-6.66,45.78.09,3.81.14,8.42.1,13.7"
          />
          <path
            style={styles.seven}
            d="m562.04,278.39s-.03-.01-.05-.01c-7.99-2.2-14.93-2.41-20.83-1.64-35.54,4.58-65.16,48.35-77.37,108.22,0,.01-.01.03-.01.04"
          />
          <path
            style={styles.seven}
            d="m555.85,276.18s-.01,0-.01-.01c-2.19-1.22-4.32-2.32-6.41-3.3-9.06-4.29-17.17-6.43-24.01-7.38-.08,0-.15-.02-.22-.03-8.38-1.13-14.84-.48-18.83.19-.32.05-.62.1-.9.15-39.88,7.3-73.23,55.46-73.73,119.2.18,5.51.37,13.41.39,22.91.04,17.6-.52,35.7-1.17,44.22-1.04,13.58-5.02,38.59-22.31,73.59-2.03,5.4-3.91,11.27-5.48,17.58-6.5,26.18-5.2,49.63-2.34,66.86,1.61-34.16,9.04-59.45,15.26-75.86,12-31.65,24.89-44.4,33.05-76.05.58-2.24,1.1-4.46,1.57-6.64,3-13.87,3.96-26.51,4-37.05.03-6.26-.27-11.78-.66-16.39"
          />
          <path
            style={styles.seven}
            d="m450.71,451.61c1.41,7.92,2.5,16.19,3.16,24.79,5.13,66.54-17.21,122.62-37.78,159.93,8.78-14.42,19.68-34.6,29.18-59.97,3.25-8.66,10.51-28.27,14.73-50.64,4-21.17,8.19-60.28-5.29-111.16"
          />
          <path
            style={styles.seven}
            d="m585.49,236.75c-2.52-4.59-6.39-9.39-12.02-14.33,0,0-.02-.01-.03-.02"
          />
          <path
            style={styles.seven}
            d="m580.82,273.38c1.1-1.3,2.25-2.83,3.35-4.56,4.8-7.56,8.55-18.94,1.33-32.07"
          />
          <path
            style={styles.seven}
            d="m574.57,281.52c.38-.98.74-1.95,1.09-2.94h.01c.17-.14,2.47-1.99,5.14-5.19"
          />
          <path
            style={styles.seven}
            d="m580.81,273.39c-2.23,1.94-3.93,3.77-5.14,5.18"
          />
          <path style={styles.seven} d="m580.81,273.39s.01-.01.01-.01" />
          <path
            style={styles.seven}
            d="m587.01,268.81c-2.39,1.48-4.45,3.06-6.19,4.57"
          />
          <path
            style={styles.seven}
            d="m659.72,320.96c.76-4.6,1.49-12.69-1.02-22.22-2.83-10.73-8.29-17.69-10.18-19.96-4.35-5.22-8.93-8.56-12.13-10.56-1.32-.72-5.09-2.69-10.45-4.26-7.47-2.18-18.05-3.57-29.46.34-3.59,1.24-6.74,2.81-9.46,4.5"
          />
          <path
            style={styles.seven}
            d="m584.17,268.82s2.52-1.22,5.35-3.41c1.74-1.34,3.59-3.04,5.05-5.05,4.23-5.78,5.23-14.11-9.07-23.61h-.01"
          />
          <path
            style={styles.seven}
            d="m587,268.82h.01s0-.01.01-.02c.1-.17.97-1.55,2.5-3.39,1.26-1.52,2.97-3.35,5.05-5.05,7.04-5.77,18.38-10.13,31.37,3.6"
          />
          <path
            style={styles.seven}
            d="m378.01,440.44c-.5,4.23-1.03,8.48-1.62,12.76-.31,2.38-.65,4.76-1,7.15-7.28,50.03-19.23,95.89-33.65,137.35,12.82-23.32,26.94-53.76,37.96-90.79,10.74-36.11,15.59-68.54,17.69-94.62.01-.02.01-.05.01-.07"
          />
        </g>
      </g>
      <path d="m553.33,273.01c-21.33-17.81-51.08-24.68-78.16-18.16,27.14-7.23,57.36-.92,79.41,16.6,0,0-1.25,1.56-1.25,1.56h0Z" />
      <path d="m377.1,440.85c-18.26-39.93-17.55-87.92,2.95-126.86-14.65,29.22-18.85,63.43-11.6,95.3,2.37,10.59,5.89,20.91,10.47,30.73l-1.82.83h0Z" />
      <path d="m429.81,312.43c-12.23,29.68-20.06,61.04-22.9,93-1.03,10.63-1.49,21.32-1.78,32.06-4.28-42.82,4.73-86.99,24.68-125.07h0Z" />
      <path d="m449.5,341.76c-6.13,27.34-8.69,55.48-6.64,83.45-2.96-27.88-1.06-56.45,6.64-83.45h0Z" />
      <path d="m651.26,318.3c9.48,30.01,9.56,62.85.71,93.02-2.97,10.04-6.82,19.83-11.67,29.11,2.11-4.78,4.1-9.61,5.83-14.53,12.49-34.31,14.14-72.25,5.13-107.6h0Z" />
      <path d="m560.9,275.61c-.4-12.52-6.42-25.05-16.74-32.37-10.35-7.51-24.17-9.3-36.5-6.48,6.2-1.62,12.75-1.97,19.1-1.11,12.9,1.52,24.8,9.64,30.86,21.16,3.06,5.76,4.86,12.17,5.28,18.71,0,0-2,.09-2,.09h0Z" />
      <path d="m566.13,256.64c-1.65-16.74-12.16-32.28-27.18-39.92-5-2.59-10.43-4.38-16.03-5.25,5.62.7,11.13,2.33,16.25,4.8,15.63,7.39,26.74,23.01,28.94,40.13,0,0-1.99.23-1.99.23h0Z" />
    </g>
  );
};

export default SixNine;
