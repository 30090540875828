import AntiMainstream from "./types/AntiMainstream";
import Aviators from "./types/Aviators";
import CartierShades from "./types/CartierShades";
import CloutGoggles from "./types/CloutGoggles";
import DealWithIt from "./types/DealWithIt";
import Gojo from "./types/Gojo";
import Ozzy from "./types/Ozzy";
import ThreeD from "./types/ThreeD";

const Glasses = ({ type }) => {
  switch (type) {
    case "aviators":
      return <Aviators />;
    case "clout_goggles":
      return <CloutGoggles />;
    case "3d":
      return <ThreeD />;
    case "deal_with_it":
      return <DealWithIt />;
    case "anti_mainstream":
      return <AntiMainstream />;
    case "ozzy":
      return <Ozzy />;
    case "gojo":
      return <Gojo />;
    case "cartier_shades":
      return <CartierShades />;
    default:
      return null;
  }
};

export default Glasses;
