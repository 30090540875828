import styled from "styled-components";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../utils/useOutsideClick";
import { Grid, Typography } from "@mui/material";
import CustomPortal from "./CustomPortal";
import { usePopper } from "react-popper";
import {
  cardDark,
  cardVeryLight,
  container,
  doubtedBlue,
  menuShadow,
  purple,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import { BsCheck } from "react-icons/bs";
import { getGameModeString, getRegionString } from "../../utils/helpers";

// const styles
const DropDownContainer = styled.div`
  font-family: "Inter";
  min-width: 100%;
  border: none;
  position: relative;
`;
const DropDownHeaderContainer = styled.button`
  font-family: "Inter";
  min-height: 40px;
  min-width: 100%;
  border-radius: 6px;
  background-color: ${(props) => props.backgroundColor};
  padding: 8px 12px 8px 12px;
  text-align: left;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 2px solid ${(props) => props.border};
`;
const DropDownList = styled.div`
  font-family: "Inter";
  border: none;
  border-radius: 6px;
  box-shadow: ${(props) => props.shadow};
  background-color: ${(props) => props.background};
  overflow-y: auto;
  max-height: 300px;
  min-width: ${(props) => props.containerWidth}px;
  position: absolute;
  z-index: 1000000;
  margin-top: 2px;
`;
const DropDownListItem = styled.button`
  display: block;
  width: 100%;
  font-family: "Inter";
  text-align: left;
  background-color: ${(props) =>
    props?.selected ? doubtedBlue : "transparent"};
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  height: 35px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.background};
  }
`;

const CustomDropdown = (props) => {
  const {
    options,
    placeholder,
    onChange,
    select = false,
    selected = null,
    removeSelected = () => {},
    clearSelected = () => {},
    backgroundColor = cardDark,
    borderColor = cardVeryLight,
    backgroundHover = container,
    value = placeholder,
    border,
    selectType = null,
  } = props;
  const wrapperRef = useRef(null);

  const [listOpen, setListOpen] = useState(false);
  const [title, setTitle] = useState(value);
  const [hovered, setHovered] = useState(false);
  const [refEl, setRefEl] = useState(null);
  const [popperEl, setPopperEl] = useState(null);

  let { styles, attributes } = usePopper(refEl, popperEl, {
    placement: "bottom-start",
  });

  const toggleOpen = () => {
    setListOpen(!listOpen);
  };

  useOutsideClick(wrapperRef, () => {
    if (listOpen) {
      setListOpen(false);
    }
  });

  useEffect(() => {
    setTitle(value);
  }, [value]);

  const localStyles = {
    icon: {
      color: secondaryText,
      fontSize: 16,
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      color: secondaryText,
      whiteSpace: "nowrap",
    },
    placeholder: {
      fontSize: 14,
      fontWeight: 500,
      color: secondaryText,
      whiteSpace: "nowrap",
    },
    options: {
      fontSize: 14,
      fontWeight: 500,
      color: text,
    },
    selectedOptions: {
      fontSize: 14,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <div ref={wrapperRef}>
      <DropDownContainer shadow={menuShadow}>
        <DropDownHeaderContainer
          ref={setRefEl}
          type="button"
          onClick={toggleOpen}
          color={cardVeryLight}
          backgroundColor={hovered ? backgroundHover : backgroundColor}
          blue={purple}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          border={border ? border : hovered ? doubtedBlue : cardVeryLight}
        >
          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 1 }}
              wrap="nowrap"
            >
              {select ? (
                <Grid item>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Typography
                        sx={
                          selected?.length > 0
                            ? localStyles.title
                            : localStyles.placeholder
                        }
                      >
                        {selected?.length > 0 ? (
                          <>
                            {title}:{" "}
                            {selected?.map((option, i) => (
                              <span style={{ fontWeight: 700, color: text }}>
                                {selectType === "region" &&
                                  getRegionString(option)}

                                {selectType === "gameMode" &&
                                  getGameModeString(option)}

                                {i === selected?.length - 1 ? null : (
                                  <>{", "}</>
                                )}
                              </span>
                            ))}
                          </>
                        ) : (
                          <>
                            {title}:{" "}
                            <span style={{ fontWeight: 700 }}>None</span>
                          </>
                        )}
                      </Typography>
                    </Grid>

                    {selected?.length > 0 && (
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <BsCheck style={{ fontSize: 22, color: doubtedBlue }} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Typography
                        sx={
                          title !== placeholder
                            ? localStyles.title
                            : localStyles.placeholder
                        }
                      >
                        {placeholder}:{" "}
                        <span
                          style={{
                            color: title === placeholder ? secondaryText : text,
                            fontWeight: 700,
                          }}
                        >
                          {title === placeholder ? "None" : title}
                        </span>
                      </Typography>
                    </Grid>

                    {title !== placeholder && (
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <BsCheck style={{ fontSize: 22, color: doubtedBlue }} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "auto",
                }}
              >
                {listOpen ? (
                  <FaAngleUp style={localStyles.icon} />
                ) : (
                  <FaAngleDown style={localStyles.icon} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </DropDownHeaderContainer>
        {listOpen && (
          <>
            <CustomPortal>
              <DropDownList
                shadow={"0 2px 8px rgb(0 0 0 / 15%)"}
                background={cardDark}
                containerWidth={wrapperRef.current.offsetWidth}
                ref={setPopperEl}
                style={styles.popper}
                {...attributes.popper}
              >
                {options?.map((option, i) => (
                  <DropDownListItem
                    type="button"
                    onClick={() => {
                      if (select) {
                        if (option.title === "None") {
                          clearSelected();
                          return;
                        } else {
                          if (selected?.includes(option?.value)) {
                            // remove option
                            return removeSelected(option?.value);
                          } else {
                            onChange(option.value);
                          }
                          return;
                        }
                      }

                      if (option.title === "None") {
                        setTitle(placeholder);
                        onChange(null);
                        setListOpen(false);
                        return;
                      } else {
                        setTitle(option.title);
                        onChange(option.value);
                        setListOpen(false);
                      }
                    }}
                    key={i}
                    selected={option.title === title}
                    background={doubtedBlue}
                  >
                    {selected?.length > 0 ? (
                      <Grid item>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          gap={{ xs: 1 }}
                          wrap="nowrap"
                        >
                          <Grid item>
                            <span
                              style={
                                selected?.includes(option?.value)
                                  ? localStyles.selectedOptions
                                  : localStyles.options
                              }
                            >
                              {option?.title}
                            </span>
                          </Grid>

                          {selected?.includes(option?.value) && (
                            <Grid
                              item
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <BsCheck
                                style={{ color: doubtedBlue, fontSize: 22 }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <span
                        style={
                          option?.title === title
                            ? localStyles.selectedOptions
                            : localStyles.options
                        }
                      >
                        {option?.title}
                      </span>
                    )}
                  </DropDownListItem>
                ))}
              </DropDownList>
            </CustomPortal>
          </>
        )}
      </DropDownContainer>
    </div>
  );
};

export default CustomDropdown;
