import { CircularProgress, Grid } from "@mui/material";
import { useContext, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { inviteToPrivateMatch } from "../../api/matches";
import { searchForUser } from "../../api/search";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, SET_SUCCESSES, StoreDispatch } from "../../store/Store";
import { emptyIcon, secondaryText } from "../../utils/themeContstants";
import CustomInput from "../custom/CustomInput";
import CustomModal from "../custom/CustomModal";
import Empty from "../custom/Empty";
import SecondaryButton from "../custom/SecondaryButton";
import UserInviteResultItem from "../teams/UserInviteResultItem";

const PrivateMatchInviteModal = ({ match, open, onClose }) => {
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();

  const [query, setQuery] = useState("");
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invited, setInvited] = useState([]);
  const [inviteHovered, setInviteHovered] = useState(false);

  const handleClose = () => {
    setQuery("");
    setResults(null);
    setLoading(false);
    setInvited([]);
    setInviteHovered(false);
    onClose();
  };

  const handleInviteUser = (user_id) => {
    setLoading(true);
    inviteToPrivateMatch(middleware, match?._id, user_id).then((res) => {
      setLoading(false);

      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
      }
    });
  };

  const handleSearch = (searchQuery) => {
    if (
      searchQuery === "" ||
      searchQuery == null ||
      searchQuery?.length >= 21
    ) {
      setResults(null);
      return;
    }

    setResults(null);
    setLoading(true);
    searchForUser(middleware, searchQuery).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setResults(res?.userArray);
      }
    });
  };

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      title="Invite Opponents"
      description="Invite the user you wish to compete against."
      titleSize="large"
      open={open}
      onClose={handleClose}
      primaryButton={<SecondaryButton label="Close" onClick={handleClose} />}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <Grid item sx={styles.width}>
              <CustomInput
                value={query}
                placeholder="Search users..."
                onChange={(val) => {
                  setQuery(val);
                  handleSearch(val);
                }}
                autoFocus={true}
                onPaste={(val) => {
                  setQuery(val);
                  handleSearch(val);
                }}
              />
            </Grid>

            {loading && (
              <Grid item alignSelf="center">
                <CircularProgress size={30} sx={{ color: secondaryText }} />
              </Grid>
            )}

            {!loading &&
              query !== "" &&
              query != null &&
              (results == null || results?.length < 1) && (
                <Empty
                  title="No results"
                  label="Try searching something else"
                  icon={<AiOutlineSearch style={emptyIcon} />}
                />
              )}

            {loading ||
            results?.length < 1 ||
            results == null ||
            query === "" ||
            query == null ? null : (
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                >
                  {results?.map((user, i) => (
                    <UserInviteResultItem
                      user={user}
                      invitedUsers={invited}
                      setInvitedUsers={setInvited}
                      onClick={handleInviteUser}
                      key={i}
                    />
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default PrivateMatchInviteModal;
