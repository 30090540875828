import { Grid, Typography, useMediaQuery } from "@mui/material";
import { BiLinkExternal } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import SpacingWrapper from "../../utils/SpacingWrapper";
import {
  background,
  backgroundDark,
  card,
  cardExtraLight,
  cardVeryLight,
  container,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";
import { useContext, useState } from "react";
import { StoreContext } from "../../store/Store";
import DownloadACModal from "../anti_cheat/DownloadACModal";

const Footer = () => {
  const store = useContext(StoreContext);
  const isDesktop = useMediaQuery("(min-width:1025px)");
  const isTablet = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  const [downloadOpen, setDownloadOpen] = useState(false);

  const styles = {
    container: {
      width: "100%",
      padding: 2,
      backgroundColor: container,
      paddingBottom: isTablet ? 10 : 4,
      // borderTop: `1px solid ${cardExtraLight}`,
    },
    width: {
      width: "100%",
    },
    header: {
      fontSize: 16,
      fontWeight: 700,
      color: text,
    },
    label: {
      fontSize: 13,
      fontWeight: 600,
      color: secondaryText,
      "&:hover": {
        cursor: "pointer",
        color: text,
      },
    },
    text: {
      fontSize: 13,
      fontWeight: 500,
      color: secondaryText,
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      fontSize: 16,
      color: secondaryText,
    },
  };

  return (
    <>
      {/* <DownloadACModal
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
      /> */}

      <Grid item sx={styles.container}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <SpacingWrapper>
            <Grid
              container
              direction="column"
              alignItems={isDesktop ? "center" : "start"}
              justifyContent="center"
              gap={{ xs: 4 }}
            >
              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="start"
                  gap={{ xs: 4, lg: 12 }}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.header}>
                          Connect With Us
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                        >
                          <Grid
                            item
                            onClick={() =>
                              window.open(
                                "https://twitter.com/rivoxgg",
                                "_blank"
                              )
                            }
                          >
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              gap={{ xs: 1 }}
                            >
                              <Grid item>
                                <Typography sx={styles.label}>
                                  Twitter
                                </Typography>
                              </Grid>

                              <Grid item sx={styles.iconContainer}>
                                <BiLinkExternal style={styles.icon} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            onClick={() =>
                              window.open(
                                "https://discord.gg/rivox",
                                "_blank"
                              )
                            }
                          >
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              gap={{ xs: 1 }}
                            >
                              <Grid item>
                                <Typography sx={styles.label}>
                                  Discord
                                </Typography>
                              </Grid>

                              <Grid item sx={styles.iconContainer}>
                                <BiLinkExternal style={styles.icon} />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.header}>Support</Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                        >
                          <Grid
                            item
                            onClick={() =>
                              window.open(
                                "https://discord.gg/rivox",
                                "_blank"
                              )
                            }
                          >
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              gap={{ xs: 1 }}
                            >
                              <Grid item>
                                <Typography sx={styles.label}>
                                  Create a Ticket
                                </Typography>
                              </Grid>

                              <Grid item sx={styles.iconContainer}>
                                <BiLinkExternal style={styles.icon} />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item onClick={() => navigate("/support/terms")}>
                            <Typography sx={styles.label}>
                              Terms of Service
                            </Typography>
                          </Grid>
                          <Grid item onClick={() => navigate("/support/rules")}>
                            <Typography sx={styles.label}>
                              Rules
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            onClick={() => navigate("/support/privacy")}
                          >
                            <Typography sx={styles.label}>
                              Privacy Policy
                            </Typography>
                          </Grid>

                          <Grid item onClick={() => navigate("/support/faq")}>
                            <Typography sx={styles.label}>FAQ</Typography>
                          </Grid>

                          {store?.user &&
                            store?.user?.anticheat?.must_run_ac && (
                              <Grid item onClick={() => setDownloadOpen(true)}>
                                <Typography sx={styles.label}>
                                  Download Rivox Anti-Cheat
                                </Typography>
                              </Grid>
                            )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.header}>Features</Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="start"
                          justifyContent="center"
                        >
                          <Grid item onClick={() => navigate("/shop/avatars")}>
                            <Typography sx={styles.label}>Shop</Typography>
                          </Grid>

                          <Grid item onClick={() => navigate("/")}>
                            <Typography sx={styles.label}>Tokens</Typography>
                          </Grid>

                          <Grid item onClick={() => navigate("/leaderboard")}>
                            <Typography sx={styles.label}>
                              Leaderboard
                            </Typography>
                          </Grid>

                          <Grid item onClick={() => navigate("/profile/teams")}>
                            <Typography sx={styles.label}>Profile</Typography>
                          </Grid>

                          <Grid item onClick={() => navigate("/locker")}>
                            <Typography sx={styles.label}>My Locker</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={{ xs: 1 }}
                >
                  <Grid
                    item
                    sx={{
                      transition: transition,
                      "&:hover": { cursor: "pointer" },
                    }}
                    onClick={() => navigate("/")}
                  >
                    <img
                      src={`https://cdn.rivox.gg/assets/logo.svg`}
                      alt="rivox logo"
                      draggable={false}
                      style={{ width: 125 }}
                    />
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.text}>
                      © 2024 Rivox LLC | All Rights Reserved.
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.text}>
                      <span style={{ color: text, fontWeight: 800 }}>
                        support@rivox.gg
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SpacingWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
