import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { card, cardDark, menuShadow, text } from "../../utils/themeContstants";
import { usePopper } from "react-popper";
import CustomPortal from "./CustomPortal";

const Badge = ({
  label,
  children,
  size = "large",
  backgroundColor = cardDark,
  onClick,
  textTransform = "uppercase",
  maxWidth = null,
}) => {
  const [hovered, setHovered] = useState(false);
  const [refEl, setRefEl] = useState(null);
  const [popperEl, setPopperEl] = useState(null);

  let { styles, attributes } = usePopper(refEl, popperEl, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const localStyles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: size === "small" ? 0.7 : 1,
      borderRadius: 1,
      backgroundColor,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: card,
      },
    },
    hoverContainer: {
      backgroundColor: "rgb(16, 18, 27, 0.7)",
      borderRadius: 1,
      padding: 1,
      boxShadow: menuShadow,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxWidth,
    },
  };

  return (
    <Grid
      item
      sx={localStyles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      ref={setRefEl}
    >
      <CustomPortal>
        {hovered && (
          <div
            ref={setPopperEl}
            style={{
              ...styles.popper,
              position: "absolute",
              zIndex: 999999999,
            }}
            {...attributes.popper}
          >
            <Grid item sx={localStyles.hoverContainer}>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 800,
                  color: text,
                  textTransform,
                }}
              >
                {label}
              </Typography>
            </Grid>
          </div>
        )}
      </CustomPortal>
      {children}
    </Grid>
  );
};

export default Badge;
