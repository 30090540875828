import { CircularProgress, Grid, Typography, Box } from "@mui/material";
import { AiOutlineWarning } from "react-icons/ai";
import { useContext, useEffect, useRef, useState } from "react";
import { FaHistory } from "react-icons/fa";
import useMiddleware from "../../api/useMiddleware";
import { getHistory } from "../../api/user";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import {
  emptyIcon,
  normal,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import { MATCH_HISTORY_LIMIT } from "../../utils/limits";
import usePagination from "../../utils/usePagination";
import NotSelected from "../custom/NotSelected";
import HistoryItem from "./HistoryItem";
import MatchHistoryGlimmer from "../glimmers/MatchHistoryGlimmer";
import { BsClockHistory } from "react-icons/bs";
import Empty from "../custom/Empty";
import MatchHistoryFilters from "../matches/filters/MatchHistoryFilters";

const History = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [winners, setWinners] = useState("");
  const [losers, setLosers] = useState("");
  const [state, setState] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const handleGetHistory = () => {
    if (isFetching) return; // Prevent concurrent calls
    setIsFetching(true);
    getHistory(middleware, skip, winners, losers, state).then((res) => {
      setLoading(false);
      setIsFetching(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.history?.length >= MATCH_HISTORY_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setHistory((prevHistory) => {
          const newHistory = prevHistory ? [...prevHistory, ...res?.history] : res?.history;
          const uniqueHistory = Array.from(new Set(newHistory.map(item => item.match_id)))
            .map(match_id => newHistory.find(item => item.match_id === match_id));
          return uniqueHistory;
        });
      }
    });
  };

  usePagination(bottom, () => setSkip(history?.length));

  useEffect(() => {
    if (history == null) {
      handleGetHistory();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetHistory();
    }
  }, [skip]);

  useEffect(() => {
    setHistory([]);
    setLoading(true);
  }, [winners, losers, state]);

  useEffect(() => {
    setSkip(0);
    handleGetHistory();
  }, [winners, losers, state]);

  const styles = {
    width: {
      width: "100%",
    },
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <BsClockHistory style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>Match History</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <MatchHistoryFilters
          setWinners={setWinners}
          setLosers={setLosers}
          setState={setState}
        />
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          {loading && (
            <Grid
              item
              alignSelf="center"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 4,
              }}
            >
              <CircularProgress size={30} sx={{ color: secondaryText }} />
            </Grid>
          )}

          {!loading && (
            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 1 }}
              >
                {history?.length < 1 && (
                  <Empty
                    title="No history"
                    label="Try playing a match"
                    icon={<BsClockHistory style={emptyIcon} />}
                  />
                )}

                {history?.length > 0 && (
                  <Grid item sx={styles.width}>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      {history?.map((token, i) => (
                        <Box position="relative" width="100%" key={i}>
                          <HistoryItem match={token} />
                          {token?.mod_action_taken && (
                            <AiOutlineWarning
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "-10px",
                                color: "yellow",
                              }}
                              size={24}
                            />
                          )}
                        </Box>
                      ))}
                      {hasMore && (
                        <div style={{ width: "100%" }} ref={bottom}>
                          <MatchHistoryGlimmer numItems={1} />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default History;
