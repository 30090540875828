import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import { currencyFormatter, getNotiTimestamp } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import {
  card,
  cardDark,
  cardLight,
  green,
  modalShadow,
  normal,
  red,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";
import MarkWithdrawModal from "./MarkWithdrawModal";
import SecondaryButton from "../custom/SecondaryButton";
import { SET_SUCCESSES, StoreDispatch } from "../../store/Store";
import MarkChargebackModal from "./MarkChargebackModal";

const PendingChargebackItem = ({ chargeback, setChargebacks, chargebacks }) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const updateStore = useContext(StoreDispatch);

  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(chargeback?.account_used);
    updateStore({
      type: SET_SUCCESSES,
      payload: "Email Copied",
    });
  };

  const styles = {
    username: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },

    container: {
      padding: 2,
      borderRadius: 1,
      backgroundColor: card,
      boxShadow: modalShadow,
      flexGrow: 1,
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        transform: "translateY(-5px)",
        backgroundColor: cardDark,
      },
    },
    label: {
      fontSize: small,
      fontWeight: 600,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
    resultText: {
      fontSize: 12,
      color: text,
      fontWeight: 800,
      textTransform: "uppercase",
    },
    notBanned: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 1,
      backgroundColor: green,
    },
    banned: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 1,
      backgroundColor: red,
    },
    width: {
      width: "100%",
    },
  };

  return (
    <>
      {/* <MarkWithdrawModal
        open={open}
        onClose={() => setOpen(false)}
        setPendingChargebackItems={setPendingChargebackItems}
        withdrawals={withdrawals}
        withdraw={chargeback}
      /> */}

      <MarkChargebackModal
        open={open}
        onClose={() => setOpen(false)}
        setChargebacks={setChargebacks}
        chargebacks={chargebacks}
        chargeback={chargeback}
      />

      <Grid item sx={styles.width}>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 2 }}
        >
          <Grid
            item
            sx={styles.container}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => setOpen(true)}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              gap={{ xs: 2 }}
              sx={{
                backgroundColor: hovered ? card : cardLight,
                transition: transition,
                padding: 1,
                borderRadius: 1,
              }}
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography sx={styles.label}>ACCOUNT</Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.value}>
                      {chargeback?.account_used}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {isDesktop && (
                <>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.label}>USERNAME</Typography>
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.username}>
                          {chargeback?.username}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.label}>AMOUNT</Typography>
                      </Grid>

                      <Grid item>
                        <Typography sx={styles.value}>
                          {currencyFormatter().format(chargeback?.amount)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {isDesktop && (
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                  >
                    <Grid item alignSelf="end">
                      <Typography sx={{ ...styles.label, textAlign: "right" }}>
                        DATE
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        sx={{
                          ...styles.value,
                          fontWeight: 500,
                          color: text,
                        }}
                      >
                        {getNotiTimestamp(new Date(chargeback?.timestamp))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item>
            <SecondaryButton
              fullWidth
              label="Dispute"
              size="small"
              onClick={() => {
                window.open(
                  "https://www.paypal.com/resolutioncenter/view/" +
                    chargeback?.paypal_id
                );
              }}
            />
            <SecondaryButton
              fullWidth
              label="Receipt"
              size="small"
              onClick={() => {
                window.open(
                  "https://rivox.gg/receipt/" +
                    chargeback?.receipt_id
                );
              }}
            />

            <SecondaryButton
              fullWidth
              label="View user"
              size="small"
              onClick={() => {
                navigate("/staff/search", {
                  state: {
                    user_id: chargeback?.user_id,
                  },
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PendingChargebackItem;
