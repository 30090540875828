import { Grid } from "@mui/material";
import CoinItem from "./CoinItem";

const ShopCoins = () => {
  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <>
      <Grid item sx={styles.width}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill,minmax(194px, 6fr))",
            gap: 12,
          }}
        >
          <CoinItem amount={5} />
          <CoinItem amount={10} />
          <CoinItem amount={25} />
          <CoinItem amount={50} />
          <CoinItem amount={100} />
        </div>
      </Grid>
    </>
  );
};

export default ShopCoins;
