const Todoroki = ({ color }) => {
  const styles = {
    one: {
      stroke: color,
      fill: color,
      filter: "brightness(65%)",
      strokeMiterLimit: 10,
    },
    two: {
      fill: color,
      filter: "brightness(65%)",
    },
    three: {
      strokeMiterLimit: 10,
      stroke: "#92a2ab",
      fill: "none",
      strokeWidth: 2,
    },
    four: {
      strokeMiterLimit: 10,
      fill: "none",
      strokeWidth: 2,
    },
    five: {
      fill: color,
    },
    six: {
      fill: "#92a2ab",
    },
    seven: {
      fill: "#fefefe",
    },
  };

  return (
    <g>
      <path
        style={styles.seven}
        d="m528.84,202.94c-9.29-2.86-24.22-6.24-42.45-4.81-1.94.15-7.49.64-15.69,2.39-8.11,1.73-30.86,7.25-56.55,23.46-13.4,8.46-29,18.3-41.24,37-5.47,8.36-8.63,15.98-10.48,21.29-3.15,9.99-6.98,20.69-11.67,31.93-4.72,11.33-9.7,21.73-14.67,31.17,6.06-5.58,12.12-11.15,18.18-16.73-.72,8.08-.84,18.08.79,29.4,1.19,8.29,3.08,15.61,5.11,21.79,3.19-10.64,6.37-21.29,9.56-31.93.17,5.43,1.18,13.7,5.42,22.87,2.87,6.2,6.39,11.02,9.37,14.47-1.29,8-2.58,15.99-3.88,23.99,3.42-1.51,9.83-4.85,15.22-11.43,4.51-5.51,6.38-10.93,7.2-14.17,2.24,3.51,5.42,7.78,9.86,12.13,4.96,4.86,9.94,8.31,13.94,10.67-1.45-9.63-2.89-19.26-4.34-28.89l15.03,25.84-3.37-42.26,11.51,25.82c-1.33-10.19-2.31-30.15,8.03-51.97,5.25-11.08,11.91-19.47,17.49-25.34,4.57,15.53,9.3,27.26,12.91,35.33,2.29,5.12,5.6,12.06,7.68,22.11,1.42,6.85,1.74,12.64,1.78,16.57,3.41-7.94,6.82-15.88,10.24-23.82,1.36,5.31,3.57,11.84,7.28,18.91,4.56,8.68,9.89,15.32,14.31,20.04-.73-11.92-1.45-23.84-2.18-35.75-6.54-16.5-12.93-39.21-13.04-66.39-.04-9.71.73-18.68,1.93-26.78-4.13,10.31-8.26,20.61-12.39,30.92-.21-10.14.52-18.6,1.3-24.76,1.41-11.13,2.89-22.07,9.2-34.69,2.36-4.72,4.76-8.4,6.45-10.81-2.54,1.6-5.07,3.2-7.61,4.79l15.22-16.91-7.61,2.35c2.2-1.98,4.65-4,7.38-6.01,7.11-5.23,14.13-8.92,20.22-11.54-3.48.57-7.24,1.34-11.22,2.37-5.46,1.41-10.32,3.06-14.54,4.73,3.44-2.45,6.87-4.91,10.31-7.36Z"
      />
      <path
        style={styles.five}
        d="m521.42,208.24c6.14-4.39,16.1-10.28,29.55-13.14,25.46-5.42,46.65,3.56,62.71,10.36,32.27,13.68,50.5,34.47,55.4,40.32,26.29,31.45,29.7,64.83,30.26,78.06-5.04,2.79-10.09,5.57-15.13,8.36l-28.77,28.64-42.46-24.2-38.56-21.67-14.53,48.06-5.74-39.18-4.09,21.96-8.3,49.76c-6.74-9.36-13.72-20.97-19.4-34.88-15.04-36.8-12.98-70.1-9.94-89.38-4.24,10.32-8.49,20.63-12.73,30.95-.07-14.95,1.33-27.16,2.73-35.9,1.93-12.06,3.86-17.4,5.94-21.75,4.05-8.47,9.33-14.54,13.14-18.33-3.12,2.37-6.24,4.75-9.36,7.12.95-2.09,2.38-4.61,4.52-7.2,4.17-5.05,9.05-7.91,12.19-9.42-4.05,1.33-8.1,2.66-12.14,3.99,2.53-2.94,6.62-6.97,12.67-10.48,8.16-4.73,15.96-6.26,20.53-6.81-3.01-.33-6.72-.49-10.92-.08-7.68.74-13.69,3.02-17.56,4.87Z"
      />
      <path
        style={styles.six}
        d="m370.13,284.55c-4.19,10.29-8.35,23.95-9.69,40.29-.66,7.99-.53,15.31,0,21.79,3.57-8.03,7.13-16.05,10.7-24.08-.39,5.84-.75,11.76-1.05,17.74-.19,3.74-.36,7.46-.51,11.15-1.42,3.67-2.81,7.47-4.15,11.4-2,5.85-3.74,11.52-5.26,16.98-3.78-9.96-9.43-29.22-5.86-53.22,2.85-19.12,10.41-33.54,15.82-42.06Z"
      />
      <path
        style={styles.six}
        d="m378.99,351.45c-1.57-5.12-2.98-11.54-3.32-19.01-.63-13.54,2.51-24.57,5.26-31.68-1.53,1.48-3.68,3.84-5.54,7.1-4.23,7.41-3.45,13.4-4.71,30.41-.2,2.76-.53,6.74-1.04,11.55.39,5.16,1.63,12.65,5.51,20.94,2.9,6.2,6.41,11.01,9.37,14.47.65-4.42,1.04-10.71-.28-18.08-1.18-6.58-3.34-11.91-5.26-15.71Z"
      />
      <path
        style={styles.six}
        d="m386.19,314.96c1.01-6.12,3.17-14.39,8.03-23.31,2.83-5.2,5.96-9.49,8.86-12.92-4.29,8.07-9.02,19.52-10.8,33.71-3.89,31.09,8.93,55.3,14.95,65.13-2.17-7.88-4.03-17.84-4.15-29.4-.22-20.42,5.09-36.86,9.59-47.39-3.8,10.16-7.87,25.38-6.83,43.84.47,8.35,1.89,15.73,3.6,21.96,1.23-8.46,2.47-16.92,3.7-25.39-.85,5.13-1.58,14.51,2.67,24.96,1.93,4.74,4.4,8.54,6.64,11.4,1.48,9.63,2.95,19.26,4.43,28.89-2.66-1.55-6.36-3.92-10.31-7.28-3.24-2.75-12.82-11.42-20.82-30.69-8.58-20.67-9.87-40.38-9.56-53.52Z"
      />
      <path
        style={styles.six}
        d="m413.59,338.78v-12.04c-.05,10.26,1.51,24.27,8.58,39.15,1.81,3.81,3.79,7.27,5.81,10.39-.81-8.02-1.13-17.52-.28-28.13.74-9.14,2.21-17.3,3.91-24.32-.76,5.2-1.23,11.66-.7,19.04.5,7,1.77,13.06,3.16,17.95,1.16,14.19,2.33,28.38,3.49,42.56-2.73-4.41-6.03-9.86-9.69-16.16-11.91-20.53-13.72-26.23-14.57-31.47-1.09-6.77-.53-12.66.28-16.98Z"
      />
      <path
        style={styles.six}
        d="m436.01,306.34c1.49-5.9,3.46-12.37,6.09-19.26,2.78-7.29,5.84-13.81,8.86-19.51-9.15,21.65-15.77,47.76-14.12,77.01.17,2.94.41,5.82.73,8.64,5.94-18.33,11.88-36.66,17.82-54.99-3.04,9.33-5.86,20.21-7.75,32.44-2.65,17.12-2.77,32.35-1.94,44.86v11.42c-5.07-7.89-13.39-23.29-14.78-44.08-1.06-15.77,2.27-28.56,5.1-36.53Z"
      />
      <path
        style={styles.six}
        d="m472.43,276.31c-1.4,4.01-2.2,7.31-2.65,9.51-1.57,7.62-1.08,11.45-3.6,17.49-1.1,2.64-2.35,4.66-3.16,5.87-1.75,5.86-3.51,11.71-5.26,17.57,2.07-3.46,4.67-7.28,7.97-11.2,1.82-2.17,3.65-4.14,5.43-5.9-1.07-4.39-2.02-10.38-1.66-17.49.32-6.41,1.61-11.79,2.93-15.84Z"
      />
      <path
        style={styles.six}
        d="m473.1,274.41l6.64-17.99c-2.44,6.3-5.12,15.31-5.81,26.36-.7,11.08.85,20.34,2.49,26.86.95,7.07,3.22,16.64,8.86,26.86,2.43,4.42,5.09,8.21,7.65,11.4-1.12-5.32-2.03-13.7.66-23.31,1.05-3.76,2.45-7.01,3.88-9.71,2.23,15.42,4.47,30.84,6.7,46.26-1.22,4.71-3.18,10.3-6.37,16.26-1.57,2.92-3.22,5.54-4.86,7.85.25-1.96.51-4.9.38-8.45-.29-8.16-2.41-13.61-6.5-24.58-2.94-7.89-5.78-15.81-8.72-23.69-3.03-8.13-5.42-14.73-6.92-18.88-1.12-4.61-2.14-11.08-1.58-18.78.49-6.71,2.02-12.3,3.52-16.44Z"
      />
      <path
        style={styles.six}
        d="m504.16,327.26c-1.53,7.26-3.37,21.13,1.44,37.62,4.94,16.93,14.45,28.31,19.81,33.89-5.32-7.41-12.1-18.64-16.61-33.38-4.75-15.55-5.15-29.03-4.65-38.13Z"
      />
      <path
        style={styles.six}
        d="m394.53,365.42c.67,4.48,1,10.68-.46,17.84-2.6,12.77-9.47,21.61-13.42,25.98,3.34-1.47,9.5-4.67,14.8-10.93,4.86-5.74,6.8-11.43,7.62-14.67-2.85-6.07-5.69-12.15-8.54-18.22Z"
      />
      <polyline
        style={styles.six}
        points="356.52 314.96 337.39 343.01 354.49 328.65"
      />
      <path
        style={styles.one}
        d="m667.87,244.83c3.46,5.13,7.11,11.37,10.33,18.69,2.27,5.16,3.97,10.02,5.26,14.45-2.08-4.98-5-10.8-9.13-16.98-3.42-5.11-6.96-9.4-10.24-12.92,4.77,6.65,10.85,16.46,15.5,29.14,9.61,26.25,7.04,48.4,6.92,48.4-.09,0,.48-13.24-5.54-30.14-1.42-3.99-2.9-7.33-4.15-9.9,2.08,6.33,5.03,18.68,1.11,33.2-1.75,6.48-4.43,11.75-6.92,15.71.03-3.76.03-7.56,0-11.4-.07-7.34-.26-14.52-.55-21.54-.98,3.74-1.86,7.9-2.49,12.42-.45,3.2-.73,6.24-.88,9.1-2.99-7.96-4.16-9.71-4.65-9.6-.95.21,1.51,7.28-.55,15.21-1.49,5.72-4.85,10.01-7.75,12.92-.3-10.66-1.75-23.65-5.81-38.01-2.18-7.71-4.78-14.66-7.47-20.78,1.91,7.2,3.6,15.72,4.43,25.34.7,8.04.67,15.36.28,21.79-2.42-11.36-5.59-23.59-9.74-36.51-3.2-9.95-6.65-19.24-10.19-27.86,2.7,7.9,5.25,17.83,6.37,29.4.76,7.89.71,15.06.28,21.29-2.21-4.66-4.43-9.33-6.64-13.99l-3.32,11.96-9.41-28.64c.03,6.36-.21,15.63-1.51,26.79-1.55,13.2-3.49,19.66-4.3,19.59-1.03-.09.69-10.75-5.26-23.82h0c-5.55-11.7-12.04-21.8-12.04-21.8l11.49,33.71c-3.46-6.08-7.49-12.54-12.18-19.26-4.92-7.04-9.88-13.37-14.67-19.01.65,5.33,1.25,13.47.31,23.35-.66,6.93-1.84,12.2-2.8,16.19-5.07,21.12-13.74,47.67-16.41,55.75,6.27-17.89,12.66-43.78,11.42-75.01-.63-15.84-3.09-29.84-6.09-41.56l1.11,71.97-4.98-27.88-4.71,32.06-12.46-31.8c1.32,18.16,2.04,37.37,1.94,57.53-.05,10.71-.34,21.14-.82,31.26,4.61-19.71,9.22-39.43,13.83-59.14l.81,45.87,18.84-65.38c4.98,5.24,10.17,11.3,15.26,18.22,8.91,12.13,15.17,23.74,19.62,33.73,2.03-5.07,4.06-10.14,6.09-15.21,1.82,6.65,3.43,14.47,4.24,23.29,1.18,12.83.34,24.04-1.05,32.96,4.57-8.61,9.13-17.22,13.7-25.83l1.94,12.16,13.84-23.06,16.88,39.28,3.25-54.49,2.21,37.76,11.98-37.51,12.46,26.86-1.38-53.22,14.39,28.64c-.56-12.32-2.31-26.74-6.4-42.51-8.08-31.1-22.04-55.07-33.55-71.22Z"
      />
      <path
        style={styles.six}
        d="m486.79,342.58c-2.14-6.87-4.05-14.96-5.11-24.08-1.96-16.89-.32-31.4,1.93-42.2-1.4,11.64-1.9,26.93,1.12,44.48,1.97,11.46,5.03,21.41,8.2,29.65-2.05-2.62-4.09-5.24-6.14-7.86Z"
      />
      <path
        style={styles.four}
        d="m517.39,211.31c3.99-3.63,9.99-8.24,18.22-11.76,39.9-17.05,94.54,7.77,124.35,36.83,25.72,25.07,34.26,54.55,40.11,74.78,5.57,19.24,7.26,35.83,7.75,47.39l-14.67-28.13,1.66,52.71-12.46-26.86-11.9,39.28-1.94-45.36-3.6,60.31-16.88-39.28-13.84,23.06-1.94-12.16-14.12,28.38c1.87-10.7,2.95-24.38.98-40-.87-6.88-2.21-13.17-3.75-18.8-2.03,5.07-4.06,10.14-6.09,15.21-3.69-8.25-8.7-17.78-15.5-27.88-6.69-9.93-13.63-18.18-19.93-24.84-6.09,22.39-12.18,44.77-18.27,67.16l-.83-46.88-13.01,61.07c-11.93-16.77-30.37-48.3-31.83-89.96-.47-13.52.93-25.65,3.04-35.99-4.61,10.88-9.23,21.76-13.84,32.64.6-23.67,3.73-42.1,7.2-51.9,2.74-7.73,6.64-13.68,6.64-13.68,3.13-4.77,6.43-8.3,8.86-10.64-3.96,3.04-7.93,6.08-11.89,9.12,2.32-3.72,5.6-8.1,10.23-12.42,1.97-1.84,3.94-3.44,5.81-4.82-2.86.84-5.72,1.69-8.58,2.53,2.43-2.94,6.95-7.61,14.12-11.4,7.43-3.93,14.35-5.15,18.39-5.58-3.64-.26-8.46-.25-13.96.76-8.47,1.55-14.76,4.77-18.55,7.1Z"
      />
      <path
        style={styles.four}
        d="m528.84,202.94c-3.05-1.04-6.31-1.98-9.79-2.78-19.35-4.44-36.77-2.33-48.99.51-17.57,4.24-43.62,12.8-67.82,30.66-18.81,13.89-27.39,26.67-29.34,29.65-5.83,8.92-9.14,17.14-11.07,23.06-3.94,13.44-9.29,28.14-16.61,43.59-2.96,6.24-6.02,12.16-9.13,17.74,6-5.58,11.99-11.15,17.99-16.73-.63,7.87-.68,17.51.83,28.38,1.21,8.71,3.16,16.37,5.26,22.81,3.14-10.31,6.27-20.61,9.41-30.92.4,6.14,1.9,15.28,7.2,25.09,2.44,4.52,5.19,8.25,7.75,11.24-1.29,8-2.58,15.99-3.88,23.99,3.27-1.43,9.01-4.43,14.12-10.14,5.42-6.05,7.47-12.18,8.3-15.46,1.96,3.53,5.16,8.35,10.24,13.18,4.91,4.66,9.86,7.71,13.56,9.63-1.48-9.63-2.95-19.26-4.43-28.89l15.22,27.12-3.6-43.84,12.18,30.92c-2.13-15.85-3.25-45.91,16.33-72.23,2.71-3.64,5.6-6.92,8.58-9.88,5.63,15.54,11.26,31.09,16.88,46.63,1.33,3.22,2.6,6.95,3.6,11.15,1.61,6.81,2.01,12.9,1.94,17.82,2.14-3.51,4.39-7.78,6.37-12.76,1.84-4.64,3.06-8.93,3.88-12.67.99,4.03,2.4,8.48,4.43,13.18,5.05,11.69,12,20.42,17.72,26.36-1-12.31-1.99-24.61-2.99-36.92"
      />
      <path
        style={styles.six}
        d="m417.03,240.94c-13.08,12.57-24.25,27.07-31.91,43.61,1.53-4.3,3.35-8.5,5.45-12.57,6.28-12.23,14.71-23.48,25.11-32.51,0,0,1.35,1.47,1.35,1.47h0Z"
      />
      <path
        style={styles.six}
        d="m475.54,237.27c-25.13,14.43-46.91,34.85-61.95,59.7,10.66-19.1,25.36-35.95,42.72-49.26,5.8-4.45,11.88-8.55,18.23-12.18l1,1.73h0Z"
      />
      <path
        style={styles.six}
        d="m508.32,211.29c-26.94,2.23-53,11.89-74.98,27.64,15.38-13.54,34.56-22.54,54.53-26.89,6.68-1.46,13.48-2.39,20.31-2.74l.14,2h0Z"
      />
      <path
        style={styles.six}
        d="m507.31,227.16c-10.41,5.25-21.54,10.31-32.27,14.82,7.4-4.29,15.79-8.72,23.43-12.68,2.64-1.34,5.28-2.67,7.96-3.93l.89,1.79h0Z"
      />
      <path
        style={styles.three}
        d="m512.13,215.37c-5.43.4-11.82,1.27-18.82,3.04-11.51,2.92-20.56,7.24-26.99,10.95"
      />
      <path
        style={styles.two}
        d="m581.01,233.5c9.69,12.33,16.29,27.23,18.31,42.81-2.64-11.34-7.07-22.23-13.25-32.09-2.05-3.27-4.26-6.44-6.63-9.48,0,0,1.57-1.23,1.57-1.23h0Z"
      />
      <path
        style={styles.two}
        d="m586.49,233.44c10.54,11.24,16.95,26.35,17.22,41.78.03,2.19-.05,4.37-.24,6.54.06-12.97-3.63-25.87-10.63-36.77-2.31-3.61-4.93-7.02-7.82-10.19l1.47-1.36h0Z"
      />
      <path
        style={styles.two}
        d="m537.66,214.49c9.78-5.93,20.79,4.15,24.29,13.04-1.7-1.82-3.34-3.61-5.1-5.24-4.77-4.51-11.59-9.13-18.22-6.05,0,0-.97-1.75-.97-1.75h0Z"
      />
      <path
        style={styles.two}
        d="m532.49,223.71c5.23,2.1,9.49,7.11,10.36,12.69-1.38-2.41-3-4.53-4.91-6.32-1.9-1.8-4.05-3.25-6.31-4.56l.87-1.8h0Z"
      />
      <path
        style={styles.two}
        d="m566.99,209.36c8.47,2.88,16.34,7.66,22.78,13.87-3.7-2.44-7.46-4.74-11.42-6.71-3.9-2.01-7.95-3.69-12.03-5.27,0,0,.67-1.88.67-1.88h0Z"
      />
      <path
        style={styles.two}
        d="m601.78,215.91c17.88,10.45,32.51,26.61,40.72,45.66,1.13,2.72,2.18,5.48,3.04,8.29-1.19-2.69-2.42-5.35-3.8-7.93-6.72-12.91-16.03-24.42-27.07-33.87-4.4-3.79-9.08-7.24-13.94-10.44l1.05-1.7h0Z"
      />
      <path d="m402.21,384.15c-13.03-20.25-19.22-45.24-16.02-69.19.36,17.94,3.56,35.51,10.25,52.08,2.2,5.49,4.71,10.85,7.5,16.09l-1.72,1.02h0Z" />
      <path d="m421.64,378.15c-8.91-10.63-12.66-26.11-8.05-39.37-.55,10.08.74,20.18,4.93,29.39,1.34,3.04,2.96,5.95,4.73,8.8l-1.61,1.18h0Z" />
      <path d="m433.11,361.1c-6.53-16.74-4.11-38.5,2.9-54.76-.63,2.23-1.22,4.46-1.72,6.7-2.49,11.15-3.02,22.73-1.42,34.03.62,4.52,1.48,8.95,2.16,13.48,0,0-1.92.55-1.92.55h0Z" />
      <path d="m470.19,309.89c-3.8-11.53-2.74-24.73,2.91-35.47-2.81,11.61-3.14,23.31-.97,34.98,0,0-1.94.49-1.94.49h0Z" />
      <path d="m492.64,384.92c3.92-12.18,3.04-25.63-2.93-37.02,2,2.57,3.61,5.46,4.86,8.51,2.45,6.14,3.19,12.98,2.33,19.53-.41,3.29-1.2,6.53-2.36,9.62l-1.89-.64h0Z" />
      <path d="m507.31,373.33c-6.81-18.65-10.11-38.61-9.85-58.45,1.06,14.76,3.49,29.41,7.44,43.67,1.28,4.75,2.75,9.44,4.3,14.12l-1.89.66h0Z" />
      <path d="m353.08,328.54c1.26-15.87,7.65-31.26,17.05-43.99-6.06,10.12-10.54,21.15-13.03,32.66-.84,3.81-1.5,7.66-2.03,11.53,0,0-1.99-.21-1.99-.21h0Z" />
      <path d="m383.54,385.1c.74-8.51.14-17.07-2.06-25.34-.69-2.78-1.57-5.52-2.48-8.3,5.05,7.29,7.41,16.29,7.43,25.11,0,2.96-.28,5.93-.91,8.82l-1.98-.29h0Z" />
      <path d="m368.58,348.86c.75-9.45,2.04-23.06,2.94-32.63-.25,9.67-.56,23.23-.94,32.75,0,0-2-.12-2-.12h0Z" />
      <path d="m506.87,221.19c-35.69,3.82-67.69,19.25-93.28,44.35,5.28-7.14,11.61-13.53,18.59-19.11,20.95-16.65,47.93-25.8,74.69-25.24h0Z" />
      <path d="m501.89,203.2c-5.2-1.31-10.48-2.24-15.83-2.24-6.26.24-14.31,1.29-20.61,1.9,0,0-.23-1.99-.23-1.99,6.9-.86,13.85-1.54,20.84-1.53,5.45.33,10.85,1.67,15.83,3.86h0Z" />
      <path d="m523.84,206.7c-4.57-2.25-9.69-3.14-14.76-2.48,3.69-1.66,7.98-1.87,11.88-.83,1.32.36,2.6.86,3.8,1.53,0,0-.92,1.78-.92,1.78h0Z" />
      <path d="m540.83,395.94c-18.56-42.76-26.76-91.83-18.73-138-.34,5.86-.6,11.71-.79,17.55-1.39,35.01,3.59,70.2,15.15,103.3,1.9,5.52,3.99,10.97,6.21,16.37,0,0-1.84.79-1.84.79h0Z" />
      <path d="m545.85,371.68c-1.79-18.87-3.43-37.77-4.11-56.72,2.73,18.77,4.49,37.65,6.1,56.54,0,0-1.99.18-1.99.18h0Z" />
      <path d="m614.45,351.34c3.04-9.7,5.02-19.6,5.36-29.79,1.73,7.58,1.37,15.7-.69,23.22-.71,2.52-1.62,4.98-2.81,7.31,0,0-1.86-.74-1.86-.74h0Z" />
      <path d="m631.31,381.82c5.11-16.81,7.76-34.32,7.6-51.9,1.34,13.22.5,26.65-2.22,39.65-.93,4.34-2.07,8.64-3.47,12.85,0,0-1.91-.6-1.91-.6h0Z" />
      <path d="m572.86,314.07c.5-7.96.87-17.69.83-25.67.04-2.87.02-5.74.16-8.66.88,2.79,1.43,5.65,1.83,8.53.76,5.77.81,11.62.37,17.42-.24,2.9-.61,5.79-1.21,8.64,0,0-1.98-.26-1.98-.26h0Z" />
      <path d="m647.27,370.58c12.47-15.28,19.64-34.6,19.87-54.35.23,2.47.29,4.97.26,7.46-.32,17.56-7.14,34.88-18.59,48.17,0,0-1.54-1.28-1.54-1.28h0Z" />
    </g>
  );
};

export default Todoroki;
