const WarTorn = ({ color, skinColor }) => {
  const styles = {
    one: {
      strokeMiterLimit: 10,
      fill: "#a67c52",
      stroke: "#000",
      strokeWidth: 2,
    },
    two: {
      strokeMiterLimit: 10,
      stroke: skinColor,
      fill: skinColor,
    },
    three: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
      fill: "#c8c8cc",
    },
    four: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
      fill: "#b2b2b2",
    },
    five: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
      fill: "none",
    },
    six: {
      strokeMiterLimit: 10,
      stroke: "#000",
      strokeWidth: 2,
      fill: "#ddd",
    },
    seven: {
      strokeMiterLimit: 10,
      fill: "#dd1d1d",
      stroke: "#ed1c24",
    },
    eight: {
      fill: skinColor,
    },
    nine: {
      fill: color,
    },
  };

  return (
    <g>
      <path
        style={styles.nine}
        d="M351.75,800.44c14.98,9.36,36.95,21.06,65.25,29.56,12.74,3.82,46.62,12.96,91,11,19.01-.84,53.93-2.73,93-21,4.19-1.96,7.62-3.73,10-5-.33-35.33-.67-70.67-1-106l-59.28-119.85c-12.24-2.38-24.48-4.76-36.72-7.15,1.48,1.27,2.43,2.62,3,4,6.82,16.31-33.84,46.22-36.52,48.16-26.85-19.78-42.55-40.3-37.48-52.16,.73-1.7,1.97-3.41,4-5-2.63,.61-5.3,1.28-8,2-12.19,3.26-23.37,7.22-33.5,11.5-20.17,36.17-40.33,72.33-60.5,108.5l6.75,101.44Z"
      />
      <path
        style={styles.eight}
        d="M611,815c5.91-2.76,13.86-6.71,23-12,2.79-1.62,7.51-4.36,13-8,6.86-4.55,16.08-11.26,26.33-20.7-.98-17.76-2.92-37.32-6.33-58.3-3.82-23.53-8.84-44.81-14.21-63.6-11.56-12.26-28.55-27.68-51.79-41.4-18.49-10.92-35.82-17.73-49.8-22.08,1.67,15.41,6.07,39.04,18.8,65.08,12.79,26.17,28.83,44.21,40,55-.42,3.96-.83,7.92-1.25,11.87,.75,31.38,1.5,62.75,2.25,94.13Z"
      />
      <path
        style={styles.eight}
        d="M287.88,745.85c7.77,9.05,17.4,19.06,29.12,29.15,12.31,10.59,24.21,18.93,34.75,25.44-.15-26.85-1.6-55.06-4.75-84.44-.61-5.73-1.28-11.4-2-17,14.33-14.28,33.05-36.62,47-68,6.53-14.69,10.74-28.48,13.5-40.5-11.4,4.33-23.69,9.73-36.5,16.5-26.35,13.93-47.32,29.67-63.36,43.81-3.12,7.99-6.07,16.73-8.64,26.19-7.06,25.96-9.12,49.57-9.12,68.85Z"
      />
      <path
        style={styles.two}
        d="M442.26,583.65c-.33,2.28-.53,5.12-.26,8.35,.46,5.53,2.08,9.91,3.44,12.79,6.64,7.86,13.28,15.72,19.92,23.58,1.07,1.06,5.54,5.25,12.64,5.62,7.77,.41,12.97-4.04,13.97-4.93,9.95-7.58,16.26-14.97,20.03-20.07,4.08-5.52,6.31-9.86,6-15-.18-3.01-1.18-5.42-2-7-2.8-.67-5.59-1.33-8.39-2l-65.35-1.35Z"
      />
      <path
        style={styles.five}
        d="M448.69,575.42c-2.4,1.83-5.19,4.59-6.69,8.58-5.5,14.55,11.2,31.95,17,38,6.86,7.16,10.68,11.13,17,12,11.28,1.55,20.59-8.17,30-18,7.63-7.97,14.74-15.39,13-24-1.1-5.41-5.24-9.05-8-11"
      />
      <path d="M406.47,590.72c-2.33,10.28-5.38,20.39-9.13,30.25-3.74,9.86-8.26,19.42-13.38,28.63-5.13,9.21-10.92,18.06-17.32,26.44-6.41,8.37-13.4,16.3-20.9,23.7l.23-1.16c.67,1.65,1.23,3.35,1.63,5.09,.41,1.74,.65,3.53,.76,5.29,.12,1.75,.22,3.43,.41,5.13,.18,1.69,.49,3.37,.75,5.06v.09s.02,.05,.02,.05l1.46,40.34,.62,20.17,.5,20.18h-.26s-1.23-20.14-1.23-20.14l-1.1-20.15-2-40.32,.02,.14c-.28-1.73-.6-3.45-.8-5.19-.21-1.74-.32-3.51-.45-5.21-.12-1.7-.35-3.33-.74-4.96-.38-1.62-.91-3.22-1.55-4.76l-.27-.65,.5-.5c7.39-7.38,14.28-15.25,20.58-23.57,6.27-8.34,12.04-17.07,17.12-26.19,5.09-9.11,9.52-18.59,13.31-28.31,3.78-9.73,6.87-19.73,9.26-29.9l1.95,.45Z" />
      <path d="M609,815.01c-.49-28.01-.79-58.41-.95-86.32-.66-6.14-.33-13.48,.98-19.88,0,0,.27,.89,.27,.89-16.1-16.13-29.54-34.9-39.61-55.35-9.98-20.46-16.42-42.6-18.97-65.2,3.45,22.39,10.4,44.2,20.56,64.42,10.04,20.23,23.42,38.8,39.42,54.73,0,0,.37,.37,.37,.37,0,0-.1,.52-.1,.52-1.27,6.4-1.52,12.99-.82,19.48,.33,20.81,.58,43.82,.75,64.74,0,0,.11,21.58,.11,21.58,0,0-2,.02-2,.02h0Z" />
      <path
        style={styles.eight}
        d="M449,762.11c-.81,1.45-1.68,2.87-2.41,4.36-1.06,2.15-2.63,3.82-4.39,5.47-1.95,1.83-3.47,4.1-5.19,6.16-.23,.28-.47,.67-.77,.76-2.97,.87-5.07,2.94-7.32,4.84-.62,.52-1.53,.72-2.3,1.07-.95,.42-2,.72-2.83,1.3-2.52,1.76-5.29,3.02-8.05,4.31-.06-.08-.12-.16-.18-.24,.94-.8,1.89-1.6,2.83-2.4-2.15,1.1-4.55,1.75-6.21,3.62-.3,.33-.92,.43-1.41,.56-.83,.22-1.73,.25-2.51,.57-2.36,.97-4.72,1.88-7.3,2.2-.96,.12-1.88,.57-2.81,.89-3.47,1.19-6.94,2.39-10.4,3.59-.05-.09-.09-.18-.14-.27,.22-.14,.43-.3,.65-.42,3.02-1.6,6.08-3.12,9.05-4.81,4.35-2.47,9.12-3.96,13.74-5.81,2.05-.82,4.14-1.56,5.63-3.29,.21-.24,.67-.39,1.01-.39,2.17,.05,4.03-.92,5.78-1.88,3.78-2.09,7.47-4.36,10.71-7.22,.33-.29,.63-.63,.89-.99,.61-.86,1.18-1.72,2.37-1.94,.39-.07,.82-.31,1.08-.6,1.54-1.66,3.59-2.7,5.27-4.16,1.69-1.47,3.1-3.26,4.63-4.9,.14-.15,.28-.31,.42-.46,.06,.04,.11,.07,.17,.11Zm-57.13,34.86l.17,.39c9.39-3.67,18.79-7.35,28.18-11.02,.06,.07,.13,.15,.19,.22-.79,.72-1.59,1.45-2.38,2.17,3.93-2.24,7.95-4.37,11.46-7.13,5.16-4.06,9.98-8.53,14.87-12.9,.93-.83,1.54-1.99,2.3-2.99-.08-.05-.16-.11-.24-.16-.63,.69-1.25,1.4-1.89,2.07-4.34,4.58-9.69,7.91-14.85,11.49-4.97,3.45-10.26,6.27-15.94,8.47-4.36,1.69-8.65,3.56-12.94,5.4-3,1.28-5.95,2.65-8.93,3.98Z"
      />
      <path
        style={styles.eight}
        d="M416.89,724.5c-1.98,1.12-4.01,2.16-5.94,3.38-1.57,.99-3.01,2.18-4.51,3.28-.29,.21-.53,.52-.85,.67-3.87,1.82-7.78,3.56-11.61,5.47-1.26,.63-2.31,1.67-3.42,2.58-2.18,1.77-4.09,4.04-6.53,5.27-6.94,3.51-12.61,8.59-18.22,13.79-1.09,1.01-2.26,1.95-3.39,2.92l-.28-.27c2.64-3.18,5.29-6.36,7.93-9.53-.04-.07-.09-.13-.13-.2-.81,.35-1.63,.71-2.44,1.06-.1-.12-.19-.23-.29-.35,1.41-1.34,2.91-2.6,4.19-4.06,1.6-1.82,3.01-3.81,4.5-5.72,.13-.16,.22-.38,.38-.49,1.91-1.34,3.85-2.64,5.75-3.99,.41-.29,.95-.65,1.03-1.07,.27-1.29,1.19-1.62,2.23-2.1,2.82-1.3,5.49-2.96,8.36-4.14,2.46-1.01,5.12-1.56,7.69-2.3,.8-.23,1.62-.37,2.41-.62,4.31-1.35,8.61-2.71,12.91-4.07,.07,.17,.14,.33,.21,.5Zm-49.43,31.63c13.43-12.55,28.86-22.01,45.28-29.98-13.2,2.69-36.03,16.05-42.43,24.67,.79-.39,1.43-.7,2.07-1.01l.14,.25-5.05,6.07Z"
      />
      <path
        style={styles.eight}
        d="M431.67,736.13c-1.61,1.39-3.21,2.78-4.82,4.17,5.85-1.13,10.51-4.57,15.58-7.31-2.38,4.52-4.77,9.05-7.28,13.81,2.17-1.09,4.26-2.14,6.58-3.29-.58,1.24-1.06,2.45-1.7,3.57-.65,1.13-1.46,2.16-2.2,3.24-.75,1.09-1.51,2.19-2.14,3.43,1.56-1.08,3.12-2.15,4.68-3.23,.1,.07,.21,.14,.31,.21-1.7,2.76-3.39,5.52-5.22,8.49,1.68-1.12,3.24-2.16,4.94-3.31-.28,.42-.45,.74-.68,1.01-2.12,2.39-4.33,4.68-7.09,6.38-.86,.53-1.42,1.54-2.2,2.23-2.58,2.29-5.24,4.5-7.8,6.82-4.91,4.44-10.52,7.67-16.6,10.2-3.9,1.62-7.68,3.53-11.56,5.21-1.44,.62-2.97,1.08-4.51,1.42-2.01,.44-3.74,1.33-5.29,2.66-1.55,1.32-3.16,2.57-4.76,3.84-.44,.35-.93,.66-1.44,1.03,3.15-4.7,6.24-9.32,9.33-13.94-.08-.08-.16-.17-.24-.25-.51,.19-1.01,.38-1.52,.58l-.12-.15c2.28-2.47,4.56-4.93,6.84-7.4-.07-.08-.14-.17-.22-.25-2.31,1.59-4.61,3.17-6.92,4.76-.05-.06-.11-.12-.16-.17l3.8-5.05c-.06-.06-.11-.13-.17-.19-2.25,1.41-4.49,2.81-6.74,4.22-.08-.09-.17-.17-.25-.26,2.76-3.29,5.52-6.58,8.28-9.87-.08-.09-.16-.17-.24-.26-.29,.2-.57,.42-.87,.6-1.7,1.04-3.44,2.01-5.09,3.12-1.03,.69-1.96,1.55-2.87,2.41-.63,.59-1.16,1.3-1.86,1.85,2.97-5.27,6.14-10.38,10.98-14.24-3.03,.68-5.43,2.55-8.1,4.25,.07-.26,.07-.39,.13-.45,3.33-3.19,6.59-6.47,10.03-9.55,5.42-4.85,11.85-8,18.65-10.34,7.01-2.41,13.41-5.93,19.61-9.91,.22-.14,.58-.06,.88-.08,0,0,.03-.02,.03-.02Z"
      />
      <path
        style={styles.eight}
        d="M438.35,668.59c17.77-7.34,36.35-13.51,53.44-22.51,17.09-9,32.7-20.83,47.97-30.77-13.27,19.01-71.02,49.7-101.41,53.28Z"
      />
      <path
        style={styles.eight}
        d="M490.35,760.59c9.87-4.08,20.19-7.5,29.68-12.5,9.49-5,18.16-11.57,26.64-17.09-7.37,10.56-39.45,27.6-56.32,29.59Z"
      />
      <path
        style={styles.eight}
        d="M493.35,767.59c9.87-4.08,20.19-7.5,29.68-12.5,9.49-5,18.16-11.57,26.64-17.09-7.37,10.56-39.45,27.6-56.32,29.59Z"
      />
      <path
        style={styles.eight}
        d="M493.35,777.59c9.87-4.08,20.19-7.5,29.68-12.5,9.49-5,18.16-11.57,26.64-17.09-7.37,10.56-39.45,27.6-56.32,29.59Z"
      />
      <path
        style={styles.seven}
        d="M595.32,655.61c6.7-1.16,13.58-1.85,20.16-3.64,6.59-1.78,12.88-4.66,18.91-6.91-6.04,5.53-28.33,11.66-39.08,10.55Z"
      />
      <path
        style={styles.eight}
        d="M379.95,752.36c3.2-3.81,35.4-18.87,41.08-19.36-13.87,6.54-27.48,12.95-41.08,19.36Z"
      />
      <g>
        <path
          style={styles.seven}
          d="M628.28,770.95c-.08-.37,.03-.96-.65-.67-.09-.25-.27-.49-.27-.74,0-1.55,.03-3.11,.04-4.66,.01-3.28,.02-6.56,.02-9.84,0-5.68,.04-11.37-.01-17.05-.01-1.77-.13-3.55-.42-5.29-.13-.79-.81-1.49-1.28-2.21-.32-.5-.71-.94-1.07-1.42-.48-.64-.96-1.28-1.43-1.93-.19-.26-.39-.53-.52-.82-.28-.62-.52-1.25-.78-1.88-.29-.7-.28-1.4,.14-2.04,.38-.58,.91-.81,1.54-.4,1.03,.67,1.89,.12,2.8-.29,.57-.26,1.19-.41,1.8-.57,.42-.1,.88-.05,1.28-.19,1.2-.4,2.26,.13,2.57,1.36,.09,.36,.13,.77,.07,1.13-.2,1.35-.42,2.7-.71,4.03-.09,.43-.38,.84-.67,1.19-.78,.96-1.23,1.99-1.41,3.24-1.02,7-.38,14.02-.43,21.03-.02,2.57,0,5.14,.09,7.71,.07,2.07,.27,4.14,.36,6.21,.04,.92,.03,1.86-.1,2.77-.07,.48-.43,.92-.66,1.37-.1-.03-.2-.05-.3-.08Z"
        />
        <path
          style={styles.seven}
          d="M643.84,749.68c-1.58-.88-1.27-2.16-1.26-3.34,.02-3.52,.12-7.05,.03-10.57-.03-1.1-.46-2.24-.93-3.25-.22-.47-.95-.82-1.51-.97-.95-.24-1.8-1.43-1.67-2.63,.12-1.06,1.05-1.85,2.12-1.73,.24,.03,.5,.21,.69,.39,.75,.72,1.44,1.44,2.46,1.84,1.14,.45,1.52,2.47,.73,3.71-.59,.93-.76,1.92-.78,2.97-.08,3.58-.05,7.16,.56,10.71,.08,.48,.12,.99,.05,1.47-.07,.47-.31,.92-.48,1.41Z"
        />
        <path
          style={styles.seven}
          d="M634.53,773.89c-.56-.62-.58-1.44-.51-2.25,.12-1.5,.39-2.98,.45-4.48,.09-2.4,.13-4.81,.06-7.21-.02-.84-.33-1.71-.68-2.49-.73-1.58-.22-3.23,1.29-4.11,.53-.31,.94-.82,1.37-1.27,.82-.87,2.07-.64,2.51,.48,.38,.98,.03,2.43-.76,3.1-.06,.05-.13,.13-.2,.13-1.88,.06-2.11,1.63-2.31,2.88-.29,1.85-.28,3.75-.29,5.63-.01,2.43,.09,4.86,.13,7.29,.01,.87,.04,1.77-1.06,2.3Z"
        />
        <path
          style={styles.seven}
          d="M634.85,735.23c-.11-.02,0,0,.13,.03,.03-.04,.06-.08,.1-.12-.28-.2-.54-.45-.85-.58-1.06-.43-1.43-1.48-.67-2.32,.54-.59,1.23-1.08,1.93-1.49,.56-.33,1.11-.07,1.51,.43,.53,.69,.62,1.47,.33,2.25-.38,1.02-.58,2.03-.46,3.13,.05,.45-.08,.92-.19,1.37-.04,.17-.28,.43-.42,.42-.2-.01-.49-.18-.58-.36-.15-.31-.16-.68-.26-1.02-.18-.61-.39-1.22-.55-1.74Z"
        />
        <path
          style={styles.seven}
          d="M643.89,758.1c-.15-.57-.27-1.15-.47-1.7-.26-.73-.29-1.42,.35-1.95,.65-.54,1.27-.28,1.86,.17,.15,.12,.31,.22,.48,.3,.58,.27,.89,.71,.84,1.36-.05,.61-.59,.56-.95,.8-.42,.28-.67,.84-.93,1.31-.11,.2,0,.52-.12,.71-.15,.25-.41,.44-.63,.65-.18-.22-.42-.41-.51-.66-.1-.3-.07-.65-.09-.97,.05-.01,.11-.03,.16-.04Z"
        />
        <path
          style={styles.seven}
          d="M640.49,722.11c-.34,.62-.56,1.2-.94,1.65-.25,.3-.75,.74-1.01-.09-.02-.06-.11-.15-.17-.15-.45-.05-.91-.13-.93-.69-.01-.61-.06-1.27,.16-1.81,.21-.52,.71-.94,1.13-1.36,.09-.09,.53-.04,.59,.06,.41,.75,.76,1.53,1.18,2.38Z"
        />
        <path
          style={styles.seven}
          d="M625.5,731.68c0,1.26-.37,2.01-1.14,2.17-.41,.08-1.02-.01-1.32-.28-.63-.55-.61-1.36-.4-2.13,.2-.71,.64-1.21,1.38-1.36,.65-.13,1.14,.12,1.33,.77,.1,.33,.12,.69,.14,.83Z"
        />
        <path
          style={styles.seven}
          d="M632.04,739.93c-.05-.28-.09-.47-.14-.74-.91,.42-.98-.21-1.06-.8-.11-.84,.04-1.63,.82-2.05,.31-.16,.9-.13,1.19,.07,.69,.48,.77,1.28,.62,2.06-.14,.72-.49,1.32-1.43,1.45Z"
        />
        <path
          style={styles.seven}
          d="M649,759.75c-.04,.12-.06,.46-.24,.6-.3,.25-.69,.48-1.07,.54-.52,.08-1.03-.67-1.02-1.36,0-.79,.48-1.34,1.14-1.33,.68,.01,1.2,.62,1.18,1.55Z"
        />
      </g>
      <path
        style={styles.seven}
        d="M294.12,730.91c.26-2.58,.35-5.15,.8-7.53,.52-2.76,1.97-4.48,4.58-4.83,.96-.13,1.91-.64,2.82-1.14,1.43-.79,2.87-1.06,4.58-.33,3.98,1.7,8.43,1.64,13.29,1.78,1.7,.05,3.48,.16,5.3,.5,2.47,.46,4.29,2.34,3.79,4.39-1.31,5.43,.31,11.61,1.83,18.28,.35,1.54,.48,3.1,.4,4.54-.07,1.4-1.04,2.11-2.66,1.55-1.61-.56-2.58-1.95-2.82-3.42-.35-2.08-.53-4.09-.72-6.05-.24-2.47-.39-4.86-.65-7.22-.29-2.63-2.54-4.8-5.36-5.44-2.69-.61-4.55,.34-4.73,2.72-.12,1.54,.26,3.27,.48,4.98,.16,1.25,.53,2.56,.65,3.83,.16,1.74-.66,2.62-1.89,2.3-1.15-.3-1.96-1.44-1.99-3.13-.05-2.72,.06-5.34,.09-7.91,0-.53,0-1.07-.05-1.61-.11-1-.48-1.85-1.56-2.13-1.05-.27-1.98,0-2.15,1.03-.25,1.57-.26,3.27-.27,4.98,0,1.21,.2,2.49,.24,3.75,.03,.95-.28,1.71-1.23,1.48-.97-.23-1.43-1.14-1.44-2.12,0-1.16,.2-2.27,.2-3.41,0-1.58,.03-3.17-.26-4.69-.12-.65-.95-1.55-1.53-1.74-.88-.29-1.01,.73-1.1,1.49-.18,1.37-.26,2.78-.48,4.17-.14,.89-.24,2-1.27,1.93-.97-.06-1.36-1.05-1.68-1.99-.15-.43-.21-.93-.44-1.27-.29-.42-.77-1-1.05-.95-.36,.06-.84,.63-.85,1.05-.04,1.8,.11,3.68,.12,5.57,0,1.23-.04,2.48-.2,3.69-.14,1.06-.54,2.16-1.52,1.84-1.05-.34-1.27-1.73-1.29-2.83-.04-1.72,.1-3.38,.17-5.02,.01-.34,.03-.68,.05-1.02-.07-.02-.13-.04-.2-.06Z"
      />
      <path
        style={styles.seven}
        d="M412.67,768.79c0-2.72,0-5.44,0-8.16,0-.31-.07-.63-.11-.94-.08-.68-.5-.94-1.14-.96-.5-.01-1.01,0-1.5-.07-1-.13-1.5-.63-1.5-1.43,0-1.05,.55-1.82,1.49-1.96,.54-.08,1.11-.1,1.66-.04,.94,.1,1.64-.18,2.15-.99,.3-.46,.66-.89,1.04-1.3,.6-.66,1.35-.99,2.25-1,1.03-.01,1.79,.43,1.96,1.14,.22,.91-.38,2.15-1.23,2.52-.51,.22-1.23,.23-1.15,1.06,.08,.82,.77,1,1.41,1.18,.41,.11,.83,.17,1.24,.27,.93,.24,1.52,.81,1.71,1.76,.19,.94-.11,1.72-.93,2.17-1.31,.73-1.75,1.78-1.55,3.23,.13,.94,.16,1.9,.15,2.84,0,.26-.26,.69-.46,.74-.49,.1-.59-.32-.58-.72,.04-1.27,.12-2.53,.14-3.8,0-.35-.07-.76-.26-1.04-.16-.24-.56-.49-.82-.45-.24,.03-.59,.43-.6,.68-.06,1.29-.03,2.58,0,3.87,.02,1.03,.12,2.06,.11,3.08,0,.56-.07,1.35-.81,1.32-.73-.03-.73-.83-.71-1.38,.05-1.74,.16-3.48,.24-5.22,.02-.4,.07-.8,.01-1.18-.04-.25-.26-.46-.4-.69-.17,.2-.48,.4-.49,.61-.06,1.08-.06,2.16-.04,3.24,.08,3.27,.18,6.55,.25,9.82,0,.39-.05,.8-.19,1.15-.08,.2-.39,.42-.59,.42-.2,0-.49-.25-.56-.45-.13-.36-.17-.77-.17-1.16-.01-2.72,0-5.44,0-8.16Z"
      />
      <g>
        <path
          style={styles.seven}
          d="M333.51,684.67c0-3.11-.04-6.68,.01-10.24,.03-2.54-.73-4.76-2.64-6.44-2.69-2.36-2.72-5.16-1.63-8.23,.14-.4,.31-.8,.48-1.19,1.51-3.54,.71-5.27-2.94-6.38-3.4-1.03-4.64-3.9-2.55-6.79,1.04-1.43,2.82-2.47,4.47-3.26,1.48-.71,2.93,0,3.63,1.59,.59,1.35,1.04,2.75,1.52,4.14,.63,1.85,1.75,3.13,3.67,3.82,5.24,1.9,7.34,7.32,3.79,11.58-4.22,5.06-4.9,10.58-4.62,16.69,.36,7.91,.53,15.84,.66,23.76,.03,1.75-.25,3.55-.69,5.25-.34,1.35-1.49,2.13-2.97,1.95-1.52-.18-2.02-1.4-2.03-2.67,0-2.14,.16-4.28,.34-6.42,.46-5.57,.97-11.13,1.5-17.16Z"
        />
        <path
          style={styles.seven}
          d="M346.34,668.53c.03,1.69-1.24,3.16-2.71,3.14-1.44-.02-2.72-1.29-2.71-2.71,.01-1.48,1.34-2.85,2.79-2.9,1.49-.05,2.6,.99,2.63,2.47Z"
        />
        <path
          style={styles.seven}
          d="M327.69,659.16c0,1.38-1.38,2.75-2.77,2.64-1.4-.11-2.28-.87-2.35-2.34-.07-1.46,1.04-2.69,2.4-2.71,1.45-.02,2.73,1.11,2.73,2.41Z"
        />
        <path
          style={styles.seven}
          d="M328.29,673.16c-.04,1.54-1.29,2.67-2.72,2.55-1.21-.1-1.96-.72-1.89-2.02,.07-1.46,1.32-2.9,2.47-2.73,1.28,.19,2.12,.92,2.14,2.19Z"
        />
        <path
          style={styles.seven}
          d="M345.82,659.49c-.42-.77-1.22-1.59-1.15-2.31,.06-.61,1.14-1.55,1.77-1.55,.56,0,1.57,1.1,1.55,1.7-.02,.74-.88,1.47-1.37,2.2-.27-.02-.54-.03-.81-.05Z"
        />
      </g>
      <g>
        <path
          style={styles.seven}
          d="M618.11,676.64c.15,2.7,.32,5.43,.45,8.2,.04,.82,.3,1.91-1.19,2.06-1.64,.17-1.52-1.01-1.55-1.9-.14-3.48-.24-6.9-.37-10.27-.01-.3,.02-.69-.2-.88-.57-.49-1.27-.9-1.91-1.35-.41,.56-1.19,1.14-1.17,1.69,.11,3.09,.4,6.21,.68,9.37,.23,2.56,.55,5.14,.78,7.75,.04,.48,.07,1.1-.25,1.46-.49,.55-1.34,1.27-1.98,1.29-.65,.01-1.48-.64-1.77-1.2-.34-.67-.27-1.53-.25-2.31,.29-10.03-1.22-19.45-2.74-28.54-.27-1.63-.13-3.31,.12-4.91,.32-2.04,2.07-3.26,4.73-3.63,1.69-.24,3.46-.19,5.1-.45,1.16-.19,2.51-.46,3.19-1.06,2.18-1.95,4.67-2.89,8.15-1.8,3.46,1.09,5.01,3.32,5.46,5.87,.41,2.32,.3,4.69,.32,7.07,.04,4.28,.03,8.67-.02,13.16-.01,1.14-.17,2.31-.42,3.45-.1,.46-.64,.85-.99,1.27-.43-.35-1.11-.65-1.23-1.07-.59-2.04-1.06-4.09-1.48-6.13-.57-2.73-.97-5.45-1.61-8.1-.21-.88-.49-2.2-2.08-2.1-1.53,.09-1.58,1.36-1.52,2.32,.23,3.4,.61,6.85,.93,10.36,.05,.59,.2,1.2,.09,1.77-.08,.41-.53,.79-.81,1.18-.51-.28-1.39-.49-1.47-.85-.25-1.06-.2-2.16-.25-3.24-.12-2.54-.12-5.05-.39-7.52-.11-1.08-.63-2.25-2.52-2.23-1.64,.02-2.67,.93-2.65,2.63,.03,2.84,.28,5.72,.44,8.64,.12,0,.24-.01,.36-.02Z"
        />
        <path
          style={styles.seven}
          d="M632.89,683.64c1.33,1.14,2.08,4.27,1.36,5.7-.5,.99-1.45,1.23-2.19,.54-1.31-1.22-.77-5.1,.83-6.24Z"
        />
        <path
          style={styles.seven}
          d="M624.04,681.54c1.73,.83,2.32,1.88,2.07,3.12-.08,.39-1.21,.98-1.76,.94-.52-.04-1.3-.8-1.29-1.26,.02-.82,.54-1.64,.98-2.8Z"
        />
        <path
          style={styles.seven}
          d="M612.97,697.45c.94,1.08,2.37,2.04,1.22,3.65-.27,.38-2.05,.5-2.79,.21-1.12-.42-.4-2.11,1.57-3.86Z"
        />
      </g>
      <g>
        <path
          style={styles.seven}
          d="M421.86,599.66c.09,.85,.14,1.7,.27,2.55,.14,.98,.17,2,.58,2.96,.75,1.74,3.17,2.32,4.58,.91,1.44-1.43,2.4-3.15,3.6-4.81,.37-.51,.64-1.06,1.09-1.55,.62-.68,1.54-.95,2.79-.51,1.34,.48,1.28,1.2,.61,1.85-.66,.65-1.52,1.2-2.33,1.75-1.69,1.14-3.36,2.21-4.91,3.27-.33,.23-.56,.53-.77,.83-.96,1.36-.26,2.21,2.11,2.48,.42,.05,.85,.08,1.44,.38-.73,.14-1.51,.22-2.13,.45-.52,.2-.87,.58-1.28,.87,.58,.24,1.16,.5,1.77,.73,.25,.09,.54,.1,.81,.15,2.02,.39,2.64,1.05,2.13,2.53-.52-.34-.93-.74-1.41-.9-.77-.25-1.57-.35-2.33-.5,.11,.45,.21,.9,.35,1.35,.11,.36,.28,.73,.43,1.1-.54-.04-1.06-.06-1.58-.11-.33-.03-.67-.08-1.38-.16,.57,.58,.9,.98,1.33,1.33,1.02,.84,2.15,1.66,3.22,2.56,.96,.81,1.02,1.5,.14,1.87-.85,.36-2.28-.03-2.89-.9-.69-.98-1.02-1.99-1.62-2.94-.32-.51-.88-.98-1.32-1.45-.35,.36-.75,.69-1.03,1.08-.17,.24-.17,.59-.24,.85-1-.93-1.82-2.15-2.71-2.43-1.77-.54-.86,1.21-1.65,1.94,.02-.68,.13-1.11,.03-1.55-.1-.46-.29-1.17-.62-1.34-.46-.23-1.2-.21-1.61-.04-.55,.23-.91,.7-1.48,.96,.36-.54,.76-1.05,1.06-1.64,.12-.25,0-.63-.01-.95-.36,.03-.83-.06-1.05,.09-1.7,1.2-3.27,2.39-4.79,3.49-.31,.22-.6,.52-.98,.56-.31,.03-.81-.18-1.06-.44-.15-.16-.15-.78,.01-.91,.65-.5,1.38-.95,2.18-1.34,1.29-.63,2.72-1.16,4.12-1.86,.5-.25,1.34-.61,.73-1.32-.31-.36-1.29-.53-1.86-.48-.96,.09-1.83,.38-2.69,.6-.95,.24-1.58-.02-1.9-.8-.33-.8,.02-1.54,.93-1.62,.87-.08,1.83,.03,2.8,.07,.29,.01,.58,.07,.88,.09,.7,.04,1.52,.21,1.68-.6,.17-.83-.4-1.36-1.28-1.7-.13-.05-.25-.1-.38-.15-1.13-.46-1.58-1-1.51-1.81,.08-.83,.92-1.5,2.07-1.6,.93-.09,1.65,.34,2.13,1.29,.13,.25,.23,.51,.38,.76,.58,.97,1.36,1.42,2.45,1.35,1.22-.08,1.57-.81,1.62-1.63,.1-1.35,.09-2.7,.13-4.04,0-.33,.02-.65,.03-.98,.11,0,.23-.01,.35-.02Z"
        />
        <path
          style={styles.seven}
          d="M412.17,600.11c.91,.03,1.43,.42,1.39,1.07-.05,.93-.9,1.67-1.78,1.59-.83-.08-1.59-.73-1.56-1.33,.03-.7,.98-1.36,1.95-1.33Z"
        />
        <path
          style={styles.seven}
          d="M409.81,600.46c-.4,.31-.64,.56-.93,.69-.08,.03-.51-.39-.47-.47,.14-.31,.42-.56,.64-.84,.2,.17,.41,.34,.75,.62Z"
        />
      </g>
      <path
        style={styles.seven}
        d="M397,767c2.43-3.46,7.47-9.61,16-14,5.65-2.91,10.88-4.03,14.5-4.5-4.56,2.47-9.23,5.14-14,8-5.83,3.5-11.33,7.01-16.5,10.5Z"
      />
      <path
        style={styles.seven}
        d="M297,716.04c3.85-.95,10.5-2.04,18.5-.54,11.01,2.06,18.29,7.85,21.39,10.65-5.07-2.65-11.93-5.62-20.39-7.65-7.52-1.81-14.2-2.38-19.5-2.46Z"
      />
      <g>
        <path
          style={styles.one}
          d="M439.7,778.29c4.54,4.41,10.62,11.37,15.53,21.21,3.49,7,5.39,13.47,6.48,18.57-.78,1.02-2.06,2.44-3.98,3.68-2.38,1.55-4.65,2.12-6.03,2.36-4.05-2.97-9.26-7.46-14.13-13.92-6.77-8.98-9.91-17.8-11.43-23.51,4.52-2.8,9.04-5.6,13.56-8.4Z"
        />
        <path
          style={styles.four}
          d="M444.81,769.24l-26.58,16.86c-.39,.03-.97,.02-1.63-.18-1.08-.33-1.76-.99-2.05-1.32-.84,1.1-1,2.58-.42,3.8,.65,1.35,2.09,2.17,3.62,2.05,9.22-5.58,18.44-11.16,27.66-16.74,.83,.77,1.99,.85,2.71,.3,.7-.53,.72-1.44,.72-1.57-.1-.42-.34-1.1-.91-1.75-1.16-1.32-2.79-1.43-3.14-1.44Z"
        />
        <path
          style={styles.six}
          d="M423.02,752.56c-.88,.17-2.11,.51-3.44,1.27-1.95,1.12-3.1,2.58-3.67,3.44-.39,.61-1.16,1.63-2.47,2.48-1.38,.9-2.71,1.17-3.44,1.27,3.77,5.73,7.53,11.45,11.3,17.18l11.87-8.04-10.16-17.6Z"
        />
        <path
          style={styles.three}
          d="M437.81,773.68c.29-1.13-.02-2.33-.83-3.11-.98-.94-2.53-1.12-3.8-.42-3.96,2.68-7.91,5.36-11.87,8.04-1.2,.99-1.56,2.58-.96,3.74,.62,1.19,1.98,1.44,2.15,1.47,5.11-3.24,10.21-6.48,15.32-9.72Z"
        />
      </g>
    </g>
  );
};

export default WarTorn;
