const KiritoCoat = ({ skinColor }) => {
  const styles = {
    one: {
      fill: "#232429",
    },
    two: {
      fill: "#646d6b",
    },
    three: {
      fill: "#798381",
    },
    four: {
      fill: "#2e2f34",
    },
    five: {
      fill: "#909392",
    },
    six: {
      fill: skinColor,
    },
    seven: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    eight: {
      fill: "#a1a0a4",
    },
    nine: {
      fill: "#353638",
    },
  };

  return (
    <g>
      <path
        style={styles.six}
        d="m288.78,746.86c-.4-14.89.38-33.27,4.22-53.91,2.91-15.7,6.98-29.41,11.15-40.89,18.82-16.26,44.95-35.52,78.85-52.06,24.12-11.77,46.61-19.45,65.69-24.57,21.07,1.84,42.13,3.67,63.2,5.51,19.71,2.58,50.2,9.07,83.11,27.06,26.46,14.46,45.41,31.41,57.79,44.37,4.94,15.77,9.6,33.7,13.21,53.58,4.64,25.54,6.56,48.82,7.05,68.96-14.2,12.19-36.17,28.56-66.05,42-51.38,23.12-97.39,24.63-120,23.99-46.92-1.33-82.48-14.45-100-21.99-49.98-21.53-81.86-53.17-98.22-72.04Z"
      />
      <path
        style={styles.six}
        d="m518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12.32,7.46,1.17,8.35.49.51,1.02.81,1.4.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22.48-.66,1.01-1.11,1.37-1.37.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
      />
      <path d="m460,523c1.9,1.69,3.9,3.36,6,5,15.78,12.32,32.48,19.14,47,23,1.96-5.48,3.91-10.96,5.87-16.44-4.61.02-9.68-.16-15.13-.64-.79-.07-6.86-.61-12.73-1.62-12.45-2.13-23.75-6.29-31-9.31Z" />
      <g>
        <path d="m460.98,525.94s-11.31,49.7-11.31,49.7c0,0-.13.58-.13.58-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7s1.95.44,1.95.44h0Z" />
        <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59,0,0,1.88.69,1.88.69h0Z" />
      </g>
      <g>
        <path
          style={styles.four}
          d="m288.78,746.93c.39-18.05,2.24-38.96,6.91-61.84,2.43-11.9,5.34-22.92,8.46-33,13.99-11.67,31.33-24.42,52.2-36.52,17.07-9.9,33.26-17.49,47.74-23.35,16.66,19.16,33.32,38.32,49.98,57.48l40.94,21.96,31.63,5.21,55.44-63.51,14.64-4.78c9.54,6.33,19.58,13.5,29.9,21.6,9.56,7.51,18.28,14.97,26.18,22.19,5.2,18.85,10.03,40.02,13.77,63.27,3.42,21.27,5.43,41.14,6.49,59.27-9.14,7.88-22.37,18.24-39.55,28.4-56.72,33.54-109.96,37.34-125.2,38.04-9.96.46-39.61,1.25-76.57-7.69-31.54-7.64-54.65-19.04-65.86-25.06-14.21-7.64-33.08-19.43-52.41-36.68-1.43-1.28-2.61-2.36-3.41-3.1-3.76-3.37-7.66-7.09-11.61-11.2-3.51-3.64-6.72-7.22-9.65-10.68Z"
        />
        <path
          style={styles.nine}
          d="m477.6,840.57c19.46,1.07,42,.38,66.58-4.05,6.38-1.15,12.5-2.48,18.35-3.94.45-42.12.9-84.24,1.34-126.37.57,1.34,1.09,1.99,1.55,1.95,3.13-.24,3.83-31.85,2.1-94.82-4.61,8.21-9.68,13.17-13.43,16.17-5.98,4.78-12.67,7.84-14.87,13.3-.28.69-1.53,4-3.65,8.48-.6,1.27-1.11,2.29-1.25,2.56-1.7,3.33-7.43,11.29-15.31,14-7.33,2.52-13.96-.38-23.22-4.43-5.56-2.44-13.89-6.9-22.43-15.13"
        />
        <path
          style={styles.one}
          d="m544.3,700.47l6.85-1.81,1.11-6.35,22.15-29.21,22.3-54.53,27.37,16.51,4.87-14.92-8.39-4.04,4.72-9.61,16.06,3.9,9.65-24.98-6.52-15.62-13.39.24-11.94-2-51.98-31.18-32.4,7.96c-5.42.8-10.84,1.61-16.25,2.41-1.85,5.45-3.64,11.91-4.89,19.27-1.58,9.29-1.92,17.6-1.73,24.42,6.44,2.85,11,6.29,13.88,8.8,3.7,3.23,5.96,6.12,6.55,6.88,0,0,3.41,4.43,5.65,9.58.53,1.21.99,2.46.99,2.46s.55,1.48.98,2.94c2.28,7.75,1.11,18.84,1.11,18.84-.38,3.58-1.19,8.49-3.06,14.15,2.78,1.09,10.9,4.69,13.5,12.58,1.31,3.96.77,7.41-.2,12.33-1.92,9.76-5.5,12.49-6.59,21.32-.5,4.05-.48,7.43-.39,9.66Z"
        />
        <path
          style={styles.one}
          d="m429.3,508.07c10.23,5.88,20.46,11.77,30.7,17.65l-11.31,49.7c-1.43.64-3.85,1.97-5.79,4.57-2.85,3.83-2.85,8.02-2.82,10.53.04,3.16.71,6.14,4.43,14.35,3.86,8.5,6.65,13.3,6.65,13.3,3.59,6.16,9.29,15.21,17.8,26.13,3.97,17.53,7.93,35.06,11.9,52.59-13.55-24.34-32.25-52.46-58-80.67-11.57-12.67-23.11-23.78-34.19-33.49-3.6,1.79-7.21,3.58-10.81,5.37-2.59-1.05-6.16-2.83-9.82-5.86-4.23-3.51-6.84-7.26-8.35-9.85-.99-2.97-1.98-5.94-2.97-8.91,2.45-3.6,5.35-7.44,8.77-11.37,5.41-6.22,10.86-11.19,15.75-15.11,2.79-3.62,6.3-7.53,10.7-11.3,13.65-11.73,28.48-15.94,37.35-17.62Z"
        />
        <path
          style={styles.three}
          d="m562.53,707.62c.6-.46,10.11-7.55,20.54-3.51,5.75,2.23,8.78,6.68,9.85,8.46,2.93,3.7,6.49,9.32,8.35,16.81.51,2.05.83,4,1.02,5.8,1.2,1.38,2.4,2.77,3.6,4.15-1.15,3.18-2.3,6.36-3.44,9.55-6.9,6.09-9.83,15.46-7.53,24.13,2.58,9.7,11.27,16.84,21.62,17.63,1.4.28,5.22,1.23,8.43,4.65,3.96,4.22,4.22,9.25,4.25,10.5-5.6,3.23-11.73,6.47-18.38,9.6-10.2,4.8-19.9,8.53-28.76,11.43.14-1.94.28-3.88.42-5.82-.2-.04-16.72-3.04-16.92-3.08,0,0-2.13-.39-2.13-.39-.2.36-.51.91-.91,1.56,0,0-1.61,2.58-4,5.16-2.02,2.18-7.27,6.66-14.97,11.54-3.42.61-11.53,1.6-20.77-2.02-8.46-3.31-13.52-8.78-15.72-11.48-.96-6.49-1.52-14.88-.46-24.52.64-5.82,1.76-10.99,3-15.39.53-1.41,2-4.71,5.51-7.19,2.19-1.55,4.35-2.18,5.71-2.47,6.05.56,12.78.8,20.09.46,3.16-.15,6.2-.4,9.09-.72,1.75-5.37,2.27-9.92,2.39-13.04.1-2.53,0-4.85-.23-6.91-.74-5.21-2.14-8.98-3.29-11.48-1-2.16-2.59-5.04-2.67-9.13-.03-1.45.14-2.64.28-3.42,5.35-6.96,10.7-13.92,16.05-20.87Z"
        />
        <path
          style={styles.five}
          d="m546.34,773.1c.69-.07,1.66-.26,2.6-.88,2.25-1.5,2.72-4.49,2.98-8.2.25-3.55.37-8.54-.24-14.69-.74-2.94-1.56-5.26-2.16-6.81-1.63-4.19-2.98-6.19-3.27-9.71-.15-1.84.05-3.36.23-4.32,5.65-6.96,11.31-13.92,16.96-20.87,2.2-1.21,5.36-2.64,9.36-3.49,3.1-.66,5.84-.79,7.99-.74,1.73.29,4.34.98,6.89,2.8,1.7,1.21,3.41,2.95,7.15,10.1,1.37,2.62,3.13,6.18,4.97,10.54-1.23-1.83-3.05-4.24-5.61-6.75-.67-.65-2.98-2.9-5.54-4.6-2.14-1.42-12.45-7.71-21.55-3.26-1.53.75-4.56,2.55-7.1,6.78-1.42,2.37-3.14,5.95-3.83,10.57-1.59,10.64,3.47,18.9,5.09,21.33.39,14.38-3.22,24.99-9,26.41-1.05.26-2.41.27-4.16-.39-4.69-.75-10.68-1.23-17.55-.59-3.88.36-7.36,1.02-10.39,1.77-1.84,2.73-4.07,6.73-5.46,11.93-2.58,9.59-.86,17.75.26,21.69,1.11,2.32,4.01,7.55,10.11,11.37,7.83,4.92,15.7,4.25,18.06,3.96,1-.25,2.43-.71,3.95-1.61,3.73-2.21,5.25-5.42,7.04-8.22,1.64-2.55,4.27-5.93,8.56-9.38,3.13-.58,10.78-1.58,19.51,1.85,8.42,3.3,13.27,8.92,15.22,11.45-5.12,1.89-10.23,3.79-15.35,5.68,1.18-3.09.85-5.72-.46-6.47-.26-.15-.78-.35-1.66-.14-4.26-.9-8.52-1.79-12.78-2.69l-23.61,18.29c-3.84.5-12.18,1.06-21.52-2.84-7.59-3.17-12.46-7.86-14.97-10.66-.87-6.54-1.36-14.68-.59-23.97.56-6.71,1.67-12.66,2.93-17.71.89-1.39,2.92-4.1,6.55-5.98,2.53-1.31,4.87-1.71,6.37-1.85"
        />
        <path
          style={styles.five}
          d="m616.24,789.24l-5.38-3.72c-4.12-1.44-6.38-3.63-7.58-5.12-5.64-6.99-4.67-19.21,2.81-30.23,0-.15.04-1.04-.61-1.69-.63-.63-1.65-.8-2.56-.4-2.03,1.93-7.82,7.95-8.92,17.36-1.89,16.2,11.98,26.83,12.65,27.33,3.2-1.17,6.39-2.35,9.59-3.52Z"
        />
        <path
          style={styles.five}
          d="m612.59,814.55c-2.7-1.75-4.19-4.69-3.88-7.61.04-.41.48-4.05,3.42-5.38,2.87-1.3,6.37.4,8.02,2.84,1.6,2.36,1.17,4.97,1.04,5.63-2.87,1.51-5.74,3.02-8.61,4.52Z"
        />
        <path
          style={styles.five}
          d="m528.59,789.34c-.41.56-3.97,5.53-2.15,11.54,1.48,4.89,5.91,8.5,11.25,9.15.62-.03,5.29-.34,8.32-4.45,3.11-4.23,1.88-8.93,1.73-9.49-1.95-5.08-6.27-8.6-11.12-9-3.93-.33-6.92,1.5-8.02,2.25Z"
        />
        <path
          style={styles.three}
          d="m307.44,689.9c-.43-.22-9.39-4.61-17.49.57-5.79,3.69-7.22,9.76-7.49,11.02-.83,3.3-1.51,7.37-1.55,12.04-.1,10.75,3.25,19.17,5.67,23.99,4.09,6.32,8.18,12.63,12.28,18.95l25.4-4.47c3.27-2.67,12.26-10.73,15.15-24.26,4.84-22.66-11.9-39.74-13.22-41.05-1.82.02-3.77.13-5.82.35-5,.54-9.34,1.65-12.91,2.86Z"
        />
        <path
          style={styles.five}
          d="m590.41,740.82c-2.21,2.34-5.68,3.24-8.61,2.74-6.73-1.16-10.92-9.8-9.79-16.63.1-.61,1.37-7.64,6.33-8.99,4.9-1.33,9.62,3.89,11.47,6.74,2.71,4.17,4.76,11.74.59,16.14Z"
        />
        <path
          style={styles.two}
          d="m293.61,689.07c-.09.42-.28,1.67.39,2.93,1.15,2.18,3.77,2.34,3.91,2.35.66-.45,9.58-6.42,18-2.54,6.8,3.13,8.68,10.57,9.78,14.93,1.85,7.32.4,12.93-2.3,23.38-3.64,14.1-8.47,18.97-10.22,20.58-1.78,1.64-3.47,2.78-4.7,3.52,5.54-.56,11.09-1.13,16.63-1.69,2.93-2.48,12.91-11.52,15.23-26.69,3.39-22.17-12.63-37.71-14.16-39.15-7.66.93-15.32,1.85-22.98,2.78l-9.59-.41Z"
        />
        <path
          style={styles.eight}
          d="m562.53,832.58c2.88-.78,5.76-1.57,8.64-2.35.52-3.95,1.03-7.9,1.55-11.85-2.37-.28-4.75-.56-7.12-.84-1.02,5.02-2.05,10.03-3.07,15.05Z"
        />
        <path
          style={styles.eight}
          d="m487.01,840.89c3.09.09,6.29.13,9.59.11,3.14-.02,6.19-.1,9.15-.22,4.8-3.02,9.61-6.04,14.41-9.05l-7.47-4.47c-8.56,4.55-17.12,9.1-25.68,13.64Z"
        />
        <path
          style={styles.eight}
          d="m508.61,786.4l-1.14,5.76c-10.31,3.97-22.31,9.46-35.04,17.13-14.26,8.59-25.74,17.67-34.62,25.71-2.02-.45-4.05-.9-6.07-1.34,8.77-9.55,22.28-22.03,41.3-32.61,13.2-7.35,25.55-11.83,35.57-14.64Z"
        />
        <path
          style={styles.eight}
          d="m543.65,701.2c.41-.52,1.84-2.21,4.32-2.68,1.37-.26,2.52-.04,3.18.14-.24,2.82-.31,6.31.13,10.26.47,4.2,1.39,7.77,2.32,10.57l-7.13,9v-11.42l-2.83-15.88Z"
        />
        <path
          style={styles.eight}
          d="m541.63,679.54l5.77-5.15-1.81-6.91c.11.15,1.51,1.97,3.73,1.82,1.3-.08,2.18-.79,2.5-1.07.59-2.61,1.19-5.22,1.78-7.84l-9.3-14.17-4.95,4.81,3,10.83c-.64.04-1.13.21-1.52.46-4.27,2.72.56,16.56.8,17.22Z"
        />
        <path
          style={styles.eight}
          d="m480.87,693.57c-.52-3.15-1.54-7.85-3.74-13.21-2.9-7.07-4.64-7.44-7.35-14.59-1.61-4.25-3.91-10.29-3.21-16.71.12-1.15.84-6.91.84-6.91h0c3.74,4.14,11.53,13.92,14.09,28.65,1.66,9.53.5,17.52-.65,22.48"
        />
        <path
          style={styles.eight}
          d="m386.15,716.46l-5.82.98-1.51,3.02v31.28c.1.64.37,1.85,1.25,3.07,1.11,1.54,2.54,2.2,3.15,2.44,20.09.54,40.18,1.07,60.27,1.61.9-.09,2.49-.37,4.16-1.38,1.21-.73,2.03-1.59,2.54-2.22v-44.09l-6.45.59.53,33.22c.02.53.06,3.18-1.96,5.32-2.63,2.78-6.37,2.2-6.68,2.15-14.69.02-29.37.05-44.06.07-.87-.3-3.29-1.26-4.96-3.79-.96-1.46-1.3-2.87-1.43-3.76.33-9.51.65-19.02.98-28.52Z"
        />
        <polygon
          style={styles.eight}
          points="309.52 768.32 320.39 777.89 319.06 768.77 319.53 753.54 309.33 754.8 309.52 768.32"
        />
        <path
          style={styles.eight}
          d="m586.31,634.95l3.95-9.87c3.47,13.51,6.61,28.99,8.7,46.17,2.54,20.91,2.97,39.84,2.4,56.1-2.18-3.69-4.35-7.38-6.53-11.08.59-12.23.45-26.59-1.32-42.54-1.62-14.67-4.29-27.69-7.2-38.8Z"
        />
        <polygon
          style={styles.eight}
          points="569.65 669.39 562.68 676.86 563.44 707.62 571.65 704.21 569.65 669.39"
        />
        <polygon
          style={styles.eight}
          points="473.09 683.62 478.25 692.31 478.25 797.65 473.09 800.59 473.09 683.62"
        />
        <polygon
          style={styles.eight}
          points="450.19 649.69 457.28 659.5 457.94 810.55 450.19 815.11 450.19 649.69"
        />
        <polygon
          style={styles.eight}
          points="592.72 617.74 590.56 623.55 647.74 648.83 638.67 639.83 592.72 617.74"
        />
        <path
          style={styles.eight}
          d="m414.66,607.72l5.31,5.64c-12.55-.77-34.55-.52-59.71,8.74-24.55,9.02-41.2,22.6-50.27,31.17-1.33,3.1-3.12,8.39-3.08,15.13.06,9.34,3.62,16.21,5.48,19.3l-4.43,2.35c-.7-.16-1.71-.49-2.71-1.21-3.64-2.65-3.66-8.2-3.55-19,.06-5.39.49-8.67.87-10.91.47-2.75,1.06-5.07,1.58-6.85,9.48-8.97,24.76-21.28,46.29-30.72,26.37-11.56,50.07-13.54,64.23-13.62Z"
        />
        <path
          style={styles.eight}
          d="m624.99,558.39l8.66,6.96c.77-.57,2.27-1.53,4.41-1.89,1.61-.28,2.96-.11,3.82.07,1.05.88,2.56,2.35,3.82,4.53,1.05,1.82,1.57,3.52,1.85,4.75-.54,3.84-1.43,8.3-2.93,13.16-1.4,4.57-3.04,8.54-4.64,11.87-3.45-1.04-6.91-2.09-10.36-3.13l-3.26-.57-2.11,4.74,23.34,11.02.68-1.62,1.14-18.68c1.57-3.03,3.2-6.76,4.5-11.15.84-2.82,1.41-5.47,1.79-7.86-3.26-5.13-6.52-10.26-9.78-15.39-2.7-.09-5.85,0-9.33.46-4.58.6-8.48,1.67-11.6,2.76Z"
        />
        <path
          style={styles.eight}
          d="m458.47,532.45l-1.05,4.6c-11.91,2.27-38.25,9.11-58.68,32.08-6.1,6.86-10.49,13.82-13.66,20.03-.75,1.71-1.5,3.41-2.25,5.12-.33.22-.88.55-1.62.76-4.88,1.41-8.22-4.9-20.43-15.09-4.58-3.82-9.05-7.06-9.78-12.65-.11-.84-.11-1.55-.09-2.03,6.18-9.3,16.31-22.24,31.79-34.36,16.34-12.79,32.21-19.63,43.04-23.34,2.29,1.19,4.59,2.37,6.88,3.56-8.68,4.41-19.93,10.25-33.1,17.43,0,0-17.54,9.56-29.61,21-3.24,3.07-5.13,5.99-5.66,6.83-1.54,2.45-2.5,4.66-3.08,6.22-.52.34-1.16.87-1.57,1.7-1.56,3.17,1.86,7.66,5.32,12.18,1.18,1.55,3.84,4.84,8.17,7.43,1.82,1.09,2.89,1.43,3.99,1.23,3.27-.58,4.58-5.51,4.7-5.98,6.72-8.51,18.55-21.32,36.78-31.84,15.53-8.96,29.98-12.96,39.91-14.88Z"
        />
        <path
          style={styles.eight}
          d="m564.16,527.99l66.36,40.75c.55.61,3.6,4.1,3.13,9.1-.36,3.81-2.56,6.24-3.46,7.22-1.35,1.45-3.05,3.27-5.73,3.45-3.49.22-5.91-2.52-6.26-2.92-25.93-17.19-51.86-34.38-77.79-51.56l-8.26,1.19c6.29,4.48,29.57,21,51.26,35.29,25.72,16.95,17.91,10.15,24.62,15.07,2.02,1.48,6.75,5.05,13.94,7.38,3.12,1.01,5.81,1.5,7.65,1.75,1.85-1.32,5.06-4.02,7.34-8.52,3.5-6.94,2.43-13.52,1.99-15.67-2.15-2.26-5.51-5.58-9.99-9.14-6.29-5.01-9.83-6.58-20.24-13.16-7.37-4.66-6.7-4.57-16.22-10.75-8.02-5.21-14.9-9.43-20.07-12.53-2.75,1.03-5.51,2.05-8.26,3.08Z"
        />
        <path
          style={styles.seven}
          d="m426.93,507.58c-13.04,3.98-34.17,12.42-54.02,30.9-9.8,9.12-16.88,18.45-21.91,26.35-.13.55-.28,1.36-.26,2.35.08,5.28,4.65,8.54,11.15,14.09,11.77,10.04,15.52,15.35,19.53,13.86.92-.34,1.55-.93,1.92-1.34.53-1.59,1.1-3.13,1.73-4.62,13.23-31.53,49.02-45.73,72.52-52.16.8-3.76,1.6-7.52,2.4-11.28-5.54-2.2-11.7-5.02-18.2-8.66-5.62-3.14-10.57-6.38-14.86-9.49Z"
        />
        <path
          style={styles.seven}
          d="m433.01,511.75c-10,4.06-18.1,8.08-23.96,11.21-2.67,1.42-15.57,8.38-30.13,19.37-5.25,3.96-10.02,7.93-14.67,14.09-1.21,1.6-2.25,3.14-3.13,4.53-1.62,2.58-1.88,5.82-.57,8.57.8,1.67,1.81,3.47,3.11,5.29,3.7,5.21,8.13,8.44,11.47,10.37,1.39.8,3.18.39,4.02-.98,1.21-1.96,2.5-3.91,3.89-5.85,21.13-29.42,54.06-41.04,75.9-45.99"
        />
        <path
          style={styles.seven}
          d="m448.69,575.42c-2.04,1.12-7.71,4.6-9,10.79-.57,2.72-.1,5.08.74,7.99,3.25,11.19,8.37,18.37,8.37,18.37,6.59,12.67,12.98,24.94,24.54,35.72,6.06,5.65,18.33,15.37,38.43,20.62.71.18,1.44.25,2.17.22,1.29-.06,2.95-.25,4.79-.76,6.89-1.88,12.26-8.09,12.26-8.09,6.37-11.28,11.6-25.75,10.17-42-.1-1.15-.23-2.28-.39-3.39-.88-4.08-3.11-11.76-9.26-19.3-7.32-8.98-16.1-12.75-20.09-14.19"
        />
        <path
          style={styles.seven}
          d="m480.87,696.89c-.58-4.68-1.9-11.61-5.3-19.37-1.75-3.99-3.06-5.92-4.3-8.41-2.77-5.53-5.47-14.21-4.35-27.55,6.27,8.01,18.39,26.09,15.55,47.78-.35,2.64-.89,5.16-1.59,7.55-8.5-15.09-18.87-31.45-31.52-48.33-20.67-27.57-42.03-49.34-60.98-66.11"
        />
        <line
          style={styles.seven}
          x1="478.25"
          y1="692.31"
          x2="478.25"
          y2="797.65"
        />
        <line
          style={styles.seven}
          x1="473.09"
          y1="683.62"
          x2="473.09"
          y2="800.59"
        />
        <path
          style={styles.seven}
          d="m456.63,658.57c.44,50.66.87,101.32,1.31,151.98"
        />
        <line
          style={styles.seven}
          x1="450.19"
          y1="649.69"
          x2="450.19"
          y2="816.83"
        />
        <path
          style={styles.seven}
          d="m450.19,755.39c-.87.82-2.44,2.11-4.76,2.93-1.81.65-3.42.78-4.5.78l-57.72-1.86c-.61-.22-1.51-.64-2.35-1.47-1.81-1.78-1.94-4.09-1.96-4.7v-29.93c-.05-.19-.39-1.64.59-2.93.93-1.24,2.34-1.36,2.54-1.37,22.72-1.7,45.43-3.39,68.15-5.09"
        />
        <path
          style={styles.seven}
          d="m386.15,716.46l-.98,28.52c.02.58.2,3.34,2.48,5.45,1.71,1.59,3.65,1.93,4.37,2.02h43.6c.25.04,4.34.63,6.94-2.41,1.75-2.04,1.72-4.44,1.7-5.06v-33.22"
        />
        <path
          style={styles.seven}
          d="m571.23,525.39c12.23,6.34,21.59,12.14,27.99,16.35,6.74,4.44,6.71,4.82,13.3,9,8.58,5.44,13.46,7.86,19.96,13.5,2.9,2.52,5.09,4.78,6.46,6.26.41,2.49.77,6.76-.59,11.74-.93,3.41-2.35,6.04-3.52,7.83-.45,1.1-1.26,2.54-2.74,3.52-5.7,3.78-16.07-2.79-26.83-9.54-2.11-1.32-32.26-20.01-32.26-20.01-13.62-9.61-27.24-19.23-40.86-28.84"
        />
        <path
          style={styles.seven}
          d="m605.26,584.05l-24.43,65.65c-2.89,8.5-6.71,14.27-9.78,18.04-3.96,4.88-7.39,7.31-12.33,14.09-3.04,4.18-5.14,7.91-6.46,10.48-.6,2.5-1.09,5.36-1.31,8.53-.56,8.05.84,14.82,2.29,19.53l-6.85,9v-11.54c-1.8-5.62-4.42-16.61-1.57-29.74,1.4-6.46,3.75-11.66,5.87-15.46,1.15-3.26,2.66-8.24,3.52-14.48.42-3.06.6-5.8.73-16.45.05-4.32.1-10.04.08-16.81.27-7.48,1.51-18.39,6.03-30.72,4.63-12.62,10.94-21.91,15.66-27.82"
        />
        <path
          style={styles.seven}
          d="m543.65,702.97c.07-.31.73-2.97,3.33-4.12,1.93-.86,3.71-.34,4.17-.19"
        />
        <path
          style={styles.seven}
          d="m482.88,677.26l32.86,20.22c6.08-2.33,14.77-6.52,23.41-14.09,5.66-4.95,9.76-10.01,12.67-14.22"
        />
        <path
          style={styles.seven}
          d="m553.24,720.37l9.2-12.16c8.71-5.39,18.35-6.46,24.92-2.17,1.42.93,3.2,2.44,4.77,4.93.9,1.83,2.32,4.63,4.16,8.02,1.99,3.66,2.76,4.87,3.72,7.04.92,2.1,2.06,5.23,2.74,9.39.88.4,2.07,1.12,2.74,2.4.44.85,1.09,2.76-1.37,7.97-2.45,5.2-4.58,6.45-7.24,11.35-1.43,2.64-2.27,4.96-2.74,6.46-.37,2.74-.89,9.31,2.35,16.63,3.08,6.96,7.92,10.9,10.17,12.52"
        />
        <path d="m597.41,821.14c-3.47-2.39-6-5.7-9.55-7.93-3.52-2.1-7.6-3.08-11.56-3.98-8.99-1.65-11.4-1.89-18,4.89,0,0-2.69,3.17-2.69,3.17-4.17,4.7-8.23,11.95-15.58,11.22-5.06-.74-9.82-2.2-14.58-4.09-2.75-1.1-5.29-2.88-7.34-5.06-4.19-4.36-5.9-10.49-5.71-16.43-.06-5.75.68-11.51,2.19-17.06.79-2.85,1.6-6.04,3.96-8.12,2.03-1.94,4.78-2.98,7.58-3.04,6.75-.08,13.53.46,20.19,1.59,5.37,1.26,10.16-1.61,11.31-7.08,1.46-5.32,2.18-10.84,2.14-16.35.02-1.23-.11-2.55-.59-3.71-.21-.61-.58-1.27-.83-1.86-1.61-3.64-2.79-7.45-3.45-11.38-1.04-8.29,3.21-19.99,10.77-24.02,9.08-4.79,21.69-3.57,26.48,6.49-6.6-8.4-16.44-9.91-25.65-4.87-1.2.61-2.17,1.37-3,2.38-3.49,4.18-5.81,9.31-6.34,14.69-.74,6.14,1.82,12.18,4.32,17.69.88,2.11.79,4.48.74,6.72-.17,5.78-1.02,11.51-2.83,17.02-2.07,5.42-7.97,8.11-13.5,6.8,0,0-2.06-.31-2.06-.31-5.49-.88-11.07-1.21-16.63-1.21-2.5-.11-5.03.58-6.86,2.36-1.96,1.76-2.48,4.41-3.2,6.89-1.77,6.62-2.46,13.61-1.85,20.43.63,6.57,5,12.59,11.17,15.07,4.35,1.78,9.11,3.26,13.76,4,6.53.52,9.69-6.18,13.66-10.31,0,0,2.8-3.24,2.8-3.24,2.09-2.08,4.29-4.06,6.93-5.43,2.07-1.02,4.53-1.19,6.76-.91,7.73.92,16.19,2.55,22.09,7.97,2.05,2.01,4.28,4.06,4.97,7h0Z" />
        <path d="m558.52,798.73c-.48,18.59-21.31,29.47-35.05,15.32-10.18-10.7-7.27-29.81,6.59-35.87,14.28-6.33,28.92,5.68,28.45,20.55h0Zm-1.57,0c-.25-16.7-19.48-26.53-31.26-13.32-6.5,7.36-6.84,19.41-.3,26.91,11.82,13.58,32.11,3.67,31.56-13.59h0Z" />
        <path d="m591.72,717.81c-7.78-5.07-21.31-11.02-25.29,1.27-2.79,9.46.33,21.05,8.41,27.03,8.79,7.1,20.06,5.33,25.06-5.08.9-1.86,1.56-3.86,2-5.92l.77.11c-2.31,20.09-22.46,23.36-34.58,8.64-6.46-8.27-8.78-23.81-.4-31.54,7.44-5.97,18.52-.7,24.04,5.48h0Z" />
        <ellipse
          style={styles.seven}
          cx="582.07"
          cy="730.96"
          rx="9.83"
          ry="13.48"
          transform="translate(-234.71 277.12) rotate(-22.41)"
        />
        <path d="m582.07,826.82c.24-2-.13-3.98-.96-5.6,0,0,.61.66.61.66-1.59.21-2.97-.04-4.33-.38-4.08-1.18-8.21-2.01-12.36-2.98,0,0,.83-.37.83-.37-6.1,7.52-13.63,14.18-22.21,18.66-13.69,1.37-27.81-3.84-37.3-13.8,0,0-.23-.23-.23-.23,0,0-.05-.32-.05-.32-1.88-13.44-.99-27.26,2.58-40.36.98-2.64,2.64-5.03,4.8-6.85,2.2-1.84,4.89-3.17,7.72-3.62,0,0,7.28.32,7.28.32,7.12.32,14.35,1.65,21.26-.67,0,0-.66.64-.66.64,2.71-7.93,3.17-16.59,1.13-24.73-.95-2.31-2.15-5.79-3.29-8.45-1.31-2.86-2.11-6.33-1.48-9.51,0,0,1.97.33,1.97.33-.55,2.96.15,5.67,1.36,8.42,1.19,2.87,2.27,5.82,3.39,8.74,2.18,8.66,1.62,17.93-1.34,26.34-7.27,2.56-14.98,1.28-22.44.89,0,0-7.21-.31-7.21-.31-4,.74-9.01,4.35-10.59,9.13-3.38,12.34-4.37,26.4-2.49,39.42,0,0-.27-.56-.27-.56,9.56,10.12,23.22,14.18,35.28,13.32,8.27-4.66,15.62-10.94,21.51-18.4,3.16-.36,6.36-.36,9.51.18,3.26.57,6.73,1.14,8.95,3.93.3,2.19-.07,4.36-.96,6.17h0Z" />
        <path d="m548.26,796.08c4.21,12.16-7.78,20.74-18.22,13.55-5.48-3.91-8.23-11.78-4.85-17.88,5.67-10.08,19.98-5.4,23.07,4.33h0Zm-2.17.7c-1.48-5.26-7.1-9.56-12.63-8.57-9.49,2.04-8.6,14.09-1.43,18.51,7.53,4.92,16.67-.88,14.06-9.94h0Z" />
        <path d="m525.69,791.31c1.54-3.4,4.42-6,7.69-7.16-2.99,1.78-5.07,4.71-5.81,7.83l-1.88-.67h0Z" />
        <path d="m543.61,807.62c3.79-.21,7.06-3.13,8.06-6.83-.22,1.97-1.01,3.92-2.36,5.52-1.35,1.6-3.26,2.76-5.37,3.28l-.33-1.97h0Z" />
        <path d="m600.92,819.52c-2.22-4.78-3.22-10.12-2.34-15.4,1.62-8.67,7.57-15.29,16.89-14.44,8.13.57,13.34,8.53,13.74,16.11-.85-8.67-8.14-16.55-17.27-13.91-2.15.67-3.85,2.29-5.28,3.97-3.95,4.26-5.66,10.1-5.12,15.88.15,2.37.59,4.75,1.25,7.07l-1.86.72h0Z" />
        <path d="m612.37,814.55c-2.8-2.44-5.13-6.69-3.43-10.51.89-1.89,2.79-3.64,4.99-3.93,5.13-.42,9.34,5.29,7.25,10,.27-4.28-2.94-7.76-7.04-7.54-2.82-.17-4.89,2.65-4.68,5.39.1,2.37,1.28,4.7,2.91,6.58h0Z" />
        <path d="m611.06,802.1c-.05-3.02,2.89-4.32,5.47-4.15,0,0-.81.25-.81.25-1.5.49-3.27,1.85-2.74,3.57,0,0-1.93.33-1.93.33h0Z" />
        <path
          style={styles.seven}
          d="m602.44,748.87c.87-.96,2.26-1.02,3.03-.33.58.52.77,1.41.49,2.25-1.53,1.7-4.24,5.18-5.67,10.37-1.47,5.31-.88,9.78-.43,12.03.22,1.36.92,4.59,3.56,7.63,2.79,3.21,6.12,4.33,7.43,4.7.4.04,3.68.4,5.09,3.03.4.75.54,1.49.59,2.08"
        />
        <path d="m621.18,792.59c3.25-16.72,4.85-33.79,4.75-50.89.76,17.08-.17,34.27-2.78,51.24l-1.97-.34h0Z" />
        <path d="m653.79,652.36c.11,31.82-3.09,63.67-8.63,94.99-1.1,6.21-2.44,12.64-4.31,18.66-2.68,9.06-4.17,18.55-5.77,27.89,0,0-1.57,9.41-1.57,9.41.76-9.52,1.8-19.02,3.5-28.43.58-3.13,1.21-6.29,2.13-9.37,3.58-12.14,5.45-24.71,7.44-37.25,3.7-25.11,5.31-50.52,5.21-75.89,0,0,2,0,2,0h0Z" />
        <path
          style={styles.seven}
          d="m415.5,608.59c-15.17-.23-42.23,1.47-71.54,15.71-17.83,8.66-30.95,19.27-39.81,27.75-1.27,4.66-2.52,11.15-2.46,18.94.06,7.23,1.24,13.28,2.46,17.74,1.27.43,2.54.87,3.81,1.3l3.65-1.83c-1.81-3.7-4.67-10.83-4.43-20.09.15-6.11,1.6-11.05,2.87-14.35,8.22-7.76,19.67-16.84,34.7-24.52,30.42-15.55,59.05-16.95,74.7-16.48-1.31-1.4-2.63-2.79-3.94-4.19Z"
        />
        <path d="m325.09,641.41c16.21,24.14,26.09,52.44,29.56,81.25-4.49-21.18-11.5-41.76-21.12-61.14-3.12-6.48-6.59-12.79-10.14-19.05,0,0,1.7-1.06,1.7-1.06h0Z" />
        <path
          style={styles.seven}
          d="m335.35,661.97c20.78,1.01,41.56,2.02,62.34,3.03l52.49-9.39"
        />
        <line
          style={styles.seven}
          x1="422.31"
          y1="615.89"
          x2="349.36"
          y2="627.01"
        />
        <path d="m373.66,812.72c-.04-24.16-4.34-48.05-13.09-70.61,11.01,21.58,15.65,46.52,13.09,70.61h0Z" />
        <path d="m320.52,753.68c-1.72,7.98-.92,16.15-.14,24.21-.65-1.95-1.15-3.95-1.49-5.98-1.01-6.1-.81-12.36-.35-18.51,0,0,1.98.28,1.98.28h0Z" />
        <path d="m310.52,755.26c-.2,4.38-.16,8.72-1,13.06-.84-4.33-.8-8.68-1-13.06,0,0,2,0,2,0h0Z" />
        <path
          style={styles.seven}
          d="m299.18,756.47l-12.62-18.95c-9.82-22.15-5.94-42,4.3-47.61.46-.25,1.25-.65,2.37-.99,2.77-.86,7.61-1.43,14.72,1.13,1.47-.57,3.57-1.33,6.13-2.09,8.87-2.63,11.24-1.67,12.09-1.26.92.45,2.24,1.33,3.44,3.22,1.36,1.84,16.43,22.82,7.83,45-3.43,8.85-9.33,14.53-13.19,17.62-8.43,1.82-16.63,2.12-25.06,3.94Z"
        />
        <path
          style={styles.seven}
          d="m295.97,688.34c-2.25.62-3.23,3.01-2.49,4.57.63,1.32,2.5,2.1,4.3,1.43,1.85-1.31,6.21-3.99,12-3.78,1.81.06,3.39.4,4.68.79,1.89.9,3.39,1.95,4.58,2.99,9.22,8.06,7.74,22.25,7.43,24.78-3.26,12.15-6.8,20.37-9.13,25.17-.99,2.04-2.22,4.39-4.56,6.79-4.65,4.74-10.55,4.79-13.94,5.38"
        />
        <path d="m311.61,699.57c3.08.28,4.21,4.52,2.29,6.7-.59.73-1.42,1.25-2.29,1.39,2.85-2.17,2.51-5.9,0-8.09h0Z" />
        <path d="m317.35,716.26c1.78,1.98,3,5.04,1.82,7.65-.57,1.27-2.23,1.57-3.36.96-.5-.24-.95-.54-1.33-.92,1.03.17,2.1.34,2.78.05.57-.26.84-1.04.9-1.94.13-1.92-.41-3.86-.82-5.81h0Z" />
        <path d="m309.99,735.64c.7-.39,1.64-.34,2.43.1.78.44,1.43,1.33,1.45,2.4.02,1.05-.51,2.02-1.32,2.53-.8.51-1.87.56-2.57-.02.74-.5,1.12-.92,1.43-1.34.3-.41.53-.81.58-1.21.05-.38-.07-.87-.41-1.33-.33-.47-.86-.86-1.6-1.14Z" />
        <line
          style={styles.seven}
          x1="404.83"
          y1="663.72"
          x2="407.35"
          y2="715.22"
        />
        <path d="m378.82,815.11c-1.06-9.76,2.38-19.3,6.36-28.04-3.88,8.56-4.89,18.84-6.36,28.04h0Z" />
        <path d="m409.12,757.96c2.12,23.63,4.28,47.27,5.39,70.98-2.42-17.64-4.05-35.36-5.78-53.08-.58-5.9-1.08-11.81-1.6-17.72l1.99-.18h0Z" />
        <path d="m431.74,833.66c19.78-23.39,47.02-40.34,76.6-48.22,0,0,.54,1.93.54,1.93-29.16,8.51-55.79,24.7-77.14,46.29h0Z" />
        <path d="m507.82,793.1c-25.52,9.89-49.05,24.34-70.01,41.9,14.52-14.68,31.78-26.59,50.29-35.7,6.19-3.04,12.54-5.74,19.02-8.07l.7,1.87h0Z" />
        <path d="m513.16,828.12c-8.57,4.56-17.15,9.14-26.16,12.77,4-2.78,8.21-5.2,12.45-7.58,4.21-2.4,8.46-4.73,12.78-6.96,0,0,.94,1.77.94,1.77h0Z" />
        <path d="m520.69,832.56c-4.59,3.12-9.58,5.58-14.58,8.01,3.24-2.64,6.57-5.13,9.99-7.5,1.14-.78,2.31-1.52,3.52-2.2l1.07,1.69h0Z" />
        <path d="m566.06,817.68c-.98,5.01-2,10.03-3.54,14.91.15-5.11.84-10.19,1.56-15.24,0,0,1.97.33,1.97.33h0Z" />
        <path d="m573.71,818.51c-.79,3.92-1.52,7.85-2.54,11.72,0-4.01.31-7.99.56-11.98,0,0,1.98.26,1.98.26h0Z" />
        <path d="m611.61,671.65c12.58,5.89,26.7,8.08,40.4,5.68,0,0,.41,1.96.41,1.96-13.8,3.54-29.29.78-40.82-7.63h0Z" />
        <polygon points="633.43 679.03 629.74 719 631.43 678.9 633.43 679.03 633.43 679.03" />
        <path
          style={styles.seven}
          d="m586.31,634.95c2.35,9.12,4.46,19.16,6.06,30.05,2.85,19.43,3.4,37.03,2.88,52.07"
        />
        <path
          style={styles.seven}
          d="m590.26,624.35c2.94,11.08,5.59,23.29,7.59,36.54,3.9,25.74,4.39,48.94,3.42,68.48"
        />
        <line
          style={styles.seven}
          x1="590.56"
          y1="623.55"
          x2="648.85"
          y2="648.33"
        />
        <line
          style={styles.seven}
          x1="592.72"
          y1="617.74"
          x2="640.43"
          y2="640.11"
        />
        <polyline
          style={styles.seven}
          points="600.62 596.51 628.96 610.16 623.54 626"
        />
        <line
          style={styles.seven}
          x1="626.35"
          y1="594.69"
          x2="620.57"
          y2="606.12"
        />
        <path
          style={styles.seven}
          d="m624.83,558.43c3.66-.94,7.76-1.79,12.24-2.41,3.12-.43,6.07-.7,8.84-.85l9.78,16.04c-.32,1.74-.74,3.6-1.3,5.54-1.21,4.21-2.77,7.81-4.3,10.76-.18.48-.43,1.19-.68,2.05,0,0-.56,1.92-.88,4.21-.32,2.27-.56,7.53-.26,14.48-.03.54-.15,1.3-.68,1.62-.8.48-2.08-.3-2.48-.54-2.16-1.28-9.89-5.12-20.86-10.48"
        />
        <path
          style={styles.seven}
          d="m540.41,534.01c20.07,14.71,41.61,29.35,64.7,43.61,5.24,3.24,10.47,6.4,15.66,9.48,2.06,1.22,4.61,1.34,6.74.24,1.58-.82,3.27-2.11,4.45-4.12,2.57-4.39,1.7-10.51-2.38-14.9-.4-.43-.87-.78-1.37-1.09-21.35-13.08-42.7-26.17-64.05-39.25"
        />
        <path
          style={styles.seven}
          d="m633.45,565.1c1.33-.62,3.22-1.32,5.58-1.55,1.05-.1,2-.09,2.84-.03.86.71,1.82,1.65,2.74,2.87,2.11,2.82,2.84,5.7,3.13,7.43-.66,3.89-1.7,8.36-3.33,13.21-1.37,4.09-2.92,7.69-4.44,10.79-1.14-.11-2.41-.3-3.77-.62-2.7-.64-4.91-1.61-6.59-2.51"
        />
        <path
          style={styles.seven}
          d="m562.68,676.86c.59,3.91,1.07,8.18,1.32,12.79.33,6.06.22,11.61-.13,16.57"
        />
        <line
          style={styles.seven}
          x1="569.65"
          y1="669.39"
          x2="570.95"
          y2="704.4"
        />
        <path d="m589.7,740.7c3.31.34,6.55-2.16,7.21-5.52-.04,3.82-3.28,7.19-7.18,7.52,0,0-.03-2-.03-2h0Z" />
        <path d="m572.26,721.96c-.61-4.07,2.79-8.14,6.87-7.92-1.82.3-3.45,1.34-4.32,2.8-.88,1.38-1.05,3.21-.59,4.71,0,0-1.96.4-1.96.4h0Z" />
        <path
          style={styles.seven}
          d="m542.23,680.54c-1.23-1.91-3.25-5.66-3.38-10.65-.05-1.89.18-3.54.49-4.89.03-.2.34-2.27,1.96-2.93.24-.1.59-.2,1.04-.2,1.44,2.47,2.92,5.54,4.04,9.2.42,1.36.75,2.67,1,3.91"
        />
        <path
          style={styles.seven}
          d="m542.35,661.87c-.82-1.56-1.71-3.63-2.32-6.16-.42-1.74-.61-3.32-.68-4.67,1.34-1.6,2.68-3.21,4.02-4.81,2.13,2.43,4.44,5.41,6.64,8.98,1.44,2.33,2.62,4.57,3.59,6.65-.48,2.06-.97,4.11-1.45,6.17-.32.21-1.92,1.2-4,.77-1.75-.36-2.76-1.49-3.05-1.85"
        />
      </g>
    </g>
  );
};

export default KiritoCoat;
