const Crown = () => {
  const styles = {
    one: {
      fill: "#ddb303",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#fece00",
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#30a020",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#ffe36c",
    },
    five: {
      fill: "#4db537",
    },
    six: {
      fill: "#ffe36c",
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="m312.08,228.66c11.13,12.56,24.05,29.54,35.59,51.27,15.75,29.64,23.06,57.08,26.68,76.98,43.45-1.76,93.92-6.73,149.34-17.73,48.46-9.62,91.5-22.02,128.53-34.85l.53-24.07c1.36-1.28,7.31-7.18,7.81-16.69.4-7.53-2.85-12.98-4.08-14.84,1.36-12.61,3.68-26.88,7.56-42.34,3.2-12.77,6.95-24.29,10.8-34.46-1-.03-2.37-.17-3.92-.66-1.28-.41-2.32-.94-3.08-1.41-1.68,6.4-5.03,15.99-12.16,25.68-1.85,2.52-27.49,36.42-57.11,29.89-22.99-5.06-41.77-32.9-38.53-66.34-1.77-.04-3.54-.09-5.31-.13-.77,6.6-2.47,15.88-6.58,26.27-2.28,5.76-21.27,52.12-51.85,52.46-16.33.18-28.76-12.85-39.52-24.13-14.48-15.18-21.06-31.51-24.13-41.3-.61.43-1.45.92-2.53,1.26-2.55.8-4.79.23-5.75-.08,2.26,9.79,4.84,27,.19,47.3-2.7,11.77-7.59,33.12-21.65,41.48-19.49,11.59-56.14-2.05-85.95-37.33-.54.72-1.38,1.67-2.61,2.53-.82.58-1.61.97-2.27,1.23Z"
      />
      <circle style={styles.two} cx="427.08" cy="161.97" r="11.71" />
      <circle style={styles.two} cx="370.47" cy="196.41" r="11.71" />
      <circle style={styles.two} cx="308.12" cy="217.28" r="11.71" />
      <circle style={styles.two} cx="488.05" cy="155.91" r="11.71" />
      <circle style={styles.two} cx="557.71" cy="147.21" r="11.71" />
      <circle style={styles.two} cx="610.73" cy="151.24" r="11.71" />
      <circle style={styles.two} cx="674.12" cy="159.76" r="11.71" />
      <path
        style={styles.one}
        d="m376.91,265.01c2.24-5.99,5.34-16.62,4.57-30.07-.69-12.07-4.22-21.43-6.83-27.01.65-.05,2.18-.27,3.55-1.44.59-.5.98-1.04,1.23-1.47,4.08,6.04,10.27,13.82,19.25,21.33,7.46,6.24,14.7,10.49,20.54,13.37-.39,1.92-3.46,15.67-16.93,22.87-11.77,6.28-23.13,3.1-25.37,2.43Z"
      />
      <path
        style={styles.one}
        d="m485.45,167.33c-.38,8.22-1.26,17.4-2.97,27.32-2.29,13.24-5.54,24.86-8.93,34.62,1.59,1.3,13.76,10.92,29.93,7.49,12.91-2.74,19.85-12,21.46-14.27-5.54-4.49-14.05-12.53-21-24.9-6.93-12.34-9.36-23.76-10.32-30.82-.91.28-2.01.55-3.28.69-1.95.22-3.63.08-4.88-.13Z"
      />
      <path
        style={styles.one}
        d="m604.66,161.89c-.53,4.43-1.44,9.26-2.9,14.38-5.1,17.85-14.6,30.66-21.8,38.56,2.9,2.81,8.66,7.56,17.23,9.84,15.85,4.22,28.94-3.31,31.66-4.95-3.96-4.41-9.78-12.02-13.61-22.89-5.11-14.5-3.98-27.15-2.97-33.48-1.04-.05-2.2-.17-3.44-.39-1.58-.28-2.98-.66-4.17-1.06Z"
      />
      <ellipse
        style={styles.three}
        cx="468.18"
        cy="297.98"
        rx="11.22"
        ry="18.11"
        transform="translate(-30.36 54.17) rotate(-6.41)"
      />
      <ellipse
        style={styles.three}
        cx="375.86"
        cy="310.72"
        rx="12.55"
        ry="20.66"
        transform="translate(-77.31 130.55) rotate(-17.9)"
      />
      <ellipse
        style={styles.three}
        cx="562.16"
        cy="279.62"
        rx="11.59"
        ry="16.83"
        transform="translate(-37.03 92.04) rotate(-9.06)"
      />
      <ellipse
        style={styles.three}
        cx="650.62"
        cy="264.27"
        rx="16.46"
        ry="9.77"
        transform="translate(320.85 884.27) rotate(-84.1)"
      />
      <path
        style={styles.four}
        d="m370.47,339.03c46.39.77,104.92-2.71,170.68-17.63,42.76-9.7,80.15-22.34,111.67-35.19v5.13c-28.2,11.39-60.37,22.35-96.26,31.35-70.62,17.71-134.02,22.4-184.7,22.05-.46-1.91-.92-3.81-1.38-5.72Z"
      />
      <ellipse
        style={styles.six}
        cx="302.9"
        cy="218.91"
        rx="2.84"
        ry="3.91"
        transform="translate(-68.88 180.78) rotate(-30)"
      />
      <ellipse
        style={styles.five}
        cx="368.41"
        cy="308.34"
        rx="2.84"
        ry="5.4"
        transform="translate(-59.79 90.53) rotate(-12.97)"
      />
      <ellipse
        style={styles.five}
        cx="463.1"
        cy="295.82"
        rx="2.84"
        ry="5.4"
        transform="translate(-54.57 111.46) rotate(-12.97)"
      />
      <ellipse
        style={styles.five}
        cx="557.41"
        cy="278.69"
        rx="2.84"
        ry="5.4"
        transform="translate(-17.33 37.77) rotate(-3.82)"
      />
      <ellipse
        style={styles.five}
        cx="646.82"
        cy="262.84"
        rx="5.4"
        ry="2.84"
        transform="translate(210.37 807.79) rotate(-73.42)"
      />
      <ellipse
        style={styles.six}
        cx="365.71"
        cy="197.91"
        rx="2.84"
        ry="3.91"
        transform="translate(-49.96 209.37) rotate(-30)"
      />
      <ellipse style={styles.six} cx="421.86" cy="162.57" rx="2.84" ry="3.91" />
      <ellipse style={styles.six} cx="482.15" cy="155.24" rx="2.84" ry="3.91" />
      <ellipse
        style={styles.six}
        cx="551.67"
        cy="145.84"
        rx="3.91"
        ry="2.84"
        transform="translate(296.69 656.32) rotate(-78.27)"
      />
      <ellipse
        style={styles.six}
        cx="604.89"
        cy="150.26"
        rx="3.91"
        ry="2.84"
        transform="translate(334.75 711.95) rotate(-78.27)"
      />
      <ellipse
        style={styles.six}
        cx="668.67"
        cy="159.76"
        rx="3.91"
        ry="2.84"
        transform="translate(376.26 781.97) rotate(-78.27)"
      />
      <path d="m455.87,223.56c27.13,38.67,82.33,25.98,92.09-19.58,0,0-.56,3.8-.56,3.8-.78,3.78-1.84,7.51-3.46,11.04-16.81,37.32-67.22,39.55-88.07,4.74h0Z" />
      <path d="m554.74,187.61c.93,16.97,8.73,33.73,22.33,44.14,25,19.81,56.67,8.19,71.85-17.69-1.34,2.52-2.74,5.03-4.43,7.34-1.6,2.3-3.53,4.63-5.5,6.62-34.64,35.16-83.81,4.43-84.24-40.4h0Z" />
      <path d="m436.57,205.35c2.48,14.82.45,30.05-4.28,44.25-4.47,14.69-13.98,28.25-30.17,30.72-7.41,1.3-15.18.12-22.48-1.29-22.14-5.1-40.19-21.17-52.64-39.61,15.13,20.92,37.33,38.3,63.9,39.84,21.06,1.98,34.17-10.88,40.13-30.08,4.98-14.01,7.26-28.99,5.54-43.83h0Z" />
    </g>
  );
};

export default Crown;
