import { useContext, useEffect, useRef, useState } from "react";
import {
  clearNotifications,
  getNotifications,
  markAllRead,
} from "../../api/notifications";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_NOTI_COUNT,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import usePagination from "../../utils/usePagination";
import { NOTIFICATIONS_LIMIT } from "../../utils/limits";
import CustomMenu from "../custom/CustomMenu";
import { Grid, Typography } from "@mui/material";
import {
  emptyIcon,
  normal,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import NotSelected from "../custom/NotSelected";
import SecondaryButton from "../custom/SecondaryButton";
import NotificationItem from "./NotificationItem";
import NotificationGlimmer from "../glimmers/NotificationGlimmer";
import NotificationSettings from "./NotificationSettings";
import CustomIconButton from "../custom/CustomIconButton";
import { BiEnvelopeOpen } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { MdOutlineNotificationsNone } from "react-icons/md";
import Empty from "../custom/Empty";

const Notifications = ({ anchor, handleClose }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [notis, setNotis] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const [markLoading, setMarkLoading] = useState(false);
  const [settingsAnchor, setSettingsAnchor] = useState(null);

  const handleGetNotis = () => {
    getNotifications(middleware, skip).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.notifications?.length >= NOTIFICATIONS_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setNotis(
          notis ? [...notis, ...res?.notifications] : res?.notifications
        );
      }
    });
  };

  const setNewNotis = (newNotis) => {
    setNotis([...newNotis]);
  };

  const handleMarkNotis = () => {
    setMarkLoading(true);
    markAllRead(middleware).then((res) => {
      setMarkLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setNotis(null);
        setLoading(true);
        setSettingsAnchor(null);
      }
    });
  };

  const handleClearNotis = () => {
    setClearLoading(true);
    clearNotifications(middleware).then((res) => {
      setClearLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setNotis(null);
        updateStore({
          type: SET_SUCCESSES,
          payload: "Cleared notifications!",
        });
      }
    });
  };

  const onClose = () => {
    handleClose();
    setSkip(0);
    setHasMore(false);
    setHovered(false);
    setClearLoading(false);
    setMarkLoading(false);
  };

  usePagination(bottom, () => setSkip(notis?.length));

  useEffect(() => {
    if (anchor) {
      if (notis == null && loading) {
        handleGetNotis();
      }
    }
  }, [anchor, loading]);

  useEffect(() => {
    if (skip !== 0) {
      handleGetNotis();
    }
  }, [skip]);

  useEffect(() => {
    if (anchor) {
      updateStore({ type: SET_NOTI_COUNT, payload: 0 });

      if (notis == null) {
        setLoading(true);
      }
    } else {
      setNotis(null);
    }
  }, [anchor, updateStore]);

  const styles = {
    width: {
      width: "100%",
    },
    container: {
      width: "100%",
      marginTop: 2,
    },
    label: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 700,
    },
  };

  return (
    <>
      <CustomMenu
        anchor={anchor}
        handleClose={onClose}
        title="Notifications"
        minWidth={360}
        leftAddOn={
          !notis || notis?.length < 1 ? null : (
            <CustomIconButton
              icon={<FiTrash2 style={{ color: text, fontSize: 20 }} />}
              label="Delete All"
              loading={clearLoading}
              onClick={handleClearNotis}
            />
          )
        }
        rightAddOn={
          notis?.length < 1 ||
          !notis ||
          notis?.findIndex((notif) => !notif?.read) === -1 ? null : (
            <CustomIconButton
              icon={<BiEnvelopeOpen style={{ color: text, fontSize: 20 }} />}
              label="Mark All as Read"
              onClick={handleMarkNotis}
              loading={markLoading}
            />
          )
        }
      >
        <Grid item sx={styles.container}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            {!loading && notis?.length > 0 && (
              <Grid item sx={{ paddingLeft: 2 }}>
                <Typography sx={styles.label}>MOST RECENT</Typography>
              </Grid>
            )}

            {loading && <NotificationGlimmer numItems={3} />}

            {!loading && (notis?.length < 1 || !notis) && (
              <Empty
                icon={<MdOutlineNotificationsNone style={emptyIcon} />}
                title="No notifications"
                label="Notifications will appear here."
              />
            )}

            {!loading && notis && notis?.length > 0 && (
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={{ xs: 1 }}
                >
                  {notis?.map((noti, i) => (
                    <NotificationItem
                      noti={noti}
                      key={i}
                      setNotifications={setNewNotis}
                      notifications={notis}
                      onClose={onClose}
                    />
                  ))}
                  {hasMore && (
                    <div style={{ width: "100%" }} ref={bottom}>
                      <NotificationGlimmer numItems={1} />
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CustomMenu>
    </>
  );
};

export default Notifications;
