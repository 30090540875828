import { Grid, Skeleton } from "@mui/material";
import { cardVeryLight, modalShadow } from "../../utils/themeContstants";

const OngoingGlimmer = ({ numItems }) => {
  const array = Array.apply(null, { length: numItems }).map(
    Number.call,
    Number
  );

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 1 }}
      >
        {array.map((_, i) => (
          <Grid item sx={styles.width} key={i}>
            <Skeleton
              variant="rectangular"
              animation="pulse"
              sx={{
                bgcolor: cardVeryLight,
                height: 57.5,
                width: "100%",
                borderRadius: 1,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default OngoingGlimmer;
