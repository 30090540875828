import { Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { FaCrown } from "react-icons/fa";
import { StoreContext } from "../../store/Store";
import { getReadableDateString } from "../../utils/helpers";
import {
  cardDark,
  cardLight,
  modalShadow,
  normal,
  offWhite,
  small,
  text,
} from "../../utils/themeContstants";
import CustomModal from "../custom/CustomModal";
import RivoxCoin from "../custom/RivoxCoin";
import SecondaryButton from "../custom/SecondaryButton";
import CancelModal from "./CancelModal";

const SubscriptionDetailsModal = ({ open, onClose }) => {
  const store = useContext(StoreContext);

  const [cancelOpen, setCancelOpen] = useState(false);

  const handleClose = () => {
    setCancelOpen(false);
    onClose();
  };

  const handleCancel = () => {};

  const styles = {
    width: {
      width: "100%",
    },
    label: {
      fontSize: small,
      fontWeight: 800,
      color: offWhite,
    },
    value: {
      fontSize: normal,
      color: text,
      fontWeight: 700,
    },
    description: {
      fontSize: normal,
      color: offWhite,
      fontWeight: 500,
      maxWidth: 500,
    },
  };

  return (
    <>
      <CancelModal
        open={cancelOpen}
        onClose={() => setCancelOpen(false)}
        closeModal={handleClose}
      />

      <CustomModal
        title="Subscription Details"
        open={open}
        onClose={handleClose}
        backgroundColor={cardLight}
        primaryButton={
          store?.user?.premium_info?.renewing ? (
            <SecondaryButton
              label="Unsubscribe"
              onClick={() => setCancelOpen(true)}
            />
          ) : null
        }
      >
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 2 }}
        >
          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.label}>PLAN</Typography>
              </Grid>

              <Grid
                item
                sx={{
                  padding: 2,
                  backgroundColor: cardDark,
                  width: "100%",
                  borderRadius: 1,
                }}
              >
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 2 }}
                >
                  <Grid
                    item
                    alignSelf="center"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 3,
                      height: 70,
                      width: 70,
                      borderRadius: 1,
                      backgroundColor: "#6634D6",
                      boxShadow: modalShadow,
                    }}
                  >
                    <FaCrown
                      style={{
                        fontSize: 35,
                        color: text,
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.value}>1 month</Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          columnGap={{ xs: 1 }}
                        >
                          <Grid item>
                            <Typography sx={styles.description}>
                              {store?.user?.premium_info?.renewing
                                ? "Renews at"
                                : "Expires"}
                            </Typography>
                          </Grid>

                          {store?.user?.premium_info?.renewing && (
                            <>
                              <RivoxCoin size={14} />

                              <Grid item sx={{ marginLeft: -0.5 }}>
                                <Typography sx={styles.description}>
                                  2.99
                                </Typography>
                              </Grid>
                            </>
                          )}

                          <Grid item>
                            <Typography sx={styles.description}>
                              on{" "}
                              {getReadableDateString(
                                new Date(
                                  store?.user?.premium_info?.expiration_date
                                )
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <Typography sx={styles.label}>BENEFITS</Typography>
              </Grid>

              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.value}>
                          10 "View Opponents"
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={styles.description}>
                          During VIP, you will receive 10 "View Opponents"
                          options which allows you to see a team before you
                          accept the match
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.value}>Tipping</Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={styles.description}>
                          Enjoy no fee tipping as a VIP user!
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.value}>
                          Faster Submit Timers
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={styles.description}>
                          Speed up your matches with 3 minute submit timers
                          rather than the normal 10 minutes
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.value}>
                          Two Free Avatar Pieces
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={styles.description}>
                          Two random avatar pieces up to the "Rare" rarity will
                          be added to your locker
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.value}>
                          Expedited Withdrawals
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={styles.description}>
                          Withdrawals made by VIP members will be received
                          within a maximum of 24 hours
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.value}>
                          Weekly Giveaways
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={styles.description}>
                          Weekly giveaways in our Discord for VIP members
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography sx={styles.value}>
                          Priority Support
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={styles.description}>
                          Benefit from having your tickets in our Discord
                          answered first
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default SubscriptionDetailsModal;
