import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { IoColorPalette } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Piece from "../../avatar/Piece";
import { StoreContext } from "../../store/Store";
import { AvatarPiecePriceEnum, AvatarRarityEnum } from "../../utils/enums";
import {
  canChangePieceColor,
  hexToRgbA,
  numFormatter,
} from "../../utils/helpers";
import {
  blue,
  emojiYellow,
  green,
  menuShadow,
  pink,
  purple,
  red,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";
import RivoxCoin from "../custom/RivoxCoin";

const ShopItem = ({ item, onClick }) => {
  const store = useContext(StoreContext);
  const isDesktop = useMediaQuery("(min-width:1025px)");
  const navigate = useNavigate();

  const [hovered, setHovered] = useState(false);
  const [owned, setOwned] = useState(false);
  const [canChangeColor, setCanChangeColor] = useState(false);

  const getBorderColor = () => {
    if (owned) {
      return getBackground();
    }

    switch (item?.rarity) {
      case AvatarRarityEnum.COMMON:
        return "#D7D9E7";
      case AvatarRarityEnum.UNCOMMON:
        return green;
      case AvatarRarityEnum.RARE:
        return blue;
      case AvatarRarityEnum.LEGENDARY:
        return purple;
      case AvatarRarityEnum.EXOTIC:
        return "#fb8b24";
      case AvatarRarityEnum.VIP:
        return red;
      case AvatarRarityEnum.GODLIKE:
        return pink;
      case AvatarRarityEnum.EMOJI:
        return emojiYellow;
    }
  };

  const getBackground = () => {
    switch (item?.rarity) {
      case AvatarRarityEnum.COMMON:
        return `rgba(215, 217, 231, ${owned ? "0.15" : "0.3"})`;
      case AvatarRarityEnum.UNCOMMON:
        return `rgba(21, 226, 164, ${owned ? "0.15" : "0.3"})`;
      case AvatarRarityEnum.RARE:
        return `rgba(21, 117, 226, ${owned ? "0.15" : "0.3"})`;
      case AvatarRarityEnum.LEGENDARY:
        return `rgba(112, 82, 221, ${owned ? "0.15" : "0.3"})`;
      case AvatarRarityEnum.EXOTIC:
        return `rgba(251, 139, 36, ${owned ? "0.15" : "0.3"})`;
      case AvatarRarityEnum.VIP:
        return `rgba(255, 84, 101, ${owned ? "0.15" : "0.3"})`;
      case AvatarRarityEnum.GODLIKE:
        return `rgba(255, 0, 110, ${owned ? "0.15" : "0.3"})`;
      case AvatarRarityEnum.EMOJI:
        return hexToRgbA(emojiYellow, owned ? 0.15 : 0.3);
    }
  };

  const getCoinAmount = () => {
    switch (item?.rarity) {
      case AvatarRarityEnum.COMMON:
        return AvatarPiecePriceEnum.COMMON;
      case AvatarRarityEnum.UNCOMMON:
        return AvatarPiecePriceEnum.UNCOMMON;
      case AvatarRarityEnum.RARE:
        return AvatarPiecePriceEnum.RARE;
      case AvatarRarityEnum.LEGENDARY:
        return AvatarPiecePriceEnum.LEGENDARY;
      case AvatarRarityEnum.EXOTIC:
        return AvatarPiecePriceEnum.EXOTIC;
      case AvatarRarityEnum.GODLIKE:
        return AvatarPiecePriceEnum.GODLIKE;
      case AvatarRarityEnum.EMOJI:
        return AvatarPiecePriceEnum.EMOJI;
      default:
        return null;
    }
  };
  const getCoinAmountSale = () => {
    switch (item?.rarity) {
      case AvatarRarityEnum.COMMON:
        return AvatarPiecePriceEnum.COMMON;
      case AvatarRarityEnum.UNCOMMON:
        return AvatarPiecePriceEnum.UNCOMMON;
      case AvatarRarityEnum.RARE:
        return AvatarPiecePriceEnum.RARE;
      case AvatarRarityEnum.LEGENDARY:
        return AvatarPiecePriceEnum.LEGENDARY;
      case AvatarRarityEnum.EXOTIC:
        return AvatarPiecePriceEnum.EXOTIC;
      case AvatarRarityEnum.GODLIKE:
        return AvatarPiecePriceEnum.GODLIKE;
      case AvatarRarityEnum.EMOJI:
        return AvatarPiecePriceEnum.EMOJI;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (store?.user) {
      if (
        store?.user?.new_locker?.options[item?.category]?.includes(item?._id)
      ) {
        setOwned(true);
      }
    }
  }, [store?.user]);

  useEffect(() => {
    if (item) {
      return setCanChangeColor(
        canChangePieceColor(item?.category, item?.value)
      );
    }
  }, [item]);

  const styles = {
    container: {
      borderRadius: 1.5,
      border: `3px solid ${getBorderColor()}`,
      boxSizing: "border-box",
      background: getBackground(),
      transition: transition,
      cursor: hovered ? "pointer" : "cursor",
      transform: hovered ? "translateY(-5px)" : null,
      boxShadow: hovered ? menuShadow : null,
      position: "relative",
    },
    wordContainer: {
      width: "100%",
      padding: 1,
      backgroundColor: getBackground(),
      marginTop: "auto",
      position: "relative",
    },
    name: {
      fontSize: 18,
      color: "#fff",
      fontWeight: 800,
      textAlign: "center",
      opacity: owned ? 0.4 : 1,
    },
    type: {
      fontSize: 12,
      color: getBorderColor(),
      fontWeight: 800,
      textAlign: "center",
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        if (!store?.user) {
          navigate("/login");
        } else {
          onClick(item);
        }
      }}
    >
      {!owned && !item?.has_been_released && (
        <Grid
          item
          sx={{
            position: "absolute",
            top: -10,
            left: -10,
            border: `2px solid #fff`,
            borderRadius: 1.5,
            backgroundColor: red,
            padding: 1,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              color: text,
              fontWeight: 800,
            }}
          >
            NEW!
          </Typography>
        </Grid>
      )}

      <Grid item sx={{ position: "absolute", top: 5, right: 10 }}>
        <Typography sx={styles.type}>
          {item?.category?.toUpperCase()}
        </Typography>
      </Grid>

      <Grid
        container
        direction="column"
        alignItems={isDesktop ? "start" : "center"}
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {canChangeColor && (
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                bottom: 5,
                left: 5,
              }}
            >
              <IoColorPalette
                style={{ fontSize: 22, color: getBorderColor() }}
              />
            </Grid>
          )}

          <Piece
            type={item?.category}
            color={
              store?.user?.new_avatar?.options[`${item?.category}Color`] ??
              "#191919"
            }
            option={item?.value}
            skinColor={
              store?.user?.new_avatar?.options?.skinColor ?? "tanned_skin"
            }
            size={200}
            owned={owned}
          />
        </Grid>

        <Grid item sx={styles.wordContainer}>
          {item?.variants?.length > 0 && (
            <Grid
              item
              sx={{
                position: "absolute",
                top: -34,
                right: 4,
                padding: 1,
                paddingTop: 0.5,
                paddingBottom: 0.5,
                borderRadius: 1.5,
                backgroundColor: "rgba(7, 24, 38, 0.3)",
                boxSizing: "border-box",
                border: `1px solid ${secondaryText}`,
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  color: secondaryText,
                  fontWeight: 800,
                }}
              >
                1 of {item?.variants?.length + 1}
              </Typography>
            </Grid>
          )}

          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography sx={styles.name}>{item?.name}</Typography>
            </Grid>

            {owned && (
              <Grid item>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  gap={{ xs: 0.5 }}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "#fff",
                        fontWeight: 800,
                      }}
                    >
                      Owned
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AiOutlineCheck style={{ fontSize: 18, color: green }} />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {!owned && (
              <Grid item>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  gap={{ xs: 0.5 }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <RivoxCoin size={22} />
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "#fff",
                        fontWeight: 800,
                      }}
                    >
                      {numFormatter().format(getCoinAmountSale())}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: red,
                        fontWeight: 800,
                        textDecoration: "line-through",
                      }}
                    >
                      <span style={{ color: secondaryText }}>
                        {numFormatter().format(getCoinAmount() * 1.5)}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShopItem;
