export const TEAMS_LIMIT = 10;
export const MATCH_LIMIT = 8;
export const HOME_MATCH_LIMIT = 5;
export const HOME_PAGE_ONGOING_MATCHES_LIMIT = 10;
export const HOME_ONGOING_LIMIT = 6;
export const MATCH_MESSAGE_LIMIT = 25;
export const NOTIFICATIONS_LIMIT = 8;
export const MATCH_HISTORY_LIMIT = 10;
export const TRANSACTION_LIMIT = 10;
export const DISPUTES_LIMIT = 25;
export const PENDING_WITHDRAWAL_LIMIT = 50;
export const STREAMS_LIMIT = 4;
export const AC_USERS_LIMIT = 10;
export const FLAGS_LIMIT = 100;
