const Guitar = () => {
  const styles = {
    one: {
      fill: "#dbb37d",
      opacity: 0.54,
    },
    two: {
      fill: "#d8d8d8",
    },
    three: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    four: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 0.75,
    },
    five: {
      fill: "#3067f2",
    },
    six: {
      fill: "#f9c569",
    },
    seven: {
      fill: "#f9e7cd",
    },
    eight: {
      fill: "#6193f7",
    },
  };

  return (
    <g>
      <g>
        <g>
          <path
            style={styles.five}
            d="M416.5,559.5c.82,3.67,1,6.78,1,9,0,4.46-.74,6.38-1,7-.87,2.08-2.1,3.55-3,4.44,.67,2.04,1.33,4.07,2,6.1l-41.32,21-.05-.09-1.48-4.11,16.18-6.03-8.46-22.72-15.96,5.94-1.48-4.1,6.57-2.43-12.87-33.62c3.48,4.31,6.09,7.98,7.87,10.62,2.1,3.11,5.23,8,9,11,2.31,1.85,4.76,2.9,5,3,.96,.41,1.85,.78,3,1,3.96,.74,7.39-1.12,9-2,1.26-.69,3.15-1.91,5-4,1.57-1.56,3.32-3.54,5-6,2.53-3.7,4.06-7.24,5-10,1.01,.31,2.47,.89,4,2,.79,.57,1.94,1.54,3,3,.56,.77,1.15,1.75,2,4,.64,1.68,1.41,4.04,2,7Z"
          />
          <polygon
            style={styles.seven}
            points="380.37 574.09 388.83 596.81 372.65 602.84 371.57 599.86 381.7 596.09 375.53 579.53 365.57 583.24 364.41 580.03 380.37 574.09"
          />
          <polygon
            style={styles.two}
            points="375.53 579.53 381.7 596.09 371.57 599.86 365.57 583.24 375.53 579.53"
          />
          <polygon
            style={styles.five}
            points="368.58 604.36 372.65 602.84 374.13 606.95 374.18 607.04 366.13 611.14 364.31 605.94 368.58 604.36"
          />
          <polygon
            style={styles.seven}
            points="372.65 602.84 368.58 604.36 367.51 601.37 371.57 599.86 372.65 602.84"
          />
          <polygon
            style={styles.two}
            points="365.57 583.24 371.57 599.86 367.51 601.37 361.58 584.73 365.57 583.24"
          />
          <polygon
            style={styles.nine}
            points="356.63 539.88 369.5 573.5 362.93 575.93 359.49 566.42 361.1 565.84 359.99 562.74 358.38 563.32 355.27 554.71 356.74 554.18 355.63 551.08 354.15 551.61 350.95 542.76 351.46 542.58 350.07 538.72 349.56 538.9 346.09 529.29 337 504.11 282.2 352.34 284.5 351.5 356.62 539.87 356.63 539.88"
          />
          <polygon
            style={styles.seven}
            points="367.51 601.37 368.58 604.36 364.31 605.94 363.27 602.95 367.51 601.37"
          />
          <polygon
            style={styles.two}
            points="367.51 601.37 363.27 602.95 357.44 586.27 361.58 584.73 367.51 601.37"
          />
          <polygon
            style={styles.five}
            points="358.9 607.96 364.31 605.94 366.13 611.14 357.44 615.55 355.28 609.31 358.9 607.96"
          />
          <polygon
            style={styles.seven}
            points="364.41 580.03 365.57 583.24 361.58 584.73 360.44 581.52 364.41 580.03"
          />
          <polygon
            style={styles.five}
            points="364.41 580.03 360.44 581.52 358.97 577.4 362.93 575.93 364.41 580.03"
          />
          <polygon
            style={styles.seven}
            points="363.27 602.95 364.31 605.94 358.9 607.96 357.88 604.96 363.27 602.95"
          />
          <polygon
            style={styles.two}
            points="357.44 586.27 363.27 602.95 357.88 604.96 352.15 588.24 357.44 586.27"
          />
          <polygon
            style={styles.nine}
            points="359.49 566.42 362.93 575.93 358.97 577.4 355.56 567.83 359.49 566.42"
          />
          <polygon
            style={styles.seven}
            points="360.44 581.52 361.58 584.73 357.44 586.27 356.3 583.06 360.44 581.52"
          />
          <polygon
            style={styles.seven}
            points="359.99 562.74 361.1 565.84 359.49 566.42 358.38 563.32 359.99 562.74"
          />
          <polygon
            style={styles.five}
            points="358.97 577.4 360.44 581.52 356.3 583.06 354.83 578.93 358.97 577.4"
          />
          <rect
            style={styles.seven}
            x="354.88"
            y="563.93"
            width="4.18"
            height="3.29"
            transform="translate(-169.76 153.44) rotate(-19.7)"
          />
          <polygon
            style={styles.nine}
            points="355.56 567.83 358.97 577.4 354.83 578.93 351.4 569.33 355.56 567.83"
          />
          <polygon
            style={styles.seven}
            points="357.88 604.96 358.9 607.96 355.28 609.31 354.25 606.31 357.88 604.96"
          />
          <polygon
            style={styles.nine}
            points="355.27 554.71 358.38 563.32 354.45 564.73 351.38 556.11 355.27 554.71"
          />
          <polygon
            style={styles.two}
            points="352.15 588.24 357.88 604.96 354.25 606.31 348.49 589.6 352.15 588.24"
          />
          <polygon
            style={styles.seven}
            points="356.3 583.06 357.44 586.27 352.15 588.24 351.03 585.02 356.3 583.06"
          />
          <polygon
            style={styles.five}
            points="357.44 615.55 356.04 616.26 353.79 609.86 355.28 609.31 357.44 615.55"
          />
          <polygon
            style={styles.seven}
            points="355.63 551.08 356.74 554.18 355.27 554.71 354.15 551.61 355.63 551.08"
          />
          <polygon
            style={styles.five}
            points="356.3 583.06 351.03 585.02 349.6 580.87 354.83 578.93 356.3 583.06"
          />
          <path
            style={styles.five}
            d="M353.79,609.86l2.25,6.4-27.87,14.17c-.89-2.97-1.78-5.95-2.67-8.93-7.67-8.67-15.33-17.33-23-26-.89-1.22-2.19-3.25-3-6-1.92-6.52,.25-12.32,2-17,.96-2.56,2.17-5.71,5-9,1.19-1.38,2.97-3.41,6-5,1.15-.6,4.99-2.55,10-2,1.67,.18,3.84,.42,6,2,2.95,2.15,3.79,5.16,4,6,.97-.29,1.94-.57,2.91-.86l7.09,19.86,1.78-.66,1.46,4.15-10.12,3.77,8.46,22.72,9.71-3.62Z"
          />
          <polygon
            style={styles.seven}
            points="354.45 564.73 355.56 567.83 351.4 569.33 350.29 566.22 354.45 564.73"
          />
          <polygon
            style={styles.seven}
            points="354.25 606.31 355.28 609.31 353.79 609.86 352.74 606.88 354.25 606.31"
          />
          <polygon
            style={styles.seven}
            points="354.15 551.61 355.27 554.71 351.38 556.11 350.28 553 354.15 551.61"
          />
          <polygon
            style={styles.nine}
            points="354.83 578.93 349.6 580.87 346.24 571.18 351.4 569.33 354.83 578.93"
          />
          <polygon
            style={styles.nine}
            points="351.38 556.11 354.45 564.73 350.29 566.22 347.22 557.6 351.38 556.11"
          />
          <polygon
            style={styles.two}
            points="348.49 589.6 354.25 606.31 352.74 606.88 346.87 590.21 348.49 589.6"
          />
          <polygon
            style={styles.nine}
            points="350.95 542.76 354.15 551.61 350.28 553 347.12 544.14 350.95 542.76"
          />
          <polygon
            style={styles.seven}
            points="353.79 609.86 344.08 613.48 335.62 590.76 345.74 586.99 346.87 590.21 344.52 591.08 350.69 607.64 352.74 606.88 353.79 609.86"
          />
          <polygon
            style={styles.two}
            points="346.87 590.21 352.74 606.88 350.69 607.64 344.52 591.08 346.87 590.21"
          />
          <polygon
            style={styles.seven}
            points="351.03 585.02 352.15 588.24 348.49 589.6 347.37 586.38 351.03 585.02"
          />
          <polygon
            style={styles.seven}
            points="350.29 566.22 351.4 569.33 346.24 571.18 345.17 568.06 350.29 566.22"
          />
          <polygon
            style={styles.seven}
            points="350.28 553 351.38 556.11 347.22 557.6 346.12 554.5 350.28 553"
          />
          <polygon
            style={styles.five}
            points="349.6 580.87 351.03 585.02 347.37 586.38 345.94 582.23 349.6 580.87"
          />
          <polygon
            style={styles.seven}
            points="349.56 538.9 350.95 542.76 347.12 544.14 345.74 540.28 349.56 538.9"
          />
          <polygon
            style={styles.nine}
            points="347.22 557.6 350.29 566.22 345.17 568.06 342.17 559.42 347.22 557.6"
          />
          <polygon
            style={styles.nine}
            points="350.28 553 346.12 554.5 342.96 545.64 347.12 544.14 350.28 553"
          />
          <polygon
            style={styles.nine}
            points="346.24 571.18 349.6 580.87 345.94 582.23 337.44 557.59 338.84 557.11 339.96 560.21 342.17 559.42 345.17 568.06 343.2 568.77 344.32 571.87 346.24 571.18"
          />
          <polygon
            style={styles.nine}
            points="346.09 529.29 349.56 538.9 345.74 540.28 342.31 530.65 345.89 529.36 346.09 529.29"
          />
          <polygon
            style={styles.seven}
            points="348.49 589.6 346.87 590.21 345.74 586.99 347.37 586.38 348.49 589.6"
          />
          <polygon
            style={styles.five}
            points="345.94 582.23 347.37 586.38 345.74 586.99 344.28 582.84 345.94 582.23"
          />
          <polygon
            style={styles.seven}
            points="346.12 554.5 347.22 557.6 342.17 559.42 341.09 556.3 346.12 554.5"
          />
          <polygon
            style={styles.seven}
            points="345.74 540.28 347.12 544.14 342.96 545.64 341.58 541.77 345.74 540.28"
          />
          <polygon
            style={styles.seven}
            points="345.17 568.06 346.24 571.18 344.32 571.87 343.2 568.77 345.17 568.06"
          />
          <polygon
            style={styles.nine}
            points="342.96 545.64 346.12 554.5 341.09 556.3 338.02 547.42 342.96 545.64"
          />
          <polygon
            style={styles.nine}
            points="337 504.11 346.09 529.29 345.89 529.36 344.5 525.5 340.93 526.78 333.32 505.44 336.26 504.38 337 504.11"
          />
          <polygon
            style={styles.nine}
            points="337.44 557.59 345.94 582.23 344.28 582.84 265.29 358.49 265.29 358.48 268.37 357.37 281.59 395.7 291.53 424.5 328.37 531.29 337.44 557.59"
          />
          <polygon
            style={styles.seven}
            points="344.5 525.5 345.89 529.36 342.31 530.65 340.93 526.78 344.5 525.5"
          />
          <polygon
            style={styles.nine}
            points="342.31 530.65 345.74 540.28 341.58 541.77 338.15 532.15 342.31 530.65"
          />
          <polygon
            style={styles.nine}
            points="344.28 582.84 342.5 583.5 335.41 563.64 262.5 359.5 263.04 359.3 265.29 358.49 344.28 582.84"
          />
          <polygon
            style={styles.seven}
            points="341.58 541.77 342.96 545.64 338.02 547.42 336.68 543.54 341.58 541.77"
          />
          <polygon
            style={styles.seven}
            points="340.93 526.78 342.31 530.65 338.15 532.15 336.77 528.28 340.93 526.78"
          />
          <polygon
            style={styles.seven}
            points="341.09 556.3 342.17 559.42 339.96 560.21 338.84 557.11 341.09 556.3"
          />
          <polygon
            style={styles.nine}
            points="338.15 532.15 341.58 541.77 336.68 543.54 333.33 533.88 338.15 532.15"
          />
          <polygon
            style={styles.nine}
            points="338.02 547.42 341.09 556.3 338.84 557.11 337.44 557.59 328.37 531.29 329.27 530.98 330.66 534.84 333.33 533.88 336.68 543.54 334.84 544.2 336.23 548.06 338.02 547.42"
          />
          <polygon
            style={styles.nine}
            points="333.32 505.44 340.93 526.78 336.77 528.28 329.16 506.93 333.32 505.44"
          />
          <polygon
            style={styles.seven}
            points="336.77 528.28 338.15 532.15 333.33 533.88 331.99 530 336.77 528.28"
          />
          <polygon
            style={styles.seven}
            points="336.68 543.54 338.02 547.42 336.23 548.06 334.84 544.2 336.68 543.54"
          />
          <polygon
            style={styles.nine}
            points="282.2 352.34 337 504.11 336.26 504.38 334.5 499.5 331.58 500.55 323.28 477.25 324.35 476.86 321.74 469.61 320.69 469.99 315.74 456.08 316.35 455.86 312.74 445.83 312.16 446.04 306.6 430.43 307.35 430.16 303.74 420.13 303.02 420.39 296.37 401.73 297.35 401.38 293.74 391.35 292.79 391.69 284.84 369.39 285.7 369.08 282.09 359.05 281.26 359.35 279.16 353.44 282.2 352.34"
          />
          <polygon
            style={styles.nine}
            points="329.16 506.93 336.77 528.28 331.99 530 324.58 508.58 329.16 506.93"
          />
          <polygon
            style={styles.seven}
            points="334.5 499.5 336.26 504.38 333.32 505.44 331.58 500.55 334.5 499.5"
          />
          <polygon
            style={styles.seven}
            points="331.99 530 333.33 533.88 330.66 534.84 329.27 530.98 331.99 530"
          />
          <polygon
            style={styles.seven}
            points="331.58 500.55 333.32 505.44 329.16 506.93 327.42 502.04 331.58 500.55"
          />
          <polygon
            style={styles.nine}
            points="324.58 508.58 331.99 530 329.27 530.98 328.37 531.29 291.53 424.5 292.96 424.01 296.56 434.04 298.53 433.33 303.95 448.99 301.96 449.71 305.56 459.74 307.44 459.07 312.27 473.02 310.96 473.49 313.56 480.74 314.79 480.3 322.88 503.68 321.31 504.24 323.06 509.13 324.58 508.58"
          />
          <polygon
            style={styles.nine}
            points="323.28 477.25 331.58 500.55 327.42 502.04 319.12 478.74 323.28 477.25"
          />
          <polygon
            style={styles.seven}
            points="327.42 502.04 329.16 506.93 324.58 508.58 322.88 503.68 327.42 502.04"
          />
          <polygon
            style={styles.nine}
            points="319.12 478.74 327.42 502.04 322.88 503.68 314.79 480.3 319.12 478.74"
          />
          <polygon
            style={styles.seven}
            points="324.58 508.58 323.06 509.13 321.31 504.24 322.88 503.68 324.58 508.58"
          />
          <polygon
            style={styles.seven}
            points="320.69 469.99 323.28 477.25 319.12 478.74 316.53 471.49 320.69 469.99"
          />
          <polygon
            style={styles.nine}
            points="315.74 456.08 320.69 469.99 316.53 471.49 311.57 457.58 315.74 456.08"
          />
          <polygon
            style={styles.seven}
            points="319.12 478.74 314.79 480.3 312.27 473.02 316.53 471.49 319.12 478.74"
          />
          <polygon
            style={styles.nine}
            points="311.57 457.58 316.53 471.49 312.27 473.02 307.44 459.07 311.57 457.58"
          />
          <polygon
            style={styles.seven}
            points="315.74 456.08 311.57 457.58 308 447.54 312.16 446.04 315.74 456.08"
          />
          <polygon
            style={styles.seven}
            points="314.79 480.3 313.56 480.74 310.96 473.49 312.27 473.02 314.79 480.3"
          />
          <polygon
            style={styles.nine}
            points="306.6 430.43 312.16 446.04 308 447.54 302.43 431.93 306.6 430.43"
          />
          <polygon
            style={styles.seven}
            points="308 447.54 311.57 457.58 307.44 459.07 303.95 448.99 308 447.54"
          />
          <polygon
            style={styles.nine}
            points="308 447.54 303.95 448.99 298.53 433.33 302.43 431.93 308 447.54"
          />
          <polygon
            style={styles.seven}
            points="303.95 448.99 307.44 459.07 305.56 459.74 301.96 449.71 303.95 448.99"
          />
          <polygon
            style={styles.seven}
            points="306.6 430.43 302.43 431.93 298.85 421.89 303.02 420.39 306.6 430.43"
          />
          <polygon
            style={styles.nine}
            points="296.37 401.73 303.02 420.39 298.85 421.89 292.2 403.23 296.37 401.73"
          />
          <polygon
            style={styles.seven}
            points="298.85 421.89 302.43 431.93 298.53 433.33 295.04 423.26 298.85 421.89"
          />
          <polygon
            style={styles.nine}
            points="292.2 403.23 298.85 421.89 295.04 423.26 288.56 404.54 292.2 403.23"
          />
          <polygon
            style={styles.seven}
            points="295.04 423.26 298.53 433.33 296.56 434.04 292.96 424.01 295.04 423.26"
          />
          <path
            style={styles.two}
            d="M297.67,322.31c.57,1.6-.26,3.37-1.86,3.95-1.6,.57-3.37-.27-3.95-1.86l-1.79-4.98c-.57-1.6,.27-3.37,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.27,0,2.46,.79,2.92,2.04l1.79,4.98Z"
          />
          <polygon
            style={styles.seven}
            points="292.79 391.69 296.37 401.73 292.2 403.23 288.63 393.19 292.79 391.69"
          />
          <polygon
            style={styles.nine}
            points="288.56 404.54 295.04 423.26 292.96 424.01 291.53 424.5 281.59 395.7 282.96 395.23 286.56 405.26 288.56 404.54"
          />
          <polygon
            style={styles.nine}
            points="284.84 369.39 292.79 391.69 288.63 393.19 280.67 370.88 284.84 369.39"
          />
          <polygon
            style={styles.seven}
            points="288.63 393.19 292.2 403.23 288.56 404.54 285.07 394.47 288.63 393.19"
          />
          <path
            style={styles.two}
            d="M291.99,307.62c.58,1.59-.26,3.37-1.86,3.95-1.59,.57-3.37-.27-3.94-1.86l-1.79-4.98c-.58-1.6,.26-3.37,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.26,0,2.46,.79,2.91,2.04l1.79,4.98Z"
          />
          <rect
            style={styles.two}
            x="285.88"
            y="321.31"
            width="5.09"
            height="4.03"
            transform="translate(-92.25 116.42) rotate(-19.74)"
          />
          <polygon
            style={styles.nine}
            points="288.63 393.19 285.07 394.47 277.33 372.08 280.67 370.88 288.63 393.19"
          />
          <polygon
            style={styles.seven}
            points="285.07 394.47 288.56 404.54 286.56 405.26 282.96 395.23 285.07 394.47"
          />
          <path
            style={styles.six}
            d="M247.12,306.59c.24-.47,.38-1.02,.38-1.59,0-1.93-1.57-3.5-3.5-3.5s-3.5,1.57-3.5,3.5,1.57,3.5,3.5,3.5c1.36,0,2.53-.77,3.12-1.91Zm19.55-7.56c.24,.05,.49,.08,.74,.08,1.94,0,3.5-1.57,3.5-3.5s-1.56-3.5-3.5-3.5-3.5,1.57-3.5,3.5c0,1.68,1.19,3.08,2.76,3.42Zm1.86,12.48l6.47,43.44-3.2,1.17-18.8-37.74-1.92,.96c-.6-1.1-1.75-1.84-3.08-1.84-1.93,0-3.5,1.57-3.5,3.5,0,.36,.05,.71,.16,1.04l-1.36,.46-10.8-32,4-1,11.5-12.9,17.5,.9,4-1.28,18,55.28-4,17,1,3-2.3,.84-4.2-21.84c1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5-3.5,1.57-3.5,3.5c0,.24,.02,.48,.07,.7l-.74,.15-2.92-14.05c.58,.44,1.31,.7,2.09,.7,1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5-3.5,1.57-3.5,3.5c0,.12,.01,.24,.02,.36l-.99,.15Z"
          />
          <path
            style={styles.two}
            d="M285.99,290.71c.58,1.59-.26,3.37-1.86,3.94-1.59,.58-3.37-.26-3.94-1.86l-1.79-4.97c-.58-1.6,.26-3.38,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.27,0,2.46,.78,2.91,2.04l1.79,4.98Z"
          />
          <polygon
            style={styles.nine}
            points="277.33 372.08 285.07 394.47 282.96 395.23 281.59 395.7 268.37 357.37 268.37 357.36 271.8 356.12 273.84 362.01 271.3 362.92 274.91 372.95 277.33 372.08"
          />
          <polygon
            style={styles.seven}
            points="281.26 359.35 284.84 369.39 280.67 370.88 277.1 360.84 281.26 359.35"
          />
          <path
            style={styles.six}
            d="M278,330.5l4.2,21.84-3.04,1.1-5.33-25.59,.74-.15c.33,1.6,1.74,2.8,3.43,2.8Z"
          />
          <polygon
            style={styles.nine}
            points="279.16 353.44 281.26 359.35 277.1 360.84 275 354.96 275 354.95 279.16 353.44"
          />
          <path
            style={styles.seven}
            d="M278,323.5c1.93,0,3.5,1.57,3.5,3.5s-1.57,3.5-3.5,3.5c-1.69,0-3.1-1.2-3.43-2.8-.05-.22-.07-.46-.07-.7,0-1.93,1.57-3.5,3.5-3.5Z"
          />
          <polygon
            style={styles.seven}
            points="277.1 360.84 280.67 370.88 277.33 372.08 273.84 362.01 277.1 360.84"
          />
          <rect
            style={styles.two}
            x="274.92"
            y="289.4"
            width="3.79"
            height="4.03"
            transform="translate(-82.16 110.62) rotate(-19.74)"
          />
          <path
            style={styles.six}
            d="M273.83,327.85l5.33,25.59-4.16,1.51-6.47-43.44,.99-.15c.1,1,.62,1.87,1.39,2.44l2.92,14.05Z"
          />
          <polygon
            style={styles.seven}
            points="277.33 372.08 274.91 372.95 271.3 362.92 273.84 362.01 277.33 372.08"
          />
          <polygon
            style={styles.nine}
            points="277.1 360.84 273.84 362.01 271.8 356.12 275 354.96 277.1 360.84"
          />
          <path
            style={styles.seven}
            d="M273,307.5c1.93,0,3.5,1.57,3.5,3.5s-1.57,3.5-3.5,3.5c-.78,0-1.51-.26-2.09-.7-.77-.57-1.29-1.44-1.39-2.44-.01-.12-.02-.24-.02-.36,0-1.93,1.57-3.5,3.5-3.5Z"
          />
          <path
            style={styles.six}
            d="M253,318.38l18.8,37.74-3.43,1.24-11.02-19.36c.1-.32,.15-.65,.15-1,0-1.61-1.1-2.98-2.59-3.38l-4.15-10.48c.47-.59,.74-1.34,.74-2.14,0-.6-.15-1.17-.42-1.66l1.92-.96Z"
          />
          <path
            style={styles.seven}
            d="M267.41,292.11c1.94,0,3.5,1.57,3.5,3.5s-1.56,3.5-3.5,3.5c-.25,0-.5-.03-.74-.08-1.57-.34-2.76-1.74-2.76-3.42,0-1.93,1.57-3.5,3.5-3.5Z"
          />
          <path
            style={styles.six}
            d="M268.37,357.36l-3.08,1.12-9.68-17.98-.15-.33c.91-.41,1.61-1.2,1.89-2.17l11.02,19.36Z"
          />
          <path
            style={styles.six}
            d="M265.29,358.48l-2.25,.82-1.54-3.8-10-8.72-8.2-24.28,1.36-.46c.44,1.42,1.77,2.46,3.34,2.46,1.12,0,2.12-.53,2.76-1.36l4.15,10.48c-.29-.08-.6-.12-.91-.12-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5c.52,0,1.02-.11,1.46-.33l.15,.33,9.68,17.98Z"
          />
          <path
            style={styles.seven}
            d="M257.5,337c0,.35-.05,.68-.15,1-.28,.97-.98,1.76-1.89,2.17-.44,.22-.94,.33-1.46,.33-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5c.31,0,.62,.04,.91,.12,1.49,.4,2.59,1.77,2.59,3.38Z"
          />
          <path
            style={styles.seven}
            d="M251.08,319.34c.27,.49,.42,1.06,.42,1.66,0,.8-.27,1.55-.74,2.14-.64,.83-1.64,1.36-2.76,1.36-1.57,0-2.9-1.04-3.34-2.46-.11-.33-.16-.68-.16-1.04,0-1.93,1.57-3.5,3.5-3.5,1.33,0,2.48,.74,3.08,1.84Z"
          />
          <polygon
            style={styles.two}
            points="247.63 337.09 249 340.88 246.02 341.95 244.66 338.16 247.63 337.09"
          />
          <path
            style={styles.seven}
            d="M247.5,305c0,.57-.14,1.12-.38,1.59-.59,1.14-1.76,1.91-3.12,1.91-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5,3.5,1.57,3.5,3.5Z"
          />
          <path
            style={styles.two}
            d="M245.99,342.71c.58,1.59-.26,3.37-1.86,3.94-1.59,.58-3.37-.26-3.94-1.86l-1.79-4.97c-.58-1.6,.26-3.38,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.27,0,2.46,.78,2.91,2.04l1.79,4.98Z"
          />
          <rect
            style={styles.two}
            x="239.85"
            y="321.58"
            width="3.16"
            height="4.02"
            transform="translate(-95.29 100.84) rotate(-19.79)"
          />
          <path
            style={styles.two}
            d="M239.99,326.71c.58,1.59-.26,3.37-1.86,3.94-1.59,.58-3.37-.26-3.94-1.86l-1.79-4.97c-.58-1.6,.26-3.38,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.27,0,2.46,.78,2.91,2.04l1.79,4.98Z"
          />
          <rect
            style={styles.two}
            x="233.83"
            y="306.21"
            width="3.81"
            height="4.02"
            transform="translate(-90.42 98.01) rotate(-19.79)"
          />
          <path
            style={styles.two}
            d="M233.99,311.71c.58,1.59-.26,3.37-1.86,3.94-1.59,.58-3.37-.26-3.94-1.86l-1.79-4.97c-.58-1.6,.26-3.38,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.27,0,2.46,.78,2.91,2.04l1.79,4.98Z"
          />
        </g>
        <g>
          <polyline
            style={styles.three}
            points="265.29 358.49 263.04 359.3 262.5 359.5 335.41 563.64"
          />
          <line
            style={styles.three}
            x1="268.37"
            y1="357.37"
            x2="265.29"
            y2="358.48"
          />
          <line
            style={styles.three}
            x1="275"
            y1="354.96"
            x2="271.8"
            y2="356.12"
          />
          <path
            style={styles.three}
            d="M247.5,305c0,.57-.14,1.12-.38,1.59-.59,1.14-1.76,1.91-3.12,1.91-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5,3.5,1.57,3.5,3.5Z"
          />
          <path
            style={styles.three}
            d="M270.91,295.61c0,1.93-1.56,3.5-3.5,3.5-.25,0-.5-.03-.74-.08-1.57-.34-2.76-1.74-2.76-3.42,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5Z"
          />
          <path
            style={styles.three}
            d="M269.52,311.36c-.01-.12-.02-.24-.02-.36,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5-1.57,3.5-3.5,3.5c-.78,0-1.51-.26-2.09-.7-.77-.57-1.29-1.44-1.39-2.44Z"
          />
          <path
            style={styles.three}
            d="M274.57,327.7c-.05-.22-.07-.46-.07-.7,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5-1.57,3.5-3.5,3.5c-1.69,0-3.1-1.2-3.43-2.8Z"
          />
          <path
            style={styles.three}
            d="M254.91,333.62c1.49,.4,2.59,1.77,2.59,3.38,0,.35-.05,.68-.15,1-.28,.97-.98,1.76-1.89,2.17-.44,.22-.94,.33-1.46,.33-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5c.31,0,.62,.04,.91,.12Z"
          />
          <path
            style={styles.three}
            d="M251.08,319.34c.27,.49,.42,1.06,.42,1.66,0,.8-.27,1.55-.74,2.14-.64,.83-1.64,1.36-2.76,1.36-1.57,0-2.9-1.04-3.34-2.46-.11-.33-.16-.68-.16-1.04,0-1.93,1.57-3.5,3.5-3.5,1.33,0,2.48,.74,3.08,1.84Z"
          />
          <polygon
            style={styles.three}
            points="284.5 351.5 283.5 348.5 287.5 331.5 269.5 276.22 265.5 277.5 248 276.6 236.5 289.5 232.5 290.5 243.3 322.5 251.5 346.78 261.5 355.5 263.04 359.3 265.29 358.48 268.37 357.36 271.8 356.12 275 354.95 279.16 353.44 282.2 352.34 284.5 351.5"
          />
          <path
            style={styles.three}
            d="M284.5,351.5l72.12,188.37h.01c3.48,4.32,6.09,7.99,7.87,10.63,2.1,3.11,5.23,8,9,11,2.31,1.85,4.76,2.9,5,3,.96,.41,1.85,.78,3,1,3.96,.74,7.39-1.12,9-2,1.26-.69,3.15-1.91,5-4,1.57-1.56,3.32-3.54,5-6,2.53-3.7,4.06-7.24,5-10,1.01,.31,2.47,.89,4,2,.79,.57,1.94,1.54,3,3,.56,.77,1.15,1.75,2,4,.64,1.68,1.41,4.04,2,7,.82,3.67,1,6.78,1,9,0,4.46-.74,6.38-1,7-.87,2.08-2.1,3.55-3,4.44,.67,2.04,1.33,4.07,2,6.1l-41.32,21-8.05,4.1-8.69,4.41-1.4,.71-27.87,14.17c-.89-2.97-1.78-5.95-2.67-8.93-7.67-8.67-15.33-17.33-23-26-.89-1.22-2.19-3.25-3-6-1.92-6.52,.25-12.32,2-17,.96-2.56,2.17-5.71,5-9,1.19-1.38,2.97-3.41,6-5,1.15-.6,4.99-2.55,10-2,1.67,.18,3.84,.42,6,2,2.95,2.15,3.79,5.16,4,6,.97-.29,1.94-.57,2.91-.86l7.09,19.86,1.78-.66,1.66-.61,3.66-1.36,5.23-1.94,4.14-1.53,3.96-1.47,6.57-2.43-12.87-33.62"
          />
          <path
            style={styles.three}
            d="M295.81,326.26c-1.6,.57-3.37-.27-3.95-1.86l-1.79-4.98c-.57-1.6,.27-3.37,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.27,0,2.46,.79,2.92,2.04l1.79,4.98c.57,1.6-.26,3.37-1.86,3.95Z"
          />
          <path
            style={styles.three}
            d="M290.13,311.57c-1.59,.57-3.37-.27-3.94-1.86l-1.79-4.98c-.58-1.6,.26-3.37,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.26,0,2.46,.79,2.91,2.04l1.79,4.98c.58,1.59-.26,3.37-1.86,3.95Z"
          />
          <path
            style={styles.three}
            d="M284.13,294.65c-1.59,.58-3.37-.26-3.94-1.86l-1.79-4.97c-.58-1.6,.26-3.38,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.27,0,2.46,.78,2.91,2.04l1.79,4.98c.58,1.59-.26,3.37-1.86,3.94Z"
          />
          <path
            style={styles.three}
            d="M232.13,315.65c-1.59,.58-3.37-.26-3.94-1.86l-1.79-4.97c-.58-1.6,.26-3.38,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.27,0,2.46,.78,2.91,2.04l1.79,4.98c.58,1.59-.26,3.37-1.86,3.94Z"
          />
          <path
            style={styles.three}
            d="M238.13,330.65c-1.59,.58-3.37-.26-3.94-1.86l-1.79-4.97c-.58-1.6,.26-3.38,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.27,0,2.46,.78,2.91,2.04l1.79,4.98c.58,1.59-.26,3.37-1.86,3.94Z"
          />
          <path
            style={styles.three}
            d="M244.13,346.65c-1.59,.58-3.37-.26-3.94-1.86l-1.79-4.97c-.58-1.6,.26-3.38,1.86-3.95,.34-.12,.69-.18,1.03-.18,1.27,0,2.46,.78,2.91,2.04l1.79,4.98c.58,1.59-.26,3.37-1.86,3.94Z"
          />
          <polyline
            style={styles.four}
            points="247.12 306.59 253 318.38 271.8 356.12 273.84 362.01 277.33 372.08 285.07 394.47 288.56 404.54 295.04 423.26 298.53 433.33 303.95 448.99 307.44 459.07 312.27 473.02 314.79 480.3 322.88 503.68 324.58 508.58 331.99 530 333.33 533.88 336.68 543.54 338.02 547.42 341.09 556.3 342.17 559.42 345.17 568.06 346.24 571.18 349.6 580.87 351.03 585.02 352.15 588.24"
          />
          <polyline
            style={styles.four}
            points="266.67 299.03 268.53 311.51 275 354.95 275 354.96 277.1 360.84 280.67 370.88 288.63 393.19 292.2 403.23 298.85 421.89 302.43 431.93 308 447.54 311.57 457.58 316.53 471.49 319.12 478.74 327.42 502.04 329.16 506.93 336.77 528.28 338.15 532.15 341.58 541.77 342.96 545.64 346.12 554.5 347.22 557.6 350.29 566.22 351.4 569.33 354.83 578.93 356.3 583.06 357.44 586.27"
          />
          <polyline
            style={styles.four}
            points="278 330.5 282.2 352.34 337 504.11 346.09 529.29 349.56 538.9 350.95 542.76 354.15 551.61 355.27 554.71 358.38 563.32 359.49 566.42 362.93 575.93 364.41 580.03 365.57 583.24 371.57 599.86 372.65 602.84 374.13 606.95"
          />
          <polyline
            style={styles.four}
            points="270.91 313.8 273.83 327.85 279.16 353.44 281.26 359.35 284.84 369.39 292.79 391.69 296.37 401.73 303.02 420.39 306.6 430.43 312.16 446.04 315.74 456.08 320.69 469.99 323.28 477.25 331.58 500.55 333.32 505.44 340.93 526.78 342.31 530.65 345.74 540.28 347.12 544.14 350.28 553 351.38 556.11 354.45 564.73 355.56 567.83 358.97 577.4 360.44 581.52 361.58 584.73 367.51 601.37 368.58 604.36 369.5 606.95"
          />
          <line
            style={styles.four}
            x1="250.76"
            y1="323.14"
            x2="254.91"
            y2="333.62"
          />
          <polyline
            style={styles.four}
            points="255.61 340.5 265.29 358.48 265.29 358.49 344.28 582.84 345.74 586.99 346.87 590.21 352.74 606.88 353.79 609.86 356.04 616.26 356.23 616.8"
          />
          <polyline
            style={styles.three}
            points="257.35 338 268.37 357.36 268.37 357.37 281.59 395.7 291.53 424.5 328.37 531.29 337.44 557.59 345.94 582.23 347.37 586.38 348.49 589.6 354.25 606.31 355.28 609.31 357.44 615.55 357.44 615.56"
          />
          <polygon
            style={styles.three}
            points="364.41 580.03 380.37 574.09 388.83 596.81 372.65 602.84 368.58 604.36 364.31 605.94 358.9 607.96 355.28 609.31 353.79 609.86 344.08 613.48 335.62 590.76 345.74 586.99 347.37 586.38 351.03 585.02 356.3 583.06 360.44 581.52 364.41 580.03"
          />
          <polygon
            style={styles.three}
            points="365.57 583.24 375.53 579.53 381.7 596.09 371.57 599.86 367.51 601.37 363.27 602.95 357.88 604.96 354.25 606.31 352.74 606.88 350.69 607.64 344.52 591.08 346.87 590.21 348.49 589.6 352.15 588.24 357.44 586.27 361.58 584.73 365.57 583.24"
          />
          <polygon
            style={styles.three}
            points="249 340.88 246.02 341.95 244.66 338.16 247.63 337.09 249 340.88"
          />
          <rect
            style={styles.three}
            x="239.85"
            y="321.58"
            width="3.16"
            height="4.02"
            transform="translate(-95.39 100.98) rotate(-19.81)"
          />
          <rect
            style={styles.three}
            x="233.83"
            y="306.21"
            width="3.81"
            height="4.02"
            transform="translate(-90.34 97.87) rotate(-19.76)"
          />
          <rect
            style={styles.three}
            x="280.49"
            y="307.13"
            width="5.09"
            height="4.02"
            transform="translate(-87.82 113.84) rotate(-19.75)"
          />
          <rect
            style={styles.three}
            x="285.88"
            y="321.31"
            width="5.09"
            height="4.03"
            transform="translate(-92.3 116.5) rotate(-19.75)"
          />
          <rect
            style={styles.three}
            x="274.92"
            y="289.4"
            width="3.79"
            height="4.03"
            transform="translate(-82.11 110.53) rotate(-19.72)"
          />
          <polygon
            style={styles.three}
            points="281.26 359.35 282.09 359.05 285.7 369.08 284.84 369.39 280.67 370.88 277.33 372.08 274.91 372.95 271.3 362.92 273.84 362.01 277.1 360.84 281.26 359.35"
          />
          <polygon
            style={styles.three}
            points="292.79 391.69 293.74 391.35 297.35 401.38 296.37 401.73 292.2 403.23 288.56 404.54 286.56 405.26 282.96 395.23 285.07 394.47 288.63 393.19 292.79 391.69"
          />
          <polygon
            style={styles.three}
            points="303.02 420.39 303.74 420.13 307.35 430.16 306.6 430.43 302.43 431.93 298.53 433.33 296.56 434.04 292.96 424.01 295.04 423.26 298.85 421.89 303.02 420.39"
          />
          <polygon
            style={styles.three}
            points="312.16 446.04 312.74 445.83 316.35 455.86 315.74 456.08 311.57 457.58 307.44 459.07 305.56 459.74 301.96 449.71 303.95 448.99 308 447.54 312.16 446.04"
          />
          <polygon
            style={styles.three}
            points="320.69 469.99 321.74 469.61 324.35 476.86 323.28 477.25 319.12 478.74 314.79 480.3 313.56 480.74 310.96 473.49 312.27 473.02 316.53 471.49 320.69 469.99"
          />
          <polygon
            style={styles.three}
            points="336.26 504.38 333.32 505.44 329.16 506.93 324.58 508.58 323.06 509.13 321.31 504.24 322.88 503.68 327.42 502.04 331.58 500.55 334.5 499.5 336.26 504.38"
          />
          <polygon
            style={styles.three}
            points="345.89 529.36 342.31 530.65 338.15 532.15 333.33 533.88 330.66 534.84 329.27 530.98 331.99 530 336.77 528.28 340.93 526.78 344.5 525.5 345.89 529.36"
          />
          <polygon
            style={styles.three}
            points="349.56 538.9 350.07 538.72 351.46 542.58 350.95 542.76 347.12 544.14 342.96 545.64 338.02 547.42 336.23 548.06 334.84 544.2 336.68 543.54 341.58 541.77 345.74 540.28 349.56 538.9"
          />
          <polygon
            style={styles.three}
            points="354.15 551.61 355.63 551.08 356.74 554.18 355.27 554.71 351.38 556.11 347.22 557.6 342.17 559.42 339.96 560.21 338.84 557.11 341.09 556.3 346.12 554.5 350.28 553 354.15 551.61"
          />
          <polygon
            style={styles.three}
            points="358.38 563.32 359.99 562.74 361.1 565.84 359.49 566.42 355.56 567.83 351.4 569.33 346.24 571.18 344.32 571.87 343.2 568.77 345.17 568.06 350.29 566.22 354.45 564.73 358.38 563.32"
          />
          <polyline
            style={styles.three}
            points="352.15 588.24 357.88 604.96 358.9 607.96 360.32 612.09"
          />
          <polyline
            style={styles.three}
            points="357.44 586.27 363.27 602.95 364.31 605.94 366.13 611.14"
          />
        </g>
      </g>
      <path
        style={styles.eight}
        d="M364.91,561.5c9.88,2.58,16.82,6.57,21.09,9.5,5.1,3.5,7.28,6.11,8,7,4.69,5.84,6.07,12.16,6.55,15.78l-10.34,4.92-9.85-24.62-8.54,3.04-6.92-15.63Z"
      />
      <polygon
        style={styles.one}
        points="279.16 353.44 358.91 577.26 369.5 573.5 284.5 351.5 279.16 353.44"
      />
      <polygon
        style={styles.three}
        points="262.5 359.5 342.5 583.5 369.5 573.5 284.5 351.5 262.5 359.5"
      />
      <circle style={styles.three} cx="244" cy="305" r="3.5" />
      <circle style={styles.three} cx="267.41" cy="295.61" r="3.5" />
      <circle style={styles.three} cx="273" cy="311" r="3.5" />
      <circle style={styles.three} cx="278" cy="327" r="3.5" />
      <circle style={styles.three} cx="254" cy="337" r="3.5" />
      <circle style={styles.three} cx="248" cy="321" r="3.5" />
      <polygon
        style={styles.three}
        points="232.5 290.5 251.5 346.78 261.5 355.5 263.04 359.3 284.5 351.5 283.5 348.5 287.5 331.5 269.5 276.22 265.5 277.5 248 276.6 236.5 289.5 232.5 290.5"
      />
      <path
        style={styles.three}
        d="M328.17,630.43c-.89-2.98-1.78-5.96-2.67-8.93-7.67-8.67-15.33-17.33-23-26-.89-1.22-2.19-3.25-3-6-1.92-6.52,.25-12.32,2-17,.96-2.56,2.17-5.71,5-9,1.19-1.38,2.97-3.41,6-5,1.15-.6,4.99-2.55,10-2,1.67,.18,3.84,.42,6,2,2.95,2.15,3.79,5.16,4,6,.97-.29,1.94-.57,2.91-.86l7.09,19.86,27-10-12.88-33.63c3.48,4.31,6.09,7.99,7.88,10.63,2.1,3.11,5.23,8,9,11,2.31,1.85,4.76,2.9,5,3,.96,.41,1.85,.78,3,1,3.96,.74,7.39-1.12,9-2,1.26-.69,3.15-1.91,5-4,1.57-1.56,3.32-3.54,5-6,2.53-3.7,4.06-7.24,5-10,1.01,.31,2.47,.89,4,2,.79,.57,1.94,1.54,3,3,.56,.77,1.15,1.75,2,4,.64,1.68,1.41,4.04,2,7,.82,3.67,1,6.78,1,9,0,4.46-.74,6.38-1,7-.87,2.08-2.1,3.55-3,4.44,.67,2.03,1.33,4.07,2,6.1l-87.33,44.39Z"
      />
      <rect
        style={styles.three}
        x="290.79"
        y="315.14"
        width="6.17"
        height="11.46"
        rx="3.08"
        ry="3.08"
        transform="translate(-91.23 118.36) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="285.11"
        y="300.44"
        width="6.17"
        height="11.46"
        rx="3.08"
        ry="3.08"
        transform="translate(-86.6 115.57) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="279.11"
        y="283.53"
        width="6.17"
        height="11.46"
        rx="3.08"
        ry="3.08"
        transform="translate(-81.23 112.54) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="227.11"
        y="304.53"
        width="6.17"
        height="11.46"
        rx="3.08"
        ry="3.08"
        transform="translate(-91.4 96.19) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="233.11"
        y="319.53"
        width="6.17"
        height="11.46"
        rx="3.08"
        ry="3.08"
        transform="translate(-96.12 99.1) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="239.11"
        y="335.53"
        width="6.17"
        height="11.46"
        rx="3.08"
        ry="3.08"
        transform="translate(-101.18 102.08) rotate(-19.78)"
      />
      <polyline
        style={styles.four}
        points="247.12 306.59 271.8 356.12 352.15 588.24"
      />
      <polyline
        style={styles.four}
        points="266.67 299.03 275 354.96 357.44 586.27"
      />
      <polyline
        style={styles.four}
        points="278 330.5 282.2 352.34 374.13 606.95"
      />
      <polyline
        style={styles.four}
        points="270.91 313.8 279.16 353.44 369.5 606.95 370.25 609.04"
      />
      <line
        style={styles.four}
        x1="250.76"
        y1="323.14"
        x2="254.91"
        y2="333.62"
      />
      <polyline
        style={styles.four}
        points="255.61 340.5 265.29 358.49 356.23 616.8"
      />
      <polyline
        style={styles.three}
        points="257.35 338 268.37 357.37 357.44 615.56"
      />
      <rect
        style={styles.three}
        x="338.35"
        y="581.66"
        width="47.75"
        height="24.25"
        transform="translate(-184.52 163.84) rotate(-20.44)"
      />
      <rect
        style={styles.three}
        x="346.57"
        y="584.75"
        width="33.09"
        height="17.67"
        transform="translate(-184.4 164.13) rotate(-20.44)"
      />
      <rect
        style={styles.three}
        x="245.25"
        y="337.51"
        width="3.16"
        height="4.02"
        transform="translate(-100.32 103.54) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="239.85"
        y="321.58"
        width="3.16"
        height="4.02"
        transform="translate(-95.25 100.77) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="233.83"
        y="306.21"
        width="3.81"
        height="4.02"
        transform="translate(-90.38 97.94) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="280.49"
        y="307.13"
        width="5.09"
        height="4.02"
        transform="translate(-87.9 114) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="285.88"
        y="321.32"
        width="5.09"
        height="4.02"
        transform="translate(-92.39 116.66) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="274.92"
        y="289.4"
        width="3.79"
        height="4.02"
        transform="translate(-82.27 110.85) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="272.77"
        y="360.67"
        width="11.46"
        height="10.66"
        transform="translate(-107.41 115.82) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="284.42"
        y="392.98"
        width="11.46"
        height="10.66"
        transform="translate(-117.65 121.67) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="294.42"
        y="421.76"
        width="11.46"
        height="10.66"
        transform="translate(-126.8 126.75) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="303.42"
        y="447.45"
        width="11.46"
        height="10.66"
        transform="translate(-134.97 131.31) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="311.92"
        y="471.33"
        width="11.46"
        height="7.7"
        transform="translate(-142.04 135.51) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="321.77"
        y="501.72"
        width="14.02"
        height="5.19"
        transform="translate(-151.25 140.99) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="329.49"
        y="528.12"
        width="16.18"
        height="4.1"
        transform="translate(-159.47 145.49) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="335.06"
        y="541.34"
        width="16.18"
        height="4.1"
        transform="translate(-163.62 148.16) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="338.88"
        y="554"
        width="17.84"
        height="3.29"
        transform="translate(-167.49 150.45) rotate(-19.78)"
      />
      <rect
        style={styles.three}
        x="343.23"
        y="565.66"
        width="17.84"
        height="3.29"
        transform="translate(-171.18 152.61) rotate(-19.78)"
      />
      <polyline
        style={styles.three}
        points="352.15 588.24 360.32 612.09 361.01 613.74"
      />
      <line
        style={styles.three}
        x1="357.44"
        y1="586.27"
        x2="366.13"
        y2="611.14"
      />
    </g>
  );
};

export default Guitar;
