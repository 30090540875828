const Rex = () => {
  const styles = {
    one: {
      fill: "#689959",
    },
    two: {
      fill: "#e3e5da",
    },
    three: {
      opacity: 0.99,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    six: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#fff",
    },
    seven: {
      fill: "#d6dac7",
    },
    eight: {
      fill: "#202020",
    },
    nine: {
      fill: "#507543",
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="m472.43,200.13c4.66.53,11.59,1.86,19.12,5.59,10.22,5.06,16.27,11.92,20.4,16.72,5.24,6.07,6.63,9.41,16.43,25.83,4.93,8.25,9.06,14.9,11.74,19.17,7.74,3.17,18.34,7.96,30.35,14.88,13.73,7.91,26.8,15.45,37.07,26.84,14.27,15.84,18,33.46,21.8,52.59,3.66,18.41,7.5,51.14-2.33,92.75-5.29,3.13-10.58,6.25-15.87,9.38,4.37,8.08,4.64,14.64,4.36,18.43-1.31,17.76-19.09,34.15-44.57,39.78,6.16,14.2,12.31,31.17,17.17,50.69,3.45,13.85,5.7,26.79,7.15,38.49,4.67,4.26,9.33,8.52,14,12.79l4.7,24.26,9.65,10.96c22.03,5.58,46.81,13.57,73.1,25.17,13.01,5.74,25.03,11.76,36.05,17.82-8.03,14.21-18.79,30.3-33.2,46.54-32.22,36.32-67.83,56.63-92.12,67.74-23.13,10.47-60.3,23.55-107.19,24.41-85.57,1.58-147.09-38.56-169.2-54.64,1.05-4.51,2.1-9.03,3.15-13.54l-7.54-35.24,2.62-9.32-.47-12.41c.12-1.86.45-4.3,1.35-7.04.98-3,2.29-5.33,3.38-6.96,6.52-10.49,13.03-20.99,19.55-31.48l.42-12.8c-.85-7.4-1.29-21.63,6.74-35.32,1.7-2.89,3.56-5.39,5.43-7.55-1.67-23.12.52-40.94,2.9-53.08,4.36-22.32,7.98-35.08,7.98-35.08,1.94-6.82,4.78-15.95,6.26-28.96.57-5,.78-9.17.86-12.02.71-7.4,1.42-14.8,2.13-22.2l-28.46-70.47c-17.02-23.94-20.28-52.29-7.95-71.95,5.72-9.13,13.77-14.72,20.51-18.11,2.19-6.45,5.48-14.09,10.56-22.02,11.68-18.25,26.7-28.63,35.98-33.94,1.25-3.79,3.58-9.42,8.06-15.05,5.11-6.42,10.32-9.43,23.54-17.22,4.02-2.37,12.24-7.21,24.37-14.41Z"
      />
      <path
        style={styles.seven}
        d="m424.27,507.9c13.13,7.66,36.69,19.17,68.39,23.02,32.98,4,59.52-2.15,74.21-6.71,1.15,1.86,2.58,4.62,3.48,8.2,1.13,4.51.93,8.37.6,10.79-.47,5.13-.53,11.59.68,18.89,2.44,14.66,8.79,25.37,13.11,31.43,1.31,10.39,2.62,20.79,3.94,31.18-4.05,2.26-9.92,5.28-17.24,8.21-5.49,2.19-19.58,7.45-52.58,10.89-19.16,2-39.51,4.01-66.59,1.44-13.81-1.31-25.29-3.43-33.58-5.24,1.43-9.63,2.86-19.26,4.28-28.89v-27.94l2.96-25.74,1.81-18.87-3.47-30.67Z"
      />
      <path
        style={styles.seven}
        d="m364.83,647.65l16.86-5.07c25.46,5.61,57.28,10.09,93.88,9.51,19.6-.31,37.54-2.04,53.53-4.44,4.38,10.21,10.22,21.67,18.07,33.59,3.89,5.9,7.84,11.25,11.72,16.09.81,1.03,1.76,2.51,2.37,4.46,1.64,5.3-.55,9.95-1.2,11.23-.17,2.27-.34,5.71-.17,9.9.35,8.69,1.79,11.64.72,16.27-.65,2.82-2.34,6.7-7.07,10.81-.55.83-1.38,2.29-1.67,4.22-.51,3.35.9,6.1,1.23,6.8,1.38,2.96,2.49,10.48,1.12,30.77-5.74,5.22-11.48,10.43-17.22,15.65-6.74-9.92-11.21-18.52-14.08-24.66-4.26-9.1-6.37-15.67-12.53-24.45-3.85-5.49-7.67-9.54-10.34-12.15-1.14-1.98-8.99-15.07-25.07-17.98-11.55-2.09-20.61,2.29-23.5,3.84-5.86,7.72-6.01,13.64-5.76,16.42,1.29,14.42,20.87,28.24,49.28,29.76,2.36,6.88,4.48,12.79,6.2,17.48,5.34,14.58,7.52,19.41,10.96,24.65,2.51,3.82,4.93,6.73,6.62,8.62,5.48,3.03,10.97,6.06,16.45,9.08-19.75,2.93-46.97,4.77-78.72.25-42.63-6.07-75.24-21.04-95.45-32.37.96-4.94,1.7-10.75,1.81-17.29.07-3.9-.1-7.52-.4-10.82,4.57-3.24,9.14-6.48,13.71-9.72.71-.38,1.81-.98,3.13-1.76,5.64-3.32,13.99-8.24,13.26-11.53-.15-.67-.76-1.64-3.22-2.46-2.69-.35-5.88-.54-9.45-.29-7.31.51-13.15,2.63-17.04,4.44,4.45-4.96,9.04-6.83,11.37-7.57,3.62-1.16,8.94-2.85,13.92-.95,1.32.5,3.68,1.67,6.04.76,1.11-.43,1.87-1.18,2.35-1.76-1.49-3.22-4.19-7.63-9-10.96-9.33-6.45-22.65-6.1-34.43.46.6-9.46,1.19-18.91,1.79-28.37-.61-3.01-1.25-8.09-.08-14.2,1.21-6.31,3.82-10.91,5.54-13.48,1.49-10.93,2.99-21.85,4.48-32.78Z"
      />
      <path
        style={styles.seven}
        d="m617.71,780.05l-5.81,17.13-3.16,5.21-1.3,14.14c-7.03,3.32-14.86,6.61-23.48,9.65-12.46,4.38-24.09,7.35-34.42,9.37,20.41-17.98,40.81-35.96,61.22-53.94l6.96-1.56Z"
      />
      <path
        style={styles.nine}
        d="m378.9,384.39c1.11,6.09,17.56,88.78,89.23,116.26,46.59,17.87,115.37,11.99,137.74-24.52,3.16-5.15,4.89-10.06,5.9-13.77-27.8,9.79-51.94,12.78-68.71,13.64-13.4.69-34.2,1.6-60.46-4.7-8.99-2.15-32.16-8.4-57.56-24.41-12.97-8.18-23.37-14.89-32.44-28.03-9.08-13.15-12.36-26.27-13.71-34.48Z"
      />
      <path
        style={styles.one}
        d="m630.02,444.43c-1.73,2.49-4.5,5.9-8.65,8.98-4.58,3.4-8.71,4.81-16.28,7.2-12.09,3.82-18.14,5.72-21.92,6.6-34.68,8.03-72.31,3.68-72.31,3.68-16.04-1.86-41.68-4.82-69.33-19.95-17.13-9.38-30.93-21.17-36.12-25.66-8.51-7.35-15.17-14.16-19.94-19.39-2.01-6.48-4.01-12.96-6.02-19.44,4.53,3.34,9.06,6.68,13.59,10.02l-.05,9.83c1.47-3.11,4.7-4.68,7.46-3.94,2.85.76,3.97,3.66,4.08,3.98-.92,5.64-1.84,11.28-2.76,16.92.81,1.39,1.55,2.05,2.21,1.98,1.77-.17,3.05-5.41,3.84-15.73.34-.56.72-.83,1.08-.95,3.13-1.01,8.62,8.57,9.41,9.97-1.1,5.24-2.19,10.48-3.29,15.72l3.6,2.65,4.06-17.21c2.48,1.25,6.28,3.71,8.66,8.18,3.78,7.07,1.43,14.36.81,15.05-.02.02-.06.07-.09.06-.39-.15,3.19-10.22,3.19-10.22,1.12-1.63,7.05-10.1,12.73-14.55,1.59-1.25,3.27-2.26,5.46-2.51,2.2-.26,4.03.35,5.1.81.6-1.62,2.22-5.19,5.78-6.87,5.41-2.53,13.38.2,18.28,7.53,1.4-.56,6.37-2.37,12.33-.5,5.47,1.72,8.44,5.5,9.36,6.78.52-.48,4.91-4.36,11.11-3.3,5.75.98,8.59,5.45,9.01,6.13,1.22,1,2.45,2.01,3.67,3.01,1.4-1.11,3.54-2.56,6.41-3.65,2.61-.99,4.95-1.35,6.65-1.48,6.77.32,13.53.64,20.3.96l.02,7.16c1.04-.2,2.64-.36,4.29.28,3.08,1.19,4.01,4.24,7.11,9.26,1.04,1.68,2.64,4.1,4.9,6.87-1.41-5.22-2.82-10.45-4.22-15.67.1-1.27.38-2.38.76-3.34,3.77-9.63,19.59-10.73,21.2-10.83,2.88-2.21,5.76-4.43,8.64-6.64.46-.35,3.44-2.52,6.99-1.59,1.36.36,2.35,1.05,2.96,1.58,2.19-3.25,4.38-6.5,6.58-9.75l9.36,41.95Z"
      />
      <path
        style={styles.two}
        d="m425.05,446.9c1.94,1.39,4.83,3.4,8.45,5.63,34.84,21.46,70.21,22.49,93.72,22.89,19.34.33,49.34-1.42,84.55-13.05l-8.44,12.45-2.37-6-5.46,11.68-4.5-10.66-8.9,16.84-7.4-14.58-3.17.17c.03,1.9-.26,4.1-1.26,6.29-3.95,8.65-15.47,9.63-16.24,9.68v5.59c-10.92,4.4-20.61,4.77-24.26.18-.53-.66-.98-1.5-1.28-2.57-2.51-5.14-5.02-10.27-7.52-15.41l-5.86-.4-11.06,18.76c-2.22,4.57-6.72,7.53-11.61,7.63-6.2.12-9.99-4.42-10.46-4.99.69-2.5,1.39-4.99,2.08-7.49l-1.05-5.68-5.28-2.19,1.3,11.34c-1.33.32-9.88,2.26-16.7-3.11-2.54-2-4.02-4.37-4.85-6.08-.74.04-6.76.26-10.76-4.48-2.6-3.09-2.89-6.55-2.93-7.76-.68.02-4.42.06-7.15-2.82-1.36-1.43-1.91-3.01-2.14-3.91-.78-4.15-1.55-8.29-2.33-12.44l-5.48,2.26-1.63-7.77Z"
      />
      <path
        style={styles.nine}
        d="m358.57,395.78l20.89-9.33c-2.42-8.71-4.69-20.84-4.06-35.28.34-7.92,1.49-14.92,2.87-20.79-.8-.58-1.75-1.39-2.67-2.49-1.54-1.83-2.33-3.68-2.75-4.93-3.2-.35-8.49-.52-14.67,1.21-6.53,1.84-11.14,4.99-13.7,7.04-1.34,5.66-2.39,12.86-1.98,21.16,1.04,21.17,10.89,36.47,16.07,43.41Z"
      />
      <path
        style={styles.nine}
        d="m465.98,392.83c-.08-1.08-.85-15.06,9.68-22.68,6.86-4.96,14.45-4.28,17.41-4.01,2.61.23,16.03,1.44,21.5,12.58,4.02,8.19,1.15,16.46.41,18.43-2.61-2.8-8.28-8.07-17.11-10.47-16.35-4.44-29.82,4.69-31.89,6.14Z"
      />
      <path
        style={styles.nine}
        d="m591,392.83c-6.16-20.55-1.8-36.83,4.96-39.05,6.34-2.08,17.89,7.19,25.67,23.12-2.54-.47-9.2-1.35-16.54,1.92-9.22,4.11-13.12,11.91-14.09,14Z"
      />
      <path d="m470.38,375.38c.34-2.13,2.64-15.1,14.09-21.49,1.87-1.04,6.98-3.89,12.65-2.99,9.66,1.54,17.94,13.49,19.05,29.63-1.2-2.55-5.09-9.95-13.48-13.5-10.34-4.38-23.28-1.12-32.31,8.35Z" />
      <path
        style={styles.nine}
        d="m477.2,210.82c.26,4.15.84,8.89,2.01,14.02,2.12,9.33,5.45,16.89,8.48,22.5,9.09,5.65,18.19,11.3,27.28,16.96.12-1.2.58-4.17,2.85-7.04,2.29-2.91,5.12-4.04,6.26-4.43-4.14-6.17-9.74-13.38-17.22-20.61-10.67-10.33-21.39-17.07-29.67-21.39Z"
      />
      <path
        style={styles.nine}
        d="m471.36,213.43c-.69,14.39-1.37,28.79-2.06,43.18-3.31.37-7.15,1.04-11.35,2.22-3.45.97-6.51,2.12-9.13,3.26-2.33-1.62-6.03-4.64-9.13-9.52-2.83-4.45-4.01-8.65-4.55-11.35,12.07-9.26,24.15-18.52,36.22-27.79Z"
      />
      <ellipse
        style={styles.six}
        cx="452.71"
        cy="248.16"
        rx="10.73"
        ry="10.47"
      />
      <circle style={styles.four} cx="454.57" cy="250.15" r="4.57" />
      <path
        style={styles.six}
        d="m487.7,247.35c.35-4.97,3.31-9.17,7.5-10.63,3.91-1.36,7.37.18,7.96.46,3.48,1.62,5.88,5.05,6.25,8.8.46,4.7-2.33,9.34-6.97,11.42-4.91-3.35-9.83-6.7-14.74-10.04Z"
      />
      <ellipse
        style={styles.four}
        cx="497.61"
        cy="247.84"
        rx="5.15"
        ry="4.99"
      />
      <path
        style={styles.five}
        d="m620.84,459.27c4.14-1.49,7.2-5,8.1-9.3,3.44-16.46,6.28-39.84,3.14-67.49-2.71-23.92-9.07-43.71-15.25-58.43-3.36-8-8.54-15.1-15.19-20.68-6.27-5.27-13.54-10.75-21.85-16.07-14.59-9.35-28.35-15.62-39.67-19.86-2.59-3.4-6.46-8.73-10.57-15.65-4.31-7.27-5.32-10.25-7.83-14.87-2.62-4.83-13.28-24.48-34.14-33-3.57-1.46-8.65-3.09-15.17-3.78-11.54,6.42-21.5,12.42-29.74,17.61-8.78,5.53-14.45,9.4-19.57,16.43-3.5,4.82-5.51,9.42-6.67,12.63-32.09,19.33-50.67,46.74-45.76,69.55.92,4.28,2.89,9.41,7.04,14.87-1.98,10.95-3.74,28.48.39,49.3,3.07,15.48,8.41,27.84,13.09,36.6.88,1.65,1.99,3.18,3.29,4.52,10.34,10.67,22.75,20.92,37.58,29.61,74.24,43.52,159.18,18.65,188.75,8.02Z"
      />
      <path
        style={styles.five}
        d="m373.14,323.64c-2.71-.58-8.32-1.4-14.97.54-7.26,2.12-11.8,6.38-13.7,8.4"
      />
      <path
        style={styles.five}
        d="m386.75,407.91c9.73,11.14,25.9,27.02,49.69,40.31,5.54,3.09,26.92,14.66,57.91,20.74,0,0,35.2,6.84,86.09-1.57,6.73-1.11,13.55-3.28,27.2-7.63,5.26-1.68,11.7-3.85,17.61-9.59,2.23-2.16,3.78-4.25,4.78-5.75"
      />
      <path
        style={styles.five}
        d="m432.16,445.76l3.1-13c1.25-2.01,12.2-19.27,21.4-17.01.62.15,1.25.4,1.88.76-3.54,12.51-7.08,25.02-10.62,37.53"
      />
      <path
        style={styles.five}
        d="m464.94,460.92l18.06-45.38c-1.68-1.44-4.48-3.5-8.41-4.89-2.8-.99-5.4-1.38-7.56-1.48-2.38-.11-4.61,1.12-5.87,3.14l-2.62,4.2"
      />
      <path
        style={styles.five}
        d="m492.73,468.63l12.18-44.28c-.59-.94-4.69-7.2-12.52-8.22-5.05-.66-8.96,1.21-10.43,2.01"
      />
      <path
        style={styles.five}
        d="m518.41,471.33l9.59-42.09c-.68-.29-1.99-.96-3.13-2.35-.24-.29-.44-.58-.62-.86-1.55-2.47-3.81-4.45-6.59-5.34-3.39-1.08-6.76-.96-9.44.55-.93.53-2.18,1.45-3.31,3.1"
      />
      <path
        style={styles.five}
        d="m551.11,470.84c-.37-11.78-.03-34.67,9.63-38.06.23-.08.47-.15.72-.21v-7.43c-5.56-.33-11.12-.67-16.69-1-6.02-.36-11.97,1.45-16.77,5.11h0"
      />
      <path
        style={styles.five}
        d="m580.43,467.39c-1.33-7.42-3.04-13.51-4.5-18-4.51-13.9-7.74-16.15-10.17-16.83-1.67-.47-3.22-.26-4.3,0"
      />
      <path
        style={styles.five}
        d="m582.7,466.98l-9.15-33.95c-.35-1.31-.2-2.7.43-3.89h0c2.04-3.87,5.51-6.84,9.7-8.09.03,0,.05-.02.08-.02,4.41-1.31,8.42-1.91,11.74-2.15,2.14,5.63,4.27,12.38,5.87,20.15,1.69,8.21,2.4,15.63,2.62,21.9"
      />
      <path
        style={styles.five}
        d="m619.48,454.67l-5.4-43.43h0c-3.91-1.31-8.21-.56-11.44,1.99l-7.14,5.64"
      />
      <path
        style={styles.five}
        d="m629.16,445.65l-7.89-43.04c-.05-.29-.43-.38-.61-.14l-6.58,8.77"
      />
      <path
        style={styles.five}
        d="m405.4,426.47l2.43-16.96c.11-.79.99-1.21,1.67-.79,1.39.85,3.02,2.04,4.63,3.69,2.32,2.38,3.71,4.78,4.5,6.46-.76,5.46-1.51,10.92-2.27,16.38"
      />
      <path
        style={styles.five}
        d="m418.63,436.9l3.02-15.35c.16-.84,1.07-1.31,1.84-.95,2.15,1,5.64,3.13,7.86,7.26,4.39,8.17-.26,16.52-.57,17.06"
      />
      <polyline
        style={styles.five}
        points="425.05 446.9 427.53 454.58 433.22 451.91"
      />
      <polyline
        style={styles.five}
        points="431.77 452.59 435.65 467.29 443.28 462.99"
      />
      <path
        style={styles.five}
        d="m441.5,456.38l7.65,20.11c2.87-3.82,5.74-7.63,8.61-11.45,2.18,6.91,4.37,13.83,6.55,20.74l8.18-17.29"
      />
      <path
        style={styles.five}
        d="m476.32,469.54l2.7,23.48c-1.26.38-7.66,2.18-14.12-1.37-4.53-2.49-6.67-6.29-7.43-7.83,1.48-2.17,2.96-4.33,4.44-6.5"
      />
      <path
        style={styles.five}
        d="m434.6,463.3c-.3,2.02.24,3.29.57,3.89,1.63,2.99,6.35,4.37,11.54,2.86"
      />
      <path
        style={styles.five}
        d="m443.98,470.59c-.06.78-.32,5.55,3.32,9.42,4.03,4.28,9.5,3.88,10.17,3.82"
      />
      <line style={styles.five} x1="477.65" y1="481.13" x2="483" y2="484.41" />
      <polyline
        style={styles.five}
        points="482.64 471.08 484.08 489.89 492.87 473.1"
      />
      <path
        style={styles.five}
        d="m494.62,473.39l9.41,21c-2.38,4.74-7.11,7.72-12.13,7.63-5.85-.11-9.41-4.34-9.94-4.99,1-2.95,2-5.89,3-8.84"
      />
      <line
        style={styles.five}
        x1="504.03"
        y1="494.39"
        x2="515.1"
        y2="475.63"
      />
      <path
        style={styles.five}
        d="m519.98,475.87c2.83,5.19,5.67,10.38,8.5,15.57,3.13-5.27,6.27-10.54,9.4-15.8"
      />
      <path
        style={styles.five}
        d="m552.65,474.81l1.37,19.01c-11.27,4.5-21.14,4.4-24.54-.31-.6-.83-1.13-1.98-1.3-3.61"
      />
      <path
        style={styles.five}
        d="m554.02,488.23c1.36,0,7.6-.1,12.82-4.99,4.61-4.33,5.48-9.64,5.67-11.16"
      />
      <path
        style={styles.five}
        d="m574.66,471.58l7.43,15.08,9-17.02,4.4,10.84c1.96-4.33,3.91-8.66,5.87-13,.65,2.44,1.3,4.88,1.96,7.32,2.61-3.65,5.22-7.29,7.83-10.94,4.74,9.27,5.03,16.39,3.89,21.69-7.86,36.69-100.32,62.94-168.43,33.87-23.54-10.05-39.63-24.73-48.91-34.63-1.87-3.01-4.87-7.97-8.22-14.28-8.08-15.23-11.33-25.32-21.48-51.21-2.08-5.3-5.31-13.46-9.43-23.53-17.9-25.57-21.39-55.54-8.35-76.26,3-4.76,8.97-12.38,20.48-18.43"
      />
      <path
        style={styles.five}
        d="m570.95,522.08c6.01,14.82,11.85,31.45,16.92,49.79,1.3,4.71,2.5,9.34,3.6,13.89,2.69,11.08,4.03,22.45,4.03,33.85h0c-1.28,1.05-3.23,2.58-5.67,4.3-19.32,13.59-40.22,17.94-72,19.96-43.97,2.79-75.44,4.79-115.43-8.61-15.69-5.25-27.98-11.3-36-15.65-.91-5.52-1.59-12.11-1.57-19.57.02-5.7.45-10.89,1.07-15.46.3-2.23.58-4.46.79-6.7,1.39-15.18,3.53-27.26,5.19-35.36,4.35-21.26,8.77-31.63,11.77-53.26,1.55-11.2,2.02-20.36,2.18-25.96"
      />
      <path
        style={styles.five}
        d="m595.26,611.26c2.33,1.27,5.28,3.19,8.26,6,2.54,2.4,4.4,4.79,5.74,6.78,1.57,8.09,3.13,16.17,4.7,24.26.58,1.48,1.53,3.46,3.13,5.48,2.29,2.9,4.86,4.58,6.52,5.48,17.43,4.4,36.99,10.43,57.91,18.78,19.35,7.72,36.45,16.07,51.24,24.21"
      />
      <path
        style={styles.five}
        d="m365.69,614.64c-1.04,1.17-1.98,2.35-2.84,3.55-10.44,14.43-10.46,32.16-8.76,44.57.47,3.41-.27,6.87-2.11,9.78-7.02,11.14-14.04,22.28-21.06,33.42-1.72,4.11-2.09,7.49-2.1,9.84-.02,3.97.97,5.56.8,9.47-.2,4.69-1.88,8.01-2.35,9.13-1.92,4.55.83,5.62,6.94,38.38"
      />
      <path
        style={styles.five}
        d="m361.15,620.71c-.3,1.54-.53,3.85.14,6.41,2.8,10.73,18.71,15.53,31.19,18.34,59.29,13.36,144.29,5.1,185.1-8.23,25.32-8.27,26.18-16.05,26.25-17.6.05-1.04-.16-1.89-.3-2.37"
      />
      <path
        style={styles.five}
        d="m423.73,507l.8,3.93c2.45,12.04,3.29,24.37,2.34,36.62-.1,1.29-.21,2.6-.33,3.91-.42,4.63-.95,9.1-1.56,13.39-1.25,8.85-1.62,17.79-1.21,26.72,0,.04,0,.09,0,.13.24,5.38,0,10.32-.47,14.72-.41,3.7-.71,7.4-.82,11.12-.06,1.98-.19,4.08-.42,6.26-.67,6.32-1.99,11.78-3.39,16.21"
      />
      <path
        style={styles.five}
        d="m566.87,523.45c1.34,2.31,3.03,5.91,3.79,10.59.45,2.82.45,5.32.29,7.3-.49,5.64-.54,13.01,1.01,21.39,2.62,14.19,8.58,24.65,12.78,30.78,1.31,10.39,2.62,20.79,3.94,31.18"
      />
      <path
        style={styles.five}
        d="m529.1,647.65l9.9,18.83c5.09,9.68,11,18.9,17.64,27.59,5.02,6.58,5.65,10.73,4.88,13.45-.39,1.4-2.09,5.79-2.09,12,0,1.5.1,2.73.19,3.55,3.04,12.52,1.09,18.16-1.49,20.97-1.21,1.32-4.37,3.97-5.56,8.6-.46,1.8-.49,3.38-.44,4.45,0,.02.01.05.02.07,1.37,4.8,2.07,9.76,2.07,14.75v19.87l26.61-24.26c0-1.31.23-10.94,8.09-16.96,1.18-.9,7.7-5.9,15.32-3.76,9.18,2.57,11.73,13.18,12.07,14.72,2.46,11.06-5.14,19.67-6,20.61,13.68-5.75,21.07-13.6,25.04-19.04,18.62-25.54,13.13-68.22-14.75-105.8"
      />
      <path
        style={styles.five}
        d="m381.69,642.58c-1.78.72-3.66,1.41-5.64,2.07-3.98,1.32-7.75,2.29-11.22,3l-3.97,27.92-5.81,17.22c-.59,2.61-1.28,6.65-1.13,11.62.11,3.5.57,5.06.73,8.34.16,3.1.06,7.63-1.3,13.3-.09,3.48-.17,6.96-.26,10.43l-7.26,6.78c-1-2.76-1.98-6.09-2.65-9.91-.93-5.28-1-9.95-.78-13.63-2.08.91-4.81,2.39-7.57,4.76-4.11,3.54-6.38,7.44-7.57,9.91"
      />
      <path
        style={styles.five}
        d="m358.57,395.78c3.35-2.34,7.52-4.84,12.52-7.04,2.89-1.27,5.65-2.26,8.18-3.03.45.75,1.21,1.88,2.38,3.03,2.33,2.29,4.3,2.72,7.63,4.89,1.06.69,2.59,1.77,4.3,3.33-.91,14.33-1.07,17.28-1.05,17.29.03,0,.07-6.08,3.59-10.44.6-.74,1.25-1.33,2.15-1.57,2.09-.54,4.78,1.04,6.26,4.11l-2.22,17.4"
      />
      <path
        style={styles.five}
        d="m363.15,392.83c7,19.3,16.3,40.46,28.81,62.43,4.42,7.76,8.92,15.08,13.45,21.97,15.8,14,49.75,39.56,97.16,42.73,38.59,2.58,98.83-8.84,108-40.7,1.71-5.93,1.31-11.41.59-15.39"
      />
      <path
        style={styles.four}
        d="m620.74,373.67c-2.18-20.19-10.16-32.95-16.97-32.82-7.48.15-12.95,15.8-13.55,17.56,3.25-.09,8.28.2,13.89,2.35,9.25,3.54,14.52,9.98,16.63,12.91Z"
      />
      <path
        style={styles.four}
        d="m472.3,373.4c5.02-9.23,15.51-13.97,25.38-11.66,8.95,2.1,15.85,9.64,17.3,19-1.05-1.64-7.02-10.53-18.87-12.93-12.85-2.6-22.41,4.51-23.81,5.6Z"
      />
      <path d="m462.85,355.87c4.37-8.49,11.44-15.92,20.15-20.13,8.76-4.31,19.99-5.21,28.42.35,5.59,3.62,9.43,9.29,11.49,15.29-3.18-5.37-7.19-10.31-12.64-13.41-17.37-9.5-37.43,3.25-47.43,17.91h0Z" />
      <path d="m579.46,350.2c2.58-10.48,11.45-23.69,23.69-22.34,10.53,2.08,17.7,18.22,17.6,28.02-.74-5.67-2.84-10.98-5.86-15.8-2.21-3.52-4.8-6.9-8.36-9.04-3.5-2.15-7.79-1.82-11.42.04-7.57,3.86-12.44,11.35-15.64,19.12h0Z" />
      <path d="m465.98,392.83c-.06-9.39,1.77-19.03,6.45-27.47,3.46-6.34,9.05-11.58,15.81-14.33,10.06-4.13,19.32,2.91,24.28,11.34,6.05,10.46,5.72,23.49,2.46,34.78,1.58-12.36,1.75-26.26-5.9-36.71-4.67-6.64-12.11-11.35-20.24-7.9-6.29,2.67-11.37,7.83-14.75,13.76-4.54,8.02-6.82,17.23-8.11,26.52h0Z" />
      <path d="m591,392.83c-4.77-10.8-5.66-23.5-1.74-34.72,0,0,1.91.6,1.91.6-3.18,11.11-3.38,22.98-.17,34.12h0Z" />
      <path d="m430.37,244.35c7.47-5.87,15-11.66,21.98-18.07,6.82-6.95,14.82-13.05,24.03-16.47,9.94,4.61,19.16,10.78,27.3,18.11,8.03,7.18,15.02,15.59,20.4,24.91-6.44-8.53-13.68-16.4-21.75-23.37-8.07-6.92-16.99-12.79-26.43-17.66,0,0,.82.03.82.03-8.73,3.7-16.22,9.4-23.04,15.95-7.17,6.35-15.06,11.79-23.33,16.59h0Z" />
      <path d="m483.78,227.13c.56,6.88,2.21,13.61,4.85,19.87,0,0-.3-.42-.3-.42,2.08,1.67,4.18,3.29,6.33,4.87,4.3,3.15,8.78,6.03,13.42,8.65,2.38,1.21,4.71,2.48,6.89,4.2-5.3-1.28-10.11-3.92-14.78-6.65-4.73-2.85-9.23-6.08-13.37-9.73-2.14-6.75-3.16-13.81-3.05-20.79h0Z" />
      <path d="m331.05,786.31c-.02-2.99,1.09-7.22,1.54-10.19.64-3.37,1.42-6.72,2.29-10.04,1.66-6.61,3.68-13.72,7.27-19.33-.07.13-.11.29-.1.43,0,0,.15-.53.15-.53,0,0,1.55-2.31,1.55-2.31l-.19.58c3-5.13,8.02-8.83,13.18-11.64,10.19-5.41,23.6-4.97,32.65,2.56,2.81,2.31,5.4,5.06,7.15,8.26,1.28,2.75-.95,6.04-3.94,5.97-10.7-3.45-22.67.04-30.69,7.69,0,0-1.03-1.84-1.03-1.84,6.55-1.78,13.15-3.52,19.89-4.55,2.85-.37,5.88-.82,8.83-.18,2.72.6,3.25,2.74,2.62,5.24-1.4,5.89-11.11,10.65-16.33,12.72,1.23-.78-13.35,9.91-13.07,9.75-.01.02.4-.86.4-.86,1.02,9.35-.47,18.74-2.16,27.91.34-9.3.5-18.51.16-27.79,0,0,0-.58,0-.58,0,0,.39-.29.4-.29,0,0,13.28-9.94,13.28-9.94,0,0,.11-.08.11-.08,4.15-1.58,8.12-3.78,11.54-6.55,2.24-1.81,4.08-3.98,3.67-6.71-1.82-2.34-12.71.21-15.71.76-5.74,1.22-11.67,2.86-17.35,4.42,0,0,3.26-3.01,3.26-3.01,8.52-8.1,21.26-11.79,32.61-8.17,0,0-.35-.04-.35-.04,1.7-.04,2.68-1.95,1.76-3.36-1.7-2.7-3.87-5.13-6.36-7.13-13.48-10.99-33.22-5.23-42.72,8.05,0,0-1.49,2.2-1.49,2.2,0,0,.15-.53.15-.53,0,.28-.16.55-.31.79-.13.18-.35.62-.48.81-4.97,8.49-6.33,18.39-9.14,27.7-.87,2.85-1.83,7.12-3.06,9.83h0Z" />
      <path d="m554.89,792.52l-17.22,15.65-.86.78-.64-.97c-6.66-10.02-12.48-20.65-17.33-31.65-2.44-5.47-5.06-10.79-8.35-15.73-3.29-4.97-7.07-9.62-11.27-13.85-3.53-5.36-8.31-9.92-13.95-12.94-9.66-5-27.82-7.35-35.27,2.25-4.08,4.86-3.8,12.91-2.67,17.63,9.7,16.39,29.8,22.18,47.73,23.52,0,0,.66.04.66.04l.23.64c4.23,11.79,8.78,23.46,13.82,34.92,2.45,5.7,5.48,11.07,9.75,15.58,2.89,3.53,7.62,5.28,11.56,7.4,1.39.74,2.83,1.39,4.15,2.26-5.79-2.41-12.56-3.61-17.04-8.35-4.66-4.47-7.89-10.22-10.49-15.95-2.66-5.71-5.09-11.53-7.36-17.4-2.3-5.87-4.37-11.82-6.28-17.81l.89.68c-18.58-1.37-39.29-7.51-49.32-24.48-.98-3.31-1.25-6.88-.9-10.36,1.19-11.77,11.1-17.17,22.1-16.85,6.69.12,13.44,1.38,19.4,4.53,5.74,2.94,11.5,8.6,14.5,13.37,4.26,4.31,8.1,9.03,11.45,14.08,3.36,5.05,6.06,10.53,8.5,16,4.82,10.95,10.56,21.42,17.17,31.37,0,0-1.51-.19-1.51-.19,0,0,17.22-15.65,17.22-15.65,0,0,1.35,1.48,1.35,1.48h0Z" />
      <path d="m610.96,782.88c-19.82,17.19-41.4,35.87-61.43,52.67,14.31-13.2,30.42-27.65,45.01-40.71,0,0,15.1-13.46,15.1-13.46,0,0,1.32,1.5,1.32,1.5h0Z" />
      <path d="m362.61,791.7c3.61,4.94,6.89,10.07,9.9,15.38.98,1.78,1.91,3.59,2.68,5.51-1.35-1.55-2.54-3.21-3.68-4.89-3.35-5.1-6.43-10.36-9.18-15.82,0,0,.28-.17.28-.17h0Z" />
      <path d="m364,651.27c13.39-2.35,28.32-1.03,40.68,3.36,13.04,2.75,26.28,4.53,39.58,5.3,13.4.83,26.86.49,40.23-.61,9.26,1.93,27.97,5.76,37.24,7.64,3.97,1.34,7.91,2.67,11.67,4.58,1,.6,1.97,1,2.67,2.08.8,1.59-1.29,2.25-2.47,2.43-2.15.35-4.26.43-6.38.41-16.38-3.64-33.05-6.06-49.82-6.79-8.41-.38-16.82.29-25.23-.33-8.33-.47-17.07-1.69-25.03-3.26,0,0-15.74-1.93-15.74-1.93-6.58-.76-13.32-1.56-19.45-4.24-8.22-3.62-18.55-5.22-26.28.38,6.4-5.15,15.39-5.03,22.89-2.64,4.95,1.76,9.88,3.63,15.15,4.19,5.1.57,18.29,1.99,23.63,2.58,14.46,2.57,29.11,3.89,43.8,3.89,0,0,6.32.35,6.32.35,16.61.98,34.08,3.21,49.88,6.76,2.49-.1,5.1,0,7.41-.85.19-.09.35-.21.37-.26.01-.04.02-.02-.04-.16-.48-.65-1.45-1.18-2.22-1.59-3.64-1.8-7.59-3.07-11.46-4.34-8.88-1.66-28.09-5.27-37.29-6.95-18.52,1.84-40.49,1.37-60-1.28-6.69-.9-13.36-2.03-19.96-3.45-12.71-4.43-26.54-5.56-39.81-3.32,0,0-.33-1.97-.33-1.97h0Z" />
      <path d="m380.48,682.22c19.01-1.63,38.17-.88,57.14.96,19.05,1.75,38.05,4.05,56.95,6.99,1.36.62,3.01.97,3.87,2.42.11.23.17.69,0,1.01-.31.64-.7.76-.93.88-1.85.66-3.37.58-5.19.65-29.5-2.87-73.1-6.76-102.18-10.05,14.73.93,36.24,2.7,51.19,3.94.02,0,51.48,4.29,51.05,4.27,1.37-.06,3.15-.09,4.33-.53.12-.03.1-.14-.04.14-.05.14-.03.38,0,.44.04.08.03.05,0,.04-.73-.6-1.85-1-2.8-1.37,0,0,.2.05.2.05-9.41-1.38-18.84-2.63-28.28-3.75-28.33-3.46-56.75-5.96-85.31-6.08h0Z" />
      <path d="m368.21,709.61c32.45,6.35,65.48,5.48,98.36,6.71,13.19.47,26.17,2.96,39.27,4.2,6.55.52,13.13.63,19.69.33,5.64.47,11.35.43,16.89-.55,5.61-1.26,11.76-1.35,17.34.35,0,0,.02,0,.02,0,0,0,.59.17.59.17l.11.61,1.21,6.91s.22,1.28.22,1.28c0,0-1.29-.11-1.29-.11-12.49-1.09-25.11-.34-37.4,2.11-10.58.46-26.55,1.63-37.25,2.39,0,0-.3.02-.3.02l-.25-.13c-4.7-2.47-9.94-3.94-15.24-4.23-5.39-.32-10.84.6-15.85,2.6-14.75-.98-29.97-3.18-44.5-6.01-13.55-3.7-27.51-6.56-41.62-6.54,10.61-.36,21.14,1.24,31.54,3.23,3.39.67,7.1,1.52,10.3,2.36,6.6,1.03,15.5,2.53,22.03,3.17,6.67.87,16.69,1.38,21.77,1.83,10.43-4.17,22.6-3.45,32.5,1.82,0,0-.55-.11-.55-.11l18.66-1.31c5.45-.42,13.15-.81,18.67-1.08,11.58-2.41,25.3-3.22,37.66-2.11,0,0-1.07,1.17-1.07,1.17,0,0-1.21-6.91-1.21-6.91-.01,0,.74.82.68.78-5.37-1.71-10.94-1.7-16.48-.57-5.56.9-11.98.88-17.16.38-6.61.24-13.24.08-19.82-.5-6.58-.52-13.08-1.76-19.6-2.62-9.77-1.27-19.61-2.17-29.46-2.22-29.6.52-59.49-.99-88.45-7.41h0Z" />
      <path d="m392.38,739.01c8.83.98,17.72,1.31,26.6,1.08,8.89-.36,18.07-1.23,26.84,1.15,0,0,.73.18.73.18.05-.22.17,8.7.21,8.85,0,0,.01,1.17.01,1.17-4.52-.61-9.05-1.15-13.57-1.62-11.99-1.24-25.44-2.58-37.45-3.67,0,0,.21-1.99.21-1.99,7.17.85,17.64,1.96,24.94,2.74,8.31.9,16.63,1.8,24.97,2.41,0,0-1.11,1.03-1.1,1.01,0,0-.19-8.08-.19-8.08,0,0,.76.95.74.94-8.56-2.71-17.42-1.92-26.29-1.61-4.47.1-8.94.12-13.41,0-4.47-.1-8.94-.29-13.4-.57l.17-1.99h0Z" />
      <path d="m389.9,758.08c8.45,1.58,17.11,3.05,25.61,4.41,8.59.83,19.75,1.79,28.35,2.55,8.19.51,16.9,2.79,24.05,6.1,4.11,1.38,12.52,4.24,16.65,5.65-15.99-1.91-36.3-4.28-52.2-6.45-10.47-.62-23.31-2.42-33.18-4.95,0,0-14.09-1.3-14.09-1.3,0,0,.18-1.99.18-1.99.07,0,14.09,1.3,14.16,1.31,10.69,2.66,22.1,4.25,33.11,4.95,6.31.8,15.19,2.04,21.42,2.77,0,0,21.38,2.56,21.38,2.56,0,0-.45,1.94-.46,1.93,0,0-7.57-2.83-7.57-2.83,0,0-.06-.02-.06-.02-5.84-2.93-12.17-4.88-18.63-5.9-9.95-1.21-22.02-2.12-32.03-3.07-1.71-.11-5.63-.8-7.36-1.03-6.54-.94-13.08-1.88-19.65-2.7,0,0,.31-1.98.31-1.98h0Z" />
      <path d="m363.13,757.47c-4.21.85-8.16,3.34-10.93,6.91,1.12-1.96,2.57-3.77,4.33-5.28,1.76-1.51,3.81-2.73,6.04-3.55l.56,1.92h0Z" />
      <path d="m362.73,778.94c-2.52,1.1-4.6,3.56-5.21,6.58-.02-1.53.33-3.1,1.04-4.54.71-1.44,1.79-2.74,3.14-3.75l1.03,1.71h0Z" />
      <path d="m445.53,753.89c3.67-9.9,15.69-9.54,23.9-6.19-2.1-.7-4.34-.85-6.51-.89-6.04-.43-13.46,1.35-15.5,7.74,0,0-1.89-.66-1.89-.66h0Z" />
      <path d="m537.83,806.87c1.45,1.36,2.17,3.22,3.13,4.91.91,1.74,1.78,3.49,2.56,5.3-1.38-1.4-2.68-2.87-3.96-4.35-1.21-1.52-2.67-2.89-3.39-4.74,0,0,1.66-1.12,1.66-1.12h0Z" />
      <path d="m619.35,778.13c-1.6,4.56-4.28,14.8-6.57,18.94-.54.98-1.34,1.83-1.97,2.74-.61.87-1.12,1.81-1.51,2.79l.04-.21c-.5,3.23-.95,7.36-1.28,10.62-.21,1.12-.18,2.54-.65,3.51-.47-3.44.12-7.23.36-10.67.09-.83.23-2.29.34-3.1,0-.04.05-.44.05-.36.49-1.71,1.52-3.28,2.58-4.69,1.58-2.18,2.26-4.79,3.08-7.4,0,0,3.63-12.78,3.63-12.78,0,0,1.91.59,1.91.59h0Z" />
      <path
        style={styles.five}
        d="m422.97,611.26c9.07,1.92,16.45,2.39,21.42,2.48,10.86.2,16.97-1.3,28.82-.09,5.37.55,9.69,1.43,12.4,2.05,1.12.1,2.85.23,4.96.26,10.43.17,13.25-2.16,25.83-3.39,5.33-.52,10.27-.64,15.91.26.03,0,.07.01.1.02,2.26.36,4.56.21,6.75-.43,2.98-.86,6.22-1.66,9.72-2.33,3.57-.68,6.93-1.14,10.04-1.43,3.6-.52,9.78-1.92,16.17-6.13,4.6-3.03,7.72-6.47,9.65-9"
      />
      <path
        style={styles.five}
        d="m585.52,607.35c-3.09,1.54-6.57,3.06-10.43,4.43-7.42,2.63-14.25,4-19.96,4.7-4.55,1.35-11.18,3.12-19.3,4.57-7.09,1.26-13.66,1.97-19.57,2.35-15.91,2.53-35.38,7.35-56.48,3.13-.65-.13-29.35-3-29.35-3-1.34-.29-3.18-.53-5.34-.31-1.15.11-2.17.34-3.03.58"
      />
      <path d="m376.74,331.16c-.61-7.36,2.04-14.81,5.89-20.98-1.27,5.33-2.25,10.48-2.96,15.83-.27,1.75-.52,3.49-.93,5.25,0,0-2-.11-2-.11h0Z" />
      <path d="m380.27,291.99c.18,3.58.16,7.16.05,10.74-.62,4.12-.11,8.35,1.31,12.25,0,0-1.87.72-1.87.72-.84-2.08-1.34-4.3-1.52-6.53-.21-2.08.05-4.9.45-6.57.34-3.55.78-7.1,1.42-10.62,0,0,.17.01.17.01h0Z" />
      <path d="m417.37,247.22c-2.47,4.55-3.93,9.72-4.22,15.03-.38-5.3.42-10.77,2.4-15.87l1.82.84h0Z" />
      <path
        style={styles.eight}
        d="m421.3,251.79c.78.27,1.2.55,1.96,1.04.2.14.6.43,1.02.87.43.44.88,1.01,1.3,1.58.42.57.85,1.12,1.19,1.5.33.39.56.62.56.62,0,0,.89.98,2.02,1.89,1.12.92,2.48,1.74,2.95,1.98.86.46,1.41.6,2.22.72-.66-.49-1.1-.81-1.92-1.26-.45-.24-1.77-1.03-2.85-1.92-1.1-.88-1.96-1.82-1.96-1.82,0,0-.87-.92-1.7-2.08-.43-.57-.9-1.17-1.35-1.64-.45-.47-.89-.8-1.12-.95-.84-.55-1.49-.65-2.3-.52Z"
      />
      <path
        style={styles.eight}
        d="m423.14,242.34c1.11,1.04,1.59,1.5,2.09,1.97.48.41,1.8,1.81,2.79,3.3,1.01,1.46,1.88,2.86,1.88,2.86,0,0,.86,1.4,1.96,2.86,1.07,1.48,2.57,2.86,3.13,3.24,1,.74,1.73.97,2.75,1.11-.84-.61-1.34-1.03-2.27-1.74-.52-.36-1.93-1.66-2.98-3.1-1.07-1.41-1.93-2.81-1.93-2.81,0,0-.86-1.39-1.89-2.89-1.01-1.51-2.39-2.99-2.91-3.42-.93-.83-1.62-1.13-2.62-1.39Z"
      />
      <path
        style={styles.eight}
        d="m438.45,249.75c-.19-.41-.28-.71-.54-1.15-.14-.24-.57-.96-.9-1.64-.34-.68-.59-1.33-.59-1.33,0,0-.26-.64-.47-1.38-.22-.73-.39-1.55-.44-1.82-.09-.51-.22-.79-.36-1.22-.09.44-.17.75-.13,1.28.02.29.12,1.15.33,1.92.19.77.46,1.45.46,1.45,0,0,.25.68.63,1.39.37.71.87,1.42,1.06,1.64.33.42.61.58.97.85Z"
      />
      <path
        style={styles.eight}
        d="m456.97,238.49c.28-.64.48-1.07.65-1.85.1-.43.39-1.7.71-2.86.31-1.16.65-2.2.65-2.2,0,0,.33-1.04.75-2.17.41-1.13.92-2.34,1.09-2.74.32-.74.41-1.2.55-1.88-.45.54-.74.91-1.09,1.65-.19.4-.72,1.61-1.15,2.75-.43,1.14-.77,2.19-.77,2.19,0,0-.35,1.05-.66,2.23-.32,1.17-.59,2.47-.67,2.91-.15.8-.12,1.28-.07,1.97Z"
      />
      <path
        style={styles.eight}
        d="m471.36,213.43c-.4,1.03-.63,1.74-.77,3.02-.08.69-.29,2.78-.42,4.69-.14,1.92-.21,3.66-.21,3.66,0,0-.08,1.74-.12,3.67-.04,1.92-.03,4.02-.01,4.71.03,1.29.19,2.02.49,3.08.23-1.08.35-1.81.34-3.09,0-.69-.02-2.78.02-4.68.03-1.91.11-3.64.11-3.64,0,0,.08-1.73.22-3.64.13-1.9.34-3.98.41-4.67.13-1.27.07-2.01-.06-3.11Z"
      />
      <path
        style={styles.eight}
        d="m477.2,210.82c-.29,1.08-.44,1.82-.39,3.13.03.71.17,2.83.5,4.76.3,1.93.75,3.65.75,3.65,0,0,.42,1.72,1.07,3.57.63,1.85,1.5,3.79,1.82,4.43.57,1.18,1.06,1.76,1.82,2.57-.29-1.08-.51-1.78-1.06-2.94-.3-.63-1.16-2.53-1.78-4.34-.63-1.8-1.05-3.49-1.05-3.49,0,0-.43-1.69-.73-3.58-.32-1.89-.46-3.97-.49-4.66-.06-1.28-.2-2-.46-3.09Z"
      />
      <path
        style={styles.eight}
        d="m431.04,271.57c.62.53,1.16.81,2.11,1,.51.13,2.08.27,3.44.53.68.14,1.29.34,1.72.55.43.2.67.39.67.39,0,0,.26.16.59.49.34.33.75.84,1.13,1.42.39.58.76,1.22,1.13,1.75.37.53.73.93.92,1.12.71.69,1.33.94,2.14.97-.71-.41-1.06-.77-1.7-1.4-.17-.17-.5-.54-.85-1.05-.35-.5-.73-1.14-1.12-1.74-.39-.6-.83-1.16-1.21-1.52-.38-.37-.67-.55-.67-.55,0,0-.27-.21-.75-.44-.47-.23-1.15-.46-1.86-.6-1.43-.27-2.96-.41-3.44-.53-.91-.18-1.43-.22-2.23-.39Z"
      />
      <path
        style={styles.eight}
        d="m438.53,273.45c-.15-.28-.18-.47-.22-.82-.04-.19.04-.76.14-1.32.11-.57-.07-1.1-.07-1.1,0,0-.16-.53-.56-.94-.38-.43-.9-.77-1.07-.88-.32-.2-.53-.27-.83-.35.23.22.39.36.7.56.17.11.67.44,1.02.84.38.39.52.86.52.86,0,0,.16.45.06.99-.1.53-.19,1.16-.14,1.38.05.39.19.62.46.79Z"
      />
      <path
        style={styles.eight}
        d="m418.93,273.25c-.41.22-.67.52-.79,1.09-.08.3-.08,1.31.51,2.01.26.34.6.59.86.74.26.15.44.22.44.22,0,0,.71.32,1.56.34.84.04,1.72-.15,2.01-.22.53-.13.82-.27,1.22-.5-.47.01-.78.04-1.3.15-.28.06-1.14.25-1.92.21-.79-.02-1.44-.31-1.44-.31,0,0-.16-.07-.4-.2-.23-.14-.53-.36-.76-.66-.5-.59-.51-1.48-.44-1.73.1-.49.18-.76.44-1.15Z"
      />
      <path
        style={styles.eight}
        d="m423.79,270.38c1.08.2,1.8.29,2.91.64.26.09.75.28,1.21.71.46.4.86,1.08.82,1.72-.03.66-.46,1.26-.88,1.46-.22.12-.38.13-.59.15-.21-.01-.6.13-.66.24-.32.3-.26.73-.18.93.08.2.19.32.27.39.18.15.2.14.2.14,0,0,.14.09.37.15.23.07.49.1.73.2.51.19,1.12.79,1.33,1.6.1.4.1.82,0,1.19-.12.36-.34.71-.61.97-.54.53-1.15.85-1.43,1.02-1.08.62-1.78,1.12-2.31,2.1.92-.61,1.59-.74,2.72-1.38.3-.18.95-.5,1.62-1.16.33-.34.63-.78.81-1.32.16-.54.15-1.12.01-1.65-.28-1.06-1.08-1.87-1.83-2.15-.38-.15-.68-.18-.82-.23-.14-.04-.19-.07-.19-.07,0,0-.03-.01-.05-.04-.01-.01-.04-.04-.05-.08-.01-.04,0,0,0-.02.03,0-.08,0,.13-.01.2,0,.6-.05.92-.24.69-.35,1.28-1.19,1.33-2.16.05-1-.51-1.87-1.09-2.39-.58-.53-1.2-.77-1.51-.88-1.34-.39-2.14-.17-3.18.15Z"
      />
      <path
        style={styles.eight}
        d="m407.25,274.94c.95.22,1.59.3,2.65.48.28.06.85.17,1.45.5.6.33,1.19.9,1.4,1.62.22.72.33,1.68.94,2.21.29.26.55.35.71.43.15.07.24.11.24.11-.02.01.39.12.59.44.1.17.15.45.14.81,0,.36-.06.79.02,1.28.07.5.41,1.03.86,1.27.44.26.9.34,1.3.38.81.07,1.44-.06,1.71-.06.54-.03.95-.03,1.32.02.37.05.74.14,1.08.5-.12-.48-.5-.83-.92-1.01-.43-.19-.92-.26-1.51-.24-.33,0-.93.12-1.61.06-.69-.04-1.43-.35-1.5-1.04-.12-.68.18-1.6-.25-2.33-.2-.35-.49-.52-.66-.62-.17-.09-.26-.13-.26-.13.02-.01-.43-.13-.75-.41-.36-.28-.48-1.02-.73-1.89-.27-.93-1.04-1.66-1.75-2.04-.71-.4-1.37-.52-1.68-.58-1.18-.18-1.84,0-2.78.25Z"
      />
      <path
        style={styles.eight}
        d="m401.24,273.7c-.27-.71-.22-1.18-.02-1.95.06-.2.22-.63.58-1.02.36-.4.92-.75,1.51-.99.59-.24,1.19-.38,1.64-.49.45-.09.74-.15.74-.15,0,0,.29-.06.74-.12.45-.06,1.07-.11,1.7-.07.64.03,1.29.18,1.8.39.51.21.89.48,1.06.61.64.53.99.84,1.42,1.47-.08-.76-.34-1.32-1.06-1.93-.2-.16-.62-.46-1.2-.7-.57-.24-1.3-.4-1.98-.44-.69-.04-1.34.02-1.81.08-.47.06-.77.12-.77.12,0,0-.3.06-.76.16-.46.1-1.09.25-1.74.51-.64.25-1.28.65-1.72,1.13-.44.48-.63.99-.7,1.24-.26.93-.01,1.63.58,2.11Z"
      />
      <path
        style={styles.eight}
        d="m396,279.17c.32.65.42,1.09.58,1.87.04.21.1.64.06,1.17-.03.53-.18,1.15-.38,1.73-.2.58-.46,1.12-.65,1.51-.2.4-.32.65-.32.65,0,0-.13.25-.33.64-.21.38-.49.9-.88,1.35-.73.95-2.12,1.21-2.55,1.41-.79.3-1.31.71-1.52,1.42.52-.51.95-.6,1.71-.9.21-.08.64-.21,1.18-.43.54-.2,1.17-.62,1.59-1.14.44-.51.73-1.06.95-1.45.21-.4.34-.65.34-.65,0,0,.13-.26.33-.66.2-.4.46-.96.67-1.57.22-.61.37-1.28.41-1.87.04-.59-.03-1.07-.07-1.31-.18-.88-.53-1.35-1.12-1.77Z"
      />
      <path
        style={styles.eight}
        d="m386.58,295.56c-.05-1.11.02-1.79.11-3.02.01-.67.47-2.66,1.1-4.48.3-.91.59-1.79.76-2.46.19-.67.28-1.1.28-1.1,0,0,.1-.43.25-1.09.15-.66.35-1.54.69-2.39.65-1.73,2.11-3.22,2.59-3.7.9-.89,1.36-1.46,2-2.37-.99.5-1.65.86-2.6,1.78-.49.49-2.05,2.06-2.78,3.98-.37.94-.58,1.85-.73,2.52-.15.66-.24,1.09-.24,1.09,0,0-.09.42-.27,1.06-.17.64-.45,1.51-.76,2.42-.64,1.81-1.13,3.93-1.14,4.67-.09,1.34.15,2.15.72,3.1Z"
      />
      <path
        style={styles.eight}
        d="m404.85,276.63c-.61-.24-1.14-.41-1.73-.48-.59-.06-1.27-.07-2.08.32-.21.12-.53.31-.85.64-.32.33-.58.8-.78,1.29-.38.99-.74,2.08-1.12,3.16-.37,1.09-.73,2.06-1,2.88-.13.43-.15.87-.08,1.18.07.3.16.46.16.46,0,0,.07.16.27.4.19.23.52.49.93.68.41.2.9.35,1.41.46.51.12,1.09.25,1.74.24.65,0,1.39-.24,1.89-.7.51-.44.82-.97,1.07-1.44.47-.95.73-1.75.86-2.16.44-1.51.39-2.45.15-3.74-.39,1.25-.68,2.04-1.1,3.44-.12.37-.37,1.15-.8,2-.21.42-.48.84-.83,1.14-.35.31-.77.45-1.25.46-.49,0-1-.1-1.49-.21-.48-.11-.9-.24-1.21-.39-.31-.15-.51-.31-.61-.43-.1-.12-.13-.2-.13-.2.02-.02-.21-.25-.01-.92.22-.7.61-1.76.98-2.82.38-1.06.77-2.22,1.11-3.11.33-.89.86-1.28,1.17-1.44.56-.29,1.11-.38,1.64-.48.54-.09,1.07-.16,1.72-.24Z"
      />
      <path
        style={styles.eight}
        d="m456.99,270.57c-1.54.32-2.53.41-4.25.53-.47.02-1.41.04-2.53-.2-.57-.12-1.24-.3-2.01-.34-.76-.05-1.7.18-2.33.75-1.3,1.1-1.48,2.91-1.07,3.95.18.54.42.93.6,1.19.19.26.31.38.31.38,0,0,.11.14.34.35.23.21.58.5,1.06.76.95.53,2.41.83,3.87.68,1.47-.15,2.81-.6,3.88-.53.51.01,1.03.18,1.6.21.56.04,1.1-.13,1.37-.31,1.04-.67,1.27-1.65,1.26-2.39-.02-.77-.27-1.41-.57-2.13-.07.79-.13,1.44-.3,2.03-.16.56-.46,1.13-.99,1.46-.15.08-.34.16-.71.14-.36,0-.91-.18-1.61-.21-1.37-.07-2.79.42-4.05.54-1.27.13-2.46-.13-3.2-.54-.74-.4-1.07-.84-1.07-.84,0,0-.09-.1-.23-.29-.14-.19-.31-.48-.44-.86-.12-.38-.19-.84-.07-1.33.11-.49.38-.99.79-1.34.79-.75,2.12-.42,3.32-.15,1.25.27,2.32.25,2.84.22,1.93-.15,2.99-.72,4.19-1.73Z"
      />
      <path
        style={styles.eight}
        d="m452.9,264.73c-.44.39-.69.8-.76,1.54-.01.2-.01.61.14,1.08.14.47.46.98.88,1.35.41.37.91.6,1.29.7.38.1.63.1.63.1,0,0,.25.02.62,0,.37-.02.86-.1,1.36-.11.5-.01.96.08,1.34.31.38.22.8.39,1.03.39.4.04.8-.14.92-.47.12-.29.02-.69-.3-.77.22.22.11.48,0,.62-.11.13-.31.2-.59.18-.16,0-.45-.11-.86-.34-.41-.25-1.03-.39-1.56-.36-.54.01-1.02.09-1.38.11-.35.03-.57,0-.57,0,0,0-.22,0-.54-.08-.32-.08-.75-.28-1.1-.6-.36-.32-.63-.75-.75-1.15-.13-.4-.13-.75-.12-.93.05-.64.11-1,.31-1.56Z"
      />
      <path
        style={styles.eight}
        d="m457.43,263.45c.47.11.77.19,1.3.29.29.05,1.15.27,1.86.68.72.38,1.25.9,1.25.9,0,0,.13.13.33.33.19.21.44.51.62.86.19.35.3.74.29,1.07,0,.33-.14.57-.21.68-.29.39-.5.61-.97.7.46.15.93,0,1.27-.5.09-.14.26-.45.27-.87.02-.41-.12-.87-.33-1.26-.43-.77-1.03-1.28-1.03-1.28,0,0-.56-.55-1.32-.96-.74-.43-1.66-.67-1.97-.71-.57-.1-.9-.06-1.37.07Z"
      />
      <path
        style={styles.eight}
        d="m460.45,271.27c.76.19,1.29.26,2.09.49.21.07.66.22,1.04.59.39.35.59.97.59,1.61,0,.64-.1,1.28-.19,1.74-.09.46-.18.75-.18.75,0,0-.08.29-.24.73-.16.43-.39,1.05-.76,1.55-.36.51-.93.79-1.48.85-.55.05-1.04-.01-1.28-.03-.89-.1-1.48-.09-2.22.18.78.11,1.23.3,2.16.41.25.02.76.1,1.41.04.65-.05,1.47-.45,1.89-1.08.44-.61.66-1.24.84-1.7.17-.47.25-.77.25-.77,0,0,.39-1.22.4-2.65.01-.72-.24-1.56-.77-2.03-.5-.47-1.03-.65-1.29-.73-.96-.25-1.51-.1-2.27.08Z"
      />
      <path
        style={styles.eight}
        d="m464.61,266.34c1.02.15,1.7.22,2.89.11,1.29-.13,7.72-.75,7.72-.75-.02.06,1.65-.37,2.95.7,1.32,1.04,2.46,2.65,2.81,3.16.65.97,1.09,1.48,1.74,2.27-.21-1-.43-1.69-1.1-2.71-.38-.55-1.5-2.16-2.98-3.34-.75-.6-1.74-.88-2.4-.88-.67-.02-1.08.03-1.08.03,0,0-6.45.63-7.74.75-1.19.12-1.85.32-2.82.67Z"
      />
      <path
        style={styles.eight}
        d="m463.37,262.65c.45-.14.74-.25,1.21-.5.26-.14,1.04-.53,1.79-.82.75-.3,1.45-.49,1.45-.49,0,0,.7-.21,1.49-.35.79-.16,1.66-.24,1.95-.26.54-.04.84-.11,1.29-.22-.45-.11-.77-.17-1.32-.13-.3.02-1.19.1-1.99.26-.81.15-1.52.36-1.52.36,0,0-.72.2-1.48.5-.77.29-1.57.7-1.83.84-.48.26-.72.48-1.05.82Z"
      />
      <path
        style={styles.eight}
        d="m475.53,247.26c-.53-.1-.97-.18-1.47-.17-.48.02-1.04.1-1.65.47-.3.2-1.03.68-1.25,1.7-.21,1.03.35,2.07.86,2.84.26.39.53.8.93,1.13.4.34.98.45,1.38.37.41-.06.69-.21.87-.31.19-.1.28-.17.28-.17,0,0,.09-.07.26-.18.15-.11.36-.27.62-.46.47-.38,1.21-.55,2.14-.58.9-.05,2.03-.08,2.84-.71.39-.3.67-.7.77-1.11.11-.41.02-.77-.06-.97-.34-.76-.96-1.03-1.46-1.12-.52-.08-.97,0-1.5.11,1,.3,1.8.49,2.2,1.31.1.19.11.71-.45,1.14-.53.42-1.45.5-2.39.54-.47.03-.96.07-1.41.19-.45.11-.87.34-1.15.54-.27.2-.49.36-.64.48-.14.1-.22.16-.22.16,0,0-.08.05-.22.13-.14.08-.35.18-.6.22-.25.04-.49,0-.75-.21-.26-.2-.5-.56-.76-.94-.51-.74-.9-1.59-.74-2.23.13-.64.65-1.02.88-1.18.93-.54,1.66-.66,2.68-.99Z"
      />
      <path
        style={styles.eight}
        d="m518,255.87c.39,1.15.66,1.96,1.66,3.07.28.29.85.89,1.75,1.38.93.51,2.07.63,2.78,1.18.75.56,1.43,1.41,2.09,1.86.64.47,1.12.69,1.12.69,0,0,.45.26,1.26.44.4.09.9.13,1.44.06.56-.06,1.13-.31,1.61-.67.48-.37.89-.86,1.12-1.44.24-.57.28-1.24.09-1.79-.39-1.12-1.27-1.57-1.63-1.75-.74-.34-1.37-.36-1.92-.32-.56.05-1.05.19-1.63.38,1.19.24,2.03.28,3.15.77.28.14.92.5,1.15,1.21.26.68-.15,1.66-.89,2.2-.72.58-1.64.58-2.29.44-.65-.14-1.05-.37-1.05-.37,0,0-.41-.18-.97-.6-.58-.39-1.17-1.15-2.1-1.87-1.01-.73-2.19-.83-2.89-1.25-.76-.4-1.27-.93-1.52-1.19-.91-.99-1.44-1.6-2.33-2.44Z"
      />
      <path
        style={styles.eight}
        d="m517.09,266.82c.67-.19,1.23-.27,1.8-.32.57-.05,1.16-.08,1.9-.03.4.03,1.22.12,2.11.55.89.42,1.82,1.18,2.43,2.12.61.92.95,2.02.74,2.57-.21.56-.67,1.08-.63,1.09,0,0-.09.12-.21.39-.13.27-.27.74-.2,1.27.1,1.08.89,2.06,1.19,3.08.33,1.06.35,2.3.38,3.37.02,1.07.1,2,.19,2.46.32,1.66,1.02,2.69,2.31,3.26-.44-.54-.65-1.05-.83-1.59-.17-.53-.31-1.1-.44-1.86-.08-.41-.16-1.23-.17-2.31-.03-1.06-.04-2.39-.43-3.65-.19-.64-.5-1.2-.73-1.67-.24-.47-.39-.89-.42-1.21-.07-.63.28-.97.26-1,0,0,.07-.11.2-.3.13-.21.38-.5.55-1.04.17-.54.12-1.17-.06-1.76-.18-.58-.45-1.16-.81-1.71-.72-1.1-1.8-2-2.85-2.49-1.06-.51-2.03-.61-2.5-.64-.88-.05-1.56.1-2.16.33-.6.24-1.11.59-1.61,1.07Z"
      />
      <path
        style={styles.eight}
        d="m522.84,278.2c-.27-.46-.46-.76-.89-1.21-.23-.25-.91-.98-1.54-1.65-.61-.68-1.17-1.31-1.17-1.31,0,0-.55-.63-1.15-1.33-.59-.71-1.23-1.48-1.45-1.73-.39-.48-.66-.71-1.09-1.03.22.48.39.8.77,1.28.22.26.86,1.03,1.45,1.74.6.7,1.15,1.33,1.15,1.33,0,0,.56.63,1.18,1.31.63.67,1.32,1.41,1.55,1.66.43.45.72.65,1.18.93Z"
      />
      <path
        style={styles.eight}
        d="m502.86,262.45c-.37.37-.51.89-.47,1.39.05.5.25,1.04.73,1.54.25.27.85.72,1.62,1.03.77.33,1.69.57,2.45.95.77.36,1.33.9,1.53,1.41.22.5.17.85.17.85,0,0,0,.08-.03.22-.03.14-.09.33-.23.52-.28.41-1,.65-1.87.64-.86,0-1.87-.04-2.7.11-.84.14-1.5.43-1.81.62-1.12.68-1.55,1.54-1.52,2.58.22-.47.49-.78.79-1.07.3-.28.64-.55,1.12-.82.25-.15.81-.4,1.55-.52.75-.14,1.64-.1,2.58-.1.47-.01.95-.06,1.4-.21.45-.14.84-.43,1.08-.74.48-.67.39-1.22.42-1.2,0,0,0-.12-.01-.34-.02-.21-.08-.52-.23-.85-.27-.7-1.04-1.41-1.9-1.8-.86-.42-1.79-.66-2.49-.97-.71-.29-1.18-.66-1.38-.86-.36-.37-.57-.74-.72-1.1-.14-.37-.24-.77-.1-1.27Z"
      />
      <path
        style={styles.eight}
        d="m480.79,268.29c.65.13,1.09.2,1.86.16.83-.04,5-.24,5-.24,0,0,4.17-.19,5-.24.77-.04,1.2-.15,1.84-.34-.65-.13-1.09-.2-1.86-.16-.83.04-5,.24-5,.24,0,0-4.17.19-5,.24-.77.04-1.2.15-1.84.34Z"
      />
      <path
        style={styles.eight}
        d="m478.92,254.54c-.65.29-.9,1.06-.55,1.89.09.23.36.64.73,1.04.36.41.79.85.98,1.36.21.49.06.99-.08,1.38-.16.4-.26.65-.26.65,0,0-.12.26-.21.73-.11.49.07,1.15.27,1.7.42,1.1.36,2.4.33,2.83-.05.79-.03,1.26.05,1.96.26-.65.42-1.1.48-1.92.03-.43.1-1.84-.36-3.04-.21-.58-.34-1.08-.25-1.44.07-.37.18-.62.18-.62,0,0,.1-.26.26-.65.16-.39.36-1.1.09-1.76-.25-.64-.73-1.13-1.07-1.52-.35-.39-.57-.73-.65-.91-.15-.32-.19-.59-.2-.86,0-.26.04-.54.26-.81Z"
      />
      <path
        style={styles.eight}
        d="m483.24,254.59c.7.72,1.22,1.15,1.96,1.93.19.22.57.66.73,1.33.09.34.14.75.23,1.2.1.44.24.97.64,1.37.39.41.96.55,1.4.56.45,0,.85-.09,1.16-.18.58-.19.96-.31.96-.31,0,0,.09-.02.25-.07.16-.04.38-.08.64-.08.53-.02,1.28.2,1.9.69.63.49,1.09,1.21,1.29,1.89.21.68.21,1.28.2,1.58-.05,1.1-.11,1.74-.24,2.73.58-.81.93-1.47,1-2.71,0-.33.01-1.02-.23-1.82-.23-.81-.79-1.69-1.55-2.27-.75-.6-1.68-.88-2.38-.86-.35,0-.63.06-.81.11-.18.05-.28.08-.28.08,0,0-.38.12-.96.31-.52.17-1.36.28-1.8-.19-.47-.46-.47-1.43-.68-2.22-.21-.85-.69-1.41-.91-1.65-.88-.91-1.57-1.12-2.52-1.42Z"
      />
      <path
        style={styles.eight}
        d="m494.05,267.6c-.29.45-.2,1.01.02,1.44.23.46.66.82,1.17,1.13.28.19.83.46,1.25,1.01.44.55.76,1.4,1.12,2.23.34.85.89,1.63,1.34,2.09.45.48.75.75.75.75,0,0,.3.27.77.7.46.42,1.07.97,1.55,1.66.48.68.78,1.5.73,2.19-.05.69-.27,1.33-.32,1.67-.13.61-.08,1.21.15,1.65.21.45.63.84,1.16.86-.44-.28-.55-.67-.62-1.04-.06-.4-.05-.77.07-1.32.04-.3.27-.89.34-1.78.07-.9-.32-1.91-.87-2.67-.54-.78-1.2-1.37-1.67-1.79-.46-.42-.76-.69-.76-.69,0,0-.3-.27-.71-.7-.42-.44-.86-1.07-1.19-1.87-.34-.79-.67-1.71-1.22-2.4-.55-.71-1.21-1.01-1.46-1.19-.51-.31-.84-.51-1.11-.8-.26-.28-.52-.6-.49-1.12Z"
      />
      <path
        style={styles.eight}
        d="m469.81,266.28c.64,1.1,1.1,1.82,2.12,2.89.28.28.81.88,1.58,1.57.39.34.85.69,1.39.96.54.27,1.13.46,1.7.57,1.15.22,2.22.22,2.99.26.77.04,1.25.08,1.25.08,0,0,1.97.15,4.06.74,2.1.55,4.25,1.55,4.94,1.92,1.28.68,2.06.97,3.24,1.44-.83-.97-1.47-1.58-2.8-2.29-.71-.4-2.95-1.44-5.13-2-2.17-.61-4.22-.76-4.22-.76,0,0-.51-.05-1.3-.09-.78-.04-1.83-.05-2.85-.25-2.09-.27-3.56-2.27-4.16-2.81-1.03-1.06-1.73-1.55-2.81-2.23Z"
      />
      <path
        style={styles.eight}
        d="m530,281.37c-.06.79.14,1.53.57,2.16.43.63,1.09,1.21,2.14,1.49.28.07.75.14,1.24.11.49-.01,1.05-.09,1.52-.02.47.06.78.3,1.11.82.34.51.57,1.19,1.14,1.85.28.33.74.62,1.22.69.49.08.96-.06,1.31-.25.7-.4,1.09-1,1.35-1.42.26-.43.45-.74.6-.93.14-.19.23-.27.23-.27,0,0,.07-.08.28-.25.18-.18.6-.39.95-1.02.34-.64.26-1.5-.1-2.17-.37-.71-1.05-1.21-1.67-1.56-.65-.36-1.23-.64-1.77-1.01-.54-.36-1.04-.75-1.47-1.14-.86-.78-1.45-1.54-1.73-1.92-.5-.7-.89-1.33-1.42-1.86-.5-.51-1.22-.97-2.04-.89.74.32,1.03.87,1.34,1.42.31.56.58,1.22,1.15,2.02.3.42.94,1.26,1.89,2.12.47.43,1.02.86,1.62,1.25.59.4,1.28.73,1.83,1.04.56.32,1,.67,1.21,1.08.23.42.23.83.1,1.05-.11.23-.42.45-.64.64-.23.18-.37.33-.37.33,0,0-.14.13-.34.39-.2.25-.42.63-.66,1.02-.24.4-.52.78-.92,1-.38.2-.72.18-1.05-.19-.35-.38-.61-1.05-1.01-1.69-.21-.32-.46-.65-.81-.91-.35-.27-.78-.41-1.14-.46-.74-.09-1.3,0-1.73.01-.43.02-.71-.03-.92-.07-.74-.19-1.29-.53-1.77-.91-.48-.39-.91-.85-1.23-1.58Z"
      />
      <path
        style={styles.eight}
        d="m544.04,274.4c.47.31.79.49,1.37.74.31.13,1.27.55,1.93,1.2.33.32.58.68.73.96.15.28.2.48.2.48,0,0,.07.19.11.51.04.31.02.74-.15,1.16-.16.41-.46.79-.78.99-.32.21-.64.27-.79.29-.58.05-.92.02-1.43-.18.35.43.78.66,1.49.6.19-.02.58-.09.98-.36.4-.26.76-.7.94-1.19.2-.48.22-1,.17-1.36-.05-.36-.13-.58-.13-.58,0,0-.06-.23-.23-.55-.17-.32-.45-.72-.81-1.07-.73-.72-1.74-1.15-2.06-1.29-.61-.25-.98-.3-1.54-.35Z"
      />
      <path
        style={styles.eight}
        d="m522.36,343.17c.77-.66,1.24-1.16,2.09-1.82.23-.17.73-.5,1.41-.55.67-.06,1.5.22,2.12.74.63.5,1,1.2,1.2,1.74.2.54.25.92.25.92,0,0,.08.37.11.97.03.59.01,1.42-.13,2.25-.14.84-.43,1.67-.8,2.31-.36.64-.79,1.07-1.01,1.27-.83.71-1.33,1.09-2.27,1.48,1.01.1,1.83-.09,2.79-.91.25-.23.75-.72,1.16-1.45.42-.72.74-1.65.89-2.56.16-.91.18-1.78.14-2.43-.04-.65-.13-1.07-.13-1.07,0,0-.06-.42-.29-1.05-.22-.62-.67-1.47-1.44-2.08-.74-.62-1.77-.99-2.66-.91-.9.07-1.53.5-1.8.7-1.01.81-1.27,1.51-1.64,2.44Z"
      />
      <path
        style={styles.eight}
        d="m525.04,334.92c.34,0,.56-.02.94-.13.2-.06.81-.27,1.35-.5.54-.23,1.01-.48,1.01-.48,0,0,.48-.24.97-.55.5-.3,1.02-.68,1.19-.81.31-.24.46-.42.65-.69-.3.15-.5.25-.81.49-.17.13-.68.5-1.17.8-.49.3-.96.54-.96.54,0,0-.47.24-1,.47-.53.23-1.13.43-1.33.49-.37.12-.57.22-.86.38Z"
      />
      <path d="m571.52,287.29c.66,0,1.32-.32,1.97-.32-.66,0-1.32.32-1.97.32h0Z" />
      <path d="m439.52,355.35c.49-2.39,1.14-4.79,2.06-7.06s2.26-3.98,4.29-5.29c.9-.59,1.87-1.07,2.86-1.49.5-.21,1-.4,1.52-.57.32-.11.65-.21.98-.3.23-.06,1.5-.36.67-.19-.76.15.55-.04.7-.05.23-.01.46-.02.69-.03.37-.02.75-.04,1.12-.04.8,0,1.72,0,2.38.51.12.09.84-.13.99-.15s.86-.26.99-.17c-.78-.59-1.93-.53-2.86-.49s-1.94.09-2.9.23c-2.36.34-4.62.84-6.81,1.83-2.42,1.09-4.56,2.63-5.85,5-1.43,2.61-2.19,5.69-2.79,8.59.05-.22,1.92-.07,1.97-.32h0Z" />
      <path d="m465.78,284.5c1.1.77,2.29,1.6,3.67,1.74.6.06,1.19-.05,1.77-.14.63-.1,1.26-.19,1.88-.34.35-.09.69-.19,1.04-.29.2-.05.4-.09.59-.15-1.57.43-1.37.18-.78.2.55.02,1.09.15,1.6.37,1.1.48,1.97,1.37,2.42,2.49.06.15,1.93-.44,1.97-.32-.44-1.09-1.27-1.96-2.34-2.45s-2.18-.42-3.35-.23c-.63.1-1.26.19-1.88.35-.35.09-.69.2-1.04.29l-.38.1,1.09-.18c-.15-.03-.36,0-.52,0-1.42-.11-2.64-.96-3.77-1.75-.13-.09-.83.12-.99.15s-.85.26-.99.17h0Z" />
      <path d="m478.89,291.85c3.76,1.08,7.63,2.32,9.96,5.69,1.1,1.59,2.01,3.29,3.83,4.16,1.64.79,3.52.95,5.13,1.79.84.44,1.38,1.14,2.11,1.7.62.48,1.2.41,1.92.29.93-.16,1.58-.72,2.32-1.27.99-.73,1.99-1.47,2.98-2.2s1.99-1.47,2.98-2.2c.8-.59,1.73-1.16,2.32-1.99,1.34-1.88-.44-3.83-1.97-4.87-4.14-2.81-9.5-2.16-14.05-3.86-.27-.1-.72.26-.99.16,4.07,1.52,8.62,1.18,12.58,3.03,1.62.76,3.86,2.2,3.93,4.21.03.96-.57,1.71-1.26,2.3-.86.74-1.82,1.38-2.74,2.05-1.13.83-2.25,1.66-3.38,2.49-.53.39-1.06.78-1.59,1.17-.56.41-1.11.91-1.8,1.06l.97-.16c-.93.07-1.5-.63-2.11-1.22s-1.34-.98-2.13-1.27c-1.56-.59-3.26-.8-4.71-1.65-1.6-.93-2.43-2.61-3.47-4.07s-2.45-2.6-4.06-3.43c-1.83-.94-3.81-1.53-5.78-2.09-.29-.08-.7.24-.99.16h0Z" />
      <path d="m427.91,289.94c.47-1.33,1.71-2.08,3.01-2.44.11-.03.38-.14.49-.12l-.27.04c.2-.02.39-.04.59-.05.44-.03.88-.05,1.32-.06.71-.03,1.41-.08,2.1-.18,1.28-.19,2.42-.48,3.52-1.19.6-.39,1.18-.83,1.83-1.14.75-.36,1.45-.28,2.25-.09,1.64.39,3.14,1.23,4.81,1.53,1.43.26,2.83.15,4.26-.09s2.68-.77,3.99-1.4c.73-.35,1.15-.58,1.89-.4s1.21.68,1.51,1.34c.55,1.21.62,2.56,1.77,3.42,1.03.78,2.41.97,3.66,1.06,2.28.16,5.84-.04,6.72,2.7.45,1.4.14,2.88,1.54,3.78,1.04.67,2.41.95,2.93,2.2s.1,2.74.32,4.07c.24,1.48,1.37,2.22,2.66,2.77,1.4.6,2.73,1.09,3.68,2.36.87,1.16,1.31,2.66,1.18,4.11s-.89,2.78-2.03,3.7c-.58.47-1.25.82-1.96,1.05-.17.05-.41.08-.56.15.55-.08.59-.09.12-.04-.11,0-.23,0-.34,0-.4,0-.8-.06-1.19-.15-.3-.07-.69.23-.99.16,2.36.53,5.3-.34,6.82-2.24,1.78-2.22,1.38-5.57-.61-7.53-1.05-1.04-2.47-1.37-3.76-2.01s-2.04-1.69-2.1-3.2c-.06-1.4.31-3.09-.82-4.18-.97-.93-2.58-1.05-3.27-2.29-.73-1.31-.23-2.94-1.39-4.09-.97-.96-2.45-1.26-3.77-1.38-2.6-.23-6.01.11-7.07-2.91-.49-1.41-.8-3.01-2.62-3.09-1.2-.05-2.45.36-3.51.89-.78.39-1.55.76-2.39,1-.93.26-1.89.28-2.84.19-1.86-.18-3.47-1.03-5.24-1.54-1.37-.4-2.94-.38-4.29.14-.8.31-1.48.84-2.19,1.3-.39.25-.79.49-1.23.67-.2.08-.4.15-.61.21-.43.1-.54.12-.3.08-.21.02-.42.04-.63.06-2.59.19-6.91-.06-8,3,.05-.15.93,0,.99-.16h0Z" />
      <path d="m452.91,300.59c2.17.23,4.31.11,6.45-.24.97-.16,1.93-.36,2.87-.63,1.04-.3,2.03-.7,3.02-.02.82.56,1.02,1.61.97,2.54-.02.46-.08.99-.31,1.4-.14.24-.31.31-.51.49.31-.01.33-.01.06,0-.17,0-.33.01-.5.02-2.65.1-5.29.19-7.94.29-.33.01-.66.15-.99.16,2.27-.08,4.55-.16,6.82-.25.81-.03,1.63-.02,2.43-.14.57-.09,1.21-.12,1.56-.65.41-.64.43-1.66.32-2.39-.13-.84-.59-1.58-1.42-1.88-1.24-.44-2.87.09-4.06.45-2.55.78-5.14.97-7.79.68-.32-.03-.67.19-.99.16h0Z" />
      <path d="m465.94,303.2c-.77.61-.23,1.7.4,2.18.83.64,1.91.97,2.18,2.1.56,2.37-1.95,3.88-3.09,5.56-1.04,1.53-.95,4.05,1.31,4.21,1.11.08,2.02.12,2.89.9.72.64,1.2,1.5,1.53,2.4.9,2.47.44,5.23,1.72,7.59.06.1.94-.25.99-.16-1.15-2.13-.88-4.57-1.48-6.85-.46-1.75-1.59-3.72-3.56-3.99-1.03-.14-2.18.09-2.78-.97-.42-.74-.38-1.72-.07-2.5.84-2.1,3.39-3.13,3.58-5.56.11-1.37-.8-1.99-1.84-2.64-.74-.46-1.76-1.67-.78-2.45-.23.18-.76-.02-.99.16h0Z" />
      <path d="m423.03,299.02c-.91-2.85,1.53-5.54,4.15-6.25.86-.23-.15-.06.74-.11.18-.01.37-.02.55-.02.41,0,.81.02,1.22.05.89.08,1.78.24,2.65.44,1.75.4,3.46.94,5.24,1.22.92.14,1.86.22,2.79.16.76-.05,1.51-.21,2.28-.22.61-.01,1.26.07,1.76.46.59.46.72,1.2.98,1.86.52,1.33,1.73,1.64,3.03,1.87s2.55.57,3.48,1.66c2.49,2.91,1.09,6.87-.79,9.66.12-.18.86.03.99-.16,1.81-2.68,3.16-6.3,1.12-9.25-.89-1.28-2.14-1.78-3.63-2.04s-2.71-.55-3.26-2.07c-.24-.67-.42-1.37-1.05-1.78s-1.45-.39-2.17-.33c-.81.07-1.61.21-2.43.21-1.02,0-2.02-.15-3.02-.35-1.89-.38-3.73-.99-5.63-1.32-1.54-.26-3.05-.28-4.59-.02s-3.07.8-4.19,2.04-1.69,2.86-1.18,4.46c.02.05.97-.2.99-.16h0Z" />
      <path d="m431.91,308.55c.66-2.18,1.31-4.72-.28-6.67-1.36-1.67-3.69-2.45-5.76-2.73-3.84-.52-8.1.63-10.91,3.33-1.47,1.41-2.7,3.3-3.12,5.32-.45,2.19.61,4.08,2.72,4.8,1.57.54,3.4.25,4.95-.22,2.28-.69,4.21-2.16,6.39-3.07.27-.11.54-.22.82-.31s.75-.32,1.04-.29l-.3.05.42-.04c.15,0,.29-.01.44-.01.33,0,.66.03.98.08,1.04.18,2.01.65,2.73,1.43,1.48,1.63,1.17,3.86-.3,5.38-3.17,3.28-8.89,1.74-11.65,5.68-1.15,1.64-1.55,3.7-1.85,5.64-.4,2.56-.76,5.13-1.13,7.7-.09.65-.19,1.29-.28,1.94-.09.58-.26,1.23-.16,1.81.2,1.14,1.57,1.1,2.46,1.05,1.74-.11,3.5-.53,5.06-1.32,4.31-2.18,7.08-6.56,9.91-10.3.78-1.02,1.64-2.02,2.31-3.12.54-.87.87-1.9.39-2.88-.86-1.76-3.18-2.12-4.51-3.4-.68-.65-1.14-1.48-.9-2.43-.03.12-.95.03-.99.16-.49,2,1.76,3.12,3.19,3.9,1.65.9,3.09,2.27,2.13,4.27-.5,1.04-1.32,1.95-2.01,2.86-.75.99-1.51,1.99-2.26,2.98-1.39,1.82-2.76,3.68-4.4,5.28-1.54,1.51-3.34,2.79-5.39,3.48-.23.08-.46.15-.7.21-.18.05-.4.11-.59.14.18-.03.41-.04-.26,0-.5.03-1.05.05-1.54-.08-1.16-.32-.95-1.48-.82-2.42.37-2.5.74-5,1.1-7.51.3-2.04.5-4.14,1.19-6.09s1.87-3.38,3.7-4.23c1.98-.91,4.2-1.07,6.24-1.82,1.82-.67,3.67-2,3.99-4.05s-1.21-3.75-3.05-4.29c-1.57-.46-3.43-.18-4.98.31-2.16.67-4.01,2.04-6.07,2.95-1.04.46-2.07.8-3.22.66-.89-.11-1.77-.43-2.48-.98-1.8-1.37-1.68-3.63-.88-5.54.75-1.8,1.98-3.39,3.51-4.6.74-.59,1.55-1.09,2.41-1.48.47-.22.96-.4,1.46-.55.3-.09.61-.17.92-.24.41-.09.2-.04.11-.03.24-.04.49-.05.73-.07,3.86-.25,9.51,1.42,9.22,6.2-.07,1.12-.39,2.21-.71,3.28.04-.13.94-.01.99-.16h0Z" />
      <path d="m434.6,313.43c1.8-1.47,4.06-2.02,6.32-2.28,2.61-.3,5.23-.56,7.84-.84.62-.07,1.24-.13,1.85-.2.33-.04.66-.07.99-.11s.84-.18,1.14-.04c.33.16.58.69.86.97.34.32.73.59,1.14.81,1.66.89,3.54,1.01,5.34,1.42,1.92.44,4.04,1.28,4.74,3.3.02.06.97-.21.99-.16-.82-2.36-3.54-3.11-5.74-3.5s-5.05-.84-6.26-3.08h-.02c-4.01.43-8.02.82-12.02,1.29-2.83.33-5.88.73-8.16,2.59.22-.18.76.03.99-.16h0Z" />
      <path d="m434.84,320.07c3.02.62,6.11.84,9.18.63,1.42-.1,2.83-.4,4.25-.45,1.14-.04,2.36.16,3.1,1.11.71.9.72,2.16.42,3.21-.21.74-1.31,2.35-.19,2.85.58.26,1.44.17,2.07.21.86.05,1.73.1,2.59.13,1.6.07,3.21.11,4.82.12,3.62.03,7.25-.08,10.86-.33.33-.02.66-.14.99-.16-6.41.44-12.84.45-19.25.01-.55-.04-1.33.01-1.53-.64-.15-.5.13-1.08.33-1.53.45-1.02.7-2.21.3-3.29-.96-2.6-4.29-1.75-6.36-1.52-3.53.4-7.1.22-10.58-.5-.31-.06-.68.22-.99.16h0Z" />
      <path d="m472.08,327.59c1.46.05,3.08.6,3.85,1.94.62,1.09.51,2.71-.52,3.51-.13.1-.28.19-.44.26-.09.04-.57.23-.23.13-.46.13-1.03.1-1.5.14-1.05.1-2.1.2-3.15.29-.96.09-2.06.34-3,.07-.8-.23-1.28-.94-1.71-1.61-.84-1.31-1.59-3.63.29-4.47-.29.13-.69.03-.99.16-1.83.82-1.17,3.08-.35,4.38.51.81,1.07,1.6,2.07,1.76s2.02-.07,2.97-.16c1.87-.17,3.75-.27,5.6-.6.71-.13,1.39-.35,1.83-.96s.56-1.35.46-2.05c-.29-2-2.39-2.91-4.19-2.97-.33-.01-.66.17-.99.16h0Z" />
      <path d="m444.13,342.74c.07-3.14.13-6.27.2-9.41.03-1.27.11-2.41,1.3-3.13s2.67-.95,3.98-1.22c1.74-.36,3.59-.55,5.37-.48,1.35.05,2.73.33,3.86,1.11.92.64,1.65,1.6,1.82,2.73.15.98-.14,1.94-.55,2.82-.94,2.02-2.73,3.93-2.48,6.3h.02c2.77-.33,5.56-.79,8.34-.96,2.18-.13,4.76.36,5.72,2.58.04.08.96-.23.99-.16-.75-1.74-2.63-2.5-4.42-2.6-2.11-.11-4.28.33-6.38.58-.57.07-1.14.14-1.71.2-.4.05-1.4.35-1.59-.05-.11-.23.02-.75.06-1,.07-.38.19-.75.33-1.12.55-1.39,1.48-2.6,2.11-3.95s.86-2.94-.02-4.28c-1.05-1.6-2.95-2.24-4.78-2.36s-3.78.12-5.62.41-3.58.58-5.25,1.22c-.85.32-1.66.84-1.93,1.75-.32,1.07-.18,2.35-.2,3.46-.06,2.57-.11,5.13-.17,7.7,0-.05.99-.09.99-.16h0Z" />
      <path d="m456.87,351.38c1.07-1.58,1.64-3.45,1.62-5.35-.01-.96-.21-1.87-.45-2.8-.27-1.03-.51-2.07-.13-3.1-.05.15-.93,0-.99.16-.38,1.03-.13,2.07.13,3.1.24.93.44,1.84.45,2.8.02,1.91-.55,3.78-1.62,5.35.13-.18.86.03.99-.16h0Z" />
      <path d="m500.23,315.63c-.48.48-1.03.84-1.66,1.08-.17.07-.35.12-.52.17-.14.04-.88.14-.32.08-.44.04-.87.06-1.32.06-1.63,0-3.25-.31-4.88-.2-1.08.07-2.29.24-3.29.67-1.28.56-2.24,1.59-3,2.73-.39.58-.73,1.19-1.05,1.81-.37.71-.82,1.45-.93,2.25-.26,1.77,1.48,2.17,2.83,2.46,3.23.69,6.47,1.26,9.77.74,2.55-.41,5.06-1.31,6.79-3.33.99-1.17,1.71-2.71,1.57-4.28s-1.29-2.81-2.32-3.94c-1.22-1.36-2.45-2.72-3.67-4.08-.93-1.03-2.54-2.34-2.08-3.91.43-1.44,2.04-2.21,3.38-2.57,1.64-.44,3.57-.12,5.2.26.9.21,1.79.48,2.67.79.69.24,1.43.56,1.89,1.15.42.53.44,1.25-.07,1.74s-1.34.6-2.02.72c-3,.53-5.55,2.49-6.69,5.35.06-.15.92,0,.99-.16,1.07-2.7,3.53-4.78,6.4-5.29.92-.16,2.4-.28,2.66-1.38.32-1.32-1.45-2.02-2.4-2.35-1.61-.56-3.28-.99-4.98-1.12-1.27-.1-2.5.04-3.74.26-1.37.24-2.86.72-3.77,1.84-1.06,1.3-.47,2.57.52,3.68,1.21,1.36,2.43,2.7,3.65,4.06,1.08,1.2,2.43,2.4,3.07,3.91,1.24,2.93-1.14,5.97-3.58,7.37-1.32.75-2.78,1.24-4.19,1.33-1.84.12-3.68-.09-5.48-.41-.87-.15-1.74-.34-2.6-.52-.71-.15-1.49-.27-2.1-.69-1.44-.99-.45-2.77.16-3.94.73-1.4,1.56-2.84,2.82-3.84.62-.5,1.4-.93,2.19-1.1.93-.2,2.01-.05,2.95.02,2.61.2,6.1.44,8.13-1.58-.19.19-.79-.03-.99.16h0Z" />
      <path d="m503.08,320.72c1.17.2,1.91,1.1,2.59,2,.75.98,1.47,1.98,2.66,2.45,1.44.56,3.16.48,4.67.35,1.1-.09,2.21-.27,3.3-.49,2.93-.61,5.93-1.86,8.23-3.8.51-.43.98-.95,1.13-1.62.2-.87-.15-1.73-.5-2.51-.71-1.58-1.45-3.14-2.2-4.7s-1.6-3.04-3.18-3.72c-1.06-.46-2.32-.35-3.41.02.31-.1.67-.06.99-.16.5-.17-.24-.09.04-.01.14.04.32.02.47.04.25.04.5.11.74.2.61.23,1.16.61,1.62,1.08.9.92,1.46,2.11,2,3.27.63,1.35,1.27,2.69,1.89,4.05.57,1.24.96,2.56-.02,3.7-.83.96-2.07,1.67-3.17,2.27-1.19.65-2.44,1.18-3.74,1.58-1.47.45-2.95.7-4.48.73-1.26.02-2.62,0-3.76-.59-1.96-.99-2.57-3.88-4.88-4.28-.31-.05-.68.21-.99.16h0Z" />
      <path d="m509.9,299.46l4.26,1.86c.97.42,2.96.91,2.37,2.36-.46,1.14-1.83,1.91-2.86,2.45-1.18.61-2.45,1.05-3.71,1.47.31-.11.67-.05.99-.16,1.26-.43,2.52-.86,3.71-1.47,1.03-.54,2.4-1.31,2.86-2.45.58-1.45-1.41-1.93-2.37-2.36l-4.26-1.86c-.1-.04-.39.06-.49.08s-.39.13-.49.08h0Z" />
      <path d="m519.44,295.95c.6,3.24,2.98,6.6,6.29,7.5,1.44.39,2.97.08,4.4-.2,2.03-.4,4.05-.86,6.07-1.3-.23.05-.66.09-.95.15-1.72.37-3.43.74-5.15,1.1s-3.37.34-4.98-.6c-2.47-1.44-4.17-4.01-4.69-6.81,0-.01-.99.16-.99.16h0Z" />
      <path d="m519.99,291.54c-.57-1.43-1.2-3.02-.86-4.58.25-1.15,1.04-2.21,2.22-2.49l-.36.06c-.36.06.22.07.31.09.2.04.39.09.58.17.39.15.75.38,1.09.62.73.5,1.39,1.1,2.17,1.53.94.52,1.89.52,2.93.48,1.11-.05,2.1.09,3,.78,1.64,1.25,2.28,3.28,3.34,4.97.55.88,1.22,1.67,2.14,2.18.97.53,2.07.74,3.09,1.16.91.37,1.78,1,2.2,1.91.45.98.08,1.86-.63,2.6s-1.31,1.3-1.61,2.25c-.27.83-.29,1.74-.07,2.59.11.43.29.85.53,1.23.1.17.22.32.35.47.06.07.21.16.23.25.04.14-.16.19-.31.28-1.01.58-2.05,1.11-3.12,1.55-2.26.93-4.61,1.39-7.03.84-4.32-.99-7.45-5.19-7.25-9.61,0,.06-.98.08-.99.16-.23,4.97,3.66,9.38,8.59,9.82,2.19.19,4.57-.27,6.63-.98,1.37-.48,2.69-1.11,3.95-1.83.11-.06.29-.14.37-.22.21-.21.2-.08,0-.29-.17-.18-.33-.38-.46-.6-.3-.47-.5-1.01-.6-1.56-.2-1.1,0-2.3.61-3.24s1.83-1.54,1.98-2.74c.15-1.28-.98-2.36-2.03-2.87-1.18-.57-2.51-.75-3.64-1.43-2.22-1.35-2.75-4.15-4.31-6.06-.82-1.01-1.88-1.72-3.21-1.77s-2.51.2-3.72-.47c-1.05-.58-1.89-1.49-2.98-2.02-.89-.44-1.77-.34-2.71-.14-1.16.24-1.95,1.23-2.25,2.34-.43,1.61.24,3.27.82,4.75.03.07.96-.22.99-.16h0Z" />
      <path d="m535.45,293c1.07-1.28,2.5-2.11,4.06-2.64.88-.3,1.78-.52,2.69-.68,1.06-.19,2.32-.48,3.4-.26.89.18,1.53.82,2.13,1.45.69.73,1.35,1.5,1.96,2.3,1.18,1.55,2.19,3.22,3.04,4.97.04.09.95-.24.99-.16-.8-1.66-1.75-3.24-2.85-4.71-.54-.72-1.11-1.42-1.72-2.09-.56-.62-1.14-1.31-1.89-1.69-1.3-.66-3.08-.15-4.45.07s-2.65.44-3.94.84c-1.69.53-3.26,1.38-4.41,2.76.16-.19.82.04.99-.16h0Z" />
      <path d="m540.31,297.41l8.24,3.15c1,.38,1.86.68,2.93.51.83-.13,1.78-.36,1.87-1.37,0,.08-.98.07-.99.16-.06.68-.59,1.09-1.22,1.26l.36-.06-.16.03.63-.1c-.86.06-1.65-.28-2.44-.59l-2.79-1.06-5.46-2.09c-.27-.1-.72.26-.99.16h0Z" />
      <path d="m554.5,281.23c1.02.93,1.96,1.94,2.81,3.03s1.75,2.28,2.03,3.64-.31,2.71-1.01,3.89c-.6,1.02-1.26,2.22-2.49,2.55l.24-.04-.18.03.74-.12c-2.7.2-3.39-3.35-5.22-4.63-.2-.14-.8.29-.99.16,1.32.93,1.81,2.52,2.93,3.64.54.54,1.24,1,2.02,1.01.45,0,.93-.1,1.37-.19,1.49-.31,2.36-2.1,2.99-3.33.41-.8.72-1.68.65-2.59s-.45-1.73-.92-2.48c-1.08-1.75-2.48-3.33-4-4.71-.16-.14-.84.3-.99.16h0Z" />
      <path d="m556.96,310.37c-1.47-3.01.56-6.09,1.63-8.86.88-2.28,1.17-5.55-1.31-6.98-.23-.13-.77.29-.99.16,2.48,1.43,2.19,4.7,1.31,6.98-1.07,2.77-3.09,5.85-1.63,8.86.05.09.95-.24.99-.16h0Z" />
      <path d="m540.17,301.79c3.59,2.78,7.32,5.64,11.79,6.8,2.19.57,4.51.8,6.38,2.18,1.72,1.27,2.87,3.15,3.98,4.94.07.11.92-.26.99-.16-1.1-1.8-2.25-3.67-3.98-4.94-1.87-1.38-4.19-1.61-6.38-2.18-4.47-1.16-8.2-4.02-11.79-6.8-.18-.14-.81.3-.99.16h0Z" />
      <path d="m568.07,303.21c1.09-1.54,1.61-3.66.52-5.35-.45-.7-1.17-1.27-2-1.43-.34-.07-.69-.04-1.03,0-.41.06-.83.11-1.22.23-.84.26-1.55.9-1.74,1.78,0-.02.45-.07.49-.08.05,0,.48-.04.49-.08.19-.88.9-1.52,1.74-1.78.08-.02.16-.04.24-.06-.74.1-.92.13-.53.1.12,0,.24,0,.35.01.21.02.42.07.62.14.45.16.85.44,1.18.79,1.59,1.67,1.11,4.15-.11,5.88.04-.05.42-.06.49-.08.08-.01.44-.01.49-.08h0Z" />
      <path d="m561.91,302.84c2.27.05,3.58,2.18,4.05,4.16.53,2.29.35,4.76,1.57,6.85.06.11.93-.26.99-.16-1.22-2.09-1.03-4.56-1.57-6.85-.46-1.98-1.78-4.12-4.05-4.16-.33,0-.66.17-.99.16h0Z" />
      <path d="m556.77,279.59c1.04,1.15,2.8.79,4.12,1.36,1.5.64,2.04,2.34,2.45,3.78.01.04.98-.19.99-.16-.41-1.44-.95-3.14-2.45-3.78-1.32-.56-3.08-.21-4.12-1.36-.04-.04-.44.07-.49.08s-.46.12-.49.08h0Z" />
      <path d="m570.37,286.58c-.22,1.74-.43,3.71.66,5.22.9,1.24,2.39,1.87,3.6,2.76.6.44,1.14.95,1.52,1.59.48.81.69,1.78,1.32,2.5.48.54,1.09.86,1.81.77.51-.06,1.09-.12,1.53-.42.7-.49,1.05-1.35,1.73-1.88.36-.28.76-.47,1.19-.63,0,0,.28-.07.12-.04-.14.02.31.01.31.01.24.01.48.06.71.11.3.07.69-.23.99-.16-1.02-.24-2.31-.08-3.29.29-.68.26-1.24.7-1.69,1.27-.48.61-.9,1.3-1.72,1.46l.97-.16c-1.75.1-2.23-1.88-2.88-3.1s-1.74-1.88-2.84-2.57-2.19-1.4-2.76-2.59c-.68-1.42-.48-3.08-.29-4.59-.01.09-.97.06-.99.16h0Z" />
      <path d="m569.44,313.34c2.94-.19,6.29-.35,8.68,1.7,1.11.94,1.68,2.25,2.06,3.63.33,1.19,1,2.93-.19,3.84-.55.42-1.28.63-1.91.88-.81.32-1.63.64-2.44.96s-1.58.64-2.39.92c-.19.07-.37.13-.56.19-.09.03-1.01.24-.53.16s-.45.01-.54.01c-.23,0-.46-.03-.69-.07-1.45-.27-2.6-1.31-3.64-2.28-.99-.92-2-1.9-2.59-3.13-.68-1.42-.48-2.94.5-4.17,1.08-1.34,2.73-2,4.28-2.61-.3.12-.68.04-.99.16-1.5.59-3.08,1.21-4.16,2.45-.97,1.12-1.32,2.57-.77,3.98s1.62,2.42,2.66,3.4c1.12,1.05,2.36,2.18,3.95,2.38,1.06.14,2.27-.1,3.29-.39,1.8-.5,3.54-1.32,5.27-2,1.32-.52,3.05-.91,2.94-2.66-.05-.74-.28-1.49-.48-2.2-.22-.79-.49-1.57-.91-2.27-2.02-3.37-6.41-3.27-9.83-3.06-.33.02-.66.14-.99.16h0Z" />
      <path d="m553.63,334.59c1.79-.74,2.35-2.93,1.91-4.68-.57-2.27-2.58-3.84-4.21-5.37-1.81-1.69-3.32-3.57-3.28-6.17.03-2.24.92-4.91-.55-6.91s-5.11-1.46-5.91-4.09c-.02-.05-.98.2-.99.16.71,2.31,3.63,2.16,5.26,3.43,1.96,1.52,1.47,4.28,1.27,6.42-.13,1.33-.11,2.64.46,3.88.47,1.04,1.22,1.93,2.02,2.74,1.63,1.65,3.67,3.06,4.65,5.25.84,1.89.49,4.64-1.62,5.51.3-.12.69-.03.99-.16h0Z" />
      <path d="m548.08,318.31c-1.51-.59-3.11-.94-4.73-1.02-1.31-.06-2.93.34-4.03-.57-.9-.75-1.2-2-1.67-3.03-.58-1.28-1.47-2.37-2.57-3.23-.18-.14-.81.3-.99.16,1.11.86,2,1.95,2.57,3.23.47,1.03.76,2.27,1.67,3.03,1.09.91,2.72.51,4.03.57,1.62.08,3.22.43,4.73,1.02.27.1.72-.26.99-.16h0Z" />
      <path d="m533.63,310.63c.79,1.85-.45,3.63-1.52,5.07-.55.74-1.11,1.5-1.42,2.38-.34.95-.31,1.98-.06,2.96.41,1.63,1.46,3.45,3.28,3.68,1.48.19,3.15-.43,4.54-.84,2.5-.75,5.01-1.49,7.51-2.24-.32.09-.67.07-.99.16-2.5.75-5.01,1.49-7.51,2.24-1.26.38-2.1.83-3.33.32-.89-.37-1.53-1.16-1.96-1.99-.5-.98-.81-2.11-.74-3.22s.57-2.07,1.19-2.96c1.14-1.64,2.91-3.57,1.99-5.71-.03-.08-.96.23-.99.16h0Z" />
      <path d="m553.66,334.29c-6.51.33-12.95-3.12-16.28-8.72-.07-.11-.93.26-.99.16,3.33,5.6,9.77,9.05,16.28,8.72.33-.02.66-.14.99-.16h0Z" />
      <path d="m547.47,316.33c1.37.34,2.2,1.5,3.11,2.48,1.15,1.25,2.49,1.86,4.17,2.09,2.28.32,6.43.37,7.07-2.56-.02.11-.96.03-.99.16-.26,1.2-1.39,2.23-2.62,2.37-1.33.16-2.96-.09-4.21-.53s-2.02-1.23-2.84-2.15c-.76-.86-1.54-1.74-2.7-2.02-.3-.07-.69.23-.99.16h0Z" />
      <path d="m554.38,328.33c.31,0,.61-.04.91-.09.34-.05.69-.1,1.03-.2.57-.17,1.11-.49,1.51-.93.86-.93,1.06-2.33.56-3.49,0-.02-.45.07-.49.08-.04,0-.49.1-.49.08.42.97.36,2.11-.2,3.02-.26.42-.63.78-1.05,1.04-.22.13-.45.24-.69.32-.13.04-.25.07-.38.1.7-.1.91-.13.62-.11-.04,0-.09,0-.13,0-.07,0-.14,0-.21,0-.33,0-.66.16-.99.16h0Z" />
      <path d="m556.43,327.91c.08,1.37.68,2.69,1.69,3.64s2.42,1.49,3.82,1.46c.33,0,.66-.15.99-.16-1.4.02-2.8-.5-3.82-1.46s-1.6-2.27-1.69-3.64c0,.03-.99.12-.99.16h0Z" />
      <path d="m564.29,330.93c-1.18,1.04-1.65,2.78-.98,4.24.31.68.84,1.26,1.48,1.65.79.47,1.64.52,2.53.54s1.76.13,2.55.66c.7.46,1.28,1.1,2.02,1.5.6.33,1.23.48,1.92.38.56-.08,1.12-.13,1.59-.46-.24.17-.74-.02-.99.16-.03.02-.41.22-.41.23.43-.04.5-.05.22-.05h-.16c-.36-.06-.71-.18-1.03-.34-.64-.32-1.16-.82-1.73-1.26-.63-.49-1.29-.84-2.1-.93s-1.61,0-2.39-.17c-2.91-.65-3.74-4.37-1.54-6.3-.21.18-.77-.03-.99.16h0Z" />
      <path d="m564.9,325.84c.2,1.94.48,4.13,2.14,5.41,1.17.9,2.6.73,3.94.38.45-.12.89-.29,1.34-.45.2-.07.41-.15.62-.21.11-.03.23-.06.34-.1.26-.06.22-.06-.1.02h.17c.42.1.78.11,1.16.34s.72.6.96.99c.54.87.67,1.89.77,2.89.1,1.08.24,2.13.52,3.17.62,2.25,1.77,4.32,3.33,6.06.12.14.87-.29.99-.16-1.37-1.53-2.43-3.32-3.08-5.27-.33-.99-.56-2.02-.68-3.05s-.14-2.16-.54-3.17c-.32-.8-.91-1.56-1.75-1.85-.67-.22-1.35-.08-2.02.03-.71.12-1.36.34-2.04.58-.21.07-.41.15-.62.21-.11.03-.24.08-.36.1.04,0,.19-.02,0-.01-.47.01-.94-.1-1.37-.31-2.15-1.06-2.51-3.66-2.74-5.79,0,.01-.99.13-.99.16h0Z" />
      <path d="m578.06,349.85c-1.25-.1-2.07-1.09-2.8-2-.38-.48-.77-.97-1.26-1.35-.52-.4-1.12-.59-1.74-.76-1.13-.32-2.09-.78-2.76-1.78-.6-.89-.85-1.97-.66-3.03-.02.1-.97.05-.99.16-.18,1.06.07,2.14.66,3.03.67,1,1.63,1.47,2.76,1.78.59.17,1.18.34,1.68.72s.92.9,1.32,1.4c.72.91,1.55,1.91,2.8,2,.32.02.67-.18.99-.16h0Z" />
      <path d="m575.66,330.99c1.38-.47,2.39-1.82,2.42-3.28s-.96-2.84-2.32-3.35c-.11-.04-.38.06-.49.08s-.38.13-.49.08c1.36.51,2.34,1.88,2.32,3.35s-1.04,2.81-2.42,3.28c.31-.11.68-.05.99-.16h0Z" />
      <path d="m587.49,312.48c-.81-.11-1.96.11-2.58.66-1.05.93-1.13,2.41-.5,3.6.72,1.37,2.07,2.22,3.4,2.91,1.48.76,2.84,1.51,4.13,2.58,1.15.95,2.3,2,3.76,2.44,1.05.32,2.06.23,3.12.03,3.12-.61,3.06-4.4,2.17-6.77-.64-1.72-1.94-2.81-3.5-3.69-1.81-1.03-3.67-1.99-5.5-2.98-.24-.13-.76.28-.99.16,1.45.78,2.89,1.57,4.34,2.35,1.2.65,2.46,1.28,3.41,2.28,1.6,1.69,2.4,4.93,1.37,7.1-.23.48-.56.9-.99,1.2-.24.17-.5.28-.77.39-.35.13-.12.02-.05.01-.21.01-.42,0-.63-.03-1.27-.14-2.37-.76-3.37-1.52-1.12-.85-2.12-1.84-3.33-2.56s-2.58-1.24-3.75-2.07c-1.09-.77-2.08-1.9-2.22-3.27-.12-1.22.65-2.51,1.87-2.8l-.84.14.15-.02-.15.02c.17,0,.31,0,.48.02.32.04.67-.2.99-.16h0Z" />
      <path d="m591.38,308.59c.1-.83.53-1.52,1.17-2.04.76-.61,1.38-.8,2.25-.71,1.96.19,4.03,1.19,5.66,2.27,1.53,1.01,2.85,2.56,2.9,4.48.03.93-.32,1.69-.8,2.46-.46.72-.94,1.42-.9,2.32,0-.03.99-.11.99-.16-.05-1.03.61-1.8,1.1-2.64.53-.9.73-1.86.51-2.89-.46-2.21-2.36-3.6-4.26-4.55s-3.84-1.69-6-1.34c-1.66.27-3.38,1.1-3.61,2.97.01-.08.98-.06.99-.16h0Z" />
      <path d="m553.49,334.53c1.78,1.59,3.5,3.24,5.16,4.96s3.24,3.32,4.54,5.19c1.1,1.58,1.58,3.38,1.02,5.25-.5,1.66-1.68,3.06-3.28,3.74.3-.13.69-.03.99-.16,1.6-.68,2.78-2.08,3.28-3.74.56-1.87.08-3.67-1.02-5.25-1.3-1.87-2.96-3.55-4.54-5.19-1.65-1.72-3.37-3.37-5.16-4.96-.16-.14-.83.3-.99.16h0Z" />
      <path d="m559.22,357.25c1.34-2.91-.38-5.9-1.09-8.73-.81-3.2.13-6.61.65-9.79-.02.1-.97.05-.99.16-.52,3.18-1.45,6.58-.65,9.79.71,2.83,2.42,5.81,1.09,8.73.07-.16.91.01.99-.16h0Z" />
      <path d="m540.65,344.33c-.15.28-.37.48-.66.62-.07.03-.36.09-.39.14,0,0,.54-.07.14-.01-.06,0-.13.02-.19.02-.49.07-.99.13-1.47.25-.68.17-1.18.56-1.29,1.28s.05,1.59.2,2.33c.32,1.52.93,3.1,1.9,4.33.45.56,1.03,1.04,1.73,1.23.6.16,1.2.08,1.8-.02.73-.12,1.4-.28,2.03-.67.75-.46,1.37-1.11,1.93-1.79-.05.06-.41.06-.49.08-.09.02-.43,0-.49.08-.56.68-1.17,1.33-1.93,1.79-.19.12-.38.22-.59.31-.09.04-.73.21-.3.15-.42.05-.89-.04-1.29-.19-1.56-.63-2.42-2.54-2.93-4.02-.3-.86-.49-1.74-.58-2.65-.08-.78-.12-1.7.64-2.16.16-.1.33-.14.5-.21-.31.12.07-.02.18-.03.31-.04.61-.09.91-.15.63-.11,1.28-.27,1.61-.87-.1.17-.89-.02-.99.16h0Z" />
      <path d="m523.36,321.52c1.37,3.02,3.41,5.71,5.96,7.83.56.46,1.16.86,1.71,1.34.7.62.88,1.29,1.22,2.12.56,1.33,2.04,1.45,3.27,1.77,3.4.89,3.9,4.53,4.32,7.5.23,1.62.56,3.26,1.55,4.6.9,1.21,2.24,1.91,3.77,1.75s2.92-.65,4.27-1.43c.89-.52,1.74-1.09,2.68-1.51s1.68-.57,2.68-.28c.29.09.7-.24.99-.16-1.16-.34-2.5-.13-3.65.21-1.71.51-3.1,1.66-4.69,2.44-.69.34-1.38.66-2.16.58s-1.56-.43-2.17-.97c-1.16-1.02-1.74-2.52-2.05-4-.59-2.9-.44-7.08-3.61-8.57-1.15-.54-2.64-.43-3.64-1.28-.59-.51-.64-1.25-.97-1.91-.34-.7-.98-1.16-1.59-1.62-2.99-2.25-5.36-5.17-6.91-8.57-.04-.09-.95.23-.99.16h0Z" />
      <path d="m565.77,354.3c.36-.22.73-.41,1.13-.56.2-.07.4-.14.6-.19.74-.2.03.02-.04-.02.08.05.51-.04.61-.05.87-.03,1.74.11,2.55.42,1.54.59,2.81,1.79,3.54,3.27.62,1.26,1.1,3.31-.13,4.38-.15.13-.31.23-.49.31-.04.02-.27.09-.27.1.02-.06.53,0,.11-.03s-.84-.05-1.26-.13c-.79-.16-1.55-.44-2.26-.82-1.64-.88-2.97-2.28-3.76-3.97-.04-.09-.95.24-.99.16,1.55,3.33,5.34,5.61,9.01,4.68,2.13-.54,1.81-3.16,1.1-4.7-.84-1.81-2.49-3.2-4.43-3.65-1.01-.24-2.01-.18-3.03-.02s-2.04.43-2.97.99c.26-.16.72,0,.99-.16h0Z" />
      <path d="m546.16,348.25c1.78,2.07.48,4.88.24,7.22-.15,1.42.27,2.62.74,3.93.43,1.22.61,2.37.19,3.61-.63,1.87-2.7,4.56-.92,6.43,1.39,1.46,3.92.69,5.6.29.22-.05.45-.12.67-.15-.3.04.38-.01.44-.01.32,0,.64,0,.95.03.63.06,1.26.2,1.86.4,1.27.44,2.4,1.18,3.31,2.17.13.14.87-.29.99-.16-1.58-1.72-3.88-2.7-6.22-2.61-.84.03-1.7.19-2.52.37-1.18.26-2.32.58-3.47-.02-2.43-1.26-.85-4.29-.06-6.05.53-1.17.77-2.31.44-3.58s-.98-2.44-1.05-3.77c-.14-2.69,1.84-5.9-.21-8.28-.12-.14-.88.29-.99.16h0Z" />
      <path d="m561.97,371.27c-1.1-2.38-1.58-5-1.35-7.62.2-2.18.92-5.28-1.49-6.56-.24-.13-.76.28-.99.16,2.4,1.28,1.68,4.38,1.49,6.56-.24,2.61.25,5.24,1.35,7.62.04.09.95-.23.99-.16h0Z" />
      <path d="m547.22,355.41c.17-.1.33-.19.52-.27.09-.04.18-.07.27-.09-.5.05-.55.06-.14.02.39-.02.79.03,1.17.1.74.15,1.45.44,2.12.79,1.44.75,2.79,1.71,4.44,1.93.32.04.67-.2.99-.16-2.06-.28-3.64-1.72-5.54-2.43-.85-.31-1.74-.48-2.64-.36-.76.1-1.51.2-2.17.61.26-.16.72,0,.99-.16h0Z" />
      <path d="m559.99,362.47c.77,1.09,1.9,1.88,3.21,2.15,1.1.23,2.71.03,2.9,1.51.09.7-.09,1.46-.17,2.16-.1.85-.2,1.7-.3,2.54s-.18,1.7-.3,2.54c-.1.74-.35,1.21-1.1,1.4l.14-.03c.75-.13.13-.08-.07-.1s-.4-.06-.61-.09c-.39-.06-.77-.1-1.17-.06-.45.05-.94.12-1.37.26.31-.1.67-.06.99-.16-.25.03-.23.05.03.05.14.01.28.03.42.05.21.03.42.07.63.1.3.04.59.04.9,0,.45-.06.97-.1,1.39-.27.95-.4.84-1.66.94-2.52.13-1.09.25-2.18.38-3.27.11-.91.42-2.03.22-2.94-.19-.85-.96-1.09-1.73-1.18-.83-.1-1.62-.2-2.38-.58s-1.46-.99-1.97-1.71c-.09-.12-.91.27-.99.16h0Z" />
      <path d="m567.26,365.76c.19-.08.39-.12.58-.2.22-.05.2-.06-.07-.02.12-.01.24-.02.36-.03.36-.02.72,0,1.07.05.73.11,1.44.36,2.07.74,1.15.71,2.02,1.85,2.41,3.15.19.64.36,1.46.06,2.09-.32.67-1.16.98-1.77,1.31-1.61.87-3.23,1.74-4.84,2.6.28-.15.71,0,.99-.16,1.42-.76,2.84-1.53,4.26-2.29.62-.33,1.33-.62,1.89-1.06s.69-1.11.6-1.81c-.32-2.56-2.49-4.58-5.04-4.78-1.15-.09-2.47.13-3.54.56.3-.12.68-.04.99-.16h0Z" />
      <path d="m539.2,368.91c-.22-.32-.55-.36-.91-.31s-.79.07-1.13.21c-.96.4-.88,1.91-.77,2.75.27,2.06,1.58,4,3.45,4.93.84.42,1.75.57,2.67.43s1.8-.27,2.4-1.04c1.43-1.86-.12-4.25-1.06-5.91-.34-.61-.64-1.22-.85-1.89-.16-.53-.32-1.1-.88-1.33-.44-.18-.94-.06-1.4.01-.49.08-.96.18-1.43.35.31-.11.68-.05.99-.16.11-.04.22-.08.32-.11s.38-.06.05-.04c-.39.02.05.02.11.03.08,0,.17.02.25.05.41.12.67.44.82.82.17.42.26.87.44,1.29.19.45.42.87.66,1.29.84,1.47,1.88,3.09,1.41,4.86-.2.77-.73,1.4-1.48,1.68-.13.05-.67.17-.14.06.44-.09.15-.04.02-.04-.21,0-.41-.02-.62-.06-.41-.07-.81-.2-1.19-.37-1.5-.7-2.66-2.05-3.22-3.59-.32-.86-.47-1.79-.37-2.7.05-.47.17-.99.56-1.3.06-.05.14-.07.19-.12.22-.1.05-.08-.5.06.05,0,.11,0,.16.02.2.08.33.13.46.32.09.12.91-.27.99-.16h0Z" />
      <path d="m541.66,356.85c-.44.96-.9,1.95-.98,3.02-.07.93.18,1.89.73,2.65s1.33,1.22,2.21,1.36c.4.06.79.04,1.19-.02.42-.06.85-.12,1.25-.24-.31.1-.67.06-.99.16-.06.02-.13.04-.19.06-.3.07-.12.05.55-.08-.12,0-.23.02-.35.01-.19,0-.39-.02-.58-.06-.36-.07-.72-.2-1.04-.38-.68-.39-1.19-1-1.5-1.72-.71-1.68-.04-3.37.68-4.92-.02.04-.44.07-.49.08-.06.01-.47.03-.49.08h0Z" />
      <path d="m535.04,355.33c-1.01.66-2.03,1.32-2.91,2.16-.79.75-1.9,1.94-1.47,3.12.38,1.04,1.57,1.64,2.61,1.8.51.07,1,.03,1.5-.05s1.02-.15,1.5-.35c1.14-.47,1.98-1.5,2.26-2.69,0,.02-.45.07-.49.08-.05,0-.48.04-.49.08-.22.94-.79,1.77-1.59,2.32-.2.14-.41.25-.63.35-.1.04-.19.08-.29.11-.07.02-.14.04-.21.06l-.19.05.63-.1c-.28-.02-.56.03-.85,0-.25-.03-.5-.08-.74-.15-.89-.27-1.9-.93-2.09-1.91-.22-1.07.75-2.11,1.45-2.8.89-.87,1.95-1.56,2.99-2.24-.1.07-.37.06-.49.08-.13.02-.38,0-.49.08h0Z" />
      <path d="m524.14,335.47c.66-1.27.39-2.82-.73-3.73-.5-.41-1.09-.57-1.71-.7-.56-.11-1.18-.2-1.63-.58-1.1-.94-.17-2.47.7-3.17,1.12-.89,2.56-1.24,3.92-1.57-.23.06-.66.08-.95.15-1.33.32-2.73.65-3.84,1.49-.89.67-1.93,2.16-.91,3.18.41.42,1.03.53,1.58.64.62.12,1.21.26,1.73.64,1.21.88,1.53,2.51.85,3.82.09-.17.89.02.99-.16h0Z" />
      <path d="m513.65,326.61c-1.14,1.19-2.6,2.91-1.63,4.64.41.74,1.13,1.27,1.67,1.92s1,1.36,1.36,2.12c.52,1.1.75,3.29,2.23,3.53,1.25.2,2.49-.51,3.35-1.31-.2.19-.78-.03-.99.16-.53.49-1.09.96-1.81,1.14l.92-.15c-1.33.19-1.85-1.16-2.2-2.18-.42-1.23-.99-2.38-1.82-3.39s-2.01-1.76-2.03-3.14,1.01-2.54,1.93-3.5c-.18.19-.8-.04-.99.16h0Z" />
      <path d="m433.96,360.81c-3.45,1.35-5.82,5.77-4.09,9.29.4.8,1.03,1.54,1.88,1.87.73.28,1.46.16,2.2.03,2.1-.36,3.58-2.01,5.09-3.38,1.92-1.74,3.85-3.49,5.77-5.23s3.9-3.36,5.7-5.17c.62-.63,1.29-1.78.51-2.58-.58-.6-1.63-.34-2.36-.23-1.69.26-3.33.66-4.89,1.38-3.97,1.83-7.15,5.25-8.59,9.38.05-.14.93,0,.99-.16,1.14-3.3,3.4-6.15,6.3-8.09,1.43-.96,3.01-1.7,4.67-2.17.81-.23,2.12-.66,2.83-.02.68.61.38,1.63-.11,2.25-.68.86-1.62,1.55-2.43,2.29-.87.79-1.75,1.58-2.62,2.37-1.71,1.55-3.43,3.11-5.14,4.66-.87.79-1.74,1.58-2.61,2.37-.73.66-1.46,1.35-2.31,1.85-.2.12-.41.23-.62.32-.12.05-.24.09-.37.14-.08.03-.33.04-.07.03.3-.01-.04-.02-.08-.03-.13-.02-.26-.02-.39-.04-.22-.04-.44-.11-.64-.2-.75-.34-1.32-1-1.69-1.73-1.8-3.52.57-8.01,4.05-9.37-.3.12-.68.04-.99.16h0Z" />
      <path d="m448.33,370.51c1.05-2.85,2.1-5.7,3.14-8.55.36-.99.81-2.66,2.06-2.88-.13.02-.06,0,.12.02.13.01.26,0,.39.02.29.02.58.07.86.11.55.09,1.11.19,1.65.33.98.25,1.99.63,2.72,1.36.85.86,1.09,2.07.84,3.22-.26,1.23-1.02,2.27-1.88,3.16-.95,1-2,1.91-3.04,2.82-2.37,2.08-4.85,4.04-7.43,5.86.24-.17.74.01.99-.16,2.51-1.78,4.94-3.68,7.26-5.71,1.1-.96,2.2-1.92,3.21-2.97.92-.97,1.74-2.1,1.93-3.46.18-1.26-.25-2.47-1.27-3.25-.92-.71-2.1-1.01-3.22-1.22-1.78-.34-4.47-.65-5.51,1.24-.39.7-.62,1.5-.89,2.25l-.95,2.58-1.98,5.39c.05-.15.93,0,.99-.16h0Z" />
      <path d="m427.23,352.74c-2.1-4.32.54-9.66,4.55-11.85.97-.53,1.76-.72,2.82-.28.81.33,1.44.98,1.85,1.75.97,1.8.69,3.87-.17,5.65-.58,1.2-1.37,2.28-2.17,3.34-.93,1.24-1.87,2.47-2.8,3.71.14-.19.84.04.99-.16.91-1.21,1.83-2.42,2.74-3.63.76-1.01,1.51-2.02,2.09-3.14,1-1.93,1.37-4.27.15-6.21-.52-.83-1.33-1.45-2.3-1.66-.81-.17-1.67,0-2.48.15-2.31.45-4.26,2.37-5.5,4.29-1.57,2.43-2.06,5.53-.76,8.19.04.09.95-.24.99-.16h0Z" />
      <path d="m413.92,360.79c-1.55-1.63-3.2-3.45-3.21-5.84s1.5-4.67,2.88-6.6c.66-.93,1.37-1.93,2.43-2.44.15-.07.31-.11.46-.18.25-.12-.29-.06-.03.02.24.07.5.05.74.14.48.18.86.52,1.12.96.55.9.63,2.07.66,3.11.02,1.1-.1,2.21-.36,3.28-.6,2.44-1.9,4.67-3.74,6.38.2-.19.78.03.99-.16,1.77-1.64,3.04-3.76,3.67-6.1.32-1.19.47-2.43.43-3.66-.03-1.05-.13-2.26-.75-3.15-1.13-1.63-3.92-.91-5.15.23-.94.87-1.67,2.03-2.37,3.1s-1.39,2.3-1.73,3.6c-.37,1.42-.25,2.86.41,4.18s1.62,2.3,2.58,3.31c.13.14.86-.29.99-.16h0Z" />
      <path d="m416.25,362.93c1.22,2.06,1.95,4.37,2.11,6.76.15,2.22-.11,4.71-1.25,6.67-.54.92-1.3,1.72-2.28,2.16-.21.1-.44.19-.67.24.27-.07-.32,0-.41-.01-.49-.03-.97-.17-1.4-.41-2.21-1.24-1.95-4.17-1.32-6.22.8-2.59,2.16-4.97,3.96-7-.17.19-.81-.04-.99.16-1.75,1.98-3.09,4.29-3.89,6.8-.7,2.18-1.03,5.48,1.56,6.58.85.36,1.71.27,2.59.12s1.74-.4,2.47-.97c1.93-1.52,2.53-4.24,2.63-6.57.14-2.96-.62-5.92-2.13-8.47-.07-.11-.93.26-.99.16h0Z" />
      <path d="m420.59,367.51l2.7-2.4,1.26-1.12c.45-.4.91-.89,1.54-1l-.92.15c1.28.01,1.55,1.93,1.69,2.89.19,1.35.09,2.72-.18,4.05-.29,1.4-.76,2.75-1.28,4.07-.39,1-.78,2.83-2.02,3.09l.97-.16c-1.86.1-2.04-2.47-3.22-3.38-.19-.14-.81.3-.99.16,1.25.96,1.4,3.62,3.42,3.35.68-.09,1.3-.16,1.74-.76.6-.83.91-1.96,1.27-2.91.84-2.2,1.51-4.53,1.36-6.91-.06-.99-.23-2.18-.75-3.05-.44-.72-1.05-.61-1.76-.49-1.11.19-1.84,1.06-2.66,1.78l-3.15,2.8c.21-.18.77.03.99-.16h0Z" />
      <path d="m422.54,379.96c-.72,5.23,6.83,6.72,6.96,11.68.03,1.16-.43,2.36-1.34,3.12-.21.18-.45.33-.7.45-.14.07-.29.11-.43.18l-.09.03c-.17.05-.12.04.17-.01-.11.1-.59-.02-.74-.05-.29-.06-.57-.15-.84-.29-2.07-1.04-2.96-3.82-3.91-5.76-.7-1.44-1.42-2.87-2.11-4.32-.55-1.14-1.06-2.65.05-3.65-.21.18-.78-.03-.99.16-1.04.93-.65,2.36-.14,3.46.64,1.37,1.32,2.72,1.98,4.07s1.22,2.65,1.95,3.9c.56.96,1.28,1.91,2.32,2.36.87.39,1.76.24,2.66.08,1.12-.2,2.07-.81,2.62-1.82,1.34-2.45-.27-4.9-2.01-6.61-1.97-1.93-4.89-4.01-4.46-7.15-.01.09-.97.06-.99.16h0Z" />
      <path d="m454.77,385.84c-.8-.43-1.63-.49-2.51-.35-.8.12-1.56.25-2.23.75-1.5,1.11-1.84,3.15-2.05,4.89-.23,1.94-.39,4.73,2.03,5.33.79.19,1.59.04,2.37-.1.96-.17,1.81-.61,2.63-1.14.98-.63,1.95-1.45,2.08-2.68s-.55-2.5-1.17-3.56c-.06-.11-.93.26-.99.16.88,1.51,1.82,3.43.56,5.01-.55.68-1.33,1.18-2.09,1.61-.37.21-.75.4-1.16.54-.11.04-.23.07-.35.11.26-.09.11,0-.01-.01-.22-.01-.43-.01-.65-.05-1.99-.34-2.51-2.33-2.39-4.08.1-1.54.27-3.23.95-4.63.37-.77.93-1.45,1.7-1.84.19-.1.4-.15.59-.24.23-.1-.29-.03-.05.02.13.02.27,0,.4.01.48.04.93.19,1.36.42.24.13.76-.28.99-.16h0Z" />
      <path d="m463.2,400.95c.09,1.08.27,2.15.55,3.2.21.78.4,2.02,1.26,2.34.74.28,1.73.03,2.49-.08.89-.13,1.78-.28,2.66-.47.75-.16,1.62-.33,2.15-.94.65-.75.33-1.87.2-2.75-.16-1.12-.33-2.23-.49-3.35-.11-.73-.04-2.23-1.1-2.16-1.93.13-3.83,1.27-5.38,2.36.24-.17.74.01.99-.16.65-.46,1.34-.88,2.05-1.24s1.53-.8,2.33-.95l-.97.16c.97-.06.95,1.09,1.05,1.79.14.93.27,1.86.41,2.79.12.85.36,1.75.32,2.61-.03.78-.49,1.31-1.19,1.62-.9.39-1.97.54-2.94.65-.71.08-1.61.22-2.12-.43-.47-.61-.63-1.57-.81-2.3-.23-.94-.38-1.89-.46-2.85,0,.02-.99.13-.99.16h0Z" />
      <path d="m471.42,394.88c1.64-2.74,4.76-7.48,8.49-4.96,1.48,1,2.3,2.74,2.92,4.35.77,2,1.24,4.16.74,6.29.03-.12.96-.03.99-.16.45-1.9.12-3.83-.5-5.64-.52-1.52-1.19-3.11-2.32-4.29s-2.49-1.58-4.03-1.36c-1.29.18-2.49.5-3.57,1.29-1.62,1.18-2.68,2.95-3.69,4.64.11-.18.87.03.99-.16h0Z" />
      <path d="m402.13,391.26c-.5-3.53-.99-7.07-1.49-10.6l-.73-5.18c-.2-1.45-.62-3.11.36-4.37.77-1,2.05-1.51,3.19-1.95.67-.26,1.24-.33,1.85.15s.93,1.26,1.27,1.92c.7,1.37,1.38,2.83,1.5,4.38s-.36,2.88-1.08,4.15c-1.6,2.85-4.55,5.47-4.1,9.04,0,0,.99-.14.99-.16-.43-3.41,2.28-5.99,3.89-8.68.82-1.37,1.41-2.89,1.28-4.51s-.89-3.22-1.65-4.68c-.31-.6-.62-1.29-1.19-1.69-.54-.38-1.17-.35-1.79-.25-1.23.19-2.38.57-3.49,1.14-1.36.7-2.3,1.73-2.29,3.33,0,.86.18,1.72.3,2.57l.4,2.84c.59,4.23,1.19,8.47,1.78,12.7,0,0,.99-.15.99-.16h0Z" />
      <path d="m406.84,398.17c-.27-2.14-.18-4.3.3-6.41.23-1.01.55-2,.95-2.96.35-.84.74-1.92,1.72-2.17l-.97.16c1.07-.12,1.8.88,2.31,1.68.62.97,1.21,1.96,1.79,2.95,1.18,2.01,2.29,4.08,3.12,6.27s1.35,4.42,1.41,6.73c.03,1.12-.05,2.24-.27,3.34-.16.8-.33,2.18-1.26,2.47l.97-.16c-.54.05-.86-.33-1.15-.71-.35-.44-.68-.9-1.02-1.34-.68-.9-1.36-1.79-2.04-2.69-.1-.13-.9.28-.99.16.53.7,1.07,1.41,1.6,2.11s1.06,1.55,1.7,2.22c.41.43.76.45,1.29.36.49-.08.9-.1,1.21-.54.41-.56.54-1.37.67-2.04.18-.88.26-1.77.28-2.67.03-1.73-.23-3.46-.68-5.13-.91-3.41-2.63-6.53-4.44-9.53-.43-.71-.85-1.43-1.3-2.12-.36-.55-.81-1.14-1.42-1.41-.77-.34-2.15-.02-2.67.61-.55.67-.87,1.62-1.17,2.43-.33.91-.59,1.84-.77,2.78-.36,1.9-.41,3.84-.17,5.75,0,0,.99-.14.99-.16h0Z" />
      <path d="m422.86,412c-.38-1.66-.48-3.37-.28-5.07.14-1.13.31-3.04,1.63-3.43l-.92.15c1.08-.16,2.05.97,2.79,1.61.8.68,1.59,1.38,2.35,2.11,1.5,1.45,2.89,3.03,3.94,4.85,1.96,3.4,2.58,7.91.28,11.31.12-.18.86.03.99-.16,2.71-4.01,1.35-9.23-1.31-12.88-1.44-1.98-3.25-3.66-5.11-5.26-.75-.65-1.75-1.81-2.83-1.76-.56.03-1.35.12-1.77.51-.88.81-.98,2.46-1.08,3.56-.14,1.55-.02,3.1.33,4.61,0,.03.98-.17.99-.16h0Z" />
      <path d="m438.15,417.79c-1.03-2.07-1.09-4.58-.07-6.67.93-1.9,2.66-3.32,4.68-3.91-.32.09-.67.07-.99.16-2.02.59-3.75,2.01-4.68,3.91-1.02,2.09-.96,4.59.07,6.67.05.09.95-.24.99-.16h0Z" />
      <path d="m442.83,419.61c1.6-1.81,2.81-3.93,3.51-6.25.56-1.84,1.33-5.57-1.45-6.02-.31-.05-.68.21-.99.16,2.79.45,2.01,4.18,1.45,6.02-.7,2.31-1.91,4.44-3.51,6.25.17-.19.81.04.99-.16h0Z" />
      <path d="m453.6,399.66c-1.78-.15-3.54.61-4.5,2.14-1.4,2.24-.72,5.14-.12,7.53.25,1,.63,2.13,1.59,2.67.84.47,1.79.3,2.69.15,1.8-.3,4.64-.97,4.5-3.28-.1-1.51-.54-3.07-.86-4.55-.35-1.62-.74-3.24-1.15-4.84,0-.04-.98.18-.99.16.6,2.29,1.13,4.6,1.59,6.93.2,1,.63,2.25.37,3.28-.22.87-1,1.37-1.78,1.7-.39.17-.8.3-1.21.43-.13.04-.27.08-.4.12-.08.02-.65.07-.23.06-.27,0-.53,0-.79-.04-.92-.18-1.55-.85-1.92-1.68-.4-.88-.57-1.88-.77-2.82-.23-1.13-.41-2.29-.32-3.45s.44-2.16,1.15-3.02c.3-.36.64-.69,1.04-.94.22-.14.44-.26.68-.35.08-.03.16-.06.24-.08-.36.08.12.05.2.05.32.03.67-.19.99-.16h0Z" />
      <path d="m435.84,378.63c.57-1.02,1.17-2.09,2.14-2.78.19-.14.4-.26.62-.35.1-.04.23-.1.34-.13-.25.06,0,0,.06.02.58.09,1.06.21,1.48.65.77.8.95,2.1,1.13,3.15.2,1.19.16,2.36-.59,3.35-.57.74-1.38,1.31-1.82,2.15-.51.96-.17,1.92.48,2.71s1.55,1.47,2.15,2.38.97,1.95,1.18,3.01.28,2.24.27,3.37,0,2.28-.2,3.37c-.1.51-.29,1.02-.7,1.36-.02.02-.63.42-.66.35h.15c.26-.04.2-.04-.18,0-.18.01-.36.02-.54.03-.67.04-1.38.12-2.03.29-1.76.45-2.22,2.46-3.16,3.77-.23.33-.5.63-.83.86-.16.12-.35.18-.52.28-.42.25.15-.11.19,0,0-.01-.42-.02-.48-.03-1.01-.16-1.91-.85-2.45-1.7-.74-1.16-.78-2.62-.65-3.95.15-1.52.47-3.03.73-4.53.56-3.23,1.11-6.46,1.67-9.69-.02.1-.97.05-.99.16-.53,3.11-1.07,6.22-1.6,9.33-.25,1.48-.55,2.96-.74,4.45-.17,1.35-.21,2.84.43,4.09.49.95,1.41,1.74,2.46,1.97.67.15,1.45,0,2.11-.16,1.94-.49,2.29-2.86,3.47-4.18.3-.33.63-.5,1.01-.73.43-.26-.44-.01.05-.06.18-.01.35-.03.53-.03.24-.01.49-.02.73-.05.71-.08,1.64-.14,2.23-.58.99-.74.87-2.32.92-3.42.06-1.23.07-2.47-.07-3.69s-.42-2.35-.96-3.41-1.46-1.85-2.29-2.75c-.73-.79-1.27-1.78-.79-2.85.42-.94,1.34-1.54,1.94-2.35.91-1.23.68-2.73.37-4.14-.28-1.29-.75-2.75-2.27-2.92-.79-.09-1.82.1-2.55.42-1.33.57-2.08,1.93-2.76,3.13.1-.18.88.03.99-.16h0Z" />
      <path d="m385.97,380.97c-.04-1.09.13-2.18.54-3.19.35-.88.92-2.15,1.96-2.36l-.97.16c1-.07,2,.79,2.66,1.46.71.73,1.26,1.61,1.59,2.58s.43,2.09.28,3.15c-.08.55-.23,1.09-.44,1.6-.17.42-.43,1.18-.88,1.35l.99-.16c-.39-.04-.77-.45-1.08-.67l-1.22-.87c-.06-.05-.42.06-.49.08s-.43.13-.49.08l1.15.82c.3.22.73.67,1.1.72.21.03.47-.06.68-.09.29-.05.48-.06.66-.31.98-1.33,1.27-3.26.99-4.86s-1.13-3.02-2.35-4.03c-.58-.49-1.35-1.06-2.15-.99-.44.04-1.02.09-1.42.3-.8.43-1.27,1.5-1.58,2.3-.38.99-.54,2.05-.5,3.11,0-.03.99-.11.99-.16h0Z" />
      <path d="m391.06,387.13l.48,2.29c.07.35.21.74.18,1.1-.04.38-.24.44-.49.64.63-.05.78-.09.46-.1-.08-.01-.17-.03-.25-.05-.16-.05-.32-.12-.47-.19-.27-.13-.54-.28-.81-.43-.61-.35-1.2-.73-1.76-1.15-1.04-.77-2.18-1.66-2.89-2.76-.59-.91-.75-2.33.32-2.97-.26.16-.72,0-.99.16-.86.51-.96,1.64-.59,2.48.46,1.05,1.46,1.88,2.33,2.6.93.77,1.93,1.45,3,2.02.46.25.93.51,1.47.45.39-.04.9-.07,1.26-.25.65-.33.34-1.15.22-1.7l-.48-2.29s-.99.17-.99.16h0Z" />
      <path d="m389.51,367.17c.39,3.79,2.69,6.92,4.41,10.2,1.97,3.76,2.59,7.83,2.9,12.02.07.99.33,2.2,0,3.17-.07.22-.18.43-.34.6-.11.12-.3.17-.41.28-.18.19.52.07.3-.04-.07-.04-.18-.03-.26-.06-.2-.08-.32-.21-.43-.39-.07-.11-.93.26-.99.16.35.6,1.01.44,1.59.35.71-.12,1.26-.32,1.51-1.07.32-.94.08-2.09.01-3.05-.09-1.18-.19-2.36-.34-3.53-.29-2.32-.78-4.62-1.7-6.78-.82-1.91-1.92-3.67-2.92-5.49-1.13-2.05-2.12-4.19-2.36-6.54,0,.01-.99.13-.99.16h0Z" />
      <path d="m396.22,371.63c-.64-4.1-1.29-8.2-1.93-12.29-.47-2.98-.63-6.87-3.25-8.86-1.16-.88-2.58-1.38-3.02-2.9-.33-1.13-.34-2.96-1.93-2.99-1.11-.02-2.3.35-2.92,1.32-.45.7-.65,1.53-.85,2.32-.23.91-.42,1.83-.59,2.75-.76,4.31-.77,8.73-.06,13.05,0,0,.99-.15.99-.16-.59-3.62-.69-7.32-.25-10.97.21-1.78.55-3.55,1.01-5.29.19-.71.4-1.44.84-2.03.22-.3.49-.55.82-.73.04-.02.32-.14.32-.14-.36.03-.41.05-.14.05.11.01.22.04.33.07,1.29.45,1.14,2.17,1.54,3.22.5,1.31,1.88,1.8,2.92,2.59,2.51,1.89,2.73,5.52,3.18,8.37.67,4.26,1.34,8.52,2.01,12.78,0,0,.99-.15.99-.16h0Z" />
      <path d="m387.09,347.59c.94,3.57,1.08,7.32.36,10.94-.56,2.85-2.67,7.09.29,9.33.19.14.81-.3.99-.16-2.97-2.24-.86-6.49-.29-9.33.72-3.62.58-7.38-.36-10.94,0-.04-.98.18-.99.16h0Z" />
      <path d="m398.45,363.21c-.9-6.63.04-13.43,2.73-19.55-.07.16-.91-.01-.99.16-2.68,6.13-3.63,12.93-2.73,19.55,0,0,.99-.14.99-.16h0Z" />
      <path d="m402.64,343.96c1.93.73,2.91,2.7,3.17,4.65.3,2.29-.05,4.7-.18,6.99-.13,2.14-.08,4.5-1.26,6.38-.52.82-1.18,1.35-2.04,1.77-.21.05-.23.07-.06.05-.12,0-.24,0-.36,0-.23-.02-.47-.06-.69-.12-.46-.14-.84-.38-1.17-.72-.14-.14-.86.29-.99.16,1.01,1.04,2.37.9,3.67.64,1.58-.32,2.66-1.65,3.19-3.11.69-1.9.63-4.05.74-6.05s.41-4.21.12-6.27c-.27-1.91-1.26-3.82-3.15-4.54-.27-.1-.72.26-.99.16h0Z" />
      <path d="m392,343.52c.1-1.67.42-3.32.97-4.9.27-.77.6-1.52.97-2.25.32-.62.68-1.31,1.4-1.52l-.97.16c1.16-.13,1.92,1.42,2.28,2.31.46,1.13.63,2.37.53,3.58-.1,1.27-.48,2.51-1.04,3.65-.48.98-1.16,2.36-2.34,2.61l.97-.16c-.65.05-1.34-.28-1.38-.99,0,.03-.99.12-.99.16.09,1.51,2.51,1.03,3.26.38s1.24-1.66,1.64-2.55c.45-1,.76-2.07.86-3.16.17-1.88-.29-4.27-1.75-5.6-.57-.52-1.18-.4-1.87-.26-.84.16-1.22.84-1.59,1.55-1.15,2.21-1.8,4.66-1.94,7.14,0-.07.98-.08.99-.16h0Z" />
      <path d="m406.72,329.47c-1.01,2.55-1.58,5.25-1.88,7.97-.14,1.23-.26,2.56.26,3.72.44,1,1.32,1.75,2.45,1.75,4.3,0,7.18-4.77,7.09-8.69-.06-2.52-1.25-4.94-3.24-6.49-.18-.14-.81.3-.99.16,3.39,2.62,4.1,7.37,2.1,11.06-.51.94-1.17,1.79-1.95,2.52-.65.6-1.64,1.35-2.39,1.25-2.45-.35-2.56-3.32-2.36-5.21.29-2.8.86-5.58,1.9-8.21-.06.15-.92,0-.99.16h0Z" />
      <path d="m487.83,407.18c.03-1.18.58-2.3,1.47-3.07.45-.39.99-.7,1.57-.85.41-.09.27-.07-.41.05l.21-.02c.15,0,.3,0,.45,0,.3.02.6.07.89.16,1.12.32,2.12,1.11,2.63,2.16.63,1.31.49,2.82.27,4.2.02-.1.97-.05.99-.16.2-1.24.33-2.56-.09-3.78-.35-.99-1.08-1.8-2.01-2.28s-1.84-.54-2.83-.39-1.87.38-2.64,1.05c-.89.77-1.44,1.89-1.47,3.07,0-.05.99-.09.99-.16h0Z" />
      <path d="m496.03,395.24c-.13,1.16-.28,2.38.15,3.49.37.98,1.11,1.66,2.15,1.87,1.1.22,2.27.17,3.38,0,.86-.13,1.91-.19,2.48-.94.6-.81.49-1.96.37-2.9,0,0-.99.14-.99.16.12.91.23,2.01-.31,2.82-.13.2-.3.37-.5.5-.14.09-.28.16-.44.21-.08.03-.15.05-.23.07l.22-.03c-.74.11-1.51.1-2.25.04-1.2-.1-2.2-.53-2.75-1.66-.58-1.19-.43-2.54-.28-3.8,0,.08-.98.06-.99.16h0Z" />
      <path d="m498.88,408.98c-.33-1.64.16-3.33,1.23-4.6.54-.64,1.21-1.16,1.94-1.55.38-.21.79-.38,1.2-.52.22-.07.45-.13.67-.19.37-.11.13-.05.03-.03.16-.03.34-.04.5-.05,3.19-.18,6.35,1.72,6.26,5.21-.06,2.05-1.18,3.85-2.48,5.36.16-.19.82.04.99-.16,1.3-1.51,2.43-3.31,2.48-5.36.05-1.85-.84-3.5-2.46-4.4-1.4-.78-3.06-.95-4.62-.72s-3.1.59-4.4,1.55c-1.75,1.29-2.77,3.46-2.33,5.62,0,.02.99-.17.99-.16h0Z" />
      <path d="m524.27,413.53c-.56-1.18-1.27-2.39-2.2-3.32-.43-.44-.93-.66-1.56-.59-.48.06-.99.12-1.45.27-2.15.7-3.93,2.89-4.23,5.12-.08.58.08,1.38.82,1.39.24,0,.49-.07.72-.1s.44-.05.62-.19c-.09.07-.38.06-.49.08-.13.02-.39,0-.49.08-.09.07-.15.1-.26.13l.99-.16c-.9.08-1-.93-.88-1.59.14-.75.44-1.48.83-2.13s.89-1.21,1.46-1.69c.29-.24.59-.45.92-.64.16-.09.33-.18.5-.26.19-.09.92-.29.26-.19l-.25.04c1.02-.09,1.62.65,2.19,1.39.6.78,1.11,1.63,1.53,2.52.04.09.95-.24.99-.16h0Z" />
      <path d="m529.4,412.54c-2.82-.81-5.43-2.75-6.09-5.74-.3-1.36-.14-2.81.58-4.01.32-.53.75-1.01,1.24-1.4.27-.21.56-.39.86-.53.15-.07.31-.14.47-.19.27-.09.95-.14.02-.05.3-.03.72-.13.95-.16-1.18.12-2.34.35-3.29,1.09-1.26.97-1.97,2.49-1.97,4.07,0,3.61,3.01,6.15,6.24,7.08.29.08.7-.24.99-.16h0Z" />
      <path d="m533.59,412.99c2.9,2.54,4.41,6.44,3.97,10.27,0-.08.98-.06.99-.16.44-3.83-1.07-7.73-3.97-10.27-.16-.14-.83.3-.99.16h0Z" />
      <path d="m545.49,416.47c.17,1.56-1,3.04-.57,4.58.46,1.66,2.36,2.13,3.83,2.44.13.03.36-.05.49-.08s.36-.11.49-.08c-1.47-.31-3.37-.78-3.83-2.44-.43-1.55.74-3.02.57-4.58,0,.01-.99.13-.99.16h0Z" />
      <path d="m550.15,413.7c-.66.71-1.46,1.23-2.37,1.56-.21.08-.41.14-.62.2-.18.05-.35.09-.53.13.17-.03.54-.06-.16,0-.48.03-.96.03-1.43,0-2.04-.13-4.1-.83-5.65-2.2s-2.48-3.36-2.32-5.4c.14-1.9,1.28-3.58,2.72-4.77,1.94-1.62,4.42-2.37,6.8-3.1-.31.1-.67.06-.99.16-4,1.23-9.67,3.32-9.53,8.46.13,4.84,5.31,7.39,9.62,6.99,2-.19,4.05-.66,5.46-2.19-.18.19-.81-.04-.99.16h0Z" />
      <path d="m537.43,400.04c-.23.6-.65,1.06-1.22,1.35-.13.07-.27.11-.4.17-.3.15.3.04-.04.02s-.64-.03-.96-.12c-1.45-.41-2.34-1.84-2.63-3.24-.31-1.48-.12-3.25.87-4.44.26-.32.58-.58.94-.78.18-.1.36-.16.54-.24.02,0,.33-.15.1-.06-.2.07.29-.02.39-.03.92-.02,1.82.23,2.68.53,1.92.68,3.8,1.52,5.7,2.28.91.37,1.88.88,2.89.84.43-.02.88-.1,1.3-.19,1.42-.31,2.1-1.9,1.7-3.23-.49-1.63-2.12-2.54-3.65-2.99-2.09-.61-4.27-.92-6.38-1.51-2.21-.62-4.38-1.37-6.5-2.24-.27-.11-.73.27-.99.16,1.98.81,3.99,1.52,6.04,2.11s4.06.97,6.1,1.45c1.68.39,3.53,1.14,4.27,2.83.61,1.4,0,3.19-1.56,3.57l.97-.16c-1.37.19-2.76-.62-4-1.12s-2.56-1.04-3.84-1.55c-1.13-.45-2.3-.9-3.54-.94-.72-.02-1.47.11-2.17.27-2.18.48-3.1,2.64-2.98,4.71.12,1.89,1.2,4,3.27,4.24.68.08,1.42-.06,2.09-.21.9-.2,1.66-.74,2-1.62-.06.15-.93,0-.99.16h0Z" />
      <path d="m551.06,380.62c-2.21,3.18-3.25,7.09-3.01,10.95.12,1.94.57,3.86,1.34,5.65s1.92,3.58,4.1,3.6c1.84.02,3.66-.68,5.27-1.49-.28.14-.7.02-.99.16-.84.42-1.7.83-2.6,1.08-.06.02-.45.15-.48.12-.01-.01.73-.06.17-.04-.26,0-.51.02-.77,0-.42-.04-.83-.15-1.21-.34-1.59-.78-2.38-2.75-2.93-4.33-1.14-3.25-1.25-6.83-.38-10.15.5-1.92,1.33-3.73,2.47-5.36-.13.19-.85-.03-.99.16h0Z" />
      <path d="m563.28,399.59c2.27-3.04,3.15-7.04,2.28-10.75-.48-2.05-1.48-3.94-2.52-5.75-.9-1.56-1.83-3.52-3.7-4.07-1.63-.47-3.6.07-5.2.49.32-.08.67-.08.99-.16.34-.09.69-.17,1.03-.26.2-.05.35-.07.74-.08.56-.02,1.12.04,1.65.23.99.35,1.71,1.13,2.28,1.97,1.29,1.9,2.47,4.04,3.26,6.2,1.51,4.13.83,8.81-1.8,12.34.14-.19.84.04.99-.16h0Z" />
      <path d="m563.22,383.3c-.05-.66-.09-1.36.11-2,.08-.28.22-.55.41-.77.1-.12.22-.22.35-.3.07-.05.14-.08.22-.12.17-.08.31-.03.07-.08-.35-.08.04-.01.16-.02.08,0,.17,0,.26,0,.19,0,.38,0,.57,0,.46,0,.91-.01,1.37-.02.86-.01,1.8-.13,2.64,0,.8.13,1.16.77,1.55,1.4.47.76.95,1.51,1.41,2.27.39.63.79,1.33.73,2.1-.06.72-.49,1.34-1,1.83-.59.56-1.31,1-2.06,1.31-.22.09-.44.17-.67.24-.11.03-.22.06-.34.09-.52.15.46-.02-.08.02-.48.03-.94.04-1.41,0-.32-.03-.67.19-.99.16,1.45.14,3.13-.1,4.48-.66,1.54-.63,3.55-2.09,2.95-4.01-.3-.97-1.01-1.86-1.54-2.71-.47-.75-.93-1.91-1.87-2.16-.46-.12-.98-.07-1.45-.06-.6,0-1.2.01-1.79.02-.84.01-1.69-.01-2.53.12-.74.11-1.56.19-2.06.81-.6.74-.54,1.82-.47,2.71,0-.02.99-.12.99-.16h0Z" />
      <path d="m571.03,390.16c-.06,2.26-.1,4.73,1.28,6.65.65.9,1.54,1.49,2.6,1.81.51.16,1.03.25,1.56.34.48.08.99.19,1.47.14.73-.08,1.6-.13,1.88-.93.15-.44.14-.93.15-1.39.02-.68.05-1.35.07-2.03.06-1.9.37-4.48-1.45-5.71s-4.44-.42-6.34.17c.31-.1.67-.06.99-.16.37-.11.73-.22,1.1-.32.15-.04.89-.17.34-.1.21-.03.42-.04.63-.04.62,0,1.24.1,1.81.35,1.14.51,1.71,1.59,1.89,2.78.2,1.32.06,2.69.01,4.01-.02.68,0,1.37-.08,2.05-.07.6-.28,1.12-.9,1.31l.92-.15c-.57.08-1.21-.07-1.77-.17-.52-.09-1.04-.2-1.54-.38-1-.35-1.84-.98-2.44-1.86-1.3-1.91-1.25-4.31-1.19-6.52,0,.05-.99.09-.99.16h0Z" />
      <path d="m548.97,370.55c-.19.83-.35,1.67-.47,2.51-.11.7-.34,1.58-.16,2.29.34,1.36,2.52,1.33,3.61,1.39,2.72.15,5.6-.33,8.14-1.32-.3.12-.68.04-.99.16-2.57,1-5.28,1.27-8,.8-.48-.08-1.01-.2-1.39-.54-.4-.36-.45-.87-.41-1.37.1-1.37.37-2.74.67-4.07-.03.11-.96.03-.99.16h0Z" />
      <path d="m419.37,296.33c-1.63.61-3.34,1.11-5.07,1.31-1.3.15-2.88.33-4.02-.47-1-.7-1.29-1.99-1.12-3.14.22-1.4,1.11-2.59,1.94-3.69-.14.19-.84-.04-.99.16-.98,1.31-2.05,2.76-1.99,4.48.06,1.62,1.14,2.71,2.73,2.9,1.47.18,3-.13,4.45-.37,1.72-.29,3.41-.73,5.05-1.34-.3.11-.68.05-.99.16h0Z" />
      <path d="m412.32,289.01c.29-.09.58-.17.87-.25.25-.07.13-.06-.05,0,.16-.05.44-.04.62-.05.49-.02.98,0,1.47.06,1.01.12,1.99.42,2.89.88,1.65.84,2.99,2.19,3.84,3.83.05.1.94-.25.99-.16-1.09-2.08-2.96-3.69-5.21-4.38-2.06-.64-4.38-.43-6.42.23.31-.1.67-.06.99-.16h0Z" />
      <path d="m409.4,290.3c-1.49-.96-3.14-1.12-4.85-.84-1.6.26-3.02.91-4.08,2.17s-1.86,3.37-.57,4.75c.61.65,1.47,1.05,2.17,1.6.78.61,1.51,1.3,2.36,1.81s1.8.79,2.78.67c.73-.09,1.49-.19,2.11-.6-.25.17-.73,0-.99.16-.13.09-.26.17-.41.23,0,0-.27.12-.29.11-.03-.02.41,0,.15-.03-.37-.05-.73-.05-1.1-.15-.74-.2-1.4-.6-2.02-1.06-.65-.49-1.25-1.04-1.9-1.52-.58-.43-1.25-.77-1.77-1.27-1.1-1.06-.86-2.69-.18-3.92s1.74-2.15,3-2.67c.16-.07.33-.13.5-.18.1-.03.44-.14.45-.12l-.29.05.31-.02c.42-.02.83,0,1.24.07.86.14,1.66.45,2.39.92.21.14.78-.29.99-.16h0Z" />
      <path d="m391.66,295.98c-1,2.54.38,5.09,1.81,7.15.32.46.63,1.03,1.09,1.37.42.31.88.25,1.36.18s.96-.13,1.37-.42c.52-.36.84-.93,1.15-1.47-.03.05-.43.06-.49.08-.07.01-.46.02-.49.08-.26.46-.53.94-.93,1.3-.2.18-.41.27-.64.4-.21.07-.08.06.4-.05h-.13c-.14-.04-.25-.05-.38-.12-.45-.21-.73-.68-1.01-1.07-.32-.46-.65-.92-.95-1.39-1.16-1.82-2.01-4.07-1.17-6.19-.01.03-.44.07-.49.08-.06,0-.47.03-.49.08h0Z" />
      <path d="m399.92,301.47c-.38-1.04-.83-2.05-1.32-3.04s-.92-1.95-1.5-2.83c-.24-.36-.54-.71-.94-.9-.32-.15-.63-.11-.96-.06-.64.1-1.66.17-1.66,1.04,0-.05.99-.1.99-.16,0-.29.13-.53.37-.69.06-.05.13-.08.2-.12.33-.15.12.13-.44.04.14.02.26.02.4.06.74.25,1.14,1.04,1.48,1.68.89,1.67,1.77,3.36,2.41,5.14.02.06.97-.21.99-.16h0Z" />
      <path d="m400.08,306.39c-.93,1.41-1.46,3.05-1.49,4.74-.01.68.01,1.54.63,1.97.46.33,1.05.26,1.57.18,1.43-.22,2.8-.67,4.19-1.07,1.85-.53,3.69-1.06,5.54-1.59-.32.09-.67.07-.99.16-1.54.44-3.08.88-4.61,1.33-.79.23-1.57.45-2.36.68-.34.1-.68.2-1.03.29-.12.04-.56.08-.65.17.36-.05.45-.07.25-.06-.08,0-.15,0-.23,0-1.46-.13-1.4-1.87-1.28-2.96.15-1.43.66-2.79,1.45-3.99-.12.18-.86-.03-.99.16h0Z" />
      <path d="m412.71,305.74c-.16.04-.81.18-.23.08s-.03-.02-.15,0c-.24.01-.49,0-.73,0-.52-.03-1.04-.12-1.56-.23-1.03-.22-2.04-.55-3.06-.82s-1.97-.48-2.97-.48c-.76,0-1.54.14-2.28.29.23-.05.66-.09.95-.15-.26.05-.05.03.1.03.14,0,.27,0,.41,0,.25,0,.5.03.74.05.5.06,1,.16,1.49.27,1.06.25,2.09.59,3.14.85,1.72.42,3.43.45,5.14-.04-.32.09-.67.07-.99.16h0Z" />
      <path d="m390.77,311.33c-.53,1.2-1.86,1.8-2.37,3-.32.74-.12,1.49.29,2.15s.96,1.32,1.57,1.81c.57.46,1.19.66,1.91.56.61-.09,1.21-.17,1.75-.49.7-.42,1.31-1.05,1.85-1.65s.99-1.27,1.07-2.07c.21-2.08-1.64-3.66-2.18-5.54-.01-.05-.98.19-.99.16.45,1.55,1.64,2.75,2.06,4.31s-.42,2.82-1.54,3.9c-.56.55-1.11.96-1.81,1.31-.28.14.54.02.25-.05-.07-.02-.15-.01-.22-.02-.17-.03-.33-.08-.48-.15-.34-.15-.65-.38-.92-.63-.94-.86-2.18-2.28-1.67-3.65.47-1.26,1.88-1.87,2.42-3.11-.07.16-.91-.01-.99.16h0Z" />
      <path d="m400.27,332.13c-2.45-.31-1.33-4.09-1-5.58.45-2.01.94-4.38,2.36-5.96.31-.35.68-.66,1.09-.88.14-.08.53-.32.7-.28-.32.04-.34.06-.07.05.12,0,.24.02.36.05.93.21,1.48.99,1.73,1.86.61,2.09.36,4.64-.39,6.66.05-.15.93,0,.99-.16.82-2.22,1.77-9.11-2.36-8.53-.54.08-1.1.14-1.6.35-.82.33-1.47.98-1.95,1.71-1.01,1.55-1.45,3.5-1.85,5.29-.33,1.49-1.45,5.27,1,5.58.32.04.67-.2.99-.16h0Z" />
      <path d="m408.6,321.23c1.18,1.98,3.65,2.32,5.53,3.32.48.25.93.55,1.32.93s.64.88,1,1.3c.32.37.72.59,1.21.53.37-.05.81-.08,1.16-.24-.29.13-.69.03-.99.16-.07.04-.15.07-.24.08l.97-.16c-1.06.1-1.46-1.17-2.08-1.79-.65-.66-1.5-1.08-2.35-1.44-1.65-.69-3.57-1.22-4.55-2.86-.07-.11-.93.26-.99.16h0Z" />
      <path d="m386.8,322.35c-1.21,1-1.18,2.71-.72,4.08.24.71.53,1.54.96,2.16s1.08.45,1.72.35c1.38-.23,2.53-1.22,3.16-2.46.31-.62.43-1.28.6-1.94.16-.6.39-1.23.93-1.58.33-.21.77-.3,1-.64s.09-.69-.26-.88c-.81-.45-1.88-.54-2.79-.47-.33.02-.66.14-.99.16.79-.06,1.58.03,2.33.28.27.09.63.2.78.47.17.29-.02.61-.25.8-.26.2-.58.29-.85.47-.24.17-.42.39-.56.65-.29.54-.38,1.17-.53,1.76-.32,1.27-1.1,2.38-2.23,3.07-.14.08-.28.16-.42.22-.08.04-.15.06-.23.1-.32.14.08-.22.38-.05-.12-.07-.31-.05-.45-.13s-.25-.21-.34-.34c-.4-.57-.66-1.31-.89-1.96-.51-1.42-.63-3.22.65-4.28-.08.07-.39.06-.49.08-.12.02-.4,0-.49.08h0Z" />
      <path d="m382.26,331.22c.8,1.71,1.6,3.41,2.4,5.12.32.69.6,1.63,1.23,2.1.5.38,1.08.33,1.66.24s1.17-.17,1.67-.54c.62-.46,1.01-1.15,1.33-1.84s.58-1.45.54-2.22c-.04-.8-.49-1.39-1.13-1.84-.82-.58-1.71-1.07-2.57-1.6-.95-.59-1.91-1.18-2.86-1.77-.07-.05-.41.06-.49.08s-.42.13-.49.08c1.48.92,2.97,1.84,4.45,2.76.6.37,1.3.73,1.73,1.32.39.54.44,1.22.33,1.86-.21,1.24-.91,2.83-2.04,3.48-.07.04-.41.15-.43.19.01-.05.48,0,.27-.05-.06-.01-.13,0-.2-.01-.33-.04-.63-.17-.88-.39-.53-.49-.78-1.3-1.08-1.93-.81-1.73-1.63-3.47-2.44-5.2-.04-.09-.95.24-.99.16h0Z" />
      <path d="m465.3,316.84c-1.58.95-3.36,1.5-5.17,1.81-1.64.29-3.56.63-5.17.07-1.4-.48-2.36-1.8-2.29-3.3s1.16-2.63,2.53-2.97c-.32.08-.66.08-.99.16-1.71.43-2.86,2.12-2.45,3.87.46,1.97,2.35,2.69,4.19,2.67,1.61-.02,3.24-.32,4.82-.6,1.93-.34,3.82-.86,5.51-1.88-.26.16-.72,0-.99.16h0Z" />
      <path d="m486.95,295.11c.47.86.2,1.9-.43,2.61-.7.79-1.75,1.16-2.72,1.5-2.37.81-4.76,1.55-7.17,2.21.32-.09.67-.07.99-.16,2.41-.66,4.81-1.4,7.17-2.21.95-.33,1.96-.69,2.67-1.44.66-.71.96-1.78.48-2.67-.06-.1-.94.25-.99.16h0Z" />
      <path d="m472.06,294.13c.81.22,1.57.12,2.38-.02s1.56-.43,2.19-1,1.15-1.37,1.94-1.77c.08-.04.16-.08.24-.11.05-.02.1-.04.15-.05.2-.06.06-.04-.42.06.18.03.36,0,.54.07.08.03.47.16.47.27,0,.05-.68.37-.74.41.27-.15.71,0,.99-.16.12-.07.25-.14.37-.2.08-.04.26-.1.31-.17.09-.02.08-.06-.02-.1-.04-.03-.08-.06-.12-.08-.07-.04-.15-.08-.22-.11-.31-.11-.63-.09-.94-.04-.76.11-1.48.22-2.1.72-.7.57-1.2,1.36-1.97,1.85-.16.11-.34.2-.52.28-.11.05-.22.09-.33.13-.08.03-.42.09-.08.04s-.01-.01-.09-.01c-.12,0-.24,0-.35-.01-.24-.02-.47-.07-.7-.13-.12-.03-.37.05-.49.08s-.37.12-.49.08h0Z" />
      <path d="m436.19,221.42c1.95,4.51,4.36,8.81,7.2,12.82.09.12.91-.27.99-.16-2.84-4.02-5.25-8.31-7.2-12.82-.04-.08-.96.23-.99.16h0Z" />
      <path d="m424.03,231.83c3.14,2.41,6.4,4.67,9.75,6.77.07.05.41-.06.49-.08s.42-.13.49-.08c-3.35-2.11-6.6-4.37-9.75-6.77-.06-.04-.42.06-.49.08s-.43.13-.49.08h0Z" />
      <path d="m459.08,207.77c.59,3.68,1.47,7.31,2.63,10.85.02.06.97-.2.99-.16-1.16-3.54-2.04-7.17-2.63-10.85,0,0-.99.15-.99.16h0Z" />
      <path d="m469.73,201.89c-.54,3.63-.03,7.34,1.47,10.69.04.08.96-.23.99-.16-1.5-3.35-2.01-7.07-1.47-10.69-.01.09-.97.05-.99.16h0Z" />
      <path d="m485.11,204.38c-.49,3.35-.99,6.7-1.48,10.06.01-.09.97-.05.99-.16.49-3.35.99-6.7,1.48-10.06-.01.09-.97.05-.99.16h0Z" />
      <path d="m500.61,212.14c-1.79,3.3-3.24,6.77-4.33,10.36.04-.13.94-.01.99-.16,1.08-3.59,2.53-7.07,4.33-10.36-.09.17-.89-.02-.99.16h0Z" />
      <path d="m512.94,224.11l-4.75,7.14c.03-.05.43-.06.49-.08.08-.01.45-.01.49-.08l4.75-7.14c-.03.05-.43.06-.49.08-.08.01-.45.01-.49.08h0Z" />
      <path d="m517.75,231.79c-1.1,1.73-2.2,3.46-3.29,5.2.03-.05.43-.06.49-.08.12-.02.23-.04.35-.06.02,0,.13,0,.14-.03,1.1-1.73,2.2-3.46,3.29-5.2-.03.05-.43.06-.49.08-.12.02-.23.04-.35.06-.02,0-.13,0-.14.03h0Z" />
      <path d="m622.25,360.37c1.81,1.71,3.92,3.8,3.65,6.52-.12,1.19-.7,2.4-.21,3.58.2.48.56.95.55,1.49-.01.6-.52.98-1.07,1.08l.92-.15c-.5.04-.96-.06-1.44-.19-.3-.08-.7.24-.99.16.88.23,2.21.34,3.04-.18.63-.4.59-1.06.32-1.67-.34-.75-.61-1.34-.51-2.2.09-.77.35-1.52.4-2.3s-.14-1.62-.47-2.37c-.69-1.58-1.97-2.78-3.2-3.94-.15-.14-.84.29-.99.16h0Z" />
      <path d="m617.87,380.72c-.69,1.84-.79,4.19.18,5.97.4.74,1,1.12,1.84,1,.33-.05.67-.09,1-.17.43-.1.81-.32,1.22-.47.85-.32,1.86-.25,2.6-.82.69-.53.95-1.45,1.05-2.27.2-1.7-.25-3.63-1.65-4.73-.72-.57-1.64-.89-2.55-.84-.75.04-1.61.17-2.28.52.28-.15.7-.01.99-.16.15-.08.29-.15.45-.2.11-.04.23-.06.34-.09-.7.22-.6.08-.36.09.38.01.74.05,1.1.15.66.19,1.27.57,1.74,1.07,1.04,1.11,1.39,2.73,1.25,4.22-.07.72-.25,1.48-.71,2.05-.52.65-1.3.79-2.07.94-.33.06-.65.14-.96.26-.18.07-.35.15-.53.23-.09.04-.18.07-.27.11-.52.19.22-.06.25,0,0,0-.47-.07-.51-.09-.15-.06-.29-.15-.41-.25-.29-.25-.5-.58-.65-.93-.81-1.78-.74-3.93-.06-5.74-.01.03-.44.07-.49.08-.06,0-.47.03-.49.08h0Z" />
      <path d="m622.22,399.43c-.15.15-.31.29-.5.39-.09.05-.19.08-.28.12.11-.05.39.02.05-.04-.45-.07-.87-.14-1.29-.33-.94-.43-1.6-1.23-2.24-2.01-1.14-1.4-2.43-2.85-4.42-2.58-.65.09-1.35.15-1.87.6-.74.64-.94,1.62-1.88,2.08-.83.4-1.79.43-2.65.76s-1.46.99-1.71,1.88c-.68,2.45,1.09,4.73.84,7.17,0-.08.98-.07.99-.16.17-1.68-.58-3.17-.88-4.79-.22-1.19-.2-2.65.69-3.59s2.32-.88,3.47-1.34c.65-.26,1.06-.69,1.42-1.27.19-.3.37-.61.63-.85.12-.11.26-.21.4-.29.18-.1.66-.21-.07-.1l-.15.02c2.73-.22,3.79,2.63,5.58,4.09.74.6,1.71.95,2.67.84.78-.09,1.6-.19,2.18-.77-.19.19-.8-.03-.99.16h0Z" />
      <path d="m591.43,394.72c2.92.01,5.13,2.07,7.45,3.56,1.08.69,2.22,1.15,3.42,1.59,1.06.39,2.12.87,2.71,1.88.06.11.93-.26.99-.16-.57-.99-1.59-1.47-2.62-1.85-1.23-.46-2.39-.91-3.5-1.62-2.32-1.49-4.53-3.54-7.45-3.56-.33,0-.66.16-.99.16h0Z" />
      <path d="m590.78,396.9c.45,3.58,4.05,5.43,6.13,8.01s3.32,5.78,3.56,9.09c0-.02.99-.12.99-.16-.23-3.3-1.47-6.51-3.56-9.09s-5.68-4.43-6.13-8.01c0,0-.99.14-.99.16h0Z" />
      <path d="m574.2,398.81c1.88.71,3.78,1.42,5.48,2.5.84.53,1.62,1.15,2.3,1.87s1.23,1.45,1.94,2.09c1.56,1.39,3.62,1.34,5.46,2.1,1.6.66,2.99,1.8,4.01,3.19,1.17,1.6,1.84,3.52,1.85,5.5,0-.04.99-.1.99-.16-.03-3.49-2.12-6.8-5.21-8.39-1.74-.89-3.87-.82-5.5-1.92-.79-.53-1.4-1.28-2.02-1.98s-1.34-1.42-2.14-2c-1.86-1.34-4.03-2.16-6.17-2.96-.27-.1-.72.26-.99.16h0Z" />
      <path d="m583.56,403.38c-.93-1.81-1.56-3.76-1.83-5.78-.2-1.49-.64-4.47,1.41-4.9l-.97.16c.68-.05,1.15.35,1.58.83.5.56.97,1.16,1.43,1.75.92,1.18,1.78,2.4,2.59,3.65s1.61,2.5,2.19,3.84c.52,1.21.68,2.67-.26,3.72.17-.19.81.04.99-.16,1.46-1.64.28-3.82-.63-5.4-1.08-1.89-2.29-3.71-3.62-5.45-.62-.81-1.24-1.69-1.97-2.4-.56-.54-1.09-.59-1.81-.48s-1.3.29-1.69.95c-.48.82-.4,1.89-.35,2.8.15,2.45.83,4.83,1.95,7.01.05.1.94-.25.99-.16h0Z" />
      <path d="m587.26,419.55c-.71-2.38-1.23-4.81-1.56-7.27,0,0-.99.14-.99.16.33,2.46.85,4.89,1.56,7.27.01.05.98-.19.99-.16h0Z" />
      <path d="m549.77,383.74c-1.21-1.1-2.53-2.12-4.06-2.72-1.32-.52-2.66-.62-4.05-.4-1.25.2-2.44.53-3.36,1.47-.97,1-1.46,2.42-1.11,3.79,0,.03.98-.18.99-.16-.29-1.14,0-2.32.68-3.27.32-.45.73-.84,1.2-1.15.26-.17.53-.3.82-.42.17-.07.35-.13.53-.18l.35-.08c-.45.07-.52.08-.21.05,2.86-.1,5.19,1.38,7.24,3.24.16.14.84-.3.99-.16h0Z" />
      <path d="m550.2,398.34c-.16,2.31.07,4.64.71,6.87.16.56.34,1.11.55,1.64.14.36.33.8.72.94.29.1.64,0,.93-.05.31-.05.65-.06.82-.37-.02.04-.43.07-.49.08-.07.01-.46.02-.49.08-.09.17-.21.24-.39.29l.97-.16c-.85.09-1.11-1.01-1.32-1.63-.28-.82-.51-1.66-.69-2.51-.36-1.76-.46-3.56-.34-5.35,0,.07-.98.08-.99.16h0Z" />
      <path d="m554.77,408.26c-1.16.36-1.44,1.61-1.44,2.68,0,1.42.09,2.84.13,4.25.02.66,0,1.34.07,1.99.05.49.27.85.8.84.34,0,.69-.09,1.02-.15.47-.09.87-.36,1.33-.48l-.92.15c.89-.13,1.6.57,2.38.87.6.24,1.2.21,1.82.12,1.44-.22,2.7-.74,3.13-2.29.5-1.8-.4-3.54-1.28-5.05-.82-1.42-1.69-2.93-3.22-3.69-.25-.12-.75.28-.99.16,1.79.89,2.83,2.89,3.72,4.59s1.56,4.22-.18,5.66c-.34.28-.69.41-1.09.57-.25.1.39.01.12-.03-.12-.02-.24,0-.36-.02-.24-.03-.48-.09-.7-.18-.82-.32-1.5-.98-2.43-.87-.31.04-.63.08-.93.15-.16.03-.31.08-.46.15-.13.05-.27.11-.38.18l-.16.07c.43-.02.55-.05.34-.08-.03-.04-.26-.09-.32-.15-.07-.06-.12-.14-.16-.21-.08-.16-.1-.36-.11-.54-.05-.64-.04-1.28-.06-1.92-.04-1.38-.12-2.77-.13-4.15,0-1.1.24-2.41,1.44-2.78-.31.1-.67.06-.99.16h0Z" />
      <path d="m573,417.73c-.82.13-1.57.36-2.34.68-.47.19-.94.39-1.43.54-.14.04-.29.1-.44.11.53-.05.1-.04.03-.05-.24-.04-.49-.02-.73-.07-2.13-.47-2.13-3.06-2.08-4.79.04-1.14.19-2.27.46-3.38.23-.93.59-1.98.12-2.89-.37-.7-1.13-1.07-1.86-1.27-.85-.24-1.83-.42-2.71-.36-.58.04-1.25.11-1.79.33-.82.33-1.4,1.42-.75,2.17.12.14.88-.29.99-.16-.81-.95.2-2.05,1.15-2.29l-.24.04c-.36.06.44.08.51.09.27.03.53.07.79.11.44.07.89.16,1.31.3.77.26,1.51.72,1.76,1.54.33,1.08-.23,2.3-.43,3.36-.23,1.2-.33,2.42-.29,3.63.03.97.09,2.09.72,2.89s1.56.97,2.48.89c.57-.05,1.17-.15,1.72-.29,1.13-.28,2.16-.97,3.32-1.16.69-.11.04-.02-.26.03h0Z" />
      <path d="m568.61,405.66c.64,3.68,1.97,7.21,3.91,10.41.07.11.93-.26.99-.16-1.94-3.2-3.27-6.72-3.91-10.41,0-.01-.99.16-.99.16h0Z" />
      <path d="m572.02,405.43c1.45,2.72,5.26,1.47,7.45,3.05,1.16.83,1.44,2.24.98,3.55s-1.62,2.35-2.69,3.26c.21-.18.77.03.99-.16,1.07-.92,2.21-1.89,2.69-3.26s.18-2.72-.98-3.55c-2.2-1.57-6-.33-7.45-3.05-.05-.1-.94.25-.99.16h0Z" />
      <path d="m568.18,432.97c.66-2.19-.88-4.08-1.82-5.92s-1.2-3.87.44-5.4c.71-.66,1.6-1.08,2.5-1.39,1.09-.38,2.17-.84,3.19-.55,2.02.57,2.48,2.93,2.74,4.71,0,0,.99-.15.99-.16-.24-1.64-.58-3.58-2.16-4.47-1.14-.64-2.73-.24-3.93.08-1.68.45-3.54.96-4.71,2.34-1.25,1.48-.92,3.29-.11,4.9.94,1.87,2.54,3.79,1.87,6.02.04-.13.94-.01.99-.16h0Z" />
      <path d="m552.96,423.35c.41-2.4.12-4.86-.85-7.09-.04-.08-.96.23-.99.16.97,2.23,1.27,4.7.85,7.09.02-.1.97-.05.99-.16h0Z" />
      <path d="m623.83,392.05c2.16.87,3.84,2.76,4.4,5.04.58,2.39-.17,4.97-1.85,6.74.18-.19.8.04.99-.16,1.68-1.77,2.43-4.35,1.85-6.74s-2.24-4.16-4.4-5.04c-.27-.11-.73.27-.99.16h0Z" />
      <path d="m632.93,425.02c-1.76-5.12-3.53-10.24-5.29-15.37-.02-.06-.97.21-.99.16,1.76,5.12,3.53,10.24,5.29,15.37.02.06.97-.21.99-.16h0Z" />
      <path d="m388.31,485.58c-1.12,1.89-1.86,3.95-2.45,6.06-.29,1.04-.59,2.09-.82,3.14-.18.81-.3,1.78.21,2.5.84,1.17,2.93.81,4.02.2,1.67-.94,2.84-2.83,3.3-4.65.57-2.23.09-4.61-1.21-6.5-.08-.12-.91.27-.99.16,1.31,1.92,1.78,4.34,1.18,6.59-.54,2.01-2.05,4.38-4.19,4.91l.24-.04c.23-.04.2-.03.01-.08-.15-.04-.3-.05-.45-.09-.23-.07-.45-.18-.63-.33-.42-.34-.61-.84-.66-1.37-.1-1.14.35-2.32.63-3.41.66-2.51,1.45-5,2.78-7.24-.11.18-.88-.03-.99.16h0Z" />
      <path d="m390.55,471.12l-.1.83s.45-.07.49-.08c.07-.01.46-.02.49-.08,1.11-2.04,2.22-4.08,3.33-6.12-.02.04-.43.07-.49.08-.07.01-.46.02-.49.08-1.11,2.04-2.22,4.08-3.33,6.12l.99-.16.1-.83c0,.08-.98.06-.99.16h0Z" />
      <path d="m387.22,463.65l1.99-7.03s-.45.07-.49.08c-.05,0-.48.04-.49.08l-1.99,7.03s.45-.07.49-.08c.05,0,.48-.04.49-.08h0Z" />
      <path d="m379.9,448.12c1.23-1.47,2.46-2.94,3.69-4.41,0,0-.16.02-.15.02-.12.02-.23.04-.35.06s-.23.04-.35.06c-.03,0-.13,0-.14.03-1.23,1.47-2.46,2.94-3.69,4.41,0,0,.16-.02.15-.02.12-.02.23-.04.35-.06s.23-.04.35-.06c.03,0,.13,0,.14-.03h0Z" />
      <path d="m376.81,440.32c1.3-2.05,2.59-4.1,3.89-6.15-.03.05-.43.06-.49.08-.08.01-.45.02-.49.08-1.3,2.05-2.59,4.1-3.89,6.15.03-.05.43-.06.49-.08.08-.01.45-.02.49-.08h0Z" />
      <path d="m376.81,427.91c-1.3,1.39-2.42,2.94-3.33,4.61.02-.04.43-.07.49-.08.12-.02.23-.04.35-.06.02,0,.13-.01.14-.03.91-1.67,2.03-3.23,3.33-4.61,0,0-.15.02-.15.02-.12.02-.23.04-.35.06s-.23.04-.35.06c-.03,0-.12,0-.14.03h0Z" />
      <path d="m372.82,420.26c-1.04.97-1.96,2.06-2.76,3.25,0,0,.16-.02.15-.02.12-.02.23-.04.35-.06s.23-.04.35-.06c.02,0,.13,0,.14-.03.79-1.18,1.72-2.27,2.76-3.25-.01.01-.15.02-.15.02-.12.02-.23.04-.35.06s-.23.04-.35.06c-.03,0-.12,0-.14.03h0Z" />
      <path d="m370.04,413.2c-.82,1.52-1.65,3.05-2.47,4.57.02-.04.43-.07.49-.08.12-.02.23-.04.35-.06.02,0,.14-.01.14-.03.82-1.52,1.65-3.05,2.47-4.57-.02.04-.43.07-.49.08-.12.02-.23.04-.35.06-.02,0-.14.01-.14.03h0Z" />
      <path d="m364.29,399.08c-.82.88-1.63,1.76-2.45,2.63,0,0,.15-.02.15-.02.12-.02.23-.04.35-.06s.23-.04.35-.06c.03,0,.12,0,.14-.03.82-.88,1.63-1.76,2.45-2.63,0,0-.15.02-.15.02-.12.02-.23.04-.35.06s-.23.04-.35.06c-.03,0-.12,0-.14.03h0Z" />
      <path d="m367.99,405.92c-1.36.77-2.63,1.69-3.79,2.73.01-.01.15-.02.15-.02.12-.02.23-.04.35-.06s.23-.04.35-.06c.03,0,.12,0,.14-.03,1.16-1.04,2.43-1.95,3.79-2.73-.02.01-.13.02-.15.02-.12.02-.23.04-.35.06s-.23.04-.35.06c-.04,0-.11,0-.14.03h0Z" />
      <path d="m401.88,473.2c1.14,4.71-4.55,8.07-3.24,12.78.01.04.98-.19.99-.16-1.31-4.71,4.38-8.07,3.24-12.78,0-.03-.98.18-.99.16h0Z" />
      <path d="m409.38,494.71c.37-4,1.3-7.93,2.78-11.67-.06.15-.92,0-.99.16-1.48,3.74-2.41,7.66-2.78,11.67,0-.08.98-.07.99-.16h0Z" />
      <path d="m417.35,502.4c.17-4.47,1.23-8.87,3.11-12.93-.08.16-.91-.02-.99.16-1.88,4.06-2.93,8.46-3.11,12.93,0-.06.98-.09.99-.16h0Z" />
      <path d="m428.86,495.55c-1.58,4.02-2.36,8.3-2.28,12.62,0-.04.99-.1.99-.16-.07-4.32.7-8.6,2.28-12.62-.06.15-.92,0-.99.16h0Z" />
      <path d="m437.54,514.28c.54-4.21,1.25-8.4,2.13-12.56-.02.11-.96.04-.99.16-.87,4.16-1.58,8.35-2.13,12.56.01-.09.97-.06.99-.16h0Z" />
      <path d="m444.73,504.77c.06,3.96.12,7.91.18,11.87,0-.04.99-.1.99-.16-.06-3.96-.12-7.91-.18-11.87,0,.04-.99.1-.99.16h0Z" />
      <path d="m476,527.29c-.66-2.55-.47-5.26.52-7.7-.01.04-.44.07-.49.08-.06,0-.47.03-.49.08-.99,2.44-1.18,5.15-.52,7.7,0,.04.98-.18.99-.16h0Z" />
      <path d="m491.68,530.04c.86-2.74,1.92-5.42,3.18-8-.02.04-.44.07-.49.08-.07.01-.46.02-.49.08-1.26,2.58-2.33,5.26-3.18,8,0-.03.45-.07.49-.08.05,0,.48-.04.49-.08h0Z" />
      <path d="m517.52,531.35c.2-2.52.84-4.98,1.88-7.29-.02.04-.44.07-.49.08-.06.01-.47.03-.49.08-1.04,2.3-1.68,4.77-1.88,7.29,0-.07.98-.07.99-.16h0Z" />
      <path d="m532.65,529.66c-.13-2.57-.08-5.14.16-7.7,0,.08-.98.07-.99.16-.24,2.56-.29,5.13-.16,7.7,0-.03.99-.12.99-.16h0Z" />
      <path d="m541.55,519.14c.92,3.22,1.27,6.57,1.04,9.91,0-.07.98-.08.99-.16.23-3.34-.12-6.69-1.04-9.91-.01-.04-.98.19-.99.16h0Z" />
      <path d="m557.91,525.23c-.26-2.56-.32-5.13-.18-7.7,0,.06-.98.08-.99.16-.14,2.57-.08,5.14.18,7.7,0-.01.99-.13.99-.16h0Z" />
      <path d="m568.16,512.7c1.04,3.08,1.56,6.3,1.54,9.55,0-.05.99-.1.99-.16.01-3.25-.51-6.47-1.54-9.55-.02-.06-.97.2-.99.16h0Z" />
      <path d="m588.39,503.74c1.25,2.64,1.82,5.55,1.66,8.46,0-.06.98-.08.99-.16.16-2.91-.41-5.82-1.66-8.46-.04-.09-.95.24-.99.16h0Z" />
      <path d="m597.22,497.83c1.39,2.46,2.25,5.18,2.52,7.99,0-.01.99-.13.99-.16-.27-2.81-1.13-5.53-2.52-7.99-.06-.11-.93.25-.99.16h0Z" />
      <path d="m603.36,492.92c.62,2.43,1.23,4.87,1.85,7.3,0,.04.98-.18.99-.16-.62-2.43-1.23-4.87-1.85-7.3,0-.04-.98.18-.99.16h0Z" />
      <g style={styles.three}>
        <path d="m424.56,518.32c6.81,5.64,14.27,10.48,22.19,14.43,7.91,3.94,16.27,7,24.87,9.06s17.73,3.18,26.73,3.18,18.21-1.1,27.09-3.05c9-1.98,17.78-5.01,26.06-9.06,1.02-.5,2.03-1.01,3.04-1.54-.24.13-.72.11-.99.15-.29.05-.72.03-.99.17-7.92,4.16-16.32,7.39-24.98,9.62s-17.99,3.39-27.15,3.39c-8.67,0-17.34-.98-25.79-2.93-8.3-1.91-16.39-4.75-24.07-8.41-7.65-3.65-14.91-8.13-21.59-13.36-.82-.65-1.64-1.3-2.44-1.97-.11-.09-.85.13-.99.15s-.88.26-.99.17h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m491.43,544.69c-.47,3.85,2.57,6.72,5.53,8.62,3.24,2.08,6.85,3.58,10.61,4.4,3.07.67,6.22.78,9.29.03,1.69-.42,3.2-1.23,4.52-2.36s2.52-2.56,3.86-3.76c1.53-1.37,3.27-2.53,4.33-4.34s1.43-3.88,1.8-5.9c-.02.1-.97.04-.99.16-.31,1.69-.63,3.4-1.33,4.97s-1.84,2.78-3.16,3.89-2.6,2.28-3.82,3.53-2.4,2.36-3.91,3.13c-3.09,1.56-6.67,1.17-9.93.42-3.46-.8-6.79-2.16-9.81-4.03s-6.49-4.84-6-8.91c-.01.08-.98.06-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m541.04,536.99c-.12,1.69.19,3.39.92,4.92s1.82,2.97,3.37,3.59c1.26.5,2.56.33,3.86.11,1.69-.3,3.18-1.12,4.66-1.96-.27.15-.71,0-.99.16-.68.39-1.35.77-2.06,1.11-.32.15-.64.29-.97.41-.2.07-.4.14-.6.2-.09.03-.81.19-.47.13s-.37.02-.47.02c-.19,0-.39,0-.58-.02-.32-.03-.63-.08-.94-.17-.7-.2-1.32-.57-1.86-1.06-2.09-1.88-3.07-4.85-2.88-7.61,0,.07-.98.08-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m456.25,539.35c-1.17,2.55-2.42,5.46-1.3,8.25.5,1.24,1.35,2.31,2.19,3.34.78.95,1.59,1.89,2.58,2.63,1.76,1.31,3.81,1.36,5.85.79,2.49-.69,4.59-2.61,6.15-4.6,1.76-2.24,2.93-4.89,3.42-7.69-.02.1-.97.05-.99.16-.79,4.51-3.46,8.96-7.44,11.35-.49.29-1,.55-1.53.74-.13.05-.26.09-.4.13-.07.02-.84.15-.51.13.25-.01-.18-.01-.23-.01-.17,0-.34,0-.51-.02-1.17-.11-2.22-.65-3.12-1.39s-1.58-1.54-2.27-2.39c-.78-.95-1.56-1.93-2.07-3.05-1.3-2.87-.04-5.88,1.18-8.54-.08.16-.91-.01-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m426.19,524.29c1.96,1.55,2.95,3.88,4.11,6.02s2.8,4.11,4.76,5.7,4.25,2.94,6.52,4.16c1.15.62,2.31,1.35,3.57,1.71.88.25,1.72.17,2.6-.01,2.8-.57,2.76-4.48,5.5-5.13l-.84.14.18-.03-.15.02c.19-.01.35,0,.54.02.32.04.67-.2.99-.16-.7-.09-1.66.05-2.29.37-1.1.55-1.69,1.67-2.29,2.68-.29.49-.6.98-1,1.38-.18.19-.38.35-.61.49-.13.08-.26.13-.39.2l-.2.07c.25-.03.25-.04-.02-.03-.64-.11-1.23-.21-1.84-.47-.64-.27-1.26-.61-1.87-.94-4.45-2.37-9.05-5.03-11.69-9.5-1.4-2.37-2.36-5.1-4.58-6.86-.18-.14-.81.3-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m438.58,592.75c-.81-.36-1.6-.23-2.45-.09s-1.59.47-2.22,1.05c-.81.74-1.31,1.75-1.71,2.76s-.88,2.07-.86,3.13c.05,2.5,3.18,2.36,4.91,2.11,1.87-.27,4.13-.6,5.59-1.91,1.83-1.65,1-4.66-1.43-5.11-.31-.06-.68.22-.99.16,1.67.31,2.78,1.93,2.36,3.6-.46,1.8-2.44,2.52-4.05,2.95-.96.25-1.97.4-3.03.27-.8-.1-1.63-.44-2.08-1.15-.51-.8-.29-1.78-.02-2.62s.59-1.75,1-2.57,1-1.65,1.81-2.19c.19-.12.39-.23.59-.33.09-.04.18-.08.28-.11l.14-.05c.19-.05.19-.05.01-.02-.29-.05-.01.02.08.02.12,0,.24.01.36.03.24.04.47.12.69.22.26.12.74-.27.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m494.71,572.93c-2.02-.12-4.01.08-6,.43-2.2.4-4.48,1.08-6.4,2.25-1.78,1.09-2.57,2.91-1.97,4.93s2.33,3.41,4.21,4.3c1.03.49,2.11.86,3.19,1.23,1.17.4,2.35.79,3.57.98,1.8.27,3.75-.02,5.48-.55,1.2-.37,2.34-.89,3.45-1.46.95-.49,1.87-1.07,2.55-1.91,1.16-1.45,1.58-3.46,1.16-5.26-.94-4.04-5.65-5.28-9.13-3.8.3-.13.69-.03.99-.16.18-.08.36-.15.55-.21.14-.05.87-.2.32-.11s.26,0,.42,0c.2,0,.39,0,.59.03.78.07,1.55.29,2.25.64,1.38.69,2.45,1.87,2.91,3.35s.32,3.18-.37,4.59c-.81,1.66-2.32,2.56-3.93,3.34-2.05,1-3.92,1.67-6.14,1.36-1.99-.28-3.97-1.03-5.84-1.78-1.64-.65-3.31-1.53-4.37-2.99-.93-1.28-1.42-3.04-.84-4.57.74-1.94,3.07-2.82,4.87-3.46,2.43-.87,4.92-1.18,7.49-1.03.33.02.66-.18.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m526.29,564.48c-1.5-.03-2.99.45-4.15,1.39-1.62,1.32-2.72,3.11-4.5,4.27s-3.66,1.79-5.59,2.44c-1.61.54-3.55,1.02-4.65,2.42-1.3,1.67-.49,3.87.15,5.63s1.34,3.36,2.5,4.73c1.32,1.56,2.99,2.22,4.98,2.5,1.09.15,2.16.3,3.22.63s2.05.74,3.09,1.08c1.81.58,3.62.71,5.47.25s3.5-2.29,2.89-4.28c-.02-.05-.98.2-.99.16.45,1.47-.37,2.99-1.62,3.77-1.63,1.02-3.74.31-5.4-.27-.92-.32-1.83-.69-2.77-.96s-1.92-.39-2.89-.53c-1.75-.25-3.3-.76-4.57-2.04-1.1-1.12-1.85-2.55-2.45-3.99-.64-1.54-1.45-3.36-1.3-5.07s1.51-2.72,2.97-3.35c1.6-.68,3.3-1.11,4.92-1.75,1.92-.76,3.69-1.77,5.12-3.29.73-.77,1.38-1.61,2.18-2.31.63-.56,1.61-1.29,2.4-1.27.33,0,.66-.17.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m527.89,564.95c3.09-.16,5.56,2.27,6.55,5.01,1.25,3.48.6,7.23.48,10.83-.03.9-.03,1.81.05,2.71.08.96.3,1.92.25,2.89-.08,1.63-1.12,2.88-2.42,3.76-2.33,1.57-5.24,1.99-7.6,3.52-1.43.93-2.25,2.2-2.9,3.75-.35.82-.68,1.66-1.25,2.35-.29.35-.62.67-1.01.91-.16.1-.34.16-.5.26-.26.08-.23.09.09.02-.13,0-.25-.01-.37-.03-.32-.04-.67.2-.99.16,1.07.15,2.4-.2,3.22-.9,1.34-1.16,1.65-2.97,2.51-4.44,1.54-2.65,4.71-3.43,7.35-4.52,1.41-.58,2.85-1.29,3.84-2.48.54-.65.9-1.42.99-2.27.1-1-.13-2.01-.22-3.01-.35-3.92.66-7.87-.05-11.77-.62-3.45-3.14-7.11-7.05-6.9-.33.02-.66.14-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m531.76,565.01c-.34-3.26.15-6.58,1.48-9.58.62-1.41,1.41-2.82,2.53-3.9.5-.49,1.08-.9,1.72-1.19.18-.08.38-.12.55-.21l.19-.06c-.33.01-.27,0,.16-.02.39-.01.77.03,1.15.13,2.65.71,3.51,3.82,3.92,6.19.26,1.52.4,3.13-.06,4.63-.36,1.19-1.13,2.16-2.04,2.98-1.87,1.69-4.58,2.97-5.22,5.62.03-.12.96-.03.99-.16.6-2.49,3.05-3.78,4.86-5.31,1-.85,1.9-1.83,2.34-3.1.55-1.62.38-3.38.08-5.03-.46-2.55-1.57-5.91-4.64-6.1-1.04-.07-2.32.18-3.26.6-1.49.67-2.57,1.99-3.38,3.37-1.97,3.39-2.75,7.41-2.35,11.29,0-.01.99-.13.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m559.15,551.93c-3.83.32-8.54.66-11.11,3.93-2.76,3.51-.79,8.17-.47,12.11.08,1.03.08,2.06-.13,3.08s-.57,2.18.59,2.79c1.52.8,3.42.83,5.08.57s3.25-.7,4.71-1.59c1.63-.98,3.02-2.37,3.96-4.02,2.49-4.37,1.94-9.88-.21-14.27-.05-.09-.95.24-.99.16,1.73,3.52,2.41,7.67,1.35,11.49-.95,3.41-3.37,6.23-6.66,7.58-.37.15-.76.29-1.15.4-.24.07-.49.14-.74.18.04,0,.22-.03.02,0s-.39.04-.58.04c-.83.04-1.66-.04-2.46-.24s-1.89-.47-2.14-1.36c-.24-.84.22-1.82.32-2.66.41-3.76-1.41-7.42-.88-11.18.3-2.06,1.46-3.77,3.19-4.91,2.19-1.44,4.75-1.74,7.3-1.96.33-.03.66-.13.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m571.03,556.48c-.55,2.45-1.28,4.85-2.21,7.18-.45,1.13-.94,2.24-1.47,3.34-.45.93-.92,2.21-2.07,2.43l.97-.16c-2.08.16-2.5-2.25-2.6-3.81,0,.02-.99.12-.99.16.1,1.54.52,3.99,2.6,3.81.38-.03.79-.09,1.15-.19.76-.21,1.17-.93,1.51-1.59,1.85-3.57,3.2-7.41,4.09-11.33-.03.11-.96.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m560.27,574.93c-.44.31-.56.89-.4,1.38.18.55.7.87,1.25.95.29.04.57,0,.86-.04.37-.06.77-.1,1.13-.23.6-.22,1.09-.67,1.47-1.17.5-.65.84-1.4,1.03-2.19,0,.02-.45.07-.49.08-.05,0-.48.04-.49.08-.19.79-.54,1.54-1.03,2.19-.22.28-.46.54-.74.76-.15.11-.31.22-.47.3-.08.04-.17.08-.26.11-.14.05-.48.11.11.01.63-.1.05-.04-.15-.07-.17-.03-.33-.07-.49-.15-.28-.13-.52-.34-.66-.61-.26-.52-.17-1.22.32-1.57-.02.01-.14.02-.15.02-.12.02-.23.04-.35.06s-.23.04-.35.06c-.04,0-.11,0-.14.03h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m517.16,604.98c.78,1.43,2.5,1.22,3.85.96,2.04-.39,3.46-2.15,5.46-2.67.14-.04.79-.26.1-.04.16-.05.54-.05.64-.06.26-.02.51-.02.77-.02.63,0,1.25.03,1.88.08,1.28.09,2.55.22,3.82.39,2.21.29,4.72.49,6.72,1.53,1.78.93,3.25,3.31,1.84,5.18.14-.19.84.04.99-.16,1.19-1.57.29-3.74-1.18-4.78-1.81-1.28-4.31-1.5-6.44-1.81-2.42-.35-4.97-.73-7.42-.58-1.79.11-3.49.52-5.01,1.49-.53.34-1.04.7-1.59,1.01-.24.13-.49.26-.75.36-.1.04-.2.07-.29.1-.34.13.13.05-.03.01-.45-.12-.91-.04-1.36-.24s-.78-.5-1.01-.93c-.06-.1-.94.25-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m540.89,610.79c-1.2.33-2.36.65-3.61.59-.99-.05-2-.31-2.83-.85-1.59-1.04-2.28-3.09-1.73-4.89-.04.13-.94.01-.99.16-.72,2.35.66,4.75,2.97,5.47s4.92-.02,7.17-.63c-.32.09-.67.07-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m552.78,596.26c.06-.02.12-.04.19-.05.25-.06.42.01-.3.04.14,0,.28-.02.42-.02.24,0,.48.01.72.04.5.07.98.21,1.43.42.88.42,1.61,1.11,2.08,1.96.45.8.71,1.8.58,2.72-.03.22-.09.44-.21.63-.15.23-.35.31-.58.43-.18.09.45-.06.44-.06-.07,0-.15.02-.22.02-.16.02-.31.03-.47.05-.34.03-.67.05-1.01.05-1.14,0-2.33-.32-3.04-1.28-.03-.03-.44.07-.49.08s-.47.12-.49.08c.78,1.07,2.15,1.32,3.4,1.27.64-.02,1.29-.09,1.92-.19.54-.09,1.22-.11,1.55-.61.3-.47.27-1.12.2-1.65-.08-.59-.28-1.17-.57-1.7-.58-1.04-1.53-1.83-2.66-2.19-1.23-.4-2.62-.23-3.84.12.32-.09.67-.07.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m559.29,589.54c1.07-1.08,2.21-2.08,3.43-3,.59-.45,1.2-.88,1.82-1.28s1.26-.85,2-1.03l-.92.15c.75-.11,1.29.28,1.74.84.48.6.91,1.25,1.35,1.88s.91,1.22,1.32,1.85c.34.52.53,1.14-.05,1.55-.52.37-1.28.5-1.88.69-.79.26-1.58.52-2.36.78-1.58.52-3.15,1.03-4.73,1.55.31-.1.67-.06.99-.16,2.03-.66,4.05-1.33,6.08-1.99.89-.29,1.94-.49,2.76-.94.89-.5.4-1.34-.05-1.97-.61-.85-1.22-1.7-1.83-2.55-.47-.65-.97-1.55-1.84-1.69-.48-.08-1.06.07-1.52.17-.82.17-1.53.7-2.22,1.15-1.83,1.21-3.52,2.62-5.07,4.17.19-.19.79.03.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m567.49,594.56c.81,1.19.8,2.72,1.59,3.92.35.53.85.99,1.49,1.11.5.09,1.06-.05,1.54-.15.62-.12,1.13-.45,1.61-.85.65-.54,1.27-1.13,1.9-1.7,1.03-.93,2.48-1.91,2.74-3.38.23-1.32-.47-2.71-1.67-3.3-.25-.12-.75.28-.99.16,1.71.83,2.21,3.07,1.1,4.58-.7.95-1.71,1.7-2.58,2.48-.45.41-.9.82-1.36,1.22-.22.19-.45.38-.7.54-.11.07-.22.14-.34.2-.15.07-.6.2.01.1l.25-.04c-2.61.18-2.55-3.52-3.59-5.04-.08-.12-.91.27-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m577.28,590.18c-1.06,0-2.11.15-3.15.33s-1.98.47-2.92.87c.13-.06.35-.05.49-.08.15-.02.35-.02.49-.08.71-.31,1.44-.55,2.2-.73.19-.04.86-.13.11-.04.2-.02.4-.05.6-.07.4-.04.8-.05,1.19-.05.33,0,.66-.16.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m558.16,589.56c.08.75.35,1.47.78,2.09,0,0,.16-.02.15-.02.12-.02.23-.04.35-.06s.23-.04.35-.06c.02,0,.14-.03.14-.03-.43-.62-.7-1.34-.78-2.09,0,.01-.99.13-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m577.48,568.16c-.07,1.51-.09,3.43,1.36,4.36.67.43,1.36.3,2.1.17.62-.1,1.21-.33,1.48-.95-.02.04-.44.07-.49.08-.06,0-.47.03-.49.08-.11.25-.27.45-.49.61-.11.08-.23.14-.35.19-.05.02-.11.04-.16.06-.28.08-.07.05.63-.09-.05,0-.1,0-.16,0-.58.01-1.11-.26-1.5-.67-1.02-1.06-.98-2.63-.92-4,0,.06-.98.08-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m581.48,566.98c1.61,2.26,3.13,4.71,4.16,7.3.5,1.27.33,2.41-.63,3.39-.89.91-2.09,1.44-2.95,2.39.17-.19.81.04.99-.16.84-.92,2-1.45,2.89-2.32,1.01-.99,1.22-2.15.7-3.46-1.03-2.59-2.55-5.04-4.16-7.3-.09-.13-.91.27-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m576.45,548.74c-.59,2.51-1.18,5.02-1.77,7.53l-.46,1.94c-.14.6-.36,1.23-.4,1.85-.07,1.09.84,1.53,1.76,1.78,1.23.34,3.34.39,3.61-1.23.19-1.13.07-2.36.08-3.51.01-1.32.03-2.64.04-3.97,0,.05-.99.1-.99.16-.03,2.29,0,4.58-.08,6.86-.01.37-.04.76-.19,1.1-.08.18-.18.34-.33.47-.09.08-.19.14-.29.19l.3-.04c-.08.13-1.1-.18-1.26-.23-.39-.11-.79-.24-1.11-.49-.85-.63-.5-1.64-.3-2.51l.77-3.28,1.6-6.8c-.03.12-.96.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m568.97,529.34c-1.99,6.21-3.97,12.42-5.96,18.62.04-.14.94,0,.99-.16,1.99-6.21,3.97-12.42,5.96-18.62-.04.14-.94,0-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m452.3,561c-2.31.35-4.95.61-6.65,2.39-2.13,2.22-.77,5.41-.06,7.96.85,3.08,1.76,6.15,2.72,9.2.38,1.2.72,2.48,1.5,3.5s1.97,1.52,3.23,1.62c1.09.09,2.18-.06,3.26-.23,1.36-.21,2.71-.44,4.06-.68,3.09-.53,6.18-1.07,9.25-1.68,1.12-.22,2.26-.6,2.57-1.84s-.19-2.79-.46-4.05c-.61-2.89-1.11-5.9-2.12-8.68-.51-1.38-1.23-2.69-2.34-3.68s-2.49-1.61-3.91-2.03c-3.08-.92-6.38-.89-9.36-2.18-.26-.11-.73.27-.99.16,4.43,1.91,10.46.89,13.9,4.85,1.61,1.85,2.17,4.34,2.71,6.66.34,1.44.66,2.89.97,4.34.27,1.26.67,2.59.7,3.89.02,1.18-.5,2.08-1.63,2.48-1.36.48-2.91.61-4.32.87-3.1.57-6.24,1.26-9.37,1.6-1.18.13-2.45.14-3.56-.33s-1.78-1.34-2.26-2.37c-.54-1.16-.86-2.43-1.23-3.64-.42-1.35-.83-2.71-1.22-4.07-.45-1.55-.9-3.11-1.32-4.68-.4-1.44-.95-2.97-.87-4.49.16-3.17,3.48-4.36,6.13-4.77.23-.04.47-.07.7-.11.14-.03.19-.04,0,0h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m458.17,587.12c-.04,1.51-.06,3.11.5,4.54.64,1.64,2,2.42,3.61,2.93,3.82,1.21,7.71,2.37,11.67,3.03,1.91.32,3.87.53,5.81.5,1.01-.02,2.27-.14,3.18-.6,1.85-.95.92-2.91.08-4.23-1.12-1.77-2.23-3.53-3.35-5.29-.59-.93-1.12-1.95-1.82-2.8-.56-.67-1.3-1.03-2.17-.94-1.59.16-2.86.78-4.1,1.79.22-.18.76.02.99-.16.58-.47,1.18-.91,1.85-1.23.18-.09.37-.14.55-.23.49-.22-.08-.01-.07-.02.22-.19,1.04.18,1.23.3.58.34.96.93,1.31,1.49.53.83,1.05,1.66,1.58,2.49.96,1.52,1.93,3.04,2.89,4.57.73,1.16,1.87,2.9.56,4.1-.21.19-.46.34-.73.45-.13.05-.26.09-.39.13l-.08.02c.24-.02.18-.01-.18.01-.87.05-1.76,0-2.63-.07-1.73-.12-3.45-.38-5.15-.72-3.46-.7-6.89-1.69-10.24-2.79-1.54-.5-2.8-1.3-3.41-2.86-.56-1.43-.54-3.03-.5-4.54,0,.06-.99.09-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m450.95,603.77c1.08-1.27,2.56-2.07,4.17-2.47,1.7-.42,3.83-.11,5.61.25,2.07.42,4.1,1.03,6.19,1.4s4.21.53,6.32.48c1.77-.04,3.53-.26,5.27-.55,1.48-.25,3.39-.83,4.49.58.92,1.17.76,3.08.58,4.47-.1.73-.28,1.5-.75,2.09-.21.27-.51.52-.84.64-.44.16-1,.05-1.47.04-1.24-.04-2.48-.07-3.72-.11-.96-.03-2.06.03-2.91-.48-1.61-.96-1.18-3.19-.78-4.69-.03.13-.95.02-.99.16-.39,1.47-.82,3.64.7,4.64.83.55,1.92.5,2.87.53,1.24.04,2.48.07,3.72.11,1.33.04,3.38.2,4.35-.94,1.04-1.22,1.04-3.46.84-4.96-.11-.86-.44-1.7-1.2-2.18-.72-.45-1.6-.45-2.41-.35-.64.08-1.27.19-1.9.31-1.46.28-2.91.46-4.39.51-2.4.09-4.8-.08-7.16-.53s-4.48-1.13-6.75-1.5c-3.6-.6-8.29-.27-10.83,2.71.16-.19.82.04.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m456.13,603.22c-.65,1.67-1.44,4.1.25,5.46.85.69,2.04.58,3.06.57,1.22-.02,2.44-.03,3.66-.05,1.74-.02,3.6.16,5.3-.25,2.11-.51.65-3.39,1.48-4.74-.11.18-.87-.03-.99.16-.84,1.37.64,4.22-1.48,4.74l.97-.16c-1.56.2-3.22.08-4.79.1s-3.11.15-4.63.03c-1.27-.1-2.2-.82-2.51-2.07-.34-1.35.18-2.7.66-3.94-.06.15-.92,0-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m501.76,602.63c-1.16.06-2.4.29-3.41.88-.69.4-1.29.94-1.75,1.59-.22.31-.41.65-.55,1-.05.12-.2.37-.18.5.03.17.48.18.69.22,2.07.38,4.14.84,6.23,1.14,1.1.16,2.3.1,3.37-.23,1.6-.49,1.88-2.24.93-3.49-1.11-1.46-3.17-1.49-4.83-1.67-.95-.11-1.92-.27-2.76-.76-.71-.42-1.27-1.04-1.8-1.66-1.13-1.33-2.24-2.46-4.07-2.66-1.56-.17-3.24.2-4.76.55-.5.12-.99.25-1.49.36-.13.03-.26.06-.39.09-.17.04-.26.04-.04.01-.29.03-.58.06-.87.07-1.01.02-1.98-.18-2.82-.75-.2-.14-.79.29-.99.16,2.27,1.55,5.43.38,7.85-.16,1.57-.35,3.34-.32,4.72.62,1.17.8,1.88,2.08,2.93,3.01,1.32,1.15,2.99,1.23,4.64,1.41,1.44.16,3.08.5,3.87,1.85.68,1.15.4,2.6-.91,3.12-.12.05-.53.09-.1.06-.17.01-.34.01-.5,0-.28,0-.57-.03-.85-.07-.86-.11-1.71-.3-2.57-.46l-3.11-.57-.72-.13-.48-.09c-.24.04-.3-.03-.19-.21,0-.29.28-.72.42-.97.39-.69.94-1.29,1.59-1.75.31-.22.64-.41.99-.57.16-.07.33-.13.49-.19.16-.05.33-.1.49-.13-.54.07-.57.09-.1.05.33-.02.66-.14.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m497.8,592.97c1.02,3.86,3.7,7.19,7.26,8.99.09.04.4-.06.49-.08s.4-.13.49-.08c-3.56-1.81-6.25-5.13-7.26-8.99-.01-.04-.98.18-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m501.38,592.87c2.03,1.97,2.79,5.15,5.55,6.35.97.42,1.88.34,2.89.16.53-.1.98-.3,1.42-.62.59-.44,1.08-.95,1.84-1.1l-.97.16c1.11-.09,2.07.6,3.17.59.54,0,1.14-.11,1.66-.25.92-.24,1.65-.91,2.25-1.61.69-.8,1.42-1.77,1.77-2.78.82-2.41-2.09-3.08-3.81-3.44-2.3-.48-4.68-.6-7.02-.37s-4.48.67-6.6,1.43c.31-.11.68-.05.99-.16,3.22-1.15,6.58-1.47,9.97-1.02,2.06.27,6.75.86,5.37,4.03-.51,1.18-1.44,2.41-2.41,3.25-.22.19-.46.37-.71.51-.1.06-.2.11-.3.15s-.53.11-.16.1c-.76.02-1.46-.35-2.18-.51s-1.79-.08-2.51.24c-.55.25-.97.71-1.47,1.05-.24.16-.51.33-.79.4.37-.09-.12-.04-.2-.05-.17-.02-.35-.05-.52-.09-3.23-.8-4.08-4.49-6.22-6.58-.15-.14-.85.29-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m389.29,503.88c-1.24,3.99-1.95,8.14-2.09,12.32-.07,2.06-.03,4.14.2,6.19.19,1.72.67,3.55,2.11,4.66,1.12.86,2.4.78,3.7.49,1.76-.4,2.89-1.89,3.5-3.5,1.44-3.82,1.22-8.22.71-12.19-.15-1.15-.33-2.29-.52-3.42s-.29-2.25-.64-3.3c-.28-.82-.9-1.62-1.84-1.61-.49,0-1.1.1-1.54.3.29-.13.69-.03.99-.16.1-.05.2-.09.31-.12l-.92.15c1.88-.27,2.19,2.21,2.42,3.49.32,1.8.63,3.61.84,5.42.43,3.61.52,7.4-.55,10.91-.47,1.55-1.29,3.09-2.8,3.86-.15.07-.3.14-.45.2-.16.06-.51.07-.06.06-.38,0-.76-.04-1.12-.16-.7-.23-1.3-.71-1.77-1.28-1.07-1.31-1.31-3.06-1.45-4.7-.15-1.81-.19-3.62-.13-5.43.14-4.18.86-8.32,2.09-12.32-.04.14-.94.01-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m399.62,496.66c-1.61,6.11-1.6,12.6,0,18.71.39,1.49.88,2.95,1.46,4.38.48,1.18,1.06,2.64,2.26,3.26.71.37,1.69.22,2.44-.01,1.3-.4,2.04-1.96,2.62-3.07.83-1.59,1.48-3.28,1.9-5.02.2-.81.39-1.75,0-2.54-.31-.61-.9-1-1.59-.92-.3.04-.72.13-.95.16,1.27-.15,1.83,1.2,1.77,2.25-.08,1.27-.56,2.6-1.01,3.78s-1,2.34-1.65,3.43c-.53.9-1.17,1.86-2.24,2.13l.74-.12-.17.03.25-.04c-1.68.11-2.6-1.65-3.16-2.97-.76-1.79-1.37-3.64-1.83-5.53-.92-3.78-1.24-7.7-.95-11.57.17-2.2.54-4.37,1.1-6.5-.03.12-.95.02-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m405.5,497.01c-1.43,1.45-1.89,3.5-2.06,5.47s-.12,4.17,1.46,5.55c1.29,1.12,2.85,1.18,4.45.91,1.69-.29,3.09-1.41,3.72-3.01.81-2.08,0-4.45-1.67-5.85-.17-.14-.82.3-.99.16,1.35,1.13,2.16,2.9,1.96,4.68-.19,1.62-1.25,3.11-2.72,3.8-.18.09-.37.14-.55.22-.12.04-.32.02,0,.02-.08,0-.17,0-.25,0-.41,0-.83-.06-1.23-.17-.77-.21-1.49-.63-2.03-1.21-1.27-1.35-1.32-3.33-1.18-5.08.16-2.03.6-4.16,2.08-5.66-.19.19-.8-.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m396.94,485.27c.94,2.03,1.85,4.23,2.27,6.44.46,2.41-2.14,2.77-3.38,4.21l.99-.16c-1.19-3.37-1.99-6.87-2.4-10.42,0,0-.99.14-.99.16.41,3.55,1.22,7.05,2.4,10.42,0,0,.94-.1.99-.16,1.23-1.44,3.84-1.8,3.38-4.21-.42-2.21-1.33-4.41-2.27-6.44-.04-.09-.95.23-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m383.02,510.18c-.69,2.16-1.19,4.38-1.5,6.63-.15,1.12-.26,2.25-.31,3.39-.03.55-.04,1.09-.04,1.64,0,.45-.01.95.24,1.34.44.7,1.44.59,2.14.51.52-.06,1.1-.12,1.6-.28,1.06-.34,1.01-1.37,1.05-2.31.05-1.19.09-2.37.14-3.56.05-1.31.1-2.62.15-3.93.03-.67.1-1.36.07-2.03-.01-.37-.09-.84-.53-.89-.32-.03-.67.19-.99.16.78.07.51,1.44.49,1.94-.04,1.06-.08,2.13-.12,3.19-.08,2.07-.03,4.19-.25,6.26-.04.36-.12.72-.39.98-.13.13-.29.19-.44.28-.03.02-.32.18-.05.05.25-.12-.17.01-.3.02-.76.04-1.55-.12-1.76-.96s-.06-1.92-.01-2.81c.06-1.02.15-2.03.28-3.04.3-2.29.82-4.54,1.51-6.75-.04.14-.94.01-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m380.41,543.11c.56-3.1,1.11-6.21,1.67-9.31.27-1.51.55-3.02.81-4.53.21-1.27.34-2.75-.57-3.79-.68-.77-1.59-.78-2.52-.63s-1.74.4-2.41,1.08c-.89.91-1.23,2.22-1.46,3.43-.25,1.3-.46,2.62-.62,3.94-.37,3.02-.4,6.16.92,8.98.04.09.95-.24.99-.16-1.06-2.27-1.26-4.76-1.09-7.23.09-1.28.27-2.55.47-3.81.19-1.15.34-2.35.72-3.46.35-1.01.93-1.95,1.89-2.47.13-.07.26-.13.39-.18.06-.02.39-.1.16-.07s.11.02.16.03c.11.02.22.03.32.05.52.13.96.45,1.27.89.76,1.09.59,2.46.38,3.69-.24,1.39-.5,2.77-.74,4.16-.57,3.18-1.14,6.37-1.71,9.55.02-.1.97-.04.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m389.64,534.13c1.31,3.81,2.67,8.3.19,11.95-.47.69-1.06,1.31-1.76,1.76-.34.22-.7.4-1.08.53-.11.04-.22.08-.33.11.04,0,.28-.02.06-.02-.23,0-.45,0-.68-.05-.84-.15-1.53-.67-1.76-1.51-.27-.98.04-2.05.23-3.02.53-2.67,1.06-5.34,1.58-8-.02.11-.96.04-.99.16-.52,2.63-1.04,5.26-1.56,7.88-.2,1.03-.56,2.19-.23,3.23.3.96,1.2,1.43,2.16,1.47,3.66.16,6.27-2.88,6.67-6.33.34-2.85-.61-5.66-1.53-8.31-.02-.06-.97.21-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m398.27,526.93c1.38,1.34,3.4,1.64,4.85,2.87,1.78,1.51,1.8,4.3,1.79,6.44,0,1.2,0,2.52-.37,3.68-.15.46-.39.91-.77,1.22-.05.04-.62.34-.62.34,0-.07.63.02.12-.03-.15-.01-.31-.01-.46-.03-1.08-.14-2.12-.62-3.11-1.04-1.06-.46-2.1-.99-2.95-1.78-1.66-1.52-2.5-4.4-.41-5.98-.23.18-.75-.02-.99.16-1.98,1.49-1.33,4.23.2,5.77.89.89,2.02,1.48,3.16,1.98,1.06.46,2.21,1,3.39,1.07.64.04,1.43-.07,2.04-.28,1.14-.4,1.49-1.59,1.62-2.67.17-1.4.19-2.83.09-4.23-.09-1.23-.21-2.57-.82-3.67-1.22-2.18-4.07-2.33-5.76-3.97-.15-.14-.85.29-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m399.33,542.33c.57,2.09,2.24,3.51,3.58,5.11,1.21,1.45,2.24,3.28,1.61,5.21-.28.86-.83,1.45-1.63,1.83-.18.08-.31,0,.02.02-.13-.01-.27,0-.4-.01-.24-.02-.47-.06-.7-.11-.53-.12-1.05-.32-1.55-.54-1.73-.76-3.52-1.86-4.36-3.62-.78-1.65-.52-3.91,1.1-4.95-.26.16-.73,0-.99.16-1.55.99-1.86,3.11-1.2,4.74.81,1.97,2.85,3.18,4.72,3.95.94.39,1.9.64,2.92.49.78-.11,1.57-.21,2.19-.75,1.59-1.39,1.15-3.79.15-5.37-1.38-2.19-3.76-3.72-4.47-6.32-.01-.04-.98.19-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m415.56,546.99c-1.55,4.05-2.94,8.36-2.38,12.75.5,3.87,2.62,7.55,6.38,9.01,1.72.66,3.4.6,5.17.28.19-.03-.16.02-.18.02-.23.03-.45.07-.68.11.07-.01.63-.06.16-.04-.12,0-.24.02-.37.02-.22,0-.44,0-.66,0-.44-.02-.87-.09-1.3-.19-.8-.18-1.57-.49-2.29-.88-1.54-.85-2.8-2.12-3.68-3.63-1.91-3.27-2.02-7.25-1.29-10.88.46-2.3,1.25-4.53,2.09-6.72-.06.15-.92,0-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m427.29,549.6c-1.2-1.94-2.98-3.47-5.07-4.38-.1-.04-.39.06-.49.08-.12.02-.23.04-.35.06-.03,0-.12.04-.14.03,2.09.91,3.87,2.44,5.07,4.38.02.03.45-.07.49-.08s.48-.11.49-.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m422.89,527.08c-2.04,2.07-1.49,5.26-.11,7.53.63,1.04,1.58,2.02,2.87,2.04.85.01,1.98-.16,2.58-.81-.06.06-.41.06-.49.08-.1.02-.42,0-.49.08-.16.17-.33.32-.54.42-.12.06-.5.12-.11.09.42-.03.01-.02-.08-.03-.5-.03-.97-.21-1.38-.49-.92-.63-1.51-1.68-1.95-2.68-.91-2.08-1-4.68.7-6.39-.06.06-.4.06-.49.08-.1.02-.42,0-.49.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m420.44,529.71c-1.36.21-2.82.39-4.1-.24-.98-.48-1.5-1.35-1.74-2.4s-.33-2.17-.8-3.2-1.08-1.9-1.39-2.94c-.56-1.87.18-3.94,1.69-5.16.37-.3.79-.54,1.23-.72.15-.06.68-.13.78-.24l-.4.07.35-.03c.19,0,.37,0,.56,0,.99.06,1.97.37,2.81.91,1.97,1.28,2.93,3.67,2.75,5.96,0-.07.98-.07.99-.16.17-2.29-.78-4.69-2.75-5.96-1.65-1.07-3.6-1.07-5.45-.6-2.12.53-3.71,2.53-3.74,4.72-.01,1.24.56,2.32,1.13,3.38.59,1.12.92,2.18,1.13,3.43.24,1.41.72,2.63,2.14,3.2,1.32.53,2.77.29,4.13.09.23-.04.47-.07.7-.11.14-.03.19-.04,0,0h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m389.28,552.14c-.61-.38-1.72-.2-2.35.09-1.12.5-1.43,1.98-1.71,3.05-.35,1.31-.64,2.69-.69,4.04-.04,1.13.2,2.35,1.17,3.05.78.56,1.75.65,2.67.51,1.05-.16,1.99-.4,2.89-1,1.91-1.28,3.86-3.49,3.56-5.95-.15-1.22-.92-2.3-2.14-2.63-.8-.22-1.94-.06-2.7.29.29-.13.69-.02.99-.16.08-.04.18-.07.26-.11.43-.23-.34.11-.3.04.03-.06.5.04.56.05.42.08.82.26,1.16.52.71.53,1.1,1.37,1.19,2.24.2,2.01-1.08,3.74-2.49,5.02-.72.65-1.52,1.23-2.44,1.54-.12.04-.42.09-.47.13.09-.07.61-.02.22-.07-.2-.02-.4-.01-.6-.04-.44-.07-.87-.21-1.25-.45-1.71-1.08-1.35-3.43-1.06-5.12.21-1.2.48-2.43.92-3.57.19-.48.44-.98.86-1.3.08-.06.16-.11.25-.16.06-.04.13-.07.2-.09.2-.07.08-.06-.35.04.02.02.27.04.28.04.14.04.26.1.38.18.21.14.78-.29.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m404.77,563.21c.38.93-.16,1.77-.6,2.56-.41.75-.59,1.53-.47,2.38.26,1.78,1.57,3.12,3.03,4.05.78.5,1.61.94,2.45,1.33.65.3,1.29.48,2,.37.62-.09,1.3-.13,1.71-.68.5-.66.53-1.68.63-2.47.28-2.07.24-4.44-1.47-5.92-.17-.14-.83.3-.99.16,1.27,1.09,1.66,2.77,1.61,4.38-.03.95-.16,1.91-.34,2.85-.06.34-.14.68-.31.98-.07.12-.16.24-.26.34-.14.14-.37.19-.49.31.27-.27.59-.02.26-.08-.08-.01-.15-.02-.23-.03-.18-.03-.36-.08-.54-.14-.39-.13-.75-.32-1.12-.5-.76-.39-1.52-.78-2.22-1.26-1.3-.88-2.42-2.11-2.71-3.7-.16-.87-.01-1.68.4-2.46.44-.82,1.02-1.68.64-2.64-.03-.08-.96.22-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m399.5,572.83c-1.42,1.93-4,7.75.02,8.37,1.54.23,3.17.08,4.69-.19s2.99-.94,3.56-2.41c.8-2.04-1-3.76-2.4-4.96-.05-.04-.42.06-.49.08s-.44.13-.49.08c1.18,1.01,2.54,2.21,2.58,3.89.04,1.44-1.08,2.59-2.34,3.1-.2.08-.4.15-.61.2l-.15.04c-.2.04-.21.05,0,.02l.15-.02c-.11.01-.22.03-.33.03-.45.04-.89.05-1.34.05-.74,0-1.52.03-2.24-.16-1.55-.41-1.83-1.89-1.64-3.29.24-1.81.96-3.52,2.04-4.99-.04.05-.42.06-.49.08-.09.01-.44.01-.49.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m401.07,586.52c-1.6,1.49-3.33,3.14-3.66,5.41-.26,1.81.76,3.98,2.78,4.16,1.74.16,3.42-.74,4.89-1.53,1.86-1,3.89-2.1,5.28-3.74.73-.87.96-1.92.05-2.73-.64-.56-1.49-.89-1.86-1.71-.04-.08-.96.23-.99.16.54,1.21,2.61,1.52,2.49,3.06-.08.95-.93,1.7-1.6,2.28-.76.67-1.6,1.24-2.46,1.77s-1.7.97-2.57,1.43-1.73,1.03-2.72.76c-1.7-.46-2.52-2.33-2.32-3.97.28-2.32,2.06-4.01,3.68-5.51-.2.19-.78-.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m416.13,581.6l.03,5.14c0,.81,0,1.62.01,2.44.01.68.12,1.34.72,1.74,1.01.68,2.5.58,3.64.41,1.29-.2,2.48-.49,3.59-1.21-.25.16-.73,0-.99.16-.5.32-1.03.59-1.59.78-.15.05-.31.1-.46.14-.79.21.36.02-.45.07-.27.02-.54.02-.81,0-1.08-.06-2.39-.33-2.62-1.57-.11-.61-.06-1.26-.06-1.88v-2.1c-.02-1.43-.03-2.86-.03-4.29,0,.04-.99.1-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m386.11,579.95c1.21-.08,2.5-.07,3.62.46s1.78,1.5,1.89,2.69-.36,2.22-1.29,2.88c-.22.15-.45.28-.7.38-.14.05-.28.1-.42.14-.28.07-.35-.06.38-.06-.09,0-.18,0-.26,0-.57,0-1.12-.16-1.6-.47-1.07-.69-1.68-2-1.5-3.26-.01.09-.97.05-.99.16-.18,1.23.4,2.51,1.43,3.21s2.29.54,3.45.27,2.15-1.15,2.42-2.34-.14-2.56-1.13-3.34c-1.2-.94-2.86-.97-4.31-.88-.33.02-.66.14-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m386.45,567.75c-.82.53-1.64,1.05-2.45,1.58-.67.44-1.36.94-1.71,1.69-.73,1.55.34,3.39,1.51,4.4.59.5,1.29.88,2.05,1.04.69.14,1.34.03,2.02-.08,1.34-.23,2.65-1.06,2.94-2.48.37-1.8-1.26-3.3-1.02-5.1-.01.09-.97.06-.99.16-.2,1.47.81,2.69,1.03,4.09s-.63,2.64-1.88,3.19c-.15.07-.31.12-.47.17-.66.19.26-.06.26-.04,0,0-.28,0-.26,0-.37,0-.75-.08-1.1-.19-.68-.22-1.29-.61-1.81-1.1-1.04-.98-1.95-2.65-1.36-4.09.31-.76.99-1.29,1.65-1.74.84-.57,1.71-1.11,2.56-1.66-.25.16-.73,0-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m370.81,576.88c-1.17-1.39-.79-3.46.3-4.78.6-.73,1.39-1.28,2.21-1.72.9-.48,1.93-1.01,2.93-1.23l-.97.16c1.02-.07,1.31,1.07,1.64,1.83.45,1.04.88,2.09,1.23,3.17.68,2.11,1.04,4.39.55,6.58-.41,1.78-1.43,3.48-3,4.46-.37.23-.77.42-1.19.55-.09.03-.18.05-.27.08.12-.04.67-.02-.13,0-.25.01-.5,0-.75-.02-.86-.09-1.71-.4-2.39-.95-1.46-1.2-1.92-3.44-.89-5.05-.12.18-.86-.03-.99.16-.72,1.12-.72,2.53-.15,3.72.61,1.28,1.86,2.07,3.24,2.26,2.46.34,5.04-.76,6.41-2.81,1.95-2.94,1.43-6.67.31-9.82-.29-.81-.62-1.61-.96-2.4-.25-.58-.5-1.57-1.14-1.86-.38-.18-.9-.02-1.29.05-.61.11-1.18.36-1.75.6-1.51.64-3.13,1.46-4.01,2.91-.79,1.3-.96,3.05.07,4.27.11.14.88-.29.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m374.39,563.7c-.23-2.23-.46-4.47-.69-6.7-.1-.99-.34-2.12.02-3.08.27-.72.83-1.1,1.5-1.41.04-.02.18-.04.2-.07-.11.15-.66.04-.13.02.17,0,.34,0,.51.02.41.05.81.18,1.18.37.77.39,1.39,1.01,1.9,1.7.95,1.27,2.04,3.39.88,4.86s-3.61,1.18-4.39,3.02c.07-.16.92,0,.99-.16.76-1.81,3.17-1.58,4.33-2.96,1.32-1.57-.01-3.96-1.1-5.26-.6-.71-1.35-1.33-2.25-1.61-.67-.2-1.32-.14-2-.03s-1.31.23-1.86.65c-.77.59-.98,1.49-.94,2.42.06,1.37.27,2.76.41,4.12l.44,4.25s.99-.13.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m372.17,594.61c-.72,4.74-1.22,9.51-1.48,14.29-.13,2.37-.2,4.75-.22,7.12-.01,1.68-.21,3.97,1.67,4.74,1.06.43,2.52.2,3.57-.18,1.68-.6,2.57-2.25,2.98-3.9.52-2.08.57-4.31.62-6.44.05-2.39-.03-4.78-.17-7.17-.08-1.36-.18-2.72-.29-4.08-.1-1.2-.06-2.52-.42-3.68-.27-.89-.98-1.68-1.98-1.62-.54.03-1.25.11-1.69.45.23-.18.75.02.99-.16.17-.14.34-.22.55-.27l-.97.16c2.06-.28,2.28,2.25,2.4,3.68.17,2.1.34,4.2.45,6.3s.18,4.28.14,6.43c-.04,1.96-.1,3.97-.47,5.9-.29,1.5-.87,3.14-2.17,4.06-.27.2-.57.35-.89.47-.37.14-.33.13-.75.1-.63-.05-1.25-.28-1.71-.71-1.27-1.21-.9-3.54-.88-5.1.02-2.09.09-4.18.21-6.26.26-4.79.76-9.56,1.48-14.29-.01.09-.97.05-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m383.49,609.8l-.68,2.73c-.2.82-.45,1.64-.55,2.47-.09.75,0,1.51.44,2.14.39.56.98.95,1.59,1.25.67.33,1.42.61,2.16.7.59.07,1.18-.05,1.76-.15s1.03-.33,1.35-.81.4-1.12.02-1.61c-.1-.13-.89.28-.99.16.33.42.32.95.09,1.41-.1.2-.24.39-.41.54-.1.09-.2.17-.32.23-.05.03-.1.05-.15.07-.22.07-.19.08.1.02,0,0-.93-.11-1-.12-.32-.08-.64-.18-.95-.3-.56-.21-1.11-.48-1.59-.85-1.14-.88-1.29-2.13-1.01-3.48.32-1.53.75-3.05,1.12-4.56-.03.12-.95.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m390.37,614.31c-.31-1.73-.85-3.41-1.63-4.99-.01-.03-.45.07-.49.08-.04,0-.48.1-.49.08.77,1.58,1.32,3.26,1.63,4.99,0,.01.99-.16.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m398.55,605.18c-2.19,1.79-3.64,4.38-3.93,7.19-.15,1.47,0,2.96.44,4.37.33,1.05.95,2.85,2.34,2.71.3-.03.72-.13.95-.16-1.43.15-2.06-1.86-2.37-2.92-.42-1.42-.54-2.91-.36-4.38.34-2.74,1.78-5.24,3.91-6.98-.22.18-.76-.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m402.77,615.27c1.94-.81,2.22-3.16,2.3-5,.1-2.18-.21-4.36-.88-6.45-.02-.05-.97.2-.99.16.67,2.08.97,4.26.88,6.45-.08,1.84-.36,4.19-2.3,5,.3-.12.69-.03.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m407.53,617.34c-3.41,1.44-6.48,4.13-7.08,7.95-.54,3.43,1.53,7.14,5.17,7.61.32.04.67-.2.99-.16-3.64-.47-5.71-4.18-5.17-7.61.6-3.82,3.67-6.51,7.08-7.95-.3.13-.69.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m411.16,620.54c3.46.94,6.22,4.45,4.96,8.11-.53,1.53-1.8,2.82-3.37,3.27-.41.1-.4.1.01,0-.11,0-.22,0-.33,0-.17,0-.33-.02-.5-.04-.44-.06-.87-.18-1.27-.35-.76-.33-1.4-.87-1.87-1.56-.08-.12-.91.27-.99.16.83,1.23,2.24,1.94,3.72,1.95,1.05,0,2.28-.23,3.2-.74,2.86-1.59,3.39-5.37,1.67-8-.98-1.49-2.54-2.5-4.25-2.96-.3-.08-.7.24-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m413.53,611.04c.3-1.54-.61-2.87-1.14-4.24-.44-1.14-.71-2.64.29-3.58.2-.19.44-.34.7-.44.15-.06.6-.15.06-.07-.62.09.13.02.29.03.64.05,1.24.32,1.76.69,1.09.78,1.98,2.02,2.46,3.26.56,1.43.5,3.14-.57,4.32.05-.06.41-.06.49-.08.1-.02.42,0,.49-.08,1.04-1.14,1.13-2.79.62-4.19s-1.51-2.8-2.78-3.57c-1.08-.66-2.31-.58-3.49-.26-1.46.4-1.89,1.82-1.59,3.19.39,1.75,1.8,3.3,1.43,5.18.02-.11.96-.04.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m427.13,635.84c.18-2.07,1.47-3.86,3.22-4.92,1.98-1.2,4.27-1.62,6.53-.79,1.93.72,2.96,2.62,3.11,4.6.19,2.36-.85,4.61-1.81,6.71.07-.16.91.01.99-.16,1.44-3.14,3.27-7.86-.01-10.57-1.45-1.2-3.51-1.23-5.29-.99-1.57.21-3.13.53-4.51,1.37-1.76,1.06-3.04,2.85-3.22,4.92,0-.07.98-.07.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m451.49,642.44c.35-1.68-.56-3.33-.23-4.99.39-2.02,2.77-2.18,4.41-2.51,1.12-.22,2.23-.44,3.35-.66s2.05-.54,3.13-.4c1.98.26,3.09,2.5,4.05,4.02,1.3,2.05,2.44,4.2,3.44,6.42.04.08.95-.23.99-.16-.98-2.18-2.1-4.3-3.37-6.32-1.12-1.79-2.34-4.21-4.77-4.15-1.87.05-3.79.61-5.61.97-1.22.24-2.44.47-3.65.72-.86.18-1.8.41-2.41,1.1-1.44,1.64.09,4.2-.31,6.11.02-.11.96-.04.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m493.2,632.9c-.76-.61-1.66-1.29-2.69-1.18-.71.07-1.49.17-2.01.7-.67.69-1,1.77-1.33,2.65-.39,1.04-.7,2.15-.6,3.26.08.92.42,1.84,1.1,2.48.75.71,1.8.91,2.8.96.86.05,1.7-.05,2.54-.2s1.64-.39,2.26-1c1.9-1.88.56-4.74-.47-6.67-.05-.1-.94.25-.99.16.79,1.46,1.62,3.06,1.37,4.78-.11.73-.41,1.44-.95,1.95-.25.23-.53.42-.84.56-.18.08-.36.15-.55.21-.09.03-.18.05-.28.07-.52.14.75-.07.22-.03-1.5.1-3.32.05-4.38-1.2s-1.02-2.89-.64-4.32c.24-.92.61-1.83,1.01-2.7.33-.74.78-1.41,1.6-1.63l-.14.03.15-.03-.84.13c1.03-.09,1.91.57,2.67,1.18.05.04.42-.06.49-.08s.44-.13.49-.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m501.85,644.05c.32-2.95.3-5.92-.05-8.87,0,0-.99.14-.99.16.36,2.95.37,5.92.05,8.87,0-.08.98-.06.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m500.83,633.39c4.48-.39,8.95-.77,13.43-1.16l3.23-.28c1.05-.09,2.12-.25,3.18-.25.89,0,1.63.3,2.02,1.14s.49,1.83.67,2.72c.47,2.39.84,4.81,1.13,7.23,0,0,.99-.14.99-.16-.26-2.14-.58-4.28-.97-6.4-.18-.98-.34-1.99-.62-2.96-.23-.81-.68-1.49-1.55-1.67-.92-.19-1.95.03-2.87.11l-3.36.29c-2.24.19-4.48.39-6.71.58l-7.59.66c-.33.03-.66.13-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m523.52,635.78c1.6.91,3.79.35,5.44-.19,1.04-.34,2.13-.85,3.13-.83,1.17.03,1.97.54,2.74,1.37.65.71,1.28,1.08,2.26.94.75-.11,1.44-.27,2.15-.58.44-.19.9-.36,1.34-.55s-.11.07-.13,0c0,0,.25,0,.29,0,.23.01.47.06.69.13.81.24,1.5.76,2.17,1.25.73.53,1.49,1.14,2.38,1.37.71.18,1.48.01,2.18-.14-.23.05-.66.09-.95.15.78-.16.28-.08.02-.12-.18-.03-.35-.08-.52-.14-.36-.13-.69-.31-1.01-.51-.66-.42-1.26-.92-1.92-1.35s-1.35-.78-2.12-.81c-.56-.02-1.16.11-1.7.23-.87.2-1.68.66-2.51.97-.38.14.15-.12.18-.01,0,0-.35-.02-.32-.02-.2-.03-.38-.09-.56-.18-.45-.23-.77-.63-1.11-1-1.28-1.38-2.91-1.22-4.6-.92-1.02.18-1.97.55-2.95.88-.58.19-1.16.39-1.77.39-.65,0-1.24-.16-1.8-.48-.23-.13-.77.29-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m540.03,631.3c3.23,0,6.45.24,9.65.69.32.04.67-.2.99-.16-3.2-.45-6.42-.68-9.65-.69-.33,0-.66.16-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m553.98,628.17c.96,3.17,1.92,6.35,2.88,9.52.01.05.98-.2.99-.16-.96-3.17-1.92-6.35-2.88-9.52-.01-.05-.98.2-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m572.82,632.37l-2-4.56c-.25-.57-.46-1.45-1.01-1.82s-1.27-.2-1.87-.14c-3.33.3-6.66.6-10,.9-.3.03-.72.14-.95.16,3.25-.29,6.5-.58,9.75-.87.66-.06,1.58-.28,2.15.18.49.39.69,1.22.93,1.77l2,4.56c.04.08.96-.23.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m554.33,625.01c1.53-1.69,3.11-3.44,5.22-4.42.53-.25,1.12-.49,1.69-.55s1.17.03,1.74.16c1.09.24,2.12.68,3.18,1,3.78,1.14,8.13.75,11.73-.82-.29.13-.69.03-.99.16-2.3,1-4.69,1.35-7.18,1.05-1.1-.13-2.17-.4-3.21-.75s-2.09-.77-3.2-.91c-1.54-.19-3.35.17-4.75.82-2.1.98-3.69,2.73-5.22,4.42.17-.19.81.04.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m577.29,613.92c.54,1.01,1.12,2.08,2.11,2.72.9.59,1.78.51,2.79.33s1.85-.82,2.61-1.48c.88-.77,1.63-1.69,2.23-2.69-.03.05-.43.06-.49.08-.07.01-.45.02-.49.08-.5.84-1.1,1.61-1.8,2.3-.66.64-1.43,1.3-2.28,1.66-.03.01-.33.13-.33.12-.01-.06.7-.02.29-.06-.24-.02-.46-.04-.69-.11-.46-.13-.87-.38-1.24-.69-.76-.65-1.24-1.56-1.71-2.42-.05-.1-.94.25-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m583.9,626.76c-1.47-.73-2.4-2.38-2.22-4.02s1.48-3.03,3.07-3.42c-.32.08-.66.08-.99.16-1.59.38-2.88,1.78-3.07,3.42s.76,3.29,2.22,4.02c.09.04.4-.06.49-.08s.4-.13.49-.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m592.43,589.36c-1.43,1.12-2.67,2.75-2.73,4.64-.05,1.65.91,3.35,2.53,3.86.29.09.71-.25.99-.16-1.62-.51-2.57-2.22-2.53-3.86.05-1.89,1.29-3.52,2.73-4.64-.08.07-.39.06-.49.08-.12.02-.39,0-.49.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m588.71,638.56c1.01,3.58,2.39,7.06,4.13,10.35.41.76.83,1.52,1.27,2.26s.92,1.62,1.63,2.19c.53.43,1.17.63,1.84.55.46-.06,1.03-.1,1.45-.33.58-.32.87-.94,1.12-1.52.41-.97.79-1.94,1.19-2.91.78-1.91,1.55-3.82,2.33-5.73.31-.77.66-1.56.73-2.4.08-.94-.27-1.78-.77-2.55-.94-1.46-2.08-3.01-3.48-4.04-.72-.53-1.55-.93-2.43-1.07-.67-.11-1.3,0-1.96.1-1.45.24-2.8,1.12-2.98,2.7,0-.08.98-.06.99-.16.07-.63.34-1.19.78-1.65.2-.21.42-.39.67-.54.1-.06.2-.12.3-.17.07-.03.59-.21.41-.18,1.39-.21,2.94.78,3.9,1.67,1.13,1.04,2.14,2.37,2.94,3.67.36.59.64,1.23.67,1.93.04.83-.26,1.64-.57,2.4-.67,1.68-1.36,3.35-2.04,5.03-.61,1.5-1.13,3.1-1.87,4.55-.15.28-.32.56-.55.78-.11.1-.24.16-.34.26l-.17.08c.32-.03.34-.06.07-.07-.3-.12-.6-.15-.89-.32-.35-.21-.64-.5-.9-.81-.96-1.18-1.67-2.63-2.38-3.96-1.72-3.26-3.08-6.7-4.08-10.25-.01-.04-.98.19-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m559.08,645.82c2.24-3.32,5.93-5.14,9.67-6.27,1.05-.32,2.12-.59,3.19-.85.55-.13,1.1-.27,1.66-.39.05-.01.46-.1.24-.06-.27.05.37-.03.33-.03.27-.02.54-.01.81.02,1.99.24,2.68,2.15,3.19,3.83,1.18,3.89,1.91,8.32.32,12.21-.64,1.56-1.63,2.99-2.91,4.08-.62.54-1.32.99-2.07,1.33-.35.16-.7.29-1.07.39-.1.03-.32.13-.41.1l.25-.04-.37.03c-.28.02-.55.02-.83,0-4.61-.23-7.77-4.08-9.66-7.91-.05-.09-.95.24-.99.16,1.89,3.83,5.05,7.68,9.66,7.91,3.45.17,6.61-1.56,8.46-4.45,2.5-3.92,2.04-8.82.86-13.1-.5-1.81-1.08-4.3-3.25-4.68-1.62-.29-3.53.35-5.09.72-4.84,1.16-10.04,2.8-12.97,7.14.12-.18.86.03.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m532.86,652.74c1.08-.55,2.16-1.1,3.32-1.45.14-.04.29-.08.44-.12.52-.11.54-.12.06-.04.15-.12.8-.05,1.01-.04.54.02,1.09.11,1.6.29,2.01.7,2.96,2.79,4.72,3.86.92.56,1.96.86,2.86,1.45.79.52,1.4,1.24,1.84,2.07.9,1.7,1.12,3.69,1.28,5.58l.24-.04c-2.71.46-5.42.91-8.14,1.37-.02,0-.36.05-.18.04.23-.02.45-.07.68-.11,2.71-.46,5.42-.91,8.14-1.37,0,0,.24-.04.24-.04-.15-1.67-.34-3.39-.99-4.95-.7-1.67-1.88-2.68-3.51-3.42-.87-.4-1.71-.79-2.42-1.44-.67-.61-1.24-1.32-1.9-1.95-1.35-1.27-3.01-1.67-4.82-1.41-1.97.29-3.69.98-5.46,1.89.28-.14.7-.01.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m511.09,653.48c-2.12,2.42-2.07,6.12-.45,8.8,1.77,2.93,5.19,4.48,8.48,4.86,2.72.31,6.36-.03,7.54-2.93.63-1.55.61-3.35.35-4.97-.28-1.71-.92-3.39-1.98-4.77-2.16-2.8-5.74-3.66-9.03-2.57.31-.1.67-.06.99-.16.14-.05.63-.13.7-.2l-.4.06c.18-.02.36-.03.54-.04.37-.01.74,0,1.11.06.75.1,1.47.32,2.15.64,1.34.62,2.46,1.62,3.29,2.84,1.6,2.38,2.29,5.78,1.53,8.57-.4,1.48-1.34,2.65-2.8,3.18-.18.06-.36.1-.53.16-.56.18.7-.02-.35.04-.39.02-.77.02-1.16,0-.77-.04-1.54-.14-2.29-.3-2.82-.6-5.62-2.12-7.14-4.65-1.62-2.68-1.67-6.38.45-8.8-.17.19-.81-.04-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m424.76,651.69c-.78,1.55.04,3.32,1.42,4.21,1.67,1.07,3.72.99,5.62.93,2.15-.07,4.3.03,6.43.38.99.16,1.98.39,2.99.39.57,0,1.15-.11,1.71-.21,1.49-.27,3.21-1.74,2.36-3.4-.05-.1-.94.25-.99.16.39.75.2,1.58-.3,2.24-.27.36-.63.66-1.03.85-.14.07-.94.31-.22.16-.28.06-.6.03-.89.02-1.17-.05-2.33-.35-3.5-.51-1.25-.17-2.51-.26-3.77-.28-2.22-.03-4.62.4-6.73-.53-1.73-.76-3.06-2.71-2.13-4.58-.08.17-.9-.02-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m462.18,660.82c-1.2-1.55-1.18-3.87.05-5.4-.05.06-.42.06-.49.08-.09.01-.43.01-.49.08-1.23,1.53-1.25,3.85-.05,5.4.03.04.44-.07.49-.08s.47-.12.49-.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m465.81,655.15c2.81.78,4.48,3.7,3.73,6.52,0-.03.45-.07.49-.08.05,0,.48-.04.49-.08.76-2.82-.92-5.75-3.73-6.52-.12-.03-.37.05-.49.08s-.37.12-.49.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m478.97,676.47c.64-1.13-.65-1.97-1.62-2.11-1.38-.21-2.83-.19-4.23-.26-1.28-.07-2.65-.29-3.93-.17-.76.07-1.71.16-2.25.73-.75.79-.86,2.12-.83,3.16.03,1.13.3,2.25.79,3.26.97,2.01,2.79,3.47,5,3.87,2.03.37,4.56-.09,6.22-1.33-.23.17-.75-.02-.99.16-.37.28-.76.51-1.18.7-.21.09-.42.18-.64.25-.11.04-.22.07-.33.1l-.32.08.27-.04c-.14.09-.66.04-.85.04-.23,0-.46-.02-.69-.04-.49-.05-.97-.15-1.44-.31-1.81-.59-3.25-1.93-4.07-3.63-.78-1.61-1.1-3.66-.53-5.39.14-.42.35-.84.69-1.14.16-.14.37-.19.52-.31.21-.16-.33-.04-.06,0,.15.02.32,0,.48,0,1.24.05,2.47.14,3.71.21s2.6.02,3.84.26c.93.18,2.02,1.04,1.43,2.08.1-.18.88.03.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m481.99,675.54c-.53,2.17.48,4.39,2.09,5.85,1.74,1.59,4.04,2.39,6.27,3.06,4.98,1.51,10.2,2.26,15.41,2.22,1.02,0,2.07,0,3.08-.16.81-.12,1.69-.21,2.28-.85.64-.69.76-1.72.8-2.62.04-1,0-2.01-.1-3.01-.22-2.02-.94-4.06-2.89-5-.98-.47-2.08-.62-3.15-.7-1.33-.09-2.67-.12-4-.15-6.18-.12-12.36.18-18.53.48-.33.02-.66.14-.99.16,5.27-.26,10.54-.51,15.81-.51,2.46,0,4.97-.04,7.42.24,2.03.23,3.84,1.05,4.71,3.01.77,1.74.82,3.85.73,5.73-.04.87-.18,1.86-.79,2.53-.16.17-.35.32-.55.43-.07.04-.23.07-.28.13l-.1.04c.22,0,.2.01-.05.02-.67.08-1.38.07-2.05.08-4.97.07-9.96-.58-14.75-1.92-2.39-.67-4.92-1.44-6.89-3.02-1.82-1.47-3.05-3.83-2.48-6.19-.03.12-.95.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m419.92,669.33c-3.96-.87-7.92-1.74-11.89-2.61-1.17-.26-2.22-.37-3.39-.07-1.67.43-1.8,2.73-1.79,4.13,0,1.78.28,3.66.8,5.37.46,1.54,1.3,2.88,2.71,3.72,2.37,1.4,5.39,1.39,7.98.7,3.23-.87,5.86-4.23,4.56-7.63-.03-.07-.97.22-.99.16.92,2.42-.23,5.18-2.3,6.6-.58.4-1.21.7-1.88.91-.13.04-.26.08-.38.11l-.37.09c.22-.03.21-.03-.04,0-.47.03-.93.06-1.4.04-1.38-.06-2.81-.38-4.02-1.05-2.65-1.47-3.23-4.57-3.55-7.33-.18-1.49-.27-3.19.29-4.62.25-.64.62-1,1.21-1.31l-.25.04c.13,0,.26,0,.39.02.13.01.26.03.39.05.44.07.87.18,1.3.27,3.88.85,7.76,1.7,11.64,2.55.31.07.69-.23.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m430.72,679.12c-.36-1.78-.73-3.82.05-5.54.39-.88,1.09-1.56,1.94-1.99.99-.5,1.99-.5,3.07-.4,3.78.36,8.73,1.32,10.41,5.23,1.05,2.45-.04,5.03-1.06,7.28.07-.16.91.01.99-.16,1-2.19,2.07-4.71,1.13-7.11-.78-1.99-2.59-3.36-4.53-4.12-1.16-.46-2.37-.73-3.6-.94s-2.49-.43-3.74-.39c-1.66.05-3.63.39-4.83,1.62-1.71,1.76-1.26,4.51-.82,6.68,0,.02.99-.16.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m383.68,676.9c1.96-2.44,3.04-5.6,2.83-8.74-.1-1.47-.66-2.76-2.17-3.2-1.26-.37-2.76-.33-4.07-.22-2.28.18-4.72.73-5.39,3.24-.39,1.47-.37,3.01-.78,4.49s-.94,3.02-.49,4.55c1,3.41,5.37,2.27,7.81,1.49-.31.1-.67.06-.99.16-.72.23-1.47.53-2.23.53-.62,0-1.24-.09-1.81-.33-1.16-.49-1.81-1.54-1.94-2.77-.14-1.38.34-2.7.69-4.02s.37-2.59.63-3.89c.23-1.16.8-2.17,1.85-2.76s1.98-.6,3.26-.58c1.12.02,2.39.02,3.35.67,1.05.71,1.26,2.02,1.29,3.19.08,3.02-.96,5.99-2.85,8.34.15-.19.83.04.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m359.85,679.41c1.17.47,2.93.18,4.01-.46,1.55-.92,2.41-2.76,2.84-4.44.47-1.84.46-3.9-.17-5.7-.27-.78-.82-1.59-1.74-1.59-.46,0-1.04.1-1.47.27.3-.12.68-.04.99-.16l.3-.1-.97.16c1.5-.12,1.98,1.58,2.21,2.75.28,1.43.24,2.91-.09,4.33-.3,1.29-.84,2.59-1.71,3.61-.42.49-.92.92-1.51,1.2-.31.14-.43.21-.63.2-.38-.03-.72-.09-1.07-.23-.27-.11-.72.26-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m359.63,650.98c1.11,3.15,1.66,6.71.49,9.92-.94,2.6-3.01,4.84-5.73,5.55.32-.08.67-.08.99-.16,2.73-.71,4.79-2.95,5.73-5.55,1.17-3.21.62-6.78-.49-9.92-.02-.06-.97.21-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m358.08,644.04c-.43,1.26-.76,2.55-.98,3.86-.16.93-.61,2.47.26,3.18.65.53,1.52.35,2.28.23.91-.15,1.86-.38,2.7-.76,2.26-1.03,2.27-3.96,2.41-6.1.08-1.23.16-2.51,0-3.73-.12-.95-.53-1.91-1.54-2.17-.67-.17-1.54,0-2.2.19-1.17.33-1.86,1.42-2.27,2.49.06-.15.93,0,.99-.16.44-1.16,1.17-2.23,2.44-2.53l-.24.04c-.54.09.16.1.29.13.22.06.43.15.62.28.38.27.62.67.76,1.11.31.96.24,2.06.22,3.04-.02,1.22-.12,2.45-.28,3.66-.13.97-.27,2.03-.84,2.85s-1.53,1.19-2.46,1.46c-.32.09-.67.22-.99.26.78-.09-.04-.08-.22-.12-.38-.09-.74-.29-.96-.62-.52-.83-.1-2.21.05-3.1.22-1.23.54-2.44.94-3.63-.05.14-.94,0-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m344.97,689.59c-.11,1.09.01,2.19.4,3.22.29.75.85,2.1,1.79,2.2.45.05,1.19-.08,1.58-.3.95-.54.86-1.97,1-2.91.36-2.33,1.05-4.62,2.05-6.76.4-.87,1.23-1.99.9-3-.28-.85-1.24-1.04-2.02-.94-.7.09-1.44.19-2.06.54-.92.52-1.55,1.44-1.71,2.48.02-.1.97-.05.99-.16.11-.73.44-1.38.96-1.9.25-.25.52-.46.83-.62.15-.08.31-.15.47-.21.12-.04.11-.04-.05,0,.09,0,.18,0,.27.02.78.09,1.41.6,1.41,1.42s-.53,1.6-.87,2.33c-.79,1.65-1.39,3.39-1.8,5.17-.2.89-.35,1.79-.46,2.7s-.25,1.94-1.28,2.14l.97-.16c-1.03,0-1.6-1.29-1.93-2.1-.42-1.06-.56-2.19-.44-3.32,0,.08-.98.07-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m339.76,697.41c-.59,1.27-1.63,2.24-2.25,3.48-.56,1.11-.79,2.59.16,3.56,1.01,1.03,2.59.96,3.91.87.53-.04,1.06-.12,1.59-.22s1.04-.25,1.39-.69c-.05.06-.42.06-.49.08-.09.01-.43.01-.49.08-.17.2-.36.36-.6.47-.1.05-.21.08-.31.12-.37.09-.27.08.29-.03-.11.01-.22.02-.33.03-.63.05-1.28.08-1.91.02-1.08-.11-2.21-.64-2.58-1.74-.8-2.38,1.73-4.27,2.61-6.19-.02.04-.44.07-.49.08-.06.01-.47.03-.49.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m349.11,698.73c.86,2.94,1.42,6.09,1.34,9.17-.01.57-.08,1.18-.42,1.66-.16.23-.36.32-.57.49-.2.07-.18.08.07.02.25-.03.27-.05.06-.05-.14-.05-.3-.05-.44-.1-1.28-.43-1.74-1.82-2.09-3-.01-.05-.98.2-.99.16.4,1.33,1.03,3.06,2.69,3.11.99.03,2.15-.24,2.51-1.26.24-.68.19-1.44.17-2.15-.03-.93-.1-1.85-.21-2.77-.22-1.84-.6-3.66-1.12-5.44-.01-.05-.98.19-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m350.68,698.75c-.6-.27-1.25-.34-1.9-.25-.71.1-1.44.18-2.08.54-1.13.63-1.77,1.95-1.63,3.23,0-.01.99-.13.99-.16-.12-1.07.31-2.18,1.14-2.88.21-.18.44-.33.69-.44.13-.06.27-.11.4-.16.07-.02.13-.04.2-.06.52-.13-.87.1-.47.07.58-.04,1.15.04,1.68.28.02,0,.14-.02.15-.02.12-.02.23-.04.35-.06s.23-.04.35-.06c.03,0,.12-.04.14-.03h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m347.36,723.33c-.8-1.79-1.27-3.7-1.36-5.66-.07-1.58-.12-4.32,1.91-4.72l-.97.16c1.12-.09,1.8.89,2.16,1.83.45,1.16.57,2.42.48,3.65s-.38,2.42-.79,3.57c-.36,1-.83,2.01-1.81,2.53.28-.15.71-.01.99-.16,1.5-.79,2.01-2.87,2.34-4.39.39-1.79.44-3.73-.27-5.45-.29-.7-.76-1.48-1.54-1.69-.48-.13-1.05.01-1.53.11-1.87.38-1.98,2.57-1.98,4.11,0,2.17.48,4.3,1.37,6.28.04.09.95-.23.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m338.43,719.79c-3.41-.57-5.66-4.09-4.6-7.41.5-1.57,1.69-3.03,3.28-3.59.06-.02.4-.11.14-.06-.23.04.01,0,.06.01.21.02.41.02.62.08.43.11.8.35,1.09.69.53.63.7,1.49.79,2.28.21,1.9.39,4.16-.92,5.73.16-.19.82.04.99-.16,1.28-1.53,1.12-3.71.93-5.57-.09-.87-.24-1.83-.85-2.5s-1.42-.78-2.24-.66c-.73.1-1.46.21-2.12.55-.84.43-1.54,1.11-2.05,1.89-1,1.52-1.29,3.49-.63,5.2.74,1.92,2.5,3.35,4.52,3.69.31.05.68-.21.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m348.69,728.99c-.4,2.67.84,5.46,3.1,6.94.07.05.41-.06.49-.08s.42-.13.49-.08c-2.26-1.48-3.51-4.27-3.1-6.94-.01.09-.97.05-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m363.54,729.92c-.42-1.58-.75-3.71,1.15-4.45.94-.36,2.02-.51,3-.72,1.33-.29,2.67-.58,4.03-.76,1.6-.22,4.07-.73,5.12.92s.07,3.73.19,5.51c0-.02.99-.12.99-.16-.13-1.91,1.18-4.91-.9-6.22-.83-.52-1.87-.47-2.81-.4-1.03.09-2.06.23-3.08.4-2.22.35-4.41.82-6.6,1.33-.94.22-1.81.52-2.21,1.48-.44,1.04-.14,2.18.14,3.22.01.04.98-.18.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m383.39,731.95c-.19-1.08.24-2.2,1.07-2.91.42-.37.93-.62,1.47-.77.37-.08.27-.07-.31.04.11-.01.21-.02.32-.02.15,0,.3,0,.45.01.27.02.53.07.79.13,1.03.27,1.98.93,2.47,1.88s.5,2.23-.11,3.18c.03-.05.43-.06.49-.08.08-.01.45-.02.49-.08,1.16-1.79.11-4.02-1.71-4.84-.85-.38-1.74-.43-2.65-.3-.96.15-1.89.31-2.66.96-.85.71-1.3,1.85-1.11,2.95,0,.01.99-.16.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m400.9,731.35c-.58-.29-1.15-.17-1.75-.07-.64.11-1.19.29-1.68.74-.53.48-.94,1.09-1.31,1.7-.38.64-.71,1.38-.64,2.15.13,1.41,1.45,2.26,2.71,2.55.69.16,1.44.23,2.14.16.57-.06,1.21-.12,1.74-.33,1.64-.64,1.86-2.63,1.96-4.14,0,.07-.98.08-.99.16-.08,1.18-.19,2.46-.97,3.4-.17.2-.36.38-.58.52-.11.07-.23.14-.35.19-.06.03-.12.05-.18.07-.36.15.4,0,.14-.02-.63-.03-1.24-.03-1.86-.17-1.07-.23-2.14-.81-2.6-1.86-.51-1.18.12-2.38.78-3.35.36-.53.75-1.06,1.27-1.43.13-.09.27-.18.41-.25s.68-.19.13-.1-.02.04.14.06c.17.03.33.09.49.17.09.04.4-.06.49-.08s.4-.13.49-.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m415.45,739.7c-1.74-2.06-3.9-4.92-2.34-7.7.31-.56.78-1.02,1.36-1.29.02-.01.42-.2.45-.17,0,0-.32,0-.04.02.09,0,.19,0,.28,0,.38.02.75.09,1.11.2,1.39.41,2.61,1.4,3.39,2.61.67,1.03,1.44,2.99.11,3.84.25-.16.73,0,.99-.16,1.29-.83.6-2.71-.03-3.73-.83-1.34-2.19-2.43-3.74-2.8-.73-.17-1.42-.12-2.16,0-.66.11-1.29.23-1.84.64-1.13.84-1.51,2.37-1.34,3.71.24,1.95,1.6,3.55,2.82,4.99.03.04.44-.07.49-.08s.46-.12.49-.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m429.2,729.68c-.75,1.63-1.57,3.5-.88,5.29s2.47,2.07,4.13,2.13c1.03.04,2.06.05,3.09.08.83.02,1.65.09,2.47-.04.2-.03.43-.04.62-.11.43-.15.43-.63.5-1.01.2-1.09.4-2.19.6-3.28-.02.1-.97.04-.99.16-.15.82-.3,1.64-.45,2.46-.09.5-.06,1.73-.71,1.84l.92-.15c-1.49.09-3.03-.06-4.52-.1-1.16-.03-2.42-.04-3.45-.66-2.47-1.5-1.28-4.75-.34-6.78-.08.16-.91-.01-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m436.33,713.44c.21-1.56.48-3.33,1.61-4.52.65-.69,1.5-1.03,2.44-1.09,1.16-.07,2.32-.09,3.48-.13s2.32-.08,3.47-.12c.82-.03,1.85-.14,2.42.61s.65,1.95.81,2.81c.21,1.12.35,2.24.43,3.37,0-.02.99-.12.99-.16-.07-.98-.19-1.95-.35-2.91s-.29-1.93-.63-2.8c-.31-.78-.95-1.07-1.75-1.1-.96-.04-1.94.05-2.9.09-1.93.07-3.9.01-5.82.25-1.21.15-2.52.33-3.44,1.21-1.23,1.18-1.51,3.03-1.73,4.64.01-.09.97-.06.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m486.56,700.14l-6.47,1.49-3.11.72c-.82.19-1.73.31-2.47.73-1.54.86-1.22,3.03-1.02,4.51.22,1.62.87,3.15,2.56,3.65.93.28,1.93.34,2.89.47,1.1.15,2.21.3,3.31.45,2.15.29,4.29.64,6.45.87,1.14.13,2.39.07,3.5-.28,1.8-.57,1.84-2.42,1.7-4.01-.17-1.9-.43-4.13-2.15-5.29-1.11-.75-2.71-.75-3.89-.15.28-.14.7-.02.99-.16.2-.1.4-.17.62-.23l-.14.03c-.74.13-.23.08-.03.1.16.02.33.05.49.1.33.09.64.24.92.43.62.4,1.08.96,1.4,1.61.68,1.38.83,3.08.88,4.6.05,1.39-.41,2.84-1.92,3.18.39-.09-.15-.02-.21-.02-.12,0-.24,0-.36,0-.35-.01-.71-.05-1.06-.09-.96-.12-1.91-.26-2.87-.39-1.88-.26-3.76-.51-5.65-.77-.89-.12-1.77-.23-2.66-.36-.8-.12-1.62-.26-2.28-.76-1.11-.82-1.42-2.29-1.54-3.58-.13-1.35-.37-3.2.99-4.02.82-.49,1.85-.61,2.76-.82l3.11-.72c2.08-.48,4.15-.95,6.23-1.43-.23.05-.66.08-.95.15h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m498.57,695.46c-.95,3.22-.84,6.99.74,10,.69,1.31,1.73,2.33,3.15,2.79s2.87.38,4.29.21c1.08-.13,2.19-.28,3.26-.53.62-.15,1.27-.37,1.71-.86.49-.56.53-1.31.5-2.02-.09-1.78-.4-3.56-.84-5.28-.39-1.5-.84-3.06-1.73-4.35s-2.14-2.12-3.69-2.09c-1.05.02-2.28.25-3.04,1.01.19-.19.79.03.99-.16.34-.34.77-.63,1.24-.74-.39.09-.04.04.07.05.14,0,.28.02.42.04.28.04.55.12.82.22,1.1.41,1.93,1.31,2.5,2.31.65,1.12,1.03,2.39,1.36,3.63.38,1.43.65,2.9.8,4.37.12,1.17.36,2.64-.72,3.44-.7.52-1.67.66-2.51.77-.69.09-1.38.15-2.08.15-1.18,0-2.39-.2-3.42-.8-1.11-.65-1.87-1.72-2.38-2.88-1.3-2.95-1.38-6.39-.48-9.46-.04.13-.94.02-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m511.04,701.94c2.27.3,4.56.37,6.85.18,1.61-.13,3.31-.32,4.88-.73,1.02-.27,1.17-1.09,1.08-2.03-.08-.85-.25-1.69-.53-2.5-.55-1.58-1.6-2.98-3.24-3.53-2.08-.7-4.37-.11-6.43-.93-.27-.11-.73.26-.99.16,1.55.62,3.22.46,4.85.62s2.97.74,3.91,2.06c.84,1.17,1.24,2.62,1.41,4.03.08.68.19,1.59-.46,2.04-.51.36-1.34.4-1.92.48s-1.12.13-1.69.18c-2.24.18-4.5.11-6.73-.19-.32-.04-.67.2-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m522.79,700.42c5.18,2.84,11.54,6.68,11.24,13.46-.13,2.99-2,5.49-4.84,6.46-.17.06-.35.11-.53.16l-.34.08c.26-.03.31-.04.14-.02-.39,0-.78.06-1.17.05-.75-.03-1.49-.16-2.2-.39-1.39-.45-2.66-1.25-3.72-2.26-2.72-2.62-3.73-6.46-3.54-10.15,0,.06-.98.08-.99.16-.18,3.49.7,7.13,3.14,9.74,2.08,2.23,5,3.41,8.03,2.95,1.29-.2,2.52-.48,3.65-1.16,1.32-.79,2.35-1.99,2.89-3.43,1.11-2.97.17-6.44-1.65-8.93-2.29-3.13-5.8-5.05-9.13-6.88-.23-.13-.76.28-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m540.23,698.29l-3.55,1.21c-1.06.36-2.26.62-3.24,1.18-1.93,1.11-.81,3.61-.1,5.14.37.81.83,1.62,1.5,2.21.8.71,1.78.89,2.83.85,1.22-.04,2.45-.11,3.66-.21.59-.05,1.36-.09,1.9-.36,1.08-.56.43-2.22.24-3.11-.3-1.43-.59-2.87-.89-4.3,0-.02-.99.17-.99.16.23,1.13.47,2.25.7,3.38.11.55.23,1.09.34,1.64.1.46.24.95.2,1.43s-.24.7-.6.96c-.14.1-.46.07.22.03-.17,0-.34.02-.51.03-.31.02-.63.04-.94.06-1.09.06-2.23.23-3.32.1-1.82-.21-2.81-1.77-3.49-3.32-.63-1.43-1.59-3.68.09-4.78.92-.6,2.15-.85,3.18-1.2l3.74-1.28c-.31.11-.68.05-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m537.46,692.78c-5.41.57-10.72-2.71-12.45-7.92-.33-.99-.53-2.35.38-3.11.06-.05.74-.38.74-.38,0,.08-.86.06.06.02.17,0,.35,0,.52-.01.77-.01,1.54,0,2.3.03,2.71.14,5.52.76,7.63,2.57s3.23,5.08,1.71,7.69c.1-.18.88.03.99-.16,1.46-2.5.54-5.62-1.49-7.49-2.24-2.07-5.35-2.69-8.31-2.78-1.23-.04-2.45-.04-3.66.16-1.12.19-2.04.65-2.13,1.91s.54,2.59,1.14,3.67c.67,1.19,1.54,2.26,2.56,3.16,2.48,2.16,5.79,3.14,9.05,2.8.3-.03.72-.13.95-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m543.3,689.9c.95,4.2,2.53,8.2,4.33,12.1.91,1.97,1.88,3.92,2.85,5.86.8,1.6,1.71,3.39,3.72,3.53,1.69.12,3.45-.55,4.94-1.26-.29.14-.7.02-.99.16-.52.25-1.04.49-1.59.69-.32.11-.68.26-1.01.3.41-.05-.39-.04-.46-.05-1.07-.11-1.91-.72-2.52-1.57s-1.08-1.92-1.56-2.89c-.54-1.08-1.07-2.16-1.59-3.24-2.13-4.43-4.05-8.98-5.13-13.78,0-.03-.98.17-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m562.05,701.47c-3.58-1.54-6.71-4.03-9.02-7.17-.57-.78-1.09-1.59-1.55-2.43s-.77-1.8-1.36-2.54c-.48-.6-1.09-.83-1.84-.72-.6.08-1.27.11-1.62.67.11-.18.87.03.99-.16s.25-.26.41-.41l.13-.06c-.45.05-.52.08-.24.09.35.14.64.19.93.47.63.59.92,1.47,1.3,2.22.75,1.49,1.69,2.89,2.76,4.16,2.2,2.61,4.99,4.68,8.12,6.03.26.11.73-.27.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m509.1,719.74c.89-1.26,1.82-2.64,2.04-4.2.11-.79-.02-1.52-.29-2.26s-.57-1.45-.3-2.2c.48-1.37,2.09-1.66,3.33-1.86,1.17-.19,2.42-.41,3.18-1.42-.14.19-.84-.04-.99.16-.82,1.09-2.17,1.23-3.42,1.44-1.19.2-2.64.57-3.1,1.84-.26.71.02,1.43.27,2.1.28.78.45,1.53.33,2.36-.22,1.56-1.16,2.94-2.04,4.2.13-.19.85.03.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m512.11,737.61c-2.33,3.45-1.95,8.22.42,11.55s6.73,5.35,10.92,5.62c1.07.07,2.17.07,3.22.32,1.14.27,1.84.89,2.67,1.66.64.59,1.23.77,2.06.63.78-.12,1.51-.26,1.47-1.25-.02-.61-.19-1.23-.3-1.83-.12-.68-.24-1.36-.37-2.05-.24-1.32-.48-2.65-.73-3.97-.47-2.48-.89-5-1.57-7.43-.34-1.21-.8-2.4-1.59-3.39s-1.79-1.66-2.91-2.13c-2.59-1.09-5.47-.89-8.21-.69-.33.02-.66.14-.99.16,4.3-.31,9.7-.38,11.93,4.11,1,2.01,1.32,4.36,1.76,6.54.5,2.48.96,4.96,1.41,7.45.12.64.23,1.28.35,1.93.1.56.31,1.2.23,1.77-.03.21-.11.4-.26.55-.06.06-.19.11-.23.17-.11.17.41.1.25-.04-.1-.08-.4-.08-.54-.14-.26-.12-.49-.3-.7-.49-.79-.7-1.42-1.35-2.47-1.65s-2.07-.32-3.1-.37c-4.03-.22-8.07-1.74-10.72-4.88-2.85-3.38-3.54-8.57-1.01-12.32-.12.18-.86-.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m531.71,741.2l.72,3.99c.21,1.17.31,2.46.74,3.58.74,1.95,2.98,2,4.74,1.73,1.92-.29,3.79-.84,5.06-2.42,1.16-1.43,1.8-4.13-.3-5.06-1.06-.47-2.33-.4-3.46-.45-1.5-.07-2.99-.13-4.49-.2-.33-.01-.66.17-.99.16,1.17.05,2.34.1,3.5.16,1.07.05,2.16.04,3.23.18.81.11,1.67.37,2.17,1.06.55.76.52,1.8.25,2.66-.58,1.86-2.24,3.15-4.06,3.69-.18.05-.36.09-.53.14-.12.03-.39.03.02,0-.28.02-.56.05-.85.05-.43,0-.88-.04-1.3-.15-.9-.23-1.61-.77-1.97-1.64-.45-1.1-.54-2.39-.75-3.54l-.74-4.1s-.99.16-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m543.87,740.5c.79,2.42,2.28,4.57,4.3,6.12,1.05.81,2.32,1.6,3.68,1.63.79.02,1.75-.14,2.47-.46,1.13-.49,1.97-1.46,2.71-2.42.38-.5.81-1.01,1.06-1.6.31-.72.16-1.34-.21-2-.77-1.37-1.6-2.71-2.42-4.05-.7-1.13-1.49-2.27-2.69-2.92-1.77-.95-5.66-.4-5.24,2.34,0,0,.99-.15.99-.16-.16-1.03.46-1.98,1.38-2.41.11-.05.22-.09.33-.13-.15.06-.4,0-.04.05.26.04.51.06.77.13.54.15,1.05.43,1.49.77.94.73,1.58,1.75,2.19,2.75.68,1.12,1.35,2.25,2.02,3.38.3.5.65,1.02.64,1.62s-.37,1.18-.73,1.68c-.61.85-1.27,1.71-2.07,2.38-.38.31-.79.58-1.24.77-.11.05-.23.07-.34.12-.25.11.34.05.03-.02s-.64-.04-.95-.12c-1.13-.28-2.16-.96-3.06-1.69-1.9-1.54-3.31-3.62-4.07-5.94-.02-.06-.97.2-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m536.53,757.08c.1-.07.8-.33.82-.4-.02.05-.88.04-.11.02.15,0,.3,0,.45.02.56.06,1.11.24,1.61.51,1.05.57,1.85,1.51,2.16,2.66.8,2.93-1.07,5.8-3.3,7.51-1.24.95-2.63,1.67-4.02,2.36-1.6.79-3.2,1.57-4.8,2.35-1.4.68-2.86,1.64-4.45,1.08-1.33-.47-2.25-1.68-2.78-2.94-.36-.85-.58-1.76-.78-2.66s-.43-1.83-.5-2.77c-.12-1.64.29-3.4,1.72-4.38-.25.17-.73-.01-.99.16-1.43.97-1.85,2.74-1.72,4.38.07.97.3,1.93.52,2.88.21.9.43,1.8.8,2.65.64,1.47,1.85,2.83,3.52,3.02,1.21.13,2.65-.24,3.75-.69,1.86-.76,3.65-1.74,5.45-2.63,1.65-.81,3.33-1.59,4.84-2.65,2.54-1.8,4.93-5.18,3.52-8.41-.58-1.33-1.91-2.33-3.34-2.55-1.06-.17-2.47.07-3.36.65.25-.16.73,0,.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m541.56,769.33c.44,1.71,1.22,3.29,2.12,4.8s1.78,3.12,2.99,4.37c1.04,1.08,2.42,1.79,3.94,1.57,1.26-.18,2.5-.49,3.06-1.77-.07.16-.91-.01-.99.16-.25.58-.69,1.03-1.25,1.32-.14.07-.29.12-.43.18-.3.14.14.05-.02,0-.3-.08-.65-.05-.96-.14-1.43-.4-2.49-1.5-3.33-2.67s-1.64-2.5-2.37-3.79-1.4-2.72-1.78-4.21c0-.04-.98.18-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m545.14,766.24c.44-.93.93-1.95,1.81-2.53.11-.07.22-.14.34-.19.06-.03.54-.21.21-.11-.3.09.08.02.17.02.15,0,.3.02.45.05.57.09,1.13.3,1.64.57,1.83.96,3.2,2.78,3.38,4.86,0-.02.99-.13.99-.16-.22-2.45-2.1-4.59-4.43-5.29-1-.3-2.22-.22-3.21.14-1.22.45-1.84,1.71-2.36,2.81.02-.04.44-.07.49-.08.06-.01.46-.03.49-.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m534.43,771.18c-.31,1.32-.63,2.7-.43,4.07s1.01,2.35,1.94,3.33c1.02,1.08,2.02,2.19,3.07,3.24.8.8,1.81,1.62,3.01,1.47.84-.1,1.68-.22,2.23-.93.77-.99.64-2.3.3-3.44-.42-1.4-1.03-2.76-1.65-4.07s-1.18-2.57-1.9-3.76c-.58-.96-1.37-1.64-2.54-1.47-.81.12-1.83.2-2.04,1.17.02-.11.96-.03.99-.16.06-.27.18-.5.4-.68.11-.09.24-.14.35-.21.33-.22-.44-.05-.22,0,.26.07.51.05.77.18.34.17.62.43.86.71.47.56.78,1.24,1.11,1.89.4.79.8,1.59,1.18,2.39.73,1.54,1.53,3.17,1.87,4.84.28,1.38-.09,3.24-1.72,3.54l.92-.15c-1.25.1-2.27-.79-3.09-1.63-1.04-1.07-2.06-2.17-3.08-3.26-.9-.95-1.63-1.93-1.79-3.27s.14-2.67.44-3.96c-.03.12-.96.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m541.85,785.08c-.52,1.19-.82,2.58-.2,3.79.55,1.07,1.57,1.41,2.7,1.51.94.08,2.08.04,2.8.77.59.6.7,1.86,1.59,2.13.35.11.75-.01,1.1-.07.5-.08.89-.24,1.33-.49,1.09-.62,2.22-1.39,2.35-2.76.12-1.25-.46-2.6-1.03-3.69s-1.31-2.03-2.31-2.69-2.2-.91-3.39-.75c-1.04.14-2.03.33-2.84,1.05.21-.18.77.03.99-.16.35-.31.74-.55,1.18-.71-.01,0,.29-.08.29-.09l-.36.04.29-.02c.29-.01.58,0,.86.05.97.14,1.86.58,2.6,1.22.8.7,1.4,1.59,1.86,2.54s.92,2.09.89,3.19-.73,1.91-1.63,2.51c-.21.14-.43.27-.66.4-.05.03-.7.33-.71.37.04-.46.47.1.18-.09-.06-.04-.15-.06-.22-.1-.19-.12-.33-.29-.45-.48-.45-.69-.62-1.5-1.41-1.91s-1.75-.34-2.61-.44c-1.06-.13-1.96-.55-2.44-1.55-.58-1.2-.28-2.55.23-3.72-.07.16-.91-.01-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m524.26,776.29c1.54,4.58,4.54,8.62,8.49,11.41.3.21.72.62,1.08.64.27.01.62-.08.88-.15,1.16-.29,2.49-.9,3.12-1.96.75-1.25.04-2.42-.59-3.53-.74-1.29-1.47-2.58-2.21-3.87-.68-1.2-1.38-2.72-2.79-3.19-.77-.26-1.89-.1-2.62.24.29-.14.7-.02.99-.16.21-.08.23-.1.04-.07-.16.01-.18.02-.04.02.15.04.31.03.46.07.31.08.6.22.87.39.59.4,1.01.99,1.38,1.59.86,1.4,1.64,2.85,2.45,4.27.65,1.14,1.82,2.57,1.3,3.96s-2.03,1.95-3.33,2.39l.99-.16c-4.42-2.81-7.79-7.09-9.47-12.06-.02-.06-.97.2-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m494.99,780.84c-2.16,1.1-4.3,2.24-6.42,3.42-1.77.99-3.84,1.96-4.56,4.02-.34.98-.38,2.03-.36,3.05.02.84-.05,1.91.45,2.63.84,1.22,3.02.42,4.09-.04,2.55-1.11,5.24-2.02,8-2.44.23-.04.47-.07.7-.11.14-.03.19-.04,0,0-1.88.29-3.75.65-5.57,1.19-1.11.33-2.2.72-3.26,1.16-.84.35-1.71,1.03-2.54.5-.76-.48-.81-1.51-.84-2.32-.04-1.03-.07-2.08.15-3.1.45-2.13,2.12-3.22,3.9-4.23,2.39-1.35,4.82-2.65,7.27-3.9-.28.14-.7.01-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m499.39,794.57c-2.09.21-4.18.63-6.14,1.43-1.16.47-2.28,1.07-3.32,1.77-.86.58-2.3,1.38-1.65,2.6.57,1.08,1.96,1.4,2.11,2.74.13,1.19-.56,2.28-.65,3.44s.45,2.06,1.62,2.19c.74.09,1.55-.1,2.27-.24,2.71-.55,5.24-1.88,7.29-3.71-.21.18-.77-.03-.99.16-1.53,1.35-3.29,2.42-5.21,3.11-.96.35-1.86.73-2.82.43-.86-.27-1.22-1.07-1.2-1.93.03-.97.49-1.85.63-2.81.17-1.12-.27-1.83-1.11-2.51-.64-.52-1.54-1.35-.99-2.25.48-.79,1.6-1.37,2.37-1.84,2.04-1.25,4.42-2.2,6.81-2.43.3-.03.72-.13.95-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m493.59,817.97c1.74.05,3.38-.57,5.1-.7,1.15-.09,2.21.12,3.2.72.92.55,1.75,1.26,2.58,1.93,1.71,1.39,3.21,2.95,4.17,4.96.44.92.77,1.9.97,2.9.18.89.37,1.94.02,2.81-.46,1.14-1.86,1.33-2.91,1.6-1.72.44-3.47.81-5.24,1.05-.3.04-.72.13-.95.16,2.18-.29,4.35-.67,6.48-1.19,1.04-.25,2.64-.42,3.34-1.32.66-.84.46-2.1.28-3.07-.43-2.33-1.54-4.51-3.17-6.24-.87-.92-1.87-1.72-2.87-2.5-.94-.73-1.9-1.54-3.08-1.85-2.31-.61-4.64.64-6.95.57-.33,0-.66.17-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m503.02,825.75c-2.42.69-4.86,1.28-7.32,1.77-2.15.43-4.37.68-6.09-.94-1.25-1.17-1.88-2.94-1.46-4.61.44-1.77,1.89-3.2,3.66-3.62-.23.05-.66.08-.95.15-3.21.75-4.86,4.51-3.02,7.3.97,1.48,2.56,2.25,4.31,2.23,1.58-.01,3.21-.41,4.75-.72,2.39-.48,4.77-1.07,7.12-1.74-.32.09-.67.07-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m474.57,835.82c-.29-1.37-.43-2.77-.39-4.17.04-1.22.19-2.83,1.63-3.16l-.97.16c.81-.06,1.41.57,2.24.45.69-.1,1.16-.21,1.72-.66.33-.27.68-.58,1.11-.67.26-.05.81.03,1.17.09.95.15,1.9.43,2.78.81,1.61.69,3.13,1.85,3.71,3.55.53,1.55.28,3.39-1.01,4.49.22-.18.77.03.99-.16,1.21-1.02,1.51-2.69,1.11-4.17-.43-1.61-1.68-2.79-3.12-3.55s-3.26-1.35-4.88-1.21c-.51.04-1.05.12-1.55.25-.85.22-1.35,1-2.17,1.24l.97-.16c-.59.08-.99-.3-1.52-.44-.27-.07-.52-.04-.8,0-.72.11-1.37.2-1.83.83-.63.86-.58,2.14-.57,3.15.01,1.17.15,2.33.4,3.47,0,.02.99-.17.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m460.74,807.05c2.46.15,5.06.33,7.21,1.67s2.94,3.39,3.14,5.75c.23,2.63.11,5.31.13,7.95.02,1.95.53,4.9-1.4,6.14-1.51.98-3.9.72-4.9,2.43.1-.18.88.03.99-.16.88-1.51,2.88-1.49,4.32-2.13,2.23-.98,2-3.41,1.98-5.47-.02-2.7.03-5.41-.08-8.1-.1-2.29-.61-4.57-2.48-6.08-2.23-1.79-5.19-2.01-7.92-2.18-.33-.02-.66.18-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m463.83,824.81c-.79-1.33-.2-2.89-.5-4.32-.37-1.79-2.39-1.68-3.54-2.74-2.97-2.75-1.29-7.1.09-10.17-.07.16-.91-.01-.99.16-1.38,3.07-3.06,7.42-.09,10.17,1.13,1.05,3.12.95,3.52,2.67.35,1.45-.29,3.04.52,4.4.07.11.93-.26.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m469.09,784.52c-3.08.75-5.23,3.32-6.47,6.11-.73,1.65-1.2,3.4-1.64,5.15-.4,1.6-.98,3.45-.19,5.03,1.17,2.34,4.13,2.44,6.37,2.06,2.77-.47,5.27-2.06,6.8-4.43,1.98-3.06,2.01-7.08.23-10.23-.06-.11-.93.25-.99.16,1.49,2.65,1.73,5.93.55,8.74-1.07,2.55-3.27,4.58-5.87,5.49-.24.08-.91.4-1.14.32l.31-.05c-.13.02-.26.03-.4.03-.14,0-.28.01-.42.01-.66,0-1.33-.09-1.96-.29-1.1-.34-2.11-1.05-2.58-2.12-.66-1.49-.16-3.17.21-4.67.42-1.68.85-3.36,1.52-4.96,1.22-2.96,3.4-5.74,6.65-6.53-.32.08-.66.08-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m479.7,785.6c-.85,2.65-1.7,5.33-2.11,8.09s-.08,5.49.08,8.29c.08,1.38.06,2.78-.27,4.13-.3,1.22-.86,2.37-1.12,3.61-.23,1.13-.12,2.3.68,3.2.72.81,1.76,1.26,2.74,1.65.67.27,1.36.56,2.09.6.43.03.86-.05,1.28-.12.98-.16,2.04-.73,1.89-1.89,0,0-.99.14-.99.16.06.48-.1.9-.43,1.25-.18.18-.38.28-.59.41-.16.1-.29,0,.04.04-.18-.02-.36-.02-.55-.05-1.03-.17-2.09-.64-3.01-1.12-.87-.45-1.69-1.07-2.07-2.01-.42-1.04-.18-2.18.13-3.2.36-1.16.84-2.27,1.03-3.48s.17-2.46.09-3.69c-.17-2.55-.46-5.04-.11-7.59.39-2.89,1.28-5.67,2.16-8.44-.04.14-.94,0-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m451.13,784.59c-2.11.22-3.93.93-5.38,2.53-1.91,2.1-2.63,4.92-3.15,7.64-.2,1.03-.8,2.75.47,3.32.55.25,1.23.26,1.82.34.79.1,1.58.2,2.37.3,1.66.21,5.23,1.15,5.94-1.13.23-.73.24-1.55.28-2.3.05-.85.07-1.71.06-2.56-.03-1.83-.2-3.66-.5-5.47,0,0-.99.15-.99.16.45,2.69.6,5.43.44,8.15-.06.97,0,2.37-.8,3.08-.1.08-.2.13-.3.21-.39.33.11.02.08.05-.07.09-.67-.03-.78-.04-.7-.08-1.39-.18-2.09-.27-1.27-.16-2.55-.29-3.81-.49-1.11-.18-1.63-.81-1.49-1.95.29-2.35.85-4.88,1.92-7,.54-1.07,1.26-2.06,2.16-2.86.41-.37.86-.69,1.34-.96.26-.15.54-.28.82-.39.11-.04.23-.08.34-.12.17-.05.86-.16.28-.1.3-.03.72-.13.95-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m443.24,806.5c-.53,2.49-.95,5.02-1.22,7.55-.09.88-.17,1.98.54,2.64.81.75,2.19.49,3.19.46,1.23-.04,2.47-.03,3.7-.12.6-.04,1.42-.09,1.95-.41.9-.54.72-2.06.77-2.95.07-1.18.19-2.37.17-3.55s-.36-2.35-1.48-2.86c-.65-.29-1.56-.12-2.22.1.31-.11.67-.05.99-.16-.71.24-.51,0-.3.07.09.03.19.03.27.05.16.04.31.11.46.2.34.2.63.48.83.82.48.79.48,1.78.44,2.67-.04,1.04-.11,2.09-.17,3.13-.04.78.07,1.93-.57,2.51-.13.12-.43.17-.53.28.02-.03.55-.04.29-.06-.11,0-.23,0-.34.01-.56.02-1.13.03-1.69.05-1.05.03-2.11.11-3.16.09-.84-.02-1.68-.26-2.03-1.1-.32-.77-.18-1.65-.09-2.45.28-2.4.68-4.78,1.18-7.14-.02.11-.96.04-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m452.24,818.45c.31-.08-.29-.04-.41-.05-.22-.01-.44-.04-.66-.07-.46-.05-.93-.12-1.39-.12-.52,0-1.04.1-1.55.2-.71.13-1.35.42-1.7,1.1-.41.81-.3,1.79-.26,2.66.05,1.09.09,2.19.15,3.28.04.75.11,1.62.68,2.18,1.09,1.08,3.45-.07,4.2,1.5.47.99-.72,1.66-1.26,2.27-.28.31-.49.71-.36,1.14.11.37.41.6.79.56.3-.03.72-.13.95-.16-.97.11-.86-1.13-.45-1.63.49-.6,1.5-1.08,1.41-1.99-.16-1.71-2.83-1.04-3.86-1.54-.73-.35-.98-1.12-1.07-1.87-.12-1-.11-2.03-.16-3.04-.06-1.43-.51-4.15,1.5-4.52-.25.05.27.03.35.04.27.01.54.05.81.08.51.06,1.02.13,1.53.1.58-.03,1.17-.14,1.73-.28-.32.08-.66.08-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m424.99,808.53c.99.02,2.01-.17,2.97-.4.3-.07.6-.15.9-.23.25-.06.52-.09.76-.18l.11-.02c-.31.04-.26.05.15,0,.59-.03,1.2.03,1.76.24,1.4.54,1.69,1.92,2.03,3.23.41,1.61.82,3.22,1.23,4.83.39,1.52.94,3.09,1.15,4.64.08.58,0,1.18-.51,1.54-.12.08-.25.13-.38.18-.27.09.47,0-.22.02-.45.02-.91.07-1.37.1-1.65.13-3.31.25-4.96.38-.79.06-1.57.12-2.36.18-.6.05-1.6.3-1.95-.36-.16-.3-.15-.7-.19-1.03-.06-.49-.12-.98-.18-1.46-.11-.89-.22-1.79-.32-2.68-.23-1.87-.45-3.74-.68-5.61,0,0-.99.14-.99.16.22,1.79.43,3.58.65,5.37.1.85.21,1.71.31,2.56.05.45.11.89.16,1.34s.05.93.18,1.34c.23.69.93.62,1.52.57.95-.07,1.9-.15,2.85-.22,1.78-.14,3.56-.27,5.34-.41,1.01-.08,2.12-.09,3.1-.39,1.67-.51.8-2.56.51-3.73-.44-1.73-.88-3.46-1.32-5.19-.22-.89-.45-1.77-.67-2.66-.18-.72-.34-1.49-.82-2.09-.87-1.08-2.39-1.13-3.65-.95-.51.07-1.02.16-1.52.27-.38.08-.75.19-1.12.28-.28.07-.58.19-.87.2.81-.03-.44,0-.59-.01-.33,0-.66.17-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m421.09,794.83c2.16-.22,4.15-.93,6.24-1.48.21-.06.43-.11.64-.16-.01,0,.71-.13.33-.08-.34.05.18-.01.3-.02s.23-.01.35-.02c.55-.01,1.11.03,1.65.14.92.18,1.83.57,2.5,1.24,1.72,1.7.94,4.32,1.96,6.33.52,1.02,1.35,1.96.52,3.08-.31.42-.72.62-1.17.85-.25.13-.2.11-.03.07-.21.05-.49.04-.71.05-.34.01-.67.02-1.01.02-3.27.05-6.54.11-9.81.16-.33,0-.66.15-.99.16,3.19-.05,6.38-.11,9.57-.16,1.65-.03,4.88.12,5.46-1.93.17-.61-.07-1.17-.38-1.69-.34-.58-.66-1.12-.82-1.78-.32-1.25-.24-2.58-.66-3.81-.77-2.23-3.17-2.98-5.33-2.87-.97.05-1.96.24-2.9.47-.81.2-1.6.46-2.4.7s-1.75.52-2.34.58c-.3.03-.72.13-.95.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m417.9,776.29c-1.88,1.6-2.16,4.24-2.37,6.55-.12,1.3-.19,2.73.48,3.91.57,1,1.58,1.61,2.64,1.97,1.27.44,2.64.6,3.97.73,1.56.15,3.13.19,4.69.1.8-.05,1.65-.15,2.43-.35,1.41-.38,1.87-1.49,2.28-2.77,1.1-3.42,2.19-6.85,3.29-10.27-.04.14-.94,0-.99.16-.9,2.8-1.79,5.6-2.69,8.4-.37,1.17-.62,2.59-1.33,3.61-.8,1.15-2.07,1.08-3.33,1.1-1.4.02-2.81-.06-4.2-.24-1.17-.15-2.37-.32-3.47-.76-1-.4-1.9-1.04-2.39-2.02-.53-1.05-.51-2.29-.41-3.43.19-2.38.45-5.2,2.4-6.85-.21.18-.77-.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m401.76,774.37c-.56,1.51.08,3.14,1.2,4.22,1.24,1.2,3.01,1.67,4.65,2.02.95.2,1.91.38,2.87.57.9.18,1.81.42,2.72.51s2.41,0,2.98-.81c-.13.19-.85-.04-.99.16-.18.25-.46.32-.63.52-.13.05-.09.05.13,0h-.17c-.17-.02-.34-.02-.5-.04-.77-.1-1.54-.29-2.31-.44-1.57-.31-3.17-.55-4.71-1.01-1.32-.39-2.62-1.01-3.49-2.11s-1.24-2.44-.76-3.73c-.06.15-.93,0-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m388.15,768.42c1.55.56,3.12,1.08,4.71,1.54.78.23,1.56.44,2.34.64.71.18,1.48.45,2.22.4.93-.06,1.94-.21,2.26-1.21.23-.72.23-1.54.3-2.29.09-.88.17-1.76.26-2.65,0,.08-.98.07-.99.16-.14,1.43-.23,2.87-.43,4.29-.07.49-.19,1.02-.58,1.36-.02.02-.34.23-.35.22,0-.03.65,0,.08-.06-.3-.03-.59-.1-.87-.16-2.7-.65-5.36-1.47-7.96-2.41-.28-.1-.72.26-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m362.83,781.77c.97-1.71,2.39-3.13,4.13-4.05s3.66-1.23,4.71-3.06c.49-.86.86-1.74,1.64-2.4.7-.59,1.55-.96,2.41-1.26,1.83-.65,3.63-1.16,5.28-2.22,1.87-1.19,3.45-2.78,4.65-4.65-.12.18-.86-.03-.99.16-1.06,1.64-2.41,3.08-4,4.21s-3.36,1.81-5.22,2.41c-.88.28-1.76.59-2.54,1.08-.83.53-1.37,1.24-1.83,2.11s-.93,1.7-1.8,2.26c-.8.52-1.72.8-2.59,1.18-2.04.9-3.72,2.45-4.82,4.39.1-.18.88.03.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m385.74,767.57c-.92,1.55-1.85,3.12-2.36,4.86-.41,1.41-.66,3.18.18,4.49.95,1.48,2.9,1.21,4.41,1.15,1.63-.07,3.3-.03,4.91-.3.28-.05.6-.07.81-.28.3-.3.23-.79.23-1.17-.02-1.13-.03-2.27-.05-3.4,0,.04-.99.1-.99.16.01.96.03,1.92.04,2.88.01.71.22,1.67-.66,1.9l.49-.08c.11-.06.06-.06-.16-.02-.14,0-.28.01-.42.02-.21,0-.42.02-.62.03-.45.02-.9.04-1.35.05-.89.04-1.78.09-2.68.1-1.41.02-2.74-.35-3.27-1.78-.5-1.36-.22-2.93.22-4.26.53-1.6,1.39-3.06,2.25-4.51-.11.18-.87-.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m384.19,778.47c-1.43,1.46-1.85,3.71-1.02,5.58.2.46.52.97.32,1.49-.17.43-.58.57-.98.71-.53.18,1.02-.1.17-.03-.14.01-.28.02-.42.03-.32.02-.64.03-.96.03-.63,0-1.27-.04-1.88-.21-1.05-.29-1.92-.94-2.36-1.95-.41-.93-.47-1.98-.7-2.96-.25-1.08-.8-1.82-1.42-2.71-.51-.75-.83-1.72-.04-2.41.18-.16.4-.28.63-.37.09-.03.18-.07.26-.09.09-.03.18-.05.27-.07.26-.06.17-.05-.25.03.34.03.72-.07,1.07-.09.37-.02.74-.04,1.11-.03,1.26.04,2.52.41,3.31,1.44.1.13.89-.28.99-.16-1.52-1.97-4.49-1.51-6.64-1.15-.84.14-1.93.39-2.19,1.34-.33,1.18.93,2.14,1.43,3.07.57,1.05.6,2.28.88,3.42.3,1.23.97,2.19,2.17,2.68s2.72.44,4.03.29c.7-.08,1.79-.12,2.31-.67.56-.59.07-1.33-.19-1.94s-.36-1.22-.37-1.85c0-1.34.51-2.63,1.45-3.58-.18.19-.8-.04-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m394.44,777.26s.39-.21.42-.17c.04.04-.56-.02-.1.04.25.03.49.04.74.11.49.13.95.37,1.35.69.82.67,1.21,1.6,1.13,2.65-.06.69-.25,1.39-.04,2.08.18.59.63,1.03,1.13,1.37,1.01.67,2.27.83,3.29,1.48,1.12.71,1.44,1.93,1.94,3.08s1.4,2,2.5,2.66c1.17.7,2.43,1.24,3.61,1.93,1.29.75,2.52,1.59,3.7,2.51,1.11.87,2.41,1.91,2.67,3.39.2,1.12-.38,2.43-1.55,2.72l.92-.15c-1.09.16-1.87-.7-2.51-1.45-.72-.83-1.4-1.65-2.49-1.99-1.15-.36-2.4-.21-3.57-.06-1.64.22-3.29.42-4.94.54-2.93.22-6.07.51-8.96-.15-2.37-.54-4.69-1.99-5.47-4.41s-.14-6.39-3.22-7.22c-.3-.08-.7.24-.99.16,2.92.78,2.54,4.53,3.11,6.79.7,2.77,3.26,4.38,5.92,4.9,1.57.31,3.18.31,4.77.28s3.15-.13,4.72-.26,3.14-.38,4.72-.55c1.4-.15,2.92-.17,4.09.74.88.68,1.43,1.7,2.31,2.38.77.59,1.55.49,2.44.32,1.02-.2,1.64-1.09,1.72-2.11.1-1.37-.91-2.51-1.88-3.35-2.31-2-4.95-3.38-7.62-4.83-1.19-.64-2.29-1.43-2.89-2.68s-.85-2.57-2.06-3.34-2.85-.88-3.9-1.98c-1.19-1.25-.09-2.84-.63-4.28-.8-2.12-3.49-2.56-5.36-1.65.29-.14.7-.02.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m379.19,786.39c-.75,1.12-1.25,2.38-1.45,3.71-.19,1.25-.19,2.7.28,3.89.24.6.66,1,1.31,1.1.73.11,1.51.04,2.24.05,1.6.01,3.2.02,4.8.03.33,0,.66-.16.99-.16-1.6-.01-3.2-.02-4.8-.03-.74,0-1.51.06-2.24-.05-.65-.1-1.07-.5-1.31-1.1-.47-1.19-.47-2.65-.28-3.89.2-1.33.7-2.59,1.45-3.71-.03.05-.43.06-.49.08-.08.01-.45.01-.49.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m366.92,790.39c.96,6.85,4.74,13.21,10.31,17.31,1.37,1.01,2.95,2.11,4.75,1.89,1.31-.16,2.68-.47,3.14-1.89.53-1.65-.25-3.63-.94-5.12-.76-1.66-1.82-3.18-3.12-4.47-2.91-2.89-7.41-4.88-8.26-9.3,0-.02-.99.16-.99.16.69,3.57,3.89,5.66,6.53,7.79s5,5.15,5.78,8.67c.33,1.49.19,3.29-1.41,3.96-.08.03-.17.07-.25.09.1-.02.22.01-.08-.02-.18-.02-.36-.02-.54-.05-.46-.08-.91-.24-1.34-.44-.78-.37-1.51-.87-2.2-1.38-2.62-1.91-4.85-4.33-6.59-7.07-1.98-3.12-3.29-6.64-3.8-10.3,0,0-.99.15-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m387.34,804.07c.37,1.31.73,2.62,1.1,3.93.32,1.14.68,2.29,1.25,3.34.96,1.77,2.75,3.29,4.88,3,.66-.09,1.4-.17,1.97-.54,1.02-.67.89-1.93.89-3,0-1.35,0-2.7-.02-4.05-.01-1.17.06-2.41-.28-3.54-.31-1.06-1.12-2.03-2.29-2.14-.63-.06-1.51.09-2.05.4.27-.15.71,0,.99-.16.06-.04.16-.07.22-.11.21-.17-.46-.08-.22.03.13.06.33.04.48.08.4.11.77.34,1.07.62.64.6.9,1.49,1,2.34.12.99.09,2.01.1,3.01.01,1.15.02,2.29.01,3.44,0,.89.15,2.02-.4,2.79-.22.3-.53.52-.87.66.01,0-.31.13-.33.11-.05-.04.51,0,.21-.05-.24-.05-.5-.02-.74-.07-1.81-.36-3.12-1.86-3.88-3.45-1.01-2.12-1.48-4.54-2.11-6.79-.01-.04-.98.19-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m399.44,801.3c-1.65,6.16,4.85,10.5,9.7,12.46,2.17.88,4.7,1.69,7.05,1.13,1.55-.37,1.36-2.49,1.51-3.74.09-.75.18-1.51.27-2.26s.21-1.52.09-2.27c-.52-3.28-5.03-2.92-6.97-4.85-.14-.14-.85.29-.99.16,1.88,1.86,5.76,1.49,6.82,4.24.28.74.22,1.54.13,2.31-.09.87-.2,1.73-.3,2.6-.09.81-.17,1.63-.29,2.44s-.44,1.35-1.25,1.54l.84-.14-.18.03.15-.02c-1.94.16-4.11-.6-5.88-1.32s-3.59-1.68-5.19-2.83c-3.03-2.17-5.56-5.74-4.52-9.63-.03.13-.95.02-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m402.61,821.57c.33-1.74-.42-3.57.23-5.27.3-.78.83-1.21,1.58-1.51-.32.13-.17.02,0,.03.1,0,.2,0,.31,0,.2,0,.41.02.61.04.51.05,1.01.11,1.52.16,1.01.11,2.02.23,3.03.36.85.11,1.9.1,2.67.52.72.4.85,1.18.93,1.93.09.87.26,1.62.69,2.39.84,1.51,2.4,3.33,1.32,5.12.11-.18.87.03.99-.16,1.06-1.75-.43-3.55-1.27-5.03-.47-.83-.65-1.63-.75-2.57s-.38-1.71-1.34-2.01-2.09-.33-3.1-.45c-1.13-.14-2.26-.27-3.4-.38-1.23-.13-3.12-.17-4.11.72-1.72,1.56-.53,4.32-.9,6.28.02-.1.96-.04.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m569.19,818.22c2.26,1.29,4.73,1.56,7.26,1.14.45-.07.89-.15,1.33-.27.17-.05.35-.09.51-.15l.22-.07c-.14,0-.13.01.01.02.3.03.58.13.83.3,1.09.74,1.33,2.18,2.29,3.04.16.14.83-.3.99-.16-.95-.84-1.19-2.23-2.22-2.99-.75-.55-1.7-.33-2.54-.14-.27.06-.52.15-.79.22-.19.06-.39.1-.58.15-.44.12.18.01-.25.05-.75.05-1.48.07-2.23-.02-1.36-.16-2.66-.61-3.85-1.29-.23-.13-.77.29-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m581.18,819.06c2.44-1.05,4.9-2.06,7.37-3.02.51-.2,1.23-.56,1.67-.62.35-.05.95.15,1.32.38.85.53,1.39,1.46,1.87,2.31.06.11.93-.25.99-.16-.56-1.01-1.25-2.19-2.42-2.58-.82-.27-1.9-.04-2.72.2-1.51.43-2.98,1.12-4.43,1.71-1.55.63-3.1,1.28-4.64,1.94.3-.13.69-.03.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m600.2,816.22c.28-2.01.55-4.03.55-6.06,0-1-.07-2.01-.24-3s-.5-1.88-.74-2.83c-.37-1.44-.15-2.71.68-3.94.73-1.08,1.7-1.98,2.56-2.96.91-1.04,1.7-2.21,1.71-3.64,0,.05-.99.1-.99.16-.01,2.6-2.48,4.26-3.93,6.13-.8,1.03-1.35,2.22-1.19,3.55.12.99.52,1.92.75,2.88s.35,1.98.39,2.98c.09,2.3-.22,4.6-.53,6.88.01-.09.97-.06.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m562.07,783.04c-2.58-2.75-4.1-6.43-4.13-10.2-.02-1.83.34-3.84,1.83-5.07.3-.25.64-.46,1.01-.6.08-.03.17-.08.25-.09-.23.04.1.03.12.03.27.04.53.06.79.12.89.2,1.73.59,2.65.68.61.06,1.26-.06,1.86-.19.46-.1.88-.3,1.3-.52.21-.11.41-.22.62-.33.15-.08.99-.36.35-.26l-.15.02c1.4-.11,2.63.79,3.3,1.97.79,1.41.8,3.11.81,4.69,0-.04.99-.1.99-.16-.02-2.34-.17-5.31-2.64-6.41-.81-.36-1.6-.25-2.43-.09-.43.08-.82.21-1.21.41-.27.13-.53.28-.8.42-.12.06-.24.12-.36.17-.03.01-.63.22-.2.15l.25-.04c-1.65.14-3.11-.97-4.75-.78-1.23.14-2.34.45-3.19,1.41-1.19,1.33-1.41,3.21-1.36,4.93s.4,3.37,1.01,4.95c.71,1.84,1.77,3.51,3.12,4.95.13.14.86-.29.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m577.58,732.12c-.9,1.29-2.32,1.94-3.53,2.87-1.12.85-2.23,2.18-1.91,3.69.16.73.62,1.34,1.08,1.9.62.74,1.26,1.46,1.9,2.19,1.25,1.45,2.51,2.89,3.76,4.34,1.01,1.16,2.1,2.78,3.67,3.21.86.24,2.18.03,2.95-.43,1.42-.85,1.77-2.6,1.84-4.13s-.1-2.93-.47-4.34c-.87-3.27-2.83-6.21-5.55-8.22-.19-.14-.8.29-.99.16,2.26,1.67,4,3.97,5.02,6.58.51,1.3.84,2.68.96,4.07s.13,2.99-.41,4.32c-.26.64-.66,1.21-1.23,1.61-.15.11-.34.17-.49.27l-.09.03c-.17.06-.11.06.17,0-.18.15-.87-.14-1.07-.22-1.39-.58-2.37-1.99-3.32-3.09-1.2-1.38-2.4-2.77-3.59-4.15-.54-.63-1.09-1.26-1.63-1.89s-1.11-1.24-1.4-2.01c-1.25-3.39,3.83-4.8,5.32-6.94-.13.19-.85-.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m588.01,714.9c-1.65,4.08-1.46,8.82.55,12.75s5.94,6.47,6.9,10.83c.6,2.71.13,5.52-.33,8.21.02-.1.97-.05.99-.16.41-2.38.82-4.82.5-7.24-.29-2.25-1.34-4.16-2.71-5.92s-2.82-3.24-3.92-5.08-1.8-4.02-2.02-6.2c-.25-2.49.09-5.02,1.03-7.34-.06.15-.92,0-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m597.1,741.74c3.28-3.22,9.89-3.1,10.82-8.47.27-1.58.05-3.21-.11-4.8-.18-1.82-.36-3.65-.54-5.47s-.36-3.65-.54-5.47c-.06-.63-.1-1.35-.46-1.9-.39-.59-1.06-.74-1.72-.74-1.98,0-3.99.27-5.97.41s-4.09.29-6.14.43c-.33.02-.66.14-.99.16,3.6-.25,7.21-.51,10.81-.76,1.42-.1,2.93-.26,3.34,1.45.18.76.2,1.58.28,2.35.09.91.18,1.82.27,2.73.18,1.82.36,3.65.54,5.47.17,1.66.42,3.35.37,5.02-.04,1.42-.39,2.82-1.3,3.94-.7.87-1.65,1.5-2.64,2.01-2.35,1.22-5.06,1.88-7,3.78.19-.19.79.03.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m590.5,710.39c-3.11-3.15-6.22-6.3-9.33-9.46-.04-.04-.95.11-.99.16-1.53,1.98-3.24,3.82-5.11,5.48-.88.79-1.86,1.47-2.72,2.29-.76.72-1.26,1.63-1.11,2.7.33,2.3,2.65,3.35,4.71,3.6s4.35.21,6.46-.13,4.47-1.31,5.13-3.64c-.04.13-.95.02-.99.16-.55,1.9-2.46,3.15-4.22,3.47-.99.18-2.37.11-3.5.08-1.05-.02-2.12-.07-3.14-.33-1.76-.46-3.56-1.88-3.5-3.87.03-.95.6-1.71,1.28-2.33.77-.7,1.63-1.32,2.41-2.01,1.93-1.7,3.69-3.59,5.27-5.63l-.99.16c3.11,3.15,6.22,6.3,9.33,9.46.14.14.86-.29.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m561.23,726.93c1.14-2.64,2.28-5.27,3.42-7.91.53-1.22,1.01-2.46,1.59-3.66.44-.91,1.03-1.8,2.06-2.07l-.49.08.19-.03-.49.08c2-.13,3,1.92,3.75,3.44.83,1.68,1.55,3.64.68,5.45-1.57,3.26-5.88,3.59-8.15,6.16.17-.19.81.04.99-.16,1.65-1.87,4.14-2.5,6.12-3.92.92-.66,1.77-1.5,2.17-2.58.48-1.28.22-2.64-.27-3.88-.46-1.17-1.05-2.44-1.83-3.43-.66-.83-1.59-1.37-2.68-1.23-.87.12-1.63.26-2.24.95-.79.9-1.19,2.14-1.65,3.22-1.39,3.21-2.77,6.42-4.16,9.62.07-.16.91.01.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m567.8,729.65c-.03,1.3-1.79,3.1-.43,4.23,1.08.9,2.68.64,3.95.41,1.51-.27,2.8-1.08,3.84-2.2.54-.58,1.03-1.23,1.45-1.91.36-.6.74-1.42.35-2.1-.42-.73-1.44-.87-2.19-1-1.07-.18-2.15-.22-3.23-.15-.33.02-.66.14-.99.16.82-.05,1.65-.04,2.47.05.72.08,1.51.17,2.18.47,1.61.71.7,2.37-.02,3.39-.81,1.14-1.8,2.2-3.06,2.85-.3.16-.62.29-.94.39-.11.04-.23.06-.34.1-.39.13.22-.02.24-.02-.19.06-.5.03-.7.03-.67-.02-1.43-.17-1.97-.59-1.44-1.11.35-2.96.38-4.27,0,.05-.99.09-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m574.91,716.62c.35,2.39-.1,5.4,1.87,7.22.93.86,2.22,1.02,3.44,1.13,1.46.12,2.93.24,4.4.35.32.03.67-.19.99-.16-1.47-.12-2.93-.23-4.4-.35-1.22-.1-2.5-.27-3.44-1.13-1.97-1.81-1.52-4.83-1.87-7.22,0,0-.99.15-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m613.4,722.98c-.79,3.59-5.4,8.06-2.46,11.73.53.66,1.3,1.18,2.16,1.26.55.05,1.15-.08,1.69-.19.93-.19,1.71-.75,2.61-1.03s2-.29,2.81-.89c2.05-1.51.9-4.37.03-6.18-.04-.09-.95.24-.99.16.77,1.6,1.62,3.49.75,5.23s-3.02,1.51-4.55,2.23c-.4.19-.85.33-1.24.53.04-.02.35.01.13-.02-.08-.01-.17,0-.26,0-.2-.02-.39-.06-.58-.12-.74-.24-1.36-.78-1.79-1.43-1.08-1.6-.75-3.6-.06-5.29.84-2.09,2.24-3.92,2.73-6.15-.02.11-.96.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m620.36,718.29c-.29,4.13,1.05,8.26,3.71,11.44.11.14.88-.29.99-.16-2.66-3.18-4-7.31-3.71-11.44,0,.07-.98.08-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m627.92,725.49c1.6,1.99,4,3.66,6.56,4.1.65.11,1.91.05,2.25-.61.27-.54.14-1.31.15-1.89,0-.86,0-1.72.01-2.58.01-3.23.11-6.46.04-9.69-.02-1.18-.23-2.54-1.41-3.12-.85-.42-1.83-.21-2.72-.05-1.4.25-2.66.94-3.93,1.55-1.69.81-3.38,1.61-5.07,2.42.29-.14.7-.02.99-.16,1.91-.91,3.82-1.82,5.73-2.74,1.37-.65,3.14-1.78,4.52-.52,1.24,1.14.9,3.34.9,4.84,0,2.13-.02,4.27-.03,6.4,0,1.19-.01,2.38-.02,3.57,0,.81.28,2.26-.7,2.6l.36-.06-.17.03.63-.1c-1.31.03-2.8-.72-3.91-1.37-1.22-.72-2.3-1.67-3.19-2.77-.11-.13-.89.28-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m624.56,735.53c.04,2.92.13,6.11,2,8.53.84,1.08,2.01,1.88,3.37,2.12.89.16,1.81.02,2.69-.16,2.57-.54,3.63-3.25,4.17-5.53.67-2.8.47-5.78-1.9-7.73-2.06-1.69-5.11-1.7-7.47-.63.29-.13.69-.03.99-.16,2.16-.98,4.72-.07,6.23,1.66,1.67,1.92,1.76,4.42,1.22,6.79-.44,1.97-1.19,4.26-3.06,5.32-.2.11-.51.17-.68.3l-.09.03c.34-.01.3,0-.14.02-.13,0-.26,0-.38,0-.58-.03-1.16-.18-1.69-.41-1.02-.44-1.85-1.19-2.48-2.1-1.66-2.39-1.73-5.41-1.77-8.22,0,.04-.99.1-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m606.68,737.7l6.22-.16c1.85-.05,3.84-.3,5.67.05,3.04.58,4.13,3.91,6.89,4.96.27.1.72-.26.99-.16-2.76-1.05-3.85-4.38-6.89-4.96-1.83-.35-3.82-.1-5.67-.05l-6.22.16c-.33,0-.66.15-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m613.8,694.64c1.53,1.49,1.23,3.8.67,5.66-.35,1.16-.78,2.3-.92,3.51s.11,2.39.57,3.51c.89,2.18,2.31,4.21,3.75,6.05.3.38.64.96,1.13,1.11.26.08.58,0,.84-.05.27-.04.56-.06.79-.23.44-.34.68-1.07.94-1.54,1.42-2.63,2.83-5.25,4.25-7.88-.09.17-.89-.02-.99.16-.91,1.69-1.82,3.38-2.74,5.07-.45.83-.89,1.65-1.34,2.48-.34.63-.68,1.75-1.41,2.01l.99-.16c-.89.05-1.56-1.23-2.02-1.85-.58-.78-1.13-1.59-1.65-2.42-1-1.61-2.03-3.38-2.16-5.31-.22-3.23,3.17-7.47.28-10.28-.15-.14-.85.29-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m614.48,683.33c.27,3.41,1.63,6.7,3.89,9.28,2.06,2.36,4.8,3.96,7.14,6.01,1.31,1.15,2.47,2.44,3.25,4.01.01.02.98-.13.99-.16,1.07-5.19,1.25-10.52.52-15.76,0,0-.99.14-.99.16.73,5.25.55,10.58-.52,15.76l.99-.16c-1.38-2.78-3.93-4.62-6.34-6.46-2.63-2-4.98-4.16-6.42-7.19-.84-1.77-1.35-3.69-1.51-5.65,0,.02-.99.13-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m627.89,684.74c-3.44-1.08-6.87-2.17-10.31-3.25-.29-.09-.71.25-.99.16,3.44,1.08,6.87,2.17,10.31,3.25.29.09.71-.25.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m607.16,677.8c1.79,7.81,1.8,15.97.04,23.78l.99-.16c-2.89-1.6-6.07-3.45-7.09-6.81-.58-1.91-.57-4.02-.61-5.99s.03-4.05.73-5.94c.65-1.75,1.8-3.31,3.47-4.18-.28.14-.7.01-.99.16-3.38,1.76-4.22,5.79-4.22,9.28,0,2,.01,4.12.43,6.08.37,1.73,1.27,3.2,2.56,4.4,1.39,1.3,3.07,2.24,4.72,3.16.07.04.98-.13.99-.16,1.76-7.82,1.75-15.97-.04-23.78,0-.03-.98.17-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m600.99,655.42c-2.53,6.79-1.84,14.57,1.84,20.81.01.02.95-.11.99-.16,1.99-2.99,3.15-6.41,3.94-9.9.41-1.78.72-3.57,1.03-5.37.26-1.53.83-3.65-.43-4.9-.48-.47-1.12-.73-1.79-.65-.53.07-1.12.11-1.57.42.25-.17.73.01.99-.16.18-.12.34-.19.54-.25l-.36.06.15-.02-.63.1c2.02-.13,2.58,2.04,2.42,3.64-.2,2.02-.64,4.05-1.05,6.04-.81,3.91-1.98,7.8-4.22,11.15l.99-.16c-3.68-6.24-4.37-14.02-1.84-20.81-.06.15-.93,0-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m586.19,667.7c.13,2.01.48,4.21,1.37,6.04.77,1.58,2.28,1.43,3.75,1.17,1.38-.24,3.2-.69,4.13-1.82.58-.7.65-1.62.61-2.49-.05-1.08-.16-2.17-.29-3.25-.29-2.45-.74-4.87-1.34-7.27,0-.03-.98.18-.99.16.6,2.39,1.05,4.82,1.34,7.27.14,1.2.31,2.44.3,3.65,0,1.03-.29,1.95-1.14,2.58-.76.56-1.73.86-2.63,1.11-.18.05-.35.09-.53.13.51-.07.53-.08.05-.04-.24,0-.48,0-.71-.06-.45-.1-.84-.31-1.14-.66-.7-.8-.97-2.06-1.22-3.05-.3-1.19-.49-2.41-.56-3.64,0,.03-.99.12-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m590.66,657.03c-1.82.31-3.47,1.22-4.6,2.69-1.25,1.62-1.71,3.76-1.3,5.76,0,.02.99-.17.99-.16-.77-3.8,1.74-7.73,5.62-8.39.16-.03.16-.03,0,0-.23.03-.47.07-.7.11h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m566.69,671.68c.78-.86,1.62-1.65,2.52-2.38s1.86-1.55,2.92-1.89l-.36.06.21-.04-.63.1c1,.02,1.77,1.13,2.43,1.78.79.78,1.59,1.56,2.38,2.35,1.41,1.41,2.83,3.04,2.96,5.13.27,4.18-5.56,5.71-8.12,2.93-1.21-1.32-1.86-3.07-3.27-4.22-.18-.14-.82.3-.99.16,1.75,1.42,2.34,3.97,4.34,5.1,1.13.64,2.53.55,3.77.38.94-.13,1.9-.27,2.78-.64,1.22-.5,2.16-1.46,2.41-2.78.26-1.41-.22-2.84-1-4.01s-1.84-2.16-2.85-3.15c-.56-.55-1.12-1.11-1.68-1.66-.5-.49-1.07-1.28-1.77-1.49-.43-.13-1.04.03-1.46.13-.62.14-1.15.53-1.67.88-1.44.98-2.75,2.13-3.92,3.41.17-.19.81.04.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m544.18,669.65c1.65-.97,3.35-1.93,5.2-2.43,1.66-.44,3.54.05,4.93,1.03,2.78,1.96,3.84,5.41,4.57,8.56.49,2.12.93,4.26,1.84,6.26l.97-.16c-1.64.18-3.28.37-4.92.55-.3.03-.72.13-.95.16,1.64-.18,3.28-.37,4.92-.55.09-.01.95-.19.97-.16-1.72-3.76-1.71-8.12-3.76-11.75-.86-1.51-2.06-2.9-3.65-3.65s-3.25-.63-4.93-.34c-2.24.39-4.25,1.5-6.19,2.65.26-.16.72,0,.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m572.03,697.01c-.08-1.85-1.09-3.55-.95-5.42.06-.71.27-1.44.76-1.97.27-.29.57-.42.9-.61s-.12.07-.2,0c.03.03.29,0,.34,0,.23.01.47.05.69.1,1.65.39,3.13,1.6,4.19,2.88.54.66,1.15,1.45,1.34,2.3.2.89-.33,1.5-1.07,1.9-1.96,1.04-4.19,1.73-6.32,2.3.32-.09.67-.07.99-.16,2.09-.56,4.19-1.25,6.14-2.21.81-.4,1.47-1.01,1.25-1.99-.21-.93-.91-1.82-1.53-2.52-1.16-1.32-2.86-2.66-4.69-2.76-.64-.03-1.39.08-1.99.27-.79.25-1.34.88-1.6,1.64-.74,2.16.66,4.26.75,6.4,0-.03.99-.11.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m581.63,689.66c-.47,1.61-1,3.66.29,5.05.58.63,1.43.84,2.26.94.95.11,1.91.16,2.86.24.67.05,1.34.06,2-.13.39-.11.49-.42.47-.8-.03-.49-.13-.99-.2-1.48-.13-.99-.27-1.97-.4-2.96-.12-.82-.26-1.67-.69-2.39s-1.19-1.23-2.04-1.21c-.61.02-1.41.12-1.91.5.09-.07.38-.06.49-.08.12-.02.39,0,.49-.08.15-.12.33-.16.49-.27-.4.25-.77.06-.33.09.08,0,.15.02.22.03.4.08.78.27,1.09.54.68.6.95,1.54,1.1,2.4.18,1.02.29,2.05.43,3.08.08.57.16,1.13.23,1.7.06.45.08.91-.43,1.07l.84-.14-.12.02.15-.02c-.52.02-1.05-.06-1.56-.1-.55-.04-1.1-.08-1.65-.13-.86-.07-1.8-.17-2.51-.73-1.64-1.28-1.07-3.63-.57-5.31-.04.13-.94.02-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m589.58,682.87c-.69-1.28.21-2.64,1.23-3.4,1.14-.85,2.55-1.33,3.44-2.49-.04.05-.42.06-.49.08-.09.01-.44.01-.49.08-.88,1.16-2.3,1.64-3.44,2.49-1.02.76-1.92,2.13-1.23,3.4.01.03.45-.07.49-.08s.48-.11.49-.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m597.58,676.48c1.42,2.29.25,4.98-.57,7.26-.46,1.27-.91,2.55-1.37,3.82-.23.66-.47,1.31-.7,1.97-.15.42-.31,1.45-.86,1.55l.92-.15c-.43-.09-.66-.54-.91-.86-.33-.42-.66-.85-.99-1.27-.66-.85-1.32-1.7-1.98-2.54-.1-.13-.89.28-.99.16.71.91,1.42,1.83,2.13,2.74.3.39.61.78.91,1.17.23.29.45.72.86.77.29.03.99-.08,1.24-.26.33-.24.45-.88.57-1.23.18-.5.36-1,.54-1.51.73-2.04,1.48-4.07,2.18-6.11.63-1.84,1.09-3.9,0-5.67-.07-.11-.92.26-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m586.93,703.34c2.31,2.06,4.8,3.92,7.44,5.52,1.29.78,2.62,1.51,3.98,2.17s2.74,1.34,4.25,1.19c.84-.08,1.78-.21,2.5-.68,1.13-.74,1.37-2.12.87-3.32s-1.63-2.01-2.8-2.56c-1.41-.66-2.9-1.17-4.36-1.7-3.45-1.25-7.01-2.28-10.71-2.25-.33,0-.66.16-.99.16,3.02-.02,5.96.66,8.81,1.6,1.46.48,2.89,1.02,4.32,1.57,1.2.46,2.46.9,3.48,1.71.91.73,1.59,1.85,1.51,3.05-.03.54-.24,1.05-.6,1.45-.19.22-.43.4-.68.54-.1.06-.21.11-.32.16-.03.01-.51.18-.23.13-.62.13-1.42-.12-2-.31-.71-.24-1.39-.56-2.06-.89-1.26-.61-2.49-1.28-3.69-2-2.75-1.64-5.34-3.55-7.73-5.69-.16-.14-.84.3-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m636.72,670.39c3.36,1.9,6.39,4.36,8.91,7.29.59.68,1.34,1.44,1.6,2.32.24.82-.12,1.64-.75,2.17-.16.13-.33.24-.51.33-.08.04-.16.07-.24.1-.14.06-.34.02-.03.03-.5-.02-.97-.06-1.45-.2-.87-.25-1.68-.68-2.41-1.2-1.69-1.2-2.98-2.86-4.23-4.49-.1-.13-.9.28-.99.16,1.21,1.58,2.46,3.19,4.08,4.38.8.59,1.7,1.08,2.66,1.34.81.22,1.57.18,2.38.04s1.49-.33,2.02-.95c.67-.78.63-1.74.11-2.58s-1.32-1.69-2.02-2.46-1.49-1.53-2.28-2.25c-1.8-1.61-3.77-3.01-5.87-4.19-.23-.13-.76.29-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m650.75,684.64c3.09,4.3,8.4,7.35,13.77,7.32.77,0,1.84-.09,2.52-.48,1.28-.75.83-2.42.02-3.33-1-1.13-2.39-2-3.67-2.8-1.34-.84-2.73-1.58-4.13-2.31s-2.84-1.67-4.43-1.52c-1.05.1-2.2.28-2.84,1.2.13-.19.85.03.99-.16.31-.45.7-.68,1.2-.9.33-.14,0,.13-.14,0,.01.01.29,0,.35,0,.35.02.71.09,1.04.2,1.28.39,2.48,1.13,3.65,1.75,2.32,1.23,4.81,2.54,6.71,4.39.77.75,1.46,1.95.87,3.02-.13.23-.31.42-.53.56-.12.08-.26.12-.38.19-.24.14-.36-.11.28,0-.27-.05-.6.03-.88.02-.32-.01-.64-.04-.96-.07-2.3-.24-4.55-.96-6.57-2.06-2.32-1.27-4.33-3.03-5.87-5.18-.09-.13-.9.28-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m666.45,679.73c-1.82,1.55-.32,3.96.81,5.39,1.55,1.95,2.94,3.99,4.15,6.17.06.11.94-.25.99-.16-1.21-2.17-2.6-4.22-4.15-6.17-1.13-1.43-2.64-3.83-.81-5.39-.21.18-.77-.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m669.87,680.44c2.02,2.81,4.9,4.96,8.2,6.03,1.43.47,3.75.88,3.92,2.77.16,1.68-1.86,2.62-3.09,3.25-.48.25-.98.48-1.5.66-.1.03-.21.06-.31.1.24-.08-.1.03-.22.03-.24,0-.48,0-.71-.05-.88-.14-1.67-.58-2.15-1.35-.07-.12-.92.26-.99.16.91,1.46,2.61,1.49,4.12,1.23.83-.14,1.6-.39,2.35-.75.87-.42,1.8-.88,2.53-1.52.64-.55,1.11-1.32.9-2.19s-1.01-1.49-1.81-1.84c-.94-.4-1.95-.6-2.9-.97-.89-.35-1.74-.77-2.56-1.27-1.89-1.15-3.51-2.67-4.8-4.47-.09-.13-.9.28-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m678.51,692.71c2.63,1.07,4.29,3.43,5.85,5.69,1.7,2.44,3.46,4.89,6.28,6.11,2.57,1.11,5.74,1.16,8.38.2.67-.24,1.36-.63,1.72-1.28.41-.75.21-1.62-.16-2.34-.71-1.42-1.88-2.65-3.07-3.68-2.78-2.4-6.22-3.88-9.57-5.31-.26-.11-.73.27-.99.16,2.8,1.19,5.64,2.42,8.13,4.19,1.22.86,2.35,1.86,3.29,3.02.86,1.05,2.15,2.7,1.37,4.12-.31.57-.89.93-1.47,1.18-.4.17-.81.27-1.23.38s-.12.02-.02.01c-.32.04-.65.06-.97.07-1.55.03-3.11-.28-4.53-.91-2.61-1.16-4.31-3.37-5.9-5.64s-3.35-4.97-6.15-6.11c-.27-.11-.73.27-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m698.96,690.38c-2.14-1.5-4.24-3.16-6.49-4.49-.96-.57-2.04-1.04-3.18-.94-.59.05-1.32.12-1.85.41-.37.2-.76.56-.79,1.01-.04.6.56,1,.97,1.34.96.81,1.94,1.6,2.95,2.35,2.01,1.51,4.12,2.9,6.3,4.15.52.3,1.16.82,1.78.87.3.03.63-.07.92-.12.93-.16,1.09-.95,1.06-1.81,0,.04-.99.11-.99.16.02.64-.04,1.59-.8,1.76l.97-.16c-.54,0-1-.33-1.46-.59-.53-.3-1.06-.61-1.58-.92-1.04-.63-2.07-1.29-3.08-1.98s-1.99-1.41-2.96-2.16-1.97-1.42-2.76-2.28c-.33-.36-.45-.79-.19-1.23.12-.19.3-.35.48-.48.1-.07.21-.12.32-.17.24-.08.22-.09-.06-.02,1.14-.02,2.19.48,3.14,1.07,2.17,1.34,4.21,2.92,6.3,4.38.2.14.8-.29.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m705.63,696.11s.54-.12.55-.14l-.19.03c-.31.05-.25.05.17,0,.22,0,.45,0,.67.01.52.04,1.02.13,1.52.28.98.29,1.89.78,2.71,1.39,1.76,1.32,2.97,3.22,3.97,5.14.24.47.48.95.71,1.43.22.45.58.99.64,1.49.07.61-.39.74-.88.88-.37.1-.17.05-.07.03-.2.03-.41.04-.61.06-.31.02-.63.02-.94.01-2.12-.07-4.2-.83-5.85-2.17-1.74-1.43-2.95-3.43-3.42-5.63,0-.02-.99.17-.99.16,1.07,4.97,5.8,8.35,10.85,7.73.78-.1,1.83-.14,2.53-.51.61-.32.3-.97.08-1.44-.3-.64-.6-1.28-.92-1.91-1.16-2.29-2.62-4.56-4.85-5.93-2.05-1.25-4.38-1.45-6.66-.76.32-.1.67-.06.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m727.65,709.82c-1.81-.83-3.21-2.26-4.53-3.72-.63-.7-1.42-1.43-1.66-2.38-.19-.74.11-1.65.89-1.9-.31.1-.67.06-.99.16-.75.24-1.06,1.11-.9,1.84.21.97,1.03,1.73,1.67,2.45,1.31,1.46,2.72,2.88,4.53,3.72.09.04.39-.06.49-.08s.4-.13.49-.08h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m725.88,703.32c1.13,1,2.27,1.99,3.4,2.99,0,0,.16-.02.15-.02.12-.02.23-.04.35-.06s.23-.04.35-.06c.02,0,.13-.04.14-.03-1.13-1-2.27-1.99-3.4-2.99,0,0-.16.02-.15.02-.12.02-.23.04-.35.06s-.23.04-.35.06c-.02,0-.13.04-.14.03h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m698.58,710.87c-.11-.8-.89-1-1.58-.9-.72.11-1.39.19-1.76.91-.54,1.03-.07,2.17.39,3.14.51,1.07,1.03,2.14,1.59,3.18,1.08,2,2.37,3.87,4.06,5.4,1.49,1.35,3.17,2.46,4.76,3.68,1.74,1.34,3.38,2.84,4.41,4.82.05.1.94-.25.99-.16-1.58-3.04-4.54-4.89-7.19-6.89-1.41-1.06-2.75-2.21-3.85-3.59-1.25-1.57-2.2-3.35-3.08-5.14-.49-1-1.18-2.09-1.32-3.21-.11-.88.25-1.93,1.2-2.16l-.97.16c.63-.07,1.28.26,1.37.93,0,0,.99-.14.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m713.96,727.54c-3.12-1.76-4.11-5.48-5.72-8.43-1.87-3.43-4.77-6.23-8.24-8.01-.24-.12-.75.28-.99.16,3.46,1.79,6.37,4.59,8.24,8.01,1.61,2.95,2.6,6.67,5.72,8.43.23.13.76-.29.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m692.9,717.7c-1.41.53-3.14,1.26-3.44,2.93-.14.81.24,1.54.69,2.19.55.77,1.15,1.51,1.73,2.26s1.17,1.54,1.77,2.29c.5.63,1.06,1.22,1.83,1.49s1.75.27,2.6.3c.97.03,1.94.02,2.91-.03.33-.02.66-.14.99-.16-.97.05-1.94.07-2.91.03-.85-.03-1.78-.02-2.6-.3s-1.34-.86-1.83-1.49c-.6-.75-1.18-1.53-1.77-2.29s-1.18-1.49-1.73-2.26c-.46-.64-.83-1.37-.69-2.19.29-1.67,2.04-2.4,3.44-2.93-.3.12-.68.04-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m677.4,697.95c-1.35.03-2.98.09-4.22.69-.7.34-1.32.9-1.58,1.65-.33.97.08,1.97.53,2.82.94,1.77,2.12,3.44,3.44,4.93.55.62,1.18,1.25,1.97,1.55.61.23,1.19.14,1.81.04s1.14-.27,1.69-.56c.33-.17.65-.36.99-.51.05-.02.54-.19.54-.21,0,.12-.52-.06-.27.06.07.04.23.02.32.04,1.37.28,1.96,1.84,3.24,2.31,1.04.38,2.66-.11,3.27-1.02.96-1.44.32-3.53-.53-4.87-1.17-1.85-3.04-2.63-5.06-3.21-1.89-.54-3.96-1.13-5.02-2.93-.06-.11-.93.26-.99.16,1.77,3.01,5.85,2.51,8.42,4.37,1.22.88,2.12,2.2,2.48,3.67.18.7.24,1.46.06,2.17-.1.38-.27.73-.52,1.03-.12.14-.26.27-.41.38-.1.07-.21.14-.32.19-.26.1-.22.1.1.02-.02.22-1.34-.64-1.4-.69-.5-.43-.92-.96-1.47-1.33-.83-.58-1.77-.43-2.7-.22-.61.14-1.15.47-1.71.76-.07.04-.93.43-.94.4-.05-.22.59.05.14-.05-.25-.06-.49-.07-.73-.16-1.49-.56-2.6-2.23-3.5-3.47-.57-.78-1.1-1.59-1.58-2.43-.51-.88-1.09-1.89-.96-2.94.09-.78.59-1.43,1.22-1.86.37-.25.78-.43,1.21-.54-.33.09.51-.06.58-.07.3-.02.61-.02.91-.03.33,0,.66-.15.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m663.7,710.18c.96.24,2.06.02,3.01-.22.64-.16,1.25-.39,1.87-.59.14-.04.28-.09.42-.12l.08-.02c.47-.1.42-.1-.13,0,.44.07.84-.03,1.29.12,1.43.47,2.14,2.06,2.85,3.25.97,1.63,1.93,3.27,2.9,4.9s1.93,3.27,2.9,4.9c.46.78.93,1.56,1.39,2.34.25.43.5.85.76,1.28.1.17.33.44.38.64.2.18.18.27-.06.26-.31.07-.25.07.16-.01-.11.16-1.2.02-1.4,0-.34-.04-.67-.1-1-.17-.84-.2-1.64-.54-2.39-.96-1.46-.83-2.69-2.01-3.78-3.28-2.4-2.79-4.28-6.12-7.43-8.17-.21-.14-.78.29-.99.16,3.03,1.97,4.88,5.12,7.15,7.83,2.15,2.57,5.1,5.02,8.67,4.76.48-.04.97-.12,1.45-.21.11-.02.69-.06.74-.16.07-.15-.78-1.31-.88-1.49-1.01-1.7-2.02-3.41-3.03-5.11s-2.1-3.55-3.15-5.33c-.5-.85-1.01-1.7-1.51-2.56-.4-.68-.79-1.4-1.3-2.01s-1.23-1.09-2.07-1.16c-.58-.05-1.17.08-1.74.18-.66.11-1.3.31-1.94.52-.98.32-1.27.5-2.23.27-.3-.07-.69.23-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m678.83,716.18c1.85.02,3.82.08,5.39,1.2,1.4,1,2.24,2.59,2.99,4.1.46.93.9,1.86,1.36,2.79.25.52.5,1.04.76,1.56.21.43.48.9.55,1.38.12.97-.71,1.24-1.47,1.47-1.12.34-2.23.67-3.35,1.01.32-.09.67-.06.99-.16,1.12-.34,2.23-.67,3.35-1.01.76-.23,1.59-.5,1.47-1.47-.06-.49-.34-.95-.55-1.38-.23-.48-.47-.96-.7-1.45-.47-.97-.94-1.94-1.41-2.9-.74-1.51-1.58-3.1-2.99-4.1-1.56-1.12-3.54-1.17-5.39-1.2-.33,0-.66.16-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m658.41,710.45l4.7-1.33c1.1-.31,2.98-.49,3.56-1.65s0-2.82-.35-3.94c-.4-1.28-.96-2.51-1.69-3.64-1.43-2.23-3.45-4.04-5.83-5.22-2.15-1.07-4.79-1.81-7.2-1.42-1.15.19-2.02.66-2.3,1.89-.35,1.53.56,2.94,1.37,4.16.08.12.91-.27.99-.16-.68-1.02-1.43-2.14-1.45-3.4-.02-1.14.51-1.98,1.5-2.47-.28.14-.21,0,0,.03.16.02.34,0,.5.02.31.02.61.05.92.09.64.08,1.28.21,1.91.38,1.22.33,2.4.81,3.5,1.43,2.15,1.22,3.97,2.98,5.26,5.09.65,1.06,1.16,2.2,1.53,3.38.33,1.06.77,2.46.48,3.57-.37,1.42-2.28,1.62-3.46,1.96l-4.94,1.4c.32-.09.67-.07.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m651.58,702.49c1.56,2.44,3.12,4.88,4.67,7.32.07.12.92-.27.99-.16-1.56-2.44-3.12-4.88-4.67-7.32-.07-.12-.92.27-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m650.95,718.56c.02,2.9.05,5.79.07,8.69s-.06,5.73.08,8.58c.06,1.22.64,1.79,1.79,2.09.87.22,1.64.5,2.35,1.09,1.27,1.06,2.06,2.58,2.88,3.99.94,1.61,1.98,3.28,3.75,4.07.08.04.99-.18.99-.16-.49-4.37-.99-8.75-1.48-13.12-.24-2.16-.48-4.33-.73-6.49-.22-1.89-.51-3.87-1.54-5.51s-2.67-2.78-4.58-2.66c-.33.02-.66.14-.99.16,1.68-.1,3.19.82,4.2,2.12,1.17,1.5,1.57,3.44,1.82,5.28.58,4.3.99,8.64,1.47,12.95.28,2.48.56,4.95.84,7.43l.99-.16c-3.19-1.44-3.89-5.38-6.22-7.69-.55-.54-1.19-.98-1.93-1.23-.99-.33-2.17-.36-2.51-1.55-.15-.52-.11-1.1-.11-1.64l-.02-2.23-.04-4.35c-.03-3.27-.06-6.54-.08-9.81,0,.04-.99.1-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m661.89,726.36c1.44,8.27,5.09,16.08,10.51,22.5.11.14.88-.29.99-.16-5.42-6.41-9.07-14.23-10.51-22.5,0-.01-.99.16-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m673.68,742.98c-.63-4.38-1.21-8.77-1.88-13.15-.25-1.62-.68-3.5-2.33-4.23-.93-.41-2.43-.29-3.28.3.24-.17.74.01.99-.16.13-.09.28-.15.42-.24.21-.07.2-.07-.04-.02-.24.03-.24.04-.02.04.33.08.63.1.95.24.59.26,1.06.69,1.41,1.23.87,1.38.95,3.17,1.18,4.74.54,3.8,1.09,7.61,1.63,11.41,0,0,.99-.15.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m676.09,732.56c-1.83,7.48.88,14.95,3.9,21.72.35.79.71,1.59,1.07,2.38.3.65.67,1.41,1.43,1.59.96.23,2.23-.28,2.95-.86,1.19-.96,2.43-2.22,3.06-3.63.76-1.7.14-3.23-.62-4.8-1.94-4-3.88-7.99-5.82-11.99-.04-.09-.95.24-.99.16,1.62,3.33,3.23,6.66,4.85,9.99.39.81.79,1.61,1.18,2.42.37.77.72,1.56.79,2.41.11,1.51-.74,2.83-1.7,3.9-.49.55-1.02,1.08-1.57,1.55-.26.22-.53.43-.84.6l-.18.09c-.26.1-.24.1.07.02-.01.05-.49-.15-.52-.16-.56-.29-.84-.92-1.09-1.46-.4-.86-.79-1.73-1.17-2.59-1.41-3.17-2.73-6.39-3.55-9.77-.94-3.84-1.2-7.87-.26-11.73-.03.12-.95.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m684.73,735.37c2.05,5.09,4.4,10.06,7.05,14.87.06.1.94-.25.99-.16-2.65-4.81-5-9.78-7.05-14.87-.03-.07-.96.22-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m688.21,734.41c3.04.46,6.06.34,9.08-.16.25-.04.49-.08.73-.13.46-.09.42-.11.87-.05.67.09,1.21.5,1.61,1.02.87,1.1,1.13,2.57,2.18,3.54.16.14.84-.3.99-.16-.99-.91-1.27-2.24-2.01-3.31-.35-.5-.8-.96-1.38-1.15-.52-.18-1.07-.1-1.6-.02-1.52.24-3.01.51-4.55.56-1.65.06-3.29-.05-4.92-.29-.32-.05-.68.21-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m668.39,751.95c.01,2.35.02,4.79.95,6.99.73,1.72,2.14,3.48,4.07,3.86.69.14,1.41,0,2.09-.13.84-.17,1.41-.72,1.62-1.55.44-1.79-.19-3.93-1.06-5.5-1.03-1.87-2.65-3.37-4.63-4.2-.26-.11-.73.27-.99.16,3.18,1.33,5.48,4.48,5.76,7.93.07.85.08,1.86-.44,2.58-.05.07-.9.76-.92.66,0-.04.46,0,.21-.04-.1-.01-.2,0-.3-.01-.22-.02-.45-.06-.66-.12-1.73-.49-3-2.06-3.71-3.64-1-2.23-1.01-4.74-1.02-7.14,0,.04-.99.1-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m648.43,760.2c-4.25-2.73-4.46-8.38-2.45-12.56.48-1,1.09-2.27,2.03-2.92.1-.07.21-.12.31-.18.2-.13.24.04-.07-.03.25.06.49.06.74.14.58.2,1.09.56,1.55.94,1.67,1.37,4.06,3.69,2.95,6.08-.31.66-.85,1.2-1.33,1.73-.54.61-1.09,1.23-1.63,1.84-1.09,1.23-2.18,2.45-3.27,3.68.17-.19.81.04.99-.16,1.03-1.16,2.07-2.33,3.1-3.49.97-1.09,2.14-2.15,2.92-3.39,1.57-2.5-1.07-5.13-2.91-6.58-.88-.69-1.76-1.05-2.87-.87-1.03.16-1.72.5-2.34,1.38-1.55,2.18-2.53,4.93-2.44,7.62s1.4,5.44,3.73,6.94c.21.14.78-.29.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m653.97,758.66c-1.76,2.96-1.77,6.47-1.31,9.79.23,1.66.53,3.33.93,4.95s1.03,3.15,2.5,3.96c2.28,1.25,5.62.31,7.12-1.73,1.02-1.39,1.2-3.12,1.54-4.76.38-1.83,1.1-3.56,1.6-5.36.8-2.87.75-7.07-2.58-8.34-.27-.1-.72.26-.99.16,2.49.96,3.3,3.95,2.99,6.38-.22,1.71-.85,3.33-1.37,4.96s-.73,3.29-1.18,4.93c-.4,1.45-1.18,2.75-2.47,3.57-.56.35-1.12.6-1.67.58-.76-.03-1.52-.24-2.18-.64-1.31-.8-1.92-2.25-2.29-3.68-.39-1.52-.67-3.08-.91-4.63-.53-3.48-.62-7.19,1.24-10.31-.11.18-.87-.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m631.25,774.65c-.73-.11-1.43.01-2.15.14-.77.13-1.48.4-2.12.86-1.3.96-2.12,2.6-2.18,4.21-.03.8.14,1.63.66,2.26s1.31.96,2.08,1.18c.71.2,1.47.42,2.21.32.63-.08,1.39-.12,1.83-.64.61-.7.8-1.86.86-2.76.07-1.07-.11-2.13-.51-3.12-.03-.07-.96.22-.99.16.46,1.14.62,2.36.46,3.58-.13,1.02-.45,2.48-1.6,2.77l.14-.03c.9-.15.29-.09.04-.12-.14-.02-.27-.04-.41-.07-.31-.06-.62-.14-.93-.22-.57-.15-1.13-.34-1.62-.68-1.03-.7-1.35-1.94-1.22-3.13s.66-2.4,1.49-3.28c.42-.44.91-.81,1.46-1.06.15-.07.3-.13.46-.18.07-.02.13-.04.2-.06.43-.1.34-.1-.28.02.38,0,.73-.03,1.11.02.32.05.68-.21.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m634.41,770.33l-.95,4.8c-.26,1.31-.92,3.1-.26,4.38.88,1.71,3.66,1.41,4.98.42,1.07-.8,1.61-2.08,1.89-3.35.34-1.51.45-3.08.65-4.62.18-1.38.55-2.87.41-4.26s-1.1-2.02-2.39-2.17c-1.04-.12-2.14-.03-3.16.22-1.1.28-2.27,1.66-1.22,2.69.14.14.85-.29.99-.16-.66-.65-.39-1.74.3-2.27.78-.6,2.05-.43,2.93-.15,1.06.34,1.54,1.21,1.57,2.28.04,1.24-.24,2.52-.4,3.74-.17,1.3-.31,2.6-.51,3.89-.17,1.13-.44,2.27-1.07,3.24-.27.41-.6.78-1,1.08-.19.15-.4.27-.62.37-.11.05-.23.1-.34.13.25-.07.09-.03-.09-.04-.95-.08-1.83-.65-2.1-1.59-.38-1.32.21-2.93.46-4.23l.9-4.56c-.02.11-.96.04-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m645.54,763.76c-2.67,2.06-4.32,5.31-4.39,8.69-.08,3.83,2.36,7.34,1.85,11.19-.17,1.27-.78,3.44.9,3.99.54.18,1.13.03,1.67-.06.68-.12,1.29-.36,1.84-.77,2.7-1.99,3.92-5.66,4.13-8.88.3-4.58-1.33-9.02-2.88-13.25-.02-.07-.97.21-.99.16,2.33,6.37,4.99,14.38.38,20.45-.47.62-1.01,1.22-1.64,1.68-.3.22-.64.42-.99.55-.02,0-.26.09-.26.09,0-.08.47.02.2-.05-.19-.05-.38-.04-.57-.11-1.55-.61-1-2.65-.83-3.88.52-3.67-1.68-7-1.83-10.62s1.54-7.15,4.38-9.34c-.23.18-.75-.02-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m620.35,801.04l3.94-7.4c.61-1.15,1.21-2.32,1.85-3.46.59-1.05,1.26-2.13,2.21-2.9.21-.17.43-.32.68-.44.12-.06.26-.09.37-.16.26-.15-.34-.07-.13.03.16.08.46.06.64.13.23.08.44.2.61.37.9.85.37,2.25.08,3.25-.73,2.52-1.61,5.04-2.76,7.4-.51,1.06-1.18,2.09-2.23,2.68-1.16.65-2.39.55-3.69.49-.33-.02-.66.17-.99.16,1.98.09,4.58.12,6.22-1.19,1.02-.81,1.58-2.05,2.09-3.22.63-1.45,1.2-2.94,1.7-4.44.25-.75.48-1.51.7-2.27.19-.66.43-1.38.33-2.08-.17-1.13-1.27-1.54-2.29-1.4s-1.94.41-2.72,1.19c-1.04,1.03-1.73,2.35-2.41,3.62-1.74,3.27-3.47,6.53-5.21,9.8.09-.17.89.02.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m368.05,679.11c-.9,2.59-1.49,5.29-1.73,8.02-.12,1.35-.16,2.7-.12,4.05.03,1.06.1,2.29,1.29,2.66.75.23,1.57.04,2.32-.08.89-.15,1.77-.41,2.61-.74,1.92-.76,3.79-2.15,3.94-4.38.19-2.86-2.09-4.95-4.47-6.07-.25-.12-.74.28-.99.16,1.92.91,3.74,2.35,4.31,4.49s-.32,3.92-2.04,5.07c-.79.53-1.69.9-2.59,1.19-.23.07-.45.14-.68.2-.11.03-.22.05-.32.08-.49.16.48.01-.04,0-.49,0-.96,0-1.41-.24-.97-.54-.91-1.79-.94-2.75-.03-1.24,0-2.47.11-3.7.24-2.77.83-5.51,1.74-8.14-.05.14-.93,0-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m376.48,689.68c2.44.33,5.53,1.07,6.18,3.86.29,1.22-.26,2.25-.95,3.22-.8,1.12-1.39,2.09-1.4,3.51s.23,2.72-.41,3.99c-.46.91-1.49,1.94-2.47,2.13-1.26.24-2.69-.58-3.49-1.47-.88-.99-1.49-2.32-1.79-3.61-.75-3.24,1.46-5.57,3.68-7.57-.21.18-.78-.03-.99.16-2.11,1.9-4.31,4.15-3.75,7.24.49,2.72,2.44,5.7,5.54,5.39.88-.09,1.83-.23,2.61-.69,1.18-.69,1.95-1.93,2.08-3.28.15-1.5-.31-3.04.39-4.46.55-1.12,1.57-1.99,1.92-3.22.41-1.45-.39-2.95-1.53-3.83-1.31-1.02-3.03-1.31-4.63-1.53-.32-.04-.67.2-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m397.51,695.36c-1.37,1.69-2.55,3.52-3.5,5.47-.47.95-.86,1.93-1.23,2.93-.3.8-.65,1.68-1.38,2.18-.1.07-.2.11-.3.17-.04.03-.34.09-.09.06.28-.04-.14-.03-.21-.04-.1-.01-.2-.03-.3-.06-.54-.13-1.02-.4-1.46-.74-.79-.61-1.52-1.38-2.15-2.15s-1.15-1.6-1.2-2.58.48-1.85,1.12-2.58c.88-.99,1.87-1.89,2.8-2.82.47-.47.94-.94,1.41-1.41.4-.39.79-.75,1.34-.9l-.14.03.15-.03-.84.13c1.05-.09,1.92.6,2.86.95.79.29,1.5.16,2.3.01.19-.03-.16.02-.18.02-.23.03-.45.07-.68.11.92-.17.15-.12-.15-.2-.26-.07-.51-.18-.76-.29-.52-.24-1.02-.53-1.58-.68-.68-.18-1.65-.07-2.29.22-.58.25-1.02.8-1.46,1.23-.62.62-1.24,1.23-1.86,1.85-.97.96-2.22,1.94-2.79,3.22s-.04,2.59.77,3.64,1.84,2.22,3.02,2.83c.9.47,1.8.39,2.76.19,1.23-.25,1.79-1.27,2.21-2.37,1.2-3.1,2.69-5.97,4.8-8.57-.15.19-.83-.04-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m399.3,694.89c2.29-.36,5.21-.43,6.37,2.03.43.92.53,1.96.59,2.96.08,1.28.09,2.56.06,3.84-.02.62-.04,1.24-.09,1.86-.04.51-.1,1.08-.52,1.42-.09.07-.21.11-.3.18-.23.18.19-.11.2-.02,0,0-.69,0-.74,0-.34.01-.67.06-1,.11-.85.14-1.66.33-2.47.64-1.02.39-2.1,1-3.22.89-2.52-.24-3.65-3.22-1.87-5.04-.18.19-.8-.04-.99.16-1.85,1.9-.53,4.94,2.07,5.06.79.04,1.63-.13,2.4-.31.55-.12,1.07-.31,1.6-.52.67-.26,1.36-.47,2.02-.74.04-.02.41-.12.07-.05.19-.04.4-.03.59-.03.25,0,.5,0,.75-.01.34-.03.68-.09,1.02-.15.4-.07.79-.16,1.05-.51.37-.5.34-1.26.37-1.85.09-1.53.09-3.06.02-4.58-.05-1.16-.12-2.38-.62-3.45-1.29-2.76-4.63-2.31-7.1-1.92-.15.02-.47.15-.61.1.02,0,.27-.04.35-.05h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m407.37,698.31l-.11-3.12c-.03-.97.01-1.98-.14-2.94-.13-.82-.61-1.42-1.38-1.74-.86-.35-1.8-.25-2.7-.15-.3.03-.72.13-.95.16.92-.1,1.9-.19,2.77.2.77.35,1.18,1,1.29,1.82.12.92.09,1.88.13,2.81l.11,3.12s.99-.11.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m409.68,696.05c-.11-1.08-.2-2.23.13-3.28.15-.46.39-.9.74-1.24.17-.17.37-.31.58-.42.13-.07.32-.1.44-.19.16-.04.12-.05-.12-.02.16-.02.32-.03.47-.03,1.3-.04,2.65.12,3.94.32s2.56.53,3.68,1.21c2.01,1.22,3.25,3.46,2.42,5.79s-3.15,3.84-5.03,5.42-4.01,4.38-2.61,7.03c.02.03.98-.14.99-.16.34-2.76-.78-5.53-2.96-7.26-1.87-1.48-4.46-2.42-5.4-4.79-.03-.07-.96.22-.99.16.94,2.37,3.53,3.31,5.4,4.79,2.18,1.73,3.31,4.5,2.96,7.26l.99-.16c-1.34-2.53.49-5.19,2.37-6.82,2.01-1.74,4.64-3.25,5.39-5.99.69-2.53-1.04-4.78-3.25-5.81-1.34-.62-2.82-.85-4.28-.99-1.18-.11-2.37-.25-3.54-.07s-2.28.41-2.9,1.53-.52,2.61-.39,3.88c0-.01.99-.13.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m422.95,697.91c1.39,1.37.81,3.52,1.55,5.18.89,2.02,3.12,1.45,4.86,1.18,1.27-.2,2.55-.4,3.82-.59.67-.1,1.5-.14,2.1-.48.88-.49.9-1.63.92-2.52.01-.84-.03-1.84-.68-2.46-.44-.42-1.05-.54-1.63-.64-.71-.13-1.42-.25-2.13-.38-1.42-.25-2.83-.51-4.25-.76-.31-.06-.68.21-.99.16,2.16.39,4.32.76,6.48,1.16.89.17,1.67.47,2,1.38.27.75.24,1.63.18,2.41s-.31,1.6-1.13,1.92c-.49.19-1.06.22-1.57.3-.56.09-1.12.17-1.67.26-1.85.29-4.36,1.07-5.32-1.11-.73-1.66-.16-3.81-1.55-5.18-.14-.14-.85.29-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m422.31,688.83c-.24,2.89,2.42,4.84,4.87,5.65,2.65.88,5.6,1.06,7.89,2.79,1.02.77,1.75,1.79,2.06,3.03s.25,2.54.75,3.71c.54,1.28,1.69,1.86,2.99,2.14,1.09.24,2.26.44,3.38.3.92-.11,1.94-.23,2.64-.9.96-.94.92-2.43.87-3.67-.06-1.48-.13-3.09.76-4.36.37-.53.84-.88,1.44-1.11-.05.02-.83.01.23-.03.21,0,.42,0,.63,0,.44,0,.88.02,1.31.02,1.68.03,3.36.08,5.05.14,1.46.05,3.02-.01,4.4.5,2.79,1.04,3.3,4.26,2.86,6.86-.25,1.48-.57,3.22-1.33,4.54-.3.52-.71.97-1.28,1.2h.13c-.15,0-.29,0-.44-.01-.22-.02-.45-.07-.67-.11-.45-.1-.9-.24-1.34-.37-3.6-1.04-7.15-2.27-10.62-3.67-.27-.11-.73.26-.99.16,3.18,1.28,6.42,2.42,9.7,3.4,1.38.41,2.72.9,4.17.67,1.09-.17,1.87-.56,2.4-1.57.65-1.25.95-2.79,1.21-4.16.27-1.46.33-2.99-.21-4.4-.48-1.27-1.41-2.27-2.71-2.72-1.44-.5-3.05-.43-4.56-.48-1.72-.06-3.45-.1-5.17-.14-1.14-.02-2.24,0-3.36.23-1.3.28-2.12,1.29-2.45,2.55-.41,1.56,0,3.17-.19,4.75-.14,1.15-.81,2.48-2.12,2.57-.6.04-1.24-.08-1.83-.17-.72-.12-1.45-.24-2.13-.53-2.82-1.19-1.81-4.52-3.03-6.74-1.58-2.88-5.21-3.42-8.08-4.16s-6.56-2.52-6.27-6.06c0,.07-.98.07-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m389.61,629.67c1.49-2.71,4.52-3.89,7.07-5.34,1.28-.73,2.53-1.56,3.46-2.72,1.09-1.36,1.45-3.66,3.35-4.18l-.14.03.18-.03c.16-.03.16-.03,0,0-.23.03-.47.07-.7.11-2.01.35-2.38,2.42-3.39,3.87-.88,1.26-2.15,2.16-3.46,2.93-2.62,1.54-5.81,2.69-7.35,5.5.1-.17.89.02.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m599.26,328.05c1.12.39,2.44.23,3.56-.11.69-.21,1.34-.59,1.85-1.1.24-.25.46-.53.63-.83.18-.33.31-.83.74-.92l-.97.16c.49.08.61.86.76,1.25.22.59.44,1.17.66,1.76,0,0,.94-.11.99-.16.25-.27.53-.49.85-.67.15-.08.3-.15.46-.2.08-.03.16-.05.24-.08-.35.04-.43.06-.24.05.56.07,1.1.09,1.66,0,.46-.07.98-.09,1.16-.6.21-.6-.11-1.31-.34-1.85-1.08-2.55-3.24-4.55-5.84-5.47-.28-.1-.72.26-.99.16,1.76.62,3.32,1.73,4.48,3.19.54.67,2.96,4.29,1.1,4.64l.92-.15c-.48.01-.95-.11-1.43-.06-.3.03-.6.09-.9.14-.83.14-1.54.44-2.13,1.06l.99-.16c-.22-.59-.44-1.17-.66-1.76-.14-.38-.27-1.21-.77-1.25-.26-.02-.61.08-.87.14-.4.08-.55.32-.73.68-.42.82-1.02,1.47-1.85,1.89-.22.11-.46.21-.69.29-.07.02-.37.15-.42.11.05.04,1-.08.15-.06-.48,0-.94-.08-1.39-.24-.28-.1-.71.26-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m477.04,303.6c.89,1.53,2.82,1.47,4.37,1.45,1.86-.03,3.73-.14,5.58-.33.89-.09,2.69-.09,2.69-1.33,0-.8-.49-1.67-.77-2.41-.2-.53-.38-1.59-1.12-1.55-.55.03-1.1.17-1.6.39-1,.46-1.99.92-2.98,1.38.29-.13.69-.02.99-.16,1.15-.53,2.3-1.19,3.49-1.6l-.24.04.16-.03-.74.12c.67.06.81.93,1.01,1.45.25.68.58,1.37.75,2.07.07.28.1.58,0,.85s-.32.34-.52.53l-.14.06c.25-.01.28-.01.1,0-.19.03-.38.04-.57.06-.48.05-.97.09-1.46.12-1.54.11-3.09.18-4.63.16-1.28-.02-2.68-.26-3.37-1.45-.06-.11-.93.26-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m491.92,555.15l-5.6,1.22c-.85.19-1.71.37-2.56.56-.62.14-1.3.26-1.81.69-1.13.96-.69,2.88-.55,4.14.18,1.66.31,3.42.76,5.03.42,1.5,1.51,2.21,3.02,2.41s3.19.1,4.74-.13c2.27-.34,5.72-.77,6.8-3.14.77-1.68-.12-3.56-.92-5.04s-1.92-3.42-3.93-3.27c-.33.02-.66.14-.99.16,1.57-.11,2.66,1.08,3.4,2.32s1.48,2.56,1.7,3.98c.48,3.21-2.92,4.45-5.48,4.89-1.53.26-3.28.31-4.81,0-1.4-.28-2.25-1.14-2.59-2.52-.36-1.46-.5-3-.67-4.49-.14-1.23-.57-2.98.16-4.1.41-.63,1.13-.85,1.82-1.01.95-.22,1.91-.41,2.86-.62l5.6-1.22c-.23.05-.66.09-.95.15h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m504.66,568.5c1.13-1.29,2.47-2.39,3.96-3.24s3.24-1.62,4.92-1.7c.81-.04,1.63.12,2.18.77.51.61.67,1.46.65,2.23-.03,1.71-.83,3.31-2.13,4.41.21-.18.77.03.99-.16,1.46-1.24,2.37-3.22,2.08-5.16-.13-.92-.6-1.79-1.52-2.11-.79-.28-1.66-.13-2.46,0-3.75.59-7.13,2.22-9.66,5.11.17-.19.82.04.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m534.76,580.12l5.85-.37,2.68-.17c.84-.05,1.86-.27,2.67.03.69.25,1.07.91,1.32,1.56.33.84.59,1.73.87,2.59.57,1.76,1.16,3.52,1.6,5.31.41,1.7.54,3.47-.34,5.05-.72,1.29-1.91,2.26-3.21,2.94-1.53.81-3.26,1.32-4.94,1.75-.16.04-.45.16-.61.14l.32-.05c-.2.02-.4.04-.61.04-.41,0-.82-.04-1.2-.17-.75-.25-1.33-.78-1.76-1.43-.99-1.5-1.39-3.52-.91-5.27.21-.77.61-1.48,1.15-2.07.28-.3.6-.57.96-.78.15-.09.31-.18.47-.25.14-.06.86-.26.21-.17.3-.04.72-.13.95-.16-1.27.17-2.43.41-3.39,1.33-1.3,1.24-1.73,3.08-1.45,4.82.27,1.65,1.13,3.65,2.88,4.16,1.21.35,2.67.02,3.87-.28,3.24-.81,7.5-2.1,8.66-5.65.64-1.96-.01-4.03-.58-5.93-.6-2.01-1.18-4.05-1.93-6.01-.32-.84-.81-1.59-1.76-1.74-1.11-.17-2.38.08-3.49.15l-7.32.46c-.33.02-.66.14-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m543.58,596.25c-.16-2.54-.71-5.04-1.63-7.41-.03-.07-.96.22-.99.16.92,2.37,1.48,4.87,1.63,7.41,0-.03.99-.12.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m457.19,617.49c-.75,1.23-.56,2.76.2,3.95s2.1,1.97,3.42,2.48c2.98,1.16,6.29,1.55,9.47,1.32,2.69-.2,5.41-.75,7.9-1.77.59-.24,1.3-.46,1.79-.88.59-.5.43-1.18-.06-1.68s-1.21-.78-1.86-1.05c-.77-.32-1.55-.6-2.34-.84-3.61-1.12-7.43-1.46-11.19-1.04-.3.03-.72.13-.95.16,3.14-.35,6.33-.16,9.41.57,1.47.35,2.9.82,4.29,1.4.8.34,2.73,1.21,1.94,2.4-.34.51-1.09.74-1.62.96-.69.3-1.4.56-2.13.79-1.65.52-3.33.81-5.07.88-2.84.1-5.75-.3-8.42-1.29-1.38-.51-2.79-1.28-3.6-2.55-.75-1.18-.95-2.72-.2-3.95-.11.18-.87-.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m502.48,618.27c4.81-.43,9.61-.86,14.42-1.28l1.74-.15c.39-.04,1.29-.3,1.58.06.41.5-.98,1.72-1.31,2.05-1.24,1.22-2.73,2.15-4.33,2.83-3.77,1.61-7.82,1.65-11.85,1.32-2.32-.19-4.63-.49-6.95-.69-1.16-.1-2.31-.18-3.47-.2-.56-.01-1.12-.01-1.68,0-.28,0-.56.02-.84.03-.11,0-.25.04-.36.02-.26-.04-.27.05-.06-.19,1.32-1.51,3.06-2.64,4.94-3.32s4.03-.72,6.12-.18c.3.08.7-.24.99-.16-3.45-.9-7.59-.2-10.58,1.72-.86.55-1.64,1.21-2.33,1.96-.07.08-.22.18-.24.28-.04.18.13.06.3.05.32-.02.64-.03.96-.04.68-.02,1.36-.02,2.04,0,2.65.07,5.28.4,7.91.67,4.18.43,8.27.56,12.4-.29,1.91-.39,3.78-1.04,5.47-2.02.82-.48,1.6-1.05,2.3-1.69.35-.33,2.1-1.85,1.52-2.35-.33-.28-1.22-.03-1.57,0l-2.24.2-4.6.41c-3.11.28-6.21.55-9.32.83-.3.03-.72.14-.95.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m427.47,560.56c.17-.17.36-.32.57-.45.17-.1.42-.15.58-.26s-.21-.07-.04.01c.09.04.31,0,.41.01.25.02.49.07.73.14,1,.28,1.83.9,2.44,1.74,1.41,1.97,1.58,4.66,1.73,7,.18,2.78.11,5.58.04,8.36-.06,2.08-.14,4.69-1.82,6.19-.34.3-.73.48-1.14.65-.05.02-.34.09-.12.05s-.15,0-.2,0c-.23,0-.45-.03-.68-.08-.87-.19-1.58-.74-1.82-1.61-.01-.04-.98.19-.99.16.53,1.91,2.7,1.78,4.25,1.46,2.49-.51,3.24-3.09,3.43-5.33.26-3.14.24-6.39.07-9.54-.15-2.69-.28-6.12-2.32-8.15-.87-.86-2.14-1.33-3.36-1.2-.99.1-2,.27-2.74.99.19-.19.79.03.99-.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m439.02,720.01c1.56,4.27,6.05,7.19,10.6,6.65,1.94-.23,3.8-.74,5.44-1.83.93-.62,1.77-1.39,2.46-2.28.35-.45.67-.94.93-1.45.06-.11.19-.29.2-.41.02-.24.24-.09-.16-.17-.36-.07-.78-.02-1.15-.04-3.31-.18-6.57-.99-9.59-2.35-.26-.12-.74.27-.99.16,2.43,1.09,5,1.83,7.64,2.17.65.08,1.3.14,1.95.18.31.02.62.03.92.03-.03,0,.46,0,.46,0,.02.05-.21.44-.23.49-.47.92-1.09,1.76-1.81,2.48s-1.57,1.35-2.48,1.84c-.45.24-.93.46-1.41.63-.26.09-.52.18-.78.25-.17.05-.34.08-.51.13-.41.1.22.03-.03,0-.55-.05-1.14.07-1.7.03s-1.07-.13-1.59-.26c-.99-.25-1.93-.64-2.8-1.17-2.01-1.21-3.58-3.06-4.38-5.27-.02-.07-.97.21-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m477.73,721.83c2.83,4.24,7.75,6.84,12.86,6.64,2.14-.08,4.37-.53,6.34-1.37.56-.24,1.11-.52,1.64-.83.29-.17.58-.35.86-.55.06-.04.28-.16.33-.24.23-.1.22-.17-.05-.22-.11-.05-.21-.09-.32-.14-1.37-.61-2.77-1.15-4.19-1.63-3.21-1.07-6.54-1.79-9.9-2.16-.32-.04-.67.19-.99.16,2.72.3,5.4.82,8.03,1.57,1.29.37,2.57.8,3.83,1.28.61.23,1.22.48,1.82.74.1.04.95.36.95.42,0,.1-.66.47-.75.53-2.15,1.41-4.65,2.27-7.23,2.26-2.29,0-4.56-.57-6.6-1.61-2.28-1.17-4.23-2.91-5.65-5.04-.08-.12-.91.27-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m480.67,664.45c.82,1.31,1.67,2.67,2.91,3.63,1.05.81,2.21,1.14,3.52.94,1.11-.17,2.27-.38,3.02-1.31.88-1.1.55-2.58-.36-3.54-1.38-1.47-3.52-1.51-5.39-1.52-.33,0-.66.16-.99.16,1.54,0,3.22,0,4.57.85,1.07.67,1.94,1.91,1.66,3.22-.22,1.05-1.14,1.75-2.12,2.04-.09.03-.23.04-.3.08,0,0,.78-.08.42-.08-.14,0-.29.02-.43.01-.29,0-.58-.04-.86-.11-.62-.15-1.19-.48-1.69-.86-1.27-.96-2.14-2.34-2.97-3.67-.07-.11-.92.26-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m412.65,749.91c-2,1.92-1.52,5.05-.04,7.14,1.93,2.71,5.47,3.37,8.6,3.47,1.76.05,3.56.01,5.31-.14,1.35-.12,2.79-.31,4.05-.82,1.36-.55,2.64-1.59,3.15-3s.05-2.97-.89-4.13c-2.6-3.21-7.35-3.54-11.17-3.55-.33,0-.66.16-.99.16,3.3,0,7.06.24,9.82,2.27,1.15.85,2.17,2.05,2.41,3.49s-.35,2.72-1.34,3.67c-.48.46-1.04.83-1.64,1.11-1.02.47-1.83.6-2.87.67-1.64.11-3.31.16-4.96.1-2.95-.1-6.3-.7-8.25-3.14-1.67-2.09-2.32-5.44-.21-7.46-.19.19-.79-.03-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m442.13,755.46c-2.09,1-4.68,3.21-3.51,5.81.48,1.07,1.5,1.83,2.59,2.21,1.2.43,2.41.3,3.63.1,1.62-.27,3.11-.88,4.6-1.55-.29.13-.69.03-.99.16-.84.38-1.69.76-2.57,1.06s-1.56.45-2.52.34c-1.29-.14-2.6-.79-3.38-1.85s-.82-2.52-.14-3.7c.73-1.25,2-2.12,3.28-2.74-.29.14-.7.02-.99.16h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m491.7,285.45l-6.79,2.05c.31-.09.67-.1.99-.15s.67-.08.99-.17l6.79-2.05c-.31.09-.67.1-.99.15s-.67.08-.99.17h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m489,280.98c-.2.05-.86.1-1.03.23l.51-.07c-.15.02-.29.04-.44.06-.14.02-.29.03-.43.05-.68.07-1.36.11-2.04.12-1.28.02-2.55-.06-3.82-.24-2.48-.35-4.95-1.06-7.2-2.18-.87-.43-1.84-1.04-2.24-1.98-.37-.88.01-1.79.77-2.3.35-.24.76-.4,1.17-.52.98-.28-.98.12-.96.12.17,0,.34-.04.51-.05.37-.03.74-.05,1.12-.06,1.27-.04,2.54.02,3.8.18,2.89.36,5.7,1.22,8.31,2.53.18.09.79-.12.99-.15s.81-.26.99-.17c-2.8-1.41-5.85-2.3-8.98-2.6-1.49-.14-3-.16-4.49-.03-1.12.1-2.33.25-3.41.59s-2.08,1.08-1.91,2.3,1.52,2.05,2.56,2.55c2.7,1.28,5.65,2.04,8.63,2.26,3.2.24,6.47-.2,9.58-.96-.64.16-1.33.16-1.97.32h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m519.09,289.42c-2.59-1.25-5.18-2.51-7.77-3.76-.04-.02-.28.04-.29.04-.23.04-.47.07-.7.11s-.47.08-.7.12c-.06,0-.24.08-.29.05,2.59,1.25,5.18,2.51,7.77,3.76.04.02.28-.04.29-.04.23-.04.47-.07.7-.11s.47-.08.7-.12c.06,0,.24-.08.29-.05h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m507.32,271.25l5.22,3.65s.3-.04.29-.04c.23-.04.47-.07.7-.11s.47-.08.7-.12c.05,0,.26-.07.29-.05l-5.22-3.65s-.3.04-.29.04c-.23.04-.47.07-.7.11s-.47.08-.7.12c-.05,0-.26.07-.29.05h0Z" />
      </g>
      <g style={styles.three}>
        <path d="m512.95,274.44l-.76,7.63s.91-.14.99-.15c.08-.01.98-.11.99-.17l.76-7.63s-.91.14-.99.15c-.08.01-.98.11-.99.17h0Z" />
      </g>
    </g>
  );
};

export default Rex;
