import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import {
  cardExtraLight,
  cardLight,
  cardVeryLight,
  red,
  text,
} from "../../utils/themeContstants";
import Hover from "./Hover";

const CircleIconButton = ({
  children,
  selected,
  onClick = () => {},
  label,
  badgeCount = 0,
  size,
  wallet = false,
  elId = null,
}) => {
  const [hovered, setHovered] = useState(false);
  const [ref, setRef] = useState(null);

  const getWidth = () => {
    if (badgeCount < 10) {
      return 19;
    }

    if (badgeCount >= 10 && badgeCount < 100) {
      return 24;
    }

    if (badgeCount >= 100) {
      return 31;
    }
  };

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      height: size === "small" ? 36 : 40,
      width: size === "small" ? 36 : 40,
      borderRadius: 100,
      backgroundColor: !selected ? cardLight : "rgba(51, 144, 255, .2)",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: !selected ? cardVeryLight : "rgba(71, 155, 255, .3)",
      },
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={setRef}
      onClick={(e) => {
        setHovered(false);
        onClick(e);
      }}
      id={elId}
    >
      {badgeCount > 0 && (
        <Grid
          item
          sx={{
            height: 19,
            width: getWidth(),
            backgroundColor: red,
            borderRadius: 100,
            position: "absolute",
            top: -4,
            right: -4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: 13,
              fontWeight: 600,
              color: text,
              textAlign: "center",
              marginTop: 0.2,
            }}
          >
            {badgeCount > 99 ? "99+" : badgeCount}
          </Typography>
        </Grid>
      )}
      {hovered && label && (
        <Hover label={label} placement="bottom" refEl={ref} wallet={wallet} />
      )}
      {children}
    </Grid>
  );
};

export default CircleIconButton;
