import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { updateChargeback, updateWithdrawal } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { TransactionStatusTypeEnum } from "../../utils/enums";
import { numFormatter } from "../../utils/helpers";
import { cardLight } from "../../utils/themeContstants";
import CustomModal from "../custom/CustomModal";
import PrimaryButton from "../custom/PrimaryButton";
import RadioListItem from "../custom/RadioListItem";
import SecondaryButton from "../custom/SecondaryButton";

const MarkChargebackModal = ({
  chargeback,
  open,
  onClose,
  chargebacks,
  setChargebacks,
}) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();

  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleClose = () => {
    setLoading(false);
    setSelectedStatus(chargeback?.status);
    onClose();
  };

  const handleUpdate = () => {
    setLoading(true);
    updateChargeback(middleware, chargeback?._id, selectedStatus).then(
      (res) => {
        setLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
          setSelectedStatus(chargeback?.status);
        } else {
          const index = chargebacks?.findIndex(
            (w) => w?._id === chargeback?._id
          );
          let newChargebacks = chargebacks;
          newChargebacks?.splice(index, 1);
          setChargebacks([...newChargebacks]);
          updateStore({
            type: SET_SUCCESSES,
            payload: "Updated chargeback!",
          });
          handleClose();
        }
      }
    );
  };

  useEffect(() => {
    setSelectedStatus(chargeback?.status);
  }, [chargeback]);

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Updating ${
        chargeback?.username
      }'s chargeback   for $${numFormatter().format(chargeback?.amount)}`}
      primaryButton={
        <PrimaryButton
          label="Update"
          type="green"
          disabled={selectedStatus === chargeback?.status}
          onClick={handleUpdate}
          loading={loading}
        />
      }
      secondaryButton={<SecondaryButton label="Close" onClick={handleClose} />}
      backgroundColor={cardLight}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 0.5, width: "100%" }}
      >
        <Grid item sx={styles.width}>
          <RadioListItem
            title={"Responded On Paypal"}
            value={TransactionStatusTypeEnum.COMPLETED}
            selected={selectedStatus === TransactionStatusTypeEnum.COMPLETED}
            onClick={() =>
              setSelectedStatus(TransactionStatusTypeEnum.COMPLETED)
            }
          />
        </Grid>

        <Grid item sx={styles.width}>
          <RadioListItem
            title={"Canceled By User"}
            value={TransactionStatusTypeEnum.CANCELED}
            selected={selectedStatus === TransactionStatusTypeEnum.CANCELED}
            onClick={() =>
              setSelectedStatus(TransactionStatusTypeEnum.CANCELED)
            }
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default MarkChargebackModal;
