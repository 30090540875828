import Angry from "./types/Angry";
import Confused from "./types/Confused";
import Default from "./types/Default";
import Sad from "./types/Sad";
import Worry from "./types/Worry";

const Eyebrows = ({ type }) => {
  switch (type) {
    case "sad":
      return <Sad />;
    case "angry":
      return <Angry />;
    case "default":
      return <Default />;
    case "worry":
      return <Worry />;
    case "confused":
      return <Confused />;
    default:
      return null;
  }
};

export default Eyebrows;
