import { Grid } from "@mui/material";
import { MatchStateEnum } from "../../utils/enums";
import MatchCancelState from "./MatchCancelState";
import MatchReadyState from "./MatchReadyState";
import MatchWaitingState from "./MatchWaitingState";
import MatchPlayingState from "./MatchPlayingState";

const MatchTeams = ({ token }) => {
  const styles = {
    container: {
      width: "100%",
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        {token?.state === MatchStateEnum.WAITING && (
          <MatchWaitingState token={token} />
        )}

        {token?.state === MatchStateEnum.CANCEL && (
          <MatchCancelState token={token} />
        )}

        {token?.state === MatchStateEnum.READY && (
          <MatchReadyState token={token} />
        )}

        {(token?.state === MatchStateEnum.PLAYING ||
          token?.state === MatchStateEnum.DISPUTE ||
          token?.state === MatchStateEnum.SUBMIT ||
          token?.state === MatchStateEnum.COMPLETED) && (
          <MatchPlayingState token={token} />
        )}
      </Grid>
    </Grid>
  );
};

export default MatchTeams;
