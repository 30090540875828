import axios from "axios";
import { API_ROUTE, errMessage } from "../utils/constants";

export const getUserLeaderboard = async (skip) => {
  const params = { skip };
  return await axios
    .get(`${API_ROUTE}/leaderboard/users`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
