const Sakonji = () => {
  const styles = {
    one: {
      fill: "#d24c40",
    },
    two: {
      fill: "#7b3130",
    },
    three: {
      fill: "#28241b",
    },
    four: {
      fill: "#e1c8bb",
    },
    five: {
      fill: "#170a09",
    },
    six: {
      fill: "#d1b6ae",
    },
    seven: {
      fill: "#e6cabd",
    },
    eight: {
      fill: "#311415",
    },
    nine: {
      fill: "#361f17",
    },
    ten: {
      fill: "#4e3326",
    },
    eleven: {
      fill: "#201210",
    },
    twelve: {
      fill: "#34171c",
    },
    thirteen: {
      fill: "#715957",
    },
    fourteen: {
      fill: "#1a0709",
    },
    fifteen: {
      fill: "#fefbf7",
    },
    sixteen: {
      fill: "#3e2119",
    },
    seventeen: {
      fill: "#1f1810",
    },
    eighteen: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    nineteen: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    twenty: {
      fill: "#310d0f",
    },
    twentyOne: {
      fill: "#5a3a2f",
    },
    twentyTwo: {
      fill: "#735b5a",
    },
    twentyThree: {
      fill: "#50151d",
    },
    twentyFour: {
      fill: "#9a3433",
    },
    twentyFive: {
      fill: "#fdf3ef",
    },
    twentySix: {
      fill: "#301f18",
    },
    twentySeven: {
      fill: "#281114",
    },
    twentyEight: {
      fill: "#1e100d",
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="m552.74,539.16c10.88-1.33,17.28-1.57,21.31-1.34,1.25.07,4.33.31,7.72-1.18,2.09-.91,3.56-2.16,4.45-3.03,4.73-3.8,11.24-9.52,18.1-17.32,3.47-3.95,7.24-8.67,14.8-19.9,5.41-8.04,12.51-19.06,20.4-32.71,20.16.29,32.92-11.14,32.55-20.03-.26-6.36-7.28-12.34-15.73-15.78,2.92-4.06,8.03-12.38,9.26-24,.69-6.55-.06-12.11-.95-16.04-.05-26.12,2.12-37.53,4.47-42.79.63-1.4,3.42-7.19,3.02-14.77-.12-2.2-.47-3.99-.76-5.18-5.01-10.41-10.03-20.82-15.04-31.24-1.16-3.09-7.53-19.21-23.87-24.98-5.17-1.82-9.96-2.12-13.63-1.95-13.78-1.4-25.11-1.7-33.07-1.7-9.56,0-20.68.61-42.52,1.83-7.57.42-13.82.8-18.06,1.06-8.94,37.61-17.87,75.22-26.81,112.82l33.02,119.07,21.33,39.16Z"
      />
      <path
        style={styles.two}
        d="m647.04,360.04c.86-.97,2.83-3.45,3.34-7.2.71-5.18-1.88-9-2.51-9.88-2.98,2.04-5.96,4.09-8.95,6.13l8.11,10.95Z"
      />
      <polygon
        style={styles.twentySix}
        points="412.65 311.85 401.6 352.63 375.44 351.89 380.41 312.56 412.65 311.85"
      />
      <path
        style={styles.fifteen}
        d="m581.22,368.9l1.2,6.36v4.76l9.17,9.4c28.98,8.33,52.31-2.1,56.15-15.65,3.02-10.66-6.32-22.18-9.03-25.32-19.17,6.82-38.33,13.63-57.5,20.45Z"
      />
      <path
        style={styles.ten}
        d="m622.69,304.48c.23.69,1.8,5.21,6.66,7.27,5.16,2.19,9.76-.19,10.34-.51-.22,2.29.85,4.52,2.77,5.79,1.94,1.27,4.43,1.36,6.46.23-.83,2.7.12,5.63,2.35,7.3,2.15,1.62,5.09,1.77,7.43.39-.02.29-.11,2.42,1.57,4.02,1.82,1.72,4.15,1.34,4.4,1.29-16.24,14.02-39.48,29.54-69.96,37.56-4.95,1.3-9.78,2.32-14.46,3.11l-.62-19.09c8.17-3.86,22.9-12.32,33.98-28.95,4.4-6.61,7.23-13.01,9.09-18.42Z"
      />
      <path
        style={styles.twentyOne}
        d="m594.52,367.02c.01,1.22.24,9.14,6.78,14.67,7.7,6.52,17.24,3.87,18.28,3.56,1.65-.49,10.8-3.2,12.89-11.91,1.49-6.19-1.18-12.97-6.75-17.15-10.4,3.61-20.81,7.21-31.21,10.82Z"
      />
      <path
        style={styles.three}
        d="m557.27,506.01c-.38-1.28-.81-3.57.28-5.58,1.83-3.36,7.38-4.9,13.17-2.56,3.98.94,9.33,1.78,15.65,1.61,7.66-.21,13.92-1.82,18.25-3.31l-10,6.83,6.04.59c-.2.81-.52,1.41-.82,1.84-2.08,2.98-6.55,2.66-7.01,2.62.34.93.94,2.22,2.01,3.53,1.54,1.88,3.28,2.87,4.32,3.36-4.65-.24-9.3-.48-13.96-.72l-.54,1.93,6.02,4.53c-3.89-.91-8.16-2.16-12.68-3.87-8.48-3.22-15.39-7.17-20.73-10.79Z"
      />
      <path
        style={styles.four}
        d="m584.35,381.57c.66-1.17,1.84-2.89,3.78-4.43,3.14-2.49,6.45-3.05,7.95-3.21-.52-2.3-1.04-4.6-1.56-6.9l-12,3.59v4.17l-.67,3.13,2.49,3.65Z"
      />
      <path
        style={styles.seven}
        d="m629.66,361.48c1.84-.74,4.81-1.66,8.55-1.7,4.66-.04,8.24,1.32,10.17,2.22-3.03-4.38-6.06-8.76-9.09-13.14l-13.58,7.34,3.93,5.28Z"
      />
      <path
        style={styles.nine}
        d="m596.08,373.92c3.36-3.13,10.38-8.76,20.81-11.01,5-1.08,9.41-1.08,12.77-.79-1.73-1.77-3.47-3.54-5.2-5.3l-29.94,10.21,1.56,6.9Z"
      />
      <path
        style={styles.seventeen}
        d="m403.64,346.13c-12.33.85-16.99-1.76-18.73-4.74-2.23-3.82-.51-9.71-3.16-10.86-1.21-.52-2.7.21-3.77.91,11.13.36,19.16.6,21.28.65,1.27.04,2.55-.13,3.82-.05,1.84.12,3.36.44,4.16-.44.19-.21.29-.44.34-.59-6.02-.66-10.88-1.12-14.14-1.42-8.08-.74-11.87-.95-13.11-3.42-1.17-2.34.53-5.52.49-5.53-.36-.1-4.93,7.4-2.84,9.68,2.1,2.3,11.01-.65,11.99-4.99.79-3.48-3.9-6.13-2.84-9.52.71-2.25,3.49-3.38,5.65-3.98-4.12.24-8.24.47-12.36.71l-4.97,39.33,25.91,1.54,2.3-7.29Z"
      />
      <path
        style={styles.twentyThree}
        d="m555,266.83c-1.29,1.12-5.86,5.36-7.04,12.61-1.23,7.52,2.02,13.17,2.89,14.58,3.39-2.48,12.9-8.76,26.59-9.1,11.97-.29,20.8,4.12,24.52,6.26-3.39-.38-7.53-.66-12.26-.59-7.05.11-11.07.2-15.66,2.16-2.49,1.06-6.05,3.05-9.55,6.98.57-.42,4.13-3,8.77-2.02,3.19.67,5.13,2.66,5.8,3.42-1.18,2.99-3.11,6.83-6.33,10.66-7.02,8.35-15.79,11.44-19.81,12.58,5.79-4.13,16.16-10.34,30.44-12.9,8.59-1.54,16.06-1.32,21.62-.68-5.9.2-14.38,1.42-22.5,6.75-2.42,1.59-3.44,2.65-5.84,4.43-4.78,3.58-12.29,8.03-23.73,11,.46,2.92.8,6.2.9,9.8.15,5.36-.28,10.12-.9,14.1-16.8,4.47-19.85,6.31-19.71,7.17.04.21.34.78,9.07,1.04,11.49.35,17.34.47,22.63-2.02,1.89-.89,4.76-2.53,7.77-5.59-.61,1.47-1.35,3.93-.53,6.44,1.47,4.49,6.98,6.06,6.59,7.24-.35,1.05-4.73-.24-8.74.26-3.98.5-9.29,2.97-15,11.73,2.22,0,4.01-.13,5.18-.25,3.67-.36,4.98-.88,9.13-1.32,1.78-.19,3.02-.25,4.76-.34,6.81-.34,8.33.19,9.2.59.92.42,2.29,1.23,3.64,2.91-5.49,1.09-9.77,2.44-12.64,3.45-4.34,1.54-7.05,2.88-11.45,3.33-3.31.34-6.06.02-7.83-.29-1.95-.49-3.9-.97-5.86-1.46.87,1.39,2.46,4.34,2.73,8.4.31,4.68-1.31,8.19-2.09,9.65,29.91,10.94,59.83,21.89,89.74,32.83,5.03,2.12,8.38,6.65,8.35,11.25-.05,6.36-6.57,12.57-15,12-6.79.22-10.23-.39-12.13-1.04-.87-.3-3.27-1.25-4.98-.28-.33.19-.78.53-1.57.81,0,0-1.12.39-2.44.32-1.68-.08-22.91-7.12-51.78-17.16-2.87,2.27-7.22,6.29-10.57,12.52-2.74,5.1-6.28,11.69-3.86,16.92,2.45,5.29,8.73,4.28,17.29,10.21,5.89,4.07,9.51,9.11,11.65,12.73-4.81-2.09-10.33-1.24-14,2.14-4.6,4.24-3.99,10.31-3.91,10.96-.28,1.31-1.96,9.87,3.68,17.25,4.76,6.23,12.89,9.21,20.97,7.75-12.25,1.57-26.67,2.55-42.78,2-12.87-.44-24.54-1.79-34.77-3.51-7.97-3.71-19.13-9.65-31.1-18.8-54.07-41.33-65.56-103.03-67.57-115.17,0,0-5.44-32.89,2.23-71.14,2.74-13.65,7.83-24.31,7.83-24.31,2.52-5.27,5.08-9.38,6.88-12.07,3.66-4.77,9.87-11.68,19.32-17.31,11.96-7.14,23.3-8.89,29.48-9.39,28.09-.16,56.17-.32,84.26-.48Z"
      />
      <path
        style={styles.twentyFive}
        d="m486.13,388.83c.21.75.56,1.29.98,1.67,4.97,4.53,23.93-9.18,27.07-11.47-1.17-2.07-2.35-4.14-3.52-6.2l-13.57-.78c-.47.95-1.03,1.94-1.68,2.95-2.28,3.51-4.95,5.98-7.19,7.67-.7,2.05-1.39,4.11-2.09,6.16Z"
      />
      <path
        style={styles.eight}
        d="m443.09,364.09c-1.19-2.54-2.92-7.09-2.93-12.98,0-4.2.86-7.63,1.65-9.94,4.45,2.66,8.9,5.33,13.35,7.99-1.94,1.55-4.21,3.63-6.46,6.33-2.6,3.13-4.39,6.15-5.61,8.61Z"
      />
      <path
        style={styles.eleven}
        d="m526.57,350.91c3.88,1.98,6.2,6.07,5.87,10.3-.36,4.63-3.83,8.55-8.52,9.55-4.18-.11-10.03-.47-16.92-1.59-17.77-2.89-30.35-8.87-39.78-13.43-11.17-5.41-26.68-14.25-43.17-28.43,2.4,1.55,5.59.84,7.04-1.43,1.34-2.09.87-5-1.17-6.65,2.38,2.86,6.41,3.5,9.26,1.7,2.37-1.5,3.59-4.48,3-7.43,1.81,3.35,5.97,4.36,8.74,2.61,2.32-1.46,3.35-4.67,2.22-7.57.33.29,2.77,2.3,6,1.7,2.79-.52,5.06-2.78,5.74-5.74,1.66,4.25,3.86,8.67,6.78,13.04,16.36,24.51,44.34,31.38,54.91,33.39Z"
      />
      <path
        style={styles.twentySeven}
        d="m497.09,373.09c-1.12,1.4-7.96,9.73-18.78,9.9-9.36.14-21.34-5.83-21.56-14.66-.19-7.71,8.7-13.12,9.55-13.63,5.03,2.68,10.66,5.39,16.87,7.95,5.24,2.16,10.24,3.95,14.94,5.45-.34,1.66-.68,3.33-1.02,4.99Z"
      />
      <path
        style={styles.twenty}
        d="m500.93,486.17c6.7.16,15.89-.58,25.77-4.57,2.69-1.08,5.13-2.29,7.32-3.52,1.75-.75,3.84-1.44,6.25-1.83,8.9-1.43,16.16,2.17,19.17,3.91,3.27,2.02,7.64,4.28,13.04,6,7.9,2.51,14.86,2.88,19.65,2.74-.86.69-2.3,1.73-4.26,2.61-10.7,4.81-24.06-.91-25.82-1.69-4.69-2.08-8.13-4.67-13.17-3.78-1.77.31-3.16.95-4.04,1.43-1.16-.51-2.96-1.2-5.23-1.65-9.03-1.79-14.47,2.44-22.96,3.15-3.71.31-9.03.03-15.71-2.8Z"
      />
      <path
        style={styles.five}
        d="m536.22,444.7c-11.78.69-20.74-1.07-26.71-2.79-9.45-2.71-14.44-6.19-30.38-13.89-9.24-4.47-16.89-7.81-21.83-9.9,2.68,2.34,4.38,5.18,3.63,6.87-.91,2.05-5.68,2.96-11.46.54.7.59,1.64,1.42,2.71,2.45,3.33,3.22,5.03,4.86,4.74,6.07-.39,1.63-4.4,2.37-9.54,2.18,4.12.77,6.13,4.19,5.5,6.14-.56,1.76-3.54,3.2-6.94,2.34.19.3.51.78.95,1.32,3.5,4.38,8.59,5.73,10.91,6.31,22.56,5.67,50.33.1,50.33.1,7.15-1.43,16.83-3.77,28.08-7.73Z"
      />
      <path
        style={styles.eight}
        d="m513.16,382.18c2.64-1.22,4.64-1.23,5.92-1.04,2.59.38,3.66,1.75,6.85,2.74,2.27.71,4.25.8,5.48.78-.63-1.19-2.2-3.73-5.28-5.48-4.85-2.75-9.73-1.46-10.73-1.17-.75,1.39-1.49,2.78-2.24,4.17Z"
      />
      <path
        style={styles.eight}
        d="m504.13,387.02c2.91-.05,4.74.74,5.84,1.43,2.22,1.39,2.65,3.07,5.16,5.27,1.79,1.56,3.56,2.44,4.7,2.92-.1-1.35-.51-4.3-2.63-7.14-3.32-4.47-8.31-5.26-9.35-5.4-1.24.97-2.49,1.94-3.73,2.92Z"
      />
      <path
        style={styles.sixteen}
        d="m520.58,349.35c.57.87,1.58,2.68,1.7,5.15.2,4.06-2.13,6.86-2.69,7.5-2.86-2.63-6.89-5.81-12.2-8.61-6.38-3.37-12.26-4.95-16.5-5.74-1.87-2.11-2.33-4.94-1.19-7.04.9-1.67,2.51-2.34,3.08-2.54,4.09,2.22,8.78,4.49,14.04,6.6,4.9,1.97,9.53,3.49,13.76,4.68Z"
      />
      <path
        style={styles.twentyEight}
        d="m459.03,360.4c4.96.25,11.21,1.01,18.2,3,8.41,2.39,15.08,5.76,19.86,8.64.34-1.32.68-2.63,1.02-3.95-5.46-1.73-11.36-3.88-17.56-6.55-4.81-2.08-9.26-4.24-13.33-6.4-2.73,1.75-5.46,3.51-8.2,5.26Z"
      />
      <path
        style={styles.thirteen}
        d="m497.09,372.04c2.59.21,6.17.78,10.12,2.36,2.88,1.15,5.21,2.54,6.96,3.77l-1.04-4.25-.14-3.15-14.89-1.87-1.02,3.14Z"
      />
      <path
        style={styles.six}
        d="m494.15,376.71c.13,1.6.2,5.28-1.86,9.2-1.46,2.77-3.39,4.52-4.6,5.45-2.82.42-7.52.9-13.24.33-11.28-1.13-30.43-6.75-32.68-18.65-.33-1.77-.51-4.79,1.31-8.94,1.32-1.14,4.58-3.66,9.46-4.3,3.2-.43,5.84.12,7.41.59-1.47,1.85-3.48,5.03-3.17,8.74.7,8.16,12.26,14.08,21.78,13.88,7.67-.16,13.23-4.27,15.59-6.28Z"
      />
      <path
        style={styles.twentyTwo}
        d="m459.95,360.37c-2.34.06-5.17.31-8.32.98-3.41.73-6.28,1.75-8.54,2.74,1.17-2.29,2.81-5.04,5.09-7.89,2.43-3.04,4.9-5.35,6.98-7.04,4.02,2,8.05,3.99,12.07,5.99l-7.28,5.23Z"
      />
      <path
        style={styles.fourteen}
        d="m481.17,488.65c4.92,2.73,12,5.83,20.9,7.21,20.58,3.18,36.85-5.01,42.75-8.38,3.14,1.86,6.71,3.77,10.71,5.59,4.41,2,8.57,3.55,12.34,4.75-.72.06-3.87.4-6.35,3.1-2.33,2.53-2.52,5.46-2.54,6.23l-14.57-10.97c-5.09,4.41-15.19,11.92-29.97,15.44-9.91,2.36-18.47,2.1-24.26,1.43,2.44-.58,5.06-1.36,5.01-2.07-.06-.81-3.54-.83-7.19-1.96-3.18-.98-6.81-2.9-10.47-6.67,7.36.04,9.19-.42,9.24-.91.09-.8-4.55-1.18-7.88-4.97-1.3-1.47-2-3.02-2.41-4.16,2.82,1.55,8.75,4.64,9.6,3.51.74-.98-2.57-4.71-4.91-7.16Z"
      />
      <path
        style={styles.twenty}
        d="m556.03,446.51c4.1-.64,7.44-.8,9.64-.83,0,0,4.27-.07,17.27,1.32h0c3.96,3.06,9.77,6.86,17.41,9.78,6.05,2.31,11.53,3.42,15.85,3.96.25.46.27.81.2,1.08-.59,2.07-7.81,1.6-9.46,1.48-9.71-3.51-19.42-7.02-29.13-10.53-3.29-1.74-7.65-3.62-12.96-4.89-3.23-.77-6.21-1.18-8.8-1.37Z"
      />
      <path
        style={styles.twelve}
        d="m589.14,369.25c-.28-.15-6.43-3.65-6.65-10.74-.15-4.75,2.43-9.22,6.65-11.53-4.51,2.15-9.02,4.29-13.53,6.44-3.54,3.51-4.66,8.54-2.9,12.23,2.38,5.02,10.03,7.49,16.43,3.59Z"
      />
      <path
        style={styles.eight}
        d="m416.16,303.09c-5.53,19.17-19.06,75.56,5.99,138.47,15.8,39.69,40.36,65.4,55.37,78.83-19.02-13.08-61.78-46.66-76.47-103.77-3.29-12.78-4.68-24.83-4.97-35.69,2.71-14.63,6.17-30.18,10.6-46.48,2.99-10.99,6.17-21.45,9.47-31.35Z"
      />
      <polygon
        style={styles.nineteen}
        points="380.24 313.21 375.44 351.89 402 353.42 412.27 311.85 380.24 313.21"
      />
      <path
        style={styles.twenty}
        d="m501.35,418.11c.06.57.19,1.42.5,2.4,1.31,4.15,5.19,8.57,26.83,15.08,21.86,6.58,29.43,5.54,35.09,3.35,1.24-.48,2.23-.95,2.9-1.29-13.69-3.48-20.83-6.27-24.85-8.22-1.62-.78-3.5-1.81-6.44-2.43-4.16-.88-6.24-.07-11.33-1.02-2.38-.44-1.83-.65-5.06-1.35-7.74-1.68-11.35-.73-14.97-3.52-.75-.58-1.75-1.51-2.68-3.01Z"
      />
      <path
        style={styles.nineteen}
        d="m396.13,377c-.04,1.49-.07,3.87,0,6.78,1.81,71.1,60.44,120.38,68.61,127.04,9,7.34,22.92,17.34,42.13,26.17,15.22,1.87,32.63,2.85,51.77,1.93,7.92-.38,15.45-1.05,22.58-1.93,1.16-.74,3.3-2.13,5.87-4.01,7.59-5.56,19.69-16,42.71-53.08,2.26-3.64,5.68-9.24,9.73-16.23,20.43-.39,33.06-11.84,32.55-20.59-.52-8.99-14.88-14.88-15.72-15.22,2.71-3.87,7.32-11.53,9-22.3,1.54-9.91-.09-17.99-1.34-22.45.41-5.26.93-10.7,1.59-16.31.82-6.94,1.79-13.59,2.87-19.94,1.32-2.23,4.29-7.93,3.91-15.65-.3-6.16-2.59-10.69-3.91-12.91-4.51-9.43-9.45-19.37-14.87-29.74-3.67-7.03-6.27-11.78-11.79-15.65-5.96-4.18-12.32-5.22-16.39-5.48-9.06-.78-18.59-1.33-28.57-1.57-17.88-.42-34.51.25-49.7,1.57-10.94.48-22.3.76-34.05.78-13.01.03-25.54-.26-37.56-.78-5.99-.02-21.75.74-37.57,11.35-17.2,11.55-23.76,27.58-25.73,33.07-5.38,21.72-10.76,43.43-16.14,65.15Z"
      />
      <path
        style={styles.nineteen}
        d="m455.61,349.35c3.64,1.8,9,4.44,15.52,7.57,10.28,4.93,15.42,7.39,18.78,8.74,7.01,2.81,17.78,5.8,33,5.35,4.49-.81,8.03-4.3,8.87-8.74.87-4.6-1.34-9.3-5.48-11.61-8.94-1.78-19.46-5.04-30.26-11.09-2.34-1.31-4.54-2.67-6.61-4.04-3.8-2.69-8.44-6.53-12.96-11.87-6.43-7.6-10-15.16-12-20.48.19,2.62-1.01,5.11-3.13,6.39-2.64,1.6-6.29,1.06-8.61-1.43,1.2,2.76.63,5.79-1.17,7.3-2.41,2.04-7.13,1.43-9.48-2.35.55,2.6-.25,5.26-2.13,6.91-2.42,2.12-6.25,2.24-9.13.13,1.13,1.66,1.23,3.8.26,5.48-1.12,1.93-3.47,2.91-5.74,2.35,3.55,3.35,7.71,6.91,12.52,10.43,6.31,4.62,12.36,8.2,17.74,10.96Z"
      />
      <path
        style={styles.nineteen}
        d="m575.61,353.42c-3.39,2.88-4.58,7.52-3,11.45,1.35,3.34,4.52,5.69,8.22,6.13,14.76-2.76,35.32-8.49,57-21.26,11.34-6.68,20.6-13.88,27.96-20.48-2.13,1.01-4.54.26-5.52-1.43-.73-1.26-.61-2.93.27-4.27-2.8,2.85-7.1,3.05-9.66.88-1.93-1.64-2.69-4.47-1.96-7.17-2.42.92-5.15.41-7.04-1.3-2.86-2.58-2.12-6.36-2.09-6.52-2.7,3.1-7.08,4.15-10.83,2.61-3.54-1.46-5.88-4.98-5.87-8.87-1.94,6.53-6.64,19.14-18,31.04-11.08,11.62-23.1,16.91-29.48,19.21Z"
      />
      <path
        style={styles.nineteen}
        d="m538.7,444.04c-20.19,1.49-33.79-2.58-42.65-6.78-5.21-2.47-10.38-5.75-22.96-11.87-8.36-4.06-15.37-7.13-20.09-9.13,1.54,1.11,9.28,6.73,8.22,9.13-1.28,2.89-14.59-.66-15.52-.91,7.62,4.03,12.67,7.93,12,9.78-.9,2.5-11.98.62-13.3.39,5.9,2.51,9.21,6.03,8.61,7.83-.57,1.69-4.78,2.39-9.91,1.57,1.79,1.42,4.52,3.4,8.11,5.29,8.39,4.41,18.06,6.52,34.02,5.8,21.62-.98,40.76-6.51,53.48-11.09Z"
      />
      <path
        style={styles.nineteen}
        d="m559.96,446.52c5.55-.67,12.2-.85,19.57.26,1.92.29,3.74.64,5.48,1.04,5.22,4.11,13.12,9.08,23.61,11.61,2.76.67,5.39,1.09,7.84,1.34.02.63-.15,1.04-.28,1.27-2.41,4.25-20.2-.38-33-6.78-1.77-.88-4.42-2.27-7.57-4.17-1.66-.63-3.4-1.24-5.22-1.83-3.68-1.18-7.18-2.07-10.43-2.74Z"
      />
      <path
        style={styles.nineteen}
        d="m544.83,487.48c-10.05,4.67-36.37,15.19-59.01,3.63-1.97-1-3.96-2.2-5.94-3.63,1.21,1.21,7.25,7.29,6.26,8.61-1.05,1.39-9.39-3.3-10.7-4.04,2.07,3.18,5.21,6.74,9.91,8.48,2.54.94,4.98,1.14,4.96,1.43-.02.29-2.45.48-12.26.13,1.3,1.37,3.36,3.25,6.26,4.83,5.72,3.1,10.92,2.95,10.96,4.04.02.51-1.09,1.28-6.39,2.35,7.41.61,20.61.67,35.35-5.22,9.11-3.64,15.95-8.4,20.61-12.26,4.14,4.01,9.57,8.55,16.43,12.78,10.54,6.5,20.5,9.82,27.83,11.61-4.48-3.53-5.15-4.83-4.87-5.35.39-.74,2.68.03,6.52.26,3.34.2,6.1-.09,7.96-.39-5.66-4.41-6.83-6-6.52-6.52.27-.45,1.54.14,3.26-.13,2.48-.39,4.31-2.31,5.61-4.24-5.51.23-6.73-.1-6.78-.46-.09-.59,3.07-1.16,6.78-3.52,2.29-1.46,3.93-3.04,4.96-4.17-4.98,1.77-11.39,3.4-18.91,3.78-20.35,1.03-35.75-7.72-42.26-12Z"
      />
      <path
        style={styles.twentyThree}
        d="m581.15,405.51l10.07,3.1c.2-.88,1.2-4.91,5.06-7.28,2.07-1.27,4.1-1.54,5.22-1.59,3.84.08,10.76-.36,17.67-4.47,2.12-1.26,3.86-2.64,5.29-3.97-3.68,1.03-9.37,2.14-16.31,1.68-7.37-.49-13.14-2.55-16.73-4.15-1.37.32-3.38.97-5.51,2.33-2.36,1.5-3.89,3.26-4.76,4.43-.48.7-1.32,2.16-1.5,4.17-.26,2.93,1.05,5.08,1.5,5.75Z"
      />
      <path
        style={styles.eighteen}
        d="m476.09,480.3c-2.76.31-4.86-1.52-4.96-3.13-.1-1.7,1.98-3.9,4.96-3.65,2.49.25,6.2.49,10.69.28,11.92-.56,21.6-3.94,28.31-7.06,4.96-2.77,16.12-8.12,31.17-7.96,16.6.18,28.5,6.97,33.26,10.04,4.93,3.39,9,5.75,11.87,7.3,2.35,1.27,3.48,1.78,4.31,2.08.22.08.38.13.54.19,4.19,1.39,6.3,1.82,8.84,2.95,2.41,1.07,4.39,1.97,4.56,3.54.09.83-.35,1.63-.85,2.08-1.31,1.18-4.13.83-6.71-1.32l-25.57-16.04c-5.35-2.85-15.29-7.24-28.44-7.8-17.09-.73-29.87,5.41-35.34,8.46-4.52,3.05-12,7.22-22.04,9.13-5.65,1.08-10.65,1.16-14.61.91Z"
      />
      <path
        style={styles.nineteen}
        d="m594.52,367.87c.92,7.93,5.83,14.68,12.78,17.22,9.6,3.5,20.58-1.94,23.87-9.13,1.15-2.52,1.21-4.92,1.17-6-.24-7.67-6.88-12.38-7.92-13.1-5.07,2.42-10.75,4.82-17.02,7.03-4.5,1.58-8.81,2.9-12.89,3.98Z"
      />
      <path
        style={styles.nineteen}
        d="m467.45,355.14c-6.14,2.43-10.34,7.33-10.51,12.47-.32,9.52,13.4,13.85,14.19,14.09,9.55,2.88,21.5-.84,25.57-8.74.87-1.7,1.25-3.32,1.42-4.59-3.38-1.04-6.91-2.25-10.56-3.67-7.6-2.96-14.3-6.26-20.11-9.55Z"
      />
      <path
        style={styles.nineteen}
        d="m455.61,349.35c-2.84,2.15-15.11,11.87-13.7,22.7,1.82,13.95,25.2,20.86,41.35,20.09,14.88-.71,25.66-8.04,30.39-11.74.43-.85.89-2.1.59-3.46-.15-.67-.4-1.02-.65-1.57-.42-.93-.8-2.37-.46-4.56-1.6-.13-4.01-.38-6.91-.87-7.74-1.3-14.59-3.56-29.15-10.18-5.79-2.64-13.09-6.1-21.46-10.41Z"
      />
      <path
        style={styles.nineteen}
        d="m638.92,349.09c.92.78,11.52,10.02,9.6,21.78-1.77,10.84-12.85,15.84-18.52,18.39-16.58,7.48-33.06,1.87-38.22-.13-1.19-1.01-2.41-2.09-3.65-3.26-1.36-1.28-2.62-2.55-3.78-3.78-1.89-1.45-2.38-2.75-2.48-3.65-.14-1.25.44-1.93.65-3.65.2-1.69-.11-3.12-.39-4.03,5.97-1.16,12.4-2.72,19.16-4.84,15.13-4.74,27.7-10.92,37.63-16.82Z"
      />
      <path d="m500,412.61c.28,5.09,4.66,8.14,9.31,8.8,2.93.38,5.89.45,8.82.88,7.99.97,15.92,2.53,23.67,4.71,15.5,4.32,30.35,11.04,43.73,19.98,0,0-1.06,1.7-1.06,1.7-13.66-8-28.3-14.3-43.54-18.56-7.61-2.12-15.36-3.74-23.19-4.83-4.01-.69-8.31-.48-12.04-2.29-3.9-1.69-6.95-6.05-5.69-10.38h0Z" />
      <path d="m573.39,404.59c7.29-.76,14.44,1.79,21.24,3.98,6.84,2.17,13.76,4.12,20.71,5.91,6.05,1.58,14.8,3.58,20.99,4.95,7.12,1.53,13.99,4.24,20.46,7.55,0,0-.89,1.79-.89,1.79-6.48-3-13.12-5.44-20.13-6.92-6.26-1.37-15-3.23-21.06-4.96-6.97-1.89-13.89-3.95-20.75-6.23-6.81-2.38-13.31-5.23-20.57-6.07h0Z" />
      <path d="m541.48,428.88c.15-4.4,1.22-8.81,3.34-12.65-.53,2.11-.94,4.21-1.18,6.32-.23,2.11-.27,4.22-.17,6.31l-2,.02h0Z" />
      <path d="m549.85,431.49c.28-5.96,2.03-11.85,5.15-16.92-2.09,5.44-3.16,11.18-3.15,16.97,0,0-2-.05-2-.05h0Z" />
      <path d="m565.17,437.82c-.62-4.27.26-8.7,2.35-12.3-.53,2.01-.86,4.03-.91,6.04-.07,2.01.15,3.99.52,5.92l-1.97.34h0Z" />
      <path
        style={styles.nineteen}
        d="m617.04,460.83c4.07,1.44,9.45,2.81,15.83,3.04,2.39.09,4.62,0,6.65-.19"
      />
      <path d="m615.59,417.21c-5.55-1.13-11.27-1.19-16.9-.49,2.69-1.08,5.58-1.67,8.5-1.9,2.92-.23,5.89-.11,8.78.43,0,0-.38,1.96-.38,1.96h0Z" />
      <path d="m509.8,315.76c19.95,15.29,45.13,19.99,69.26,13.01-22.83,10.44-51.59,4.61-69.26-13.01h0Z" />
      <path d="m503.93,286.9c18.55,10.69,39.27,14.99,60.55,12.81-20.62,6.01-43.82.27-60.55-12.81h0Z" />
      <path
        style={styles.twentyFour}
        d="m493.17,393.03c.38.4,1.67,1.68,3.78,1.97,2.68.36,4.6-1.17,4.93-1.43-.22-.28-1.69-2.08-4.27-2.22-2.54-.14-4.18,1.43-4.43,1.68Z"
      />
    </g>
  );
};

export default Sakonji;
