const ShoulderJacket = ({ color, skinColor }) => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#fff",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      fill: color,
    },
    four: {
      fill: "#dad9db",
    },
    five: {
      fill: skinColor,
    },
    six: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
  };

  return (
    <g>
      <path
        style={styles.five}
        d="M486,713c-2.39,1.23-3.54,7.43,0,11,4.04,4.07,11.11,1.61,12-1,1.25-3.68-8.34-11.89-12-10Z"
      />
      <path
        style={styles.five}
        d="M448.69,575.42c-18.65,4.99-40.41,12.39-63.69,23.58-34.84,16.74-61.63,36.46-80.85,53.09-2.52,7.66-4.96,15.97-7.15,24.91-2.08,8.48-3.72,16.56-5,24.15,28.4,16.75,56.81,33.5,85.21,50.25l163.28-45.69-29.05-124.29-62.74-6Z"
      />
      <path d="M536.26,615.43c-6.98-.24-13.96,.25-20.79,1.65-6.89,1.38-13.35,3.84-20,6.18l-.07-.13c11.5-8.3,27.13-10.65,40.96-8.7,0,0-.09,1-.09,1h0Z" />
      <path
        style={styles.one}
        d="M405.75,588.89l6.71,81.17-47.68,71.56c11.41,27.46,22.82,54.92,34.23,82.38,18.92,7.18,46.31,15.14,80,17,25.76,1.43,47.88-1.11,64.85-4.36-1.27-49.51-2.54-99.01-3.82-148.52l-50.6,34.02-63.43-52.15-15.69-81.97-4.57,.86Z"
      />
      <path
        style={styles.three}
        d="M292,697c-1.43,8.68-2.54,18.39-3,29-.32,7.46-.28,14.49,0,21,12.02,14.16,29.97,32.3,55,49,22.56,15.05,43.87,24.26,60.39,29.98-1.07-12.68-5.74-48.1-33.39-80.98-29.64-35.25-66.93-45.28-79-48Z"
      />
      <path
        style={styles.four}
        d="M503.87,752.85c10.04,10.79,28.75,16.62,46.87,14.71,21.97-2.31,32.1-14.83,33.13-16.15-2.44,1.99-14.03,10.98-34.22,12.65-26.35,2.18-44.13-10.04-45.78-11.21Z"
      />
      <path
        style={styles.three}
        d="M511.43,581.42c.84,29.16,2.61,59.73,5.57,91.58,5.53,59.56,14.37,114.41,24.82,164,22.75-3.77,54.81-12.04,88.68-31.5,17.24-9.9,31.33-20.64,42.55-30.51-.64-16.46-2.16-34.57-5.05-53.99-3.83-25.74-9.31-48.73-15.21-68.6-10.27-11.03-23.85-23.44-41.29-34.9-38.67-25.41-76.82-33.31-100.07-36.08Z"
      />
      <path d="M365.94,738.74c.98-11.51,3.98-22.94,9.12-33.31,5.29-10.24,8.6-21.26,9.12-32.8,0,0,.08,0,.08,0,.29,8.65-1.21,17.42-4.4,25.49-3.34,7.94-7.44,15.53-9.58,23.91-1.54,5.5-2.62,11.13-3.35,16.81l-.99-.11h0Z" />
      <path d="M415.42,614.48c14.01-1.64,29.06,.89,40.93,8.75,0,0-.01,.02-.01,.02-9.71-4.04-19.79-7.23-30.34-7.78-3.49-.2-6.98-.21-10.48,0l-.09-1h0Z" />
      <circle style={styles.two} cx="361.5" cy="773.5" r="13.5" />
      <circle style={styles.two} cx="563" cy="722" r="10" />
      <circle style={styles.two} cx="568" cy="814" r="10" />
      <path d="M613.46,813.99c-5.65-20.46-8.35-41.62-8.47-62.86,1.29-21.1,3.18-42.06,5.52-63.05,0,0,.89,.07,.89,.07-.49,18.22-2,44.58-2.91,63.05,.1,5.12,.28,10.43,.64,15.63,1.01,15.69,3.2,31.25,6.27,46.69,0,0-1.95,.46-1.95,.46h0Z" />
      <path d="M512.43,581.38c5.4,85.64,14.12,171.1,30.37,255.42,0,0-1.96,.39-1.96,.39-13.35-63.08-22.42-127.05-27.55-191.32-1.59-21.44-2.77-42.91-2.86-64.41l2-.08h0Z" />
      <path
        style={styles.six}
        d="M405.57,589.93l6.89,80.13c-15.82,22.98-31.64,45.96-47.45,68.94"
      />
      <polyline
        style={styles.six}
        points="410.31 588.03 426.39 670.32 489.43 722.15 520.58 701.2"
      />
      <path d="M404.39,825.98c-4.62-54.25-40.88-100.81-90.81-121.5-3.51-1.45-7.08-2.76-10.7-3.93-3.62-1.26-7.36-1.95-10.88-3.54,3.88,.13,7.52,1.44,11.21,2.47,3.67,1.11,7.29,2.36,10.87,3.75,50.67,19.41,88.02,68.11,90.31,122.76h0Z" />
      <path d="M489.4,722.15c-1.21-9.13-.08-18.86,4.12-27.16,2.79-4.83,6.57-9.14,10.98-12.49-5.16,5.24-8.57,11.65-11.44,18.31-2.88,6.67-3.77,14.23-3.66,21.34h0Z" />
      <path d="M488.42,720.97c.48-9.12-1.59-18.19-5.86-26.21-2.27-4.96-5.37-9.45-9.16-13.44,4.41,3.35,8.19,7.66,10.98,12.49,4.13,8.23,5.48,18.25,4.04,27.15h0Z" />
      <path
        style={styles.four}
        d="M368,768c-.42-.62-2.44-3.46-6-4-3.89-.59-8.03,1.8-10,6,1.61-1.03,5.18-2.98,10-3,2.49,0,4.55,.5,6,1Z"
      />
      <path
        style={styles.four}
        d="M576.28,812.25c-.42-.62-2.44-3.46-6-4-3.89-.59-8.03,1.8-10,6,1.61-1.03,5.18-2.98,10-3,2.49,0,4.55,.5,6,1Z"
      />
      <path
        style={styles.four}
        d="M570.96,720.04c-.42-.62-2.44-3.46-6-4-3.89-.59-8.03,1.8-10,6,1.61-1.03,5.18-2.98,10-3,2.49,0,4.55,.5,6,1Z"
      />
      <path
        style={styles.four}
        d="M389,756.44c10.04,10.79,28.75,16.62,46.87,14.71,21.97-2.31,32.1-14.83,33.13-16.15-2.44,1.99-14.03,10.98-34.22,12.65-26.35,2.18-44.13-10.04-45.78-11.21Z"
      />
    </g>
  );
};

export default ShoulderJacket;
