import { Grid, Typography } from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { normal, text } from "../../../utils/themeContstants";
import CustomIconButton from "../../custom/CustomIconButton";

const MobileNotiNavbar = () => {
  const navigate = useNavigate();

  const styles = {
    container: {
      width: "100%",
      paddingLeft: 2,
      paddingRight: 2,
    },
    width: {
      width: "100%",
    },
    title: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
  };
  return (
    <Grid item sx={styles.container}>
      <Grid container justifyContent="space-between" alignItems="center">
        <CustomIconButton
          onClick={() => navigate(-1)}
          icon={<IoIosArrowBack style={{ color: text, fontSize: 24 }} />}
        />

        <Grid item>
          <Typography sx={styles.title}>Notifications</Typography>
        </Grid>

        <Grid item></Grid>
      </Grid>
    </Grid>
  );
};

export default MobileNotiNavbar;
