import { Grid, Typography } from "@mui/material";
import { currencyFormatter, numFormatter } from "../../utils/helpers";
import { doubtedBlue, text } from "../../utils/themeContstants";
import DoubtedCoin from "../custom/DoubtedCoin";
import RivoxCoin from "../custom/RivoxCoin";
import SecondaryButton from "../custom/SecondaryButton";

const DepositItem = ({ amount, onClick }) => {
  const getType = () => {
    switch (amount) {
      case 5:
        return null;
      case 10:
        return "gold";
      case 25:
        return "purple";
      case 50:
        return "blue";
    }
  };

  const styles = {
    container: {
      width: "100%",
      padding: 1,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 0.5 }}
          >
            <RivoxCoin size={20} type={getType()} />

            <Grid item>
              <Typography
                sx={{
                  fontSize: 15,
                  color: text,
                  fontWeight: 700,
                }}
              >
                {amount} Coins
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <SecondaryButton
          label={currencyFormatter().format(amount)}
          bg={doubtedBlue}
          onClick={onClick}
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export default DepositItem;
