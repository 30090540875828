const LipstickTwo = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
  };

  return (
    <g transform="translate(510, 455)">
      <path d="M28.65,48.86c-10.13,.1-18.05-4.08-23.1-12.87-1.55-2.7-1.84-6.13-2.73-9.22-.31-1.07-.45-2.26-1.02-3.17-2.95-4.67-2.1-7.27,1.25-10.41,3.01-2.82,6.63-4.98,10-7.4,1.77-1.27,3.61-2.45,5.37-3.73C21.25,0,24.28-.26,27.39,1.16c1.98,.91,3.72,.85,5.55-.31,2.36-1.49,4.85-.8,7.02,.3,6.21,3.13,11.89,7.05,16.62,12.22,2.53,2.77,2.87,5.65,1.2,9.12-1.02,2.12-2.1,4.24-2.84,6.47-.79,2.38-.97,4.98-1.86,7.32-1.3,3.43-3.91,5.93-7.22,7.39-4.31,1.92-8.75,3.56-13.21,5.12-1.19,.42-2.66,.06-4,.06Zm1.53-3.54c4.34-.33,9.28-2.54,14.07-5.29,3.5-2,5.95-4.92,6.62-8.78,.65-3.74,2.04-7,3.88-10.24,1.42-2.5,.84-3.98-1.14-6.19-4.11-4.59-9.43-7.55-14.55-10.74-1.2-.75-3.36-1.04-4.56-.46-2.72,1.31-5.16,1.68-7.93,.36-2.07-.99-4.22-.92-6.27,.31-4.98,2.98-10.03,5.83-14.93,8.93-4.27,2.7-3.96,5.75-2.08,8.08,1.61,1.99,2.39,4.13,2.81,6.61,.59,3.51,1.57,6.91,4.09,9.69,5.03,5.57,11.46,7.63,20,7.72Z" />
      <path d="M30.19,45.32c-8.54-.09-14.96-2.15-20-7.72-2.51-2.78-3.5-6.17-4.09-9.69-.42-2.48-1.2-4.62-2.81-6.61-1.88-2.33-2.19-5.38,2.08-8.08,4.9-3.1,9.96-5.95,14.93-8.93,2.05-1.23,4.2-1.3,6.27-.31,2.77,1.33,5.21,.96,7.93-.36,1.2-.58,3.36-.29,4.56,.46,5.12,3.19,10.44,6.14,14.55,10.74,1.98,2.21,2.56,3.69,1.14,6.19-1.84,3.24-3.23,6.5-3.88,10.24-.67,3.86-3.12,6.77-6.62,8.78-4.79,2.75-9.73,4.96-14.07,5.29Zm13.48-24.16c-1.85-3.16-4.81-4.83-8.12-5.62-5.93-1.42-11.54,.14-16.87,2.76-3.19,1.56-3.35,3.31-.75,5.77,2.98,2.82,6.7,3.73,10.62,3.88,5.99,.23,10.91-2.16,15.12-6.79Z" />
      <path d="M43.67,21.16c-4.21,4.63-9.14,7.02-15.12,6.79-3.91-.15-7.64-1.06-10.62-3.88-2.6-2.46-2.43-4.21,.75-5.77,5.33-2.61,10.93-4.17,16.87-2.76,3.31,.79,6.27,2.46,8.12,5.62Zm-3.8,.56c-5.67-5.1-13.5-5.3-21.26-2.22,3.35,7.37,15.22,8.66,21.26,2.22Z" />
      <path
        style={styles.one}
        d="M39.87,21.72c-6.04,6.44-17.91,5.14-21.26-2.22,7.76-3.08,15.58-2.88,21.26,2.22Z"
      />
    </g>
  );
};

export default LipstickTwo;
