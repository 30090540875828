const NoseHoop = () => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 0.5,
    },
    two: {
      fill: "#e5e5e5",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 0.5,
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="m544.33,366.82c.56,2.46,1.06,4.95,1.7,7.39,2.35,8.95,4.77,17.89,8.98,26.19,1.86,3.68,4,7.23,6.21,10.71,2.19,3.45,1.76,6.69-1.83,8.61-2.88,1.54-4.62,3.66-5.83,6.67-1.41,3.51-4.42,5.65-7.95,6.81-3.03.99-5.34-.72-7.51-2.6-2.35-2.04-4.72-4.09-7.98-4.51-.86,1.53-1.55,3.08-2.52,4.44-2.96,4.15-8.4,4.8-11.46.39-.3-.44-1.1-.81-1.64-.77-4.61.32-8.22-1.42-10.34-5.46-2.24-4.26-1.59-8.56,1-12.53.81-1.24,1.83-2.36,2.89-3.4.35-.35,1.12-.27,1.7-.39-.23.49-.35,1.08-.71,1.44-2.89,2.86-4.51,6.2-4.35,10.36.2,5.08,4.69,8.84,9.93,8.11-1.2-3.77-1.57-7.67-.37-11.4.76-2.38,2.22-4.61,3.74-6.63.72-.96,2.24-1.49,3.49-1.8.81-.2,2.41.16,2.58.65.32.9.21,2.37-.36,3.08-2.35,2.94-3.99,6.17-4.42,9.89-.19,1.67.18,3.4.31,5.33,1.84-.65,3.22-1.01,4.49-1.61,5.54-2.65,10.25-1.04,14.57,2.73.92.8,1.87,1.57,2.86,2.29,1.37,1,2.84,1.13,4.41.4,2.6-1.23,4.81-2.93,5.74-5.73,1.13-3.41,3.1-5.89,6.36-7.54,2.75-1.39,3.23-3.3,1.51-5.9-7.82-11.82-11.7-25.19-15.36-38.65-.42-1.56-.72-3.17-.95-4.77-.08-.56.22-1.18.35-1.78.25,0,.5,0,.75,0Zm-21.47,43.75c-.17-.26-.33-.52-.5-.78-1.03.61-2.2,1.06-3.06,1.85-3.73,3.43-4.8,11.21-2.37,16.38,1.29,2.75,3.24,4.04,5.68,3.76,2.21-.25,4.35-2.55,4.98-5.86-1.27,1.06-2.14,1.81-3.03,2.54-1.28,1.05-2.62,2.08-4.4,1.44-1.82-.65-2.59-2.24-2.7-3.99-.12-1.88-.06-3.81.24-5.67.62-3.75,2.31-7.02,5.16-9.67Z"
      />
      <path
        style={styles.two}
        d="m522.86,410.56c-2.85,2.65-4.54,5.93-5.16,9.67-.31,1.86-.36,3.79-.24,5.67.11,1.75.88,3.34,2.7,3.99,1.79.64,3.12-.39,4.4-1.44.89-.73,1.76-1.48,3.03-2.54-.63,3.31-2.78,5.61-4.98,5.86-2.44.28-4.39-1.01-5.68-3.76-2.43-5.17-1.36-12.95,2.37-16.38.86-.79,2.03-1.25,3.06-1.85.17.26.33.52.5.78Z"
      />
    </g>
  );
};

export default NoseHoop;
