const WrapItUp = () => {
  const styles = {
    one: {
      fill: "#c4c6b7",
    },
    two: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      fill: "#ced7c7",
    },
  };

  return (
    <g>
      <path
        style={styles.three}
        d="M396.13,375.45c-7.13,4.19-15.61,9.97-23.87,17.67-5.14,4.79-9.22,9.43-12.46,13.6,7.53,19.1,15.06,38.19,22.59,57.29l2.3,42.77c8.19,6.06,17.68,12.4,28.57,18.68,17.23,9.94,33.97,17.41,48.79,23.04,5.55,3.22,30.06,16.63,65.58,13.48,30.12-2.67,49.97-15.64,56.12-19.98,12.06-14.87,24.13-29.74,36.19-44.62,9.57-7.71,24.86-22.26,31.72-43.05,2.27-6.89,3.21-13.21,3.44-18.67,2.3-14.6,4.61-29.19,6.91-43.79-2.65,1.43-6.71,3.32-12.01,4.63-7.23,1.78-17.05,2.56-34.96-2.07-10.85-2.81-28.6-8.62-47.61-21.13-3.49-.97-9.95-2.35-18.22-1.74-9.71,.71-16.81,3.79-20.15,5.45-16.21,6.22-32.42,12.45-48.64,18.67-10.03,2.08-24.3,4.18-41.31,3.88-15.05-.27-27.47-2.33-36.5-4.4-2.17-6.56-4.33-13.13-6.5-19.69Z"
      />
      <polygon
        style={styles.one}
        points="568.68 386.09 565.95 420.81 547.77 441.32 584 423.44 568.68 386.09"
      />
      <path
        style={styles.one}
        d="M395.97,375.64l-14.3,32.98,22.29,49.6-21.32-25.5,16.18,54.03,90.59,60.17-46.47-53.27,64.07,67.76c-6.6-.58-15.61-1.92-25.5-5.15-10.55-3.45-18.25-7.8-23.32-11.18-14.7-12.63-26.27-21.26-34.53-27.03-4.66-3.26-13.28-9.1-22.14-18.67-3.27-3.54-5.7-6.63-7.29-8.78-11.5-28.33-23-56.66-34.5-84.99l36.25-29.97Z"
      />
      <path
        style={styles.one}
        d="M475.25,396.66c-2.68,1.4-5.61,2.74-8.8,3.95-22.08,8.38-44.25,6.04-56.02,3.93,10.66,10.07,21.32,20.13,31.99,30.2l-23.31,4.65,5.99,10.97,66.09,9.32-60.05,.74,13.88,14.22-6.54,1.78-24-30.16-11.83-51.12c9.87,2.18,23.54,4.31,40.09,4.3,12.74,0,23.79-1.28,32.52-2.78Z"
      />
      <path
        style={styles.one}
        d="M438.46,476.42c10.32,11.83,22.94,24.64,38.31,37.68,10.08,8.56,20.11,16.16,29.76,22.89,1.74,.99,4.47,2.29,8.18,3.17,4.09,.97,7.71,1.04,10.05,.94-25.38-20.16-50.76-40.31-76.15-60.47l121.56-3.94-15.26,4.56,52.33,5.35-18.98,19.89-4.59,7.67-41.79,.44,32.42,4.88-29.72,39.75c6.93-1.74,15.63-4.51,24.87-9.01,5.66-2.75,10.41-5.61,14.3-8.26,11.15-14.26,22.3-28.52,33.46-42.78l25.96-27.2,11.94-36.36,6.91-43.79c-2.08,1.39-5.54,3.36-10.36,4.72-6.9,1.95-12.84,1.59-15.77,1.26,.43,2,.87,4.01,1.3,6.01l-17.24,30.49,8.57-5.78-10.88,44.95c-9.13-1.68-18.85-3.18-29.14-4.4-29.68-3.54-56.88-3.93-80.29-2.89-23.25,3.4-46.5,6.81-69.74,10.21Z"
      />
      <polygon
        style={styles.one}
        points="473.01 495.02 501.62 505.84 543.95 496.7 503.01 500.66 473.01 495.02"
      />
      <path
        style={styles.two}
        d="M396.13,375.45c-7.13,4.19-15.61,9.97-23.87,17.67-5.14,4.79-9.22,9.43-12.46,13.6,7.53,19.1,15.06,38.19,22.59,57.29l2.3,42.77c8.19,6.06,17.68,12.4,28.57,18.68,17.23,9.94,33.97,17.41,48.79,23.04,5.55,3.22,30.06,16.63,65.58,13.48,30.12-2.67,49.97-15.64,56.12-19.98,12.06-14.87,24.13-29.74,36.19-44.62,9.57-7.71,24.86-22.26,31.72-43.05,2.27-6.89,3.21-13.21,3.44-18.67,2.3-14.6,4.61-29.19,6.91-43.79-2.65,1.43-6.71,3.32-12.01,4.63-7.23,1.78-17.05,2.56-34.96-2.07-10.85-2.81-28.6-8.62-47.61-21.13-3.49-.97-9.95-2.35-18.22-1.74-9.71,.71-16.81,3.79-20.15,5.45-16.21,6.22-32.42,12.45-48.64,18.67-10.03,2.08-24.3,4.18-41.31,3.88-15.05-.27-27.47-2.33-36.5-4.4-2.17-6.56-4.33-13.13-6.5-19.69Z"
      />
      <path
        style={styles.two}
        d="M638.18,479.04c-4.83-1.58-10.18-3.13-16.04-4.52-9.81-2.33-18.96-3.73-27-4.57-11.27-1.34-23.53-2.44-36.7-3.14-15.04-.8-29.11-.94-42.01-.68-8.21,.32-16.93,.9-26.08,1.82-19.39,1.95-36.79,5.05-51.88,8.48"
      />
      <path
        style={styles.two}
        d="M382.4,464l8.64,20.45c2.99,7.08,7.98,13.5,14.64,18.83l56.38,45.2"
      />
      <line style={styles.two} x1="411.34" y1="431.39" x2="454.13" y2="436" />
      <line
        style={styles.two}
        x1="411.03"
        y1="426.23"
        x2="425.18"
        y2="428.05"
      />
      <path
        style={styles.two}
        d="M414.81,445.61l-12.18-50.47,11.83,49.25c.53,2.22,1.54,4.33,2.97,6.26,9.72,13.07,21.64,27.26,36.26,41.89,17.03,17.05,34.37,31.32,50.54,43.12,5.4,3.94,12.83,5.92,20.52,5.45,11.31-.68,22.62-1.37,33.93-2.05,7.36-.45,14.38-3.08,19.35-7.41,.04-.03,.07-.07,.11-.1,2.35-2.06,4.13-4.07,5.48-5.83,1.02-1.33,2.24-2.57,3.6-3.73,3.58-3.04,7.37-6.14,11.4-9.27,7.27-5.66,14.44-10.77,21.33-15.36"
      />
    </g>
  );
};

export default WrapItUp;
