import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../store/Store";
import { numFormatter } from "../../utils/helpers";
import {
  cardDark,
  cardLight,
  large,
  normal,
  secondaryText,
  small,
  text,
} from "../../utils/themeContstants";
import CustomModal from "../custom/CustomModal";
import RivoxCoin from "../custom/RivoxCoin";
import OutlineButton from "../custom/OutlineButton";
import WalletDeposit from "./WalletDeposit";
import { FaLock } from "react-icons/fa";
import WalletWithdraw from "./WalletWithdraw";
import PrimaryButton from "../custom/PrimaryButton";
import TipModal from "../tip/TipModal";
import { CDN_URL } from "../../utils/constants";

const Wallet = ({ open, onClose, selectedTab = "balance" }) => {

  const paypal = `${CDN_URL}paypal-wallet.svg`;
  const cashapp = `${CDN_URL}cash-app-wallet.svg`;



  const store = useContext(StoreContext);

  const [selected, setSelected] = useState("balance");
  const [tipOpen, setTipOpen] = useState(false);

  const handleClose = () => {
    setSelected("balance");
    onClose();
  };

  const getTitle = () => {
    switch (selected) {
      case "balance":
        return "your balance";
      case "deposit":
        return "deposit options";
      case "withdraw":
        return "withdraw";
      default:
        return null;
    }
  };

  useEffect(() => {
    setSelected(selectedTab);
  }, [open, selectedTab]);

  const styles = {
    width: {
      width: "100%",
    },
    title: {
      fontSize: large,
      textTransform: "uppercase",
      fontStyle: "italic",
      fontWeight: 800,
      color: text,
    },
    container: {
      width: "100%",
      backgroundColor: cardLight,
      borderRadius: 1,
      padding: 2,
    },
    balanceContainer: {
      width: "100%",
      backgroundColor: cardDark,
      padding: 2,
      borderRadius: 1,
    },
    value: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
    bottom: {
      fontSize: small,
      fontWeight: 400,
      color: secondaryText,
      textAlign: "left",
      maxWidth: 500,
    },
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <>
      <TipModal open={tipOpen} onClose={() => setTipOpen(false)} />

      <CustomModal
        open={open}
        onClose={handleClose}
        maxWidth={selected === "withdraw" ? 455 : null}
        minWidth={selected === "withdraw" ? 455 : null}
        backgroundColor={cardLight}
        bottomContent={
          selected === "deposit" || selected === "withdraw" ? (
            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap={{ xs: 2 }}
              >
                <Grid item>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 2 }}
                  >
                    <Grid item sx={styles.icon}>
                      <img
                        draggable={false}
                        alt="paypal-logo"
                        src={paypal}
                        style={{ width: 85 }}
                      />
                    </Grid>

                    <Grid item sx={styles.icon}>
                      <img
                        draggable={false}
                        alt="cashapp-logo"
                        src={cashapp}
                        style={{ width: 100 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item sx={styles.icon}>
                      <FaLock style={{ color: secondaryText, fontSize: 16 }} />
                    </Grid>

                    <Grid item>
                      <Typography sx={styles.bottom}>
                        All transactions are handled and encrypted through
                        PayPal and Cash App.{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null
        }
        titleOptions={
          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 1 }}
            >
              <Grid item>
                <OutlineButton
                  label={"balance"}
                  selected={selected === "balance"}
                  onClick={() => setSelected("balance")}
                />
              </Grid>

              <Grid item>
                <OutlineButton
                  label={"deposit"}
                  selected={selected === "deposit"}
                  onClick={() => setSelected("deposit")}
                />
              </Grid>

              <Grid item>
                <OutlineButton
                  label={"withdraw"}
                  selected={selected === "withdraw"}
                  onClick={() => setSelected("withdraw")}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      >
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 2 }}
        >
          {selected !== "balance" && (
            <Grid item>
              <Typography sx={styles.title}>{getTitle()}</Typography>
            </Grid>
          )}

          {selected === "balance" && (
            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 2 }}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Typography
                        sx={{
                          fontSize: normal,
                          color: secondaryText,
                          fontWeight: 600,
                        }}
                      >
                        Total Balance
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 0.5 }}
                      >
                        <RivoxCoin size={32} />

                        <Grid item>
                          <Typography
                            sx={{ fontSize: 32, color: text, fontWeight: 700 }}
                          >
                            {numFormatter().format(store?.user?.funds?.balance)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <PrimaryButton
                    type="purple"
                    label="Tip a User"
                    fullWidth
                    onClick={() => {
                      setTipOpen(true);
                      handleClose();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {selected === "deposit" && <WalletDeposit />}

          {selected === "withdraw" && <WalletWithdraw />}
        </Grid>
      </CustomModal>
    </>
  );
};

export default Wallet;
