import "../styles/Scar.css";

const Scar = () => {
  return (
    <g>
      <g>
        <g>
          <path
            class="scar-8"
            d="m393.48,566.83l15.5,21.73-6.02,2.44-62.59-90.19,1.39-.97c.26.4.72.66,1.24.66.83,0,1.5-.67,1.5-1.5,0-.31-.09-.6-.26-.84l.18-.13,13.32,18.68c-.21-.14-.47-.21-.74-.21-.83,0-1.5.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5c0-.09-.01-.18-.03-.27l6.54,9.17c-.26-.25-.62-.4-1.01-.4-.83,0-1.5.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5c0-.21-.04-.4-.12-.58l5.77,8.1c-.05-.02-.1-.02-.15-.02-.83,0-1.5.67-1.5,1.5s.67,1.5,1.5,1.5c.71,0,1.31-.49,1.46-1.16l5.23,7.34c-.21-.12-.44-.18-.69-.18-.83,0-1.5.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5c0-.07-.01-.14-.02-.21l6.98,9.79c-.14-.05-.3-.08-.46-.08-.83,0-1.5.67-1.5,1.5s.67,1.5,1.5,1.5,1.48-.65,1.5-1.47l6.15,8.63c-.2-.11-.42-.16-.65-.16-.83,0-1.5.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5c0-.06,0-.12-.02-.17Zm12.02,18.17c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5,1.5.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm-6-9c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5,1.5.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm-48-67c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5,1.5.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"
          />
          <circle cx="404" cy="585" r="1.5" />
          <polygon
            class="scar-2"
            points="340.37 500.81 402.96 591 399.65 592.4 318.5 478.5 323.5 476.5 340.37 500.81"
          />
          <path
            class="scar-12"
            d="m329.52,573.36c-2.3-.31-4.34-.35-6.02-.27-2.09-2.77-4.17-5.53-6.26-8.3-15.91-21.1-31.83-42.2-47.74-63.29l-5-9c.42-1,1.53-3.25,4-5,1.99-1.41,3.95-1.84,5-2l5,5c1.6-.02,4.67-.28,8-2,3.12-1.61,5.03-3.75,6-5,2.33-1.33,4.67-2.67,7-4,1.07.32,3.79.98,7,0,2.55-.77,4.21-2.21,5-3,.93.69,2.66,1.75,5,2,.74.08,1.42.06,2,0l81.15,113.9c-6.94,3.44-13.89,6.88-20.84,10.32l-2.81-5.22c.12-.38,1.06-3.62-1-6.5-1.56-2.19-4.26-3.34-7-3l-1.76-2.39c-2.08,1.46-4.16,2.93-6.24,4.39.03.81.25,4.21,3,7,2.34,2.37,5.09,2.87,6,3l2.73,5.74.19.38c-2.9,1.44-5.8,2.87-8.7,4.31l-25.7-35.01c-1.84-.72-4.2-1.46-7.02-1.92-.33-.05-.66-.1-.98-.14Zm58.98,12.14c-8.43-12.59-16.85-25.19-25.28-37.78-1.19-.93-2.74-.99-3.72-.22-.87.68-.98,1.77-1,2l25,37c.53.14,1.66.36,3,0,.93-.25,1.6-.69,2-1Zm-39-17l-13.5-18.24c-1.05-.43-2.25-1-3.5-1.76-1.65-1-2.97-2.06-4-3-.24-.21-1.51-1.27-3-1-1.77.32-3.26,2.41-3,5l17.63,23.85c1.33-.22,3.27-.71,5.37-1.85,1.8-.98,3.12-2.11,4-3Zm-.5-39.5l-9.65-14c-.31-.23-2.12-1.52-4.35-1-.92.22-1.6.67-2,1l13,17,3-3Zm-18-25l-9.65-14c-.31-.23-2.12-1.52-4.35-1-.92.22-1.6.67-2,1l13,17,3-3Zm-23.5,20.5c.46-1.73.2-3.22,0-4-.14,1.1-.42,2.48-1,4-.88,2.28-2.08,3.94-3,5,.56-.31,3.16-1.83,4-5Zm-14,0l5,7c-.18-1.87-1.01-7.96-6-12-1.29-1.05-2.61-1.76-3.79-2.24-.07-.59-.14-1.17-.21-1.76l-6-5,5,6,2,3,3,2,1,3Z"
          />
          <circle cx="398" cy="576" r="1.5" />
          <path d="m393.48,566.83c.02.05.02.11.02.17,0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.23,0,.45.05.65.16l.83,1.17Z" />
          <path d="m393.48,566.83l-.83-1.17c.45.21.78.64.83,1.17Z" />
          <path
            class="scar-8"
            d="m363.22,547.72c8.43,12.59,16.85,25.19,25.28,37.78-.4.31-1.07.75-2,1-1.34.36-2.47.14-3,0l-25-37c.02-.23.13-1.32,1-2,.98-.77,2.53-.71,3.72.22Z"
          />
          <path d="m386.5,557v.03l-1.04-1.45c.6.19,1.04.75,1.04,1.42Z" />
          <path d="m385.46,555.58l1.04,1.45c-.02.82-.68,1.47-1.5,1.47s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.16,0,.32.03.46.08Z" />
          <path
            class="scar-8"
            d="m376,597.5l2.81,5.22c-2.29,1.13-4.59,2.27-6.89,3.4l-.19-.38-2.73-5.74,7-2.5Z"
          />
          <path d="m378.48,545.79c0,.07.02.14.02.21,0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.25,0,.48.06.69.18l.79,1.11Z" />
          <path d="m378.48,545.79l-.79-1.11c.42.21.73.62.79,1.11Z" />
          <path
            class="scar-8"
            d="m375,591c2.06,2.88,1.12,6.12,1,6.5l-7,2.5c-.91-.13-3.66-.63-6-3-2.75-2.79-2.97-6.19-3-7,2.08-1.46,4.16-2.93,6.24-4.39l1.76,2.39c2.74-.34,5.44.81,7,3Z"
          />
          <path d="m372.5,537c0,.12-.01.23-.04.34l-1.31-1.82c.76.06,1.35.7,1.35,1.48Z" />
          <path d="m371.15,535.52l1.31,1.82c-.15.67-.75,1.16-1.46,1.16-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5c.05,0,.1,0,.15.02Z" />
          <path d="m365.38,527.42c.08.18.12.37.12.58,0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.39,0,.75.15,1.01.4l.37.52Z" />
          <path d="m365.38,527.42l-.37-.52c.16.14.29.32.37.52Z" />
          <path
            class="scar-1"
            d="m337.52,575.42l25.7,35.01-2.49,1.43-31.21-38.5c.32.04.65.09.98.14,2.82.46,5.18,1.2,7.02,1.92Z"
          />
          <path
            class="scar-12"
            d="m329.52,573.36l31.21,38.5-3.86,2.58-.16-.25-8.71-12.19-17.88,5.91,11.92,16.43-21.54,14.39c-2.16-.11-4.08-1.35-5-3.23-1.17-2.4-.14-4.69,0-5l-2-2c-1.91-4.73-3.86-10.79-5-18-.76-4.81-1.01-9.2-1-13h-4.54l-3.46-12c.98-.42,2.34-.87,4-1,.74-.06,1.41-.05,2,0,1.15.74,2.83,1.61,5,2,.66.12,1.28.18,1.85.2.81,3.18,1.63,6.36,2.44,9.54l7.71-2.74c-.33-4.67-.67-9.33-1-14-.14-.61-.3-1.7,0-3,.43-1.84,1.53-2.98,2-3.41,1.68-.08,3.72-.04,6.02.27Z"
          />
          <path d="m358.47,517.73c.02.09.03.18.03.27,0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.27,0,.53.07.74.21l.73,1.02Z" />
          <path d="m358.47,517.73l-.73-1.02c.38.2.65.58.73,1.02Z" />
          <path
            class="scar-8"
            d="m356.71,614.19l.16.25-14.83,9.9-11.92-16.43,17.88-5.91,8.71,12.19Zm-8.71-2.19c0-1.66-1.34-3-3-3s-3,1.34-3,3,1.34,3,3,3,3-1.34,3-3Z"
          />
          <circle cx="350" cy="509" r="1.5" />
          <path
            class="scar-8"
            d="m336,550.26l13.5,18.24c-.88.89-2.2,2.02-4,3-2.1,1.14-4.04,1.63-5.37,1.85l-17.63-23.85c-.26-2.59,1.23-4.68,3-5,1.49-.27,2.76.79,3,1,1.03.94,2.35,2,4,3,1.25.76,2.45,1.33,3.5,1.76Zm4,12.24c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5,1.5.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm-3-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5,1.5.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm-3-4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5,1.5.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"
          />
          <path
            class="scar-8"
            d="m339.35,515l9.65,14-3,3-13-17c.4-.33,1.08-.78,2-1,2.23-.52,4.04.77,4.35,1Z"
          />
          <circle cx="345" cy="612" r="3" />
          <path
            class="scar-8"
            d="m346.5,461.5l-36.38,13.03c-1.11-1.58-2.22-3.17-3.32-4.75,4.23-3.42,8.47-6.85,12.7-10.28l1.46,2.35,5.54-4.35c.72.9,1.7,1.97,3,3,.91.72,1.79,1.28,2.58,1.72l-5.58-4.72v-2c3.33-1.67,6.67-3.33,10-5,1.41.45,5.38,1.94,8,6,1.21,1.88,1.75,3.7,2,5Z"
          />
          <path
            class="scar-8"
            d="m338.46,489.67l5.96,8.36-.18.13c-.26-.4-.72-.66-1.24-.66-.83,0-1.5.67-1.5,1.5,0,.31.09.6.26.84l-1.39.97-16.87-24.31,4.5-1.5,3.23,4.52c-.07-.01-.15-.02-.23-.02-.83,0-1.5.67-1.5,1.5s.67,1.5,1.5,1.5c.74,0,1.35-.53,1.47-1.23l5.33,7.47c-.23-.16-.5-.24-.8-.24-.83,0-1.5.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5c0-.11-.01-.23-.04-.33Z"
          />
          <path d="m344.24,498.16c.17.24.26.53.26.84,0,.83-.67,1.5-1.5,1.5-.52,0-.98-.26-1.24-.66-.17-.24-.26-.53-.26-.84,0-.83.67-1.5,1.5-1.5.52,0,.98.26,1.24.66Z" />
          <circle cx="338.5" cy="562.5" r="1.5" />
          <path d="m338.46,489.67c.03.1.04.22.04.33,0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.3,0,.57.08.8.24l.66.93Z" />
          <path d="m338.46,489.67l-.66-.93c.33.2.57.53.66.93Z" />
          <circle cx="335.5" cy="558.5" r="1.5" />
          <circle cx="332.5" cy="554.5" r="1.5" />
          <path d="m332.5,481c0,.09-.01.18-.03.27l-1.24-1.75c.72.11,1.27.73,1.27,1.48Z" />
          <path d="m331.23,479.52l1.24,1.75c-.12.7-.73,1.23-1.47,1.23-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5c.08,0,.16.01.23.02Z" />
          <path d="m326.5,457.5l5.58,4.72c-.79-.44-1.67-1-2.58-1.72-1.3-1.03-2.28-2.1-3-3Z" />
          <path
            class="scar-8"
            d="m321.35,490l9.65,14-3,3-13-17c.4-.33,1.08-.78,2-1,2.23-.52,4.04.77,4.35,1Z"
          />
          <path
            class="scar-12"
            d="m317.24,564.79c2.09,2.77,4.17,5.53,6.26,8.3-.47.43-1.57,1.57-2,3.41-.3,1.3-.14,2.39,0,3,.33,4.67.67,9.33,1,14l-7.71,2.74c-.81-3.18-1.63-6.36-2.44-9.54.48-.12,1.36-.42,2.15-1.2,1.54-1.52,1.4-3.6,1.37-3.93-.46-4.03-.91-8.05-1.37-12.07.03-.57.19-1.78,1-3,.59-.88,1.29-1.42,1.74-1.71Z"
          />
          <path
            class="scar-8"
            d="m310.12,474.53c.46.66.92,1.31,1.38,1.97-.79.79-2.45,2.23-5,3-3.21.98-5.93.32-7,0l-5-9c.76.18,3.94.85,7-1,1.6-.96,2.52-2.22,3-3,.76,1.09,1.53,2.19,2.3,3.28,1.1,1.58,2.21,3.17,3.32,4.75Z"
          />
          <path
            class="scar-9"
            d="m308.5,610.5c1.14,7.21,3.09,13.27,5,18-7.63,3.37-18.89,7.34-33,9-14.26,1.67-26.24.37-34.48-1.17h-.19c-.61-11.43-1.22-22.89-1.83-34.33l9,2c7.23.13,15.28-.11,24-1,11.52-1.17,21.76-3.22,30.5-5.5-.01,3.8.24,8.19,1,13Z"
          />
          <path d="m307.5,520.5c.2.78.46,2.27,0,4-.84,3.17-3.44,4.69-4,5,.92-1.06,2.12-2.72,3-5,.58-1.52.86-2.9,1-4Z" />
          <path
            class="scar-8"
            d="m304.5,466.5c-.48.78-1.4,2.04-3,3-3.06,1.85-6.24,1.18-7,1-1.58-3.26-.85-6.73,1-8,2.24-1.54,6.95-.41,9,4Z"
          />
          <path
            class="scar-8"
            d="m294.5,470.5l5,9c-2.33,1.33-4.67,2.67-7,4-2.01-2.58-4.01-5.15-6.01-7.73l8.01-5.27Z"
          />
          <path d="m298.5,531.5l-5-7-1-3-3-2-2-3-5-6,6,5c.07.59.14,1.17.21,1.76,1.18.48,2.5,1.19,3.79,2.24,4.99,4.04,5.82,10.13,6,12Z" />
          <path
            class="scar-8"
            d="m286.49,475.77c2,2.58,4,5.15,6.01,7.73-.97,1.25-2.88,3.39-6,5-3.33,1.72-6.4,1.98-8,2l-5-5-9-12.72c.36.21,2.27,1.25,4.79,1.28h.01c.06.01.13,0,.2,0,.01-.01.02-.01.03,0,.93-.02,1.94-.17,2.97-.56,2.35-.89,4.21-2.73,5.21-5.04.05-.12.11-.23.15-.34.32-.83.54-1.71.64-2.62,2.66,3.42,5.33,6.85,7.99,10.27Z"
          />
          <path
            class="scar-8"
            d="m275.55,465.06l2.31,3.06c-.04.11-.1.22-.15.34-.39.85-1.06,1.99-2.21,3.04-2.24,2.06-4.85,2.46-5.97,2.56,0-.01-.02-.01-.03,0h-.21l-3.41-4.72-19.38-26.84c1.56-.24,3.71-.77,6-2,1.2-.64,2.2-1.35,3-2,6.68,8.85,13.36,17.71,20.05,26.56Z"
          />
          <path
            class="scar-8"
            d="m265.88,469.34l3.41,4.72c-2.52-.03-4.43-1.07-4.79-1.28.2-1,.59-2.24,1.38-3.44Z"
          />
          <path
            class="scar-8"
            d="m256.5,433.5c.83,1.55.46,3.62-1,5-.8.65-1.8,1.36-3,2-2.29,1.23-4.44,1.76-6,2-1.77.73-3.78.32-5-1-1.51-1.63-1.04-3.81-1-4,.78.43,3.13,1.58,6,1,3.32-.67,6.02-3.4,7-7,.23.03,2.11.34,3,2Z"
          />
          <path
            class="scar-8"
            d="m252.5,430.5c.41-.01.76.51,1,1-.98,3.6-3.68,6.33-7,7-2.87.58-5.22-.57-6-1v-2c.93.28,3.27.85,6,0,3.85-1.2,5.63-4.31,6-5Z"
          />
          <path
            class="scar-8"
            d="m243.5,414.5c3,5.33,6,10.67,9,16-.37.69-2.15,3.8-6,5-2.73.85-5.07.28-6,0h-3.67l-10.33-13c1.36-1.7,4.6-5.24,10-7,2.76-.9,5.23-1.05,7-1Z"
          />
          <path
            class="scar-9"
            d="m245.83,636.34c.07,1.18.13,2.35.19,3.53-.87.97-1.56,1.14-2.02,1.13-2.07-.06-4.02-4.05-5-10-.67-10.33-1.33-20.67-2-31,.34-.27,1.46-1.08,3-1,1.71.09,3.26,1.27,4,3,.61,11.44,1.22,22.9,1.83,34.34Z"
          />
        </g>
        <g>
          <polyline
            class="scar-4"
            points="393.48 566.83 408.98 588.56 402.96 591 340.37 500.81 323.5 476.5 328 475 331.23 479.52"
          />
          <line class="scar-4" x1="386.5" y1="557.03" x2="392.65" y2="565.66" />
          <line
            class="scar-4"
            x1="378.48"
            y1="545.79"
            x2="385.46"
            y2="555.58"
          />
          <line
            class="scar-4"
            x1="372.46"
            y1="537.34"
            x2="377.69"
            y2="544.68"
          />
          <line
            class="scar-4"
            x1="365.38"
            y1="527.42"
            x2="371.15"
            y2="535.52"
          />
          <line class="scar-4" x1="358.47" y1="517.73" x2="365.01" y2="526.9" />
          <polyline
            class="scar-4"
            points="338.46 489.67 344.42 498.03 357.74 516.71"
          />
          <line class="scar-4" x1="332.47" y1="481.27" x2="337.8" y2="488.74" />
          <polyline
            class="scar-4"
            points="323.5 476.5 318.5 478.5 399.65 592.4 402.96 591"
          />
          <polyline
            class="scar-4"
            points="329.51 573.35 329.52 573.36 360.73 611.86 363.22 610.43"
          />
          <path
            class="scar-4"
            d="m318.5,478.5c-.58.06-1.26.08-2,0-2.34-.25-4.07-1.31-5-2-.79.79-2.45,2.23-5,3-3.21.98-5.93.32-7,0-2.33,1.33-4.67,2.67-7,4-.97,1.25-2.88,3.39-6,5-3.33,1.72-6.4,1.98-8,2l-5-5c-1.05.16-3.01.59-5,2-2.47,1.75-3.58,4-4,5l5,9c15.91,21.09,31.83,42.19,47.74,63.29,2.09,2.77,4.17,5.53,6.26,8.3,1.68-.08,3.72-.04,6.02.27.32.04.65.09.98.14,2.82.46,5.18,1.2,7.02,1.92l25.7,35.01c2.9-1.44,5.8-2.87,8.7-4.31,2.3-1.13,4.6-2.27,6.89-3.4,6.95-3.44,13.9-6.88,20.84-10.32"
          />
          <path
            class="scar-4"
            d="m292.5,483.5c-2.01-2.58-4.01-5.15-6.01-7.73-2.66-3.42-5.33-6.85-7.99-10.27-.99-.25-1.98-.39-2.95-.44"
          />
          <path
            class="scar-4"
            d="m265.88,469.34c-.79,1.2-1.18,2.44-1.38,3.44l9,12.72"
          />
          <path class="scar-4" d="m277.86,468.12c.32-.83.54-1.71.64-2.62" />
          <path
            class="scar-4"
            d="m269.53,474.06c.93-.02,1.94-.17,2.97-.56,2.35-.89,4.21-2.73,5.21-5.04"
          />
          <path
            class="scar-4"
            d="m264.5,472.78c.36.21,2.27,1.25,4.79,1.28h.21"
          />
          <polyline
            class="scar-4"
            points="286.42 475.82 286.49 475.77 294.5 470.5 299.5 479.5"
          />
          <path
            class="scar-4"
            d="m311.5,476.5c-.46-.66-.92-1.31-1.38-1.97-1.11-1.58-2.22-3.17-3.32-4.75-.77-1.09-1.54-2.19-2.3-3.28-.48.78-1.4,2.04-3,3-3.06,1.85-6.24,1.18-7,1-1.58-3.26-.85-6.73,1-8,2.24-1.54,6.95-.41,9,4"
          />
          <path
            class="scar-4"
            d="m358.5,549.5l25,37c.53.14,1.66.36,3,0,.93-.25,1.6-.69,2-1-8.43-12.59-16.85-25.19-25.28-37.78-1.19-.93-2.74-.99-3.72-.22-.87.68-.98,1.77-1,2Z"
          />
          <path
            class="scar-4"
            d="m310.12,474.53l36.38-13.03c-.25-1.3-.79-3.12-2-5-2.62-4.06-6.59-5.55-8-6-3.33,1.67-6.67,3.33-10,5v2l-5.54,4.35-1.46-2.35c-4.23,3.43-8.47,6.86-12.7,10.28"
          />
          <path
            class="scar-4"
            d="m255.5,438.5c6.68,8.85,13.36,17.71,20.05,26.56l2.31,3.06c-.04.11-.1.22-.15.34-.39.85-1.06,1.99-2.21,3.04-2.24,2.06-4.85,2.46-5.97,2.56,0-.01-.02-.01-.03,0-.07,0-.14.01-.2.01h-.01s-3.41-4.73-3.41-4.73l-19.38-26.84"
          />
          <path
            class="scar-4"
            d="m240.5,437.5c.78.43,3.13,1.58,6,1,3.32-.67,6.02-3.4,7-7,.23.03,2.11.34,3,2,.83,1.55.46,3.62-1,5-.8.65-1.8,1.36-3,2-2.29,1.23-4.44,1.76-6,2-1.77.73-3.78.32-5-1-1.51-1.63-1.04-3.81-1-4Z"
          />
          <path class="scar-4" d="m252.5,430.5c.41-.01.76.51,1,1" />
          <path class="scar-4" d="m240.5,437.5v-2" />
          <path
            class="scar-4"
            d="m240.5,435.5h-3.67l-10.33-13c1.36-1.7,4.6-5.24,10-7,2.76-.9,5.23-1.05,7-1,3,5.33,6,10.67,9,16-.37.69-2.15,3.8-6,5-2.73.85-5.07.28-6,0Z"
          />
          <path
            class="scar-4"
            d="m317.24,564.79c-.45.29-1.15.83-1.74,1.71-.81,1.22-.97,2.43-1,3,.46,4.02.91,8.04,1.37,12.07.03.33.17,2.41-1.37,3.93-.79.78-1.67,1.08-2.15,1.2.81,3.18,1.63,6.36,2.44,9.54l7.71-2.74c-.33-4.67-.67-9.33-1-14-.14-.61-.3-1.7,0-3,.43-1.84,1.53-2.98,2-3.41"
          />
          <path
            class="scar-4"
            d="m322.5,549.5l17.63,23.85c1.33-.22,3.27-.71,5.37-1.85,1.8-.98,3.12-2.11,4-3l-13.5-18.24c-1.05-.43-2.25-1-3.5-1.76-1.65-1-2.97-2.06-4-3-.24-.21-1.51-1.27-3-1-1.77.32-3.26,2.41-3,5Z"
          />
          <path
            class="scar-4"
            d="m360.73,611.86l-3.86,2.58-14.83,9.9-21.54,14.39c-2.16-.11-4.08-1.35-5-3.23-1.17-2.4-.14-4.69,0-5l-2-2c-1.91-4.73-3.86-10.79-5-18-.76-4.81-1.01-9.2-1-13h-4.54l-3.46-12c.98-.42,2.34-.87,4-1,.74-.06,1.41-.05,2,0,1.15.74,2.83,1.61,5,2,.66.12,1.28.18,1.85.2"
          />
          <path
            class="scar-4"
            d="m246.02,636.33c8.24,1.54,20.22,2.84,34.48,1.17,14.11-1.66,25.37-5.63,33-9"
          />
          <path
            class="scar-4"
            d="m307.5,597.5c-8.74,2.28-18.98,4.33-30.5,5.5-8.72.89-16.77,1.13-24,1l-9-2c-.74-1.73-2.29-2.91-4-3-1.54-.08-2.66.73-3,1,.67,10.33,1.33,20.67,2,31,.98,5.95,2.93,9.94,5,10,.46.01,1.15-.16,2.02-1.13-.06-1.18-.12-2.35-.19-3.53-.61-11.44-1.22-22.9-1.83-34.34"
          />
          <polyline
            class="scar-4"
            points="342.04 624.34 330.12 607.91 348 602 356.71 614.19"
          />
          <polyline
            class="scar-4"
            points="371.73 605.74 369 600 376 597.5 378.81 602.72"
          />
          <path
            class="scar-4"
            d="m369,600c-.91-.13-3.66-.63-6-3-2.75-2.79-2.97-6.19-3-7,2.08-1.46,4.16-2.93,6.24-4.39l1.76,2.39c2.74-.34,5.44.81,7,3,2.06,2.88,1.12,6.12,1,6.5"
          />
          <path
            class="scar-4"
            d="m333,515l13,17,3-3-9.65-14c-.31-.23-2.12-1.52-4.35-1-.92.22-1.6.67-2,1Z"
          />
          <path
            class="scar-4"
            d="m315,490l13,17,3-3-9.65-14c-.31-.23-2.12-1.52-4.35-1-.92.22-1.6.67-2,1Z"
          />
          <line class="scar-3" x1="318" y1="601" x2="325" y2="627" />
          <path
            class="scar-3"
            d="m282.5,510.5l5,6,2,3,3,2,1,3,5,7c-.18-1.87-1.01-7.96-6-12-1.29-1.05-2.61-1.76-3.79-2.24-.07-.59-.14-1.17-.21-1.76l-6-5Z"
          />
          <path
            class="scar-3"
            d="m307.5,520.5c.2.78.46,2.27,0,4-.84,3.17-3.44,4.69-4,5,.92-1.06,2.12-2.72,3-5,.58-1.52.86-2.9,1-4Z"
          />
          <line class="scar-3" x1="298.5" y1="493.5" x2="308.5" y2="498.5" />
          <path
            class="scar-3"
            d="m326.5,457.5c.72.9,1.7,1.97,3,3,.91.72,1.79,1.28,2.58,1.72"
          />
          <path
            class="scar-3"
            d="m331.23,479.52c.72.11,1.27.73,1.27,1.48,0,.09-.01.18-.03.27-.12.7-.73,1.23-1.47,1.23-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5c.08,0,.16.01.23.02Z"
          />
          <path
            class="scar-3"
            d="m338.46,489.67c.03.1.04.22.04.33,0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.3,0,.57.08.8.24.33.2.57.53.66.93Z"
          />
          <path
            class="scar-3"
            d="m344.24,498.16c.17.24.26.53.26.84,0,.83-.67,1.5-1.5,1.5-.52,0-.98-.26-1.24-.66-.17-.24-.26-.53-.26-.84,0-.83.67-1.5,1.5-1.5.52,0,.98.26,1.24.66Z"
          />
          <circle class="scar-3" cx="350" cy="509" r="1.5" />
          <path
            class="scar-3"
            d="m358.47,517.73c.02.09.03.18.03.27,0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.27,0,.53.07.74.21.38.2.65.58.73,1.02Z"
          />
          <path
            class="scar-3"
            d="m365.38,527.42c.08.18.12.37.12.58,0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.39,0,.75.15,1.01.4.16.14.29.32.37.52Z"
          />
          <path
            class="scar-3"
            d="m371.15,535.52c.76.06,1.35.7,1.35,1.48,0,.12-.01.23-.04.34-.15.67-.75,1.16-1.46,1.16-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5c.05,0,.1,0,.15.02Z"
          />
          <path
            class="scar-3"
            d="m378.48,545.79c0,.07.02.14.02.21,0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.25,0,.48.06.69.18.42.21.73.62.79,1.11Z"
          />
          <path
            class="scar-3"
            d="m385.46,555.58c.6.19,1.04.75,1.04,1.42v.03c-.02.82-.68,1.47-1.5,1.47s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.16,0,.32.03.46.08Z"
          />
          <path
            class="scar-3"
            d="m393.48,566.83c.02.05.02.11.02.17,0,.83-.67,1.5-1.5,1.5s-1.5-.67-1.5-1.5.67-1.5,1.5-1.5c.23,0,.45.05.65.16.45.21.78.64.83,1.17Z"
          />
          <circle class="scar-3" cx="398" cy="576" r="1.5" />
          <circle class="scar-3" cx="404" cy="585" r="1.5" />
        </g>
      </g>
      <path
        class="scar-6"
        d="m369,607.58l-28.87-34.22c-1.42-1.07-3.46-2.36-6.13-3.35-3.67-1.36-6.92-1.55-9-1.5-18.67-25.67-37.33-51.33-56-77l-4.5,1,17.45,25.5,41.55,55.09c2.27-.04,5.2.13,8.5.91,2.32.55,4.31,1.28,5.95,2,8.18,11.15,16.37,22.3,24.55,33.45l6.5-1.87Z"
      />
      <path
        class="scar-10"
        d="m264.5,492.5l30.24-1,23.76-13-7-2c-1.03.68-2.36,1.42-4,2-3.27,1.17-6.19,1.16-8,1-2.33,1.22-4.67,2.43-7,3.65-.97,1.22-2.58,2.93-5,4.35-3.69,2.17-7.23,2.47-9,2.5-1.67-1.5-3.33-3-5-4.5-.97.03-2.44.22-4,1-3.5,1.76-4.73,5.16-5,6Z"
      />
      <path
        class="scar-10"
        d="m312.35,586.7l2.09,9.54,8.07-2.74c0-3.62-.26-6.51-.5-8.5-.44-3.61-1.03-6.01,0-9,.45-1.31,1.06-2.3,1.5-2.91-2.09-2.77-4.17-5.53-6.26-8.3l-2.7,4.33,1.46,12.88c0,.38-.01,1.74-1,3-.98,1.26-2.28,1.61-2.65,1.7Z"
      />
      <polygon
        class="scar-10"
        points="323.5 573.09 356.71 614.19 360.73 611.86 329.65 573.53 323.5 573.09"
      />
      <polygon
        class="scar-10"
        points="307.5 597.66 314.43 596.24 312.35 586.7 305.5 584.5 299.5 585.5 302.82 597.01 307.5 597.66"
      />
      <polygon class="scar-10" points="318 601 323.5 627 325 627 318 601" />
      <path d="m226.5,422.5c1.52-1.7,4.63-4.68,9.5-6.5,3.37-1.26,6.38-1.51,8.39-1.51-5.96,2.67-11.93,5.34-17.89,8.01Z" />
      <path d="m244.53,612c7.7,1.86,19.82,3.8,34.47,2,12.4-1.52,22.26-5.18,28.89-8.26-8.06,2.54-18.14,4.99-29.89,6.26-13.04,1.41-24.45.98-33.47,0Z" />
      <path d="m244.98,620.31c8.49,1.48,20.66,2.62,35.02.69,11.98-1.6,21.79-4.84,28.97-7.83-8.31,2.32-18.03,4.46-28.97,5.83-13.22,1.66-25.07,1.81-35.02,1.31Z" />
      <polygon
        class="scar-11"
        points="235 419 284.02 489.55 288.71 486.72 237.15 417.74 235 419"
      />
      <path d="m245.33,627c8.45,2.32,24.13,5.33,42.67,1,9.31-2.18,16.83-5.61,22.38-8.75-6.77,2.58-14.95,5.07-24.38,6.75-16.23,2.89-30.32,2.3-40.67,1Z" />
      <polyline
        class="scar-11"
        points="299.5 470.38 303.5 480 308.5 478.11 301.99 469.17 300 470"
      />
      <polygon
        class="scar-11"
        points="344.35 456.28 332.08 462.22 309.35 473.43 310.45 475 346.5 461.5 344.35 456.28"
      />
      <polygon
        class="scar-7"
        points="240 599 244 641 246.02 639.87 243.81 601.62 240 599"
      />
      <path
        class="scar-7"
        d="m246.02,632.43c7.93,1.74,20.2,3.46,34.98,1.57,13.61-1.74,24.31-5.89,31.35-9.27l.97,3.85c-7.67,3.2-18.29,6.73-31.32,8.42-14.72,1.91-27.2.8-35.98-.67v-3.89Z"
      />
      <polygon
        class="scar-4"
        points="328 475 408.98 588.56 402.96 591 323.5 476.5 328 475"
      />
      <polyline
        class="scar-4"
        points="323.5 476.5 318.5 478.5 399.65 592.4 402.96 591"
      />
      <polyline
        class="scar-4"
        points="329.51 573.35 360.73 611.86 363.22 610.43"
      />
      <path
        class="scar-4"
        d="m318.5,478.5c-.58.06-1.26.08-2,0-2.34-.25-4.07-1.31-5-2-.79.79-2.45,2.23-5,3-3.21.98-5.93.32-7,0-2.33,1.33-4.67,2.67-7,4-.97,1.25-2.88,3.39-6,5-3.33,1.72-6.4,1.98-8,2l-5-5c-1.05.16-3.01.59-5,2-2.47,1.75-3.58,4-4,5l5,9c18,23.86,36,47.72,54,71.59,1.92-.08,4.3-.03,7,.41,2.82.46,5.18,1.2,7.02,1.92l25.7,35.01,36.43-18.04"
      />
      <path
        class="scar-4"
        d="m292.5,483.5c-2.01-2.58-4.01-5.15-6.01-7.73-2.66-3.42-5.33-6.85-7.99-10.27-.99-.25-1.98-.39-2.95-.44"
      />
      <path
        class="scar-4"
        d="m265.88,469.34c-.79,1.2-1.18,2.44-1.38,3.44l9,12.72"
      />
      <path
        class="scar-4"
        d="m264.5,472.78c.5.28,4.02,2.22,8,.72,3.29-1.24,5.61-4.35,6-8"
      />
      <polyline class="scar-4" points="286.42 475.82 294.5 470.5 299.5 479.5" />
      <path
        class="scar-4"
        d="m311.5,476.5c-2.33-3.33-4.67-6.67-7-10-.48.78-1.4,2.04-3,3-3.06,1.85-6.24,1.18-7,1-1.58-3.26-.85-6.73,1-8,2.24-1.54,6.95-.41,9,4"
      />
      <path
        class="scar-4"
        d="m358.5,549.5l25,37c.53.14,1.66.36,3,0,.93-.25,1.6-.69,2-1-8.43-12.59-16.85-25.19-25.28-37.78-1.19-.93-2.74-.98-3.72-.22-.87.68-.98,1.77-1,2Z"
      />
      <path
        class="scar-4"
        d="m310.12,474.53l36.38-13.03c-.25-1.3-.79-3.12-2-5-2.62-4.06-6.59-5.55-8-6-3.33,1.67-6.67,3.33-10,5v2l-5.54,4.35-1.46-2.35c-4.23,3.43-8.47,6.86-12.7,10.28"
      />
      <path
        class="scar-4"
        d="m255.5,438.5c7.45,9.87,14.9,19.75,22.36,29.62-.36.86-1.05,2.18-2.36,3.38-2.41,2.22-5.25,2.51-6.2,2.57l-22.8-31.57"
      />
      <path
        class="scar-4"
        d="m240.5,437.5c.78.43,3.13,1.58,6,1,3.32-.67,6.02-3.4,7-7,.23.03,2.11.34,3,2,.83,1.55.46,3.62-1,5-.8.65-1.8,1.36-3,2-2.29,1.23-4.44,1.76-6,2-1.77.73-3.78.32-5-1-1.51-1.63-1.04-3.81-1-4Z"
      />
      <path class="scar-4" d="m252.5,430.5c.41-.01.76.51,1,1" />
      <path class="scar-4" d="m240.5,437.5v-2" />
      <path
        class="scar-4"
        d="m226.5,422.5c1.36-1.7,4.6-5.24,10-7,2.76-.9,5.23-1.05,7-1,3,5.33,6,10.67,9,16-.37.69-2.15,3.8-6,5-2.73.85-5.07.28-6,0h-3.67l-10.33-13Z"
      />
      <path
        class="scar-4"
        d="m317.24,564.79c-.45.29-1.15.83-1.74,1.71-.81,1.22-.97,2.43-1,3,.46,4.02.91,8.04,1.37,12.07.03.33.17,2.41-1.37,3.93-.79.78-1.67,1.08-2.15,1.2.82,3.18,1.63,6.36,2.45,9.54l7.71-2.74c-.33-4.67-.67-9.33-1-14-.14-.61-.3-1.7,0-3,.43-1.84,1.53-2.98,2-3.41"
      />
      <path
        class="scar-4"
        d="m322.5,549.5l17.63,23.85c1.33-.23,3.27-.71,5.37-1.85,1.8-.98,3.12-2.11,4-3l-13.5-18.24c-1.05-.43-2.25-1-3.5-1.76-1.65-1-2.97-2.06-4-3-.24-.21-1.51-1.27-3-1-1.77.32-3.26,2.41-3,5Z"
      />
      <path
        class="scar-4"
        d="m360.73,611.86l-40.23,26.87c-2.16-.11-4.08-1.35-5-3.23-1.17-2.4-.14-4.69,0-5-.67-.67-1.33-1.33-2-2-1.91-4.73-3.86-10.79-5-18-.76-4.81-1.01-9.2-1-13h-4.54l-3.46-12c.98-.42,2.34-.87,4-1,.74-.06,1.41-.05,2,0,1.15.74,2.83,1.61,5,2,.66.12,1.28.18,1.85.2"
      />
      <path
        class="scar-4"
        d="m246.02,636.33c8.24,1.54,20.22,2.85,34.48,1.17,14.11-1.66,25.37-5.63,33-9"
      />
      <path
        class="scar-4"
        d="m307.5,597.5c-8.74,2.28-18.98,4.33-30.5,5.5-8.72.89-16.77,1.13-24,1l-9-2c-.74-1.73-2.29-2.91-4-3-1.54-.08-2.66.73-3,1,.67,10.33,1.33,20.67,2,31,.98,5.95,2.93,9.94,5,10,.46.01,1.15-.16,2.02-1.13-.67-12.62-1.35-25.25-2.02-37.87"
      />
      <polyline
        class="scar-4"
        points="342.04 624.34 330.12 607.91 348 602 356.71 614.19"
      />
      <polyline
        class="scar-4"
        points="371.73 605.74 369 600 376 597.5 378.81 602.72"
      />
      <path
        class="scar-4"
        d="m369,600c-.91-.13-3.66-.63-6-3-2.75-2.79-2.97-6.19-3-7,2.08-1.46,4.16-2.93,6.24-4.39l1.76,2.39c2.74-.34,5.44.81,7,3,2.06,2.88,1.12,6.12,1,6.5"
      />
      <path
        class="scar-4"
        d="m333,515l13,17,3-3-9.65-14c-.31-.23-2.12-1.52-4.35-1-.92.22-1.6.67-2,1Z"
      />
      <path
        class="scar-4"
        d="m315,490l13,17,3-3-9.65-14c-.31-.23-2.12-1.52-4.35-1-.92.22-1.6.67-2,1Z"
      />
      <circle cx="345" cy="612" r="3" />
      <line class="scar-5" x1="318" y1="601" x2="325" y2="627" />
      <path
        class="scar-5"
        d="m282.5,510.5l5,6,2,3,3,2,1,3,5,7c-.18-1.87-1.01-7.96-6-12-1.29-1.05-2.61-1.76-3.79-2.24-.07-.59-.14-1.17-.21-1.76l-6-5Z"
      />
      <path
        class="scar-5"
        d="m307.5,520.5c.2.78.46,2.27,0,4-.84,3.17-3.44,4.69-4,5,.92-1.06,2.12-2.72,3-5,.58-1.52.86-2.9,1-4Z"
      />
      <line class="scar-5" x1="298.5" y1="493.5" x2="308.5" y2="498.5" />
      <path
        class="scar-5"
        d="m326.5,457.5c.72.9,1.7,1.97,3,3,.91.72,1.79,1.28,2.58,1.72"
      />
      <circle class="scar-5" cx="331" cy="481" r="1.5" />
      <circle class="scar-5" cx="337" cy="490" r="1.5" />
      <circle class="scar-5" cx="343" cy="499" r="1.5" />
      <circle class="scar-5" cx="350" cy="509" r="1.5" />
      <circle class="scar-5" cx="357" cy="518" r="1.5" />
      <circle class="scar-5" cx="364" cy="528" r="1.5" />
      <circle class="scar-5" cx="371" cy="537" r="1.5" />
      <circle class="scar-5" cx="377" cy="546" r="1.5" />
      <circle class="scar-5" cx="385" cy="557" r="1.5" />
      <circle class="scar-5" cx="392" cy="567" r="1.5" />
      <circle class="scar-5" cx="398" cy="576" r="1.5" />
      <circle class="scar-5" cx="404" cy="585" r="1.5" />
      <circle cx="332.5" cy="554.5" r="1.5" />
      <circle cx="335.5" cy="558.5" r="1.5" />
      <circle cx="338.5" cy="562.5" r="1.5" />
    </g>
  );
};

export default Scar;
