import { API_ROUTE, errMessage } from "../utils/constants";
import useMiddleware from "./useMiddleware";

export const cancelMatch = async (useMiddleware, match_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/match/cancel`, { match_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const resetMatch = async (useMiddleware, match_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/match/reset`, { match_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const forceWin = async (
  useMiddleware,
  match_id,
  winning_team_id,
  losing_team_id
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/match/forcewin`, {
      match_id,
      winning_team_id,
      losing_team_id,
    })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getDisputes = async (useMiddleware, skip) => {
  const params = { skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/disputes`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getMaps = async (useMiddleware, skip) => {
  const params = { skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/maps/`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getReferrals = async (useMiddleware, skip) => {
  const params = { skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/referrals/`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const createReferralCode = async (useMiddleware, code) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/referrals/create`, { code })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};



export const searchUser = async (useMiddleware, user_id) => {
  const params = { user_id };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/user`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const adminUserSearch = async (useMiddleware, user, skip) => {
  const params = { user, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/user/search`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const banUser = async (
  useMiddleware,
  user_id,
  reason,
  ban_length,
  ban_evidence
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/ban`, {
      user_id,
      reason,
      ban_length,
      ban_evidence,
    })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const chatUnbanUser = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/chat/unban`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const chatBanUser = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/chat/ban`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const unlockFunds = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/unlockbones`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const forceAnticheat = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/forceac`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const forceStream = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/forcestream`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const lockTipping = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/locktipping`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const verifyUserSecurity = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/security/check`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const unbanUser = async (
  useMiddleware,
  user_id,
  dont_charge = false
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/unban`, { user_id, dont_charge })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const setUnbanDate = async (
  useMiddleware,
  user_id,
  timestamp,
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/setunbandate`, { user_id, timestamp })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};


export const unlinkPaypal = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/unlinkpp`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const giveVip = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/givevip`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const giveBp = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/givebp`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const clearBanDebt = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/clearbandebt`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const resetBalance = async (useMiddleware, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/resetbalance`, { user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const unlinkEpic = async (useMiddleware, user_id, must_pay) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/unlinkepic`, { user_id, must_pay })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserAlts = async (useMiddleware, user_id) => {
  const params = { user_id };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/user/alts`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserPurchasesAdmin = async (useMiddleware, user_id, skip) => {
  const params = { user_id, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/user/purchases`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getAutoLogs = async (useMiddleware, user_id, skip) => {
  const params = { user_id, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/user/logs`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getACFlags = async (useMiddleware, user_id, skip) => {
  const params = { user_id, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/ac/flags`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const deleteLogs = async (useMiddleware, log) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/ac/flags/delete`, { log })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getDiscordTranscripts = async (useMiddleware, user_id, skip) => {
  const params = { user_id, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/user/discord/transcripts`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getMoneyLogs = async (useMiddleware, user_id, skip) => {
  const params = { user_id, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/user/logs/money`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserTips = async (useMiddleware, user_id, skip) => {
  const params = { user_id, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/tips`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserHistory = async (
  useMiddleware,
  user_id,
  skip,
  winners,
  losers,
  state
) => {
  const params = { user_id, skip, winners, losers, state };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/user/history`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserDeposits = async (useMiddleware, user_id, skip) => {
  const params = { user_id, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/user/deposits`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserWithdrawals = async (useMiddleware, user_id, skip) => {
  const params = { user_id, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/user/withdrawals`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const promoteUser = async (useMiddleware, user_id, role) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/promote`, { user_id, role })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const sauceBones = async (
  useMiddleware,
  user_id,
  amount,
  must_repay
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/saucebones`, { user_id, amount, must_repay })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const setSalary = async (
  useMiddleware,
  user_id,
  amount,
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/setsalary`, { user_id, amount })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};


export const giveSnipes = async (useMiddleware, user_id, amount) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/givesnipes`, { user_id, amount })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const addExpense = async (
  useMiddleware,
  type,
  payment_method,
  paid_to,
  description,
  amount
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/expense/add`, {
      type,
      payment_method,
      paid_to,
      description,
      amount,
    })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getPendingWithdrawals = async (useMiddleware, skip) => {
  const params = { skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/withdrawals`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const getPendingChargebacks = async (useMiddleware, skip) => {
  const params = { skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/chargebacks`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const updateWithdrawal = async (
  useMiddleware,
  withdrawal_id,
  withdrawal_status
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/withdrawals/update`, {
      withdrawal_id,
      withdrawal_status,
    })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const updateChargeback = async (
  useMiddleware,
  chargeback_id,
  chargeback_status
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/chargebacks/update`, {
      chargeback_id,
      chargeback_status,
    })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getProfitStats = async (useMiddleware, type) => {
  const params = { type };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/profit/stats`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const getExpenseStats = async (useMiddleware, skip) => {
  const params = { skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/expense/get`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const punishUser = async (useMiddleware, type, points, user_id_to_punish) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/punish`, { type,points, user_id_to_punish })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const toggleAvatarPiece = async (useMiddleware, action, id, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/locker`, { action, id, user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const addNote = async (useMiddleware, note, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/user/notes`, { note, user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getNotes = async (useMiddleware, user_id, skip) => {
  const params = { user_id, skip };
  return await useMiddleware
    .get(`${API_ROUTE}/staff/user/notes`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getACUsers = async (useMiddleware, skip) => {
  const params = { skip };

  return await useMiddleware
    .get(`${API_ROUTE}/staff/ac/list`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const getSecurityCheckUsers = async (useMiddleware, skip) => {
  const params = { skip };

  return await useMiddleware
    .get(`${API_ROUTE}/staff/ac/check`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
export const getStaffListUsers = async (useMiddleware, skip) => {
  const params = { skip };

  return await useMiddleware
    .get(`${API_ROUTE}/staff/current/list`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getStaffStatsListUsers = async (useMiddleware, skip) => {
  const params = { skip };

  return await useMiddleware
    .get(`${API_ROUTE}/staff/current/stats`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getACRecentActivity = async (useMiddleware, skip) => {
  const params = { skip };

  return await useMiddleware
    .get(`${API_ROUTE}/staff/ac/activity`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getUserACActivity = async (useMiddleware, user_id, skip) => {
  const params = { user_id, skip };

  return await useMiddleware
    .get(`${API_ROUTE}/staff/user/ac`, { params })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const revertTip = async (useMiddleware, tip_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/staff/tips/revert`, { tip_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
