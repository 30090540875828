import { Grid, Typography } from "@mui/material";
import {
  bestOfOptions,
  newGameModeOptions,
  warzoneRegionOptions,
} from "../../../utils/options";
import {
  cardLight,
  doubtedBlue,
  normal,
  text,
} from "../../../utils/themeContstants";
import CustomDropdown from "../../custom/CustomDropdown";
import CustomRadio from "../../custom/CustomRadio";
import RadioListItem from "../../custom/RadioListItem";

const WarzoneCreate = ({
  game,
  setGameMode,
  platform,
  setPlatform,
  setRegion,
  setBestOf,
  mustStream,
  setMustStream,
}) => {
  const styles = {
    width: {
      width: "100%",
    },
    subLabel: {
      fontSize: normal,
      fontWeight: 600,
      color: "#d7e4f1",
    },
  };

  return (
    <>
      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Platform</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 4 }}
            >
              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <CustomRadio
                    onClick={() => setPlatform(null)}
                    selected={platform == null}
                    color={doubtedBlue}
                    checkColor={text}
                  />

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: normal,
                        fontWeight: 600,
                        color: text,
                      }}
                    >
                      All Platforms
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  gap={{ xs: 1 }}
                >
                  <CustomRadio
                    onClick={() => setPlatform("console")}
                    selected={platform === "console"}
                    color={doubtedBlue}
                    checkColor={text}
                  />

                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: normal,
                        fontWeight: 600,
                        color: text,
                      }}
                    >
                      Console Only
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {platform !== "console" && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <Grid item>
              <Typography sx={styles.subLabel}>
                PC Stream Requirements
              </Typography>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 1 }}
              >
                <RadioListItem
                  title="No Streaming Required"
                  description="PC Players are not required to stream this match"
                  value={false}
                  selected={!mustStream}
                  onClick={() => setMustStream(false)}
                  backgroundColorHover={cardLight}
                />
                <RadioListItem
                  title="Streaming Required"
                  description="PC Players must stream this match"
                  value={true}
                  selected={mustStream}
                  onClick={() => setMustStream(true)}
                  backgroundColorHover={cardLight}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Game mode</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={newGameModeOptions(game)}
              placeholder="Game mode"
              onChange={(value) => setGameMode([value])}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Region</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={warzoneRegionOptions}
              placeholder="Region"
              onChange={(value) => setRegion([value])}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={styles.width}>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap={{ xs: 1 }}
        >
          <Grid item>
            <Typography sx={styles.subLabel}>Best of</Typography>
          </Grid>

          <Grid item sx={styles.width}>
            <CustomDropdown
              options={bestOfOptions}
              placeholder="Best of"
              onChange={(value) => setBestOf(value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WarzoneCreate;
