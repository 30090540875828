const Vlone = ({ color }) => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: color,
    },
    three: {
      fill: "#5465ff",
    },
  };

  return (
    <g>
      <g id="Vlone_T" data-name="Vlone T">
        <path d="m450.27,570.05c-.01,1.33-.14,2.64-.28,3.95l-.12.98c-.03.33-.12.72-.22,1.06-.2.71-.51,1.4-.93,2.02-.81,1.27-2.06,2.17-3.12,2.89-.52.36-.86.67-1.21,1.11-.31.44-.6.92-.79,1.46-.41,1.07-.59,2.29-.62,3.5-.02.61,0,1.22.06,1.85.05.59.15,1.15.32,1.7.69,2.22,2.35,4.17,4.18,5.87,1.85,1.71,3.95,3.2,6.12,4.54,2.15,1.38,4.47,2.48,6.82,3.51,4.73,1.97,9.73,3.33,14.8,4.05,1.25.18,2.58.33,3.76.38,1.29,0,2.58.03,3.86,0,2.56-.12,5.11-.35,7.63-.82,5.03-.91,9.97-2.48,14.48-4.86,2.25-1.18,4.45-2.51,6.39-4.11.49-.39.94-.83,1.41-1.24.45-.43.86-.89,1.29-1.33l.55-.71c.2-.23.37-.47.5-.72.26-.42.66-1.15.94-1.65.57-1.05.89-2.1.81-3.18-.05-1.09-.45-2.17-1.07-3.16-1.25-1.99-3.27-3.61-5.41-4.86l.98-1.74c1.2.67,2.33,1.44,3.39,2.33,1.06.89,2.04,1.91,2.83,3.13.78,1.21,1.35,2.65,1.44,4.18.14,1.54-.35,3.09-1.02,4.33-.35.65-.58,1.07-.99,1.77-.18.33-.38.62-.6.89l-.65.84c-.47.5-.93,1.03-1.43,1.5-.51.45-1,.93-1.53,1.36-2.09,1.74-4.38,3.17-6.75,4.42-4.75,2.51-9.9,4.14-15.15,5.1-2.63.49-5.29.73-7.96.86-1.33.04-2.66,0-3.98.01-1.44-.06-2.71-.21-4.05-.4-10.51-1.56-20.84-5.62-28.88-12.86-1.94-1.86-3.84-4.02-4.72-6.84-.21-.7-.34-1.44-.39-2.15-.05-.68-.07-1.38-.05-2.07.05-1.39.25-2.8.79-4.18.25-.69.63-1.35,1.09-1.96.45-.6,1.11-1.16,1.67-1.53,1.1-.73,2.01-1.41,2.67-2.33.32-.46.61-.95.82-1.48.11-.28.18-.52.27-.84l.26-.95c.37-1.26.76-2.51,1.26-3.75l.54.11Z" />
        <g>
          <path
            style={styles.two}
            d="m288.78,746.93c12.97,15.29,33.87,36.48,64.22,55.07,14.17,8.69,51.27,29.59,104,37,29.16,4.1,97.74,8.93,167-29,21.95-12.02,38.28-25.23,49.05-35.01-.56-18.31-2.28-39.19-6.05-61.99-3.77-22.78-8.85-43.09-14.21-60.6-10.29-10.92-23.72-23.1-40.79-34.4-38.48-25.48-76.44-33.93-100.11-37.07,6.01,3.85,7,7.17,7.11,9.07.51,9.09-17.54,18.05-32,20-2.33.31-14.09,1.73-27-4-5.91-2.62-15.91-6.96-18-16-.65-2.82-.35-5.33,0-7,2.23-2.53,4.46-5.05,6.69-7.58-15,3.92-31.42,9.26-48.69,16.58-41.74,17.69-73.46,40.6-95.85,60.09-2.99,8.83-5.79,18.48-8.15,28.91-5.61,24.73-7.29,47.17-7.22,65.93Z"
          />
          <g>
            <path
              style={styles.three}
              d="m380.27,645.9l.04-9.71h37.67l-.03,9.69h-6.35c-1.82,0-3.09.73-3.83,2.19-.73,1.46-1.11,3.83-1.12,7.09l-.43,115.04c-.02,4.68.32,7.67,1.01,8.96.69,1.29,2.33,1.93,4.92,1.91l10.59-.07c3.94-.02,7.63-3.99,11.06-11.9,3.43-7.9,6.08-20.51,7.95-37.8l4.46-.02-1.86,59.32-64.7.43.04-9.77,6.48-.04c1.43,0,2.51-.78,3.25-2.33s1.11-3.71,1.12-6.51l.48-118.37c.01-2.85-.35-4.92-1.09-6.21-.74-1.29-2.02-1.93-3.84-1.93h-5.82Z"
            />
            <path
              style={styles.one}
              d="m454,636.19h64.28l.66,52.97h-4.43c-.76-16.34-2.56-27.65-5.38-33.92-2.83-6.26-7.19-9.4-13.1-9.39h-10.79c-1.58,0-2.73.7-3.45,2.09-.73,1.39-1.09,3.36-1.1,5.93l-.12,51.53h3.94c7.84-.04,12.38-9.91,13.63-29.61h4.33s-.13,71.12-.13,71.12l-4.34.02c-.12-7.65-.7-13.67-1.74-18.08s-2.48-7.86-4.33-10.36c-1.85-2.49-3.98-3.74-6.41-3.73l-4.97.02-.13,56.26c0,3,.37,5.19,1.12,6.58,1.08,2.01,2.37,3.02,3.85,3.01l6.91-.04-.02,9.72-38.73.26.03-9.74,6.49-.04c1.52,0,2.69-.87,3.52-2.59.83-1.71,1.25-3.86,1.25-6.44l.3-117.88c0-2.98-.39-5.06-1.19-6.24-.8-1.18-2.46-1.77-4.96-1.77h-5.04s.03-9.68.03-9.68Z"
            />
            <path
              style={styles.one}
              d="m527.22,636.19h63.29l1.61,58.19h-4.45c-1.04-12.43-2.39-22.13-4.05-29.1-1.66-6.97-3.56-11.92-5.69-14.86-2.13-2.93-4.47-4.4-7.01-4.4-1.47,0-2.54.64-3.23,1.92-.69,1.28-1.03,3.13-1.03,5.54l-.06,118.82c0,2.29.33,4.16,1,5.62s1.48,2.18,2.43,2.17l8.24-.05v9.68s-38.84.26-38.84.26v-9.7s7.88-.05,7.88-.05c1.18,0,2.11-.73,2.8-2.15.69-1.43,1.03-3.32,1.04-5.68l.1-118.28c0-2.76-.35-4.8-1.05-6.12-.7-1.31-1.8-1.97-3.31-1.97-8.33,0-14,16.16-16.98,48.5h-4.32s1.65-58.35,1.65-58.35Z"
            />
          </g>
        </g>
      </g>
      <g id="Outline">
        <g>
          <path d="m460.98,525.94l-11.31,49.7-.13.58c-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7,1.95.44h0Z" />
          <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59l1.88.69h0Z" />
        </g>
      </g>
    </g>
  );
};

export default Vlone;
