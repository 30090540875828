const JordanJersey = ({ skinColor }) => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      stroke: "#fff",
      fill: "none",
      strokeMiterLimit: 10,
    },
    three: {
      stroke: "#fff",
      fill: "none",
    },
    four: {
      stroke: "#fff",
      fill: "none",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    five: {
      strokeMiterLimit: 10,
      fill: "none",
      strokeWidth: 2,
      stroke: "#000",
    },
    six: {
      strokeMiterLimit: 10,
      strokeWidth: 2,
      stroke: "#000",
    },
    seven: {
      fill: "#cb1244",
    },
    eight: {
      fill: skinColor,
    },
  };

  return (
    <g>
      <path
        style={styles.eight}
        d="m288.78,746.86c-.4-14.89.38-33.27,4.22-53.91,2.91-15.7,6.98-29.41,11.15-40.89,18.82-16.26,44.95-35.52,78.85-52.06,24.12-11.77,46.61-19.45,65.69-24.57,21.07,1.84,42.13,3.67,63.2,5.51,19.71,2.58,50.2,9.07,83.11,27.06,26.46,14.46,45.41,31.41,57.79,44.37,4.94,15.77,9.6,33.7,13.21,53.58,4.64,25.54,6.56,48.82,7.05,68.96-14.2,12.19-36.17,28.56-66.05,42-51.38,23.12-97.39,24.63-120,23.99-46.92-1.33-82.48-14.45-100-21.99-49.98-21.53-81.86-53.17-98.22-72.04Z"
      />
      <path
        style={styles.eight}
        d="m518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12.32,7.46,1.17,8.35.49.51,1.02.81,1.4.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22.48-.66,1.01-1.11,1.37-1.37.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
      />
      <path d="m460,523c1.9,1.69,3.9,3.36,6,5,15.78,12.32,32.48,19.14,47,23,1.96-5.48,3.91-10.96,5.87-16.44-4.61.02-9.68-.16-15.13-.64-.79-.07-6.86-.61-12.73-1.62-12.45-2.13-23.75-6.29-31-9.31Z" />
      <g>
        <path d="m460.98,525.94l-11.31,49.7-.13.58c-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7,1.95.44h0Z" />
        <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59l1.88.69h0Z" />
      </g>
      <path
        style={styles.seven}
        d="m354.73,802.26c22.03,13.09,68.77,36.79,132.27,38.64,49.51,1.44,88.54-11,111-20.13-.63-36.65-1.25-73.29-1.88-109.94-9.44-9.95-26.6-30.74-35.25-62.25-6.59-23.99-5.61-44.61-4.01-57.24l-44.97-10.39c-8.38,16.54-26.43,24.32-41.41,19.5-16.76-5.39-21.54-23.96-21.79-25.01-15.3,5.07-30.6,10.14-45.9,15.21,2.1,11.05,5.73,38.34-6.66,69.28-11.13,27.81-29.47,44.23-38.34,51.23-1.02,30.37-2.04,60.74-3.06,91.11Z"
      />
      <path
        style={styles.one}
        d="m357.79,723.83v6.32c13.49-8.28,31.97-22.67,45.27-46.02,12.07-21.17,14.95-41.04,17.2-56.57,2.61-18,2.37-33.21,1.63-43.83-2.26.94-4.52,1.87-6.78,2.81.59,14.98.77,52.01-13.77,84.72-2.24,5.04-6.44,14.34-14.46,25-10.18,13.53-21.3,22.31-29.1,27.57Z"
      />
      <path
        style={styles.one}
        d="m546.39,588.17c-2.12,12.42-5.31,41.1,7.3,73.96,12.26,31.92,32.92,50.83,42.72,58.76v5.48c-10.09-7.63-33.9-27.74-47.15-63.19-12.62-33.77-8.81-63.14-6.45-75.63,1.19.21,2.39.42,3.58.63Z"
      />
      <path
        style={styles.one}
        d="m440.23,577.8l-5.16,1.56c4.58,18.25,18.58,32.31,35.8,35.81,21.6,4.39,44.88-8.53,53.74-31.44l-4.59-1.16c-8.06,19.35-28.01,30.32-46.99,26.79-15.66-2.91-28.5-15.29-32.8-31.56Z"
      />
      <path
        style={styles.one}
        d="m448.69,575.42l-3.53.97c.5,1.86,5.89,20.88,23.36,27.04,16.78,5.92,37.69-2.48,48.03-21.36l-5.56-1.22c-6.17,12.84-19.79,20.44-33.79,18.9-13.48-1.48-24.81-11.14-28.52-24.33Z"
      />
      <path
        style={styles.one}
        d="m550.22,590.17l6.64,1.15c-1.44,13.64-2.26,38.81,8.05,67.68,8.9,24.91,22.35,42.12,31.21,51.82.02,1.2.04,2.39.07,3.59-9.4-7.7-25.93-23.37-37.01-48.62-14.64-33.34-11.07-63.5-8.95-75.61Z"
      />
      <path
        style={styles.one}
        d="m402.79,590.63l6.98-2.93c1.74,26.87-2.22,48-5.84,61.55-2.67,10.01-5.65,20.84-13.16,33.31-10.65,17.68-24.25,28.45-32.98,34.28v-5.7c9.02-6.97,19.3-16.95,27.91-30.75,12.69-20.37,15.64-39.3,17.09-49.3,2.39-16.52,1.43-30.57,0-40.46Z"
      />
      <path
        style={styles.five}
        d="m448.69,575.42c3.81,12.46,14.06,21.79,26.48,24.03,14.29,2.57,29.05-4.67,36.26-18.03"
      />
      <path
        style={styles.five}
        d="m445.16,576.39c3.73,13.61,13.94,24.27,26.81,27.49,16.76,4.19,35.52-4.87,43.89-22.4"
      />
      <path
        style={styles.five}
        d="m440.23,577.8c4.22,18.09,19.98,31.29,38.44,32.25,18.22.95,35.09-10.24,41.35-27.47"
      />
      <path
        style={styles.five}
        d="m435.55,579.21c4.43,19.77,21.09,34.41,40.8,36.09,21,1.79,40.93-11.5,47.58-32.09"
      />
      <path
        style={styles.five}
        d="m402.79,590.63c2,13.68,5.84,52.84-17.61,90.02-8.71,13.81-18.89,23.7-27.39,30.49v19.01c7.93-4.35,14.58-9.19,20.15-14.09,47.56-41.8,44.73-112.18,43.37-131.29-6.18,1.96-12.35,3.91-18.52,5.86Z"
      />
      <path
        style={styles.five}
        d="m409.44,588.5c1.23,23.7,1.12,84.85-40.11,119.93-3.46,2.94-7.29,5.77-11.54,8.41"
      />
      <path
        style={styles.five}
        d="m357.79,724.67c6.57-4.62,13.06-10.09,19.21-16.58,39.72-41.96,39.4-99.82,38.12-121.55"
      />
      <path d="m354.73,802.26c-.17-17.92,1.01-40.21,1.39-58.22,0,0,.67-19.4.67-19.4l2,.08c-.41,9.67-1.32,29.11-1.82,38.78-.65,11.39-1.11,27.55-2.24,38.76h0Z" />
      <g>
        <polyline points="480.35 694.71 482.3 694.71 482.3 687.54 473.3 687.54 473.3 694.71 476.83 694.71" />
        <polyline
          style={styles.two}
          points="480.35 694.71 482.3 694.71 482.3 687.54 473.3 687.54 473.3 694.71 476.83 694.71"
        />
      </g>
      <g>
        <polyline points="480.54 694.22 480.54 739.84 480.54 742.06 475.07 742.06 475.07 739.84 475.07 694.22" />
        <polyline
          style={styles.two}
          points="480.54 694.22 480.54 739.84 480.54 742.06 475.07 742.06 475.07 739.84 475.07 694.22"
        />
      </g>
      <g>
        <polyline points="475.71 739.84 472.65 739.84 472.65 745.71 490.26 745.71 494.96 745.71 494.96 739.84 490.26 739.84 480.11 739.84" />
        <polyline
          style={styles.two}
          points="475.71 739.84 472.65 739.84 472.65 745.71 490.26 745.71 494.96 745.71 494.96 739.84 490.26 739.84 480.11 739.84"
        />
      </g>
      <g>
        <polyline points="490.26 740.33 490.26 730.84 494.96 730.84 494.96 739.84 494.96 745.71 490.26 745.71" />
        <polyline
          style={styles.two}
          points="490.26 740.33 490.26 730.84 494.96 730.84 494.96 739.84 494.96 745.71 490.26 745.71"
        />
      </g>
      <g>
        <polyline
          style={styles.six}
          points="513.82 698.6 515.71 698.68 515.82 691.3 507.41 691.3 507.29 698.34 510.5 698.47"
        />
        <polyline
          style={styles.six}
          points="513.82 698.6 513.11 744.77 513.07 747.04 509.75 746.01 509.78 743.78 510.5 698.47"
        />
        <polyline
          style={styles.six}
          points="528.31 749.28 528.22 755.82 523.06 754.11 505.91 748.45 506 742.66 509.78 743.78"
        />
        <line
          style={styles.five}
          x1="523.15"
          y1="747.75"
          x2="513.11"
          y2="744.77"
        />
        <polyline
          style={styles.six}
          points="523.15 747.75 523.29 738 528.45 739.26 528.31 749.28 528.22 755.82 523.06 754.11"
        />
      </g>
      <g>
        <polygon points="558.22 722.01 558.22 706.58 542.69 698.68 538.78 702.01 538.78 728.49 541.95 731.66 554.17 738.27 554.17 760.84 544.78 755.82 544.78 745.19 538.78 742.77 538.78 761.49 555.74 770.75 559.26 768.27 559.26 734.72 555.35 730.84 543.22 724.9 543.09 708.27 552.87 711.93 552.61 720.54 558.22 722.01" />
        <polygon
          style={styles.two}
          points="558.22 722.01 558.22 706.58 542.69 698.68 538.78 702.01 538.78 728.49 541.95 731.66 554.17 738.27 554.17 760.84 544.78 755.82 544.78 745.19 538.78 742.77 538.78 761.49 555.74 770.75 559.26 768.27 559.26 734.72 555.35 730.84 543.22 724.9 543.09 708.27 552.87 711.93 552.61 720.54 558.22 722.01"
        />
      </g>
      <path d="m557.07,592.37c-4.1,42.59,10.28,86.61,39.78,117.77,0,0,.26.29.27.29,0,0,0,.38,0,.38.39,25.41.65,56.92.78,82.47.07,9.16.1,18.33.1,27.49-.31-9.16-.6-18.32-.84-27.48-.76-25.6-1.54-56.99-2.03-82.44,0,0,.28.68.26.66-29.88-31.56-44.46-76.17-40.3-119.31,0,0,1.99.18,1.99.18h0Z" />
      <path
        style={styles.five}
        d="m596.41,726.37c-10.42-8.33-31.8-27.75-44.9-60.45-13.88-34.67-10.81-65.2-8.7-78.37"
      />
      <path
        style={styles.five}
        d="m546.39,588.17c-1.8,13.13-3.81,38.93,5.12,66.39,11.68,35.95,34.44,57.53,44.9,66.32"
      />
      <path
        style={styles.five}
        d="m550.22,590.17c-1.82,12.75-4.11,41.13,9.11,73,10.9,26.29,27.37,42.94,36.85,51.23"
      />
      <g>
        <polygon points="441.22 698.68 439.91 749.24 443.3 753.01 458.83 748.45 462.09 744.01 462.09 696.4 464.17 696.4 464.17 688.12 454 689.36 454.26 696.97 456.87 696.97 455.04 742.06 447.22 744.14 446.56 698.68 449.13 698.17 449.13 689.3 438.61 690.54 438.61 699.08 441.22 698.68" />
        <polygon
          style={styles.two}
          points="441.22 698.68 439.91 749.24 443.3 753.01 458.83 748.45 462.09 744.01 462.09 696.4 464.17 696.4 464.17 688.12 454 689.36 454.26 696.97 456.87 696.97 455.04 742.06 447.22 744.14 446.56 698.68 449.13 698.17 449.13 689.3 438.61 690.54 438.61 699.08 441.22 698.68"
        />
      </g>
      <g>
        <path d="m428.78,696.53l-3.32-3.32-20.83,8.21v8.41l1.96-1.17v50.67l-4.11,1.76v10.37l23.48-13.89,1.76-5.67v-26.41l-2.21-2.21,3.27-3.27v-23.48Zm-6.54,54.59l-11.22,4.7v-21.13l11.22-5.09v21.52Zm.78-30.52l-10.37,4.3v-18.39l10.37-3.33v17.41Z" />
        <path
          style={styles.two}
          d="m428.78,696.53l-3.32-3.32-20.83,8.21v8.41l1.96-1.17v50.67l-4.11,1.76v10.37l23.48-13.89,1.76-5.67v-26.41l-2.21-2.21,3.27-3.27v-23.48Zm-6.54,54.59l-11.22,4.7v-21.13l11.22-5.09v21.52Zm.78-30.52l-10.37,4.3v-18.39l10.37-3.33v17.41Z"
        />
      </g>
      <g>
        <polygon points="437.3 793.84 448.52 793.19 448.52 788.36 465.22 788.36 465.22 800.75 436.39 828.4 435.95 833.71 447.58 835.89 448.26 833.75 478.59 805.32 478.59 784.45 471.61 777.27 444.61 777.27 437.17 784.71 437.3 793.84" />
        <polygon
          style={styles.five}
          points="437.3 793.84 448.52 793.19 448.52 788.36 465.22 788.36 465.22 800.75 436.39 828.4 435.95 833.71 447.58 835.89 448.26 833.75 478.59 805.32 478.59 784.45 471.61 777.27 444.61 777.27 437.17 784.71 437.3 793.84"
        />
        <polygon
          style={styles.four}
          points="437.3 793.84 448.52 793.19 448.52 788.36 465.22 788.36 465.22 800.75 436.39 828.4 435.95 833.71 447.58 835.89 448.26 833.75 478.59 805.32 478.59 784.45 471.61 777.27 444.61 777.27 437.17 784.71 437.3 793.84"
        />
      </g>
      <g>
        <polygon points="465.74 838.48 465.74 833.1 477.74 833.1 477.74 839.59 465.74 838.48" />
        <polygon
          style={styles.five}
          points="465.74 838.48 465.74 833.1 477.74 833.1 477.74 839.59 465.74 838.48"
        />
        <polygon
          style={styles.four}
          points="465.74 838.48 465.74 833.1 477.74 833.1 477.74 839.59 465.74 838.48"
        />
      </g>
      <g>
        <polygon points="488.56 795.01 499.39 795.01 499.39 787.84 517.11 787.84 517.11 805.58 502.84 805.58 502.84 818.23 517.11 818.23 517.11 839 527.31 838.02 527.31 814.15 523.65 814.15 523.65 810.01 527.81 805.86 527.81 783.8 521.42 777.41 494.43 777.41 488.5 783.34 488.56 795.01" />
        <polygon
          style={styles.five}
          points="488.56 795.01 499.39 795.01 499.39 787.84 517.11 787.84 517.11 805.58 502.84 805.58 502.84 818.23 517.11 818.23 517.11 839 527.31 838.02 527.31 814.15 523.65 814.15 523.65 810.01 527.81 805.86 527.81 783.8 521.42 777.41 494.43 777.41 488.5 783.34 488.56 795.01"
        />
        <polygon
          style={styles.four}
          points="488.56 795.01 499.39 795.01 499.39 787.84 517.11 787.84 517.11 805.58 502.84 805.58 502.84 818.23 517.11 818.23 517.11 839 527.31 838.02 527.31 814.15 523.65 814.15 523.65 810.01 527.81 805.86 527.81 783.8 521.42 777.41 494.43 777.41 488.5 783.34 488.56 795.01"
        />
      </g>
      <g>
        <rect x="488.5" y="834.45" width="11.15" height="5.53" />
        <rect
          style={styles.five}
          x="488.5"
          y="834.45"
          width="11.15"
          height="5.53"
        />
        <rect
          style={styles.four}
          x="488.5"
          y="834.45"
          width="11.15"
          height="5.53"
        />
      </g>
      <polyline
        style={styles.three}
        points="509.28 699.54 509.29 699.18 508.66 742.28 505.17 741.3 505.17 749.24 529.43 757.35 529.43 738.83 522.13 736.74 522.13 746.26 513.82 744.04 514.57 699.57 517.11 699.57 517.11 690.3 505.91 690.3 505.91 699.57 509.75 699.54"
      />
      <rect
        x="475.47"
        y="737.83"
        width="1.27"
        height="3.82"
        transform="translate(50.26 -30.03) rotate(3.81)"
      />
      <g>
        <path d="m460.97,525.94l-11.31,49.7-.13.58c-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7,1.95.44h0Z" />
        <path d="m518.9,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59l1.88.69h0Z" />
      </g>
    </g>
  );
};

export default JordanJersey;
