import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { sauceBones } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { numFormatter } from "../../utils/helpers";
import {
  cardLight,
  normal,
  purple,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import CurrencyInput from "../custom/CurrencyInput";
import CustomModal from "../custom/CustomModal";
import RivoxCoin from "../custom/RivoxCoin";
import PrimaryButton from "../custom/PrimaryButton";
import SecondaryButton from "../custom/SecondaryButton";
import CustomCheckbox from "../custom/CustomCheckbox";

const AddFundsModal = ({ open, onClose, user, balance, setBalance }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const [mustRepay, setMustRepay] = useState(null);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setAmount("");
    setLoading(false);
    setMustRepay(false);
    onClose();
  };

  const handleAddFunds = () => {
    setLoading(true);
    sauceBones(middleware, user?._id, parseFloat(amount), mustRepay).then(
      (res) => {
        setLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          updateStore({
            type: SET_SUCCESSES,
            payload: res?.message,
          });
          setBalance(parseFloat(amount) + parseFloat(user?.funds?.balance));
          setAmount("");
        }
      }
    );
  };

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Give ${user?.username} Funds`}
      primaryButton={
        <PrimaryButton
          label="Add Funds"
          disabled={amount === "" || amount == null || amount <= 0}
          loading={loading}
          onClick={handleAddFunds}
        />
      }
      secondaryButton={<SecondaryButton label="Close" onClick={handleClose} />}
      backgroundColor={cardLight}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: normal,
                  color: secondaryText,
                  fontWeight: 600,
                }}
              >
                {user?.username}'s Balance
              </Typography>
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 0.5 }}
              >
                <RivoxCoin size={32} />

                <Grid item>
                  <Typography
                    sx={{ fontSize: 32, color: text, fontWeight: 700 }}
                  >
                    {numFormatter().format(balance)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <CurrencyInput
            value={amount}
            onChange={(value) => setAmount(value)}
            autoFocus={true}
          />
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: normal,
              color: secondaryText,
              fontWeight: 600,
            }}
          >
            Are you adding funds to {user?.username} that must be repaid? <br />
          </Typography>
        </Grid>
        <Grid item>
          <CustomCheckbox
            checked={mustRepay}
            onChange={(value) => setMustRepay(value)}
            color={purple}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default AddFundsModal;
