const Default = () => {
  return (
    <g>
      <path d="M438.49,342.87c-.5-4.69,11.16-10.72,21.78-12.26,.97-.14,3.6-.49,7.04-.39,0,0,13.71,.38,24.13,9.39,1.57,1.36,3.32,3.19,3.26,5.48,0,.16-.09,2.26-1.43,3-1.57,.87-3.83-.71-5.61-1.7-2.97-1.65-6.07-2.14-12.26-3.13-8.22-1.31-15.65,.82-19.17,1.83-5.76,1.65-8.54,3.53-12.52,2.22-.48-.16-4.92-1.67-5.22-4.43Z" />
      <path d="M633.73,345.36c.5-4.69-11.16-10.72-21.78-12.26-.97-.14-3.6-.49-7.04-.39,0,0-13.71,.38-24.13,9.39-1.57,1.36-3.32,3.19-3.26,5.48,0,.16,.09,2.26,1.43,3,1.57,.87,3.83-.71,5.61-1.7,2.97-1.65,6.07-2.14,12.26-3.13,8.22-1.31,15.65,.82,19.17,1.83,5.76,1.65,8.54,3.53,12.52,2.22,.48-.16,4.92-1.67,5.22-4.43Z" />
    </g>
  );
};

export default Default;
