import "../styles/Ozzy.css";

const Ozzy = () => {
  return (
    <g>
      <g>
        <g>
          <path
            class="ozzy-4"
            d="m569.74,390.95c.45-.96.95-1.9,1.5-2.81,5.96-9.91,17.62-16.64,31.01-16.64,14.98,0,27.77,8.41,32.88,20.28l.89,2.35v.02c.97,2.88,1.48,5.94,1.48,9.1,0,17.54-15.78,31.75-35.25,31.75s-35.25-14.21-35.25-31.75c0-4.36.98-8.52,2.74-12.3Z"
          />
          <path
            class="ozzy-4"
            d="m509.27,388.96c2.08,4.04,3.23,8.54,3.23,13.29,0,17.54-15.78,31.75-35.25,31.75s-35.25-14.21-35.25-31.75c0-4.89,1.23-9.52,3.42-13.66.67-1.27,1.44-2.49,2.29-3.66,6.28-8.69,17.17-14.43,29.54-14.43,12.98,0,24.31,6.31,30.43,15.71.58.89,1.12,1.81,1.59,2.75Z"
          />
          <path
            class="ozzy-5"
            d="m535,386c-8.74-.05-19.14,1.87-25.73,2.96-.47-.94-1.01-1.86-1.59-2.75l.32-.21c5.23-.93,13.63-2.77,20-3,13.25-.5,33.94,2.86,43,5l.24.14c-.55.91-1.05,1.85-1.5,2.81-7.34-1.44-24.8-4.89-34.74-4.95Z"
          />
          <path
            class="ozzy-5"
            d="m664.5,378.68l-.39,3.59-28.08,11.88v-.02c-.27-.8-.57-1.59-.9-2.35l29.37-13.1Z"
          />
          <path
            class="ozzy-5"
            d="m369,351l78.71,33.93c-.85,1.17-1.62,2.39-2.29,3.66-25.51-9.95-51.04-19.9-76.55-29.86-.99-.85-2.62-2-4.87-2.73-4.05-1.31-7.56-.41-8.95.03.85-1.05,3.47-4.03,7.95-5.03,2.54-.57,4.7-.28,6,0Z"
          />
        </g>
        <g>
          <path
            class="ozzy-3"
            d="m636.03,394.15c.96,2.88,1.47,5.94,1.47,9.1,0,17.54-15.78,31.75-35.25,31.75s-35.25-14.21-35.25-31.75c0-4.36.98-8.52,2.74-12.3.45-.96.95-1.9,1.5-2.81,5.96-9.91,17.62-16.64,31.01-16.64,14.98,0,27.77,8.41,32.88,20.28.33.76.63,1.55.89,2.35"
          />
          <path
            class="ozzy-3"
            d="m509.27,388.96c2.08,4.04,3.23,8.54,3.23,13.29,0,17.54-15.78,31.75-35.25,31.75s-35.25-14.21-35.25-31.75c0-4.89,1.23-9.52,3.42-13.66.67-1.27,1.44-2.49,2.29-3.66,6.28-8.69,17.17-14.43,29.54-14.43,12.98,0,24.31,6.31,30.43,15.71.58.89,1.12,1.81,1.59,2.75Z"
          />
          <path
            class="ozzy-3"
            d="m508,386c5.23-.93,13.63-2.77,20-3,13.25-.5,33.94,2.86,43,5"
          />
          <path
            class="ozzy-3"
            d="m445.42,388.59c-25.51-9.95-51.04-19.9-76.55-29.86-.99-.85-2.62-2-4.87-2.73-4.05-1.31-7.56-.41-8.95.03.85-1.05,3.47-4.03,7.95-5.03,2.54-.57,4.7-.28,6,0l78.71,33.93"
          />
          <path
            class="ozzy-3"
            d="m509,389c.09-.01.18-.03.27-.04,6.59-1.09,16.99-3.01,25.73-2.96,9.94.06,27.4,3.51,34.74,4.95.09.02.17.03.26.05"
          />
          <polygon
            class="ozzy-3"
            points="635.13 391.78 664.5 378.68 664.11 382.27 636.03 394.15 636.02 394.13 635.13 391.78"
          />
        </g>
      </g>
      <ellipse class="ozzy-2" cx="477.75" cy="402.25" rx="34.25" ry="30.25" />
      <ellipse class="ozzy-2" cx="602.75" cy="403.25" rx="34.25" ry="30.25" />
      <polygon
        class="ozzy-1"
        points="446.15 413.93 508.73 389.35 511.72 398.42 449.32 419.13 446.15 413.93"
      />
      <polygon
        class="ozzy-1"
        points="570.15 415.93 632.73 391.35 635.72 400.42 573.32 421.13 570.15 415.93"
      />
      <ellipse class="ozzy-3" cx="602.25" cy="403.25" rx="35.25" ry="31.75" />
      <ellipse class="ozzy-3" cx="477.25" cy="402.25" rx="35.25" ry="31.75" />
      <path
        class="ozzy-3"
        d="m508,386c5.23-.93,13.63-2.77,20-3,13.25-.5,33.94,2.86,43,5"
      />
      <path
        class="ozzy-3"
        d="m445.42,388.59c-25.51-9.95-51.04-19.9-76.55-29.86-.99-.85-2.62-2-4.87-2.73-4.05-1.31-7.56-.41-8.95.03.85-1.05,3.47-4.03,7.95-5.03,2.54-.57,4.7-.28,6,0l78.71,33.93"
      />
      <path
        class="ozzy-3"
        d="m509,389c6.58-1.08,17.14-3.05,26-3,10.06.06,27.82,3.59,35,5"
      />
      <polygon
        class="ozzy-3"
        points="635.13 391.78 664.5 378.68 664.11 382.27 636.03 394.15 635.13 391.78"
      />
    </g>
  );
};

export default Ozzy;
