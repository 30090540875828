import { CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { getAutoLogs, getUserTips } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { CLIENT_URL } from "../../utils/constants";
import {
  getNotiTimestamp,
  getReadableDateString,
  getReadableTime,
} from "../../utils/helpers";
import { TRANSACTION_LIMIT } from "../../utils/limits";
import {
  card,
  cardLight,
  green,
  modalShadow,
  normal,
  secondaryText,
  small,
  text,
  transition,
} from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";
import NotSelected from "../custom/NotSelected";
import LogGlimmer from "../glimmers/LogGlimmer";
import SecondaryButton from "../custom/SecondaryButton";
import { AccountTypeEnum } from "../../utils/enums";
import RevertTipModal from "./RevertTipModal";

const UserTips = ({ user, onClick }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [tips, setTips] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [selectedTip, setSelectedTip] = useState(null);
  const [revertOpen, setRevertOpen] = useState(false);

  const handleGetTips = () => {
    getUserTips(middleware, user?._id, skip).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.tips?.length >= TRANSACTION_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setTips(tips ? [...tips, ...res?.tips] : res?.tips);
      }
    });
  };

  usePagination(bottom, () => setSkip(tips?.length));

  function isWithinTwoDaysAgo(date) {
    // Get the current date and time
    const currentDate = new Date();

    // Calculate the difference in milliseconds between the current date and the given date
    const timeDiff = currentDate.getTime() - date.getTime();

    // Convert milliseconds to hours
    const hoursDiff = timeDiff / (1000 * 60 * 60);

    // Check if the difference is within 48 hours
    return hoursDiff <= 48;
  }

  useEffect(() => {
    if (tips == null) {
      handleGetTips();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetTips();
    }
  }, [skip]);

  const styles = {
    width: {
      width: "100%",
    },
    label: {
      fontSize: small,
      fontWeight: 500,
      color: secondaryText,
    },
  };

  return (
    <>
      <RevertTipModal
        open={revertOpen}
        onClose={() => setRevertOpen(false)}
        tip={selectedTip}
        tips={tips}
        setTips={setTips}
      />

      {loading && (
        <Grid
          item
          alignSelf="center"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!tips || tips?.length < 1) && (
        <Grid item alignSelf="center">
          <NotSelected label="NO TIPS" />
        </Grid>
      )}

      {!loading && tips && tips?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 0.5 }}
          >
            {tips?.map((tip, i) => (
              <Grid
                item
                sx={{
                  borderRadius: 1,
                  padding: 1,
                  width: "100%",
                  backgroundColor: card,
                  boxShadow: modalShadow,
                  transition: transition,
                  // "&:hover": {
                  //   cursor: "pointer",
                  //   transform: "translateY(-5px)",
                  // },
                }}
                key={i}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: "100%",
                    padding: 1,
                    borderRadius: 1,
                    backgroundColor: cardLight,
                  }}
                >
                  <Grid item xs={10}>
                    <Typography sx={styles.label}>
                      {getReadableDateString(new Date(tip?.timestamp)) +
                        " at " +
                        getReadableTime(new Date(tip?.timestamp))}
                    </Typography>
                    {tip?.user_tipping === user?.username &&
                      tip?.user_tipped !== "sellingunbans" && (
                        <Typography
                          sx={{
                            fontSize: normal,
                            color: text,
                            fontWeight: 600,
                          }}
                        >
                          {`${user?.username} tipped $${tip?.amount} to ${tip?.user_tipped}`}
                        </Typography>
                      )}
                    {tip?.user_tipped === "sellingunbans" && (
                      <Typography
                        sx={{
                          fontSize: normal,
                          color: green,
                          fontWeight: 600,
                        }}
                      >
                        {`${user?.username} tipped $${tip?.amount} to ${tip?.user_tipped}`}
                      </Typography>
                    )}
                    {tip?.user_tipped === user?.username && (
                      <Typography
                        sx={{
                          fontSize: normal,
                          color: text,
                          fontWeight: 600,
                        }}
                      >
                        {`${user?.username} was tipped $${tip?.amount} by ${tip?.user_tipping}`}
                      </Typography>
                    )}
                  </Grid>

                  {tip?.user_tipping === user?.username &&
                    isWithinTwoDaysAgo(new Date(tip?.timestamp)) &&
                    store?.user?.account_type >= AccountTypeEnum.ADMIN && (
                      <Grid item>
                        <SecondaryButton
                          label="Revert Tip"
                          fullWidth
                          onClick={() => {
                            setSelectedTip(tip);
                            setRevertOpen(true);
                          }}
                          // onClick={() =>
                          //   updateStore({ type: SET_WALLET_OPEN, payload: true })
                          // }
                        />
                      </Grid>
                    )}
                </Grid>
              </Grid>
            ))}
            {hasMore && (
              <div style={{ width: "100%" }} ref={bottom}>
                <LogGlimmer numItems={1} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default UserTips;
