const StrongMan = ({ color }) => {
  const styles = {
    one: {
      fill: color,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
  };

  return (
    <g transform="translate(450, 425)">
      <path
        style={styles.one}
        d="M6.53,4.72c-.29,2.99-.71,5.73-.76,8.49-.04,2.15,.49,4.29,1.85,6.08,1.8,2.39,3.89,4.4,6.6,5.8,3.37,1.74,6.83,2.12,10.46,1.12,3.4-.94,6.73-2.07,9.72-4,3.83-2.46,7.63-4.96,11.44-7.46,8.53-5.59,17.71-9.12,28.06-9.11,3.39,0,6.68,.62,9.82,1.91,3.26,1.34,5.94,3.41,7.96,6.32,.39,.57,.82,1.12,1.35,1.84,.25-.68,.42-1.11,.57-1.55,1.47-4.21,4.43-6.92,8.56-8.46,4.65-1.73,9.42-1.72,14.18-.75,5.35,1.1,10.43,2.9,15.06,5.93,2.67,1.74,5.51,3.23,8.38,4.63,1.56,.76,3.3,1.22,5,1.59,2.95,.64,5.11-.4,6.65-3.05,1.39-2.39,1.79-4.96,1.11-7.63-.41-1.62-1.04-3.18-1.55-4.77-.09-.27-.08-.58-.15-1.06,1.59,.26,2.34,1.24,2.97,2.35,1.3,2.29,1.74,4.81,1.77,7.38,.16,11.78-4.88,20.89-15.19,26.35-13,6.9-26.15,6.12-38.85-1.31-1.3-.76-2.49-1.74-3.62-2.75-1.06-.95-1.97-2.06-3.03-3.19-.58,1.09-1.1,2.29-1.81,3.37-3.07,4.64-7.3,8.03-12.11,10.68-8.14,4.48-16.93,6.61-26.16,6.97-10.47,.42-20.64-1.06-30-5.93-7.49-3.89-14.35-8.74-19.03-16.03C2.84,23.95,.74,19.06,.52,13.54c-.09-2.19,.16-4.33,1.41-6.21,1.1-1.66,2.52-2.85,4.61-2.61Z"
      />
    </g>
  );
};

export default StrongMan;
