import { API_ROUTE, errMessage } from "../utils/constants";

export const createTeam = async (useMiddleware, name) => {
  return await useMiddleware
    .post(`${API_ROUTE}/team/create`, { name })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const leaveTeam = async (useMiddleware, teamId) => {
  return await useMiddleware
    .post(`${API_ROUTE}/team/leave`, { teamId })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const kickUser = async (useMiddleware, userToKick, teamToKick) => {
  return await useMiddleware
    .post(`${API_ROUTE}/team/kick`, {
      userToKick,
      teamToKick,
    })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const inviteUser = async (useMiddleware, team_id, user_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/team/invite`, { team_id, user_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const joinTeam = async (
  useMiddleware,
  notiId = null,
  teamToJoinId,
  invite_code = null
) => {
  return await useMiddleware
    .post(`${API_ROUTE}/team/join`, { notiId, teamToJoinId, invite_code })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const changeTeamName = async (useMiddleware, team_id, name) => {
  return await useMiddleware
    .post(`${API_ROUTE}/team/name/edit`, { team_id, name })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};
