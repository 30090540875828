import { getColor } from "../../utils/getColor";
import Beard from "./types/Beard";
import Goatee from "./types/Goatee";
import Handlebar from "./types/Handlebar";
import Leftovers from "./types/Leftovers";
import Musketeer from "./types/Musketeer";
import StrongMan from "./types/StrongMan";

const FacialHair = ({ type, color }) => {
  switch (type) {
    case "beard":
      return <Beard color={getColor(color)} />;
    case "goatee":
      return <Goatee color={getColor(color)} />;
    case "handle_bar":
      return <Handlebar color={getColor(color)} />;
    case "musketeer":
      return <Musketeer color={getColor(color)} />;
    case "strong_man":
      return <StrongMan color={getColor(color)} />;
    case "leftovers":
      return <Leftovers />;
    default:
      return null;
  }
};

export default FacialHair;
