import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FaWallet } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { PAYPAL_URL } from "../../utils/authURL";
import {
  currencyFormatter,
  getWithdrawMethodString,
  numFormatter,
} from "../../utils/helpers";
import {
  cardLight,
  cardVeryLight,
  normal,
  offWhite,
  secondaryText,
  small,
  text,
} from "../../utils/themeContstants";
import ConnectionButton from "../connections/ConnectionButton";
import CurrencyInput from "../custom/CurrencyInput";
import PrimaryButton from "../custom/PrimaryButton";
import useMiddleware from "../../api/useMiddleware";
import { cashAppWithdraw, withdraw } from "../../api/user";
import CustomDropdown from "../custom/CustomDropdown";
import CustomInput from "../custom/CustomInput";

const WalletWithdraw = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const [amount, setAmount] = useState("");
  const [canWithdraw, setCanWithdraw] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("cashapp");
  const [cashTag, setCashTag] = useState("");

  const handleWithdrawal = () => {
    setLoading(true);
    withdraw(middleware, store?.user?.connections?.paypal?.email, amount).then(
      (res) => {
        setLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          updateStore({
            type: SET_SUCCESSES,
            payload: res?.message,
          });
          setAmount("");
          setCanWithdraw(false);
        }
      }
    );
  };

  const handleCashAppWithdraw = () => {
    setLoading(true);
    cashAppWithdraw(middleware, cashTag, amount, type).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        setAmount("");
        setCashTag("");
        setCanWithdraw(false);
      }
    });
  };

  useEffect(() => {
    if (type === "paypal") {
      if (
        amount == null ||
        amount === "" ||
        amount < 10 ||
        !store?.user?.connections?.paypal?.email
      ) {
        setCanWithdraw(false);
      } else {
        setCanWithdraw(true);
      }
    } else {
      if (
        amount == null ||
        amount === "" ||
        amount < 10 ||
        cashTag == null ||
        cashTag === ""
      ) {
        setCanWithdraw(false);
      } else {
        setCanWithdraw(true);
      }
    }
  }, [amount, store?.user?.connections?.paypal?.email, type, cashTag]);

  useEffect(() => {
    setCashTag("");
  }, [type]);

  const styles = {
    width: {
      width: "100%",
    },
    label: {
      fontSize: small,
      fontWeight: 800,
      color: offWhite,
    },
    details: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 400,
    },
    value: {
      fontSize: normal,
      color: text,
      fontWeight: 400,
    },
    warning: {
      color: secondaryText,
      fontWeight: 400,
      fontSize: small,
    },
  };

  return (
    <>
      {store?.user?.funds?.max_withdrawal_amount >= 5 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 4 }}
            sx={{
              maxWidth: isDesktop ? 375 : "100%",
              minWidth: isDesktop ? 375 : 0,
            }}
          >
            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 2 }}
              >
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.label}>AMOUNT</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <CurrencyInput
                        value={amount}
                        onChange={(value) => setAmount(value)}
                        autoFocus={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Divider
                    sx={{ width: "100%", backgroundColor: cardVeryLight }}
                  />
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.label}>WITHDRAW TYPE</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <CustomDropdown
                        options={[
                          { title: "PayPal", value: "paypal" },
                          { title: "Cash App", value: "cashapp" },
                        ]}
                        placeholder="Withdraw Type"
                        onChange={(value) => setType(value)}
                        value={getWithdrawMethodString(type)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Divider
                    sx={{ width: "100%", backgroundColor: cardVeryLight }}
                  />
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="start"
                    alignItems="center"
                    justifyContent="center"
                    gap={{ xs: 1 }}
                  >
                  {type === "paypal" && (
                      <>
                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            direction="column"
                            alignItems="start"
                            justifyContent="center"
                            gap={{ xs: 1 }}
                          >
                            <Grid item>
                              <Typography sx={styles.label}>
                                Paypal Email
                              </Typography>
                            </Grid>

                            <Grid item sx={styles.width}>
                              <CustomInput
                                value={cashTag}
                                onChange={(value) => {
                                  setCashTag(value);
                                }}
                                autoFocus={false}
                                cashTag={true}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography sx={styles.details}>
                                Sending To
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography
                                sx={{ ...styles.value, fontWeight: 800 }}
                              >
                                ${cashTag}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {type === "cashapp" && (
                      <>
                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            direction="column"
                            alignItems="start"
                            justifyContent="center"
                            gap={{ xs: 1 }}
                          >
                            <Grid item>
                              <Typography sx={styles.label}>
                                CASH TAG
                              </Typography>
                            </Grid>

                            <Grid item sx={styles.width}>
                              <CustomInput
                                value={cashTag}
                                onChange={(value) => {
                                  if (value === "$") return;

                                  setCashTag(value);
                                }}
                                autoFocus={false}
                                cashTag={true}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography sx={styles.details}>
                                Sending To
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography
                                sx={{ ...styles.value, fontWeight: 800 }}
                              >
                                ${cashTag}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography sx={styles.details}>Amount</Typography>
                        </Grid>

                        <Grid item>
                          <Typography sx={styles.value}>
                            {amount
                              ? currencyFormatter().format(amount)
                              : "$0.00"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography sx={styles.details}>Fee</Typography>
                        </Grid>

                        <Grid item>
                          <Typography sx={styles.value}>5% + $1.00</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Divider
                    sx={{ width: "100%", backgroundColor: cardVeryLight }}
                  />
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography sx={styles.value}>Total</Typography>
                    </Grid>

                    <Grid item>
                      <Typography sx={{ ...styles.value, fontWeight: 800 }}>
                        {amount - amount * 0.05 - 1 > 0
                          ? currencyFormatter().format(
                              amount - amount * 0.05 - 1
                            )
                          : "$0.00"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 1 }}
              >
                <Grid item sx={styles.width}>
                  <PrimaryButton
                    type="green"
                    fullWidth
                    label="Withdraw"
                    disabled={!canWithdraw}
                    onClick={
                      type === "paypal"
                        ? handleWithdrawal
                        : handleCashAppWithdraw
                    }
                    loading={loading}
                  />
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <RiErrorWarningFill
                        style={{ color: secondaryText, fontSize: 14 }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography sx={styles.warning}>
                        Minimum withdraw amount is $10.00
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {store?.user?.funds?.max_withdrawal_amount < 5 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={{ xs: 2 }}
          >
            <Grid item sx={styles.icon}>
              <FaWallet style={{ fontSize: 32, color: secondaryText }} />
            </Grid>

            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap={{ xs: 1 }}
              >
                <Grid item>
                  <Typography
                    sx={{ fontSize: 15, color: secondaryText, fontWeight: 700 }}
                  >
                    Not Enough Tokens Won
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    sx={{
                      fontSize: normal,
                      fontWeight: 400,
                      color: secondaryText,
                    }}
                  >
                    You need at least $5.00 won through Tokens to withdraw.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default WalletWithdraw;
