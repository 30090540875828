import React, { useContext, useState } from 'react';
import { Divider, Grid, Typography } from "@mui/material";
import { purchaseBattlePass } from "../../api/avatar";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { numFormatter } from "../../utils/helpers";
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  offWhite,
  red,
  secondaryText,
  small,
  text,
} from "../../utils/themeContstants";
import CustomCheckbox from "../custom/CustomCheckbox";
import CustomModal from "../custom/CustomModal";
import RivoxCoin from "../custom/RivoxCoin";
import SecondaryButton from "../custom/SecondaryButton";

const PurchaseBattlePassModal = ({ open, onClose }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [understands, setUnderstands] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setUnderstands(false);
    setLoading(false);
    onClose();
  };

  const handlePurchase = () => {
    setLoading(true);
    purchaseBattlePass(middleware).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
      }
    });
  };

  const getUserBalance = () => {
    if (!store?.user) {
      return 0;
    }

    return store?.user?.funds?.balance - 4.49; // Assuming Rivox Pass price is 10 coins
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    label: {
      fontSize: normal,
      fontWeight: 500,
      color: secondaryText,
    },
    value: {
      fontSize: 16,
      fontWeight: 800,
      color: text,
    },
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Confirm Purchase for Rivox Pass`}
      titleSize="large"
      primaryButton={
        <SecondaryButton
          bg={doubtedBlue}
          label="Buy Now"
          loading={loading}
          disabled={!understands}
          onClick={handlePurchase}
        />
      }
      secondaryButton={<SecondaryButton label="Cancel" onClick={handleClose} />}
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 1 }}
      >
        <Grid item sx={styles.width}>
          <Typography
            sx={{
              fontSize: normal,
              fontWeight: 500,
              color: text,
              maxWidth: 600,
            }}
          >
            Upon purchase, you will start gaining XP per match you win. You can view your progress in your profile and rewards in the Rivox Pass section.
          </Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider sx={{ width: "100%", backgroundColor: cardVeryLight }} />
        </Grid>

        <Grid item sx={styles.width}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography sx={styles.label}>Total</Typography>
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 0.5 }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RivoxCoin size={25} />
                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.value}>
                        {numFormatter().format(4.49)} {/* Assuming Rivox Pass price is 10 coins */}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        sx={{
                          fontSize: 15,
                          color: red,
                          fontWeight: 600,
                          textDecoration: "line-through",
                        }}
                      >
                        <span style={{ color: secondaryText }}>
                          {numFormatter().format(6.74)} {/* Assuming original price was 15 coins */}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography sx={styles.label}>Balance After Purchase</Typography>
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 0.5 }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RivoxCoin size={25} />
                </Grid>

                <Grid item>
                  <Typography sx={styles.value}>
                    {numFormatter().format(getUserBalance())}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={styles.width}>
          <Divider sx={{ width: "100%", backgroundColor: cardVeryLight }} />
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 2 }}
          >
            <Grid>
              <CustomCheckbox
                checked={understands}
                onChange={(value) => setUnderstands(value)}
                color={doubtedBlue}
                disabled={loading}
                checkColor="#fff"
              />
            </Grid>

            <Grid>
              <Typography
                sx={{
                  fontSize: small,
                  color: offWhite,
                  fontWeight: 600,
                  maxWidth: 400,
                }}
              >
                I understand that the Rivox Pass is for cosmetic use only and gives no competitive advantage. I also understand that I cannot refund this item once purchased.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default PurchaseBattlePassModal;
