import { Divider, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { sendRematch } from "../../api/matches";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, SET_SUCCESSES, StoreDispatch } from "../../store/Store";
import {
  cardVeryLight,
  doubtedBlue,
  normal,
  secondaryText,
  small,
  text,
} from "../../utils/themeContstants";
import CustomCheckbox from "../custom/CustomCheckbox";
import CustomModal from "../custom/CustomModal";
import SecondaryButton from "../custom/SecondaryButton";

const RequestRematchModal = ({ open, onClose, token }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(false);
  const [canRequest, setCanRequest] = useState(false);

  const handleClose = () => {
    setLoading(false);
    setCanRequest(false);
    onClose();
  };

  const handleRequestRematch = () => {
    setLoading(true);
    sendRematch(middleware, token?._id).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        handleClose();
      }
    });
  };

  const styles = {
    description: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
    width: {
      width: "100%",
    },
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title={`Request Rematch?`}
      primaryButton={
        <SecondaryButton
          label="Send Rematch"
          bg={doubtedBlue}
          loading={loading}
          disabled={!canRequest}
          onClick={handleRequestRematch}
        />
      }
      titleSize="large"
      description="Request to play your opponents again with the same settings."
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Divider sx={{ width: "100%", backgroundColor: cardVeryLight }} />
        </Grid>

        <Grid item>
          <Typography sx={styles.description}>
            You are about to send your opponent a request for a rematch.
          </Typography>
        </Grid>

        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            gap={{ xs: 2 }}
          >
            <Grid>
              <CustomCheckbox
                checked={canRequest}
                onChange={(value) => setCanRequest(value)}
                color={doubtedBlue}
                disabled={loading}
                checkColor="#fff"
              />
            </Grid>

            <Grid>
              <Typography
                sx={{
                  fontSize: small,
                  color: secondaryText,
                  fontWeight: 600,
                  maxWidth: 400,
                }}
              >
                By checking this box you understand that you are going to send
                your opponent a rematch to play the same match again with the
                same settings and entry fee.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default RequestRematchModal;
