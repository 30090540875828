import { Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import Avatar from "../../avatar/Avatar";
import { SET_SEARCH_USER, StoreDispatch } from "../../store/Store";
import {
  cardLight,
  container,
  doubtedBlue,
  normal,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";
import SecondaryButton from "../custom/SecondaryButton";

const UserSearchTipItem = ({ user, onClick }) => {
  const updateStore = useContext(StoreDispatch);

  const [hovered, setHovered] = useState(false);
  const [usernameHovered, setUsernameHovered] = useState(false);

  const handleOpenProfile = (user_id) => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const styles = {
    container: {
      width: "100%",
    },
    username: {
      transition: transition,
      fontSize: normal,
      color: user?.isPremium
        ? usernameHovered
          ? yellow
          : "rgb(255, 255, 193)"
        : usernameHovered
        ? doubtedBlue
        : text,
      textShadow: user?.isPremium ? "rgb(255 93 0) 0px 0px 10px" : null,
      fontWeight: 800,
      cursor: usernameHovered ? "pointer" : "default",
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        gap={{ xs: 2 }}
      >
        <Grid item>
          <Grid container justifyContent="start" alignItems="center">
            <Grid
              item
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onClick={() => handleOpenProfile(user?.user_id)}
              sx={{
                marginLeft: -2,
                transition: transition,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Avatar
                size={75}
                bgColor={hovered ? cardLight : container}
                avatar={user?.avatar}
              />
            </Grid>

            <Grid
              item
              onMouseEnter={() => setUsernameHovered(true)}
              onMouseLeave={() => setUsernameHovered(false)}
              onClick={() => handleOpenProfile(user?.user_id)}
            >
              <Typography sx={styles.username}>{user?.username}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <SecondaryButton
            bg={doubtedBlue}
            label="Tip"
            onClick={() => onClick(user)}
            size="small"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserSearchTipItem;
