import { Button, Grid } from "@mui/material";
import { useState } from "react";
import {
  cardLight,
  cardVeryLight,
  doubtedBlue,
  normal,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";

const CustomMenuButton = ({
  label,
  onClick,
  selected,
  stretch,
  size,
  icon,
}) => {
  const [hovered, setHovered] = useState(false);

  const getColor = () => {
    if (selected) return doubtedBlue;

    if (hovered) {
      return text;
    } else {
      return text;
    }
  };

  const styles = {
    button: {
      padding: 1,
      color: getColor(),
      borderBottom: `2px solid ${selected ? doubtedBlue : "transparent"}`,
      boxSizing: "border-box",
      fontSize: size === "large" ? 18 : normal,
      fontWeight: size === "large" ? 700 : 600,
      borderRadius: 1,
      boxShadow: "0 0",
      transition: transition,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      textTransform: "none",
      width: "100%",
      "&:hover": {
        backgroundColor: cardLight,
        boxShadow: "0 0",
        borderBottom: `2px solid ${selected ? doubtedBlue : cardVeryLight}`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  };

  return (
    <Grid
      item
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{ flexGrow: stretch ? 1 : null }}
    >
      <Button size="large" sx={styles.button} onClick={onClick}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item>{label}</Grid>

          {icon && (
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {icon}
            </Grid>
          )}
        </Grid>
      </Button>
    </Grid>
  );
};

export default CustomMenuButton;
