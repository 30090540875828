import { getColor } from "../../utils/getColor";
import CeeDeeGee from "./types/CeeDeeGee";
import BapeHoodie from "./types/BapeHoodie";
import Basketball from "./types/Basketball";
import Blouse from "./types/Blouse";
import Brrrrrr from "./types/Brrrrrr";
import ButtonDown from "./types/ButtonDown";
import Cardigan from "./types/Cardigan";
import CollegeHoodie from "./types/college_hoodie/CollegeHoodie";
import CollegeHoodieDuke from "./types/college_hoodie/CollegeHoodieDuke";
import CollegeHoodieMichigan from "./types/college_hoodie/CollegeHoodieMichigan";
import CollegeHoodieMSU from "./types/college_hoodie/CollegeHoodieMSU";
import CollegeHoodieUCLA from "./types/college_hoodie/CollegeHoodieUCLA";
import Dawn from "./types/Dawn";
import GraphicBlazer from "./types/graphic_blazer/GraphicBlazer";
import GraphicBlazerIdea from "./types/graphic_blazer/GraphicBlazerIdea";
import GraphicBlazerNeutron from "./types/graphic_blazer/GraphicBlazerNeutron";
import GraphicBlazerStark from "./types/graphic_blazer/GraphicBlazerStark";
import Hockey from "./types/Hockey";
import Hoodie from "./types/Hoodie";
import JeanJacket from "./types/JeanJacket";
import Jumper from "./types/Jumper";
import Letterman from "./types/Letterman";
import Louis from "./types/Louis";
import ShoulderJacket from "./types/ShoulderJacket";
import TShirt from "./types/TShirt";
import UglySweater from "./types/UglySweater";
import WarTorn from "./types/WarTorn";
import LFTShirt from "./types/LFTShirt";
import LFTHockey from "./types/LFTHockey";
import USA from "./types/world_cup/USA";
import Brazil from "./types/world_cup/Brazil";
import England from "./types/world_cup/England";
import France from "./types/world_cup/France";
import Germany from "./types/world_cup/Germany";
import ManCity from "./types/world_cup/ManCity";
import ManUnited from "./types/world_cup/ManUnited";
import Messi from "./types/world_cup/Messi";
import Netherlands from "./types/world_cup/Netherlands";
import Spain from "./types/world_cup/Spain";
import Mexico from "./types/world_cup/Mexico";
import Ronaldo from "./types/world_cup/Ronaldo";
import Minion from "./types/Minion";
import Goose from "./types/Goose";
import Tech from "./types/Tech";
import Chrome from "./types/Chrome";
import Vlone from "./types/Vlone";
import Tate from "./types/Tate";
import Flannel from "./types/Flannel";
import HoodieOverEyes from "./types/HoodieOverEyes";
import Incognito from "./types/Incognito";
import KobeJersey from "./types/KobeJersey";
import GreaserJacket from "./types/GreaserJacket";
import JordanJersey from "./types/JordanJersey";
import Moncleezy from "./types/Moncleezy";
import Pinhead from "./types/Pinhead";
import GymRat from "./types/GymRat";
import Scream from "./types/Scream";
import SkellyZip from "./types/SkellyZip";
import SkellyWool from "./types/SkellyWool";
import SpiderTourOne from "./types/SpiderTourOne";
import SpiderTourTwo from "./types/SpiderTourTwo";
import SupremeCrew from "./types/SupremeCrew";
import GymRatTatted from "./types/GymRatTatted";
import TurtleNeck from "./types/TurtleNeck";
import Y2KHoodie from "./types/Y2KHoodie";
import Tanjiro from "./types/Tanjiro";
import PSGJacket from "./types/PSGJacket";
import TravisScott from "./types/TravisScott";
import LilUzi from "./types/LilUzi";
import Alien from "./types/Alien";
import JuiceWrld from "./types/JuiceWrld";
import Drizzy from "./types/Drizzy";
import Creeper from "./types/Creeper";
import ApeShit from "./types/ApeShit";
import MimeShirt from "./types/MimeShirt";
import Zombie from "./types/Zombie";
import Mummy from "./types/Mummy";
import Rex from "./types/Rex";
import BulletVest from "./types/BulletVest";
import KiritoCoat from "./types/KiritoCoat";
import YeatCatHoodie from "./types/YeatCatHoodie";
import Doge from "./types/Doge";
import BlackBull from "./types/BlackBull";
import DroolEmoji from "./types/emoji/DroolEmoji";
import NerdEmoji from "./types/emoji/NerdEmoji";
import AlienEmoji from "./types/emoji/AlienEmoji";
import LaughEmoji from "./types/emoji/LaughEmoji";
import HeartsEmoji from "./types/emoji/HeartsEmoji";
import SingerEmoji from "./types/emoji/SingerEmoji";
import PressedEmoji from "./types/emoji/PressedEmoji";
import CryEmoji from "./types/emoji/CryEmoji";
import DemonEmoji from "./types/emoji/DemonEmoji";
import HalfDeadEmoji from "./types/emoji/HalfDeadEmoji";
import EyesEmoji from "./types/emoji/EyesEmoji";
import HeartEyesEmoji from "./types/emoji/HeartEyesEmoji";
import CrossbonesEmoji from "./types/emoji/CrossbonesEmoji";
import MonkeyEmoji from "./types/emoji/MonkeyEmoji";
import InterestingEmoji from "./types/emoji/InterestingEmoji";
import DisguiseEmoji from "./types/emoji/DisguiseEmoji";
import SunglassesEmoji from "./types/emoji/SunglassesEmoji";
import CowboyEmoji from "./types/emoji/CowboyEmoji";
import BlownEmoji from "./types/emoji/BlownEmoji";
import SwirlEmoji from "./types/emoji/SwirlEmoji";
import ConfusedEmoji from "./types/emoji/ConfusedEmoji";
import ZipperEmoji from "./types/emoji/ZipperEmoji";

const Clothes = ({ type, color, skinColor }) => {
  switch (type) {
    case "t-shirt":
      return <TShirt color={getColor(color)} />;
    case "lft_shirt":
      return <LFTShirt color={getColor(color)} />;
    case "jumper":
      return <Jumper color={getColor(color)} />;
    case "cardigan":
      return (
        <Cardigan color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "shoulder_with_jacket":
      return (
        <ShoulderJacket
          color={getColor(color)}
          skinColor={getColor(skinColor)}
        />
      );
    case "blouse":
      return <Blouse color={getColor(color)} skinColor={getColor(skinColor)} />;
    case "jean_jacket":
      return <JeanJacket color={getColor(color)} />;
    case "ugly_sweater":
      return <UglySweater />;
    case "hoodie":
      return <Hoodie color={getColor(color)} />;
    case "button_down":
      return <ButtonDown color={getColor(color)} />;
    case "louis":
      return <Louis skinColor={getColor(skinColor)} />;
    case "hockey_jersey":
      return <Hockey color={getColor(color)} skinColor={getColor(skinColor)} />;
    case "lft_hockey_jersey":
      return (
        <LFTHockey color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "basketball":
      return (
        <Basketball color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "letterman":
      return <Letterman color={getColor(color)} />;
    case "bathing_ape":
      return <BapeHoodie />;
    case "war_torn":
      return (
        <WarTorn color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "dawn":
      return <Dawn color={getColor(color)} />;
    case "graphic_blazer":
      return <GraphicBlazer color={getColor(color)} />;
    case "graphic_blazer_neutron":
      return <GraphicBlazerNeutron color={getColor(color)} />;
    case "graphic_blazer_stark":
      return <GraphicBlazerStark color={getColor(color)} />;
    case "graphic_blazer_idea":
      return <GraphicBlazerIdea color={getColor(color)} />;
    case "college_hoodie":
      return <CollegeHoodie />;
    case "college_hoodie_michigan":
      return <CollegeHoodieMichigan />;
    case "college_hoodie_duke":
      return <CollegeHoodieDuke />;
    case "college_hoodie_ucla":
      return <CollegeHoodieUCLA />;
    case "college_hoodie_msu":
      return <CollegeHoodieMSU />;
    case "brrrrrr":
      return <Brrrrrr color={getColor(color)} />;
    case "ceedeegee":
      return (
        <CeeDeeGee color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "warn_torn":
      return (
        <WarTorn color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "wc_usa":
      return <USA />;
    case "wc_brazil":
      return <Brazil />;
    case "wc_england":
      return <England />;
    case "wc_france":
      return <France />;
    case "wc_germany":
      return <Germany />;
    case "wc_mancity":
      return <ManCity />;
    case "wc_manu":
      return <ManUnited />;
    case "wc_messi":
      return <Messi />;
    case "wc_netherlands":
      return <Netherlands />
    case "wc_spain":
      return <Spain />
    case "wc_mexico":
      return <Mexico />;
    case "wc_ronaldo":
      return <Ronaldo />;
    case "minion":
      return <Minion />;
    case "canadian_goose":
      return <Goose />;
    case "tech":
      return <Tech color={getColor(color)} />;
    case "chrome":
      return <Chrome color={getColor(color)} skinColor={getColor(skinColor)} />;
    case "vlone_tee":
      return <Vlone color={getColor(color)} />;
    case "tate":
      return <Tate skinColor={getColor(skinColor)} color={getColor(color)} />;
    case "flannel":
      return (
        <Flannel color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "hoodie_eyes":
      return (
        <HoodieOverEyes
          skinColor={getColor(skinColor)}
          color={getColor(color)}
        />
      );
    case "incognito":
      return <Incognito />;
    case "kobe":
      return <KobeJersey skinColor={getColor(skinColor)} />;
    case "greaser_jacket":
      return <GreaserJacket color={getColor(color)} />;
    case "mj_jersey":
      return <JordanJersey skinColor={getColor(skinColor)} />;
    case "cleezy":
      return (
        <Moncleezy color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "pinhead":
      return <Pinhead />;
    case "gym_rat":
      return <GymRat skinColor={getColor(skinColor)} />;
    case "scream":
      return <Scream color={getColor(color)} />;
    case "skelly_zip":
      return <SkellyZip color={getColor(color)} />;
    case "skelly_wool":
      return (
        <SkellyWool color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "spider_tour_1":
      return (
        <SpiderTourOne
          color={getColor(color)}
          skinColor={getColor(skinColor)}
        />
      );
    case "spider_tour_2":
      return (
        <SpiderTourTwo
          color={getColor(color)}
          skinColor={getColor(skinColor)}
        />
      );
    case "supreme_crew":
      return (
        <SupremeCrew color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "gym_rat_tatted":
      return <GymRatTatted skinColor={getColor(skinColor)} />;
    case "turtle_neck":
      return (
        <TurtleNeck color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "y2k_hoodie":
      return (
        <Y2KHoodie color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "tanjiro":
      return (
        <Tanjiro color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "psg_jacket":
      return (
        <PSGJacket color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "travis_scott":
      return <TravisScott skinColor={getColor(skinColor)} />;
    case "lil_uzi":
      return <LilUzi color={getColor(color)} skinColor={getColor(skinColor)} />;
    case "alien":
      return <Alien color={getColor(color)} />;
    case "juice_wrld":
      return (
        <JuiceWrld color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "drizzy_drake":
      return <Drizzy color={getColor(color)} skinColor={getColor(skinColor)} />;
    case "creeper":
      return <Creeper />;
    case "ape_shit":
      return <ApeShit />;
    case "mime_shirt":
      return (
        <MimeShirt color={getColor(color)} skinColor={getColor(skinColor)} />
      );
    case "zombie":
      return <Zombie color={getColor(color)} />;
    case "mummy":
      return <Mummy skinColor={getColor(skinColor)} />;
    case "rex":
      return <Rex />;
    case "bullet_vest":
      return <BulletVest skinColor={getColor(skinColor)} />;
    case "kirito_coat":
      return <KiritoCoat skinColor={getColor(skinColor)} />;
    case "yeat_cat_hoodie":
      return <YeatCatHoodie color={getColor(color)} />;
    case "doge":
      return <Doge />;
    case "black_bull":
      return <BlackBull skinColor={getColor(skinColor)} />;
    case "drool_emoji":
      return <DroolEmoji />;
    case "nerd_emoji":
      return <NerdEmoji />;
    case "alien_emoji":
      return <AlienEmoji />;
    case "laugh_emoji":
      return <LaughEmoji />;
    case "hearts_emoji":
      return <HeartsEmoji />;
    case "angry_emoji":
      return <PressedEmoji />;
    case "crying_emoji":
      return <CryEmoji />;
    case "demon_emoji":
      return <DemonEmoji />;
    case "half-skull_emoji":
      return <HalfDeadEmoji />;
    case "eyes_emoji":
      return <EyesEmoji />;
    case "heart_eyes_emoji":
      return <HeartEyesEmoji />;
    case "singer_emoji":
      return <SingerEmoji />;
    case "crossbones_emoji":
      return <CrossbonesEmoji />;
    case "monkey_emoji":
      return <MonkeyEmoji />;
    case "interesting_emoji":
      return <InterestingEmoji />;
    case "disguise_emoji":
      return <DisguiseEmoji />;
    case "sunglasses_emoji":
      return <SunglassesEmoji />;
    case "cowboy_emoji":
      return <CowboyEmoji />;
    case "blown_emoji":
      return <BlownEmoji />;
    case "zipper_emoji":
      return <ZipperEmoji />;
    case "confused_emoji":
      return <ConfusedEmoji />;
    case "swirl_emoji":
      return <SwirlEmoji />;
    default:
      return null;
  }
};

export default Clothes;
