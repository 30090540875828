const Pochita = () => {
  const styles = {
    one: {
      fill: "#604534",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#bad2d2",
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#515f60",
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#f8d5ce",
    },
    six: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#4e4d53",
    },
    seven: {
      fill: "#fff",
    },
    eight: {
      fill: "#d46c59",
    },
    nine: {
      fill: "#dd8580",
    },
    ten: {
      fill: "#b14832",
    },
    eleven: {
      fill: "#c5d8d7",
    },
    twelve: {
      fill: "#60251c",
    },
  };

  return (
    <g>
      <path
        style={styles.eight}
        d="m316.3,547.41c3.04-.05,7.6.24,12.61,2.15,10.64,4.06,16.06,12.66,20.06,19.2,10.08,16.49,11.33,33.74,11.21,43.42,1.1.82,7.1,5.43,7.26,12.65.03,1.47.11,5.41-2.62,7.82-3.83,3.37-9.96,1.13-10.91.78-7.15-2.63-9.49-10.31-9.82-11.47-10.11,7.37-20.22,14.74-30.33,22.11-.09,1.14-.36,2.54-1.07,3.98-2.54,5.17-8.48,6.41-9.44,6.59-2.5,8.46-5,16.92-7.5,25.38,1.56,1.48,3.49,3.62,5.23,6.51,2.27,3.79,3.29,7.34,3.78,9.78,3.52-.69,10.18-1.51,18,.68,4.23,1.19,7.3,1.7,9.28,4.27.46.6,2.1,2.73,1.78,5.12-.68,5.07-9.64,7.44-12.16,8.1-7.77,2.05-14.32.89-22.58-.67-4.17-.79-10.04-2.14-16.92-4.6-2.83-1.94-9.64-7.16-13.62-16.75-3.56-8.59-2.99-16.21-2.52-19.64-30.12-31.9-39.38-72.73-24.07-101.22,3.33-6.19,10.84-17.43,27.39-26.35,15.65.72,31.3,1.43,46.96,2.15Z"
      />
      <path
        style={styles.ten}
        d="m357.72,589.98c-.55,5.12-2.22,13.4-8.02,21.15-4,5.33-8.37,8.24-16.96,13.83-5.37,3.5-12.93,8-22.52,12.45,1.18,2.22,2.36,4.44,3.54,6.66l37.76-27.39c1.79-.23,5.29-.96,7.43-3.77,2.23-2.92,1.82-6.53,1.18-12.21-.3-2.69-.95-6.4-2.41-10.71Z"
      />
      <path
        style={styles.nine}
        d="m310.22,605.98c.73-1.12,3.78-5.56,9.77-7.17,4.92-1.33,8.99.01,10.4.55,3.16,4.83,6.33,9.65,9.49,14.48.41,1.55,1.09,4.99-.67,6.97-2.46,2.76-9.38,2.35-15.96-3.15-.7-.52-1.41-1.08-2.12-1.7-2.63-2.26-4.65-4.64-6.2-6.82-1.57-1.05-3.14-2.11-4.72-3.16Z"
      />
      <path
        style={styles.twelve}
        d="m315.47,584.64c-.01,1.25-.22,3.63-1.74,5.97-3.25,5.01-9.48,5.2-10.1,5.21.48,1.4,1.27,3.24,2.57,5.18,1.33,1.99,2.76,3.44,3.89,4.43.59-1.14,2.8-5.09,7.65-7.07,6.34-2.58,12.02.3,12.84.73-.53-2.27-1.06-4.54-1.59-6.82-1.27.09-4.33.14-7.61-1.6-3.65-1.94-5.35-4.93-5.92-6.04Z"
      />
      <path
        style={styles.ten}
        d="m237.59,623.46c.71.87,15.42,18.78,24.59,22.36.96.37,5.11,1.87,10.04,3.39,2.74.85,4.14,1.28,5.61,1.57,3.79.74,5.3.4,9.65,1.04,2.78.41,3.84.79,5.74,1.17,3.28.66,8.07,1.11,14.48.04-5.78,1.27-11.56,2.54-17.34,3.81l-32.05,7.04c-3.35-4.19-6.93-9.27-10.31-15.27-5.24-9.32-8.42-18.06-10.41-25.15Z"
      />
      <path
        style={styles.ten}
        d="m286.83,674c0-6.97.52-18.09,4.17-18.91.7-.16,1.6.04,2.77.99,3.16-.48,6.32-.96,9.48-1.44l-7.5,25.38-8.92-6.02Z"
      />
      <path
        style={styles.ten}
        d="m363.46,615.24c-2.87,1.74-5.86,3.67-8.93,5.8-2.9,2.02-5.59,4.03-8.09,6-1.04-1.6-2.09-3.2-3.13-4.8l16.87-10.06,3.28,3.06Z"
      />
      <path
        style={styles.ten}
        d="m268.55,692.46c4.52,3.66,10.27,7.62,17.33,11.08,16.1,7.89,31.14,9.35,40.54,9.43-2.37.92-5.35,1.83-8.84,2.32-7,1-12.84-.09-16.52-1.11-6.3-1.66-12.6-3.31-18.9-4.97-2.24-1.36-5.56-3.75-8.61-7.63-2.7-3.44-4.18-6.79-5.01-9.12Z"
      />
      <ellipse style={styles.five} cx="269.35" cy="584" rx="12.82" ry="14.09" />
      <path
        style={styles.five}
        d="m337.11,560.37c3.84,6.02,2.58,13-3.31,14.58-5.74,1.54-12.4-3.04-14.66-9.24-1.95-5.35.07-10.27,4.35-11.64,4.37-1.4,10.32,1.13,13.61,6.3Z"
      />
      <ellipse style={styles.one} cx="269.2" cy="583.71" rx="9.44" ry="10.27" />
      <path
        style={styles.one}
        d="m336.15,566.23c.21,4.22-2.99,7.71-7.39,7.71-4.69,0-8.72-3.92-8.72-8.64,0-4.58,3.83-7.77,8.31-7.27,4.21.47,7.6,4.09,7.8,8.2Z"
      />
      <circle style={styles.seven} cx="261.79" cy="578.6" r="2.71" />
      <circle style={styles.seven} cx="321.55" cy="563.3" r="1.9" />
      <path d="m278.64,671.85c13.01,1.38,24.33,11.37,27.1,24.26,0,0-1.11-.79-1.11-.79,8.32-1.06,17.36-.86,24.97,3.19,13.19,6.74.39,14.76-8.08,17.05-5.56,1.47-11.36,1.4-16.92.52-.08.06-22.61-5.92-22.69-5.89,0,0-.17-.04-.17-.04-5.96-4.09-10.8-9.81-13.74-16.42-2.94-6.55-3.94-13.93-2.97-21.03l.24.8c-8.31-9.4-15.47-19.84-20.97-31.15-13.8-27.86-16.72-65.48,8.79-87.61,4.74-4.21,10.15-7.59,15.81-10.37l.22-.11h.25c12.65.33,25.04,5.56,34.1,14.43,6.04,5.84,11.35,13.05,12.53,21.61.88,6.94-1.56,13.94-8.95,15.77-6.33,1.56-12.92-.14-18.75-2.53,0,0,.78-1.84.78-1.84,5.49,2.24,11.61,3.86,17.46,2.44,6.39-1.56,8.22-7.65,7.48-13.59-1.09-7.97-6.23-14.92-11.93-20.41-8.74-8.54-20.56-13.55-32.79-13.87-16.29,8.48-28.69,20.94-32.41,41.43-5.17,30.93,9.84,61.68,29.87,84.47,0,0,.3.34.3.34l-.06.45c-2.09,14.35,4.92,27.81,15.4,35.29-.09-.04,22.96,5.97,22.5,5.85,5.36.85,10.9.9,16.1-.48,3.8-1.01,11.37-4.34,12.28-8.41.04-2.43-2.75-3.84-4.64-4.95-7.23-3.82-15.75-3.97-23.76-2.97,0,0-.92.12-.92.12,0,0-.19-.91-.19-.91-1.98-12.51-12.66-22.7-25.14-24.66h0Z" />
      <path
        style={styles.four}
        d="m234.95,611.97l-10.68-22.05c-.07-.15-.04-.32.08-.43l13.95-13c.28-.26.73.02.6.39-1.5,4.14-2.98,9.45-3.7,15.73-.87,7.58-.36,14.15.45,19.16.07.42-.51.59-.69.21Z"
      />
      <path d="m264.51,627.63c6.12-.57,12.49.61,18.15,3.43,0,0-.74-.1-.74-.1,6.97-1.72,14.49-2.2,21.34.42,8.82,3.34,17.12,14.53,6.85,21.53-3.02,2.12-6.79,2.69-10.34,3.12,0,0-5.21.73-5.21.73-6.95.94-13.92,1.78-20.91,2.37,6.85-1.51,13.74-2.77,20.66-3.94,4.78-.99,10.7-.97,14.73-3.84,7.82-5.38,2-13.41-4.31-16.92-3.7-1.97-7.92-2.72-12.16-2.73-3.54-.01-7.12.45-10.57,1.29-5.12-3.32-11.2-5.28-17.5-5.37h0Z" />
      <path
        style={styles.three}
        d="m319.13,547.41c.33.01,1.32.04,1.88.1,17.57,1.72,30.49,21.62,35.6,35.51,2.1,5.7,4.86,15.79,3.57,29.16-8.37,4.84-17.2,10.38-26.3,16.74-6.93,4.84-13.36,9.69-19.28,14.45"
      />
      <path
        style={styles.three}
        d="m360.18,612.18c.98.79,7.54,6.22,6.99,13.89-.09,1.33-.38,5.32-3.33,7.43-4.51,3.24-11.32-.71-12.13-1.17-5.36-3.11-7.16-8.52-7.63-10.17"
      />
      <path
        style={styles.three}
        d="m303.25,654.64c-1.12,3.16-2.21,6.45-3.27,9.87-1.65,5.37-3.05,10.55-4.23,15.51"
      />
      <path
        style={styles.three}
        d="m315.19,583.71c3.55,7.39,10.47,10.1,14.42,8.12,3.23-1.62,3.96-6.14,4.09-7.04"
      />
      <path
        style={styles.three}
        d="m303.25,595.64c.57,1.81,1.83,5.01,4.64,8.12,2.69,2.97,5.58,4.52,7.3,5.28,1.72,3.21,4.97,7.92,10.65,10.92,1.25.66,9.55,5.05,13.29,1.78.8-.7,1.13-1.53,1.27-1.87,2.56-6.4-8.55-18.64-10.01-20.22.15-.76.34-2.3-.2-4.11-.48-1.61-1.32-2.7-1.83-3.27"
      />
      <path
        style={styles.three}
        d="m310.22,605.98c.62-1.26,2.54-4.71,6.67-6.72,6.47-3.14,12.76,0,13.5.39"
      />
      <path d="m279.41,631.5c-3.05-5.1-8.99-7.92-14.9-8.03,3.02-.25,6.13.13,9.04,1.26,2.9,1.14,5.54,3.08,7.5,5.62l-1.64,1.15h0Z" />
      <path d="m318.63,597.67c3.93,1.26,7.29,3.83,9.47,7.07-2.71-2.82-6.28-4.62-9.93-5.13l.46-1.95h0Z" />
      <path d="m331.17,607.6c2.16.52,3.19,2.8,3.72,4.77-1.4-1.52-2.01-3.51-3.72-4.77h0Z" />
      <path d="m332.2,709c-4.71-4.15-11.38-5.93-17.6-4.67,3.08-.94,6.41-1.19,9.66-.66,3.26.52,6.41,1.82,9.17,3.75,0,0-1.23,1.58-1.23,1.58h0Z" />
      <path d="m312.9,646.73c-2.06-4.21-6.51-7.1-11.27-7.36,2.43-.18,4.95.28,7.22,1.37,2.28,1.09,4.29,2.81,5.77,4.96l-1.72,1.02h0Z" />
      <path
        style={styles.six}
        d="m269.35,545.26l-3.91-45.26c5.67-9.37,12.68-12.25,15.24-13.13,15.68-5.38,34.38,6.28,40.99,21.07,2.99,6.69,3.05,13.01,2.78,16.77-4.05,15.7-8.1,31.4-12.15,47.1-2.22-4.11-8.01-13.49-19.6-20.05-9.64-5.46-18.71-6.37-23.35-6.51Z"
      />
      <path d="m362.47,634.94c-.36-3.88-1.61-7.76-3.71-11.22,2.64,3.07,4.61,6.81,5.68,10.87l-1.97.35h0Z" />
      <path
        style={styles.two}
        d="m272.37,545.46l-3.02-45.46c2.95-3.35,5.84-4.86,7.83-5.61,13.62-5.15,32.94,6.96,37.96,21.72.75,2.2,1.04,4.07,1.17,5.09-2.46,15.05-4.91,30.1-7.37,45.16-2.83-3.93-8.86-11.16-19.09-16.1-6.9-3.33-13.23-4.42-17.48-4.8Z"
      />
      <polygon
        style={styles.eleven}
        points="315.22 521.38 272.67 533.34 273.26 540.66 312.99 532.75 315.22 521.38"
      />
      <polygon
        style={styles.six}
        points="266.11 498.93 255.55 489.79 268.56 495.58 266.11 498.93"
      />
      <polygon
        style={styles.six}
        points="271.05 492.9 261.28 485.54 273.59 490.72 271.05 492.9"
      />
      <polygon
        style={styles.six}
        points="291.12 485.63 289.99 474.19 294.99 486.21 291.12 485.63"
      />
      <polygon
        style={styles.six}
        points="310.85 494.1 314.45 485.93 313.67 496.63 310.85 494.1"
      />
      <polygon
        style={styles.six}
        points="321.97 508.65 329.95 505.2 323.33 512.6 321.97 508.65"
      />
      <polygon
        style={styles.six}
        points="321.66 535.5 328.1 535.5 320.63 539.52 321.66 535.5"
      />
      <polygon
        style={styles.six}
        points="317.25 552.6 322.03 550.79 316.23 556.57 317.25 552.6"
      />
      <polygon
        style={styles.six}
        points="313.71 566.35 317.92 569.47 312.71 570.2 313.71 566.35"
      />
      <polygon
        style={styles.six}
        points="265.86 504.9 260.28 508.35 266.12 507.92 265.86 504.9"
      />
      <path d="m260.93,656.33c1.24,1.31,2.42,2.58,3.68,3.76,1.29,1.28,2.73,2.09,3.63,3.81-1.99-.13-3.31-1.56-4.56-2.91-1.2-1.37-2.18-2.93-2.75-4.66h0Z" />
      <path d="m269.93,661.22c-1.39.7-3.25.02-4.01-1.29-.22-.46-.32-1.02-.03-1.64,1.1.27,1.51.6,2.17,1.27.47.45,1.36,1.22,1.87,1.67h0Z" />
      <path d="m272.37,661.22c-2.02-.2-3-2.6-2.43-4.37,1.27,1.24,2.37,2.51,2.43,4.37h0Z" />
      <path
        style={styles.eleven}
        d="m310.37,509.06c-12.8,4.33-25.61,8.66-38.41,12.99-.3-4.38-.61-8.76-.91-13.14l28.04-9.97c1.87,1.1,4.1,2.63,6.39,4.72,2.07,1.89,3.68,3.78,4.89,5.4Z"
      />
    </g>
  );
};

export default Pochita;
