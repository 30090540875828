const Sad = () => {
  return (
    <g>
      <path d="M422.45,353.27c.41-.64,.72-1.41,1.27-1.95,2.25-2.21,4.43-4.49,6.89-6.48,6.41-5.21,13.77-8.9,22.05-11.04,3.4-.88,6.84-.84,10.3-.73,3.05,.09,6.11,.28,9.16,.27,5.33-.01,10.56-.91,15.72-2.03,1.75-.38,3.41-1.12,5.34-1.77-.62,2.24-2.2,3.62-3.98,4.85-.51,.35-1.11,.61-1.69,.84-5.87,2.32-11.97,3.67-18.37,4.26-13.33,1.21-26.03,4.54-37.96,10.19-2.2,1.04-4.26,2.35-6.37,3.55-.68,.39-1.31,.92-2.36,.04Zm11.42-8.04c.65,0,.83,.05,.96,0,9.69-4.26,20.03-6.43,30.56-7.89,5.71-.79,11.53-.97,17.09-2.66,2.16-.66,4.42-1.06,6.64-2.54-.62,.08-.85,.09-1.07,.14-6.78,1.74-13.66,2.79-20.74,2.57-2.17-.07-4.36-.11-6.53-.02-2.88,.13-5.8,.17-8.55,1.17-2.17,.79-4.38,1.49-6.53,2.31-4.29,1.61-7.96,4.12-11.84,6.93Z" />
      <path d="M564.71,329.51c8.76,3.17,17.47,4.11,26.39,3.76,2.9-.11,5.81-.39,8.69-.2,2.54,.16,5.09,.68,7.54,1.34,10.03,2.68,18.18,8.09,25.29,14.94,.83,.8,1.55,1.7,2.23,2.61,.32,.43,.44,1,.87,2.04-1.13-.32-1.77-.36-2.2-.65-7.07-4.72-15.13-7.53-23.37-9.87-5.13-1.46-10.45-2.41-15.74-3.34-3.78-.66-7.7-.67-11.47-1.38-4.56-.86-9.11-1.89-13.27-3.94-2.29-1.13-3.84-2.82-4.96-5.3Zm59.64,15.92c-6.61-5.27-13.85-8.42-21.83-10.24-.23-.05-.48-.07-.72-.08-3.22-.09-6.43-.26-9.65-.26-5.08,0-10.15-.06-15.15-1.04-2.53-.5-5.05-1.07-7.57-1.62,.25,.43,.56,.67,.92,.8,5.12,1.81,10.38,3.13,15.85,3.7,3.43,.36,6.87,.73,10.28,1.24,9.17,1.37,18.09,3.56,26.54,7.2,.26,.11,.58,.13,1.32,.28Z" />
      <path d="M492.98,335.59c-1.95,3.53-4.66,6.21-9.14,6.94,3.26-2.09,6.7-3.99,9.14-6.94Z" />
      <path d="M574.3,342.48c-4.67-.7-7.42-3.53-9.41-7.17,2.55,3.03,5.86,5.21,9.41,7.17Z" />
      <path d="M433.88,345.23c3.88-2.8,7.55-5.31,11.84-6.93,2.16-.81,4.36-1.52,6.53-2.31,2.75-1,5.67-1.04,8.55-1.17,2.17-.1,4.35-.05,6.53,.02,7.07,.22,13.95-.84,20.74-2.57,.21-.05,.44-.06,1.07-.14-2.22,1.49-4.48,1.88-6.64,2.54-5.56,1.69-11.37,1.87-17.09,2.66-10.53,1.46-20.88,3.63-30.56,7.89-.12,.06-.3,0-.96,0Z" />
      <path d="M624.35,345.42c-.75-.16-1.06-.17-1.32-.28-8.45-3.64-17.36-5.83-26.54-7.2-3.41-.51-6.85-.88-10.28-1.24-5.47-.57-10.73-1.89-15.85-3.7-.36-.13-.68-.37-.92-.8,2.52,.54,5.03,1.12,7.57,1.62,4.99,.98,10.06,1.05,15.15,1.04,3.22,0,6.43,.16,9.65,.26,.24,0,.49,.02,.72,.08,7.99,1.81,15.22,4.97,21.83,10.24Z" />
    </g>
  );
};

export default Sad;
