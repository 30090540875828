const Hokage = () => {
  const styles = {
    one: {
      fill: "#e8e8ea",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#fff",
    },
    four: {
      fill: "#fff",
    },
    five: {
      fill: "#b11d1d",
    },
    six: {
      fill: "#c5aaa3",
    },
  };

  return (
    <g>
      <polygon
        style={styles.one}
        points="445.69 575.42 448.69 575.42 460 525.72 441.73 516.94 443.09 562.22 445.69 575.42"
      />
      <path
        style={styles.one}
        d="m610.41,617.2c.49-38.89.98-77.77,1.48-116.66-8.92,7.67-23.68,18.5-44.34,26.27-20.14,7.57-37.94,9.24-49.57,9.43-2,5.6-3.96,12.47-5.24,20.43-1.54,9.56-1.67,18.01-1.3,24.76,13.21,1.46,29.63,4.46,47.79,10.65,21.64,7.38,38.78,16.93,51.19,25.12Z"
      />
      <path
        style={styles.three}
        d="m446.74,686.91c3.2-20.38,5.29-45.76,3.39-74.74-1.22-18.67-3.88-35.43-7.04-49.96-2.06-68.61-4.13-137.22-6.19-205.83l-105.1-32.51v198.66c-5.95,15.76-13.76,33.42-24.1,52.07-13.62,24.55-28.23,44.7-41.48,60.65,3.03,3.54,10.43,11.21,22.7,15.26,14.12,4.66,26.15,1.7,30.52.39,4.31-.59,13.61-1.22,23.48,3.52,4.47,2.15,7.82,4.81,10.17,7.04.61,2.42,4.67,17.36,19.57,25.04,10.66,5.5,20.8,4.14,24.26,3.52,16.61-1.04,33.22-2.09,49.83-3.13Z"
      />
      <path
        style={styles.two}
        d="m349.17,535.09c2.56,7.42,5.02,16.17,6.78,26.09,2.45,13.75,2.91,26.01,2.58,36-1.56,6.96-3.12,13.91-4.68,20.87l-4.68,14.09"
      />
      <path
        style={styles.two}
        d="m388.83,535.09c-.41,15.71.62,35.79,5.74,58.43,7.72,34.12,21.57,60.02,32.35,76.7"
      />
      <path style={styles.two} d="m380.48,507.27s27.13-86.96,12.52-138.1" />
      <polyline
        style={styles.two}
        points="358.53 332.15 358.53 391.09 331.8 438.22"
      />
      <g>
        <path
          style={styles.three}
          d="m684.82,262.33l-6.22,100.21c-2.14,16.7-4.09,36.85-4.91,59.47-.84,23.25-.28,43.07.68,58.66,4.7,29.33,8.72,59.16,11.84,89.3.54,5.24,1.05,10.4,1.52,15.48,2.38,9.95,4.72,19.75,7.03,29.38.05,1.87-.02,4.21-.42,6.78-1.23,7.81-4.66,12.05-5.81,13.34-3.61,6.39-7.19,12.72-10.73,18.99-3.18,4.27-6.69,8.55-10.52,12.71-9.56,10.35-18.69,17.15-26.51,21.73-1.89,2.26-4.11,5.39-6.25,9.58-3.17,6.22-4.95,12.39-5.96,16.88-1.18,2.19-2.86,4.72-5.17,6.76-6.54,5.81-13.49,3.62-15.85,2.71.24-7.36.49-14.84.76-22.45.34-9.61.7-19.34,1.07-29.18.32-15.93.64-32.28.98-49.1l3.76-297.3,70.72-63.96Z"
        />
        <path
          style={styles.two}
          d="m628.29,313.45l-2.52,150.87s.5,49.14.43,51.3-1.54,51.19-1.54,51.19"
        />
        <polyline
          style={styles.two}
          points="643.68 533.2 632.07 641.22 625.61 708.42"
        />
        <polyline
          style={styles.two}
          points="655.52 321.97 649.41 498.93 649.83 589.44"
        />
        <path
          style={styles.two}
          d="m663.4,439.69c-2.69,20.46-5.06,48.81-3.31,81.13.73,13.5,2.05,25.34,3.54,35.37,3.32,13.26,6.58,26.24,9.77,38.95l2.63,45.97"
        />
      </g>
      <g>
        <g>
          <path
            style={styles.four}
            d="m508.04,242.78l96.21,85.19c-31.73,15.26-61.44,26.36-87.6,34.55-30.89-7.14-63.07-15.44-96.35-25.1l87.74-94.64Zm37.57,100.7c.87-3.09,1.74-6.18,2.61-9.26-4.63-2.09-10.57-5.38-16.57-10.57-9.73-8.41-14.95-17.77-17.61-23.61v-35.21h-7.04c.35,13.6.7,27.21,1.04,40.82-1.52,4.76-4.81,12.89-12,20.74-5.8,6.34-11.91,10.05-16.04,12.13,1.04,2.65,2.09,5.31,3.13,7.96,4.01-1.36,10.05-4.01,15.91-9.26,10.19-9.13,13.35-20.33,14.35-24.82,2.83,5.37,7.81,13.2,16.18,20.51,5.81,5.08,11.53,8.41,16.04,10.57Zm-2.89-61.4c-2.02-.91-4.05-1.81-6.07-2.72-1.37,3.34-2.98,6.87-4.86,10.55-2.44,4.77-4.97,9.04-7.44,12.81,2.18.98,4.36,1.96,6.55,2.93,2.14-3.15,4.38-6.85,6.52-11.08,2.27-4.49,3.99-8.72,5.3-12.49Zm-48.76,14.18c.24-5.02.08-9.58-.26-13.56h-6.66c.11,3.6.09,7.48-.13,11.6-.28,5.35-.85,10.29-1.56,14.74h7.17c.67-3.75,1.21-8.04,1.44-12.78Z"
          />
          <path
            style={styles.four}
            d="m712.37,262.27c3.52,2.95,6.97,5.89,10.37,8.82-28.71,18.48-60.54,37.36-95.48,55.82-37.71,19.94-73.81,36.62-107.48,50.61-47.19-10.93-97.5-24.44-150.26-41.22-45.39-14.43-87.86-29.76-127.3-45.39,4.37-3.39,8.78-6.79,13.24-10.18,45.85,18.3,96.06,36.15,150.45,52.44,4.82,1.44,9.62,2.86,14.39,4.25,33.28,9.66,65.46,17.96,96.35,25.1,26.16-8.19,55.87-19.29,87.6-34.55,10.13-4.87,20.47-10.17,30.97-15.93,29.74-16.33,55.42-33.46,77.15-49.77Z"
          />
          <path
            style={styles.six}
            d="m621.96,249.64c.57.85.91,1.92.91,3.07,0,2.84-2.04,5.14-4.55,5.14s-4.55-2.3-4.55-5.14c0-1.74.76-3.27,1.93-4.19h0c.74-.61,1.64-.95,2.62-.95,1.49,0,2.82.81,3.64,2.07Z"
          />
          <path
            style={styles.six}
            d="m650.78,209.1c.47.38.4,1.38-.13,2.87-.19.53-.44,1.13-.75,1.78-2.22,4.74-7.37,12.46-14,20.67-5.06,6.26-10.02,11.62-13.94,15.22-.82-1.26-2.15-2.07-3.64-2.07-.98,0-1.88.34-2.62.94,2.46-4.79,7.32-11.96,13.45-19.54,6.51-8.06,12.86-14.63,17.04-17.86.53-.41,1.03-.77,1.49-1.07,1.51-.99,2.59-1.35,3.1-.94Z"
          />
          <path
            style={styles.six}
            d="m582.85,225.19c.58.86.91,1.92.91,3.07,0,2.83-2.04,5.13-4.55,5.13s-4.55-2.3-4.55-5.13c0-1.74.76-3.27,1.93-4.19h0c.74-.6,1.65-.95,2.62-.95,1.49,0,2.82.81,3.64,2.07Z"
          />
          <path
            style={styles.six}
            d="m611.67,184.65c.47.38.4,1.38-.13,2.87-1.58,4.41-7.21,13.11-14.75,22.45-5.06,6.26-10.02,11.62-13.94,15.22-.82-1.26-2.15-2.07-3.64-2.07-.97,0-1.88.35-2.62.94,2.46-4.79,7.32-11.96,13.45-19.54,7.34-9.09,14.48-16.28,18.53-18.93,1.51-.99,2.59-1.35,3.1-.94Z"
          />
          <path
            style={styles.five}
            d="m493.7,282.7c.34,3.98.5,8.54.26,13.56-.23,4.74-.77,9.03-1.44,12.78h-7.17c.71-4.45,1.28-9.39,1.56-14.74.22-4.12.24-8,.13-11.6h6.66Z"
          />
          <path
            style={styles.five}
            d="m536.65,279.36c2.02.91,4.05,1.81,6.07,2.72-1.31,3.77-3.03,8-5.3,12.49-2.14,4.23-4.38,7.93-6.52,11.08-2.19-.97-4.37-1.95-6.55-2.93,2.47-3.77,5-8.04,7.44-12.81,1.88-3.68,3.49-7.21,4.86-10.55Z"
          />
          <path
            style={styles.five}
            d="m548.22,334.22c-.87,3.08-1.74,6.17-2.61,9.26-4.51-2.16-10.23-5.49-16.04-10.57-8.37-7.31-13.35-15.14-16.18-20.51-1,4.49-4.16,15.69-14.35,24.82-5.86,5.25-11.9,7.9-15.91,9.26-1.04-2.65-2.09-5.31-3.13-7.96,4.13-2.08,10.24-5.79,16.04-12.13,7.19-7.85,10.48-15.98,12-20.74-.34-13.61-.69-27.22-1.04-40.82h7.04v35.21c2.66,5.84,7.88,15.2,17.61,23.61,6,5.19,11.94,8.48,16.57,10.57Z"
          />
          <path
            style={styles.five}
            d="m649.9,213.75c22.59,16.21,43.39,32.5,62.47,48.52-21.73,16.31-47.41,33.44-77.15,49.77-10.5,5.76-20.84,11.06-30.97,15.93l-96.21-85.19-87.74,94.64c-4.77-1.39-9.57-2.81-14.39-4.25-54.39-16.29-104.6-34.14-150.45-52.44,52.62-40.13,110.89-80.38,175.11-119.21,22.52-13.62,44.8-26.47,66.78-38.61,34.56,16.79,72.12,37.38,111.22,62.68-4.05,2.65-11.19,9.84-18.53,18.93-6.13,7.58-10.99,14.75-13.45,19.54h0c-1.17.93-1.93,2.46-1.93,4.2,0,2.83,2.04,5.13,4.55,5.13s4.55-2.3,4.55-5.13c0-1.15-.33-2.21-.91-3.07,3.92-3.6,8.88-8.96,13.94-15.22,7.54-9.34,13.17-18.04,14.75-22.45,10.7,6.97,21.52,14.3,32.42,22,.75.53,1.49,1.05,2.23,1.59-4.18,3.23-10.53,9.8-17.04,17.86-6.13,7.58-10.99,14.75-13.45,19.54h0c-1.17.93-1.93,2.46-1.93,4.2,0,2.84,2.04,5.14,4.55,5.14s4.55-2.3,4.55-5.14c0-1.15-.34-2.22-.91-3.07,3.92-3.6,8.88-8.96,13.94-15.22,6.63-8.21,11.78-15.93,14-20.67Z"
          />
        </g>
        <g>
          <path
            style={styles.two}
            d="m649.9,213.75c22.59,16.21,43.39,32.5,62.47,48.52,3.52,2.95,6.97,5.89,10.37,8.82-28.71,18.48-60.54,37.36-95.48,55.82-37.71,19.94-73.81,36.62-107.48,50.61-47.19-10.93-97.5-24.44-150.26-41.22-45.39-14.43-87.86-29.76-127.3-45.39,4.37-3.39,8.78-6.79,13.24-10.18,52.62-40.13,110.89-80.38,175.11-119.21,22.52-13.62,44.8-26.47,66.78-38.61,34.56,16.79,72.12,37.38,111.22,62.68"
          />
          <path
            style={styles.two}
            d="m611.54,187.52c10.7,6.97,21.52,14.3,32.42,22,.75.53,1.49,1.05,2.23,1.59"
          />
          <path
            style={styles.two}
            d="m712.37,262.27c-21.73,16.31-47.41,33.44-77.15,49.77-10.5,5.76-20.84,11.06-30.97,15.93-31.73,15.26-61.44,26.36-87.6,34.55-30.89-7.14-63.07-15.44-96.35-25.1-4.77-1.39-9.57-2.81-14.39-4.25-54.39-16.29-104.6-34.14-150.45-52.44"
          />
          <polyline
            style={styles.two}
            points="604.25 327.97 508.04 242.78 420.3 337.42"
          />
          <path
            style={styles.two}
            d="m576.59,224.06c2.46-4.79,7.32-11.96,13.45-19.54,7.34-9.09,14.48-16.28,18.53-18.93,1.51-.99,2.59-1.35,3.1-.94.47.38.4,1.38-.13,2.87-1.58,4.41-7.21,13.11-14.75,22.45-5.06,6.26-10.02,11.62-13.94,15.22"
          />
          <path
            style={styles.two}
            d="m582.85,225.19c.58.86.91,1.92.91,3.07,0,2.83-2.04,5.13-4.55,5.13s-4.55-2.3-4.55-5.13c0-1.74.76-3.27,1.93-4.19h0c.74-.6,1.65-.95,2.62-.95,1.49,0,2.82.81,3.64,2.07Z"
          />
          <path
            style={styles.two}
            d="m615.7,248.51c2.46-4.79,7.32-11.96,13.45-19.54,6.51-8.06,12.86-14.63,17.04-17.86.53-.41,1.03-.77,1.49-1.07,1.51-.99,2.59-1.35,3.1-.94.47.38.4,1.38-.13,2.87-.19.53-.44,1.13-.75,1.78-2.22,4.74-7.37,12.46-14,20.67-5.06,6.26-10.02,11.62-13.94,15.22"
          />
          <path
            style={styles.two}
            d="m621.96,249.64c.57.85.91,1.92.91,3.07,0,2.84-2.04,5.14-4.55,5.14s-4.55-2.3-4.55-5.14c0-1.74.76-3.27,1.93-4.19h0c.74-.61,1.64-.95,2.62-.95,1.49,0,2.82.81,3.64,2.07Z"
          />
          <path
            style={styles.two}
            d="m507,264.83h7.04v35.21c2.66,5.84,7.88,15.2,17.61,23.61,6,5.19,11.94,8.48,16.57,10.57-.87,3.08-1.74,6.17-2.61,9.26-4.51-2.16-10.23-5.49-16.04-10.57-8.37-7.31-13.35-15.14-16.18-20.51-1,4.49-4.16,15.69-14.35,24.82-5.86,5.25-11.9,7.9-15.91,9.26-1.04-2.65-2.09-5.31-3.13-7.96,4.13-2.08,10.24-5.79,16.04-12.13,7.19-7.85,10.48-15.98,12-20.74-.34-13.61-.69-27.22-1.04-40.82Z"
          />
          <path
            style={styles.two}
            d="m487.04,282.7c.11,3.6.09,7.48-.13,11.6-.28,5.35-.85,10.29-1.56,14.74h7.17c.67-3.75,1.21-8.04,1.44-12.78.24-5.02.08-9.58-.26-13.56h-6.66Z"
          />
          <path
            style={styles.two}
            d="m536.65,279.36c-1.37,3.34-2.98,6.87-4.86,10.55-2.44,4.77-4.97,9.04-7.44,12.81,2.18.98,4.36,1.96,6.55,2.93,2.14-3.15,4.38-6.85,6.52-11.08,2.27-4.49,3.99-8.72,5.3-12.49-2.02-.91-4.05-1.81-6.07-2.72Z"
          />
        </g>
      </g>
      <path
        style={styles.two}
        d="m649.9,213.75c26.75,19.19,50.99,38.5,72.84,57.34-28.71,18.48-60.54,37.36-95.48,55.82-37.71,19.94-73.81,36.62-107.48,50.61-47.19-10.93-97.5-24.44-150.26-41.22-45.39-14.43-87.86-29.76-127.3-45.39,4.37-3.39,8.78-6.79,13.24-10.18,52.62-40.13,110.89-80.38,175.11-119.21,22.52-13.62,44.8-26.47,66.78-38.61,34.56,16.79,72.12,37.38,111.22,62.68"
      />
      <path
        style={styles.two}
        d="m611.54,187.52c10.7,6.97,21.52,14.3,32.42,22,.75.53,1.49,1.05,2.23,1.59"
      />
      <path
        style={styles.two}
        d="m712.37,262.27c-21.73,16.31-47.41,33.44-77.15,49.77-43.35,23.8-84.06,39.68-118.57,50.48-35.32-8.16-72.33-17.84-110.74-29.35-54.39-16.29-104.6-34.14-150.45-52.45"
      />
      <polyline
        style={styles.two}
        points="604.25 327.97 508.04 242.78 420.3 337.42"
      />
      <path
        style={styles.two}
        d="m576.59,224.07c2.45-4.79,7.32-11.96,13.45-19.55,7.34-9.09,14.48-16.28,18.53-18.93,1.51-.99,2.59-1.35,3.1-.94.47.38.4,1.38-.13,2.87-1.58,4.41-7.21,13.11-14.75,22.45-5.06,6.26-10.02,11.62-13.94,15.22"
      />
      <ellipse style={styles.two} cx="579.21" cy="228.26" rx="4.55" ry="5.14" />
      <path
        style={styles.two}
        d="m615.7,248.52c2.45-4.79,7.32-11.96,13.45-19.55,7.34-9.09,14.48-16.28,18.53-18.93,1.51-.99,2.59-1.35,3.1-.94.47.38.4,1.38-.13,2.87-1.58,4.41-7.21,13.11-14.75,22.45-5.06,6.26-10.02,11.62-13.94,15.22"
      />
      <ellipse style={styles.two} cx="618.32" cy="252.71" rx="4.55" ry="5.14" />
      <path
        style={styles.two}
        d="m507,264.83h7.04v35.22c2.66,5.83,7.87,15.19,17.61,23.61,6,5.18,11.94,8.48,16.57,10.57-.87,3.09-1.74,6.17-2.61,9.26-4.51-2.16-10.23-5.49-16.04-10.57-8.36-7.31-13.35-15.15-16.17-20.52-1,4.5-4.16,15.69-14.35,24.82-5.86,5.25-11.91,7.9-15.91,9.26-1.04-2.65-2.09-5.3-3.13-7.96,4.13-2.08,10.24-5.79,16.04-12.13,7.19-7.85,10.48-15.99,12-20.74-.35-13.61-.7-27.22-1.04-40.83Z"
      />
      <path
        style={styles.two}
        d="m487.04,282.7c.11,3.61.09,7.49-.13,11.61-.28,5.35-.85,10.28-1.57,14.74h7.17c.67-3.75,1.21-8.04,1.43-12.78.24-5.03.08-9.59-.26-13.57h-6.65Z"
      />
      <path
        style={styles.two}
        d="m536.65,279.36c-1.37,3.34-2.98,6.87-4.86,10.55-2.44,4.77-4.97,9.04-7.44,12.82l6.55,2.93c2.14-3.16,4.38-6.85,6.53-11.08,2.27-4.49,3.99-8.72,5.3-12.49l-6.07-2.71Z"
      />
    </g>
  );
};

export default Hokage;
