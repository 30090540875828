const Default = () => {
  const styles = {
    one: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2.5,
    },
  };

  return (
    <g id="Nose">
      <path
        style={styles.one}
        d="M512.54,395.09c4.79,.05,10.54-.24,16.96-1.3,6.64-1.1,12.34-2.77,16.96-4.43,5.84,.63,10.81,4.37,12.78,9.65,2.45,6.58-.93,12.35-1.37,13.09-1.59,2.79-4.34,6.84-8.8,10.65-5.05,4.32-10.1,6.48-13.3,7.57"
      />
    </g>
  );
};

export default Default;
