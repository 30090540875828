const BlowinBubs = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#cb899e",
    },
    three: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    four: {
      fill: "#ed2226",
    },
    five: {
      fill: "#f8c8dd",
    },
    six: {
      fill: "#ae1b18",
    },
  };

  return (
    <g>
      <path
        style={styles.four}
        d="M566.14,501.24c-.93,1.12-2.03,2.17-3.34,3.06-10.95,7.5-31,.81-41.07-11.63-3.06-3.78-4.77-7.56-5.74-10.27,1.32-6.07,3.08-10.91,4.53-14.34,2.41-5.72,5.05-11.8,10.87-17.51,2.22-2.18,4.3-3.71,5.74-4.68,1.05-.47,2.54-.97,4.38-1.06,4.62-.22,7.94,2.36,8.91,3.17,.21-.43,2.01-4.03,6.04-4.83,3.4-.68,6.99,.91,9.02,4.02"
      />
      <path
        style={styles.three}
        d="M540.99,469.66s0,0,0,0c-.02,.02-.05,.04-.07,.06l-14.49,11.32,16.56,4.21h0"
      />
      <circle style={styles.five} cx="567.71" cy="474.18" r="27.1" />
      <path
        style={styles.three}
        d="M542.97,485.26c-.81-1.73-1.77-4.3-2.21-7.54-.45-3.31-.18-6.1,.15-8"
      />
      <path
        style={styles.two}
        d="M540.91,469.73l-14.49,11.32,16.56,4.21c-1.05-2.15-2.38-5.65-2.52-10.1-.06-2.09,.16-3.93,.45-5.44Z"
      />
      <path
        style={styles.six}
        d="M558.08,448.84c-3.14,.59-6.6,1.6-10.15,3.3-16.05,7.67-22.28,23.74-24.08,29.06,8.61,5.69,17.21,11.37,25.82,17.06-1.72,.85-3.95,1.74-6.64,2.26-6.18,1.21-11.34-.12-14.04-1.06,1.19,.88,18.21,13.11,31.63,6.11,1.71-.89,3.64-2.24,5.53-4.35-2.49-.16-8.61-.88-14.59-5.31-5.09-3.78-7.55-8.37-8.58-10.66-5.52-1.4-11.04-2.81-16.56-4.21l14.49-11.32c.44-2.44,1.71-7.59,5.81-12.61,4.16-5.1,9.04-7.37,11.36-8.28Z"
      />
      <path
        style={styles.one}
        d="M544.69,447.83l-6.19,.3c-1.95,1.46-4.06,3.25-6.19,5.44-3.94,4.05-6.63,8.13-8.46,11.45,.73-2.62,2.45-7.41,6.64-11.91,2.9-3.11,5.94-4.97,8.07-6.04,2.04,.25,4.08,.5,6.12,.75Z"
      />
      <path
        style={styles.one}
        d="M518.72,484.52c.56,1.88,1.6,4.48,3.62,7.1,4.16,5.38,9.72,7.24,11.93,7.85-3.03-1.39-7.74-4.08-11.78-9.06-1.68-2.07-2.89-4.1-3.77-5.89Z"
      />
      <path
        style={styles.one}
        d="M566.14,451.08c.5,.08,2.99,.55,4.51,2.79,1.47,2.16,1.47,5.12,0,7.55-1.48,1.22-4.06,3.03-7.7,4.08-5.3,1.52-9.79,.56-11.78,0-.17-.1-.65-.42-.91-1.06-.32-.8-.06-1.51,0-1.66,.41-1.18,3.21-8.95,10.57-11.17,1.97-.6,3.8-.66,5.3-.53Z"
      />
      <ellipse style={styles.one} cx="546.65" cy="468.9" rx="1.66" ry="1.89" />
      <path d="M538.41,484.52c-2.22,1.25-3.66,3.29-4.89,5.44-.76-2.88,1.82-5.78,4.52-6.37,0,0,.37,.93,.37,.93h0Z" />
      <path d="M530.71,482.56c-2.22,1.25-3.66,3.29-4.89,5.44-.76-2.88,1.82-5.78,4.52-6.37,0,0,.37,.93,.37,.93h0Z" />
      <path d="M531.28,477.84c-2.76,.11-5.99-2.05-5.98-5.03,0,0,1.43,1.36,1.43,1.36,1.33,1.3,2.86,2.11,4.66,2.67,0,0-.12,.99-.12,.99h0Z" />
      <path d="M543.2,463.35c-2.76,.11-5.99-2.05-5.98-5.03,0,0,1.43,1.36,1.43,1.36,1.33,1.3,2.86,2.11,4.66,2.67,0,0-.12,.99-.12,.99h0Z" />
      <path d="M543.09,485.73c-2.22,1.25-3.66,3.29-4.89,5.44-.76-2.88,1.82-5.78,4.52-6.37,0,0,.37,.93,.37,.93h0Z" />
      <path
        style={styles.three}
        d="M566.14,501.24c-.93,1.12-2.03,2.17-3.34,3.06-10.95,7.5-31,.81-41.07-11.63-3.06-3.78-4.77-7.56-5.74-10.27,1.32-6.07,3.08-10.91,4.53-14.34,2.41-5.72,5.05-11.8,10.87-17.51,2.22-2.18,4.3-3.71,5.74-4.68,1.05-.47,2.54-.97,4.38-1.06,4.62-.22,7.94,2.36,8.91,3.17,.21-.43,2.01-4.03,6.04-4.83,3.4-.68,6.99,.91,9.02,4.02"
      />
      <path
        style={styles.three}
        d="M540.99,469.66s0,0,0,0c-.02,.02-.05,.04-.07,.06l-14.49,11.32,16.56,4.21h0"
      />
      <circle style={styles.three} cx="567.71" cy="474.18" r="27.1" />
      <path
        style={styles.three}
        d="M542.97,485.26c-.81-1.73-1.77-4.3-2.21-7.54-.45-3.31-.18-6.1,.15-8"
      />
    </g>
  );
};

export default BlowinBubs;
