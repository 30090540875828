// export const SERVER_URL = "https://rvx-server-93a9d79b470f.herokuapp.com";
export const SERVER_URL = "https://rivox-server-e6cbc6e3107d.herokuapp.com";
// export const SERVER_URL="http://localhost:8080"
// export const CLIENT_URL = "http://localhost:3000";
// export const SERVER_URL = "https://rivox-server.herokuapp.com";
export const CLIENT_URL = "https://rivox.gg";

export const API_ROUTE = `${SERVER_URL}/api`;

export const CDN_URL = `https://cdn.rivox.gg/assets/`

export const errMessage = (err) => {
  return err?.response?.data?.message;
};
