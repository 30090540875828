import Airpod from "./types/Airpod";
import Earring from "./types/Earring";
import EarSmoke from "./types/EarSmoke";
import Retro from "./types/Retro";

const Earrings = ({ type }) => {
  switch (type) {
    case "default_earring":
      return <Earring />;
    case "retro_earring":
      return <Retro />;
    case "airpod":
      return <Airpod />;
    case "ear_smoke":
      return <EarSmoke />;
    default:
      return null;
  }
};

export default Earrings;
