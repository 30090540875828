const Yuji = () => {
  const styles = {
    one: {
      fill: "#443f3d",
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="m518.19,301.63c-2.27,3.21-5.06,7.97-6.92,14.21-3.01,10.09-2.01,18.78-1.12,23.46,3.14-3.18,6.05-5.31,8.14-6.67,3.35-2.19,4.75-2.49,5.82-2.04,1.35.57,1.87,2.21,2.29,3.8.76,2.89,1.65,7.38,1.77,13.47.83-5.35,1.29-12.82-.41-21.41-.73-3.71-1.74-6.99-2.82-9.82.17.86.58,3.49-.84,6.22-2.17,4.15-6.72,4.89-7.23,4.97-.32-2.82-.51-5.92-.5-9.26.02-6.5.81-12.22,1.82-16.93Z"
      />
      <path
        style={styles.one}
        d="m554.67,300.82c1.94,3.42,4.26,8.42,5.51,14.81,2.02,10.33.19,18.89-1.16,23.46-2.82-3.47-5.51-5.87-7.45-7.42-3.12-2.5-4.49-2.94-5.6-2.59-1.4.44-2.07,2.02-2.64,3.56-1.04,2.81-2.35,7.19-3.06,13.23-.31-5.4-.05-12.89,2.47-21.27,1.09-3.62,2.41-6.79,3.75-9.5-.25.84-.92,3.42.24,6.27,1.76,4.34,6.22,5.52,6.71,5.64.59-2.78,1.08-5.84,1.4-9.16.61-6.47.38-12.24-.17-17.03Z"
      />
      <polygon
        style={styles.one}
        points="533.22 302.73 530 311.91 529.5 318.74 534.69 325.59 537.81 317.77 538.28 311.33 533.22 302.73"
      />
      <path
        style={styles.one}
        d="m492.76,389.45c4.88-.06,12.69-.57,21.24-3.17,6.61-2.01,11.42-4.58,14.54-6.56,1.32-.43,3.8-1.08,6.98-.97,2.14.07,3.86.46,4.99.8,3.51,2.09,9.62,5.2,18.14,7.02,9.13,1.95,16.9,1.55,21.24,1.09-4.71-.14-12.94-.87-21.87-4.36-6.64-2.6-11.36-5.82-14.36-8.24-1.25-.73-3.66-1.93-7.09-2.43-5.87-.85-10.52.95-11.84,1.5-2.18,1.76-4.73,3.6-7.71,5.41-8.55,5.18-17.39,8.15-24.26,9.91Z"
      />
      <path
        style={styles.one}
        d="m409.43,365.29c5.97,1.43,7.7,9.16,5.56,14.26,0,0-.64-.64-.64-.64,5.08-2.03,11.09-1.97,16.02.47-5.51-1.33-11.28-1.1-16.63.76,2.01-5.1,1.34-12.45-4.3-14.85h0Z"
      />
      <path
        style={styles.one}
        d="m642.2,365.47c-4.9,3.69-3.77,10.99-.59,15.45-5.64-.52-11.3.66-16.33,3.27,4.19-3.56,10.01-5.06,15.43-4.32,0,0-.46.81-.47.77-3.31-4.43-3.48-12.34,1.96-15.17h0Z"
      />
      <path
        style={styles.one}
        d="m565.05,523.52l13.94-39.93,22.49-1.68,34.36-39.47,8-47.85-1.66-4.71c-.55.29-2.13,1.05-4.34,1.02-1.89-.03-3.24-.62-3.8-.91-1.07-.12-2.32-.16-3.69-.05-5.39.43-9.26,2.99-10.87,4.19,2.35-2.01,4.67-3.99,6.96-5.95.38-.36,2.24-2.06,5.39-2.38,2.52-.26,4.38.51,4.98.78.32-.58,1.12-1.81,2.83-2.83,1.66-.99,3.32-1.27,4.1-1.37l-.06-7.36,5.73-6.86-3.39,8.61c.65.75,1.82,2.34,2.03,4.56.15,1.58-.25,2.87-.58,3.67.83,1.85,1.67,3.72,2.52,5.61l-6.35,53.69-37.55,46.7-22.08,1.79-18.95,30.73Z"
      />
      <path
        style={styles.one}
        d="m511.2,525.72l-19.44-39.78-25.51-2.84-44.08-42-14.36-49.23,1.52-4.57c.69.36,2.66,1.31,5.3,1.53,2.25.19,3.79-.25,4.44-.48,1.26,0,2.73.1,4.37.37,6.4,1.04,11.19,4.02,13.19,5.4-2.95-2.26-5.87-4.5-8.75-6.71-.48-.4-2.84-2.31-6.6-3.01-3.01-.56-5.16,0-5.84.2-.44-.62-1.52-1.95-3.66-3.18-2.08-1.2-4.11-1.68-5.06-1.87l-.65-7.42-7.59-7.66,4.94,9.13c-.7.68-1.97,2.14-2,4.36-.02,1.58.58,2.93,1.07,3.79-.82,1.78-1.65,3.58-2.5,5.39l13.03,55.23,48.87,49.59,25.21,2.81,24.09,30.95Z"
      />
      <polygon
        style={styles.one}
        points="504.52 497.38 512.3 497.38 515.93 522.54 504.52 497.38"
      />
      <polygon
        style={styles.one}
        points="569.41 495.3 561.63 495.3 558 520.45 569.41 495.3"
      />
      <polygon
        style={styles.one}
        points="417.11 352.55 421.3 373.01 437.37 378.03 423.64 368.94 417.11 352.55"
      />
      <polygon
        style={styles.one}
        points="639.52 355.04 635.34 375.5 619.27 380.53 633 371.43 639.52 355.04"
      />
    </g>
  );
};

export default Yuji;
