const Pharaoh = () => {
  const styles = {
    one: {
      stroke: "#000",
      strokeWidth: 2,
      strokeMiterLimit: 10,
      fill: "#e0b065",
    },
    two: {
      stroke: "#000",
      strokeWidth: 2,
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },
    three: {
      stroke: "#000",
      strokeWidth: 2,
      strokeMiterLimit: 10,
      fill: "#f8b74d",
    },
    four: {
      stroke: "#000",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 0.5,
    },
    five: {
      stroke: "#000",
      strokeWidth: 2,
      strokeMiterLimit: 10,
      fill: "#e5dbd6",
    },
    six: {
      stroke: "#000",
      strokeWidth: 2,
      strokeMiterLimit: 10,
      fill: "#d2414e",
    },
    seven: {
      stroke: "#000",
      strokeWidth: 2,
      strokeMiterLimit: 10,
      fill: "#142f8a",
    },
    eight: {
      stroke: "#000",
      strokeWidth: 2,
      strokeMiterLimit: 10,
      fill: "#2f4a96",
    },
    nine: {
      stroke: "#000",
      strokeWidth: 2,
      strokeLinecap: "round",
      strokeLinejoin: "round",
      fill: "none",
    },
    ten: {
      fill: "#e0b065",
    },
  };

  return (
    <g>
      <path
        style={styles.three}
        d="m501.76,138.28l4.66,11.04s22.32,3.55,22.42,3.19,2.18-13.29,2.18-13.29l-29.26-.94"
      />
      <path
        style={styles.seven}
        d="m438.32,278.39c-2.8-13.74-7.76-33.01-16.77-54.36-10.99-26.03-23.43-44.37-32.3-55.76l-21.87,12.93c8.29,8.52,19.12,21.43,29.72,39.87,14.34,24.95,21.82,48.86,25.64,63.82l15.59-6.5Z"
      />
      <path
        style={styles.seven}
        d="m442.07,147.21c7.23,17.82,14.83,38.9,21.7,62.87,6.1,21.26,10.4,40.55,13.48,57.13-6.28,1.6-12.54,3.2-18.79,4.79-3.6-15.84-9.1-35.82-17.62-58-9.05-23.55-18.82-42.63-27.21-57.11,9.44-3.21,18.92-6.44,28.44-9.68Z"
      />
      <path
        style={styles.three}
        d="m504.36,265.04c-3.39-20.56-8.37-44.72-15.79-71.48-5.34-19.26-11.12-36.86-16.87-52.64l-29.64,6.29c5.91,14.57,11.92,30.95,17.55,49.01,8.32,26.69,13.87,50.77,17.63,70.99,9.02-.72,18.06-1.44,27.12-2.17Z"
      />
      <path
        style={styles.seven}
        d="m520,264.87c2.13-13.92,5.59-45.82-3.2-83.56-4.3-18.47-10.25-32.87-15.26-43l-29.83,2.62c6.76,18.55,13.71,40.06,19.96,64.17,5.77,22.27,9.82,42.48,12.7,59.95l15.64-.17Z"
      />
      <path
        style={styles.three}
        d="m603.83,284.93c2.07-16.6,7.33-45.52,22.11-75.07,6.88-13.75,14.15-24.33,20.5-32.22l-27.5-14.48c-6.73,13.26-14.43,30.7-21.13,52.17-7.57,24.25-11.43,45.78-13.48,61.89,6.52,2.58,13.02,5.15,19.51,7.72Z"
      />
      <path
        style={styles.seven}
        d="m584.32,277.21c2.67-20.57,8.01-48.45,19.29-78.95,4.93-13.34,10.2-25.03,15.32-35.1l-28.77-11.42c-5.19,14-10.57,30.82-15.14,50.18-6.39,27.07-9.27,50.9-10.56,69.28,6.63,2.01,13.24,4.01,19.85,6.01Z"
      />
      <path
        style={styles.three}
        d="m564.47,271.19c1.33-18.89,4.32-43.42,11.03-71.26,4.45-18.46,9.63-34.6,14.67-48.19l-30.15-8.16c-6.16,19.34-12.46,42.77-17.33,69.69-3.57,19.7-5.7,37.55-6.96,52.97,7.26.9,14.71,2.09,22.33,3.61,2.16.43,4.29.88,6.41,1.35Z"
      />
      <path
        style={styles.seven}
        d="m530.79,139.22c-2.94,12.7-5.69,26.38-8.06,40.95-5.26,32.34-7.34,61.04-7.9,84.75l20.9,1.31c1.82-22.1,5.4-49.17,12.42-79.46,3.64-15.72,7.71-30.15,11.87-43.19l-29.23-4.35Z"
      />
      <path
        style={styles.three}
        d="m389.25,168.27l24.37-11.39c7.61,13.12,16.03,29.47,24.02,49.05,10.34,25.33,16.76,48.25,20.81,66.06l-20.14,6.4c-2.92-14.33-8.4-35.71-18.95-59.37-10.42-23.38-21.79-40.07-30.12-50.75Z"
      />
      <path
        style={styles.seven}
        d="m666.9,190.9l-20.47-13.27c-7.03,8.76-15.91,21.7-23.98,39.57-12.32,27.24-16.81,52.81-18.62,67.73,5.72,2.83,11.43,5.66,17.13,8.48,14.69-32.78,30-66.92,45.95-102.5"
      />
      <path
        style={styles.seven}
        d="m367.93,350.97c.38-6.72,2.98-13,7.01-18.24l-50.07.34-.57,4.25-2.4,45.15,42.36,1.33c-1.52-3.93-2.43-8.16-2.62-12.54-.33-7.44,2.1-14.46,6.29-20.29Z"
      />
      <path
        style={styles.seven}
        d="m364.6,414.52l-42.48-1.63,1.46,27.53,52.37,1.66c-4.45-8.85-8.2-18.08-11.35-27.55Z"
      />
      <path
        style={styles.three}
        d="m437.55,655.34l40.7,1,5.67,41.63c-4.87-1-15.88-4.02-27.11-14.25-11.46-10.44-17.05-22.79-19.26-28.38Z"
      />
      <line
        style={styles.seven}
        x1="734.94"
        y1="425.14"
        x2="735.65"
        y2="452.63"
      />
      <path
        style={styles.three}
        d="m602.54,668.88l36.32,1.16c-1.59,3.79-3.83,8.07-7,12.28-10.63,14.11-24.66,17.89-30.17,19.01.28-10.63.56-21.45.85-32.45Z"
      />
      <path
        style={styles.three}
        d="m732.84,396.63l-71.34-2.31c.68,6.74-.78,13.13-3.42,19.44-1.73,10.38-3.16,20.48-6.12,30.29,33.15,9.54,80.85,22.57,82.54,19.04,2.61-5.47-1.66-66.47-1.66-66.47Z"
      />
      <path
        style={styles.seven}
        d="m447.65,519.56c-7.68-4.45-14.87-9.97-21.75-15.83-10.88-9.26-21.83-18.98-29.96-30.83-.43-.52-.88-1.04-1.3-1.57-21.69-.55-37.01-1.1-58.61-1.65,2.83.19.6.84,5.43,3,8.6,3.86,14.56,10.31,26.34,24.45,12.88,15.45,19.22,23.06,22.63,28.03,7.61,11.13,18.44,29.17,27.13,53.95,6.91,26.36,13.57,51.75,19.99,76.22l40.7,1-17.6-129.73c-4.51-1.89-8.85-4.33-13-7.05Z"
      />
      <path
        style={styles.three}
        d="m713.93,295.72l-87.22-1.93,2.16,39.14c.31.14.62.27.94.41,2.62,1.18,4.96,2.91,7.01,4.99,5.87,1.44,11.08,4.34,15.34,8.28,28.54.84,74,2.26,74,2.26l-12.23-53.15Z"
      />
      <path
        style={styles.seven}
        d="m631.12,479.46c-2.02,2.25-2.38,3.69-4.51,5.83-3.76,6.57-10.96,12.96-17.1,17.6-.38,1.03-.83,2.05-1.34,3.05l-5.62,162.95,36.32,1.16c.18-.45.35-.9.53-1.36,1.17-4.04,2.35-8.08,3.52-12.12,2.57-11.89,5.14-23.79,7.7-35.68l4.4-33.6c.73-6.14,2.12-12.99,4.59-20.24.6-1.77,1.23-3.46,1.89-5.1,2.1-5.65,4.65-11.7,7.76-17.99,1.84-3.73,3.72-7.23,5.61-10.52,5.31-7.83,10.4-13.95,14.48-18.44,2.78-3.07,5.56-5.88,8.27-8.47,3.74-3.46,7.85-7.01,12.34-10.58,6.81-5.4,13.43-9.97,19.63-13.84-1.04.26-2.07.52-3.11.78l-95.35-3.44Z"
      />
      <path
        style={styles.seven}
        d="m646.32,458.15l89.33-5.53-.71-27.48-78.42-1.93c-2.02,12.05-4.4,23.71-10.2,34.94Z"
      />
      <path
        style={styles.three}
        d="m375.95,442.07l-52.37-1.66,2.32,24.54,15.56,7.73,53.12-1.43c-7.26-9.03-13.42-18.84-18.62-29.19Z"
      />
      <path
        style={styles.three}
        d="m648.91,452.62c-1.28,3.05-2.76,6.08-4.52,9.07-3.83,6.51-8.36,12.33-13.26,17.78l95.35,3.44,8.64-4.03.54-26.24h-86.75Z"
      />
      <path
        style={styles.three}
        d="m363.97,412.67c-2.79-8.64-1.6-17.3,2.18-24.74-.7-1.33-1.34-2.71-1.89-4.13l-42.36-1.33.21,30.41,42.48,1.63c-.21-.62-.42-1.23-.62-1.85Z"
      />
      <polygon
        style={styles.seven}
        points="713.93 295.72 630.02 296.46 648.9 243.55 701.49 256.62 713.93 295.72"
      />
      <path
        style={styles.three}
        d="m422.74,284.89c-3.95-15.43-11.76-40.29-26.87-65.94-10.12-17.17-20.39-29.42-28.48-37.75-3.49,2.57-6.97,5.13-10.43,7.69-6.06,19.89-11.97,39.32-17.76,58.3,10.7-.53,21.95-.66,33.73-.28,8.76.29,17.29.84,25.56,1.61,5.44,13.66,10.8,27.11,16.08,40.36l8.17-3.99Z"
      />
      <path
        style={styles.three}
        d="m620.95,293.4c3.5-17.55,10.65-44.66,25.8-72.31,6.81-12.43,13.82-22.38,20.15-30.19,3.3,2.42,6.6,4.83,9.89,7.24l26,62.14c-6.99.22-14.08.33-21.27.32-12.31-.02-24.26-.38-35.86-1.04-4.85,13.49-9.62,26.76-14.32,39.81l-10.39-5.97Z"
      />
      <path
        style={styles.four}
        d="m631.34,299.37c1.47-6.37,20.84-88.65,38.24-86.59,2.82.33,10.32,3.55,15.38,38.68-3.63-7.78-7.4-10.2-10.01-11.03-12.85-4.04-31.4,19.51-43.6,58.93Z"
      />
      <polygon
        style={styles.seven}
        points="331.38 284.31 414.57 288.88 398.48 248.52 339.19 247.19 331.38 284.31"
      />
      <path
        style={styles.three}
        d="m403.96,317.99c6.73,0,12.87,1.84,18.11,5l-1.88-36.9-88.81-1.78-7.08,53.02,47.8-.31c6.33-11.2,18.9-19.02,31.85-19.02Z"
      />
      <path
        style={styles.seven}
        d="m664.36,373.35c.26,5.75-1.14,11.25-3.73,16.15.41,1.63.71,3.24.87,4.83l71.34,2.31-6.68-47.76-73.93-2.19c7.16,6.66,11.66,16.23,12.12,26.67Z"
      />
      <path
        style={styles.three}
        d="m365.35,355.16l-41.84-.7-.89,12.94,39.06.59c.29-4.55,1.59-8.89,3.67-12.84Z"
      />
      <path
        style={styles.three}
        d="m664.36,373.35c.13,2.85-.16,5.64-.79,8.32l67.51,1.56-1.92-12.83-65.26-1.5c.23,1.46.39,2.95.46,4.45Z"
      />
      <g>
        <g>
          <path
            style={styles.ten}
            d="m684.61,302.13c2.09.83,3.15,2.96,2.61,5.03-.41,1.6-1.74,2.97-3.43,3.59-39.22-15.89-102.43-35.64-180.22-34.71-68.91.83-121.64,17.79-153.23,30.89-.58-.47-1.82-1.6-2.5-3.61-1.13-3.39.28-6.53.52-7.04,2.37-.97,4.87-1.97,7.48-2.97,32.71-12.62,84.55-27.53,150.95-27.39,76.84.16,139.03,20.14,177.82,36.21Z"
          />
          <path
            style={styles.ten}
            d="m683.79,310.75c-3.99,15.17-7.95,30.19-11.87,45.09-3.18-3.97-7.85-8.91-14.38-13.54-6.53-4.62-12.8-7.43-17.63-9.15-33.54-11.97-83.51-25.25-143.79-24.84-29.31.19-69.47,4.51-114.79,18.44-5.92,1.82-11.51,4.88-16.25,9.21-.04.03-.09.07-.13.11-3.08,2.83-5.41,5.7-7.13,8.13l-7.48-37.27c31.59-13.1,84.32-30.06,153.23-30.89,77.79-.93,141,18.82,180.22,34.71Z"
          />
          <path
            style={styles.ten}
            d="m671.92,355.84c.77,8.27,4.48,52.01-3.53,86.35h-.02c.15-.66.31-1.34.45-2.03-9.46,11.9-18.82,23.66-28.06,35.28,5.2-10.94,10.44-21.97,15.73-33.11l-9.91,12.05,3.88-26.35c1.65-7.56,2.58-14.61,3.07-20.89l-3.07,20.89c-1.8,8.2-4.46,17.02-8.35,26.13,2.74-7.61,5.24-16.19,7.41-25.58,2.06-8.93,3.28-17.5,3.87-25.58l.55.04c1.53,1.65,3.47,4.18,4.93,7.66,2.22,5.29,2.2,10.12,1.97,12.84.38-1.51.74-3.03,1.08-4.57,4.1-18.65,4.52-36.42,2.82-52.66-.36-2.53-1.63-8.89-7.08-14.14-3.44-3.31-7.16-4.85-9.48-5.59-31.68-12.01-76.98-25-131.54-27.15-61.87-2.43-110.2,10.21-139.81,20.67-.75,2.8-1.74,6.8-2.64,11.64-.47,2.52-.85,4.95-1.17,7.29.81-7,1.86-13.26,2.95-18.67-1.52.39-3.58,1.14-5.73,2.67-4.44,3.15-6.45,7.6-7.2,9.55-.4,17.81-.79,35.47-1.18,52.99l7.04-16.82,2.21,23.9,2.05,22.21-6.65-9.77c.61,5.23,1.97,12.38,5.11,20.3,3.42,8.6,7.71,14.97,11,19.16-1.66-.77-4-2.01-6.54-3.96-4.68-3.59-14.9-13.36-19.4-49.83-1.87-15.15-3.15-38.05,1.14-66.56,1.72-2.43,4.05-5.3,7.13-8.13.04-.04.09-.08.13-.11,4.74-4.33,10.33-7.39,16.25-9.21,45.32-13.93,85.48-18.25,114.79-18.44,60.28-.41,110.25,12.87,143.79,24.84,4.83,1.72,11.1,4.53,17.63,9.15,6.53,4.63,11.2,9.57,14.38,13.54Z"
          />
          <path d="m668.82,440.16c-.14.69-.3,1.37-.45,2.03-2.35,10-6.03,17.37-8.8,22.07-3.89,6.6-6.96,9.5-8.33,10.71-5.15,4.55-10.56,6.72-13.95,7.78,1.15-2.43,2.31-4.87,3.47-7.31,9.24-11.62,18.6-23.38,28.06-35.28Z" />
          <path d="m664.74,366.31c1.7,16.24,1.28,34.01-2.82,52.66-.34,1.54-.7,3.06-1.08,4.57.23-2.72.25-7.55-1.97-12.84-1.46-3.48-3.4-6.01-4.93-7.66,1.81-10.87,2.74-25.94-1.33-42.7-1.24-5.07-2.77-9.66-4.43-13.76,2.32.74,6.04,2.28,9.48,5.59,5.45,5.25,6.72,11.61,7.08,14.14Z" />
          <path d="m646.58,454.38l9.91-12.05c-5.29,11.14-10.53,22.17-15.73,33.11-1.91,2.4-3.81,4.79-5.71,7.18-1.36-2.61-1.55-2.97-2.7-5.19.1-.55.23-1.08.39-1.59,1.35-1.74,3.29-4.83,3.5-7.61,0,0,.01-.02.02-.06.01-.02.02-.04.03-.07,2.04-4.17,3.98-8.83,5.82-13.93h0c3.89-9.12,6.55-17.94,8.35-26.14l-3.88,26.35Z" />
          <path d="m383.51,455.47c-.29,3.03-.59,6.06-.89,9.08-3.29-4.19-7.58-10.56-11-19.16-3.14-7.92-4.5-15.07-5.11-20.3l6.65,9.77-2.05-22.21,1.72-.16c.45,5.41,1.13,9.49,2.35,13.21.27.81.52,1.62.77,2.43,2.2,10.72,4.95,19.9,7.56,27.34Z" />
          <path d="m375.97,340.36c-1.09,5.41-2.14,11.67-2.95,18.67-.72,5.38-1.08,10.35-1.23,15.07-.42,8.77-.35,18.33.5,28.52v.06c.14,3.73.31,6.95.54,9.81l-1.72.16-2.21-23.9-7.04,16.82c.39-17.52.78-35.18,1.18-52.99.75-1.95,2.76-6.4,7.2-9.55,2.15-1.53,4.21-2.28,5.73-2.67Z" />
        </g>
        <g>
          <path
            style={styles.nine}
            d="m683.79,310.75c1.69-.62,3.02-1.99,3.43-3.59.54-2.07-.52-4.2-2.61-5.03-38.79-16.07-100.98-36.05-177.82-36.21-66.4-.14-118.24,14.77-150.95,27.39-2.61,1-5.11,2-7.48,2.97-.24.51-1.65,3.65-.52,7.04.68,2.01,1.92,3.14,2.5,3.61,31.59-13.1,84.32-30.06,153.23-30.89,77.79-.93,141,18.82,180.22,34.71Z"
          />
          <path
            style={styles.nine}
            d="m350.34,306.93l7.48,37.27c1.72-2.43,4.05-5.3,7.13-8.13.04-.04.09-.08.13-.11,4.74-4.33,10.33-7.39,16.25-9.21,45.32-13.93,85.48-18.25,114.79-18.44,60.28-.41,110.25,12.87,143.79,24.84,4.83,1.72,11.1,4.53,17.63,9.15,6.53,4.63,11.2,9.57,14.38,13.54,3.92-14.9,7.88-29.92,11.87-45.09"
          />
          <path
            style={styles.nine}
            d="m636.26,468.17c-.68,1.4-1.37,2.74-2.07,4.03-.3.73-.97,2.05-1.45,3.64-.16.51-.29,1.04-.39,1.59-.19,1.08-.25,2.22-.02,3.34.46,1.77,1.22,3.15,1.88,4.12,2.87-.34,5.49-1.03,7.9-2.02,13.93-5.74,21.88-21.79,26.28-40.68,8.01-34.34,4.3-78.08,3.53-86.35"
          />
          <path
            style={styles.nine}
            d="m642.11,454.17c-1.84,5.1-3.78,9.76-5.82,13.93"
          />
          <path
            style={styles.nine}
            d="m372.29,402.62c-.13-3.31-.24-7.03-.37-11.25-.16-5.59-.32-11.23-.13-17.27.15-4.72.51-9.69,1.23-15.07.32-2.34.7-4.77,1.17-7.29.9-4.84,1.89-8.84,2.64-11.64,29.61-10.46,77.94-23.1,139.81-20.67,54.56,2.15,99.86,15.14,131.54,27.15,3.31,12.61,7,32.4,5.21,56.42-.59,8.08-1.81,16.65-3.87,25.58-2.17,9.39-4.67,17.97-7.41,25.58"
          />
          <path
            style={styles.nine}
            d="m357.82,344.2c-4.29,28.51-3.01,51.41-1.14,66.56,4.5,36.47,14.72,46.24,19.4,49.83,2.54,1.95,4.88,3.19,6.54,3.96.44-.93.9-2.22,1.13-3.82.3-2.07.07-3.91-.24-5.26-.2-.94-.45-1.65-.58-2.02-2.84-8.25-4.43-16.95-6.98-25.32-.25-.81-.5-1.62-.77-2.43-1.22-3.72-1.9-7.8-2.35-13.21-.23-2.86-.4-6.08-.54-9.81"
          />
          <path
            style={styles.nine}
            d="m373.02,359.03c.81-7,1.86-13.26,2.95-18.67-1.52.39-3.58,1.14-5.73,2.67-4.44,3.15-6.45,7.6-7.2,9.55-.4,17.81-.79,35.47-1.18,52.99l7.04-16.82,2.21,23.9,2.05,22.21-6.65-9.77c.61,5.23,1.97,12.38,5.11,20.3,3.42,8.6,7.71,14.97,11,19.16.3-3.02.6-6.05.89-9.08-2.61-7.44-5.36-16.62-7.56-27.34-1.59-7.71-2.9-16.22-3.66-25.45v-.06c-.85-10.19-.92-19.75-.5-28.52.25-5.33.68-10.36,1.23-15.07Z"
          />
          <path
            style={styles.nine}
            d="m648.18,346.58c2.32.74,6.04,2.28,9.48,5.59,5.45,5.25,6.72,11.61,7.08,14.14,1.7,16.24,1.28,34.01-2.82,52.66-.34,1.54-.7,3.06-1.08,4.57.23-2.72.25-7.55-1.97-12.84-1.46-3.48-3.4-6.01-4.93-7.66,1.81-10.87,2.74-25.94-1.33-42.7-1.24-5.07-2.77-9.66-4.43-13.76Z"
          />
          <path
            style={styles.nine}
            d="m650.46,428.03l-3.88,26.35,9.91-12.05c-5.29,11.14-10.53,22.17-15.73,33.11-1.16,2.44-2.32,4.88-3.47,7.31,3.39-1.06,8.8-3.23,13.95-7.78,1.37-1.21,4.44-4.11,8.33-10.71,2.77-4.7,6.45-12.07,8.8-22.07.15-.66.31-1.34.45-2.03-9.46,11.9-18.82,23.66-28.06,35.28-1.91,2.4-3.81,4.79-5.71,7.18-1.36-2.61-1.55-2.97-2.7-5.19-.11-.21-.23-.43-.36-.68.21-.23.47-.54.75-.91,1.35-1.74,3.29-4.83,3.5-7.61,0,0,.01-.02.02-.06.01-.02.02-.04.03-.07.3-.83,1.99-5.47,4.26-10.42.54-1.17,1.06-2.34,1.56-3.51h0c3.89-9.12,6.55-17.94,8.35-26.14"
          />
          <path
            style={styles.nine}
            d="m650.46,428.03c1.65-7.56,2.58-14.61,3.07-20.89l-3.07,20.89Z"
          />
        </g>
      </g>
      <path
        style={styles.four}
        d="m408.07,275.26c-12.02-55.11-26.96-75.64-40.27-73.36-10.66,1.83-14.83,27.12-16.2,38.08,6.31-10.75,12.83-12.48,15.99-12.8,16.1-1.65,30.38,9.46,40.48,48.08Z"
      />
      <path
        style={styles.six}
        d="m539.8,264.66l-36.74.39c-4.04.04-7.4-4.9-7.45-10.92l-.79-83.15c-.05-5.11,2.92-9.28,6.59-9.32l33.39-.35c3.67-.04,6.91,4.07,7.19,9.18l4.56,83.11c.33,6.02-2.7,11.03-6.75,11.07Z"
      />
      <polyline
        style={styles.eight}
        points="543.03 189.45 518.47 211.49 518.47 211.49 542.43 190 541.33 167.09 536.47 161.08 527.08 175.43 507.81 175.74 498.92 163.49 497.98 162.19 495 168.14 494.69 190.9 518.47 211.49"
      />
      <polygon
        style={styles.one}
        points="526.41 146.82 506.84 147.16 497.64 161.72 507.81 175.74 527.08 175.43 536.47 161.08 526.41 146.82"
      />
      <line style={styles.nine} x1="517.44" y1="175.58" x2="520" y2="264.87" />
      <polyline
        style={styles.nine}
        points="495.32 223.44 519.26 244.82 544.84 222.36"
      />
      <path
        style={styles.eight}
        d="m542.74,263.66c-14.04.26-28.09.52-42.13.77-.94-.84-2.22-2.2-3.26-4.17-1.56-2.95-1.76-5.72-1.75-7.17.19-10.7.38-21.39.58-32.09l23.21,22.55,25.37-22.55,1.79,32.59c.15,1.35.34,4.96-1.96,8.13-.59.81-1.24,1.45-1.85,1.94Z"
      />
      <polygon
        style={styles.two}
        points="632.87 476 629.02 481.74 631.79 486.17 646.96 480.01 644.41 472.67 632.87 476"
      />
      <path
        style={styles.five}
        d="m351.28,478.94c10.69,8.02,24.17,19.78,37.22,36.19,12.73,16.02,21.03,31.41,26.41,43.43,7.48,18.44,12.22,33.67,15.07,43.63,10.47,36.7,10.5,55.66,26.41,74.54,7.86,9.32,16.5,15.11,21.85,18.13-5.09-1.36-12.61-4.28-20.14-10-13.61-10.33-18.78-23.9-20.56-29.54-3.98-21.63-9.84-45.41-18.51-70.58-3.38-9.83-6.95-19.18-10.63-28.04-6.72-14.82-13.85-25.85-19.2-33.26-4.1-5.68-7.65-9.9-14.17-17.64-9.69-11.5-17.98-20.65-23.76-26.87Z"
      />
      <path
        style={styles.five}
        d="m608.64,697.53c6.38-3.88,15.38-10.6,22.28-21.57,4.08-6.49,6-12.19,7.08-15.65,10.51-33.64,6.29-61.58,17.31-97.96,1.39-4.6,3.88-12.13,7.96-21.26,4.38-8.12,11.15-18.57,21.39-28.96,14.47-14.67,29.58-22.82,39.65-27.24-11.39,7.13-25.61,18.05-38.63,34.3-5.76,7.19-21.13,26.77-28.33,55.56,0,0-6.72,46.14-6.72,46.14-1.91,14.18-4.84,25.77-7.39,34.21-6.2,20.56-10.29,26.19-12.6,28.79-7.28,8.18-16.21,11.88-22,13.64Z"
      />
    </g>
  );
};

export default Pharaoh;
