import { CircularProgress, Grid, Skeleton, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStaffListUsers } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import Avatar from "../../avatar/Avatar";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import {
  getReadableDateString,
  getRole,
  getTime,
  isWithinOneMinute,
} from "../../utils/helpers";
import { AC_USERS_LIMIT } from "../../utils/limits";
import {
  card,
  cardVeryLight,
  container,
  green,
  lightGrey,
  normal,
  red,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";

const StaffListUsers = () => {
  const middleware = useMiddleware();
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const bottom = useRef(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [users, setUsers] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  const handleGetStaffListUsers = () => {
    getStaffListUsers(middleware, skip).then((res) => {
      setLoading(false);

      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        if (res?.users?.length >= AC_USERS_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setUsers(users ? [...users, ...res?.users] : res?.users);
      }
    });
  };

  usePagination(bottom, () => setSkip(users?.length));

  useEffect(() => {
    if (users == null) {
      handleGetStaffListUsers();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetStaffListUsers();
    }
  }, [skip]);

  const styles = {
    width: {
      width: "100%",
    },
    userContainer: {
      width: "100%",
      padding: 1,
      borderRadius: 2,
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: card,
      },
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf="center"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && users && users != null && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            {users?.map((user, i) => (
              <Grid
                item
                sx={styles.userContainer}
                key={i}
                onClick={() => {
                  navigate("/staff/search", {
                    state: {
                      user_id: user?._id,
                    },
                  });
                }}
              >
                <Grid container justifyContent="start" alignItems="center">
                  <Grid item>
                    <Avatar
                      size={75}
                      bgColor={container}
                      avatar={user?.new_avatar}
                    />
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: normal,
                            color: text,
                            fontWeight: 800,
                          }}
                        >
                          {user?.username}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: small,
                            color: lightGrey,
                            fontWeight: 500,
                          }}
                        >
                          {getRole(user?.account_type)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: small,
                            color: red,
                            fontWeight: 500,
                          }}
                        >
                          {user?.likely_alts_of?.join(", ")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}

            {hasMore && (
              <div style={{ width: "100%" }} ref={bottom}>
                <Grid item sx={styles.width}>
                  <Skeleton
                    variant="rectangular"
                    animation="pulse"
                    sx={{
                      bgcolor: cardVeryLight,
                      borderRadius: 2,
                      width: "100%",
                      height: 81,
                    }}
                  />
                </Grid>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StaffListUsers;
