const Jumper = ({ color }) => {
  const styles = {
    one: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: color,
    },
    four: {
      fill: color,
    },
    five: {
      fill: "none",
    },
  };
  return (
    <g>
      <path
        style={styles.four}
        d="M288.78,746.93c12.53,14.57,32.16,34.29,60.22,52.07,12.19,7.73,31.17,18.39,56,27,16.64,5.77,49.08,15.2,91,15,52.58-.25,92.01-15.52,109-23,30.84-13.58,53.45-30.42,68.05-43.01-.76-17.74-2.55-37.56-6.05-58.99-3.9-23.9-9.17-45.28-14.77-63.84-10.03-9.59-22.36-20.03-37.23-30.16-21.68-14.77-42.34-24.54-59.33-31.08-9.18,6.89-22.44,14.79-39.67,19.08-51.45,12.82-94.68-17.36-102.65-23.13-16.34,7.05-34.38,15.92-53.35,27.13-21.42,12.66-39.71,25.75-55,38-1.77,5.14-3.44,10.47-5,16-8.07,28.66-10.96,55.48-11.22,78.93Z"
      />
      <path
        style={styles.five}
        d="M481.52,612c.48,0,18.48,.5,18.48,.5l-6-5.5-10.27,2.02-2.21,2.98Z"
      />
      <polygon
        style={styles.five}
        points="451.5 575.5 446.17 577.21 452.5 579.5 451.5 575.5"
      />
      <line style={styles.two} x1="477" y1="612" x2="500.66" y2="612.89" />
      <path
        style={styles.three}
        d="M511.75,563.23c6.66-.14,15.51,.52,25.25,3.77,3.4,1.14,6.46,2.44,9.17,3.78-11.39,3.54-22.78,7.08-34.17,10.62l-.25-18.18Z"
      />
      <path
        style={styles.three}
        d="M452,561c-4.31-.34-12.82-.39-22,4-5.06,2.42-8.76,5.44-11.24,7.85,7.45,1.45,14.89,2.9,22.34,4.36,2.3-.74,4.6-1.47,6.9-2.21,1.33-4.67,2.67-9.33,4-14Z"
      />
      <path
        style={styles.three}
        d="M416.06,575.85c2.29-4.06,7.22-5.79,11.54-4.04,7.94,3.21,17.28,6.32,27.9,8.69,8.1,1.81,15.67,2.9,22.51,3.52,4.45,.41,7.99,3.95,8.34,8.4,.08,.99,.13,2.01,.14,3.08,.05,3.48-.31,6.64-.85,9.41-.88,4.5-4.87,7.7-9.45,7.53-9.16-.34-20.82-1.78-33.71-5.94-9.85-3.18-18.12-7.2-24.75-11.09-3.88-2.28-5.36-7.02-3.77-11.23,.2-.53,.37-1.08,.52-1.67,.24-.97,.35-1.89,.39-2.71,.07-1.39,.49-2.73,1.17-3.94h0Z"
      />
      <path
        style={styles.three}
        d="M552.24,574.79c-2.52-3.64-6.96-5.1-10.46-3.45-6.52,3.07-14.37,6.13-23.52,8.56-7.07,1.88-13.76,3.08-19.85,3.82-3.97,.48-7.14,4-7.46,8.42-.07,.99-.12,2.03-.13,3.1-.04,3.56,.29,6.84,.8,9.75,.84,4.81,4.68,8.21,9.06,7.9,8.73-.61,19.72-2.48,31.49-7.28,8.81-3.59,15.9-7.94,21.38-12.04,3.16-2.36,3.75-7.05,1.71-11.06-.25-.5-.49-1.02-.7-1.57-.35-.91-.58-1.77-.73-2.53-.26-1.29-.81-2.52-1.57-3.62h0Z"
      />
      <rect
        style={styles.one}
        x="541"
        y="643"
        width="9"
        height="68"
        rx="4.5"
        ry="4.5"
      />
      <line style={styles.one} x1="545.5" y1="643" x2="545.5" y2="711" />
      <line style={styles.two} x1="483.5" y1="612.5" x2="480.31" y2="840.45" />
      <line style={styles.two} x1="492.11" y1="613" x2="490.81" y2="840.45" />
      <path d="M304.84,651.47c4.98,7.56,8.3,16.2,9.98,25.09,1.06,5.83,1.33,11.8,3.44,17.39,12.88,30.21,19.23,63.21,17.73,96.06,0,0-.07,0-.07,0,.42-23.81-2.81-47.68-9.9-70.4-2.58-8.58-5.97-16.81-9.34-25.1-2.19-5.64-2.56-11.72-3.67-17.58-1.18-5.75-2.98-11.41-5.5-16.71-1.26-2.64-2.76-5.17-4.35-7.67,0,0,1.69-1.07,1.69-1.07h0Z" />
      <path d="M653.16,652.51c-2.66,7.06-14.98,39.95-17.37,46.18-1.74,4.43-3.67,9.16-3.76,13.98-.73,8.87-2.09,25.4-2.83,34.42-.12,1.73-.92,10.69-.98,12.27-.11,1.75-.05,5.59-.08,7.37,.02,13.16-.76,26.32-.56,39.47,0,0-.17,0-.17,0,.2-13.16-.58-26.32-.56-39.47,0,0-.02-4.93-.02-4.93-.08-3.27,.71-11.43,.92-14.82,0,0,1.46-19.68,1.46-19.68l.73-9.84c.36-4.8,.47-10.29,2.4-14.78,3.54-9.68,15.18-40.77,18.95-50.86,0,0,1.87,.7,1.87,.7h0Z" />
      <path
        style={styles.one}
        d="M426.5,600.5c-13.19,2.6-38.74,9.36-63,28-28.97,22.26-41.99,49.42-47.14,62.14"
      />
      <path
        style={styles.one}
        d="M632.95,703.65c-3.02-12.48-10.56-36.25-30.45-59.15-23.64-27.22-51.42-37.91-63.8-41.84"
      />
      <path
        style={styles.one}
        d="M481.87,729.09c-16.54-.53-39.87,.63-66.37,8.41-38.21,11.2-64.77,30.79-79.44,43.5"
      />
      <path
        style={styles.one}
        d="M491.46,729.18c24.08-1.56,58.62-.53,97.04,12.32,14.92,4.99,27.96,10.93,39.08,16.93"
      />
      <path d="M407.5,826.5c10.11-7.94,16.39-20.38,17.1-33.17,0,0,.45,0,.45,0l55.91-.83s.01,1,.01,1l-55.91,.83s.49-.47,.49-.46c-1.15,12.78-7.77,24.96-18.06,32.63h0Z" />
      <path d="M491.45,792.49s52.81-.62,52.81-.62h.39s.1,.38,.1,.38c1.95,7.96,5.76,15.48,11.15,21.71,5.36,6.25,12.24,11.19,19.86,14.38-7.54-2.55-14.79-7.95-20.28-14.01-5.55-6.2-9.57-13.76-11.69-21.84,0,0,.49,.38,.49,.38l-52.81,.62v-1h-.01Z" />
    </g>
  );
};

export default Jumper;
