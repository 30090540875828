const Earring = () => {
  const styles = {
    one: {
      fill: "gold",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
  };

  return (
    <g transform="translate(349, 400)">
      <path
        style={styles.one}
        d="M11.06,2.09c-.6,.25-1.88,.69-3.5,.5-2.11-.25-3.48-1.42-3.94-1.85C2.71,1.97,.93,4.71,.56,8.59c-.21,2.31,.15,4.23,.5,5.5,.07,.35,1.24,5.63,4.5,6.5,3.3,.88,8.53-2.77,10-10-.83,.17-1.67,.33-2.5,.5-.83,2.29-3.11,3.18-4.5,2.5-.94-.46-1.51-1.66-1.5-2.96,.36-1.22,.84-2.59,1.5-4.04,.8-1.77,1.68-3.27,2.5-4.5Z"
      />
    </g>
  );
};

export default Earring;
