import { getColor } from "../../utils/getColor";

const Base = ({ color }) => {
  const styles = {
    one: {
      fill: getColor(color),
    },
    two: {
      fill: getColor(color),
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
  };

  return (
    <g>
      <g id="Neck">
        <path
          style={styles.one}
          d="M518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12,.32,7.46,1.17,8.35,.49,.51,1.02,.81,1.4,.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22,.48-.66,1.01-1.11,1.37-1.37,.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
        />
        <path d="M460,523c1.9,1.69,3.9,3.36,6,5,15.78,12.32,32.48,19.14,47,23,1.96-5.48,3.91-10.96,5.87-16.44-4.61,.02-9.68-.16-15.13-.64-.79-.07-6.86-.61-12.73-1.62-12.45-2.13-23.75-6.29-31-9.31Z" />
      </g>
      <g id="Outline">
        <g>
          <path d="M460.98,525.94l-11.31,49.7-.13,.58c-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72,.74-.72,.74l11.31-49.7,1.95,.44h0Z" />
          <path d="M518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15,.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59l1.88,.69h0Z" />
        </g>
      </g>
      <g id="Head">
        <path
          style={styles.two}
          d="M340.97,363.98c-.44,.45-.85,.92-.85,.92-.63,.71-1.47,1.75-2.32,3.08-3.83,6.02-4.02,12.25-4.08,14.87-.08,3.74-.24,12.36,5.93,19.7,1.41,1.67,5.86,6.72,13.5,8.02,5.31,.91,9.62-.43,11.65-1.21,.35,1.08,.7,2.16,1.05,3.24,.89,2.66,2.2,6.5,3.92,11.08,5.77,15.36,12.34,29.14,16.63,36.78,18.49,32.89,48.78,52.52,50.09,53.35,5.51,3.49,26.99,16.57,56.35,20.87,48.98,7.17,96.5-11.21,122.61-37.57,8.76-8.84,15.79-19.26,16.17-19.83,9.81-14.66,13.89-26.95,20.35-46.96,6.8-21.04,10.75-33.5,12.52-51.65,1.23-12.64,2.58-26.47-1.21-44.22-2.02-9.48-9.2-40.95-35.7-60.62,0,0-.97-.72-2.22-1.59-25.41-17.65-105.98-50.7-181.99-20.74-31.37,12.37-44.52,24.29-50.87,33.65-5.34,7.87-8.29,15.77-9.39,18.78-10.44,28.46-3.48,51.92-11.74,54.78-3.81,1.32-5.33-3.66-13.3-3.13-9.13,.61-16.5,7.78-17.11,8.39Z"
        />
      </g>
      <g id="Ear">
        <g>
          <path
            style={styles.three}
            d="M369.44,383.6c.52-3.87,1.04-7.74,1.57-11.61-1.13,.65-2.26,1.3-3.39,1.96-1.05,.61-2.09,1.22-3.13,1.83-.3-.25-4.21-3.44-8.61-2.09-4.19,1.29-7.07,6.16-6.13,11.48,0,.18,.02,1.28,.46,2.68,.49,1.56,1.5,3.49,3.58,4.89,3.92,2.63,8.12,1.06,8.48,.91"
          />
          <path
            style={styles.three}
            d="M364.09,380.99c-1.16-.12-3.95-.24-6.91,1.3-4.06,2.12-5.53,5.85-5.87,6.78"
          />
        </g>
      </g>
    </g>
  );
};

export default Base;
