import { Grid } from "@mui/material";
import { CDN_URL } from "../../utils/constants";

const RivoxCoin = ({ size }) => {


  const Coin = `${CDN_URL}coin.svg`;


  return (
    <Grid
      item
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img draggable={false} height={size} width={size} src={Coin} />
    </Grid>
  );
};

export default RivoxCoin;
