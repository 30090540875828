import { CircularProgress, Grid, Box } from "@mui/material";
import { AiOutlineWarning } from "react-icons/ai";
import { useContext, useEffect, useRef, useState } from "react";
import { getUserHistory } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { MATCH_HISTORY_LIMIT } from "../../utils/limits";
import { secondaryText } from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";
import NotSelected from "../custom/NotSelected";
import MatchHistoryGlimmer from "../glimmers/MatchHistoryGlimmer";
import HistoryItem from "../history/HistoryItem";

const UserHistory = ({ user }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetHistory = () => {
    getUserHistory(middleware, user?._id, skip).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.history?.length >= MATCH_HISTORY_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setHistory(history ? [...history, ...res?.history] : res?.history);
      }
    });
  };

  usePagination(bottom, () => setSkip(history?.length));

  useEffect(() => {
    if (history == null) {
      handleGetHistory();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetHistory();
    }
  }, [skip]);

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf="center"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!history || history?.length < 1) && (
        <Grid item alignSelf="center">
          <NotSelected label="NO HISTORY" />
        </Grid>
      )}

      {!loading && history && history?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 0.5 }}
          >
            {history?.map((token, i) => (
              <Box position="relative" width="100%">
                <HistoryItem match={token} key={i} staffUser={user} />
                {token?.mod_action_taken && (
                  <AiOutlineWarning
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      color: "yellow",
                    }}
                    size={24}
                  />
                )}
              </Box>
            ))}
            {hasMore && (
              <div style={{ width: "100%" }} ref={bottom}>
                <MatchHistoryGlimmer numItems={1} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserHistory;
