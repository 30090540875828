import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import Piece from "../../avatar/Piece";
import {
  normal,
  offWhite,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import TextButton from "../custom/TextButton";
import PiecePreview from "../locker/PiecePreview";
import CategoryOptions from "./CategoryOptions";

const UserLocker = ({ user, setUser }) => {
  const [selected, setSelected] = useState(null);

  const styles = {
    width: {
      width: "100%",
    },
    header: {
      fontSize: 18,
      color: text,
      fontWeight: 800,
    },
    subHeader: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 500,
    },
  };

  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 1 }}
      >
        {selected != null && (
          <Grid item>
            <TextButton
              label="Back"
              color={offWhite}
              icon={
                <BiArrowBack
                  style={{
                    color: offWhite,
                    fontSize: 22,
                  }}
                />
              }
              onClick={() => setSelected(null)}
            />
          </Grid>
        )}

        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item>
              <Typography
                sx={styles.header}
              >{`${user?.username}'s Locker`}</Typography>
            </Grid>

            <Grid item>
              <Typography sx={styles.subHeader}>Select a category</Typography>
            </Grid>
          </Grid>
        </Grid>

        {selected == null && (
          <Grid item sx={styles.width}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 1 }}
            >
              <PiecePreview
                title="Hair"
                size={100}
                option={user?.new_avatar?.options?.hair}
                value={user?.new_avatar?.options?.hair?.value}
                selected={selected === "hair"}
                onClick={() => setSelected("hair")}
              >
                <Piece
                  type="hair"
                  color={user?.new_avatar?.options?.hairColor}
                  option={user?.new_avatar?.options?.hair?.value}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Hat"
                size={100}
                value={user?.new_avatar?.options?.hat?.value}
                option={user?.new_avatar?.options?.hat}
                selected={selected === "hat"}
                onClick={() => setSelected("hat")}
              >
                <Piece
                  type="hat"
                  option={user?.new_avatar?.options?.hat?.value}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Facial Hair"
                size={100}
                option={user?.new_avatar?.options?.facialHair}
                value={user?.new_avatar?.options?.facialHair?.value}
                selected={selected === "facialHair"}
                onClick={() => setSelected("facialHair")}
              >
                <Piece
                  type="facialHair"
                  option={user?.new_avatar?.options?.facialHair?.value}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Earrings"
                size={100}
                option={user?.new_avatar?.options?.earrings}
                value={user?.new_avatar?.options?.earrings?.value}
                selected={selected === "earrings"}
                onClick={() => setSelected("earrings")}
              >
                <Piece
                  type="earrings"
                  option={user?.new_avatar?.options?.earrings?.value}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Eyes"
                size={100}
                option={user?.new_avatar?.options?.eyes}
                value={user?.new_avatar?.options?.eyes?.value}
                selected={selected === "eyes"}
                onClick={() => setSelected("eyes")}
              >
                <Piece
                  type="eyes"
                  option={user?.new_avatar?.options?.eyes?.value}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Eyebrows"
                size={100}
                option={user?.new_avatar?.options?.eyebrows}
                value={user?.new_avatar?.options?.eyebrows?.value}
                selected={selected === "eyebrows"}
                onClick={() => setSelected("eyebrows")}
              >
                <Piece
                  type="eyebrows"
                  option={user?.new_avatar?.options?.eyebrows?.value}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Nose"
                size={100}
                option={user?.new_avatar?.options?.nose}
                value={user?.new_avatar?.options?.nose?.value}
                selected={selected === "nose"}
                onClick={() => setSelected("nose")}
              >
                <Piece
                  type="nose"
                  option={user?.new_avatar?.options?.nose?.value}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Mouth"
                size={100}
                option={user?.new_avatar?.options?.mouth}
                value={user?.new_avatar?.options?.mouth?.value}
                selected={selected === "mouth"}
                onClick={() => setSelected("mouth")}
              >
                <Piece
                  type="mouth"
                  option={user?.new_avatar?.options?.mouth?.value}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Glasses"
                size={100}
                option={user?.new_avatar?.options?.glasses}
                value={user?.new_avatar?.options?.glasses?.value}
                selected={selected === "glasses"}
                onClick={() => setSelected("glasses")}
              >
                <Piece
                  type="glasses"
                  option={user?.new_avatar?.options?.glasses?.value}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Clothes"
                size={100}
                option={user?.new_avatar?.options?.clothes}
                value={user?.new_avatar?.options?.clothes?.value}
                selected={selected === "clothes"}
                onClick={() => setSelected("clothes")}
              >
                <Piece
                  type="clothes"
                  option={user?.new_avatar?.options?.clothes?.value}
                  color={user?.new_avatar?.options?.clothesColor}
                  skinColor={user?.new_avatar?.options?.skinColor}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Necklace"
                size={100}
                option={user?.new_avatar?.options?.necklace}
                value={user?.new_avatar?.options?.necklace?.value}
                selected={selected === "necklace"}
                onClick={() => setSelected("necklace")}
              >
                <Piece
                  type="necklace"
                  option={user?.new_avatar?.options?.necklace?.value}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Back Bling"
                size={100}
                option={user.avatar?.options?.backBling}
                value={user.avatar?.options?.backBling?.value}
                selected={selected === "backBling"}
                onClick={() => setSelected("backBling")}
              >
                <Piece
                  type="backBling"
                  option={user.avatar?.options?.backBling?.value}
                  size={100}
                />
              </PiecePreview>

              <PiecePreview
                title="Tattoos"
                size={100}
                option={user?.new_avatar?.options?.tattoos}
                value={user?.new_avatar?.options?.tattoos?.value}
                selected={selected === "tattoos"}
                onClick={() => setSelected("tattoos")}
              >
                <Piece
                  type="tattoos"
                  option={user?.new_avatar?.options?.tattoos?.value}
                  size={100}
                />
              </PiecePreview>
              <PiecePreview
                title="Emote"
                size={100}
                option={user?.new_avatar?.options?.emote}
                value={user?.new_avatar?.options?.emote?.value}
                selected={selected === "emote"}
                onClick={() => setSelected("emote")}
              >
                <Piece
                  type="emote"
                  option={user?.new_avatar?.options?.emote?.value}
                  size={100}
                />
              </PiecePreview>
            </Grid>
          </Grid>
        )}

        {selected != null && (
          <CategoryOptions selected={selected} user={user} setUser={setUser} />
        )}
      </Grid>
    </Grid>
  );
};

export default UserLocker;
