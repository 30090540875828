const Wavy = ({ color }) => {
  const styles = {
    one: {
      fill: color,
      filter: "brightness(85%)",
      strokeWidth: 2,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "none",
      strokeWidth: 3,
    },
    three: {
      fill: color,
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="M665.32,308.08c-20.24-58.88-97.44-73.54-121.32-78.08-13.84-2.63-64.04-11.5-113,12-6.75,3.24-20.7,10.56-35.5,23.5-7.35,6.43-30.45,27.06-37.22,52.06-3.78,13.94-4.6,21.66-3.63,37.74,.47,7.79,1.23,10.56,2.52,20.73,.71,5.59,1.62,13.62,2.33,23.47,1.4,1.56,2.37,2.97,3,4,1.16,1.88,2,3.77,3,6,.11,.26,.35,.79,.62,1.41,.74,1.74,1.72,4.3,2.7,7.66,.85-2.86,3.06-8.22,9.39-13.83,3.46-3.06,7.11-5.28,9.95-6.77,1.72-12.05,3.59-24.28,5.62-36.7,2.15-13.18,4.39-26.11,6.73-38.78l178.2,1.13c7.79-.28,36.46-1.7,38.14-10.71,.97-5.19-7.36-11.14-13.34-15.42-16.23-11.61-31.8-12.84-31.77-12.97,.05-.2,38.66,.89,49.77,22.97,3.05,6.07,3.23,12.13,3,16,3.13,.16,6.26,.32,9.39,.48,1.87,10.17,3.74,20.35,5.61,30.52,4.14,2.4,10.2,6.74,15,14,3.64,5.5,5.19,10.63,5.93,13.16,2.02,6.86,1.56,11.82,2.07,11.84,.49,.02,1.36-4.51,3-25,3.95-13.8,8.03-36.52-.18-60.42Z"
      />
      <path
        style={styles.three}
        d="M369.03,293.62c5.4-3.36,10.97-6.44,16.62-9.38,2.85-1.42,5.68-2.89,8.59-4.19l4.34-2.01c1.45-.67,2.93-1.27,4.39-1.91l4.42-1.85,4.47-1.73c1.49-.57,2.97-1.17,4.48-1.71l4.52-1.59c1.51-.52,3.01-1.08,4.54-1.56l4.59-1.41,4.59-1.41,4.63-1.26c6.16-1.75,12.42-3.14,18.7-4.4l9.46-1.74,9.52-1.39c12.73-1.57,25.57-2.45,38.41-2.33,12.83,.18,25.67,1,38.37,2.86,12.68,1.93,25.27,4.48,37.57,8.06,12.32,3.48,24.37,7.88,36.05,13l4.36,1.96,4.31,2.07c2.89,1.35,5.71,2.84,8.53,4.31,5.61,3.01,11.14,6.17,16.49,9.6l-.97,1.75c-22.8-10.81-46.48-19.33-70.77-25.13-12.16-2.83-24.42-5.14-36.78-6.63l-9.29-.99c-3.1-.32-6.21-.46-9.31-.69-1.55-.09-3.1-.25-4.66-.31l-4.66-.15-4.66-.15-2.33-.08-2.33,.02c-24.88-.09-49.79,2.46-74.13,8-24.36,5.48-48.16,13.63-71.12,24.12l-.94-1.76Z"
      />
      <path d="M372.03,289.62c5.31-3.3,10.79-6.32,16.35-9.21,2.8-1.4,5.59-2.83,8.45-4.11l4.27-1.97c1.43-.64,2.88-1.24,4.32-1.87,1.45-.61,2.89-1.22,4.35-1.81l4.4-1.69c1.47-.55,2.92-1.15,4.41-1.67l4.45-1.56c1.49-.51,2.96-1.07,4.47-1.52l4.51-1.38,4.52-1.37,4.56-1.23,4.56-1.23c1.52-.41,3.06-.73,4.6-1.09l4.61-1.03,2.31-.51,2.32-.43c3.1-.56,6.2-1.16,9.31-1.68l9.37-1.35c12.53-1.51,25.15-2.3,37.78-2.16,12.62,.23,25.24,1.11,37.72,2.96,12.46,1.96,24.83,4.52,36.91,8.1,12.1,3.48,23.92,7.86,35.39,12.97l4.28,1.95,4.23,2.06c2.83,1.34,5.6,2.82,8.36,4.29,5.5,3,10.92,6.13,16.16,9.54l-.98,1.75c-22.37-10.73-45.61-19.2-69.46-25-11.94-2.83-23.98-5.15-36.12-6.66-12.15-1.5-24.37-2.26-36.59-2.45l-9.17,.04c-1.53-.02-3.06,.05-4.58,.13l-4.58,.21-4.58,.19c-1.53,.1-3.05,.24-4.57,.36-3.05,.27-6.1,.45-9.14,.8-12.19,1.15-24.26,3.35-36.25,5.9-23.96,5.31-47.38,13.26-69.98,23.5l-.94-1.77Z" />
      <path
        style={styles.three}
        d="M393.05,269.61c18.88-11.37,39.61-19.86,61.14-25.35,2.7-.65,5.39-1.38,8.11-1.96,2.73-.54,5.44-1.15,8.18-1.62,2.74-.45,5.48-.95,8.23-1.37l8.28-1.11c2.77-.29,5.55-.52,8.32-.78,2.77-.24,5.56-.33,8.34-.5,2.78-.19,5.57-.14,8.36-.17l4.18-.03c1.39,0,2.79-.05,4.18,.04,11.14,.33,22.26,1.35,33.26,3.12,10.97,1.93,21.85,4.33,32.46,7.64,21.23,6.54,41.56,15.9,59.91,28.12l-.99,1.74c-19.72-9.43-40.11-17.01-61.04-22.33-10.48-2.56-21.04-4.72-31.7-6.2-2.67-.3-5.34-.63-8.01-.96-2.66-.39-5.35-.5-8.03-.74-5.35-.54-10.73-.66-16.1-.89-10.74-.16-21.5,.05-32.22,1.02l-8.03,.82c-2.67,.32-5.33,.71-8,1.07-5.32,.83-10.63,1.73-15.92,2.78-21.16,4.28-41.85,10.96-62,19.46l-.91-1.78Z"
      />
      <path d="M393.02,266.62c29.55-19.17,64.5-30.68,99.64-33.98,51.49-4.84,105.12,8.13,147.95,37.17,0,0-1.01,1.73-1.01,1.73-19.27-9.43-39.28-16.91-59.8-22.15-41-10.29-84.22-11.05-125.5-1.67-20.66,4.64-40.8,11.72-60.32,20.65,0,0-.97-1.75-.97-1.75h0Z" />
      <path
        style={styles.three}
        d="M359.32,313.25c45.3-28.34,98.58-43.35,151.91-44.53,53.38-1.09,107.19,11.6,153.71,37.88,0,0-.89,1.79-.89,1.79-24.27-10.28-49.36-18.14-74.95-23.3-47.91-9.65-97.74-9.31-145.43,1.21-28.77,6.19-56.59,16.03-83.4,28.7,0,0-.96-1.75-.96-1.75h0Z"
      />
      <path d="M360,310.63c5.54-3.69,11.27-7.1,17.1-10.35,2.93-1.6,5.86-3.19,8.86-4.66l4.48-2.24,4.54-2.13c3.02-1.44,6.11-2.71,9.19-4.02,3.06-1.35,6.21-2.49,9.32-3.73l2.34-.91c.78-.29,1.58-.55,2.37-.83l4.75-1.64c3.15-1.14,6.38-2.04,9.58-3.05,3.19-1.03,6.44-1.87,9.68-2.75l4.87-1.27,4.91-1.09c3.28-.72,6.55-1.51,9.86-2.06,3.31-.58,6.6-1.26,9.92-1.76,13.28-2.1,26.72-3.08,40.15-3.39h10.08c1.68-.03,3.36,.11,5.04,.16l5.04,.23,5.03,.25,5.02,.46,5.02,.46c1.67,.14,3.34,.32,5.01,.57l9.98,1.39,9.92,1.76,2.48,.45,2.46,.54,4.91,1.09c1.63,.39,3.28,.71,4.9,1.15l4.86,1.29c12.97,3.41,25.66,7.78,37.99,12.95l4.6,1.98,4.55,2.09c3.05,1.36,6.02,2.88,9.01,4.37,5.93,3.07,11.77,6.27,17.43,9.78l-.95,1.76c-23.99-11.1-48.95-19.67-74.49-25.33-12.79-2.72-25.66-5.05-38.67-6.24-.81-.09-1.62-.19-2.43-.26l-2.44-.18-4.88-.38-4.88-.38-4.89-.18c-3.26-.08-6.52-.31-9.78-.32l-9.78,.04h-2.45s-2.44,.11-2.44,.11l-4.89,.21-4.89,.21c-1.63,.07-3.25,.26-4.88,.38-3.25,.28-6.51,.48-9.75,.83l-9.72,1.17c-3.24,.4-6.45,.97-9.68,1.45-3.24,.45-6.43,1.13-9.64,1.74l-4.81,.93-4.77,1.1c-3.19,.71-6.39,1.39-9.54,2.26-25.35,6.48-50,15.87-73.64,27.72l-1-1.73Z" />
      <path d="M668.81,332.23c-6.73-5.6-14-10.43-21.86-14.11-3.91-1.86-7.97-3.42-12.11-4.74-4.15-1.29-8.38-2.35-12.68-3.16l.25-1.98c4.47,.3,8.93,1.03,13.29,2.19,4.36,1.16,8.61,2.72,12.7,4.63,4.08,1.94,8,4.21,11.65,6.85,3.67,2.62,7.07,5.6,10.13,8.88l-1.38,1.45Z" />
      <path d="M668.81,334.23c-13.36-11.34-29.44-18.9-46.65-22.01,0,0,.25-1.98,.25-1.98,13.48,.9,26.67,5.81,37.64,13.67,3.67,2.62,7.07,5.6,10.13,8.88l-1.38,1.45h0Z" />
      <path
        style={styles.three}
        d="M354.01,335.63c18.91-12.42,39.53-22.36,61-29.92l8.09-2.75c2.72-.86,5.46-1.62,8.19-2.43,2.72-.84,5.49-1.53,8.25-2.23,2.77-.68,5.51-1.48,8.3-2.05,11.11-2.59,22.41-4.3,33.74-5.67,11.36-1.08,22.77-1.83,34.18-1.71,11.4,.14,22.81,.76,34.1,2.25,22.6,2.81,44.85,8.2,65.93,16.43l-.6,1.91c-5.48-1.27-10.95-2.5-16.44-3.62-5.49-1.1-10.98-2.13-16.5-3-5.51-.88-11.02-1.71-16.56-2.32-5.53-.65-11.07-1.21-16.61-1.62-2.77-.23-5.55-.34-8.32-.51l-8.32-.36-8.32-.15c-2.78,.02-5.55,0-8.32,.06-11.09,.19-22.16,.99-33.19,2.08l-8.25,1.03c-1.37,.18-2.75,.33-4.12,.53l-4.1,.67c-2.74,.45-5.48,.83-8.21,1.33l-8.17,1.58c-10.88,2.18-21.65,4.91-32.33,8.04-21.32,6.37-42.12,14.57-62.45,24.18l-.98-1.74Z"
      />
      <path d="M354,332.63c18.76-12.5,39.27-22.51,60.65-30.09l8.06-2.75c2.71-.86,5.44-1.62,8.16-2.43,2.71-.84,5.47-1.53,8.22-2.23l4.13-1.06,2.07-.53,2.08-.46c11.08-2.56,22.34-4.28,33.65-5.59,2.83-.29,5.67-.47,8.5-.71,2.84-.22,5.67-.5,8.51-.54l8.53-.28c2.84-.12,5.69-.03,8.53-.02,11.37,.22,22.73,.89,33.98,2.48,11.25,1.48,22.4,3.69,33.36,6.49,10.94,2.9,21.72,6.34,32.15,10.61l-.62,1.9c-10.88-2.69-21.76-5.13-32.73-6.99-10.96-1.91-21.97-3.28-33.01-4.24-11.04-.87-22.1-1.36-33.15-1.18-11.05,.13-22.08,.86-33.06,1.93l-8.22,1c-1.37,.18-2.74,.3-4.11,.53l-4.09,.66c-2.72,.44-5.46,.8-8.18,1.31l-8.14,1.56c-10.84,2.17-21.56,4.89-32.19,8.03-21.22,6.39-41.91,14.64-62.1,24.35l-.99-1.74Z" />
      <path
        style={styles.three}
        d="M442.72,321.81c11.68-4.32,23.67-7.24,35.91-9.22,12.24-1.95,24.6-2.93,37.05-2.88,12.39,.09,24.71,1.05,36.94,2.76,12.21,1.73,24.32,4.22,36.12,7.8l-.22-.02,2,.25v2l-2,.25-.22-.02-18.28-1.2c-6.07-.4-12.13-.78-18.17-1.11-12.09-.65-24.18-1.11-36.22-1.22-11.99-.06-24.05,.38-36.09,1.2-12.04,.78-24.14,2.1-36.26,3.33l-.55-1.92Z"
      />
      <path d="M427.73,321.71c54.18-21.22,116.9-21.44,171.31-.9,0,0-.53,1.93-.53,1.93-42.29-7.26-85.04-9.86-127.7-5.19-14.17,1.44-28.29,3.66-42.53,6.09,0,0-.55-1.92-.55-1.92h0Z" />
      <path
        style={styles.three}
        d="M636.22,334.52c2.73,1.24,5.46,2.5,8.17,3.79l8.11,3.92c2.68,1.35,5.39,2.65,8.05,4.05,2.67,1.38,5.32,2.79,7.96,4.22-2.79-1.11-5.57-2.24-8.34-3.41l-8.3-3.48-8.26-3.57c-2.75-1.2-5.48-2.44-8.2-3.7l.82-1.82Z"
      />
      <path d="M636.17,333.5c11.41,4.35,22.16,10.27,32.33,17-8.15-4.07-16.22-8.26-24.65-11.7-2.79-1.18-5.59-2.32-8.4-3.44,0,0,.73-1.86,.73-1.86h0Z" />
      <path
        style={styles.three}
        d="M668.8,335.45c-13.36-11.34-29.44-18.9-46.65-22.01,0,0,.25-1.98,.25-1.98,13.48,.9,26.67,5.81,37.64,13.67,3.67,2.62,7.07,5.6,10.13,8.88,0,0-1.38,1.45-1.38,1.45h0Z"
      />
      <path
        style={styles.three}
        d="M398.85,335.63c-16.02,3.65-31.07,11.1-43.69,21.62,0,0-1.32-1.5-1.32-1.5,9.19-8.66,20.39-15.17,32.38-19.1,4.01-1.3,8.1-2.32,12.25-2.99l.39,1.96h0Z"
      />
      <path d="M398.85,333.63c-16.02,3.65-31.07,11.1-43.69,21.62,0,0-1.32-1.5-1.32-1.5,9.19-8.66,20.39-15.17,32.38-19.1,4.01-1.3,8.1-2.32,12.25-2.99l.39,1.96h0Z" />
      <path
        style={styles.three}
        d="M394.1,363.56c-12.79,3.24-24.76,9.29-34.93,17.69,0,0-1.33-1.49-1.33-1.49,7.32-7.1,16.33-12.43,25.98-15.68,3.23-1.07,6.52-1.92,9.87-2.47l.42,1.96h0Z"
      />
      <path d="M394.1,361.56c-3.19,.84-6.35,1.8-9.42,2.96-3.08,1.15-6.11,2.43-9.02,3.95-2.94,1.46-5.78,3.13-8.53,4.93-2.75,1.8-5.39,3.78-7.96,5.85l-1.33-1.49c2.43-2.37,5.06-4.53,7.84-6.49,2.77-1.97,5.71-3.69,8.73-5.26,3.04-1.52,6.18-2.85,9.41-3.93,3.23-1.07,6.52-1.92,9.87-2.47l.42,1.96Z" />
      <path
        style={styles.two}
        d="M665.39,308.03c-20.24-58.88-97.44-73.54-121.32-78.08-13.84-2.63-64.04-11.5-113,12-6.75,3.24-20.7,10.56-35.5,23.5-7.35,6.43-30.45,27.06-37.22,52.06-3.78,13.94-4.6,21.66-3.63,37.74,.47,7.79,1.23,10.56,2.52,20.73,.71,5.59,1.62,13.62,2.33,23.47,1.4,1.56,2.37,2.97,3,4,1.16,1.88,2,3.77,3,6,.11,.26,.35,.79,.62,1.41,.74,1.74,1.72,4.3,2.7,7.66,.85-2.86,3.06-8.22,9.39-13.83,3.46-3.06,7.11-5.28,9.95-6.77,1.72-12.05,3.59-24.28,5.62-36.7,2.15-13.18,4.39-26.11,6.73-38.78l178.2,1.13c7.79-.28,36.46-1.7,38.14-10.71,.97-5.19-7.36-11.14-13.34-15.42-16.23-11.61-31.8-12.84-31.77-12.97,.05-.2,38.66,.89,49.77,22.97,3.05,6.07,3.23,12.13,3,16,3.13,.16,6.26,.32,9.39,.48,1.87,10.17,3.74,20.35,5.61,30.52,4.14,2.4,10.2,6.74,15,14,3.64,5.5,5.19,10.63,5.93,13.16,2.02,6.86,1.56,11.82,2.07,11.84,.49,.02,1.36-4.51,3-25,3.95-13.8,8.03-36.52-.18-60.42Z"
      />
    </g>
  );
};

export default Wavy;
