import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useState } from "react";
import { BiLeftArrowAlt, BiX } from "react-icons/bi";
import { IoSearch } from "react-icons/io5";
import { usePopper } from "react-popper";
import { useNavigate } from "react-router-dom";
import { searchForUser } from "../../../api/search";
import { adminUserSearch } from "../../../api/staff";
import useMiddleware from "../../../api/useMiddleware";
import Avatar from "../../../avatar/Avatar";
import {
  SET_ERRORS,
  SET_SEARCH_OPEN,
  SET_SEARCH_USER,
  StoreContext,
  StoreDispatch,
} from "../../../store/Store";
import { AccountTypeEnum } from "../../../utils/enums";
import {
  card,
  cardDark,
  cardExtraLight,
  cardLight,
  cardVeryLight,
  doubtedBlue,
  emptyIcon,
  lightGrey,
  menuShadow,
  normal,
  secondaryText,
  text,
  transition,
} from "../../../utils/themeContstants";
import CircleIconButton from "../../custom/CircleIconButton";
import CustomInput from "../../custom/CustomInput";
import CustomPortal from "../../custom/CustomPortal";
import Empty from "../../custom/Empty";
import "../../custom/styles/ScrollBar.css";

const NavSearchBar = ({ setFocused, focused }) => {
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const isTablet = useMediaQuery("(max-width: 768px)");
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpenProfile = (user_id) => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const handleOpenSearch = () => {
    updateStore({ type: SET_SEARCH_OPEN, payload: true });
    setFocused(true);
  };

  const handleCloseSearch = () => {
    updateStore({ type: SET_SEARCH_OPEN, payload: false });
  };

  const closeSearch = () => {
    setQuery("");
    setResults(null);
    setLoading(false);
    setFocused(false);
    handleCloseSearch();
  };

  const handleSearch = (searchQuery) => {
    if (
      searchQuery === "" ||
      searchQuery == null ||
      searchQuery?.length >= 21
    ) {
      setResults(null);
      return;
    }

    setResults(null);
    setLoading(true);
    if (store?.user?.account_type >= AccountTypeEnum.JUNIOR_ADMIN) {
      adminUserSearch(middleware, searchQuery).then((res) => {
        setLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          setResults(res?.userArray);
        }
      });
    } else {
      searchForUser(middleware, searchQuery).then((res) => {
        setLoading(false);
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          setResults(res?.userArray);
        }
      });
    }
  };

  const localStyles = {
    width: {
      width: "100%",
    },
    container: {
      maxWidth: 240,
      zIndex: 9999,
    },
    resultsContainer: {
      backgroundColor: card,
      borderRadius: 2,
      padding: 1,
      boxShadow: menuShadow,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: !isTablet ? 300 : "95vw",
      position: "fixed",
      border: `1px solid ${cardExtraLight}`,
      top: 74,
      left: 10,
      zIndex: 9998,
      overflowY: "auto",
    },
    closeButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      right: 0,
      top: 10,
    },
  };

  return (
    <Grid item sx={localStyles.container}>
      {(isDesktop || store?.searchOpen) && (
        <>
          <Grid item>
            <Grid item>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 1 }}
                wrap="nowrap"
              >
                {focused && (
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 100,
                      height: 38,
                      width: 38,
                      minHeight: 38,
                      minWidth: 38,
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: cardVeryLight,
                      },
                    }}
                    onClick={closeSearch}
                  >
                    <BiLeftArrowAlt
                      style={{ fontSize: 24, color: lightGrey }}
                    />
                  </Grid>
                )}

                <Grid item sx={{ position: "relative" }}>
                  <CustomInput
                    value={query}
                    placeholder="Search users..."
                    onChange={(val) => {
                      setQuery(val);
                      handleSearch(val);
                    }}
                    onPaste={(val) => {
                      setQuery(val);
                      handleSearch(val);
                    }}
                    backgroundColor={cardDark}
                    borderColor={cardExtraLight}
                    borderRadius={100}
                    onFocus={() => setFocused(true)}
                    autoFocus={store?.searchOpen ? true : false}
                    onBlur={closeSearch}
                  />

                  {query != null && query !== "" && (
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 100,
                        backgroundColor: doubtedBlue,
                        height: 20,
                        width: 20,
                        position: "absolute",
                        top: 10,
                        right: 10,
                        "&:hover": {
                          cursor: "pointer",
                          backgroundColor: "#147fff",
                        },
                      }}
                      onClick={closeSearch}
                      tabIndex={0}
                    >
                      <BiX style={{ color: cardLight, fontSize: 20 }} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {((results && query != null && query !== "") || loading) && (
            <Grid item sx={localStyles.resultsContainer}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="start"
                sx={{ maxHeight: "85vh", overflowY: "auto" }}
                className="scroll"
              >
                {loading && (
                  <Grid
                    item
                    alignSelf="center"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={30} sx={{ color: secondaryText }} />
                  </Grid>
                )}

                {!loading &&
                  query !== "" &&
                  query != null &&
                  (results == null || results?.length < 1) && (
                    <Empty
                      title="No results"
                      label="Try searching something else."
                      icon={<IoSearch style={emptyIcon} />}
                    />
                  )}

                {loading ||
                results?.length < 1 ||
                results == null ||
                query === "" ||
                query == null ? null : (
                  <Grid item sx={localStyles.width}>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item sx={{ paddingLeft: 1, paddingTop: 1 }}>
                        <Typography
                          sx={{
                            fontSize: normal,
                            fontWeight: 600,
                            color: text,
                          }}
                        >
                          Search for "
                          <span style={{ fontWeight: 800 }}>{query}</span>"
                        </Typography>
                      </Grid>

                      {results?.map((user, i) => (
                        <Grid
                          item
                          key={i}
                          id="user-button"
                          sx={{
                            width: "100%",
                            borderRadius: 2,
                            "&:hover": {
                              cursor: "pointer",
                              backgroundColor: cardVeryLight,
                            },
                          }}
                          onMouseDown={() => {
                            if (
                              store?.user?.account_type >=
                              AccountTypeEnum.JUNIOR_ADMIN
                            ) {
                              navigate("/staff/search", {
                                state: {
                                  user_id: user?.user_id,
                                },
                              });
                            } else {
                              handleOpenProfile(user?.user_id);
                            }

                            closeSearch();
                          }}
                        >
                          <Grid
                            container
                            justifyContent="start"
                            alignItems="center"
                            gap={{ xs: 1 }}
                          >
                            <Grid item>
                              <Avatar
                                size={65}
                                bgColor={cardVeryLight}
                                avatar={user?.avatar}
                              />
                            </Grid>

                            <Grid item>
                              <Typography
                                sx={{
                                  transition: transition,
                                  fontSize: normal,
                                  color: user?.isPremium
                                    ? "rgb(255, 255, 193)"
                                    : text,
                                  textShadow: user?.isPremium
                                    ? "rgb(255 93 0) 0px 0px 10px"
                                    : null,
                                  fontWeight: 800,
                                }}
                              >
                                {user?.username}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}

      {!isDesktop && !store?.searchOpen && (
        <CircleIconButton label="Search" onClick={handleOpenSearch}>
          <IoSearch fontSize={20} color={lightGrey} />
        </CircleIconButton>
      )}
    </Grid>
  );
};

export default NavSearchBar;
