import { Grid, Typography } from "@mui/material";

import { RiErrorWarningFill } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";

import { getReadableDateString, getTime } from "../../utils/helpers";
import {
  doubtedBlue,
  offWhite,
  red,
  secondaryText,
  small,
} from "../../utils/themeContstants";
import CustomModal from "../custom/CustomModal";

const BannedModal = ({ open, onClose }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    setSearchParams({});
    onClose();
  };

  const styles = {
    meta: {
      fontSize: small,
      color: secondaryText,
      fontWeight: 500,
      textAlign: "center",
      maxWidth: 400,
    },
    width: {
      width: "100%",
    },
    label: {
      fontSize: 15,
      color: offWhite,
      fontWeight: 500,
      maxWidth: 400,
    },
  };

  return (
    <CustomModal
      title="You have been banned"
      titleSize="large"
      open={open}
      onClose={handleClose}
      bottomContent={
        <Grid item>
          <Typography sx={styles.meta}>
            Please be aware that you cannot purchase or appeal an unban if you
            were banned for cheating.{" "}
          </Typography>
        </Grid>
      }
    >
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        <Grid item sx={styles.width}>
          <Grid
            container
            justifyContent="start"
            alignItems="start"
            gap={{ xs: 1 }}
          >
            <Grid item>
              <RiErrorWarningFill style={{ color: red, fontSize: 22 }} />
            </Grid>

            <Grid item alignContent={"center"}>
              <Typography sx={styles.label}>
                You are currently banned until{" "}
                <span style={{ color: red }}>
                  {searchParams.get("time") &&
                    // getReadableDateString(new Date(searchParams.get("time")))}
                    getReadableDateString(new Date(searchParams.get("time"))) +
                      " at " +
                      getTime(new Date(searchParams.get("time")))}
                </span>{" "}
                for the reasoning: "
                <span style={{ color: red }}>{searchParams.get("log")}</span>".
                <br></br>
                <br></br>
                You will automatically be unbanned after this date. If you
                believe this was a mistake, or would like to purchase an unban,
                please make a ticket via our Discord.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default BannedModal;
