import {
  Dialog,
  Divider,
  Grid,
  Typography,
  DialogActions,
  useMediaQuery,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { FiChevronRight } from "react-icons/fi";
import {
  card,
  cardLight,
  detailIcon,
  large,
  normal,
  secondaryText,
  text,
  doubtedBlue,
  cardVeryLight,
  cardDark,
  emptyIcon,
  linearGradientToTop,
  backgroundOpacity,
} from "../../utils/themeContstants";
import CoinBadge from "../custom/CoinBadge";
import CustomCountdown from "../custom/CustomCountdown";
import CustomIconButton from "../custom/CustomIconButton";
import {
  IoClose,
  IoGameControllerOutline,
  IoPodiumOutline,
  IoTimerOutline,
} from "react-icons/io5";
import {
  getChessTime,
  getGameHeader,
  getGameName,
  getPlatformString,
  getRegionString,
  getRegionStringFromArray,
  getTeamSizeString,
  getMatchTitle,
  hexToRgbA,
  numFormatter,
  shouldShowFirstTo,
  shouldShowBestOf,
  getFee,
  getLootType,
  getGameMapType,
} from "../../utils/helpers";
import DetailContainer from "../custom/DetailContainer";
import GameBadge from "../custom/GameBadge";
import { BiGlobe } from "react-icons/bi";
import { RiMap2Fill, RiSwordLine, RiTeamLine } from "react-icons/ri";
import { getMatch, getSensitiveMatch, joinMatch } from "../../api/matches";
import useMiddleware from "../../api/useMiddleware";
import { useContext, useEffect, useState } from "react";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { getUserTeamsDetailed } from "../../api/user";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getTeamOptions,
  multiGameModeOptions,
  multiRegionOptions,
} from "../../utils/options";
import { AiOutlineFlag } from "react-icons/ai";
import TeamProfileModal from "../teams/TeamProfileModal";
import SecondaryButton from "../custom/SecondaryButton";
import CustomDropdown from "../custom/CustomDropdown";
import CustomCheckbox from "../custom/CustomCheckbox";
import Empty from "../custom/Empty";
import DialogGlimmer from "../glimmers/DialogGlimmer";
import ScrollableContainer from "../custom/ScrollableContainer";
import ACCoin from "../custom/ACCoin";
import { HiDesktopComputer } from "react-icons/hi";

const JoinMatch = ({ open, onClose, match, matchId = null }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const isTablet = useMediaQuery("(max-width: 768px)");
  const isDesktop = useMediaQuery("(min-width:1025px)");
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [_, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [teams, setTeams] = useState(null);
  const [teamOptions, setTeamOptions] = useState(null);
  const [joinLoading, setJoinLoading] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [team, setTeam] = useState(null);
  const [puttingUp, setPuttingUp] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [canJoin, setCanJoin] = useState(false);
  const [region, setRegion] = useState(null);
  const [gameMode, setGameMode] = useState(null);

  const handleClose = () => {
    setCurrentMatch(null);
    setTeams(null);
    setTeamOptions(null);
    setTeamsLoading(false);
    setJoinLoading(false);
    setSelectedTeam(null);
    setPuttingUp(false);
    setTeam(null);
    setViewOpen(false);
    setCanJoin(false);
    setRegion(null);
    setGameMode(null);
    onClose();
  };

  const handleJoinMatch = () => {
    setJoinLoading(true);
    joinMatch(
      middleware,
      selectedTeam,
      currentMatch?._id,
      puttingUp ? true : null,
      region,
      gameMode
    ).then((res) => {
      setJoinLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        setSelectedTeam(null);
      } else {
        handleClose();
        navigate(`/token/${res?.match?._id}`);
      }
    });
  };

  const isJoinDisabled = () => {
    if (
      currentMatch?.platform === "console" ||
      currentMatch?.platform === "last_gen" ||
      currentMatch?.platform === "current_gen"
    ) {
      // if only region
      if (
        currentMatch?.region?.length > 1 &&
        currentMatch?.game_mode_options?.options?.length < 2
      ) {
        return !canJoin || !selectedTeam || !region;
      }

      // if only game mode
      if (
        currentMatch?.region?.length < 2 &&
        currentMatch?.game_mode_options?.options?.length > 1
      ) {
        return !canJoin || !selectedTeam || !gameMode;
      }

      // if both
      if (
        currentMatch?.region?.length > 1 &&
        currentMatch?.game_mode_options?.options?.length > 1
      ) {
        return !canJoin || !selectedTeam || !gameMode || !region;
      }

      // if neither
      return !canJoin || !selectedTeam;
    }

    // if only region
    if (
      currentMatch?.region?.length > 1 &&
      currentMatch?.game_mode_options?.options?.length < 2
    ) {
      return !selectedTeam || !region;
    }

    // if only game mode
    if (
      currentMatch?.region?.length < 2 &&
      currentMatch?.game_mode_options?.options?.length > 1
    ) {
      return !selectedTeam || !gameMode;
    }

    // if both
    if (
      currentMatch?.region?.length > 1 &&
      currentMatch?.game_mode_options?.options?.length > 1
    ) {
      return !selectedTeam || !gameMode || !region;
    }

    return !selectedTeam;
  };

  const handleGetTeams = () => {
    setTeamsLoading(true);
    getUserTeamsDetailed(middleware, currentMatch?.team_size).then((res) => {
      setTeamsLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        handleClose();
      } else {
        setTeams(res?.teams);
      }
    });
  };

  const handleFetchMatch = () => {
    getSensitiveMatch(middleware, matchId).then((res) => {
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
        handleClose();
      } else {
        setCurrentMatch(res?.match);
        console.log(res?.match)
      }
    });
  };

  const handleFetchData = () => {
    setLoading(true);
    if (match == null) {
      handleFetchMatch();
    }
  };

  useEffect(() => {
    if (open && currentMatch) {
      handleGetTeams();
    }
  }, [currentMatch, open]);

  useEffect(() => {
    if (!open) {
      setLoading(true);
      return;
    }

    window.open.history?.replaceState({}, document?.title);
    handleFetchData();
    setSearchParams({});
  }, [open]);

  useEffect(() => {
    setCurrentMatch(match);
  }, [match]);

  useEffect(() => {
    if (!teamsLoading && currentMatch) {
      setLoading(false);
    }
  }, [teamsLoading, currentMatch]);

  useEffect(() => {
    if (teams && teams?.length > 0) {
      setTeamOptions(getTeamOptions(teams));
    }
  }, [teams]);

  const styles = {
    width: {
      width: "100%",
    },
    top: {
      width: "100%",
      backgroundColor: card,
      position: "relative",
      // backgroundImage: `url(${getGameHeader(currentMatch?.game)})`,
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 2,
    },
    paper: {
      backgroundColor: card,
      minWidth: isDesktop ? 450 : isTablet ? "100%" : "97%",
      maxWidth: 800,
      borderRadius: 12,
      boxShadow: "0 1px 5px 1px rgb(0 0 0 / 40%)",
      height: isTablet ? "100%" : null,
    },
    closeButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      right: 10,
      top: 10,
      zIndex: 10,
    },
    title: {
      color: text,
      textAlign: "left",
      fontSize: 28,
      fontWeight: 800,
    },
    label: {
      fontSize: large,
      color: text,
      fontWeight: 700,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    divider: {
      width: "100%",
      backgroundColor: text,
      opacity: 0.1,
    },
  };

  return (
    <>
      <TeamProfileModal
        viewOnly={true}
        open={viewOpen}
        onClose={() => setViewOpen(false)}
        team={team}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: styles.paper }}
        BackdropProps={{ style: { background: "rgba(16, 18, 27, 0.8)" } }}
      >
        {loading && (
          <Grid item sx={{ padding: 2, width: "100%" }}>
            <DialogGlimmer />
          </Grid>
        )}

        {!loading && (
          <>
            <DialogTitle sx={styles.top}>
              {handleClose && (
                <Grid item sx={styles.closeButton}>
                  <CustomIconButton
                    onClick={handleClose}
                    icon={<IoClose style={{ color: text, fontSize: 20 }} />}
                    opacity
                  />
                </Grid>
              )}

              <div
                style={{
                  backgroundColor: hexToRgbA(card, 0.6),
                  position: "absolute",
                  width: "100%",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  zIndex: 0,
                }}
              />

              <div
                style={{
                  background: linearGradientToTop(
                    hexToRgbA(card, 1),
                    hexToRgbA(card, 0)
                  ),
                  position: "absolute",
                  width: "100%",
                  top: 0,
                  bottom: -1,
                  right: 0,
                  left: 0,
                  zIndex: 0,
                }}
              />

              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ zIndex: 2 }}
              >
                <Grid item>
                  <CustomCountdown
                    remaining={currentMatch?.expire_time}
                    duration={1800}
                  />
                </Grid>

                <Grid item sx={{ marginBottom: 1 }}>
                  <Typography sx={styles.title}>{`${getMatchTitle(
                    currentMatch?.game_match_type,
                    currentMatch?.team_size,
                    currentMatch?.game_map_type,
                    currentMatch?.extra_options,
                    currentMatch?.game_mode_options
                  )}`}</Typography>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                  >
                    <CoinBadge
                      amount={numFormatter().format(currentMatch?.entry_fee)}
                      label="Fee to compete in this match"
                      placement="bottom"
                      insideLabel="Entry"
                    />

                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FiChevronRight
                        style={{ fontSize: 25, color: secondaryText }}
                      />
                    </Grid>

                    <CoinBadge
                      amount={numFormatter().format(
                        parseFloat(
                          currentMatch?.entry_fee * getFee(currentMatch?.game) +
                            currentMatch?.entry_fee
                        )
                      )}
                      label="The total amount you will receive if you win this match"
                      type="prize"
                      placement="bottom"
                      insideLabel="Prize"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent sx={{ padding: 0, width: "100%" }}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 2 }}
              >
                <Grid
                  item
                  sx={{ width: "100%", paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.label}>Details</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      {!isTablet && (
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="stretch"
                          gap={{ xs: 1 }}
                        >
                          <DetailContainer
                            label="Game"
                            value={getGameName(currentMatch?.game)}
                            // icon={<GameBadge game={currentMatch?.game} />}
                            bg={cardLight}
                          />

                          {currentMatch?.game !== "clashroyale" && (
                            <DetailContainer
                              label="Platform"
                              value={getPlatformString(currentMatch?.platform)}
                              icon={
                                <IoGameControllerOutline style={detailIcon} />
                              }
                              bg={cardLight}
                            />
                          )}

                          {currentMatch?.region && (
                            <>
                              {currentMatch?.region?.length > 0 ? (
                                <DetailContainer
                                  label="Region"
                                  value={getRegionStringFromArray(
                                    currentMatch?.region,
                                    currentMatch?.selected_region
                                  )}
                                  icon={<BiGlobe style={detailIcon} />}
                                  bg={cardLight}
                                />
                              ) : (
                                <DetailContainer
                                  label="Region"
                                  value={getRegionString(currentMatch?.region)}
                                  icon={<BiGlobe style={detailIcon} />}
                                  bg={cardLight}
                                />
                              )}
                            </>
                          )}

                          <DetailContainer
                            label="Team Size"
                            value={getTeamSizeString(currentMatch?.team_size)}
                            icon={<RiTeamLine style={detailIcon} />}
                            bg={cardLight}
                          />

                          {currentMatch?.game === "chess" && (
                            <DetailContainer
                              label="Time limit"
                              value={getChessTime(
                                currentMatch?.game_match_type
                              )}
                              icon={<IoTimerOutline style={detailIcon} />}
                              bg={cardLight}
                            />
                          )}

                          {shouldShowFirstTo(
                            currentMatch?.game_mode_options?.selected_option,
                            currentMatch?.game_mode_options?.options,
                            currentMatch?.game
                          ) && (
                            <DetailContainer
                              label="First To"
                              value={`${currentMatch?.first_to}`}
                              firstTo={true}
                              killLeadLabel={`${currentMatch?.extra_options?.kill_lead} round lead`}
                              icon={<AiOutlineFlag style={detailIcon} />}
                              bg={cardLight}
                              killLead={
                                !!currentMatch?.extra_options?.kill_lead
                              }
                            />
                          )}

                        {currentMatch?.extra_options?.loot_type && (
                                  <DetailContainer
                                    label="Loot Type"
                                    value={getLootType(currentMatch?.extra_options?.loot_type)}
                                    icon={
                                      <RiSwordLine style={detailIcon} />
                                    }
                                    bg={cardLight}
                                  />
                                )}
                           {currentMatch?.game_map_type && (
                                  <DetailContainer
                                    label="Map"
                                    value={getGameMapType(currentMatch?.game_map_type)}
                                    icon={
                                      <RiMap2Fill style={detailIcon} />
                                    }
                                    bg={cardLight}
                                  />
                                )}


                          {shouldShowBestOf(
                            currentMatch?.game,
                            currentMatch?.game_mode_options?.selected_option,
                            currentMatch?.game_mode_options?.options
                          ) && (
                            <DetailContainer
                              label="Best Of"
                              value={currentMatch?.best_of}
                              icon={<IoPodiumOutline style={detailIcon} />}
                              bg={cardLight}
                            />
                          )}

                          {currentMatch?.extra_options?.must_stream &&
                            currentMatch?.platform == null && (
                              <DetailContainer
                                label="PC Settings"
                                value={"Must Stream"}
                                icon={<HiDesktopComputer style={detailIcon} />}
                                bg={cardLight}
                              />
                            )}

                          {currentMatch?.ac_verified && (
                            <DetailContainer
                              label="Anti-Cheat"
                              value="Verified"
                              icon={<ACCoin size={32} />}
                              bg={cardLight}
                            />
                          )}
                        </Grid>
                      )}

                      {isTablet && (
                        <ScrollableContainer gap={1} stretch>
                          <DetailContainer
                            label="Game"
                            value={getGameName(currentMatch?.game)}
                            // icon={<GameBadge game={currentMatch?.game} />}
                            bg={cardLight}
                          />

                          {currentMatch?.game === "fortnite" && (
                            <DetailContainer
                              label="Platform"
                              value={getPlatformString(currentMatch?.platform)}
                              icon={
                                <IoGameControllerOutline style={detailIcon} />
                              }
                              bg={cardLight}
                            />
                          )}

                          {currentMatch?.region && (
                            <>
                              {currentMatch?.region?.length > 0 ? (
                                <DetailContainer
                                  label="Region"
                                  value={getRegionStringFromArray(
                                    currentMatch?.region,
                                    currentMatch?.selected_region
                                  )}
                                  icon={<BiGlobe style={detailIcon} />}
                                  bg={cardLight}
                                />
                              ) : (
                                <DetailContainer
                                  label="Region"
                                  value={getRegionString(currentMatch?.region)}
                                  icon={<BiGlobe style={detailIcon} />}
                                  bg={cardLight}
                                />
                              )}
                            </>
                          )}

                          <DetailContainer
                            label="Team Size"
                            value={getTeamSizeString(currentMatch?.team_size)}
                            icon={<RiTeamLine style={detailIcon} />}
                            bg={cardLight}
                          />

                          {currentMatch?.game === "chess" && (
                            <DetailContainer
                              label="Time limit"
                              value={getChessTime(
                                currentMatch?.game_match_type
                              )}
                              icon={<IoTimerOutline style={detailIcon} />}
                              bg={cardLight}
                            />
                          )}

                          {!currentMatch?.best_of && (
                            <DetailContainer
                              label="First To"
                              value={currentMatch?.first_to}
                              firstTo={true}
                              icon={<AiOutlineFlag style={detailIcon} />}
                              bg={cardLight}
                            />
                          )}

                          {currentMatch?.best_of && (
                            <DetailContainer
                              label="Best Of"
                              value={currentMatch?.best_of}
                              icon={<IoPodiumOutline style={detailIcon} />}
                              bg={cardLight}
                            />
                          )}

                          {currentMatch?.extra_options?.must_stream &&
                            currentMatch?.platform == null && (
                              <DetailContainer
                                label="PC Settings"
                                value={"Must Stream"}
                                icon={<HiDesktopComputer style={detailIcon} />}
                                bg={cardLight}
                              />
                            )}

                          {currentMatch?.ac_verified && (
                            <DetailContainer
                              label="Anti-Cheat"
                              value="Verified"
                              icon={<ACCoin size={32} />}
                              bg={cardLight}
                            />
                          )}
                        </ScrollableContainer>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{ width: "100%", paddingLeft: 2, paddingRight: 2 }}
                >
                  <Divider sx={styles.divider} />
                </Grid>

                <Grid
                  item
                  sx={{ width: "100%", paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.label}>Team Select</Typography>
                    </Grid>

                    {teams?.length < 1 && (
                      <>
                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Empty
                              title={`You have no ${currentMatch?.team_size}v${currentMatch?.team_size} teams`}
                              label="Try creating a team."
                              icon={<RiTeamLine style={emptyIcon} />}
                            />

                            <Grid item alignSelf="center">
                              <SecondaryButton
                                label="Create Team"
                                onClick={() =>
                                  navigate("/profile/teams", {
                                    state: {
                                      create: true,
                                    },
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {teams?.length > 0 && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          gap={{ xs: 1 }}
                        >
                          {teamOptions && teamOptions?.length > 0 && (
                            <Grid item sx={{ flexGrow: 1 }}>
                              <CustomDropdown
                                options={teamOptions}
                                placeholder="Teams"
                                onChange={(value) => {
                                  setSelectedTeam(value?._id);
                                  setTeam(value);
                                }}
                                backgroundColor={cardDark}
                                backgroundHover={cardDark}
                              />
                            </Grid>
                          )}

                          {team && (
                            <Grid item>
                              <SecondaryButton
                                label="View Team"
                                onClick={() => setViewOpen(true)}
                                fullWidth
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {currentMatch?.region?.length > 1 && (
                  <Grid
                    item
                    sx={{ width: "100%", paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.label}>Region Select</Typography>
                      </Grid>

                      <Grid item sx={styles.width}>
                        <CustomDropdown
                          options={multiRegionOptions(currentMatch?.region)}
                          placeholder="Region"
                          onChange={(value) => {
                            setRegion(value);
                          }}
                          backgroundColor={cardDark}
                          backgroundHover={cardDark}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {currentMatch?.game_mode_options?.options?.length > 1 && (
                  <Grid
                    item
                    sx={{ width: "100%", paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid item>
                        <Typography sx={styles.label}>
                          Game Mode Select
                        </Typography>
                      </Grid>

                      <Grid item sx={styles.width}>
                        <CustomDropdown
                          options={multiGameModeOptions(
                            currentMatch?.game_mode_options?.options
                          )}
                          placeholder="Game mode"
                          onChange={(value) => {
                            setGameMode(value);
                          }}
                          backgroundColor={cardDark}
                          backgroundHover={cardDark}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {teams?.length > 0 && (
                  <>
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                    >
                      <Divider sx={styles.divider} />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: "100%",
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 1 }}
                      >
                        {currentMatch?.team_size > 1 && (
                          <Grid item sx={styles.width}>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              wrap="nowrap"
                              gap={{ xs: 1 }}
                            >
                              <Grid item>
                                <CustomCheckbox
                                  checked={puttingUp}
                                  onChange={(value) => setPuttingUp(value)}
                                  color={doubtedBlue}
                                  checkColor={"#fff"}
                                />
                              </Grid>

                              <Grid item>
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    color: text,
                                    fontWeight: 400,
                                    maxWidth: 400,
                                  }}
                                >
                                  Cover Entry Fee for Teammates
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        {currentMatch?.platform === "console" && (
                          <Grid item sx={styles.width}>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              wrap="nowrap"
                              gap={{ xs: 1 }}
                            >
                              <Grid>
                                <CustomCheckbox
                                  checked={canJoin}
                                  onChange={(value) => setCanJoin(value)}
                                  color={doubtedBlue}
                                  disabled={joinLoading}
                                  checkColor={"#fff"}
                                />
                              </Grid>

                              <Grid>
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    color: text,
                                    fontWeight: 400,
                                    maxWidth: 400,
                                  }}
                                >
                                  I understand that by joining this match and I
                                  am NOT on a console the match will be
                                  cancelled and I will be punished.
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        {(currentMatch?.platform === "last_gen" ||
                          currentMatch?.platform === "current_gen") && (
                          <Grid item sx={styles.width}>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              wrap="nowrap"
                              gap={{ xs: 1 }}
                            >
                              <Grid>
                                <CustomCheckbox
                                  checked={canJoin}
                                  onChange={(value) => setCanJoin(value)}
                                  color={doubtedBlue}
                                  disabled={joinLoading}
                                  checkColor={"#fff"}
                                />
                              </Grid>

                              <Grid>
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    color: text,
                                    fontWeight: 400,
                                    maxWidth: 400,
                                  }}
                                >
                                  I understand that by joining this match and I
                                  am NOT on a{" "}
                                  {currentMatch?.platform === "last_gen" &&
                                    "PS4 or an Xbox One"}{" "}
                                  {currentMatch?.platform === "current_gen" &&
                                    "PS5, Xbox Series S/X, or a PC"}{" "}
                                  the match will be cancelled and I will be
                                  punished.
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>

            <DialogActions sx={{ padding: 2, marginTop: "auto" }}>
              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent="end"
                  alignItems="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item>
                    <SecondaryButton label="Close" onClick={handleClose} />
                  </Grid>

                  <Grid item>
                    <SecondaryButton
                      label="Accept"
                      bg={doubtedBlue}
                      loading={joinLoading}
                      onClick={handleJoinMatch}
                      disabled={isJoinDisabled()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default JoinMatch;
