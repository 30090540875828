import { Grid, Typography, useMediaQuery } from "@mui/material";
import {
  doubtedBlue,
  doubtedOrange,
  normal,
  purple,
  secondaryText,
  text,
  yellow,
} from "../../utils/themeContstants";

const ZoneWarsRules = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const styles = {
    label: {
      fontSize: 11,
      fontWeight: 600,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    nonBoldValue: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="start"
      justifyContent="center"
      gap={{ xs: 2 }}
      sx={{ maxWidth: isDesktop ? 275 : "100%" }}
    >
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>MAP</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <span style={{ color: doubtedBlue }}>3825-0766-9324</span>{" "}
              <span style={{ color: text, fontWeight: 500 }}>
                (BFC Zone Wars)
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>SHOTGUN</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>Gold Pumps</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>TEAMS</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                Host is{" "}
                <span style={{ color: doubtedBlue, fontWeight: 700 }}>
                  Team 1
                </span>
              </li>
              <li>
                Non-Host is{" "}
                <span style={{ color: doubtedOrange, fontWeight: 700 }}>
                  Team 2
                </span>
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>ROUND ELIGIBILITY</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                If damage is dealt the round counts{" "}
                <span style={{ fontWeight: 800 }}>no matter what</span>.
              </li>
              <li>
                If no players leave the portal, the round{" "}
                <span style={{ fontWeight: 800 }}>does not count</span>.
              </li>
              <li>
                If one or more players come out of the portals,{" "}
                <span style={{ fontWeight: 800 }}>the round counts</span>.
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ZoneWarsRules;
