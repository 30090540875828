import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { FaCoins } from "react-icons/fa";
import { getPendingChargebacks } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { PENDING_WITHDRAWAL_LIMIT } from "../../utils/limits";
import {
  card,
  green,
  modalShadow,
  normal,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";
import NotSelected from "../custom/NotSelected";
import DisputeGlimmer from "../glimmers/DisputeGlimmer";
import { currencyFormatter } from "../../utils/helpers";
import PendingChargebackItem from "./PendingChargebackItem";
import { AiTwotoneBank } from "react-icons/ai";

const PendingChargebacks = () => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const bottom = useRef(null);

  const [loading, setLoading] = useState(true);
  const [chargebacks, setChargebacks] = useState(null);

  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetPendingChargebacks = () => {
    getPendingChargebacks(middleware, skip).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.chargebacks?.length >= PENDING_WITHDRAWAL_LIMIT) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setChargebacks(
          chargebacks ? [...chargebacks, ...res?.chargebacks] : res?.chargebacks
        );
      }
    });
  };

  usePagination(bottom, () => setSkip(chargebacks?.length));

  useEffect(() => {
    if (chargebacks == null) {
      handleGetPendingChargebacks();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetPendingChargebacks();
    }
  }, [skip]);

  const styles = {
    width: {
      width: "100%",
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 800,
    },
    title: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    totalLabel: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 600,
    },
    totalValue: {
      fontSize: 22,
      color: text,
      fontWeight: 800,
    },
    container: {
      borderRadius: 2,
      padding: 2,
      backgroundColor: card,
      boxShadow: modalShadow,
      flexGrow: 1,
    },
  };

  return (
    <>
      <Grid item>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1 }}
        >
          <Grid item sx={styles.icon}>
            <AiTwotoneBank style={{ fontSize: 22, color: text }} />
          </Grid>

          <Grid item>
            <Typography sx={styles.title}>PENDING CHARGEBACKS</Typography>
          </Grid>
        </Grid>
      </Grid>

      {loading && <DisputeGlimmer numItems={3} />}

      {!loading && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            {(chargebacks?.length < 1 || !chargebacks) && (
              <Grid item alignSelf="center">
                <NotSelected label="NO CHARGEBACKS" />
              </Grid>
            )}

            {chargebacks && chargebacks?.length > 0 && (
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="row"
                  alignItems="start"
                  justifyContent="left"
                  gap={{ xs: 1 }}
                >
                  {chargebacks?.map((chargeback, i) => (
                    <PendingChargebackItem
                      chargeback={chargeback}
                      key={i}
                      setChargebacks={setChargebacks}
                      chargebacks={chargebacks}
                    />
                  ))}
                  {hasMore && (
                    <div style={{ width: "100%" }} ref={bottom}>
                      <DisputeGlimmer numItems={1} />
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PendingChargebacks;
