const FightingStance = ({ skinColor }) => {
  const styles = {
    one: {
      fill: "#191919",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    two: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#ede6e2",
    },
    three: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "none",
    },
    five: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#424242",
    },
    six: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#cec6c2",
    },
    seven: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: skinColor,
    },
    eight: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
      fill: "#303030",
    },
    nine: {
      fill: skinColor,
    },
  };

  return (
    <g>
      <path
        style={styles.nine}
        d="m332.01,787.03c-6.46-4.81-13.28-10.34-20.26-16.66-8.62-7.81-16.04-15.53-22.37-22.77l23.59-26.01,40.93,21.79.41,12.57-22.31,31.09Z"
      />
      <path
        style={styles.nine}
        d="m670.79,776.87c-5.81,5.32-12.75,11.04-20.9,16.67-9.75,6.73-19.04,11.82-27.21,15.69-5.83-7.84-11.66-15.68-17.49-23.52l40.79-30.33,2.65-1.3,22.16,22.78Z"
      />
      <path
        style={styles.four}
        d="m311.84,722.38c1.14-.14-22.45,25.21-22.45,25.21"
      />
      <line
        style={styles.four}
        x1="355.89"
        y1="753.76"
        x2="332.01"
        y2="787.03"
      />
      <g>
        <path
          style={styles.eight}
          d="m347.88,654.88c3.83-7.21,9.61-16.36,18.21-25.6,10.72-11.53,21.76-18.86,29.84-23.35,3.14.37,7.39,1.32,11.74,3.83,1.2.69,2.27,1.42,3.22,2.15,2.66,16.48,5.22,33.2,7.66,50.15,1.41,9.77,2.76,19.48,4.05,29.12-5.33,3.24-12.78,7.05-22.17,9.84-8.99,2.67-16.97,3.58-23.04,3.82-4.71-2.72-12.31-7.94-18.71-17-9.52-13.47-10.66-27.25-10.8-32.95Z"
        />
        <path
          style={styles.two}
          d="m377.39,704.83c.39.35,6.18,5.81,4.71,12.89-.64,3.08-2.39,5.17-3.41,6.21-2.91-.33-7.15-1.12-11.82-3.22-1.58-.71-8.44-3.92-16.24-13.31-8.83-10.62-13.01-22.11-15.08-30.23,3.57-6.41,6.63-11,8.09-12.57.41-.44,1.18-1.25,2.45-1.72,1-.37,1.88-.38,2.4-.35.79,5.22,3,15.16,10.19,25.29,6.54,9.22,14.23,14.42,18.71,17Z"
        />
        <path
          style={styles.eight}
          d="m309.79,717.33c1.36-6.68,4.72-18.2,13.85-29.12,4.05-4.85,8.27-8.43,11.92-11.04,1.2,5.6,3.99,15.14,11.08,24.93,7.85,10.84,17.14,16.7,22.32,19.47.55.49,3.02,2.78,3.07,6.28.02,1.26-.29,2.31-.59,3.04-.87,2.64-1.99,5.53-3.47,8.55-3.27,6.68-7.19,11.82-10.58,15.56-4.95-.22-15.83-1.39-26.74-8.76-15.04-10.17-19.77-25.13-20.96-29.43"
        />
        <path
          style={styles.eight}
          d="m422.61,691.17c.43,1.94,1.55,5.67,4.69,9.1,1.73,1.89,3.54,3.11,4.92,3.88-7.34,2.71-21.02,7.22-34.85,8.11-6.9.45-12.68-.05-16.27-3.95-.77-.83-1.59-1.97-2.25-3.51,6.9-.41,16.09-1.7,26.32-5.34,7-2.49,12.83-5.49,17.44-8.29Z"
        />
        <path
          style={styles.six}
          d="m378.69,723.93c1.61.15,9.96.76,16-5.14,2-1.95,3.19-4.07,3.9-5.75-2.18.23-5.52.32-9.41-.64-3.45-.85-6.11-2.21-7.87-3.3.49,1.15,1.5,3.93.97,7.48-.6,3.94-2.75,6.46-3.59,7.35Z"
        />
        <path
          style={styles.eight}
          d="m357.39,755.01c3.17.19,5.83-.21,7.96-.8,15.67-4.31,21.26-23.53,21.93-25.95.02-.45,0-1.44-.62-2.4-1.27-1.97-3.83-1.9-3.97-1.9-1.22.07-2.56.08-4-.03-3.98-.29-7.29-1.32-9.73-2.35.69.6,1.62,1.56,2.26,2.95,1.61,3.55.22,7.49-1.79,11.73-2.07,4.37-5.72,11-12.04,18.75Z"
        />
        <polygon
          style={styles.five}
          points="404.93 608.98 414.16 686.55 422.61 691.17 410.9 611.91 404.93 608.98"
        />
        <path
          style={styles.one}
          d="m373.29,702.24c8.1-1.63,17.71-4.34,27.99-8.98,4.75-2.14,9.05-4.43,12.89-6.71,2.81,1.54,4.74,2.6,7.56,4.14-5.07,3.08-11,7.06-19.46,9.76-9.75,3.11-18.4,4.12-24.87,4.39-1.37-.87-2.74-1.73-4.11-2.6Z"
        />
        <path
          style={styles.one}
          d="m336.19,682.5c.02,4.71.7,16.3,8.41,27.98,9.27,14.05,22.65,19.04,26.85,20.42.18-.97.39-2.82-.21-5.01-.58-2.11-1.64-3.56-2.27-4.31-4.98-2.74-12.24-7.53-19.12-15.4-7.83-8.97-11.72-18.01-13.65-23.68Z"
        />
        <path
          style={styles.one}
          d="m364.14,727.84c-.6,3.22-2.2,9.39-6.96,15.5-3.96,5.08-8.42,7.96-11.17,9.45,3.79.74,7.59,1.47,11.38,2.21,3.09-3.58,6.57-8.28,9.68-14.17,1.87-3.54,3.29-6.9,4.37-9.94-1.42-.47-2.94-1.04-4.52-1.74-.98-.43-1.91-.88-2.78-1.32Z"
        />
        <path
          style={styles.eight}
          d="m410.9,611.91l11.71,79.27c13.84,1.94,24.72-3.95,26.62-11.53.31-1.21,1.06-5.17-2.28-10.72.66-2.02,1.38-5,1.33-8.65-.07-5.07-1.59-8.99-2.64-11.18.29-1.75.5-4.31,0-7.32-.71-4.23-2.5-7.3-3.66-8.98-.08-2.45-.44-4.51-.82-6.09-.39-1.6-.79-3.27-1.8-5.27-1.01-1.99-1.67-2.42-2.4-4.27-.65-1.62-.85-3.08-.92-4.07-1.85-1.08-4.27-2.22-7.24-2.94-8.13-1.97-15,.49-17.88,1.72Z"
        />
        <path
          style={styles.seven}
          d="m438.23,618.11c.55.06,8.58.99,12.12,8.09,2.47,4.96,1.16,9.73.81,10.88-1.14-.41-2.33-.87-3.57-1.41-2.09-.92-3.97-1.89-5.63-2.86-.15-2.24-.51-4.97-1.31-7.99-.7-2.62-1.57-4.87-2.42-6.71Z"
        />
        <path
          style={styles.seven}
          d="m443.35,633.73c1.33.65,2.75,1.31,4.25,1.94,1.22.52,2.41.99,3.57,1.41,1.64,2.06,4.76,6.56,5.73,13.09.68,4.55.05,8.33-.53,10.66-.78-1.63-1.96-3.68-3.73-5.78-2.16-2.58-4.43-4.31-6.13-5.41-.1-2.91-.46-6.37-1.39-10.18-.51-2.11-1.12-4.02-1.76-5.73Z"
        />
        <path
          style={styles.seven}
          d="m446.95,668.93l8.83,9.27c.89-1.39,2.23-3.91,2.61-7.31.64-5.8-1.99-10.15-2.88-11.48-.54-1.32-1.67-3.6-3.98-5.63-1.46-1.29-2.92-2.06-4-2.52.5,1.68.95,3.76,1.1,6.16.32,4.95-.76,8.98-1.68,11.51Z"
        />
        <path
          style={styles.seven}
          d="m432.22,704.15c2.11-.79,23.75-9.17,24.02-22.36.02-1.12-.11-2.32-.46-3.59-2.45-2.11-4.9-4.22-7.35-6.33,1.52,3.69,1.2,6.3.81,7.77-1.95,7.27-12.67,12.52-26.62,11.53.37,1.73,1.3,4.89,3.78,8.04,2.07,2.61,4.36,4.13,5.83,4.94Z"
        />
        <polygon
          style={styles.three}
          points="413.8 630.28 418.16 631.7 414.44 634.54 413.8 630.28"
        />
        <polygon
          style={styles.three}
          points="416.19 641.61 420.44 643.05 416.84 645.87 416.19 641.61"
        />
        <polygon
          style={styles.three}
          points="418.54 656.57 422.96 657.98 419.18 660.83 418.54 656.57"
        />
        <path
          style={styles.three}
          d="m385.53,704.15c1.76.49,3.61.92,5.54,1.26,14.75,2.62,27.8-.86,36.79-4.54,1.45,1.09,2.9,2.18,4.35,3.28-4.9,2.33-35.99,16.74-50.63,3.7-.98-.87-1.9-1.88-2.74-3.04,1.29-.1,2.61-.21,3.95-.35.92-.1,1.83-.2,2.73-.31Z"
        />
        <path d="m427.52,680.94c.48,2.76.65,5.54.53,8.33,0,0-1.7-.77-1.7-.77,2.09-2.5,4.53-4.69,7.23-6.52.95-.57,1.92-1.23,3.05-1.4-2.68,3.38-5.5,6.64-8.87,9.34,0,0-1.82,1.83-1.82,1.83.08-3.63.53-7.33,1.58-10.81h0Z" />
        <path d="m446.28,669.68c-5.23-4.74-11.8-7.85-18.71-9.22,0,0-4.54-1.29-4.55-1.29,2.97-.44,13.59-2,16.74-2.47,0,0-11.79,3.75-11.79,3.75,0,0-.04-1.96-.04-1.96,7.26,1.44,14.17,4.71,19.67,9.69,0,0-1.33,1.49-1.33,1.49h0Z" />
        <path
          style={styles.three}
          d="m445.76,649.73c-2.4-1.17-14.98-4.83-18.12-5.3-.09-.01-.17-.02-.24-.03l7.25-3.65-.86,2.37,4.36,1.3-.87,1.41-8.8-1.64"
        />
        <path d="m441.41,633.64c-4.44-3-9.73-4.71-15.08-4.88,0,0-3.11-.28-3.11-.28,1.67-1,7.68-4.59,9.46-5.65,0,0-5.69,5.71-5.69,5.71,0,0-.59-1.79-.58-1.79,5.72.18,11.39,2.01,16.13,5.22l-1.12,1.66h0Z" />
        <ellipse
          style={styles.three}
          cx="437.12"
          cy="628.71"
          rx="1.24"
          ry="4.46"
          transform="translate(-272.79 915.73) rotate(-77.2)"
        />
        <ellipse
          style={styles.three}
          cx="441.48"
          cy="645.46"
          rx="1.92"
          ry="4.11"
          transform="translate(-285.73 933.02) rotate(-77.2)"
        />
        <ellipse
          style={styles.three}
          cx="443.6"
          cy="664.44"
          rx=".96"
          ry="3.94"
          transform="translate(-351.64 588) rotate(-50.78)"
        />
      </g>
      <path
        style={styles.four}
        d="m648.63,754.09c-1.15-.07,22.16,22.78,22.16,22.78"
      />
      <line
        style={styles.four}
        x1="606.61"
        y1="788.14"
        x2="622.68"
        y2="809.23"
      />
      <g>
        <path
          style={styles.eight}
          d="m608.47,688.96c-4.27-6.96-10.61-15.73-19.77-24.42-11.41-10.84-22.88-17.47-31.23-21.45-3.11.56-7.29,1.78-11.48,4.55-1.15.76-2.18,1.56-3.08,2.34-1.64,16.61-3.15,33.46-4.54,50.53-.8,9.84-1.54,19.61-2.23,29.32,5.52,2.9,13.19,6.24,22.74,8.44,9.14,2.11,17.16,2.52,23.23,2.39,4.54-3,11.79-8.69,17.62-18.13,8.67-14.03,8.95-27.86,8.73-33.56Z"
        />
        <path
          style={styles.two}
          d="m582.11,740.65c-.36.37-5.81,6.18-3.9,13.16.83,3.03,2.7,5.01,3.79,5.98,2.88-.51,7.07-1.56,11.6-3.94,1.54-.81,8.18-4.44,15.38-14.29,8.15-11.15,11.61-22.88,13.17-31.11-3.96-6.17-7.3-10.57-8.85-12.04-.43-.41-1.26-1.18-2.55-1.56-1.02-.31-1.9-.26-2.42-.2-.46,5.26-2.06,15.32-8.6,25.88-5.95,9.61-13.31,15.28-17.62,18.13Z"
        />
        <path
          style={styles.eight}
          d="m650.36,748.92c-1.78-6.59-5.84-17.87-15.63-28.2-4.34-4.58-8.78-7.9-12.58-10.28-.85,5.66-3.04,15.36-9.52,25.57-7.16,11.31-16.07,17.73-21.07,20.82-.52.52-2.85,2.96-2.67,6.46.06,1.26.43,2.29.77,3,1.03,2.59,2.33,5.39,4,8.31,3.68,6.46,7.91,11.35,11.53,14.87,4.93-.53,15.72-2.37,26.15-10.41,14.38-11.08,18.17-26.31,19.1-30.67"
        />
        <path
          style={styles.eight}
          d="m536.13,729.82c-.31,1.96-1.19,5.75-4.11,9.38-1.61,1.99-3.34,3.32-4.67,4.17,7.49,2.25,21.43,5.9,35.28,5.93,6.92.02,12.66-.83,15.99-4.95.71-.88,1.47-2.07,2.03-3.64-6.91.02-16.17-.7-26.61-3.7-7.14-2.05-13.14-4.69-17.92-7.19Z"
        />
        <path
          style={styles.six}
          d="m582,759.79c-1.6.25-9.89,1.38-16.29-4.13-2.11-1.82-3.43-3.86-4.25-5.49,2.19.09,5.52-.03,9.35-1.22,3.4-1.06,5.96-2.59,7.65-3.78-.42,1.17-1.26,4.01-.5,7.52.84,3.89,3.14,6.28,4.04,7.11Z"
        />
        <path
          style={styles.eight}
          d="m605.19,789.48c-3.16.39-5.83.15-7.99-.3-15.91-3.33-22.68-22.16-23.5-24.54-.05-.45-.09-1.44.46-2.44,1.14-2.05,3.7-2.14,3.85-2.14,1.22,0,2.56-.08,3.99-.28,3.96-.54,7.19-1.77,9.57-2.95-.66.64-1.52,1.66-2.07,3.09-1.39,3.65.25,7.49,2.51,11.59,2.34,4.23,6.39,10.63,13.18,17.96Z"
        />
        <polygon
          style={styles.five}
          points="548.67 646.69 544.28 724.68 536.13 729.82 542.9 649.98 548.67 646.69"
        />
        <path
          style={styles.one}
          d="m586.05,737.8c-8.19-1.12-17.94-3.23-28.49-7.22-4.88-1.84-9.3-3.86-13.28-5.9-2.71,1.71-4.57,2.89-7.29,4.6,5.25,2.76,11.42,6.36,20.03,8.53,9.92,2.5,18.62,2.97,25.09,2.83,1.31-.95,2.63-1.9,3.94-2.85Z"
        />
        <path
          style={styles.one}
          d="m621.84,715.8c.27,4.7.31,16.31-6.65,28.45-8.38,14.6-21.42,20.41-25.53,22.05-.24-.96-.57-2.79-.1-5.02.45-2.15,1.42-3.66,2-4.44,4.8-3.05,11.75-8.27,18.12-16.55,7.26-9.44,10.58-18.71,12.16-24.48Z"
        />
        <path
          style={styles.one}
          d="m596.77,762.79c.8,3.18,2.78,9.24,7.91,15.04,4.27,4.82,8.9,7.42,11.74,8.74-3.74.97-7.48,1.94-11.23,2.91-3.31-3.38-7.07-7.85-10.54-13.54-2.09-3.42-3.71-6.68-4.98-9.65,1.39-.56,2.87-1.22,4.41-2.02.95-.49,1.85-.99,2.7-1.49Z"
        />
        <path
          style={styles.eight}
          d="m542.9,649.98l-6.77,79.84c-13.69,2.8-24.92-2.41-27.29-9.85-.38-1.19-1.38-5.1,1.61-10.84-.78-1.97-1.68-4.91-1.86-8.55-.24-5.07,1.03-9.07,1.94-11.32-.4-1.73-.77-4.27-.45-7.3.45-4.27,2.04-7.44,3.1-9.19-.07-2.45.16-4.53.44-6.13.29-1.62.58-3.31,1.47-5.37.88-2.05,1.51-2.52,2.13-4.41.54-1.66.66-3.12.67-4.12,1.78-1.2,4.13-2.48,7.04-3.38,7.99-2.47,15-.44,17.95.61Z"
        />
        <path
          style={styles.seven}
          d="m516.01,657.87c-.55.09-8.5,1.52-11.59,8.83-2.16,5.1-.55,9.78-.13,10.91,1.11-.48,2.27-1.02,3.47-1.63,2.03-1.04,3.84-2.14,5.44-3.21,0-2.25.2-4.99.81-8.05.53-2.66,1.27-4.96,2-6.85Z"
        />
        <path
          style={styles.seven}
          d="m511.87,673.78c-1.29.74-2.66,1.47-4.12,2.2-1.19.59-2.35,1.13-3.47,1.63-1.51,2.16-4.34,6.84-4.91,13.42-.39,4.58.46,8.31,1.19,10.61.68-1.67,1.73-3.79,3.37-6,2-2.71,4.15-4.57,5.79-5.78-.08-2.91.07-6.39.76-10.25.38-2.13.87-4.08,1.4-5.82Z"
        />
        <path
          style={styles.seven}
          d="m510.46,709.13l-8.24,9.8c-.98-1.33-2.47-3.76-3.05-7.13-1-5.75,1.36-10.25,2.16-11.64.45-1.35,1.45-3.7,3.62-5.87,1.38-1.37,2.78-2.23,3.84-2.76-.4,1.71-.71,3.81-.72,6.22,0,4.96,1.32,8.92,2.39,11.39Z"
        />
        <path
          style={styles.seven}
          d="m527.35,743.37c-2.15-.66-24.28-7.68-25.36-20.82-.09-1.11-.04-2.32.23-3.62,2.32-2.26,4.63-4.51,6.95-6.77-1.29,3.78-.81,6.36-.32,7.81,2.4,7.13,13.43,11.71,27.29,9.85-.26,1.75-.99,4.97-3.28,8.25-1.9,2.73-4.1,4.39-5.51,5.3Z"
        />
        <polygon
          style={styles.three}
          points="541.15 668.5 536.88 670.19 540.77 672.79 541.15 668.5"
        />
        <polygon
          style={styles.three}
          points="539.47 679.95 535.31 681.66 539.08 684.24 539.47 679.95"
        />
        <polygon
          style={styles.three}
          points="538.04 695.03 533.73 696.71 537.67 699.32 538.04 695.03"
        />
        <path
          style={styles.three}
          d="m573.95,740.47c-1.73.6-3.55,1.14-5.46,1.61-14.56,3.53-27.8.86-37-2.24-1.38,1.18-2.76,2.36-4.14,3.54,5.03,2.02,36.96,14.47,50.76.55.92-.93,1.78-1.99,2.54-3.21-1.29-.02-2.62-.05-3.97-.1-.93-.04-1.84-.08-2.74-.14Z"
        />
        <path d="m530.6,719.91c1.27,3.41,1.95,7.08,2.25,10.69,0,0-1.93-1.71-1.93-1.71-3.54-2.49-6.55-5.57-9.43-8.78,2.2.34,4.08,1.77,5.86,3.01,1.75,1.29,3.41,2.66,4.89,4.26,0,0-1.65.87-1.65.87-.3-2.77-.3-5.55.01-8.34h0Z" />
        <path d="m509.75,708.43c5.18-5.31,11.87-9.01,19.03-10.89.01-.01.08,1.95.09,1.95,0,0-12-3.01-12-3.01,3.23.27,13.81,1.17,16.86,1.42-1.61.58-5.38,1.91-6.98,2.36-5.89,1.79-11.24,5.23-15.58,9.57,0,0-1.42-1.41-1.42-1.41h0Z" />
        <path
          style={styles.three}
          d="m510.46,689.89c2.32-1.32,14.65-5.75,17.76-6.41.09-.02.17-.03.24-.05l-7.46-3.19,1,2.31-4.27,1.57.96,1.35,8.68-2.19"
        />
        <path d="m512.58,671.98c4.54-3.5,10.07-5.68,15.78-6.21,0,0-.47,1.82-.47,1.82l-6.04-5.35c1.87.97,8.02,4.14,9.79,5.05,0,0-3.09.47-3.09.47-5.33.5-10.5,2.54-14.75,5.81l-1.22-1.59h0Z" />
        <ellipse
          style={styles.three}
          cx="517.77"
          cy="668.38"
          rx="4.46"
          ry="1.24"
          transform="translate(-167.25 172.84) rotate(-16.35)"
        />
        <ellipse
          style={styles.three}
          cx="514.47"
          cy="685.37"
          rx="4.11"
          ry="1.92"
          transform="translate(-172.17 172.59) rotate(-16.35)"
        />
        <ellipse
          style={styles.three}
          cx="513.52"
          cy="704.44"
          rx="3.94"
          ry=".96"
          transform="translate(-341.82 536.15) rotate(-42.78)"
        />
      </g>
    </g>
  );
};

export default FightingStance;
