import { Divider, Grid, Typography } from "@mui/material";
import { AiOutlinePaperClip } from "react-icons/ai";
import { RiTeamFill } from "react-icons/ri";
import {
  badgeIconSize,
  cardLight,
  cardVeryLight,
  normal,
  offWhite,
  purple,
  red,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";
import Badge from "../custom/Badge";
import { TbSwords } from "react-icons/tb";

const TeamEvidenceItem = ({ isCreatingTeam, team, links }) => {
  const getLink = (link) => {
    if (!/^(f|ht)tps?:\/\//i.test(link)) {
      return `https://${link}`;
    }

    return link;
  };

  const styles = {
    team: {
      fontSize: normal,
      fontWeight: 800,
      color: text,
    },
    teamContainer: {
      width: "100%",
      padding: 2,
      borderRadius: 1,
      backgroundColor: cardLight,
    },
    width: {
      width: "100%",
    },
  };

  return (
    <Grid item sx={styles.teamContainer}>
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 1 }}
      >
        {isCreatingTeam !== null && (
          <Grid item>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 1 }}
            >
              <Badge
                label={isCreatingTeam ? "Creating Team" : "Joining Team"}
                size="small"
              >
                <RiTeamFill
                  style={{
                    color: isCreatingTeam ? yellow : purple,
                    fontSize: badgeIconSize,
                  }}
                />
              </Badge>

              <Grid item>
                <Typography sx={styles.team}>
                  {team.name ? team.name : "Temporary Team"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isCreatingTeam == null && (
          <Grid item>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              gap={{ xs: 1 }}
            >
              <Badge label={"Staff"} size="small">
                <TbSwords
                  style={{
                    color: red,
                    fontSize: badgeIconSize,
                  }}
                />
              </Badge>

              <Grid item>
                <Typography sx={styles.team}>{"Staff Evidence"}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            {links?.length < 1 && (
              <Grid item>
                <Typography
                  sx={{
                    fontSize: normal,
                    fontWeight: 500,
                    color: secondaryText,
                  }}
                >
                  No evidence submitted
                </Typography>
              </Grid>
            )}

            {links?.length > 0 && (
              <>
                {links?.map((link, i) => (
                  <Grid item key={i}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      gap={{ xs: 1 }}
                    >
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <AiOutlinePaperClip
                          style={{ fontSize: 15, color: offWhite }}
                        />
                      </Grid>

                      <Grid
                        item
                        onClick={() => window.open(getLink(link), "_blank")}
                      >
                        <Typography
                          sx={{
                            fontSize: small,
                            fontWeight: 600,
                            color: secondaryText,
                            transition: transition,
                            textDecoration: "underline",
                            "&:hover": {
                              cursor: "pointer",
                              color: text,
                            },
                          }}
                        >
                          {link}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamEvidenceItem;
