const Leftovers = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    three: {
      fill: "#4b56ed",
    },
    four: {
      fill: "#e9f9ff",
    },
    five: {
      fill: "#3e27f3",
    },
    six: {
      fill: "#c7f0fc",
    },
    seven: {
      fill: "#21c1dd",
    },
    eight: {
      fill: "#7bd6ff",
    },
    nine: {
      fill: "#7bd6ff",
    },
    ten: {
      fill: "#e7fbff",
    },
    eleven: {
      fill: "#20c3dd",
    },
    twelve: {
      fill: "#20c3dd",
    },
    thirteen: {
      fill: "#2cc1d1",
    },
  };

  return (
    <g>
      <polygon
        style={styles.eight}
        points="498.92 439.93 510.07 458.8 485.45 475.65 486.01 459.98 498.92 439.93"
      />
      <polygon
        style={styles.six}
        points="510.07 458.8 486.01 459.98 498.92 439.93 510.07 458.8"
      />
      <polygon
        style={styles.five}
        points="510.07 458.8 486.01 459.98 508.03 459.75 510.07 458.8"
      />
      <polygon
        style={styles.eight}
        points="556.16 476.5 551.22 497.86 521.77 493.1 532.98 482.13 556.16 476.5"
      />
      <polygon
        style={styles.six}
        points="551.22 497.86 532.98 482.13 556.16 476.5 551.22 497.86"
      />
      <path
        style={styles.five}
        d="M550.5,497.5c-5.84-5.12-11.68-10.25-17.52-15.37l16.11,15.01c.47,.12,.94,.24,1.41,.36Z"
      />
      <polygon
        style={styles.eight}
        points="588.92 484.95 600.07 503.82 575.45 520.67 576.01 505 588.92 484.95"
      />
      <polygon
        style={styles.six}
        points="600.07 503.82 576.01 505 588.92 484.95 600.07 503.82"
      />
      <polygon
        style={styles.five}
        points="600.07 503.82 576.01 505 598.03 504.77 600.07 503.82"
      />
      <g>
        <polygon
          style={styles.eight}
          points="404.7 451.17 394.13 475.65 427.39 459.98 413.67 454.66 404.7 451.17"
        />
        <polygon
          style={styles.six}
          points="395 475 413.67 454.66 404.7 451.17 395 475"
        />
      </g>
      <polygon
        style={styles.eight}
        points="536.83 441.17 547.39 465.65 514.13 449.98 527.85 444.66 536.83 441.17"
      />
      <polygon
        style={styles.six}
        points="547 465 527.85 444.66 536.83 441.17 547 465"
      />
      <g>
        <path
          style={styles.six}
          d="M415.51,475.9c0,.39-5.51,25.96-5.51,25.96l12.58,3.15,22.42-4.33-11.4-19.27-18.09-5.51Z"
        />
        <polygon
          style={styles.thirteen}
          points="422.58 505 433.6 481.4 445 500.67 422.58 505"
        />
        <path
          style={styles.five}
          d="M422.58,505c3.03-8.16,6.64-16.06,11.01-23.6-2.96,8.19-6.7,16.03-11.01,23.6h0Z"
        />
        <polygon
          style={styles.ten}
          points="410 501.85 423.46 478.32 415.51 475.9 410 501.85"
        />
        <path
          style={styles.ten}
          d="M428.09,484.76c.39,.58-5.51,11.39-5.51,11.39"
        />
      </g>
      <g>
        <path
          style={styles.six}
          d="M489.37,500.93c.39-.01,26.13,4.62,26.13,4.62l2.72-12.68-5.09-22.26-18.87,12.05-4.89,18.27Z"
        />
        <polygon
          style={styles.thirteen}
          points="518.21 492.87 494.25 482.67 513.12 470.61 518.21 492.87"
        />
        <path
          style={styles.five}
          d="M518.21,492.87c-8.26-2.75-16.28-6.09-23.96-10.2,8.29,2.68,16.25,6.15,23.96,10.2h0Z"
        />
        <polygon
          style={styles.ten}
          points="515.49 505.55 491.51 492.9 489.37 500.93 515.49 505.55"
        />
        <path
          style={styles.ten}
          d="M497.79,488.05c.57-.41,11.57,5.12,11.57,5.12"
        />
      </g>
      <path
        style={styles.six}
        d="M491.51,503.9c0,.39-5.51,25.96-5.51,25.96l12.58,3.15,22.42-4.33-11.4-19.27-18.09-5.51Z"
      />
      <polygon
        style={styles.thirteen}
        points="498.58 533 509.6 509.4 521 528.67 498.58 533"
      />
      <path
        style={styles.five}
        d="M498.58,533c1.69-4,3.43-7.98,5.24-11.92,1.78-3.96,3.62-7.89,5.69-11.71l.16,.08c-3.27,8.05-7.18,15.81-11.09,23.56h0Z"
      />
      <polygon
        style={styles.ten}
        points="486 529.85 499.46 506.32 491.51 503.9 486 529.85"
      />
      <path
        style={styles.ten}
        d="M504.09,512.76c.39,.58-5.51,11.39-5.51,11.39"
      />
      <g>
        <path
          style={styles.eight}
          d="M527.11,536.17c.11-.75,7.14-21.37,7.14-21.37l25.42-5.31c.18,.03,1.35,.21,2.04,1.28,.5,.78,.46,1.56,.43,1.82-.63,5.22-1.26,10.45-1.9,15.67-.23,.39-.51,.79-.86,1.18-1.63,1.89-3.73,2.61-4.93,2.91-9.12,1.27-18.23,2.54-27.35,3.82Z"
        />
        <polygon
          style={styles.four}
          points="533.41 523.49 535.33 516.41 547.45 514.13 533.41 523.49"
        />
        <path
          style={styles.twelve}
          d="M530.11,531.76c.32-.11,21.87-9.49,21.87-9.49l.84-10.3c.76,.7,1.52,1.51,2.26,2.46,3.32,4.3,4.11,9.01,4.32,11.79,.01,.19,.09,1.78-1.17,2.9-1.06,.94-2.33,.88-2.57,.86-8.83,1.41-17.66,2.83-26.49,4.24l.96-2.47Z"
        />
        <polygon
          style={styles.five}
          points="531.03 524.3 551.93 521.78 552.16 521.75 552.32 521.91 559.39 529.45 551.63 522.63 552.02 522.77 531.03 524.3 531.03 524.3"
        />
        <polygon
          style={styles.five}
          points="553.15 510.85 552.47 522.32 551.48 522.22 553.15 510.85 553.15 510.85"
        />
      </g>
      <g>
        <path
          style={styles.eight}
          d="M453.66,487.2c.76,.05,21.82,5.59,21.82,5.59l7.12,24.97c-.01,.18-.11,1.36-1.13,2.13-.74,.55-1.53,.57-1.79,.56-5.26-.26-10.51-.51-15.77-.77-.4-.2-.82-.46-1.24-.77-2-1.49-2.87-3.53-3.25-4.71-1.92-9-3.84-18.01-5.76-27.01Z"
        />
        <polygon
          style={styles.four}
          points="466.75 492.58 473.96 493.98 477.1 505.9 466.75 492.58"
        />
        <path
          style={styles.twelve}
          d="M458.27,489.87c.13,.31,11.03,21.13,11.03,21.13l10.34,.1c-.64,.8-1.4,1.63-2.29,2.43-4.05,3.62-8.69,4.75-11.45,5.15-.19,.03-1.77,.22-2.98-.96-1.01-.99-1.04-2.26-1.05-2.5-2.04-8.71-4.09-17.41-6.13-26.12l2.53,.78Z"
        />
        <polygon
          style={styles.five}
          points="465.78 490.25 469.79 510.92 469.84 511.15 469.68 511.32 462.68 518.91 468.92 510.68 468.81 511.08 465.78 490.25 465.78 490.25"
        />
        <polygon
          style={styles.five}
          points="480.78 511.35 469.28 511.5 469.31 510.5 480.78 511.35 480.78 511.35"
        />
      </g>
      <g>
        <path
          style={styles.eight}
          d="M559.54,454.98c0-1.2-1.2-12.02-1.2-12.02l11.62-12.82,13.22,3.61,9.22,6.81,1.6,13.22-19.24,5.61-15.23-4.41Z"
        />
        <polygon
          style={styles.eleven}
          points="565.35 451.17 569.75 435.14 594 453.78 576.17 458.98 577.67 444.76 571.76 440.75 569.75 446.36 565.35 451.17"
        />
        <path
          style={styles.four}
          d="M570.56,432.74c.4,0,10.25,2.4,10.25,2.4l-2.64,5.21-7.61-7.61Z"
        />
        <polygon
          style={styles.four}
          points="563.74 454.7 572.16 451.97 576.17 451.46 577.5 446.35 570.56 447.06 563.74 454.7"
        />
        <polygon
          style={styles.four}
          points="560.53 444.76 566.95 441.15 561.34 445.96 560.53 444.76"
        />
        <path
          style={styles.five}
          d="M559.69,443.56l9.26-4.66,.23-.11,.23,.14c-.03,.06,24.79,14.79,24.58,14.89,0,0-25.08-14.02-25.08-14.02l.45,.02-9.66,3.75h0Z"
        />
        <polygon
          style={styles.five}
          points="569.95 430.13 569.65 439.39 568.66 439.31 569.95 430.13 569.95 430.13"
        />
        <polygon
          style={styles.five}
          points="592.4 440.55 578.62 445.24 577.81 445.52 577.98 444.66 579.18 438.81 579.21 438.7 579.26 438.63 583.18 433.74 580.09 439.2 580.16 439.02 578.96 444.86 578.33 444.28 592.4 440.55 592.4 440.55"
        />
        <polygon
          style={styles.five}
          points="561.07 455.42 569.39 446.02 569.5 445.9 569.67 445.87 578.48 444.27 579.28 444.12 579.05 444.89 574.76 459.39 578.09 444.64 578.66 445.25 569.84 446.86 570.12 446.71 561.07 455.42 561.07 455.42"
        />
      </g>
      <g>
        <path
          style={styles.eight}
          d="M476.17,470.3c-.98,.7-9.06,8-9.06,8l-17.2-1.95-4.79-12.84,.15-11.46,9.8-9.02,15.79,12.34,5.31,14.94Z"
        />
        <polygon
          style={styles.eleven}
          points="469.69 467.8 454.1 473.58 455.07 443.02 469.71 454.46 457.29 461.54 457.49 468.68 463.21 467.03 469.69 467.8"
        />
        <path
          style={styles.four}
          d="M451.68,474.34c-.23-.33-4.04-9.73-4.04-9.73l5.77-.9-1.74,10.63Z"
        />
        <polygon
          style={styles.four}
          points="473.49 467.04 466.36 461.8 463.61 458.85 458.68 460.74 463.31 465.97 473.49 467.04"
        />
        <polygon
          style={styles.four}
          points="467.3 475.46 460.62 472.35 467.8 474.1 467.3 475.46"
        />
        <path
          style={styles.five}
          d="M466.81,476.84l-9.19-4.79s-.22-.12-.22-.12l-.02-.27c.06-.01-2.47-28.76-2.26-28.65,0,0,3.26,28.55,3.26,28.55l-.25-.38,8.69,5.66h0Z"
        />
        <polygon
          style={styles.five}
          points="449.92 476.34 457.61 471.18 458.12 472.04 449.92 476.34 449.92 476.34"
        />
        <polygon
          style={styles.five}
          points="445.27 452.04 457.13 460.49 457.82 460.99 457.03 461.35 451.58 463.78 451.47 463.83 451.39 463.83 445.12 463.5 451.36 462.83 451.17 462.87 456.62 460.43 456.52 461.29 445.27 452.04 445.27 452.04"
        />
        <polygon
          style={styles.five}
          points="475.64 468.79 463.14 467.53 462.99 467.51 462.86 467.39 456.42 461.17 455.83 460.61 456.58 460.34 470.86 455.36 456.94 461.28 457.11 460.45 463.56 466.67 463.28 466.54 475.64 468.79 475.64 468.79"
        />
      </g>
      <path
        style={styles.five}
        d="M395.44,475.03c2.97-3.46,5.97-6.89,8.98-10.31,2.71-2.98,6.33-7.36,9.25-10.07-2.35,3.19-6.33,7.3-8.98,10.31-3.06,3.38-6.14,6.73-9.25,10.07h0Z"
      />
      <polygon
        style={styles.five}
        points="527.95 444.55 546.5 464.5 527.74 444.76 527.95 444.55 527.95 444.55"
      />
      <g>
        <path
          style={styles.eight}
          d="M589.47,470.88l31.15-15.73,7.61,10.72-5.85,31.74s-31.7-17.68-31.68-18.36-1.23-8.38-1.23-8.38Z"
        />
        <polygon
          style={styles.seven}
          points="590.72 478.59 600.72 474.79 608.93 478.94 622.37 497.62 590.69 479.26 590.72 478.59"
        />
        <path
          style={styles.three}
          d="M616.52,457.22l-2.49,6.21-.06,.14s-.16,.08-.16,.08l-8.65,4.45c-.17,.09-.13,.09-.33,.04,0,0-5.43-1.16-5.43-1.16l.51-.2-9.19,11.8,8.38-12.38,.18-.27s.33,.07,.33,.07l5.43,1.16-.33,.04,8.65-4.45-.22,.22,3.39-5.77h0Z"
        />
        <polygon
          style={styles.three}
          points="620.62 455.15 622 495.49 622.05 497.06 621.1 495.8 608.53 479.24 608.49 479.18 608.46 479.11 604.48 468.37 605.45 468.22 604.07 487.01 604.45 468.17 604.51 465.55 605.42 468.02 609.4 478.77 609.33 478.64 621.9 495.2 621 495.51 620.62 455.15 620.62 455.15"
        />
        <path
          style={styles.three}
          d="M613.87,462.8l7.6,5.42-.51-.04c1.65-.81,5.39-2.65,7.01-3.44-1.34,1.35-5.28,5.29-6.68,6.7,0,0,3.83-5.25,3.83-5.25l.6,.77-4.33,2.12-.27,.13-.24-.17-7.6-5.42,.58-.81h0Z"
        />
        <polygon
          style={styles.three}
          points="623.28 469.46 626.79 473.68 622.57 470.17 623.28 469.46 623.28 469.46"
        />
        <polygon
          style={styles.three}
          points="609.41 478.81 612.3 491.78 608.45 479.07 609.41 478.81 609.41 478.81"
        />
        <polygon
          style={styles.three}
          points="604.85 487.46 609.39 481.77 610.13 482.45 604.85 487.46 604.85 487.46"
        />
        <path
          style={styles.nine}
          d="M601.3,468.97c.12,.19-3.76,6.45-3.76,6.45l-4.98,2.31,4.58-6.92,4.16-1.83Z"
        />
        <path
          style={styles.nine}
          d="M606.46,468.91c.27,.62,2.69,9.44,2.69,9.44l8.28-11.45-4.77-2.43-6.21,4.45Z"
        />
        <path
          style={styles.nine}
          d="M610.83,480.55c.69-.65,8.7-11.98,8.7-11.98l.55,4.05-7.78,9.86-1.47-1.93Z"
        />
        <polygon
          style={styles.nine}
          points="601.67 474.61 603.42 474.86 604.07 468.44 601.67 474.61"
        />
      </g>
      <g>
        <polygon
          style={styles.eight}
          points="608.4 445.61 625.72 428.48 633.71 462 608.4 445.61"
        />
        <polygon
          style={styles.ten}
          points="625.72 428.48 619.08 442.01 633.71 462 625.72 428.48"
        />
        <polygon
          style={styles.twelve}
          points="619.08 442.01 608.4 445.61 625.72 428.48 619.08 442.01"
        />
      </g>
      <path d="M404.7,451.17c3.07,.23,7.43,.89,12.3,2.83,4.59,1.82,8.05,4.14,10.39,5.98-7.57-2.93-15.13-5.87-22.7-8.8Z" />
      <polyline
        style={styles.two}
        points="415.51 475.9 433.6 481.4 445 500.67"
      />
      <path
        style={styles.two}
        d="M453.66,487.2c-.16,.3,21.82,5.59,21.82,5.59"
      />
      <polyline
        style={styles.two}
        points="485.45 475.65 486.01 459.98 498.92 439.93"
      />
      <polyline
        style={styles.two}
        points="455.07 443.02 470.86 455.36 476.17 470.3"
      />
      <polyline
        style={styles.two}
        points="489.37 500.93 494.25 482.67 513.12 470.61"
      />
      <line
        style={styles.two}
        x1="514.13"
        y1="449.98"
        x2="536.83"
        y2="441.17"
      />
      <polyline
        style={styles.two}
        points="556.16 476.5 532.98 482.13 521.77 493.1"
      />
      <polyline
        style={styles.two}
        points="534.25 514.8 559.67 509.49 562.16 511.35 560.25 528.26"
      />
      <polyline
        style={styles.two}
        points="575.45 520.67 576.01 505 588.92 484.95"
      />
      <polyline
        style={styles.two}
        points="559.54 454.98 574.76 459.39 594 453.78"
      />
      <line
        style={styles.two}
        x1="589.47"
        y1="470.88"
        x2="620.62"
        y2="455.15"
      />
      <line style={styles.two} x1="608.4" y1="445.61" x2="625.72" y2="428.48" />
      <g>
        <path
          style={styles.one}
          d="M409.04,448c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="397.01"
            y1="459.38"
            x2="420.99"
            y2="458.62"
          />
          <path
            style={styles.one}
            d="M397,459.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M423.04,470c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="411.01"
            y1="481.38"
            x2="434.99"
            y2="480.62"
          />
          <path
            style={styles.one}
            d="M411,481.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M459.04,443c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="447.01"
            y1="454.38"
            x2="470.99"
            y2="453.62"
          />
          <path
            style={styles.one}
            d="M447,454.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M498.04,445c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="486.01"
            y1="456.38"
            x2="509.99"
            y2="455.62"
          />
          <path
            style={styles.one}
            d="M486,456.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M506.04,474c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="494.01"
            y1="485.38"
            x2="517.99"
            y2="484.62"
          />
          <path
            style={styles.one}
            d="M494,485.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M505.04,507c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="493.01"
            y1="518.38"
            x2="516.99"
            y2="517.62"
          />
          <path
            style={styles.one}
            d="M493,518.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M469.04,494c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="457.01"
            y1="505.38"
            x2="480.99"
            y2="504.62"
          />
          <path
            style={styles.one}
            d="M457,505.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M531.04,435c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="519.01"
            y1="446.38"
            x2="542.99"
            y2="445.62"
          />
          <path
            style={styles.one}
            d="M519,446.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M573.04,433c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="561.01"
            y1="444.38"
            x2="584.99"
            y2="443.62"
          />
          <path
            style={styles.one}
            d="M561,444.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M550.04,480c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="538.01"
            y1="491.38"
            x2="561.99"
            y2="490.62"
          />
          <path
            style={styles.one}
            d="M538,491.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M552.04,505c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="540.01"
            y1="516.38"
            x2="563.99"
            y2="515.62"
          />
          <path
            style={styles.one}
            d="M540,516.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M585.04,492c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="573.01"
            y1="503.38"
            x2="596.99"
            y2="502.62"
          />
          <path
            style={styles.one}
            d="M573,503.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M611.04,460c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="599.01"
            y1="471.38"
            x2="622.99"
            y2="470.62"
          />
          <path
            style={styles.one}
            d="M599,471.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
      <g>
        <path
          style={styles.one}
          d="M625.04,432c.2,7.87,.2,16.13-.04,24-.17-7.82-.38-16.33,.04-24h0Z"
        />
        <g>
          <line
            style={styles.one}
            x1="613.01"
            y1="443.38"
            x2="636.99"
            y2="442.62"
          />
          <path
            style={styles.one}
            d="M613,443.34c7.86-.45,16.11-.71,23.99-.72-7.81,.41-16.31,.9-23.99,.72h0Z"
          />
        </g>
      </g>
    </g>
  );
};

export default Leftovers;
