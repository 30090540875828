const GymRat = ({ skinColor }) => {
  const styles = {
    one: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 0.5,
    },
    two: {
      fill: skinColor,
    },
  };

  return (
    <g>
      <path
        style={styles.two}
        d="m582.38,762.45c.86-4.94.92-10.04.15-15-.21-1.34.32-2.68,1.75-3.08.17-.05.35-.07.53-.07-.08-.93.42-1.82,1.22-2.42,1.16-.86,2.12-1.99,3.21-2.94,1.39-1.2,3.65-.82,4.18,1.1,1,3.64,1.07,7.42,1.48,11.15.41,3.67,1.35,7.34,2.13,10.95,1.34,6.25,2.73,12.51,4.91,18.54,2.03,5.62,4.72,11.13,8.06,16.09.28.41.58.82.88,1.22.09.11.17.23.26.34.02.03.05.06.06.08.26.33.51.65.77.97,1.2,1.5,2.44,2.96,3.72,4.39,1.28,1.43,2.6,2.84,4.04,4.11.11.1.76.62.75.62.2.15.4.29.61.42.09.05.18.1.26.13.38.01.75.13,1.08.31,22.15-11.69,39.01-24.48,50.64-34.47-.48-20.14-2.41-43.42-7.05-68.96-3.61-19.89-8.27-37.81-13.21-53.58-12.37-12.96-31.33-29.91-57.79-44.37-32.91-17.99-63.4-24.48-83.11-27.06l-63.2-5.51c-19.09,5.12-41.58,12.8-65.69,24.57-33.89,16.54-60.03,35.8-78.85,52.06-4.17,11.48-8.24,25.2-11.15,40.89-3.83,20.64-4.62,39.02-4.22,53.91,13.78,15.91,38.59,40.87,75.96,61.22,2.19-3.89,3.61-8.14,5.3-12.28,2-4.9,3.39-9.88,3.83-15.17.05-.58.26-1.12.59-1.56-.34-.66-.65-1.35-.9-2.08-1.03-3.06,3.8-4.36,4.82-1.33.62,1.83,1.82,3.09,3.25,4.34,1.76,1.54,3.41,3.05,4.72,5,1.13,1.69,2,3.52,3.02,5.28.93,1.59,2.02,2.88,3.34,4.16,2.95,2.87,5.76,5.93,5.9,10.29.13,4.1-1.61,8.09-2.15,12.12-.25,1.87-.54,3.73-1.07,5.5,19.3,7.54,51.24,17.4,91.59,18.54,18.2.51,51.57-.38,90.7-12.8-.16-5.76-.91-11.48-1.12-17.24-.2-5.57-.95-11.1-1.15-16.67-.21-5.83,1.05-11.26,2.81-16.79,1.57-4.94,3.23-9.83,4.13-14.95Z"
      />
      <path
        style={styles.two}
        d="m518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12.32,7.46,1.17,8.35.49.51,1.02.81,1.4.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22.48-.66,1.01-1.11,1.37-1.37.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
      />
      <path d="m460,523c1.9,1.69,3.9,3.36,6,5,15.78,12.32,32.48,19.14,47,23,1.96-5.48,3.91-10.96,5.87-16.44-4.61.02-9.68-.16-15.13-.64-.79-.07-6.86-.61-12.73-1.62-12.45-2.13-23.75-6.29-31-9.31Z" />
      <g>
        <path d="m460.98,525.94s-11.31,49.7-11.31,49.7c0,0-.13.58-.13.58-26.53,7.62-52.28,17.91-76.63,30.88-23.64,12.3-48.51,29.89-67.81,45.32-5.35,15.12-9.38,30.71-12.07,46.52-2.52,15.85-4.14,31.94-4.24,47.98-.85-16.12-.31-32.33,2.34-48.29,2.59-16.07,6.42-32.2,12.38-47.31,30.69-27.84,66.84-49.55,105.48-64.41,12.88-4.96,26.06-9.14,39.45-12.46,0,0-.72.74-.72.74l11.31-49.7s1.95.44,1.95.44h0Z" />
        <path d="m518.91,536.58c-5.25,14.25-7.48,29.61-6.48,44.77,0,.02-.88-.93-.88-.92,54,4.88,105.74,30.97,141.96,71.28,8.74,30,15.07,60.99,18.13,92.15.93,10.33,1.39,20.83,1.39,31.13-1.32-31.11-6.52-61.89-13.49-92.2-1.97-9.41-5.65-22.09-7.49-29.72-23.2-24.11-51.47-43.28-82.63-55.47-18.65-7.43-38.22-12.33-58.11-15.18,0,0-.84-.12-.84-.12,0,0-.04-.81-.04-.81-1.01-15.44,1.25-31.08,6.6-45.59,0,0,1.88.69,1.88.69h0Z" />
      </g>
      <path
        style={styles.one}
        d="m622.85,808.07c-.96,1.56-1.73.74-2.58-.03-11.03-9.92-17.99-22.31-21.65-36.53-1.76-6.83-2.8-13.85-4.13-20.79-.8-4.2-1.53-8.42-2.23-12.28-2.8,2.54-5.66,5.59-8.95,8.05-11.29,8.43-24.11,11.31-38.01,9.83-14.4-1.54-27.05-7.33-38.07-16.48-4.34-3.6-7.89-8.17-11.75-12.34-.54-.59-.81-1.43-1.2-2.15.19-.18.38-.35.57-.53.67.52,1.44.95,2.01,1.56,6.28,6.83,13.11,12.99,21.15,17.72,12.17,7.17,25.21,10.67,39.39,9.4,20.56-1.84,37.35-16.63,41.98-36.89,2.1-9.19,1.79-18.33-.12-27.49-.04-.19-.2-.4-.15-.54.25-.69.55-1.36.84-2.04.57.47,1.49.84,1.64,1.41.62,2.44,1.17,4.93,1.47,7.43,1.47,12.1.08,23.82-5.85,34.6-1.98,3.6-2.8,6.91-2.04,10.91,2.67,14.15,4.78,28.42,10.71,41.76,3.78,8.5,9.19,15.85,15,22.99.64.78,1.27,1.58,1.98,2.46Z"
      />
      <path
        style={styles.one}
        d="m370.45,697.46c.14,1.45.33,2.9.42,4.35.58,9.94,2.96,19.35,8.2,27.9,8.12,13.24,20.15,20.72,35.24,23.4,16.52,2.93,32.3-.19,47.35-6.99,6.77-3.05,13.04-7.2,19.57-10.79.51-.28,1.29-.06,1.94-.07-.28.55-.42,1.29-.86,1.62-14.31,10.52-30.04,17.78-47.8,19.91-16.01,1.92-31.11-.68-44.31-10.58-11.46-8.6-17.79-20.36-20.02-34.35-.66-4.16-.59-8.44-.82-12.66-.03-.57.13-1.15.2-1.72.3,0,.6-.02.9-.02Z"
      />
      <path
        style={styles.one}
        d="m365.22,804.61c1.67-6.06,3.47-12.09,4.99-18.18,2.9-11.6,3.7-23.35,2.27-35.24-.95-7.91-1.79-15.84-2.68-23.76-.05-.44-.12-.9-.04-1.32.09-.44.35-.85.53-1.27.34.36.91.68.99,1.09.92,4.9,1.91,9.79,2.61,14.73,1.32,9.34,2.39,18.72,1.94,28.19-.03.66.36,1.48.8,2.01,4.69,5.64,9.46,11.22,14.16,16.84,2.27,2.72,4.7,5.35,6.6,8.32,1.13,1.77,2.01,4.14,1.9,6.19-.31,5.79-1.18,11.55-1.85,17.31-.05.43-.16.93-.43,1.24-.29.33-.8.6-1.22.62-.19,0-.6-.65-.6-.99.01-1.2.21-2.39.26-3.59.2-4.49.33-8.99.57-13.48.17-3.08-.81-5.74-2.72-8.08-2-2.45-4.1-4.82-6.1-7.28-3.36-4.11-6.68-8.26-10-12.4-.51-.63-.96-1.3-1.63-2.22-1.75,11.23-4.84,21.71-9.76,31.6-.2-.11-.4-.22-.6-.33Z"
      />
      <path
        style={styles.one}
        d="m500.63,758.55c-.74,3.28-1.15,6.72-2.29,9.89-2.32,6.47-2.33,13.01-1.42,19.67.51,3.73.53,7.45-.63,11.09-.74,2.32-.56,4.58.13,6.89,1.88,6.22,1.69,12.62,1.46,19.02-.01.31-.03.7-.21.91-.45.54-1,1-1.51,1.5-.35-.6-.95-1.19-1-1.82-.17-2.21.05-4.45-.26-6.63-.62-4.43-1.6-8.8-2.2-13.23-.3-2.21-.7-4.68-.04-6.7,1.6-4.86.93-9.63.44-14.47-.5-4.89-.44-9.69,1.2-14.43,1.51-4.34,3.3-8.67,2.83-13.45-.19-1.88-.55-3.75-.67-5.64-.04-.62.42-1.26.65-1.9.55.4,1.45.71,1.6,1.22.73,2.59,1.27,5.24,1.93,8.08Z"
      />
      <path
        style={styles.one}
        d="m582.54,748.06c.18.55.43,1.09.52,1.65.85,5.11-.09,10.07-1.34,14.98-1.66,6.55-3.54,13.05-5.01,19.64-1.76,7.9-.9,15.86.03,23.8.68,5.78,1.21,11.57,1.7,17.37.06.68-.58,1.42-.89,2.14-.56-.61-1.47-1.14-1.62-1.83-.61-2.84-1.16-5.71-1.42-8.6-.71-7.93-1.53-15.87-1.81-23.82-.27-7.53,2.13-14.69,4.07-21.88,1.51-5.6,3.11-11.19,4.47-16.83.51-2.11.48-4.34.69-6.52.2-.03.4-.06.6-.08Z"
      />
      <path
        style={styles.one}
        d="m423.35,630.56c-4.96-1.02-9.9-2.13-14.88-3.03-14.41-2.61-28.92-4.16-43.58-3.6-11.45.44-22.3,3.52-32.75,8.11-.62.27-1.43.1-2.16.14.43-.67.71-1.59,1.32-1.98,5.72-3.69,12.21-5.47,18.72-7.02,9.88-2.36,19.93-2.47,30.01-1.64,11.34.93,22.55,2.71,33.6,5.4,2.98.72,5.88,1.78,8.81,2.7.38.12.71.39,1.06.59-.05.11-.1.23-.16.34Z"
      />
      <path
        style={styles.one}
        d="m489.78,684.14c-.07-.47-.22-.94-.19-1.4.42-7.73,1.56-15.34,3.81-22.75,2.43-8.02,7.93-13.07,15.75-15.64,3.04-1,6.22-1.62,9.36-2.31,4.99-1.09,10-2.13,15.01-3.1.69-.13,1.46.2,2.2.32-.52.55-.95,1.42-1.58,1.6-5.43,1.62-10.85,3.32-16.37,4.6-4.99,1.16-9.72,2.83-14.04,5.52-3.29,2.05-5.47,5.19-6.59,8.8-2.23,7.16-4.1,14.44-6.11,21.67-.25.89-.49,1.79-.74,2.68-.17,0-.33,0-.5,0Z"
      />
      <path
        style={styles.one}
        d="m508.81,798.68c1.67-.48,3.32-1.08,5.02-1.42,5.88-1.18,11.8-2.17,17.67-3.42,8.87-1.9,15.76-6.59,20.1-14.75,1.93-3.63,3.96-7.2,6-10.77.26-.45.91-.68,1.38-1.01.1.63.48,1.4.24,1.86-2.81,5.58-5.29,11.38-8.71,16.57-3.19,4.84-8.38,7.71-13.82,9.61-8.93,3.11-18.32,3.44-27.63,4.17-.09-.28-.17-.56-.26-.84Z"
      />
      <path
        style={styles.one}
        d="m643.58,723.34c1.13-3.37,2.18-6.77,3.42-10.09,3.18-8.53,6.59-17.02,8.11-26.03.81-4.83.79-9.81,1.14-14.72.04-.63-.07-1.28.04-1.89.07-.36.41-.88.69-.92.31-.05.92.32.99.61.38,1.59.84,3.19.89,4.81.36,9.84-1.81,19.24-5.51,28.28-2.61,6.36-5.57,12.58-8.39,18.86-.22.48-.56.9-.85,1.35-.18-.08-.36-.17-.54-.25Z"
      />
      <path
        style={styles.one}
        d="m312.9,721.03c-1.42-3.24-3.03-6.42-4.23-9.75-5.06-14.07-8.28-28.48-7.29-43.57.33-4.98.9-9.93,2.95-14.57.2-.46.38-.93.62-1.37.08-.14.31-.19.87-.5-.75,4.85-1.74,9.39-2.08,13.99-.35,4.72-.31,9.5.03,14.21.34,4.78,1.06,9.55,1.96,14.25.89,4.63,2.09,9.22,3.4,13.75,1.3,4.49,2.9,8.88,4.37,13.32-.2.07-.4.15-.61.22Z"
      />
      <path
        style={styles.one}
        d="m411.39,780.44c-2.15-.59-4.38-.96-6.42-1.8-9.29-3.84-17.27-9.47-23.34-17.6-.67-.9-1.2-1.93-1.66-2.96-.16-.36-.06-1.12.2-1.31.31-.22,1.01-.17,1.38.04.59.34,1.09.87,1.54,1.39,5.92,6.81,12.98,12.19,20.79,16.65,2.65,1.52,5.24,3.15,7.85,4.74-.11.29-.23.57-.34.86Z"
      />
      <path
        style={styles.one}
        d="m490.84,769.55c-2.68.68-5.33,1.63-8.06,2-9.51,1.29-19.06,1.61-28.54-.22-2.57-.5-5.01-1.67-7.47-2.65-.44-.17-.67-.87-.99-1.32.5-.06,1.05-.28,1.49-.15,11.16,3.29,22.58,2.29,33.94,2,3.15-.08,6.29-.38,9.43-.58.06.31.13.62.19.92Z"
      />
      <path
        style={styles.one}
        d="m586.41,633.99c1.4-.17,2.8-.46,4.21-.49,16.56-.42,32.97.35,48.78,6.02.83.3,1.67.6,2.43,1.03.35.2.52.72.77,1.09-.44.13-.92.46-1.3.37-2.39-.57-4.75-1.24-7.12-1.87-10.27-2.77-20.7-4.55-31.36-4.85-5.18-.15-10.35-.47-15.53-.71-.29-.01-.58-.09-.87-.14,0-.15,0-.29-.01-.44Z"
      />
      <path
        style={styles.one}
        d="m478.4,636.14c-1.32-1.3-2.81-2.47-3.94-3.92-3.56-4.62-4.7-10.17-5.49-15.77-.35-2.48-.39-5.01-.35-7.52,0-.53.97-1.04,1.5-1.57.4.55,1.08,1.07,1.16,1.67,1.15,8.7,2.9,17.21,7.13,25.03.26.48.38,1.03.56,1.54-.19.18-.38.36-.57.54Z"
      />
      <path
        style={styles.one}
        d="m464.81,803.38c-6.13.14-12.05-1.14-17.88-2.82-1.11-.32-2.11-1.1-3.07-1.79-.28-.2-.24-.84-.34-1.28.4-.02.83-.13,1.21-.05,5.29,1.09,10.55,2.43,15.88,3.27,6.65,1.04,13.2-.19,19.68-1.75.89-.21,2-.87,2.35.52.33,1.27-.83,1.43-1.77,1.7-5.25,1.51-10.59,2.31-16.06,2.21Z"
      />
      <path
        style={styles.one}
        d="m418.05,746.45c-.74-.15-1.54-.16-2.21-.47-3.19-1.51-6.38-3.04-9.46-4.73-.66-.36-1.43-1.71-1.22-2.19.46-1.06,1.23-2.3,2.83-2,4.12.76,8.17,1.73,11.75,4.03,1.18.76,2.35,1.79,1.86,3.4-.51,1.66-1.98,1.96-3.56,1.98Z"
      />
      <path
        style={styles.one}
        d="m426,794.81c-.79-1.41-1.93-2.74-2.32-4.26-1.84-7.2-1.02-14.17,2.38-20.8.4-.78.92-1.56,1.56-2.13.43-.38,1.18-.39,1.79-.56.16.61.35,1.21.45,1.82.04.22-.14.48-.25.7-3.3,6.65-3.73,13.71-3.03,20.94.13,1.31.16,2.62.24,3.93-.28.12-.55.24-.83.36Z"
      />
      <path
        style={styles.one}
        d="m446.72,827.25c.5.12,1.06.14,1.5.38,6.85,3.66,14.25,4.62,21.86,4.38,5.2-.16,10.34-.73,14.97-3.46.32-.19.96.17,1.45.28-.25.51-.38,1.33-.77,1.49-2.68,1.06-5.36,2.35-8.16,2.89-8.18,1.57-16.38,1.28-24.41-1.03-1.84-.53-3.54-1.63-5.22-2.61-.66-.39-1.07-1.19-1.6-1.8.13-.17.25-.34.38-.52Z"
      />
      <path
        style={styles.one}
        d="m637.08,721.36c-.59-.16-.99-.16-1.24-.35-7.83-5.8-16.05-10.9-25.24-14.3-.13-.05-.14-.44-.33-1.1,1.43,0,2.72-.21,3.92.03,9.44,1.88,16.73,7.1,22.46,14.68.16.22.22.52.42,1.04Z"
      />
      <path
        style={styles.one}
        d="m507.83,828.53c.48,0,.98-.1,1.43.01,11.08,2.75,21.81,1.2,32.27-2.82,1.79-.69,3.23-2.27,4.86-3.4.36-.25.85-.31,1.28-.45.14.49.55,1.19.36,1.44-.74.99-1.55,2.01-2.56,2.71-3.89,2.67-8.42,3.7-12.94,4.55-6.16,1.16-12.39,1.87-18.66.88-1.61-.25-3.19-.72-4.73-1.25-.59-.2-1.04-.83-1.55-1.27.08-.14.16-.27.25-.41Z"
      />
      <path
        style={styles.one}
        d="m532.14,764.34c-.66.34-1.29.82-2,.99-5.27,1.29-10.56,2.49-15.82,3.77-2.64.64-5.23,1.48-7.87,2.06-.63.14-1.4-.39-2.11-.61.47-.5.84-1.21,1.42-1.48,8.29-3.88,17.1-5.15,26.12-5.36.09.21.17.42.26.63Z"
      />
      <path
        style={styles.one}
        d="m436.93,828.6c-.56-.49-1.2-.9-1.65-1.47-2.4-3.02-3.43-6.62-3.86-10.33-.31-2.73-.21-5.52-.09-8.27.05-1.08.44-2.44,2.02-2.2,1.6.25,1.12,1.5,1.22,2.65.4,4.7.97,9.38,1.59,14.05.24,1.77.8,3.5,1.22,5.24-.15.11-.3.22-.45.32Z"
      />
      <path
        style={styles.one}
        d="m354.53,705.68c-4.08.38-7.59.61-11.08,1.04-4.32.53-8.26,2.19-12.05,4.28-.67.37-1.64.2-2.47.28.35-.84.47-2,1.1-2.48,4.42-3.31,9.47-4.93,14.99-5.09,3.16-.1,6.2.32,9.51,1.97Z"
      />
      <path
        style={styles.one}
        d="m650.9,758.13c2.09,3.54,3.33,7.4,4.16,11.42.99,4.8,1.59,9.61.66,14.49-.09.49-.16,1.07-.46,1.42-.36.42-.95.65-1.44.97-.3-.5-.85-1-.85-1.5-.01-1.58.27-3.15.28-4.73.05-7.05-1.16-13.95-2.72-20.79-.09-.38-.1-.79-.14-1.18.17-.03.34-.06.5-.09Z"
      />
      <path
        style={styles.one}
        d="m443.23,605.46c.28-2.26.48-4.54.84-6.79,1.22-7.66,2.5-15.32,3.76-22.97.06-.37.1-.79.3-1.08.22-.31.62-.49.94-.73.23.33.65.67.64.99-.07,1.89-.03,3.83-.41,5.67-1.69,8.2-3.5,16.37-5.28,24.54-.03.16-.17.3-.26.45-.18-.02-.35-.05-.53-.07Z"
      />
      <path
        style={styles.one}
        d="m566.37,741.48c-2.13-.03-3.1-1.3-2.32-2.79.53-1.01,1.43-1.97,2.4-2.55,1.94-1.16,4.02-2.11,6.1-3.02,1.13-.49,2.43-.64,3.19.68.79,1.36-.08,2.32-1.1,3.04-1.95,1.37-3.95,2.7-6,3.92-.79.47-1.78.58-2.28.73Z"
      />
      <path
        style={styles.one}
        d="m546.63,816.46c0-1.04-.21-2.14.04-3.12,1.28-5.1,1.2-10.27.94-15.46-.03-.57-.13-1.17.02-1.7.14-.47.55-1.14.89-1.18.39-.04,1.14.46,1.23.85,1.67,7.23,1.46,14.22-2.54,20.68-.19-.03-.39-.05-.58-.08Z"
      />
      <path
        style={styles.one}
        d="m507.67,604.17c-1.66-3.92-.99-7.78-.28-11.59.58-3.08,1.47-6.11,2.28-9.14.24-.91.67-2.07,1.87-1.48.46.23.72,1.53.56,2.23-1.19,5.35-2.53,10.66-3.78,16-.31,1.31-.44,2.66-.65,3.99Z"
      />
      <path
        style={styles.one}
        d="m500.19,840.87c-2.14-.07-4.07-.13-6.11-.19.31-2.28,1.06-3.68,2.86-3.52,1.61.15,2.95.88,3.25,3.71Z"
      />
    </g>
  );
};

export default GymRat;
