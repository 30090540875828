const Injured = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
  };

  return (
    <g>
      <path d="M546.26,388.03c-1.1-4.07-2.35-8.1-3.25-12.21-.87-3.97-2.77-7.7-3.27-11.81,1.45-.23,1.55,.65,1.87,1.22,2.15,3.87,3.38,8.09,4.47,12.35,.99,3.87,2.04,7.72,2.97,11.6,.23,.97,.75,1.52,1.57,1.95,1.91,1,3.79,2.05,5.7,3.06,1.47,.78,2.63,1.88,3.22,3.42,1,2.6,1.97,5.22,2.75,7.89,.33,1.11,.22,2.38,.18,3.57-.07,2.1-.45,4.05-3.31,4.2,.36,.62,.56,1.04,.83,1.41,5.44,7.63,2.28,15.39-4.84,19.77-4.17,2.56-8.7,2.96-13.41,2.13-2.67-.47-5.32-1.1-8.01-1.46-3.17-.42-6.23-.04-8.85,2.05-.87,.7-1.73,.59-2.7,.23-3.22-1.2-4.93-3.68-5.84-6.82-1.4-4.85-.75-9.32,2.7-13.2,.75-.84,1.59-1.56,2.82-1.58,.8,.98-.04,1.49-.55,2.01-3.2,3.32-4.06,7.32-3.41,11.73,.34,2.31,1.52,4.2,3.49,5.5,1.46,.96,2.14,.89,3.59-.21,2.8-2.13,6.04-2.38,9.36-2.08,2.22,.2,4.42,.82,6.64,.93,2.77,.14,5.61,.25,8.32-.21,1.96-.33,3.96-1.34,5.58-2.53,5.2-3.81,6.11-9.3,2.68-14.75-.98-1.55-1.95-3.12-3-4.62-.39-.56-.94-1.1-1.54-1.43-2.49-1.33-5.09-2.29-7.99-2.12-3.35,.2-6.71,.22-10.04,.53-1.64,.15-3.28,.67-4.85,1.21-2.86,.99-5.67,2.14-8.51,3.21-.52,.2-1.06,.36-1.6,.5-3.77,1.01-4.28,.74-5.58-3-.99-2.87-1.98-5.73-2.94-8.61-.61-1.84-.9-3.71-.52-5.66,.2-1.03,.68-1.76,1.64-2.18,.51-.22,.98-.55,1.51-.73,5.89-2,11.75-4.09,17.69-5.94,4.77-1.48,9.58-1.99,14.1,1.15,.11-.16,.22-.33,.33-.49Zm14.21,20.81c-.28-4.24-1.19-7.74-3.25-10.86-.51-.77-1.26-1.5-2.07-1.93-3.86-2.08-7.78-4.06-11.68-6.07-2.29-1.19-4.71-1.47-7.22-.96-1.8,.36-3.59,.76-5.35,1.28-4.74,1.39-9.47,2.86-14.2,4.31-.74,.23-1.46,.54-2.25,.84,.31,5.19,2.35,9.44,5.54,13.29,.96-.37,1.7-.66,2.44-.95,3.71-1.44,7.36-3.09,11.16-4.29,6.63-2.09,13.21-2.03,19.49,1.44,1.67,.92,3.32,1.88,4.99,2.8,.61,.33,1.26,.6,2.39,1.13Z" />
      <path
        style={styles.one}
        d="M560.47,408.85c-1.14-.53-1.79-.79-2.39-1.13-1.67-.92-3.32-1.88-4.99-2.8-6.27-3.47-12.85-3.52-19.49-1.44-3.79,1.19-7.44,2.84-11.16,4.29-.74,.29-1.48,.58-2.44,.95-3.18-3.85-5.23-8.1-5.54-13.29,.79-.29,1.5-.61,2.25-.84,4.73-1.45,9.45-2.91,14.2-4.31,1.76-.52,3.55-.91,5.35-1.28,2.5-.5,4.93-.22,7.22,.96,3.9,2.02,7.82,3.99,11.68,6.07,.81,.44,1.56,1.16,2.07,1.93,2.05,3.13,2.97,6.62,3.25,10.86Z"
      />
    </g>
  );
};

export default Injured;
