import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import { FaDiscord, FaUsers, FaUserSecret } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { RiSwordFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../../store/Store";
import { AccountTypeEnum } from "../../../utils/enums";
import {
  isDateGreaterThanCurrent,
  isWithinOneMinute,
} from "../../../utils/helpers";
import {
  card,
  cardExtraLight,
  cardLight,
  cardVeryLight,
  lightGrey,
  text,
} from "../../../utils/themeContstants";
import DownloadACModal from "../../anti_cheat/DownloadACModal";
import MainMenuListItem from "../../custom/MainMenuListItem";
import NewCustomMenu from "../../custom/NewCustomMenu";
import NewListItem from "../../custom/NewListItem";
import SelectGameModal from "../../custom/SelectGameModal";
import CreateMatch from "../../matches/CreateMatch";
import PurchaseChatUnbanModal from "../../public_profile/PurchaseChatUnbanModal";
import PurchaseUnbanModal from "../../public_profile/PurchaseUnbanModal";
import ResetStatsModal from "../../public_profile/ResetStatsModal";
import BuySnipesModal from "../../subscriptions/BuySnipesModal";
import UpdateTOSModal from "../../support/UpdateTOSModal";
import UpgradeModal from "../UpgradeModal";
import ResetEarningsModal from "../../public_profile/ResetEarningsModal";

const MainMenu = ({ anchor, handleClose }) => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:1024px)");

  const [premiumOpen, setPremiumOpen] = useState(false);
  const [snipesOpen, setSnipesOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [gameOpen, setGameOpen] = useState(false);
  const [game, setGame] = useState(null);
  const [createOpen, setCreateOpen] = useState(false);
  const [tosOpen, setTosOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [unbanOpen, setUnbanOpen] = useState(false);
  const [chatUnbanOpen, setChatUnbanOpen] = useState(false);
  const [resetEarningsOpen, setResetEarningsOpen] = useState(false);

  const onClose = () => {
    handleClose();
  };

  const styles = {
    width: {
      width: "100%",
    },
    container: {
      borderRadius: 2,
      backgroundColor: isDesktop ? card : "transparent",
      padding: isDesktop ? 1.5 : 1,
      paddingBottom: isDesktop ? 1.5 : 2,
    },
    header: {
      fontSize: 16,
      fontWeight: 600,
      color: text,
      paddingLeft: 1,
    },
    icon: {
      fontSize: 20,
      color: lightGrey,
    },
  };

  return (
    <>
      <UpgradeModal open={premiumOpen} onClose={() => setPremiumOpen(false)} />
      <BuySnipesModal open={snipesOpen} onClose={() => setSnipesOpen(false)} />
      <ResetStatsModal open={resetOpen} onClose={() => setResetOpen(false)} />
      <ResetEarningsModal
        open={resetEarningsOpen}
        onClose={() => setResetEarningsOpen(false)}
      />
      <SelectGameModal
        title="Create Match"
        open={gameOpen}
        onClose={(selectedGame) => {
          setGameOpen(false);

          if (selectedGame) {
            setGame(selectedGame);

            // if (
            //   store?.user?.info?.last_agreed_tos_version !== store?.tosVersion
            // ) {
            //   return setTosOpen(true);
            // }

            if (
              game === "fortnite" &&
              store?.user?.anticheat?.must_run_ac &&
              !isWithinOneMinute(
                store?.user?.anticheat?.last_heartbeat_timestamp
              )
            ) {
              setDownloadOpen(true);
              return;
            }

            setCreateOpen(true);
          }
        }}
      />
      <CreateMatch
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        game={game}
      />
      <UpdateTOSModal open={tosOpen} onClose={() => setTosOpen(false)} />
      {/* <DownloadACModal
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
      /> */}
      <PurchaseUnbanModal
        open={unbanOpen}
        onClose={() => setUnbanOpen(false)}
      />
      <PurchaseChatUnbanModal
        open={chatUnbanOpen}
        onClose={() => setChatUnbanOpen(false)}
      />

      <NewCustomMenu
        title="Menu"
        anchor={anchor}
        handleClose={onClose}
        verticalOrigin="bottom"
        horizontalOrigin="right"
        backgroundColor={isDesktop ? cardLight : card}
        stickyHeader
        padding={isDesktop ? true : false}
      >
        {isDesktop ? (
          <Grid
            container
            justifyContent="start"
            alignItems="start"
            gap={{ xs: 1.5 }}
            wrap="nowrap"
            sx={{ position: "relative" }}
          >
            <Grid item sx={{ ...styles.container, width: 360, maxWidth: 360 }}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 2 }}
              >
                {store?.user &&
                  store?.user?.account_type >= AccountTypeEnum.MODERATOR && (
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 0.5 }}
                      >
                        <Grid item>
                          <Typography sx={styles.header}>Staff</Typography>
                        </Grid>

                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            direction="column"
                            alignItems="start"
                            justifyContent="center"
                          >
                            <MainMenuListItem
                              title="Disputes"
                              description="See matches where both users have submitted a win."
                              type="disputes"
                              onClick={() => {
                                navigate("/staff/disputes");
                                handleClose();
                              }}
                            />
                            {store?.user?.account_type >=
                              AccountTypeEnum.ADMIN && (
                              <MainMenuListItem
                                title="Search"
                                description="Find information about a specific player."
                                type="search"
                                onClick={() => {
                                  navigate("/staff/search");
                                  handleClose();
                                }}
                              />
                            )}
                            {(store?.user?.account_type ===
                              AccountTypeEnum.ANTICHEAT ||
                              store?.user?.account_type ===
                                AccountTypeEnum.GOD) && (
                              <MainMenuListItem
                                title="AC Dashboard"
                                description="Anti-cheat dashboard."
                                type="AC"
                                onClick={() => {
                                  navigate("/staff/ac/users");
                                  handleClose();
                                }}
                              />
                            )}
                            {store?.user?.account_type >=
                              AccountTypeEnum.GOD && (
                              <>
                                <MainMenuListItem
                                  title="Withdrawals"
                                  description="Withdrawals from users waiting to be sent."
                                  type="withdrawals"
                                  onClick={() => {
                                    navigate("/staff/withdrawals");
                                    handleClose();
                                  }}
                                />
                                <MainMenuListItem
                                  title="Profit Stats"
                                  description="All of the stats about the profitability of the business."
                                  type="profit"
                                  onClick={() => {
                                    navigate("/staff/stats");
                                    handleClose();
                                  }}
                                />
                                <MainMenuListItem
                                  title="Expense Tracking"
                                  description="Tracking all of the expenses of the business."
                                  type="expense"
                                  onClick={() => {
                                    navigate("/staff/expenses");
                                    handleClose();
                                  }}
                                />
                                <MainMenuListItem
                                  title="Chargebacks"
                                  description="All of the current ongoing chargeback cases through PayPal."
                                  type="chargeback"
                                  onClick={() => {
                                    navigate("/staff/chargebacks");
                                    handleClose();
                                  }}
                                />
                                <MainMenuListItem
                                  title="Security Dashboard"
                                  description="#RIP's super secret dashboard."
                                  type="security"
                                  onClick={() => {
                                    navigate("/staff/security/userstocheck");
                                    handleClose();
                                  }}
                                />
                                <MainMenuListItem
                                  title="Referrals"
                                  description="Stats on Rivox Referral Codes."
                                  type="referrals"
                                  onClick={() => {
                                    navigate("/staff/referrals/dashboard");
                                    handleClose();
                                  }}
                                />
                                {/* <MainMenuListItem
                                  title="Maps"
                                  description="Stats on Rivox Fortnite maps."
                                  type="maps"
                                  onClick={() => {
                                    navigate("/staff/maps/dashboard");
                                    handleClose();
                                  }}
                                /> */}
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 0.5 }}
                  >
                    <Grid item>
                      <Typography sx={styles.header}>Compete</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                      >
                        <MainMenuListItem
                          title="Matches"
                          description="Compete in your favorite tokens."
                          type="games"
                          onClick={() => {
                            navigate("/wagers/matches");
                            handleClose();
                          }}
                        />
                        <MainMenuListItem
                          title="Leaderboard"
                          description="Earn your way to the top of the leaderboard."
                          type="leaderboard"
                          onClick={() => {
                            navigate("/leaderboard");
                            handleClose();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Divider
                    sx={{ width: "100%", backgroundColor: cardExtraLight }}
                  />
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 0.5 }}
                  >
                    <Grid item>
                      <Typography sx={styles.header}>Shopping</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                      >
                        {store?.user?.behavior_info?.has_debt && (
                          <MainMenuListItem
                            title="Unban"
                            description="Re-gain access to Rivox by purchasing an unban."
                            type="unban"
                            onClick={() => {
                              setUnbanOpen(true);
                              handleClose();
                            }}
                          />
                        )}
                        {isDateGreaterThanCurrent(
                          new Date(
                            store?.user?.behavior_info?.match_chat_unban_timestamp
                          )
                        ) && (
                          <MainMenuListItem
                            title="Chat Unban"
                            description="Re-gain access to chatting in Rivox matches by purchasing a chat unban."
                            type="chat-unban"
                            onClick={() => {
                              setChatUnbanOpen(true);
                              handleClose();
                            }}
                          />
                        )}
                        {!store?.user?.premium_info?.active && store?.user && (
                          <MainMenuListItem
                            title="VIP"
                            description="Upgrade to VIP and compete on Rivox at the highest level."
                            type="premium"
                            onClick={() => {
                              setPremiumOpen(true);
                              handleClose();
                            }}
                          />
                        )}
                        <MainMenuListItem
                          title="Store"
                          description="Customize your look and shop our latest releases."
                          type="store"
                          onClick={() => {
                            navigate("/shop/avatars");
                            handleClose();
                          }}
                        />
                        {store?.user && (
                          <>
                            <MainMenuListItem
                              title="Snipes"
                              description="View your opponent before you play them."
                              type="snipes"
                              onClick={() => {
                                setSnipesOpen(true);
                                handleClose();
                              }}
                            />
                            <MainMenuListItem
                              title="Reset"
                              description="Start from 0 by resetting your stats."
                              type="reset"
                              onClick={() => {
                                setResetOpen(true);
                                handleClose();
                              }}
                            />
                            <MainMenuListItem
                              title="Earnings Reset"
                              description="Start from 0 by resetting your earnings."
                              type="reset_earnings"
                              onClick={() => {
                                setResetEarningsOpen(true);
                                onClose();
                              }}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Divider
                    sx={{ width: "100%", backgroundColor: cardExtraLight }}
                  />
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 0.5 }}
                  >
                    <Grid item>
                      <Typography sx={styles.header}>Support</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                      >
                        <MainMenuListItem
                          title="Privacy Policy"
                          description="Our policy on your privacy."
                          type="privacy"
                          onClick={() => {
                            navigate("/support/privacy");
                            handleClose();
                          }}
                        />
                        <MainMenuListItem
                          title="Terms"
                          description="Read our terms and conditions for using our platform."
                          type="terms"
                          onClick={() => {
                            navigate("/support/terms");
                            handleClose();
                          }}
                        />
                        <MainMenuListItem
                          title="FAQ"
                          description="Questions that users frequently ask us."
                          type="faq"
                          onClick={() => {
                            navigate("/support/faq");
                            handleClose();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sx={{
                ...styles.container,
                maxWidth: 200,
                width: 200,
                position: "sticky",
                top: 0,
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 1 }}
              >
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 0.5 }}
                  >
                    <Grid item>
                      <Typography
                        sx={{ fontSize: 18, fontWeight: 700, color: text }}
                      >
                        Create
                      </Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                      >
                        <NewListItem
                          title="Match"
                          icon={<RiSwordFill style={styles.icon} />}
                          onClick={() => {
                            if (!store?.user) {
                              navigate("/login");
                              handleClose();
                              return;
                            }

                            // setGameOpen(true);
                            navigate("/wagers/matches");
                            handleClose();
                          }}
                          backgroundHover={cardVeryLight}
                        />
                        <NewListItem
                          title="Team"
                          icon={<FaUsers style={styles.icon} />}
                          onClick={() => {
                            if (!store?.user) {
                              navigate("/login");
                              handleClose();
                              return;
                            }

                            navigate("/profile/teams", {
                              state: {
                                create: true,
                              },
                            });
                            handleClose();
                          }}
                          backgroundHover={cardVeryLight}
                        />
                        <NewListItem
                          title="Avatar"
                          icon={<FaUserSecret style={styles.icon} />}
                          onClick={() => {
                            if (!store?.user) {
                              navigate("/login");
                              handleClose();
                              return;
                            }

                            navigate("/locker");
                            handleClose();
                          }}
                          backgroundHover={cardVeryLight}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={styles.width}>
                  <Divider
                    sx={{ width: "100%", backgroundColor: cardExtraLight }}
                  />
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                  >
                    <NewListItem
                      title="Ticket"
                      icon={<FaDiscord style={styles.icon} />}
                      rightAddOn={
                        <FiExternalLink
                          style={{ fontSize: 20, color: lightGrey }}
                        />
                      }
                      onClick={() =>
                        window.open("https://discord.gg/rivox", "_blank")
                      }
                      backgroundHover={cardVeryLight}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            {store?.user &&
              store?.user?.account_type >= AccountTypeEnum.MODERATOR && (
                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 0.5 }}
                  >
                    <Grid item>
                      <Typography sx={styles.header}>Staff</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                      >
                        <MainMenuListItem
                          title="Disputes"
                          description="See matches where both users have submitted a win."
                          type="disputes"
                          onClick={() => {
                            navigate("/staff/disputes");
                            handleClose();
                          }}
                        />
                        {store?.user?.account_type >=
                          AccountTypeEnum.SENIOR_MODERATOR && (
                          <MainMenuListItem
                            title="Search"
                            description="Find information about a specific player."
                            type="search"
                            onClick={() => {
                              navigate("/staff/search");
                              handleClose();
                            }}
                          />
                        )}
                        {(store?.user?.account_type ===
                          AccountTypeEnum.ANTICHEAT ||
                          store?.user?.account_type ===
                            AccountTypeEnum.GOD) && (
                          <MainMenuListItem
                            title="AC Dashboard"
                            description="Anti-cheat dashboard."
                            type="AC"
                            onClick={() => {
                              navigate("/staff/ac/users");
                              handleClose();
                            }}
                          />
                        )}
                        {store?.user?.account_type >= AccountTypeEnum.GOD && (
                          <>
                            <MainMenuListItem
                              title="Withdrawals"
                              description="Withdrawals from users waiting to be sent."
                              type="withdrawals"
                              onClick={() => {
                                navigate("/staff/withdrawals");
                                handleClose();
                              }}
                            />
                            <MainMenuListItem
                              title="Profit Stats"
                              description="All of the stats about the profitability of the business."
                              type="profit"
                              onClick={() => {
                                navigate("/staff/stats");
                                handleClose();
                              }}
                            />
                            <MainMenuListItem
                              title="Expense Tracking"
                              description="Tracking all of the expenses of the business."
                              type="expense"
                              onClick={() => {
                                navigate("/staff/expenses");
                                handleClose();
                              }}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

            <Grid item sx={styles.width}>
              <Divider
                sx={{ width: "100%", backgroundColor: cardExtraLight }}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 0.5 }}
              >
                <Grid item>
                  <Typography sx={styles.header}>Compete</Typography>
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                  >
                    <MainMenuListItem
                      title="Home"
                      description="Navigate to the home page."
                      type="home"
                      onClick={() => {
                        navigate("/");
                        handleClose();
                      }}
                    />

                    <MainMenuListItem
                      title="Matches"
                      description="Compete in your favorite tokens."
                      type="games"
                      onClick={() => {
                        navigate("/wagers/matches");
                        handleClose();
                      }}
                    />
                    <MainMenuListItem
                      title="Leaderboard"
                      description="Earn your way to the top of the leaderboard."
                      type="leaderboard"
                      onClick={() => {
                        navigate("/leaderboard");
                        handleClose();
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Divider
                sx={{ width: "100%", backgroundColor: cardExtraLight }}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 0.5 }}
              >
                <Grid item>
                  <Typography sx={styles.header}>Shopping</Typography>
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                  >
                    {store?.user?.behavior_info?.has_debt && (
                      <MainMenuListItem
                        title="Unban"
                        description="Re-gain access to Rivox by purchasing an unban."
                        type="unban"
                        onClick={() => {
                          setUnbanOpen(true);
                          handleClose();
                        }}
                      />
                    )}
                    {isDateGreaterThanCurrent(
                      new Date(
                        store?.user?.behavior_info?.match_chat_unban_timestamp
                      )
                    ) && (
                      <MainMenuListItem
                        title="Chat Unban"
                        description="Re-gain access to chatting in Rivox matches by purchasing a chat unban."
                        type="chat-unban"
                        onClick={() => {
                          setChatUnbanOpen(true);
                          handleClose();
                        }}
                      />
                    )}
                    {!store?.user?.premium_info?.active && store?.user && (
                      <MainMenuListItem
                        title="VIP"
                        description="Upgrade to VIP and compete on Rivox at the highest level."
                        type="premium"
                        onClick={() => {
                          setPremiumOpen(true);
                          handleClose();
                        }}
                      />
                    )}
                    <MainMenuListItem
                      title="Store"
                      description="Customize your look and shop our latest releases."
                      type="store"
                      onClick={() => {
                        navigate("/shop/avatars");
                        handleClose();
                      }}
                    />
                    {store?.user && (
                      <>
                        <MainMenuListItem
                          title="Snipes"
                          description="View your opponent before you play them."
                          type="snipes"
                          onClick={() => {
                            setSnipesOpen(true);
                            handleClose();
                          }}
                        />
                        <MainMenuListItem
                          title="Reset"
                          description="Start from 0 by resetting your stats."
                          type="reset"
                          onClick={() => {
                            setResetOpen(true);
                            handleClose();
                          }}
                        />
                        <MainMenuListItem
                          title="Earnings Reset"
                          description="Start from 0 by resetting your earnings."
                          type="reset_earnings"
                          onClick={() => {
                            setResetEarningsOpen(true);
                            onClose();
                          }}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Divider
                sx={{ width: "100%", backgroundColor: cardExtraLight }}
              />
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 0.5 }}
              >
                <Grid item>
                  <Typography sx={styles.header}>Support</Typography>
                </Grid>

                <Grid item sx={styles.width}>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                  >
                    <MainMenuListItem
                      title="Privacy Policy"
                      description="Our policy on your privacy."
                      type="privacy"
                      onClick={() => {
                        navigate("/support/privacy");
                        handleClose();
                      }}
                    />
                    <MainMenuListItem
                      title="Terms"
                      description="Read our terms and conditions for using our platform."
                      type="terms"
                      onClick={() => {
                        navigate("/support/terms");
                        handleClose();
                      }}
                    />
                    <MainMenuListItem
                      title="FAQ"
                      description="Questions that users frequently ask us."
                      type="faq"
                      onClick={() => {
                        navigate("/support/faq");
                        handleClose();
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </NewCustomMenu>
    </>
  );
};

export default MainMenu;
