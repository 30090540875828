import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Piece from "../../avatar/Piece";
import { StoreContext } from "../../store/Store";
import {
  getAvatarBackground,
  getAvatarBorder,
  getAvatarRarity,
} from "../../utils/helpers";
import { normal, text } from "../../utils/themeContstants";

const ShopItemSimple = ({ item, isCrateObject, isGodlike }) => {
  const store = useContext(StoreContext);
  const isDesktop = useMediaQuery("(min-width:1025px)");

  const [owned, setOwned] = useState(false);

  useEffect(() => {
    if (store?.user) {
      if (
        store?.user?.new_locker?.options[item?.category]?.includes(item?._id) && !isCrateObject
      ) {
        setOwned(true);
        return;
      }
    }

    setOwned(false);
    return;
  }, [store?.user]);

  const styles = {
    width: {
      width: "100%",
    },
    titleContainer: {
      width: "100%",
      padding: 1,
      backgroundColor: getAvatarBackground(item?.rarity, owned),
    },
    pieceContainer: {
      overflow: "hidden",
      borderRadius: 1.5,
      border: `3px solid ${getAvatarBorder(item?.rarity, owned)}`,
      boxSizing: "border-box",
      background: getAvatarBackground(item?.rarity, owned),
      position: "relative",
      width: isDesktop ? null : "100%",
    },
  };

  return (
    <Grid item sx={styles.pieceContainer}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent={isDesktop ? "start" : "center"}
      >
        {/* <Grid item sx={styles.titleContainer}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
          >
            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 1 }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: 11,
                      fontWeight: 800,
                      color: getAvatarBorder(item?.rarity, owned),
                    }}
                  >
                    {getAvatarRarity(item?.rarity)}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography sx={{ color: "rgba(255, 255, 255, 0.3)" }}>
                    |
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: "rgba(255, 255, 255, 0.5)",
                      fontWeight: 700,
                    }}
                  >
                    {item?.category?.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Typography
                sx={{
                  fontSize: normal,
                  color: text,
                  fontWeight: 600,
                }}
              >
                {item?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}

        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Piece
            type={item?.category}
            color={
              store?.user?.new_avatar?.options[`${item?.category}Color`] ||
              "demon"
            }
            option={
              item?.current_variant ? item?.current_variant?.value : item?.value
            }
            skinColor={store?.user?.new_avatar?.skinColor || "tanned_skin"}
            size={!isDesktop && isCrateObject && !isGodlike ? 50 : 100}
            owned={owned}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShopItemSimple;
