import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getAvatarOptions } from "../../api/avatar";
import { toggleAvatarPiece } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import Piece from "../../avatar/Piece";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import LockerGlimmer from "../glimmers/LockerGlimmer";
import PiecePreview from "../locker/PiecePreview";

const CategoryOptions = ({ user, selected, setUser }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(null);
  const [pieceLoading, setPieceLoading] = useState(null);

  const handleGetOptions = () => {
    getAvatarOptions(middleware, selected).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setOptions(res?.options);
      }
    });
  };

  const handleTogglePiece = (owned, piece) => {
    toggleAvatarPiece(
      middleware,
      owned ? "remove" : "add",
      piece?._id,
      user?._id
    ).then((res) => {
      setPieceLoading(null);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: res?.message });
        setUser(res?.user);
      }
    });
  };

  useEffect(() => {
    if (selected) {
      setOptions(null);
      setLoading(true);
      setPieceLoading(null);
      handleGetOptions();
    }
  }, [selected]);

  const styles = {
    container: {
      width: "100%",
      overflow: "hidden",
      paddingTop: 2,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        gap={{ xs: 1 }}
      >
        {loading && <LockerGlimmer numItems={20} size={100} />}

        {!loading && options?.length > 0 && (
          <>
            {options?.map((option, i) => {
              if (option?.value == null) {
                return null;
              }

              return (
                <PiecePreview
                  key={i}
                  option={option}
                  value={option?.value}
                  selected={
                    option?.value === user?.new_avatar?.options[selected]?.value
                  }
                  owned={
                    user?.new_locker?.options[`${selected}`]?.findIndex(
                      (opt) => opt === option?._id
                    ) !== -1
                  }
                  size={100}
                  staff={true}
                  onClick={() => {
                    if (pieceLoading) return;

                    setPieceLoading(option?.value);
                    handleTogglePiece(
                      user?.new_locker?.options[`${selected}`]?.findIndex(
                        (opt) => opt === option?._id
                      ) !== -1,
                      option
                    );
                  }}
                  loading={pieceLoading === option?.value}
                  disabled={pieceLoading}
                >
                  <Piece
                    type={selected}
                    color={user?.new_avatar?.options[`${selected}Color`]}
                    option={option?.value}
                    skinColor={user?.new_avatar?.options?.skinColor}
                    size={100}
                  />
                </PiecePreview>
              );
            })}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CategoryOptions;
