import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AiOutlineFlag } from "react-icons/ai";
import { BiGlobe } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";
import { HiDesktopComputer } from "react-icons/hi";
import { IoMdAlert } from "react-icons/io";
import {
  IoClose,
  IoGameControllerOutline,
  IoPodiumOutline,
} from "react-icons/io5";
import { RiTeamLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { createMatch } from "../../api/matches";
import useMiddleware from "../../api/useMiddleware";
import { getUserTeamsDetailed } from "../../api/user";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import {
  getGameHeader,
  getGameName,
  getPlatformString,
  getRegionString,
  getRegionStringFromArray,
  getTeamSizeString,
  getMatchTitle,
  hexToRgbA,
  numFormatter,
  getFee,
} from "../../utils/helpers";
import { getTeamOptions } from "../../utils/options";
import {
  backgroundOpacity,
  card,
  cardDark,
  cardLight,
  detailIcon,
  doubtedBlue,
  doubtedOrange,
  emptyIcon,
  large,
  linearGradientToTop,
  normal,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import ACCoin from "../custom/ACCoin";
import CoinBadge from "../custom/CoinBadge";
import CustomCheckbox from "../custom/CustomCheckbox";
import CustomDropdown from "../custom/CustomDropdown";
import CustomIconButton from "../custom/CustomIconButton";
import DetailContainer from "../custom/DetailContainer";
import Empty from "../custom/Empty";
import GameBadge from "../custom/GameBadge";
import ScrollableContainer from "../custom/ScrollableContainer";
import SecondaryButton from "../custom/SecondaryButton";
import DialogGlimmer from "../glimmers/DialogGlimmer";
import TeamProfileModal from "../teams/TeamProfileModal";

const RecreateMatchModal = ({ currentMatch, open, onClose }) => {
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const middleware = useMiddleware();
  const navigate = useNavigate();
  const isTablet = useMediaQuery("(max-width: 768px)");
  const isDesktop = useMediaQuery("(min-width:1025px)");

  const [createLoading, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState(null);
  const [teamOptions, setTeamOptions] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [team, setTeam] = useState(null);
  const [puttingUp, setPuttingUp] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);

  const handleClose = () => {
    setLoading(true);
    setTeams(null);
    setTeamOptions(null);
    setSelectedTeam(null);
    setTeam(null);
    setPuttingUp(false);
    setViewOpen(false);
    onClose();
  };

  const handleGetTeams = () => {
    getUserTeamsDetailed(middleware, currentMatch?.team_size).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        handleClose();
      } else {
        setTeams(res?.teams);
      }
    });
  };

  const handleCreateMatch = () => {
    setCreateLoading(true);
    createMatch(
      middleware,
      currentMatch?.entry_fee,
      currentMatch?.region,
      currentMatch?.game_match_type,
      selectedTeam,
      currentMatch?.first_to,
      currentMatch?.public,
      "token",
      puttingUp ? true : null,
      currentMatch?.team_size,
      currentMatch?.platform,
      currentMatch?.best_of,
      currentMatch?.game,
      currentMatch?.game_map_type,
      currentMatch?.extra_options,
      currentMatch?.game_mode_options?.options
    ).then((res) => {
      setCreateLoading(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
        handleClose();
      } else {
        handleClose();
        navigate(`/token/${res?.match?._id}`);
      }
    });
  };

  const isCreateDisabled = () => {
    return !selectedTeam;
  };

  useEffect(() => {
    if (open && currentMatch) {
      handleGetTeams();
    }
  }, [currentMatch, open]);

  useEffect(() => {
    if (teams && teams?.length > 0) {
      setTeamOptions(getTeamOptions(teams));
    }
  }, [teams]);

  const styles = {
    width: {
      width: "100%",
    },
    top: {
      width: "100%",
      backgroundColor: card,
      position: "relative",
      // backgroundImage: `url(${getGameHeader(currentMatch?.game)})`,
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 2,
    },
    paper: {
      backgroundColor: card,
      minWidth: isDesktop ? 450 : isTablet ? "100%" : "97%",
      maxWidth: 800,
      borderRadius: 12,
      boxShadow: "0 1px 5px 1px rgb(0 0 0 / 40%)",
      height: isTablet ? "100%" : null,
    },
    closeButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      right: 10,
      top: 10,
      zIndex: 10,
    },
    title: {
      color: text,
      textAlign: "left",
      fontSize: 28,
      fontWeight: 800,
    },
    label: {
      fontSize: large,
      color: text,
      fontWeight: 700,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    divider: {
      width: "100%",
      backgroundColor: text,
      opacity: 0.1,
    },
  };

  return (
    <>
      <TeamProfileModal
        viewOnly={true}
        open={viewOpen}
        onClose={() => setViewOpen(false)}
        team={team}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: styles.paper }}
        BackdropProps={{ style: { background: "rgba(16, 18, 27, 0.8)" } }}
      >
        {loading && (
          <Grid item sx={{ padding: 2, width: "100%" }}>
            <DialogGlimmer />
          </Grid>
        )}

        {!loading && (
          <>
            <DialogTitle sx={styles.top}>
              {handleClose && (
                <Grid item sx={styles.closeButton}>
                  <CustomIconButton
                    onClick={handleClose}
                    icon={<IoClose style={{ color: text, fontSize: 20 }} />}
                    opacity
                  />
                </Grid>
              )}

              <div
                style={{
                  backgroundColor: hexToRgbA(card, 0.6),
                  position: "absolute",
                  width: "100%",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  zIndex: 0,
                }}
              />

              <div
                style={{
                  background: linearGradientToTop(
                    hexToRgbA(card, 1),
                    hexToRgbA(card, 0)
                  ),
                  position: "absolute",
                  width: "100%",
                  top: 0,
                  bottom: -1,
                  right: 0,
                  left: 0,
                  zIndex: 0,
                }}
              />

              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ zIndex: 2 }}
              >
                <Grid item sx={{ marginBottom: 1 }}>
                  <Typography sx={styles.title}>{`Recreate ${getMatchTitle(
                    currentMatch?.game_match_type,
                    currentMatch?.team_size,
                    currentMatch?.game_map_type,
                    currentMatch?.extra_options,
                    currentMatch?.game_mode_options,
                    true
                  )}`}</Typography>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                  >
                    <CoinBadge
                      amount={numFormatter().format(currentMatch?.entry_fee)}
                      label="Fee to compete in this match"
                      placement="bottom"
                      insideLabel="Entry"
                    />

                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FiChevronRight
                        style={{ fontSize: 25, color: secondaryText }}
                      />
                    </Grid>

                    <CoinBadge
                      amount={numFormatter().format(
                        parseFloat(
                          currentMatch?.entry_fee * getFee(currentMatch?.game) +
                            currentMatch?.entry_fee
                        )
                      )}
                      label="The total amount you will receive if you win this match"
                      type="prize"
                      placement="bottom"
                      insideLabel="Prize"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent sx={{ padding: 0, width: "100%" }}>
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
                gap={{ xs: 2 }}
              >
                <Grid
                  item
                  sx={{ width: "100%", paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 1 }}
                      >
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IoMdAlert
                            style={{ fontSize: 22, color: doubtedOrange }}
                          />
                        </Grid>

                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: normal,
                              fontWeight: 500,
                              color: text,
                              maxWidth: 450,
                            }}
                          >
                            You are about to post an open match with the same
                            settings as the match that was just played.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={{ width: "100%" }}>
                      <Divider sx={styles.divider} />
                    </Grid>

                    <Grid item>
                      <Typography sx={styles.label}>Details</Typography>
                    </Grid>

                    <Grid item sx={styles.width}>
                      {!isTablet && (
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="stretch"
                          gap={{ xs: 1 }}
                        >
                          <DetailContainer
                            label="Game"
                            value={getGameName(currentMatch?.game)}
                            // icon={<GameBadge game={currentMatch?.game} />}
                            bg={cardLight}
                          />

                          {currentMatch?.game !== "clashroyale" && (
                            <DetailContainer
                              label="Platform"
                              value={getPlatformString(currentMatch?.platform)}
                              icon={
                                <IoGameControllerOutline style={detailIcon} />
                              }
                              bg={cardLight}
                            />
                          )}

                          {currentMatch?.region && (
                            <>
                              {currentMatch?.region?.length > 0 ? (
                                <DetailContainer
                                  label="Region"
                                  value={getRegionStringFromArray(
                                    currentMatch?.region
                                  )}
                                  icon={<BiGlobe style={detailIcon} />}
                                  bg={cardLight}
                                />
                              ) : (
                                <DetailContainer
                                  label="Region"
                                  value={getRegionString(currentMatch?.region)}
                                  icon={<BiGlobe style={detailIcon} />}
                                  bg={cardLight}
                                />
                              )}
                            </>
                          )}

                          <DetailContainer
                            label="Team Size"
                            value={getTeamSizeString(currentMatch?.team_size)}
                            icon={<RiTeamLine style={detailIcon} />}
                            bg={cardLight}
                          />

                          {!currentMatch?.best_of && (
                            <DetailContainer
                              label="First To"
                              value={currentMatch?.first_to}
                              firstTo={true}
                              icon={<AiOutlineFlag style={detailIcon} />}
                              bg={cardLight}
                            />
                          )}

                          {currentMatch?.best_of && (
                            <DetailContainer
                              label="Best Of"
                              value={currentMatch?.best_of}
                              icon={<IoPodiumOutline style={detailIcon} />}
                              bg={cardLight}
                            />
                          )}

                          {currentMatch?.extra_options?.must_stream &&
                            currentMatch?.platform == null && (
                              <DetailContainer
                                label="PC Settings"
                                value={"Must Stream"}
                                icon={<HiDesktopComputer style={detailIcon} />}
                                bg={cardLight}
                              />
                            )}

                          {currentMatch?.ac_verified && (
                            <DetailContainer
                              label="Anti-Cheat"
                              value="Verified"
                              icon={<ACCoin size={32} />}
                              bg={cardLight}
                            />
                          )}
                        </Grid>
                      )}

                      {isTablet && (
                        <ScrollableContainer gap={1}>
                          <DetailContainer
                            label="Game"
                            value={getGameName(currentMatch?.game)}
                            // icon={<GameBadge game={currentMatch?.game} />}
                            bg={cardLight}
                          />

                          {currentMatch?.game === "fortnite" && (
                            <DetailContainer
                              label="Platform"
                              value={getPlatformString(currentMatch?.platform)}
                              icon={
                                <IoGameControllerOutline style={detailIcon} />
                              }
                              bg={cardLight}
                            />
                          )}

                          {currentMatch?.region && (
                            <DetailContainer
                              label="Region"
                              value={getRegionString(currentMatch?.region)}
                              icon={<BiGlobe style={detailIcon} />}
                              bg={cardLight}
                            />
                          )}

                          <DetailContainer
                            label="Team Size"
                            value={getTeamSizeString(currentMatch?.team_size)}
                            icon={<RiTeamLine style={detailIcon} />}
                            bg={cardLight}
                          />

                          {!currentMatch?.best_of && (
                            <DetailContainer
                              label="First To"
                              value={currentMatch?.first_to}
                              firstTo={true}
                              icon={<AiOutlineFlag style={detailIcon} />}
                              bg={cardLight}
                            />
                          )}

                          {currentMatch?.best_of && (
                            <DetailContainer
                              label="Best Of"
                              value={currentMatch?.best_of}
                              icon={<IoPodiumOutline style={detailIcon} />}
                              bg={cardLight}
                            />
                          )}

                          {currentMatch?.extra_options?.must_stream &&
                            currentMatch?.platform == null && (
                              <DetailContainer
                                label="PC Settings"
                                value={"Must Stream"}
                                icon={<HiDesktopComputer style={detailIcon} />}
                                bg={cardLight}
                              />
                            )}

                          {currentMatch?.ac_verified && (
                            <DetailContainer
                              label="Anti-Cheat"
                              value="Verified"
                              icon={<ACCoin size={32} />}
                              bg={cardLight}
                            />
                          )}
                        </ScrollableContainer>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{ width: "100%", paddingLeft: 2, paddingRight: 2 }}
                >
                  <Divider sx={styles.divider} />
                </Grid>

                <Grid
                  item
                  sx={{ width: "100%", paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    gap={{ xs: 1 }}
                  >
                    <Grid item>
                      <Typography sx={styles.label}>Team Select</Typography>
                    </Grid>

                    {teams?.length < 1 && (
                      <>
                        <Grid item sx={styles.width}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Empty
                              title={`You have no ${currentMatch?.team_size}v${currentMatch?.team_size} teams`}
                              label="Try creating a team."
                              icon={<RiTeamLine style={emptyIcon} />}
                            />

                            <Grid item alignSelf="center">
                              <SecondaryButton
                                label="Create Team"
                                onClick={() =>
                                  navigate("/profile/teams", {
                                    state: {
                                      create: true,
                                    },
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {teams?.length > 0 && (
                      <Grid item sx={styles.width}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          gap={{ xs: 1 }}
                        >
                          {teamOptions && teamOptions?.length > 0 && (
                            <Grid item sx={{ flexGrow: 1 }}>
                              <CustomDropdown
                                options={teamOptions}
                                placeholder="Teams"
                                onChange={(value) => {
                                  setSelectedTeam(value?._id);
                                  setTeam(value);
                                }}
                                backgroundColor={cardDark}
                                backgroundHover={cardDark}
                              />
                            </Grid>
                          )}

                          {team && (
                            <Grid item>
                              <SecondaryButton
                                label="View Team"
                                onClick={() => setViewOpen(true)}
                                fullWidth
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {teams?.length > 0 && (
                  <>
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                    >
                      <Divider sx={styles.divider} />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        width: "100%",
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 1 }}
                      >
                        {currentMatch?.team_size > 1 && (
                          <Grid item sx={styles.width}>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              wrap="nowrap"
                              gap={{ xs: 1 }}
                            >
                              <Grid item>
                                <CustomCheckbox
                                  checked={puttingUp}
                                  onChange={(value) => setPuttingUp(value)}
                                  color={doubtedBlue}
                                  checkColor={"#fff"}
                                />
                              </Grid>

                              <Grid item>
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    color: text,
                                    fontWeight: 400,
                                    maxWidth: 400,
                                  }}
                                >
                                  Cover Entry Fee for Teammates
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>

            <DialogActions sx={{ padding: 2, marginTop: "auto" }}>
              <Grid item sx={styles.width}>
                <Grid
                  container
                  justifyContent="end"
                  alignItems="center"
                  gap={{ xs: 2 }}
                >
                  <Grid item>
                    <SecondaryButton label="Close" onClick={handleClose} />
                  </Grid>

                  <Grid item>
                    <SecondaryButton
                      label="Create Match"
                      bg={doubtedBlue}
                      disabled={isCreateDisabled()}
                      onClick={handleCreateMatch}
                      loading={createLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default RecreateMatchModal;
