import "../styles/AnimeScar.css";

const AnimeScar = () => {
  return (
    <g>
      <path
        class="anime_scar-1"
        d="m666,362l-1-20c-2.37-.65-5.16-2.02-9-5-4.66-3.62-7.08-7.83-8.22-10.17,3.04-1.47,9.18-3.37,12.22-4.83l-2-6-30,2c-.22-.05-4.76-1.06-6-5-.53-1.7-.23-3.22,0-4-4-.67-8-1.33-12-2-.13-1.55-.33-6.55,3-11,3.71-4.95,15.33-17.26,22-16-10-8-19-13.33-30-19l-17,23h-4l-2-5-10,15,11,2c.64,2.08,2.01,7.55,0,14-2.46,7.92-8.33,11.94-10,13h-8c-.19,1.25-.5,2.59-1,4-2.34,6.55-7.35,10.32-10,12,2.67-1.33,5.33-2.67,8-4,.43.08,1.28.29,2,1,1.57,1.53,1.04,3.85,1,4-3.33,4-6.67,8-10,12l15-11,6,1-3,9c2.16-4.05,6.15-10.29,13-16,8.52-7.1,17.23-9.86,22-11,.89.22,2.45.74,4,2,1.78,1.45,2.63,3.13,3,4-4.33,3.33-8.67,6.67-13,10l13-5,7,1-5,7,17-10,10,2,2,4-8,1,11,2,5,6-6,1c2.25.79,5.59,2.26,9,5,3.55,2.85,7.74,5.92,9,8Z"
      />
    </g>
  );
};

export default AnimeScar;
