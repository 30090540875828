import "../styles/Cig.css";

const Cig = () => {
  return (
    <g>
      <g transform="translate(520, 470)">
        <g>
          <path
            className="cig-1"
            d="M13.22,6.34c20.61,24.83,41.22,49.65,61.84,74.48,1.31-.28,3.19-.84,5.18-2.07,2.87-1.77,4.51-4.03,5.31-5.34C65.41,49.13,45.26,24.85,25.12,.58c-1.61,.25-3.87,.79-6.35,1.98-2.51,1.21-4.34,2.66-5.55,3.78Z"
          />
          <path
            className="cig-3"
            d="M75.11,80.03c-.48-.6,1.71-2.63,1.87-2.77,1.44-1.32,3.01-1.92,4.42-2.44,1.35-.5,2.55-.78,3.42-.95-.53,1.03-1.44,2.47-3.04,3.73-2.55,2.02-6.15,3.08-6.66,2.42Z"
          />
          <path
            className="cig-2"
            d="M33.47,1.55c0-.25-8.28-1.72-17.31,1.49C6.4,6.5,.79,13.63,1.01,13.9c.22,.28,6.85-7.14,18.48-10.53,7.81-2.28,13.99-1.6,13.98-1.82Z"
          />
          <path
            className="cig-4"
            d="M31.05,7.72c-.53-.05-5.07-.43-8.09,2.96-1.61,1.81-2.03,3.84-2.16,4.79-2.53-3.04-5.05-6.09-7.58-9.13,1.21-1.11,3.05-2.57,5.55-3.78,2.48-1.2,4.73-1.73,6.35-1.98,1.98,2.38,3.95,4.76,5.93,7.15Z"
          />
        </g>
      </g>
    </g>
  );
};

export default Cig;
