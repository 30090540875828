const Lips = () => {
  return (
    <g transform="translate(502, 455)">
      <path d="M0,22.64c.5-.59,1.14-1.34,1.89-2.23,1.63,1.59,2.71,.49,4-.41,5.44-3.8,11.15-7.15,17.22-9.85,1.46-.65,2.91-1.31,4.4-1.88,2.42-.92,4.83-.97,7.2,.24,.57,.29,1.18,.5,1.75,.78,1.39,.69,2.55,.63,3.58-.72,1.28-1.67,2.95-1.89,4.93-1.24,4.51,1.47,8.36,3.9,11.59,7.35,.21,.23,.47,.41,.85,.75,1.37-1.82-1.05-3.92,.54-5.53,2.13,.8,3.3,3.77,2.05,5.85-.93,1.54-2.21,2.91-3.49,4.19-.98,.98-1.74,1.85-.99,3.28,.07,.14,.08,.31,.12,.46,1.26,5.8-.67,9.54-6.14,11.87-4.85,2.07-9.95,2.41-15.06,1.72-11.38-1.52-21.69-5.79-30.94-12.61-1.01-.75-2.19-1.27-3.5-2.01Zm4.54,.96c.08,.25,.08,.46,.18,.54,.49,.41,.98,.82,1.5,1.18,6.79,4.7,14.2,7.94,22.35,9.37,5.86,1.03,11.73,1.51,17.58-.04,2.41-.64,4.72-1.57,6.58-3.28,.66-.61,1.25-1.49,1.46-2.35,.65-2.6,.29-5.19-.48-7.75-.48-.13-.85-.28-1.24-.33-2.29-.27-4.59-.68-6.88-.71-1.37-.02-2.92,.28-4.1,.94-2.54,1.41-5.23,2.1-8.03,2.01-3.87-.12-7.63,.28-11.39,1.14-3.84,.88-7.75,.98-11.66,.36-1.95-.31-3.88-.71-5.87-1.07Zm51.96-7.47c-3.06-2.5-6.06-4.86-9.61-6.45-2.26-1.01-4.28-1.63-6.19,.71-1,1.22-2.44,1.35-3.94,.69-1.02-.45-2.09-.78-3.15-1.14-1.83-.63-3.6-.48-5.36,.32-6.39,2.93-12.83,5.76-18.9,9.34-.91,.54-1.78,1.16-2.74,1.8,.41,.32,.52,.46,.66,.51,.3,.1,.61,.16,.93,.22,4.81,.92,9.54,.4,14.25-.69,2.64-.61,5.29-1.27,7.97-1.62,3.79-.49,7.65-.4,11.36-1.52,.59-.18,1.27-.16,1.9-.11,2.55,.19,5.09,.43,7.64,.65,2.22,.19,4.16-.14,5.18-2.72Z" />
      <path d="M38.9,0c.4,.28,.84,.42,.97,.7,.49,1.08,.95,2.19,1.28,3.33,.36,1.28-.15,2.01-1.4,2.25-1.41,.28-4.41-.29-6.34-1.36,2.15-.21,3.91-.38,5.75-.55-.02-1.44-1.52-2.65-.25-4.38Z" />
      <path d="M56.49,16.13c-1.01,2.58-2.96,2.92-5.18,2.72-2.54-.22-5.09-.46-7.64-.65-.63-.05-1.31-.07-1.9,.11-3.71,1.12-7.57,1.03-11.36,1.52-2.68,.35-5.33,1.01-7.97,1.62-4.72,1.09-9.45,1.61-14.25,.69-.31-.06-.63-.12-.93-.22-.14-.05-.25-.18-.66-.51,.97-.64,1.83-1.26,2.74-1.8,6.07-3.58,12.51-6.41,18.9-9.34,1.76-.81,3.53-.95,5.36-.32,1.06,.36,2.13,.69,3.15,1.14,1.5,.66,2.95,.53,3.94-.69,1.91-2.34,3.93-1.73,6.19-.71,3.54,1.59,6.54,3.94,9.61,6.45Z" />
    </g>
  );
};

export default Lips;
