import { Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { BsDot } from "react-icons/bs";
import {
  deleteNotification,
  toggleNotification,
} from "../../api/notifications";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { getNotiTimestamp } from "../../utils/helpers";
import {
  arcteryx,
  arcteryxHover,
  card,
  cardLight,
  doubtedBlue,
  normal,
  purple,
  red,
  secondaryText,
  small,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";
import NotificationAvatar from "./NotificationAvatar";
import NotificationItemSettings from "./NotificationItemSettings";
import { NotificationTypeEnum } from "../../utils/enums";

const NotificationItem = ({
  noti,
  setNotifications,
  notifications,
  onClose,
}) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [anchor, setAnchor] = useState(null);
  const [notification, setNotification] = useState(noti);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteNoti = () => {
    setDeleteLoading(true);
    deleteNotification(middleware, noti?._id).then((res) => {
      setDeleteLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
        setAnchor(null);
      } else {
        const index = notifications.findIndex(
          (noti) => noti?._id === notification?._id
        );
        let newNotifications = notifications;
        newNotifications?.splice(index, 1);
        setNotifications(newNotifications);
        setNotification(null);
        setAnchor(null);
      }
    });
  };

  const handleToggleNoti = (value) => {
    let newNotification = notification;
    newNotification.read = value;
    let newNotifications = notifications;
    let index = newNotifications?.findIndex(
      (storeNoti) => storeNoti?._id === notification?._id
    );
    newNotifications[index] = newNotification;
    setNotification(newNotification);
    setNotifications(newNotifications);
    setAnchor(null);
  };

  const handleToggleNotiRequest = (value) => {
    toggleNotification(middleware, value, notification?._id).then((res) => {
      if (res?.error) {
        handleToggleNoti(!value);
      }
    });
  };

  const handleToggleNotiHelper = (value) => {
    handleToggleNoti(value);
    handleToggleNotiRequest(value);
  };

  const handleSettingsOpen = (e) => {
    if (!notification?.read) {
      handleToggleNotiHelper(true);
    }
    e.stopPropagation();
    setAnchor(e.currentTarget);
  };

  const handleSettingsClose = (e) => {
    e?.stopPropagation();
    setAnchor(null);
  };

  const styles = {
    container: {
      width: "100%",
      padding: 1,
      paddingRight: 2,
      paddingLeft: 1,
      borderRadius: 1,
      transition: transition,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: cardLight,
      },
      position: "relative",
    },
    title: {
      fontSize: normal,
      fontWeight: notification?.read ? 600 : 800,
      color: notification?.read ? secondaryText : text,
    },
    body: {
      fontSize: normal,
      fontWeight: 400,
      color: notification?.read ? secondaryText : text,
    },
    time: {
      fontSize: small,
      fontWeight: notification?.read ? 400 : 600,
      color: notification?.read ? secondaryText : doubtedBlue,
    },
    unreadDot: {
      position: "absolute",
      margin: "auto",
      top: 0,
      bottom: 0,
      right: -10,
      fontSize: 50,
      color: doubtedBlue,
    },
  };

  return (
    <>
      <NotificationItemSettings
        anchor={anchor}
        handleClose={handleSettingsClose}
        noti={notification}
        handleToggleNoti={handleToggleNotiHelper}
        handleDeleteNoti={handleDeleteNoti}
        deleteLoading={deleteLoading}
        handleNotificationClose={onClose}
      />

      <Grid item sx={styles.container} onClick={handleSettingsOpen}>
        {!notification?.read && <BsDot style={styles.unreadDot} />}

        <Grid
          container
          justifyContent="start"
          alignItems="center"
          gap={{ xs: 1.5 }}
          wrap="nowrap"
        >
          <Grid item>
            <NotificationAvatar noti={notification} />
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="start"
              justifyContent="center"
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography sx={styles.title}>
                      {notification?.title}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography sx={styles.body}>
                      {notification?.body}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {notification?.timestamp && (
                <Grid item>
                  <Typography sx={styles.time}>
                    {getNotiTimestamp(new Date(notification?.timestamp))}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationItem;
