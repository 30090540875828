import { useContext, useEffect, useRef, useState } from "react";
import { getUserLeaderboard } from "../../api/leaderboard";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import { text } from "../../utils/themeContstants";
import usePagination from "../../utils/usePagination";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import SpacingWrapper from "../../utils/SpacingWrapper";
import { MdLeaderboard } from "react-icons/md";
import NotSelected from "../custom/NotSelected";
import LeaderboardItem from "./LeaderboardItem";
import LeaderboardGlimmer from "../glimmers/LeaderboardGlimmer";
import { AiOutlineTrophy } from "react-icons/ai";
import { CDN_URL } from "../../utils/constants";

const Leaderboard = () => {

  const leaderboardIcon = `${CDN_URL}3d_icons/leaderboard.png`;


  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const bottom = useRef(null);
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const handleGetLeaderboard = () => {
    getUserLeaderboard(skip).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        if (res?.users?.length >= 10) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setUsers(users ? [...users, ...res?.users] : res?.users);
      }
    });
  };

  usePagination(bottom, () => setSkip(users?.length));

  useEffect(() => {
    if (users == null) {
      handleGetLeaderboard();
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      handleGetLeaderboard();
    }
  }, [skip]);

  const styles = {
    container: {
      width: "100%",
      minHeight: "100vh",
      // backgroundImage: `url(${background})`,
      // backgroundAttachment: "fixed",
      // backgroundSize: "cover",
    },
    width: {
      width: "100%",
    },
    header: {
      fontSize: 32,
      color: text,
      fontWeight: 700,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <SpacingWrapper>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: isDesktop ? 4 : 2 }}
            sx={{ paddingBottom: 8, maxWidth: 1200 }}
          >
            <Grid item>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 1 }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={leaderboardIcon}
                    draggable={false}
                    height={42}
                    width={42}
                  />
                </Grid>

                <Grid item>
                  <Typography sx={styles.header}>Leaderboard</Typography>
                </Grid>
              </Grid>
            </Grid>

            {loading && <LeaderboardGlimmer numItems={10} />}

            {!loading && (
              <Grid item sx={styles.width}>
                <Grid
                  container
                  direction="column"
                  alignItems="start"
                  justifyContent="center"
                  gap={{ xs: 1 }}
                >
                  {(!users || users?.length < 1) && (
                    <Grid item alignSelf="center">
                      <NotSelected label="NO EARNINGS YET" />
                    </Grid>
                  )}

                  {users && users?.length > 0 && (
                    <Grid item sx={styles.width}>
                      <Grid
                        container
                        direction="column"
                        alignItems="start"
                        justifyContent="center"
                        gap={{ xs: 1 }}
                      >
                        {users?.map((user, i) => (
                          <LeaderboardItem user={user} key={i} i={i} />
                        ))}
                        {hasMore && (
                          <div style={{ width: "100%" }} ref={bottom}>
                            <LeaderboardGlimmer numItems={1} />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </SpacingWrapper>
      </Grid>
    </Grid>
  );
};

export default Leaderboard;
