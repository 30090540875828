const Meh = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
  };

  return (
    <g transform="translate(495, 455)">
      <path d="M81.46,.24c-.47,.48-.9,1.02-1.42,1.44-4.18,3.44-8.11,7.19-12.68,10.17-1.04,.68-1.78,2-2.35,3.17-.94,1.93-1.68,3.96-2.42,5.99-.76,2.08-2.12,3.53-4.18,4.33-7.68,2.98-15.55,4.49-23.86,3.94-9.07-.6-17.59-2.94-25.72-6.89-.43-.21-.87-.41-1.31-.59-.14-.06-.31-.07-.52-.11-2.44,1.03-3.83,3.88-7,4.14-.11-1.19,.68-1.88,1.3-2.62,2.56-3.08,5.75-5.28,9.48-6.7,4.99-1.9,10.1-2.82,15.48-2.45,9.72,.67,19.48,1.22,29.15-.48,5.69-1,11.08-2.95,15.78-6.5,3.06-2.31,6.2-4.49,9.32-6.72,.23-.16,.52-.24,.78-.36,.06,.08,.11,.16,.17,.24Zm-17.42,13.66c-5.48,2.08-11.11,3.21-16.84,3.52-7.96,.44-15.95,.45-23.92,.67-1.35,.04-2.7,.22-4.05,.33,0,.16-.01,.32-.02,.48,.91,.16,1.82,.32,2.74,.49,9.57,1.79,19.22,1.73,28.86,.88,3.08-.27,6.11-1.06,9.14-1.73,.7-.15,1.43-.68,1.88-1.25,.84-1.05,1.48-2.25,2.21-3.4Zm-4.08,7.49c-.55-.14-.71-.24-.83-.2-6.47,1.81-13.13,1.7-19.73,1.67-7.61-.03-15.07-1.4-22.37-3.57-1.7-.5-3.27-.38-4.88,.66,.34,.21,.58,.42,.86,.53,6.48,2.54,13.08,4.5,20.1,5.03,6.92,.52,13.7-.05,20.44-1.44,1.48-.3,2.93-.76,4.34-1.28,.67-.25,1.23-.82,2.07-1.4Z" />
      <path d="M28.64,37.03c2.38,.59,4.76,1.22,7.16,1.76,1.16,.26,2.36,.49,3.54,.48,7.01-.03,13.55-1.72,19.49-5.55,1.58-1.02,3.26-1.86,5.09-2.89,.12,1.58-.64,2.64-1.39,3.58-1.59,2.01-3.5,3.62-5.9,4.74-5.78,2.7-11.88,3.29-18.01,2.31-3.31-.53-6.43-2.24-9.63-3.45-.25-.09-.41-.42-.62-.64l.26-.34Z" />
      <path
        style={styles.one}
        d="M64.04,13.91c-.72,1.14-1.37,2.35-2.21,3.4-.45,.57-1.19,1.09-1.88,1.25-3.03,.67-6.07,1.46-9.14,1.73-9.64,.85-19.29,.91-28.86-.88-.91-.17-1.82-.33-2.74-.49,0-.16,.01-.32,.02-.48,1.35-.11,2.7-.29,4.05-.33,7.97-.22,15.96-.24,23.92-.67,5.73-.32,11.36-1.45,16.84-3.52Z"
      />
      <path d="M59.96,21.4c-.84,.58-1.39,1.15-2.07,1.4-1.41,.52-2.87,.98-4.34,1.28-6.75,1.39-13.52,1.96-20.44,1.44-7.03-.53-13.62-2.49-20.1-5.03-.28-.11-.52-.32-.86-.53,1.61-1.03,3.19-1.16,4.88-.66,7.3,2.17,14.77,3.53,22.37,3.57,6.6,.03,13.26,.14,19.73-1.67,.12-.03,.28,.06,.83,.2Z" />
    </g>
  );
};

export default Meh;
