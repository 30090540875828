const OnPause = () => {
  const styles = {
    one: {
      fill: "#526370",
    },
    two: {
      fill: "none",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    three: {
      fill: "#395c7c",
    },
    four: {
      fill: "#6c7e8e",
    },
    five: {
      fill: "#e0dfea",
    },
    six: {
      fill: "#bcbcbc",
    },
    seven: {
      fill: "#e0e1e2",
    },
    eight: {
      fill: "#1d3650",
    },
    nine: {
      fill: "#bebbcd",
    },
    ten: {
      fill: "#2d4e6b",
    },
  };

  return (
    <g>
      <path
        style={styles.four}
        d="M423.7,624.34c2.7-.63,10.54-2.07,19.3,1.66,7.52,3.2,11.71,8.5,13.36,10.85,4.8,11.21,9.6,22.41,14.39,33.62,.64,2.19,3.08,11.46-1.75,21.53-4.92,10.27-13.93,14.15-16,14.98-9.77-27.54-19.53-55.09-29.3-82.63Z"
      />
      <path
        style={styles.four}
        d="M536,625c-2.83-.54-11.81-1.88-21.36,3-8.03,4.1-12.11,10.36-13.64,13-4.7,10.49-9.41,20.98-14.11,31.47-.56,2.08-2.74,11.01,2.11,20.53,5.04,9.89,14.06,13.32,16,14,10.33-27.33,20.67-54.67,31-82Z"
      />
      <path
        style={styles.nine}
        d="M392.92,648.43c.98-2.43,7.13-16.97,22.08-21.43,5.08-1.52,9.67-1.42,13-1,2.76,.34,7.23,1.25,12,4,6.16,3.55,9.59,8.3,11.16,10.82,4.63,11.47,9.26,22.95,13.9,34.42,1.66,12.44-3.31,24.7-13.06,31.76-12.12,8.77-25.83,5.32-27,5-10.43-2.82-16.12-10.16-17.76-12.44l-15.11-36.34c-.18-2.12-.26-4.54-.13-7.22,.14-2.84,.5-5.38,.92-7.57Z"
      />
      <path
        style={styles.nine}
        d="M565,647c-1.22-2.39-8.05-15.15-21.7-19-6.34-1.79-12.2-1.1-13-1-2.76,.34-7.23,1.25-12,4-6.16,3.55-9.59,8.3-11.16,10.82-2.45,7.28-5.12,12.61-7.15,16.18-1.75,3.08-4.48,7.42-6,14-.41,1.78-.63,3.26-.75,4.24-.52,2.84-2.2,14.01,4.75,23.76,8.25,11.56,23.66,14.09,35,11,9.02-2.46,14.64-8.24,17-11,5.39-11.93,10.78-23.85,16.18-35.78,.32-1.09,.65-2.52,.82-4.22,.06-.59,.24-2.49,0-5-.18-1.9-.65-4.73-2-8Z"
      />
      <g>
        <path
          style={styles.eight}
          d="M513,558c1,0-1,9-1,9,4.88,1.68,15.7,6.15,25,17,9.33,10.89,12.08,22.3,12.99,27.38,2.67,.87,5.34,1.75,8.01,2.62-.51-6.24-2.27-16.93-9-28-11.75-19.32-30.34-26.16-36-28Z"
        />
        <path
          style={styles.ten}
          d="M515.28,568.85s0,0,0-.01c.12-.12,11.33,8.1,18.72,18.16,9.61,13.08,13.19,29.82,13.19,29.83l2.57-6.66c-1.19-5.62-4.83-19.02-16.76-30.16-6.42-6-13.05-9.31-17.72-11.15Z"
        />
      </g>
      <path
        style={styles.eight}
        d="M453,556s-2,8-2,8h0c-4.88,1.68-15.7,6.15-25,17-9.33,10.89-12.08,22.3-12.99,27.38-2.67,.87-5.34,1.75-8.01,2.62,.41-6.08,2.03-16.94,9-28,12.85-20.38,34.04-25.86,39-27Z"
      />
      <path
        style={styles.ten}
        d="M447.72,565.85s0,0,0-.01c-.12-.12-11.33,8.1-18.72,18.16-9.61,13.08-13.19,29.82-13.19,29.83l-2.57-6.66c1.19-5.62,4.83-19.02,16.76-30.16,6.42-6,13.05-9.31,17.72-11.15Z"
      />
      <rect
        style={styles.six}
        x="407"
        y="614"
        width="9"
        height="8"
        rx="3.46"
        ry="3.46"
      />
      <path
        style={styles.six}
        d="M409.52,609.52s-4.52,1.35-4.52,2.41,1.79,4.62,1.79,4.62l3.19,6.59,.62,3.38,4.4,.47,3.91-3.46-1.91-3.54-2.91-6.89v-2.95c-.19-.22-.89-.97-2.09-1.16-1.29-.21-2.25,.38-2.48,.52Z"
      />
      <path
        style={styles.six}
        d="M551,611c0,1-2.1,2.39-2.1,2.39,0,0-1.95,6.17-2.42,6.89s-1.37,3.48-1.37,3.48l-1.89,2.39,.78,2.85,6.39,2.05,1.96-2.64v-2.48l3.21-6.54,1.9-2.06,1.02-3.33-7.48-2.99Z"
      />
      <path
        style={styles.five}
        d="M418.8,625.91c6.49,1.62,19.57,5.97,28.2,18.09,3.05,4.29,4.4,7.93,7,15,3.89,10.55,7.73,20.97,5,33-.79,3.47-2.44,8.5-6.32,14.07,2.62-2.47,6.49-6.74,9.32-13.07,3.98-8.88,3.75-16.94,3.37-20.85l-15.75-33.71c-1.72-2.36-6.7-8.5-15.62-11.44-6.58-2.17-12.29-1.59-15.2-1.09Z"
      />
      <path
        style={styles.five}
        d="M539.56,627.91c-6.49,1.62-19.57,5.97-28.2,18.09-3.05,4.29-4.4,7.93-7,15-3.89,10.55-7.73,20.97-5,33,.79,3.47,2.44,8.5,6.32,14.07-2.62-2.47-6.49-6.74-9.32-13.07-3.98-8.88-3.75-16.94-3.37-20.85,5.25-11.24,10.5-22.47,15.75-33.71,1.72-2.36,6.7-8.5,15.62-11.44,6.58-2.17,12.29-1.59,15.2-1.09Z"
      />
      <path
        style={styles.one}
        d="M425,625c2.42-.48,7.8-1.2,14,1,7.56,2.68,11.64,8.03,13,10,2.37,4.24,4.75,8.9,7,14,4.15,9.38,7,18.19,9,26,.84,3.27,.67,6.24,0,10-1.77,9.93-8.64,16.3-12,19,2.96-2.93,8.62-9.43,10-19,.68-4.73,.43-8.46-.63-12.28-1.89-6.84-4.8-15-9.37-23.72-2.9-5.54-5.98-10.35-8.92-14.43-1.3-1.81-3.29-3.66-6.08-5.57-6.02-4.12-12.33-4.89-16-5Z"
      />
      <path
        style={styles.one}
        d="M535,626c-3.12-.38-8.96-.63-15.43,2-6.94,2.82-10.89,7.46-13,10-2.2,2.64-2.84,4.13-7.57,14-8.02,16.74-9.74,19.89-10,26-.21,4.85,.63,8.54,1,10,2.61,10.19,9.39,16.43,12.57,19-2.96-2.93-8.62-9.43-10-19-.68-4.73-.43-8.46,.63-12.28,1.89-6.84,4.8-15,9.37-23.72,2.9-5.54,5.98-10.35,8.92-14.43,1.13-1.57,3.21-3.53,6.08-5.57,2.66-1.89,8.42-5.34,17.43-6Z"
      />
      <path
        style={styles.three}
        d="M408.55,610.16c.29-4.69,1.99-23.49,17.45-38.16,10.03-9.52,21.1-12.79,26.5-14,.17-.67,.33-1.33,.5-2-4.85,1.04-11.26,3.02-18,7-13.71,8.1-20.61,19.67-23.5,25.36-1.9,7.61-3.8,15.22-5.7,22.84l2.75-1.04Z"
      />
      <path
        style={styles.three}
        d="M555.31,614.16c-.39-5.32-2.4-23.36-17.08-38.16-9.35-9.42-19.67-13.35-25.23-15v-3c4.82,1.95,10.52,4.8,16.42,9,11.97,8.53,19.08,18.63,23,25.36,1.86,7.61,3.72,15.22,5.58,22.84l-2.69-1.04Z"
      />
      <path
        style={styles.seven}
        d="M407,611.2c0-.2,5.98,15.58,5.98,15.58h-2.07l-5.91-14.85,2-.74Z"
      />
      <path
        style={styles.seven}
        d="M413,609.42c0-.2,5.98,15.58,5.98,15.58h-2.07l-5.91-14.85,2-.74Z"
      />
      <path
        style={styles.seven}
        d="M557.8,613.69c.14-.16-6.82,16.04-6.82,16.04l-1.37-1.17,6.36-15.4,1.83,.53Z"
      />
      <path
        style={styles.seven}
        d="M551.8,611.69c.14-.16-6.82,16.04-6.82,16.04l-1.37-1.17,6.36-15.4,1.83,.53Z"
      />
      <path
        style={styles.two}
        d="M412.91,627.19c.03,.33,.07,.61,.09,.81,.02-.27,.03-.56,.05-.85"
      />
      <line
        style={styles.two}
        x1="412.56"
        y1="621.78"
        x2="412.56"
        y2="621.77"
      />
      <path
        style={styles.two}
        d="M416,597.42s.01-.03,.02-.04c2.53-7.54,6.83-15.2,13.98-21.38,3.02-2.61,6.16-4.62,9.22-6.17,.01-.01,.02-.01,.03-.02,2.32-1.17,4.6-2.09,6.75-2.81-5.3,3.83-11.98,9.63-18,18-7.55,10.51-11.31,21.22-13.19,29.86"
      />
      <path
        style={styles.two}
        d="M548.53,629.88s.01,.08,.01,.12c0-.03,0-.07,.02-.11"
      />
      <path
        style={styles.two}
        d="M548.81,614.88h0c0-.05-.01-.1-.01-.14v-.02c-.02-.24-.04-.49-.06-.73"
      />
      <path
        style={styles.two}
        d="M542.82,592.17s-.04-.07-.06-.1c-2.58-5.12-6.2-10-11.22-14.07-1.35-1.09-2.72-2.07-4.11-2.94-.01,0-.01-.01-.02-.01s-.02,0-.03-.02c-3.8-2.37-7.7-3.96-11.38-5.03,5.17,3.61,11.66,9.07,17.54,17,8.25,11.13,11.96,22.64,13.65,31.55h0"
      />
      <path
        style={styles.two}
        d="M444.78,710.15c1.13-.31,2.26-.7,3.38-1.16l4.26-1.76c15.9-6.57,23.53-24.97,16.96-40.87l-9.71-23.49c-6.58-15.91-24.97-23.54-40.87-16.96l-2.25,.93"
      />
      <rect
        style={styles.two}
        x="396.59"
        y="625.23"
        width="64"
        height="88"
        rx="30.52"
        ry="30.52"
        transform="translate(-223.17 214.51) rotate(-22.46)"
      />
      <g>
        <path
          style={styles.two}
          d="M412.74,614.11l-4.99,1.85c-.4,.15-.81,.16-1.19,.04l2.27,5.47c.29,.72,.8,1.29,1.41,1.68"
        />
        <path
          style={styles.two}
          d="M414.09,613.11l.72,1.75,1.46,3.54c.29,.7,.34,1.45,.18,2.14"
        />
        <rect
          style={styles.two}
          x="409.67"
          y="621.3"
          width="9.11"
          height="5.17"
          rx="2.46"
          ry="2.46"
          transform="translate(-206.93 205.58) rotate(-22.46)"
        />
      </g>
      <path
        style={styles.two}
        d="M412.37,609.08c.61-2.91,1.68-7.01,3.63-11.66,.01-.01,.01-.03,.02-.04"
      />
      <path
        style={styles.two}
        d="M439.22,569.83s.02-.01,.03-.02c4.52-2.88,8.69-4.68,11.75-5.81,.67-2.67,1.33-5.33,2-8-5.89,1.2-21.2,5.19-33,19-11.56,13.53-14.01,29.78-14.2,36.2"
      />
      <path
        style={styles.two}
        d="M510.51,710.12h-.01c-1.16-.42-2.3-.91-3.42-1.48l-4.11-2.08c-15.37-7.75-21.59-26.67-13.84-42.03l11.46-22.69c7.75-15.37,26.67-21.59,42.03-13.84l2.14,1.08"
      />
      <rect
        style={styles.two}
        x="497.59"
        y="626.47"
        width="64"
        height="88"
        rx="30.52"
        ry="30.52"
        transform="translate(700.27 1507.64) rotate(-153.22)"
      />
      <g>
        <path
          style={styles.two}
          d="M548.84,614.84s-.03,.02-.03,.03h0c-.17,.22-.3,.46-.4,.71l-1.22,2.97h0s-1.18,2.85-1.18,2.85c-.29,.7-.35,1.45-.19,2.14"
        />
        <path
          style={styles.two}
          d="M552.04,626.15c.61-.39,1.12-.96,1.41-1.68l2.41-5.82c.08-.19,.14-.39,.18-.58"
        />
        <rect
          style={styles.two}
          x="543.5"
          y="624.3"
          width="9.11"
          height="5.17"
          rx="2.46"
          ry="2.46"
          transform="translate(815.03 1415.6) rotate(-157.54)"
        />
      </g>
      <path
        style={styles.two}
        d="M549.99,611.38c-2.93-10.74-5.38-16.22-7.17-19.21-.03-.04-.05-.09-.06-.1-2.36-4.03-5.44-8.17-9.48-12.07-1.95-1.88-3.92-3.52-5.87-4.95,0,0-.02-.01-.03-.02-5.88-4.3-11.52-6.69-15.38-8.03,.33-3,.67-6,1-9,5.7,1.78,18.42,6.61,29.28,19,13.01,14.84,15.22,31.26,15.72,37-2.67-.87-5.34-1.75-8.01-2.62Z"
      />
      <path
        style={styles.two}
        d="M406.56,616c-.56-.16-1.04-.58-1.25-1.16l-.47-1.28c-.34-.92,.09-1.94,.96-2.36,.05-.03,.1-.06,.16-.08l5.33-1.97c.36-.13,.73-.15,1.08-.07,.31,.07,.61,.22,.85,.44,.22,.2,.4,.45,.51,.75l.47,1.28c.2,.53,.14,1.1-.11,1.56-.21,.39-.56,.72-1.01,.88l-.34,.12"
      />
      <rect
        style={styles.two}
        x="548.78"
        y="611.98"
        width="9.48"
        height="5.16"
        rx="1.9"
        ry="1.9"
        transform="translate(859.46 1382.98) rotate(-159.7)"
      />
    </g>
  );
};

export default OnPause;
