const Alien = ({ color }) => {
  const styles = {
    one: {
      fill: color,
    },
    two: {
      strokeWidth: 2,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
    three: {
      strokeWidth: 2,
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "none",
    },
    four: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "#fff",
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="m687.59,761.42l5.86-28.24.77-57.96c-1.08-5.74-6.84-33-31.88-48.83-11.27-7.13-22.37-9.36-29.44-10.12-6.27-1.94-15.98-4.88-28.03-8.27-15.87-4.47-18.59-4.88-25.23-7.6-15.91-6.52-25.7-14.61-28-16.55-6.83-5.77-11.68-11.52-14.91-15.85,2.52-14.62,5.05-29.25,7.57-43.87,1.65-.16,3.61-.48,5.75-1.12,3.55-1.06,6.31-2.6,8.24-3.9,6.9-5.72,13.8-11.44,20.71-17.15,4.93-10.79,10.3-21.9,16.17-33.26,3.96-7.67,7.96-15.09,11.97-22.24,8.35-9.42,16.69-18.84,25.04-28.26l13.53-8.98c.6-.47,1.61-1.4,2.36-2.9.87-1.75.91-3.36.88-4.14-1.18-8.09-2.37-16.17-3.55-24.26,4.83-5.09,9.67-10.17,14.5-15.26,1.59-1.16,7.22-5.58,8.75-13.42.27-1.37,2.12-11.75-5.13-18.14-3.8-3.35-8.24-3.97-10.17-4.11l-2.32-6.06c-2.3-13.05-7.5-31-20.51-47.52-40.19-51.05-126.84-52.79-185.1-25.63-48.34,22.54-75.98,64.45-82.95,89.32-6.16,22.02-3.41,41.75-3.41,41.75,1.46,10.49,4.44,18.86,6.83,24.43,1.55,3.15,3.59,6.73,6.28,10.49,3.14,4.39,6.37,7.89,9.21,10.6,20.74,20.93,41.47,41.86,62.21,62.79l6.52,3.7c4.67,10.31,9.42,22.68,13.32,36.95,3.39,12.39,5.48,23.84,6.76,33.92-.73,2.28-1.93,5.51-3.89,9.13-4.31,7.95-11.29,15.94-27.88,25.55-10.27,5.95-27,14.13-50.17,19.38-5.28.12-30.93,1.26-51.14,22.09-23.82,24.54-20.51,56.24-19.99,60.43-.59,1.13-1.18,2.27-1.77,3.43-8.88,17.52-15.4,35.12-20.07,52.42,5.75,6.17,12.32,12.64,19.76,19.17,10.06,8.83,19.87,16.13,28.88,22.13,5.35-10.8,10.7-21.61,16.05-32.41l-.64,9.59.84,8.45,8.62,29.09c22.17,11.5,68.75,31.85,130.65,30.82,20.29-.34,79.05-3.58,139.38-40.93,21.71-13.44,37.85-27.72,48.77-38.62Z"
      />
      <path
        style={styles.one}
        d="m279.09,735.08c-1.21-3.83-2.83-9.11-4.6-15.42-1.32-4.7-5.97-21.59-11.6-52.31-6.31-34.48-6.33-45.39-6.26-50.09.12-7.93.77-14.48,1.33-18.97,8.61-10.56,17.22-21.13,25.83-31.69-.12-1.73-.38-4.02-.94-6.67-1.52-7.09-4.29-12.06-5.22-13.65-2.43-4.15-3.59-4.52-8.48-10.8-3.28-4.22-5.7-7.83-7.2-10.19-.6-3.1-1.2-6.2-1.81-9.3,1.18-3.07,2.4-6.96,3.17-11.55.82-4.89.92-9.2.76-12.63.93-3.52,1.76-7.5,2.34-11.9.84-6.38.96-12.12.74-16.99,3-6.48,4.42-12.05,5.16-15.8,1.18-6.06,3.66-18.75-2.14-23.34-.41-.32-1.55-1.2-3.17-1.37-5.22-.57-9.46,6.74-9.92,7.53-3.4,5.86-2.44,12.06-1.94,14.4-1.81,5.12-3.61,10.23-5.42,15.35l-1.65,14.15-2.82-19.26c1.22-5.38,1.84-9.93,2.18-13.21,1.08-10.42.01-14.64-.41-16.12-.5-1.76-2.64-9.33-6.48-9.6-1.58-.11-2.85,1.06-3.63,1.77-2.56,2.36-3.26,5.59-3.96,12.4-.37,3.6-.73,8.12-.91,13.36-1,3.06-2,6.13-3,9.19l.55,9.81-6.97,20.85v10.29l-3.17,41.38c-.7.78-1.41,1.56-2.15,2.35-2.32,2.46-4.63,4.72-6.91,6.79-.32.7-.55,1.38-.71,2.03-1.45,5.88,2.17,11.27,3.52,13.09,1.02,4.57,2.03,9.14,3.05,13.71l.19,15.16,7.9,20.05,2.4,1.63-1.03,6.59c6.25,31.1,18.41,67.67,42.25,103.49,3.62,5.45,7.34,10.6,11.11,15.49Z"
      />
      <path
        style={styles.two}
        d="m604.13,403.88h29.03c1.42-.35,4.02-1.21,6.26-3.5.79-.81,1.38-1.64,1.81-2.37,3.48-4.98,6.96-9.96,10.44-14.94,1.99-3.72,4.44-9.28,5.78-16.4,1.29-6.84,1.1-12.7.65-16.81-.83-1.12-2.09-2.51-3.92-3.68-4.83-3.09-10.01-2.35-11.56-2.06-7.66,1.57-17.31,4.35-27.74,9.45-16.1,7.88-27.22,17.98-34.02,25.28-.27.28-.94,1.05-1.15,2.25-.19,1.05.07,1.9.21,2.27,8.07,6.83,16.14,13.67,24.21,20.5Z"
      />
      <path
        style={styles.two}
        d="m554.52,375.28c.25.21,1.69,1.45,1.78,3.54.07,1.6-.69,2.73-.94,3.08-3.34,2.7-8.24,6.54-14.34,10.9-17.32,12.41-25.98,18.61-36.24,21.88-3.89,1.24-31.44,10.01-50.84-6.09-9.9-8.21-13.38-19.33-14.69-25.04-.21-2.23-.25-5.96,1.26-10.17.88-2.48,2.05-4.42,3.06-5.82,3.95-4.22,7.91-8.44,11.86-12.66,1.44-1.22,4.14-3.18,8.06-4.31,4-1.15,7.41-.9,9.28-.62,13.08,1.61,28.82,4.63,46.1,10.32,13.81,4.54,25.73,9.84,35.65,14.98Z"
      />
      <path
        style={styles.four}
        d="m476.89,362.32l1.47-5.59c8.11,1.25,17.32,3.25,27.24,6.48,5.69,1.85,10.91,3.88,15.65,5.95-14.79-2.28-29.57-4.56-44.36-6.84Z"
      />
      <polygon
        style={styles.four}
        points="457.13 358.94 456.75 362.65 467.97 361.69 467.47 357.46 457.13 358.94"
      />
      <path
        style={styles.four}
        d="m456.83,403.27c4.86,2.95,15.02,8.22,28.9,8.9,16.85.84,29.31-5.56,34.2-8.42-6.64,2.64-18.16,6.26-32.83,5.93-13.38-.3-23.9-3.76-30.28-6.41Z"
      />
      <path
        style={styles.four}
        d="m601.31,370.2l42.9-17.4-.18-.99c-4.61-.05-10.95.42-18.06,2.69-12.59,4.02-20.72,11.49-24.67,15.7Z"
      />
      <polygon
        style={styles.four}
        points="600.27 394.74 600.02 395.52 612.06 399.01 612.45 397.37 600.76 393.68 600.27 394.74"
      />
      <path d="m647.85,389.2c.86,4.24,1.6,8.51,2.25,12.79.59,4.43-2.78,8.24-6.31,10.32-6.81,4.4-13.69,8.67-20.9,12.46,0,0-.54-.84-.54-.84,7.09-5.67,14.91-10.35,22.28-15.54,2.13-1.74,3.51-4.18,3.07-6.95-.38-4.02-.66-8.06-.85-12.12l.99-.12h0Z" />
      <path d="m480.76,506.66c-12.62-5.3-24.94-11.14-37.03-17.47-.67-.31-2.36-2.08-2.97-2.6-5.2-4.8-12.8-12.09-18.02-16.93-4.02-4.01-9.84-9.98-13.89-14.09-4.61-4.6-15.81-16.61-20.46-21.48-4.53-4.81-9.06-9.53-13.59-14.41-4.41-5.11-8.11-10.8-10.94-16.92-4.37-9.14-6.25-19.16-6.98-29.2-2.29-24.25,4.69-48.79,18.06-69.02,14.21-21.58,34.33-38.86,56.73-51.53,40.54-23.03,89.34-31.09,134.74-19.54,26.57,6.45,52.32,20.31,68.38,42.97,9.24,12.89,15.03,28.11,17.32,43.77.21,2.88,2.37,5.37,5.2,6.01,7.13,1.92,10.89,9.18,12.03,15.89.47,1.5.99,4.85.42,7.02-.92,4.63-4.53,8.13-7.2,11.79,0,0-4.12,5.29-4.12,5.29l-1.59-1.21c2.21-3.02,6-7.63,8.12-10.67,2.77-3.51,3.64-7.68,2.22-11.86-1-5.87-4.38-12.44-10.44-14.03-3.45-.76-6.37-3.74-6.88-7.28-2.29-15.48-8.03-30.5-17.24-43.18-15.82-22.01-41.03-35.27-66.96-41.55-44.68-11.25-92.53-3.22-132.35,19.5-21.88,12.48-41.63,29.34-55.57,50.44-12.99,19.62-19.8,43.36-17.61,66.9.69,9.65,2.45,19.33,6.65,28.11,5.24,12.12,15.07,21.02,23.92,30.52,4.69,4.93,15.74,16.8,20.39,21.46,4.05,4.09,9.79,10.04,13.82,14.06,5.16,4.85,12.81,12.07,17.95,16.91.89.77,2.29,2.38,3.47,2.85,11.65,6.14,23.21,12.45,34.41,19.47h0Z" />
      <path d="m496.55,515.08c17.08,4.83,33.95,11.54,51.66,6.99,3.78-.77,7.3-2.07,10.19-4.72,2.9-2.35,10.95-8.87,13.97-11.32,2.93-2.36,6.25-4.73,7.93-8.21,3.58-7.1,7.39-14.14,10.47-21.49,4-8.55,7.93-19.17,12.99-27.11,1.03-1.76,2.58-3.32,3.99-4.72,2.78-2.84,5.46-5.78,8.03-8.8,3.84-4.55,7.56-9.34,11.64-13.73,0,0,4.04-4.46,4.04-4.46,0,0,1.46,1.37,1.46,1.37l-4.02,4.41c-4.1,4.39-7.74,9.06-11.64,13.66-3.2,3.8-6.75,7.43-10.19,10.99-1.87,2.24-3.22,4.88-4.62,7.43-3.1,6.1-6.9,15.47-9.79,21.81-3.09,7.4-6.82,14.46-10.34,21.68-1.2,2.36-2.91,4.47-4.96,6.15-5.6,4.55-14.15,11.93-19.76,16.26-2.61,1.83-5.7,2.73-8.75,3.45-3.96.96-8.01,1.58-12.08,1.85-14.15,1.54-29.69-1.31-40.21-11.49h0Z" />
      <path d="m451.03,490.43c10.2,22.4,16.91,46.35,20.32,70.72,1,8.2,2.34,16.27,2.31,24.55-.08,9.32-1.85,18.73-5.77,27.24-.73,1.95-2.27,3.6-4.27,4.27-.47.19-3.12,1.05-3.66,1.24-7.83,2.54-15.9,4.39-24.06,5.38-12.21,1.37-24.47,2.15-36.76,1.98,8.14-.65,16.27-1.34,24.38-2.34,13.44-1.38,26.7-3.59,39.37-8.3,2.57-.84,3.18-3.71,4.16-5.91,4.39-11.28,5.1-23.7,3.34-35.63-2-20.27-6.14-40.27-12.44-59.62-2.52-7.73-5.51-15.3-8.75-22.78,0,0,1.83-.8,1.83-.8h0Z" />
      <path d="m545.28,524.35c-5.22,23.59-8.62,47.58-10.2,71.68-.4,3.87-.08,7.81,1.62,11.33,1.64,3.45,4.52,6.24,8.01,7.78-1.56-.29,62.55,7.62,62.21,7.55-.15-.01-62.74-5.65-62.88-5.66-2-.88-3.86-2.1-5.44-3.63-4.68-4.46-6.26-11.25-5.52-17.5.53-8.07,1.26-16.13,2.19-24.16,1.87-16.07,4.55-32.04,8.05-47.83l1.95.43h0Z" />
      <path d="m537.54,567.41c12.04,16.55,29.72,28.81,49.42,34.33,4.04,1.2,15.57,4.6,19.8,5.86,0,0,26.39,7.83,26.39,7.83,6.72.3,15.61,2.29,22.38,5.53,21.6,9.88,34.68,32.29,39.23,54.89-.13,9.9-.19,33.4-.42,43.02-.02.04-.36,14.33-.38,14.37-.39,2.07-1.53,8.61-1.91,10.68-1.07,5.92-2.67,11.75-4.47,17.49,1.1-4.69,2.14-9.38,3.03-14.1.69-4.02,1.65-10.11,2.32-14.21.09-4.9.4-16.5.39-21.41,0-4.14.11-31.35.14-35.66-6.19-30.53-27.72-57.45-60.61-58.9-8.73-2.47-31.36-9.15-39.84-11.59,0,0-6.6-1.94-6.6-1.94-11.14-3.15-21.73-8.35-30.99-15.31-7.42-5.56-14.01-12.24-19.49-19.72,0,0,1.61-1.18,1.61-1.18h0Z" />
      <path d="m471.14,562.03c-8.68,25.05-36.36,39.42-59.8,48.05-8.24,2.95-16.67,5.33-25.22,7.18-9.84.39-19.62,2.66-28.51,6.88-27.72,12.66-40.99,43.79-39.01,73.11,0,0,0,.02,0,.02,0,0,.02.28.02.28l-.13.27c-2.24,4.55-4.56,9.07-6.86,13.6-6.59,13.76-12.04,28.06-16.36,42.7,3.65-14.86,8.55-29.44,14.78-43.43,2.21-4.61,4.4-9.23,6.66-13.81,0,0-.11.55-.11.55-.38-5.06-.19-10.07.33-15.06,2.3-25.1,16.37-49.36,39.81-60.04,8.83-4.27,19.84-6.79,28.95-7.03,8.46-1.83,16.82-4.2,24.97-7.11,22.84-8.41,50.13-22.45,58.58-46.82,0,0,1.89.66,1.89.66h0Z" />
      <path d="m657.39,328.45c-4.54-.62-9.15-.79-13.74-.46-4.59.32-9.14,1.16-13.59,2.41-4.42,1.31-8.75,3.02-12.84,5.2-4.11,2.15-7.99,4.74-11.61,7.65,3.52-3.04,7.31-5.78,11.37-8.09,4.04-2.35,8.34-4.23,12.78-5.72,4.46-1.43,9.07-2.46,13.75-2.95,4.68-.5,9.41-.5,14.11-.03l-.24,1.99Z" />
      <path d="m492,332.41c20.6,2.84,40.68,9.84,58.54,20.48-2.39-1.02-4.75-2.07-7.15-3.02-11.89-4.95-24.16-8.98-36.64-12.13-4.99-1.27-10.02-2.33-15.07-3.36,0,0,.33-1.97.33-1.97h0Z" />
      <path d="m462.36,331.62c-11.31,1.52-21.38,6.84-29.99,14.19,6.24-9.78,18.48-15.56,29.99-14.19h0Z" />
      <path d="m415.63,389.26c7.2,10.06,14.1,20.34,20.39,30.99,5.96,5.85,16.28,11.48,25.66,13.1-9.96-1.3-19.75-5.36-27.36-12.04-6.75-10.37-12.88-21.12-18.69-32.05h0Z" />
      <path d="m538.01,491.29c4.09-3.07,8.36-5.9,12.69-8.61,2.52-1.35,6.09-.73,7.75,1.69.01.01-.21-.2-.21-.2,1.74,1.06,3.93,1.19,5.75.29,1.86-.5,3.96-.29,5.63.67,1.59.91,2.8,2.42,3.21,4.18-2.39-2.09-5.08-3.73-8.18-2.74,0,0,.2-.07.2-.07-1.22.63-2.62.92-4,.8-1.41-.12-2.79-.68-3.88-1.59-1.28-1.62-3.46-2.04-5.33-1.24-4,2.21-9.09,4.82-13.63,6.81h0Z" />
      <path
        style={styles.two}
        d="m553.42,498.68c.62-2.02,2.28-3.56,4.34-4.01,2.12-.46,4.33.3,5.73,1.98l-10.07,2.03Z"
      />
      <line style={styles.two} x1="501.89" y1="517.9" x2="540" y2="546.02" />
      <path d="m485.59,556.89c-.57,11.8-.18,23.79,2.05,35.4,1.97,8.48,4.74,17.12,9.55,24.37,4.51,6.55,8.11,3.43,11.52-1.67,10.79-17.79,12.32-39.28,13.57-59.6.31,20.7-.68,42.67-11.48,60.95-4.28,7.06-10.44,9.77-15.66,1.74-4.8-7.78-6.59-16.8-8.34-25.62-1.85-11.76-1.86-23.71-1.21-35.56h0Z" />
      <path d="m694.23,675.23c-.46,10.9-2.42,21.7-5.46,32.17.59-10.88,2.29-21.73,5.46-32.17h0Z" />
      <path d="m638.78,800.11c11.45-22.1,16.55-46.92,15.15-71.71,4.08,24.61-1.62,50.83-15.15,71.71h0Z" />
      <path d="m535.55,810.14c26.43,5.79,53.7,7.15,80.43,2.65-26.19,7.22-54.81,6.49-80.43-2.65h0Z" />
      <polygon points="522 727.2 520.18 774.63 520 727.17 522 727.2 522 727.2" />
      <path d="m379.55,787.95c17.13,18.55,43.43,23.18,67.61,21.96,8.2-.36,16.39-1.29,24.58-2.5-7.94,2.37-16.14,3.95-24.43,4.74-24.37,2.51-52.89-3-67.76-24.2h0Z" />
      <path d="m343.93,795.42c7-16.43,14.87-32.47,22.87-48.43,0,0,1.79.89,1.79.89-7.84,16.04-15.84,32.02-24.67,47.54h0Z" />
      <path d="m373.46,767.11c-9.28-16.62-10.32-37.41-4.61-55.39-1.3,9.3-1.7,18.64-.84,27.96.88,9.34,2.96,18.36,5.45,27.43h0Z" />
      <polygon points="318.58 697.12 320.22 708.7 316.63 697.57 318.58 697.12 318.58 697.12" />
      <polygon points="515.55 411.05 504.45 421.56 514.08 409.69 515.55 411.05 515.55 411.05" />
      <polygon points="604.87 403.21 610.77 411.22 603.39 404.55 604.87 403.21 604.87 403.21" />
      <path d="m279.09,735.08c-9.79-28.06-16.17-57.23-20.47-86.61-1.4-9.83-2.59-19.7-3.4-29.6-.07-6.95.52-13.91,1.77-20.75,0,0,.05-.26.05-.26,0,0,.16-.2.16-.2l25.83-31.69s-.22.65-.22.65c-.09-4.86-1.1-9.69-3-14.15-1.89-4.52-4.69-8.64-8.06-12.2,0,0-10.59-14.38-10.59-14.38l.94.4c-9.76,1.14-12.68,10.2-9.51,18.59.98,2.63,2.43,5.08,4.23,7.26,1.24.96,5.9,3.51,8.87,4.05-3.49-.16-7.02-1.04-10.29-2.61-8.55-9.72-9.21-27.08,6.41-29.26,0,0,.59-.08.59-.08,0,0,.35.48.35.48,0,0,10.59,14.38,10.59,14.38,3.08,3.18,6.44,8.04,8.29,12.61,2,4.7,3.07,9.79,3.16,14.89v.37s-.22.28-.22.28l-25.83,31.69s.22-.47.21-.45c-1.21,6.72-1.78,13.55-1.69,20.37.81,9.43,1.86,19.71,3.18,29.37,3.92,29.4,10.63,58.33,18.65,86.86h0Z" />
      <path d="m228.91,629.33c-.42-1.66-.9-3.28-1.43-4.88-.55-1.6-1.16-3.12-1.82-4.72-.32-.8-.63-1.66-.82-2.57-.18-.9-.29-1.82-.26-2.74,0-1.85.51-3.64,1.22-5.28l.14-.32.21-.14c2.66-1.76,5.81-2.74,8.89-2.65-.72.28-1.43.53-2.11.85-.68.3-1.35.63-1.99.98-1.28.71-2.48,1.53-3.58,2.44l.34-.45c-.5,1.53-.79,3.09-.91,4.64-.11,1.57.07,3.08.51,4.69.45,1.61.92,3.31,1.17,5.02.29,1.7.43,3.42.44,5.13Z" />
      <path
        style={styles.three}
        d="m257.08,526.73c-5.26-3.26-10.52-6.52-15.78-9.78-7.1,4.61-14.59,10.42-21.85,17.74-1.43,1.44-2.79,2.88-4.08,4.31-.92.43-3.52,1.8-4.98,4.82-2.48,5.13.66,10.2.88,10.54,1.08,2.25,2.18,4.96,3.1,8.11,1.11,3.84,1.64,7.32,1.89,10.18-.62,4.03-1.23,11.54,1.44,20.34,2.42,7.97,6.47,13.49,9.05,16.5"
      />
      <path d="m260.97,525.48s-1.81-9.3-1.81-9.3l-.05-.28.1-.27c2.93-7.59,4.26-15.79,3.85-23.92,2.41-9.37,3.46-19.09,3.09-28.75,0,0,0-.25,0-.25l.11-.23c2.57-5.34,4.42-11.04,5.29-16.89,1.01-7.68,3.07-30.31-10.36-19.2-.74.66-1.55,1.5-2.15,2.29-3.15,4.41-3.68,10.23-2.92,15.48,0,0,.04.26.04.26l-.08.24-5.42,15.35c.26,4.91-1.32,14.41-3.18,19.77-1.26,6.2-1.21,12.53-.61,18.8.25,6.76-1.54,13.56-4.99,19.37-4.04-.17-8.22.49-12.14,1.5,3.65-1.64,7.52-2.96,11.49-3.51,0,0-.8.5-.8.5v-.02c3.1-5.36,4.66-11.59,4.44-17.77-.62-6.41-.64-12.91.64-19.24,2.06-6.42,3.17-13.15,3.21-19.9,0,0,.06-.16.06-.16l5.42-15.35-.04.5c-.85-5.8-.21-12.13,3.28-17,2.54-3.2,7.16-6.95,11.55-5.9,7.32,2.35,5.19,18.28,4.55,24.29-.91,6.07-2.82,11.96-5.47,17.47.74,7.23-.71,19.95-3.01,28.7.56,6.75-1,16.97-3.96,24.29,0,0,.05-.55.05-.55l1.81,9.3s-1.96.38-1.96.38h0Z" />
      <path
        style={styles.three}
        d="m219.46,534.7c.95-5.33,1.76-11.13,2.32-17.36.46-5.16.7-10.06.77-14.65.43-3.05.44-5.54.36-7.26-.19-4.51-1.07-6.72-.57-10.73.26-2.07.78-3.73,1.17-4.8,1-3.12,2.06-6.27,3.16-9.47.96-2.8,1.94-5.55,2.92-8.25-.18-3.27-.37-6.54-.55-9.81,2.15-7.8,2.84-14.09,3.07-18.47.25-4.74.08-9.88,3.44-14.73.83-1.2,2.56-3.63,4.77-3.55,2.52.09,4.23,3.39,5.03,4.94,4.4,8.49,1.88,21.63.46,27.59-.18,2.14-.36,4.28-.55,6.42l2.82,19.26"
      />
      <path
        style={styles.three}
        d="m244.6,544.28c-.99.5-2.45,1.4-3.82,2.93-1.97,2.22-2.67,4.57-2.93,5.77-.36,1.83-.66,3.83-.88,5.97-.33,3.31-.39,6.35-.29,9.04.21,1.54,1.41,2.76,2.93,2.99,1.55.23,3.08-.61,3.72-2.05,2.09-3.1,4.17-6.2,6.26-9.29,2.15-2.29,4.3-4.58,6.46-6.87"
      />
      <path d="m240.85,538.96c.57.86,1.06,1.63,1.58,2.35.52.72,1.05,1.37,1.63,1.99.58.62,1.21,1.19,1.9,1.74.69.56,1.44,1.07,2.28,1.66-2.03.37-4.16-.53-5.61-2.03-.73-.76-1.29-1.67-1.61-2.66-.31-.98-.43-2.03-.17-3.04Z" />
      <path d="m260.5,541.55c4.13.32,8.24-.7,11.59-2.77l.9,1.79c-4.02,1.66-8.47,1.96-12.49.98,0,0,4.02.98,0,0Z" />
      <path d="m254.51,555.18c1.46,6.91,4.3,13.59,8.34,19.55-4.57-5.55-8.1-12.05-10.27-19.04l1.93-.51h0Z" />
      <path d="m243.56,569.69c1.45,5.9,4.23,11.53,8.11,16.38-4.38-4.4-7.85-9.82-10.03-15.8l1.91-.59h0Z" />
      <path d="m241.41,571.05c-.63,5.98-2.59,11.74-5.61,16.76,2.44-5.34,3.67-11.14,3.62-16.86l2,.1h0Z" />
      <path d="m237.9,559.32c-1.38,2.65-3.24,4.99-5.4,6.87,1.67-2.33,2.86-4.93,3.54-7.59l1.87.72h0Z" />
      <path d="m238.83,552.7c-2.6,2.28-5.64,3.96-8.81,4.95,2.92-1.6,5.43-3.78,7.35-6.32l1.46,1.37h0Z" />
      <path d="m232.05,541.88c3.61,1.56,7.49,2.3,11.27,2.19l-.12,2c-4.03-.57-7.86-2.03-11.15-4.18,0,0,3.29,2.15,0,0Z" />
      <path d="m251.44,516.52c2.4-1.92,6.13-2.84,9.05-1.47,0,0-.69,1.88-.69,1.88-2.73-.75-5.49-1.27-8.36-.4h0Z" />
      <path d="m255.37,493.32c2.55-1.66,5.66-2.57,8.71-2.51,0,0-.02,2-.02,2-2.9,0-5.78.13-8.69.51h0Z" />
      <path d="m233.38,489.2c3.32-2.83,7.93-4.29,12.31-3.89,0,0-.15,1.99-.15,1.99-4.17-.27-8.23.32-12.16,1.9h0Z" />
      <path d="m237.32,453.89c2.2-2.11,5.39-3.17,8.49-2.82,0,0-.4,1.96-.4,1.96-2.51-.86-5.54-.62-8.08.87h0Z" />
      <path d="m259.72,462.7c2.02-1.92,5.62-2.35,7.95-.63,0,0-1.03,1.71-1.03,1.71-2.17-1.07-4.4-1.6-6.92-1.08h0Z" />
      <polygon
        style={styles.two}
        points="555.62 444.15 557.12 443.18 559.72 444.86 558.07 446.01 555.62 444.15"
      />
      <polygon
        style={styles.two}
        points="571.31 443.7 573.75 441.87 575.82 442.87 572.62 444.58 571.31 443.7"
      />
      <path d="m360.97,763.17c-1.24,7.86-.84,15.95,1.28,23.64,2.28,7.71,4.44,15.56,6.56,23.34-1.33-3.82-2.66-7.63-3.93-11.46-2.49-7.71-5.84-15.31-6.31-23.52-.34-4.09-.21-8.23.43-12.3l1.98.31h0Z" />
      <path d="m550.9,440.56c-2.71,2.19-3.52,6.28-1.9,9.41-.94-1.52-1.38-3.37-1.17-5.2.21-1.83,1.06-3.6,2.39-4.95l.68.74h0Z" />
      <path d="m580.83,439.32c2.69,2.62,3.23,7.03,1.23,10.15.79-1.6,1.03-3.43.67-5.12-.35-1.69-1.29-3.22-2.58-4.29l.68-.74h0Z" />
      <polygon
        style={styles.two}
        points="507.03 521.7 540 546.02 544.16 524.79 519.2 524.82 507.03 521.7"
      />
    </g>
  );
};

export default Alien;
