import { CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getUserAlts } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import { SET_ERRORS, StoreContext, StoreDispatch } from "../../store/Store";
import {
  currencyFormatter,
  getDateFromId,
  getReadableDateString,
  getReadableTime,
} from "../../utils/helpers";
import {
  card,
  green,
  modalShadow,
  normal,
  red,
  secondaryText,
  text,
  transition,
  yellow,
} from "../../utils/themeContstants";
import NotSelected from "../custom/NotSelected";

const UserAlts = ({ user, onClick }) => {
  const store = useContext(StoreContext);
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  const [loading, setLoading] = useState(true);
  const [alts, setAlts] = useState(null);

  const handleGetAlts = () => {
    getUserAlts(middleware, user?._id).then((res) => {
      setLoading(false);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        setAlts(res?.alts);
      }
    });
  };

  useEffect(() => {
    if (alts == null) {
      handleGetAlts();
    }
  }, []);

  const styles = {
    width: {
      width: "100%",
    },
    text: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },

    container: {
      width: "100%",
      padding: 2,
      backgroundColor: card,
      boxShadow: modalShadow,
      transition: transition,
      borderRadius: 1,
      "&:hover": {
        cursor: "pointer",
        transform: "translateY(-5px)",
      },
    },
  };

  return (
    <>
      {loading && (
        <Grid
          item
          alignSelf="center"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 4,
          }}
        >
          <CircularProgress size={30} sx={{ color: secondaryText }} />
        </Grid>
      )}

      {!loading && (!alts || alts?.length < 1) && (
        <Grid item alignSelf="center">
          <NotSelected label="NO ALTS" />
        </Grid>
      )}

      {!loading && alts && alts?.length > 0 && (
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 0.5 }}
          >
            {alts?.map((alt, i) => (
              <Grid
                item
                sx={styles.container}
                key={i}
                onClick={() => onClick(alt?.user_id)}
              >
                <Typography sx={styles.text}>
                  <span
                    style={{ fontWeight: 800, color: alt?.banned ? red : text }}
                  >
                    {alt?.username}
                  </span>{" "}
                  has logged in to this account.
                  <span
                    style={{
                      fontWeight: 800,
                      color: alt?.earnings > 0 ? green : text,
                    }}
                  >
                    {" "}
                    Earned: {currencyFormatter().format(alt?.earnings)}
                  </span>{" "}
                  {alt?.debt > 0 && (
                    <span
                      style={{
                        fontWeight: 800,
                        color: yellow,
                      }}
                    >
                      {" "}
                      Debt: {currencyFormatter().format(alt?.debt)}
                    </span>
                  )}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserAlts;
