const Clown = () => {
  const styles = {
    one: {
      fill: "#c1272d",
    },
    two: {
      fill: "#ed1c24",
    },
  };

  return (
    <g transform="translate(515, 380)">
      <path d="M0,27.62C.29,18.54,3.98,10.89,11.68,5.21c9.8-7.23,23.63-6.88,33.69,.67,6.36,4.78,9.94,10.98,11.03,18.57,2.05,14.24-9.48,27.25-24.08,29.17C14.18,56.01-.31,43.69,0,27.62ZM27.41,2.38C16.28,1.98,1.78,10.41,1.67,26.23c-.04,5.97,1.87,11.44,5.8,16.14,6.05,7.24,14.01,10.88,24.02,9.88,13.8-1.38,24.55-13.01,22.98-27.2-1.41-12.76-13.32-22.9-27.05-22.67Z" />
      <path
        style={styles.two}
        d="M27.41,2.38c13.73-.23,25.64,9.91,27.05,22.67,1.57,14.19-9.18,25.82-22.98,27.2-10,1-17.96-2.64-24.02-9.88-3.93-4.7-5.84-10.17-5.8-16.14C1.78,10.41,16.28,1.98,27.41,2.38Zm18.63,39.1c-.7-.1-.88-.19-1-.14-.65,.28-1.3,.56-1.91,.89-8.21,4.4-16.36,4.05-24.16-.68-7.78-4.72-11.55-11.8-12.66-20.21-.21-1.55-.33-3.1-.5-4.65-.54,.33-.79,.69-.93,1.08-1.08,2.97-1.39,5.97-1.02,9.14,1.26,10.79,10.95,20.99,22.4,23.08,7.66,1.4,13.99-.81,18.72-6.68,.4-.49,.64-1.09,1.07-1.84Zm-6.7-32.35c-1.71-.05-2.27,.6-1.81,1.96,1.13,3.35,3.64,5.66,6.79,7.42,.71,.39,1.72,1,2.37,.17,.44-.56,.4-1.5,.42-2.27,0-.41-.27-.85-.5-1.23-1.39-2.3-3.32-4.15-5.77-5.49-.54-.29-1.17-.44-1.5-.56Zm-11.25-4.63c.03,2.13,2.32,4.15,4.83,4.29,1.47,.09,2.15-.58,1.75-1.88-.16-.53-.56-1.1-1.03-1.43-1.65-1.17-3.48-1.77-5.55-.98Z"
      />
      <path
        style={styles.one}
        d="M46.04,41.48c-.43,.75-.68,1.35-1.07,1.84-4.73,5.86-11.06,8.07-18.72,6.68-11.45-2.09-21.14-12.29-22.4-23.08-.37-3.17-.06-6.16,1.02-9.14,.14-.39,.39-.75,.93-1.08,.16,1.55,.29,3.1,.5,4.65,1.12,8.41,4.89,15.48,12.66,20.21,7.8,4.74,15.95,5.08,24.16,.68,.62-.33,1.27-.61,1.91-.89,.12-.05,.3,.04,1,.14ZM5.18,20.27c-.8,2.66-.63,5.09-.09,7.44,2.17,9.46,8.18,16.05,17.79,19.7,4.38,1.66,8.97,2.04,13.54,.49,1.75-.59,3.45-1.31,4.9-2.62-.27-.08-.35-.13-.4-.12-.6,.2-1.19,.41-1.8,.6-6.26,2.05-12.41,1.86-18.29-1.05-7.78-3.86-12.37-10.05-14.49-17.87-.56-2.05-.76-4.18-1.17-6.57Z"
      />
      <path
        style={styles.one}
        d="M39.35,9.13c.34,.12,.97,.26,1.5,.56,2.45,1.34,4.37,3.19,5.77,5.49,.23,.38,.51,.82,.5,1.23-.02,.77,.02,1.71-.42,2.27-.65,.83-1.66,.22-2.37-.17-3.15-1.76-5.66-4.07-6.79-7.42-.46-1.36,.1-2.01,1.81-1.96Zm-1.18,1.57c1.2,3.41,4.7,6.38,7.6,6.58-.58-3.31-4.41-6.72-7.6-6.58Z"
      />
      <path
        style={styles.one}
        d="M28.1,4.5c2.07-.79,3.89-.18,5.55,.98,.47,.33,.86,.9,1.03,1.43,.4,1.3-.27,1.96-1.75,1.88-2.5-.15-4.8-2.16-4.83-4.29Zm.6,1.08c1.41,1.52,2.73,2.12,4.71,1.76-1.1-1.62-2.51-2.01-4.71-1.76Z"
      />
      <path
        style={styles.one}
        d="M5.18,20.27c.41,2.39,.61,4.52,1.17,6.57,2.12,7.82,6.71,14.02,14.49,17.87,5.88,2.91,12.03,3.1,18.29,1.05,.6-.2,1.19-.41,1.8-.6,.05-.02,.13,.04,.4,.12-1.46,1.3-3.15,2.02-4.9,2.62-4.57,1.55-9.16,1.17-13.54-.49-9.62-3.65-15.63-10.24-17.79-19.7-.54-2.34-.7-4.77,.09-7.44Z"
      />
      <path
        style={styles.one}
        d="M38.16,10.7c3.19-.14,7.02,3.27,7.6,6.58-2.9-.19-6.4-3.17-7.6-6.58Z"
      />
      <path
        style={styles.one}
        d="M28.7,5.58c2.2-.26,3.61,.13,4.71,1.76-1.98,.36-3.29-.23-4.71-1.76Z"
      />
    </g>
  );
};

export default Clown;
