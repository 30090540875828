import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import {
  card,
  cardVeryLight,
  large,
  normal,
  secondaryText,
  text,
} from "../../utils/themeContstants";
import CustomIconButton from "./CustomIconButton";
import { IoClose } from "react-icons/io5";
import DialogGlimmer from "../glimmers/DialogGlimmer";
import { useTheme } from "@emotion/react";

const CustomModal = ({
  open = false,
  onClose = () => {},
  title = null,
  titleSize = "medium",
  titleOptions = null,
  description = null,
  primaryButton = null,
  primaryButtonFullWidth = false,
  secondaryButton = null,
  loading = false,
  children,
  backgroundColor = "transparent",
  bottomContent = null,
  minWidth = 450,
  icon = null,
}) => {
  const isDesktop = useMediaQuery("(min-width:1025px)");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [hovered, setHovered] = useState(false);

  const handleClose = () => {
    setHovered(false);
    onClose();
  };

  const styles = {
    container: {
      backgroundColor: card,
      minWidth: isDesktop ? minWidth : "97%",
      maxWidth: 800,
      borderRadius: fullScreen ? 0 : 12,
      boxShadow: "0 1px 5px 1px rgb(0 0 0 / 40%)",
    },
    title: {
      fontSize: titleSize === "medium" ? large : 28,
      color: text,
      fontWeight: 800,
      textAlign: "left",
    },
    description: {
      fontSize: normal,
      color: secondaryText,
      fontWeight: 500,
    },
    closeButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      right: 10,
      top: 10,
    },
    backdrop: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      minWidth: "100vw",
      background: "rgba(16, 18, 27, 0.6)",
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: styles.container }}
      BackdropProps={{ style: { background: "rgba(16, 18, 27, 0.8)" } }}
      fullScreen={fullScreen}
    >
      {loading && (
        <Grid item sx={{ padding: 2, width: "100%" }}>
          <DialogGlimmer />
        </Grid>
      )}

      {!loading && (
        <>
          <DialogTitle>
            {titleOptions && <>{titleOptions}</>}

            {!titleOptions && (
              <Grid
                container
                direction="column"
                alignItems="start"
                justifyContent="center"
              >
                <Grid item>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    gap={{ xs: 1 }}
                  >
                    {icon && (
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {icon}
                      </Grid>
                    )}

                    <Grid item>
                      <Typography sx={styles.title}>{title}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {description && (
                  <Grid item>
                    <Typography sx={styles.description}>
                      {description}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}

            {handleClose && (
              <Grid item sx={styles.closeButton}>
                <CustomIconButton
                  onClick={handleClose}
                  icon={<IoClose style={{ color: text, fontSize: 20 }} />}
                />
              </Grid>
            )}
          </DialogTitle>

          <DialogContent
            sx={{
              backgroundColor: loading ? "transparent" : backgroundColor,
              marginBottom: primaryButton ? 0 : 2,
            }}
          >
            <Grid item sx={{ width: "100%" }}>
              {!loading && <>{children}</>}

              {loading && (
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 200,
                  }}
                >
                  <CircularProgress size={50} sx={{ color: secondaryText }} />
                </Grid>
              )}
            </Grid>
          </DialogContent>

          {bottomContent && (
            <DialogActions
              sx={{
                padding: 2,
                paddingTop: 0,
                paddingBottom: 2,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {bottomContent}
            </DialogActions>
          )}

          {primaryButton && (
            <DialogActions sx={{ padding: 2, minHeight: loading ? 50 : 0 }}>
              {!loading && (
                <>
                  {secondaryButton ? (
                    <Grid item sx={{ width: "100%" }}>
                      <Grid
                        container
                        justifyContent="end"
                        alignItems="center"
                        gap={{ xs: 2 }}
                      >
                        <Grid item>{secondaryButton}</Grid>

                        <Grid item>{primaryButton}</Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      {primaryButtonFullWidth ? (
                        <Grid item sx={{ width: "100%" }}>
                          {primaryButton}
                        </Grid>
                      ) : (
                        <Grid item sx={{ marginLeft: "auto" }}>
                          {primaryButton}
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )}
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};

export default CustomModal;
