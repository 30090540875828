import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../../store/Store";
import { isDateGreaterThanCurrent } from "../../utils/helpers";
import CustomMenu from "../custom/CustomMenu";
import MainMenuListItem from "../custom/MainMenuListItem";
import PurchaseChatUnbanModal from "../public_profile/PurchaseChatUnbanModal";
import PurchaseUnbanModal from "../public_profile/PurchaseUnbanModal";
import ResetStatsModal from "../public_profile/ResetStatsModal";
import BuySnipesModal from "../subscriptions/BuySnipesModal";
import UpgradeModal from "./UpgradeModal";
import ResetEarningsModal from "../public_profile/ResetEarningsModal";

const PurchaseMenu = ({ anchor, handleClose }) => {
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  const [unbanOpen, setUnbanOpen] = useState(false);
  const [chatUnbanOpen, setChatUnbanOpen] = useState(false);
  const [premiumOpen, setPremiumOpen] = useState(false);
  const [snipesOpen, setSnipesOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [resetEarningsOpen, setResetEarningsOpen] = useState(false);

  const onClose = () => {
    handleClose();
  };

  const styles = {
    width: {
      width: "100%",
    },
  };

  return (
    <>
      <UpgradeModal open={premiumOpen} onClose={() => setPremiumOpen(false)} />
      <BuySnipesModal open={snipesOpen} onClose={() => setSnipesOpen(false)} />
      <ResetStatsModal open={resetOpen} onClose={() => setResetOpen(false)} />
      <ResetEarningsModal
        open={resetEarningsOpen}
        onClose={() => setResetEarningsOpen(false)}
      />
      <PurchaseUnbanModal
        open={unbanOpen}
        onClose={() => setUnbanOpen(false)}
      />
      <PurchaseChatUnbanModal
        open={chatUnbanOpen}
        onClose={() => setChatUnbanOpen(false)}
      />

      <CustomMenu anchor={anchor} handleClose={onClose}>
        <Grid item sx={styles.width}>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            {store?.user?.behavior_info?.has_debt && (
              <MainMenuListItem
                title="Unban"
                description="Re-gain access to Rivox by purchasing an unban."
                type="unban"
                onClick={() => {
                  setUnbanOpen(true);
                  onClose();
                }}
              />
            )}

            {isDateGreaterThanCurrent(
              new Date(store?.user?.behavior_info?.match_chat_unban_timestamp)
            ) && (
              <MainMenuListItem
                title="Chat Unban"
                description="Re-gain access to chatting in Rivox matches by purchasing a chat unban."
                type="chat-unban"
                onClick={() => {
                  setChatUnbanOpen(true);
                  onClose();
                }}
              />
            )}

            {!store?.user?.premium_info?.active && store?.user && (
              <MainMenuListItem
                title="VIP"
                description="Upgrade to VIP and compete on Rivox at the highest level."
                type="premium"
                onClick={() => {
                  setPremiumOpen(true);
                  onClose();
                }}
              />
            )}

            <MainMenuListItem
              title="Store"
              description="Customize your look and shop our latest releases."
              type="store"
              onClick={() => {
                navigate("/shop/avatars");
                handleClose();
              }}
            />

            {store?.user && (
              <>
                <MainMenuListItem
                  title="Match Snipes"
                  description="View your opponent before you play them."
                  type="snipes"
                  onClick={() => {
                    setSnipesOpen(true);
                    onClose();
                  }}
                />
                <MainMenuListItem
                  title="Stat Reset"
                  description="Start from 0 by resetting your stats."
                  type="reset"
                  onClick={() => {
                    setResetOpen(true);
                    onClose();
                  }}
                />
                <MainMenuListItem
                  title="Earnings Reset"
                  description="Start from 0 by resetting your earnings."
                  type="reset_earnings"
                  onClick={() => {
                    setResetEarningsOpen(true);
                    onClose();
                  }}
                />
              </>
            )}
          </Grid>
        </Grid>
      </CustomMenu>
    </>
  );
};

export default PurchaseMenu;
