const Incognito = () => {
  const styles = {
    one: {
      fill: "#677580",
    },
    two: {
      fill: "#2f333a",
    },
    three: {
      fill: "#2a2f33",
    },
    four: {
      fill: "#f4c8a4",
    },
    five: {
      fill: "#f4c8a4",
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    six: {
      strokeWidth: 2.5,
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "none",
    },
    seven: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 1.5,
    },
    eight: {
      stroke: "#000",
      strokeMiterLimit: 10,
      strokeWidth: 2,
    },
    nine: {
      stroke: "#000",
      strokeMiterLimit: 10,
      fill: "none",
      strokeWidth: 2,
    },
    ten: {
      fill: "#ffdc5e",
    },
    eleven: {
      fill: "#fbb02e",
    },
    twelve: {
      fill: "#be655e",
    },
    thirteen: {
      fill: "#272b2e",
    },
  };

  return (
    <g>
      <path
        style={styles.one}
        d="m288.78,746.86c-.4-14.89.38-33.27,4.22-53.91,2.91-15.7,6.98-29.41,11.15-40.89,18.82-16.26,44.95-35.52,78.85-52.06,24.12-11.77,46.61-19.45,65.69-24.57,21.07,1.84,42.13,3.67,63.2,5.51,19.71,2.58,50.2,9.07,83.11,27.06,26.46,14.46,45.41,31.41,57.79,44.37,4.94,15.77,9.6,33.7,13.21,53.58,4.64,25.54,6.56,48.82,7.05,68.96-14.2,12.19-36.17,28.56-66.05,42-51.38,23.12-97.39,24.63-120,23.99-46.92-1.33-82.48-14.45-100-21.99-49.98-21.53-81.86-53.17-98.22-72.04Z"
      />
      <path
        style={styles.four}
        d="m518.87,533.53c-1.93,5.66-3.14,10.23-3.91,13.46-.37,1.56-.46,2.04-1.83,7.96-1.43,6.18-1.46,6.21-1.7,7.43-.91,4.8-1.06,8.01-1.17,10.7-.26,6.12.32,7.46,1.17,8.35.49.51,1.02.81,1.4.98,5.26,4.47,5.85,8.04,5.77,9.96-.35,9.12-16.94,15.17-27,16.97-23.01,4.12-44.54-8.01-48-17.09-.73-1.93-2.88-7.57-.23-11.22.48-.66,1.01-1.11,1.37-1.37.58-.37,1.48-1.02,2.38-2.02,0,0,1.01-.93,1.57-2.22,1.09-2.53,5.86-23.75,11.97-52.88,7.52,3.12,17.19,6.39,28.72,8.56,11.36,2.13,21.42,2.6,29.48,2.43Z"
      />
      <path d="m460,523c1.9,1.69,3.9,3.36,6,5,15.78,12.32,32.48,19.14,47,23,1.96-5.48,3.91-10.96,5.87-16.44-4.61.02-9.68-.16-15.13-.64-.79-.07-6.86-.61-12.73-1.62-12.45-2.13-23.75-6.29-31-9.31Z" />
      <path
        style={styles.five}
        d="m340.97,363.98c-.44.45-.85.92-.85.92-.63.71-1.47,1.75-2.32,3.08-3.83,6.02-4.02,12.25-4.08,14.87-.08,3.74-.24,12.36,5.93,19.7,1.41,1.67,5.86,6.72,13.5,8.02,5.31.91,9.62-.43,11.65-1.21.35,1.08.7,2.16,1.05,3.24.89,2.66,2.2,6.5,3.92,11.08,5.77,15.36,12.34,29.14,16.63,36.78,18.49,32.89,48.78,52.52,50.09,53.35,5.51,3.49,26.99,16.57,56.35,20.87,48.98,7.17,96.5-11.21,122.61-37.57,8.76-8.84,15.79-19.26,16.17-19.83,9.81-14.66,13.89-26.95,20.35-46.96,6.8-21.04,10.75-33.5,12.52-51.65,1.23-12.64,2.58-26.47-1.21-44.22-2.02-9.48-9.2-40.95-35.7-60.62,0,0-.97-.72-2.22-1.59-25.41-17.65-105.98-50.7-181.99-20.74-31.37,12.37-44.52,24.29-50.87,33.65-5.34,7.87-8.29,15.77-9.39,18.78-10.44,28.46-3.48,51.92-11.74,54.78-3.81,1.32-5.33-3.66-13.3-3.13-9.13.61-16.5,7.78-17.11,8.39Z"
      />
      <path
        style={styles.eight}
        d="m561.21,467.57c0-.25-8.28-1.72-17.31,1.49-9.75,3.46-15.37,10.59-15.15,10.86.22.28,6.85-7.14,18.48-10.53,7.81-2.28,13.99-1.6,13.98-1.82Z"
      />
      <g>
        <path
          style={styles.nine}
          d="m369.44,383.6c.52-3.87,1.04-7.74,1.57-11.61-1.13.65-2.26,1.3-3.39,1.96-1.05.61-2.09,1.22-3.13,1.83-.3-.25-4.21-3.44-8.61-2.09-4.19,1.29-7.07,6.16-6.13,11.48,0,.18.02,1.28.46,2.68.49,1.56,1.5,3.49,3.58,4.89,3.92,2.63,8.12,1.06,8.48.91"
        />
        <path
          style={styles.nine}
          d="m364.09,380.99c-1.16-.12-3.95-.24-6.91,1.3-4.06,2.12-5.53,5.85-5.87,6.78"
        />
      </g>
      <g>
        <path d="m438.49,342.87c-.5-4.69,11.16-10.72,21.78-12.26.97-.14,3.6-.49,7.04-.39,0,0,13.71.38,24.13,9.39,1.57,1.36,3.32,3.19,3.26,5.48,0,.16-.09,2.26-1.43,3-1.57.87-3.83-.71-5.61-1.7-2.97-1.65-6.07-2.14-12.26-3.13-8.22-1.31-15.65.82-19.17,1.83-5.76,1.65-8.54,3.53-12.52,2.22-.48-.16-4.92-1.67-5.22-4.43Z" />
        <path d="m633.73,345.36c.5-4.69-11.16-10.72-21.78-12.26-.97-.14-3.6-.49-7.04-.39,0,0-13.71.38-24.13,9.39-1.57,1.36-3.32,3.19-3.26,5.48,0,.16.09,2.26,1.43,3,1.57.87,3.83-.71,5.61-1.7,2.97-1.65,6.07-2.14,12.26-3.13,8.22-1.31,15.65.82,19.17,1.83,5.76,1.65,8.54,3.53,12.52,2.22.48-.16,4.92-1.67,5.22-4.43Z" />
      </g>
      <path
        style={styles.six}
        d="m512.54,395.09c4.79.05,10.54-.24,16.96-1.3,6.64-1.1,12.34-2.77,16.96-4.43,5.84.63,10.81,4.37,12.78,9.65,2.45,6.58-.93,12.35-1.37,13.09-1.59,2.79-4.34,6.84-8.8,10.65-5.05,4.32-10.1,6.48-13.3,7.57"
      />
      <g>
        <path
          style={styles.seven}
          d="m446.15,367.35c-.18.31,10.22,11.45,24,10.43,12.03-.89,20.08-10.57,19.83-10.96-.25-.38-8.63,8.54-20.97,9.03-13.12.53-22.68-8.8-22.86-8.51Z"
        />
        <path
          style={styles.seven}
          d="m582.11,367.35c-.18.31,10.22,11.45,24,10.43,12.03-.89,20.08-10.57,19.83-10.96-.25-.38-8.63,8.54-20.97,9.03-13.12.53-22.68-8.8-22.86-8.51Z"
        />
      </g>
      <path
        style={styles.eleven}
        d="m346.56,442.02c-5.51-1.22-8.9-3.61-10.86-5.4-9.06-8.26-10.44-24.42-3.3-40.41-1.38-.36-12.32-3.44-16.2-13.61-1.58-4.13-1.41-7.91-1.09-10.24,9.98,3.23,19.97,6.47,29.95,9.7-1.68,10.74-2.69,24-1.5,39.07.6,7.62,1.69,14.61,3,20.88Z"
      />
      <path
        style={styles.one}
        d="m622.7,592.77l49.67-98.96c.13-.4.3-.78.48-1.17.77-1.65.79-2.92.53-3.88-1.47-5.57-14.1-8.16-31.45-7.23-144.44-.29-254.75-.2-276.11-.24-3.73,0-14.35-.04-27.98,2.63-5.24,1.03-9.74,2.22-13.32,3.3-1.82.55-3.32,1.92-3.82,3.74,0,.01,0,.03-.01.04-.99,3.59.38,6.77,1.13,8.17.23.43.42.88.58,1.34,23.38,66.64,46.76,133.28,70.14,199.92.86,2.45,3.28,4.02,5.9,3.81l62.13-4.9,174.02-13.73c2.24-.18-26.83,7.54-41.68,10.22-3.64.66-6.42-3.19-4.65-6.43"
      />
      <path
        style={styles.eleven}
        d="m654.59,445.52c4.41-1.11,7.13-3.3,8.69-4.92,7.25-7.53,8.36-22.28,2.64-36.87,1.11-.33,9.86-3.13,12.97-12.41,1.26-3.77,1.13-7.22.87-9.34-7.99,2.95-15.98,5.9-23.97,8.85,1.34,9.8,2.15,21.9,1.2,35.64-.48,6.96-1.35,13.33-2.4,19.05Z"
      />
      <path
        style={styles.two}
        d="m359.64,483.11c-.06,0,2.96,1.07,2.9,1.08l-34.58,3.77c-1.74.43-3.08,1.63-3.27,2.96-.27,1.88,1.7,4.25,4.76,4.31.07,0,.13,0,.2.02,8.03,1.81,20.41,4.43,35.62,6.98,59.61,10.01,102.42,8.77,184.57,5.94,35.89-1.24,66.56-3.26,104.31-13.89,5.17-1.45,9.63-2.84,13.14-3.97,1.1-.35,1.06-1.9-.06-2.21-2.04-.56-4.21-1.07-6.51-1.51-8.53-1.63-16.16-1.76-22.29-1.35-.03,0-.05,0-.08,0-92.9-.72-185.8-1.44-278.7-2.15Z"
      />
      <path
        style={styles.ten}
        d="m364.59,499.67c32.37,4.65,68.19,8.11,107.01,9.3,62.51,1.91,120.96.34,167.43-10.79,7.1-14.52,13.97-34.81,17.04-62.33,1.95-17.48,1.23-32.82-.29-45.03-103.97-2.92-207.93-5.84-311.9-8.76-1.87,17.16-2.5,41.81,3.95,70.32,4.33,19.17,10.72,35.06,16.75,47.29Z"
      />
      <path
        style={styles.three}
        d="m654.28,393.09l5.05,1.48c2.43.71,4.31,2.73,4.65,5.22,0,.02,0,.04,0,.05.18,1.37.13,2.63-.04,3.74-.34,2.23-1.84,4.17-3.95,5.03-.3.12-.6.25-.91.4-5.79,2.69-9.09,7.42-10.79,10.59-.42.77-.67,1.61-.77,2.48-1.17,11.07-4.9,27.79-17.31,42.43-1.62,1.91-3.28,3.66-4.95,5.26-.99.95-2.23,1.59-3.59,1.81-6.78,1.1-14.22,1.96-22.24,2.36-16.76.84-31.56-.5-43.72-2.54-.63-.1-1.23-.29-1.8-.57-2.82-1.38-6.29-3.46-9.76-6.6-6.08-5.48-9.2-11.53-10.76-15.3-.09-.22-.17-.44-.24-.68-3.02-10.38-6.05-20.77-9.07-31.15-.45-1.54-1.44-2.87-2.81-3.74-2.66-1.71-7.17-3.91-13.08-4.1-6.4-.2-11.38,2.06-14.31,3.85-1.61.99-2.68,2.63-3.01,4.48-1.09,6.15-2.98,13.66-6.37,21.84-3.84,9.26-8.43,16.59-12.4,22-.44.6-.98,1.13-1.61,1.54-2.89,1.93-6.48,4-10.77,5.83-3.89,1.66-7.51,2.76-10.62,3.5-10.75,2.18-26.1,3.98-44.2,1.77-10.49-1.28-19.55-3.62-26.96-6.1-1.06-.35-1.99-.97-2.75-1.78-2.07-2.21-4.29-4.92-6.42-8.18-3.6-5.51-5.72-10.75-7-14.82-.06-.18-.1-.36-.15-.54-2.13-9.44-4.26-18.89-6.39-28.33-.24-1.06-.73-2.05-1.45-2.87-1.36-1.57-2.98-3.23-4.9-4.89-3.45-2.99-6.84-5.1-9.7-6.58-.66-.34-1.27-.76-1.77-1.3-2.08-2.19-3.04-5.11-2.41-7.76.97-4.1,5.67-7.17,10.92-6.34.3.05.61.09.92.09,101.89,2.7,203.78,5.4,305.67,8.11.58.02,1.16.11,1.72.27Z"
      />
      <path
        style={styles.one}
        d="m341.58,307.7c-56.46,3.03-85.87,10.93-88.23,23.71-1.36,7.36,6.27,16.34,22.87,26.94,8.61,4,20.9,9.43,35.94,15.09,23.17,8.71,77.03,27.04,171.01,33.41,0,0,118.12,8.01,203.14-24.25,5.94-2.25,22.36-8.48,29.95-23.71.01-.02.02-.05.03-.07,1.78-3.59,1.92-7.82.33-11.51-.29-.66-.59-1.3-.91-1.9-11.66-21.86-51.47-19.1-54.46-18.86-1.26-5.76-24.89-108.64-83.32-116.93-5.5-.78-11.67-.77-18.52.54-15.17.99-32.79,1.13-52.28-.54-23.56-2.02-44.1-6.21-61-10.78-8.07-2.4-15.16-2.78-21.24-2.16-37.14,3.83-69.06,48.8-83.32,111.01Z"
      />
      <path
        style={styles.three}
        d="m346.35,289.67c50.39,15.01,121.6,29.41,206.53,25.04,37.75-1.94,71.62-7.26,100.9-13.81,2.36,8.21,4.71,16.42,7.07,24.63,1.01,3.51.37,7.33-1.89,10.22-.02.02-.03.04-.05.07-4.35,5.49-10.97,7.41-15.07,8-55.52,7.97-102.57,10.38-138.33,10.78-56.12.63-86.18-3.67-109.06-8.49-19.2-4.04-34.97-8.79-46.16-12.53-1.84-.61-6.73-2.5-10.01-7.48-.01-.02-.03-.04-.04-.07-1.88-2.88-2.32-6.46-1.39-9.77l7.5-26.59Z"
      />
      <rect
        style={styles.twelve}
        x="491.07"
        y="475.11"
        width="32.68"
        height="12.85"
        rx="6.42"
        ry="6.42"
      />
      <path
        style={styles.thirteen}
        d="m460.01,833.02l-17.79-133.44c-.11-.84-.07-1.69.1-2.52.22-1.08.5-2.19.83-3.31,2.57-8.65,7.16-12.91,9.65-14.76.11-.08.21-.16.31-.25,19.03-16.02,38.06-32.04,57.09-48.05,1.19-1,2.06-2.36,2.4-3.88.12-.55.22-1.17.26-1.87.19-3.45-1.18-5.76-1.36-6.04-16.32-26.01-32.64-52.03-48.96-78.04-.25-.4-.46-.82-.63-1.26-.62-1.62-1.83-5.57-1.06-9.96.86-4.96,3.7-7.73,7.47-8.44.62-.12,2.31-.35,1.86-.23-14.99,3.89,28.3-7.34,43.29-11.22-8.61,1.47-17,4.2-27.84,10.59-3.12,1.84-6.08,3.82-8.86,5.86-1.25.92-2.17,2.22-2.62,3.7-.38,1.26-.66,2.91-.51,4.85.17,2.16.85,4.09,1.84,5.44,2.28,3.09,4.68,6.09,6.79,9.3,13.7,20.93,27.4,41.86,41.1,62.79.29.44.53.9.71,1.39.6,1.61,1.68,5.3,1.02,9.87-.83,5.76-3.73,8.1-4.08,8.37-20.27,17.83-40.53,35.67-60.8,53.5-.75.66-1.36,1.46-1.78,2.36-.68,1.44-1.48,3.58-1.95,6.43-.54,3.25-.43,6.05-.29,7.7.01.14.03.28.05.42,6.07,41.26,12.2,86.84,18.28,128.68.68,4.66-3.16,8.73-7.85,8.35h0c-3.44-.28-6.21-2.92-6.67-6.34Z"
      />
    </g>
  );
};

export default Incognito;
