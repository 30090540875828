import { Grid, Typography, useMediaQuery } from "@mui/material";
import { getLootType } from "../../utils/helpers";
import {
  doubtedBlue,
  normal,
  purple,
  secondaryText,
  text,
  yellow,
} from "../../utils/themeContstants";

const BuildFightRules = ({ match }) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const styles = {
    label: {
      fontSize: 11,
      fontWeight: 600,
      color: secondaryText,
    },
    value: {
      fontSize: normal,
      fontWeight: 700,
      color: text,
    },
    nonBoldValue: {
      fontSize: normal,
      fontWeight: 500,
      color: text,
    },
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="start"
      justifyContent="center"
      gap={{ xs: 2 }}
      sx={{ maxWidth: isDesktop ? 275 : "100%" }}
    >
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>MAP</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <span style={{ color: doubtedBlue }}>2596-7504-3887</span>{" "}
              {/* <span style={{ color: text, fontWeight: 500 }}>
                (1v1 Build Fights! (4.6.4))
              </span> */}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>LOADOUT</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              <li>{getLootType(match?.extra_options?.loot_type)}</li>
              <li>Blue AR</li>
              <li>Grappler</li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>HEALTH</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>
              {match?.extra_options?.health}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>BUILD MATERIAL</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.value}>Wood</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="start"
          justifyContent="center"
        >
          <Grid item>
            <Typography sx={styles.label}>ROUND ELIGIBILITY</Typography>
          </Grid>

          <Grid item>
            <Typography sx={styles.nonBoldValue}>
              <li>
                If you{" "}
                <span style={{ fontWeight: 800 }}>
                  do NOT start with TWO 90's to begin the round
                </span>
                , you forfeit the round.
              </li>
              <li>
                If you{" "}
                <span style={{ fontWeight: 800 }}>
                  fall and grapple to height
                </span>
                , that round results in a loss. You must grapple{" "}
                <span style={{ fontWeight: 800 }}>below</span> the opposing
                player.
              </li>
              <li>
                If you{" "}
                <span style={{ fontWeight: 800 }}>
                  shoot someone and deal damage while they're grappling
                </span>
                , you forfeit the round.
              </li>
              <li>
                If you{" "}
                <span style={{ fontWeight: 800 }}>
                  chop more than 50% of the builds
                </span>
                , you forfeit the round.
              </li>
              <li>
                If you{" "}
                <span style={{ fontWeight: 800 }}>
                  stay on the same layer for more than 20 seconds
                </span>
                , you forfeit the round.
              </li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BuildFightRules;
