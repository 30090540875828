const Bald = ({ color }) => {
  const styles = {
    one: {
      fill: color,
      stroke: "#000",
      strokeMiterLimit: 10,
    },
  };
  return (
    <g transform="translate(334, 235)">
      <path
        style={styles.one}
        d="M35.33,124.15c.63-2.65,1.15-6.09,.99-10.1-.33-8.22-3.35-14.44-5.31-17.76-.11,.26-1.44,3.13-4.69,3.76-3.47,.67-5.86-1.85-6-2-.89-.36-2.6-1.2-4-3-2.27-2.91-2.07-6.18-2-7-.91-.36-6.2-2.53-8-8-.67-2.02-.65-3.85-.5-5.11-4.74-3.96-6.52-10.36-4.5-15.89,2.71-7.39,10.67-8.94,11-9,1.05-2.01,3.43-5.88,8-9,3.98-2.72,7.82-3.64,10-4,1.3-2.57,3.22-5.74,6-9,3.37-3.95,6.86-6.67,9.56-8.46,4.73-2.03,10.95-4.19,18.44-5.54,8-1.45,14.99-1.53,20.34-1.2,16.18-2.28,32.37-4.56,48.55-6.84,15.7-1.65,31.41-3.31,47.11-4.96,11.01-.81,24.61-.92,40,1,14.26,1.78,26.56,4.85,36.53,8.03,15.74-3.3,26.97-1.47,34.47,.97,3.19,1.04,5.08,1.98,15,6,9.68,3.92,17.65,6.98,23,9,5.11,2.49,10.91,5.75,17,10,5.67,3.96,10.42,7.97,14.34,11.67,.94,.45,8.89,4.44,10.66,13.33,1.5,7.55-2.06,15.54-9,20,.72,1.77,1.55,4.64,1,8-.98,6.02-5.96,11.06-12.74,13.23-.76,1.79-2.53,5.23-6.26,7.77-1.43,.97-2.83,1.6-4,2l-7.72,12.1c-1.23-15.47-4.37-28.02-7.28-37.1-3.2-9.98-5.77-17.96-12-27-8.82-12.79-19.31-19.47-28-25-7.8-4.96-20.2-11.65-37-16-16-.55-38.87,.49-65,8-20.82,5.98-37.44,14.27-49.52,21.49,11.04-8.7,26.85-19.22,47.52-27.49,14.29-5.72,27.43-8.97,38.34-10.87-8.25-.17-18.25,.43-29.34,2.87-25.03,5.52-42.89,17.64-52.96,25.82,12.99-9.94,25.97-19.88,38.96-29.82-5.94,.64-14.06,2.17-23,6-12.14,5.19-20.49,12.33-25.39,17.24,1.89-3.47,3.79-7.55,5.39-12.24,.7-2.04,1.27-4.01,1.76-5.89-1-.4-2.66-.96-4.76-1.11-4.27-.3-7.56,1.31-9,2-11.36,5.43-11.56,3.42-28,12-5.41,2.83-9.1,4.77-14,8-5.2,3.43-9.25,6.66-12,9-2.68,4.1-5.41,8.77-8,14-4.81,9.73-7.85,18.85-9.81,26.6-2.17,8.68-4.35,17.35-6.52,26.03,1.18,5.36-.3,10.3-3.67,12.37-.74,.46-2.03,1.06-3.99,1.1Z"
      />
    </g>
  );
};

export default Bald;
