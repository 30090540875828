const RedLipstick = () => {
  const styles = {
    one: {
      fill: "#f76666",
    },
    two: {
      fill: "f43d3d",
    },
  };

  return (
    <g transform="translate(500, 460)">
      <g>
        <path d="M3.36,6.01c.21,.45,.33,.68,.44,.91-.05,.06-.09,.12-.14,.18-1.18-1.13-2.35-2.25-3.65-3.5,.33,0,.52-.06,.66,0,5.06,2.72,10.37,2.96,15.71,1.66,2.94-.72,5.77-2.05,8.66-3.06,2.05-.72,4.1-1.49,6.19-2,2.66-.65,4.92,.31,6.66,2.78,.59,.83,1.22,1.63,1.93,2.31,.4,.38,1.01,.48,1.61,.74,.2-.64,.33-1.07,.46-1.5,.93-2.95,3.22-4.58,5.91-3.99,1.77,.38,3.47,1.11,5.22,1.6,2.2,.61,4.4,1.28,6.64,1.67,2.48,.43,5,.17,7.46-.38,.72-.16,1.4-.63,2.05-1.05,.74-.48,1.44-1.05,2.35-1.37-.14,.22-.24,.51-.43,.67-1.79,1.49-3.47,3.25-5.43,4.35-3.07,1.72-6.28,3.17-9.53,4.39-6.97,2.63-14.14,3.78-21.5,3.55-10.65-.33-20.91-2.62-30.63-7.74-.15-.08-.32-.12-.63-.23Zm61.35-1.04c-2.78,.4-5.54,.29-8.29-.5-2.7-.78-5.41-1.56-8.12-2.31-2.54-.7-4.17,.41-4.88,3.28-.54,2.16-1.56,2.8-3.36,1.93-.72-.35-1.38-.96-1.96-1.59-.54-.59-.98-1.32-1.4-2.05-1.41-2.45-3.44-3.13-5.79-2.52-1.98,.51-3.95,1.16-5.84,1.99-5.56,2.44-11.21,4.02-17.22,3.1-.72-.11-1.43-.24-2.46-.42,15.25,9.12,44.02,8.82,59.32-.92Z" />
        <path
          style={styles.one}
          d="M2.1,4.59c4.42,5.18,8.85,10.35,13.27,15.53,.88,.93,10.53,10.86,24.36,8.72,9.97-1.54,15.73-8.37,17.13-10.12,3.78-4.84,7.55-9.67,11.33-14.51-4.95,3.15-14.87,8.51-28.45,9.57C20.95,15.26,7.02,7.61,2.1,4.59Z"
        />
        <path d="M4.89,7.93c.59,.59,1.2,1.16,1.77,1.77,2.18,2.31,4.35,4.64,6.54,6.95,5.04,5.31,10.75,9.17,17.61,10.26,9,1.43,17.42-.37,24.75-6.87,1.63-1.45,2.91-3.43,4.29-5.23,1-1.3,1.89-2.7,3.05-3.89-.18,.35-.33,.73-.54,1.06-1.37,2.16-2.63,4.41-4.15,6.42-1.21,1.6-2.6,3.09-4.12,4.3-9.91,7.89-20.49,8.69-31.53,3.61-2.18-1-4.03-2.66-5.71-4.54-3.64-4.04-7.28-8.08-10.91-12.12-.43-.48-.85-.97-1.27-1.46,.07-.09,.14-.17,.21-.26Z" />
        <path
          style={styles.two}
          d="M64.71,4.97c-15.3,9.74-44.06,10.03-59.32,.92,1.03,.17,1.74,.31,2.46,.42,6.01,.92,11.66-.66,17.22-3.1,1.89-.83,3.86-1.48,5.84-1.99,2.35-.61,4.38,.07,5.79,2.52,.42,.72,.85,1.46,1.4,2.05,.58,.63,1.24,1.24,1.96,1.59,1.8,.87,2.83,.23,3.36-1.93,.71-2.87,2.35-3.98,4.88-3.28,2.71,.75,5.42,1.53,8.12,2.31,2.74,.79,5.5,.9,8.29,.5Z"
        />
      </g>
    </g>
  );
};

export default RedLipstick;
