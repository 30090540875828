const Whine = () => {
  const styles = {
    one: {
      fill: "#fff",
    },
    two: {
      fill: "#c1272d",
    },
  };

  return (
    <g transform="translate(507, 463)">
      <path d="M49.15,18.91c-1.43,0-2.43,.08-3.41-.01-6.16-.62-12.28-.34-18.4,.58-6.87,1.03-13.52,2.97-20.24,4.61-2,.49-4.03,.88-6.05,1.3-.74,.15-1.12-.21-1.03-.98,.03-.29,.14-.58,.23-.86,1.95-5.73,4.77-10.96,9.02-15.34,3.14-3.24,6.79-5.72,11.12-7.09,6.71-2.13,13-1.26,18.74,2.84,4.46,3.18,7.61,7.41,9.53,12.54,.26,.68,.3,1.44,.5,2.41Zm-1.66-1.38c-2.94-6.99-8.66-12.45-12.79-13.62-.08,.14-.21,.28-.23,.44-.23,1.75-1.35,2.54-2.97,2.81-1.63,.27-3.27,.54-4.9,.8-5.08,.81-10.16,1.62-15.25,2.4-.93,.14-1.62,.45-1.88,1.56,2.97-.26,5.8-.23,8.61,.32,3.66,.71,7.39,1.26,10.71,3.06,2.1,1.14,4.17,1.85,6.57,1.4,.57-.11,1.18,0,1.77,.04,3.41,.26,6.83,.53,10.36,.8ZM2.14,23.6c2.04-.52,3.74-.94,5.44-1.38,1.77-.46,3.54-.95,5.31-1.42,1.71-.46,3.42-.95,5.14-1.38,1.78-.44,3.56-.84,5.36-1.22,1.68-.35,3.36-.64,5.05-.96-.66-.61-1.3-.9-1.96-1.13-5.07-1.76-10.27-2.53-15.66-2.62-2.42-.04-3.79,.63-4.9,2.69-1.26,2.33-2.73,4.55-3.77,7.42ZM33.31,3.39c-5.78-2.9-16.9-.14-20.35,4.95,3.45-.54,6.99-1.14,10.55-1.65,2.63-.38,5.29-.61,7.92-.98,1.69-.24,1.85-.5,1.88-2.33Z" />
      <path d="M47.49,17.54c-3.53-.28-6.94-.55-10.36-.8-.59-.04-1.2-.15-1.77-.04-2.4,.45-4.48-.26-6.57-1.4-3.32-1.8-7.05-2.35-10.71-3.06-2.81-.55-5.64-.58-8.61-.32,.26-1.12,.95-1.42,1.88-1.56,5.09-.78,10.17-1.59,15.25-2.4,1.64-.26,3.27-.53,4.9-.8,1.62-.27,2.74-1.06,2.97-2.81,.02-.16,.15-.3,.23-.44,4.14,1.17,9.85,6.63,12.79,13.62Z" />
      <path
        style={styles.two}
        d="M2.14,23.6c1.04-2.87,2.51-5.08,3.77-7.42,1.11-2.06,2.48-2.73,4.9-2.69,5.38,.09,10.59,.86,15.66,2.62,.66,.23,1.3,.52,1.96,1.13-1.68,.32-3.37,.61-5.05,.96-1.79,.37-3.58,.78-5.36,1.22-1.72,.43-3.42,.92-5.14,1.38-1.77,.48-3.53,.96-5.31,1.42-1.7,.44-3.4,.86-5.44,1.38Z"
      />
      <path
        style={styles.one}
        d="M33.31,3.39c-.03,1.82-.19,2.09-1.88,2.33-2.63,.37-5.29,.6-7.92,.98-3.55,.51-7.1,1.1-10.55,1.65C16.41,3.25,27.53,.49,33.31,3.39Z"
      />
    </g>
  );
};

export default Whine;
