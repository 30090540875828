const OHHH = () => {
  return (
    <g transform="translate(514, 460)">
      <path d="M25.46,0c-2.41,.11-4.73,.37-6.97,1.18-3.86,1.4-6.7,3.95-8.63,7.54-.45,.85-.83,1.74-1.3,2.58-.55,1-1.16,1.92-2.19,2.57-1.32,.83-1.38,2.45,0,3.25,1.5,.87,2.07,2.2,2.8,3.59,1.58,3,3.66,5.69,6.11,8,4.73,4.46,12.34,4.58,17.42,.54,2.54-2.02,4.28-4.7,6.25-7.2,.45-.57,.88-1.15,1.39-1.65,.75-.74,1.58-1.26,2.75-.9,.43,.13,.96,.05,1.43-.03,1.05-.17,1.75-.8,2.02-1.82,.27-1.02-.05-1.9-.84-2.6-.13-.12-.28-.26-.44-.31-1.97-.53-2.54-2.22-3.21-3.83-.6-1.44-1.19-2.89-1.96-4.26-1.59-2.83-3.95-4.64-7.05-5.52-2.51-.71-5.07-1.03-7.6-1.13Z" />
      <path d="M44.51,29.06c-1.69,.73-2.96,1.61-4.27,2.42-2.66,1.65-5.25,3.37-8.28,4.37-2.31,.76-4.63,1.32-7.06,1.34-4.86,.04-9.58-.58-14.09-2.57-3.26-1.43-6.44-2.99-9.47-4.84-.38-.23-.73-.65-1.34-.41,.03,.79,.64,1.15,1.13,1.51,3.92,2.8,7.84,5.58,12.29,7.51,3.55,1.54,7.27,2.15,11.1,1.99,2.05-.09,4.07-.57,5.89-1.55,3.39-1.82,6.83-3.56,9.92-5.9,.81-.61,1.73-1.09,2.59-1.65,.71-.46,1.39-.95,1.58-2.23Z" />
    </g>
  );
};

export default OHHH;
